import {
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { ButtonCompo } from "../../components/Comman/Button";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import endpoint from "../../config/endpoints";
import * as myConstClass from "../../config/messageconstant";

import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import {
  checkedListEmptyMsg,
  noInternetMsg,
  saveWarningMsg,
  serverMsg,
} from "../../config/messageconstant";
import { assignBeatJson } from "../../DynamicFormsJson/Transaction/assignBeat";
import {
  getAreaByUserType,
  getAreaBySalesTeamIds,
} from "../../Slice/area.slice";
import {
  getBeatBySalesTeamIdAreaIdForAllowancePostAPI,
  setBeatBySalesTeamIdAreaId,
  setBeatList,
} from "../../Slice/beat.slice";
import { getDistrictByZone } from "../../Slice/district.slice";
import { getOutletByBeatId } from "../../Slice/outlet.slice";
import { getRegionUpdated } from "../../Slice/region.slice";
import { getSalesExecutiveTypeByUserTypeIdsAndAreaId, getSalesExecutive } from "../../Slice/salesExecutive.slice";
import { getStateByRegion } from "../../Slice/state.slice";
import { getTalukaByDistrict } from "../../Slice/taluka.slice";
import { getZoneByState } from "../../Slice/zone.slice";
import { apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { getStockStakeHolderType } from "../../Slice/stockStakeHolderType.slice";

class AssignBeatAllowanceWiseForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      salesTeamId: "",
      areaId: "",
      setList: [],
      checkList: [],
      isError: false,
      areaName: "",
      salesTeamName: "",
      searchValue: "",
      selection: [],
      allChecked: false,
      open: false,
      DataToSet: [],
      adminFlag: false,
      dynamicMasterData: {
        areaDetail: this.props.areaList.areaBySalesTeam,
        salesExecutiveDetail: this.props.salesExecutive.salesExecutive,
        stockStakeHolderTypelistDetail:
          this.props.stockStakeHolderTypeList?.stockStakeHolderType,

      },
    };
  }

  componentWillReceiveProps(nextProps) {


    if (this.props.stockStakeHolderTypeList !== nextProps.stockStakeHolderTypeList) {
      if (
        this.props.stockStakeHolderTypeList?.stockStakeHolderType !==
        nextProps.stockStakeHolderTypeList?.stockStakeHolderType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stockHolderTypeDetail: nextProps.stockStakeHolderTypeList?.stockStakeHolderType,
          },
        });
      }
    }



    if (this.props.regionList !== nextProps.regionList) {
      if (this.props.regionList?.region !== nextProps.regionList?.region) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            regionDetail: nextProps.regionList?.region,
          },
        });
      }
    }
    if (this.props.stateList !== nextProps.stateList) {
      if (
        this.props.stateList?.stateByRegion !==
        nextProps.stateList?.stateByRegion
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stateDetail: nextProps.stateList?.stateByRegion,
          },
        });
      }
    }
    if (this.props.zoneList !== nextProps.zoneList) {
      if (
        this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            zoneDetail: nextProps.zoneList?.zoneByState,
          },
        });
      }
    }
    if (this.props.districtList !== nextProps.districtList) {
      if (
        this.props.districtList?.districtByZone !==
        nextProps.districtList?.districtByZone
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            districtDetail: nextProps.districtList?.districtByZone,
          },
        });
      }
    }
    if (this.props.talukaList !== nextProps.talukaList) {
      if (
        this.props.talukaList?.talukaByDistrict !==
        nextProps.talukaList?.talukaByDistrict
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            talukaDetail: nextProps.talukaList?.talukaByDistrict,
          },
        });
      }
    }
    if (this.props.areaList !== nextProps.areaList) {
      if (
        this.props.areaList?.areaBySalesTeam !== nextProps.areaList?.areaBySalesTeam
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.areaBySalesTeam,
          },
        });
      }
    }
    if (this.props.areaList !== nextProps.areaList) {
      if (this.props.areaList?.area !== nextProps.areaList?.area) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.area,
          },
        });
      }
    }
    if (this.props.salesExecutive !== nextProps.salesExecutive) {
      if (
        this.props.salesExecutive?.salesExecutive !==
        nextProps.salesExecutive?.salesExecutive
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            salesExecutiveDetail: nextProps.salesExecutive?.salesExecutive,
          },
        });
      }
    }
  }

  async componentDidMount() {
    const {
      getAreaByUserType,
      setBeatBySalesTeamIdAreaId,
      getRegionUpdated,
      showLoador,
      showNotification,
      getStockStakeHolderType,
    } = this.props;
    if (navigator.onLine) {

      const userTypeId = localStorage.getItem("userTypeId");

      if (userTypeId == 1 || userTypeId == 6) {
        showLoador({ loador: true });
        getRegionUpdated().then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });

        this.setState({
          adminFlag: true,
        });
      }

      setBeatBySalesTeamIdAreaId({ row: [] });

      if (userTypeId != 1 && userTypeId != 6) {
        showLoador({ loador: true });
        getAreaByUserType().then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }

      const moduleObjData = localStorage.getItem("moduleObj");

      this.setState({
        fieldDataForModule:
          moduleObjData != null ? JSON.parse(moduleObjData) : {},
      });


      showLoador({ loador: true });
      await getStockStakeHolderType({ userTypeIds: "3,4" }).then(
        ({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
          }
        }
      );

    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  getListById = async (data) => {
    const {
      getStateByRegion,
      getZoneByState,
      getDistrictByZone,
      getTalukaByDistrict,
      getAreaBySalesTeamIds,
      getSalesExecutiveTypeByUserTypeIdsAndAreaId,
      getSalesExecutive,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {

      if (data.stockHolderType && data.stockHolderType != "") {
        showLoador({ loador: true });
        await getSalesExecutive({ stockStakeHolderId: data.stockHolderType }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }

      if (data.region && data.region != "") {
        showLoador({ loador: true });
        await getStateByRegion({ regionIds: data.region }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.state && data.state != "") {
        showLoador({ loador: true });
        await getZoneByState({ stateIds: data.state }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.zone && data.zone != "") {
        showLoador({ loador: true });
        await getDistrictByZone({ zoneIds: data.zone }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.district && data.district != "") {
        showLoador({ loador: true });
        await getTalukaByDistrict({ districtIds: data.district }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.salesExecutive && data.salesExecutive != "") {
        let salesTeamJson = {
          salesTeamIds: data.salesExecutive.split(","),
        };
        showLoador({ loador: true });
        await getAreaBySalesTeamIds({ salesTeamIds: salesTeamJson }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.area && data.area != "") {
        let dataToSave = {
          userTypeIds: [3, 4],
          areaIds: [data.area.id],
        };

        showLoador({ loador: true });
        await getSalesExecutiveTypeByUserTypeIdsAndAreaId({
          dataJson: dataToSave,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onSearch = (data) => {
    const {
      getBeatBySalesTeamIdAreaIdForAllowancePostAPI,
      showLoador,
      showNotification,
      areaList,
      salesExecutive
    } = this.props;
    if (navigator.onLine) {
      this.setState({
        salesTeamId: data.salesExecutive,
        areaId: data.areaIds,
      });


      let dataToSend = {
        salesTeamId: data.salesExecutive,
        areaIds: data.areaIds.split(","),
      }
      showLoador({ loador: true });
      getBeatBySalesTeamIdAreaIdForAllowancePostAPI({ jsonData: dataToSend }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }

  };

  onSubmit = () => {
    const { beatList, showLoador, showNotification } = this.props;
    const { salesTeamId, areaId } = this.state;
    let filterBeatList = [];
    let hasError = false;

    beatList?.beat.length != 0 &&
      beatList?.beat.map((beatObject) => {

        if (beatObject.isChecked) {

          if (beatObject.isError) {
            hasError = true;
          }

          const beatListObjectToSave = {
            beat: {
              id: beatObject.beatId,
            },
            salesTeam: {
              id: salesTeamId,
            },
            beatType: +beatObject.beatType,
            ratePerKm: beatObject.ratePerKm ? +beatObject.ratePerKm : 0,
            dailyAllowance: beatObject.dailyAllowance ? +beatObject.dailyAllowance : 0,
            distanceFromHq: beatObject.distance ? +beatObject.distance : 0,
          };
          filterBeatList.push(beatListObjectToSave);
        }
      });

    let dataToSave = {
      salesTeamId: salesTeamId,
      areaIds: areaId.split(","),
      beatAssign: filterBeatList,
    };

    console.log(dataToSave)

    if (filterBeatList.length != 0) {
      if (!hasError) {
        swal({
          title: "Are you sure?",
          text: saveWarningMsg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            showLoador({ loador: true });
            apiPost({
              url: endpoint.assignBeat + "/save-list-with-info",
              postBody: dataToSave,
            }).then(({ success }) => {
              showLoador({ loador: false });
              if (success) {
                showNotification({
                  msg: myConstClass.savemsg,
                });
                window.location.reload();
              } else {
                showNotification({
                  msg: myConstClass.saveFailedMsg,
                  severity: "error",
                });
              }
            });
          }
        });
      }

    } else {
      showNotification({
        msg: checkedListEmptyMsg,
        severity: "error",
      });
    }
  };

  onSearchBox = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  handleAllCheckedClick = (e) => {
    const { beatList, setBeatList } = this.props;
    this.setState({ selection: e });
    this.setState({ allChecked: e.target.checked });
    let dataList = beatList?.beat.map((dataObj) => {
      return {
        ...dataObj,
        isChecked: e.target.checked,
      };
    });
    setBeatList({ row: dataList });
  };

  handleClick = (e, id) => {
    const { beatList, setBeatList } = this.props;
    this.setState({ selection: e });
    let data = beatList?.beat.map((dataObj) => {
      if (dataObj.id == id) {
        return {
          ...dataObj,
          isChecked: e.target.checked,
        };
      } else {
        return dataObj;
      }
    });
    setBeatList({ row: data });
  };

  changeRadioHandler = (e, id) => {
    const { beatList, setBeatList } = this.props;

    let data = beatList?.beat.map((dataObj) => {
      if (dataObj.id == id) {
        return {
          ...dataObj,
          beatType: e.target.value,
          ratePerKm: e.target.value == 0 ? 0 : dataObj.ratePerKm,
        };
      } else {
        return dataObj;
      }
    });
    setBeatList({ row: data });
  };

  changeHandler = (row, name) => (e) => {

    const { name, value, placeholder } = e.target;
    const { beatList, setBeatList } = this.props;

    let updatedList = beatList?.beat.map((dataObj) => {
      if (dataObj.id === row.id) {
        return {
          ...dataObj,
          [name]: value,
          [`${name}Error`]: value.trim() === "" ? [placeholder] + " cannot be empty" : "",
          isError: value.trim() === "" ? true : false,
        };
      } else {
        return dataObj;
      }
    });

    setBeatList({ row: updatedList });
  };

  getFilteredTableData = (searchData) => {
    const { searchValue } = this.state;
    return searchData.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["beatName"] &&
          currentRow["beatName"]
            .toString()
            .toLowerCase()
            .includes(searchValue.trim().toLowerCase())) ||
        (currentRow["areaName"] &&
          currentRow["areaName"]
            .toString()
            .toLowerCase()
            .includes(searchValue.trim().toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
    });
  };

  render() {
    const { beatList } = this.props;
    const { dynamicMasterData, allChecked } = this.state;
    return (
      <>
        <Paper
          sx={{
            padding: 2,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            dynamicMasterData={dynamicMasterData}
            getListById={this.getListById}
            searchList={assignBeatJson.searchList}
            showSaveBtn={true}
            showSaveBtnMain={false}
            showTitle={assignBeatJson.showTitle}
            screenTitle={"Beat Configuration Allowance Wise"}
            fieldMeta={assignBeatJson.fieldMetaForAllowanceWise}
            showCancel={assignBeatJson.showCancel}
            apiBaseURL={assignBeatJson.apiBaseURL}
            showSaveNextBtn={assignBeatJson.showSaveNextBtn}
            saveBtnText={"Search"}
            onSave={this.onSearch}
          />
          <>
            <LandingScreenHeader
              screenTitle={""}
              showSearchBox={true}
              showTable={true}
              showFilter={false}
              showPdfDownload={false}
              showExcelDownload={false}
              onSearch={this.onSearchBox}
            />

            <TableContainer style={{ maxHeight: "550px", overflow: "auto" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="error"
                        onClick={(event) => this.handleAllCheckedClick(event)}
                        checked={allChecked}
                        disabled={false}
                        inputProps={{
                          "aria-label": "select all desserts",
                        }}
                      />
                    </TableCell>
                    <TableCell align="center" width="5%">
                      Sr. No.
                    </TableCell>
                    <TableCell align="center" width="12%">
                      Beat Name
                    </TableCell>
                    <TableCell align="center" width="12%">
                      Area Name
                    </TableCell>
                    <TableCell align="center" width="15%">
                      Beat Distance(KM)
                    </TableCell>
                    <TableCell align="center" width="10%">
                      Beat Type
                    </TableCell>
                    <TableCell align="center" width="10%">
                      Rate per KM
                    </TableCell>
                    <TableCell align="center" width="12%">
                      Daily Allowance
                    </TableCell>
                    <TableCell align="center" width="15%">
                      Travelling Allowance
                    </TableCell>
                    <TableCell align="center" width="12%">
                      Total
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.getFilteredTableData(beatList?.beat)?.map(
                    (row, index) => (
                      <TableRow key={row.id}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            onClick={(event) => this.handleClick(event, row.id)}
                            checked={row.isChecked}
                            disabled={false}
                            inputProps={{
                              "aria-labelledby": `enhanced-table-checkbox-${0}`,
                            }}
                          />
                        </TableCell>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="left">{row.beatName}</TableCell>
                        <TableCell align="left">{row.areaName}</TableCell>
                        <TableCell align="center">
                          <TextFieldCompo
                            placeHolder="Beat Distance(KM)"
                            color="primary"
                            type="number"
                            name="distance"
                            id={"id"}
                            size="small"
                            value={row.distance}
                            fullWidth
                            onChange={this.changeHandler(row, "distance")}
                            errorText={row.distanceError || ""}
                          />
                        </TableCell>
                        <TableCell align="left">
                          {" "}
                          <RadioGroup
                            row
                            aria-label={"Type"}
                            name={"beatType"}
                            id={"beatType"}
                            value={row.beatType}
                            onChange={(event) =>
                              this.changeRadioHandler(event, row.id)
                            }
                            size="small"
                          >
                            <FormControlLabel
                              value={0}
                              control={<Radio />}
                              label={"Local"}
                            />
                            <FormControlLabel
                              value={1}
                              control={<Radio />}
                              label={"Outstation"}
                            />
                          </RadioGroup>
                        </TableCell>
                        <TableCell align="center">
                          <TextFieldCompo
                            placeHolder="Rate Per KM"
                            color="primary"
                            type="number"
                            name="ratePerKm"
                            id={"id"}
                            size="small"
                            value={row.ratePerKm}
                            fullWidth
                            onChange={this.changeHandler(row, "ratePerKm")}
                            errorText={row.ratePerKmError || ""}
                            disabled={row.beatType == 0 ? true : false}
                          />
                        </TableCell>
                        <TableCell>
                          <TextFieldCompo
                            placeHolder="Daily Allowance"
                            color="primary"
                            type="number"
                            name="dailyAllowance"
                            id={"id"}
                            size="small"
                            value={row.dailyAllowance}
                            fullWidth
                            onChange={this.changeHandler(row, "dailyAllowance")}
                            errorText={row.dailyAllowanceError || ""}
                          />
                        </TableCell>

                        <TableCell align="right">
                          {(+row.ratePerKm * +row.distance).toFixed(2)}
                        </TableCell>
                        <TableCell align="right">
                          {(+row.dailyAllowance + +row.ratePerKm * +row.distance).toFixed(2)}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>

          <br />
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            rowSpacing={2}
            columnSpacing={{ xs: 12, sm: 6, md: 1 }}
            container
            justifyContent="right"
          >
            <ButtonCompo
              size="medium"
              type="submit"
              variant="contained"
              name="Submit"
              onClick={this.onSubmit}
            />
          </Grid>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  managerList: state.manager,
  areaList: state.area,
  salesExecutive: state.salesExecutive,
  beatList: state.beat,
  outletList: state.outlet,
  regionList: state.region,
  stateList: state.states,
  zoneList: state.zone,
  districtList: state.district,
  talukaList: state.taluka,
  stockStakeHolderTypeList: state.stockStakeHolderType,
  salesTeamList: state.salesExecutive,

});
const mapDispatchToProps = {
  getAreaByUserType,
  getRegionUpdated,
  getSalesExecutiveTypeByUserTypeIdsAndAreaId,
  getBeatBySalesTeamIdAreaIdForAllowancePostAPI,
  getStateByRegion,
  getZoneByState,
  getDistrictByZone,
  getTalukaByDistrict,
  getAreaBySalesTeamIds,
  getStateByRegion,
  getOutletByBeatId,
  showLoador,
  showNotification,
  setBeatBySalesTeamIdAreaId,
  setBeatList,
  getStockStakeHolderType,
  getSalesExecutive,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignBeatAllowanceWiseForm);
