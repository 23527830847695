import CloseIcon from "@mui/icons-material/Close";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import { LabelCompo } from "../../components/Comman/Label";

import { ButtonCompo } from "../../components/Comman/Button";
import { blackColor, checkboxColor } from "../../config/ColorObj";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const BeatPopup = ({
  handleCloses,
  open,
  onSubmit,
  beatSalesPersonWise,
  onCheckBoxClick,
  matrixData,
}) => {
  const [checkList, setCheckList] = [];
  const handleClose = () => {
    handleCloses();
  };
  return (
    <>
      <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
          maxWidth="md"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Beat List
          </BootstrapDialogTitle>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            sx={{ p: 2 }}
            justifyContent="space-evenly"
          >
            <Grid item xs={12} sm={12} md={2}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label="Date : "
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={beatSalesPersonWise.date}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label="Sales Person Name : "
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={beatSalesPersonWise.salesTeam.name}
              />
            </Grid>
          </Grid>
          <DialogContent>
            {matrixData.length != 0 && (
              <>
                <TableContainer>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{ width: "5%" }}
                        ></TableCell>
                        <TableCell align="center" sx={{ width: "5%" }}>
                          Sr. No
                        </TableCell>
                        <TableCell align="center" sx={{ width: "55%" }}>
                          Beats
                        </TableCell>
                        <TableCell align="center" sx={{ width: "55%" }}>
                          Area
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {matrixData.map((matrixDataRow, indexRow) => {
                        return (
                          <TableRow>
                            <TableCell>
                              <FormControl component="fieldset">
                                <FormGroup aria-label="position" row>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        sx={{
                                          color: blackColor,
                                          "&.Mui-checked": {
                                            color: checkboxColor,
                                          },
                                        }}
                                        checked={matrixDataRow.checked}
                                      />
                                    }
                                    onChange={(e) => {
                                      onCheckBoxClick(
                                        matrixDataRow,
                                        indexRow,
                                        e
                                      );
                                    }}
                                  />
                                </FormGroup>
                              </FormControl>
                            </TableCell>
                            <TableCell width="10%" align="center">
                              {indexRow + 1}
                            </TableCell>
                            <TableCell>{matrixDataRow.beatName}</TableCell>
                            <TableCell>{
                              matrixDataRow.area !== null
                                && matrixDataRow.area.name !== null ? matrixDataRow.area.name
                                : ""
                            }</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <br />
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                  container
                  justifyContent="right"
                >
                  <ButtonCompo
                    size="medium"
                    type="submit"
                    variant="contained"
                    name="Submit"
                    onClick={onSubmit}
                  />
                </Grid>
              </>
            )}

            <br />
          </DialogContent>
        </BootstrapDialog>
      </Paper>
    </>
  );
};
const mapStateToProps = (state) => ({
  beatList: state.beat,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BeatPopup);
