import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { showMyBillListJson } from "../../DynamicFormsJson/Transaction/showMyBillList";
import {
  getBillByDateSearchList,
  getLatestBillDetails,
} from "../../Slice/orderBillList.slice";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import endpoint from "../../config/endpoints";
import logo from "../../Images/gokulImg.png";
import ofc from "../../Images/OFC_logo.png";
import TiroDevanagariHindi from "../../config/TiroDevanagariHindi-Regular-normal"; // Replace with the actual path to your font file
import Aakar from "../../config/Aakar-normal";
import jsPDF from "jspdf";
import { compose } from "@reduxjs/toolkit";
import { langugae } from "../../config/languageJSON";

import {
  checkedListEmptyMsg,
  noInternetMsg,
  receiveBillSuccess,
  serverMsg,
  syncMsg,
} from "../../config/messageconstant";
import { withRouter } from "../../components/withRouter";
import AutoComplete from "../../components/Comman/AutoComplete";
import { getActiveLanguage } from "../../Slice/multilingual.slice";
import { redColor, titleColor } from "../../config/ColorObj";
import { ShowMyBillListColumns } from "../../tableColumns/table-columns";
import { apiGet, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { downloadF } from "../PDF/PDFDownload";
import moment from "moment";
import { Grid, Paper } from "@mui/material";
import CheckBoxSelectionTable from "../../components/Comman/RT/CheckBoxSelectionTable";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import dayjs from "dayjs";
let billListColumns = [
  {
    title: "Bill No",
    name: "billNo",
    positionCenter: true,
    canSearch: true,
    align: "center",
  },
  {
    title: "Bill Date",
    name: "billDate",
    positionCenter: true,
    canSearch: true,
    align: "center",
  },
  {
    title: "Total Quantity",
    name: "totalQty",
    positionCenter: true,
    canSearch: true,
    align: "right",
  },
  {
    title: "Taxable Amount",
    name: "taxableAmt",
    positionCenter: true,
    canSearch: true,
    align: "right",
  },
  {
    title: "CGST",
    name: "cgstAmt",
    positionCenter: true,
    canSearch: true,
    align: "right",
  },
  {
    title: "SGST",
    name: "sgstAmt",
    positionCenter: true,
    canSearch: true,
    align: "right",
  },
  {
    title: "IGST",
    name: "igstAmt",
    positionCenter: true,
    canSearch: true,
    align: "right",
  },
  {
    title: "Total Amount",
    name: "totalAmt",
    positionCenter: true,
    canSearch: true,
    align: "right",
  },
  {
    title: "Total Discount",
    name: "itemDiscountAmt",
    positionCenter: true,
    canSearch: true,
    align: "right",
  },
  {
    title: "Other Amount",
    name: "discountOnbillAmt",
    positionCenter: true,
    canSearch: true,
    align: "right",
  },
  {
    title: "Bill Received",
    name: "isRecievedStatus",
    positionCenter: true,
    canSearch: true,
    align: "center",
  },

  {
    title: "Action",
    name: "action",
    positionCenter: true,
    showInscreen: true,
    alignCell: "center",
    alignCenter: "left",
    minWidth: 200,
  },
];
class ShowMyBillList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      langNew: "",
      lang: "2",
      searchBy: "0",
      dynamicMasterData: {},
      formData: {},
      selection: [],
      fieldDataForModule: {},
    };
  }
  async componentDidMount() {
    await this.getData();
  }
  getData = async () => {
    const {
      getLatestBillDetails,
      getActiveLanguage,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {



      showLoador({ loador: true });
      await getLatestBillDetails().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getActiveLanguage().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      const moduleObjData = localStorage.getItem("moduleObj");
      this.setState({
        fieldDataForModule:
          moduleObjData != null ? JSON.parse(moduleObjData) : {},
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  getBillListData = ({ fromdate, todate }) => {
    const { getBillByDateSearchList, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getBillByDateSearchList({
        fromdate: fromdate,
        todate: todate,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  getSyncBill = ({ fromdate, todate }) => {
    const { showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiGet({
        url: endpoint.holderBillSync + "?fromDate=" + fromdate + "&toDate=" + todate,
      }).then(({ data, success }) => {
        console.log(data);
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          showNotification({ msg: syncMsg });
          this.getBillListData({ fromdate: fromdate, todate: todate });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onSearchList = (data) => {
    if (data.flag) {
      this.setState({
        searchBy: data.flag,
        formData: {
          flag: data.flag,
          fromdate: dayjs(),
          todate: dayjs(),
        },
      });
    }

    if (data.flag == 0) {
      this.getBillListData({ fromdate: data.fromdate, todate: data.todate });
    } else {
      this.getSyncBill({ fromdate: data.fromdate, todate: data.todate });
    }
  };


  onSearchListSimple = (data) => {
    this.getBillListData({ fromdate: data.fromdate, todate: data.todate });
  };

  rowPDF = (rowData) => {
    const { lang } = this.state;
    const { showLoador, showNotification } = this.props;
    localStorage.setItem("lang", lang);

    localStorage.setItem("purchaseBillPdfId", rowData.id);
    window.open("./purchase-bill-pdf", "_blank");

  };

  onClickApply = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to receive the bill?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willUpdate) => {
      if (willUpdate) {
        if (navigator.onLine) {
          this.props.showLoador({ loador: true });
          apiGet({
            url:
              endpoint.stockStakeholderBillHeader +
              "/update-received-status?ids=" +
              rowData.id +
              "&status=1",
          }).then(({ success }) => {
            if (success) {
              const { formData } = this.state;
              this.props.showLoador({ loador: false });
              this.props.showNotification({
                msg: receiveBillSuccess,
              });
              // this.getBillListData({
              //   fromdate: formData.fromdate,
              //   todate: formData.todate,
              // });
              this.getData();
            } else {
              this.props.showNotification({
                msg: serverMsg,
                severity: "error",
              });
              this.props.showLoador({ loador: false });
            }
          });
        } else {
          this.props.showNotification({
            msg: noInternetMsg,
            severity: "error",
          });
        }
      }
    });
  };

  // generatePDFHeader = (rowList) => {
  //   var doc = new jsPDF();

  //   rowList.map((dataToSet, index) => {
  //     var line = 15; // Line height to start text at
  //     var lineHeight = 5;
  //     var leftMargin = 10;
  //     var wrapWidth = 120;
  //     doc.setFontSize(16);
  //     var DelerText = dataToSet.fromBillFirmName
  //       ? dataToSet.fromBillFirmName
  //       : "-";
  //     var splitText = doc.splitTextToSize(DelerText, wrapWidth + 10);
  //     for (var i = 0, length = splitText.length; i < length; i++) {
  //       // loop thru each line and increase
  //       doc.text(splitText[i], leftMargin, line);
  //       line = lineHeight + line;
  //     }
  //     line = line + 2;
  //     doc.setFontSize(10);
  //     doc.setFont(undefined, "bold");
  //     doc.text("Address : ", leftMargin, line);
  //     doc.setFont(undefined, "normal");
  //     var addressText = dataToSet.fromBillAddress
  //       ? dataToSet.fromBillAddress
  //       : "-";
  //     var splitTextAddr = doc.splitTextToSize(addressText, wrapWidth);
  //     for (var i = 0, length = splitTextAddr.length; i < length; i++) {
  //       // loop thru each line and increase
  //       if (i == 0) {
  //         doc.text(splitTextAddr[i], leftMargin + 20, line);
  //       } else {
  //         doc.text(splitTextAddr[i], leftMargin, line);
  //       }

  //       line = lineHeight + line;
  //     }
  //     let yAxis = line + 2;
  //     var pageWidth = doc.internal.pageSize.getWidth();
  //     var xPos = pageWidth - 45;
  //     var top = 0;

  //     let fromBillContactPersonNo = dataToSet.fromBillContactPersonNo
  //       ? dataToSet.fromBillContactPersonNo
  //       : "-";
  //     doc.text("Mobile : " + fromBillContactPersonNo, 10, yAxis, {
  //       maxWidth: wrapWidth,
  //     });

  //     yAxis = yAxis + 7;
  //     let fromBillFssiFdiNo = dataToSet.fromBillFssiFdiNo
  //       ? dataToSet.fromBillFssiFdiNo
  //       : "-";
  //     let fromBillGstNo = dataToSet.fromBillGstNo
  //       ? dataToSet.fromBillGstNo
  //       : "-";

  //     doc.text(
  //       "GST No : " + fromBillGstNo + " , FSSI No : " + fromBillFssiFdiNo,
  //       10,
  //       yAxis,
  //       { maxWidth: wrapWidth }
  //     );
  //     doc.line(150, 5, 150, 50);
  //     doc.addImage(logo, "JPEG", xPos, 10, 30, 30);
  //     yAxis = yAxis + 5;
  //     doc.line(5, yAxis, pageWidth - 5, yAxis);
  //     yAxis = yAxis + 7;
  //     var xPosTax = pageWidth - 57;
  //     let yAxisTax = yAxis;

  //     doc.setFont(undefined, "bold");
  //     doc.text("Tax Invoice", xPos, yAxisTax, { maxWidth: wrapWidth });
  //     yAxisTax = yAxisTax + 7;
  //     doc.text("Bill No :", xPosTax, yAxisTax, { maxWidth: wrapWidth });
  //     doc.setFont(undefined, "normal");
  //     let billNo = dataToSet.billNo ? dataToSet.billNo : "-";

  //     doc.text(billNo, xPosTax + 17, yAxisTax, { maxWidth: 100 });
  //     yAxisTax = yAxisTax + 7;
  //     doc.setFont(undefined, "bold");
  //     doc.text("Date :", xPosTax, yAxisTax, { maxWidth: wrapWidth });
  //     doc.setFont(undefined, "normal");
  //     let billDate = dataToSet.billDate ? dataToSet.billDate : "-";

  //     doc.text(billDate, xPosTax + 17, yAxisTax, { maxWidth: wrapWidth });
  //     yAxisTax = yAxisTax + 7;
  //     doc.setFont(undefined, "bold");
  //     let insertDateTime = dataToSet.insertDateTime
  //       ? dataToSet.insertDateTime.split(" ")
  //       : [];
  //     doc.text("Time :", xPosTax, yAxisTax, { maxWidth: wrapWidth });
  //     doc.setFont(undefined, "normal");
  //     let insertDateTimeData =
  //       insertDateTime.length == 2 ? insertDateTime[1] : "";
  //     doc.text(insertDateTimeData, xPosTax + 17, yAxisTax, {
  //       maxWidth: wrapWidth,
  //     });
  //     line = yAxis;
  //     top = 10;
  //     doc.setFontSize(8);
  //     doc.text("Bill To : ", 10, line);
  //     doc.setFontSize(10);
  //     doc.setFont(undefined, "bold");
  //     let toBill = dataToSet.toBill ? dataToSet.toBill : {};

  //     var DText = toBill.ownerName ? toBill.ownerName : "";
  //     var splitTextDText = doc.splitTextToSize(DText, wrapWidth);
  //     for (var i = 0, length = splitTextDText.length; i < length; i++) {
  //       if (i == 0) {
  //         doc.text(splitTextDText[i], leftMargin + 10, line);
  //       } else {
  //         doc.text(splitTextDText[i], leftMargin, line);
  //       }
  //       line = lineHeight + line;
  //       top = top + lineHeight;
  //     }
  //     doc.setFont(undefined, "normal");

  //     doc.setFontSize(10);
  //     line = line + 2;
  //     top = top + 2;
  //     let addr = toBill.address ? toBill.address : "";
  //     var DAddrText = "Address : " + addr;
  //     var splitTextDAddrText = doc.splitTextToSize(DAddrText, wrapWidth);
  //     for (var i = 0, length = splitTextDAddrText.length; i < length; i++) {
  //       // loop thru each line and increase
  //       doc.text(splitTextDAddrText[i], leftMargin, line);
  //       line = lineHeight + line;
  //       top = top + lineHeight;
  //     }
  //     yAxis = line + 2;
  //     top = top + 2;
  //     let contactPersonNo = toBill.contactPersonNo
  //       ? toBill.contactPersonNo
  //       : "";
  //     let gstNo = toBill.gstNo != null ? toBill.gstNo : "-";
  //     doc.text(
  //       "Phone No : " + contactPersonNo + " , GST No: " + gstNo,
  //       10,
  //       yAxis,
  //       { maxWidth: wrapWidth }
  //     );

  //     yAxis = yAxis + 7;
  //     top = top + 7;
  //     let fssiFdiNo =
  //       toBill.fssiFdiNo != null && toBill.fssiFdiNo != ""
  //         ? toBill.fssiFdiNo
  //         : "-";
  //     doc.text("Food Licence No : " + fssiFdiNo, 10, yAxis, { maxWidth: 100 });

  //     yAxis = yAxis + 7;
  //     top = top + 7;
  //     doc.line(150, 5, 150, yAxis);
  //     doc.line(5, yAxis, pageWidth - 5, yAxis);
  //     let toBillState = toBill.state != null ? toBill.state : {};
  //     let dataToSetState = dataToSet.state != null ? dataToSet.state : {};
  //     let headData = [
  //       { content: "#" },
  //       { content: "HSN" },
  //       { content: "Item Name" },
  //       { content: "UOM" },
  //       { content: "Qty" },
  //       { content: "Rate" },
  //     ];

  //     var align = {
  //       1: { halign: "center" },
  //       4: { halign: "right" },
  //       5: { halign: "right" },
  //     };
  //     let headData2 = [];
  //     if (toBillState.id == dataToSetState.id) {
  //       headData = [
  //         ...headData,
  //         { content: "CGST", colSpan: 2 },
  //         { content: "SGST", colSpan: 2 },
  //         { content: "Amount" },
  //       ];
  //       headData2 = ["", "", "", "", "", "", "%", "Amt", "%", "Amt", ""];
  //       align = {
  //         ...align,
  //         6: { halign: "right" },
  //         7: { halign: "right" },
  //         8: { halign: "right" },
  //         9: { halign: "right" },
  //         10: { halign: "right" },
  //       };
  //     } else {
  //       headData = [
  //         ...headData,
  //         { content: "IGST", colSpan: 2 },
  //         { content: "Amount" },
  //       ];
  //       headData2 = ["", "", "", "", "", "", "%", "Amt", ""];
  //       align = {
  //         ...align,
  //         6: { halign: "right" },
  //         7: { halign: "right" },
  //         8: { halign: "right" },
  //       };
  //     }

  //     let head = [headData, headData2];
  //     let stockStakeHolderBillDetails =
  //       dataToSet.stockStakeHolderBillDetails != null
  //         ? dataToSet.stockStakeHolderBillDetails
  //         : [];
  //     let productList = [];
  //     stockStakeHolderBillDetails.map((rowData) => {
  //       let packagingType =
  //         rowData.product != null && rowData.product.packagingType != null
  //           ? rowData.product.packagingType
  //           : {};
  //       let filterData = productList.filter(
  //         (row) => row.id == packagingType.id
  //       );
  //       if (filterData.length != 0) {
  //         productList = productList.map((productInfo) => {
  //           if (productInfo.id == packagingType.id) {
  //             let dataToPush = {
  //               ...packagingType,
  //               productList: [...productInfo.productList, rowData],
  //             };
  //             return dataToPush;
  //           } else {
  //             return productInfo;
  //           }
  //         });
  //       } else {
  //         let dataToPush = {
  //           ...packagingType,
  //           productList: [rowData],
  //         };
  //         productList.push(dataToPush);
  //       }
  //     });
  //     console.log(productList);
  //     var data = [];
  //     let totalAmt = 0;
  //     let qtyTot = 0;
  //     productList.map((packagingType) => {
  //       data.push([
  //         {
  //           content:
  //             packagingType.name + " ( " + packagingType.shortName + " ) ",
  //           colSpan: 11,
  //         },
  //       ]);

  //       packagingType.productList.map((rowData, indexData) => {
  //         let productData = [];
  //         let product = rowData.product != null ? rowData.product : {};

  //         productData.push(indexData + 1);
  //         productData.push(product.hsnCode);
  //         productData.push(product.name);
  //         productData.push(rowData.uom != null ? rowData.uom.name : "-");
  //         productData.push(rowData.qty);
  //         qtyTot = qtyTot + rowData.qty;
  //         productData.push(rowData.rate.toFixed(2));
  //         if (toBillState.id == dataToSetState.id) {
  //           productData.push(rowData.cgstPer.toFixed(2));
  //           productData.push(rowData.cgstAmt.toFixed(2));
  //           productData.push(rowData.sgstPer.toFixed(2));
  //           productData.push(rowData.sgstAmt.toFixed(2));
  //         } else {
  //           productData.push(rowData.igstPer.toFixed(2));
  //           productData.push(rowData.igstAmt.toFixed(2));
  //         }
  //         totalAmt = totalAmt + rowData.totalAmt;
  //         productData.push(rowData.totalAmt.toFixed(2));
  //         data.push(productData);
  //       });
  //     });
  //     data.push([
  //       {
  //         content: "Invoice Total",
  //         colSpan: 4,
  //         styles: { fontWeight: "bold" },
  //       },
  //       qtyTot,
  //       "",
  //       "",
  //       "",
  //       totalAmt.toFixed(2),
  //     ]);
  //     doc.autoTable({
  //       head: head,
  //       body: data,
  //       startY: yAxis + 5,
  //       columnStyles: align,
  //       margin: { right: 5, left: 5, top: 20 },
  //       headStyles: {
  //         halign: "center",
  //         lineWidth: 0.2,
  //         lineColor: "#fff",
  //         fillColor: [3, 169, 244],
  //         textColor: [255, 255, 255],
  //       },
  //       theme: "grid",

  //       showHead: "everyPage",

  //       didDrawPage: function (data) {
  //         doc.setTextColor(40);
  //         var pageWidth = doc.internal.pageSize.getWidth();
  //         if (data.pageNumber > 1) {
  //           var xPos1 = pageWidth - 45;
  //           var line1 = 15;
  //           var yAxisTax = 10;
  //           doc.setFontSize(10);
  //           doc.text("Tax Invoice", 90, 5);
  //           var lineHeight1 = 5;
  //           var wrapWidth1 = 130;
  //           doc.setFontSize(8);
  //           doc.text("Bill To", 10, line1);
  //           doc.setFontSize(10);
  //           doc.setFont(undefined, "bold");
  //           var DText = " Vijaybhai Vrundavan Pan / Vijaybhai";
  //           var splitTextDText = doc.splitTextToSize(DText, wrapWidth1);
  //           for (var i = 0, length = splitTextDText.length; i < length; i++) {
  //             // loop thru each line and increase
  //             doc.text(splitTextDText[i], 20, line1);
  //             line1 = lineHeight1 + line1;
  //           }
  //           let text = "INV/23-24/41534 ( " + "07-10-2023" + " )";
  //           var textWidth1 =
  //             (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
  //             doc.internal.scaleFactor;

  //           var xPos1 = pageWidth - textWidth1 - 10;
  //           doc.setFont(undefined, "normal");
  //           doc.text(text, xPos1, 15, { maxWidth: 100 });
  //         }
  //         var str = "Page " + doc.internal.getNumberOfPages();

  //         // jsPDF 1.4+ uses getWidth, <1.4 uses .width
  //         var pageSize = doc.internal.pageSize;
  //         var pageHeight = pageSize.height
  //           ? pageSize.height
  //           : pageSize.getHeight();
  //         let pb = "Powered By Bizintel DMS";
  //         const compName = localStorage.getItem("compName");
  //         var textWidthCN =
  //           (doc.getStringUnitWidth(compName) * doc.internal.getFontSize()) /
  //           doc.internal.scaleFactor;
  //         var textWidth =
  //           (doc.getStringUnitWidth(pb) * doc.internal.getFontSize()) /
  //           doc.internal.scaleFactor;

  //         // Calculate the X position to align to the right of the page

  //         doc.setLineWidth(0.2);
  //         if (data.pageNumber > 1) {
  //           doc.rect(5, 7, pageWidth - 10, pageHeight - 12);
  //         } else {
  //           doc.rect(5, 5, pageWidth - 10, pageHeight - 10);
  //         }
  //         // Adjust for a margin if needed
  //         doc.setFontSize(10);
  //         var xPosCN = (pageWidth - textWidthCN) / 2;
  //         var xPos = pageWidth - textWidth - 10;
  //         doc.text(compName, 10, pageHeight - 10);

  //         doc.setFontSize(10);
  //         doc.text(pb, xPos, pageHeight - 10);
  //         doc.text(str, xPosCN, pageHeight - 10);
  //       },
  //     });

  //     let productHeadWithPack =
  //     dataToSet.packagingTypes.length != 0 ? ["Filling Type"] : [];
  //     let productListWithPack = [];
  //     let productListalign = { 0: { cellWidth: 50 } };
  //     dataToSet.categories.map((categoryData, index) => {
  //       productHeadWithPack.push(categoryData.name);
  //       productListalign = {
  //         ...productListalign,
  //         [index + 1]: { halign: "right" },
  //       };
  //     });

  //     dataToSet.packagingTypes.map((packagingTypeData) => {
  //       let GruopInfo = [packagingTypeData.name];
  //       dataToSet.categories.map((categoryData) => {
  //         let flag = 0;
  //         dataToSet.dtoCategoryWithPackagingTypes.map((rowData) => {
  //           if (
  //             categoryData.id == rowData.category.id &&
  //             rowData.packagingType.id == packagingTypeData.id
  //           ) {
  //             flag = 1;
  //             GruopInfo.push(rowData.totalQty);
  //           }
  //         });
  //         if (flag == 0) {
  //           GruopInfo.push("");
  //         }
  //       });
  //       productListWithPack.push(GruopInfo);
  //     });
  //     console.log(productListWithPack);
  //     console.log(productHeadWithPack);
  //     doc.autoTable({
  //       head: [productHeadWithPack],
  //       body: productListWithPack,

  //       startY: doc.autoTableEndPosY() + 5,
  //       columnStyles: productListalign,
  //       margin: { right: 6, left: 6, top: 20 },
  //       headStyles: {
  //         halign: "center",
  //         lineWidth: 0.2,
  //         lineColor: "#fff",
  //         fillColor: [3, 169, 244],
  //         textColor: [255, 255, 255],
  //       },
  //       theme: "grid",

  //       showHead: "everyPage",

  //       didDrawPage: function (data) {},
  //     });
  //     var line = doc.autoTableEndPosY() + 5; // Line height to start text at
  //     var lineHeight = 5;
  //     var leftMargin = 10;
  //     var wrapWidth = 120;

  //     doc.text("Note : ", leftMargin, line);
  //     var line = line + 15;
  //     doc.line(5, line, pageWidth - 5, line);
  //     var line = line + 20;
  //     doc.text("Subject to Jurisdiction. ", leftMargin, line);
  //     doc.text("Aurthorised Sign", 175, line);
  //     var line = line + 5;
  //     doc.line(5, line, pageWidth - 5, line);
  //     if (index + 1 != rowList.length) {
  //       doc.addPage();
  //     }
  //   });

  //   doc.save("outputImage.pdf");
  // };

  generatePDFHeader = (rowList) => {
    var doc = new jsPDF();
    const { lang } = this.state;
    if (lang != 3) {
      doc.addFileToVFS(
        "TiroDevanagariHindi-Regular-normal.ttf",
        TiroDevanagariHindi
      );
      doc.addFont(
        "TiroDevanagariHindi-Regular-normal.ttf",
        "TiroDevanagariHindi-Regular",
        "normal"
      );
      doc.setFont("TiroDevanagariHindi-Regular");
    } else {
      doc.addFileToVFS("Aakar-normal.ttf", Aakar);
      doc.addFont("Aakar-normal.ttf", "Aakar", "normal");
      doc.setFont("Aakar");
    }
    let fontName = lang != 3 ? "TiroDevanagariHindi-Regular" : "Aakar";

    rowList.map((dataToSet, indexMain) => {
      let indexInfoData = 0;
      var line = 10; // Line height to start text at
      var lineHeight = 5;
      var leftMargin = 5;
      var wrapWidth = 120;
      doc.setFontSize(12);
      var DelerText = dataToSet.fromBillFirmName
        ? dataToSet.fromBillFirmName
        : "-";
      var splitText = doc.splitTextToSize(DelerText, wrapWidth + 10);
      for (var i = 0, length = splitText.length; i < length; i++) {
        // loop thru each line and increase
        doc.text(splitText[i], leftMargin, line);
        line = lineHeight + line;
      }
      let ownerName = langugae[lang].ownerName
        ? langugae[lang].ownerName + " : "
        : "";

      doc.setFontSize(10);
      line = line + 2;
      var DelerText =
        dataToSet.fromBillOwnerName != null
          ? ownerName + dataToSet.fromBillOwnerName
          : "-";
      var splitText = doc.splitTextToSize(DelerText, wrapWidth + 10);
      for (var i = 0, length = splitText.length; i < length; i++) {
        // loop thru each line and increase
        doc.text(splitText[i], leftMargin, line);
        line = lineHeight + line;
      }
      line = line + 2;

      doc.setFont(fontName, "normal");
      let address = langugae[lang].address
        ? langugae[lang].address + " : "
        : "";

      var addressText = dataToSet.fromBillAddress
        ? address + dataToSet.fromBillAddress
        : "-";

      var splitTextAddr = doc.splitTextToSize(addressText, wrapWidth);
      for (var i = 0, length = splitTextAddr.length; i < length; i++) {
        // loop thru each line and increase
        if (i == 0) {
          doc.text(splitTextAddr[i], leftMargin, line);
        } else {
          doc.text(splitTextAddr[i], leftMargin, line);
        }

        line = lineHeight + line;
      }
      let yAxis = line + 2;
      var pageWidth = doc.internal.pageSize.getWidth();
      var xPos = pageWidth - 50;
      var xPos1 = pageWidth - 55;
      var top = 0;

      let fromBillContactPersonNo = dataToSet.fromBillContactPersonNo
        ? dataToSet.fromBillContactPersonNo
        : "-";
      let mobileNo = langugae[lang].mobileNo
        ? langugae[lang].mobileNo + " : "
        : "";
      doc.text(mobileNo + fromBillContactPersonNo, leftMargin, yAxis, {
        maxWidth: wrapWidth,
      });

      yAxis = yAxis + 7;
      let fromBillFssiFdiNo = dataToSet.fromBillFssiFdiNo
        ? dataToSet.fromBillFssiFdiNo
        : "-";
      let fromBillGstNo = dataToSet.fromBillGstNo
        ? dataToSet.fromBillGstNo
        : "-";
      let gSTNo = langugae[lang].gSTNo ? langugae[lang].gSTNo + " : " : "";
      let FSSAINo = langugae[lang].FSSAINo
        ? langugae[lang].FSSAINo + " : "
        : "";

      doc.text(
        gSTNo + fromBillGstNo + " , " + FSSAINo + fromBillFssiFdiNo,
        leftMargin,
        yAxis,
        { maxWidth: wrapWidth }
      );
      doc.line(150, 5, 150, 50);
      let pdfLogo =
        localStorage.getItem("compId") == "5c140f80-3929-46dc-83b1-592b7bc30d1f"
          ? logo
          : ofc;
      doc.addImage(pdfLogo, "JPEG", xPos1, 5, 45, 35);
      yAxis = yAxis + 5;
      doc.line(3, yAxis, pageWidth - 3, yAxis);
      yAxis = yAxis + 7;
      var xPosTax = pageWidth - 57;
      let yAxisTax = yAxis;
      doc.setFontSize(10);
      doc.setFont(fontName, "bold");

      doc.text("Tax Invoice", xPos, yAxisTax, { maxWidth: wrapWidth });
      yAxisTax = yAxisTax + 7;
      let billNoLan = langugae[lang].billNO
        ? langugae[lang].billNO + " : "
        : "";
      doc.text("Bill No.", xPosTax, yAxisTax, { maxWidth: wrapWidth });
      doc.setFont(fontName, "normal");
      let billNo = dataToSet.billNo ? dataToSet.billNo : "-";

      doc.text(billNo, xPosTax + 17, yAxisTax, { maxWidth: 100 });
      yAxisTax = yAxisTax + 7;
      doc.setFont(fontName, "bold");
      let date = langugae[lang].date ? langugae[lang].date + " : " : "";
      doc.text("Date", xPosTax, yAxisTax, { maxWidth: wrapWidth });
      doc.setFont(fontName, "normal");
      let billDate = dataToSet.billDate ? dataToSet.billDate : "-";

      doc.text(billDate, xPosTax + 17, yAxisTax, { maxWidth: wrapWidth });
      yAxisTax = yAxisTax + 7;
      doc.setFont(fontName, "bold");
      let insertDateTime = dataToSet.insertDateTime
        ? dataToSet.insertDateTime.split(" ")
        : [];
      let time = langugae[lang].time ? langugae[lang].time + " : " : "";
      doc.text("Time", xPosTax, yAxisTax, { maxWidth: wrapWidth });
      doc.setFont(fontName, "normal");
      let insertDateTimeData =
        insertDateTime.length == 2 ? insertDateTime[1] : "";
      doc.text(insertDateTimeData, xPosTax + 17, yAxisTax, {
        maxWidth: wrapWidth,
      });
      line = yAxis;
      top = 10;
      doc.setFontSize(10);
      let billTo = langugae[lang].billTo ? langugae[lang].billTo + " : " : "";
      doc.text(billTo, leftMargin, line);
      let toBill = dataToSet.toBill != null ? dataToSet.toBill : {};
      var DText = toBill.firmName ? toBill.firmName : "";
      var splitTextDText = doc.splitTextToSize(DText, wrapWidth);
      for (var i = 0, length = splitTextDText.length; i < length; i++) {
        if (i == 0) {
          doc.text(splitTextDText[i], leftMargin + 15, line);
        } else {
          doc.text(splitTextDText[i], leftMargin, line);
        }
        line = lineHeight + line;
        top = top + lineHeight;
      }

      line = line + 2;
      var DelerText = toBill.ownerName ? ownerName + toBill.ownerName : "-";

      var splitText = doc.splitTextToSize(DelerText, wrapWidth + 10);
      for (var i = 0, length = splitText.length; i < length; i++) {
        // loop thru each line and increase
        doc.text(splitText[i], leftMargin, line);
        line = lineHeight + line;
      }
      doc.setFont(fontName, "normal");

      doc.setFontSize(10);
      line = line + 2;
      top = top + 2;
      let taluka =
        toBill.beat != null &&
          toBill.beat.area != null &&
          toBill.beat.area.taluka != null
          ? toBill.beat.area.taluka
          : "";
      let talukaName = taluka != null ? taluka.name : "";
      let districtName =
        taluka != null && taluka.district != null ? taluka.district.name : "";
      let addr = toBill.address
        ? toBill.address + " - " + talukaName + " - " + districtName
        : "";
      var DAddrText = address + addr;
      var splitTextDAddrText = doc.splitTextToSize(DAddrText, wrapWidth);
      for (var i = 0, length = splitTextDAddrText.length; i < length; i++) {
        // loop thru each line and increase
        doc.text(splitTextDAddrText[i], leftMargin, line);
        line = lineHeight + line;
        top = top + lineHeight;
      }
      yAxis = line + 2;
      top = top + 2;
      let contactPersonNo = toBill.contactPersonNo
        ? toBill.contactPersonNo
        : "";
      let gstNo = toBill.gstNo != null ? toBill.gstNo : "-";

      let phoneNo = langugae[lang].phoneNo
        ? langugae[lang].phoneNo + " : "
        : "";
      doc.text(
        phoneNo + contactPersonNo + " , " + gSTNo + gstNo,
        leftMargin,
        yAxis,
        { maxWidth: wrapWidth }
      );

      yAxis = yAxis + 7;
      top = top + 7;
      let fssiFdiNo =
        toBill.fssiFdiNo != null && toBill.fssiFdiNo != ""
          ? toBill.fssiFdiNo
          : "-";

      doc.text(FSSAINo + fssiFdiNo, leftMargin, yAxis, { maxWidth: 100 });

      yAxis = yAxis + 7;
      top = top + 7;
      doc.line(150, 5, 150, yAxis);
      doc.line(3, yAxis, pageWidth - 3, yAxis);
      let toBillState = toBill.state != null ? toBill.state : {};
      let dataToSetState = dataToSet.state != null ? dataToSet.state : {};
      let HSN = langugae[lang].HSN ? langugae[lang].HSN + " : " : "";

      let itemName = langugae[lang].itemName
        ? langugae[lang].itemName + " : "
        : "";

      let uom = langugae[lang].uom ? langugae[lang].uom + " : " : "";

      let qty = langugae[lang].qty ? langugae[lang].qty + " : " : "";

      let rate = langugae[lang].rate ? langugae[lang].rate + " : " : "";

      let CGST = langugae[lang].CGST ? langugae[lang].CGST + " : " : "";

      let SGST = langugae[lang].SGST ? langugae[lang].SGST + " : " : "";

      let amount = langugae[lang].amount ? langugae[lang].amount + " : " : "";

      let IGST = langugae[lang].IGST ? langugae[lang].IGST + " : " : "";

      let total = langugae[lang].total ? langugae[lang].total + " : " : "";

      let headData = [
        { content: "#" },
        { content: HSN },
        { content: itemName },
        { content: uom },
        { content: qty },
        { content: rate },
      ];

      var align = {
        1: { halign: "center" },
        4: { halign: "right" },
        5: { halign: "right" },
      };
      let headData2 = [];
      if (toBillState.id == dataToSetState.id) {
        headData = [
          ...headData,
          { content: CGST, colSpan: 2 },
          { content: SGST, colSpan: 2 },
          { content: amount },
        ];
        headData2 = ["", "", "", "", "", "", "%", amount, "%", amount, ""];
        align = {
          ...align,
          6: { halign: "right" },
          7: { halign: "right" },
          8: { halign: "right" },
          9: { halign: "right" },
          10: { halign: "right" },
        };
      } else {
        headData = [
          ...headData,
          { content: IGST, colSpan: 2 },
          { content: amount },
        ];
        headData2 = ["", "", "", "", "", "", "%", amount, ""];
        align = {
          ...align,
          6: { halign: "right" },
          7: { halign: "right" },
          8: { halign: "right" },
        };
      }

      let head = [headData, headData2];
      let stockStakeHolderBillDetails =
        dataToSet.outletBillDetails != null ? dataToSet.outletBillDetails : [];
      let productList = [];
      // dataToSet.packagingTypes.map((packaginType)=>{})

      console.log(productList);
      var data = [];
      let totalAmt = 0;
      let qtyTot = 0;
      dataToSet.packagingTypes.map((packagingType) => {
        data.push([
          {
            content: packagingType.name,
            colSpan: 11,

            styles: { fontWeight: 900, fontSize: 10, fontColor: "#000" },
          },
        ]);
        let indexInfo = 0;
        stockStakeHolderBillDetails.map((stockDetails, indexData) => {
          let packagingTypeDetails =
            stockDetails.product != null &&
              stockDetails.product.packagingType != null
              ? stockDetails.product.packagingType
              : {};

          if (packagingType.id == packagingTypeDetails.id) {
            let productData = [];
            let product =
              stockDetails.product != null ? stockDetails.product : {};
            indexInfo = indexInfo + 1;
            productData.push(indexInfo);
            productData.push(stockDetails.hsnCode);
            productData.push(product.name);
            productData.push(
              stockDetails.uom != null ? stockDetails.uom.name : "-"
            );
            productData.push(stockDetails.qty);
            qtyTot = qtyTot + stockDetails.qty;
            productData.push(stockDetails.rate.toFixed(2));
            if (toBillState.id == dataToSetState.id) {
              productData.push(stockDetails.cgstPer.toFixed(2));
              productData.push(stockDetails.cgstAmt.toFixed(2));
              productData.push(stockDetails.sgstPer.toFixed(2));
              productData.push(stockDetails.sgstAmt.toFixed(2));
            } else {
              productData.push(stockDetails.igstPer.toFixed(2));
              productData.push(stockDetails.igstAmt.toFixed(2));
            }
            totalAmt = totalAmt + stockDetails.totalAmt;
            productData.push(stockDetails.totalAmt.toFixed(2));
            data.push(productData);
          }
        });
      });

      if (toBillState.id == dataToSetState.id) {
        data.push([
          {
            content: total,
            colSpan: 4,
            styles: { fontWeight: 900, fontSize: 12, fontColor: "#000" },
          },
          {
            content: qtyTot,
            styles: { fontWeight: 900, fontSize: 12, fontColor: "#000" },
          },
          "",
          "",
          "",
          "",
          "",
          {
            content: totalAmt.toFixed(2),
            styles: { fontWeight: 900, fontSize: 12, fontColor: "#000" },
          },
        ]);
      } else {
        data.push([
          {
            content: total,
            colSpan: 4,
            styles: { fontWeight: "bold" },
          },
          qtyTot,
          "",
          "",
          "",
          totalAmt.toFixed(2),
        ]);
      }
      var fontSize = 8; // Set your desired font size
      var styles = {
        fontSize: fontSize,
        fontColor: "#000",
        font: lang != 3 ? "TiroDevanagariHindi-Regular" : "Aakar",
      };
      doc.autoTable({
        head: head,
        body: data,
        startY: yAxis + 5,
        columnStyles: align,
        margin: { right: 5, left: 5, top: 20 },
        headStyles: {
          halign: "center",
          lineWidth: 0.2,
          lineColor: "#fff",
          fillColor: [3, 169, 244],
          textColor: [255, 255, 255],
        },
        styles: styles,
        theme: "grid",

        showHead: "everyPage",

        didDrawPage: function (data) {
          doc.setTextColor(40);
          var pageWidth = doc.internal.pageSize.getWidth();
          if (data.pageNumber > 1) {
            var xPos1 = pageWidth - 45;
            var line1 = 15;
            var yAxisTax = 10;
            doc.setFontSize(10);
            doc.setFont(fontName);
            let taxInvoice = langugae[lang].taxInvoice
              ? langugae[lang].taxInvoice + " : "
              : "";
            doc.text(taxInvoice, 90, 10);
            var lineHeight1 = 5;
            var wrapWidth1 = 130;
            doc.setFontSize(10);
            let billTO = langugae[lang].billTO
              ? langugae[lang].billTO + " : "
              : "";
            doc.text(billTO, leftMargin, line1);
            doc.setFontSize(10);
            let toBill = dataToSet.outLet ? dataToSet.outLet : {};

            var DText = toBill.ownerName ? toBill.ownerName : "";
            var splitTextDText = doc.splitTextToSize(DText, wrapWidth1);
            for (var i = 0, length = splitTextDText.length; i < length; i++) {
              // loop thru each line and increase

              if (i == 0) {
                doc.text(splitTextDText[i], leftMargin + 15, line1);
              } else {
                doc.text(splitTextDText[i], leftMargin, line1);
              }
              line1 = lineHeight1 + line1;
            }
            let billDate = dataToSet.billDate ? dataToSet.billDate : "-";

            let text = dataToSet.billNo
              ? dataToSet.billNo + " (" + billDate + ")"
              : "-";
            var textWidth1 =
              (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
              doc.internal.scaleFactor;

            var xPos1 = pageWidth - textWidth1 - 10;
            doc.setFont(fontName, "normal");
            doc.text(text, xPos1, 15, { maxWidth: 100 });
          }
          indexInfoData = indexInfoData + 1;
          var str = "Page " + indexInfoData;

          // jsPDF 1.4+ uses getWidth, <1.4 uses .width
          var pageSize = doc.internal.pageSize;
          var pageHeight = pageSize.height
            ? pageSize.height
            : pageSize.getHeight();
          let pb = "Powered By Bizintel DMS";
          const compName = localStorage.getItem("compName");
          var textWidthCN =
            (doc.getStringUnitWidth(compName) * doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textWidth =
            (doc.getStringUnitWidth(pb) * doc.internal.getFontSize()) /
            doc.internal.scaleFactor;

          // Calculate the X position to align to the right of the page

          doc.setLineWidth(0.2);
          if (data.pageNumber > 1) {
            doc.rect(3, 3, pageWidth - 6, pageHeight - 8);
          } else {
            doc.rect(3, 3, pageWidth - 6, pageHeight - 8);
          }
          // Adjust for a margin if needed
          doc.setFontSize(10);
          var xPosCN = (pageWidth - textWidthCN) / 2;
          var xPos = pageWidth - textWidth - 10;
          doc.text(compName, 10, pageHeight - 10);

          doc.setFontSize(10);
          doc.text(pb, xPos, pageHeight - 10);
          doc.text(str, xPosCN, pageHeight - 10);
        },
      });

      let fillingType = langugae[lang].fillingType
        ? langugae[lang].fillingType + " : "
        : "";

      let productHeadWithPack =
        dataToSet.packagingTypes.length != 0 ? [fillingType] : [];
      let productListWithPack = [];
      let productListalign = { 0: { cellWidth: 50 } };
      dataToSet.categories.map((categoryData, index) => {
        productHeadWithPack.push(categoryData.name);
        productListalign = {
          ...productListalign,
          [index + 1]: { halign: "right" },
        };
      });

      dataToSet.packagingTypes.map((packagingTypeData) => {
        let GruopInfo = [packagingTypeData.name];
        dataToSet.categories.map((categoryData) => {
          let flag = 0;
          dataToSet.dtoCategoryWithPackagingTypes.map((rowData) => {
            if (
              categoryData.id == rowData.category.id &&
              rowData.packagingType.id == packagingTypeData.id
            ) {
              flag = 1;
              GruopInfo.push(rowData.totalQty);
            }
          });
          if (flag == 0) {
            GruopInfo.push("");
          }
        });
        productListWithPack.push(GruopInfo);
      });
      var styles1 = {
        font: lang != 3 ? "TiroDevanagariHindi-Regular" : "Aakar",
      };
      console.log(productListWithPack);
      console.log(productHeadWithPack);
      doc.autoTable({
        head: [productHeadWithPack],
        body: productListWithPack,
        styles: styles1,
        startY: doc.autoTableEndPosY() + 5,
        columnStyles: productListalign,
        margin: { right: 6, left: 6, top: 20 },
        headStyles: {
          halign: "center",
          lineWidth: 0.2,
          lineColor: "#fff",
          fillColor: [3, 169, 244],
          textColor: [255, 255, 255],
        },
        theme: "grid",

        showHead: "everyPage",

        didDrawPage: function (data) { },
      });
      var line = doc.autoTableEndPosY() + 5; // Line height to start text at
      var lineHeight = 5;
      var leftMargin = 5;
      var leftMarginData = 152;
      var wrapWidth = 180;
      let note = langugae[lang].note ? langugae[lang].note + " : " : "";
      doc.text(note, leftMargin, line);
      var DelerText =
        dataToSet.orderRemarks != null ? dataToSet.orderRemarks : "";
      var splitText = doc.splitTextToSize(DelerText, wrapWidth + 10);
      for (var i = 0, length = splitText.length; i < length; i++) {
        // loop thru each line and increase
        doc.text(splitText[i], leftMargin + 10, line);
        line = lineHeight + line;
      }
      let orderBy = langugae[lang].orderBy
        ? langugae[lang].orderBy + " : "
        : "";
      doc.line(3, line, pageWidth - 3, line);
      let lineData = line;
      var line = line + 5;
      let salesTeamNames = dataToSet.salesTeamNames
        ? dataToSet.salesTeamNames
        : "";
      doc.text("1." + orderBy, leftMargin, line);
      let lineOrder = line;
      var wrapWidth = 50;
      var DelerText = dataToSet.fromBillFirmName
        ? dataToSet.fromBillFirmName
        : "-";
      var splitText = doc.splitTextToSize(DelerText, wrapWidth + 10);
      for (var i = 0, length = splitText.length; i < length; i++) {
        // loop thru each line and increase
        doc.text(splitText[i], leftMarginData, lineOrder);
        lineOrder = lineHeight + lineOrder;
      }
      var line = line + 2;
      doc.line(3, line, 90, line);
      var line = line + 5;
      let subjectToJurisdiction = langugae[lang].subjectToJurisdiction
        ? langugae[lang].subjectToJurisdiction + " : "
        : "";
      let distName = localStorage.getItem("districtName");
      let aurthorisedSign = langugae[lang].aurthorisedSign
        ? langugae[lang].aurthorisedSign + " : "
        : "";

      let goodsCheckBeforeReceived = langugae[lang].goodsCheckBeforeReceived
        ? langugae[lang].goodsCheckBeforeReceived + " : "
        : "";
      doc.text("2." + goodsCheckBeforeReceived, leftMargin, line);
      var line = line + 2;
      doc.line(3, line, 90, line);
      var line = line + 5;
      doc.text("3. " + subjectToJurisdiction + distName, leftMargin, line);

      doc.text(aurthorisedSign, leftMarginData, line);
      var line = line + 5;
      doc.line(90, lineData, 90, line);
      doc.line(150, lineData, 150, line);
      doc.line(3, line, pageWidth - 3, line);
      if (indexMain + 1 != rowList.length) {
        doc.addPage();
      }
    });

    let bill = langugae[lang].bill ? langugae[lang].bill + " : " : "";
    doc.save(bill + moment().format("DD-MM-YYYY") + ".pdf");
  };
  getSelectedList = (e) => {
    const { BillList } = this.props;
    this.setState({
      selection: e,
    });
    const rowList = this.props.BillList.billByDate.map((rowData) => {
      const filterData = e.filter((id) => rowData.id == id);
      return { ...rowData, checked: filterData.length != 0 ? true : false };
    });
  };
  onLoadSheet = () => {
    const { showNotification, BillList } = this.props;
    const { selection } = this.state;
    if (selection.length === 0) {
      showNotification({
        msg: checkedListEmptyMsg,
        severity: "error",
      });
    } else {
      const rowList = BillList.billByDate.map((rowData) => {
        const filterData = selection.filter((id) => rowData.id == id);
        return { ...rowData, checked: filterData.length != 0 ? true : false };
      });
      console.log(selection);
      this.rowPDF({
        id: selection.join(),
      });
      // this.props.setAllProductList({ row: rowList });
    }
  };

  rowView = (rowData) => {
    this.props.navigate(showMyBillListJson.formPathView + "/" + rowData.id);
  };

  onAutocompleteChange = (name, newValue) => {
    if (newValue != null) {
      this.setState({
        lang: newValue,
        langNew: newValue,
      });
    } else {
      this.setState({
        lang: "",
        langNew: "",
      });
    }
  };



  render() {
    const { BillList, multilingualList } = this.props;
    const { formData, dynamicMasterData, selection, lang, fieldDataForModule, searchBy } =
      this.state;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 0,
          }}
          elevation={0}
        >
          {localStorage.getItem("showCompanyFlag") !== null ? (
            <DynamicMainScreen
              formData={formData}
              deleteMsgAuto={true}
              showViewButtonInTable={true}
              showDeleteIcon={false}
              dynamicMasterData={dynamicMasterData}
              searchList={localStorage.getItem("showCompanyFlag") == 0 ? showMyBillListJson.searchListSimple :
                showMyBillListJson.searchList}
              onSearchData={localStorage.getItem("showCompanyFlag") == 0 ? this.onSearchListSimple : this.onSearchList}
              formPathView={showMyBillListJson.formPathView}
              formPath={showMyBillListJson.formPath}
              screenTitle={showMyBillListJson.screenTitle}
              showPdfDownload={false}
              showExcelDownload={false}
              isActiveURL={showMyBillListJson.isActiveURL}
              showAddButton={false}
              tableColumns={[]}
              tableData={BillList.billByDate}
              apiBaseURL={showMyBillListJson.apiBaseURL}
              callApi={false}
              getTableDataById={true}
              showSearchBox={false}
              showEditIcon={false}
              showPegination={false}
              getTableData={this.getData}
            />
          ) : (null)}


          <CheckBoxSelectionTable
            selection={selection}
            onSelectionChange={this.getSelectedList}
            columns={billListColumns}
            rows={(billListColumns, BillList?.billByDate)}
            isActionColActive={true}
            rowApply={this.onClickApply}
            showApply={true}
            showApplyTitle={"Receive Bill"}
            credentialConditionName="isRecieved"
            credentialConditionValue="0"
            rowPrint={this.rowPDF}
            SummarizeRoundedShow={true}
            showViewButtonInTable={true}
            rowView={this.rowView}
            showSearchBox={true}
          />
          <br />
          {fieldDataForModule.view == 1 ? (
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              container
              justifyContent="right"
            >
              <Grid container xs={12} sm={3} md={3} lg={3}>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label={"*"}
                  />
                  <LabelCompo
                    className="text-black"
                    style={{
                      fontSize: 16,
                      color: titleColor,
                    }}
                    label={"Language" + " :"}
                  />
                </Grid>
                <Grid item xs={12} sm={8} md={8} lg={8}>
                  <AutoComplete
                    name={"Language"}
                    label={"Language"}
                    placeHolder={"Select Language"}
                    keyColName={"id"}
                    value={lang}
                    options={multilingualList.language}
                    onChange={this.onAutocompleteChange}
                    errorText={""}
                  />
                </Grid>
              </Grid>
              &nbsp;&nbsp;
              <ButtonCompo
                size="medium"
                type="submit"
                variant="contained"
                name={"Bill PDF"}
                onClick={this.onLoadSheet}
              />
            </Grid>
          ) : (
            ""
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  BillList: state.billHeaderList,
  multilingualList: state.multilingual,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getBillByDateSearchList,
  getLatestBillDetails,
  getActiveLanguage,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ShowMyBillList);
