import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import { primary, titleColor } from "../../config/ColorObj";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { apiPost } from "../../utils/api_service";
import endpoint from "../../config/endpoints";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ClaimOfOutletOfferPopup = ({
  handleCloses,
  open,
  selection,
  showLoador,
  showNotification,
  getOutletOfferData,
}) => {
  const [searchValue, setSearchValue] = React.useState("");
  const [dataToShow, setDataToShow] = React.useState([]);
  const [dataToPass, setDataToPass] = React.useState([]);

  React.useEffect(() => {
    showLoador({ loador: true });

    console.log(selection);

    if (selection.length > 0) {
      showLoador({ loador: true });
      // selection
      let dataToPass = {
        creditNoteHeaderIds: selection,
      };

      apiPost({
        url: `${endpoint.grnGVNHeader}/generate-preview-by-credit-notes`,
        postBody: dataToPass,
      })
        .then(({ data, success }) => {
          console.log("data", data?.data);
          showLoador({ loador: false });
          // const dataList = data?.data?.map((itemData) => ({
          //   type:
          //     itemData?.outletCrnType === 4
          //       ? "Outlet Sampling Credit Note"
          //       : itemData?.outletCrnType === 2
          //       ? "Outlet Offer Credit Note"
          //       : itemData?.outletCrnType === 3
          //       ? "Outlet Expiry Product Credit Note"
          //       : "Unknown Type",
          //   grnGvnDetails: itemData?.grnGvnDetails.map(
          //     (item) => ({
          //       outletFirmName: item?.outletFirmName || "-",
          //       itemName: item?.product?.name || "-",
          //       qty: item?.secondaryQty || 0,
          //       taxableAmt: Number(item?.taxableAmt || 0).toFixed(2),
          //       taxAmt: Number(
          //         (item?.cgstAmt || 0) +
          //           (item?.sgstAmt || 0) +
          //           (item?.igstAmt || 0)
          //       ).toFixed(2),
          //       totalAmt: Number(item?.totalAmt || 0).toFixed(2),
          //       outletFirmName: item?.outletFirmName || "-",
          //       outletOwnerName: item?.outletOwnerName || "-",
                
          //     })
          //   ),
          // }));




          const dataList = data?.data?.map((itemData) => {
            // Calculate totals for taxableAmt, taxAmt, and totalAmt
            const totalTaxableAmt = itemData?.grnGvnDetails?.reduce(
              (sum, item) => sum + Number(item?.taxableAmt || 0),
              0
            );
          
            const totalTaxAmt = itemData?.grnGvnDetails?.reduce(
              (sum, item) =>
                sum +
                Number(item?.cgstAmt || 0) +
                Number(item?.sgstAmt || 0) +
                Number(item?.igstAmt || 0),
              0
            );
          
            const totalTotalAmt = itemData?.grnGvnDetails?.reduce(
              (sum, item) => sum + Number(item?.totalAmt || 0),
              0
            );
          
            return {
              type:
                itemData?.outletCrnType === 4
                  ? "Outlet Sampling Credit Note"
                  : itemData?.outletCrnType === 2
                  ? "Outlet Offer Credit Note"
                  : itemData?.outletCrnType === 3
                  ? "Outlet Expiry Product Credit Note"
                  : "Unknown Type",
          
              totalTaxableAmt: totalTaxableAmt.toFixed(2), // Total of taxableAmt
              totalTaxAmt: totalTaxAmt.toFixed(2), // Total of taxAmt
              totalAmt: totalTotalAmt.toFixed(2), // Total of totalAmt
          
              grnGvnDetails: itemData?.grnGvnDetails.map((item) => ({
                outletFirmName: item?.outletFirmName || "-",
                itemName: item?.product?.name || "-",
                qty: item?.secondaryQty || 0,
                taxableAmt: Number(item?.taxableAmt || 0).toFixed(2),
                taxAmt: Number(
                  (item?.cgstAmt || 0) + (item?.sgstAmt || 0) + (item?.igstAmt || 0)
                ).toFixed(2),
                totalAmt: Number(item?.totalAmt || 0).toFixed(2),
                outletFirmName: item?.outletFirmName || "-",
                outletOwnerName: item?.outletOwnerName || "-",
              })),
            };
          });
          

          console.log("dataList", dataList);

          setDataToShow(dataList);

          // setDataToShow([
          //   {
          //     grnGvnDetails: [
          //       {
          //         product: { name: "BHAVNAGRI GATHIYA 5RS" },
          //         taxableAmt: 500.00,
          //         cgstAmt: 30.00,
          //         sgstAmt: 30.00,
          //         igstAmt: 0.00,
          //         totalAmt: 560.00,
          //         outletFirmName: "Gokul Snacks",
          //         outletOwnerName: "Jignesh Gond"
          //       },
          //       {
          //         product: { name: "MASALA WAFERS 5RS" },
          //         taxableAmt: 400.00,
          //         cgstAmt: 24.00,
          //         sgstAmt: 24.00,
          //         igstAmt: 0.00,
          //         totalAmt: 448.00,
          //        outletFirmName: "Gokul Snacks",
          //         outletOwnerName: "Jignesh Gond"
          //       }
          //     ]
          //   },
          //   {
          //     grnGvnDetails: [
          //       {
          //         product: { name: "SEV 5RS" },
          //         taxableAmt: 300.00,
          //         cgstAmt: 18.00,
          //         sgstAmt: 18.00,
          //         igstAmt: 0.00,
          //         totalAmt: 336.00,
          //         outletFirmName: "Rajkot Snacks",
          //         outletOwnerName: "Dilip Patel"
          //       },
          //       {
          //         product: { name: "PAPDI GATHIYA 5RS" },
          //         taxableAmt: 600.00,
          //         cgstAmt: 36.00,
          //         sgstAmt: 36.00,
          //         igstAmt: 0.00,
          //         totalAmt: 672.00,
          //  outletFirmName: "Rajkot Snacks",
          //         outletOwnerName: "Dilip Patel"
          //       }
          //     ]
          //   },
          //   {
          //     grnGvnDetails: [
          //       {
          //         product: { name: "GARLIC MASALA MURMURA 5RS" },
          //         taxableAmt: 450.00,
          //         cgstAmt: 27.00,
          //         sgstAmt: 27.00,
          //         igstAmt: 0.00,
          //         totalAmt: 504.00,
          //         outletFirmName: "Kashyap Traders",
          //         outletOwnerName: "Manish Kashyap"
          //       },
          //       {
          //         product: { name: "SALTY WAFERS 5RS" },
          //         taxableAmt: 550.00,
          //         cgstAmt: 33.00,
          //         sgstAmt: 33.00,
          //         igstAmt: 0.00,
          //         totalAmt: 616.00,
          //        outletFirmName: "Kashyap Traders",
          //         outletOwnerName: "Manish Kashyap"
          //       }
          //     ]
          //   }
          // ]);

          setDataToPass(data?.data);

          if (success) {
          } else {
            showNotification({
              msg: "Something went wrong",
              severity: "error",
            });
          }
        })
        .catch((error) => {
          showLoador({ loador: false });
          showNotification({
            msg: "API request failed",
            severity: "error",
          });
          console.error("API Error:", error);
        });
    }
  }, []); // Dependency array added

  const handleClose = () => {
    handleCloses();
  };

  const onSubmitData = () => {
    console.log("dataToPass", dataToPass);

    let dataToSave = {
      flag: 0,
      ids: selection,
      grnGvnHeaders: [...dataToPass],
    };

    console.log("dataToSave", dataToSave);

    swal({
      title: "Are you sure?",
      text: "Do you want to submit this data?",
      // icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((isSubmit) => {
      if (isSubmit) {
        // if (selection.length > 0) {
        showLoador({ loador: true });
        apiPost({
          url: `${endpoint.grnGVNHeader}/save`,
          postBody: dataToSave,
        })
          .then(({ data, success }) => {
            showLoador({ loador: false });
            if (success) {
              handleCloses();
              getOutletOfferData();
              showNotification({
                msg: "Submitted Successfully",
                severity: "success",
              });
            } else {
              showNotification({
                msg: "Something went wrong",
                severity: "error",
              });

              // handleCloses();
              // getOutletOfferData();
            }
          })
          .catch((error) => {
            showLoador({ loador: false });
            showNotification({
              msg: "API request failed",
              severity: "error",
            });
            console.error("API Error:", error);
          });
        // }
      }
    });
  };

  const getFilteredTableData = (tableData) => {
    if (!searchValue) return tableData;
    return tableData.filter((currentRow) => {
      const lowerSearchValue = searchValue.toLowerCase();

      const searchFields = [currentRow.outletName];

      return searchFields.some(
        (field) => field && field.toLowerCase().includes(lowerSearchValue)
      );
    });
  };

  const tabColumns = [
    {
      title: "Outlet Name",
      name: "outletName",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,
    },
    {
      title: "Item Name",
      name: "itemName",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,
    },

    {
      title: "Quantity",
      name: "qty",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
      canSearch: true,
    },
    {
      title: "Taxable Amount",
      name: "taxableAmt",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
      canSearch: true,
    },
    {
      title: "Tax Amount",
      name: "taxAmt",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
      canSearch: true,
    },

    {
      title: "Total Amount",
      name: "totalAmt",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
      canSearch: true,
    },
  ];

  return (
    <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="lg"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Details of Outlet CRN
        </BootstrapDialogTitle>
        {/* <DialogContent dividers>
          {dataToShow?.map((item, index) => (
            <div
              key={index}
              style={{
                marginBottom: "20px",
                borderBottom: "1px solid #000",
                paddingBottom: "20px",
              }}
            >
              {item?.grnGvnDetails?.length > 0 && (
                <Grid
                  container
                  spacing={2}
                  sx={{ marginLeft: 1, marginBottom: 1 }}
                  justifyContent="flex-start"
                >
                  <Grid item xs={12} md={4} lg={4}>
                    <Typography variant="h6" sx={{ color: primary }}>
                      Type :{" "}
                      {item?.type}
                    </Typography>
                  </Grid>
                 
                </Grid>
              )}


              <Divider />

                <Grid
                  container
                  spacing={2}
                  sx={{ marginTop: 1 }}
                >
                  <Grid container item xs={12} md={12} sm={12}>
                    <DynamicTable
                      data={
                        Array.isArray(item?.grnGvnDetails)
                          ? getFilteredTableData(item?.grnGvnDetails)
                          : []
                      }
                      tableHead={tabColumns}
                      showPegination={false}
                      isActionColActive={false}
                    />
                  </Grid>
                </Grid>
         
            </div>
          ))}

          {dataToShow?.length > 0 && (
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              container
              justifyContent="right"
              sx={{ marginTop: 2, padding: 2 }}
            >
              <ButtonCompo
                size="medium"
                type="Submit"
                variant="contained"
                name="Submit"
                onClick={onSubmitData}
              />
            </Grid>
          )}
        </DialogContent> */}

        <DialogContent dividers>
          {dataToShow?.map((item, index) => (
            <div
              key={index}
              style={{
                marginBottom: "20px",
                borderBottom: "1px solid #000",
                paddingBottom: "20px",
              }}
            >
              {item?.grnGvnDetails?.length > 0 && (
                <>
                  <Grid
                    container
                    spacing={2}
                    justifyContent="flex-start"
                  >
                    <Grid item xs={12} md={4} lg={4}>
                      <Typography variant="h6" sx={{ color: primary }}>
                        {item?.type}
                      </Typography>
                    </Grid>
                  </Grid>


                  {/* Only render the table if grnGvnDetails has data */}
                  {Array.isArray(item?.grnGvnDetails) &&
                    item?.grnGvnDetails.length > 0 && (
                      <Grid container spacing={2} sx={{ marginTop: 1 }}>
                        <Grid container item xs={12} md={12} sm={12}>
                          <DynamicTable
                            data={getFilteredTableData(
                              item?.grnGvnDetails
                            )}
                            // tableHead={tabColumns}
                            tableHead={
                              item.type === "From offer" // Check if type is "From offer"
                                ? [
                                  {
                                    title: "Outlet Name",
                                    name: "outletFirmName",
                                    positionCenter: false,
                                    showInscreen: true,
                                    alignCenter: "left",
                                    canSearch: true,
                                  },
                                    {
                                      title: "Item Name",
                                      name: "itemName",
                                      positionCenter: false,
                                      showInscreen: true,
                                      alignCenter: "left",
                                      canSearch: true,
                                    },
                                    {
                                      title: "Taxable Amount",
                                      name: "taxableAmt",
                                      positionCenter: false,
                                      showInscreen: true,
                                      alignCenter: "right",
                                      canSearch: true,
                                    },
                                    {
                                      title: "Tax Amount",
                                      name: "taxAmt",
                                      positionCenter: false,
                                      showInscreen: true,
                                      alignCenter: "right",
                                      canSearch: true,
                                    },
                                    {
                                      title: "Total Amount",
                                      name: "totalAmt",
                                      positionCenter: false,
                                      showInscreen: true,
                                      alignCenter: "right",
                                      canSearch: true,
                                    },
                                  ]
                                : [
                                    {
                                      title: "Outlet Name",
                                      name: "outletFirmName",
                                      positionCenter: false,
                                      showInscreen: true,
                                      alignCenter: "left",
                                      canSearch: true,
                                    },
                                    {
                                      title: "Item Name",
                                      name: "itemName",
                                      positionCenter: false,
                                      showInscreen: true,
                                      alignCenter: "left",
                                      canSearch: true,
                                    },
                                    {
                                      title: "Quantity",
                                      name: "qty",
                                      positionCenter: false,
                                      showInscreen: true,
                                      alignCenter: "right",
                                      canSearch: true,
                                    },
                                    {
                                      title: "Taxable Amount",
                                      name: "taxableAmt",
                                      positionCenter: false,
                                      showInscreen: true,
                                      alignCenter: "right",
                                      canSearch: true,
                                    },
                                    {
                                      title: "Tax Amount",
                                      name: "taxAmt",
                                      positionCenter: false,
                                      showInscreen: true,
                                      alignCenter: "right",
                                      canSearch: true,
                                    },
                                    {
                                      title: "Total Amount",
                                      name: "totalAmt",
                                      positionCenter: false,
                                      showInscreen: true,
                                      alignCenter: "right",
                                      canSearch: true,
                                    },
                                  ]
                            }
                            showPegination={false}
                            isActionColActive={false}
                          />
                        </Grid>


                        <Grid
                                    container
                                    spacing={2}
                                    sx={{ marginLeft: 1, marginTop: 1 }}
                                    justifyContent="space-between"
                                  >
                                    <Grid item xs={12} md={4} lg={4}>
                                      <Typography
                                        variant="h6"
                                        sx={{ color: primary }}
                                      >
                                        ₹ Total Taxable Amount:{" "}
                                        {item?.totalTaxableAmt}
                                          
          
                                      </Typography>
                                    </Grid>

                                    <Grid item xs={12} md={4} lg={4}>
                                      <Typography
                                        variant="h6"
                                        sx={{ color: primary }}
                                      >
                                        ₹ Total Tax Amount:{" "}
                                        {item?.totalTaxAmt}
                                      </Typography>
                                    </Grid>

                                    <Grid item xs={12} md={4} lg={4}>
                                      <Typography
                                        variant="h6"
                                        sx={{ color: primary }}
                                      >
                                        ₹ Total Amount:{" "}
                                        {item?.totalAmt}
                                      </Typography>
                                    </Grid>
                                  </Grid>

                      </Grid>
                    )}
                </>
              )}
            </div>
          ))}

          {dataToShow?.length > 0 && (
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              container
              justifyContent="right"
              sx={{ marginTop: 2, padding: 2 }}
            >
              <ButtonCompo
                size="medium"
                type="Submit"
                variant="contained"
                name="Submit"
                onClick={onSubmitData}
              />
            </Grid>
          )}
        </DialogContent>
      </BootstrapDialog>
    </Paper>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = { showLoador, showNotification };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClaimOfOutletOfferPopup);
