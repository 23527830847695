import { Autocomplete, Chip, TextField } from "@mui/material";
import * as React from "react";

export default function MultipleSelect({
  er = "",
  disabled = false,
  label,
  name1,
  value1,
  names,
  onChange,
}) {
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const [selectedId, setSelectedId] = React.useState([]);

  const handleChange = (event, newValue) => {
    const {
      target: { value },
    } = event;
    const selectedIds = newValue.map((option) => option.id);
    onChange(selectedIds, name1);
  };
  React.useEffect(() => {
    let flag=value1 != selectedId && names.length != 0
   
    if(names.length == 0&&value1.length==0&&selectedId.length!=0){
      setSelectedOptions([]);
      setSelectedId([]);
      return
    }
    if (flag) {
      let filterData = value1.map((id) => {
        let valueData = names.find((rowInfo) => rowInfo.id == id);
        return valueData ? valueData : "";
      });
      setSelectedOptions(filterData.filter((row) => row != ""));
      setSelectedId(value1);
    }
  }, [value1, names]);
  return (
    <>
      
      <Autocomplete
        multiple
        options={names}
        size="small"
        getOptionLabel={(option) => option.name}
        variant="standard"
        value={selectedOptions}
        disableCloseOnSelect
        onChange={(event, newValue) => handleChange(event, newValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            placeholder="Search..."
            error={er !== "" ? true : false}
            margin="0px"
            helperText={er !== "" ? er : " "}
          />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              label={option.name}
              size="small"
              {...getTagProps({ index })}
            />
          ))
        }
      />
    </>
  );
}
