import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
const initialState = {
  salesExecutive: [],
  saleExcutiveObject: {},
  area: [],
  salesExecutiveObject: {},
  salesExecutiveWithCheckInOut: [],
  salesPersonByArea: [],
  salesPersonByAreaAndStockHolderType: [],
  assignedSalesTeamByBeatId: [],
  salesExecutiveByAreaAndStockHolderType: [],
  salesTeamByUserTypeAndTaluka: [],
  salesPersonByAreaAndStockHolderTypeForClaimStructure: [],
  salesTeamWithoutConfigurationByArea: [],
};
let URL = endpoints.salesExecutive;
const salesExecutiveSlice = createSlice({
  name: "salesExecutive",
  initialState,
  reducers: {
    salesExecutiveSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        salesExecutive: row,
      };
    },

    salesPersonByAreaAndStockHolderTypeSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        salesPersonByAreaAndStockHolderType: row,
      };
    },

    salesExecutiveObjectSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        saleExcutiveObject: row,
      };
    },
    salesExecutiveWithCheckInOutSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        salesExecutiveWithCheckInOut: row,
      };
    },

    salesExecutiveObjectSuccess: (state = cloneDeep(initialState), action) => {
      const { salesExecutiveData } = action.payload;
      return {
        ...state,
        salesExecutiveObject: salesExecutiveData,
      };
    },

    areaSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        area: row,
      };
    },

    salesPersonSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        salesPersonByArea: row,
      };
    },

    assignedSalesExecutieByBeatIdSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        assignedSalesTeamByBeatId: row,
      };
    },

    salesExecutiveByAreaAndStockHolderTypeIdSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        salesExecutiveByAreaAndStockHolderType: row,
      };
    },

    salesTeamByUserTypeAndTalukaSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        salesTeamByUserTypeAndTaluka: row,
      };
    },

    salesPersonByAreaAndStockHolderTypeForClaimStructureSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        salesPersonByAreaAndStockHolderTypeForClaimStructure: row,
      };
    },

    salesTeamWithoutConfigurationByAreaSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        salesTeamWithoutConfigurationByArea: row,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  salesExecutiveSuccess,
  salesExecutiveObjectSuccess,
  salesExecutiveWithCheckInOutSuccess,
  areaSuccess,
  salesPersonByAreaAndStockHolderTypeSuccess,
  salesPersonSuccess,
  assignedSalesExecutieByBeatIdSuccess,
  salesExecutiveByAreaAndStockHolderTypeIdSuccess,
  salesTeamByUserTypeAndTalukaSuccess,
  salesPersonByAreaAndStockHolderTypeForClaimStructureSuccess,
  salesTeamWithoutConfigurationByAreaSuccess,
  resetState,
} = salesExecutiveSlice.actions;

export default salesExecutiveSlice.reducer;

export const getSalesExecutive =
  ({ stockStakeHolderId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL +
            "/by-stock-stake-holder-type-id?stockStakeHolderTypeIds=" +
            stockStakeHolderId,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((salesExecutiveObject, index) => {
              let salesExecutiveData = {
                index: index + 1,
                id:
                  salesExecutiveObject.id === null ? "" : salesExecutiveObject.id,
                name:
                  salesExecutiveObject.name === null
                    ? ""
                    : salesExecutiveObject.name,
                userName:
                  salesExecutiveObject.userName === null
                    ? ""
                    : salesExecutiveObject.userName,
                address:
                  salesExecutiveObject.address === null
                    ? ""
                    : salesExecutiveObject.address,
                regionName:
                  salesExecutiveObject.region !== null &&
                    salesExecutiveObject.region.name
                    ? salesExecutiveObject.region.name
                    : "",
                region:
                  salesExecutiveObject.region !== null &&
                    salesExecutiveObject.region.id
                    ? salesExecutiveObject.region.id
                    : "",
                zoneNames:
                  salesExecutiveObject.zoneNames !== null
                    ? salesExecutiveObject.zoneNames
                    : "",
                areaNames:
                  salesExecutiveObject.areaNames !== null
                    ? salesExecutiveObject.areaNames
                    : "",

                emailId:
                  salesExecutiveObject.emailId === null
                    ? ""
                    : salesExecutiveObject.emailId,
                contactNo:
                  salesExecutiveObject.contactNo === null
                    ? ""
                    : salesExecutiveObject.contactNo,
                whatsAppNo:
                  salesExecutiveObject.whatsAppNo === null
                    ? ""
                    : salesExecutiveObject.whatsAppNo,

                isActive:
                  salesExecutiveObject.isActive === null
                    ? ""
                    : salesExecutiveObject.isActive,
              };
              return salesExecutiveData;
            });
            dispatch(salesExecutiveSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getSalesExecutiveWithStockHolderIdWithoutSelf =
  ({ stockStakeHolderTypeIds }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL +
            "/by-stock-stake-holder-type-id-with-out-self?stockStakeHolderTypeIds=" +
            stockStakeHolderTypeIds,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((salesExecutiveObject, index) => {
              let salesExecutiveData = {
                index: index + 1,
                id:
                  salesExecutiveObject.id === null ? "" : salesExecutiveObject.id,
                name:
                  salesExecutiveObject.name === null
                    ? ""
                    : salesExecutiveObject.name,
                userName:
                  salesExecutiveObject.userName === null
                    ? ""
                    : salesExecutiveObject.userName,
                address:
                  salesExecutiveObject.address === null
                    ? ""
                    : salesExecutiveObject.address,
                regionName:
                  salesExecutiveObject.region !== null &&
                    salesExecutiveObject.region.name
                    ? salesExecutiveObject.region.name
                    : "",
                region:
                  salesExecutiveObject.region !== null &&
                    salesExecutiveObject.region.id
                    ? salesExecutiveObject.region.id
                    : "",
                zoneNames:
                  salesExecutiveObject.zoneNames !== null
                    ? salesExecutiveObject.zoneNames
                    : "",
                areaNames:
                  salesExecutiveObject.areaNames !== null
                    ? salesExecutiveObject.areaNames
                    : "",

                emailId:
                  salesExecutiveObject.emailId === null
                    ? ""
                    : salesExecutiveObject.emailId,
                contactNo:
                  salesExecutiveObject.contactNo === null
                    ? ""
                    : salesExecutiveObject.contactNo,
                whatsAppNo:
                  salesExecutiveObject.whatsAppNo === null
                    ? ""
                    : salesExecutiveObject.whatsAppNo,

                isActive:
                  salesExecutiveObject.isActive === null
                    ? ""
                    : salesExecutiveObject.isActive,
              };
              return salesExecutiveData;
            });
            dispatch(salesExecutiveSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getSalesExecutiveWithStockHolderWithLoginUserAndType =
  ({ stockStakeHolderTypeIds }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL +
            "/by-login-user-and-type?stockStakeHolderTypeIds=" +
            stockStakeHolderTypeIds,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((salesExecutiveObject, index) => {
              let salesExecutiveData = {
                index: index + 1,
                id:
                  salesExecutiveObject.id === null ? "" : salesExecutiveObject.id,
                name:
                  salesExecutiveObject.name === null
                    ? ""
                    : salesExecutiveObject.name,
                userName:
                  salesExecutiveObject.userName === null
                    ? ""
                    : salesExecutiveObject.userName,

                stockStakeHolderType:
                  salesExecutiveObject.stockStakeHolderType !== null
                    ? ""
                    : salesExecutiveObject.stockStakeHolderType,

                stockStakeHolderTypeId:
                  salesExecutiveObject.stockStakeHolderType !== null &&
                    salesExecutiveObject.stockStakeHolderType.id !== null
                    ? ""
                    : salesExecutiveObject.stockStakeHolderType.id,

                stockStakeHolderTypeName:
                  salesExecutiveObject.stockStakeHolderType !== null &&
                    salesExecutiveObject.stockStakeHolderType.name !== null
                    ? ""
                    : salesExecutiveObject.stockStakeHolderType.name,
              };
              return salesExecutiveData;
            });
            dispatch(salesExecutiveSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const setSalesExecutiveWithStockHolderWithLoginUserAndType =
  ({ row }) =>
    async (dispatch) => {
      dispatch(salesExecutiveSuccess({ row }));
    };

export const getSalesExecutiveByAreaAndStockHolderType =
  ({ dataJson }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          url: URL + "/with-admin-by-areas-and-stock-stake_holder-type",
          postBody: dataJson,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((salesExecutiveObject, index) => {
              let salesExecutiveData = {
                index: index + 1,
                id:
                  salesExecutiveObject.id === null ? "" : salesExecutiveObject.id,
                name:
                  salesExecutiveObject.name === null
                    ? ""
                    : salesExecutiveObject.name,
                userName:
                  salesExecutiveObject.userName === null
                    ? ""
                    : salesExecutiveObject.userName,
                address:
                  salesExecutiveObject.address === null
                    ? ""
                    : salesExecutiveObject.address,
                regionName:
                  salesExecutiveObject.regionName !== null
                    ? salesExecutiveObject.regionName
                    : "",
                region:
                  salesExecutiveObject.regionId !== null
                    ? salesExecutiveObject.regionId
                    : "",
                zoneNames:
                  salesExecutiveObject.zoneNames !== null
                    ? salesExecutiveObject.zoneNames
                    : "",
                areaNames:
                  salesExecutiveObject.areaNames !== null
                    ? salesExecutiveObject.areaNames
                    : "",

                emailId:
                  salesExecutiveObject.emailId === null
                    ? ""
                    : salesExecutiveObject.emailId,
                contactNo:
                  salesExecutiveObject.contactNo === null
                    ? ""
                    : salesExecutiveObject.contactNo,
                whatsAppNo:
                  salesExecutiveObject.whatsAppNo === null
                    ? ""
                    : salesExecutiveObject.whatsAppNo,

                isActive:
                  salesExecutiveObject.isActive === null
                    ? ""
                    : salesExecutiveObject.isActive,
              };
              return salesExecutiveData;
            });
            dispatch(salesExecutiveByAreaAndStockHolderTypeIdSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getSalesExecutiveByUserType =
  ({ userTypeId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/by-user-type-id?userTypeIds=" + userTypeId,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((salesExecutiveObject, index) => {
              let salesExecutiveData = {
                index: index + 1,
                id:
                  salesExecutiveObject.id === null ? "" : salesExecutiveObject.id,
                userName:
                  salesExecutiveObject.userName === null
                    ? ""
                    : salesExecutiveObject.userName,
                name:
                  salesExecutiveObject.name === null
                    ? ""
                    : salesExecutiveObject.name,
                address:
                  salesExecutiveObject.address === null
                    ? ""
                    : salesExecutiveObject.address,
                regionName:
                  salesExecutiveObject.region !== null &&
                    salesExecutiveObject.region.name
                    ? salesExecutiveObject.region.name
                    : "",
                region:
                  salesExecutiveObject.region !== null &&
                    salesExecutiveObject.region.id
                    ? salesExecutiveObject.region.id
                    : "",
                zoneNames:
                  salesExecutiveObject.zoneNames !== null
                    ? salesExecutiveObject.zoneNames
                    : "",
                areaNames:
                  salesExecutiveObject.areaNames !== null
                    ? salesExecutiveObject.areaNames
                    : "",

                emailId:
                  salesExecutiveObject.emailId === null
                    ? ""
                    : salesExecutiveObject.emailId,
                contactNo:
                  salesExecutiveObject.contactNo === null
                    ? ""
                    : salesExecutiveObject.contactNo,
                whatsAppNo:
                  salesExecutiveObject.whatsAppNo === null
                    ? ""
                    : salesExecutiveObject.whatsAppNo,

                isActive:
                  salesExecutiveObject.isActive === null
                    ? ""
                    : salesExecutiveObject.isActive,
              };
              return salesExecutiveData;
            });
            dispatch(salesExecutiveSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getSalesExecutiveByArea = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/get-sales-person-by-areas",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((salesExecutiveObject, index) => {
          let salesExecutiveData = {
            index: index + 1,
            id: salesExecutiveObject.id === null ? "" : salesExecutiveObject.id,
            userName:
              salesExecutiveObject.userName === null
                ? ""
                : salesExecutiveObject.userName,
            name:
              salesExecutiveObject.name === null
                ? ""
                : salesExecutiveObject.name,
            address:
              salesExecutiveObject.address === null
                ? ""
                : salesExecutiveObject.address,
            regionName:
              salesExecutiveObject.region !== null &&
                salesExecutiveObject.region.name
                ? salesExecutiveObject.region.name
                : "",
            region:
              salesExecutiveObject.region !== null &&
                salesExecutiveObject.region.id
                ? salesExecutiveObject.region.id
                : "",
            stateNames:
              salesExecutiveObject.stateNames !== null
                ? salesExecutiveObject.stateNames
                : "-",
            zoneNames:
              salesExecutiveObject.zoneNames !== null
                ? salesExecutiveObject.zoneNames
                : "-",
            areaNames:
              salesExecutiveObject.areaNames !== null
                ? salesExecutiveObject.areaNames
                : "",
            emailId:
              salesExecutiveObject.emailId === null
                ? ""
                : salesExecutiveObject.emailId,
            isActive:
              salesExecutiveObject.isActive === null
                ? ""
                : salesExecutiveObject.isActive,
            contactNo:
              salesExecutiveObject.contactNo === null
                ? ""
                : salesExecutiveObject.contactNo,
            whatsAppNo:
              salesExecutiveObject.whatsAppNo === null
                ? ""
                : salesExecutiveObject.whatsAppNo,
          };
          return salesExecutiveData;
        });
        dispatch(salesExecutiveSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getSalesExecutiveByAreaId =
  ({ areaId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL + "/not-in-beat-assign-daywise-header-by-area?areaId=" + areaId,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((salesExecutiveObject, index) => {
              let salesExecutiveData = {
                index: index + 1,
                id:
                  salesExecutiveObject.id === null ? "" : salesExecutiveObject.id,
                name:
                  salesExecutiveObject.name !== null &&
                    salesExecutiveObject.stockStakeHolderType !== null &&
                    salesExecutiveObject.stockStakeHolderType.prefix !== null
                    ? salesExecutiveObject.name +
                    " (" +
                    salesExecutiveObject.stockStakeHolderType.prefix +
                    ")"
                    : "",
                salesTeamName:
                  salesExecutiveObject.name === null
                    ? ""
                    : salesExecutiveObject.name,
                address:
                  salesExecutiveObject.address === null
                    ? ""
                    : salesExecutiveObject.address,
                regionName:
                  salesExecutiveObject.region !== null &&
                    salesExecutiveObject.region.name
                    ? salesExecutiveObject.region.name
                    : "",
                region:
                  salesExecutiveObject.region !== null &&
                    salesExecutiveObject.region.id
                    ? salesExecutiveObject.region.id
                    : "",
                zoneNames:
                  salesExecutiveObject.zoneNames !== null
                    ? salesExecutiveObject.zoneNames
                    : "",
                areaNames:
                  salesExecutiveObject.areaNames !== null
                    ? salesExecutiveObject.areaNames
                    : "",

                emailId:
                  salesExecutiveObject.emailId === null
                    ? ""
                    : salesExecutiveObject.emailId,
                contactNo:
                  salesExecutiveObject.contactNo === null
                    ? ""
                    : salesExecutiveObject.contactNo,
                whatsAppNo:
                  salesExecutiveObject.whatsAppNo === null
                    ? ""
                    : salesExecutiveObject.whatsAppNo,

                isActive:
                  salesExecutiveObject.isActive === null
                    ? ""
                    : salesExecutiveObject.isActive,
              };
              return salesExecutiveData;
            });
            dispatch(salesExecutiveSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getSalesExecutiveTypeByAreaId =
  ({ areaId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/by-area?areaId=" + areaId,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((salesExecutiveObject, index) => {
              let salesExecutiveData = {
                index: index + 1,
                id:
                  salesExecutiveObject.id === null ? "" : salesExecutiveObject.id,
                name:
                  salesExecutiveObject.name !== null &&
                    salesExecutiveObject.stockStakeHolderType !== null &&
                    salesExecutiveObject.stockStakeHolderType.prefix !== null
                    ? salesExecutiveObject.name +
                    " (" +
                    salesExecutiveObject.stockStakeHolderType.prefix +
                    ")"
                    : "",
                salesTeamName:
                  salesExecutiveObject.name === null
                    ? ""
                    : salesExecutiveObject.name,

                address:
                  salesExecutiveObject.address === null
                    ? ""
                    : salesExecutiveObject.address,
                regionName:
                  salesExecutiveObject.region !== null &&
                    salesExecutiveObject.region.name
                    ? salesExecutiveObject.region.name
                    : "",
                region:
                  salesExecutiveObject.region !== null &&
                    salesExecutiveObject.region.id
                    ? salesExecutiveObject.region.id
                    : "",
                zoneIds:
                  salesExecutiveObject.zoneIds !== null
                    ? salesExecutiveObject.zoneIds
                    : "",
                areaIds:
                  salesExecutiveObject.areaIds !== null
                    ? salesExecutiveObject.areaIds
                    : "",

                emailId:
                  salesExecutiveObject.emailId === null
                    ? ""
                    : salesExecutiveObject.emailId,
                contactNo:
                  salesExecutiveObject.contactNo === null
                    ? ""
                    : salesExecutiveObject.contactNo,
                whatsAppNo:
                  salesExecutiveObject.whatsAppNo === null
                    ? ""
                    : salesExecutiveObject.whatsAppNo,

                isActive:
                  salesExecutiveObject.isActive === null
                    ? ""
                    : salesExecutiveObject.isActive,
              };
              return salesExecutiveData;
            });
            dispatch(salesExecutiveSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getAreaByStockStakeHolderTypeIdAndZoneIds =
  ({ StockStakeHolderTypeId, zoneIds }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL +
            "/get-areas?StockStakeHolderTypeId=" +
            StockStakeHolderTypeId +
            "&zoneIds=" +
            zoneIds,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((areaObject, index) => {
              let areaData = {
                index: index + 1,
                id: areaObject.id === null ? "" : areaObject.id,
                name:
                  areaObject.name === null
                    ? ""
                    : areaObject.name + " (" + areaObject.shortName + ")",
                zoneName:
                  areaObject.taluka !== null &&
                    areaObject.taluka.district.zone.name
                    ? areaObject.taluka.district.zone.name
                    : "",
                zone:
                  areaObject.zone !== null && areaObject.taluka.district.zone.id
                    ? areaObject.taluka.district.zone.id
                    : "",
                shortName:
                  areaObject.shortName === null ? "" : areaObject.shortName,
                sortOrder:
                  areaObject.sortOrder === null ? "" : areaObject.sortOrder,
              };
              return areaData;
            });
            dispatch(areaSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getAreaByStockStakeHolderTypeIdAndZoneIdsEdit =
  ({ saleExcId, StockStakeHolderTypeId, zoneIds }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL +
            "/" +
            saleExcId +
            "/get-areas-on-edit?StockStakeHolderTypeId=" +
            StockStakeHolderTypeId +
            "&zoneIds=" +
            zoneIds,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((areaObject, index) => {
              let areaData = {
                index: index + 1,
                id: areaObject.id === null ? "" : areaObject.id,
                name:
                  areaObject.name === null
                    ? ""
                    : areaObject.name + " (" + areaObject.shortName + ")",
                zoneName:
                  areaObject.taluka !== null &&
                    areaObject.taluka.district.zone.name
                    ? areaObject.taluka.district.zone.name
                    : "",
                zone:
                  areaObject.taluka !== null && areaObject.taluka.district.zone.id
                    ? areaObject.taluka.district.zone.id
                    : "",
                shortName:
                  areaObject.shortName === null ? "" : areaObject.shortName,
                sortOrder:
                  areaObject.sortOrder === null ? "" : areaObject.sortOrder,
              };
              return areaData;
            });
            dispatch(areaSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };
export const getSalesExecutiveByBeatId =
  ({ beatIds }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/by-beat?beatId=" + beatIds,
        }).then(({ data, success }) => {
          if (success) {
            dispatch(salesExecutiveObjectSuccess({ row: data.data }));
            return { response: data.data, success };
          }
          return { response: {}, success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getSalesExecutiveByBeatIds =
  ({ beatIds }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/by-beats?beatIds=" + beatIds,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((salesExecutiveObject, index) => {
              let salesExecutiveData = {
                ...salesExecutiveObject,
                name:
                  salesExecutiveObject.name !== null &&
                    salesExecutiveObject.stockStakeHolderType !== null &&
                    salesExecutiveObject.stockStakeHolderType.prefix !== null
                    ? salesExecutiveObject.name + " (" + salesExecutiveObject.stockStakeHolderType.prefix + ")"
                    : "",
              };
              return salesExecutiveData;
            });
            dispatch(salesExecutiveSuccess({ row: row }));
            return { response: row, success };
          }
          return { response: {}, success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getSalesExecutiveWithCheckInOut = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/sales-person-with-check-in-out",
    }).then(({ data, success }) => {
      if (success) {
        if (success) {
          const row = data.data.map((salesExecutiveObject, index) => {
            let salesExecutiveData = {
              index: index + 1,
              id:
                salesExecutiveObject.id === null ? "" : salesExecutiveObject.id,
              name:
                salesExecutiveObject.name === null
                  ? ""
                  : salesExecutiveObject.name,
              address:
                salesExecutiveObject.address === null
                  ? ""
                  : salesExecutiveObject.address,
              regionName:
                salesExecutiveObject.region !== null &&
                  salesExecutiveObject.region.name
                  ? salesExecutiveObject.region.name
                  : "",
              region:
                salesExecutiveObject.region !== null &&
                  salesExecutiveObject.region.id
                  ? salesExecutiveObject.region.id
                  : "",
              zoneIds:
                salesExecutiveObject.zoneIds !== null
                  ? salesExecutiveObject.zoneIds
                  : "",
              areaIds:
                salesExecutiveObject.areaIds !== null
                  ? salesExecutiveObject.areaIds
                  : "",

              emailId:
                salesExecutiveObject.emailId === null
                  ? ""
                  : salesExecutiveObject.emailId,
              contactNo:
                salesExecutiveObject.contactNo === null
                  ? ""
                  : salesExecutiveObject.contactNo,
              whatsAppNo:
                salesExecutiveObject.whatsAppNo === null
                  ? ""
                  : salesExecutiveObject.whatsAppNo,

              isActive:
                salesExecutiveObject.isActive === null
                  ? ""
                  : salesExecutiveObject.isActive,
              checkIn:
                salesExecutiveObject.checkInOut === null
                  ? "-"
                  : salesExecutiveObject.checkInOut.checkInTime === null
                    ? "-"
                    : salesExecutiveObject.checkInOut.checkInTime,
              checkOut:
                salesExecutiveObject.checkInOut === null
                  ? "-"
                  : salesExecutiveObject.checkInOut.checkOutTime === null
                    ? "-"
                    : salesExecutiveObject.checkInOut.checkOutTime,
            };
            return salesExecutiveData;
          });
          dispatch(salesExecutiveWithCheckInOutSuccess({ row }));
          return { response: row, success };
        }
      }
      return { response: {}, success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getSalesExecutiveByLoginAreas = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/by-login-areas",
    }).then(({ data, success }) => {
      if (success) {
        if (success) {
          const row = data.data.map((salesExecutiveObject, index) => {
            let salesExecutiveName =
              salesExecutiveObject.name !== null
                ? salesExecutiveObject.name
                : "";
            let stockStakeHolderTypeName =
              salesExecutiveObject.stockStakeHolderType !== null
                ? salesExecutiveObject.stockStakeHolderType.prefix
                : "";
            let salesExecutiveData = {
              index: index + 1,
              id:
                salesExecutiveObject.id === null ? "" : salesExecutiveObject.id,
              userId:
                salesExecutiveObject.userId === null
                  ? ""
                  : salesExecutiveObject.userId,
              userName:
                salesExecutiveObject.userName === null
                  ? ""
                  : salesExecutiveObject.userName,
              stockStakeHolderTypeId:
                salesExecutiveObject.stockStakeHolderType !== null &&
                  salesExecutiveObject.stockStakeHolderType.id
                  ? salesExecutiveObject.stockStakeHolderType.id
                  : "",
              stockStakeHolderType:
                salesExecutiveObject.stockStakeHolderType !== null &&
                  salesExecutiveObject.stockStakeHolderType.name
                  ? salesExecutiveObject.stockStakeHolderType.name
                  : "",
              name:
                salesExecutiveName + " ( " + stockStakeHolderTypeName + " )",
              whatsAppNo:
                salesExecutiveObject.whatsAppNo !== null
                  ? salesExecutiveObject.whatsAppNo
                  : "",

              address:
                salesExecutiveObject.address !== null
                  ? salesExecutiveObject.address
                  : "",

              employeeCode:
                salesExecutiveObject.employeeCode !== null
                  ? salesExecutiveObject.employeeCode
                  : "",

              emailId:
                salesExecutiveObject.emailId === null
                  ? ""
                  : salesExecutiveObject.emailId,
              contactNo:
                salesExecutiveObject.contactNo === null
                  ? ""
                  : salesExecutiveObject.contactNo,
              whatsAppNo:
                salesExecutiveObject.whatsAppNo === null
                  ? ""
                  : salesExecutiveObject.whatsAppNo,

              isActive:
                salesExecutiveObject.isActive === null
                  ? ""
                  : salesExecutiveObject.isActive,
              type:
                salesExecutiveObject.type === null
                  ? ""
                  : salesExecutiveObject.type,
            };
            return salesExecutiveData;
          });
          dispatch(salesExecutiveSuccess({ row }));
          return { response: row, success };
        }
      }
      return { response: {}, success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getSalesPersonByArea =
  ({ areaIds }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/get-all-sales-person-by-areas?areaIds=" + areaIds,
        }).then(({ data, success }) => {
          if (success) {
            if (success) {
              const row = data.data.map((salesExecutiveObject, index) => {
                let salesExecutiveData = {
                  index: index + 1,
                  id:
                    salesExecutiveObject.id === null
                      ? ""
                      : salesExecutiveObject.id,
                  name:
                    salesExecutiveObject.name === null
                      ? ""
                      : salesExecutiveObject.name,
                  address:
                    salesExecutiveObject.address === null
                      ? ""
                      : salesExecutiveObject.address,

                  areaIds:
                    salesExecutiveObject.areaIds !== null
                      ? salesExecutiveObject.areaIds
                      : "",
                  region:
                    salesExecutiveObject.region !== null
                      ? salesExecutiveObject.region.name
                      : "",

                  address:
                    salesExecutiveObject.region !== null
                      ? salesExecutiveObject.region
                      : "",

                  employeeCode:
                    salesExecutiveObject.employeeCode !== null
                      ? salesExecutiveObject.employeeCode
                      : "",

                  emailId:
                    salesExecutiveObject.emailId === null
                      ? ""
                      : salesExecutiveObject.emailId,
                  contactNo:
                    salesExecutiveObject.contactNo === null
                      ? ""
                      : salesExecutiveObject.contactNo,
                  whatsAppNo:
                    salesExecutiveObject.whatsAppNo === null
                      ? ""
                      : salesExecutiveObject.whatsAppNo,

                  isActive:
                    salesExecutiveObject.isActive === null
                      ? ""
                      : salesExecutiveObject.isActive,
                  type:
                    salesExecutiveObject.type === null
                      ? ""
                      : salesExecutiveObject.type,
                };
                return salesExecutiveData;
              });
              dispatch(salesPersonSuccess({ row }));
              return { response: row, success };
            }
          }
          return { response: {}, success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getSalesPersonByAreaPost =
  ({ areaIdsJson }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          url: URL + "/all-sales-person-by-areas",
          postBody: areaIdsJson,
        }).then(({ data, success }) => {
          if (success) {
            if (success) {
              const row = data.data.map((salesExecutiveObject, index) => {
                let salesExecutiveData = {
                  index: index + 1,
                  id:
                    salesExecutiveObject.id === null
                      ? ""
                      : salesExecutiveObject.id,
                  name:
                    salesExecutiveObject.name !== null &&
                      salesExecutiveObject.stockStakeHolderType !== null &&
                      salesExecutiveObject.stockStakeHolderType.prefix
                      ? salesExecutiveObject.name + " (" + salesExecutiveObject.stockStakeHolderType.prefix + ")"
                      : "",
                  address:
                    salesExecutiveObject.address === null
                      ? ""
                      : salesExecutiveObject.address,

                  areaIds:
                    salesExecutiveObject.areaIds !== null
                      ? salesExecutiveObject.areaIds
                      : "",
                  region:
                    salesExecutiveObject.region !== null
                      ? salesExecutiveObject.region.name
                      : "",

                  address:
                    salesExecutiveObject.region !== null
                      ? salesExecutiveObject.region
                      : "",

                  employeeCode:
                    salesExecutiveObject.employeeCode !== null
                      ? salesExecutiveObject.employeeCode
                      : "",

                  emailId:
                    salesExecutiveObject.emailId === null
                      ? ""
                      : salesExecutiveObject.emailId,
                  contactNo:
                    salesExecutiveObject.contactNo === null
                      ? ""
                      : salesExecutiveObject.contactNo,
                  whatsAppNo:
                    salesExecutiveObject.whatsAppNo === null
                      ? ""
                      : salesExecutiveObject.whatsAppNo,

                  isActive:
                    salesExecutiveObject.isActive === null
                      ? ""
                      : salesExecutiveObject.isActive,
                  type:
                    salesExecutiveObject.type === null
                      ? ""
                      : salesExecutiveObject.type,
                };
                return salesExecutiveData;
              });
              dispatch(salesPersonSuccess({ row }));
              return { response: row, success };
            }
          }
          return { response: {}, success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getInactiveSalesPersonByArea =
  ({ areaIdsJson }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          url: URL + "/get-all-inactive-sales-person-by-areas",
          postBody: areaIdsJson,
        }).then(({ data, success }) => {
          if (success) {
            if (success) {
              const row = data.data.map((salesExecutiveObject, index) => {
                let salesExecutiveData = {
                  index: index + 1,
                  id:
                    salesExecutiveObject.id === null
                      ? ""
                      : salesExecutiveObject.id,
                  name:
                    salesExecutiveObject.name === null
                      ? ""
                      : salesExecutiveObject.name,
                  address:
                    salesExecutiveObject.address === null
                      ? ""
                      : salesExecutiveObject.address,

                  areaIds:
                    salesExecutiveObject.areaIds !== null
                      ? salesExecutiveObject.areaIds
                      : "",
                  region:
                    salesExecutiveObject.region !== null
                      ? salesExecutiveObject.region.name
                      : "",

                  address:
                    salesExecutiveObject.region !== null
                      ? salesExecutiveObject.region
                      : "",

                  employeeCode:
                    salesExecutiveObject.employeeCode !== null
                      ? salesExecutiveObject.employeeCode
                      : "",

                  emailId:
                    salesExecutiveObject.emailId === null
                      ? ""
                      : salesExecutiveObject.emailId,
                  contactNo:
                    salesExecutiveObject.contactNo === null
                      ? ""
                      : salesExecutiveObject.contactNo,
                  whatsAppNo:
                    salesExecutiveObject.whatsAppNo === null
                      ? ""
                      : salesExecutiveObject.whatsAppNo,

                  isActive:
                    salesExecutiveObject.isActive === null
                      ? ""
                      : salesExecutiveObject.isActive,
                  type:
                    salesExecutiveObject.type === null
                      ? ""
                      : salesExecutiveObject.type,
                };
                return salesExecutiveData;
              });
              dispatch(salesPersonSuccess({ row }));
              return { response: row, success };
            }
          }
          return { response: {}, success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getSalesPersonByAreaAndStockHolderType =
  ({ dataJson }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          url: URL + "/by-areas-and-stock-stake-holder-types",
          postBody: dataJson,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((salesExecutiveObject, index) => {
              let salesExecutiveData = {
                index: index + 1,
                id:
                  salesExecutiveObject.id === null ? "" : salesExecutiveObject.id,
                name:
                  salesExecutiveObject.name === null
                    ? ""
                    : salesExecutiveObject.name,

                employeeCode:
                  salesExecutiveObject.employeeCode !== null
                    ? salesExecutiveObject.employeeCode
                    : "",

                address:
                  salesExecutiveObject.address === null
                    ? ""
                    : salesExecutiveObject.address,

                areaIds:
                  salesExecutiveObject.areaIds !== null
                    ? salesExecutiveObject.areaIds
                    : "",
                regionName:
                  salesExecutiveObject.regionName !== null
                    ? salesExecutiveObject.regionName
                    : "",

                emailId:
                  salesExecutiveObject.emailId === null
                    ? ""
                    : salesExecutiveObject.emailId,
                contactNo:
                  salesExecutiveObject.contactNo === null
                    ? ""
                    : salesExecutiveObject.contactNo,
                whatsAppNo:
                  salesExecutiveObject.whatsAppNo === null
                    ? ""
                    : salesExecutiveObject.whatsAppNo,

                isActive:
                  salesExecutiveObject.isActive === null
                    ? ""
                    : salesExecutiveObject.isActive,
                type:
                  salesExecutiveObject.type === null
                    ? ""
                    : salesExecutiveObject.type,
                stockStakeHolderTypeId:
                  salesExecutiveObject.stockStakeHolderTypeId === null
                    ? ""
                    : salesExecutiveObject.stockStakeHolderTypeId,
                stockStakeHolderTypeName:
                  salesExecutiveObject.stockStakeHolderTypeName === null
                    ? ""
                    : salesExecutiveObject.stockStakeHolderTypeName,
                userId:
                  salesExecutiveObject.userId === null
                    ? ""
                    : salesExecutiveObject.userId,
                userName:
                  salesExecutiveObject.userName === null
                    ? ""
                    : salesExecutiveObject.userName,
              };
              return salesExecutiveData;
            });
            dispatch(salesPersonByAreaAndStockHolderTypeSuccess({ row }));
            return { response: row, success };
          }

          return { response: {}, success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getSalesPersonByAreaAndStockHolderTypeUpdated =
  ({ dataJson }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          url: URL + "/by-areas-and-stock-stake-holder-types-with-area-names",
          postBody: dataJson,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((salesExecutiveObject, index) => {
              let salesExecutiveData = {
                index: index + 1,
                id:
                  salesExecutiveObject.id === null ? "" : salesExecutiveObject.id,
                name:
                  salesExecutiveObject.name === null
                    ? ""
                    : salesExecutiveObject.name,

                employeeCode:
                  salesExecutiveObject.employeeCode !== null
                    ? salesExecutiveObject.employeeCode
                    : "",

                address:
                  salesExecutiveObject.address === null
                    ? ""
                    : salesExecutiveObject.address,

                areaIds:
                  salesExecutiveObject.areaIds !== null
                    ? salesExecutiveObject.areaIds
                    : "",
                areaNames:
                  salesExecutiveObject.areaNames !== null
                    ? salesExecutiveObject.areaNames
                    : "",
                regionName:
                  salesExecutiveObject.regionName !== null
                    ? salesExecutiveObject.regionName
                    : "",
                emailId:
                  salesExecutiveObject.emailId === null
                    ? ""
                    : salesExecutiveObject.emailId,
                contactNo:
                  salesExecutiveObject.contactNo === null
                    ? ""
                    : salesExecutiveObject.contactNo,
                whatsAppNo:
                  salesExecutiveObject.whatsAppNo === null
                    ? ""
                    : salesExecutiveObject.whatsAppNo,

                isActive:
                  salesExecutiveObject.isActive === null
                    ? ""
                    : salesExecutiveObject.isActive,
                type:
                  salesExecutiveObject.type === null
                    ? ""
                    : salesExecutiveObject.type,
                stockStakeHolderTypeId:
                  salesExecutiveObject.stockStakeHolderTypeId === null
                    ? ""
                    : salesExecutiveObject.stockStakeHolderTypeId,
                stockStakeHolderTypeName:
                  salesExecutiveObject.stockStakeHolderTypeName === null
                    ? ""
                    : salesExecutiveObject.stockStakeHolderTypeName,
                userId:
                  salesExecutiveObject.userId === null
                    ? ""
                    : salesExecutiveObject.userId,
                userName:
                  salesExecutiveObject.userName === null
                    ? ""
                    : salesExecutiveObject.userName,
              };
              return salesExecutiveData;
            });
            dispatch(salesPersonByAreaAndStockHolderTypeSuccess({ row }));
            return { response: row, success };
          }

          return { response: {}, success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const setSalesPersonByAreaAndStockHolderType =
  ({ row }) =>
    async (dispatch) => {
      dispatch(salesPersonByAreaAndStockHolderTypeSuccess({ row }));
    };

export const getSalesPersonByAreaAndStockHolderTypeForClaimStructure =
  ({ claimStructureHeaderId, dataJson }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          url:
            URL +
            "/by-areas-and-stock-stake-holder-types-with-area-names-for-claim?claimStructureHeaderId=" +
            claimStructureHeaderId,
          postBody: dataJson,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((salesExecutiveObject, index) => {
              let salesExecutiveData = {
                index: index + 1,
                id:
                  salesExecutiveObject.id === null ? "" : salesExecutiveObject.id,
                name:
                  salesExecutiveObject.name === null
                    ? ""
                    : salesExecutiveObject.name,

                employeeCode:
                  salesExecutiveObject.employeeCode !== null
                    ? salesExecutiveObject.employeeCode
                    : "",

                address:
                  salesExecutiveObject.address === null
                    ? ""
                    : salesExecutiveObject.address,

                areaIds:
                  salesExecutiveObject.areaIds !== null
                    ? salesExecutiveObject.areaIds
                    : "",
                areaNames:
                  salesExecutiveObject.areaNames !== null
                    ? salesExecutiveObject.areaNames
                    : "",
                regionName:
                  salesExecutiveObject.regionName !== null
                    ? salesExecutiveObject.regionName
                    : "",
                emailId:
                  salesExecutiveObject.emailId === null
                    ? ""
                    : salesExecutiveObject.emailId,
                contactNo:
                  salesExecutiveObject.contactNo === null
                    ? ""
                    : salesExecutiveObject.contactNo,
                whatsAppNo:
                  salesExecutiveObject.whatsAppNo === null
                    ? ""
                    : salesExecutiveObject.whatsAppNo,

                isActive:
                  salesExecutiveObject.isActive === null
                    ? ""
                    : salesExecutiveObject.isActive,
                type:
                  salesExecutiveObject.type === null
                    ? ""
                    : salesExecutiveObject.type,
                stockStakeHolderTypeId:
                  salesExecutiveObject.stockStakeHolderTypeId === null
                    ? ""
                    : salesExecutiveObject.stockStakeHolderTypeId,
                stockStakeHolderTypeName:
                  salesExecutiveObject.stockStakeHolderTypeName === null
                    ? ""
                    : salesExecutiveObject.stockStakeHolderTypeName,
                userId:
                  salesExecutiveObject.userId === null
                    ? ""
                    : salesExecutiveObject.userId,
                userName:
                  salesExecutiveObject.userName === null
                    ? ""
                    : salesExecutiveObject.userName,
              };
              return salesExecutiveData;
            });
            dispatch(
              salesPersonByAreaAndStockHolderTypeForClaimStructureSuccess({ row })
            );
            return { response: row, success };
          }

          return { response: {}, success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const setSalesPersonByAreaAndStockHolderTypeForClaimStructure =
  ({ row }) =>
    async (dispatch) => {
      dispatch(
        salesPersonByAreaAndStockHolderTypeForClaimStructureSuccess({ row })
      );
    };

//Used in manual order
export const getAssignedSalesExecutiveByBeatId =
  ({ beatId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/check-assigned-sales-teams-by-beat?beatId=" + beatId,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.salesTeam.map((salesExecutiveObject, index) => {
              let salesExecutiveData = {
                index: index + 1,
                id:
                  salesExecutiveObject.id === null ? "" : salesExecutiveObject.id,
                name:
                  salesExecutiveObject.name !== null &&
                    salesExecutiveObject.stockStakeHolderType !== null
                    ? salesExecutiveObject.name +
                    " (" +
                    salesExecutiveObject.stockStakeHolderType.prefix +
                    ")"
                    : "",
                employeeCode:
                  salesExecutiveObject.employeeCode !== null
                    ? salesExecutiveObject.employeeCode
                    : "",

                address:
                  salesExecutiveObject.address === null
                    ? ""
                    : salesExecutiveObject.address,

                areaIds:
                  salesExecutiveObject.areaIds !== null
                    ? salesExecutiveObject.areaIds
                    : "",
                areaNames:
                  salesExecutiveObject.areaNames !== null
                    ? salesExecutiveObject.areaNames
                    : "",
                regionName:
                  salesExecutiveObject.regionName !== null
                    ? salesExecutiveObject.regionName
                    : "",
                emailId:
                  salesExecutiveObject.emailId === null
                    ? ""
                    : salesExecutiveObject.emailId,
                contactNo:
                  salesExecutiveObject.contactNo === null
                    ? ""
                    : salesExecutiveObject.contactNo,
                whatsAppNo:
                  salesExecutiveObject.whatsAppNo === null
                    ? ""
                    : salesExecutiveObject.whatsAppNo,

                isActive:
                  salesExecutiveObject.isActive === null
                    ? ""
                    : salesExecutiveObject.isActive,
                type:
                  salesExecutiveObject.type === null
                    ? ""
                    : salesExecutiveObject.type,
                stockStakeHolderTypeId:
                  salesExecutiveObject.stockStakeHolderType === null
                    ? ""
                    : salesExecutiveObject.stockStakeHolderType.id,
                stockStakeHolderTypeName:
                  salesExecutiveObject.stockStakeHolderType === null
                    ? ""
                    : salesExecutiveObject.stockStakeHolderType.name,
                userId:
                  salesExecutiveObject.userId === null
                    ? ""
                    : salesExecutiveObject.userId,
                userName:
                  salesExecutiveObject.userName === null
                    ? ""
                    : salesExecutiveObject.userName,
              };
              return salesExecutiveData;
            });

            dispatch(assignedSalesExecutieByBeatIdSuccess({ row }));
            return { response: row, success };
          }
          return { response: {}, success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getSalesTeamByUserTypeAndTaluka =
  ({ jsonForSave }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          url: URL + "/by-stock-stake-holder-type-and-taluka",
          postBody: jsonForSave,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((salesExecutiveObject, index) => {
              let salesExecutiveData = {
                index: index + 1,
                id:
                  salesExecutiveObject.id === null ? "" : salesExecutiveObject.id,
                userId:
                  salesExecutiveObject.userId === null
                    ? ""
                    : salesExecutiveObject.userId,
                userName:
                  salesExecutiveObject.userName === null
                    ? ""
                    : salesExecutiveObject.userName,
                name:
                  salesExecutiveObject.name !== null &&
                    salesExecutiveObject.stockStakeHolderType !== null &&
                    salesExecutiveObject.stockStakeHolderType.name !== null
                    ? salesExecutiveObject.name +
                    " (" +
                    salesExecutiveObject.stockStakeHolderType.name +
                    ")"
                    : "",
                salesTeamName:
                  salesExecutiveObject.name !== null
                    ? salesExecutiveObject.name
                    : "",
                whatsAppNo:
                  salesExecutiveObject.whatsAppNo === null
                    ? ""
                    : salesExecutiveObject.whatsAppNo,
                contactNo:
                  salesExecutiveObject.contactNo === null
                    ? ""
                    : salesExecutiveObject.contactNo,
                address:
                  salesExecutiveObject.address === null
                    ? ""
                    : salesExecutiveObject.address,
                employeeCode:
                  salesExecutiveObject.employeeCode === null
                    ? ""
                    : salesExecutiveObject.employeeCode,
                emailId:
                  salesExecutiveObject.emailId === null
                    ? ""
                    : salesExecutiveObject.emailId,
                reportingManagerTypeId:
                  salesExecutiveObject.reportingManagerTypeId === null
                    ? ""
                    : salesExecutiveObject.reportingManagerTypeId,
                reportingManagerId:
                  salesExecutiveObject.reportingManagerId === null
                    ? ""
                    : salesExecutiveObject.reportingManagerId,
                regionId:
                  salesExecutiveObject.regionId === null
                    ? ""
                    : salesExecutiveObject.regionId,
                regionId:
                  salesExecutiveObject.regionId === null
                    ? ""
                    : salesExecutiveObject.regionId,
                zoneIds:
                  salesExecutiveObject.zoneIds === null
                    ? ""
                    : salesExecutiveObject.zoneIds,
                areaIds:
                  salesExecutiveObject.areaIds === null
                    ? ""
                    : salesExecutiveObject.areaIds,
                accessRoleId:
                  salesExecutiveObject.accessRoleId === null
                    ? ""
                    : salesExecutiveObject.accessRoleId,
                type:
                  salesExecutiveObject.type === null
                    ? ""
                    : salesExecutiveObject.type,
                isActive:
                  salesExecutiveObject.isActive === null
                    ? ""
                    : salesExecutiveObject.isActive,
                insertDateTime:
                  salesExecutiveObject.insertDateTime === null
                    ? ""
                    : salesExecutiveObject.insertDateTime,
                stockStakeHolderTypeId:
                  salesExecutiveObject.stockStakeHolderType !== null &&
                    salesExecutiveObject.stockStakeHolderType.id !== null
                    ? salesExecutiveObject.stockStakeHolderType.id
                    : "",
                stockStakeHolderTypeName:
                  salesExecutiveObject.stockStakeHolderType !== null &&
                    salesExecutiveObject.stockStakeHolderType.name !== null
                    ? salesExecutiveObject.stockStakeHolderType.name
                    : "",
              };
              return salesExecutiveData;
            });

            dispatch(salesTeamByUserTypeAndTalukaSuccess({ row }));
            return { response: row, success };
          }
          return { response: {}, success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getSalesExecutiveTypeByUserTypeIdsAndAreaId =
  ({ dataJson }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          url: URL + "/by-user-type-ids-and-areas",
          postBody: dataJson,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((salesExecutiveObject, index) => {
              let salesExecutiveData = {
                index: index + 1,
                id:
                  salesExecutiveObject.id === null ? "" : salesExecutiveObject.id,
                name:
                  salesExecutiveObject.name !== null &&
                    salesExecutiveObject.stockStakeHolderType !== null &&
                    salesExecutiveObject.stockStakeHolderType.prefix !== null
                    ? salesExecutiveObject.name +
                    " (" +
                    salesExecutiveObject.stockStakeHolderType.prefix +
                    ")"
                    : "",
                salesTeamName:
                  salesExecutiveObject.name === null
                    ? ""
                    : salesExecutiveObject.name,
                address:
                  salesExecutiveObject.address === null
                    ? ""
                    : salesExecutiveObject.address,
                regionName:
                  salesExecutiveObject.region !== null &&
                    salesExecutiveObject.region.name
                    ? salesExecutiveObject.region.name
                    : "",
                region:
                  salesExecutiveObject.region !== null &&
                    salesExecutiveObject.region.id
                    ? salesExecutiveObject.region.id
                    : "",
                zoneIds:
                  salesExecutiveObject.zoneIds !== null
                    ? salesExecutiveObject.zoneIds
                    : "",
                areaIds:
                  salesExecutiveObject.areaIds !== null
                    ? salesExecutiveObject.areaIds
                    : "",

                emailId:
                  salesExecutiveObject.emailId === null
                    ? ""
                    : salesExecutiveObject.emailId,
                contactNo:
                  salesExecutiveObject.contactNo === null
                    ? ""
                    : salesExecutiveObject.contactNo,
                whatsAppNo:
                  salesExecutiveObject.whatsAppNo === null
                    ? ""
                    : salesExecutiveObject.whatsAppNo,

                isActive:
                  salesExecutiveObject.isActive === null
                    ? ""
                    : salesExecutiveObject.isActive,
              };
              return salesExecutiveData;
            });
            dispatch(salesExecutiveSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };
export const getSalesTeamByUserTypeAndHierarchy =
  ({ jsonForSave }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          url:
            URL +
            "/by-stock-stake-holder-type-and-region-state-zone-dist-taluka-area ",
          postBody: jsonForSave,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((salesExecutiveObject, index) => {
              let salesExecutiveData = {
                index: index + 1,
                id:
                  salesExecutiveObject.id === null ? "" : salesExecutiveObject.id,
                userId:
                  salesExecutiveObject.userId === null
                    ? ""
                    : salesExecutiveObject.userId,
                userName:
                  salesExecutiveObject.userName === null
                    ? ""
                    : salesExecutiveObject.userName,
                name:
                  salesExecutiveObject.name !== null &&
                    salesExecutiveObject.stockStakeHolderType !== null &&
                    salesExecutiveObject.stockStakeHolderType.prefix !== null
                    ? salesExecutiveObject.name +
                    " (" +
                    salesExecutiveObject.stockStakeHolderType.prefix +
                    ")"
                    : "",
                salesTeamName:
                  salesExecutiveObject.name !== null
                    ? salesExecutiveObject.name
                    : "",
                whatsAppNo:
                  salesExecutiveObject.whatsAppNo === null
                    ? ""
                    : salesExecutiveObject.whatsAppNo,
                contactNo:
                  salesExecutiveObject.contactNo === null
                    ? ""
                    : salesExecutiveObject.contactNo,
                address:
                  salesExecutiveObject.address === null
                    ? ""
                    : salesExecutiveObject.address,
                employeeCode:
                  salesExecutiveObject.employeeCode === null
                    ? ""
                    : salesExecutiveObject.employeeCode,
                emailId:
                  salesExecutiveObject.emailId === null
                    ? ""
                    : salesExecutiveObject.emailId,
                reportingManagerTypeId:
                  salesExecutiveObject.reportingManagerTypeId === null
                    ? ""
                    : salesExecutiveObject.reportingManagerTypeId,
                reportingManagerId:
                  salesExecutiveObject.reportingManagerId === null
                    ? ""
                    : salesExecutiveObject.reportingManagerId,
                regionId:
                  salesExecutiveObject.regionId === null
                    ? ""
                    : salesExecutiveObject.regionId,
                regionId:
                  salesExecutiveObject.regionId === null
                    ? ""
                    : salesExecutiveObject.regionId,
                zoneIds:
                  salesExecutiveObject.zoneIds === null
                    ? ""
                    : salesExecutiveObject.zoneIds,
                areaIds:
                  salesExecutiveObject.areaIds === null
                    ? ""
                    : salesExecutiveObject.areaIds,
                accessRoleId:
                  salesExecutiveObject.accessRoleId === null
                    ? ""
                    : salesExecutiveObject.accessRoleId,
                type:
                  salesExecutiveObject.type === null
                    ? ""
                    : salesExecutiveObject.type,
                isActive:
                  salesExecutiveObject.isActive === null
                    ? ""
                    : salesExecutiveObject.isActive,
                insertDateTime:
                  salesExecutiveObject.insertDateTime === null
                    ? ""
                    : salesExecutiveObject.insertDateTime,
                stockStakeHolderTypeId:
                  salesExecutiveObject.stockStakeHolderType !== null &&
                    salesExecutiveObject.stockStakeHolderType.id !== null
                    ? salesExecutiveObject.stockStakeHolderType.id
                    : "",
                stockStakeHolderPrefix:
                  salesExecutiveObject.stockStakeHolderType !== null &&
                    salesExecutiveObject.stockStakeHolderType.prefix !== null
                    ? salesExecutiveObject.stockStakeHolderType.prefix
                    : "",
              };
              return salesExecutiveData;
            });

            dispatch(salesTeamByUserTypeAndTalukaSuccess({ row }));
            return { response: row, success };
          }
          return { response: {}, success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getSalesTeamWithoutConfigurationByArea =
  ({ jsonForSave }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          url: URL + "/with-beat-count-day-end-ru-config-by-areas",
          postBody: jsonForSave,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.filter(row => !(row.dayEndClaim == 1 &&
              row.ruConfig == 1)
            ).map((salesExecutiveObject, index) => {
              let salesExecutiveData = {
                index: index + 1,
                id:
                  salesExecutiveObject.id === null ? "" : salesExecutiveObject.id,
                areaIds:
                  salesExecutiveObject.areaIds === null
                    ? ""
                    : salesExecutiveObject.areaIds,
                areaNames:
                  salesExecutiveObject.areaNames === null
                    ? ""
                    : salesExecutiveObject.areaNames,
                name:
                  salesExecutiveObject.name === null
                    ? ""
                    : salesExecutiveObject.name,
                salesTeamName:
                  salesExecutiveObject.name === null
                    ? ""
                    : salesExecutiveObject.name +
                    " (" +
                    salesExecutiveObject.prefix +
                    ")",
                beatCount:
                  salesExecutiveObject.beatCount === null
                    ? ""
                    : salesExecutiveObject.beatCount,
                dayEndClaim:
                  salesExecutiveObject.dayEndClaim === null
                    ? ""
                    : salesExecutiveObject.dayEndClaim,
                dayEndClaimForTable:
                  salesExecutiveObject.dayEndClaim === null
                    ? ""
                    : salesExecutiveObject.dayEndClaim == 1 ? "Yes" : "No",
                ruConfig:
                  salesExecutiveObject.ruConfig === null
                    ? ""
                    : salesExecutiveObject.ruConfig,
                ruConfigForTable:
                  salesExecutiveObject.ruConfig === null
                    ? ""
                    : salesExecutiveObject.ruConfig == 1 ? "Yes" : "No",
                stockStakeHolderTypeId:
                  salesExecutiveObject.stockStakeHolderTypeId === null
                    ? ""
                    : salesExecutiveObject.stockStakeHolderTypeId,
                stockStakeHolderTypeName:
                  salesExecutiveObject.stockStakeHolderTypeName === null
                    ? ""
                    : salesExecutiveObject.stockStakeHolderTypeName,
                ruConfigValue:
                  salesExecutiveObject.ruConfig === null
                    ? ""
                    : salesExecutiveObject.ruConfig,
                dayEndClaimValue:
                  salesExecutiveObject.dayEndClaim === null
                    ? ""
                    : salesExecutiveObject.dayEndClaim
              };
              return salesExecutiveData;
            });

            dispatch(salesTeamWithoutConfigurationByAreaSuccess({ row }));
            return { response: row, success };
          }
          return { response: {}, success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };


export const setSalesTeamWithoutConfigurationByArea =
  ({ row }) =>
    async (dispatch) => {
      dispatch(salesTeamWithoutConfigurationByAreaSuccess({ row }));
    };