import React from "react";
import { connect } from "react-redux";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import { CountryJson } from "../../../DynamicFormsJson/MastersJSON/country";
import { ChannelJson } from "../../../DynamicFormsJson/MastersJSON/channel";
class ChannelForm extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <>
        <DynamicForm
          formPath={ChannelJson.formPath}
          screenTitle={ChannelJson.screenTitle}
          fieldMeta={ChannelJson.fieldMeta}
          tableColumnsToFilter={ChannelJson.tableColumnsToFilter}
          apiBaseURL={ChannelJson.apiBaseURL}
          baseIdColumn={ChannelJson.baseIdColumn}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ChannelForm);
