import endpoint from "../../config/endpoints";

export const salesTeamCategoryWiseDataJson = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.reportForExcelReports,
  formPath: "/assign-rate-configuration-to-stakeholder-form",
  screenTitle: "Sales Team Category-Wise Data",
  showTitle: true,
  showCancel: false,
  showSaveBtn: false,
  fieldMeta: [
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 5,
      lg: 5,
      sm: 5,
      xs: 12,
      dataKey: "fromDate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "toDate-le",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 5,
      lg: 5,
      sm: 5,
      xs: 12,
      dataKey: "toDate",
      onSubmit: "compare",
      onSubmitParameter: "fromDate-ge",
      isMandatory: true,
      onSubmit: "compare",
    },
  ],
};
