import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import * as React from "react";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import { onlyNumber } from "../../components/Comman/Util/Validations";
import { discountPercentageErrorMsg } from "../../config/messageconstant";
import { FormHelperText } from "@mui/material";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, tableHead, orderBy } = props;

  return (
    <TableHead>
      <TableRow>
        {tableHead.map((headCell) => {
          if (headCell.showInscreen && headCell.title !== "Action") {
            return (
              <TableCell
                key={headCell.name}
                align={"center"}
                style={{ minWidth: headCell.width }}
              // padding={headCell.disablePadding ? 'none' : 'normal'}
              // sortDirection={orderBy === headCell.name ? order : false}
              >
                {headCell.title}
                {orderBy === headCell.name ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableCell>
            );
          }
          return null;
        })}
      </TableRow>
    </TableHead>
  );
}
function Row({
  stockHolderAndProductConfiguration,
  row,
  index,
  rowList = [],
  freeQtyFlag,
  setList,
  flag,
  companyFlagTwo,
  isAdditionalRateApplicable,
  isRateEditableForHoreca,
}) {

  const onTextFieldDataChangePriSecQty = (dataKey, productId) => (event) => {
    const { value } = event.target;
    console.log(dataKey);
    console.log(value);

    if (
      value === "" ||
      onlyNumber.test(value) ||
      (dataKey == "itemDiscPer" && value !== "e" && +value >= 0)
    ) {
      const ListToSet = rowList.map((rowD) => {
        let primaryQty = 0;
        let secondaryQty = 0;
        let conversionPrimaryQty = 0;
        let qty = 0;
        let total = 0;

        const subListData = rowD.productWithMarginAndMrps.map((productData) => {

          if (productData.products.id == productId) {


            productData = {
              ...productData,
              [dataKey]: value,
            };

            primaryQty = productData.primaryQty ? +productData.primaryQty : 0;
            secondaryQty = productData.secondaryQty ? +productData.secondaryQty : 0;

            conversionPrimaryQty = primaryQty * (productData.products.uomConversionValue ? productData.products.uomConversionValue : 0);



            let original = (((conversionPrimaryQty) + (secondaryQty)) * (+productData.rate));

            console.log(original);

            // let discountAmt = (
            //   (original * +productData.itemDiscPer) /
            //   100
            // ).toFixed(2);

            productData = {
              ...productData,
              qty: (conversionPrimaryQty + secondaryQty),
              // total: original - discountAmt,
              total: original,
            };
          }

          qty = qty + (conversionPrimaryQty + secondaryQty);

          total = total + +productData.total;
          return productData;
        });


        console.log(subListData);

        return {
          ...rowD,

          qty: qty,
          total: total,
          productWithMarginAndMrps: subListData,
        };
      });

      console.log(ListToSet);

      setList({ row: ListToSet });
    }
  };


  const onTextFieldDataChangeForItemDiscount = (dataKey, productId) => (event) => {
    const { value } = event.target;
    console.log(dataKey);
    console.log(value);

    if (
      value === "" ||
      onlyNumber.test(value) ||
      (dataKey == "itemDiscPer" && value !== "e" && +value >= 0)
    ) {
      const ListToSet = rowList.map((rowD) => {
        let qty = 0;
        let total = 0;

        const subListData = rowD.productWithMarginAndMrps.map((productData) => {

          if (productData.products.id == productId) {
            productData = {
              ...productData,
              [dataKey]: value,
            };
            let original = (+productData.rate * +productData.qty);

            productData = {
              ...productData,
              total: original,
            };
          }
          qty = qty + +productData.qty;

          total = total + +productData.total;
          return productData;
        });

        return {
          ...rowD,
          qty: qty,
          total: total,
          productWithMarginAndMrps: subListData,
        };
      });

      setList({ row: ListToSet });
    }
  };


  const onTextFieldDataChange = (dataKey, productId) => (event) => {
    const { value } = event.target;

    if (
      value === "" ||
      onlyNumber.test(value) ||
      (dataKey == "itemDiscPer" && value !== "e" && +value >= 0)
    ) {


      const ListToSet = rowList.map((rowD) => {
        let qty = 0;
        let total = 0;

        const subListData = rowD.productWithMarginAndMrps.map((productData) => {

          if (productData.products.id == productId) {

            let isMrpRateError = productData.isMrpRateError;
            let isStockHolderRateError = productData.isStockHolderRateError;

            if (dataKey === "rate") {
              const rateValue = Number(value);
              const mrpValue = Number(productData.mrp);

              isMrpRateError = rateValue > mrpValue;
              isStockHolderRateError = rateValue <= (productData.stockHolderRate / productData.uomConversionValue);
            }

            productData = {
              ...productData,
              [dataKey]: value,
              isMrpRateError: isRateEditableForHoreca == 1 ? isMrpRateError : false,
              isStockHolderRateError: isRateEditableForHoreca == 1 ? isStockHolderRateError : false,
            };


            let original = (+productData.rate * +productData.qty);

            let discountAmt = (
              (original * +productData.itemDiscPer) /
              100
            ).toFixed(2);

            productData = {
              ...productData,
              total: original - discountAmt,
            };
          }
          qty = qty + +productData.qty;

          total = total + +productData.total;
          return productData;
        });


        return {
          ...rowD,

          qty: qty,
          total: total,
          productWithMarginAndMrps: subListData,
        };
      });

      setList({ row: ListToSet });
    }
  };


  const checkValidationOfItemDiscount = () => {
    const { discountOnBillAmt } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (discountOnBillAmt === "") {
      formIsValid = false;
      formErrors["discountOnBillAmtError"] = cdMsg;
    } else if (discountOnBillAmt > 100) {
      formIsValid = false;
      formErrors["discountOnBillAmtError"] = discountPercentageErrorMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  };

  return (

    companyFlagTwo ? (
      <>

        <TableRow key={row.name}>
          <TableCell style={{ width: "8%" }}>
            <center>{index + 1}</center>
          </TableCell>
          <TableCell style={{ width: "20%" }}>
            {" "}
            {row.products != null ? row.products.name : "-"}
          </TableCell>
          <TableCell style={{ width: "10%" }}>
            <center>
              {" "}
              {row.products != null && row.products.outletUom != null
                ? row.products.outletUom.name
                : "-"}
            </center>
          </TableCell>

          {(stockHolderAndProductConfiguration.rateEditOrNot
            .stockHolderAndProductConfiguration &&
            stockHolderAndProductConfiguration.rateEditOrNot.stockHolderAndProductConfiguration.filter(
              (rowData) =>
                rowData.product &&
                row.products &&
                rowData.product.id == row.products.id
            ).length != 0) || (isRateEditableForHoreca == 1 && !isAdditionalRateApplicable) ? (
            <TableCell align="right" style={{ width: "10%" }}>
              <input
                style={{
                  color: row.isMrpRateError || row.isStockHolderRateError ? "red" : "black",
                }}
                className={"customTextfield"}
                placeHolder={"Rate"}
                value={row.rate}
                name="rate"
                onChange={onTextFieldDataChange("rate", row.products.id)}
                type="number"
                id={"1"}
              ></input>
              <span style={{ color: "red" }}>
                {row.isMrpRateError || row.isStockHolderRateError
                  ? "Invalid Rate" : ""}
              </span>
            </TableCell>
          ) : (
            <TableCell align="right" style={{ width: "10%" }}>
              {row.rate}
            </TableCell>
          )}
          {flag && (
            <TableCell align="right" style={{ width: "10%" }}>
              {row.productStock}
            </TableCell>
          )}

          <TableCell style={{ width: "10%" }}>
            <input
              style={{
                color: row.stockError == 1 || row.stockError == 3 ? "red" : "black",
              }}
              className={"customTextfield"}
              placeHolder={"Primary Quantity"}
              value={row.primaryQty}
              name="primaryQty"
              onChange={onTextFieldDataChangePriSecQty("primaryQty", row.products.id)}
              type="text"
              id="primaryQty"
              disabled={flag && row.productStock <= 0 ? true : false}
            ></input>
            <span style={{ color: "red" }}>
              {row.stockError == 1 || row.stockError == 3
                ? "Primary Quantity should be less than stock"
                : ""}
            </span>
          </TableCell>


          <TableCell style={{ width: "10%" }}>
            <input
              style={{
                color: row.stockError == 1 || row.stockError == 3 ? "red" : "black",
              }}
              className={"customTextfield"}
              placeHolder={"Secondary Quantity"}
              value={row.secondaryQty}
              name="secondaryQty"
              onChange={onTextFieldDataChangePriSecQty("secondaryQty", row.products.id)}
              type="text"
              id="secondaryQty"
              disabled={flag && row.productStock <= 0 ? true : false}
            ></input>
            <span style={{ color: "red" }}>
              {row.stockError == 1 || row.stockError == 3
                ? "Secondary Quantity should be less than stock"
                : ""}
            </span>
          </TableCell>


          <TableCell style={{ width: "10%" }}>
            <input
              style={{
                color: row.itemDiscPer > 100 ? "red" : "black",
              }}
              className={"customTextfield"}
              placeHolder={"Discount"}
              value={row.itemDiscPer}
              name="itemDiscPer"
              onChange={onTextFieldDataChangeForItemDiscount("itemDiscPer", row.products.id)}
              type="text"
              id="itemDiscPer"
              disabled={flag && row.productStock <= 0 ? true : false}
            ></input>
            <FormHelperText error>
              {row.itemDiscPer > 100 ? discountPercentageErrorMsg : ""}
            </FormHelperText>
          </TableCell>

          {freeQtyFlag == 1 && (
            <>
              <TableCell style={{ width: "10%" }}>
                <TextFieldCompo
                  placeHolder={"Free Quantity"}
                  size="small"
                  color="primary"
                  type="number"
                  id={"1"}
                  value={row.freeQty}
                  onChange={onTextFieldDataChange("freeQty", row.products.id)}
                  fullWidth={false}
                  errorText={
                    row.stockError == 2 || row.stockError == 3
                      ? "Free Quantity should be less than stock"
                      : ""
                  }
                  disabled={flag && row.productStock <= 0 ? true : false}
                />
              </TableCell>
              <TableCell style={{ width: "10%" }}>
                <TextFieldCompo
                  placeHolder={"Discount %"}
                  size="small"
                  color="primary"
                  type="number"
                  id={"1"}
                  value={row.itemDiscPer}
                  onChange={onTextFieldDataChange("itemDiscPer", row.products.id)}
                  fullWidth={false}
                  errorText={""}
                  disabled={flag && row.productStock <= 0 ? true : false}
                />
              </TableCell>
            </>
          )}

          <TableCell align="right" style={{ width: "10%" }}>
            {row.total.toFixed(2)}
          </TableCell>
        </TableRow>
      </>

    ) : (
      <>

        <TableRow key={row.name}>
          <TableCell style={{ width: "8%" }}>
            <center>{index + 1}</center>
          </TableCell>
          <TableCell style={{ width: "20%" }}>
            {" "}
            {row.products != null ? row.products.name : "-"}
          </TableCell>
          <TableCell style={{ width: "10%" }}>
            <center>
              {" "}
              {row.products != null && row.products.outletUom != null
                ? row.products.outletUom.name
                : "-"}
            </center>
          </TableCell>

          {
            (stockHolderAndProductConfiguration.rateEditOrNot
              .stockHolderAndProductConfiguration &&
              stockHolderAndProductConfiguration.rateEditOrNot.stockHolderAndProductConfiguration.filter(
                (rowData) =>
                  rowData.product &&
                  row.products &&
                  rowData.product.id == row.products.id
              ).length != 0) || (isRateEditableForHoreca == 1 && !isAdditionalRateApplicable)
              ? (
                <TableCell align="right" style={{ width: "10%" }}>
                  <input
                    style={{
                      color: row.isMrpRateError || row.isStockHolderRateError ? "red" : "black",
                    }}
                    className={"customTextfield"}
                    placeHolder={"Rate"}
                    value={row.rate}
                    name="rate"
                    onChange={onTextFieldDataChange("rate", row.products.id)}
                    type="number"
                    id={"1"}
                  ></input>
                  <span style={{ color: "red" }}>
                    {row.isMrpRateError || row.isStockHolderRateError
                      ? "Invalid Rate" : ""}
                  </span>
                </TableCell>
              ) : (
                <TableCell align="right" style={{ width: "10%" }}>
                  {row.rate}
                </TableCell>
              )}
          {flag && (
            <TableCell align="right" style={{ width: "10%" }}>
              {row.productStock}
            </TableCell>
          )}
          <TableCell style={{ width: "10%" }}>
            <input
              style={{
                color: row.stockError == 1 || row.stockError == 3 ? "red" : "black",
              }}
              className={"customTextfield"}
              placeHolder={"Quantity"}
              value={row.qty}
              name="qty"
              onChange={onTextFieldDataChange("qty", row.products.id)}
              type="text"
              id="qty"
              disabled={flag && row.productStock <= 0 ? true : false}
            ></input>
            <span style={{ color: "red" }}>
              {row.stockError == 1 || row.stockError == 3
                ? "Quantity should be less than stock"
                : ""}
            </span>
            {/* <TextFieldCompo
          placeHolder={"Quantity"}
          size="small"
          color="primary"
          type="number"
          id={"1"}
          value={row.qty}
          onChange={onTextFieldDataChange("qty", row.products.id)}
          fullWidth={false}
          errorText={
            row.stockError == 1 || row.stockError == 3
              ? "Quantity should be less than stock"
              : ""
          }
          disabled={flag && row.productStock <= 0 ? true : false}
        /> */}
          </TableCell>
          {freeQtyFlag == 1 && (
            <>
              <TableCell style={{ width: "10%" }}>
                <TextFieldCompo
                  placeHolder={"Free Quantity"}
                  size="small"
                  color="primary"
                  type="number"
                  id={"1"}
                  value={row.freeQty}
                  onChange={onTextFieldDataChange("freeQty", row.products.id)}
                  fullWidth={false}
                  errorText={
                    row.stockError == 2 || row.stockError == 3
                      ? "Free Quantity should be less than stock"
                      : ""
                  }
                  disabled={flag && row.productStock <= 0 ? true : false}
                />
              </TableCell>
              <TableCell style={{ width: "10%" }}>
                <TextFieldCompo
                  placeHolder={"Discount %"}
                  size="small"
                  color="primary"
                  type="number"
                  id={"1"}
                  value={row.itemDiscPer}
                  onChange={onTextFieldDataChange("itemDiscPer", row.products.id)}
                  fullWidth={false}
                  errorText={""}
                  disabled={flag && row.productStock <= 0 ? true : false}
                />
              </TableCell>
            </>
          )}

          <TableCell align="right" style={{ width: "10%" }}>
            {row.total.toFixed(2)}
          </TableCell>
        </TableRow>
      </>
    )
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function MaterialUITableWithTextField({
  tableHead = [],
  stockHolderAndProductConfiguration,
  setList,
  rowList = [],
  data = [],
  flag,
  companyFlagTwo,
  freeQtyFlag = 1,
  isAdditionalRateApplicable,
  isRateEditableForHoreca,
}) {

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer sx={{ maxHeight: 500 }}>
        <Table stickyHeader aria-label="sticky table">
          <EnhancedTableHead
            tableHead={tableHead}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            rowCount={data.length}
          />
          <TableBody>
            {stableSort(data, getComparator(order, orderBy)).map(
              (row, index) => {
                return (
                  <Row
                    freeQtyFlag={freeQtyFlag}
                    rowList={rowList}
                    row={row}
                    setList={setList}
                    companyFlagTwo={companyFlagTwo}
                    index={index}
                    flag={flag}
                    stockHolderAndProductConfiguration={
                      stockHolderAndProductConfiguration
                    }
                    isAdditionalRateApplicable={isAdditionalRateApplicable}
                    isRateEditableForHoreca={isRateEditableForHoreca}
                  />
                );
              }
            )}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={tableHead.length} />
              </TableRow>
            )}
            {data.length === 0 && (
              <TableRow
                style={{
                  height: 53 * 1,
                }}
              >
                <TableCell align="center" colSpan={tableHead.length}>
                  No Record Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
