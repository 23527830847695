import {
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../../../components/withRouter";
import {
  approvedAssetsQtyMsg,
  costGreaterThanZeroMsg,
  costRequiredMsg,
  noInternetMsg,
  saveFailedMsg,
  savemsg,
  saveWarningMsg,
  serverMsg,
  settingValueMsg,
  venderRequiredMsg,
} from "../../../config/messageconstant";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import LandingScreenHeader from "../../../components/Comman/LandingScreenHeader";
import { TextFieldCompo } from "../../../components/Comman/TextFieldCompo";
import { ButtonCompo } from "../../../components/Comman/Button";
import { apiPost } from "../../../utils/api_service";
import endpoint from "../../../config/endpoints";
import {
  getAllSetting,
  setSettingKeyDetails,
} from "../../../Slice/setting.slice";

class SettingKey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
      allChecked: false,
      fieldData: {},
      formData: {},
      formErrors: {},
      selection: [],
    };
  }
  async componentDidMount() {
    const {
      getAllSetting,
      showLoador,
      showNotification,
      setSettingKeyDetails,
    } = this.props;
    if (navigator.onLine) {
      setSettingKeyDetails({ row: [] });

      showLoador({ loador: true });
      await getAllSetting().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  checkValidationNew = () => {
    const { settingList } = this.props;
    let formErrors = {};
    let formIsValid = true;

    settingList?.settingGetAll.map((dataObj) => {
      if (dataObj.isChecked) {
        if (dataObj.settingValue === "") {
          formIsValid = false;
          formErrors[`settingKeyError_${dataObj.id}`] = settingValueMsg;
        }
      }
    });

    this.setState({ formErrors });
    return formIsValid;
  };

  handleClick = (e, id) => {
    const { settingList, setSettingKeyDetails } = this.props;

    this.setState({ selection: e });

    let data = settingList.settingGetAll.map((dataObj) => {
      if (dataObj.id == id) {
        return {
          ...dataObj,
          isChecked: e.target.checked,
        };
      } else {
        return dataObj;
      }
    });
    setSettingKeyDetails({ row: data });
  };

  handleAllCheckedClick = (e) => {
    const { settingList, setSettingKeyDetails } = this.props;

    this.setState({ selection: e });

    this.setState({ allChecked: e.target.checked });

    let dataList = settingList.settingGetAll.map((dataObj) => {
      return {
        ...dataObj,
        isChecked: e.target.checked,
      };
    });

    setSettingKeyDetails({ row: dataList });
  };

  changeHandler = (row, name) => (e) => {
    const { name, value } = e.target;
    const { settingList, setSettingKeyDetails } = this.props;

    let updatedList = settingList.settingGetAll.map((dataObj) => {
      if (dataObj.id === row.id) {
        return {
          ...dataObj,
          [name]: value,
        };
      } else {
        return dataObj;
      }
    });
    setSettingKeyDetails({ row: updatedList });
  };

  onSearchBox = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  getFilteredTableData = (searchData) => {
    const { searchValue } = this.state;
    return searchData.filter((currentRow) => {
      let isValid = false;
      if (
        currentRow["settingKey"] &&
        currentRow["settingKey"]
          .toString()
          .toLowerCase()
          .includes(searchValue.trim().toLowerCase())
      ) {
        isValid = true;
        return true;
      }
    });
  };

  onSave = (dataToSave) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiPost({
        url: endpoint.setting + "/save-list",
        postBody: dataToSave,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          window.location.reload();
          showNotification({ msg: savemsg });
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  onSubmit = () => {
    const { settingList, showNotification } = this.props;
    const { selection } = this.state;

    if (selection.length !== 0) {
      if (this.checkValidationNew()) {
        const dataToSaveList = settingList.settingGetAll
          .filter((rowData) => rowData.isChecked)
          .map((rowData) => {
            return {
              id: rowData.id,

              settingKey: rowData.settingKey,

              settingValue: rowData.settingValue,

              description: rowData.description,
            };
          });
        swal({
          title: "Are you sure?",
          text: saveWarningMsg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            this.onSave(dataToSaveList);
          }
        });
      }
    } else {
      showNotification({
        msg: "Please Select at least one Setting Key",
        severity: "error",
      });
    }
  };

  render() {
    const { settingList, venderList } = this.props;
    const { allChecked, formErrors } = this.state;

    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 0,
          }}
          elevation={0}
        >
          <>
            <LandingScreenHeader
              screenTitle={"Setting Key"}
              showSearchBox={true}
              showTable={true}
              showFilter={false}
              showPdfDownload={false}
              showExcelDownload={false}
              onSearch={this.onSearchBox}
              showAddButton={false}
            />
            <TableContainer style={{ maxHeight: "550px", overflow: "auto" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox" width="5%">
                      <Checkbox
                        color="error"
                        onClick={(event) => this.handleAllCheckedClick(event)}
                        checked={allChecked}
                        disabled={false}
                        inputProps={{
                          "aria-label": "select all desserts",
                        }}
                      />
                    </TableCell>
                    <TableCell align="center" width="5%">
                      Sr. No.
                    </TableCell>
                    <TableCell align="center" width="20%">
                      Setting Key Name
                    </TableCell>
                    <TableCell align="center" width="20%">
                      Setting Value
                    </TableCell>
                    <TableCell align="center" width="50%">
                      Discription
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.getFilteredTableData(settingList?.settingGetAll)?.map(
                    (row, index) => (
                      <TableRow key={row.id}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            onClick={(event) => this.handleClick(event, row.id)}
                            checked={row.isChecked}
                            disabled={false}
                            inputProps={{
                              "aria-labelledby": `enhanced-table-checkbox-${index}`,
                            }}
                          />
                        </TableCell>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="left">{row.settingKey}</TableCell>
                        <TableCell>
                          <TextFieldCompo
                            placeHolder="Setting Key Value"
                            color="primary"
                            type="text"
                            name="settingValue"
                            id={"id"}
                            size="small"
                            value={row.settingValue}
                            fullWidth
                            onChange={this.changeHandler(row, "settingValue")}
                            errorText={
                              row.isChecked &&
                                formErrors[`settingKeyError_${row.id}`]
                                ? formErrors[`settingKeyError_${row.id}`]
                                : ""
                            }
                          />
                        </TableCell>
                        <TableCell align="left">{row.description}</TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <>
              <br />

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                container
                justifyContent="right"
              >
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Submit"
                  onClick={this.onSubmit}
                />
              </Grid>
            </>
          </>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  settingList: state.setting,
});
const mapDispatchToProps = {
  getAllSetting,
  setSettingKeyDetails,
  showNotification,
  showLoador,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SettingKey);
