import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
const initialState = {
  claimType: [],
  claimStructure: [],
  claimApplyHeader: [],
};

let URL = endpoints.claimType;
let URL1 = endpoints.claimStructure;
let URL3 = endpoints.claimApplyHeader;

const claimSlice = createSlice({
  name: "claim",
  initialState,
  reducers: {
    claimTypeSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        claimType: row,
      };
    },

    claimStructureSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        claimStructure: row,
      };
    },

    claimApplyHeaderSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        claimApplyHeader: row,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  claimTypeSuccess,
  claimStructureSuccess,
  claimApplyHeaderSuccess,
  resetState,
} = claimSlice.actions;

export default claimSlice.reducer;

export const getClaimType = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL,
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        const row = content.map((claimTypeObj, index) => {
          let claimTypeData = {
            index: index + 1,
            id: claimTypeObj.id === null ? "" : claimTypeObj.id,
            name: claimTypeObj.name === null ? "" : claimTypeObj.name,
            shortName:
              claimTypeObj.shortName === null ? "" : claimTypeObj.shortName,
            remark: claimTypeObj.remark === null ? "" : claimTypeObj.remark,
            limitValue:
              claimTypeObj.limitVal === null
                ? ""
                : claimTypeObj.limitVal == 1
                  ? "Yes"
                  : "No",
            limitVal:
              claimTypeObj.limitVal === null ? "" : claimTypeObj.limitVal,
            type: claimTypeObj.type === null ? "" : claimTypeObj.type,
            uomName: claimTypeObj.uomName === null ? "" : claimTypeObj.uomName,
            attachmentApplicable:
              claimTypeObj.attachmentApplicable === null
                ? ""
                : claimTypeObj.attachmentApplicable,
            extra1: claimTypeObj.extra1 === null ? "" : claimTypeObj.extra1,
            company: claimTypeObj.company === null ? "" : claimTypeObj.company,
            companyId:
              claimTypeObj.company === null ? "" : claimTypeObj.company.id,
            companyName:
              claimTypeObj.company === null ? "" : claimTypeObj.company.name,
            isActive:
              claimTypeObj.isActive === null ? "" : claimTypeObj.isActive,
            claimAmt: 0,
          };
          return claimTypeData;
        });
        dispatch(claimTypeSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getAllClaimType = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/get-all",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((claimTypeObj, index) => {
          let claimTypeData = {
            index: index + 1,
            id: claimTypeObj.id === null ? "" : claimTypeObj.id,
            flag: claimTypeObj.flag === null ? "" : claimTypeObj.flag,
            name: claimTypeObj.name === null ? "" : claimTypeObj.name,
            shortName:
              claimTypeObj.shortName === null ? "" : claimTypeObj.shortName,
            remark: claimTypeObj.remark === null ? "" : claimTypeObj.remark,
            limitValue:
              claimTypeObj.limitVal === null
                ? ""
                : claimTypeObj.limitVal == 1
                  ? "Yes"
                  : "No",
            limitVal:
              claimTypeObj.limitVal === null ? "" : claimTypeObj.limitVal,
            type: claimTypeObj.type === null ? "" : claimTypeObj.type,
            uomName: claimTypeObj.uomName === null ? "" : claimTypeObj.uomName,
            attachmentApplicable:
              claimTypeObj.attachmentApplicable === null
                ? ""
                : claimTypeObj.attachmentApplicable,
            extra1: claimTypeObj.extra1 === null ? "" : claimTypeObj.extra1,
            companyId:
              claimTypeObj.companyId === null ? "" : claimTypeObj.companyId,
            companyName:
              claimTypeObj.companyName === null ? "" : claimTypeObj.companyName,
            isActive:
              claimTypeObj.isActive === null ? "" : claimTypeObj.isActive,
            claimAmt: 0,
          };
          return claimTypeData;
        });
        dispatch(claimTypeSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const setClaimType =
  ({ row }) =>
    async (dispatch) => {
      dispatch(claimTypeSuccess({ row }));
    };

export const getClaimStructure = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL1,
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        const row = content.map((claimTypeObj, index) => {
          let claimTypeData = {
            index: index + 1,
            id: claimTypeObj.id === null ? "" : claimTypeObj.id,
            name: claimTypeObj.name === null ? "" : claimTypeObj.name,
            shortName:
              claimTypeObj.shortName === null ? "" : claimTypeObj.shortName,
            remark: claimTypeObj.remark === null ? "" : claimTypeObj.remark,
            extra1: claimTypeObj.extra1 === null ? "" : claimTypeObj.extra1,
            company: claimTypeObj.company === null ? "" : claimTypeObj.company,
            companyId:
              claimTypeObj.company === null ? "" : claimTypeObj.company.id,
            companyName:
              claimTypeObj.company === null ? "" : claimTypeObj.company.name,
            isActive:
              claimTypeObj.isActive === null ? "" : claimTypeObj.isActive,
          };
          return claimTypeData;
        });
        dispatch(claimStructureSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getAllClaimStructure = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL1 + "/get-all",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((claimTypeObj, index) => {
          let claimTypeData = {
            index: index + 1,
            id: claimTypeObj.id === null ? "" : claimTypeObj.id,
            companyId:
              claimTypeObj.companyId === null ? "" : claimTypeObj.companyId,
            name: claimTypeObj.name === null ? "" : claimTypeObj.name,
            type:
              claimTypeObj.fixedCalculation === null
                ? ""
                : claimTypeObj.fixedCalculation == 1 ? "TA / DA" : "Normal",
            fixedCalculation:
              claimTypeObj.fixedCalculation === null
                ? ""
                : claimTypeObj.fixedCalculation,

            salesTeamCount:
              claimTypeObj.salesTeamCount === null
                ? ""
                : claimTypeObj.salesTeamCount,

            isActive:
              claimTypeObj.isActive === null ? "" : claimTypeObj.isActive,
          };
          return claimTypeData;
        });
        dispatch(claimStructureSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getActiveClaimStructure = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL1 + "/get-active",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((claimTypeObj, index) => {
          let claimTypeData = {
            index: index + 1,
            id: claimTypeObj.id === null ? "" : claimTypeObj.id,
            name: claimTypeObj.name === null ? "" : claimTypeObj.name,
            shortName:
              claimTypeObj.shortName === null ? "" : claimTypeObj.shortName,
            remark: claimTypeObj.remark === null ? "" : claimTypeObj.remark,
            extra1: claimTypeObj.extra1 === null ? "" : claimTypeObj.extra1,
            company: claimTypeObj.company === null ? "" : claimTypeObj.company,
            companyId:
              claimTypeObj.company === null ? "" : claimTypeObj.company.id,
            companyName:
              claimTypeObj.company === null ? "" : claimTypeObj.company.name,
          };
          return claimTypeData;
        });
        dispatch(claimStructureSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getClaimApply =
  ({ fromDate, toDate, salesTeamIds }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          url:
            URL3 +
            "/by-sales-team-and-date?fromDate=" +
            fromDate +
            "&toDate=" +
            toDate,
          postBody: salesTeamIds,
        }).then(({ data, success }) => {
          console.log(data);
          if (success) {
            const row = data.data.map((claimApplyObj, index) => {
              let claimApplyData = {
                index: index + 1,
                id: claimApplyObj.id === null ? "" : claimApplyObj.id,
                name: claimApplyObj.name === null ? "" : claimApplyObj.name,
                reportingPersonId:
                  claimApplyObj.reportingPersonId === null
                    ? ""
                    : claimApplyObj.reportingPersonId,
                reportingPersonName:
                  claimApplyObj.reportingPersonName === null
                    ? ""
                    : claimApplyObj.reportingPersonName,
                claimApplyHeader:
                  claimApplyObj.claimApplyHeader === null
                    ? ""
                    : claimApplyObj.claimApplyHeader.map(
                      (headerObj, headerIndex) => {
                        let claimApplyHeaderData = {
                          index: headerIndex + 1,
                          id: headerObj.id === null ? "" : headerObj.id,
                          claimTitle:
                            headerObj.claimTitle === null
                              ? ""
                              : headerObj.claimTitle,
                          claimAmount:
                            headerObj.claimAmount === null
                              ? ""
                              : headerObj.claimAmount,
                          status:
                            headerObj.status === null
                              ? ""
                              : headerObj.status == 0
                                ? "Pending"
                                : headerObj.status == 1
                                  ? "Cancelled"
                                  : headerObj.status == 2
                                    ? "Approved"
                                    : "Reject",
                          date: headerObj.date === null ? "" : headerObj.date,
                          insertedUserId:
                            headerObj.insertedUserId === null
                              ? ""
                              : headerObj.insertedUserId,
                          claimApplyDetail:
                            headerObj.claimApplyDetail === null
                              ? ""
                              : headerObj.claimApplyDetail.map(
                                (detailObj, detailIndex) => {
                                  let claimApplyDetailData = {
                                    index: detailIndex + 1,
                                    id:
                                      detailObj.id === null
                                        ? ""
                                        : detailObj.id,
                                    claimApplyHeaderId:
                                      detailObj.claimApplyHeaderId === null
                                        ? ""
                                        : detailObj.claimApplyHeaderId,
                                    claimAmt:
                                      detailObj.claimAmt === null
                                        ? ""
                                        : detailObj.claimAmt,
                                    claimType:
                                      detailObj.claimType === null
                                        ? ""
                                        : detailObj.claimType,
                                    claimTypeName:
                                      detailObj.claimTypeName === null
                                        ? ""
                                        : detailObj.claimTypeName,
                                    attachFile:
                                      detailObj.attachFile === null
                                        ? ""
                                        : detailObj.attachFile,
                                    remark:
                                      detailObj.remark === null
                                        ? ""
                                        : detailObj.remark,
                                  };
                                  return claimApplyDetailData;
                                }
                              ),
                        };
                        return claimApplyHeaderData;
                      }
                    ),
              };
              return claimApplyData;
            });

            dispatch(claimApplyHeaderSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });

        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getClaim =
  ({ fromDate, toDate, type }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL3 +
            "/claim-apply-list-for-admin?fromDate=" +
            fromDate +
            "&toDate=" +
            toDate +
            "&type=" +
            type,
        }).then(({ data, success }) => {
          console.log(data);
          if (success) {
            const row = data.data.map((claimDataObj, index) => {
              let claimData = {
                index: index + 1,
                id: claimDataObj.id === null ? "" : claimDataObj.id,
                name: claimDataObj.name === null ? "" : claimDataObj.name,
                date: claimDataObj.date === null ? "" : claimDataObj.date,
                salesTeamName:
                  claimDataObj.salesTeamName !== null && claimDataObj.salesTeamTypePrefix !== null
                    ? claimDataObj.salesTeamName + " (" + claimDataObj.salesTeamTypePrefix + ")"
                    : "",
                salesTeamTypePrefix:
                  claimDataObj.salesTeamTypePrefix === null
                    ? ""
                    : claimDataObj.salesTeamTypePrefix,

                authoritySalesPersonName:
                  claimDataObj.authoritySalesPersonName !== null &&
                    claimDataObj.authoritySalesPersonTypePrefix !== null
                    ? claimDataObj.authoritySalesPersonName + " (" + claimDataObj.authoritySalesPersonTypePrefix + ")"
                    : "",

                authoritySalesPersonTypePrefix:
                  claimDataObj.authoritySalesPersonTypePrefix === null
                    ? ""
                    : claimDataObj.authoritySalesPersonTypePrefix,
                claimStructureHeader:
                  claimDataObj.claimStructureHeader === null
                    ? ""
                    : claimDataObj.claimStructureHeader,
                salesTeamId:
                  claimDataObj.salesTeamId === null
                    ? ""
                    : claimDataObj.salesTeamId,

                salesTeamTypeName:
                  claimDataObj.salesTeamTypeName === null
                    ? ""
                    : claimDataObj.salesTeamTypeName,

                claimType:
                  claimDataObj.claimStructureHeader !== null &&
                    claimDataObj.claimStructureHeader.fixedCalculation !== null
                    ? claimDataObj.claimStructureHeader.fixedCalculation == 0 ?
                      "Regular Claim" : "Allowance wise"
                    : "",
                claimAmt:
                  claimDataObj.claimAmt === null ? "" : claimDataObj.claimAmt.toFixed(2),
                isActive:
                  claimDataObj.isActive === null ? "" : claimDataObj.isActive,
                status:
                  claimDataObj.status === null
                    ? ""
                    : claimDataObj.status == 0
                      ? "Pending"
                      : claimDataObj.status == 1
                        ? "Cancelled"
                        : claimDataObj.status == 2
                          ? "Approved"
                          : "Reject",
              };
              return claimData;
            });

            dispatch(claimApplyHeaderSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });

        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };
