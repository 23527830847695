import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
const initialState = {
  billingHistory: [],
  billHistoryById: [],
  billingByOutlet: [],
  billProductListById: [],
  billData: [],
  monthYearTargetForSale: [],
  topProductListFromYearAndMonth: [],
  topProductListFromYearAndMonthTotal: 0,
  topOutletListFromYearAndMonth: [],
  topOutletListFromYearAndMonthTotal: 0,
  categoryWiseBill: [],
  packagingType: [],
};
let URL = endpoints.outletBillHeader;
let URL1 = endpoints.outletBillDetail;
let URLLatest = endpoints.outletBillHeaderLastest;

const billingHistorySlice = createSlice({
  name: "billingHistory",
  initialState,
  reducers: {
    billingHistorySuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        billingHistory: row,
        isFetch: true,
      };
    },
    billingByOutletSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        billingByOutlet: row,
        isFetch: true,
      };
    },
    categoryWiseBillSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        categoryWiseBill: row,
        isFetch: true,
      };
    },

    packagingTypeSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        packagingType: row,
        isFetch: true,
      };
    },
    billHeaderByIdSuccess: (state = cloneDeep(initialState), action) => {
      const { billHeaderData } = action.payload;
      return {
        ...state,
        billHeaderById: billHeaderData,
        isFetch: true,
      };
    },

    billHistoryByIdSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        billHistoryById: row,
        isFetch: true,
      };
    },

    billProductListByIdSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        billProductListById: row,
        isFetch: true,
      };
    },
    billDataSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        billData: row,
        isFetch: true,
      };
    },
    topProductListFromYearAndMonthBillwiseSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row, total } = action.payload;
      return {
        ...state,
        topProductListFromYearAndMonth: row,
        topProductListFromYearAndMonthTotal: total,
      };
    },
    topOutletListFromYearAndMonthSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row, total } = action.payload;
      return {
        ...state,
        topOutletListFromYearAndMonth: row,
        topOutletListFromYearAndMonthTotal: total,
      };
    },
    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  billingHistorySuccess,
  billingByOutletSuccess,
  categoryWiseBillSuccess,
  packagingTypeSuccess,
  billHeaderByIdSuccess,
  billHistoryByIdSuccess,
  billProductListByIdSuccess,
  billDataSuccess,
  topProductListFromYearAndMonthBillwiseSuccess,
  topOutletListFromYearAndMonthSuccess,
  resetState,
} = billingHistorySlice.actions;

export default billingHistorySlice.reducer;

export const getTodaysBillingHistory = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/todays-bill-generated",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((billingHistoryObject, index) => {
          let billingHistoryData = {
            index: index + 1,
            id: billingHistoryObject.id === null ? "" : billingHistoryObject.id,

            firmName:
              billingHistoryObject.outLet === null
                ? ""
                : billingHistoryObject.outLet.firmName,

            beatName:
              billingHistoryObject.outLet === null
                ? ""
                : billingHistoryObject.outLet.beat.beatName,
            billNo:
              billingHistoryObject.billNo === null
                ? ""
                : billingHistoryObject.billNo,
            billDate:
              billingHistoryObject.billDate === null
                ? ""
                : billingHistoryObject.billDate,
            taxableAmt:
              billingHistoryObject.taxableAmt === null
                ? ""
                : billingHistoryObject.taxableAmt.toFixed(2),
            cgstAmt:
              billingHistoryObject.cgstAmt === null
                ? ""
                : billingHistoryObject.cgstAmt.toFixed(2),
            sgstAmt:
              billingHistoryObject.sgstAmt === null
                ? ""
                : billingHistoryObject.sgstAmt.toFixed(2),
            totalAmt:
              billingHistoryObject.totalAmt === null
                ? ""
                : billingHistoryObject.totalAmt.toFixed(2),

            statusName:
              billingHistoryObject.status === null
                ? ""
                : billingHistoryObject.status === 0
                  ? "Generated"
                  : billingHistoryObject.status === 1
                    ? "Cancelled"
                    : billingHistoryObject.status === 0 &&
                      billingHistoryObject.payoutIsSettle === 1
                      ? "Paid"
                      : "Pending",
            isInvoiceGenerated:
              billingHistoryObject.acknoledgeNo == "0" &&
                billingHistoryObject.invoiceJson === null
                ? "No"
                : "Yes",
            payoutIsSettle:
              billingHistoryObject.payoutIsSettle === null
                ? ""
                : billingHistoryObject.payoutIsSettle,
            status:
              billingHistoryObject.status === null
                ? ""
                : billingHistoryObject.status,
            stockStakeHolderId:
              billingHistoryObject.stockStakeHolderId === null
                ? ""
                : billingHistoryObject.stockStakeHolderId,
            itemDistcountAmt:
              billingHistoryObject.itemDistcountAmt === null
                ? ""
                : +billingHistoryObject.itemDistcountAmt.toFixed(2),
            discountOnBillPer:
              billingHistoryObject.discountOnBillPer === null
                ? ""
                : billingHistoryObject.discountOnBillPer,
            discountOnBillAmt:
              billingHistoryObject.discountOnBillAmt === null
                ? ""
                : +billingHistoryObject.discountOnBillAmt.toFixed(2),

            payoutAmt:
              billingHistoryObject.payoutAmt === null
                ? ""
                : +billingHistoryObject.payoutAmt.toFixed(2),

            igstAmt:
              billingHistoryObject.igstAmt === null
                ? ""
                : +billingHistoryObject.igstAmt.toFixed(2),
            cessAmt:
              billingHistoryObject.cessAmt === null
                ? ""
                : +billingHistoryObject.cessAmt.toFixed(2),
            remainingAmt:
              billingHistoryObject.remainingAmt === null
                ? ""
                : +billingHistoryObject.remainingAmt.toFixed(2),
            acknoledgeNo:
              billingHistoryObject.acknoledgeNo === null
                ? ""
                : billingHistoryObject.acknoledgeNo,
            payoutIsSettle:
              billingHistoryObject.payoutIsSettle === null
                ? ""
                : billingHistoryObject.payoutIsSettle,
            isSettle:
              billingHistoryObject.isSettle === null
                ? ""
                : billingHistoryObject.isSettle,
            insertedUserId:
              billingHistoryObject.insertedUserId === null
                ? ""
                : billingHistoryObject.insertedUserId,
            insertDateTime:
              billingHistoryObject.insertDateTime === null
                ? ""
                : billingHistoryObject.insertDateTime,
          };

          return billingHistoryData;
        });
        dispatch(billingHistorySuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getTodaysBillingHistoryUpdated = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URLLatest + "/get-todays-bill-generated",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((billingHistoryObject, index) => {
          let billingHistoryData = {
            index: index + 1,
            id: billingHistoryObject.id === null ? "" : billingHistoryObject.id,

            firmName:
              billingHistoryObject.outletFirmName === null
                ? ""
                : billingHistoryObject.outletFirmName,

            beatName:
              billingHistoryObject.beatName === null
                ? ""
                : billingHistoryObject.beatName,
            billNo:
              billingHistoryObject.billNo === null
                ? ""
                : billingHistoryObject.billNo,
            billDate:
              billingHistoryObject.billDate === null
                ? ""
                : billingHistoryObject.billDate,
            taxableAmt:
              billingHistoryObject.taxableAmt === null
                ? ""
                : billingHistoryObject.taxableAmt.toFixed(2),
            cgstAmt:
              billingHistoryObject.cgstAmt === null
                ? ""
                : billingHistoryObject.cgstAmt.toFixed(2),
            sgstAmt:
              billingHistoryObject.sgstAmt === null
                ? ""
                : billingHistoryObject.sgstAmt.toFixed(2),
            totalAmt:
              billingHistoryObject.totalAmt === null
                ? ""
                : billingHistoryObject.totalAmt.toFixed(2),

            statusName:
              billingHistoryObject.status === null
                ? ""
                : billingHistoryObject.status === 0
                  ? "Generated"
                  : billingHistoryObject.status === 1
                    ? "Cancelled"
                    : billingHistoryObject.status === 0 &&
                      billingHistoryObject.payoutIsSettle === 1
                      ? "Paid"
                      : "Pending",
            isInvoiceGenerated:
              billingHistoryObject.acknoledgeNo == "0" &&
                billingHistoryObject.invoiceJson === null
                ? "No"
                : "Yes",
            payoutIsSettle:
              billingHistoryObject.payoutIsSettle === null
                ? ""
                : billingHistoryObject.payoutIsSettle,
            status:
              billingHistoryObject.status === null
                ? ""
                : billingHistoryObject.status,
            stockStakeHolderId:
              billingHistoryObject.stockStakeHolderId === null
                ? ""
                : billingHistoryObject.stockStakeHolderId,
            itemDistcountAmt:
              billingHistoryObject.itemDistcountAmt === null
                ? ""
                : +billingHistoryObject.itemDistcountAmt.toFixed(2),
            discountOnBillPer:
              billingHistoryObject.discountOnBillPer === null
                ? ""
                : billingHistoryObject.discountOnBillPer,
            discountOnBillAmt:
              billingHistoryObject.discountOnBillAmt === null
                ? ""
                : +billingHistoryObject.discountOnBillAmt.toFixed(2),

            payoutAmt:
              billingHistoryObject.payoutAmt === null
                ? ""
                : +billingHistoryObject.payoutAmt.toFixed(2),

            igstAmt:
              billingHistoryObject.igstAmt === null
                ? ""
                : +billingHistoryObject.igstAmt.toFixed(2),
            cessAmt:
              billingHistoryObject.cessAmt === null
                ? ""
                : +billingHistoryObject.cessAmt.toFixed(2),
            remainingAmt:
              billingHistoryObject.remainingAmt === null
                ? ""
                : +billingHistoryObject.remainingAmt.toFixed(2),
            acknoledgeNo:
              billingHistoryObject.acknoledgeNo === null
                ? ""
                : billingHistoryObject.acknoledgeNo,
            payoutIsSettle:
              billingHistoryObject.payoutIsSettle === null
                ? ""
                : billingHistoryObject.payoutIsSettle,
            isSettle:
              billingHistoryObject.isSettle === null
                ? ""
                : billingHistoryObject.isSettle,
            insertedUserId:
              billingHistoryObject.insertedUserId === null
                ? ""
                : billingHistoryObject.insertedUserId,
            insertDateTime:
              billingHistoryObject.insertDateTime === null
                ? ""
                : billingHistoryObject.insertDateTime,
          };

          return billingHistoryData;
        });
        dispatch(billingHistorySuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getBillingHistoryPost =
  ({ billingHistoryDataForPost }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          url: URL + "/by-outlets-and-date",
          postBody: billingHistoryDataForPost,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((billingHistoryObject, index) => {
              let billingHistoryData = {
                index: index + 1,
                id:
                  billingHistoryObject.id === null ? "" : billingHistoryObject.id,

                firmName:
                  billingHistoryObject.outLet === null
                    ? ""
                    : billingHistoryObject.outLet.firmName,

                beatName:
                  billingHistoryObject.outLet === null
                    ? ""
                    : billingHistoryObject.outLet.beat.beatName,
                billNo:
                  billingHistoryObject.billNo === null
                    ? ""
                    : billingHistoryObject.billNo,
                billDate:
                  billingHistoryObject.billDate === null
                    ? ""
                    : billingHistoryObject.billDate,
                taxableAmt:
                  billingHistoryObject.taxableAmt === null
                    ? ""
                    : billingHistoryObject.taxableAmt.toFixed(2),
                cgstAmt:
                  billingHistoryObject.cgstAmt === null
                    ? ""
                    : billingHistoryObject.cgstAmt.toFixed(2),
                sgstAmt:
                  billingHistoryObject.sgstAmt === null
                    ? ""
                    : billingHistoryObject.sgstAmt.toFixed(2),
                totalAmt:
                  billingHistoryObject.totalAmt === null
                    ? ""
                    : billingHistoryObject.totalAmt.toFixed(2),

                statusName:
                  billingHistoryObject.status === null
                    ? ""
                    : billingHistoryObject.status === 0
                      ? "Generated"
                      : billingHistoryObject.status === 1
                        ? "Cancelled"
                        : billingHistoryObject.status === 0 &&
                          billingHistoryObject.payoutIsSettle === 1
                          ? "Paid"
                          : "Pending",
                isInvoiceGenerated:
                  billingHistoryObject.acknoledgeNo == "0" &&
                    billingHistoryObject.invoiceJson === null
                    ? "No"
                    : "Yes",
                payoutIsSettle:
                  billingHistoryObject.payoutIsSettle === null
                    ? ""
                    : billingHistoryObject.payoutIsSettle,
                status:
                  billingHistoryObject.status === null
                    ? ""
                    : billingHistoryObject.status,
                stockStakeHolderId:
                  billingHistoryObject.stockStakeHolderId === null
                    ? ""
                    : billingHistoryObject.stockStakeHolderId,
                itemDistcountAmt:
                  billingHistoryObject.itemDistcountAmt === null
                    ? ""
                    : +billingHistoryObject.itemDistcountAmt.toFixed(2),
                discountOnBillPer:
                  billingHistoryObject.discountOnBillPer === null
                    ? ""
                    : billingHistoryObject.discountOnBillPer,
                discountOnBillAmt:
                  billingHistoryObject.discountOnBillAmt === null
                    ? ""
                    : +billingHistoryObject.discountOnBillAmt.toFixed(2),

                payoutAmt:
                  billingHistoryObject.payoutAmt === null
                    ? ""
                    : +billingHistoryObject.payoutAmt.toFixed(2),

                igstAmt:
                  billingHistoryObject.igstAmt === null
                    ? ""
                    : +billingHistoryObject.igstAmt.toFixed(2),
                cessAmt:
                  billingHistoryObject.cessAmt === null
                    ? ""
                    : +billingHistoryObject.cessAmt.toFixed(2),
                remainingAmt:
                  billingHistoryObject.remainingAmt === null
                    ? ""
                    : +billingHistoryObject.remainingAmt.toFixed(2),
                acknoledgeNo:
                  billingHistoryObject.acknoledgeNo === null
                    ? ""
                    : billingHistoryObject.acknoledgeNo,
                payoutIsSettle:
                  billingHistoryObject.payoutIsSettle === null
                    ? ""
                    : billingHistoryObject.payoutIsSettle,
                isSettle:
                  billingHistoryObject.isSettle === null
                    ? ""
                    : billingHistoryObject.isSettle,
                insertedUserId:
                  billingHistoryObject.insertedUserId === null
                    ? ""
                    : billingHistoryObject.insertedUserId,
                insertDateTime:
                  billingHistoryObject.insertDateTime === null
                    ? ""
                    : billingHistoryObject.insertDateTime,
              };

              return billingHistoryData;
            });
            dispatch(billingHistorySuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getBillingHistoryPostUpdated =
  ({ billingHistoryDataForPost }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          url: URLLatest + "/by-outlets-and-date-new",
          postBody: billingHistoryDataForPost,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((billingHistoryObject, index) => {
              let billingHistoryData = {
                index: index + 1,
                id:
                  billingHistoryObject.id === null ? "" : billingHistoryObject.id,

                firmName:
                  billingHistoryObject.firmName === null
                    ? ""
                    : billingHistoryObject.firmName,

                beatName:
                  billingHistoryObject.beatName === null
                    ? ""
                    : billingHistoryObject.beatName,
                billNo:
                  billingHistoryObject.billNo === null
                    ? ""
                    : billingHistoryObject.billNo,
                billDate:
                  billingHistoryObject.billDate === null
                    ? ""
                    : billingHistoryObject.billDate,
                taxableAmt:
                  billingHistoryObject.taxableAmt === null
                    ? ""
                    : billingHistoryObject.taxableAmt.toFixed(2),
                cgstAmt:
                  billingHistoryObject.cgstAmt === null
                    ? ""
                    : billingHistoryObject.cgstAmt.toFixed(2),
                sgstAmt:
                  billingHistoryObject.sgstAmt === null
                    ? ""
                    : billingHistoryObject.sgstAmt.toFixed(2),
                totalAmt:
                  billingHistoryObject.totalAmt === null
                    ? ""
                    : billingHistoryObject.totalAmt.toFixed(2),

                statusName:
                  billingHistoryObject.status === null
                    ? ""
                    : billingHistoryObject.status === 0
                      ? "Generated"
                      : billingHistoryObject.status === 1
                        ? "Cancelled"
                        : billingHistoryObject.status === 0 &&
                          billingHistoryObject.payoutIsSettle === 1
                          ? "Paid"
                          : "Pending",
                isInvoiceGenerated:
                  billingHistoryObject.acknoledgeNo == "0" &&
                    billingHistoryObject.invoiceJson === null
                    ? "No"
                    : "Yes",
                payoutIsSettle:
                  billingHistoryObject.payoutIsSettle === null
                    ? ""
                    : billingHistoryObject.payoutIsSettle,
                status:
                  billingHistoryObject.status === null
                    ? ""
                    : billingHistoryObject.status,
                stockStakeHolderId:
                  billingHistoryObject.stockStakeHolderId === null
                    ? ""
                    : billingHistoryObject.stockStakeHolderId,
                itemDistcountAmt:
                  billingHistoryObject.itemDistcountAmt === null
                    ? ""
                    : +billingHistoryObject.itemDistcountAmt.toFixed(2),
                discountOnBillPer:
                  billingHistoryObject.discountOnBillPer === null
                    ? ""
                    : billingHistoryObject.discountOnBillPer,
                discountOnBillAmt:
                  billingHistoryObject.discountOnBillAmt === null
                    ? ""
                    : +billingHistoryObject.discountOnBillAmt.toFixed(2),

                payoutAmt:
                  billingHistoryObject.payoutAmt === null
                    ? ""
                    : +billingHistoryObject.payoutAmt.toFixed(2),

                igstAmt:
                  billingHistoryObject.igstAmt === null
                    ? ""
                    : +billingHistoryObject.igstAmt.toFixed(2),
                cessAmt:
                  billingHistoryObject.cessAmt === null
                    ? ""
                    : +billingHistoryObject.cessAmt.toFixed(2),
                remainingAmt:
                  billingHistoryObject.remainingAmt === null
                    ? ""
                    : +billingHistoryObject.remainingAmt.toFixed(2),
                acknoledgeNo:
                  billingHistoryObject.acknoledgeNo === null
                    ? ""
                    : billingHistoryObject.acknoledgeNo,
                payoutIsSettle:
                  billingHistoryObject.payoutIsSettle === null
                    ? ""
                    : billingHistoryObject.payoutIsSettle,
                isSettle:
                  billingHistoryObject.isSettle === null
                    ? ""
                    : billingHistoryObject.isSettle,
                insertedUserId:
                  billingHistoryObject.insertedUserId === null
                    ? ""
                    : billingHistoryObject.insertedUserId,
                insertDateTime:
                  billingHistoryObject.insertDateTime === null
                    ? ""
                    : billingHistoryObject.insertDateTime,
              };

              return billingHistoryData;
            });
            dispatch(billingHistorySuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getBillingHistoryByOutletAndDate =
  ({ billingHistoryDataForPost }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          url: URLLatest + "/get-by-outlets-and-date",
          postBody: billingHistoryDataForPost,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((billingHistoryObject, index) => {
              let billingHistoryData = {
                index: index + 1,
                id:
                  billingHistoryObject.id === null ? "" : billingHistoryObject.id,

                firmName:
                  billingHistoryObject.outletFirmName === null
                    ? ""
                    : billingHistoryObject.outletFirmName,

                beatName:
                  billingHistoryObject.beatName === null
                    ? ""
                    : billingHistoryObject.beatName,
                billNo:
                  billingHistoryObject.billNo === null
                    ? ""
                    : billingHistoryObject.billNo,
                billDate:
                  billingHistoryObject.billDate === null
                    ? ""
                    : billingHistoryObject.billDate,
                taxableAmt:
                  billingHistoryObject.taxableAmt === null
                    ? ""
                    : billingHistoryObject.taxableAmt.toFixed(2),
                cgstAmt:
                  billingHistoryObject.cgstAmt === null
                    ? ""
                    : billingHistoryObject.cgstAmt.toFixed(2),
                sgstAmt:
                  billingHistoryObject.sgstAmt === null
                    ? ""
                    : billingHistoryObject.sgstAmt.toFixed(2),
                totalAmt:
                  billingHistoryObject.totalAmt === null
                    ? ""
                    : billingHistoryObject.totalAmt.toFixed(2),

                statusName:
                  billingHistoryObject.status === null
                    ? ""
                    : billingHistoryObject.status === 0
                      ? "Generated"
                      : billingHistoryObject.status === 1
                        ? "Cancelled"
                        : billingHistoryObject.status === 0 &&
                          billingHistoryObject.payoutIsSettle === 1
                          ? "Paid"
                          : "Pending",
                isInvoiceGenerated:
                  billingHistoryObject.acknoledgeNo == "0" &&
                    billingHistoryObject.invoiceJson === null
                    ? "No"
                    : "Yes",
                payoutIsSettle:
                  billingHistoryObject.payoutIsSettle === null
                    ? ""
                    : billingHistoryObject.payoutIsSettle,
                status:
                  billingHistoryObject.status === null
                    ? ""
                    : billingHistoryObject.status,
                stockStakeHolderId:
                  billingHistoryObject.stockStakeHolderId === null
                    ? ""
                    : billingHistoryObject.stockStakeHolderId,
                itemDistcountAmt:
                  billingHistoryObject.itemDistcountAmt === null
                    ? ""
                    : +billingHistoryObject.itemDistcountAmt.toFixed(2),
                discountOnBillPer:
                  billingHistoryObject.discountOnBillPer === null
                    ? ""
                    : billingHistoryObject.discountOnBillPer,
                discountOnBillAmt:
                  billingHistoryObject.discountOnBillAmt === null
                    ? ""
                    : +billingHistoryObject.discountOnBillAmt.toFixed(2),

                payoutAmt:
                  billingHistoryObject.payoutAmt === null
                    ? ""
                    : +billingHistoryObject.payoutAmt.toFixed(2),

                igstAmt:
                  billingHistoryObject.igstAmt === null
                    ? ""
                    : +billingHistoryObject.igstAmt.toFixed(2),
                cessAmt:
                  billingHistoryObject.cessAmt === null
                    ? ""
                    : +billingHistoryObject.cessAmt.toFixed(2),
                remainingAmt:
                  billingHistoryObject.remainingAmt === null
                    ? ""
                    : +billingHistoryObject.remainingAmt.toFixed(2),
                acknoledgeNo:
                  billingHistoryObject.acknoledgeNo === null
                    ? ""
                    : billingHistoryObject.acknoledgeNo,
                payoutIsSettle:
                  billingHistoryObject.payoutIsSettle === null
                    ? ""
                    : billingHistoryObject.payoutIsSettle,
                isSettle:
                  billingHistoryObject.isSettle === null
                    ? ""
                    : billingHistoryObject.isSettle,
                insertedUserId:
                  billingHistoryObject.insertedUserId === null
                    ? ""
                    : billingHistoryObject.insertedUserId,
                insertDateTime:
                  billingHistoryObject.insertDateTime === null
                    ? ""
                    : billingHistoryObject.insertDateTime,
              };

              return billingHistoryData;
            });
            dispatch(billingHistorySuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getBillingHistoryBySalesPerson =
  ({ salesTeamIdsData }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          url: URL + "/by-date-and-sales-teams",
          postBody: salesTeamIdsData,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((billingHistoryObject, index) => {
              let billCount = 0;
              let orderHistoryData = {
                index: index + 1,
                id:
                  billingHistoryObject.id === null ? "" : billingHistoryObject.id,

                firmName:
                  billingHistoryObject.outLet === null
                    ? ""
                    : billingHistoryObject.outLet.firmName,

                beatName:
                  billingHistoryObject.outLet === null
                    ? ""
                    : billingHistoryObject.outLet.beat.beatName,
                billNo:
                  billingHistoryObject.billNo === null
                    ? ""
                    : billingHistoryObject.billNo,
                billDate:
                  billingHistoryObject.billDate === null
                    ? ""
                    : billingHistoryObject.billDate,
                taxableAmt:
                  billingHistoryObject.taxableAmt === null
                    ? ""
                    : billingHistoryObject.taxableAmt.toFixed(2),
                cgstAmt:
                  billingHistoryObject.cgstAmt === null
                    ? ""
                    : billingHistoryObject.cgstAmt.toFixed(2),
                sgstAmt:
                  billingHistoryObject.sgstAmt === null
                    ? ""
                    : billingHistoryObject.sgstAmt.toFixed(2),
                totalAmt:
                  billingHistoryObject.totalAmt === null
                    ? ""
                    : billingHistoryObject.totalAmt.toFixed(2),

                statusName:
                  billingHistoryObject.status === null
                    ? ""
                    : billingHistoryObject.status === 0
                      ? "Generated"
                      : billingHistoryObject.status === 1
                        ? "Cancelled"
                        : billingHistoryObject.status === 0 &&
                          billingHistoryObject.payoutIsSettle === 1
                          ? "Paid"
                          : "Pending",
                isInvoiceGenerated:
                  billingHistoryObject.acknoledgeNo == "0" &&
                    billingHistoryObject.invoiceJson === null
                    ? "No"
                    : "Yes",
                payoutIsSettle:
                  billingHistoryObject.payoutIsSettle === null
                    ? ""
                    : billingHistoryObject.payoutIsSettle,
                status:
                  billingHistoryObject.status === null
                    ? ""
                    : billingHistoryObject.status,
                stockStakeHolderId:
                  billingHistoryObject.stockStakeHolderId === null
                    ? ""
                    : billingHistoryObject.stockStakeHolderId,
                itemDistcountAmt:
                  billingHistoryObject.itemDistcountAmt === null
                    ? ""
                    : +billingHistoryObject.itemDistcountAmt.toFixed(2),
                discountOnBillPer:
                  billingHistoryObject.discountOnBillPer === null
                    ? ""
                    : billingHistoryObject.discountOnBillPer,
                discountOnBillAmt:
                  billingHistoryObject.discountOnBillAmt === null
                    ? ""
                    : +billingHistoryObject.discountOnBillAmt.toFixed(2),

                payoutAmt:
                  billingHistoryObject.payoutAmt === null
                    ? ""
                    : +billingHistoryObject.payoutAmt.toFixed(2),

                igstAmt:
                  billingHistoryObject.igstAmt === null
                    ? ""
                    : +billingHistoryObject.igstAmt.toFixed(2),
                cessAmt:
                  billingHistoryObject.cessAmt === null
                    ? ""
                    : +billingHistoryObject.cessAmt.toFixed(2),
                remainingAmt:
                  billingHistoryObject.remainingAmt === null
                    ? ""
                    : +billingHistoryObject.remainingAmt.toFixed(2),
                acknoledgeNo:
                  billingHistoryObject.acknoledgeNo === null
                    ? ""
                    : billingHistoryObject.acknoledgeNo,
                payoutIsSettle:
                  billingHistoryObject.payoutIsSettle === null
                    ? ""
                    : billingHistoryObject.payoutIsSettle,
                isSettle:
                  billingHistoryObject.isSettle === null
                    ? ""
                    : billingHistoryObject.isSettle,
                insertedUserId:
                  billingHistoryObject.insertedUserId === null
                    ? ""
                    : billingHistoryObject.insertedUserId,
                insertDateTime:
                  billingHistoryObject.insertDateTime === null
                    ? ""
                    : billingHistoryObject.insertDateTime,
              };

              return orderHistoryData;
            });
            dispatch(billingHistorySuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getBillingHistoryBySalesPersonUpdated =
  ({ salesTeamIdsData }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          url: URL + "/get-by-date-and-sales-teams",
          postBody: salesTeamIdsData,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((billingHistoryObject, index) => {
              let billCount = 0;
              let orderHistoryData = {
                index: index + 1,
                id:
                  billingHistoryObject.id === null ? "" : billingHistoryObject.id,

                firmName:
                  billingHistoryObject.outletFirmName === null
                    ? ""
                    : billingHistoryObject.outletFirmName,

                beatName:
                  billingHistoryObject.beatName === null
                    ? ""
                    : billingHistoryObject.beatName,
                billNo:
                  billingHistoryObject.billNo === null
                    ? ""
                    : billingHistoryObject.billNo,
                billDate:
                  billingHistoryObject.billDate === null
                    ? ""
                    : billingHistoryObject.billDate,
                taxableAmt:
                  billingHistoryObject.taxableAmt === null
                    ? ""
                    : billingHistoryObject.taxableAmt.toFixed(2),
                cgstAmt:
                  billingHistoryObject.cgstAmt === null
                    ? ""
                    : billingHistoryObject.cgstAmt.toFixed(2),
                sgstAmt:
                  billingHistoryObject.sgstAmt === null
                    ? ""
                    : billingHistoryObject.sgstAmt.toFixed(2),
                totalAmt:
                  billingHistoryObject.totalAmt === null
                    ? ""
                    : billingHistoryObject.totalAmt.toFixed(2),

                statusName:
                  billingHistoryObject.status === null
                    ? ""
                    : billingHistoryObject.status === 0
                      ? "Generated"
                      : billingHistoryObject.status === 1
                        ? "Cancelled"
                        : billingHistoryObject.status === 0 &&
                          billingHistoryObject.payoutIsSettle === 1
                          ? "Paid"
                          : "Pending",
                isInvoiceGenerated:
                  billingHistoryObject.acknoledgeNo == "0" &&
                    billingHistoryObject.invoiceJson === null
                    ? "No"
                    : "Yes",
                payoutIsSettle:
                  billingHistoryObject.payoutIsSettle === null
                    ? ""
                    : billingHistoryObject.payoutIsSettle,
                status:
                  billingHistoryObject.status === null
                    ? ""
                    : billingHistoryObject.status,
                stockStakeHolderId:
                  billingHistoryObject.stockStakeHolderId === null
                    ? ""
                    : billingHistoryObject.stockStakeHolderId,
                itemDistcountAmt:
                  billingHistoryObject.itemDistcountAmt === null
                    ? ""
                    : +billingHistoryObject.itemDistcountAmt.toFixed(2),
                discountOnBillPer:
                  billingHistoryObject.discountOnBillPer === null
                    ? ""
                    : billingHistoryObject.discountOnBillPer,
                discountOnBillAmt:
                  billingHistoryObject.discountOnBillAmt === null
                    ? ""
                    : +billingHistoryObject.discountOnBillAmt.toFixed(2),

                payoutAmt:
                  billingHistoryObject.payoutAmt === null
                    ? ""
                    : +billingHistoryObject.payoutAmt.toFixed(2),

                igstAmt:
                  billingHistoryObject.igstAmt === null
                    ? ""
                    : +billingHistoryObject.igstAmt.toFixed(2),
                cessAmt:
                  billingHistoryObject.cessAmt === null
                    ? ""
                    : +billingHistoryObject.cessAmt.toFixed(2),
                remainingAmt:
                  billingHistoryObject.remainingAmt === null
                    ? ""
                    : +billingHistoryObject.remainingAmt.toFixed(2),
                acknoledgeNo:
                  billingHistoryObject.acknoledgeNo === null
                    ? ""
                    : billingHistoryObject.acknoledgeNo,
                payoutIsSettle:
                  billingHistoryObject.payoutIsSettle === null
                    ? ""
                    : billingHistoryObject.payoutIsSettle,
                isSettle:
                  billingHistoryObject.isSettle === null
                    ? ""
                    : billingHistoryObject.isSettle,
                insertedUserId:
                  billingHistoryObject.insertedUserId === null
                    ? ""
                    : billingHistoryObject.insertedUserId,
                insertDateTime:
                  billingHistoryObject.insertDateTime === null
                    ? ""
                    : billingHistoryObject.insertDateTime,
              };

              return orderHistoryData;
            });
            dispatch(billingHistorySuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getBillingHistory =
  ({ outletIds, fromDate, toDate }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL +
            "/by-outlets-and-date?outletIds=" +
            outletIds +
            "&fromDate=" +
            fromDate +
            "&toDate=" +
            toDate,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((billingHistoryObject, index) => {
              let billingHistoryData = {
                index: index + 1,
                id:
                  billingHistoryObject.id === null ? "" : billingHistoryObject.id,

                firmName:
                  billingHistoryObject.outLet === null
                    ? ""
                    : billingHistoryObject.outLet.firmName,

                beatName:
                  billingHistoryObject.outLet === null
                    ? ""
                    : billingHistoryObject.outLet.beat.beatName,
                billNo:
                  billingHistoryObject.billNo === null
                    ? ""
                    : billingHistoryObject.billNo,
                billDate:
                  billingHistoryObject.billDate === null
                    ? ""
                    : billingHistoryObject.billDate,
                taxableAmt:
                  billingHistoryObject.taxableAmt === null
                    ? ""
                    : billingHistoryObject.taxableAmt.toFixed(2),
                cgstAmt:
                  billingHistoryObject.cgstAmt === null
                    ? ""
                    : billingHistoryObject.cgstAmt.toFixed(2),
                sgstAmt:
                  billingHistoryObject.sgstAmt === null
                    ? ""
                    : billingHistoryObject.sgstAmt.toFixed(2),
                totalAmt:
                  billingHistoryObject.totalAmt === null
                    ? ""
                    : billingHistoryObject.totalAmt.toFixed(2),

                statusName:
                  billingHistoryObject.status === null
                    ? ""
                    : billingHistoryObject.status === 0
                      ? "Generated"
                      : billingHistoryObject.status === 1
                        ? "Cancelled"
                        : billingHistoryObject.status === 0 &&
                          billingHistoryObject.payoutIsSettle === 1
                          ? "Paid"
                          : "Pending",
                isInvoiceGenerated:
                  billingHistoryObject.acknoledgeNo == "0" &&
                    billingHistoryObject.invoiceJson === null
                    ? "No"
                    : "Yes",
                payoutIsSettle:
                  billingHistoryObject.payoutIsSettle === null
                    ? ""
                    : billingHistoryObject.payoutIsSettle,
                status:
                  billingHistoryObject.status === null
                    ? ""
                    : billingHistoryObject.status,
                stockStakeHolderId:
                  billingHistoryObject.stockStakeHolderId === null
                    ? ""
                    : billingHistoryObject.stockStakeHolderId,
                itemDistcountAmt:
                  billingHistoryObject.itemDistcountAmt === null
                    ? ""
                    : +billingHistoryObject.itemDistcountAmt.toFixed(2),
                discountOnBillPer:
                  billingHistoryObject.discountOnBillPer === null
                    ? ""
                    : billingHistoryObject.discountOnBillPer,
                discountOnBillAmt:
                  billingHistoryObject.discountOnBillAmt === null
                    ? ""
                    : +billingHistoryObject.discountOnBillAmt.toFixed(2),

                payoutAmt:
                  billingHistoryObject.payoutAmt === null
                    ? ""
                    : +billingHistoryObject.payoutAmt.toFixed(2),

                igstAmt:
                  billingHistoryObject.igstAmt === null
                    ? ""
                    : +billingHistoryObject.igstAmt.toFixed(2),
                cessAmt:
                  billingHistoryObject.cessAmt === null
                    ? ""
                    : +billingHistoryObject.cessAmt.toFixed(2),
                remainingAmt:
                  billingHistoryObject.remainingAmt === null
                    ? ""
                    : +billingHistoryObject.remainingAmt.toFixed(2),
                acknoledgeNo:
                  billingHistoryObject.acknoledgeNo === null
                    ? ""
                    : billingHistoryObject.acknoledgeNo,
                payoutIsSettle:
                  billingHistoryObject.payoutIsSettle === null
                    ? ""
                    : billingHistoryObject.payoutIsSettle,
                isSettle:
                  billingHistoryObject.isSettle === null
                    ? ""
                    : billingHistoryObject.isSettle,
                insertedUserId:
                  billingHistoryObject.insertedUserId === null
                    ? ""
                    : billingHistoryObject.insertedUserId,
                insertDateTime:
                  billingHistoryObject.insertDateTime === null
                    ? ""
                    : billingHistoryObject.insertDateTime,
              };

              return billingHistoryData;
            });
            dispatch(billingHistorySuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const setBillingHistory = () => async (dispatch) => {
  dispatch(billingHistorySuccess({ row: [] }));
};

export const getBillProductListById =
  ({ billHeaderId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/" + billHeaderId + "/get-by-id",
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.outletBillDetails.map(
              (billHistoryObject, index) => {
                let billHistoryData = {
                  index: index + 1,
                  id: billHistoryObject.id === null ? "" : billHistoryObject.id,
                  name:
                    billHistoryObject.product === null
                      ? ""
                      : billHistoryObject.product.name,
                  product:
                    billHistoryObject.product === null
                      ? ""
                      : billHistoryObject.product,
                  outletUom:
                    billHistoryObject.product === null
                      ? ""
                      : billHistoryObject.product.outletUom.name,
                  outlet:
                    billHistoryObject.outLet === null
                      ? ""
                      : billHistoryObject.outLet,
                  orderNo:
                    billHistoryObject.orderNo === null
                      ? ""
                      : billHistoryObject.orderNo,
                  orderDate:
                    billHistoryObject.orderDate === null
                      ? ""
                      : billHistoryObject.orderDate,
                  cgstAmt:
                    billHistoryObject.cgstAmt === null
                      ? ""
                      : billHistoryObject.cgstAmt,
                  sgstAmt:
                    billHistoryObject.sgstAmt === null
                      ? ""
                      : billHistoryObject.sgstAmt.toFixed(2),
                  taxableAmt:
                    billHistoryObject.taxableAmt === null
                      ? ""
                      : billHistoryObject.taxableAmt.toFixed(2),
                  totalAmt:
                    billHistoryObject.totalAmt === null
                      ? ""
                      : billHistoryObject.totalAmt.toFixed(2),
                  distcountAmt:
                    billHistoryObject.distcountAmt === null
                      ? ""
                      : billHistoryObject.distcountAmt,

                  igstAmt:
                    billHistoryObject.igstAmt === null
                      ? ""
                      : billHistoryObject.igstAmt.toFixed(2),

                  stockStakeHolder:
                    billHistoryObject.stockStakeHolder === null
                      ? ""
                      : billHistoryObject.stockStakeHolder,
                  type:
                    billHistoryObject.type === null ? "" : billHistoryObject.type,
                  rate:
                    billHistoryObject.rate === null
                      ? ""
                      : billHistoryObject.rate.toFixed(2),
                  billQty:
                    billHistoryObject.qty === null ? "" : billHistoryObject.qty,
                  qty:
                    billHistoryObject.qty === null ? "" : billHistoryObject.qty,
                  freeQty:
                    billHistoryObject.freeQty === null
                      ? ""
                      : billHistoryObject.freeQty,
                  itemDiscPer:
                    billHistoryObject.itemDiscPer === null
                      ? ""
                      : billHistoryObject.itemDiscPer,
                  total: (
                    +billHistoryObject.qty * +billHistoryObject.rate
                  ).toFixed(2),
                  discountAmt: 0,

                  insertedUserId:
                    billHistoryObject.insertedUserId === null
                      ? ""
                      : billHistoryObject.insertedUserId,
                  insertDateTime:
                    billHistoryObject.insertDateTime === null
                      ? ""
                      : billHistoryObject.insertDateTime,
                  isBillGenerated:
                    billHistoryObject.isBillGenerated === null
                      ? ""
                      : billHistoryObject.isBillGenerated,
                  checked: false,
                };
                return billHistoryData;
              }
            );
            dispatch(billProductListByIdSuccess({ row }));
            return { response: row, success };
          }
          return { response: {}, success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getBillHistoryByIdUpdated =
  ({ outletBillHeaderId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL1 +
            "/get-by-outlet-bill-header?outletBillHeaderId=" +
            outletBillHeaderId,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((billHistoryObject, index) => {
              let billHistoryData = {
                index: index + 1,
                id: billHistoryObject.id === null ? "" : billHistoryObject.id,
                name:
                  billHistoryObject.product === null
                    ? ""
                    : billHistoryObject.product.name,
                product:
                  billHistoryObject.product === null
                    ? ""
                    : billHistoryObject.product,
                outletUom:
                  billHistoryObject.product === null
                    ? ""
                    : billHistoryObject.product.outletUom.name,
                outlet:
                  billHistoryObject.outLet === null
                    ? ""
                    : billHistoryObject.outLet,
                orderNo:
                  billHistoryObject.orderNo === null
                    ? ""
                    : billHistoryObject.orderNo,
                orderDate:
                  billHistoryObject.orderDate === null
                    ? ""
                    : billHistoryObject.orderDate,
                cgstAmt:
                  billHistoryObject.cgstAmt === null
                    ? ""
                    : billHistoryObject.cgstAmt,
                sgstAmt:
                  billHistoryObject.sgstAmt === null
                    ? ""
                    : billHistoryObject.sgstAmt.toFixed(2),
                taxableAmt:
                  billHistoryObject.taxableAmt === null
                    ? ""
                    : billHistoryObject.taxableAmt.toFixed(2),
                totalAmt:
                  billHistoryObject.totalAmt === null
                    ? ""
                    : billHistoryObject.totalAmt.toFixed(2),
                distcountAmt:
                  billHistoryObject.distcountAmt === null
                    ? ""
                    : billHistoryObject.distcountAmt,

                igstAmt:
                  billHistoryObject.igstAmt === null
                    ? ""
                    : billHistoryObject.igstAmt.toFixed(2),

                stockStakeHolder:
                  billHistoryObject.stockStakeHolder === null
                    ? ""
                    : billHistoryObject.stockStakeHolder,
                type:
                  billHistoryObject.type === null ? "" : billHistoryObject.type,
                rate:
                  billHistoryObject.rate === null
                    ? ""
                    : billHistoryObject.rate.toFixed(2),
                billQty:
                  billHistoryObject.qty === null ? "" : billHistoryObject.qty,
                qty: billHistoryObject.qty === null ? "" : billHistoryObject.qty,
                freeQty:
                  billHistoryObject.freeQty === null
                    ? ""
                    : billHistoryObject.freeQty,
                itemDiscPer:
                  billHistoryObject.itemDiscPer === null
                    ? ""
                    : billHistoryObject.itemDiscPer,
                total: (+billHistoryObject.qty * +billHistoryObject.rate).toFixed(
                  2
                ),
                discountAmt: 0,

                insertedUserId:
                  billHistoryObject.insertedUserId === null
                    ? ""
                    : billHistoryObject.insertedUserId,
                insertDateTime:
                  billHistoryObject.insertDateTime === null
                    ? ""
                    : billHistoryObject.insertDateTime,
                isBillGenerated:
                  billHistoryObject.isBillGenerated === null
                    ? ""
                    : billHistoryObject.isBillGenerated,
                checked: false,
              };
              return billHistoryData;
            });
            dispatch(billProductListByIdSuccess({ row }));
            return { response: row, success };
          }
          return { response: {}, success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getBillHistoryById =
  ({ outletBillHeaderId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL1 +
            "/by-outlet-bill-header?outletBillHeaderId=" +
            outletBillHeaderId,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((billHeaderObject, index) => {
              let billHistoryByIdData = {
                index: index + 1,
                id: billHeaderObject.id === null ? "" : billHeaderObject.id,
                product:
                  billHeaderObject.product === null
                    ? ""
                    : billHeaderObject.product.name,
                name:
                  billHeaderObject.product === null
                    ? ""
                    : billHeaderObject.product.name,

                productId:
                  billHeaderObject.product === null
                    ? ""
                    : billHeaderObject.product.id,
                outletUom:
                  billHeaderObject.product === null
                    ? ""
                    : billHeaderObject.product.outletUom.name,
                rate:
                  billHeaderObject.rate === null
                    ? ""
                    : billHeaderObject.rate.toFixed(2),
                qty: billHeaderObject.qty === null ? "" : billHeaderObject.qty,
                freeQty:
                  billHeaderObject.freeQty === null
                    ? ""
                    : billHeaderObject.freeQty,
                itemDiscPer:
                  billHeaderObject.itemDiscPer === null
                    ? ""
                    : billHeaderObject.itemDiscPer,
                totalAmt:
                  billHeaderObject.totalAmt === null
                    ? ""
                    : billHeaderObject.totalAmt.toFixed(2),
                outletBillHeader:
                  billHeaderObject.outletBillHeader === null
                    ? ""
                    : billHeaderObject.outletBillHeader,
                billDate:
                  billHeaderObject.outletBillHeader === null
                    ? ""
                    : billHeaderObject.outletBillHeader.billDate,
                billNo:
                  billHeaderObject.outletBillHeader === null
                    ? ""
                    : billHeaderObject.outletBillHeader.billNo,

                discountOnBillAmt:
                  billHeaderObject.discountOnBillAmt === null
                    ? ""
                    : billHeaderObject.discountOnBillAmt,

                itemDiscAmt:
                  billHeaderObject.itemDiscAmt === null
                    ? ""
                    : billHeaderObject.itemDiscAmt,
                cessPer:
                  billHeaderObject.cessPer === null
                    ? ""
                    : billHeaderObject.cessPer,
                cessAmt:
                  billHeaderObject.cessAmt === null
                    ? ""
                    : billHeaderObject.cessAmt,
                taxableAmt:
                  billHeaderObject.taxableAmt === null
                    ? ""
                    : billHeaderObject.taxableAmt,
                cgstAmt:
                  billHeaderObject.cgstAmt === null
                    ? ""
                    : billHeaderObject.cgstAmt,
                sgstAmt:
                  billHeaderObject.sgstAmt === null
                    ? ""
                    : billHeaderObject.sgstAmt,
                igstAmt:
                  billHeaderObject.igstAmt === null
                    ? ""
                    : billHeaderObject.igstAmt,

                cgstPer:
                  billHeaderObject.cgstPer === null
                    ? ""
                    : billHeaderObject.cgstPer,
                sgstPer:
                  billHeaderObject.sgstPer === null
                    ? ""
                    : billHeaderObject.sgstPer,
                igstPer:
                  billHeaderObject.igstPer === null
                    ? ""
                    : billHeaderObject.igstPer,
                outletOrderDetail:
                  billHeaderObject.outletOrderDetail === null
                    ? ""
                    : billHeaderObject.outletOrderDetail,
                billDetailId:
                  billHeaderObject.billDetailId === null
                    ? ""
                    : billHeaderObject.billDetailId,
                batchCode:
                  billHeaderObject.batchCode === null
                    ? ""
                    : billHeaderObject.batchCode,
                insertedUserId:
                  billHeaderObject.insertedUserId === null
                    ? ""
                    : billHeaderObject.insertedUserId,
                insertDateTime:
                  billHeaderObject.insertDateTime === null
                    ? ""
                    : billHeaderObject.insertDateTime,
                hsnCode:
                  billHeaderObject.hsnCode === null
                    ? ""
                    : billHeaderObject.hsnCode,
                uom: billHeaderObject.uom === null ? "" : billHeaderObject.uom,
              };

              return billHistoryByIdData;
            });
            dispatch(billHistoryByIdSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };
export const setBillHistoryEditDetail =
  ({ row }) =>
    async (dispatch) => {
      const rowList = row.map((rowObject, index) => {
        const objTotal = +rowObject.qty * +rowObject.rate;
        const addObj = {
          ...rowObject,
          totalAmt:
            rowObject.itemDiscPer > 0
              ? (
                objTotal -
                (+rowObject.qty * +rowObject.rate * +rowObject.itemDiscPer) /
                100
              ).toFixed(2)
              : (+rowObject.qty * +rowObject.rate).toFixed(2),
        };
        return addObj;
      });
      dispatch(billHistoryByIdSuccess({ row: rowList }));
    };

export const setBillwiseProductList =
  ({ row }) =>
    async (dispatch) => {
      const rowList = row.map((rowObject, index) => {
        console.log(rowObject);
        const addObj = {
          ...rowObject,
          total: (+rowObject.qty * +rowObject.rate).toFixed(2),
        };
        return addObj;
      });
      console.log(rowList);
      dispatch(billProductListByIdSuccess({ row: rowList }));
    };

export const getBillFromOutlet =
  ({ outletId, fromDate, toDate }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL +
            "/by-outlet-and-date?outletId=" +
            outletId +
            "&fromDate=" +
            fromDate +
            "&toDate=" +
            toDate,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((billingHistoryObject, index) => {
              let billingHistoryData = {
                index: index + 1,
                id:
                  billingHistoryObject.id === null ? "" : billingHistoryObject.id,
                outLet:
                  billingHistoryObject.outLet === null
                    ? ""
                    : billingHistoryObject.outLet,
                firmName:
                  billingHistoryObject.outLet === null
                    ? ""
                    : billingHistoryObject.outLet.firmName,

                beatName:
                  billingHistoryObject.outLet === null
                    ? ""
                    : billingHistoryObject.outLet.beat.beatName,
                billNo:
                  billingHistoryObject.billNo === null
                    ? ""
                    : billingHistoryObject.billNo,
                name:
                  billingHistoryObject.billNo === null
                    ? ""
                    : billingHistoryObject.billNo +
                    " ( " +
                    billingHistoryObject.billDate +
                    " )",
                billDate:
                  billingHistoryObject.billDate === null
                    ? ""
                    : billingHistoryObject.billDate,
                taxableAmt:
                  billingHistoryObject.taxableAmt === null
                    ? ""
                    : billingHistoryObject.taxableAmt.toFixed(2),
                cgstAmt:
                  billingHistoryObject.cgstAmt === null
                    ? ""
                    : billingHistoryObject.cgstAmt.toFixed(2),
                sgstAmt:
                  billingHistoryObject.sgstAmt === null
                    ? ""
                    : billingHistoryObject.sgstAmt.toFixed(2),
                totalAmt:
                  billingHistoryObject.totalAmt === null
                    ? ""
                    : billingHistoryObject.totalAmt.toFixed(2),
                statusName:
                  billingHistoryObject.status === null
                    ? ""
                    : billingHistoryObject.status === 0
                      ? "Generated"
                      : billingHistoryObject.status === 1
                        ? "Cancelled"
                        : billingHistoryObject.status === 0 &&
                          billingHistoryObject.payoutIsSettle === 1
                          ? "Paid"
                          : "Pending",
                payoutIsSettle:
                  billingHistoryObject.payoutIsSettle === null
                    ? ""
                    : billingHistoryObject.payoutIsSettle,
                status:
                  billingHistoryObject.status === null
                    ? ""
                    : billingHistoryObject.status,
                stockStakeHolderId:
                  billingHistoryObject.stockStakeHolderId === null
                    ? ""
                    : billingHistoryObject.stockStakeHolderId,
                itemDistcountAmt:
                  billingHistoryObject.itemDistcountAmt === null
                    ? ""
                    : +billingHistoryObject.itemDistcountAmt.toFixed(2),
                discountOnBillPer:
                  billingHistoryObject.discountOnBillPer === null
                    ? ""
                    : billingHistoryObject.discountOnBillPer,
                discountOnBillAmt:
                  billingHistoryObject.discountOnBillAmt === null
                    ? ""
                    : +billingHistoryObject.discountOnBillAmt.toFixed(2),

                payoutAmt:
                  billingHistoryObject.payoutAmt === null
                    ? ""
                    : +billingHistoryObject.payoutAmt.toFixed(2),

                igstAmt:
                  billingHistoryObject.igstAmt === null
                    ? ""
                    : +billingHistoryObject.igstAmt.toFixed(2),
                cessAmt:
                  billingHistoryObject.cessAmt === null
                    ? ""
                    : +billingHistoryObject.cessAmt.toFixed(2),
                remainingAmt:
                  billingHistoryObject.remainingAmt === null
                    ? ""
                    : +billingHistoryObject.remainingAmt.toFixed(2),
                acknoledgeNo:
                  billingHistoryObject.acknoledgeNo === null
                    ? ""
                    : billingHistoryObject.acknoledgeNo,
                payoutIsSettle:
                  billingHistoryObject.payoutIsSettle === null
                    ? ""
                    : billingHistoryObject.payoutIsSettle,
                isSettle:
                  billingHistoryObject.isSettle === null
                    ? ""
                    : billingHistoryObject.isSettle,
                insertedUserId:
                  billingHistoryObject.insertedUserId === null
                    ? ""
                    : billingHistoryObject.insertedUserId,
                insertDateTime:
                  billingHistoryObject.insertDateTime === null
                    ? ""
                    : billingHistoryObject.insertDateTime,
                qty: 0,
                total: 0,
              };

              return billingHistoryData;
            });
            dispatch(billingByOutletSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getBillHeaderById =
  ({ billHeaderId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/" + billHeaderId,
        }).then(({ data, success }) => {
          if (success) {
            const billHeaderObject = data.data;
            let billHeaderData = {
              id: billHeaderObject.id === null ? "" : billHeaderObject.id,
              stockStakeHolderId:
                billHeaderObject.stockStakeHolderId === null
                  ? ""
                  : billHeaderObject.stockStakeHolderId,
              outLet:
                billHeaderObject.outLet === null ? "" : billHeaderObject.outLet,
              billNo:
                billHeaderObject.billNo === null ? "" : billHeaderObject.billNo,
              billDate:
                billHeaderObject.billDate === null
                  ? ""
                  : billHeaderObject.billDate,

              itemDistcountAmt:
                billHeaderObject.itemDistcountAmt === null
                  ? ""
                  : billHeaderObject.itemDistcountAmt.toFixed(2),
              discountOnBillPer:
                billHeaderObject.discountOnBillPer === null
                  ? ""
                  : billHeaderObject.discountOnBillPer,
              discountOnBillAmt:
                billHeaderObject.discountOnBillAmt === null
                  ? ""
                  : billHeaderObject.discountOnBillAmt.toFixed(2),
              payoutAmt:
                billHeaderObject.payoutAmt === null
                  ? ""
                  : billHeaderObject.payoutAmt.toFixed(2),
              taxableAmt:
                billHeaderObject.taxableAmt === null
                  ? ""
                  : billHeaderObject.taxableAmt.toFixed(2),
              cgstAmt:
                billHeaderObject.cgstAmt === null ? "" : billHeaderObject.cgstAmt,
              sgstAmt:
                billHeaderObject.sgstAmt === null ? "" : billHeaderObject.sgstAmt,
              igstAmt:
                billHeaderObject.igstAmt === null ? "" : billHeaderObject.igstAmt,
              cessAmt:
                billHeaderObject.cessAmt === null ? "" : billHeaderObject.cessAmt,
              remainingAmt:
                billHeaderObject.remainingAmt === null
                  ? ""
                  : billHeaderObject.remainingAmt,
              totalAmt:
                billHeaderObject.totalAmt === null
                  ? ""
                  : billHeaderObject.totalAmt.toFixed(2),
              status:
                billHeaderObject.status === null ? "" : billHeaderObject.status,
              acknoledgeNo:
                billHeaderObject.acknoledgeNo === null
                  ? ""
                  : billHeaderObject.acknoledgeNo,
              payoutIsSettle:
                billHeaderObject.payoutIsSettle === null
                  ? ""
                  : billHeaderObject.payoutIsSettle,
              isSettle:
                billHeaderObject.isSettle === null
                  ? ""
                  : billHeaderObject.isSettle,
              outletBillDetails:
                billHeaderObject.outletBillDetails === null
                  ? ""
                  : billHeaderObject.outletBillDetails.map(
                    (billHistoryObject, index) => {
                      let billHistoryData = {
                        index: index + 1,
                        id:
                          billHistoryObject.id === null
                            ? ""
                            : billHistoryObject.id,
                        name:
                          billHistoryObject.product === null
                            ? ""
                            : billHistoryObject.product.name,
                        product:
                          billHistoryObject.product === null
                            ? ""
                            : billHistoryObject.product,
                        outletUom:
                          billHistoryObject.product === null
                            ? ""
                            : billHistoryObject.product.outletUom.name,
                        outlet:
                          billHistoryObject.outLet === null
                            ? ""
                            : billHistoryObject.outLet,
                        orderNo:
                          billHistoryObject.orderNo === null
                            ? ""
                            : billHistoryObject.orderNo,
                        orderDate:
                          billHistoryObject.orderDate === null
                            ? ""
                            : billHistoryObject.orderDate,
                        cgstAmt:
                          billHistoryObject.cgstAmt === null
                            ? ""
                            : billHistoryObject.cgstAmt,
                        sgstAmt:
                          billHistoryObject.sgstAmt === null
                            ? ""
                            : billHistoryObject.sgstAmt.toFixed(2),
                        taxableAmt:
                          billHistoryObject.taxableAmt === null
                            ? ""
                            : billHistoryObject.taxableAmt.toFixed(2),
                        totalAmt:
                          billHistoryObject.totalAmt === null
                            ? ""
                            : billHistoryObject.totalAmt.toFixed(2),
                        distcountAmt:
                          billHistoryObject.distcountAmt === null
                            ? ""
                            : billHistoryObject.distcountAmt,

                        igstAmt:
                          billHistoryObject.igstAmt === null
                            ? ""
                            : billHistoryObject.igstAmt.toFixed(2),

                        stockStakeHolder:
                          billHistoryObject.stockStakeHolder === null
                            ? ""
                            : billHistoryObject.stockStakeHolder,
                        type:
                          billHistoryObject.type === null
                            ? ""
                            : billHistoryObject.type,
                        rate:
                          billHistoryObject.rate === null
                            ? ""
                            : billHistoryObject.rate.toFixed(2),
                        orderQty:
                          billHistoryObject.outletOrderDetail === null
                            ? ""
                            : billHistoryObject.outletOrderDetail.qty === null
                              ? ""
                              : billHistoryObject.outletOrderDetail.qty,
                        billQty:
                          billHistoryObject.qty === null
                            ? ""
                            : billHistoryObject.qty,
                        freeQty:
                          billHistoryObject.freeQty === null
                            ? ""
                            : billHistoryObject.freeQty,
                        itemDiscPer:
                          billHistoryObject.itemDiscPer === null
                            ? ""
                            : billHistoryObject.itemDiscPer,
                        total:
                          billHistoryObject.totalAmt === null
                            ? ""
                            : billHistoryObject.totalAmt.toFixed(2),
                        discountAmt: 0,
                        total:
                          billHistoryObject.totalAmt === null
                            ? ""
                            : billHistoryObject.totalAmt.toFixed(2),

                        insertedUserId:
                          billHistoryObject.insertedUserId === null
                            ? ""
                            : billHistoryObject.insertedUserId,
                        insertDateTime:
                          billHistoryObject.insertDateTime === null
                            ? ""
                            : billHistoryObject.insertDateTime,
                        isBillGenerated:
                          billHistoryObject.isBillGenerated === null
                            ? ""
                            : billHistoryObject.isBillGenerated,
                      };
                      return billHistoryData;
                    }
                  ),
            };

            dispatch(billHeaderByIdSuccess({ billHeaderData }));
            return { response: billHeaderData, success };
          }
          return { response: {}, success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };
export const getBillDataByOutletId =
  ({ outletId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/by-outlet?outletId=" + outletId,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((billHeaderObject, index) => {
              let billHistoryByIdData = {
                index: index + 1,
                id: billHeaderObject.id === null ? "" : billHeaderObject.id,
                billNo:
                  billHeaderObject.billNo === null ? "" : billHeaderObject.billNo,
                billDate:
                  billHeaderObject.billDate === null
                    ? ""
                    : billHeaderObject.billDate,
                firmName:
                  billHeaderObject.outLet === null
                    ? ""
                    : billHeaderObject.outLet.firmName,
                taxableAmt:
                  billHeaderObject.taxableAmt === null
                    ? ""
                    : billHeaderObject.taxableAmt.toFixed(2),
                cgstAmt:
                  billHeaderObject.cgstAmt === null
                    ? ""
                    : billHeaderObject.cgstAmt.toFixed(2),
                sgstAmt:
                  billHeaderObject.sgstAmt === null
                    ? ""
                    : billHeaderObject.sgstAmt.toFixed(2),
                totalAmt:
                  billHeaderObject.totalAmt === null
                    ? ""
                    : billHeaderObject.totalAmt.toFixed(2),
                remainingAmt:
                  billHeaderObject.remainingAmt === null
                    ? ""
                    : billHeaderObject.remainingAmt.toFixed(2),
              };

              return billHistoryByIdData;
            });
            dispatch(billDataSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getTopProductOrderListFromYearAndMonthBillWise =
  ({ month, year }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URLLatest +
            "/top-product-bill-value-by-month-year-assign-area?month=" +
            month +
            "&year=" +
            year,
        }).then(({ data, success }) => {
          if (success) {
            let finalTotal = 0;
            data.data.map((productObject) => {
              finalTotal = finalTotal + productObject.orderVale;
            });
            const row = data.data.map((productObject, index) => {
              let productListData = {
                index: index + 1,
                id: productObject.id === null ? "" : productObject.id,
                name: productObject.name === null ? "" : productObject.name,
                code: productObject.code === null ? "" : productObject.code,
                uomConversionValue:
                  productObject.uomConversionValue === null
                    ? ""
                    : productObject.uomConversionValue,
                orderValue:
                  productObject.orderVale === null
                    ? ""
                    : +productObject.orderVale.toFixed(0),
                qty: productObject.qty === null ? "" : productObject.qty,
                isActive:
                  productObject.isActive === null ? "" : productObject.isActive,
                sortOrder:
                  productObject.sortOrder === null ? "" : productObject.sortOrder,
                shortName:
                  productObject.shortName === null ? "" : productObject.shortName,
                weight: productObject.weight === null ? "" : productObject.weight,
                description:
                  productObject.description === null
                    ? ""
                    : productObject.description,
                selfLife:
                  productObject.selfLife === null ? "" : productObject.selfLife,
                group: productObject.group === null ? "" : productObject.group,
                category:
                  productObject.group.subCategory.category === null &&
                    productObject.group.subCategory.category.name
                    ? ""
                    : productObject.group.subCategory.category.name,
                subCategory:
                  productObject.group.subCategory === null &&
                    productObject.group.subCategory.name === null
                    ? ""
                    : productObject.group.subCategory.name,
                taxPercentage:
                  productObject.taxPercentage === null
                    ? ""
                    : productObject.taxPercentage.name,
                stakeHolderUom:
                  productObject.stakeHolderUom === null
                    ? ""
                    : productObject.stakeHolderUom.name,
                outletUom:
                  productObject.outletUom === null
                    ? ""
                    : productObject.outletUom.name,
                contribution: +(
                  (productObject.orderVale / finalTotal) *
                  100
                ).toFixed(),
              };
              return productListData;
            });
            dispatch(
              topProductListFromYearAndMonthBillwiseSuccess({
                row,
                total: finalTotal.toFixed(2),
              })
            );
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };
export const getTopOutletOrderListFromYearAndMonthByBillWise =
  ({ month, year }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URLLatest +
            "/top-outlet-bill-value-by-month-year-assign-area?month=" +
            month +
            "&year=" +
            year,
        }).then(({ data, success }) => {
          if (success) {
            let finalTotal = 0;
            data.data.map((outletObject) => {
              finalTotal = finalTotal + outletObject.orderValue;
            });
            const row = data.data.map((outletObject, index) => {
              let outletListData = {
                index: index + 1,
                id: outletObject.id === null ? "" : outletObject.id,
                firmName:
                  outletObject.firmName === null ? "" : outletObject.firmName,
                beatName:
                  outletObject.beatName === null
                    ? ""
                    : outletObject.beat.beatName,
                area:
                  outletObject.beat !== null &&
                    outletObject.beat.area !== null &&
                    outletObject.beat.area.name
                    ? outletObject.beat.area.name
                    : "",
                salesExective: outletObject.salesTeam === null ? "-" : "0",
                outLetType:
                  outletObject.outLetType === null
                    ? ""
                    : outletObject.outLetType.name,
                orderValue:
                  outletObject.orderValue === null
                    ? ""
                    : +outletObject.orderValue.toFixed(0),

                address:
                  outletObject.address === null ? "" : outletObject.address,
                state: outletObject.state === null ? "" : outletObject.state.name,
                mobileNo:
                  outletObject.mobileNo === null ? "" : outletObject.mobileNo,
                emailId:
                  outletObject.emailId === null ? "" : outletObject.emailId,
                gstNo: outletObject.gstNo === null ? "" : outletObject.gstNo,
                panNo: outletObject.panNo === null ? "" : outletObject.panNo,
                gstType:
                  outletObject.gstType === null ? "" : outletObject.gstType,
                fssiFdiNo:
                  outletObject.fssiFdiNo === null ? "" : outletObject.fssiFdiNo,
                isActive:
                  outletObject.isActive === null ? "" : outletObject.isActive,
                status: outletObject.status === null ? "" : outletObject.status,
                image: outletObject.image === null ? "" : outletObject.image,
                referenceName:
                  outletObject.referenceName === null
                    ? ""
                    : outletObject.referenceName,
                contribution: +(
                  (outletObject.orderValue / finalTotal) *
                  100
                ).toFixed(),
              };
              return outletListData;
            });
            dispatch(
              topOutletListFromYearAndMonthSuccess({
                row,
                total: finalTotal.toFixed(2),
              })
            );
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };
export const getCategorywiseBill =
  ({ year, month, regionId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL +
            "/category-wise-by-region?year=" +
            year +
            "&month=" +
            month +
            "&regionId=" +
            regionId,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((categoryWiseBillObject, index) => {
              let categoryWiseBillData = {
                index: index + 1,
                id:
                  categoryWiseBillObject.id === null
                    ? ""
                    : categoryWiseBillObject.id,
                categoryId:
                  categoryWiseBillObject.category !== null &&
                    categoryWiseBillObject.category.id !== null
                    ? categoryWiseBillObject.category.id
                    : "",
                category:
                  categoryWiseBillObject.category !== null &&
                    categoryWiseBillObject.category.name !== null
                    ? categoryWiseBillObject.category.name : "",
                totalAmt:
                  categoryWiseBillObject.totalAmt === null
                    ? ""
                    : categoryWiseBillObject.totalAmt,
              };

              return categoryWiseBillData;
            });
            dispatch(categoryWiseBillSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getCategorywiseBillNew =
  ({ month, year, regionId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL +
            "/category-wise-by-region?month=" +
            month +
            "&year=" +
            year +
            "&regionId=" +
            regionId,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((categoryWiseBillObject, index) => {
              let categoryWiseBillData = {
                index: index + 1,
                id:
                  categoryWiseBillObject.id === null
                    ? ""
                    : categoryWiseBillObject.id,
                category:
                  categoryWiseBillObject.category === null
                    ? ""
                    : categoryWiseBillObject.category.name,
                totalAmt:
                  categoryWiseBillObject.totalAmt === null
                    ? ""
                    : categoryWiseBillObject.totalAmt,
              };

              return categoryWiseBillData;
            });
            dispatch(categoryWiseBillSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getPackagingType =
  ({ year, month, regionId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL +
            "/packaging-type-wise-by-region?year=" +
            year +
            "&month=" +
            month +
            "&regionId=" +
            regionId,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((packagingTypeObject, index) => {
              let packagingTypeData = {
                index: index + 1,
                id: packagingTypeObject.id === null ? "" : packagingTypeObject.id,
                packagingTypeId:
                  packagingTypeObject.packagingType !== null &&
                    packagingTypeObject.packagingType.id !== null
                    ? packagingTypeObject.packagingType.id : "",
                packagingType:
                  packagingTypeObject.packagingType !== null &&
                    packagingTypeObject.packagingType.name !== null
                    ? packagingTypeObject.packagingType.name : "",
                totalAmt:
                  packagingTypeObject.totalAmt === null
                    ? ""
                    : packagingTypeObject.totalAmt,
              };

              return packagingTypeData;
            });
            dispatch(packagingTypeSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getPackagingTypeNew =
  ({ month, year, regionId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL +
            "/packaging-type-wise-by-region?month=" +
            month +
            "&year=" +
            year +
            "&regionId=" +
            regionId,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((packagingTypeObject, index) => {
              let packagingTypeData = {
                index: index + 1,
                id: packagingTypeObject.id === null ? "" : packagingTypeObject.id,
                packagingType:
                  packagingTypeObject.packagingType === null
                    ? ""
                    : packagingTypeObject.packagingType.name,
                totalAmt:
                  packagingTypeObject.totalAmt === null
                    ? ""
                    : packagingTypeObject.totalAmt,
              };

              return packagingTypeData;
            });
            dispatch(packagingTypeSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };
