import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import { CentralizedRateStructureColumn, CentralizedRateStructureColumnForPopup } from "../../tableColumns/table-columns";
import endpoint from "../../config/endpoints";
import { changeActive, changeActiveForRuCreationList, noInternetMsg, statusmsg } from "../../config/messageconstant";
import { CentralizedRateStructureJson } from "../../DynamicFormsJson/MastersJSON/centralizedRateStructure";
import { getCentralizedRateStructure } from "../../Slice/centralizedRateStructure.slice";
import { apiGet } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";

class CentralizedRateStructureList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    onChangeStatus = (rowData) => {
        const { showLoador, showNotification, getCentralizedRateStructure } = this.props;
        if (navigator.onLine) {
            let status = "";
            if (rowData.isActive == 0) {
                status = 1;
            } else {
                status = 0;
            }



            showLoador({ loador: true });
            apiGet({
                url: endpoint.centralRateStructureHeader +
                    "/update-status?id="
                    + rowData.id + "&status=" + status,
            }).then(({ data, success }) => {
                showLoador({ loador: false });
                if (success) {
                    showNotification({ msg: statusmsg });
                    getCentralizedRateStructure();
                }
            });


        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }


    render() {
        const { centralizedRate, getCentralizedRateStructure } = this.props;
        return (
            <>
                <DynamicMainScreen
                    formPath={CentralizedRateStructureJson.formPath}
                    openFormPathStatus={CentralizedRateStructureJson.openFormPathStatus}
                    formPathView={CentralizedRateStructureJson.formPathView}
                    screenTitle={CentralizedRateStructureJson.screenTitle}
                    fieldMeta={CentralizedRateStructureJson.fieldMeta}
                    showPdfDownload={false}
                    showExcelDownload={false}
                    changeExcelURL={CentralizedRateStructureJson.excelUrl}
                    showExtension={false}
                    filterDataExcel={true}
                    pdfFileName={CentralizedRateStructureJson.pdfFileName}
                    excelFileName={CentralizedRateStructureJson.excelFileName}
                    showAddButton={CentralizedRateStructureJson.showAddButton}
                    tableColumnsToFilter={CentralizedRateStructureJson.tableColumnsToFilter}
                    tableColumns={CentralizedRateStructureColumn}
                    tableData={centralizedRate?.centralizedRate}
                    getTableData={getCentralizedRateStructure}
                    apiBaseURL={CentralizedRateStructureJson.apiBaseURL}
                    baseIdColumn={CentralizedRateStructureJson.baseIdColumn}
                    isActiveURLFalse={true}
                    isActiveURLFalseMethod={this.onChangeStatus}
                    showDeleteIcon={false}
                    showViewButtonInTable={true}
                    showTransferButtonInTable={true}
                    autoTransfer={true}
                    showTransferTitle={"Assign Structure"}
                    showPopUpDataList={
                        [
                            {
                                dataKey: "outletCount",
                                columns: CentralizedRateStructureColumnForPopup,
                                title: "Outlets",
                                isActionColActive:true,
                                header: {
                                    dataKey: "name",
                                    label: "Structure Name",
                                },
                            }
                        ]}
                />

            </>
        );
    }
}
const mapStateToProps = (state) => ({
    centralizedRate: state.centralizedRateStruct,
});
const mapDispatchToProps = {
    getCentralizedRateStructure,
    showLoador,
    showNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(CentralizedRateStructureList);
