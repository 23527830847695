import { Checkbox, Divider, FormControlLabel, Grid, Paper, Radio, RadioGroup } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../../components/withRouter";
import endpoint from "../../config/endpoints";
import { checkBoxSelectMsg, noInternetMsg, saveFailedMsg, savemsg, saveWarningMsg, serverMsg, remarkMsg } from "../../config/messageconstant";
import { getAuditTransactionData } from "../../Slice/audit.slice";
import { apiPost } from "../../utils/api_service";
import NoDataFound from "../DashbourdChanges/NoDataFound";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { CheckBox } from "@mui/icons-material";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import { redColor, titleColor } from "../../config/ColorObj";


class QuestionsChecklist extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            DataToSet: [],
            response: [],
            questionData: {},
            value: "",
            editFlagForHideChecklistFlag: false,
            isCheckedFlag: false,
            selection: [],
            questionData: {
                auditHeadDTO: {
                    auditQuestions: []
                }
            },
            selectedOptions: {},
            formErrors: {},
            questionTransactionData: {},
            remark: "",
        };

    }
    async componentDidMount() {
        const { dataRow, showLoador, showNotification } = this.props;
        if (navigator.onLine) {

            console.log(this.props)
            if (this.props.location.search) {
                let searchParams = new URLSearchParams(location.search);
                let editFlagForHideChecklist = searchParams.get('editFlagForHideChecklist');
                if (editFlagForHideChecklist) {
                    this.setState({ editFlagForHideChecklistFlag: editFlagForHideChecklist })
                }
            }


            let distributorInquiryIdsData = {
                distributorInquiryIds: this.props.params.id.split(",")
            }

            showLoador({ loador: true });
            apiPost({
                url: endpoint.distributorInquiry + "/with-audit-head-quetion-and-questionary-by-distributor-inquiries",
                postBody: distributorInquiryIdsData,
            }).then(({ data, success }) => {
                showLoador({ loador: false });
                if (success) {
                    let auditQuestions = data.data.auditHeadDTO?.auditQuestions || [];

                    let updatedAuditQuestions = auditQuestions.map((question) => {
                        return {
                            ...question,
                            auditQuestionOption: question.auditQuestionOption.map((option) => ({
                                ...option,
                                isChecked: false,
                            })),
                        };
                    });

                    let updatedData = {
                        ...data.data,
                        auditHeadDTO: {
                            ...data.data.auditHeadDTO,
                            auditQuestions: updatedAuditQuestions,
                        },
                    };

                    let questionaryTransactionsData = [];
                    if (data.data.auditHeadDTO !== null && Object.keys(data.data.auditHeadDTO).length !== 0) {
                        questionaryTransactionsData = [
                            ...data.data.auditHeadDTO.questionaryTransactions,
                        ]
                    }

                    this.setState({ questionData: updatedData, questionTransactionData: questionaryTransactionsData });
                } else {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });

        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }



    handleChange = (optionValue, questionId) => {
        this.setState((prevState) => ({
            selectedOptions: {
                ...prevState.selectedOptions,
                [questionId]: optionValue,
            }
        }));
    };


    handleClick = (event, optionId) => {
        const { questionData } = this.state;

        this.setState({ selection: event });
        let updatedAuditQuestions = questionData.auditHeadDTO.auditQuestions.map((question) => {
            return {
                ...question,
                auditQuestionOption: question.auditQuestionOption.map((option) => {
                    if (option.id === optionId) {
                        return { ...option, isChecked: !option.isChecked };
                    }
                    return option;
                }),
            };
        });

        this.setState({
            questionData: {
                ...questionData,
                auditHeadDTO: {
                    ...questionData.auditHeadDTO,
                    auditQuestions: updatedAuditQuestions,
                },
            },
        });
    };

    onSubmitNew = () => {
        const { questionData, selection, selectedOptions, questionTransactionData, remark } = this.state;
        const { showLoador, showNotification } = this.props;
        if (navigator.onLine) {

            let questionaryTransactrionDetails = questionData.auditHeadDTO.auditQuestions.map((queObj) => {
                if (queObj.optionType == 1) {
                    const selectedOptionValue = selectedOptions[queObj.id];

                    const selectedOption = queObj.auditQuestionOption.find(
                        (optionObj) => optionObj.id == selectedOptionValue
                    );

                    const totalPointsForOptionTypeOne = selectedOption ? selectedOption.points : 0;

                    return {
                        auditQuestion: { id: queObj.id },
                        totalPoint: totalPointsForOptionTypeOne,
                        queScore: queObj.points,
                        auditQuestionOptionValue: selectedOptionValue,
                        remark: remark[queObj.id] || "",
                    };
                }

                if (queObj.optionType == 2) {
                    const selectedOptions = queObj.auditQuestionOption
                        .filter(optionObj => optionObj.isChecked);

                    const totalPointsForOptionTypetwo = selectedOptions
                        .reduce((sum, optionObj) => sum + (optionObj.points || 0), 0);

                    return {
                        auditQuestion: { id: queObj.id },
                        totalPoint: totalPointsForOptionTypetwo,
                        queScore: queObj.points,
                        auditQuestionOptionValue: selectedOptions.map(optionObj => optionObj.id).join(","),
                        remark: remark[queObj.id] || "",
                    };
                }

            }).filter(Boolean)


            const hasEmptyAnswer = questionaryTransactrionDetails.some(
                (item) => !item.auditQuestionOptionValue || item.auditQuestionOptionValue.trim() === ""
            );


            if (hasEmptyAnswer) {
                showNotification({ msg: "Please select an answer for all questions", severity: "error" });
                return;
            }


            if (this.checkValidation()) {

                let saveJsonData = {
                    auditHead: {
                        id: questionData.auditHeadDTO.id,
                    },
                    distributorInquiry: {
                        id: this.props.params.id,
                    },
                    status: 2,
                    isAuditSurvey: questionData.auditHeadDTO.isAuditSurvey,
                    questionaryTransactrionDetails: questionaryTransactrionDetails,
                };

                console.log(saveJsonData);


                swal({
                    title: "Are you sure?",
                    text: saveWarningMsg,
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                }).then((willDelete) => {
                    if (willDelete) {
                        showLoador({ loador: true });
                        apiPost({
                            url: endpoint.questionaryTransaction,
                            postBody: saveJsonData,
                        }).then(({ data, success }) => {
                            showLoador({ loador: false });
                            if (success) {
                                showNotification({ msg: savemsg });
                                window.location.replace("/request-for-distributor")
                            } else {
                                showNotification({ msg: saveFailedMsg, severity: "error" });
                            }
                        });
                    }
                });
            }

        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }

    changeHandler = (e, questionId) => {
        const { value } = e.target;
        this.setState((prevState) => ({
            remark: { ...prevState.remark, [questionId]: value },
        }));
    };

    checkValidation = () => {
        const { remark, questionData } = this.state;
        let formErrors = {};
        let formIsValid = true;

        questionData &&
            questionData.auditHeadDTO &&
            questionData.auditHeadDTO.auditQuestions &&
            questionData.auditHeadDTO.auditQuestions.length !== 0 && (
                questionData.auditHeadDTO.auditQuestions.map((questionObj) => {
                    if (questionObj.remarkMandatory == 0) {
                        if (!remark[questionObj.id] || remark[questionObj.id].trim() === "") {
                            formIsValid = false;
                            formErrors[questionObj.id] = remarkMsg;
                        }
                    }
                }));

        this.setState({ formErrors });
        return formIsValid;
    };



    render() {
        const { dataRow, open } = this.props;
        const {
            formErrors,
            remark,
            questionData,
            questionTransactionData,
            selectedOptions,
            value,
            editFlagForHideChecklistFlag
        } = this.state;
        return (
            <>
                <Paper
                    sx={{
                        p: 2,
                        borderRadius: 2,
                    }}
                    elevation={0}
                >

                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: '20px',
                            justifyContent: 'flex-start',
                        }}
                    >
                        <div
                            style={{
                                background: '#ffffff',
                                borderRadius: '10px 10px 0 0',
                                color: '#fff',
                                padding: '10px',
                                textAlign: 'center',
                            }}
                        >
                            {questionData &&
                                questionData.auditHeadDTO &&
                                questionData.auditHeadDTO.auditQuestions &&
                                questionData.auditHeadDTO.auditQuestions.length !== 0 ? (
                                questionData.auditHeadDTO.auditQuestions.map((questionObj, questionIndex) => {
                                    return (
                                        <React.Fragment key={questionIndex}>
                                            <div
                                                style={{
                                                    background: '#ffffff',
                                                    borderRadius: '5px',
                                                    padding: '10px',
                                                    marginBottom: '10px',
                                                    marginTop: '10px',
                                                }}
                                            >

                                                <div
                                                    style={{
                                                        color: '#333',
                                                        fontSize: '18px',
                                                        fontFamily: 'Poppins, sans-serif',
                                                        fontWeight: '500',
                                                        marginBottom: '8px',
                                                        justifyContent: 'flex-start',
                                                        display: 'flex',
                                                    }}
                                                >
                                                    {questionIndex + 1 + ') ' + questionObj.question}
                                                </div>

                                                <div
                                                    style={{
                                                        color: '#555',
                                                        fontSize: '18px',
                                                        fontFamily: 'Poppins, sans-serif',
                                                        fontWeight: '300',
                                                        justifyContent: 'flex-start',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        textAlign: 'left',
                                                    }}
                                                >


                                                    {editFlagForHideChecklistFlag ? (
                                                        questionTransactionData.map((tnxDataObj, index1) => {
                                                            const matchedDetails = tnxDataObj.questionaryTransactrionDetails.filter(
                                                                detailsObj => detailsObj.auditQuestion.id === questionObj.id
                                                            );

                                                            return matchedDetails.length !== 0 ? (
                                                                matchedDetails.map((detailsObj, index2) => (
                                                                    <div style={{ paddingLeft: '20px' }} key={`question-${index1}-${index2}`}>
                                                                        {detailsObj.auditQuestion.optionType == 1 ? (
                                                                            <>
                                                                                <Grid container spacing={3} alignItems="center">
                                                                                    <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                                                                                        <LabelCompo
                                                                                            className="text-black"
                                                                                            style={{ fontSize: 16, color: redColor }}
                                                                                            label="*"
                                                                                        />

                                                                                        <LabelCompo
                                                                                            className="text-black"
                                                                                            style={{ fontSize: 16, color: "#333" }}
                                                                                            label="Answer :"
                                                                                        />
                                                                                    </Grid>

                                                                                    <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                                                                                        <RadioGroup value={detailsObj.auditQuestionOptionValue || ''} row>
                                                                                            {detailsObj.auditQuestion.auditQuestionOption &&
                                                                                                detailsObj.auditQuestion.auditQuestionOption.map(
                                                                                                    (optionObj, optionIndex) => (
                                                                                                        <FormControlLabel
                                                                                                            key={optionIndex}
                                                                                                            value={optionObj.id}
                                                                                                            control={<Radio disabled={true} />}
                                                                                                            label={optionObj.optionTitle}
                                                                                                            checked={detailsObj.auditQuestionOptionValue === optionObj.id}
                                                                                                        />
                                                                                                    )
                                                                                                )}
                                                                                        </RadioGroup>
                                                                                    </Grid>
                                                                                </Grid>

                                                                                {questionObj.remark == 0 && (
                                                                                    <Grid container spacing={2} alignItems="center">
                                                                                        <Grid item>
                                                                                            {questionObj.remarkMandatory == 0 && (
                                                                                                <LabelCompo
                                                                                                    className="text-black"
                                                                                                    style={{ fontSize: 16, color: redColor }}
                                                                                                    label="*"
                                                                                                />
                                                                                            )}
                                                                                            <LabelCompo
                                                                                                className="text-black"
                                                                                                style={{ fontSize: 16, color: "#333" }}
                                                                                                label="Remark :"
                                                                                            />
                                                                                        </Grid>
                                                                                        <Grid item xs>
                                                                                            <TextFieldCompo
                                                                                                placeholder="Add Remark"
                                                                                                color="primary"
                                                                                                name="remark"
                                                                                                size="small"
                                                                                                value={detailsObj.remark}
                                                                                                fullWidth
                                                                                                disabled={true}
                                                                                                multiline
                                                                                                onChange={(e) => this.changeHandler(e, questionObj.id)}
                                                                                            />
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                )}
                                                                            </>
                                                                        ) : detailsObj.auditQuestion.optionType == 2 ? (
                                                                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                                                                                <Grid container spacing={3} alignItems="center">
                                                                                    <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                                                                                        <LabelCompo
                                                                                            className="text-black"
                                                                                            style={{ fontSize: 16, color: redColor }}
                                                                                            label="*"
                                                                                        />

                                                                                        <LabelCompo
                                                                                            className="text-black"
                                                                                            style={{ fontSize: 16, color: "#333" }}
                                                                                            label="Answer :"
                                                                                        />
                                                                                    </Grid>


                                                                                    {detailsObj.auditQuestion.auditQuestionOption &&
                                                                                        detailsObj.auditQuestion.auditQuestionOption.map((optionObj, optionIndex) => {
                                                                                            const isChecked = detailsObj.auditQuestionOptionValue.includes(optionObj.id);
                                                                                            return (
                                                                                                <Grid item style={{ display: 'flex', alignItems: 'center' }}>

                                                                                                    <div key={optionIndex} style={{ display: 'flex', alignItems: 'center' }}>
                                                                                                        <Checkbox
                                                                                                            color="primary"
                                                                                                            checked={isChecked}
                                                                                                            disabled={true}
                                                                                                            inputProps={{
                                                                                                                'aria-labelledby': `enhanced-table-checkbox-${optionIndex}`,
                                                                                                            }}
                                                                                                        />
                                                                                                        <span style={{ marginLeft: '1px' }}>{optionObj.optionTitle}</span>
                                                                                                    </div>
                                                                                                </Grid>

                                                                                            );
                                                                                        })}

                                                                                </Grid>

                                                                                {questionObj.remark == 0 && (
                                                                                    <Grid container spacing={2} alignItems="center">
                                                                                        <Grid item>
                                                                                            {questionObj.remarkMandatory == 0 && (
                                                                                                <LabelCompo
                                                                                                    className="text-black"
                                                                                                    style={{ fontSize: 16, color: redColor }}
                                                                                                    label="*"
                                                                                                />
                                                                                            )}
                                                                                            <LabelCompo
                                                                                                className="text-black"
                                                                                                style={{ fontSize: 16, color: "#333" }}
                                                                                                label="Remark :"
                                                                                            />
                                                                                        </Grid>
                                                                                        <Grid item xs>
                                                                                            <TextFieldCompo
                                                                                                placeholder="Add Remark"
                                                                                                color="primary"
                                                                                                name="remark"
                                                                                                size="small"
                                                                                                value={detailsObj.remark}
                                                                                                fullWidth
                                                                                                disabled={true}
                                                                                                multiline
                                                                                                onChange={(e) => this.changeHandler(e, questionObj.id)}
                                                                                            />
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                )}

                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <div style={{ fontSize: 17, paddingLeft: '24px', opacity: 0.8 }} key={`question-${index1}`}>
                                                                    Not Answered
                                                                </div>
                                                            );
                                                        })
                                                    ) : (
                                                        questionObj && (
                                                            <div style={{ paddingLeft: "20px" }}>
                                                                {questionObj.optionType == 1 ? (
                                                                    <>
                                                                        <Grid container spacing={3} alignItems="center">
                                                                            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                                                                                <LabelCompo
                                                                                    className="text-black"
                                                                                    style={{ fontSize: 16, color: redColor }}
                                                                                    label="*"
                                                                                />

                                                                                <LabelCompo
                                                                                    className="text-black"
                                                                                    style={{ fontSize: 16, color: "#333" }}
                                                                                    label="Answer :"
                                                                                />
                                                                            </Grid>
                                                                            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                                                                                <RadioGroup
                                                                                    value={selectedOptions[questionObj.id] || ""}
                                                                                    onChange={(event) =>
                                                                                        this.handleChange(event.target.value, questionObj.id)
                                                                                    }
                                                                                    row
                                                                                >
                                                                                    {questionObj.auditQuestionOption &&
                                                                                        questionObj.auditQuestionOption.map((optionObj, index) => (
                                                                                            <FormControlLabel
                                                                                                key={index}
                                                                                                value={optionObj.id}
                                                                                                control={<Radio />}
                                                                                                label={optionObj.optionTitle}
                                                                                                checked={selectedOptions[questionObj.id] === optionObj.id}
                                                                                            />
                                                                                        ))}
                                                                                </RadioGroup>
                                                                            </Grid>
                                                                        </Grid>

                                                                        {questionObj.remark == 0 && (
                                                                            <Grid container spacing={2} alignItems="center">
                                                                                <Grid item>
                                                                                    {questionObj.remarkMandatory == 0 && (
                                                                                        <LabelCompo
                                                                                            className="text-black"
                                                                                            style={{ fontSize: 16, color: redColor }}
                                                                                            label="*"
                                                                                        />
                                                                                    )}
                                                                                    <LabelCompo
                                                                                        className="text-black"
                                                                                        style={{ fontSize: 16, color: "#333" }}
                                                                                        label="Remark :"
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs>
                                                                                    <TextFieldCompo
                                                                                        placeholder="Add Remark"
                                                                                        color="primary"
                                                                                        name="remark"
                                                                                        size="small"
                                                                                        value={remark[questionObj.id] || ""}
                                                                                        fullWidth
                                                                                        multiline
                                                                                        onChange={(e) => this.changeHandler(e, questionObj.id)}
                                                                                        errorText={formErrors[questionObj.id] ? formErrors[questionObj.id] : ""}
                                                                                        isError={formErrors[questionObj.id] ? formErrors[questionObj.id] : ""}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        )}
                                                                    </>
                                                                ) : questionObj.optionType == 2 ? (
                                                                    <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>

                                                                        <Grid container spacing={3} alignItems="center">
                                                                            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                                                                                <LabelCompo
                                                                                    className="text-black"
                                                                                    style={{ fontSize: 16, color: redColor }}
                                                                                    label="*"
                                                                                />

                                                                                <LabelCompo
                                                                                    className="text-black"
                                                                                    style={{ fontSize: 16, color: "#333" }}
                                                                                    label="Answer :"
                                                                                />
                                                                            </Grid>

                                                                            {questionObj.auditQuestionOption.map((optionObj, index) => (
                                                                                <Grid item style={{ display: 'flex', alignItems: 'center' }}>

                                                                                    <div key={index} style={{ display: "flex", alignItems: "center" }}>

                                                                                        <Checkbox
                                                                                            color="primary"
                                                                                            onChange={(event) => this.handleClick(event, optionObj.id)}
                                                                                            checked={optionObj.isChecked ? optionObj.isChecked : false}
                                                                                            disabled={false}
                                                                                            inputProps={{
                                                                                                "aria-labelledby": `enhanced-table-checkbox-${index}`,
                                                                                            }}
                                                                                        />
                                                                                        <span style={{ marginLeft: "1px" }}>{optionObj.optionTitle}</span>

                                                                                    </div>
                                                                                </Grid>
                                                                            ))}
                                                                        </Grid>

                                                                        {questionObj.remark == 0 && (
                                                                            <Grid container spacing={2} alignItems="center">
                                                                                <Grid item>
                                                                                    {questionObj.remarkMandatory == 0 && (
                                                                                        <LabelCompo
                                                                                            className="text-black"
                                                                                            style={{ fontSize: 16, color: redColor }}
                                                                                            label="*"
                                                                                        />
                                                                                    )}
                                                                                    <LabelCompo
                                                                                        className="text-black"
                                                                                        style={{ fontSize: 16, color: "#333" }}
                                                                                        label="Remark :"
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs>
                                                                                    <TextFieldCompo
                                                                                        placeholder="Add Remark"
                                                                                        color="primary"
                                                                                        name="remark"
                                                                                        size="small"
                                                                                        value={remark[questionObj.id] || ""}
                                                                                        fullWidth
                                                                                        multiline
                                                                                        onChange={(e) => this.changeHandler(e, questionObj.id)}
                                                                                        errorText={formErrors[questionObj.id] ? formErrors[questionObj.id] : ""}
                                                                                        isError={formErrors[questionObj.id] ? formErrors[questionObj.id] : ""}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        )}
                                                                    </div>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </div>

                                            <Divider style={{ marginTop: -10 }} />
                                        </React.Fragment>
                                    );
                                })
                            ) : (
                                <NoDataFound />
                            )}
                        </div>
                    </div>


                    <>
                        <br />
                        {!editFlagForHideChecklistFlag && (
                            <>
                                <Grid
                                    item
                                    sm={12}
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    rowSpacing={2}
                                    columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                                    container
                                    justifyContent="right"
                                >
                                    <ButtonCompo
                                        size="medium"
                                        type="submit"
                                        variant="contained"
                                        name="Submit"
                                        onClick={this.onSubmitNew}
                                    />
                                </Grid>
                            </>
                        )}

                    </>
                </Paper >
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    auditList: state.audit,
});
const mapDispatchToProps = {
    showNotification,
    showLoador,
    getAuditTransactionData,
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(QuestionsChecklist);

