import endpoint from "../../config/endpoints";

export const outletJson = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.outlet,
  changeExcel: endpoint.exportData,
  searchButtonGrid: 1,
  formPath: "/outlet-form",
  screenTitle: "Outlet ",
  showAddButton: true,
  showDeleteIcon: false,
  showPdfDownload: true,
  pdfFileName: "Outlet",
  showExcelDownload: true,
  excelFileName: "Outlet",
  excelUrl: "outlet-data" + '?sort={"sortOrder": "ASC"}',

  tableColumnsToFilter: [
    {
      columnDisplayName: "Firm Name",
      columnKeyName: "firmName",
      isChecked: true,
    },
    {
      columnDisplayName: "Owner Name",
      columnKeyName: "ownerName",
      isChecked: true,
    },
    {
      columnDisplayName: "State",
      columnKeyName: "stateName",
      isChecked: true,
    },
    {
      columnDisplayName: "Area",
      columnKeyName: "areaName",
      isChecked: true,
    },
    {
      columnDisplayName: "Beat",
      columnKeyName: "beatName",
      isChecked: true,
    },
    {
      columnDisplayName: "Address",
      columnKeyName: "address",
      isChecked: true,
    },
    {
      columnDisplayName: "Latitude",
      columnKeyName: "latitude",
      isChecked: true,
    },
    {
      columnDisplayName: "Longitude",
      columnKeyName: "longitude",
      isChecked: true,
    },
    {
      columnDisplayName: "Mobile Number",
      columnKeyName: "mobileNo",
      isChecked: true,
    },
    {
      columnDisplayName: "Status",
      columnKeyName: "isActive",
      isChecked: true,
    },
    {
      columnDisplayName: "Outlet Type",
      columnKeyName: "outLetTypeName",
      isChecked: true,
    },
    {
      columnDisplayName: "Sort Order Number",
      columnKeyName: "sortOrder",
      isChecked: true,
    },
  ],

  fieldMetaToSearch: [
    {
      label: "Type",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "type",
      tableDataKey: "type",
      defaultValue: "0",
      radioGroupItems: [
        {
          label: "Regular",
          value: "0",
        },
        {
          label: "Outlet",
          value: "1",
        },
      ],
      isMandatory: true,
    },
  ],
  searchList: [
    {
      label: "Region",
      controlType: "autocomplete",
      placeHolder: "Select Region",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "regionDetail",
      dataKey: "region",
      getListId: "region",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "State",
      controlType: "autocomplete",
      placeHolder: "Select State",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "stateDetail",
      dataKey: "state",
      getListFrom: "region",
      getListId: "state",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Zone",
      controlType: "multiSelect",
      placeHolder: "Select Zone",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "zoneDetail",
      dataKey: "zone",
      getListFrom: "state",
      getListId: "zone",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "District",
      controlType: "multiSelect",
      placeHolder: "Select District",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "districtDetail",
      dataKey: "district",
      getListFrom: "zone",
      getListId: "district",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Taluka",
      controlType: "multiSelect",
      placeHolder: "Select Taluka",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "talukaDetail",
      dataKey: "taluka",
      getListFrom: "district",
      getListId: "taluka",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Area",
      controlType: "multiSelect",
      placeHolder: "Select Area",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "areaDetail",
      dataKey: "area",
      getListFrom: "taluka",
      getListId: "area",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Beat",
      controlType: "multiSelect",
      placeHolder: "Select Beat",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "beatDetail",
      dataKey: "beat",
      getListFrom: "area",
      getListId: "beat",
      isRootLevelKey: true,
      isMandatory: true,
    },
  ],

  searchListForDistributor: [
    {
      label: "Area",
      controlType: "autocomplete",
      placeHolder: "Select Area",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "areaByUserTypeDetail",
      dataKey: "area",
      getListId: "area",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Beat",
      controlType: "multiSelect",
      placeHolder: "Select Beat",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "beatDetail",
      dataKey: "beat",
      getListFrom: "area",
      getListId: "beat",
      isRootLevelKey: true,
      isMandatory: true,
    },
  ],
  fieldMeta: [
    // {
    //   label: "State",
    //   controlType: "autocomplete",
    //   placeHolder: "Select State",
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   masterName: "stateDetails",
    //   dataKey: "state",
    //   isRootLevelKey: false,
    //   isMandatory: true,
    // },
    {
      label: "Beat",
      controlType: "autocomplete",
      placeHolder: "Select Beat ",
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "beatDetail",
      dataKey: "beat",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Outlet Type",
      controlType: "autocomplete",
      placeHolder: "Select Outlet ",
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "outletTypeDetail",
      dataKey: "outLetType",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Firm Name",
      controlType: "textfield",
      placeHolder: "Firm Name",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      autoFocus: true,
      uniqueValidation: false,
      dataKey: "firmName",
      isMandatory: true,
    },
    {
      label: "Owner Name",
      controlType: "textfield",
      placeHolder: "Owner Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "ownerName",
      isMandatory: true,
    },
    {
      label: "Sort Order No",
      controlType: "textfield",
      placeHolder: "Enter Sort Order No",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "sortOrder",
      isMandatory: true,
    },
    {
      label: "Address",
      controlType: "textfield",
      placeHolder: "Enter Address",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      dataKey: "address",
      isMandatory: true,
    },
    {
      label: "Address 1",
      controlType: "textfield",
      placeHolder: "Enter Address",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      dataKey: "address1",
      isMandatory: true,
    },
    {
      label: "Address 2",
      controlType: "textfield",
      placeHolder: "Enter Address",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      dataKey: "address2",
      isMandatory: true,
    },
    {
      label: "Village/City",
      controlType: "textfield",
      placeHolder: "Enter Village/City",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "villageCity",
      isMandatory: true,
    },
    {
      label: "Mobile Number",
      controlType: "textfield",
      placeHolder: "Enter Mobile Number",
      inputType: "number",
      maxLength: 10,
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "mobileNo",
      valid: true,
      validName: "phoneNo",
      isMandatory: true,
    },
    {
      label: "GST Type",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      disable: true,
      dataKey: "gstType",
      tableDataKey: "gstType",
      defaultValue: "1",
      radioGroupItems: [
        {
          label: "Non Register",
          value: "1",
        },
        {
          label: "Composite ",
          value: "2",
        },
        {
          label: "Regular",
          value: "3",
        },
      ],
      isMandatory: true,
    },
    {
      label: "GST Number",
      controlType: "hideTextfieldNotEqual",
      placeHolder: "22AAAAA0000A1Z5",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "gstNo",
      tableDataKey: "gstType",
      openDataKey: "gstType",
      open: "1",
      valid: true,
      validName: "gstNo",
      error: "GST Number",
      saveDataKey: true,
      setTextHide: true,
      maxLength: 15,
    },

    {
      label: "PAN Number ",
      controlType: "textfield",
      placeHolder: "ABCTY1234D",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "panNo",
      isMandatory: false,
      // valid: true,
      // validName: "panNo",
    },
    {
      label: "FSSAI Number",
      controlType: "textfield",
      placeHolder: "Enter FSSAI Number",
      inputType: "number",
      maxLength: 14,
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fssiFdiNo",
      isMandatory: false,
    },
    {
      label: "Email Id ",
      controlType: "textfield",
      placeHolder: "Enter Email Id ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "emailId",
      isMandatory: false,
      // valid: true,
      // validName: "email",
    },
    {
      label: "Select Image",
      controlType: "selectImage",
      placeHolder: "Select Image",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKeyToSave: "image",
      dataKey: "productImageShow",
      isMandatory: false,
    },
    {
      label: "Reference Name",
      controlType: "textfield",
      placeHolder: "Enter Reference Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "billName",
      isMandatory: true,
    },
  ],

  fieldMetaForAdditionalRateApplicable: [

    {
      label: "Beat",
      controlType: "autocomplete",
      placeHolder: "Select Beat ",
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "beatDetail",
      dataKey: "beat",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Outlet Type",
      controlType: "autocomplete",
      placeHolder: "Select Outlet ",
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "outletTypeDetail",
      dataKey: "outLetType",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Firm Name",
      controlType: "textfield",
      placeHolder: "Enter Firm Name",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      autoFocus: true,
      uniqueValidation: false,
      dataKey: "firmName",
      isMandatory: true,
    },
    {
      label: "Owner Name",
      controlType: "textfield",
      placeHolder: "Enter Owner Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "ownerName",
      isMandatory: true,
    },
    {
      label: "Sort Order No",
      controlType: "textfield",
      placeHolder: "Enter Sort Order Number",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "sortOrder",
      isMandatory: true,
    },
    {
      label: "Address",
      controlType: "textfield",
      placeHolder: "Enter Address",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      dataKey: "address",
      isMandatory: true,
    },
    {
      label: "Address 1",
      controlType: "textfield",
      placeHolder: "Enter Address 1",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      dataKey: "address1",
      isMandatory: true,
    },
    {
      label: "Address 2",
      controlType: "textfield",
      placeHolder: "Enter Address 2",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      dataKey: "address2",
      isMandatory: true,
    },
    {
      label: "Village/City",
      controlType: "textfield",
      placeHolder: "Enter Village/City",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "villageCity",
      isMandatory: true,
    },
    {
      label: "Mobile Number",
      controlType: "textfield",
      placeHolder: "Enter Mobile Number",
      inputType: "number",
      maxLength: 10,
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "mobileNo",
      valid: true,
      validName: "phoneNo",
      isMandatory: true,
    },
    {
      label: "GST Type",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      disable: true,
      dataKey: "gstType",
      tableDataKey: "gstType",
      defaultValue: "1",
      radioGroupItems: [
        {
          label: "Non Register",
          value: "1",
        },
        {
          label: "Composite ",
          value: "2",
        },
        {
          label: "Regular",
          value: "3",
        },
      ],
      isMandatory: true,
    },
    {
      label: "GST Number",
      controlType: "hideTextfieldNotEqual",
      placeHolder: "22AAAAA0000A1Z5",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "gstNo",
      tableDataKey: "gstType",
      openDataKey: "gstType",
      open: "1",
      valid: true,
      validName: "gstNo",
      error: "GST Number",
      saveDataKey: true,
      setTextHide: true,
      maxLength: 15,
    },

    {
      label: "PAN Number ",
      controlType: "textfield",
      placeHolder: "ABCTY1234D",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "panNo",
      isMandatory: false,
      // valid: true,
      // validName: "panNo",
    },
    {
      label: "FSSAI Number",
      controlType: "textfield",
      placeHolder: "Enter FSSAI Number",
      inputType: "number",
      maxLength: 14,
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fssiFdiNo",
      isMandatory: false,
    },
    {
      label: "Email Id ",
      controlType: "textfield",
      placeHolder: "Enter Email Id ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "emailId",
      isMandatory: false,
      // valid: true,
      // validName: "email",
    },
    {
      label: "Select Image",
      controlType: "selectImage",
      placeHolder: "Select Image",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKeyToSave: "image",
      dataKey: "productImageShow",
      isMandatory: false,
    },
    {
      label: "Is Additional Rate Applicable",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 12,
      xs: 12,
      labelSM: 4,
      labelMD: 4,
      labelLG: 4,
      valueSM: 10,
      valueMD: 8,
      valueLG: 8,
      dataKey: "additionalRateApplicable",
      tableDataKey: "additionalRateApplicable",
      defaultValue: "0",
      radioGroupItems: [
        {
          label: "No",
          value: "0",
        },
        {
          label: "Yes",
          value: "1",
        }
      ],
      isMandatory: true,
    },
    {
      label: "Reference Name",
      controlType: "textfield",
      placeHolder: "Enter Reference Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "billName",
      isMandatory: true,
    },


  ],


  fieldMetaOfDistributorMappedAndSubChanel: [

    {
      label: "Beat",
      controlType: "autocomplete",
      placeHolder: "Select Beat ",
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "beatDetail",
      dataKey: "beat",
      isRootLevelKey: false,
      isMandatory: true,
      getListId: "beat",
    },
    {
      label: "Stock Holder",
      controlType: "autocomplete",
      placeHolder: "Select Stock Holder ",
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stockHolderDetail",
      dataKey: "stockStakeHolderId",
      isRootLevelKey: true,
      isMandatory: true,
      getListFrom: "beat",
    },
    {
      label: "Outlet Type",
      controlType: "autocomplete",
      placeHolder: "Select Outlet ",
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "outletTypeDetail",
      dataKey: "outLetType",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Channel",
      controlType: "autocomplete",
      placeHolder: "Select Channel ",
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "channelDetail",
      dataKey: "channelId",
      getListId: "channelId",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Sub Channel",
      controlType: "autocomplete",
      placeHolder: "Select Sub Channel ",
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "subChannelDetail",
      dataKey: "subChannelId",
      getListFrom: "channelId",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Firm Name",
      controlType: "textfield",
      placeHolder: "Firm Name",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      autoFocus: true,
      uniqueValidation: false,
      dataKey: "firmName",
      isMandatory: true,
    },
    {
      label: "Owner Name",
      controlType: "textfield",
      placeHolder: "Owner Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "ownerName",
      isMandatory: true,
    },
    {
      label: "Sort Order No",
      controlType: "textfield",
      placeHolder: "Enter Sort Order No",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "sortOrder",
      isMandatory: true,
    },
    {
      label: "Address",
      controlType: "textfield",
      placeHolder: "Enter Address",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      dataKey: "address",
      isMandatory: true,
    },
    {
      label: "Address 1",
      controlType: "textfield",
      placeHolder: "Enter Address",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      dataKey: "address1",
      isMandatory: true,
    },
    {
      label: "Address 2",
      controlType: "textfield",
      placeHolder: "Enter Address",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      dataKey: "address2",
      isMandatory: true,
    },
    {
      label: "Village/City",
      controlType: "textfield",
      placeHolder: "Enter Village/City",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "villageCity",
      isMandatory: true,
    },
    {
      label: "Mobile Number",
      controlType: "textfield",
      placeHolder: "Enter Mobile Number",
      inputType: "number",
      maxLength: 10,
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "mobileNo",
      valid: true,
      validName: "phoneNo",
      isMandatory: true,
    },
    {
      label: "GST Type",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      disable: true,
      dataKey: "gstType",
      tableDataKey: "gstType",
      defaultValue: "1",
      radioGroupItems: [
        {
          label: "Non Register",
          value: "1",
        },
        {
          label: "Composite ",
          value: "2",
        },
        {
          label: "Regular",
          value: "3",
        },
      ],
      isMandatory: true,
    },
    {
      label: "GST Number",
      controlType: "hideTextfieldNotEqual",
      placeHolder: "22AAAAA0000A1Z5",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "gstNo",
      tableDataKey: "gstType",
      openDataKey: "gstType",
      open: "1",
      valid: true,
      validName: "gstNo",
      error: "GST Number",
      saveDataKey: true,
      setTextHide: true,
      maxLength: 15,
    },

    {
      label: "PAN Number ",
      controlType: "textfield",
      placeHolder: "ABCTY1234D",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "panNo",
      isMandatory: false,
      // valid: true,
      // validName: "panNo",
    },
    {
      label: "FSSAI Number",
      controlType: "textfield",
      placeHolder: "Enter FSSAI Number",
      inputType: "number",
      maxLength: 14,
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fssiFdiNo",
      isMandatory: false,
    },
    {
      label: "Email Id ",
      controlType: "textfield",
      placeHolder: "Enter Email Id ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "emailId",
      isMandatory: false,
      // valid: true,
      // validName: "email",
    },
    {
      label: "Select Image",
      controlType: "selectImage",
      placeHolder: "Select Image",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKeyToSave: "image",
      dataKey: "productImageShow",
      isMandatory: false,
    },
    {
      label: "Reference Name",
      controlType: "textfield",
      placeHolder: "Enter Reference Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "billName",
      isMandatory: true,
    },
  ],

  fieldMetaOfDistributorMappedAndSubChannelWithAdditionalRate: [

    {
      label: "Beat",
      controlType: "autocomplete",
      placeHolder: "Select Beat ",
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "beatDetail",
      dataKey: "beat",
      getListId: "beat",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Stock Holder",
      controlType: "autocomplete",
      placeHolder: "Select Stock Holder ",
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stockHolderDetail",
      dataKey: "stockStakeHolderId",
      isRootLevelKey: true,
      isMandatory: true,
      getListFrom: "beat",
    },
    {
      label: "Outlet Type",
      controlType: "autocomplete",
      placeHolder: "Select Outlet ",
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "outletTypeDetail",
      dataKey: "outLetType",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Channel",
      controlType: "autocomplete",
      placeHolder: "Select Channel ",
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "channelDetail",
      dataKey: "channelId",
      getListId: "channelId",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Sub Channel",
      controlType: "autocomplete",
      placeHolder: "Select Sub Channel ",
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "subChannelDetail",
      dataKey: "subChannelId",
      getListFrom: "channelId",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Firm Name",
      controlType: "textfield",
      placeHolder: "Enter Firm Name",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      autoFocus: true,
      uniqueValidation: false,
      dataKey: "firmName",
      isMandatory: true,
    },
    {
      label: "Owner Name",
      controlType: "textfield",
      placeHolder: "Enter Owner Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "ownerName",
      isMandatory: true,
    },
    {
      label: "Sort Order No",
      controlType: "textfield",
      placeHolder: "Enter Sort Order Number",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "sortOrder",
      isMandatory: true,
    },
    {
      label: "Address",
      controlType: "textfield",
      placeHolder: "Enter Address",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      dataKey: "address",
      isMandatory: true,
    },
    {
      label: "Address 1",
      controlType: "textfield",
      placeHolder: "Enter Address 1",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      dataKey: "address1",
      isMandatory: true,
    },
    {
      label: "Address 2",
      controlType: "textfield",
      placeHolder: "Enter Address 2",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      dataKey: "address2",
      isMandatory: true,
    },
    {
      label: "Village/City",
      controlType: "textfield",
      placeHolder: "Enter Village/City",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "villageCity",
      isMandatory: true,
    },
    {
      label: "Mobile Number",
      controlType: "textfield",
      placeHolder: "Enter Mobile Number",
      inputType: "number",
      maxLength: 10,
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "mobileNo",
      valid: true,
      validName: "phoneNo",
      isMandatory: true,
    },
    {
      label: "GST Type",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      disable: true,
      dataKey: "gstType",
      tableDataKey: "gstType",
      defaultValue: "1",
      radioGroupItems: [
        {
          label: "Non Register",
          value: "1",
        },
        {
          label: "Composite ",
          value: "2",
        },
        {
          label: "Regular",
          value: "3",
        },
      ],
      isMandatory: true,
    },
    {
      label: "GST Number",
      controlType: "hideTextfieldNotEqual",
      placeHolder: "22AAAAA0000A1Z5",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "gstNo",
      tableDataKey: "gstType",
      openDataKey: "gstType",
      open: "1",
      valid: true,
      validName: "gstNo",
      error: "GST Number",
      saveDataKey: true,
      setTextHide: true,
      maxLength: 15,
    },

    {
      label: "PAN Number ",
      controlType: "textfield",
      placeHolder: "ABCTY1234D",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "panNo",
      isMandatory: false,
      // valid: true,
      // validName: "panNo",
    },
    {
      label: "FSSAI Number",
      controlType: "textfield",
      placeHolder: "Enter FSSAI Number",
      inputType: "number",
      maxLength: 14,
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fssiFdiNo",
      isMandatory: false,
    },
    {
      label: "Email Id ",
      controlType: "textfield",
      placeHolder: "Enter Email Id ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "emailId",
      isMandatory: false,
      // valid: true,
      // validName: "email",
    },
    {
      label: "Select Image",
      controlType: "selectImage",
      placeHolder: "Select Image",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKeyToSave: "image",
      dataKey: "productImageShow",
      isMandatory: false,
    },
    {
      label: "Is Additional Rate Applicable",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 12,
      xs: 12,
      labelSM: 4,
      labelMD: 4,
      labelLG: 4,
      valueSM: 10,
      valueMD: 8,
      valueLG: 8,
      dataKey: "additionalRateApplicable",
      tableDataKey: "additionalRateApplicable",
      defaultValue: "0",
      radioGroupItems: [
        {
          label: "No",
          value: "0",
        },
        {
          label: "Yes",
          value: "1",
        }
      ],
      isMandatory: true,
    },
    {
      label: "Reference Name",
      controlType: "textfield",
      placeHolder: "Enter Reference Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "billName",
      isMandatory: true,
    },


  ],
};
