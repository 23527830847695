import {
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import dayjs from "dayjs";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import "../../CSS/app.min.css";
import "../../CSS/bootstrap.min.css";
import "../../CSS/custom.min.css";
import "../../CSS/mermaid.min.css";
import "../../CSS/slickDashboard.css";
import { onToggle } from "../../CommonActions/common.slice";
import image4 from "../../Images/image4.png";
import image5 from "../../Images/image5.png";
import image6 from "../../Images/image6.png";
import image7BgClr from "../../Images/image7BgClr.png";
import target1 from "../../Images/target1.png";
import {
  showLoador,
  showNotification,
} from "../../Pages/Landing/Landing.slice";
import {
  getMonthYearTarget,
  getMonthYearTargetForSales,
  getRegionListFromYearAndMonth,
  getSalesGraph,
  getSalesListFromYearAndMonth,
  getSalesTeamInfoByDatesNew,
  getZoneListFromYearAndMonth,
  getZoneStatewiseListFromYearAndMonth,
  getDistrictListFromYearAndMonth,
  setRegionListFromYearAndMonth,
  getTalukaListFromYearAndMonth,
} from "../../Slice/adminDashboard.slice";
import { getBeatFromSalesTeamId, getSelectedBeatCountFromSalesTeamIdAndDate } from "../../Slice/beat.slice";
import {
  getCategorywiseBillNew,
  getPackagingTypeNew,
} from "../../Slice/billingHistory.slice";
import {
  getAdminDashboard,
  getTopProductOrderListFromYearAndMonthBillWise,
} from "../../Slice/dashboard.slice";
import { getStockStakeHolderType } from "../../Slice/stockStakeHolderType.slice";
import {
  getCategoryByPackagingType,
  getSubCategoryPackagingType,
} from "../../Slice/subCategory.slice";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
import { ConvertAmount } from "../../components/Comman/ConvertValueWithDenominator";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../components/Comman/Label";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import MonthCalender from "../../components/MonthCalender";
import SearchBar from "../../components/Search";
import { cyanBlue, redColor, titleColor } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import * as myConstClass from "../../config/messageconstant";
import { serverMsg } from "../../config/messageconstant";
import { apiGet } from "../../utils/api_service";
import BeatPopUp from "../DashbourdChanges/BeatPopUp";
import MonthlyBarChart from "../DashbourdChanges/MonthlyBarChart";
import NoDataFound from "../DashbourdChanges/NoDataFound";
import PieChart from "../DashbourdChanges/PieChart";
import { downloadF } from "../PDF/PDFDownload";
import CategoryPopup from "./CategoryPopup";
import HiearchyDropDown from "./HiearchyDropDown";
import HierarchyDropDownOld from "./HierarchyDropDownOld";
import SubCategoryPackagingTypePopup from "./SubCategoryPackagingTypePopup";
import { getUserWorkSummary } from "../../Slice/managerLogs.slice";
import WorkNotStartedCountPopUp from "../DashbourdChanges/WorkNotStartedCountPopUp";
import { getAttendanceTargetAchievmentForRegion } from "../../Slice/target.slice";
import HierarchyForPackagingType from "./HierarchyForPackagingType";

class NewAdminDashboard extends Component {
  toggleDrawer = () => {
    this.props.onToggle();
  };
  state = {
    openFlag: false,
    showFeedback: false,
    showPreview: false,
    date1: new Date(),
    newDate: "",
    currentDate: new Date(),
    curDate: new Date(),
    dob: "",
    dobDay: "",
    dobMonth: "",
    currenDate: "",
    currentMonth: "",
    categoryWiseBillFlag: false,
    packagingTypeFlag: false,
    timeTableList: [],
    subject: "",
    batch: "",
    yearDetail: "",
    semester: "",
    division: "",
    pieChartData: [],
    isLoading: false,
    showLoader: false,
    profile: "",
    nameFirstLetter: "",
    showZone: false,
    showArea: false,
    popUpFlag: false,
    popUpFlagCategory: false,
    beatPopupFlag: false,
    workNotStartedFlag: false,
    workNotStartedCountList: [],
    date: "",
    saleDate: "",
    productArea: "",
    productZone: "",
    outletArea: "",
    outletZone: "",
    outletDate: "",
    monthDate: "",
    yearDate: "",
    type: "",
    companyFlagState: "",
    saleMonth: dayjs(),
    saleYear: "",
    month: "",
    year: "",
    searchValue: "",
    searchValueForZone: "",
    searchValueForSalesRegion: "",
    searchValueForZoneDetails: "",
    searchValueForAreaDetails: "",
    searchValuePersonWiseSales: "",
    searchValueStateList: "",
    searchValueForProduct: "",
    searchValueForSalesTeamList: "",
    searchValueForOutlets: "",
    regionId: "",
    regionListFromYearAndMonthData: [],
    regionListFromYearAndMonthDataForZone: [],
    regionListFromYearAndMonthDataForSalesRegion: [],
    stateListFromYearAndMonth: [],
    zoneDetailsSalesData: [],
    stateDetailsData: [],
    zoneDetailsRegionwiseData: [],
    districtDetailsZoneWiseData: [],
    districtDetailsData: [],
    talukaDetailsData: [],
    areaDetailsListData: [],
    salesGraphData: [],
    salesMonthData: [],
    areaDetailsData: [],
    productList: [],
    topPerformingOutletData: [],
    salesTeamData: [],
    formErrors: {},
    graphList: [],
    radioValue: [],
    lineGraphData: [],
    productList: [],
    salesRegionName: "",
    totalOutletCount: 0,
    zero: 0,
    productiveCall: 0,
    unvisited: 0,
    totalOrderCall: 0,
    totalAmount: 0,
    salesAmt: 0,
    remoteOrder: 0,
    salesListFromYearAndMonthOnSearch: false,
    fromDate: dayjs(),
    toDate: dayjs(),
    productDataOnSearchFlag: false,
    workNotStartedCount: "",
    showAttendanceTargetAchievement: "",
    showPackagingTypeAmounts: "",
    monthList: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    columns: [
      { id: "name", label: "Name", minWidth: 170 },
      { id: "code", label: "ISO\u00a0Code", minWidth: 100 },
      {
        id: "population",
        label: "Population",
        minWidth: 170,
        align: "right",
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "size",
        label: "Size\u00a0(km\u00b2)",
        minWidth: 170,
        align: "right",
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "density",
        label: "Density",
        minWidth: 170,
        align: "right",
        format: (value) => value.toFixed(2),
      },
    ],
    rows: [
      this.createData("India", "IN", 1324171354, 3287263),
      this.createData("China", "CN", 1403500365, 9596961),
      this.createData("Italy", "IT", 60483973, 301340),
      this.createData("United States", "US", 327167434, 9833520),
      this.createData("Canada", "CA", 37602103, 9984670),
      this.createData("Australia", "AU", 25475400, 7692024),
      this.createData("Germany", "DE", 83019200, 357578),
      this.createData("Ireland", "IE", 4857000, 70273),
      this.createData("Mexico", "MX", 126577691, 1972570),
      this.createData("Japan", "JP", 126317000, 377973),
      this.createData("France", "FR", 67022000, 640679),
      this.createData("United Kingdom", "GB", 67545757, 242495),
      this.createData("Russia", "RU", 146793744, 17098246),
      this.createData("Nigeria", "NG", 200962417, 923768),
      this.createData("Brazil", "BR", 210147125, 8515767),
    ],
    rowsPerPage: 10,
    page: 0,
  };

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: +event.target.value,
      page: 0,
    });
  };
  createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
  }

  async componentDidMount() {
    const {
      getAdminDashboard,
      showLoador,
      showNotification,
      getCategorywiseBillNew,
      getPackagingTypeNew,
      getStockStakeHolderType,
      getAttendanceTargetAchievmentForRegion,
      getSalesListFromYearAndMonth,
      getZoneListFromYearAndMonth,
      getZoneStatewiseListFromYearAndMonth,
      getDistrictListFromYearAndMonth,
      getMonthYearTarget,
      getSalesGraph,
      getMonthYearTargetForSales,
      getSalesTeamInfoByDatesNew,
      dashboardData,
      getTalukaListFromYearAndMonth,
      getUserWorkSummary,
    } = this.props;
    const { year, month, regionId } = this.state;

    if (navigator.onLine) {
      const showAttendanceTargetAchievementState = localStorage.getItem(
        "showAttendanceTargetAchievement"
      );

      const showPackagingTypeAmountsState = localStorage.getItem(
        "showPackagingTypeAmounts"
      );

      this.setState({
        showAttendanceTargetAchievement: showAttendanceTargetAchievementState,
        showPackagingTypeAmounts: showPackagingTypeAmountsState,
      });

      const adminDashboard = localStorage.getItem("adminDashboard");
      const adminDashboardData = JSON.parse(adminDashboard);

      const stockStakeHolderTypeListData = localStorage.getItem(
        "stockStakeHolderTypeListData"
      );
      const stockStakeHolderTypeListLocalStorage = JSON.parse(
        stockStakeHolderTypeListData
      );

      const regionListFromYearAndMonth = localStorage.getItem(
        "regionListFromYearAndMonth"
      );
      const regionListFromYearAndMonthLocalStorage = JSON.parse(
        regionListFromYearAndMonth
      );

      const categoryWiseBillData = localStorage.getItem("categoryWiseBillData");
      const categoryWiseBillDataLocalStorage = JSON.parse(categoryWiseBillData);

      const packagingTypeData = localStorage.getItem("packagingTypeData");
      const packagingTypeDataLocalStorage = JSON.parse(packagingTypeData);

      const monthYearTargetData = localStorage.getItem("monthYearTargetData");
      const monthYearTargetDataLocalStorage = JSON.parse(monthYearTargetData);


      const savedSearchValue = localStorage.getItem("searchValueForSalesTeamList");

      if (savedSearchValue !== null) {

        this.setState({ searchValueForSalesTeamList: savedSearchValue });

      }


      if (!adminDashboardData) {
        showLoador({ loador: true });
        await getAdminDashboard().then(({ data, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            if (data !== null && Object.keys(data).length !== 0) {
              localStorage.setItem("adminDashboard", JSON.stringify(data));
            } else {
              localStorage.setItem("adminDashboard", JSON.stringify([]));
            }
          }
        });
      }

      if (!stockStakeHolderTypeListLocalStorage) {
        showLoador({ loador: true });
        getStockStakeHolderType({ userTypeIds: "4" }).then(
          ({ response, success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            } else {
              const stockStakeHolderTypeList =
                response.length !== 0 ? response : [];
              localStorage.setItem(
                "stockStakeHolderTypeListData",
                JSON.stringify(stockStakeHolderTypeList)
              );
              localStorage.setItem(
                "stockStakeHolderTypeId",
                response.length !== 0 ? response[0].id : ""
              );

              this.setState({
                type: response.length !== 0 ? response[0].id : "",
              });
            }
          }
        );
      }

      if (!monthYearTargetDataLocalStorage) {
        showLoador({ loador: true });
        getMonthYearTarget().then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            if (response.length !== 0) {
              let totalTargetNewList = [];
              let totalAchievedNewList = [];
              for (var i = 0; i < response.length; i++) {
                totalAchievedNewList.push(+response[i].achivedAmt);
                totalTargetNewList.push(+response[i].targetAmt);
              }

              let radioValue = [
                {
                  name: "Target",
                  data: totalTargetNewList,
                },
                {
                  name: "Achieved",
                  data: totalAchievedNewList,
                },
              ];

              const monthYearTargetList =
                radioValue.length !== 0 ? radioValue : [];
              localStorage.setItem(
                "monthYearTargetData",
                JSON.stringify(monthYearTargetList)
              );

              this.setState({
                radioValue: [
                  {
                    name: "Target",
                    data: totalTargetNewList,
                  },
                  {
                    name: "Achieved",
                    data: totalAchievedNewList,
                  },
                ],
              });
            }
          }
        });
      }

      const showCompanyFlag = localStorage.getItem("showCompanyFlag");
      this.setState({ companyFlagState: showCompanyFlag });

      showLoador({ loador: true });
      await getUserWorkSummary({ date: dayjs().format("DD-MM-YYYY") }).then(
        ({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            if (response.length !== 0) {
              response.map((dataObj) => {
                if (dataObj.id == 5) {
                  this.setState({ workNotStartedCount: dataObj.count });
                }
              });
            }
          }
        }
      );
    } else {
      showNotification({ msg: myConstClass.noInternetMsg, severity: "error" });
    }
  }

  handleClosesSalesTeam = () => {
    this.setState({
      beatPopupFlag: false,
    });
  };

  handleClosesWorkNotStarted = () => {
    this.setState({ workNotStartedFlag: false });
  };

  salesTeamHandler = (e, salesTeamId) => {
    const { getSelectedBeatCountFromSalesTeamIdAndDate, getBeatFromSalesTeamId, showLoador, showNotification } = this.props;
    const { companyFlagState, popUpFlag, fromDate, toDate } = this.state;

    if (navigator.onLine) {

      if (companyFlagState == 2) {

        let fromDateValidate = dayjs(fromDate).format("DD-MM-YYYY");
        let toDateValidate = dayjs(toDate).format("DD-MM-YYYY");

        showLoador({ loador: true });
        getSelectedBeatCountFromSalesTeamIdAndDate({
          salesTeamId: salesTeamId,
          fromDate: fromDateValidate,
          toDate: toDateValidate,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            this.setState({
              beatPopupFlag: true,
            });
          }
        });

      } else {

        showLoador({ loador: true });
        getBeatFromSalesTeamId({
          salesTeamIds: salesTeamId,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            this.setState({
              beatPopupFlag: true,
            });
          }
        });
      }

    } else {
      showNotification({ msg: myConstClass.noInternetMsg, severity: "error" });
    }
  };
  regionWiseSalesHandler = (e, regionId) => {
    const {
      getZoneListFromYearAndMonth,
      showLoador,
      showNotification,
      getSalesGraph,
    } = this.props;
    const { month, year, saleMonth } = this.state;
    let graphData = [];
    if (navigator.onLine) {
      showLoador({ loador: true });
      getZoneListFromYearAndMonth({
        month: month !== "" ? month : saleMonth.format("MM"),
        year: year !== "" ? year : saleMonth.format("YYYY"),
        regionId: regionId,
      }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.setState({
            showZone: true,
            showArea: false,
            zoneDetailsSalesData: response.length !== 0 ? response : [],
            salesRegionName:
              response.length !== 0 ? response[0].region.name : "",
          });
        }
      });
      showLoador({ loador: true });
      getSalesGraph({
        month: month !== "" ? month : saleMonth.format("MM"),
        year: year !== "" ? year : saleMonth.format("YYYY"),
        regionId: regionId,
      }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          let graphData = [];
          let monthData = [];
          response.length !== 0
            ? response.map((responseObject) => {
              graphData.push(responseObject.achivedAmt);
              monthData.push(responseObject.monthString);
            })
            : [];

          this.setState({
            salesGraphData: graphData,
            salesMonthData: monthData,
          });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  viewProfile = () => {
    window.location.replace("/empPreview");
  };
  GoBack = () => {
    this.setState({
      showPreview: false,
    });
  };
  openFeedBack = (e) => {
    e.preventDefault();
    this.setState({
      showFeedback: true,
    });
  };

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };
  topProductHandler = (e, productId) => {
    const { saleMonth } = this.state;
    const { showLoador, showNotification } = this.props;
    showLoador({ loador: true });
    apiGet({
      url:
        endpoint.product +
        "/get-month-wise-bill-value-by-month-year-and-product?month=" +
        saleMonth.format("MM") +
        "&year=" +
        saleMonth.format("YYYY") +
        "&productId=" +
        productId,
    }).then(({ data, success }) => {
      showLoador({ loador: false });
      this.setState({
        productList: data.data,
      });
    });
  };

  salesHandler = (e, id) => {
    const { getMonthYearTargetForSales, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getMonthYearTargetForSales({ salesTeamId: id }).then(
        ({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            // showNotification({ msg: serverMsg, severity: "error" });
          } else {
            let lineGraphDataNew = [];
            for (var i = 0; i < response.length; i++) {
              lineGraphDataNew.push(+response[i].achivedAmt);
            }
            this.setState({
              lineGraphData: lineGraphDataNew,
            });
          }
        }
      );
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  salesTeamGraphHandler = (e, regionId) => {
    const { getZoneListFromYearAndMonth, showLoador, showNotification } =
      this.props;
    const { month, year } = this.state;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getZoneListFromYearAndMonth({
        month: month,
        year: year,
        regionId: regionId,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          // showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.setState({
            showZone: true,
            showArea: false,
          });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  onChangeRadioValue = (value) => {
    console.log(value);
    if (value == "0") {
      // let zeroRadioValue = [
      //   {
      //     name: "Target",
      //     data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
      //   },
      //   {
      //     name: "Achieved",
      //     data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
      //   },
      // ];

      // localStorage.setItem("radioValue", zeroRadioValue);
      this.setState({
        radioValue: [
          {
            name: "Target",
            data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
          },
          {
            name: "Achieved",
            data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
          },
        ],
      });
    } else if (value == "1") {
      // let oneRadioValue = [
      //   {
      //     name: "Target",
      //     data: [50, 60, 57, 56, 61, 58, 63, 60, 66],
      //   },
      //   {
      //     name: "Achieved",
      //     data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
      //   },
      // ];

      // localStorage.setItem("radioValue", oneRadioValue);
      this.setState({
        radioValue: [
          {
            name: "Target",
            data: [50, 60, 57, 56, 61, 58, 63, 60, 66],
          },
          {
            name: "Achieved",
            data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
          },
        ],
      });
    } else if (value == "2") {
      // let secondRadioValue = [
      //   {
      //     name: "Target",
      //     data: [80, 70, 57, 56, 61, 58, 63, 60, 66],
      //   },
      //   {
      //     name: "Achieved",
      //     data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
      //   },
      // ];

      // localStorage.setItem("radioValue", secondRadioValue);

      this.setState({
        radioValue: [
          {
            name: "Target",
            data: [80, 70, 57, 56, 61, 58, 63, 60, 66],
          },
          {
            name: "Achieved",
            data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
          },
        ],
      });
    } else if (value == "3") {
      // let thirdRadioValue =  [
      //   {
      //     name: "Target",
      //     data: [10, 20, 57, 56, 61, 58, 63, 60, 66],
      //   },
      //   {
      //     name: "Achieved",
      //     data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
      //   },
      // ];

      // localStorage.setItem("radioValue", thirdRadioValue);

      this.setState({
        radioValue: [
          {
            name: "Target",
            data: [10, 20, 57, 56, 61, 58, 63, 60, 66],
          },
          {
            name: "Achieved",
            data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
          },
        ],
      });
    }
  };

  columns = [
    {
      name: "index",
      title: "Sr.No",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
    },

    {
      name: "name",
      title: " Product Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
    {
      name: "code",
      title: "Product Code",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "actualSale",
      title: "Acheived",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
    {
      name: "contribution",
      title: "% Contribution",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
  ];
  graphList = [{ x: 1, y: 64 }];
  onChangeMonthOrYear = (data, name) => {
    this.setState({
      [name]: data,
    });
  };

  onClickLineChart = (data) => { };

  onSearch = (searchData) => {
    const { dashboardData, setRegionListFromYearAndMonth } = this.props;
    const { regionListFromYearAndMonthData } = this.state;
    if (searchData != "" || searchData != undefined) {
      this.setState({
        searchValue: searchData,
      });
      const list = dashboardData.regionListFromYearAndMonth.filter(
        (currentRow) => {
          let isValid = false;
          if (
            currentRow["name"] &&
            currentRow["name"]
              .toString()
              .toLowerCase()
              .includes(searchData.toLowerCase())
          ) {
            isValid = true;
            return true;
          }
          return isValid;
        }
      );
      this.setState({
        regionListFromYearAndMonthData: list,
      });
    } else {
      this.setState({
        searchValue: "",
      });
      this.setState({
        regionListFromYearAndMonthData:
          dashboardData.regionListFromYearAndMonth,
      });
    }
  };

  onSearchForTopPerformingProducts = (searchData) => {
    const { dashboardDetails } = this.props;
    const { productList } = this.state;
    if (searchData != "" || searchData != undefined) {
      this.setState({
        searchValueForProduct: searchData,
      });
      const list = dashboardDetails?.dashboardDataForProduct.filter(
        (currentRow) => {
          let isValid = false;
          if (
            (currentRow["name"] &&
              currentRow["name"]
                .toString()
                .toLowerCase()
                .includes(searchData.toLowerCase())) ||
            (currentRow["code"] &&
              currentRow["code"]
                .toString()
                .toLowerCase()
                .includes(searchData.toLowerCase()))
          ) {
            isValid = true;
            return true;
          }
          return isValid;
        }
      );
      this.setState({
        dashboardDataForProduct: list,
      });
    } else {
      this.setState({
        searchValueForProduct: "",
      });
      this.setState({
        productList: dashboardDetails?.dashboardDataForProduct,
      });
    }
  };
  handleCloses = () => {
    this.setState({
      popUpFlag: false,
    });
  };
  handleClosesCategory = () => {
    this.setState({
      popUpFlagCategory: false,
    });
  };
  rigionIdHandler = (regionId, month, year) => {
    this.setState({
      regionId,
      month,
      year,
    });
  };
  onCLickPie = (event, categoryId) => {
    const { getSubCategoryPackagingType, showLoador, showNotification } =
      this.props;
    const { month, year, regionId } = this.state;

    const regionIdLocalStorage = localStorage.getItem("regionId");
    if (navigator.onLine) {
      showLoador({ loador: true });
      getSubCategoryPackagingType({
        year: dayjs().format("YYYY"),
        month: dayjs().format("MM"),
        regionId: regionId ? regionId : regionIdLocalStorage,
        categoryId: categoryId,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.setState({
            popUpFlag: true,
          });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onCLickPieChart = (e, pacakingTypeId) => {
    const { getCategoryByPackagingType, showLoador, showNotification } =
      this.props;
    const { month, year, regionId } = this.state;
    const regionIdLocalStorage = localStorage.getItem("regionId");

    if (navigator.onLine) {
      showLoador({ loador: true });
      getCategoryByPackagingType({
        year: dayjs().format("YYYY"),
        month: dayjs().format("MM"),
        regionId: regionId ? regionId : regionIdLocalStorage,
        pacakingTypeId: pacakingTypeId,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.setState({
            popUpFlagCategory: true,
          });
        }
      });
    } else {
      showNotification({ msg: myConstClass.noInternetMsg, severity: "error" });
    }
  };
  onSearchForSalesTeamList = (searchValueForSalesTeamList) => {

    localStorage.setItem("searchValueForSalesTeamList", searchValueForSalesTeamList);

    this.setState({
      searchValueForSalesTeamList: searchValueForSalesTeamList,
    });
  };

  getFilteredTableDataForSalesTeamList = (searchData) => {
    const { searchValueForSalesTeamList } = this.state;
    return searchData.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["name"] &&
          currentRow["name"]
            .toString()
            .toLowerCase()
            .includes(searchValueForSalesTeamList.trim().toLowerCase())) ||
        (currentRow["district_names"] &&
          currentRow["district_names"]
            .toString()
            .toLowerCase()
            .includes(searchValueForSalesTeamList.trim().toLowerCase())) ||
        (currentRow["area_names"] &&
          currentRow["area_names"]
            .toString()
            .toLowerCase()
            .includes(searchValueForSalesTeamList.trim().toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
    });
  };
  changeDate = (name) => (date) => {
    const {
      getSalesTeamInfoByDatesNew,
      showLoador,
      showNotification,
      myConstClass,
    } = this.props;
    const { fromDate, toDate } = this.state;

    if (name === "fromDate") {
      this.setState({
        fromDate: date,
      });
    }

    if (name === "toDate") {
      this.setState({
        toDate: date,
      });
    }
  };

  generateExcel = () => {
    const { showLoador, showNotification } = this.props;
    const { fromDate, toDate } = this.state;
    if (navigator.onLine) {
      showLoador({ loador: true });
      downloadF({
        url:
          endpoint.adminDashboard +
          "/get-excel-sales-teams?fromDate=" +
          dayjs(fromDate).format("DD-MM-YYYY") +
          "&toDate=" +
          dayjs(toDate).format("DD-MM-YYYY"),
        ext: "xlsx",
        openNewTab: true,
      }).then((response) => {
        showLoador({ loador: false });
        if (!response) {
          this.props.showNotification({
            msg: serverMsg,
            severity: "error",
          });
        } else {
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };


  changeDateValidation = () => {
    const { fromDate, toDate } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (toDate.diff(fromDate, "days") > 31) {
      formIsValid = false;
      formErrors["toDateError"] = myConstClass.days31Validation;
    }

    this.setState({ formErrors });
    return formIsValid;
  };


  searchSalesTeamData = () => {
    const {
      getSalesTeamInfoByDatesNew,
      showLoador,
      showNotification,
      myConstClass,
    } = this.props;
    const { fromDate, toDate } = this.state;

    if (navigator.onLine) {
      if (this.changeDateValidation()) {
        showLoador({ loador: true });
        getSalesTeamInfoByDatesNew({
          fromDate: dayjs(fromDate).format("DD-MM-YYYY"),
          toDate: dayjs(toDate).format("DD-MM-YYYY"),
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: response.serverMsg, severity: "error" });
          } else {
            let totalOutletCount = 0,
              zero = 0,
              productiveCall = 0,
              unvisited = 0,
              totalOrderCall = 0,
              salesAmt = 0,
              totalAmount = 0,
              remoteOrder = 0;
            if (response.length !== 0) {
              response.map((salesTeam) => {
                totalOutletCount = totalOutletCount + +salesTeam.totalOutletCount;
                productiveCall = productiveCall + +salesTeam.productiveCall;
                zero = zero + +salesTeam.zeroOrderCount;
                unvisited = unvisited + +salesTeam.unvisitedOutletCount;
                totalOrderCall = totalOrderCall + +salesTeam.totalOrderCall;
                salesAmt = salesAmt + +salesTeam.totalSalesAmt;
                totalAmount = totalAmount + +salesTeam.totalAmount;
                remoteOrder = remoteOrder + +salesTeam.remoteOrderCount;
              });
            }
            this.setState({
              totalOutletCount: totalOutletCount,
              zero: zero,
              productiveCall: productiveCall,
              unvisited: unvisited,
              totalOrderCall: totalOrderCall,
              salesAmt: salesAmt,
              totalAmount: totalAmount,
              remoteOrder: remoteOrder,
            });

            localStorage.setItem("totalOutletCount", totalOutletCount);
            localStorage.setItem("zero", zero);
            localStorage.setItem("productiveCall", productiveCall);
            localStorage.setItem("unvisited", unvisited);
            localStorage.setItem("salesAmt", salesAmt);
            localStorage.setItem("totalAmount", totalAmount);
            localStorage.setItem("remoteOrder", remoteOrder);
            localStorage.setItem("totalOrderCall", totalOrderCall);
          }
        });
      }

    } else {
      showNotification({ msg: myConstClass.noInternetMsg, severity: "error" });
    }
  };

  onClickWorkNotStarted = async () => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await apiGet({
        url:
          endpoint.managerLogs +
          "/work-not-started-sales-team?date=" +
          dayjs().format("DD-MM-YYYY"),
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (!success) {
          this.props.showNotification({ msg: serverMsg, severity: "error" });
        }
        {
          if (data && data.data !== null) {
            const dataList = data.data.map((dataObj, index) => ({
              index: index + 1,
              userName: dataObj.userName,
              name: dataObj.name,
              stockHolderType: dataObj.stockStakeHolderTypeName,
              address: dataObj.address,
            }));

            this.setState({
              workNotStartedCountList: dataList,
            });
          }
        }
      });

      this.setState({ workNotStartedFlag: true });
    } else {
      showNotification({ msg: myConstClass.noInternetMsg, severity: "error" });
    }
  };

  render() {
    const {
      dashboardData,
      dashboardDetails,
      billingHistoryList,
      subCategoryList,
      stockStakeHolderTypeList,
      zoneList,
      areaList,
      showNotification,
      showLoador,
      beatList,
      dashboardDataForProduct,
    } = this.props;
    const {
      type,
      showZone,
      showArea,
      saleMonth,
      monthList,
      columns,
      rows,
      rowsPerPage,
      page,
      radioValue,
      productArea,
      productZone,
      outletArea,
      outletZone,
      regionListFromYearAndMonthData,
      regionListFromYearAndMonthDataForZone,
      regionListFromYearAndMonthDataForSalesRegion,
      stateListFromYearAndMonth,
      zoneDetailsSalesData,
      zoneDetailsRegionwiseData,
      districtDetailsZoneWiseData,
      salesGraphData,
      salesMonthData,
      areaDetailsData,
      districtDetailsData,
      talukaDetailsData,
      areaDetailsListData,
      topPerformingOutletData,
      salesTeamData,
      searchValue,
      searchValueForZone,
      searchValueForSalesRegion,
      searchValueForZoneDetails,
      searchValueForAreaDetails,
      searchValueStateList,
      searchValuePersonWiseSales,
      searchValueForProduct,
      searchValueForOutlets,
      lineGraphData,
      productList,
      salesRegionName,
      packagingTypeFlag,
      popUpFlag,
      popUpFlagCategory,
      beatPopupFlag,
      workNotStartedFlag,
      workNotStartedCountList,
      totalOutletCount,
      productiveCall,
      remoteOrder,
      unvisited,
      totalOrderCall,
      zero,
      totalAmount,
      salesAmt,
      toDate,
      fromDate,
      formErrors,
      salesListFromYearAndMonthOnSearch,
      productDataOnSearchFlag,
      categoryWiseBillFlag,
      workNotStartedCount,
      companyFlagState,
      showAttendanceTargetAchievement,
      showPackagingTypeAmounts,
    } = this.state;
    const { typeError, dateError } = this.state.formErrors;

    const adminDashboard = localStorage.getItem("adminDashboard");
    const adminDashboardData = JSON.parse(adminDashboard);

    const stockStakeHolderType = localStorage.getItem("stockStakeHolderTypeId");
    const stockStakeHolderTypeListData = localStorage.getItem(
      "stockStakeHolderTypeListData"
    );
    const stockStakeHolderTypeListLocalStorage = JSON.parse(
      stockStakeHolderTypeListData
    );

    const monthYearTargetData = localStorage.getItem("monthYearTargetData");
    const monthYearTargetDataLocalStorage = JSON.parse(monthYearTargetData);

    const salesListFromYearAndMonthData = localStorage.getItem(
      "salesListFromYearAndMonth"
    );
    const salesListFromYearAndMonthLocalStorage = JSON.parse(
      salesListFromYearAndMonthData
    );

    const categoryWiseBillData = localStorage.getItem("categoryWiseBillData");
    const categoryWiseBillDataLocalStorage = JSON.parse(categoryWiseBillData);

    const packagingTypeData = localStorage.getItem("packagingTypeData");
    const packagingTypeDataLocalStorage = JSON.parse(packagingTypeData);


    const totalOutletCountLocalStorage = localStorage.getItem("totalOutletCount");
    const zeroLocalStorage = localStorage.getItem("zero");
    const productiveCallLocalStorage = localStorage.getItem("productiveCall");
    const unvisitedLocalStorage = localStorage.getItem("unvisited");
    const salesAmtLocalStorage = localStorage.getItem("salesAmt");
    const totalAmountLocalStorage = localStorage.getItem("totalAmount");
    const remoteOrderLocalStorage = localStorage.getItem("remoteOrder");
    const totalOrderCallLocalStorage = localStorage.getItem("totalOrderCall");

    return (
      <>
        <div
          id="layout-wrapper"
          style={{
            backgroundColor: "#fff",
          }}
        >
          {/* ========== App Menu ========== */}

          {/* Left Sidebar End */}
          {/* Vertical Overlay*/}
          <div className="vertical-overlay" />
          {/* ============================================================== */}
          {/* Start right Content here 
          {/* ============================================================== */}
          <div className="main-content">
            <div className="page-content">
              <div className="container-fluid">
                <div
                  className="row"
                  style={{
                    gap: 8,
                    paddingBottom: 10,
                  }}
                >
                  {/* Card 1 */}
                  <div
                    className="Frame427319159"
                    style={{
                      width: "24.5%",
                      height: "auto",
                      paddingTop: 22,
                      paddingBottom: 22,
                      background: "#FFFDE7",
                      borderRadius: 8,
                      overflow: "hidden",
                      // border: "1px solid",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "inline-flex",
                    }}
                  >
                    <div
                      className="Frame11262"
                      style={{
                        flex: "1 1 0",
                        alignSelf: "stretch",
                        paddingLeft: 16,
                        paddingRight: 16,
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        display: "inline-flex",
                      }}
                    >
                      <div
                        className="Frame427319153"
                        style={{
                          width: "60%",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          gap: 8,
                          display: "inline-flex",
                        }}
                      >
                        <img
                          className="Image4"
                          style={{ width: 32, height: 32 }}
                          src={image4}
                        />

                        <div
                          className="TotalSales"
                          style={{
                            color: "black",
                            fontSize: 22,
                            fontFamily: "Poppins",
                            fontWeight: "400",
                            wordWrap: "break-word",
                            top: 5,
                          }}
                        >
                          Total Sales
                        </div>
                      </div>
                      <div
                        className="Frame427319116"
                        style={{
                          flexDirection: "column",
                          justifyContent: "space-between",
                          alignItems: "center",
                          display: "inline-flex",
                        }}
                      >
                        <div
                          className="Cr"
                          style={{
                            color: "black",
                            fontSize: 28,
                            fontFamily: "Poppins",
                            fontWeight: "500",
                            textTransform: "capitalize",
                            lineHeight: 1.8,
                            wordWrap: "break-word",
                          }}
                        >
                          {ConvertAmount({
                            amount: adminDashboardData
                              ? +adminDashboardData.currentAchievedAmt
                              : "",
                          })}
                        </div>
                        <div
                          className="Frame427319115"
                          style={{
                            justifyContent: "flex-end",
                            alignItems: "center",
                            gap: 2,
                            display: "inline-flex",
                          }}
                        >
                          <div
                            style={{
                              color: "#43C622",
                              fontSize: 16,
                              fontFamily: "Poppins",
                              fontWeight: "400",
                              textTransform: "capitalize",
                              wordWrap: "break-word",
                            }}
                          >
                            {adminDashboardData
                              ? (+adminDashboardData.contribution).toFixed(2)
                              : ""}
                            %
                          </div>
                          <div
                            className="HeroiconsMiniArrowSmallUp"
                            style={{
                              width: 20,
                              height: 20,
                              position: "relative",
                            }}
                          >
                            <div
                              className="VectorStroke"
                              style={{
                                width: 8,
                                height: 17,
                                left: 6,
                                // top: 5,
                                position: "absolute",
                                background: "#43C622",
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Card 1  end*/}
                  {/* Card 2 */}
                  <div
                    className="Frame427319162"
                    style={{
                      width: "24.5%",
                      height: "auto",
                      paddingTop: 22,
                      paddingBottom: 22,
                      background: "rgba(253, 126, 20, 0.10)",
                      borderRadius: 8,
                      overflow: "hidden",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "inline-flex",
                    }}
                  >
                    <div
                      className="Frame11262"
                      style={{
                        flex: "1 1 0",
                        alignSelf: "stretch",
                        paddingLeft: 16,
                        paddingRight: 16,
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        display: "inline-flex",
                      }}
                    >
                      <div
                        className="Frame427319153"
                        style={{
                          width: "60%",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          gap: 8,
                          display: "inline-flex",
                        }}
                      >
                        <img
                          className="Image5"
                          style={{ width: 32, height: 32 }}
                          src={image5}
                        />
                        <div
                          className="TotalSales"
                          style={{
                            color: "black",
                            fontSize: 22,
                            fontFamily: "Poppins",
                            fontWeight: "400",
                            wordWrap: "break-word",
                          }}
                        >
                          Outlet
                        </div>
                      </div>
                      <div
                        className="Frame427319116"
                        style={{
                          flexDirection: "column",
                          justifyContent: "space-between",
                          alignItems: "center",
                          display: "inline-flex",
                        }}
                      >
                        <div
                          style={{
                            color: "black",
                            fontSize: 28,
                            fontFamily: "Poppins",
                            fontWeight: "500",
                            textTransform: "capitalize",
                            lineHeight: 1.8,
                            wordWrap: "break-word",
                          }}
                        >
                          {adminDashboardData
                            ? +adminDashboardData.totalOutletCount
                            : ""}
                        </div>
                        <div
                          className="Frame427319115"
                          style={{
                            justifyContent: "flex-end",
                            alignItems: "center",
                            gap: 2,
                            display: "inline-flex",
                          }}
                        >
                          <div
                            style={{
                              color: "#43C622",
                              fontSize: 16,
                              fontFamily: "Poppins",
                              fontWeight: "400",
                              textTransform: "capitalize",
                              wordWrap: "break-word",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Card 2 end */}
                  {/* Card 3 */}
                  <div
                    className="Frame427319160"
                    style={{
                      width: "24.5%",
                      height: "auto",
                      paddingTop: 22,
                      paddingBottom: 22,
                      background: "rgba(39, 197, 231, 0.10)",
                      borderRadius: 8,
                      overflow: "hidden",
                      // border: "1px solid",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "inline-flex",
                    }}
                  >
                    <div
                      className="Frame11262"
                      style={{
                        flex: "1 1 0",
                        alignSelf: "stretch",
                        paddingLeft: 16,
                        paddingRight: 16,
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        display: "inline-flex",
                      }}
                    >
                      <div
                        className="Frame427319153"
                        style={{
                          width: "60%",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          gap: 8,
                          display: "inline-flex",
                        }}
                      >
                        <img
                          className="Image5"
                          style={{ width: 32, height: 32 }}
                          src={image6}
                        />
                        <div
                          className="TotalSales"
                          style={{
                            color: "black",
                            fontSize: 22,
                            fontFamily: "Poppins",
                            fontWeight: "400",
                            wordWrap: "break-word",
                          }}
                        >
                          Stock-Holder
                        </div>
                      </div>
                      <div
                        className="Frame427319116"
                        style={{
                          // alignSelf: "stretch",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          alignItems: "center",
                          display: "inline-flex",
                        }}
                      >
                        <div
                          style={{
                            color: "black",
                            fontSize: 28,
                            fontFamily: "Poppins",
                            fontWeight: "500",
                            textTransform: "capitalize",
                            lineHeight: 1.8,
                            wordWrap: "break-word",
                          }}
                        >
                          {adminDashboardData
                            ? +adminDashboardData.totalStakeHolderCount
                            : ""}
                        </div>
                        <div
                          className="Frame427319115"
                          style={{
                            justifyContent: "flex-end",
                            alignItems: "center",
                            gap: 2,
                            display: "inline-flex",
                          }}
                        >
                          <div
                            style={{
                              color: "#43C622",
                              fontSize: 16,
                              fontFamily: "Poppins",
                              fontWeight: "400",
                              textTransform: "capitalize",
                              wordWrap: "break-word",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Card 3 end */}
                  {/* Card 4 */}
                  <div
                    className="Frame427319161"
                    style={{
                      width: "24.5%",
                      height: "auto",
                      paddingTop: 22,
                      paddingBottom: 22,
                      background: "rgba(243, 91, 138, 0.10)",
                      borderRadius: 8,
                      overflow: "hidden",
                      // border: "1px solid",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "inline-flex",
                    }}
                  >
                    <div
                      className="Frame11262"
                      style={{
                        flex: "1 1 0",
                        alignSelf: "stretch",
                        paddingLeft: 16,
                        paddingRight: 16,
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        display: "inline-flex",
                      }}
                    >
                      <div
                        className="Frame427319153"
                        style={{
                          width: "60%",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          gap: 8,
                          display: "inline-flex",
                        }}
                      >
                        <img
                          className="Image7"
                          style={{ width: 32, height: 32 }}
                          src={image7BgClr}
                        />
                        <div
                          className="TotalSales"
                          style={{
                            color: "black",
                            fontSize: 22,
                            fontFamily: "Poppins",
                            fontWeight: "400",
                            wordWrap: "break-word",
                          }}
                        >
                          Manpower
                        </div>
                      </div>
                      <div
                        className="Frame427319116"
                        style={{
                          // alignSelf: "stretch",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          alignItems: "center",
                          display: "inline-flex",
                        }}
                      >
                        <div
                          style={{
                            color: "black",
                            fontSize: 28,
                            fontFamily: "Poppins",
                            fontWeight: "500",
                            textTransform: "capitalize",
                            lineHeight: 1.8,
                            wordWrap: "break-word",
                          }}
                        >
                          {adminDashboardData
                            ? +adminDashboardData.totalSalesTeamCount
                            : ""}
                        </div>
                        <div
                          className="Frame427319115"
                          style={{
                            justifyContent: "flex-end",
                            alignItems: "center",
                            gap: 2,
                            display: "inline-flex",
                          }}
                        >
                          <div
                            style={{
                              color: "#43C622",
                              fontSize: 16,
                              fontFamily: "Poppins",
                              fontWeight: "400",
                              textTransform: "capitalize",
                              wordWrap: "break-word",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Card 4 end */}
                </div>

                <div
                  className="row"
                  style={{
                    gap: 8,
                    paddingBottom: 10,
                  }}
                >
                  {/* Target Card Start */}

                  <div
                    className="Frame1000002990"
                    style={{
                      width: "24%",
                      height: "auto",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      gap: 12,
                      display: "inline-flex",
                    }}
                  >
                    {/*Total Target Card*/}
                    <div
                      className="Frame1000002999"
                      style={{
                        width: "100%",
                        height: "auto",
                        padding: 12,
                        background: "#FFE3FB",
                        borderRadius: 8,
                        justifyContent: "space-between",
                        alignItems: "center",
                        display: "inline-flex",
                      }}
                    >
                      <div
                        className="Frame1000002995"
                        style={{
                          height: 76,
                          flexDirection: "column",
                          justifyContent: "space-between",
                          alignItems: "flex-start",
                          display: "inline-flex",
                          flex: "1",
                        }}
                      >
                        <div
                          className="Frame427319178"
                          style={{
                            width: 44,
                            height: 44,
                            padding: 10,
                            background: "#E205BF",
                            borderRadius: 23,
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 10,
                            display: "inline-flex",
                          }}
                        >
                          <div
                            className="Target1"
                            style={{
                              width: 32,
                              height: 32,
                              justifyContent: "center",
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            <div
                              className="Frame"
                              style={{
                                width: 32,
                                height: 32,
                                position: "relative",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                display: "flex",
                              }}
                            >
                              <img
                                className="Image7"
                                style={{ width: 32, height: 32 }}
                                src={target1}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="TotalTarget"
                          style={{
                            color: "black",
                            fontSize: 16,
                            fontFamily: "Poppins",
                            fontWeight: "400",
                            wordWrap: "break-word",
                          }}
                        >
                          Total Target
                        </div>
                      </div>
                      <div
                        className="Frame1000002994"
                        style={{
                          alignSelf: "stretch",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          display: "inline-flex",
                          flex: "2",
                        }}
                      >
                        <div
                          className="Frame427319117"
                          style={{
                            flexDirection: "column",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            gap: 8,
                            display: "flex",
                          }}
                        >
                          <div
                            className="Frame427319116"
                            style={{
                              width: 121,
                              justifyContent: "center",
                              alignItems: "center",
                              display: "inline-flex",
                            }}
                          >
                            <div
                              className="Cr"
                              style={{
                                flex: "1 1 0",
                                textAlign: "center",
                                color: "black",
                                fontSize: 18,
                                fontFamily: "Poppins",
                                fontWeight: "500",
                                textTransform: "capitalize",
                                lineHeight: 1,
                                wordWrap: "break-word",
                              }}
                            >
                              {ConvertAmount({
                                amount: adminDashboardData
                                  ? +adminDashboardData.currentTargetAmt
                                  : "",
                              })}
                            </div>
                            <div
                              className="Frame427319115"
                              style={{
                                justifyContent: "flex-end",
                                alignItems: "right",
                                // gap: 2,
                                display: "flex",
                              }}
                            >
                              <div
                                style={{
                                  color: "#43C622",
                                  fontSize: 14,
                                  fontFamily: "Poppins",
                                  fontWeight: "500",
                                  textTransform: "capitalize",
                                  wordWrap: "break-word",
                                }}
                              ></div>
                            </div>
                          </div>
                          <div
                            className="Total"
                            style={{
                              color: "#888888",
                              fontSize: 12,
                              fontFamily: "Poppins",
                              fontWeight: "400",
                              wordWrap: "break-word",
                            }}
                          >
                            Total{" "}
                          </div>
                        </div>
                        <div
                          className="Frame427319118"
                          style={{
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 7,
                            display: "flex",
                          }}
                        >
                          <div
                            className="Frame427319116"
                            style={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "inline-flex",
                            }}
                          >
                            <div
                              className="Cr"
                              style={{
                                // width: 80,
                                textAlign: "center",
                                color: "black",
                                fontSize: 18,
                                fontFamily: "Poppins",
                                fontWeight: "500",
                                textTransform: "capitalize",
                                lineHeight: 1,
                                wordWrap: "break-word",
                              }}
                            >
                              {ConvertAmount({
                                amount: adminDashboardData
                                  ? +adminDashboardData.currentAchievedAmt
                                  : "",
                              })}
                            </div>
                            <div
                              className="Frame427319115"
                              style={{
                                justifyContent: "flex-start",
                                alignItems: "center",
                                gap: 2,
                                display: "flex",
                              }}
                            >
                              <div
                                style={{
                                  color: "#43C622",
                                  fontSize: 14,
                                  fontFamily: "Poppins",
                                  fontWeight: "500",
                                  textTransform: "capitalize",
                                  wordWrap: "break-word",
                                }}
                              ></div>
                            </div>
                          </div>
                          <div
                            className="Achieved"
                            style={{
                              color: "#888888",
                              fontSize: 12,
                              fontFamily: "Poppins",
                              fontWeight: "400",
                              wordWrap: "break-word",
                            }}
                          >
                            Achieved
                          </div>
                        </div>
                      </div>
                    </div>

                    {/*Monthly Target Card*/}
                    <div
                      className="Frame1000002996"
                      style={{
                        width: "100%",
                        height: "auto",
                        padding: 12,
                        background: "#EEF6FF",
                        borderRadius: 8,
                        justifyContent: "space-between",
                        alignItems: "center",
                        display: "inline-flex",
                      }}
                    >
                      <div
                        className="Frame1000002995"
                        style={{
                          height: 76,
                          flexDirection: "column",
                          justifyContent: "space-between",
                          alignItems: "flex-start",
                          flex: "1",
                          display: "inline-flex",
                        }}
                      >
                        <div
                          className="Frame427319178"
                          style={{
                            width: 44,
                            height: 44,
                            padding: 10,
                            background: "#056BE2",
                            borderRadius: 23,
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 10,
                            display: "inline-flex",
                          }}
                        >
                          <div
                            className="Target1"
                            style={{
                              width: 32,
                              height: 32,
                              justifyContent: "center",
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            <div
                              className="Frame"
                              style={{
                                width: 32,
                                height: 32,
                                position: "relative",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                display: "flex",
                              }}
                            >
                              <img
                                className="Image7"
                                style={{ width: 32, height: 32 }}
                                src={target1}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="MonthlyTarget"
                          style={{
                            color: "black",
                            fontSize: 16,
                            fontFamily: "Poppins",
                            fontWeight: "400",
                            wordWrap: "break-word",
                          }}
                        >
                          Monthly Target
                        </div>
                      </div>
                      <div
                        className="Frame1000002994"
                        style={{
                          alignSelf: "stretch",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          display: "inline-flex",
                          flex: "2",
                        }}
                      >
                        <div
                          className="Frame427319117"
                          style={{
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 8,
                            display: "flex",
                          }}
                        >
                          <div
                            className="Frame427319116"
                            style={{
                              width: 121,
                              justifyContent: "center",
                              alignItems: "center",
                              display: "inline-flex",
                            }}
                          >
                            <div
                              className="Cr"
                              style={{
                                flex: "1 1 0",
                                textAlign: "center",
                                color: "black",
                                fontSize: 18,
                                fontFamily: "Poppins",
                                fontWeight: "500",
                                textTransform: "capitalize",
                                lineHeight: 1,
                                wordWrap: "break-word",
                              }}
                            >
                              {ConvertAmount({
                                amount: adminDashboardData
                                  ? +adminDashboardData.targetAmt
                                  : "",
                              })}
                            </div>
                            <div
                              className="Frame427319115"
                              style={{
                                justifyContent: "flex-start",
                                alignItems: "center",
                                gap: 2,
                                display: "flex",
                              }}
                            >
                              <div
                                style={{
                                  color: "#43C622",
                                  fontSize: 14,
                                  fontFamily: "Poppins",
                                  fontWeight: "500",
                                  textTransform: "capitalize",
                                  wordWrap: "break-word",
                                }}
                              ></div>
                            </div>
                          </div>
                          <div
                            className="Total"
                            style={{
                              color: "#888888",
                              fontSize: 12,
                              fontFamily: "Poppins",
                              fontWeight: "400",
                              wordWrap: "break-word",
                            }}
                          >
                            Total{" "}
                          </div>
                        </div>
                        <div
                          className="Frame427319118"
                          style={{
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 7,
                            display: "flex",
                          }}
                        >
                          <div
                            className="Frame427319116"
                            style={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "inline-flex",
                            }}
                          >
                            <div
                              className="Cr"
                              style={{
                                // width: 80,
                                textAlign: "center",
                                color: "black",
                                fontSize: 18,
                                fontFamily: "Poppins",
                                fontWeight: "500",
                                textTransform: "capitalize",
                                lineHeight: 1,
                                wordWrap: "break-word",
                              }}
                            >
                              {ConvertAmount({
                                amount: adminDashboardData
                                  ? +adminDashboardData.achivedAmt
                                  : "",
                              })}
                            </div>
                            <div
                              className="Frame427319115"
                              style={{
                                justifyContent: "flex-start",
                                alignItems: "center",
                                gap: 2,
                                display: "flex",
                              }}
                            >
                              <div
                                style={{
                                  color: "#43C622",
                                  fontSize: 14,
                                  fontFamily: "Poppins",
                                  fontWeight: "500",
                                  textTransform: "capitalize",
                                  wordWrap: "break-word",
                                }}
                              ></div>
                            </div>
                          </div>
                          <div
                            className="Achieved"
                            style={{
                              color: "#888888",
                              fontSize: 12,
                              fontFamily: "Poppins",
                              fontWeight: "400",
                              wordWrap: "break-word",
                            }}
                          >
                            Achieved
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* WORK NOT STARTED CARD*/}

                    {companyFlagState == 2 && (
                      <>
                        <div
                          className="Frame1000002996"
                          style={{
                            width: "100%",
                            height: "auto",
                            padding: 12,
                            background: "rgba(253, 126, 20, 0.1)",
                            borderRadius: 8,
                            justifyContent: "space-between",
                            alignItems: "center",
                            display: "inline-flex",
                          }}
                          onClick={() => this.onClickWorkNotStarted()}
                        >
                          <div
                            className="Frame1000002995"
                            style={{
                              height: 55,
                              flexDirection: "column",
                              justifyContent: "space-between",
                              alignItems: "flex-start",
                              flex: "1",
                              display: "inline-flex",
                            }}
                          >
                            <div
                              className="Frame"
                              style={{
                                width: 32,
                                height: 32,
                                position: "relative",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                display: "flex",
                              }}
                            >
                              <img
                                className="Image7"
                                style={{ width: 32, height: 32 }}
                                src={image7BgClr}
                              />
                            </div>
                            <div
                              className="MonthlyTarget"
                              style={{
                                color: "black",
                                fontSize: 16,
                                fontFamily: "Poppins",
                                fontWeight: "400",
                                wordWrap: "break-word",
                              }}
                            >
                              Work Not Started
                            </div>
                          </div>
                          <div
                            className="Frame1000002994"
                            style={{
                              alignSelf: "stretch",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              display: "inline-flex",
                            }}
                          >
                            <div
                              className="Frame427319117"
                              style={{
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: 8,
                                display: "flex",
                              }}
                            >
                              <div
                                className="Frame427319116"
                                style={{
                                  width: 63,
                                  justifyContent: "center",
                                  alignItems: "center",
                                  display: "inline-flex",
                                }}
                              >
                                <div
                                  className="Cr"
                                  style={{
                                    flex: "1 1 0",
                                    textAlign: "center",
                                    color: "black",
                                    fontSize: 18,
                                    fontFamily: "Poppins",
                                    fontWeight: "500",
                                    textTransform: "capitalize",
                                    lineHeight: 1,
                                    wordWrap: "break-word",
                                  }}
                                >
                                  {workNotStartedCount
                                    ? workNotStartedCount
                                    : 0}
                                </div>
                                <div
                                  className="Frame427319115"
                                  style={{
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    gap: 2,
                                    display: "flex",
                                  }}
                                >
                                  <div
                                    style={{
                                      color: "#43C622",
                                      fontSize: 14,
                                      fontFamily: "Poppins",
                                      fontWeight: "500",
                                      textTransform: "capitalize",
                                      wordWrap: "break-word",
                                    }}
                                  ></div>
                                </div>
                              </div>
                              <div
                                className="Total"
                                style={{
                                  color: "#888888",
                                  fontSize: 12,
                                  fontFamily: "Poppins",
                                  fontWeight: "400",
                                  wordWrap: "break-word",
                                }}
                              >
                                Count{" "}
                              </div>
                            </div>
                            <div
                              className="Frame427319118"
                              style={{
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: 7,
                                display: "flex",
                              }}
                            >
                              <div
                                className="Frame427319116"
                                style={{
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  display: "inline-flex",
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  {/* Target Card Ends */}
                  {/* Bar Graph starts */}
                  <div
                    className="Group11172"
                    style={{ width: "75%", position: "relative" }}
                  >
                    <div
                      className="SalesOverview"
                      style={{
                        left: 14,
                        //top: 10,
                        position: "absolute",
                        color: "black",
                        fontSize: 16,
                        fontFamily: "Poppins",
                        fontWeight: "400",
                        wordWrap: "break-word",
                      }}
                    >
                      Sales Overview
                    </div>
                    <div>
                      {monthYearTargetDataLocalStorage !== undefined && monthYearTargetDataLocalStorage !== null && monthYearTargetDataLocalStorage.length !== 0 && (
                        <MonthlyBarChart
                          flag={false}
                          height={300}
                          radioValue={this.onChangeRadioValue}
                          series={
                            monthYearTargetDataLocalStorage
                              ? monthYearTargetDataLocalStorage
                              : []
                          }
                          optionsObj={{
                            chart: {
                              type: "bar",
                              height: 65,
                              events: {
                                dataPointSelection: (
                                  event,
                                  chartContext,
                                  config
                                ) => {
                                  this.onClickLineChart(config.dataPointIndex);
                                },
                              },
                            },

                            plotOptions: {
                              bar: {
                                columnWidth: "55%",
                                endingShape: "rounded",
                                horizontal: false,
                              },
                            },
                            stroke: {
                              show: true,
                              width: 2,
                              // colors: ["transparent"],
                            },
                            dataLabels: {
                              enabled: false,
                            },
                            xaxis: {
                              categories: [
                                "Apr",
                                "May",
                                "Jun",
                                "Jul",
                                "Aug",
                                "Sep",
                                "Oct",
                                "Nov",
                                "Dec",
                                "Jan",
                                "Feb",
                                "Mar",
                              ],
                            },
                            yaxis: {
                              title: [
                                "Feb",
                                "Mar",
                                "Apr",
                                "May",
                                "Jun",
                                "Jul",
                                "Aug",
                                "Sep",
                                "Oct",
                              ],
                            },
                            fill: {
                              opacity: 1,
                            },
                            // colors: [info],

                            tooltip: {
                              theme: "light",
                            },
                          }}
                        />
                      )}

                    </div>
                  </div>

                  {/* Bar Graph ends */}
                </div>

                {/* Sales Div starts */}

                <div
                  className="Frame427319149"
                  style={{
                    width: "100%",
                    height: 36,
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 12,
                    display: "inline-flex",
                  }}
                >
                  <div
                    className="SalesManpower"
                    style={{
                      color: "black",
                      fontSize: 24.28,
                      fontFamily: "Poppins",
                      fontWeight: "500",
                      textTransform: "capitalize",
                      wordWrap: "break-word",
                    }}
                  >
                    Sales
                  </div>
                </div>
                {/* Sales Div end */}

                {showAttendanceTargetAchievement == 1 ? (
                  <HiearchyDropDown rigionIdHandler={this.rigionIdHandler} />
                ) : (
                  <HierarchyDropDownOld
                    rigionIdHandler={this.rigionIdHandler}
                  />
                )}

                {showPackagingTypeAmounts == 1 && <HierarchyForPackagingType />}

                {/* Top 20 Div end */}

                {/* TOP PERFORMING PRODUCT list remove for optimisation purpose */}

                {/* Current month category-wise Sale && Current month packaging-Type-Wise Sale remove for optimisation purpose */}

                <div
                  className="row"
                  style={{
                    gap: 8,
                    marginBottom: 10,
                    width: "100%",
                    marginLeft: 5,
                  }}
                >
                  <div
                    style={{
                      width: "97%",
                      background: "white",
                      padding: 5,
                      borderRadius: 8,
                      border: "1px #E6E6E6 solid",
                    }}
                  >
                    {/* Top beat starts */}
                    <div
                      // className="card"
                      style={{
                        marginBottom: -2,
                        background: "white",
                        borderRadius: 8,
                        border: "0 #E6E6E6 solid",
                      }}
                    >
                      <div
                        className="card-header align-items-center d-flex"
                        style={{
                          background: "white",
                          borderRadius: 8,
                          border: "0 #E6E6E6 solid",
                          // marginTop: 20,
                        }}
                      >
                        <h4
                          className="text-uppercase card-title mb-0 flex-grow-1"
                          style={{}}
                        >
                          Sales Team List
                          <div
                            style={{
                              marginLeft: -20,
                            }}
                          >
                            <LandingScreenHeader
                              screenTitle={""}
                              searchValue={localStorage.getItem("searchValueForSalesTeamList")}
                              showSearchBox={true}
                              showTable={true}
                              showFilter={false}
                              showPdfDownload={false}
                              showExcelDownload={false}
                              generateExcel={this.generateExcel}
                              onSearch={this.onSearchForSalesTeamList}
                            />
                          </div>
                        </h4>
                        <h4
                          className="text-uppercase card-title mb-0 flex-grow-1"
                          style={{
                            textAlign: "center",
                            width: "500px",
                          }}
                        >
                          <Grid
                            item
                            sm={12}
                            xs={12}
                            md={12}
                            lg={12}
                            container
                            justifyContent="space-between"
                          >
                            <Grid
                              item
                              sm={4}
                              xs={4}
                              md={4}
                              lg={4}
                              container
                              justifyContent="space-evenly"
                            >
                              <Grid item xs={12} md={5} sm={5}>
                                <LabelCompo
                                  className="text-black"
                                  style={{ fontSize: 16, color: redColor }}
                                  label="* "
                                />
                                <LabelCompo
                                  className="text-black"
                                  style={{ fontSize: 16, color: titleColor }}
                                  label="From Date :"
                                />
                              </Grid>
                              <Grid item xs={12} md={7} sm={7}>
                                <BasicDatePicker
                                  color="primary"
                                  name="fromDate"
                                  size="small"
                                  value={fromDate}
                                  disabled={false}
                                  fullWidth
                                  onChange={this.changeDate("fromDate")}
                                  errorText={
                                    formErrors["fromDateError"]
                                      ? formErrors["fromDateError"]
                                      : ""
                                  }
                                  isError={
                                    formErrors["fromDateError"]
                                      ? formErrors["fromDateError"]
                                      : ""
                                  }
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              sm={4}
                              xs={4}
                              md={4}
                              lg={4}
                              container
                              justifyContent="space-evenly"
                            >
                              <Grid item xs={12} md={5} sm={5}>
                                <LabelCompo
                                  className="text-black"
                                  style={{ fontSize: 16, color: redColor }}
                                  label="* "
                                />
                                <LabelCompo
                                  className="text-black"
                                  style={{ fontSize: 16, color: titleColor }}
                                  label="To Date :"
                                />
                              </Grid>
                              <Grid item xs={12} md={7} sm={7}>
                                <BasicDatePicker
                                  color="primary"
                                  name="toDate"
                                  size="small"
                                  value={toDate}
                                  minDate={fromDate}
                                  disabled={false}
                                  fullWidth
                                  onChange={this.changeDate("toDate")}
                                  errorText={
                                    formErrors["toDateError"]
                                      ? formErrors["toDateError"]
                                      : ""
                                  }
                                  isError={
                                    formErrors["toDateError"]
                                      ? formErrors["toDateError"]
                                      : ""
                                  }
                                />
                              </Grid>
                            </Grid>

                            <Grid
                              item
                              sm={3}
                              xs={3}
                              md={3}
                              lg={3}
                              gap={2}
                              container
                              flexDirection="row"
                              justifyContent="end"
                              alignItems="center"
                            >
                              <ButtonCompo
                                sx={{
                                  bgcolor: "dodgerblue",
                                  color: "#fbff00",
                                  height: "40px",
                                  width: "100px",
                                  lineHeight: "1.5",
                                }}
                                size="medium"
                                type="button"
                                variant="contained"
                                name={"Search"}
                                onClick={this.searchSalesTeamData}
                                fullWidth={false}
                              />

                              <ButtonCompo
                                sx={{
                                  bgcolor: "dodgerblue",
                                  color: "#fbff00",
                                  height: "40px",
                                  width: "100px",
                                  lineHeight: "1.5",
                                }}
                                size="medium"
                                type="button"
                                variant="contained"
                                name={"Excel"}
                                onClick={this.generateExcel}
                                fullWidth={false}
                              />
                            </Grid>
                          </Grid>
                        </h4>
                      </div>
                    </div>

                    <TableContainer
                      sx={{
                        maxHeight: 440,
                        // width: 610,
                        // padding: 1.5,
                        // marginTop: 2.5,
                      }}
                    >
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead
                          style={{
                            backgroundColor: "#fff",
                          }}
                        >
                          <TableRow>
                            <TableCell
                              color="#000"
                              align="center"
                              sx={{ width: "1%", color: "#000" }}
                              canSearch="true"
                            >
                              Sr.No
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ width: "7%", color: "#000" }}
                              canSearch="true"
                            >
                              Sales Team Name
                            </TableCell>
                            {localStorage.getItem("showCompanyFlag") &&
                              localStorage.getItem("showCompanyFlag") != 2 && (
                                <TableCell
                                  align="center"
                                  sx={{ width: "2%", color: "#000" }}
                                  canSearch="true"
                                >
                                  District
                                </TableCell>
                              )}

                            {localStorage.getItem("showCompanyFlag") &&
                              localStorage.getItem("showCompanyFlag") !=
                              2 && (
                                <TableCell
                                  align="center"
                                  sx={{ width: "15%", color: "#000" }}
                                  canSearch="true"
                                >
                                  Area
                                </TableCell>
                              )}
                            <TableCell
                              align="center"
                              sx={{ width: "3%", color: "#000" }}
                              canSearch="true"
                            >
                              Beat Count
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ width: "3%", color: "#000" }}
                              canSearch="true"
                            >
                              Total Outlet
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ width: "3%", color: "#000" }}
                              canSearch="true"
                            >
                              Productive Call
                            </TableCell>
                            {localStorage.getItem("showCompanyFlag") &&
                              localStorage.getItem("showCompanyFlag") == 2 && (
                                <TableCell
                                  align="center"
                                  sx={{ width: "3%", color: "#000" }}
                                  canSearch="true"
                                >
                                  Remote Order
                                </TableCell>
                              )}


                            {localStorage.getItem("showCompanyFlag") &&
                              localStorage.getItem("showCompanyFlag") !=
                              2 && (
                                <TableCell
                                  align="center"
                                  sx={{ width: "3%", color: "#000" }}
                                  canSearch="true"
                                >
                                  Unvisited Outlet
                                </TableCell>
                              )}

                            <TableCell
                              align="center"
                              sx={{ width: "3%", color: "#000" }}
                              canSearch="true"
                            >
                              Zero Order
                            </TableCell>

                            {localStorage.getItem("showCompanyFlag") &&
                              localStorage.getItem("showCompanyFlag") == 2 && (
                                <TableCell
                                  align="center"
                                  sx={{ width: "3%", color: "#000" }}
                                  canSearch="true"
                                >
                                  Total Order
                                </TableCell>
                              )}

                            <TableCell
                              align="center"
                              sx={{ width: "3%", color: "#000" }}
                              canSearch="true"
                            >
                              Total Order Amount
                            </TableCell>

                            {localStorage.getItem("showCompanyFlag") &&
                              localStorage.getItem("showCompanyFlag") !=
                              2 && (
                                <TableCell
                                  align="center"
                                  sx={{ width: "3%", color: "#000" }}
                                  canSearch="true"
                                >
                                  Total Sales Amount
                                </TableCell>
                              )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {dashboardData.salesInfo.length != 0 &&
                            this.getFilteredTableDataForSalesTeamList(
                              dashboardData.salesInfo
                            ).map((salesTeamObject, index) => {
                              return (
                                <TableRow>
                                  <TableCell align="center" canSearch="true">
                                    {index + 1}
                                  </TableCell>
                                  <TableCell width="2%" canSearch="true">
                                    <Link
                                      color={cyanBlue}
                                      component={RouterLink}
                                      to={
                                        "/sales/" +
                                        salesTeamObject.salesTeamId +
                                        "/" +
                                        salesTeamObject.userTypeId
                                      }
                                    >
                                      {salesTeamObject.name}
                                    </Link>
                                  </TableCell>
                                  {localStorage.getItem("showCompanyFlag") &&
                                    localStorage.getItem("showCompanyFlag") !=
                                    2 && (
                                      <TableCell align="left" canSearch="true">
                                        {salesTeamObject.district_names}
                                      </TableCell>
                                    )}

                                  {localStorage.getItem("showCompanyFlag") &&
                                    localStorage.getItem("showCompanyFlag") !=
                                    2 && (
                                      <TableCell align="left" canSearch="true">
                                        {salesTeamObject.area_names}
                                      </TableCell>
                                    )}
                                  <TableCell
                                    width="2%"
                                    align="right"
                                    canSearch="true"
                                  >
                                    <div
                                      onClick={(e) =>
                                        this.salesTeamHandler(
                                          e,
                                          salesTeamObject.salesTeamId
                                        )
                                      }
                                      style={{
                                        color: cyanBlue,
                                        cursor: "pointer",
                                      }}
                                    >
                                      {salesTeamObject.beatCount}
                                    </div>
                                  </TableCell>
                                  <TableCell align="right" canSearch="true">
                                    {salesTeamObject.totalOutletCount}
                                  </TableCell>
                                  <TableCell align="right" canSearch="true">
                                    {salesTeamObject.productiveCall}
                                  </TableCell>
                                  {localStorage.getItem("showCompanyFlag") &&
                                    localStorage.getItem("showCompanyFlag") ==
                                    2 && (
                                      <TableCell align="right" canSearch="true">
                                        {salesTeamObject.remoteOrderCount}
                                      </TableCell>
                                    )}

                                  {localStorage.getItem("showCompanyFlag") &&
                                    localStorage.getItem("showCompanyFlag") !=
                                    2 && (
                                      <TableCell align="right" canSearch="true">
                                        {salesTeamObject.unvisitedOutletCount}
                                      </TableCell>
                                    )}

                                  <TableCell align="right" canSearch="true">
                                    {salesTeamObject.zeroOrderCount}
                                  </TableCell>

                                  {localStorage.getItem("showCompanyFlag") &&
                                    localStorage.getItem("showCompanyFlag") ==
                                    2 && (
                                      <TableCell align="right" canSearch="true">
                                        {salesTeamObject.totalOrderCall}
                                      </TableCell>
                                    )}

                                  <TableCell align="right" canSearch="true">
                                    {Number(salesTeamObject.totalAmount).toFixed(2)}
                                  </TableCell>

                                  {localStorage.getItem("showCompanyFlag") &&
                                    localStorage.getItem("showCompanyFlag") !=
                                    2 && (
                                      <TableCell align="right" canSearch="true">
                                        {Number(salesTeamObject.totalSalesAmt).toFixed(2)}
                                      </TableCell>
                                    )}
                                </TableRow>
                              );
                            })}

                          <TableRow>
                            {localStorage.getItem("showCompanyFlag") &&
                              localStorage.getItem("showCompanyFlag") == 2 ? (
                              <TableCell align="right" width="2%" colSpan={3}>
                                Total
                              </TableCell>
                            ) : (
                              <TableCell align="right" width="2%" colSpan={5}>
                                Total
                              </TableCell>
                            )}
                            <TableCell align="right">
                              {totalOutletCountLocalStorage ? totalOutletCountLocalStorage : totalOutletCount}
                            </TableCell>
                            <TableCell align="right">
                              {productiveCallLocalStorage ? productiveCallLocalStorage : productiveCall}
                            </TableCell>
                            {localStorage.getItem("showCompanyFlag") &&
                              localStorage.getItem("showCompanyFlag") == 2 && (
                                <TableCell align="right">
                                  {remoteOrderLocalStorage ? remoteOrderLocalStorage : remoteOrder}
                                </TableCell>
                              )}
                            {localStorage.getItem("showCompanyFlag") &&
                              localStorage.getItem("showCompanyFlag") !=
                              2 && (
                                <TableCell align="right">{unvisitedLocalStorage ? unvisitedLocalStorage : unvisited}</TableCell>
                              )}
                            <TableCell align="right">{zeroLocalStorage ? zeroLocalStorage : zero}</TableCell>

                            {localStorage.getItem("showCompanyFlag") &&
                              localStorage.getItem("showCompanyFlag") == 2 && (
                                <TableCell align="right">
                                  {totalOrderCallLocalStorage ? totalOrderCallLocalStorage : totalOrderCall}
                                </TableCell>
                              )}

                            <TableCell align="right">
                              {totalAmountLocalStorage ? Number(totalAmountLocalStorage).toFixed(2) : totalAmount.toFixed(2)}
                            </TableCell>
                            {localStorage.getItem("showCompanyFlag") &&
                              localStorage.getItem("showCompanyFlag") !=
                              2 && (
                                <TableCell align="right">{salesAmtLocalStorage ? Number(salesAmtLocalStorage).toFixed(2) : salesAmt.toFixed(2)}</TableCell>
                              )}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {beatPopupFlag && (
                      <BeatPopUp
                        open={beatPopupFlag}
                        handleCloses={this.handleClosesSalesTeam}
                        columns={[
                          {
                            title: "Sr. No.",
                            name: "index",
                            positionCenter: false,
                            showInscreen: true,
                            alignCenter: "center",
                            canSearch: true,
                          },
                          {
                            title: "Beat",
                            name: "beatName",
                            positionCenter: false,
                            showInscreen: true,
                            alignCenter: "left",
                            canSearch: true,
                          },
                        ]}
                        rows={beatList?.beatFromSalesTeamId}
                      />
                    )}
                  </div>
                </div>
              </div>

              {/* Person-wise sales Div starts */}

              {/* Sales & Manpower Div end */}

              <footer className="footer">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-6">2022 Â© Byte Elephants. </div>
                    <div className="col-sm-6">
                      <div className="text-sm-end d-none d-sm-block">
                        {" "}
                        Designed &amp; Developed by Byte Elephants Technologies
                        PVT LTD{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </footer>
            </div>
          </div>

          {workNotStartedFlag && (
            <WorkNotStartedCountPopUp
              open={workNotStartedFlag}
              handleCloses={this.handleClosesWorkNotStarted}
              columns={[
                {
                  title: "Sr. No.",
                  name: "index",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "center",
                  canSearch: true,
                },
                {
                  title: "User Name",
                  name: "userName",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "left",
                  canSearch: true,
                },
                {
                  title: "Name",
                  name: "name",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "left",
                  canSearch: true,
                },
                {
                  title: "Stock Holder Type",
                  name: "stockHolderType",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "left",
                  canSearch: true,
                },
                {
                  title: "Address",
                  name: "address",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "left",
                  canSearch: true,
                },
              ]}
              rows={workNotStartedCountList ? workNotStartedCountList : []}
            />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  common: state.common,
  dashboardData: state.adminDashboard,
  stockStakeHolderTypeList: state.stockStakeHolderType,
  zoneList: state.zone,
  areaList: state.area,
  dashboardDetails: state.dashboard,
  billingHistoryList: state.billingHistory,
  subCategoryList: state.subCategory,
  beatList: state.beat,
});

const mapDispatchToProps = {
  onToggle,
  showLoador,
  showNotification,
  getRegionListFromYearAndMonth,
  getAttendanceTargetAchievmentForRegion,
  getZoneListFromYearAndMonth,
  getStockStakeHolderType,
  getSalesListFromYearAndMonth,
  getMonthYearTarget,
  getMonthYearTargetForSales,
  setRegionListFromYearAndMonth,
  getSalesGraph,
  getZoneStatewiseListFromYearAndMonth,
  getAdminDashboard,
  getCategorywiseBillNew,
  getSalesTeamInfoByDatesNew,
  getPackagingTypeNew,
  getSubCategoryPackagingType,
  getCategoryByPackagingType,
  getTopProductOrderListFromYearAndMonthBillWise,
  getBeatFromSalesTeamId,
  getSelectedBeatCountFromSalesTeamIdAndDate,
  getDistrictListFromYearAndMonth,
  getTalukaListFromYearAndMonth,
  getUserWorkSummary,
};
export default connect(mapStateToProps, mapDispatchToProps)(NewAdminDashboard);
