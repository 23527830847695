import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import { withRouter } from "../../../components/withRouter";
import { noInternetMsg, serverMsg } from "../../../config/messageconstant";
import { stackHolderJson } from "../../../DynamicFormsJson/MastersJSON/StackHolder";
import { getBrand } from "../../../Slice/brand.slice";
import { getDistrictByZone } from "../../../Slice/district.slice";
import { getRegionNew, getZoneByRegionIds } from "../../../Slice/region.slice";
import {
  getAreaByDistrictPostNew,
  getAreaByDistrictPost,
  getOrderToListPost,
} from "../../../Slice/StackHolder.slice";
import { getStateByRegion } from "../../../Slice/state.slice";
import { getStockStakeHolderType } from "../../../Slice/stockStakeHolderType.slice";
import { getZoneByState } from "../../../Slice/zone.slice";
import { getPaymentTerm } from "../../../Slice/paymentTerm.Slice";
import { apiGet } from "../../../utils/api_service";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
class StockHolderEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stockStakeHolder: "",
      stockStakeHolderName: "",
      isInitialLoad: true,
      dynamicMasterData: {
        stateIDsDetails: this.props.stateList?.stateByRegion,
        stateDetails: this.props.stateList?.stateByRegion,
        regionDetail: this.props.regionList?.region,
        zoneDetail: this.props.zoneList?.zoneByState,
        districtDetail: this.props.districtList?.districtByZone,
        districtIdsDetail: this.props.districtList?.districtByZone,
        areaDetail: this.props.stackHolderList?.areaByDistrictPost,
        paymentTermDetail: this.props.paymentTermList?.paymentTerm,
        stakeHolderTypeDetail:
          this.props.stakeHolderTypeList?.stockStakeHolderType,
        orderList: this.props.stackHolderList?.orderTo,
        brandDetail: this.props.brandList?.brand,
        zoneIds: "",
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.regionList !== nextProps.regionList) {
      if (this.props.regionList?.region !== nextProps.regionList?.region) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            regionDetail: nextProps.regionList?.region,
          },
        });
      }
    }

    if (this.props.paymentTermList !== nextProps.paymentTermList) {
      if (this.props.paymentTermList?.paymentTerm !== nextProps.paymentTermList?.paymentTerm) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            paymentTermDetail: nextProps.paymentTermList?.paymentTerm,
          },
        });
      }
    }

    if (this.props.stateList !== nextProps.stateList) {
      if (
        this.props.stateList?.stateByRegion !==
        nextProps.stateList?.stateByRegion
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stateDetails: nextProps.stateList?.stateByRegion,
            stateIDsDetails: nextProps.stateList?.stateByRegion,
          },
        });
      }
    }

    if (this.props.brandList !== nextProps.brandList) {
      if (this.props.brandList?.brand !== nextProps.brandList?.brand) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            brandDetail: nextProps.brandList?.brand,
          },
        });
      }
    }
    if (this.props.zoneList !== nextProps.zoneList) {
      if (
        this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            zoneDetail: nextProps.zoneList?.zoneByState,
          },
        });
      }
    }
    if (this.props.districtList !== nextProps.districtList) {
      if (
        this.props.districtList?.districtByZone !==
        nextProps.districtList?.districtByZone
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            districtDetail: nextProps.districtList?.districtByZone,
            districtIdsDetail: nextProps.districtList?.districtByZone,
          },
        });
      }
    }
    if (this.props.stakeHolderTypeList !== nextProps.stakeHolderTypeList) {
      if (
        this.props.stakeHolderTypeList?.stockStakeHolderType !==
        nextProps.stakeHolderTypeList?.stockStakeHolderType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stakeHolderTypeDetail:
              nextProps.stakeHolderTypeList?.stockStakeHolderType,
          },
        });
      }
    }
    if (this.props.stackHolderList !== nextProps.stackHolderList) {
      if (
        this.props.stackHolderList?.orderToAdmin !==
        nextProps.stackHolderList?.orderToAdmin ||
        this.props.stackHolderList?.orderTo !==
        nextProps.stackHolderList?.orderTo
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            orderList:
              nextProps.stackHolderList?.orderToAdmin == "1"
                ? [
                  { id: "0", name: "Admin" },
                  ...nextProps.stackHolderList?.orderTo,
                ]
                : nextProps.stackHolderList?.orderTo,
          },
        });
      }

      if (
        this.props.stackHolderList?.areaByDistrictPost !==
        nextProps.stackHolderList?.areaByDistrictPost
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.stackHolderList?.areaByDistrictPost,
          },
        });
      }
    }
  }

  async componentDidMount() {
    const {
      getStockStakeHolderType,
      getBrand,
      getRegionNew,
      getPaymentTerm,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {

      const moduleObjData = localStorage.getItem("moduleObj");
      const moduleObjDataObj = moduleObjData != null ? JSON.parse(moduleObjData) : {};

      this.setState({ moduleObjDataObj });

      showLoador({ loador: true });
      getRegionNew().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

      showLoador({ loador: true });
      getBrand().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

      showLoador({ loador: true });
      getPaymentTerm().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

      showLoador({ loador: true });
      await getStockStakeHolderType({ userTypeIds: 2 }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

      showLoador({ loador: true });
      await apiGet({
        url: stackHolderJson.apiBaseURL + "/" + this.props.params.id,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          if (
            data.data.stockStakeHolderType &&
            data.data.stockStakeHolderType.id
          ) {
            const filterData =
              this.state.dynamicMasterData.stakeHolderTypeDetail.filter(
                (row) => row.id == data.data.stockStakeHolderType.id
              );
            this.setState({
              stockStakeHolder: data.data.stockStakeHolderType.id,
              stockStakeHolderName:
                filterData.length != 0 ? filterData[0].sortOrder : "",
            });
          }
        }
      });

      if (this.props.stackHolderList?.orderToAdmin) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            orderList:
              this.props.stackHolderList?.orderToAdmin == "1"
                ? [
                  { id: "0", name: "Admin" },
                  ...this.props.stackHolderList?.orderTo,
                ]
                : this.props.stackHolderList?.orderTo,
          },
        });
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  getListById = async (data) => {
    const {
      stockStakeHolder,
      stockStakeHolderName,
      zoneIds,
      dynamicMasterData,
      isInitialLoad,
    } = this.state;
    const {
      getStateByRegion,
      getZoneByState,
      getDistrictByZone,
      getAreaByDistrictPostNew,
      getAreaByDistrictPost,
      showLoador,
      getOrderToListPost,
      showNotification,
    } = this.props;
    if (navigator.onLine) {

      if (data.zoneIds) {
        this.setState({
          zoneIds: data.zoneIds,
        });
      }

      if (data.region && data.region.id && data.region.id != "") {
        showLoador({ loador: true });
        await getStateByRegion({ regionIds: data.region.id }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.stateIds && data.stateIds != "") {
        showLoador({ loador: true });
        await getZoneByState({ stateIds: data.stateIds }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.zoneIds && data.zoneIds != "") {
        showLoador({ loador: true });
        await getDistrictByZone({ zoneIds: data.zoneIds }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.stockStakeHolderType && data.stockStakeHolderType.id) {
        const filterData = dynamicMasterData.stakeHolderTypeDetail.filter(
          (row) => row.id == data.stockStakeHolderType.id
        );
        this.setState({
          stockStakeHolder: data.stockStakeHolderType.id,
          stockStakeHolderName:
            filterData.length != 0 ? filterData[0].sortOrder : "",
        });
      }


      if (isInitialLoad && data.districtId && data.districtId != "") {
        this.setState({ isInitialLoad: false });
        let areaData = {
          stockStakeHolderTypeId: stockStakeHolder,
          stockStakeHolderId: this.props.params.id,
          zoneIds: typeof zoneIds == "string" ? [zoneIds] : zoneIds,
          districtIds:
            typeof data.districtId == "string"
              ? [data.districtId]
              : data.districtId,
        };
        showLoador({ loador: true });
        await getAreaByDistrictPost({
          areaDataForPost: areaData,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });

      }


      if (!isInitialLoad && data.districtId && data.districtId != "") {
        let areaData = {
          stockStakeHolderTypeId: stockStakeHolder,
          stockStakeHolderId: this.props.params.id,
          zoneIds: typeof zoneIds == "string" ? [zoneIds] : zoneIds,
          districtIds:
            typeof data.districtId == "string"
              ? [data.districtId]
              : data.districtId,
        };
        showLoador({ loador: true });
        await getAreaByDistrictPostNew({
          areaDataForPost: areaData,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }

      if (data.areaIds && data.areaIds != "") {

        let dataToSend = {
          stockStakeHolderTypeId: stockStakeHolder,

          areaIds: typeof data.areaIds == "string"
            ? data.areaIds.split(",")
            : data.areaIds
        }

        showLoador({ loador: true });
        await getOrderToListPost({ jsonData: dataToSend }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };


  callBeforeSaveMethod = async (dataToSave, editData) => {
    if (
      Object.keys(editData).length == 0 ||
      (Object.keys(editData).length != 0 &&
        editData.billName.trim() != dataToSave.billName.trim())
    ) {
      const { showLoador, showNotification } = this.props;
      if (navigator.onLine) {
        showLoador({ loador: true });
        const response = await apiGet({
          url:
            stackHolderJson.apiBaseURL +
            "/bill-name-exist-or-not?billName=" +
            dataToSave.billName,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (success) {
            if (!data.data.error) {
              showNotification({
                msg: data.data.message,
                severity: "error",
              });
            }
            return data.data.error;
          }
          return false;
        });
        return response;
      } else {
        showNotification({ msg: noInternetMsg, severity: "error" });
      }
    }
    return true;
  };

  render() {
    const { dynamicMasterData, stockStakeHolderName } = this.state;

    return (
      <>
        {stockStakeHolderName != "" && (
          <DynamicForm
            callBeforeSave={true}
            callBeforeSaveMethod={this.callBeforeSaveMethod}
            formPathEdit={stackHolderJson.formPathEdit}
            showPdfDownload={false}
            showExcelDownload={false}
            showBackToList={true}
            getListById={this.getListById}
            screenTitle={stackHolderJson.screenTitle}
            fieldMeta={
              localStorage.getItem("showMultipleAreasToDistributor") &&
                localStorage.getItem("showMultipleAreasToDistributor") == 1 ?
                (stackHolderJson.fieldMetaForEditMultipleArea) :
                (
                  stockStakeHolderName ==
                    localStorage.getItem("stockHolderSortOrderNo")
                    ? stackHolderJson.fieldMetaForEdit
                    : stackHolderJson.fieldMetaForEditMultipleArea
                )
            }
            dynamicMasterData={dynamicMasterData}
            apiBaseURL={stackHolderJson.apiBaseURL}
            showSaveNextBtn={stackHolderJson.showSaveNextBtn}
            isEditURL={stackHolderJson.isEditURL}
            isLog={true}
            type={1}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  regionList: state.region,
  stateList: state.states,
  zoneList: state.zone,
  districtList: state.district,
  talukaList: state.taluka,
  stackHolderList: state.stockholder,
  stakeHolderTypeList: state.stockStakeHolderType,
  orderToList: state.orderTo,
  stateList: state.states,
  brandList: state.brand,
  paymentTermList: state.paymentTerm,
});
const mapDispatchToProps = {
  getAreaByDistrictPostNew,
  getAreaByDistrictPost,
  getStockStakeHolderType,
  getRegionNew,
  getOrderToListPost,
  getStateByRegion,
  getZoneByRegionIds,
  getDistrictByZone,
  showLoador,
  showNotification,
  getBrand,
  getZoneByState,
  getPaymentTerm,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(StockHolderEdit);
