import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import { SubChannelColumns } from "../../../tableColumns/table-columns";
import { getSubChannel } from "../../../Slice/channel.slice";
import { SubChannelJson } from "../../../DynamicFormsJson/MastersJSON/subChannel";

class SubChannelTable extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { subChannel, getSubChannel } = this.props;
    return (
      <>
        <DynamicMainScreen
          formPath={SubChannelJson.formPath}
          screenTitle={SubChannelJson.screenTitle}
          fieldMeta={SubChannelJson.fieldMeta}
          showPdfDownload={SubChannelJson.showPdfDownload}
          showExcelDownload={true}
          showExtension={false}
          filterDataExcel={true}
          pdfFileName={SubChannelJson.pdfFileName}
          excelFileName={SubChannelJson.excelFileName}
          showAddButton={SubChannelJson.showAddButton}
          tableColumnsToFilter={SubChannelJson.tableColumnsToFilter}
          tableColumns={SubChannelColumns}
          tableData={subChannel?.subChannel}
          getTableData={getSubChannel}
          apiBaseURL={SubChannelJson.apiBaseURL}
          baseIdColumn={SubChannelJson.baseIdColumn}
          isNote={true}
          isNoteValue={"Sub Channel"}
          showDeleteIcon={true}
          isActiveURL={SubChannelJson.isActiveURL}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  subChannel: state.channel,
});
const mapDispatchToProps = {
  getSubChannel,
};
export default connect(mapStateToProps, mapDispatchToProps)(SubChannelTable);
