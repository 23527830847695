import { Paper } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { CentralizedRateStructureJson } from "../../DynamicFormsJson/MastersJSON/centralizedRateStructure";
import { getAreaByTalukaPostBody } from "../../Slice/area.slice";
import { getDistrictByZone } from "../../Slice/district.slice";
import { getOutletByAreaForCentralRateStrutcure, setOutletByAreaForCentralRateStrutcure } from "../../Slice/outlet.slice";
import { getRegionNew } from "../../Slice/region.slice";
import { getStateByRegion } from "../../Slice/state.slice";
import { getTalukaByDistrict } from "../../Slice/taluka.slice";
import { getZoneByState } from "../../Slice/zone.slice";
import CheckBoxSelectionTable from "../../components/Comman/RT/CheckBoxSelectionTable";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import endpoint from "../../config/endpoints";
import {
    checkedListEmptyMsg,
    noInternetMsg,
    saveWarningMsg,
    savemsg,
    serverMsg
} from "../../config/messageconstant";
import { apiGet, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";

const columns = [
    {
        title: "Sr. No.",
        name: "index",
        positionCenter: false,
        showInscreen: true,
        alignCell: "center",
        align: "center",
    },
    {
        title: "Outlet",
        name: "firmName",
        positionCenter: false,
        showInscreen: true,
        alignCell: "center",
        alignCenter: "left",
        canSearch: true,
    },
    {
        title: "Beat",
        name: "beatName",
        positionCenter: false,
        showInscreen: true,
        alignCell: "center",
        alignCenter: "left",
        canSearch: true,
    },
    {
        title: "Area",
        name: "areaName",
        positionCenter: false,
        showInscreen: true,
        alignCell: "center",
        alignCenter: "left",
        canSearch: true,
    },

];
class CentralizedRateStructureAssignation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            salesTeamId: "",
            setList: [],
            checkList: [],
            isError: false,
            areaName: "",
            jsonByStructureId: "",
            open: false,
            DataToSet: [],
            selection: [],
            dynamicMasterData: {
                stateDetail: this.props.stateList?.stateByRegion,
                zoneDetail: this.props.zoneList?.zoneByState,
                districtDetail: this.props.districtList?.districtByZone,
                talukaDetail: this.props.talukaList?.talukaByDistrict,
                areaDetail: this.props.areaList?.areaByTaluka,
            },
        };
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.stockStakeHolderType !== nextProps.stockStakeHolderType) {
            if (
                this.props.stockStakeHolderType?.stockStakeHolderType !==
                nextProps.stockStakeHolderType?.stockStakeHolderType
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        stockStakeHolderTypeDetail:
                            nextProps.stockStakeHolderType?.stockStakeHolderType,
                    },
                });
            }
        }
        if (this.props.regionList !== nextProps.regionList) {
            if (this.props.regionList?.region !== nextProps.regionList?.region) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        regionDetail: nextProps.regionList?.region,
                    },
                });
            }
        }
        if (this.props.stateList !== nextProps.stateList) {
            if (
                this.props.stateList?.stateByRegion !==
                nextProps.stateList?.stateByRegion
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        stateDetail: nextProps.stateList?.stateByRegion,
                    },
                });
            }
        }
        if (this.props.zoneList !== nextProps.zoneList) {
            if (
                this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        zoneDetail: nextProps.zoneList?.zoneByState,
                    },
                });
            }
        }
        if (this.props.districtList !== nextProps.districtList) {
            if (
                this.props.districtList?.districtByZone !==
                nextProps.districtList?.districtByZone
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        districtDetail: nextProps.districtList?.districtByZone,
                    },
                });
            }
        }
        if (this.props.talukaList !== nextProps.talukaList) {
            if (
                this.props.talukaList?.talukaByDistrict !==
                nextProps.talukaList?.talukaByDistrict
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        talukaDetail: nextProps.talukaList?.talukaByDistrict,
                    },
                });
            }
        }
        if (this.props.areaList !== nextProps.areaList) {
            if (
                this.props.areaList?.areaByTaluka !== nextProps.areaList?.areaByTaluka
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        areaDetail: nextProps.areaList?.areaByTaluka,
                    },
                });
            }
        }
    }

    async componentDidMount() {
        const { getRegionNew, setOutletByAreaForCentralRateStrutcure, showLoador, showNotification } =
            this.props;
        if (navigator.onLine) {

            setOutletByAreaForCentralRateStrutcure({ row: [] });

            showLoador({ loador: true });
            await getRegionNew().then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });

            if (this.props.params.id) {
                showLoador({ loador: true });
                apiGet({
                    url: endpoint.centralRateStructureHeader + "/" + this.props.params.id,
                }).then(({ data, success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    } else {

                        if (data && Object.keys(data.data) !== null) {
                            let rowData = data.data;
                            this.setState({
                                DataToSet: [
                                    {
                                        xs: 12,
                                        sm: 5.9,
                                        md: 5.9,
                                        lg: 5.9,
                                        label: "Structure Name",
                                        value:
                                            rowData.name !== null
                                                ? rowData.name
                                                : "",
                                    },
                                    {
                                        xs: 12,
                                        sm: 5.9,
                                        md: 5.9,
                                        lg: 5.9,
                                        label: "Structure Date",
                                        value: rowData.structureDate !== null ? rowData.structureDate : "",
                                    },
                                ],
                            })
                        }

                    }
                });
            }
        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }

    getListById = async (data) => {
        const {
            getStateByRegion,
            getZoneByState,
            getDistrictByZone,
            getTalukaByDistrict,
            getAreaByTalukaPostBody,
            showLoador,
            showNotification,
        } = this.props;
        if (navigator.onLine) {
            if (data.region && data.region != "") {
                showLoador({ loador: true });
                await getStateByRegion({ regionIds: data.region }).then(
                    ({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    }
                );
            }
            if (data.state && data.state != "") {
                showLoador({ loador: true });
                await getZoneByState({ stateIds: data.state }).then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }
            if (data.zone && data.zone != "") {
                showLoador({ loador: true });
                await getDistrictByZone({ zoneIds: data.zone }).then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }
            if (data.district && data.district != "") {
                showLoador({ loador: true });
                await getTalukaByDistrict({ districtIds: data.district }).then(
                    ({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    }
                );
            }
            if (data.taluka && data.taluka != "") {
                let talukaIdsByPost = {
                    talukaIds: data.taluka,
                };
                showLoador({ loador: true });
                await getAreaByTalukaPostBody({ talukaIds: talukaIdsByPost }).then(
                    ({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    }
                );
            }
        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };

    onSearch = (data) => {

        console.log(data);
        const {
            getOutletByAreaForCentralRateStrutcure,
            setOutletByAreaForCentralRateStrutcure,
            showLoador,
            showNotification,
        } = this.props;
        if (navigator.onLine) {
            if (this.props.params.id) {

                showLoador({ loador: true });
                getOutletByAreaForCentralRateStrutcure({
                    areaId: data.area,
                }).then(({ response, success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }
        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };

    getSelectedList = (e) => {
        this.setState({
            selection: e,
        });
        const rowList = this.props.outletList.outletByAreaForCentralStructure.map(
            (rowData) => {
                const filterData = e.filter((id) => rowData.id == id);
                return { ...rowData, checked: filterData.length != 0 ? true : false };
            }
        );
        this.props.setOutletByAreaForCentralRateStrutcure({ row: rowList });
    };

    saveAssignStructure = (data) => {
        const { showNotification, showLoador, outletList } = this.props;


        let assignStructureList =
            outletList.outletByAreaForCentralStructure.filter(
                (row) => row.checked
            );

        let outletIds = assignStructureList.flatMap(row => row.id.split(","));

        let assignStructure = {
            outletIds: outletIds,
        };

        if (
            outletList.outletByAreaForCentralStructure.filter((row) => row.checked)
                .length === 0
        ) {
            showNotification({
                msg: checkedListEmptyMsg,
                severity: "error",
            });
        } else {
            swal({
                title: "Are you sure?",
                text: saveWarningMsg,
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    if (navigator.onLine) {
                        showLoador({ loador: true });
                        apiPost({
                            url: endpoint.centralRateStructureAssignation +
                                "/save-assignation?centralRateStructureHeaderId=" + this.props.params.id,
                            postBody: assignStructure,
                        }).then(({ data, success }) => {
                            showLoador({ loador: false });
                            if (success) {
                                showNotification({
                                    msg: savemsg,
                                });
                                this.props.navigate(-1);
                            } else {
                                showNotification({
                                    msg: serverMsg,
                                    severity: "error",
                                });
                                showLoador({ loador: false });
                            }
                        });
                    }
                }
            });
        }
    };

    render() {
        const { outletList } = this.props;
        const { dynamicMasterData, DataToSet, selection } = this.state;

        return (
            <>
                <Paper
                    sx={{
                        padding: 2,
                    }}
                    elevation={0}
                >
                    <DynamicFormWithoutSave
                        DataToSet={DataToSet}
                        dynamicMasterData={dynamicMasterData}
                        showBackToList={true}
                        getListById={this.getListById}
                        showSaveBtn={true}
                        showSaveBtnMain={false}
                        showTitle={CentralizedRateStructureJson.showTitle}
                        screenTitle={"Centralized Rate Structure Assignation"}
                        fieldMeta={CentralizedRateStructureJson.fieldMetaForAssignation}
                        showCancel={CentralizedRateStructureJson.showCancel}
                        apiBaseURL={CentralizedRateStructureJson.apiBaseURL}
                        showSaveNextBtn={CentralizedRateStructureJson.showSaveNextBtn}
                        saveBtnText={"Search"}
                        onSave={this.onSearch}
                    />
                    <CheckBoxSelectionTable
                        disabled={false}
                        selection={selection}
                        rows={outletList.outletByAreaForCentralStructure}
                        columns={columns}
                        setList={setOutletByAreaForCentralRateStrutcure}
                        showDetailsIcon={false}
                        isActionColActive={false}
                        onSelectionChange={this.getSelectedList}
                    />
                    {outletList.outletByAreaForCentralStructure.length != 0 && (
                        <>
                            <br />
                            <DynamicFormWithoutSave
                                showBackToList={false}
                                dynamicMasterData={dynamicMasterData}
                                showSaveBtn={true}
                                showSaveBtnMain={false}
                                showTitle={false}
                                screenTitle={""}
                                fieldMeta={CentralizedRateStructureJson.footerFieldMeta}
                                showCancel={false}
                                apiBaseURL={CentralizedRateStructureJson.apiBaseURL}
                                showSaveNextBtn={CentralizedRateStructureJson.showSaveNextBtn}
                                saveBtnText={"Submit"}
                                onSave={this.saveAssignStructure}
                            />
                        </>
                    )}

                </Paper>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    areaList: state.area,
    regionList: state.region,
    stateList: state.states,
    zoneList: state.zone,
    districtList: state.district,
    talukaList: state.taluka,
    stockStakeHolderType: state.stockStakeHolderType,
    outletList: state.productRateStructure,
    outletList: state.outlet,
});
const mapDispatchToProps = {
    getStateByRegion,
    getZoneByState,
    getRegionNew,
    getDistrictByZone,
    getTalukaByDistrict,
    getAreaByTalukaPostBody,
    showLoador,
    showNotification,
    getOutletByAreaForCentralRateStrutcure,
    setOutletByAreaForCentralRateStrutcure,
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(CentralizedRateStructureAssignation);