import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import {
  beatTargetColumns,
  beatNameColumns,
} from "../../tableColumns/table-columns";
import { stockHolderTargetJson } from "../../DynamicFormsJson/Transaction/stockholderTarget";
import { getBeatTarget } from "../../Slice/beatTarget.slice";
import { compose } from "@reduxjs/toolkit";
import { withRouter } from "../../components/withRouter";
class StockHolderTargetList extends React.Component {
  constructor(props) {
    super(props);
  }
  rowView = (rowData) => {
    console.log(rowData);
    this.props.navigate(
      stockHolderTargetJson.formPathView + "/" + rowData.yearId
    );
  };
  rowEdit = (rowData) => {
    this.props.navigate("/stock-holder-target-edit" + "/" + rowData.yearId);
  };
  render() {
    const { beatTargegtList, getBeatTarget } = this.props;
    return (
      <>
        <DynamicMainScreen
          formPath={stockHolderTargetJson.formPath}
          screenTitle={stockHolderTargetJson.screenTitle}
          showViewButtonInTable={true}
          fieldMeta={stockHolderTargetJson.fieldMeta}
          showAddButton={stockHolderTargetJson.showAddButton}
          tableColumns={beatTargetColumns}
          tableData={beatTargegtList?.beatTarget}
          getTableData={getBeatTarget}
          apiBaseURL={stockHolderTargetJson.apiBaseURL}
          baseIdColumn={stockHolderTargetJson.baseIdColumn}
          tableColumnsToFilter={stockHolderTargetJson.tableColumnsToFilter}
          showEditIcon={true}
          staticView={true}
          rowView={this.rowView}
          rowEdit={this.rowEdit}
          showDeleteIcon={false}
          isLog={true}
          type={1}
          showPdfDownload={false}
          showExcelDownload={false}
          formPathView={stockHolderTargetJson.formPathView}
          showPopUpDataList={[
            {
              dataKey: "beatCount",
              columns: beatNameColumns,
              title: "Beat List",
              header: {
                dataKey: "yearName",
                label: "Year Name",
              },
            },
          ]}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  beatTargegtList: state.beatTarget,
});
const mapDispatchToProps = {
  getBeatTarget,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(StockHolderTargetList);
