import { Grid, Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { ButtonCompo } from "../../../components/Comman/Button";
import RTTable from "../../../components/Comman/RT/RTTable";
import DynamicFormForDocumentUpload from "../../../components/dynamicscreens/DynamicFormForDocumentUpload";
import DynamicFormWithoutValidation from "../../../components/dynamicscreens/DynamicFormWithoutValidation";
import { withRouter } from "../../../components/withRouter";
import endpoint from "../../../config/endpoints";
import {
  atleastOne,
  deleteFailedMsg,
  deletemsg,
  deleteWarningMsg,
  noInternetMsg,
  saveFailedMsg,
  savemsg,
  saveWarningMsg,
  serverMsg,
  statusmsg,
} from "../../../config/messageconstant";
import { groupProductJson } from "../../../DynamicFormsJson/MastersJSON/groupProduct";
import { getBrand } from "../../../Slice/brand.slice";
import {
  getCategory,
  getSubCategoryByCategoryId,
} from "../../../Slice/category.slice";
import { getPackagingType } from "../../../Slice/packagingType.slice";
import { getTag } from "../../../Slice/tag.slice";
import { getTax } from "../../../Slice/Tax.slice";
import { getUom } from "../../../Slice/uom.slice";
import {
  apiDelete,
  apiGet,
  apiPost,
  apiPostForImage,
} from "../../../utils/api_service";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import moment from "moment";
class GroupProductForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowList: [],
      formErrors: {},
      fieldData: {},
      editData: {},
      submitFlag: false,
      editFlag: false,
      isLog: true,
      moduleObjDataObj: {},
      dynamicMasterData: {
        categoryDetail: this.props.categoryList?.category,
        subCategoryDetail: this.props.categoryList.subCategory,
        stakeHolderUomDetail: this.props.uomList.uom,
        taxDetail: this.props.taxList?.tax,
        brandDetail: this.props.brandList?.brand,
        packagingTypeDetail: this.props.packagingTypeList?.packagingType,
        tagDetail: this.props.tagList?.tag,
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.categoryList !== nextProps.categoryList) {
      if (
        this.props.categoryList?.category !== nextProps.categoryList?.category
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            categoryDetail: nextProps.categoryList?.category,
          },
        });
      }
    }
    if (this.props.categoryList !== nextProps.categoryList) {
      if (
        this.props.categoryList?.subCategory !==
        nextProps.categoryList?.subCategory
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            subCategoryDetail: nextProps.categoryList.subCategory,
          },
        });
      }
    }
    if (this.props.brandList !== nextProps.brandList) {
      if (this.props.brandList?.brand !== nextProps.brandList?.brand) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            brandDetail: nextProps.brandList.brand,
          },
        });
      }
    }
    if (this.props.packagingTypeList !== nextProps.packagingTypeList) {
      if (
        this.props.packagingTypeList?.packagingType !==
        nextProps.packagingTypeList?.packagingType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            packagingTypeDetail: nextProps.packagingTypeList.packagingType,
          },
        });
      }
    }
    if (this.props.uomList !== nextProps.uomList) {
      if (this.props.uomList?.uom !== nextProps.uomList?.uom) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stakeHolderUomDetail: nextProps.uomList.uom,
          },
        });
      }
    }

    if (this.props.taxList !== nextProps.taxList) {
      if (this.props.taxList?.tax !== nextProps.taxList?.tax) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            taxDetail: nextProps.taxList.tax,
          },
        });
      }
    }
    if (this.props.tagList !== nextProps.tagList) {
      if (this.props.tagList?.tag !== nextProps.tagList?.tag) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            tagDetail: nextProps.tagList.tag,
          },
        });
      }
    }
  }

  getListById = async (data) => {
    const { getSubCategoryByCategoryId, showLoador, showNotification } =
      this.props;
    if (data.category) {
      if (navigator.onLine) {
        showLoador({ loador: true });
        await getSubCategoryByCategoryId({ categoryId: data.category.id }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      } else {
        this.props.showNotification({ msg: noInternetMsg, severity: "error" });
      }
    }
  };

  async componentDidMount() {
    this.setState({
      rowList: [],
    });

    const {
      showLoador,
      showNotification,
      getCategory,
      getBrand,
      getPackagingType,
      getUom,
      getTax,
      getTag,
    } = this.props;
    if (navigator.onLine) {

      const moduleObjData = localStorage.getItem("moduleObj");
      const moduleObjDataObj = moduleObjData != null ? JSON.parse(moduleObjData) : {};

      this.setState({ moduleObjDataObj });

      if (this.props.params.id) {
        this.getDataById(this.props.params.id);
      } else {
        this.setState({
          submitFlag: true,
        });
      }

      showLoador({ loador: true });
      await getCategory().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getBrand().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getPackagingType().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getUom().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

      showLoador({ loador: true });
      await getTax().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getTag().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  }


  onSearch = (objectToSave, formData, isImageUpload, isToggle, fieldData) => {

    console.log(objectToSave);

    const { showLoador, showNotification } = this.props;
    const { rowList, dynamicMasterData, editData, editFlag } = this.state;

    if (isImageUpload && fieldData.image && fieldData.image !== "" && fieldData.image !== null && fieldData.image.name !== null) {

      const formData = new FormData();

      const imageTime = moment().format("hh:mm:ss") + fieldData.image.name;

      formData.append("file", fieldData.image);

      formData.append("imageName", imageTime);


      showLoador({ loador: true });
      apiPostForImage({
        url: endpoint.uploadImage,
        postBody: formData,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({
            msg: "Image not uploaded",
            severity: "error",
          });
        } else {
          this.setState({
            submitFlag: true,
          });

          let packagingTypeList = dynamicMasterData["packagingTypeDetail"]
            ? dynamicMasterData["packagingTypeDetail"].filter(
              (row) => row.id == objectToSave.packagingType
            )
            : [];
          let uomList = dynamicMasterData["stakeHolderUomDetail"]
            ? dynamicMasterData["stakeHolderUomDetail"].filter(
              (row) => row.id == objectToSave.uom
            )
            : [];
          let taxList = dynamicMasterData["taxDetail"]
            ? dynamicMasterData["taxDetail"].filter(
              (row) => row.id == objectToSave.tax
            )
            : [];
          let tagList = dynamicMasterData["tagDetail"]
            ? dynamicMasterData["tagDetail"].filter(
              (row) => row.id == objectToSave.tag
            )
            : [];

          let record = {
            index: editFlag ? editData.index : rowList.length + 1,
            productName: objectToSave.name != 0 ? objectToSave.name : "-",
            name: objectToSave.name != 0 ? objectToSave.name : "-",
            description:
              objectToSave.description != 0 ? objectToSave.description : "-",
            shortName:
              objectToSave.shortName != 0 ? objectToSave.shortName : "-",
            code: objectToSave.code != 0 ? objectToSave.code : "-",
            productCode: objectToSave.productCode !== "" ? objectToSave.productCode : "-",
            stakeHolderUom: uomList.length != 0 ? uomList[0].name : "-",
            outletUom: uomList.length != 0 ? uomList[0].name : "-",
            taxPercentage: taxList.length != 0 ? taxList[0].name : "-",
            uomConversionValue:
              objectToSave.uomConversionValue != 0
                ? objectToSave.uomConversionValue
                : "-",
            selfLife: objectToSave.selfLife != 0 ? objectToSave.selfLife : "-",
            weight: objectToSave.weight != 0 ? objectToSave.weight : "-",
            image: objectToSave.image != 0 ? objectToSave.image : "-",
            packagingType:
              packagingTypeList.length != 0 ? packagingTypeList[0].name : "-",
            tagIds: objectToSave.tagIds != 0 ? objectToSave.tagIds : "-",
            outletUom: { id: objectToSave.outletUom.id },
            packagingType: { id: objectToSave.packagingType.id },
            stakeHolderUom: { id: objectToSave.stakeHolderUom.id },
            taxPercentage: { id: objectToSave.taxPercentage.id },
            sortOrder:
              objectToSave.sortOrder != 0 ? objectToSave.sortOrder : "-",
            isActive: objectToSave.isActive != 0 ? objectToSave.isActive : "-",
          };

          if (editFlag && editData !== "") {
            record = {
              ...record,
              id: editData.id,
            };
          }

          if (editFlag) {
            const existingList = rowList.filter(
              (row) => row.index !== editData.index
            );
            this.setState({ rowList: [...existingList, record] });
          } else {
            this.setState({ rowList: [...rowList, record] });
          }

          this.setState({
            editFlag: false,
          });
        }
      });
    } else {
      const { rowList, dynamicMasterData, editData, editFlag } = this.state;
      this.setState({
        submitFlag: true,
      });

      let packagingTypeList = dynamicMasterData["packagingTypeDetail"]
        ? dynamicMasterData["packagingTypeDetail"].filter(
          (row) => row.id == objectToSave.packagingType
        )
        : [];
      let uomList = dynamicMasterData["stakeHolderUomDetail"]
        ? dynamicMasterData["stakeHolderUomDetail"].filter(
          (row) => row.id == objectToSave.uom
        )
        : [];
      let taxList = dynamicMasterData["taxDetail"]
        ? dynamicMasterData["taxDetail"].filter(
          (row) => row.id == objectToSave.tax
        )
        : [];
      let tagList = dynamicMasterData["tagDetail"]
        ? dynamicMasterData["tagDetail"].filter(
          (row) => row.id == objectToSave.tag
        )
        : [];

      let record = {
        index: editFlag ? editData.index : rowList.length + 1,
        productName: objectToSave.name != 0 ? objectToSave.name : "-",
        name: objectToSave.name != 0 ? objectToSave.name : "-",
        description:
          objectToSave.description != 0 ? objectToSave.description : "-",
        shortName: objectToSave.shortName != 0 ? objectToSave.shortName : "-",
        code: objectToSave.code != 0 ? objectToSave.code : "-",
        productCode: objectToSave.productCode !== "" ? objectToSave.productCode : "-",
        stakeHolderUom: uomList.length != 0 ? uomList[0].name : "-",
        outletUom: uomList.length != 0 ? uomList[0].name : "-",
        taxPercentage: taxList.length != 0 ? taxList[0].name : "-",
        uomConversionValue:
          objectToSave.uomConversionValue != 0
            ? objectToSave.uomConversionValue
            : "-",
        selfLife: objectToSave.selfLife != 0 ? objectToSave.selfLife : "-",
        weight: objectToSave.weight != 0 ? objectToSave.weight : "-",
        image: objectToSave.image != 0 ? objectToSave.image : "-",
        packagingType:
          packagingTypeList.length != 0 ? packagingTypeList[0].name : "-",
        tagIds: objectToSave.tagIds != 0 ? objectToSave.tagIds : "-",
        outletUom: { id: objectToSave.outletUom.id },
        packagingType: { id: objectToSave.packagingType.id },
        stakeHolderUom: { id: objectToSave.stakeHolderUom.id },
        taxPercentage: { id: objectToSave.taxPercentage.id },
        sortOrder: objectToSave.sortOrder != 0 ? objectToSave.sortOrder : "-",
        isActive: objectToSave.isActive != 0 ? objectToSave.isActive : "-",
      };

      if (editFlag && editData !== "") {
        record = {
          ...record,
          id: editData.id,
        };
      }

      if (editFlag) {
        const existingList = rowList.filter(
          (row) => row.index !== editData.index
        );
        this.setState({ rowList: [...existingList, record] });
      } else {
        this.setState({ rowList: [...rowList, record] });
      }

      this.setState({
        editFlag: false,
      });
    }
  };

  onSubmitData = () => {
    const { showNotification } = this.props;
    const { rowList, fieldData } = this.state;
    if (rowList.length != 0) {
      const groupProductDetails = rowList.map((rowData) => {
        if (
          rowData.id !== null &&
          rowData.id !== "" &&
          rowData.id !== undefined
        ) {
          const rowObj = {
            ...rowData,
            id: rowData.id,
          };
          return rowObj;
        } else {
          return rowData;
        }
      });
      const dataToSave = {
        id: fieldData.id,
        name: fieldData.name,
        shortName: fieldData.shortName,
        code: fieldData.code,
        productCode: fieldData.productCode ? fieldData.productCode : null,
        description: fieldData.description,
        subCategory: fieldData.subCategory,
        brand: fieldData.brand,
        sortOrder: fieldData.sortOrder,
        isActive: fieldData.isActive,
        products: groupProductDetails,
      };
      swal({
        title: "Are you sure?",
        text: saveWarningMsg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.onSave(dataToSave);
        }
      });
    } else {
      showNotification({
        msg: atleastOne,
        severity: "error",
      });
    }
  };

  onSave = (dataToSave) => {
    const { showLoador, showNotification } = this.props;
    const { isLog, moduleObjDataObj } = this.state;

    if (navigator.onLine) {
      showLoador({ loador: true });
      apiPost({
        url: endpoint.group + "/save-group-and-products",
        postBody: dataToSave,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          if (isLog) {
            if (data && data.data !== null) {

              let updatedJsonToSend = JSON.stringify(data.data);

              let dataToSaveForMLog = {
                module: {
                  moduleId: moduleObjDataObj.moduleId
                },
                subModule: {
                  subModuleId: moduleObjDataObj.subModuleId
                },
                operationType: this.props.params.id ? 1 : 0,
                type: 0,
                operationPerformId: data.data.id,
                updatedJson: updatedJsonToSend,
              }

              showLoador({ loador: true });
              apiPost({
                url: endpoint.mlog,
                postBody: dataToSaveForMLog,
              }).then(({ success }) => {
                showLoador({ loador: false });
                if (success) {
                } else {
                  showNotification({
                    msg: msgConstant.saveFailedMsg,
                    severity: "error",
                  });
                }
              });
            }
          }
          showNotification({ msg: savemsg });
          this.props.navigate(-1);
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onDataChange = (fieldName, newValue) => {

    const { fieldData } = this.state;
    const dataToSearch = {};
    const dataToReset = { ...this.state.fieldData };
    groupProductJson.fieldMeta.map((currentField) => {
      if (currentField.dataKey === fieldName && currentField.getListId) {
        const masterKeyList = currentField.getListId.split(",");

        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
          return null;
        });

        this.getListById(dataToSearch);
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key == fieldName && dataToReset[key]) {
            delete dataToReset[currentField.dataKey];
          }
        });
      }
      return null;
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
    });
  };
  getDataForDisabled = () => { };

  rowDelete = (rowData) => {
    const { showNotification, showLoador } = this.props;
    const { isLog, moduleObjDataObj } = this.state;
    swal({
      title: "Are you sure?",
      text: deleteWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (rowData.id) {
          showLoador({ loador: true });
          apiDelete({
            url: endpoint.product + "/" + rowData.id,
          }).then(({ success }) => {
            showLoador({ loador: false });
            if (success) {

              if (isLog) {
                if (rowData !== null) {

                  let updatedJsonToSend = JSON.stringify(rowData);

                  let dataToSaveForMLog = {
                    module: {
                      moduleId: moduleObjDataObj.moduleId
                    },
                    subModule: {
                      subModuleId: moduleObjDataObj.subModuleId
                    },
                    operationType: 2,
                    type: 0,
                    operationPerformId: rowData.id,
                    updatedJson: updatedJsonToSend,
                  }

                  showLoador({ loador: true });
                  apiPost({
                    url: endpoint.mlog,
                    postBody: dataToSaveForMLog,
                  }).then(({ success }) => {
                    showLoador({ loador: false });
                    if (success) {
                    } else {
                      showNotification({
                        msg: msgConstant.saveFailedMsg,
                        severity: "error",
                      });
                    }
                  });
                }
              }

              showNotification({ msg: deletemsg });
              window.location.reload();
            } else {
              showNotification({ msg: deleteFailedMsg, severity: "error" });
            }
          });
        } else {
          const rowListData = this.state.rowList.filter(
            (row) => row.index != rowData.index
          );
          const row = rowListData.map((rowListDataObj, index) => {
            return { ...rowListDataObj, index: index + 1 };
          });
          this.setState({
            rowList: row,
          });
        }
      }
    });
  };

  rowEdit = (editData) => {
    this.setState({
      editFlag: true,
    });
    const tagIdsList = editData.tagIds.split(",");
    this.setState({
      editData: { ...editData, tagIds: tagIdsList },
    });
  };

  getDataById = () => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      if (this.props.params.id) {
        showLoador({ loador: true });
        const response = apiGet({
          url:
            groupProductJson.apiBaseURL +
            "/" +
            this.props.params.id +
            "/with-product-by-group",
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (success) {
            if (!data.error) {
              let rowData = data.data;
              console.log(rowData);
              this.setState({
                fieldData:
                  rowData.group != null
                    ? {
                      ...rowData.group,
                      id: rowData.group.id,
                      category: {
                        id: rowData.group.subCategory.category.id,
                      },
                    }
                    : {},
                rowList: rowData.products.map((groupProductObject, index) => {

                  let newList = {
                    index: index + 1,
                    id: groupProductObject.id,
                    name: groupProductObject.name,
                    productName: groupProductObject.name,
                    shortName: groupProductObject.shortName,
                    tagIds: groupProductObject.tagIds,
                    code: groupProductObject.code,
                    productCode: groupProductObject.productCode,
                    description: groupProductObject.description,
                    packagingType: groupProductObject.packagingType,
                    stakeHolderUom: groupProductObject.stakeHolderUom,
                    outletUom: groupProductObject.outletUom,
                    taxPercentage: groupProductObject.taxPercentage,
                    uomConversionValue: groupProductObject.uomConversionValue,
                    weight: groupProductObject.weight,
                    selfLife: groupProductObject.selfLife,
                    sortOrder: groupProductObject.sortOrder,
                    isActive: groupProductObject.isActive,
                    ...groupProductObject,
                  };
                  return newList;
                }),
              });
              this.getListById(rowData.group.subCategory);

              return { data: rowData, success };
            }
            return { data: {}, success: false };
          } else {
            showNotification({ msg: serverMsg, severity: "error" });
          }

          return { data: {}, success: false };
        });
        return response;
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  onChangeStatus = (rowData) => {
    const { showLoador, showNotification } = this.props;
    const { isLog, moduleObjDataObj } = this.state;

    let status = rowData.isActive == 1 ? 0 : 1;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiPost({
        url:
          endpoint.product +
          "/update-is-active/?id=" +
          rowData.id +
          "&isActive=" +
          status,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          if (isLog) {
            if (rowData !== null) {

              let updatedJsonToSend = JSON.stringify(rowData);

              let dataToSaveForMLog = {
                module: {
                  moduleId: moduleObjDataObj.moduleId
                },
                subModule: {
                  subModuleId: moduleObjDataObj.subModuleId
                },
                operationType: this.props.params.id ? 1 : 0,
                type: 0,
                operationPerformId: rowData.id,
                updatedJson: updatedJsonToSend,
              }

              showLoador({ loador: true });
              apiPost({
                url: endpoint.mlog,
                postBody: dataToSaveForMLog,
              }).then(({ success }) => {
                showLoador({ loador: false });
                if (success) {
                } else {
                  showNotification({
                    msg: msgConstant.saveFailedMsg,
                    severity: "error",
                  });
                }
              });
            }
          }

          showNotification({ msg: statusmsg });
          this.getDataById();
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  render() {
    const {
      dynamicMasterData,
      rowList,
      formErrors,
      fieldData,
      open,
      editData,
      submitFlag,
      editFlag,
    } = this.state;
    return (
      <>
        {!open && (
          <>
            <Paper
              sx={{
                padding: 2,
              }}
              elevation={0}
            >
              <DynamicFormWithoutValidation
                dynamicMasterData={dynamicMasterData}
                screenTitle={groupProductJson.screenTitle}
                fieldMeta={groupProductJson.fieldMeta}
                apiBaseURL={groupProductJson.apiBaseURL}
                formErrors={formErrors}
                showSaveBtn={groupProductJson.showSaveBtn}
                showSaveBtnMain={false}
                getDataById={this.getDataById}
                getListById={this.getListById}
                getByIdApi={true}
                callApi={true}
                fieldData={fieldData}
                onDataChange={this.onDataChange}
                showBackToList={true}
              />
              <br />

              <DynamicFormForDocumentUpload
                callGetById={false}
                showSaveBtn={true}
                showSaveBtnMain={false}
                dynamicMasterData={dynamicMasterData}
                getListById={this.getListById}
                searchList={groupProductJson.searchList}
                showTitle={true}
                screenTitle={"SKU"}
                fieldMeta={groupProductJson.fieldMetaDetails}
                showCancel={false}
                showBackToList={false}
                apiBaseURL={groupProductJson.apiBaseURL}
                showSaveNextBtn={false}
                saveBtnText={"ADD"}
                directSave={false}
                // onSave={editFlag ? this.onSearchForEdit : this.onSearch}
                onSave={this.onSearch}
                resetAfterSave={true}
                getDataForDisabled={this.getDataForDisabled}
                saveText={"ADD"}
                fieldData={editData}
              />

              <br />
              <RTTable
                showPegination={false}
                columns={[
                  { name: "index", title: "Sr. No.", align: "center" },
                  { name: "productName", title: "Product" },
                  { name: "code", title: "Code", align: "center" },
                  { name: "productCode", title: "Extra Product Code", align: "center" },
                  {
                    name: "selfLife",
                    title: "Shelf Life (In Month)",
                    align: "right",
                  },
                  {
                    name: "sortOrder",
                    title: "Sort Order NO",
                    align: "center",
                  },
                  { name: "isActive", title: "Status", align: "center" },
                  { name: "action", title: "Action" },
                ]}
                tableData={rowList}
                isActionColActive={true}
                showEditIcon={true}
                rowEdit={this.rowEdit}
                showDeleteIcon={true}
                rowDelete={this.rowDelete}
                onChangeStatus={this.onChangeStatus}
              />
              <br />

              {!this.props.params.id ? (
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="right"
                >
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="contained"
                    name="Submit"
                    onClick={this.onSubmitData}
                  />
                </Grid>
              ) : submitFlag ? (
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="right"
                >
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="contained"
                    name="Submit"
                    onClick={this.onSubmitData}
                  />
                </Grid>
              ) : (
                <> </>
              )}
            </Paper>
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  categoryList: state.category,
  brandList: state.brand,
  uomList: state.uom,
  taxList: state.tax,
  packagingTypeList: state.packagingType,
  tagList: state.tag,
});
const mapDispatchToProps = {
  showLoador,
  showNotification,
  getCategory,
  getSubCategoryByCategoryId,
  getUom,
  getTax,
  getBrand,
  getPackagingType,
  getTag,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(GroupProductForm);
