import { Grid, Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { distributorOpeningJson } from "../../DynamicFormsJson/Transaction/distributorOpeningForm";
import { getAreaByTalukaPostBodyUpdated } from "../../Slice/area.slice";
import { getDistrictByZone } from "../../Slice/district.slice";
import { getRegionNew } from "../../Slice/region.slice";
import { getStateByRegion } from "../../Slice/state.slice";
import { getStockStakeHolderType } from "../../Slice/stockStakeHolderType.slice";
import { getTalukaByDistrictPost } from "../../Slice/taluka.slice";
import { getZoneByState } from "../../Slice/zone.slice";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import {
  noInternetMsg,
  saveFailedMsg,
  savemsg,
  serverMsg,
} from "../../config/messageconstant";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import OpeningManageDistributorTable from "./OpeningManageDistributorTable";
import endpoint from "../../config/endpoints";
import { apiPost } from "../../utils/api_service";
import { getStockholderByAreaIdsForFilterPost } from "../../Slice/StackHolder.slice";

class OpeningManageDistributorForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      dynamicMasterData: {
        stockStakeHolderTypeDetail:
          this.props.stakeHolderTypeList?.stockStakeHolderType,
        initialData: [],
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.regionList !== nextProps.regionList) {
      if (this.props.regionList?.region !== nextProps.regionList?.region) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            regionDetail: nextProps.regionList?.region,
          },
        });
      }
    }
    if (this.props.stateList !== nextProps.stateList) {
      if (
        this.props.stateList?.stateByRegion !==
        nextProps.stateList?.stateByRegion
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stateDetail: nextProps.stateList?.stateByRegion,
          },
        });
      }
    }
    if (this.props.zoneList !== nextProps.zoneList) {
      if (
        this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            zoneDetail: nextProps.zoneList?.zoneByState,
          },
        });
      }
    }
    if (this.props.districtList !== nextProps.districtList) {
      if (
        this.props.districtList?.districtByZone !==
        nextProps.districtList?.districtByZone
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            districtDetail: nextProps.districtList?.districtByZone,
          },
        });
      }
    }
    if (this.props.talukaList !== nextProps.talukaList) {
      if (
        this.props.talukaList?.talukaByDistrict !==
        nextProps.talukaList?.talukaByDistrict
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            talukaDetail: nextProps.talukaList?.talukaByDistrict,
          },
        });
      }
    }
    if (this.props.areaList !== nextProps.areaList) {
      if (
        this.props.areaList?.areaByTaluka !== nextProps.areaList?.areaByTaluka
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.areaByTaluka,
          },
        });
      }
    }
    if (this.props.stakeHolderTypeList !== nextProps.stakeHolderTypeList) {
      if (
        this.props.stakeHolderTypeList?.stockStakeHolderType !==
        nextProps.stakeHolderTypeList?.stockStakeHolderType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stockStakeHolderTypeDetail:
              nextProps.stakeHolderTypeList?.stockStakeHolderType,
          },
        });
      }
    }
  }

  async componentDidMount() {
    const {
      getStockStakeHolderType,
      getRegionNew,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getStockStakeHolderType({ userTypeIds: 6 }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

      showLoador({ loador: true });
      await getRegionNew().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  getListById = async (data) => {
    const {
      getStateByRegion,
      getZoneByState,
      getDistrictByZone,
      getTalukaByDistrictPost,
      getAreaByTalukaPostBodyUpdated,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      console.log(data);

      if (data.regionIds && data.regionIds !== "") {
        showLoador({ loador: true });
        await getStateByRegion({ regionIds: data.regionIds }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.stateIds && data.stateIds != "") {
        showLoador({ loador: true });
        await getZoneByState({ stateIds: data.stateIds }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.zoneIds && data.zoneIds != "") {
        showLoador({ loador: true });
        await getDistrictByZone({ zoneIds: data.zoneIds }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.districtIds && data.districtIds != "") {
        let districtIdsByPost = {
          districtIds: data.districtIds,
        };
        showLoador({ loador: true });
        await getTalukaByDistrictPost({ districtIds: districtIdsByPost }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.talukaIds && data.talukaIds != "") {
        let talukaIdsByPost = {
          talukaIds: data.talukaIds,
        };
        showLoador({ loador: true });
        await getAreaByTalukaPostBodyUpdated({
          talukaIds: talukaIdsByPost,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  };

  onSave = async (data) => {
    const {
      getStockholderByAreaIdsForFilterPost,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      let stockHolderJson = {
        areaIds: data.areaIds,
      };
      showLoador({ loador: true });
      await getStockholderByAreaIdsForFilterPost({
        areaIds: stockHolderJson,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  passTheData = (data) => {
    const { showLoador, showNotification } = this.props;

    console.log("data", data);
    swal({
      title: "Are you sure?",
      text: "Do you want to submit this data?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willSave) => {
      if (willSave) {
        if (navigator.onLine) {
          showLoador({ loador: true });
          apiPost({
            url: endpoint.stockStackHolderOpening + "/save-list",
            postBody: data,
          }).then(({ data, success }) => {
            showLoador({ loador: false });
            if (success) {
              showNotification({ msg: savemsg });
              window.location.replace("/manage-opening-distributor-list");
            } else {
              showNotification({ msg: saveFailedMsg, severity: "error" });
            }
            return success;
          });
        } else {
          this.props.showNotification({
            msg: noInternetMsg,
            severity: "error",
          });
        }
      }
    });
  };

  render() {
    const { dynamicMasterData } = this.state;
    const { stackHolderByAreaList } = this.props;
    return (
      <>
        <Paper sx={{ padding: 2 }} elevation={0}>
          <DynamicFormWithoutSave
            apiBaseURL={distributorOpeningJson.formPath}
            getListById={this.getListById}
            screenTitle={distributorOpeningJson.screenTitle}
            fieldMeta={distributorOpeningJson.fieldMeta}
            dynamicMasterData={dynamicMasterData}
            showSaveNextBtn={distributorOpeningJson.showSaveNextBtn}
            showSaveBtnMain={false}
            showSaveBtn={true}
            saveBtnText={"Search"}
            onSave={this.onSave}
            showTitle={true}
            showBackToList={true}
          />
          <Grid sx={{ marginTop: 2 }}>
            <OpeningManageDistributorTable
              passTheData={this.passTheData}
              initialData={stackHolderByAreaList?.stackholder}
            />
          </Grid>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  stackHolderByAreaList: state.stockholder,
  stakeHolderTypeList: state.stockStakeHolderType,
  regionList: state.region,
  stateList: state.states,
  zoneList: state.zone,
  districtList: state.district,
  talukaList: state.taluka,
  areaList: state.area,
});
const mapDispatchToProps = {
  getStockStakeHolderType,
  getAreaByTalukaPostBodyUpdated,
  getTalukaByDistrictPost,
  getDistrictByZone,
  getZoneByState,
  getStateByRegion,
  getRegionNew,
  showLoador,
  showNotification,
  getStockholderByAreaIdsForFilterPost,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(OpeningManageDistributorForm);
