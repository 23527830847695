import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { connect } from "react-redux";
import { StockBetweenDateJSON } from "../../DynamicFormsJson/Transaction/stockBetweenDate";
import {
  getDetailUpdated,
  getUpdatedStockBetweenDateList,
} from "../../Slice/manageStock.slice";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { cyanBlue } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { downloadF } from "../PDF/PDFDownload";
// import PopUpStockBetweenDate from "./PopUpStockBetweenDate";
import { auditJson } from "../../DynamicFormsJson/MastersJSON/audit";
import { generateExcel } from "../../components/GeneratePDFExcel";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";

import { getAreaByUserType } from "../../Slice/area.slice";
import { getBeatsByAreaIds } from "../../Slice/beat.slice";
import { getOutletByBeatIds } from "../../Slice/outlet.slice";
import { distributorOpeningJson } from "../../DynamicFormsJson/Transaction/distributorOpeningForm";
import { getStockholderByAreaIdsForFilterPost } from "../../Slice/StackHolder.slice";
import { getAreaByTalukaPostBodyUpdated } from "../../Slice/area.slice";
import { getDistrictByZone } from "../../Slice/district.slice";
import { getRegionNew } from "../../Slice/region.slice";
import { getStateByRegion } from "../../Slice/state.slice";
import { getStockStakeHolderType } from "../../Slice/stockStakeHolderType.slice";
import { getTalukaByDistrictPost } from "../../Slice/taluka.slice";
import { getZoneByState } from "../../Slice/zone.slice";
import { apiGet } from "../../utils/api_service";

class DistributorLedgerReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      excelData: {},
      ledgerData: [],
      totalBalance: 0,
      dynamicMasterData: {
        stockHolderDetail: this.props.stakeholdersList.stackholder,
        areaDetail: this.props.areaList?.area,
        beatDetail: this.props.beatList?.beat,
        stockStakeHolderTypeDetail:
          this.props.stockStakeHolderType?.stockStakeHolderType,
        stockStakeHolderTypelistDetail:
          this.props.stockStakeHolderType?.stockStakeHolderTypeList,
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.regionList !== nextProps.regionList) {
      if (this.props.regionList?.region !== nextProps.regionList?.region) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            regionDetail: nextProps.regionList?.region,
          },
        });
      }
    }
    if (this.props.stateList !== nextProps.stateList) {
      if (
        this.props.stateList?.stateByRegion !==
        nextProps.stateList?.stateByRegion
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stateDetail: nextProps.stateList?.stateByRegion,
          },
        });
      }
    }
    if (this.props.zoneList !== nextProps.zoneList) {
      if (
        this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            zoneDetail: nextProps.zoneList?.zoneByState,
          },
        });
      }
    }
    if (this.props.districtList !== nextProps.districtList) {
      if (
        this.props.districtList?.districtByZone !==
        nextProps.districtList?.districtByZone
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            districtDetail: nextProps.districtList?.districtByZone,
          },
        });
      }
    }
    if (this.props.talukaList !== nextProps.talukaList) {
      if (
        this.props.talukaList?.talukaByDistrict !==
        nextProps.talukaList?.talukaByDistrict
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            talukaDetail: nextProps.talukaList?.talukaByDistrict,
          },
        });
      }
    }
    if (this.props.areaList !== nextProps.areaList) {
      if (
        this.props.areaList?.areaByTaluka !== nextProps.areaList?.areaByTaluka
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.areaByTaluka,
          },
        });
      }
    }
    if (this.props.stakeHolderTypeList !== nextProps.stakeHolderTypeList) {
      if (
        this.props.stakeHolderTypeList?.stockStakeHolderType !==
        nextProps.stakeHolderTypeList?.stockStakeHolderType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stockStakeHolderTypeDetail:
              nextProps.stakeHolderTypeList?.stockStakeHolderType,
          },
        });
      }
    }
    if (this.props.stakeholdersList !== nextProps.stakeholdersList) {
      if (
        this.props.stakeholdersList?.stackholder !==
        nextProps.stakeholdersList?.stackholder
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stockHolderDetail: nextProps.stakeholdersList?.stackholder,
          },
        });
      }
    }
  }

  getListById = async (data) => {
    const {
      getStateByRegion,
      getZoneByState,
      getDistrictByZone,
      getTalukaByDistrictPost,
      getAreaByTalukaPostBodyUpdated,
      getStockholderByAreaIdsForFilterPost,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      console.log(data);

      if (data.regionIds && data.regionIds !== "") {
        showLoador({ loador: true });
        await getStateByRegion({ regionIds: data.regionIds }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.stateIds && data.stateIds != "") {
        showLoador({ loador: true });
        await getZoneByState({ stateIds: data.stateIds }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.zoneIds && data.zoneIds != "") {
        showLoador({ loador: true });
        await getDistrictByZone({ zoneIds: data.zoneIds }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.districtIds && data.districtIds != "") {
        let districtIdsByPost = {
          districtIds: data.districtIds,
        };
        showLoador({ loador: true });
        await getTalukaByDistrictPost({ districtIds: districtIdsByPost }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.talukaIds && data.talukaIds != "") {
        let talukaIdsByPost = {
          talukaIds: data.talukaIds,
        };
        showLoador({ loador: true });
        await getAreaByTalukaPostBodyUpdated({
          talukaIds: talukaIdsByPost,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.areaIds && data.areaIds != "") {
        let areaIdsByPost = {
          areaIds: data.areaIds,
        };
        showLoador({ loador: true });
        await getStockholderByAreaIdsForFilterPost({
          areaIds: areaIdsByPost,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  };

  async componentDidMount() {
    const {
      getStockStakeHolderType,
      getRegionNew,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getStockStakeHolderType({ userTypeIds: 6 }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

      showLoador({ loador: true });
      await getRegionNew().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  onSearchData = async (data) => {
    const { getUpdatedStockBetweenDateList, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getUpdatedStockBetweenDateList({
        fromdate: data.fromdate,
        todate: data.todate,
        stockType: "0",
      }).then(({ success, response }) => {
        showLoador({ loador: false });
        this.setState({
          fromdate: data.fromdate,
          todate: data.todate,
        });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.columnTotal(response);
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onSearchForPerformingOutlets = (searchValueForOutlets) => {
    this.setState({
      searchValueForOutlets: searchValueForOutlets,
    });
  };

  getFilteredTableData = (searchData) => {
    const { searchValueForOutlets } = this.state;
    return searchData.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["name"] &&
          currentRow["name"]
            .toString()
            .toLowerCase()
            .includes(searchValueForOutlets.trim().toLowerCase())) ||
        (currentRow["stakeHolderUomName"] &&
          currentRow["stakeHolderUomName"]
            .toString()
            .toLowerCase()
            .includes(searchValueForOutlets.trim().toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
    });
  };

  handleCloses = () => {
    this.setState({
      popUpFlag: false,
    });
  };

  onClickHandler = (e, row, flag) => {
    const { getDetailUpdated, showLoador, showNotification } = this.props;
    const { fromdate, todate, columnFlag } = this.state;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getDetailUpdated({
        fromdate: fromdate ? fromdate : dayjs().format("DD-MM-YYYY"),
        todate: todate ? todate : dayjs().format("DD-MM-YYYY"),
        productId: row.product,
        flag: flag,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.setState({
            popUpFlag: true,
            columnFlag: flag,
          });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  columnTotal(response) {
    let openingQTyTotal = 0,
      purchaseQtyTotal = 0,
      primarySellQtyTotal = 0,
      secondarySellTotalQty = 0,
      grnQtyTotal = 0,
      crnQtyTotal = 0,
      inStockQtyTotal = 0,
      outStockQtyTotal = 0,
      closingQtyTotal = 0,
      stockInHandTotal = 0,
      primaryStockTotal = 0;
    response.productStocks.map((qtyData) => {
      openingQTyTotal =
        openingQTyTotal + (+qtyData.openingQty > 0 ? +qtyData.openingQty : 0);
      purchaseQtyTotal =
        purchaseQtyTotal +
        (+qtyData.purchaseQty > 0 ? +qtyData.purchaseQty : 0);
      primarySellQtyTotal =
        primarySellQtyTotal +
        (+qtyData.distributorSellQty > 0 ? +qtyData.distributorSellQty : 0);
      secondarySellTotalQty =
        secondarySellTotalQty +
        (+qtyData.outletSellQty > 0 ? +qtyData.outletSellQty : 0);
      grnQtyTotal = grnQtyTotal + (+qtyData.grnQty > 0 ? +qtyData.grnQty : 0);
      crnQtyTotal = crnQtyTotal + (+qtyData.crnQty > 0 ? +qtyData.crnQty : 0);
      inStockQtyTotal =
        inStockQtyTotal + (+qtyData.inStockQty > 0 ? +qtyData.inStockQty : 0);
      outStockQtyTotal =
        outStockQtyTotal +
        (+qtyData.outStockQty > 0 ? +qtyData.outStockQty : 0);
      closingQtyTotal =
        closingQtyTotal + (+qtyData.closingQty > 0 ? +qtyData.closingQty : 0);
      stockInHandTotal =
        stockInHandTotal +
        (+qtyData.stockInHand > 0 ? +qtyData.stockInHand : 0);
      primaryStockTotal =
        primaryStockTotal +
        (+qtyData.primaryClosingQty > 0 ? +qtyData.primaryClosingQty : 0);
    });

    this.setState({
      openingQTyTotal: openingQTyTotal.toFixed(2),
      purchaseQtyTotal: purchaseQtyTotal.toFixed(2),
      primarySellQtyTotal: primarySellQtyTotal.toFixed(2),
      secondarySellTotalQty: secondarySellTotalQty.toFixed(2),
      grnQtyTotal: grnQtyTotal.toFixed(2),
      crnQtyTotal: crnQtyTotal.toFixed(2),
      inStockQtyTotal: inStockQtyTotal.toFixed(2),
      outStockQtyTotal: outStockQtyTotal.toFixed(2),
      closingQtyTotal: closingQtyTotal.toFixed(2),
      stockInHandTotal: stockInHandTotal.toFixed(2),
      primaryStockTotal: primaryStockTotal.toFixed(0),
    });
  }
  generateExcelStatic = () => {
    const { excelData } = this.state;
    console.log(excelData);
    const { showLoador, showNotification } = this.props;

    if (navigator.onLine) {
      showLoador({ loador: true });
      downloadF({
        isGet: true,
        url:
          endpoint.transaction +
          "/ledger-excel-by-date-and-stock-holder?stockStakeHolderId=" +
          excelData?.stackholder +
          "&fromDate=" +
          excelData?.fromDate +
          "&toDate=" +
          excelData?.toDate,
        ext: "xls",
        openNewTab: false,
      }).then((response) => {
        if (!response) {
          showNotification({
            msg: serverMsg,
            severity: "error",
          });
        }
        showLoador({ loador: false });
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  filterExcel = () => {
    const {
      openingQTyTotal,
      purchaseQtyTotal,
      primarySellQtyTotal,
      secondarySellTotalQty,
      grnQtyTotal,
      crnQtyTotal,
      inStockQtyTotal,
      outStockQtyTotal,
      closingQtyTotal,
      stockInHandTotal,
      primaryStockTotal,
    } = this.state;

    const filteredData = this.getFilteredTableData(
      this.props.manageStockList.stockBetweenDate.productStocks
    );

    if (filteredData.length === 0) {
      this.props.showNotification({
        msg: "No data available for export",
        severity: "error",
      });
      return;
    }

    const searchableColumns = [
      "SR.No",
      "Product Name",
      "UOM",
      "Opening Quantity",
      "Purchase Quantity",
      "Primary Sell Quantity",
      "Secondary Sell Quantity",
      "GRN Quantity",
      "Credit Note Quantity",
      "InStock Quantity",
      "OutStock Quantity",
      "Closing Quantity",
      "Stock In Hand",
      "Total",
    ];

    const formattedData = filteredData.map((row, index) => {
      openingQTyTotal || 0;
      purchaseQtyTotal || 0;
      primarySellQtyTotal || 0;
      secondarySellTotalQty || 0;
      grnQtyTotal || 0;
      crnQtyTotal || 0;
      inStockQtyTotal || 0;
      outStockQtyTotal || 0;
      closingQtyTotal || 0;
      stockInHandTotal || 0;
      primaryStockTotal || 0;

      return [
        index + 1,
        row.name || "",
        row.stakeHolderUomName || "",
        row.openingQty || 0,
        row.purchaseQty || 0,
        row.distributorSellQty || 0,
        row.outletSellQty || 0,
        row.grnQty || 0,
        row.crnQty || 0,
        row.inStockQty || 0,
        row.outStockQty || 0,
        row.closingQty || 0,
        row.stockInHand || 0,
        row.total || 0,
      ];
    });

    formattedData.push([
      "Total",
      "",
      "",
      openingQTyTotal,
      purchaseQtyTotal,
      primarySellQtyTotal,
      secondarySellTotalQty,
      grnQtyTotal,
      crnQtyTotal,
      inStockQtyTotal,
      outStockQtyTotal,
      closingQtyTotal,
      stockInHandTotal,
      primaryStockTotal + " Primary Stock",
    ]);

    generateExcel({
      columns: searchableColumns,
      rows: formattedData,
      alignList: [],
      headerList: [],
      title: StockBetweenDateJSON.screenTitle,
      reportName: StockBetweenDateJSON.screenTitle,
    });
  };

  onSearchDataForExcel = (data) => {
    console.log(data);
    const { showLoador } = this.props;
    this.setState({
      excelData: data,
    });

    if (navigator.onLine) {
      showLoador({ loador: true });
      apiGet({
        url:
          endpoint.transaction +
          "/ledger-list-by-date-and-stock-holder?stockStakeHolderId=" +
          data?.stackholder +
          "&fromDate=" +
          data?.fromDate +
          "&toDate=" +
          data?.toDate,
      }).then((response) => {
        showLoador({ loador: false });
        console.log("response", response);
        if (response) {
          // let totalBalance = 0;
          // response?.data?.data?.forEach((item) => {
          //   totalBalance = parseFloat(item.balanceAmt);
          // });
          this.setState({
            ledgerData: response?.data?.data,
            //  totalBalance
          });
        } else {
          this.props.showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  render() {
    const {
      dynamicMasterData,
      ledgerData,
      DataToSet,
      //  totalBalance
    } = this.state;
    return (
      <>
        <Paper
          sx={{
            padding: 2,
          }}
          elevation={0}
        >
          <LandingScreenHeader
            showAddButton={false}
            screenTitle={"Stock Holder Ledger Report"}
            showSearchBox={false}
            showTable={true}
            showFilter={false}
            showPdfDownload={false}
            generateExcel={this.generateExcelStatic}
            staticExcel={true}
            showExcelDownload={false}
            onSearch={this.onSearchForPerformingOutlets}
          />
          <DynamicFormWithoutSave
            getListById={this.getListById}
            padding={true}
            paddingTop={true}
            showBackToList={true}
            DataToSet={DataToSet}
            dynamicMasterData={dynamicMasterData}
            getByIdApi={true}
            callApi={true}
            getDataById={this.getDataById}
            showTitle={false}
            screenTitle={false}
            fieldMeta={distributorOpeningJson.fieldMeta}
            showCancel={distributorOpeningJson.showCancel}
            apiBaseURL={distributorOpeningJson.apiBaseURL}
            showSaveNextBtn={distributorOpeningJson.showSaveNextBtn}
            showSaveBtnMain={false}
            showSaveBtn={true}
            showSubmit={false}
            onSubmit={this.onSubmitAudit}
            saveBtnText={"Search"}
            onSave={this.onSearchDataForExcel}
          />

          <br />
          <LandingScreenHeader
            showAddButton={false}
            screenTitle={""}
            showSearchBox={false}
            showTable={true}
            showFilter={false}
            showPdfDownload={false}
            generateExcel={this.generateExcelStatic}
            staticExcel={true}
            showExcelDownload={ledgerData.length > 0 ? true : false}
            onSearch={this.onSearchForPerformingOutlets}
          />

          <TableContainer style={{ maxHeight: "550px", overflow: "auto" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Sr. No</TableCell>
                  <TableCell align="center">Transaction Type</TableCell>
                  <TableCell align="center">Transaction No</TableCell>
                  <TableCell align="center">Date</TableCell>
                  <TableCell align="center">Credit Amount</TableCell>
                  <TableCell align="center">Debit Amount</TableCell>
                  <TableCell align="center">Balance Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {ledgerData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{row.date}</TableCell>
                    <TableCell align="center">{row.taransactionType}</TableCell>
                    <TableCell
                      align="right"
                      style={{ color: row.flag === 1 ? "green" : "inherit" }}
                    >
                      {row.flag === 1 ? row.creditAmt : "-"}
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ color: row.flag === 0 ? "red" : "inherit" }}
                    >
                      {row.flag === 0 ? row.debitAmt : "-"}
                    </TableCell>
                    <TableCell align="right">{row.balanceAmt}</TableCell>
                  </TableRow>
                ))} */}
                {/* <TableRow>
                  <TableCell align="right" colSpan={5}>
                    <strong>Total Balance</strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>{totalBalance}</strong>
                  </TableCell>
                </TableRow> */}
                {ledgerData.length > 0 ? (
                  ledgerData.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {row.taransactionType}
                      </TableCell>
                      <TableCell align="center">{row.taransactionNo}</TableCell>
                      <TableCell align="center">{row.date}</TableCell>
                      <TableCell align="right" style={{ color: "green" }}>
                        {row.creditAmt ? (+row.creditAmt).toFixed(2) : "-"}
                      </TableCell>
                      <TableCell align="right" style={{ color: "red" }}>
                        {row.debitAmt ? (+row.debitAmt).toFixed(2) : "-"}
                      </TableCell>
                      <TableCell align="right">
                        {row.balanceAmt ? (+row.balanceAmt).toFixed(2) : "-"}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={7}
                      style={{ padding: "20px" }}
                    >
                      No Data Available.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  stakeholdersList: state.stackholder,
  stakeHolderTypeList: state.stockStakeHolderType,
  regionList: state.region,
  stateList: state.states,
  zoneList: state.zone,
  districtList: state.district,
  talukaList: state.taluka,
  areaList: state.area,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getUpdatedStockBetweenDateList,
  getDetailUpdated,
  getAreaByUserType,
  getBeatsByAreaIds,
  getOutletByBeatIds,
  getDistrictByZone,
  getRegionNew,
  getStateByRegion,
  getStockStakeHolderType,
  getTalukaByDistrictPost,
  getZoneByState,
  getAreaByTalukaPostBodyUpdated,
  getStockholderByAreaIdsForFilterPost,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DistributorLedgerReport);
