import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
const initialState = {
  requestGRNListData: [],
  requestGRNHeaderDetailsData: {},
  requestGRNHeaderDetails: [],
  requestGRNHeaderData: {},
  pendingGrnList: [],
  grnHistoryByDate: [],
  grnByStakeholderId: [],
  grnByStakeholderIdForGrnToCrn: [],
  getGrnDataById: [],
  getGrnDataByGrnGvnHeaderIds: [],
};
let URL = endpoints.grnGVNHeader;
let URL1 = endpoints.grnGVNDetail;
const requestGRNSlice = createSlice({
  name: "requestGRN",
  initialState,
  reducers: {
    requestGRNListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        requestGRNListData: row,
      };
    },

    requestGRNHeaderDetailsSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        requestGRNHeaderDetailsData: row,
      };
    },

    requestGRNHeaderDetailsSucess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        requestGRNHeaderDetails: row,
      };
    },

    requestGRNHeaderSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        requestGRNHeaderData: row,
      };
    },
    GrnHistorySuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        grnHistoryByDate: row,
      };
    },
    grnPendingSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        pendingGrnList: row,
      };
    },

    grnByStakeholderIdSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        grnByStakeholderId: row,
      };
    },
    grnByStakeholderIdForGrnToCrnSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        grnByStakeholderIdForGrnToCrn: row,
      };
    },

    getGrnSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        getGrnDataById: row,
      };
    },
    GrnLatestSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        GRNList: row,
      };
    },
    getGrnToCrnSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        getGrnDataByGrnGvnHeaderIds: row,
      };
    },
    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  requestGRNListSuccess,
  resetState,
  requestGRNHeaderDetailsSuccess,
  requestGRNHeaderSuccess,
  requestGRNHeaderDetailsSucess,
  grnPendingSuccess,
  GrnHistorySuccess,
  grnByStakeholderIdSuccess,
  grnByStakeholderIdForGrnToCrnSuccess,
  getGrnSuccess,
  setGrnDataSuccess,
  GrnLatestSuccess,
  getGrnToCrnSuccess,
} = requestGRNSlice.actions;

export default requestGRNSlice.reducer;
export const getTodaysRequestGRNList = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/todays-grn",
    }).then(({ data, success }) => {
      console.log("data ev", data);

      if (success) {
        const row = data.data.map((creditNoteHeaderObject, index) => {
          let total = 0;
          creditNoteHeaderObject.grnGvnDetails.map((grnGvnDetailsObj) => {
            total = total + +grnGvnDetailsObj.secondaryQty;
          });

          return {
            index: index + 1,
            billId:
              creditNoteHeaderObject.billId == null
                ? ""
                : creditNoteHeaderObject.billId,
            cessAmt:
              creditNoteHeaderObject.cessAmt == null
                ? ""
                : creditNoteHeaderObject.cessAmt,
            grnGvnNo:
              creditNoteHeaderObject.grnGvnNo == null
                ? ""
                : creditNoteHeaderObject.grnGvnNo,
            date:
              creditNoteHeaderObject.date == null
                ? ""
                : creditNoteHeaderObject.date,
            toStockStakeHolderFirmName:
              creditNoteHeaderObject.toStockStakeHolderFirmName == null
                ? ""
                : creditNoteHeaderObject.toStockStakeHolderFirmName,
            toStockStakeHolderOwnerName:
              creditNoteHeaderObject.toStockStakeHolderOwnerName == null
                ? ""
                : creditNoteHeaderObject.toStockStakeHolderOwnerName,
            fromStockStakeHolder:
              creditNoteHeaderObject.fromStockStakeHolder == null
                ? ""
                : creditNoteHeaderObject.fromStockStakeHolder,
            id:
              creditNoteHeaderObject.id == null
                ? ""
                : creditNoteHeaderObject.id,
            insertDateTime:
              creditNoteHeaderObject.insertDateTime == null
                ? ""
                : creditNoteHeaderObject.insertDateTime,
            insertedUserId:
              creditNoteHeaderObject.insertedUserId == null
                ? ""
                : creditNoteHeaderObject.insertedUserId,
            remark:
              creditNoteHeaderObject.remark == null
                ? ""
                : creditNoteHeaderObject.remark,
            status:
              creditNoteHeaderObject.status == null
                ? ""
                : creditNoteHeaderObject.status,
            totalQty: total,
            taxableAmt:
              creditNoteHeaderObject.taxableAmt == null
                ? ""
                : creditNoteHeaderObject.taxableAmt.toFixed(2),
            totalAmt:
              creditNoteHeaderObject.totalAmt == null
                ? ""
                : creditNoteHeaderObject.totalAmt.toFixed(2),
            statusName:
              creditNoteHeaderObject.status == null
                ? ""
                : creditNoteHeaderObject.status == 0
                ? "Pending"
                : creditNoteHeaderObject.status == 1
                ? "Send GRN"
                : creditNoteHeaderObject.status == 2
                ? "Approved"
                : "Credit Note Generated",
            taxAmt:
              creditNoteHeaderObject.taxAmt == null
                ? ""
                : creditNoteHeaderObject.taxAmt.toFixed(2),
            toStockStakeHolder:
              creditNoteHeaderObject.toStockStakeHolder == null
                ? ""
                : creditNoteHeaderObject.toStockStakeHolder,

            type:
              creditNoteHeaderObject.grnGvnType === 0
                ? "Purchase Return From Bill-Wise"
                : creditNoteHeaderObject.grnGvnType === 1
                ? "Purchase Return From Category-Wise"
                : creditNoteHeaderObject.grnGvnType === 2
                ? "Outlet's CRN"
                : creditNoteHeaderObject.grnGvnType === 3
                ? "Stake holder's CRN"
                : creditNoteHeaderObject.grnGvnType === 4
                ? "Primary offer"
                : creditNoteHeaderObject.grnGvnType === 5
                ? "Rate difference claim"
                : "-",
          };
        });
        dispatch(requestGRNListSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
export const getRequestGRNList =
  ({ fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/get-by-date?fromDate=" + fromDate + "&toDate=" + toDate,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          const row = content.map((creditNoteHeaderObject, index) => {
            let total = 0;
            creditNoteHeaderObject.grnGvnDetails.map((grnGvnDetailsObj) => {
              total = total + +grnGvnDetailsObj.qty;
            });

            return {
              index: index + 1,
              billId:
                creditNoteHeaderObject.billId == null
                  ? ""
                  : creditNoteHeaderObject.billId,
              cessAmt:
                creditNoteHeaderObject.cessAmt == null
                  ? ""
                  : creditNoteHeaderObject.cessAmt,
              grnGvnNo:
                creditNoteHeaderObject.grnGvnNo == null
                  ? ""
                  : creditNoteHeaderObject.grnGvnNo,
              date:
                creditNoteHeaderObject.date == null
                  ? ""
                  : creditNoteHeaderObject.date,
              toStockStakeHolderFirmName:
                creditNoteHeaderObject.toStockStakeHolderFirmName == null
                  ? ""
                  : creditNoteHeaderObject.toStockStakeHolderFirmName,
              toStockStakeHolderOwnerName:
                creditNoteHeaderObject.toStockStakeHolderOwnerName == null
                  ? ""
                  : creditNoteHeaderObject.toStockStakeHolderOwnerName,
              fromStockStakeHolder:
                creditNoteHeaderObject.fromStockStakeHolder == null
                  ? ""
                  : creditNoteHeaderObject.fromStockStakeHolder,
              id:
                creditNoteHeaderObject.id == null
                  ? ""
                  : creditNoteHeaderObject.id,
              insertDateTime:
                creditNoteHeaderObject.insertDateTime == null
                  ? ""
                  : creditNoteHeaderObject.insertDateTime,
              insertedUserId:
                creditNoteHeaderObject.insertedUserId == null
                  ? ""
                  : creditNoteHeaderObject.insertedUserId,
              remark:
                creditNoteHeaderObject.remark == null
                  ? ""
                  : creditNoteHeaderObject.remark,
              status:
                creditNoteHeaderObject.status == null
                  ? ""
                  : creditNoteHeaderObject.status,
              totalQty: total,
              taxableAmt:
                creditNoteHeaderObject.taxableAmt == null
                  ? ""
                  : creditNoteHeaderObject.taxableAmt.toFixed(2),
              totalAmt:
                creditNoteHeaderObject.totalAmt == null
                  ? ""
                  : creditNoteHeaderObject.totalAmt.toFixed(2),
              statusName:
                creditNoteHeaderObject.status == null
                  ? ""
                  : creditNoteHeaderObject.status == 0
                  ? "Pending"
                  : creditNoteHeaderObject.status == 1
                  ? "Send GRN"
                  : creditNoteHeaderObject.status == 2
                  ? "Approved"
                  : "Credit Note Generated",
              taxAmt:
                creditNoteHeaderObject.taxAmt == null
                  ? ""
                  : creditNoteHeaderObject.taxAmt.toFixed(2),
              toStockStakeHolder:
                creditNoteHeaderObject.toStockStakeHolder == null
                  ? ""
                  : creditNoteHeaderObject.toStockStakeHolder,
              type:
                creditNoteHeaderObject.grnGvnType === 0
                  ? "Purchase Return From Bill-Wise"
                  : creditNoteHeaderObject.grnGvnType === 1
                  ? "Purchase Return From Category-Wise"
                  : creditNoteHeaderObject.grnGvnType === 2
                  ? "Outlet's CRN"
                  : creditNoteHeaderObject.grnGvnType === 3
                  ? "Stake holder's CRN"
                  : creditNoteHeaderObject.grnGvnType === 4
                  ? "Primary offer"
                  : creditNoteHeaderObject.grnGvnType === 5
                  ? "Rate difference claim"
                  : "-",
            };
          });
          dispatch(requestGRNListSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const setRequestGRNList = () => async (dispatch) => {
  dispatch(requestGRNListSuccess({ row: [] }));
};
export const getGRNHeaderDetailsById =
  ({ requestGRNHeaderId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/" + requestGRNHeaderId,
      }).then(({ data, success }) => {
        if (success) {
          const grnData = data.data;
          let grnGVNHeaderDetailsData = {
            id: grnData.id === null ? "" : grnData.id,
            billId: grnData.billId === null ? "" : grnData.billId,
            grnGvnNo: grnData.grnGvnNo === null ? "" : grnData.grnGvnNo,
            date: grnData.date === null ? "" : grnData.date,
            fromStockStakeHolder:
              grnData.fromStockStakeHolder === null
                ? ""
                : grnData.fromStockStakeHolder,
            toStockStakeHolder:
              grnData.toStockStakeHolder === null
                ? ""
                : grnData.toStockStakeHolder,
            status: grnData.status === null ? "" : grnData.status,
            statusName: grnData.status === null ? "" : grnData.status,
            taxableAmt:
              grnData.taxableAmt === null ? "" : grnData.taxableAmt.toFixed(2),
            totalAmt:
              grnData.totalAmt === null ? "" : grnData.totalAmt.toFixed(2),
            statusName:
              grnData.status === null
                ? ""
                : grnData.status == 0
                ? "Pending"
                : grnData.status == 1
                ? "Send GRN"
                : grnData.status == 2
                ? "Approved"
                : grnData.status == 3
                ? "Credit Note Generated"
                : "",
            insertedUserId:
              grnData.insertedUserId === null ? "" : grnData.insertedUserId,
            insertDateTime:
              grnData.insertDateTime === null ? "" : grnData.insertDateTime,
            remark: grnData.remark === null ? "" : grnData.remark,
            instockOutstock:
              grnData.instockOutstock === null ? "" : grnData.instockOutstock,
            grnGvnDetails:
              grnData.grnGvnDetails === null
                ? ""
                : grnData.grnGvnDetails.map((orderHistoryObject, index) => {
                    let orderHistoryData = {
                      index: index + 1,
                      id:
                        orderHistoryObject.id === null
                          ? ""
                          : orderHistoryObject.id,
                      productName:
                        orderHistoryObject.product === null
                          ? ""
                          : orderHistoryObject.product.name +
                            " ( " +
                            orderHistoryObject.product.shortName +
                            " ) ",
                      uomName:
                        orderHistoryObject.uom === null
                          ? ""
                          : orderHistoryObject.uom.name,
                      outletUomName:
                        orderHistoryObject.product === null
                          ? ""
                          : orderHistoryObject.product.outletUom != null
                          ? orderHistoryObject.product.outletUom.name
                          : "",
                      stakeHolderUomName:
                        orderHistoryObject.product === null
                          ? ""
                          : orderHistoryObject.product.stakeHolderUom != null
                          ? orderHistoryObject.product.stakeHolderUom.name
                          : "",

                      batchCode:
                        orderHistoryObject.batchCode === null
                          ? ""
                          : orderHistoryObject.batchCode,
                      rate:
                        orderHistoryObject.rate === null
                          ? ""
                          : orderHistoryObject.rate.toFixed(2),
                      qty:
                        orderHistoryObject.qty === null
                          ? ""
                          : +orderHistoryObject.qty,
                      approvedQty:
                        orderHistoryObject.approvedQty === null
                          ? 0
                          : orderHistoryObject.approvedQty,
                      approvedQtyError: 0,
                      // secQty:
                      //   orderHistoryObject.secondaryQty === null
                      //     ? ""
                      //     : orderHistoryObject.secondaryQty +
                      //     "/" +
                      //     orderHistoryObject.qty,

                      qtyForShow:
                        orderHistoryObject.qty !== null &&
                        orderHistoryObject.secondaryQty !== null &&
                        orderHistoryObject.uomConversionValue !== null &&
                        orderHistoryObject.product !== null &&
                        orderHistoryObject.product.stakeHolderUom !== null &&
                        orderHistoryObject.product.stakeHolderUom.name !==
                          null &&
                        orderHistoryObject.product.outletUom !== null &&
                        orderHistoryObject.product.outletUom.name !== null
                          ? Math.floor(orderHistoryObject.qty) +
                            " " +
                            orderHistoryObject.product.stakeHolderUom.name +
                            " " +
                            ((orderHistoryObject.secondaryQty %
                              orderHistoryObject.uomConversionValue) +
                              " " +
                              orderHistoryObject.product.outletUom.name)
                          : "",

                      secQty:
                        orderHistoryObject.secondaryQty !== null
                          ? orderHistoryObject.secondaryQty
                          : "",

                      secondaryQty: 0,

                      secondaryQtyForView:
                        orderHistoryObject.secondaryQty !== null
                          ? orderHistoryObject.secondaryQty
                          : "",

                      taxableAmt:
                        orderHistoryObject.taxableAmt === null
                          ? ""
                          : orderHistoryObject.taxableAmt.toFixed(2),
                      totalAmt:
                        orderHistoryObject.totalAmt === null
                          ? ""
                          : orderHistoryObject.totalAmt.toFixed(2),
                      totalAmtForPendingGrn: 0,
                      product:
                        orderHistoryObject.product === null
                          ? ""
                          : orderHistoryObject.product,
                      uom:
                        orderHistoryObject.uom === null
                          ? ""
                          : orderHistoryObject.uom,

                      billDetailId:
                        orderHistoryObject.billDetailId === null
                          ? ""
                          : orderHistoryObject.billDetailId,
                      cessAmt:
                        orderHistoryObject.billDetailId === null
                          ? ""
                          : orderHistoryObject.billDetailId,
                      cessPer:
                        orderHistoryObject.cessPer === null
                          ? ""
                          : orderHistoryObject.cessPer,
                      cgstAmt:
                        orderHistoryObject.cgstAmt === null
                          ? ""
                          : orderHistoryObject.cgstAmt,
                      cgstPer:
                        orderHistoryObject.cgstPer === null
                          ? ""
                          : orderHistoryObject.cgstPer,
                      grnPer:
                        orderHistoryObject.grnPer === null
                          ? ""
                          : orderHistoryObject.grnPer,
                      grnRate:
                        orderHistoryObject.grnRate === null
                          ? ""
                          : orderHistoryObject.grnRate,
                      hsnCode:
                        orderHistoryObject.hsnCode === null
                          ? ""
                          : orderHistoryObject.hsnCode,
                      id:
                        orderHistoryObject.id === null
                          ? ""
                          : orderHistoryObject.id,
                      igstAmt:
                        orderHistoryObject.igstAmt === null
                          ? ""
                          : orderHistoryObject.igstAmt,
                      igstPer:
                        orderHistoryObject.igstPer === null
                          ? ""
                          : orderHistoryObject.igstPer,
                      mrp:
                        orderHistoryObject.mrp === null
                          ? ""
                          : orderHistoryObject.mrp,
                      primaryApprovedQty:
                        orderHistoryObject.primaryApprovedQty === null
                          ? ""
                          : orderHistoryObject.primaryApprovedQty,

                      billSecondaryQty:
                        orderHistoryObject.secondaryQty === null
                          ? ""
                          : orderHistoryObject.secondaryQty,

                      primaryQty: 0,

                      totalQty: 0,

                      qtyError: false,
                      orderDate:
                        orderHistoryObject.orderDate === null
                          ? ""
                          : orderHistoryObject.orderDate,
                      stockStakeHolder:
                        orderHistoryObject.stockStakeHolder === null
                          ? ""
                          : orderHistoryObject.stockStakeHolder,
                      type:
                        orderHistoryObject.type === null
                          ? ""
                          : orderHistoryObject.type,
                      insertedUserId:
                        orderHistoryObject.insertedUserId === null
                          ? ""
                          : orderHistoryObject.insertedUserId,
                      insertDateTime:
                        orderHistoryObject.insertDateTime === null
                          ? ""
                          : orderHistoryObject.insertDateTime,
                      uomConversionValue:
                        orderHistoryObject.uomConversionValue === null
                          ? ""
                          : orderHistoryObject.uomConversionValue,
                      stockStakeHolderBillDetailId:
                        orderHistoryObject.stockStakeHolderBillDetailId === null
                          ? ""
                          : orderHistoryObject.stockStakeHolderBillDetailId,
                    };
                    return orderHistoryData;
                  }),
          };
          dispatch(
            requestGRNHeaderDetailsSuccess({ row: grnGVNHeaderDetailsData })
          );
          dispatch(
            requestGRNHeaderDetailsSucess({
              row: grnGVNHeaderDetailsData.grnGvnDetails,
            })
          );
          return { response: grnGVNHeaderDetailsData, success };
        }
        return { response: {}, success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const setRequestGRNDetails =
  ({ row }) =>
  async (dispatch) => {
    const rowList = row.map((rowObject, index) => {
      const addObj = {
        ...rowObject,
        totalQty:
          +rowObject.primaryQty * rowObject.product.uomConversionValue +
          +rowObject.secondaryQty,
        // totalAmtForPending: (
        //   (+rowObject.primaryQty *
        //     rowObject.product.uomConversionValue +
        //     +rowObject.secQty) *
        //   +rowObject.rate
        // ).toFixed(2),
        totalAmtForPendingGrn: (
          (+rowObject.primaryQty * rowObject.product.uomConversionValue +
            +rowObject.secondaryQty) *
          +rowObject.rate
        ).toFixed(2),
        qtyError:
          +rowObject.primaryQty * rowObject.product.uomConversionValue +
            +rowObject.secondaryQty >
          +rowObject.secQty
            ? true
            : false,
      };
      return addObj;
    });

    dispatch(requestGRNHeaderDetailsSucess({ row: rowList }));
  };

export const setNewRequestGRNDetails =
  ({ row }) =>
  async (dispatch) => {
    const rowList = row.map((rowObject, index) => {
      const addObj = {
        ...rowObject,
        totalQty:
          +rowObject.primaryQty * rowObject.product.uomConversionValue +
          +rowObject.secQty,
        totalAmtForPendingGrn: (
          (+rowObject.primaryQty * rowObject.product.uomConversionValue +
            +rowObject.secQty) *
          +rowObject.rate
        ).toFixed(2),
        qtyError:
          +rowObject.primaryQty * rowObject.product.uomConversionValue +
            +rowObject.secQty >
          +rowObject.billSecondaryQty
            ? true
            : false,
        secondaryQty: +rowObject.secQty,
      };
      return addObj;
    });
    dispatch(requestGRNHeaderDetailsSucess({ row: rowList }));
  };

export const getGRNHeaderById =
  ({ requestGRNHeaderId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/by-id?id=" + requestGRNHeaderId,
      }).then(({ data, success }) => {
        if (success) {
          const grnData = data.data;
          let grnGVNHeaderData = {
            id: grnData.id === null ? "" : grnData.id,
            billId: grnData.billId === null ? "" : grnData.billId,
            grnGvnNo: grnData.grnGvnNo === null ? "" : grnData.grnGvnNo,
            date: grnData.date === null ? "" : grnData.date,
            fromStockStakeHolder:
              grnData.fromStockStakeHolder === null
                ? ""
                : grnData.fromStockStakeHolder,
            fromStockStakeHolderFirmName:
              grnData.fromStockStakeHolderFirmName === null
                ? ""
                : grnData.fromStockStakeHolderFirmName,
            fromStockStakeHolderOwnerName:
              grnData.fromStockStakeHolderOwnerName === null
                ? ""
                : grnData.fromStockStakeHolderOwnerName,
            toStockStakeHolder:
              grnData.toStockStakeHolder === null
                ? ""
                : grnData.toStockStakeHolder,
            status: grnData.status === null ? "" : grnData.status,
            remark: grnData.remark === null ? "" : grnData.remark,
            taxableAmt:
              grnData.taxableAmt === null ? "" : grnData.taxableAmt.toFixed(2),
            totalAmt:
              grnData.totalAmt === null ? "" : grnData.totalAmt.toFixed(2),
            insertedUserId:
              grnData.insertedUserId === null ? "" : grnData.insertedUserId,
            insertDateTime:
              grnData.insertDateTime === null ? "" : grnData.insertDateTime,
            grnGvnType: grnData?.grnGvnType,
            outletCrnType: grnData?.outletCrnType,
          };
          dispatch(requestGRNHeaderSuccess({ row: grnGVNHeaderData }));
          return { response: grnGVNHeaderData, success };
        }
        return { response: {}, success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getPendingGrnList = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/pending-to-approve",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((pendingGrnObject, index) => {
          let pendingGrnData = {
            index: index + 1,
            id: pendingGrnObject.id === null ? "" : pendingGrnObject.id,
            billId:
              pendingGrnObject.billId === null ? "" : pendingGrnObject.billId,
            grnGvnNo:
              pendingGrnObject.grnGvnNo === null
                ? ""
                : pendingGrnObject.grnGvnNo,
            date: pendingGrnObject.date === null ? "" : pendingGrnObject.date,
            fromStockStakeHolderFirmName:
              pendingGrnObject.fromStockStakeHolderFirmName === null
                ? ""
                : pendingGrnObject.fromStockStakeHolderFirmName,
            fromStockStakeHolderStateName:
              pendingGrnObject.fromStockStakeHolderStateName === null
                ? ""
                : pendingGrnObject.fromStockStakeHolderStateName,
            fromStockStakeHolderAreaNames:
              pendingGrnObject.fromStockStakeHolderAreaNames === null
                ? ""
                : pendingGrnObject.fromStockStakeHolderAreaNames,
            ownerAndFirmName:
              pendingGrnObject.fromStockStakeHolderOwnerName === null
                ? ""
                : pendingGrnObject.fromStockStakeHolderOwnerName +
                  " (" +
                  pendingGrnObject.fromStockStakeHolderFirmName +
                  ")",
            fromStockStakeHolderOwnerName:
              pendingGrnObject.fromStockStakeHolderOwnerName === null
                ? ""
                : pendingGrnObject.fromStockStakeHolderOwnerName,
            fromStockStakeHolder:
              pendingGrnObject.fromStockStakeHolder === null
                ? ""
                : pendingGrnObject.fromStockStakeHolder,

            toStockStakeHolder:
              pendingGrnObject.toStockStakeHolder === null
                ? ""
                : pendingGrnObject.toStockStakeHolder,
            toStockStakeHolderFirmName:
              pendingGrnObject.toStockStakeHolderFirmName === null
                ? ""
                : pendingGrnObject.toStockStakeHolderFirmName,
            toStockStakeHolderOwnerName:
              pendingGrnObject.toStockStakeHolderOwnerName === null
                ? ""
                : pendingGrnObject.toStockStakeHolderOwnerName,
            taxableAmt:
              pendingGrnObject.taxableAmt === null
                ? ""
                : pendingGrnObject.taxableAmt.toFixed(2),
            taxAmt:
              pendingGrnObject.taxAmt === null ? "" : pendingGrnObject.taxAmt,
            cessAmt:
              pendingGrnObject.cessAmt === null ? "" : pendingGrnObject.cessAmt,
            totalAmt:
              pendingGrnObject.totalAmt === null
                ? ""
                : pendingGrnObject.totalAmt.toFixed(2),
          };
          return pendingGrnData;
        });
        dispatch(grnPendingSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
export const getPendingGrnListByDate =
  ({ fromdate, todate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/pending-to-approve-by-date?fromDate=" +
          fromdate +
          "&toDate=" +
          todate,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((pendingGrnObject, index) => {
            let pendingGrnData = {
              index: index + 1,
              id: pendingGrnObject.id === null ? "" : pendingGrnObject.id,
              billId:
                pendingGrnObject.billId === null ? "" : pendingGrnObject.billId,
              grnGvnNo:
                pendingGrnObject.grnGvnNo === null
                  ? ""
                  : pendingGrnObject.grnGvnNo,
              date: pendingGrnObject.date === null ? "" : pendingGrnObject.date,
              fromStockStakeHolderFirmName:
                pendingGrnObject.fromStockStakeHolderFirmName === null
                  ? ""
                  : pendingGrnObject.fromStockStakeHolderFirmName,
              fromStockStakeHolderStateName:
                pendingGrnObject.fromStockStakeHolderStateName === null
                  ? ""
                  : pendingGrnObject.fromStockStakeHolderStateName,
              fromStockStakeHolderAreaNames:
                pendingGrnObject.fromStockStakeHolderAreaNames === null
                  ? ""
                  : pendingGrnObject.fromStockStakeHolderAreaNames,
              ownerAndFirmName:
                pendingGrnObject.fromStockStakeHolderOwnerName === null
                  ? ""
                  : pendingGrnObject.fromStockStakeHolderOwnerName +
                    " (" +
                    pendingGrnObject.fromStockStakeHolderFirmName +
                    ")",
              fromStockStakeHolderOwnerName:
                pendingGrnObject.fromStockStakeHolderOwnerName === null
                  ? ""
                  : pendingGrnObject.fromStockStakeHolderOwnerName,
              fromStockStakeHolder:
                pendingGrnObject.fromStockStakeHolder === null
                  ? ""
                  : pendingGrnObject.fromStockStakeHolder,

              toStockStakeHolder:
                pendingGrnObject.toStockStakeHolder === null
                  ? ""
                  : pendingGrnObject.toStockStakeHolder,
              toStockStakeHolderFirmName:
                pendingGrnObject.toStockStakeHolderFirmName === null
                  ? ""
                  : pendingGrnObject.toStockStakeHolderFirmName,
              toStockStakeHolderOwnerName:
                pendingGrnObject.toStockStakeHolderOwnerName === null
                  ? ""
                  : pendingGrnObject.toStockStakeHolderOwnerName,
              taxableAmt:
                pendingGrnObject.taxableAmt === null
                  ? ""
                  : pendingGrnObject.taxableAmt.toFixed(2),
              taxAmt:
                pendingGrnObject.taxAmt === null ? "" : pendingGrnObject.taxAmt,
              cessAmt:
                pendingGrnObject.cessAmt === null
                  ? ""
                  : pendingGrnObject.cessAmt,
              totalAmt:
                pendingGrnObject.totalAmt === null
                  ? ""
                  : pendingGrnObject.totalAmt.toFixed(2),
            };
            return pendingGrnData;
          });
          dispatch(grnPendingSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getGrnearchListByDate =
  ({ fromdate, todate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL + "/history-by-date?fromDate=" + fromdate + "&toDate=" + todate,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          const row = content.map((creditNoteHeaderObject, index) => {
            creditNoteHeaderObject = {
              index: index + 1,
              id:
                creditNoteHeaderObject.id == null
                  ? ""
                  : creditNoteHeaderObject.id,
              grnGvnNo:
                creditNoteHeaderObject.grnGvnNo == null
                  ? ""
                  : creditNoteHeaderObject.grnGvnNo,
              date:
                creditNoteHeaderObject.date == null
                  ? ""
                  : creditNoteHeaderObject.date,
              billId:
                creditNoteHeaderObject.billId == null
                  ? ""
                  : creditNoteHeaderObject.billId,
              cessAmt:
                creditNoteHeaderObject.cessAmt == null
                  ? ""
                  : creditNoteHeaderObject.cessAmt.toFixed(2),
              fromStockStakeHolderOwnerName:
                creditNoteHeaderObject.fromStockStakeHolderFirmName === null
                  ? ""
                  : creditNoteHeaderObject.fromStockStakeHolderFirmName,
              fromStockStakeHolderStateName:
                creditNoteHeaderObject.fromStockStakeHolderStateName === null
                  ? ""
                  : creditNoteHeaderObject.fromStockStakeHolderStateName,
              fromStockStakeHolderAreaNames:
                creditNoteHeaderObject.fromStockStakeHolderAreaNames === null
                  ? ""
                  : creditNoteHeaderObject.fromStockStakeHolderAreaNames,
              fromStockStakeHolder:
                creditNoteHeaderObject.fromStockStakeHolder == null
                  ? ""
                  : creditNoteHeaderObject.fromStockStakeHolder,

              insertDateTime:
                creditNoteHeaderObject.insertDateTime == null
                  ? ""
                  : creditNoteHeaderObject.insertDateTime,
              insertedUserId:
                creditNoteHeaderObject.insertedUserId == null
                  ? ""
                  : creditNoteHeaderObject.insertedUserId,
              remark:
                creditNoteHeaderObject.remark == null
                  ? ""
                  : creditNoteHeaderObject.remark,
              status:
                creditNoteHeaderObject.status == null
                  ? ""
                  : creditNoteHeaderObject.status,

              statusName:
                creditNoteHeaderObject.status == null
                  ? ""
                  : creditNoteHeaderObject.status == 0
                  ? "Pending"
                  : creditNoteHeaderObject.status == 1
                  ? "Send GRN"
                  : creditNoteHeaderObject.status == 2
                  ? "Approved"
                  : "Credit Note Generated",
              taxAmt:
                creditNoteHeaderObject.taxAmt == null
                  ? ""
                  : creditNoteHeaderObject.taxAmt.toFixed(2),
              taxableAmt:
                creditNoteHeaderObject.taxableAmt == null
                  ? ""
                  : creditNoteHeaderObject.taxableAmt.toFixed(2),
              totalAmt:
                creditNoteHeaderObject.totalAmt == null
                  ? ""
                  : creditNoteHeaderObject.totalAmt.toFixed(2),
              toStockStakeHolder:
                creditNoteHeaderObject.toStockStakeHolder == null
                  ? ""
                  : creditNoteHeaderObject.toStockStakeHolder,
            };
            return creditNoteHeaderObject;
          });
          dispatch(GrnHistorySuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const setGrnList = () => async (dispatch) => {
  dispatch(grnPendingSuccess({ row: [] }));
};

export const getGrnearchList =
  ({ fromdate, todate, holderIds }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/history?fromDate=" +
          fromdate +
          "&toDate=" +
          todate +
          "&holderIds=" +
          holderIds,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          const row = content.map((creditNoteHeaderObject, index) => {
            creditNoteHeaderObject = {
              index: index + 1,
              id:
                creditNoteHeaderObject.id == null
                  ? ""
                  : creditNoteHeaderObject.id,
              grnGvnNo:
                creditNoteHeaderObject.grnGvnNo == null
                  ? ""
                  : creditNoteHeaderObject.grnGvnNo,
              date:
                creditNoteHeaderObject.date == null
                  ? ""
                  : creditNoteHeaderObject.date,
              billId:
                creditNoteHeaderObject.billId == null
                  ? ""
                  : creditNoteHeaderObject.billId,
              cessAmt:
                creditNoteHeaderObject.cessAmt == null
                  ? ""
                  : creditNoteHeaderObject.cessAmt.toFixed(2),
              fromStockStakeHolderOwnerName:
                creditNoteHeaderObject.fromStockStakeHolderFirmName === null
                  ? ""
                  : creditNoteHeaderObject.fromStockStakeHolderFirmName,
              fromStockStakeHolderStateName:
                creditNoteHeaderObject.fromStockStakeHolderStateName === null
                  ? ""
                  : creditNoteHeaderObject.fromStockStakeHolderStateName,
              fromStockStakeHolderAreaNames:
                creditNoteHeaderObject.fromStockStakeHolderAreaNames === null
                  ? ""
                  : creditNoteHeaderObject.fromStockStakeHolderAreaNames,
              fromStockStakeHolder:
                creditNoteHeaderObject.fromStockStakeHolder == null
                  ? ""
                  : creditNoteHeaderObject.fromStockStakeHolder,

              insertDateTime:
                creditNoteHeaderObject.insertDateTime == null
                  ? ""
                  : creditNoteHeaderObject.insertDateTime,
              insertedUserId:
                creditNoteHeaderObject.insertedUserId == null
                  ? ""
                  : creditNoteHeaderObject.insertedUserId,
              remark:
                creditNoteHeaderObject.remark == null
                  ? ""
                  : creditNoteHeaderObject.remark,
              status:
                creditNoteHeaderObject.status == null
                  ? ""
                  : creditNoteHeaderObject.status,

              statusName:
                creditNoteHeaderObject.status == null
                  ? ""
                  : creditNoteHeaderObject.status == 0
                  ? "Pending"
                  : creditNoteHeaderObject.status == 1
                  ? "Send GRN"
                  : creditNoteHeaderObject.status == 2
                  ? "Approved"
                  : "Credit Note Generated",
              taxAmt:
                creditNoteHeaderObject.taxAmt == null
                  ? ""
                  : creditNoteHeaderObject.taxAmt.toFixed(2),
              taxableAmt:
                creditNoteHeaderObject.taxableAmt == null
                  ? ""
                  : creditNoteHeaderObject.taxableAmt.toFixed(2),
              totalAmt:
                creditNoteHeaderObject.totalAmt == null
                  ? ""
                  : creditNoteHeaderObject.totalAmt.toFixed(2),
              toStockStakeHolder:
                creditNoteHeaderObject.toStockStakeHolder == null
                  ? ""
                  : creditNoteHeaderObject.toStockStakeHolder,
            };
            return creditNoteHeaderObject;
          });
          dispatch(GrnHistorySuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const setGrnHistory = () => async (dispatch) => {
  dispatch(GrnHistorySuccess({ row: [] }));
};
export const getGRNByStakeholderId =
  ({ holderId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/approved?holderId=" + holderId,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((grnByStakeholderObject, index) => {
            let grnByStakeholderData = {
              index: index + 1,
              id:
                grnByStakeholderObject.id === null
                  ? ""
                  : grnByStakeholderObject.id,
              name:
                grnByStakeholderObject.grnGvnNo === null
                  ? ""
                  : grnByStakeholderObject.grnGvnNo +
                    " - " +
                    grnByStakeholderObject.date,
              billId:
                grnByStakeholderObject.billId === null
                  ? ""
                  : grnByStakeholderObject.billId,
              date:
                grnByStakeholderObject.date === null
                  ? ""
                  : grnByStakeholderObject.date,
              grnGvnNo:
                grnByStakeholderObject.grnGvnNo === null
                  ? ""
                  : grnByStakeholderObject.grnGvnNo,
              fromStockStakeHolder:
                grnByStakeholderObject.fromStockStakeHolder === null
                  ? ""
                  : grnByStakeholderObject.fromStockStakeHolder,
              fromStockStakeHolderFirmName:
                grnByStakeholderObject.fromStockStakeHolderFirmName === null
                  ? ""
                  : grnByStakeholderObject.fromStockStakeHolderFirmName,
              toStockStakeHolder:
                grnByStakeholderObject.toStockStakeHolder === null
                  ? ""
                  : grnByStakeholderObject.toStockStakeHolder,
              toStockStakeHolderFirmName:
                grnByStakeholderObject.toStockStakeHolderFirmName === null
                  ? ""
                  : grnByStakeholderObject.toStockStakeHolderFirmName,
              toStockStakeHolderOwnerName:
                grnByStakeholderObject.toStockStakeHolderOwnerName === null
                  ? ""
                  : grnByStakeholderObject.toStockStakeHolderOwnerName,
              fromStockStakeHolderOwnerName:
                grnByStakeholderObject.fromStockStakeHolderOwnerName === null
                  ? ""
                  : grnByStakeholderObject.fromStockStakeHolderOwnerName,
              taxableAmt:
                grnByStakeholderObject.taxableAmt === null
                  ? ""
                  : grnByStakeholderObject.taxableAmt.toFixed(2),
              taxAmt:
                grnByStakeholderObject.taxAmt === null
                  ? ""
                  : grnByStakeholderObject.taxAmt.toFixed(2),
              cessAmt:
                grnByStakeholderObject.cessAmt === null
                  ? ""
                  : grnByStakeholderObject.cessAmt.toFixed(2),
              totalAmt:
                grnByStakeholderObject.totalAmt === null
                  ? ""
                  : grnByStakeholderObject.totalAmt.toFixed(2),
              status:
                grnByStakeholderObject.status === null
                  ? ""
                  : grnByStakeholderObject.status,
              remark:
                grnByStakeholderObject.remark === null
                  ? ""
                  : grnByStakeholderObject.remark,
              insertedUserId:
                grnByStakeholderObject.insertedUserId === null
                  ? ""
                  : grnByStakeholderObject.insertedUserId,
              insertDateTime:
                grnByStakeholderObject.insertDateTime === null
                  ? ""
                  : grnByStakeholderObject.insertDateTime,
            };
            return grnByStakeholderData;
          });
          dispatch(grnByStakeholderIdSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getGRNByStakeholderIdForGrnToCrn =
  ({ holderId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/get-all-approved?holderId=" + holderId,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((grnByStakeholderObject, index) => {
            let grnByStakeholderData = {
              index: index + 1,
              id:
                grnByStakeholderObject.id === null
                  ? ""
                  : grnByStakeholderObject.id,
              name:
                grnByStakeholderObject.grnGvnNo === null
                  ? ""
                  : grnByStakeholderObject.grnGvnNo +
                    " - " +
                    grnByStakeholderObject.date,
              billId:
                grnByStakeholderObject.billId === null
                  ? ""
                  : grnByStakeholderObject.billId,
              date:
                grnByStakeholderObject.date === null
                  ? ""
                  : grnByStakeholderObject.date,
              grnGvnNo:
                grnByStakeholderObject.grnGvnNo === null
                  ? ""
                  : grnByStakeholderObject.grnGvnNo,
              fromStockStakeHolder:
                grnByStakeholderObject.fromStockStakeHolder === null
                  ? ""
                  : grnByStakeholderObject.fromStockStakeHolder,
              fromStockStakeHolderFirmName:
                grnByStakeholderObject.fromStockStakeHolderFirmName === null
                  ? ""
                  : grnByStakeholderObject.fromStockStakeHolderFirmName,
              toStockStakeHolder:
                grnByStakeholderObject.toStockStakeHolder === null
                  ? ""
                  : grnByStakeholderObject.toStockStakeHolder,
              toStockStakeHolderFirmName:
                grnByStakeholderObject.toStockStakeHolderFirmName === null
                  ? ""
                  : grnByStakeholderObject.toStockStakeHolderFirmName,
              toStockStakeHolderOwnerName:
                grnByStakeholderObject.toStockStakeHolderOwnerName === null
                  ? ""
                  : grnByStakeholderObject.toStockStakeHolderOwnerName,
              fromStockStakeHolderOwnerName:
                grnByStakeholderObject.fromStockStakeHolderOwnerName === null
                  ? ""
                  : grnByStakeholderObject.fromStockStakeHolderOwnerName,
              taxableAmt:
                grnByStakeholderObject.taxableAmt === null
                  ? ""
                  : grnByStakeholderObject.taxableAmt.toFixed(2),
              taxAmt:
                grnByStakeholderObject.taxAmt === null
                  ? ""
                  : grnByStakeholderObject.taxAmt.toFixed(2),
              cessAmt:
                grnByStakeholderObject.cessAmt === null
                  ? ""
                  : grnByStakeholderObject.cessAmt.toFixed(2),
              totalAmt:
                grnByStakeholderObject.totalAmt === null
                  ? ""
                  : grnByStakeholderObject.totalAmt.toFixed(2),
              status:
                grnByStakeholderObject.status === null
                  ? ""
                  : grnByStakeholderObject.status,
              remark:
                grnByStakeholderObject.remark === null
                  ? ""
                  : grnByStakeholderObject.remark,
              insertedUserId:
                grnByStakeholderObject.insertedUserId === null
                  ? ""
                  : grnByStakeholderObject.insertedUserId,
              insertDateTime:
                grnByStakeholderObject.insertDateTime === null
                  ? ""
                  : grnByStakeholderObject.insertDateTime,
            };
            return grnByStakeholderData;
          });
          dispatch(grnByStakeholderIdForGrnToCrnSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getGrnData =
  ({ grnGvnHeaderIds }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL1 + "/pending-for-crn?grnGvnHeaderIds=" + grnGvnHeaderIds,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((grnDataObject, index) => {
            let grnData = {
              index: index + 1,
              id: grnDataObject.id === null ? "" : grnDataObject.id,
              grnGvnHeader:
                grnDataObject.grnGvnHeader === null
                  ? ""
                  : grnDataObject.grnGvnHeader,
              grnGvnNo:
                grnDataObject.grnGvnHeader === null
                  ? ""
                  : grnDataObject.grnGvnHeader.grnGvnNo,
              date:
                grnDataObject.grnGvnHeader === null
                  ? ""
                  : grnDataObject.grnGvnHeader.date,
              product:
                grnDataObject.product === null ? "" : grnDataObject.product,
              productName:
                grnDataObject.product === null
                  ? ""
                  : grnDataObject.product.name,
              outletUom:
                grnDataObject.product === null
                  ? ""
                  : grnDataObject.product.outletUom.name,
              rate:
                grnDataObject.rate === null
                  ? ""
                  : grnDataObject.rate.toFixed(2),
              qty: grnDataObject.qty === null ? "" : grnDataObject.qty,

              qtyForShow:
                grnDataObject.qty !== null &&
                grnDataObject.secondaryQty !== null &&
                grnDataObject.uomConversionValue !== null &&
                grnDataObject.product !== null &&
                grnDataObject.product.stakeHolderUom !== null &&
                grnDataObject.product.stakeHolderUom.name !== null &&
                grnDataObject.product.outletUom !== null &&
                grnDataObject.product.outletUom.name !== null
                  ? Math.floor(grnDataObject.qty) +
                    " " +
                    grnDataObject.product.stakeHolderUom.name +
                    " " +
                    ((grnDataObject.secondaryQty %
                      grnDataObject.uomConversionValue) +
                      " " +
                      grnDataObject.product.outletUom.name)
                  : "",

              approvedQty:
                grnDataObject.approvedQty === null
                  ? ""
                  : grnDataObject.approvedQty,
              secondaryApprovedQty:
                grnDataObject.secondaryApprovedQty === null
                  ? ""
                  : grnDataObject.secondaryApprovedQty,
              secQty:
                grnDataObject.secondaryApprovedQty !== null &&
                grnDataObject.uomConversionValue !== null
                  ? grnDataObject.secondaryApprovedQty %
                    grnDataObject.uomConversionValue
                  : "",
              primaryQty: 0,
              secondaryQty: 0,
              totalQty: 0,
              totalAmtForPending: 0,
              stakeHolderUom:
                grnDataObject.product === null
                  ? ""
                  : grnDataObject.product.stakeHolderUom.name,

              status:
                grnDataObject.grnGvnHeader === null
                  ? ""
                  : grnDataObject.grnGvnHeader.status,
              primaryApprovedQty:
                grnDataObject.primaryApprovedQty === null
                  ? ""
                  : grnDataObject.primaryApprovedQty,
              uomConversionValue:
                grnDataObject.uomConversionValue === null
                  ? ""
                  : grnDataObject.uomConversionValue,
              uom: grnDataObject.uom === null ? "" : grnDataObject.uom,
              hsnCode:
                grnDataObject.hsnCode === null ? "" : grnDataObject.hsnCode,
              mrp: grnDataObject.mrp === null ? "" : grnDataObject.mrp,
              grnPer: grnDataObject.grnPer === null ? "" : grnDataObject.grnPer,
              grnRate:
                grnDataObject.grnRate === null ? "" : grnDataObject.grnRate,
              taxableAmt:
                grnDataObject.taxableAmt === null
                  ? ""
                  : grnDataObject.taxableAmt,
              cgstAmt:
                grnDataObject.cgstAmt === null ? "" : grnDataObject.cgstAmt,
              sgstAmt:
                grnDataObject.sgstAmt === null ? "" : grnDataObject.sgstAmt,
              igstAmt:
                grnDataObject.igstAmt === null ? "" : grnDataObject.igstAmt,
              totalAmt:
                grnDataObject.totalAmt === null
                  ? ""
                  : grnDataObject.totalAmt.toFixed(2),

              cgstPer:
                grnDataObject.cgstPer === null ? "" : grnDataObject.cgstPer,
              sgstPer:
                grnDataObject.sgstPer === null ? "" : grnDataObject.sgstPer,
              igstPer:
                grnDataObject.igstPer === null ? "" : grnDataObject.igstPer,
              cessPer:
                grnDataObject.cessPer === null ? "" : grnDataObject.cessPer,
              cessAmt:
                grnDataObject.cessAmt === null ? "" : grnDataObject.cessAmt,
              billDetailId:
                grnDataObject.billDetailId === null
                  ? ""
                  : grnDataObject.billDetailId,
              batchCode:
                grnDataObject.batchCode === null ? "" : grnDataObject.batchCode,
              insertedUserId:
                grnDataObject.insertedUserId === null
                  ? ""
                  : grnDataObject.insertedUserId,
              insertDateTime:
                grnDataObject.insertDateTime === null
                  ? ""
                  : grnDataObject.insertDateTime,
              stockStakeHolderBillDetailId:
                grnDataObject.stockStakeHolderBillDetailId === null
                  ? ""
                  : grnDataObject.stockStakeHolderBillDetailId,
            };

            return grnData;
          });
          dispatch(getGrnSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const setGrnData =
  ({ row }) =>
  async (dispatch) => {
    const rowList = row.map((rowObject, index) => {
      const addObj = {
        ...rowObject,
        totalQty:
          +rowObject.primaryQty * rowObject.product.uomConversionValue +
          +rowObject.secondaryQty,

        // totalAmtForPending: (
        //   ((+rowObject.primaryQty * rowObject.product.uomConversionValue +
        //     +rowObject.secondaryQty) *
        //     +rowObject.rate) /
        //   +rowObject.product.uomConversionValue
        // ).toFixed(2),
        totalAmtForPending: (
          (+rowObject.primaryQty * rowObject.product.uomConversionValue +
            +rowObject.secondaryQty) *
          +rowObject.rate
        ).toFixed(2),
        qtyError:
          +rowObject.primaryQty * rowObject.product.uomConversionValue +
            +rowObject.secondaryQty >
          +rowObject.secondaryApprovedQty
            ? true
            : false,
      };
      return addObj;
    });
    dispatch(getGrnSuccess({ row: rowList }));
  };
export const getGrnToCrn =
  ({ grnGvnHeaderIds }) =>
  async (dispatch) => {
    try {
      const response = await apiPost({
        url: URL + "/and-details-by-ids",
        postBody: grnGvnHeaderIds,
      });
      const { data, success } = response;
      if (success) {
        const row = data.data.map((grnHeader, index) => {
          return {
            ...grnHeader,
            index: index + 1,
            grnGvnDetails: (grnHeader.grnGvnDetails || []).map(
              (grnDataObject, detailIndex) => ({
                ...grnDataObject,
                detailIndex: detailIndex + 1,
                qtyForShow:
                  grnDataObject.qty !== null &&
                  grnDataObject.secondaryQty !== null &&
                  grnDataObject.productUomConversionValue !== null &&
                  grnDataObject.product !== null &&
                  grnDataObject.product.stakeHolderUom !== null &&
                  grnDataObject.product.stakeHolderUom.name !== null &&
                  grnDataObject.product.outletUom !== null &&
                  grnDataObject.product.outletUom.name !== null
                    ? Math.floor(grnDataObject.qty) +
                      " " +
                      grnDataObject.product.stakeHolderUom.name +
                      " " +
                      ((grnDataObject.secondaryQty %
                        grnDataObject.productUomConversionValue) +
                        " " +
                        grnDataObject.product.outletUom.name)
                    : "",
                secQty: 0,
                primaryQty: 0,
                totalQty: 0,
                totalAmtForPending: 0,
                totalAmt: grnDataObject.totalAmt
                  ? grnDataObject.totalAmt.toFixed(2)
                  : "0",
                qtyError: false,
              })
            ),
          };
        });

        dispatch(getGrnToCrnSuccess({ row }));
        return { response: row, success };
      }

      return { response: [], success };
    } catch (e) {
      console.error(e.message);
      return { response: [], success: false };
    }
  };

export const setGrnToCrnData =
  ({ row }) =>
  async (dispatch) => {
    console.log(row);

    dispatch(getGrnToCrnSuccess({ row: row }));
  };
export const getGrnLatest = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/latest",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((grnDataObject, index) => {
          return {
            index: index + 1,
            grnGvnNo: grnDataObject.grnGvnNo,
            date: grnDataObject.date,
            totalAmt: grnDataObject.totalAmt,
            ...grnDataObject,
          };
        });
        dispatch(GrnLatestSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getGRNHeaderAndDetailByIdUpdated =
  ({ grnGvnHeaderId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/with-detail-by-id?grnGvnHeaderId=" + grnGvnHeaderId,
      }).then(({ data, success }) => {
        if (success) {
          const grnData = data.data;
          let grnGVNHeaderData = {
            id: grnData.id === null ? "" : grnData.id,
            cessAmt: grnData.cessAmt === null ? "" : grnData.cessAmt,
            billId: grnData.billId === null ? "" : grnData.billId,
            grnGvnNo: grnData.grnGvnNo === null ? "" : grnData.grnGvnNo,
            date: grnData.date === null ? "" : grnData.date,
            taxAmt: grnData.taxAmt === null ? "" : grnData.taxAmt,
            toStockStakeHolderAreaNames:
              grnData.toStockStakeHolderAreaNames === null
                ? ""
                : grnData.toStockStakeHolderAreaNames,
            toStockStakeHolderFirmName:
              grnData.toStockStakeHolderFirmName === null
                ? ""
                : grnData.toStockStakeHolderFirmName,
            toStockStakeHolderOwnerName:
              grnData.toStockStakeHolderOwnerName === null
                ? ""
                : grnData.toStockStakeHolderOwnerName,
            toStockStakeHolderStateName:
              grnData.toStockStakeHolderStateName === null
                ? ""
                : grnData.toStockStakeHolderStateName,
            totalQty: grnData.totalQty === null ? "" : grnData.totalQty,

            fromStockStakeHolder:
              grnData.fromStockStakeHolder === null
                ? ""
                : grnData.fromStockStakeHolder,
            fromStockStakeHolderFirmName:
              grnData.fromStockStakeHolderFirmName === null
                ? ""
                : grnData.fromStockStakeHolderFirmName,
            fromStockStakeHolderOwnerName:
              grnData.fromStockStakeHolderOwnerName === null
                ? ""
                : grnData.fromStockStakeHolderOwnerName,
            toStockStakeHolder:
              grnData.toStockStakeHolder === null
                ? ""
                : grnData.toStockStakeHolder,
            status: grnData.status === null ? "" : grnData.status,
            remark: grnData.remark === null ? "" : grnData.remark,
            taxableAmt:
              grnData.taxableAmt === null ? "" : grnData.taxableAmt.toFixed(2),
            totalAmt:
              grnData.totalAmt === null ? "" : grnData.totalAmt.toFixed(2),
            insertedUserId:
              grnData.insertedUserId === null ? "" : grnData.insertedUserId,
            insertDateTime:
              grnData.insertDateTime === null ? "" : grnData.insertDateTime,
            grnGvnDetails:
              grnData.grnGvnDetails === null
                ? ""
                : grnData.grnGvnDetails.map((orderHistoryObject, index) => {
                    let orderHistoryData = {
                      index: index + 1,
                      id:
                        orderHistoryObject.id === null
                          ? ""
                          : orderHistoryObject.id,
                      productName:
                        orderHistoryObject.product === null
                          ? ""
                          : orderHistoryObject.product.name +
                            " ( " +
                            orderHistoryObject.product.shortName +
                            " ) ",
                      uomName:
                        orderHistoryObject.uom === null
                          ? ""
                          : orderHistoryObject.uom.name,

                      outletUomName:
                        orderHistoryObject.product === null
                          ? ""
                          : orderHistoryObject.product.outletUom != null
                          ? orderHistoryObject.product.outletUom.name
                          : "",
                      stakeHolderUomName:
                        orderHistoryObject.product === null
                          ? ""
                          : orderHistoryObject.product.stakeHolderUom != null
                          ? orderHistoryObject.product.stakeHolderUom.name
                          : "",

                      batchCode:
                        orderHistoryObject.batchCode === null
                          ? ""
                          : orderHistoryObject.batchCode,
                      rate:
                        orderHistoryObject.rate === null
                          ? ""
                          : orderHistoryObject.rate.toFixed(2),
                      qty:
                        orderHistoryObject.qty === null
                          ? ""
                          : +orderHistoryObject.qty,

                      secondaryApprovedQty:
                        orderHistoryObject.secondaryApprovedQty === null
                          ? ""
                          : +orderHistoryObject.secondaryApprovedQty,

                      sgstAmt:
                        orderHistoryObject.sgstAmt === null
                          ? ""
                          : +orderHistoryObject.sgstAmt,

                      sgstPer:
                        orderHistoryObject.sgstPer === null
                          ? ""
                          : +orderHistoryObject.sgstPer,

                      approvedQty:
                        orderHistoryObject.approvedQty === null
                          ? 0
                          : orderHistoryObject.approvedQty,
                      approvedQtyError: 0,
                      secQty:
                        orderHistoryObject.secondaryQty !== null &&
                        orderHistoryObject.uomConversionValue !== null
                          ? orderHistoryObject.secondaryQty %
                            orderHistoryObject.uomConversionValue
                          : "",
                      taxableAmt:
                        orderHistoryObject.taxableAmt === null
                          ? ""
                          : orderHistoryObject.taxableAmt.toFixed(2),
                      totalAmt:
                        orderHistoryObject.totalAmt === null
                          ? ""
                          : orderHistoryObject.totalAmt.toFixed(2),
                      totalAmtForPendingGrn:
                        orderHistoryObject.totalAmt === null
                          ? ""
                          : orderHistoryObject.totalAmt.toFixed(2),
                      product:
                        orderHistoryObject.product === null
                          ? ""
                          : orderHistoryObject.product,
                      uom:
                        orderHistoryObject.uom === null
                          ? ""
                          : orderHistoryObject.uom,

                      billDetailId:
                        orderHistoryObject.billDetailId === null
                          ? ""
                          : orderHistoryObject.billDetailId,
                      cessAmt:
                        orderHistoryObject.billDetailId === null
                          ? ""
                          : orderHistoryObject.billDetailId,
                      cessPer:
                        orderHistoryObject.cessPer === null
                          ? ""
                          : orderHistoryObject.cessPer,
                      cgstAmt:
                        orderHistoryObject.cgstAmt === null
                          ? ""
                          : orderHistoryObject.cgstAmt,
                      cgstPer:
                        orderHistoryObject.cgstPer === null
                          ? ""
                          : orderHistoryObject.cgstPer,
                      grnPer:
                        orderHistoryObject.grnPer === null
                          ? ""
                          : orderHistoryObject.grnPer,
                      grnRate:
                        orderHistoryObject.grnRate === null
                          ? ""
                          : orderHistoryObject.grnRate,
                      hsnCode:
                        orderHistoryObject.hsnCode === null
                          ? ""
                          : orderHistoryObject.hsnCode,
                      id:
                        orderHistoryObject.id === null
                          ? ""
                          : orderHistoryObject.id,
                      igstAmt:
                        orderHistoryObject.igstAmt === null
                          ? ""
                          : orderHistoryObject.igstAmt,
                      igstPer:
                        orderHistoryObject.igstPer === null
                          ? ""
                          : orderHistoryObject.igstPer,
                      mrp:
                        orderHistoryObject.mrp === null
                          ? ""
                          : orderHistoryObject.mrp,
                      primaryApprovedQty:
                        orderHistoryObject.primaryApprovedQty === null
                          ? ""
                          : orderHistoryObject.primaryApprovedQty,

                      billSecondaryQty:
                        orderHistoryObject.secondaryQty === null
                          ? ""
                          : +orderHistoryObject.secondaryQty.toFixed(2),

                      secondaryQty:
                        orderHistoryObject.secondaryQty === null
                          ? ""
                          : +orderHistoryObject.secondaryQty.toFixed(2),

                      primaryQty:
                        orderHistoryObject.secondaryQty !== null &&
                        orderHistoryObject.uomConversionValue !== null
                          ? Math.floor(
                              orderHistoryObject.secondaryQty /
                                orderHistoryObject.uomConversionValue
                            )
                          : "",
                      totalQty:
                        orderHistoryObject.secondaryQty === null
                          ? ""
                          : +orderHistoryObject.secondaryQty,
                      qtyError: false,
                      orderDate:
                        orderHistoryObject.orderDate === null
                          ? ""
                          : orderHistoryObject.orderDate,
                      stockStakeHolder:
                        orderHistoryObject.stockStakeHolder === null
                          ? ""
                          : orderHistoryObject.stockStakeHolder,
                      type:
                        orderHistoryObject.type === null
                          ? ""
                          : orderHistoryObject.type,
                      insertedUserId:
                        orderHistoryObject.insertedUserId === null
                          ? ""
                          : orderHistoryObject.insertedUserId,
                      insertDateTime:
                        orderHistoryObject.insertDateTime === null
                          ? ""
                          : orderHistoryObject.insertDateTime,
                      uomConversionValue:
                        orderHistoryObject.uomConversionValue === null
                          ? ""
                          : orderHistoryObject.uomConversionValue,
                      stockStakeHolderBillDetailId:
                        orderHistoryObject.stockStakeHolderBillDetailId === null
                          ? ""
                          : orderHistoryObject.stockStakeHolderBillDetailId,
                    };
                    return orderHistoryData;
                  }),
          };
          dispatch(requestGRNHeaderDetailsSuccess({ row: grnGVNHeaderData }));
          dispatch(
            requestGRNHeaderDetailsSucess({
              row: grnGVNHeaderData.grnGvnDetails,
            })
          );
          return { response: grnGVNHeaderData, success };
        }
        return { response: {}, success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
