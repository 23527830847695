import {
  FormControlLabel,
  Grid,
  Link,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../components/Comman/Label";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import RTTable from "../../components/Comman/RT/RTTable";
import { cyanBlue, redColor, titleColor } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import { dataNotFoundMsg, noInternetMsg, serverMsg } from "../../config/messageconstant";
import { getSelectedBeatCountFromSalesTeamIdAndDate } from "../../Slice/beat.slice";
import {
  getBelowSalesTeamByReportingId,
  getSalesTeamUserSummary,
  getSalesTeamWorkingSummary,
  getTopOrderSalesTeam,
  setSalesTeamWorkingSummary,
} from "../../Slice/manager.slice";
import { getSalesExecutive } from "../../Slice/salesExecutive.slice";
import { getStockStakeHolderType } from "../../Slice/stockStakeHolderType.slice";
import BeatPopUp from "../DashbourdChanges/BeatPopUp";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { downloadF } from "../PDF/PDFDownload";

class UserSummaryNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        nationalSalesManagerDetail: this.props.managerList?.allNSM,
        salesOfficerDetail: this.props.managerList?.salesTeamBelowReportingIdSO,

      },

      userType3SortOrderNo1Value: "",
      userType3SortOrderNo2Value: "",
      userType3SortOrderNo3Value: "",
      userType3SortOrderNo4Value: "",
      userType3SortOrderNo5Value: "",
      userType3SortOrderNo6Value: "",
      userType3SortOrderNo7Value: "",
      userType3SortOrderNo8Value: "",
      userType4SortOrderNo1Value: "",

      userType3SortOrderNo1Flag: {},
      userType3SortOrderNo2Flag: {},
      userType3SortOrderNo3Flag: {},
      userType3SortOrderNo4Flag: {},
      userType3SortOrderNo5Flag: {},
      userType3SortOrderNo6Flag: {},
      userType3SortOrderNo7Flag: {},
      userType3SortOrderNo8Flag: {},
      userType4SortOrderNo1Flag: {},

      userType3SortOrderNo1List: [],
      userType3SortOrderNo2List: [],
      userType3SortOrderNo3List: [],
      userType3SortOrderNo4List: [],
      userType3SortOrderNo5List: [],
      userType3SortOrderNo6List: [],
      userType3SortOrderNo7List: [],
      userType3SortOrderNo8List: [],
      userType4SortOrderNo1List: [],

      fromDate: "",
      toDate: "",
      formErrors: {},
      stockHolderTypeId: "",
      rowList: [],
      beatPopUpFlag: false,
      summaryTableFlag: false,
      salesTeamList: [],
      nsmList: [],
      rsmList: [],
      zsmList: [],
      asmList: [],
      aseList: [],
      soList: [],
      tsiList: [],
      nsmId: "",
      zsmId: "",
      rsmId: "",
      asmId: "",
      aseId: "",
      soId: "",
      tsiId: "",
      fromDate: dayjs(),
      toDate: dayjs(),
      value: 0,
      radioType: 0,
      flag: 0,
      stockHolderType: "",
      salesTeam: "",
      dateFlag: "",
      salesTeamIdForExcel: "",
      userTypeId: "",
      sortOrderNo: "",
    };
  }

  async componentDidMount() {
    const {
      getTopOrderSalesTeam,
      getStockStakeHolderType,
      showLoador,
      showNotification,
      setSalesTeamWorkingSummary,
    } = this.props;


    showLoador({ loador: true });
    await getStockStakeHolderType({ userTypeIds: "3,4" }).then(
      ({ response, success }) => {
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          getTopOrderSalesTeam().then(({ response, success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            } else {
              this.setState({
                userType3SortOrderNo1Flag:
                  response.length != 0 ? response[0] : {},
                userType3SortOrderNo1List: response,
              });
            }
          });
        }
      }
    );
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.managerList !== nextProps.managerList) {
      if (this.props.managerList?.allNSM !== nextProps.managerList?.allNSM) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            nationalSalesManagerDetail: nextProps.managerList?.allNSM,
          },
        });
      }
    }
  }

  getListById = (userTypeAndSortOrderList, newValue) => {
    const { getBelowSalesTeamByReportingId, showLoador, showNotification } =
      this.props;

    showLoador({ loador: true });
    getBelowSalesTeamByReportingId({
      stockStakeHolderTypeId: userTypeAndSortOrderList.id,
      salesTeamId: newValue,
    }).then(({ response, success }) => {
      showLoador({ loador: false });
      if (!success) {
        showNotification({ msg: serverMsg, severity: "error" });
      } else {
        let userType3SortOrderNo2ListNew = response.data.filter(
          (row) => row.userTypeId == 3 && row.sortOrder == 2
        );
        let userType3SortOrderNo3ListNew = response.data.filter(
          (row) => row.userTypeId == 3 && row.sortOrder == 3
        );
        let userType3SortOrderNo4ListNew = response.data.filter(
          (row) => row.userTypeId == 3 && row.sortOrder == 4
        );
        let userType3SortOrderNo5ListNew = response.data.filter(
          (row) => row.userTypeId == 3 && row.sortOrder == 5
        );
        let userType3SortOrderNo6ListNew = response.data.filter(
          (row) => row.userTypeId == 3 && row.sortOrder == 6
        );
        let userType3SortOrderNo7ListNew = response.data.filter(
          (row) => row.userTypeId == 3 && row.sortOrder == 7
        );
        let userType3SortOrderNo8ListNew = response.data.filter(
          (row) => row.userTypeId == 3 && row.sortOrder == 8
        );
        let userType4SortOrderNo1ListNew = response.data.filter(
          (row) => row.userTypeId == 4 && row.sortOrder == 1
        );

        if (userTypeAndSortOrderList.sortOrder == 1) {
          this.setState({
            userType3SortOrderNo2List:
              userType3SortOrderNo2ListNew.length != 0
                ? userType3SortOrderNo2ListNew[0].salesTeamList
                : "",
            userType3SortOrderNo3List:
              userType3SortOrderNo3ListNew.length != 0
                ? userType3SortOrderNo3ListNew[0].salesTeamList
                : "",
            userType3SortOrderNo4List:
              userType3SortOrderNo4ListNew.length != 0
                ? userType3SortOrderNo4ListNew[0].salesTeamList
                : "",
            userType3SortOrderNo5List:
              userType3SortOrderNo5ListNew.length != 0
                ? userType3SortOrderNo5ListNew[0].salesTeamList
                : "",
            userType3SortOrderNo6List:
              userType3SortOrderNo6ListNew.length != 0
                ? userType3SortOrderNo6ListNew[0].salesTeamList
                : "",
            userType3SortOrderNo7List:
              userType3SortOrderNo7ListNew.length != 0
                ? userType3SortOrderNo7ListNew[0].salesTeamList
                : "",
            userType3SortOrderNo8List:
              userType3SortOrderNo8ListNew.length != 0
                ? userType3SortOrderNo8ListNew[0].salesTeamList
                : "",
            userType4SortOrderNo1List:
              userType4SortOrderNo1ListNew.length != 0
                ? userType4SortOrderNo1ListNew[0].salesTeamList
                : "",
          });
        } else if (userTypeAndSortOrderList.sortOrder == 2) {
          this.setState({
            userType3SortOrderNo3List:
              userType3SortOrderNo3ListNew.length != 0
                ? userType3SortOrderNo3ListNew[0].salesTeamList
                : "",
            userType3SortOrderNo4List:
              userType3SortOrderNo4ListNew.length != 0
                ? userType3SortOrderNo4ListNew[0].salesTeamList
                : "",
            userType3SortOrderNo5List:
              userType3SortOrderNo5ListNew.length != 0
                ? userType3SortOrderNo5ListNew[0].salesTeamList
                : "",
            userType3SortOrderNo6List:
              userType3SortOrderNo6ListNew.length != 0
                ? userType3SortOrderNo6ListNew[0].salesTeamList
                : "",
            userType3SortOrderNo7List:
              userType3SortOrderNo7ListNew.length != 0
                ? userType3SortOrderNo7ListNew[0].salesTeamList
                : "",
            userType3SortOrderNo8List:
              userType3SortOrderNo8ListNew.length != 0
                ? userType3SortOrderNo8ListNew[0].salesTeamList
                : "",
            userType4SortOrderNo1List:
              userType4SortOrderNo1ListNew.length != 0
                ? userType4SortOrderNo1ListNew[0].salesTeamList
                : "",
          });
        } else if (userTypeAndSortOrderList.sortOrder == 3) {
          this.setState({
            userType3SortOrderNo4List:
              userType3SortOrderNo4ListNew.length != 0
                ? userType3SortOrderNo4ListNew[0].salesTeamList
                : "",
            userType3SortOrderNo5List:
              userType3SortOrderNo5ListNew.length != 0
                ? userType3SortOrderNo5ListNew[0].salesTeamList
                : "",
            userType3SortOrderNo6List:
              userType3SortOrderNo6ListNew.length != 0
                ? userType3SortOrderNo6ListNew[0].salesTeamList
                : "",
            userType3SortOrderNo7List:
              userType3SortOrderNo7ListNew.length != 0
                ? userType3SortOrderNo7ListNew[0].salesTeamList
                : "",
            userType3SortOrderNo8List:
              userType3SortOrderNo8ListNew.length != 0
                ? userType3SortOrderNo8ListNew[0].salesTeamList
                : "",
            userType4SortOrderNo1List:
              userType4SortOrderNo1ListNew.length != 0
                ? userType4SortOrderNo1ListNew[0].salesTeamList
                : "",
          });
        } else if (userTypeAndSortOrderList.sortOrder == 4) {
          this.setState({
            userType3SortOrderNo5List:
              userType3SortOrderNo5ListNew.length != 0
                ? userType3SortOrderNo5ListNew[0].salesTeamList
                : "",
            userType3SortOrderNo6List:
              userType3SortOrderNo6ListNew.length != 0
                ? userType3SortOrderNo6ListNew[0].salesTeamList
                : "",
            userType3SortOrderNo7List:
              userType3SortOrderNo7ListNew.length != 0
                ? userType3SortOrderNo7ListNew[0].salesTeamList
                : "",
            userType3SortOrderNo8List:
              userType3SortOrderNo8ListNew.length != 0
                ? userType3SortOrderNo8ListNew[0].salesTeamList
                : "",
            userType4SortOrderNo1List:
              userType4SortOrderNo1ListNew.length != 0
                ? userType4SortOrderNo1ListNew[0].salesTeamList
                : "",
          });
        } else if (userTypeAndSortOrderList.sortOrder == 5) {
          this.setState({
            userType3SortOrderNo6List:
              userType3SortOrderNo6ListNew.length != 0
                ? userType3SortOrderNo6ListNew[0].salesTeamList
                : "",
            userType3SortOrderNo7List:
              userType3SortOrderNo7ListNew.length != 0
                ? userType3SortOrderNo7ListNew[0].salesTeamList
                : "",
            userType3SortOrderNo8List:
              userType3SortOrderNo8ListNew.length != 0
                ? userType3SortOrderNo8ListNew[0].salesTeamList
                : "",
            userType4SortOrderNo1List:
              userType4SortOrderNo1ListNew.length != 0
                ? userType4SortOrderNo1ListNew[0].salesTeamList
                : "",
          });
        } else if (userTypeAndSortOrderList.sortOrder == 6) {
          this.setState({
            userType3SortOrderNo7List:
              userType3SortOrderNo7ListNew.length != 0
                ? userType3SortOrderNo7ListNew[0].salesTeamList
                : "",
            userType3SortOrderNo8List:
              userType3SortOrderNo8ListNew.length != 0
                ? userType3SortOrderNo8ListNew[0].salesTeamList
                : "",
            userType4SortOrderNo1List:
              userType4SortOrderNo1ListNew.length != 0
                ? userType4SortOrderNo1ListNew[0].salesTeamList
                : "",
          });
        } else if (userTypeAndSortOrderList.sortOrder == 7) {
          this.setState({
            userType3SortOrderNo8List:
              userType3SortOrderNo8ListNew.length != 0
                ? userType3SortOrderNo8ListNew[0].salesTeamList
                : "",
            userType4SortOrderNo1List:
              userType4SortOrderNo1ListNew.length != 0
                ? userType4SortOrderNo1ListNew[0].salesTeamList
                : "",
          });
        } else if (userTypeAndSortOrderList.sortOrder == 8) {
          this.setState({
            userType4SortOrderNo1List:
              userType4SortOrderNo1ListNew.length != 0
                ? userType4SortOrderNo1ListNew[0].salesTeamList
                : "",
          });
        }

        this.setState({
          userType3SortOrderNo2Flag:
            userType3SortOrderNo2ListNew.length != 0
              ? userType3SortOrderNo2ListNew[0]
              : {},
          userType3SortOrderNo3Flag:
            userType3SortOrderNo3ListNew.length != 0
              ? userType3SortOrderNo3ListNew[0]
              : {},
          userType3SortOrderNo4Flag:
            userType3SortOrderNo4ListNew.length != 0
              ? userType3SortOrderNo4ListNew[0]
              : {},
          userType3SortOrderNo5Flag:
            userType3SortOrderNo5ListNew.length != 0
              ? userType3SortOrderNo5ListNew[0]
              : {},
          userType3SortOrderNo6Flag:
            userType3SortOrderNo6ListNew.length != 0
              ? userType3SortOrderNo6ListNew[0]
              : {},
          userType3SortOrderNo7Flag:
            userType3SortOrderNo7ListNew.length != 0
              ? userType3SortOrderNo7ListNew[0]
              : {},
          userType3SortOrderNo8Flag:
            userType3SortOrderNo8ListNew.length != 0
              ? userType3SortOrderNo8ListNew[0]
              : {},
          userType4SortOrderNo1Flag:
            userType4SortOrderNo1ListNew.length != 0
              ? userType4SortOrderNo1ListNew[0]
              : {},
        });
      }
    });
  };

  onSearchHandler = (userTypeId, sortOrderNo) => {
    const {
      getSalesTeamUserSummary,
      stockStakeHolderTypeList,
      showLoador,
      showNotification,
    } = this.props;
    const {
      fromDate,
      toDate,
      userType3SortOrderNo1Value,
      userType3SortOrderNo2Value,
      userType3SortOrderNo3Value,
      userType3SortOrderNo4Value,
      userType3SortOrderNo5Value,
      userType3SortOrderNo6Value,
      userType3SortOrderNo7Value,
      userType3SortOrderNo8Value,
      userType4SortOrderNo1Value,
    } = this.state;

    this.setState({ summaryTableFlag: false });

    let stockStakeHolderList =
      stockStakeHolderTypeList?.stockStakeHolderType.filter(
        (row) => row.userType.id == userTypeId && row.sortOrder == sortOrderNo
      );

    let value =
      userTypeId == 3 && sortOrderNo == 1
        ? userType3SortOrderNo1Value
        : userTypeId == 3 && sortOrderNo == 2
          ? userType3SortOrderNo2Value
          : userTypeId == 3 && sortOrderNo == 3
            ? userType3SortOrderNo3Value
            : userTypeId == 3 && sortOrderNo == 4
              ? userType3SortOrderNo4Value
              : userTypeId == 3 && sortOrderNo == 5
                ? userType3SortOrderNo5Value
                : userTypeId == 3 && sortOrderNo == 6
                  ? userType3SortOrderNo6Value
                  : userTypeId == 3 && sortOrderNo == 7
                    ? userType3SortOrderNo7Value
                    : userTypeId == 3 && sortOrderNo == 8
                      ? userType3SortOrderNo8Value
                      : userTypeId == 4 && sortOrderNo == 1
                        ? userType4SortOrderNo1Value
                        : "";

    this.setState({
      salesTeamIdForExcel: value,
      userTypeId: userTypeId,
      sortOrderNo: sortOrderNo,
    });

    if (navigator.onLine) {

      if (this.checkValidation(userTypeId, sortOrderNo)) {
        let responseList = [];
        showLoador({ loador: true });
        getSalesTeamUserSummary({
          stockStakeHolderTypeId:
            stockStakeHolderList.length != 0 ? stockStakeHolderList[0].id : "",
          salesTeamId: value,
          userTypeId: userTypeId,
          fromDate: dayjs(fromDate).format("DD-MM-YYYY"),
          toDate: dayjs(toDate).format("DD-MM-YYYY"),
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            if (Object.keys(response).length !== 0) {
              this.setState({ dateFlag: response.flag });
              responseList.push(response);
            }
            this.setState({
              rowList: responseList,
              fromDate: fromDate,
              toDate: toDate,
            });
          }
        });
      }

    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onSearchHandlerTypeWise = () => {
    const {
      getSalesTeamUserSummary,
      showLoador,
      showNotification,
    } = this.props;
    const {
      fromDate,
      toDate,
      stockHolderType,
      salesTeam,
    } = this.state;

    if (navigator.onLine) {

      this.setState({ summaryTableFlag: false });

      if (this.checkValidationTypeWise()) {
        let responseList = [];
        showLoador({ loador: true });
        getSalesTeamUserSummary({
          stockStakeHolderTypeId: stockHolderType,
          salesTeamId: salesTeam,
          fromDate: dayjs(fromDate).format("DD-MM-YYYY"),
          toDate: dayjs(toDate).format("DD-MM-YYYY"),
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            if (Object.keys(response).length !== 0) {
              this.setState({ dateFlag: response.flag });
              responseList.push(response);
            }
            this.setState({
              rowList: responseList,
              fromDate: fromDate,
              toDate: toDate,
            });
          }
        });
      }

    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onSummaryHandler = (userTypeId, sortOrderNo) => {
    const {
      getSalesTeamWorkingSummary,
      showLoador,
      showNotification,
    } = this.props;
    const {
      fromDate,
      toDate,
      userType3SortOrderNo1Value,
      userType3SortOrderNo2Value,
      userType3SortOrderNo3Value,
      userType3SortOrderNo4Value,
      userType3SortOrderNo5Value,
      userType3SortOrderNo6Value,
      userType3SortOrderNo7Value,
      userType3SortOrderNo8Value,
      userType4SortOrderNo1Value,
    } = this.state;

    this.setState({ summaryTableFlag: true });

    let value =
      userTypeId == 3 && sortOrderNo == 1
        ? userType3SortOrderNo1Value
        : userTypeId == 3 && sortOrderNo == 2
          ? userType3SortOrderNo2Value
          : userTypeId == 3 && sortOrderNo == 3
            ? userType3SortOrderNo3Value
            : userTypeId == 3 && sortOrderNo == 4
              ? userType3SortOrderNo4Value
              : userTypeId == 3 && sortOrderNo == 5
                ? userType3SortOrderNo5Value
                : userTypeId == 3 && sortOrderNo == 6
                  ? userType3SortOrderNo6Value
                  : userTypeId == 3 && sortOrderNo == 7
                    ? userType3SortOrderNo7Value
                    : userTypeId == 3 && sortOrderNo == 8
                      ? userType3SortOrderNo8Value
                      : userTypeId == 4 && sortOrderNo == 1
                        ? userType4SortOrderNo1Value
                        : "";

    this.setState({
      salesTeamIdForExcel: value,
      userTypeId: userTypeId,
      sortOrderNo: sortOrderNo,
    });

    if (navigator.onLine) {


      if (this.checkValidation(userTypeId, sortOrderNo)) {
        let jsonToSend = {
          salesTeamIds: value.split(","),
          fromDate: dayjs(fromDate).format("DD-MM-YYYY"),
          toDate: dayjs(toDate).format("DD-MM-YYYY"),
        }

        showLoador({ loador: true });
        getSalesTeamWorkingSummary({ dataJson: jsonToSend }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            if (response.length === 0) {
              showNotification({ msg: dataNotFoundMsg, severity: "error" });
            }
          }
        });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }

  }

  onSummaryHandlerTypeWise = () => {
    const {
      getSalesTeamWorkingSummary,
      showLoador,
      showNotification,
    } = this.props;
    const {
      fromDate,
      toDate,
      salesTeam,
    } = this.state;

    this.setState({ summaryTableFlag: true });

    if (this.checkValidationTypeWise()) {


      if (navigator.onLine) {
        let jsonToSend = {
          salesTeamIds: salesTeam.split(","),
          fromDate: dayjs(fromDate).format("DD-MM-YYYY"),
          toDate: dayjs(toDate).format("DD-MM-YYYY"),
        }

        showLoador({ loador: true });
        getSalesTeamWorkingSummary({ dataJson: jsonToSend }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            if (response.length === 0) {
              showNotification({ msg: dataNotFoundMsg, severity: "error" });
            }
          }
        });

      } else {
        showNotification({ msg: noInternetMsg, severity: "error" });
      }
    }
  };

  generateExcelUserSummaryEmployeeWise = () => {
    const { stockStakeHolderTypeList, showLoador, showNotification } = this.props;

    const {
      fromDate,
      toDate,
      salesTeamIdForExcel,
      userTypeId,
      sortOrderNo,
    } = this.state;


    let stockStakeHolderList =
      stockStakeHolderTypeList?.stockStakeHolderType.filter(
        (row) => row.userType.id == userTypeId && row.sortOrder == sortOrderNo
      );

    if (navigator.onLine) {
      let excelJson = {
        stockStakeHolderTypeId:
          stockStakeHolderList.length != 0 ? stockStakeHolderList[0].id : "",
        salesTeamId: salesTeamIdForExcel,
        userTypeId: userTypeId,
        fromDate: dayjs(fromDate).format("DD-MM-YYYY"),
        toDate: dayjs(toDate).format("DD-MM-YYYY"),
      };

      showLoador({ loador: true });
      downloadF({
        isGet: false,
        url: endpoint.manager + "/user-summary-excel",
        postBody: excelJson,
        ext: "xlsx",
        openNewTab: true,
      }).then(({ response, success }) => {
        showLoador({ loador: false });
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }

  };


  generateExcelForUserWorkSummaryEmployeeWise = () => {
    const { stockStakeHolderTypeList, showLoador, showNotification } = this.props;

    const {
      fromDate,
      toDate,
      salesTeamIdForExcel,
      userTypeId,
      sortOrderNo,
    } = this.state;


    if (navigator.onLine) {
      let excelJson = {
        salesTeamId: salesTeamIdForExcel.split(","),
        fromDate: dayjs(fromDate).format("DD-MM-YYYY"),
        toDate: dayjs(toDate).format("DD-MM-YYYY"),
      };

      showLoador({ loador: true });
      downloadF({
        isGet: false,
        url: endpoint.manager + "/working-summary-excel",
        postBody: excelJson,
        ext: "xlsx",
        openNewTab: true,
      }).then(({ response, success }) => {
        showLoador({ loador: false });
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }

  };

  generateExcelForUserSummaryTypeWise = () => {
    const { showLoador, showNotification } =
      this.props;
    const {
      fromDate,
      toDate,
      salesTeam,
      stockHolderType,
    } = this.state;


    if (navigator.onLine) {
      let excelJson = {
        stockStakeHolderTypeId: stockHolderType,
        salesTeamId: salesTeam,
        userTypeId: "",
        fromDate: dayjs(fromDate).format("DD-MM-YYYY"),
        toDate: dayjs(toDate).format("DD-MM-YYYY"),
      };

      showLoador({ loador: true });
      downloadF({
        isGet: false,
        url: endpoint.manager + "/user-summary-excel",
        postBody: excelJson,
        ext: "xlsx",
        openNewTab: true,
      }).then(({ response, success }) => {
        showLoador({ loador: false });
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }

  };

  generateExcelForUserWorkSummaryTypeWise = () => {
    const { showLoador, showNotification } =
      this.props;
    const {
      fromDate,
      toDate,
      salesTeam,
    } = this.state;


    if (navigator.onLine) {
      let excelJson = {
        salesTeamIds: salesTeam.split(","),
        fromDate: dayjs(fromDate).format("DD-MM-YYYY"),
        toDate: dayjs(toDate).format("DD-MM-YYYY"),
      };

      showLoador({ loador: true });
      downloadF({
        isGet: false,
        url: endpoint.manager + "/working-summary-excel",
        postBody: excelJson,
        ext: "xlsx",
        openNewTab: true,
      }).then(({ response, success }) => {
        showLoador({ loador: false });
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }

  };

  salesTeamHandler = (e, salesTeamId) => {
    const {
      getSelectedBeatCountFromSalesTeamIdAndDate,
      showLoador,
      showNotification,
    } = this.props;
    const { fromDate, toDate } = this.state;

    if (navigator.onLine) {
      showLoador({ loador: true });
      getSelectedBeatCountFromSalesTeamIdAndDate({
        salesTeamId: salesTeamId,
        fromDate: dayjs(fromDate).format("DD-MM-YYYY"),
        toDate: dayjs(toDate).format("DD-MM-YYYY"),
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.setState({
            beatPopUpFlag: true,
          });
        }
      });
    } else {
      showNotification({ msg: myConstClass.noInternetMsg, severity: "error" });
    }
  };

  handleClosesSalesTeam = () => {
    this.setState({
      beatPopUpFlag: false,
    });
  };

  changeDate = (name) => (date) => {
    this.setState({
      [name]: date,
    });
  };

  onAutocompleteChange = (userTypeAndSortOrder, newValue) => {
    const { stockStakeHolderTypeList } = this.props;
    const {
      userType3SortOrderNo1Flag,
      userType3SortOrderNo2Flag,
      userType3SortOrderNo3Flag,
      userType3SortOrderNo4Flag,
      userType3SortOrderNo5Flag,
      userType3SortOrderNo6Flag,
      userType3SortOrderNo7Flag,
      userType3SortOrderNo8Flag,
      userType4SortOrderNo1Flag,
    } = this.state;
    let userTypeValue =
      userTypeAndSortOrder == userType4SortOrderNo1Flag ? 4 : 3;
    let sortOrderValue = "";
    if (userTypeAndSortOrder == userType3SortOrderNo1Flag) {
      sortOrderValue = 1;
      this.setState({
        userType3SortOrderNo1Value: newValue,
      });
    } else if (userTypeAndSortOrder == userType3SortOrderNo2Flag) {
      sortOrderValue = 2;
      this.setState({
        userType3SortOrderNo2Value: newValue,
      });
    } else if (userTypeAndSortOrder == userType3SortOrderNo3Flag) {
      sortOrderValue = 3;
      this.setState({
        userType3SortOrderNo3Value: newValue,
      });
    } else if (userTypeAndSortOrder == userType3SortOrderNo4Flag) {
      sortOrderValue = 4;
      this.setState({
        userType3SortOrderNo4Value: newValue,
      });
    } else if (userTypeAndSortOrder == userType3SortOrderNo5Flag) {
      sortOrderValue = 5;
      this.setState({
        userType3SortOrderNo5Value: newValue,
      });
    } else if (userTypeAndSortOrder == userType3SortOrderNo6Flag) {
      sortOrderValue = 6;
      this.setState({
        userType3SortOrderNo6Value: newValue,
      });
    } else if (userTypeAndSortOrder == userType3SortOrderNo7Flag) {
      sortOrderValue = 7;
      this.setState({
        userType3SortOrderNo7Value: newValue,
      });
    } else if (userTypeAndSortOrder == userType3SortOrderNo8Flag) {
      sortOrderValue = 8;
      this.setState({
        userType4SortOrderNo1Value: newValue,
      });
    } else {
      sortOrderValue = 1;
      this.setState({
        userType4SortOrderNo1Value: newValue,
      });
    }


    this.setState({
      rowList: [],
    });

    if (
      newValue != null &&
      userTypeAndSortOrder !== userType4SortOrderNo1Flag
    ) {
      let stockStakeHolderList =
        stockStakeHolderTypeList?.stockStakeHolderType.filter(
          (row) =>
            row.userType.id == userTypeValue && row.sortOrder == sortOrderValue
        );

      this.getListById(stockStakeHolderList[0], newValue);
    }
  };


  onAutocompleteChangeTypeWise = (name, newValue) => {
    const { showLoador, showNotification, getSalesExecutive } = this.props;

    if (name === "stockHolderType") {
      this.setState({
        stockHolderType: newValue,
      });

      showLoador({ loador: true });
      getSalesExecutive({ stockStakeHolderId: newValue }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    }

    if (name === "salesTeam") {
      this.setState({
        salesTeam: newValue,
      });
    }

  }




  onRadioChange = (e) => {
    const { value, name } = e.target;
    const { setSalesTeamWorkingSummary } = this.props;

    this.setState({ rowList: [] });

    setSalesTeamWorkingSummary({ row: [] });

    if (name == "radioType") {
      this.setState({
        radioType: value,
      });
    }

  };


  checkValidation = (userTypeId, sortOrderNo) => {
    const {
      userType3SortOrderNo1Value,
      userType3SortOrderNo2Value,
      userType3SortOrderNo3Value,
      userType3SortOrderNo4Value,
      userType3SortOrderNo5Value,
      userType3SortOrderNo6Value,
      userType3SortOrderNo7Value,
      userType3SortOrderNo8Value,
      userType4SortOrderNo1Value,
    } = this.state;


    let formErrors = {};
    let formIsValid = true;

    if (userTypeId == 3 && sortOrderNo == 1) {
      if (userType3SortOrderNo1Value === "" || userType3SortOrderNo1Value === null) {
        formIsValid = false;
        formErrors["userType3SortOrderNo1ValueError"] = "Select Value";
      }
    }

    if (userTypeId == 3 && sortOrderNo == 2) {
      if (userType3SortOrderNo2Value === "" || userType3SortOrderNo2Value === null) {
        formIsValid = false;
        formErrors["userType3SortOrderNo2ValueError"] = "Select Value";
      }
    }

    if (userTypeId == 3 && sortOrderNo == 3) {
      if (userType3SortOrderNo3Value === "" || userType3SortOrderNo3Value === null) {
        formIsValid = false;
        formErrors["userType3SortOrderNo3ValueError"] = "Select Value";
      }
    }

    if (userTypeId == 3 && sortOrderNo == 4) {
      if (userType3SortOrderNo4Value === "" || userType3SortOrderNo4Value === null) {
        formIsValid = false;
        formErrors["userType3SortOrderNo4ValueError"] = "Select Value";
      }
    }

    if (userTypeId == 3 && sortOrderNo == 5) {
      if (userType3SortOrderNo5Value === "" || userType3SortOrderNo5Value === null) {
        formIsValid = false;
        formErrors["userType3SortOrderNo5ValueError"] = "Select Value";
      }
    }


    if (userTypeId == 3 && sortOrderNo == 6) {
      if (userType3SortOrderNo6Value === "" || userType3SortOrderNo6Value === null) {
        formIsValid = false;
        formErrors["userType3SortOrderNo6ValueError"] = "Select Value";
      }
    }

    if (userTypeId == 3 && sortOrderNo == 7) {
      if (userType3SortOrderNo7Value === "" || userType3SortOrderNo7Value === null) {
        formIsValid = false;
        formErrors["userType3SortOrderNo7ValueError"] = "Select Value";
      }
    }

    if (userTypeId == 3 && sortOrderNo == 8) {
      if (userType3SortOrderNo8Value === "" || userType3SortOrderNo8Value === null) {
        formIsValid = false;
        formErrors["userType3SortOrderNo8ValueError"] = "Select Value";
      }
    }

    if (userTypeId == 4 && sortOrderNo == 1) {
      if (userType4SortOrderNo1Value === "" || userType4SortOrderNo1Value === null) {
        formIsValid = false;
        formErrors["userType4SortOrderNo1ValueError"] = "Select Value";
      }
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  };

  checkValidationForExcelEmployeeWise = () => {
    const {
      userType3SortOrderNo1Value,
      userType3SortOrderNo2Value,
      userType3SortOrderNo3Value,
      userType3SortOrderNo4Value,
      userType3SortOrderNo5Value,
      userType3SortOrderNo6Value,
      userType3SortOrderNo7Value,
      userType3SortOrderNo8Value,
      userType4SortOrderNo1Value,
      userTypeId,
      sortOrderNo,
    } = this.state;


    let formErrors = {};
    let formIsValid = true;

    if (userTypeId == 3 && sortOrderNo == 1) {
      if (userType3SortOrderNo1Value === "" || userType3SortOrderNo1Value === null) {
        formIsValid = false;
        formErrors["userType3SortOrderNo1ValueError"] = "Select Value";
      }
    }

    if (userTypeId == 3 && sortOrderNo == 2) {
      if (userType3SortOrderNo2Value === "" || userType3SortOrderNo2Value === null) {
        formIsValid = false;
        formErrors["userType3SortOrderNo2ValueError"] = "Select Value";
      }
    }

    if (userTypeId == 3 && sortOrderNo == 3) {
      if (userType3SortOrderNo3Value === "" || userType3SortOrderNo3Value === null) {
        formIsValid = false;
        formErrors["userType3SortOrderNo3ValueError"] = "Select Value";
      }
    }

    if (userTypeId == 3 && sortOrderNo == 4) {
      if (userType3SortOrderNo4Value === "" || userType3SortOrderNo4Value === null) {
        formIsValid = false;
        formErrors["userType3SortOrderNo4ValueError"] = "Select Value";
      }
    }

    if (userTypeId == 3 && sortOrderNo == 5) {
      if (userType3SortOrderNo5Value === "" || userType3SortOrderNo5Value === null) {
        formIsValid = false;
        formErrors["userType3SortOrderNo5ValueError"] = "Select Value";
      }
    }


    if (userTypeId == 3 && sortOrderNo == 6) {
      if (userType3SortOrderNo6Value === "" || userType3SortOrderNo6Value === null) {
        formIsValid = false;
        formErrors["userType3SortOrderNo6ValueError"] = "Select Value";
      }
    }

    if (userTypeId == 3 && sortOrderNo == 7) {
      if (userType3SortOrderNo7Value === "" || userType3SortOrderNo7Value === null) {
        formIsValid = false;
        formErrors["userType3SortOrderNo7ValueError"] = "Select Value";
      }
    }

    if (userTypeId == 3 && sortOrderNo == 8) {
      if (userType3SortOrderNo8Value === "" || userType3SortOrderNo8Value === null) {
        formIsValid = false;
        formErrors["userType3SortOrderNo8ValueError"] = "Select Value";
      }
    }

    if (userTypeId == 4 && sortOrderNo == 1) {
      if (userType4SortOrderNo1Value === "" || userType4SortOrderNo1Value === null) {
        formIsValid = false;
        formErrors["userType4SortOrderNo1ValueError"] = "Select Value";
      }
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  };


  checkValidationTypeWise = () => {
    const { stockHolderType, salesTeam } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (stockHolderType === "" || stockHolderType === null) {
      formIsValid = false;
      formErrors["stockHolderTypeError"] = "Select Stock Holder Type";
    }

    if (salesTeam === "" || salesTeam === null) {
      formIsValid = false;
      formErrors["salesTeamError"] = "Select Sales Team";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }




  render() {
    const { beatList, stockStakeHolderTypeList, salesExecutiveList, managerList } = this.props;
    const {
      formErrors,
      rowList,
      beatPopUpFlag,
      fromDate,
      toDate,
      radioType,
      summaryTableFlag,
      stockHolderType,
      salesTeam,
      dateFlag,

      userType3SortOrderNo1Flag,
      userType3SortOrderNo2Flag,
      userType3SortOrderNo3Flag,
      userType3SortOrderNo4Flag,
      userType3SortOrderNo5Flag,
      userType3SortOrderNo6Flag,
      userType3SortOrderNo7Flag,
      userType3SortOrderNo8Flag,
      userType4SortOrderNo1Flag,

      userType3SortOrderNo1List,
      userType3SortOrderNo2List,
      userType3SortOrderNo3List,
      userType3SortOrderNo4List,
      userType3SortOrderNo5List,
      userType3SortOrderNo6List,
      userType3SortOrderNo7List,
      userType3SortOrderNo8List,
      userType4SortOrderNo1List,

      userType3SortOrderNo1Value,
      userType3SortOrderNo2Value,
      userType3SortOrderNo3Value,
      userType3SortOrderNo4Value,
      userType3SortOrderNo5Value,
      userType3SortOrderNo6Value,
      userType3SortOrderNo7Value,
      userType3SortOrderNo8Value,
      userType4SortOrderNo1Value,
    } = this.state;

    return (
      <>
        <Paper
          sx={{
            paddingTop: 2,
            borderRadius: 2,
            px: "2%",
          }}
          elevation={0}
        // style={{ width: "100%" }}
        >
          <LandingScreenHeader
            screenTitle={"User Summary"}
            showAddButton={false}
            showBackButton={false}
          // onCancel={onCancel}
          />
          <br />


          <Grid
            item
            sm={6}
            xs={8}
            md={6}
            lg={6}
            container
            justifyContent="left"
          >
            <Grid item xs={12} md={7} sm={7}>
              <RadioGroup
                row
                aria-label={"radioType"}
                name={"radioType"}
                id={"radioType"}
                value={radioType}
                onChange={this.onRadioChange}
                size="small"
              >
                <FormControlLabel
                  value={"0"}
                  control={<Radio />}
                  label={"Employee Wise"}
                />
                <FormControlLabel
                  value={"1"}
                  control={<Radio />}
                  label={"Type Wise"}
                />
              </RadioGroup>
            </Grid>
          </Grid>
          <br />




          <>
            <Grid
              sm={12}
              xs={12}
              md={12}
              lg={12}
              container
              display="flex"
              justifyContent="space-between"
            >
              <Grid item sm={4} xs={12} md={4.5} lg={4.5}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: redColor }}
                  label={"*"}
                />
                <LabelCompo
                  className="text-black"
                  style={{
                    fontSize: 16,
                    color: titleColor,
                  }}
                  label={"From date"}
                />
                <BasicDatePicker
                  color="primary"
                  name="fromDate"
                  size="small"
                  value={fromDate}
                  disabled={false}
                  fullWidth
                  onChange={this.changeDate("fromDate")}
                  errorText={" "}
                  isError={false}
                />
              </Grid>
              <Grid item sm={4} xs={12} md={4.5} lg={4.5}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: redColor }}
                  label={"*"}
                />
                <LabelCompo
                  className="text-black"
                  style={{
                    fontSize: 16,
                    color: titleColor,
                  }}
                  label={"To date"}
                />
                <BasicDatePicker
                  color="primary"
                  name="toDate"
                  size="small"
                  value={toDate}
                  disabled={false}
                  fullWidth
                  onChange={this.changeDate("toDate")}
                  errorText={" "}
                  isError={false}
                />
              </Grid>
              <Grid item sm={4} xs={12} md={2.5} lg={2.5} />
            </Grid>
          </>

          {radioType == 0 ? (
            <>

              {userType3SortOrderNo1List.length != 0 && (

                <Grid
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  display="flex"
                  justifyContent="space-between"
                >
                  <Grid item sm={4} xs={12} md={4.5} lg={4.5}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16, color: redColor }}
                      label={"*"}
                    />
                    <LabelCompo
                      className="text-black"
                      style={{
                        fontSize: 16,
                        color: titleColor,
                      }}
                      label={userType3SortOrderNo1Flag.stockStakeHolderTypeName}
                    />{" "}
                    <AutoComplete
                      name={userType3SortOrderNo1Flag}
                      label={""}
                      placeHolder={"SELECT VALUE"}
                      keyColName={"id"}
                      value={userType3SortOrderNo1Value}
                      options={userType3SortOrderNo1List}
                      onChange={this.onAutocompleteChange}
                      errorText={
                        formErrors["userType3SortOrderNo1ValueError"]
                          ? formErrors["userType3SortOrderNo1ValueError"]
                          : ""
                      }
                      isError={
                        formErrors["userType3SortOrderNo1ValueError"]
                          ? formErrors["userType3SortOrderNo1ValueError"]
                          : ""
                      }
                    />
                  </Grid>

                  <Grid
                    item
                    display="flex"
                    alignItems="flex-end"
                    gap={2}
                    sm={4}
                    xs={12}
                    md={4.5}
                    lg={4.5}
                  >

                    <ButtonCompo
                      size="medium"
                      type="submit"
                      variant="contained"
                      name={"Analysis"}
                      onClick={() => this.onSearchHandler(3, 1)}
                    />

                    <ButtonCompo
                      size="medium"
                      type="submit"
                      variant="contained"
                      name={"Summary"}
                      onClick={() => this.onSummaryHandler(3, 1)}
                    />

                  </Grid>
                  <Grid item sm={4} xs={12} md={2.5} lg={2.5} />
                </Grid>

              )}
              <br />
              {userType3SortOrderNo2List.length != 0 && (
                <>
                  <Grid
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Grid item sm={4} xs={12} md={4.5} lg={4.5}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16, color: redColor }}
                        label={"*"}
                      />
                      <LabelCompo
                        className="text-black"
                        style={{
                          fontSize: 16,
                          color: titleColor,
                        }}
                        label={userType3SortOrderNo1Flag.name}
                      />{" "}
                      <AutoComplete
                        name={userType3SortOrderNo2Flag.name}
                        label={""}
                        placeHolder={"SELECT VALUE"}
                        keyColName={"id"}
                        value={userType3SortOrderNo2Value}
                        options={userType3SortOrderNo2List}
                        onChange={this.onAutocompleteChange}
                        errorText={
                          formErrors["userType3SortOrderNo2ValueError"]
                            ? formErrors["userType3SortOrderNo2ValueError"]
                            : ""
                        }
                        isError={
                          formErrors["userType3SortOrderNo2ValueError"]
                            ? formErrors["userType3SortOrderNo2ValueError"]
                            : ""
                        }
                      />
                    </Grid>

                    <Grid
                      item
                      display="flex"
                      alignItems="flex-end"
                      gap={2}
                      sm={4}
                      xs={12}
                      md={4.5}
                      lg={4.5}
                    >

                      <ButtonCompo
                        size="medium"
                        type="submit"
                        variant="contained"
                        name={"Analysis"}
                        onClick={() => this.onSearchHandler(3, 2)}
                      />

                      <ButtonCompo
                        size="medium"
                        type="submit"
                        variant="contained"
                        name={"Summary"}
                        onClick={() => this.onSummaryHandler(3, 2)}
                      />

                    </Grid>
                    <Grid item sm={4} xs={12} md={2.5} lg={2.5} />
                  </Grid>
                  <br />
                </>
              )}
              {userType3SortOrderNo3List.length != 0 && (
                <>
                  <Grid
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Grid item sm={4} xs={12} md={4.5} lg={4.5}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16, color: redColor }}
                        label={"*"}
                      />
                      <LabelCompo
                        className="text-black"
                        style={{
                          fontSize: 16,
                          color: titleColor,
                        }}
                        label={userType3SortOrderNo1Flag.name}
                      />{" "}
                      <AutoComplete
                        name={userType3SortOrderNo3Flag}
                        label={""}
                        placeHolder={"SELECT VALUE"}
                        keyColName={"id"}
                        value={userType3SortOrderNo3Value}
                        options={userType3SortOrderNo3List}
                        onChange={this.onAutocompleteChange}
                        errorText={
                          formErrors["userType3SortOrderNo3ValueError"]
                            ? formErrors["userType3SortOrderNo3ValueError"]
                            : ""
                        }
                        isError={
                          formErrors["userType3SortOrderNo3ValueError"]
                            ? formErrors["userType3SortOrderNo3ValueError"]
                            : ""
                        }
                      />
                    </Grid>

                    <Grid
                      item
                      display="flex"
                      alignItems="flex-end"
                      gap={2}
                      sm={4}
                      xs={12}
                      md={4.5}
                      lg={4.5}
                    >

                      <ButtonCompo
                        size="medium"
                        type="submit"
                        variant="contained"
                        name={"Analysis"}
                        onClick={() => this.onSearchHandler(3, 3)}
                      />


                      <ButtonCompo
                        size="medium"
                        type="submit"
                        variant="contained"
                        name={"Summary"}
                        onClick={() => this.onSummaryHandler(3, 3)}
                      />

                    </Grid>
                    <Grid item sm={4} xs={12} md={2.5} lg={2.5} />
                  </Grid>
                  <br />
                </>
              )}
              {userType3SortOrderNo4List.length != 0 && (
                <>
                  <Grid
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Grid item sm={4} xs={12} md={4.5} lg={4.5}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16, color: redColor }}
                        label={"*"}
                      />
                      <LabelCompo
                        className="text-black"
                        style={{
                          fontSize: 16,
                          color: titleColor,
                        }}
                        label={userType3SortOrderNo4Flag.name}
                      />{" "}
                      <AutoComplete
                        name={userType3SortOrderNo4Flag}
                        label={""}
                        placeHolder={"SELECT VALUE"}
                        keyColName={"id"}
                        value={userType3SortOrderNo4Value}
                        options={userType3SortOrderNo4List}
                        onChange={this.onAutocompleteChange}
                        errorText={
                          formErrors["userType3SortOrderNo4ValueError"]
                            ? formErrors["userType3SortOrderNo4ValueError"]
                            : ""
                        }
                        isError={
                          formErrors["userType3SortOrderNo4ValueError"]
                            ? formErrors["userType3SortOrderNo4ValueError"]
                            : ""
                        }
                      />
                    </Grid>

                    <Grid
                      item
                      display="flex"
                      alignItems="flex-end"
                      gap={2}
                      sm={4}
                      xs={12}
                      md={4.5}
                      lg={4.5}
                    >

                      <ButtonCompo
                        size="medium"
                        type="submit"
                        variant="contained"
                        name={"Analysis"}
                        onClick={() => this.onSearchHandler(3, 4)}
                      />

                      <ButtonCompo
                        size="medium"
                        type="submit"
                        variant="contained"
                        name={"Summary"}
                        onClick={() => this.onSummaryHandler(3, 4)}
                      />

                    </Grid>
                    <Grid item sm={4} xs={12} md={2.5} lg={2.5} />
                  </Grid>
                  <br />
                </>
              )}
              {userType3SortOrderNo5List.length != 0 && (
                <>
                  <Grid
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Grid item sm={4} xs={12} md={4.5} lg={4.5}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16, color: redColor }}
                        label={"*"}
                      />
                      <LabelCompo
                        className="text-black"
                        style={{
                          fontSize: 16,
                          color: titleColor,
                        }}
                        label={userType3SortOrderNo5Flag.name}
                      />{" "}
                      <AutoComplete
                        name={userType3SortOrderNo5Flag}
                        label={""}
                        placeHolder={"SELECT VALUE"}
                        keyColName={"id"}
                        value={userType3SortOrderNo5Value}
                        options={userType3SortOrderNo5List}
                        onChange={this.onAutocompleteChange}
                        errorText={
                          formErrors["userType3SortOrderNo5ValueError"]
                            ? formErrors["userType3SortOrderNo5ValueError"]
                            : ""
                        }
                        isError={
                          formErrors["userType3SortOrderNo5ValueError"]
                            ? formErrors["userType3SortOrderNo5ValueError"]
                            : ""
                        }
                      />
                    </Grid>

                    <Grid
                      item
                      display="flex"
                      alignItems="flex-end"
                      gap={2}
                      sm={4}
                      xs={12}
                      md={4.5}
                      lg={4.5}
                    >

                      <ButtonCompo
                        size="medium"
                        type="submit"
                        variant="contained"
                        name={"Analysis"}
                        onClick={() => this.onSearchHandler(3, 5)}
                      />


                      <ButtonCompo
                        size="medium"
                        type="submit"
                        variant="contained"
                        name={"Summary"}
                        onClick={() => this.onSummaryHandler(3, 5)}
                      />

                    </Grid>
                    <Grid item sm={4} xs={12} md={2.5} lg={2.5} />
                  </Grid>
                  <br />
                </>
              )}
              {userType3SortOrderNo6List.length != 0 && (
                <>
                  <Grid
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Grid item sm={4} xs={12} md={4.5} lg={4.5}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16, color: redColor }}
                        label={"*"}
                      />
                      <LabelCompo
                        className="text-black"
                        style={{
                          fontSize: 16,
                          color: titleColor,
                        }}
                        label={userType3SortOrderNo6Flag.name}
                      />{" "}
                      <AutoComplete
                        name={userType3SortOrderNo6Flag}
                        label={""}
                        placeHolder={"SELECT VALUE"}
                        keyColName={"id"}
                        value={userType3SortOrderNo6Value}
                        options={userType3SortOrderNo6List}
                        onChange={this.onAutocompleteChange}
                        errorText={
                          formErrors["userType3SortOrderNo6ValueError"]
                            ? formErrors["userType3SortOrderNo6ValueError"]
                            : ""
                        }
                        isError={
                          formErrors["userType3SortOrderNo6ValueError"]
                            ? formErrors["userType3SortOrderNo6ValueError"]
                            : ""
                        }
                      />
                    </Grid>

                    <Grid
                      item
                      display="flex"
                      alignItems="flex-end"
                      gap={2}
                      sm={4}
                      xs={12}
                      md={4.5}
                      lg={4.5}
                    >

                      <ButtonCompo
                        size="medium"
                        type="submit"
                        variant="contained"
                        name={"Analysis"}
                        onClick={() => this.onSearchHandler(3, 6)}
                      />

                      <ButtonCompo
                        size="medium"
                        type="submit"
                        variant="contained"
                        name={"Summary"}
                        onClick={() => this.onSummaryHandler(3, 6)}
                      />

                    </Grid>
                    <Grid item sm={4} xs={12} md={2.5} lg={2.5} />
                  </Grid>
                  <br />
                </>
              )}
              {userType3SortOrderNo7List.length != 0 && (
                <>
                  <Grid
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Grid item sm={4} xs={12} md={4.5} lg={4.5}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16, color: redColor }}
                        label={"*"}
                      />
                      <LabelCompo
                        className="text-black"
                        style={{
                          fontSize: 16,
                          color: titleColor,
                        }}
                        label={userType3SortOrderNo6Flag.name}
                      />{" "}
                      <AutoComplete
                        name={userType3SortOrderNo7Flag}
                        label={""}
                        placeHolder={"SELECT VALUE"}
                        keyColName={"id"}
                        value={userType3SortOrderNo7Value}
                        options={userType3SortOrderNo7List}
                        onChange={this.onAutocompleteChange}
                        errorText={
                          formErrors["userType3SortOrderNo7ValueError"]
                            ? formErrors["userType3SortOrderNo7ValueError"]
                            : ""
                        }
                        isError={
                          formErrors["userType3SortOrderNo7ValueError"]
                            ? formErrors["userType3SortOrderNo7ValueError"]
                            : ""
                        }
                      />
                    </Grid>

                    <Grid
                      item
                      display="flex"
                      alignItems="flex-end"
                      gap={2}
                      sm={4}
                      xs={12}
                      md={4.5}
                      lg={4.5}
                    >
                      <ButtonCompo
                        size="medium"
                        type="submit"
                        variant="contained"
                        name={"Analysis"}
                        onClick={() => this.onSearchHandler(3, 7)}
                      />


                      <ButtonCompo
                        size="medium"
                        type="submit"
                        variant="contained"
                        name={"Summary"}
                        onClick={() => this.onSummaryHandler(3, 7)}
                      />

                    </Grid>
                    <Grid item sm={4} xs={12} md={2.5} lg={2.5} />
                  </Grid>
                  <br />
                </>
              )}
              {userType3SortOrderNo8List.length != 0 && (
                <>
                  <Grid
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Grid item sm={4} xs={12} md={4.5} lg={4.5}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16, color: redColor }}
                        label={"*"}
                      />
                      <LabelCompo
                        className="text-black"
                        style={{
                          fontSize: 16,
                          color: titleColor,
                        }}
                        label={userType3SortOrderNo8Flag.name}
                      />{" "}
                      <AutoComplete
                        name={userType3SortOrderNo8Flag}
                        label={""}
                        placeHolder={"SELECT VALUE"}
                        keyColName={"id"}
                        value={userType3SortOrderNo8Value}
                        options={userType3SortOrderNo8List}
                        onChange={this.onAutocompleteChange}
                        errorText={
                          formErrors["userType3SortOrderNo8ValueError"]
                            ? formErrors["userType3SortOrderNo8ValueError"]
                            : ""
                        }
                        isError={
                          formErrors["userType3SortOrderNo8ValueError"]
                            ? formErrors["userType3SortOrderNo8ValueError"]
                            : ""
                        }
                      />
                    </Grid>

                    <Grid
                      item
                      display="flex"
                      alignItems="flex-end"
                      gap={2}
                      sm={4}
                      xs={12}
                      md={4.5}
                      lg={4.5}
                    >

                      <ButtonCompo
                        size="medium"
                        type="submit"
                        variant="contained"
                        name={"Analysis"}
                        onClick={() => this.onSearchHandler(3, 8)}
                      />

                      <ButtonCompo
                        size="medium"
                        type="submit"
                        variant="contained"
                        name={"Summary"}
                        onClick={() => this.onSummaryHandler(3, 8)}
                      />

                    </Grid>
                    <Grid item sm={4} xs={12} md={2.5} lg={2.5} />
                  </Grid>
                  <br />
                </>
              )}
              {userType4SortOrderNo1List.length != 0 && (
                <>
                  <Grid
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Grid item sm={4} xs={12} md={4.5} lg={4.5}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16, color: redColor }}
                        label={"*"}
                      />
                      <LabelCompo
                        className="text-black"
                        style={{
                          fontSize: 16,
                          color: titleColor,
                        }}
                        label={userType4SortOrderNo1Flag.name}
                      />{" "}
                      <AutoComplete
                        name={userType4SortOrderNo1Flag}
                        label={""}
                        placeHolder={"SELECT VALUE"}
                        keyColName={"id"}
                        value={userType4SortOrderNo1Value}
                        options={userType4SortOrderNo1List}
                        onChange={this.onAutocompleteChange}
                        errorText={
                          formErrors["userType4SortOrderNo1ValueError"]
                            ? formErrors["userType4SortOrderNo1ValueError"]
                            : ""
                        }
                        isError={
                          formErrors["userType4SortOrderNo1ValueError"]
                            ? formErrors["userType4SortOrderNo1ValueError"]
                            : ""
                        }
                      />
                    </Grid>

                    <Grid
                      item
                      display="flex"
                      alignItems="flex-end"
                      gap={2}
                      sm={4}
                      xs={12}
                      md={4.5}
                      lg={4.5}
                    >

                      <ButtonCompo
                        size="medium"
                        type="submit"
                        variant="contained"
                        name={"Analysis"}
                        onClick={() => this.onSearchHandler(4, 1)}
                      />


                      <ButtonCompo
                        size="medium"
                        type="submit"
                        variant="contained"
                        name={"Summary"}
                        onClick={() => this.onSummaryHandler(4, 1)}
                      />

                    </Grid>
                    <Grid item sm={4} xs={12} md={2.5} lg={2.5} />
                  </Grid>
                  <br />
                </>
              )}

            </>
          ) : (
            <>

              <Grid
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                display="flex"
                justifyContent="space-between"
              >
                <Grid item sm={4} xs={12} md={4.5} lg={4.5}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label={"*"}
                  />
                  <LabelCompo
                    className="text-black"
                    style={{
                      fontSize: 16,
                      color: titleColor,
                    }}
                    label={"Stock Holder Type"}
                  />{" "}

                  <AutoComplete
                    keyColName={"id"}
                    placeHolder="Select Stock Holder Type"
                    value={stockHolderType}
                    name={"stockHolderType"}
                    options={stockStakeHolderTypeList?.stockStakeHolderType}
                    errorText={
                      formErrors["stockHolderTypeError"]
                        ? formErrors["stockHolderTypeError"]
                        : ""
                    }
                    isError={
                      formErrors["stockHolderTypeError"]
                        ? formErrors["stockHolderTypeError"]
                        : ""
                    }
                    onChange={this.onAutocompleteChangeTypeWise}
                  />
                </Grid>

                <Grid item sm={4} xs={12} md={4.5} lg={4.5}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label={"*"}
                  />
                  <LabelCompo
                    className="text-black"
                    style={{
                      fontSize: 16,
                      color: titleColor,
                    }}
                    label={"Sales Team"}
                  />{" "}

                  <AutoComplete
                    keyColName={"id"}
                    placeHolder="Select Sales Team"
                    value={salesTeam}
                    name={"salesTeam"}
                    options={salesExecutiveList?.salesExecutive}
                    errorText={
                      formErrors["salesTeamError"]
                        ? formErrors["salesTeamError"]
                        : ""
                    }
                    isError={
                      formErrors["salesTeamError"]
                        ? formErrors["salesTeamError"]
                        : ""
                    }
                    onChange={this.onAutocompleteChangeTypeWise}
                  />
                </Grid>

                <Grid item
                  sm={4}
                  xs={12}
                  md={2.5}
                  lg={2.5}
                  gap={2}
                  container
                  display="flex"
                  alignItems={"flex-end"}
                  justifyContent="flex-start"
                >

                  <ButtonCompo
                    size="medium"
                    type="submit"
                    variant="contained"
                    name={"Analysis"}
                    onClick={() => this.onSearchHandlerTypeWise()}
                  />

                  <ButtonCompo
                    size="medium"
                    type="submit"
                    variant="contained"
                    name={"Summary"}
                    onClick={() => this.onSummaryHandlerTypeWise()}
                  />

                </Grid>

              </Grid>

            </>
          )}


          <br />

          {rowList.length !== 0 && !summaryTableFlag && (
            <LandingScreenHeader
              screenTitle={""}
              showTable={true}
              showBackButton={false}
              showAddButton={false}
              showPdfDownload={false}
              showExcelDownload={true}
              showSearchBox={false}
              generateExcel={radioType == 0 ? this.generateExcelUserSummaryEmployeeWise : this.generateExcelForUserSummaryTypeWise}
            />
          )}

          {rowList.length !== 0 && !summaryTableFlag && (

            <TableContainer
              sx={{
                maxHeight: 440,
                padding: 1.5,
              }}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead
                  style={{
                    backgroundColor: "#fff",
                  }}
                >
                  <TableRow>
                    <TableCell
                      color="#000"
                      align="center"
                      sx={{ width: "1%", color: "#000" }}
                      canSearch="true"
                    >
                      Sr.No
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ width: "9%", color: "#000" }}
                      canSearch="true"
                    >
                      Sales Team Name
                    </TableCell>

                    <TableCell
                      align="center"
                      sx={{ width: "7%", color: "#000" }}
                      canSearch="true"
                    >
                      Type
                    </TableCell>



                    {dateFlag == 1 && (
                      <>
                        <TableCell
                          align="center"
                          sx={{ width: "6%", color: "#000" }}
                          canSearch="true"
                        >
                          Check-In Time
                        </TableCell>

                        <TableCell
                          align="center"
                          sx={{ width: "6%", color: "#000" }}
                          canSearch="true"
                        >
                          Check-Out Time
                        </TableCell>

                        <TableCell
                          align="center"
                          sx={{ width: "5%", color: "#000" }}
                          canSearch="true"
                        >
                          First Call
                        </TableCell>

                        <TableCell
                          align="center"
                          sx={{ width: "5%", color: "#000" }}
                          canSearch="true"
                        >
                          Last Call
                        </TableCell>

                        <TableCell
                          align="center"
                          sx={{ width: "7%", color: "#000" }}
                          canSearch="true"
                        >
                          Total Call Time
                        </TableCell>

                        <TableCell
                          align="center"
                          sx={{ width: "3%", color: "#000" }}
                          canSearch="true"
                        >
                          Working Hours
                        </TableCell>
                      </>

                    )}

                    <TableCell
                      align="center"
                      sx={{ width: "3%", color: "#000" }}
                      canSearch="true"
                    >
                      Beat Count
                    </TableCell>

                    <TableCell
                      align="center"
                      sx={{ width: "3%", color: "#000" }}
                      canSearch="true"
                    >
                      Total Outlet
                    </TableCell>

                    <TableCell
                      align="center"
                      sx={{ width: "3%", color: "#000" }}
                      canSearch="true"
                    >
                      Productive Call
                    </TableCell>

                    <TableCell
                      align="center"
                      sx={{ width: "3%", color: "#000" }}
                      canSearch="true"
                    >
                      Remote Order
                    </TableCell>

                    <TableCell
                      align="center"
                      sx={{ width: "3%", color: "#000" }}
                      canSearch="true"
                    >
                      Zero Order
                    </TableCell>

                    <TableCell
                      align="center"
                      sx={{ width: "3%", color: "#000" }}
                      canSearch="true"
                    >
                      Total Order Call
                    </TableCell>

                    <TableCell
                      align="center"
                      sx={{ width: "3%", color: "#000" }}
                      canSearch="true"
                    >
                      Total Order Amount
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowList.map((salesTeamObject, index) => {
                    return (
                      <TableRow>
                        <TableCell align="center" canSearch="true">
                          {index + 1}
                        </TableCell>
                        <TableCell width="2%" canSearch="true">
                          <Link
                            color={cyanBlue}
                            component={RouterLink}
                            to={
                              "/sales/" +
                              salesTeamObject.id +
                              "/" +
                              salesTeamObject.userTypeId
                            }
                          >
                            {salesTeamObject.name}
                          </Link>
                        </TableCell>

                        <TableCell align="left" canSearch="true">
                          {salesTeamObject.stockStakeHolderTypeName}
                        </TableCell>


                        {dateFlag == 1 && (
                          <>
                            <TableCell align="center" canSearch="true">
                              {salesTeamObject.checkInTime}
                            </TableCell>

                            <TableCell align="center" canSearch="true">
                              {salesTeamObject.checkOutTime}
                            </TableCell>

                            <TableCell align="center" canSearch="true">
                              {salesTeamObject.firstCallTime}
                            </TableCell>

                            <TableCell align="center" canSearch="true">
                              {salesTeamObject.lastCallTime}
                            </TableCell>

                            <TableCell align="center" canSearch="true">
                              {salesTeamObject.totalRetailCallTime}
                            </TableCell>

                            <TableCell align="center" canSearch="true">
                              {salesTeamObject.workingHrs}
                            </TableCell>
                          </>
                        )}

                        <TableCell width="2%" align="right" canSearch="true">
                          <div
                            onClick={(e) =>
                              this.salesTeamHandler(e, salesTeamObject.id)
                            }
                            style={{
                              color: cyanBlue,
                              cursor: "pointer",
                            }}
                          >
                            {salesTeamObject.beatCounts}
                          </div>
                        </TableCell>

                        <TableCell align="right" canSearch="true">
                          {salesTeamObject.totalOutlet}
                        </TableCell>

                        <TableCell align="right" canSearch="true">
                          {salesTeamObject.productiveOrderCall}
                        </TableCell>

                        <TableCell align="right" canSearch="true">
                          {salesTeamObject.remoteOrderCall}
                        </TableCell>

                        <TableCell align="right" canSearch="true">
                          {salesTeamObject.zeroOrdeCall}
                        </TableCell>

                        <TableCell align="right" canSearch="true">
                          {salesTeamObject.totalOrderCall}
                        </TableCell>

                        <TableCell align="right" canSearch="true">
                          {salesTeamObject.totalOrderAmt.toFixed(2)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}




          {managerList?.salesTeamWorkingSummary.length !== 0 && summaryTableFlag && (
            <LandingScreenHeader
              screenTitle={""}
              showTable={true}
              showBackButton={false}
              showAddButton={false}
              showPdfDownload={false}
              showExcelDownload={true}
              showSearchBox={false}
              generateExcel={radioType == 0 ?
                this.generateExcelForUserWorkSummaryEmployeeWise
                : this.generateExcelForUserWorkSummaryTypeWise
              }
            />
          )}
          {managerList?.salesTeamWorkingSummary.length !== 0 && summaryTableFlag && (
            <TableContainer
              sx={{
                maxHeight: 540,
                padding: 1.5,
                overflow: "auto",
                maxWidth: "100%"
              }}
            >
              <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 1000 }}>
                <TableHead
                  style={{
                    backgroundColor: "#fff",
                  }}
                >
                  <TableRow>
                    <TableCell
                      color="#000"
                      align="center"
                      sx={{ width: "1%", color: "#000" }}
                      canSearch="true"
                    >
                      Sr.No
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ width: "3%", color: "#000" }}
                      canSearch="true"
                    >
                      Date
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ width: "7%", color: "#000" }}
                      canSearch="true"
                    >
                      Sales Team Name
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ width: "7%", color: "#000" }}
                      canSearch="true"
                    >
                      Type
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ width: "3%", color: "#000" }}
                      canSearch="true"
                    >
                      Working Type
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ width: "3%", color: "#000" }}
                      canSearch="true"
                    >
                      Task Name
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ width: "3%", color: "#000" }}
                      canSearch="true"
                    >
                      From Time
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ width: "3%", color: "#000" }}
                      canSearch="true"
                    >
                      To Time
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ width: "7%", color: "#000" }}
                      canSearch="true"
                    >
                      Reason
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ width: "5%", color: "#000" }}
                      canSearch="true"
                    >
                      Outlet Name
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {managerList?.salesTeamWorkingSummary.map((salesTeamObject, index) => (
                    <TableRow key={index}>
                      <TableCell align="center" canSearch="true">
                        {index + 1}
                      </TableCell>
                      <TableCell width="2%" canSearch="true" align="center">
                        {salesTeamObject.date}
                      </TableCell>
                      <TableCell align="left" canSearch="true">
                        <Link
                          color={cyanBlue}
                          component={RouterLink}
                          to={
                            "/sales/" +
                            salesTeamObject.salesTeamId +
                            "/" +
                            salesTeamObject.userTypeId
                          }
                        >
                          {salesTeamObject.salesTeamName}
                        </Link>
                      </TableCell>
                      <TableCell width="2%" align="left" canSearch="true">
                        {salesTeamObject.stockStakeHolderTypeName}
                      </TableCell>
                      <TableCell align="left" canSearch="true">
                        {salesTeamObject.workingTypeName}
                      </TableCell>
                      <TableCell align="left" canSearch="true">
                        {salesTeamObject.otherTaskName}
                      </TableCell>
                      <TableCell align="center" canSearch="true">
                        {salesTeamObject.fromTime}
                      </TableCell>
                      <TableCell align="center" canSearch="true">
                        {salesTeamObject.toTime}
                      </TableCell>
                      <TableCell align="left" canSearch="true">
                        {salesTeamObject.reason}
                      </TableCell>
                      <TableCell align="left" canSearch="true">
                        {salesTeamObject.firmName}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}


          {beatPopUpFlag && (
            <BeatPopUp
              open={beatPopUpFlag}
              handleCloses={this.handleClosesSalesTeam}
              columns={[
                {
                  title: "Sr. No.",
                  name: "index",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "center",
                  canSearch: true,
                },
                {
                  title: "Beat",
                  name: "beatName",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "left",
                  canSearch: true,
                },
              ]}
              rows={beatList?.beatFromSalesTeamId}
            />
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  beatList: state.beat,
  managerList: state.manager,
  stockStakeHolderTypeList: state.stockStakeHolderType,
  salesExecutiveList: state.salesExecutive,
});
const mapDispatchToProps = {
  getTopOrderSalesTeam,
  getSalesTeamUserSummary,
  getBelowSalesTeamByReportingId,
  getSelectedBeatCountFromSalesTeamIdAndDate,
  showLoador,
  showNotification,
  getStockStakeHolderType,
  getSalesExecutive,
  getSalesTeamWorkingSummary,
  setSalesTeamWorkingSummary,
};
export default connect(mapStateToProps, mapDispatchToProps)(UserSummaryNew);
