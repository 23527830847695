import React from "react";
import { connect } from "react-redux";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import { BrandJson } from "../../../DynamicFormsJson/MastersJSON/brand";
class BrandForm extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <>
                <DynamicForm
                    formPath={BrandJson.formPath}
                    screenTitle={BrandJson.screenTitle}
                    fieldMeta={BrandJson.fieldMeta}
                    tableColumnsToFilter={BrandJson.tableColumnsToFilter}
                    apiBaseURL={BrandJson.apiBaseURL}
                    baseIdColumn={BrandJson.baseIdColumn}
                    isLog={true}
                    type={0}
                />
            </>
        );
    }
}
const mapStateToProps = (state) => ({
});
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(BrandForm);
