export const multipleStockJson = {
  showSaveNextBtn: true,
  screenTitle: "Multiple Stock",
  showTitle: true,
  showCancel: false,
  showSaveBtn: true,
  fieldMeta: [
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromDate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "toDate-le",
      format: "DD-MM-YYYY",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "toDate",
      isMinDate: false,
      minDate: "fromDate",
      addOne: false,
      isMandatory: true,
      onSubmit: "compare",
      format: "DD-MM-YYYY",
      maxDatekey: 'fromDate',
      days: 7
    },
    {
      label: "Region",
      controlType: "autocomplete",
      placeHolder: "Select Region",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "regionDetail",
      dataKey: "region",
      getListId: "region",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "State",
      controlType: "autocomplete",
      placeHolder: "Select State",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stateDetail",
      dataKey: "state",
      getListFrom: "region",
      getListId: "state",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Zone",
      controlType: "multiSelect",
      placeHolder: "Select Zone",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "zoneDetail",
      dataKey: "zone",
      getListFrom: "state",
      getListId: "zone",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "District",
      controlType: "multiSelect",
      placeHolder: "Select District",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "districtDetail",
      dataKey: "district",
      getListFrom: "zone",
      getListId: "district",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Taluka",
      controlType: "multiSelect",
      placeHolder: "Select Taluka",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "talukaDetail",
      dataKey: "taluka",
      getListFrom: "district",
      getListId: "taluka",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Area",
      controlType: "multiSelect",
      placeHolder: "Select Area",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "areaByTalukaDetail",
      dataKey: "areaIds",
      getListFrom: "taluka",
      isRootLevelKey: true,
      isMandatory: true,
      maxLength: 30
    },
    {
      label: "Stock Holder Type",
      controlType: "autocomplete",
      placeHolder: "Select Stock Holder Type",
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stakeHolderTypeDetail",
      dataKey: "stockStakeHolderType",
      isRootLevelKey: true,
      isMandatory: true,
    },
    // {
    //   label: "Type",
    //   controlType: "radiogroup",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   dataKey: "stockType",
    //   tableDataKey: "stockType",
    //   defaultValue: "1",
    //   radioGroupItems: [
    //     {
    //       label: "Secondary Quantity",
    //       value: "1",
    //     },
    //   ],
    //   isMandatory: true,
    // },
    {
      label: "Quantity",
      controlType: "autocomplete",
      placeHolder: "Select Quantity",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "multipleStockDropdown",
      dataKey: "multipleStockDropdown",
      isMandatory: true,
    },
  ],
  fieldMetaForStockHolder: [
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromDate",
      format: "DD-MM-YYYY",
      isMandatory: true,
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "toDate",
      isMinDate: false,
      minDate: "fromDate",
      addOne: false,
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "fromDate-ge",
      format: "DD-MM-YYYY",
      maxDatekey: 'fromDate',
      days: 7
    },
    {
      label: "Area",
      controlType: "multiSelect",
      placeHolder: "Select Area",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "areaDetail",
      dataKey: "areaIds",
      isRootLevelKey: true,
      isMandatory: true,
      maxLength: 30
    },
    {
      label: "Stock Holder Type",
      controlType: "autocomplete",
      placeHolder: "Select Stock Holder Type",
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stakeHolderTypeDetail",
      dataKey: "stockStakeHolderType",
      isRootLevelKey: true,
      isMandatory: true,
    },
    // {
    //   label: "Type",
    //   controlType: "radiogroup",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   dataKey: "stockType",
    //   tableDataKey: "stockType",
    //   defaultValue: "1",
    //   radioGroupItems: [
    //     {
    //       label: "Secondary Quantity",
    //       value: "1",
    //     },
    //   ],
    //   isMandatory: true,
    // },
    {
      label: "Quantity",
      controlType: "autocomplete",
      placeHolder: "Select Quantity",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "multipleStockDropdown",
      dataKey: "multipleStockDropdown",
      isMandatory: true,
    },
  ],
  fieldMetaForSearch: [],
  searchList: [],
};
