import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { cyanBlue } from "../../config/ColorObj";



const RUCreationViewTable = ({ onClickProductCountHandler, rowList, type }) => {


  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">Sr. No.</TableCell>
            <TableCell align="center">Name</TableCell>
            <TableCell align="center">From Date</TableCell>
            <TableCell align="center">To Date</TableCell>
            <TableCell align="center">RU Qty</TableCell>
            {type === "0" && (
              <TableCell align="center">Product count</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowList.map((row, index) => (
            <TableRow key={row.index}>
              <TableCell width={"5%"} align="center">
                {index + 1}
              </TableCell>
              <TableCell width={"40%"}>{row.name}</TableCell>
              <TableCell width={"10%"} align="center">{row.fromDate}</TableCell>
              <TableCell width={"10%"} align="center">{row.toDate}</TableCell>
              <TableCell width={"10%"} align="center">
                {row.ruQty}
              </TableCell>
              {type === "0" && (
                <TableCell width={"12%"} align="center">
                  <div
                    onClick={(e) =>
                      onClickProductCountHandler(
                        e,
                        row
                      )
                    }
                    style={{
                      color: cyanBlue,
                      cursor: "pointer",
                    }}
                  >
                    {row.productCount}
                  </div>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RUCreationViewTable;
