import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import { ClaimStructureColumns, SalesTeamCountColumnsPopupForClaimStructure } from "../../../tableColumns/table-columns";
import { getAllClaimStructure } from "../../../Slice/claim.slice";
import { ClaimStructureJson } from "../../../DynamicFormsJson/MastersJSON/claimStructure";
import { apiGet } from "../../../utils/api_service";
import endpoint from "../../../config/endpoints";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import { noInternetMsg, statusmsg } from "../../../config/messageconstant";

class ClaimStructureList extends React.Component {
    constructor(props) {
        super(props);
    }

    onChangeStatus = (rowData) => {
        const { showLoador, showNotification, getAllClaimStructure } = this.props;
        if (navigator.onLine) {
            let status = "";
            if (rowData.isActive == 0) {
                status = 1;
            } else {
                status = 0;
            }
            showLoador({ loador: true });
            apiGet({
                url: endpoint.claimStructure + "/update-status?claimStructureHeaderId=" + rowData.id +
                    "&status=" + status,
            }).then(({ data, success }) => {
                showLoador({ loador: false });
                if (success) {
                    showNotification({ msg: statusmsg });
                    getAllClaimStructure();
                }
            });
        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }

    };

    render() {
        const { ClaimStructureList, getAllClaimStructure } = this.props;
        return (
            <>
                <DynamicMainScreen
                    formPath={ClaimStructureJson.formPath}
                    screenTitle={ClaimStructureJson.screenTitle}
                    openFormPathStatus={ClaimStructureJson.openFormPathStatus}
                    fieldMeta={ClaimStructureJson.fieldMeta}
                    showPdfDownload={false}
                    showExcelDownload={false}
                    pdfFileName={ClaimStructureJson.pdfFileName}
                    excelFileName={ClaimStructureJson.excelFileName}
                    showAddButton={ClaimStructureJson.showAddButton}
                    tableColumnsToFilter={ClaimStructureJson.tableColumnsToFilter}
                    tableColumns={ClaimStructureColumns}
                    tableData={ClaimStructureList?.claimStructure}
                    getTableData={getAllClaimStructure}
                    apiBaseURL={ClaimStructureJson.apiBaseURL}
                    baseIdColumn={ClaimStructureJson.baseIdColumn}
                    showDeleteIcon={true}
                    isActiveURLFalse={true}
                    isActiveURLFalseMethod={this.onChangeStatus}
                    showTransferButtonInTable={true}
                    autoTransfer={true}
                    showTransferTitle={"Assign Structure"}
                    showPopUpDataList={
                        [
                            {
                                dataKey: "salesTeamCount",
                                columns: SalesTeamCountColumnsPopupForClaimStructure,
                                title: "Sales Team Count",
                                isActionColActive: true,
                                header: {
                                    dataKey: "name",
                                    label: "Structure Name",
                                },
                            },
                        ]}
                />
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    ClaimStructureList: state.claim,
});
const mapDispatchToProps = {
    getAllClaimStructure,
    showLoador,
    showNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(ClaimStructureList);
