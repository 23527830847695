import CloseIcon from "@mui/icons-material/Close";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { auditJson } from "../../DynamicFormsJson/MastersJSON/audit";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import RTTable from "../Comman/RT/RTTable";
import AutoComplete from "../../components/Comman/AutoComplete";
import { serverMsg } from "../../config/messageconstant";
import { apiGet } from "../../utils/api_service";

import {
  showLoador,
  showNotification,
} from "../../Pages/Landing/Landing.slice";
import { Grid } from "@mui/material";
import { LabelCompo } from "../Comman/Label";
import { greyColor, titleColor } from "../../config/ColorObj";
import NewMultipleSelect from "../Comman/NewMultipleSelect";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const AddQuestion = ({ showLoador,onChangeStatusPopUp,rowDelete, showNotification, rowList, rowData, open, tableRow, handleCloses }) => {
  const [filterList, setFilterList] = React.useState([]);
  const [filterData, setFilterData] = React.useState([]);

  const handleClose = () => {
    handleCloses();
  };
  useEffect(() => {
    if (tableRow.filterData) {
      showLoador({ loador: true });
      apiGet({
        url: tableRow.filterData.url + rowData[tableRow.filterData.passId],
      }).then(({ data, success }) => {
        if (success) {
          let rowList = data.data.map((rowData, index) => {
            rowData = {
              ...rowData,
              index: index + 1
            }

            return rowData
          })
          setFilterList(rowList)
          showLoador({ loador: false });
        } else {
          showLoador({ loador: false });
          showNotification({ msg: serverMsg, severity: "error" });
        }
        return { data: data.data, success }
      })
    }




  }, []);

  const multiSelectHandler = (value, name) => {

    let dataValue = typeof value === "string" ? value.split(",") : value;
    const filterData = dataValue.filter((rowData) => rowData == 0);
    if (filterData.length != 0) {
      dataValue = filterList.map((rowData) => {
        return rowData.id;
      });
    }
    setFilterData(dataValue)
  }
  const getFilteredTableData = () => {
    return rowList.filter((currentRow) => {
      let isValid = filterData.length != 0 ? false : true;
      filterData.map((ids) => {
        if (
          currentRow[tableRow.filterData.name].includes(ids)
        ) {
          isValid = true;
          return true
        }
        return null
      })

      return isValid;
    });
  }
  const onChangeStatus=(rowData)=>{
    onChangeStatusPopUp(rowData)
  }
  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="lg"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {tableRow.title}
        </BootstrapDialogTitle>
        <DialogContent dividers>


          <Grid item sm={12} xs={12} md={12} lg={12}>

            <Grid container spacing={1}>
              <Grid
                container
                xs={12}
                sm={12}
                md={12}

              >
                <Grid
                  container
                  xs={12}
                  sm={6}
                  md={6}

                >

                  <Grid item xs={12} sm={3} md={3}>

                    <LabelCompo
                      className="text-black"
                      style={{
                        fontSize: 16,
                        color: titleColor,
                        paddingLeft: 10
                      }}
                      label={tableRow.header.label + " :"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={9} md={9}>
                    <LabelCompo
                      className="text-black"
                      style={{
                        fontSize: 16,
                        color: greyColor,
                      }}
                      label={rowData[tableRow.header.dataKey]}
                    />
                  </Grid>
                </Grid>
                {
                  tableRow.filterData &&
                  <Grid
                    container
                    xs={12}
                    sm={6}
                    md={6}

                  >

                    <Grid item xs={12} sm={4} md={4}>

                      <LabelCompo
                        className="text-black"
                        style={{
                          fontSize: 16,
                          color: titleColor,
                        }}
                        label={tableRow.filterData.label + " :"}
                      />
                    </Grid>
                    <Grid item xs={12} sm={8} md={8}>

                      <NewMultipleSelect
                        label={tableRow.filterData.label}

                        name1={tableRow.filterData.dataKey}
                        value1={filterData}
                        names={[{ id: 0, name: "All" }, ...filterList]}
                        onChange={multiSelectHandler}
                      />
                    </Grid>
                  </Grid>
                }
              </Grid>



            </Grid>
          </Grid>


          <RTTable
            columns={tableRow.columns ? tableRow.columns : []}
            hiddenColumnNames={[]}
            tableData={tableRow.filterData ? getFilteredTableData() : rowList}
            isActionColActive={tableRow.isActionColActive?tableRow.isActionColActive:false}
            rowDelete={rowDelete}
            showEditIcon={false}
            showPegination={false}
            onChangeStatus={onChangeStatus}
          />
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};
const mapStateToProps = (state) => ({ feedBack: state.feedBack });

const mapDispatchToProps = { showNotification, showLoador };

export default connect(mapStateToProps, mapDispatchToProps)(AddQuestion);
