import { Grid, Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { ButtonCompo } from "../../../components/Comman/Button";
import CheckBoxSelectionTable from "../../../components/Comman/RT/CheckBoxSelectionTable";
import { withRouter } from "../../../components/withRouter";
import endpoint from "../../../config/endpoints";
import {
  noInternetMsg,
  saveFailedMsg,
  savemsg,
  saveWarningMsg,
  serverMsg,
} from "../../../config/messageconstant";
import { getAreaByUserType } from "../../../Slice/area.slice";
import {
  getBeatBySalesTeamIdForAssign,
  setBeatBySalesTeamIdAreaId,
} from "../../../Slice/beat.slice";
import { getOutletByBeatId } from "../../../Slice/outlet.slice";
import { getSalesExecutiveTypeByAreaId } from "../../../Slice/salesExecutive.slice";
import { apiPost } from "../../../utils/api_service";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import OutletListTable from "./OutletListTable";

const columns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: false,
    showInscreen: true,
    alignCell: "center",
    align: "center",
  },
  {
    title: "Beat Name",
    name: "beatName",
    positionCenter: false,
    showInscreen: true,
    alignCell: "center",
    alignCenter: "left",
    canSearch: true,
  },

  {
    title: "Number of Outlet",
    name: "noOfOutlet",
    positionCenter: false,
    showInscreen: true,
    alignCell: "center",
    alignCenter: "right",
    canSearch: true,
  },
  {
    title: "Action",
    name: "action",
    positionCenter: false,
    showInscreen: true,
    alignCell: "center",
    alignCenter: "left",
    canSearch: true,
  },
];
class AssignBeatForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      salesTeamId: "",
      setList: [],
      checkList: [],
      isError: false,
      areaName: "",
      salesTeamName: "",
      open: false,
      DataToSet: [],
      beatName: "",
      moduleObjDataObj: {},
      showCompanyFlag: "",
      isLog: true,
      dynamicMasterData: {
        areaDetail: this.props.areaList.area,
        salesExecutiveDetail: this.props.salesExecutive.salesExecutive,
        beatDetail: this.props.beatList.beat,
      },
      openOutletDialog: false,
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.areaList !== nextProps.areaList) {
      if (this.props.areaList?.area !== nextProps.areaList?.area) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.area,
          },
        });
      }
    }
    if (this.props.salesExecutive !== nextProps.salesExecutive) {
      if (
        this.props.salesExecutive?.salesExecutive !==
        nextProps.salesExecutive?.salesExecutive
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            salesExecutiveDetail: nextProps.salesExecutive?.salesExecutive,
          },
        });
      }
    }
    if (this.props.beatList !== nextProps.beatList) {
      if (this.props.beatList?.beat !== nextProps.beatList?.beat) {
        let totalOutlet = 0;
        let totalAssignOutlet = 0;
        let totalNotAssignOutlet = 0;
        nextProps.beatList?.beat.map((beatList) => {
          totalOutlet = totalOutlet + +beatList.noOfOutlet;
        });

        nextProps.beatList?.beat.map((beatList) => {
          if (beatList.flag == 0 || beatList.flag == 2) {
            totalAssignOutlet = totalAssignOutlet + 1;
          } else {
            totalNotAssignOutlet = totalNotAssignOutlet + 1;
          }
        });

        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
          },
          DataToSet: [
            {
              xs: 12,
              sm: 3,
              md: 3,
              lg: 3,
              label: "Number of Beats ",
              value: nextProps.beatList?.beat.length,
            },
            {
              xs: 12,
              sm: 3,
              md: 3,
              lg: 3,
              label: "Number of Outlets ",
              value: totalOutlet,
            },
            {
              xs: 12,
              sm: 3,
              md: 3,
              lg: 3,
              label: "Number of Beats Assign",
              value: totalAssignOutlet,
            },
            {
              xs: 12,
              sm: 3,
              md: 3,
              lg: 3,
              label: "Number of Beats Not Assign",
              value: totalNotAssignOutlet,
            },
          ],
        });
      }
    }
  }

  async componentDidMount() {
    const moduleObjData = localStorage.getItem("moduleObj");
    const moduleObjDataObj = moduleObjData != null ? JSON.parse(moduleObjData) : {};

    const showCompanyFlag = localStorage.getItem("showCompanyFlag");

    this.setState({ moduleObjDataObj, showCompanyFlag });


    this.onSearch();
  }
  getListById = async (data) => {
    const { getSalesExecutiveTypeByAreaId, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      if (data.area.id) {
        showLoador({ loador: true });
        await getSalesExecutiveTypeByAreaId({ areaId: data.area.id }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onSearch = () => {
    const { getBeatBySalesTeamIdForAssign, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      this.setState({
        salesTeamId: this.props.params.id,
      });
      if (this.props.params.id) {
        showLoador({ loador: true });
        getBeatBySalesTeamIdForAssign({
          salesTeamId: this.props.params.id,
        }).then(({ response, success, selectedList }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            this.setState({
              setList: selectedList,
            });
          }
        });
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };
  getRow = (e) => {
    const { beatList, setBeatBySalesTeamIdAreaId } = this.props;
    this.setState({ setList: e });

    const setBeatList = beatList.beat.map((beatListObject) => {
      let filterData = e.filter((row) => row == beatListObject.id);
      if (filterData.length != 0) {
        beatListObject = {
          ...beatListObject,
          checked: true,
        };
        return beatListObject;
      }
      beatListObject = {
        ...beatListObject,
        checked: false,
      };
      return beatListObject;
    });
    setBeatBySalesTeamIdAreaId({ row: setBeatList });
  };

  onSubmit = (status) => {
    const { beatList } = this.props;
    const { salesTeamId } = this.state;
    let filterBeatList = [];
    beatList.beat.map((beatListObject) => {
      if (beatListObject.checked && beatListObject.flag !== 2) {
        const beatListObjectToSave = {
          beat: {
            id: beatListObject.id,
          },
          salesTeam: {
            id: salesTeamId,
          },
        };
        filterBeatList.push(beatListObjectToSave);
      }
    });




    let beatNewObjectToSave = {
      salesTeamId: salesTeamId,
      beatAssign: filterBeatList,
    };

    swal({
      title: "Are you sure?",
      text: saveWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {

        this.onSave(beatNewObjectToSave, status);
      }
    });
  };

  onSave = (beatNewObjectToSave, status) => {
    const { showLoador, showNotification } = this.props;
    const { moduleObjDataObj, isLog } = this.state;

    if (navigator.onLine) {
      let selectdBeatIds = [];

      selectdBeatIds = beatNewObjectToSave.beatAssign.map((objData) => objData.beat.id);

      showLoador({ loador: true });
      apiPost({
        url: endpoint.assignBeat + "/save-list",
        postBody: beatNewObjectToSave,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {

          if (isLog) {
            if (data && data.data !== null) {

              let updatedJsonToSend = JSON.stringify(data.data);

              let dataToSaveForMLog = {
                module: {
                  moduleId: moduleObjDataObj.moduleId
                },
                subModule: {
                  subModuleId: moduleObjDataObj.subModuleId
                },
                operationType: this.props.params.id ? 1 : 0,
                type: 1,
                operationPerformId: beatNewObjectToSave.salesTeamId,
                subOperationPerformIds: selectdBeatIds.length !== 0 ? selectdBeatIds.join(", ") : 0,
                updatedJson: updatedJsonToSend,
              }

              showLoador({ loador: true });
              apiPost({
                url: endpoint.mlog,
                postBody: dataToSaveForMLog,
              }).then(({ success }) => {
                showLoador({ loador: false });
                if (success) {
                } else {
                  showNotification({
                    msg: saveFailedMsg,
                    severity: "error",
                  });
                }
              });
            }
          }

          showNotification({ msg: savemsg });

          if (status == 1) {
            this.props.navigate("/sales-executive");
          }
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
        return success;
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  rowAppliedList = (rowData) => {
    this.setState({
      beatName: rowData.beatName,
      areaName: rowData.areaName,
      salesTeamName: rowData.salesTeamName,
    });
    const { getOutletByBeatId, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getOutletByBeatId({
        beatId: rowData.id,
      }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.setState({
            openOutletDialog: true,
          });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  handleClosesForOutlet = () => {
    const { openOutletDialog } = this.state;
    this.setState({
      openOutletDialog: !openOutletDialog,
    });
  };

  render() {
    const { beatList, outletList } = this.props;
    const {
      dynamicMasterData,
      setList,
      areaName,
      salesTeamName,
      beatName,
      openOutletDialog,
      DataToSet,
      showCompanyFlag,
    } = this.state;

    return (
      <>
        <Paper
          sx={{
            padding: 2,
          }}
          elevation={0}
        >
          <CheckBoxSelectionTable
            disabled={true}
            rows={beatList.beat}
            columns={columns}
            selection={setList}
            showDetailsIcon={true}
            rowAppliedList={this.rowAppliedList}
            isActionColActive={true}
            onSelectionChange={this.getRow}
          />
          {beatList?.beat.length != 0 && (
            <>
              <br />
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                container
                gap={2}
                justifyContent="right"
              >
                {showCompanyFlag && showCompanyFlag == 3 ? (
                  <>
                    <ButtonCompo
                      size="medium"
                      type="submit"
                      variant="contained"
                      name="Save"
                      onClick={() => this.onSubmit(1)}
                    />

                    <ButtonCompo
                      size="medium"
                      type="submit"
                      variant="contained"
                      name="Save And Next"
                      onClick={() => this.onSubmit(2)}
                    />
                  </>

                ) : (
                  <>
                    <ButtonCompo
                      size="medium"
                      type="submit"
                      variant="contained"
                      name="Submit"
                      onClick={() => this.onSubmit(1)}
                    />
                  </>
                )
                }
              </Grid>
            </>
          )}
        </Paper>
        {openOutletDialog && (
          <OutletListTable
            areaName={areaName}
            salesTeamName={salesTeamName}
            beatName={beatName}
            columns={[
              {
                title: "Sr. No.",
                name: "index",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "center",
              },
              {
                title: "Outlet Name",
                name: "firmName",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
              },
            ]}
            open={openOutletDialog}
            handleCloses={this.handleClosesForOutlet}
            onFormSave={this.rowAppliedList}
            rows={outletList?.outlets}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  managerList: state.manager,
  areaList: state.area,
  salesExecutive: state.salesExecutive,
  beatList: state.beat,
  outletList: state.outlet,
});
const mapDispatchToProps = {
  getAreaByUserType,
  getSalesExecutiveTypeByAreaId,
  getBeatBySalesTeamIdForAssign,
  getOutletByBeatId,
  showLoador,
  showNotification,
  setBeatBySalesTeamIdAreaId,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AssignBeatForm);
