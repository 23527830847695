import endpoint from "../../config/endpoints";

export const assignBeatJson = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.assignRateConfigurationToStakeholder,
  formPath: "/assign-rate-configuration-to-stakeholder-form",
  screenTitle: "Beat Configuration",
  showTitle: true,
  showCancel: false,
  showSaveBtn: false,
  fieldMetaAdmin: [
    {
      label: "Region",
      controlType: "autocomplete",
      placeHolder: "Select Region",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "regionDetail",
      dataKey: "region",
      getListId: "region",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "State",
      controlType: "autocomplete",
      placeHolder: "Select State",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "stateDetail",
      dataKey: "state",
      getListFrom: "region",
      getListId: "state",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Zone",
      controlType: "multiSelect",
      placeHolder: "Select Zone",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "zoneDetail",
      dataKey: "zone",
      getListFrom: "state",
      getListId: "zone",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "District",
      controlType: "multiSelect",
      placeHolder: "Select District",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "districtDetail",
      dataKey: "district",
      getListFrom: "zone",
      getListId: "district",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Taluka",
      controlType: "multiSelect",
      placeHolder: "Select Taluka",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "talukaDetail",
      dataKey: "taluka",
      getListFrom: "district",
      getListId: "taluka",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Area",
      controlType: "autocomplete",
      placeHolder: "Select Area ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "areaDetail",
      dataKey: "area",
      getListFrom: "taluka",
      isRootLevelKey: false,
      isMandatory: true,
      getListId: "area",
    },
    {
      label: "Sales Team ",
      controlType: "autocomplete",
      placeHolder: "Select Sales Team",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "salesExecutiveDetail",
      dataKey: "salesExecutive",
      getListFrom: "area",
      isRootLevelKey: false,
      isMandatory: true,
    },
  ],

  fieldMeta: [
    {
      label: "Area",
      controlType: "autocomplete",
      placeHolder: "Select Area ",
      md: 5,
      lg: 5,
      sm: 5,
      xs: 12,
      masterName: "areaDetail",
      dataKey: "area",
      isRootLevelKey: false,
      isMandatory: true,
      getListId: "area",
    },
    {
      label: "Sales Team ",
      controlType: "autocomplete",
      placeHolder: "Select Sales Team",
      md: 5.5,
      lg: 5.5,
      sm: 5.5,
      xs: 12,
      masterName: "salesExecutiveDetail",
      dataKey: "salesExecutive",
      getListFrom: "area",
      isRootLevelKey: false,
      isMandatory: true,
    },
  ],
  fieldMetaForSearch: [
    {
      label: "Stock Holder Type",
      controlType: "multiSelect",
      placeHolder: "Select Stock Holder Type",
      md: 5,
      lg: 5,
      sm: 5,
      xs: 12,
      getListId: "stockStakeHolderType",
      masterName: "stakeHolderTypeDetail",
      dataKey: "stockStakeHolderType",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Stock Holder Name",
      controlType: "multiSelect",
      placeHolder: "Select Stock Holder",
      md: 5,
      lg: 5,
      sm: 5,
      xs: 12,
      masterName: "stakeHolderName",
      dataKey: "stackholder",
      getListFrom: "stockStakeHolderType",
      isRootLevelKey: false,
      isMandatory: true,
    },
  ],

  fieldMetaForAllowanceWise: [
    {
      label: "Stock Holder Type",
      controlType: "autocomplete",
      placeHolder: "Select Stock Holder Type",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stockHolderTypeDetail",
      dataKey: "stockHolderType",
      getListId: "stockHolderType",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Sales Team",
      controlType: "autocomplete",
      placeHolder: "Select Sales Team Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "salesExecutiveDetail",
      dataKey: "salesExecutive",
      getListId: "salesExecutive",
      getListFrom: "stockHolderType",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Area",
      controlType: "multiSelect",
      placeHolder: "Select Area ",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "areaDetail",
      dataKey: "areaIds",
      getListFrom: "salesExecutive",
      isRootLevelKey: true,
      isMandatory: true,
      getListId: "areaIds",
    },
  ]
};