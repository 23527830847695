import React from "react";
import { connect } from "react-redux";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import { subCategoryJson } from "../../../DynamicFormsJson/MastersJSON/Subcategory";
import { getCategory } from "../../../Slice/category.slice";
import {
    showLoador,
    showNotification,
} from "../../Landing/Landing.slice";
import { serverMsg,noInternetMsg, } from "../../../config/messageconstant";


class SubCategoryForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicMasterData: {
                categoryDetail: this.props.categoryList?.category,
            },
        };
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.categoryList !== nextProps.categoryList) {
            if (this.props.categoryList?.category !== nextProps.categoryList?.category) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        categoryDetail: nextProps.categoryList?.category,
                    },
                });
            }
        }
    }

   async componentDidMount() {
        const { getCategory, showLoador, showNotification } = this.props;
        if (navigator.onLine) {
        showLoador({ loador: true });
       await getCategory().then(({ success }) => {
            showLoador({ loador: false });
            if (!success) {
                showNotification({ msg: serverMsg, severity: "error" });
            }
        });
    }
    else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
    }
   
    render() {
        const { dynamicMasterData } = this.state;
        return (
            <>
                <DynamicForm
                    formPath={subCategoryJson.formPath}
                    screenTitle={subCategoryJson.screenTitle}
                    fieldMeta={subCategoryJson.fieldMeta}
                    dynamicMasterData={dynamicMasterData}
                    apiBaseURL={subCategoryJson.apiBaseURL}
                    showSaveNextBtn={subCategoryJson.showSaveNextBtn}
                    isLog={true}
                    type={0}
                />
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    categoryList: state.category,
});
const mapDispatchToProps = {
    getCategory,
    showLoador,
    showNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(SubCategoryForm);
