import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { StockHolderOrderingConfigurationJson } from "../../DynamicFormsJson/Transaction/stockHolderOrderingConfigurationJson";
import { getAreaByUserType } from "../../Slice/area.slice";
import {
  getStockHolderByStockIdsAndAreaIdsUpdated,
  setStockHolderByStockIdsAndAreaIds,
} from "../../Slice/stockHolderOrderConfiguration.slice";
import { getStockStakeHolderType } from "../../Slice/stockStakeHolderType.slice";
import { ButtonCompo } from "../../components/Comman/Button";
import TimePickerCompo from "../../components/Comman/TimePicker";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import endpoint from "../../config/endpoints";
import {
  noInternetMsg,
  saveFailedMsg,
  saveWarningMsg,
  savemsg,
  serverMsg,
} from "../../config/messageconstant";
import { apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import StockHolderOrderConfigurationEdit from "./StockHolderOrderConfigurationEdit";
import { getRegionNew } from "../../Slice/region.slice";
import { getStateByRegion } from "../../Slice/state.slice";
import { getZoneByState } from "../../Slice/zone.slice";
import { getDistrictByZone } from "../../Slice/district.slice";
import { getTalukaByDistrict } from "../../Slice/taluka.slice";
import { getAreaByTalukaPostBody } from "../../Slice/area.slice";
import dayjs from "dayjs";

class StockHolderOrderingConfiguration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stockStakeHolderType: [],
      area: [],
      dynamicMasterData: {
        stockStakeHolderTypeDetail:
          this.props.stockStakeHolderTypeList?.stockStakeHolderType,
        areaDetail: this.props.areaList?.area,
      },
      formErrors: {},
      fieldData: {},
      stakeholderTime: "",
      isLog: true,
      moduleObjDataObj: {},
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.regionList !== nextProps.regionList) {
      if (this.props.regionList?.region !== nextProps.regionList?.region) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            regionDetail: nextProps.regionList?.region,
          },
        });
      }
    }
    if (this.props.stateList !== nextProps.stateList) {
      if (
        this.props.stateList?.stateByRegion !==
        nextProps.stateList?.stateByRegion
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stateDetail: nextProps.stateList?.stateByRegion,
          },
        });
      }
    }
    if (this.props.zoneList !== nextProps.zoneList) {
      if (
        this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            zoneDetail: nextProps.zoneList?.zoneByState,
          },
        });
      }
    }
    if (this.props.districtList !== nextProps.districtList) {
      if (
        this.props.districtList?.districtByZone !==
        nextProps.districtList?.districtByZone
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            districtDetail: nextProps.districtList?.districtByZone,
          },
        });
      }
    }
    if (this.props.talukaList !== nextProps.talukaList) {
      if (
        this.props.talukaList?.talukaByDistrict !==
        nextProps.talukaList?.talukaByDistrict
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            talukaDetail: nextProps.talukaList?.talukaByDistrict,
          },
        });
      }
    }
    if (this.props.areaList !== nextProps.areaList) {
      if (
        this.props.areaList?.areaByTaluka !== nextProps.areaList?.areaByTaluka
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.areaByTaluka,
          },
        });
      }
    }

    if (
      this.props.stockStakeHolderTypeList?.stockStakeHolderType !==
      nextProps.stockStakeHolderTypeList?.stockStakeHolderType
    ) {
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          stockStakeHolderTypeDetail:
            nextProps.stockStakeHolderTypeList?.stockStakeHolderType,
        },
      });
    }
  }

  async componentDidMount() {
    const { getStockStakeHolderType, getRegionNew, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      console.log(new Date().toLocaleString("en-GB"));

      const moduleObjData = localStorage.getItem("moduleObj");
      const moduleObjDataObj = moduleObjData != null ? JSON.parse(moduleObjData) : {};

      this.setState({ moduleObjDataObj });

      showLoador({ loador: true });
      await getRegionNew().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

      showLoador({ loador: true });
      await getStockStakeHolderType({ userTypeIds: 2 }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  getListById = async (data) => {
    const {
      getStateByRegion,
      getZoneByState,
      getDistrictByZone,
      getTalukaByDistrict,
      getAreaByTalukaPostBody,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      console.log(data);
      if (data.region && data.region != "") {
        showLoador({ loador: true });
        await getStateByRegion({ regionIds: data.region }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.state && data.state != "") {
        showLoador({ loador: true });
        await getZoneByState({ stateIds: data.state }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.zone && data.zone != "") {
        showLoador({ loador: true });
        await getDistrictByZone({ zoneIds: data.zone }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.district && data.district != "") {
        showLoador({ loador: true });
        await getTalukaByDistrict({ districtIds: data.district }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.taluka && data.taluka != "") {
        let talukaIdsByPost = {
          talukaIds: data.taluka,
        };
        showLoador({ loador: true });
        await getAreaByTalukaPostBody({ talukaIds: talukaIdsByPost }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onChange = (e, row, name) => {
    console.log(e);
    const { stockHolderNameList, setStockHolderByStockIdsAndAreaIds } =
      this.props;
    let list = stockHolderNameList?.stockHolderStockIdsAndAreaIds.map(
      (rowData) => {
        return {
          ...rowData,
          [name]: rowData.id === row.id ? e : rowData[name],
        };
      }
    );
    setStockHolderByStockIdsAndAreaIds({ row: list });
    console.log(list);
  };

  onSubmitAll = () => {
    const { stockHolderNameList, setStockHolderByStockIdsAndAreaIds } =
      this.props;
    const { stakeholderTime } = this.state;
    let list = stockHolderNameList?.stockHolderStockIdsAndAreaIds.map(
      (rowData) => {
        return {
          ...rowData,
          [name]: e,
        };
      }
    );
    setStockHolderByStockIdsAndAreaIds({ row: list });
    console.log(list);
  };

  onChangeAll = (e, name) => {
    const { stockHolderNameList, setStockHolderByStockIdsAndAreaIds } =
      this.props;
    let list = stockHolderNameList?.stockHolderStockIdsAndAreaIds.map(
      (rowData) => {
        return {
          ...rowData,
          [name]: e,
        };
      }
    );
    setStockHolderByStockIdsAndAreaIds({ row: list });
  };

  onSubmit = () => {
    const { stockHolderNameList } = this.props;
    if (navigator.onLine) {
      let dataToSave = [];
      stockHolderNameList?.stockHolderStockIdsAndAreaIds.map(
        (stockHolderObject) => {
          console.log(stockHolderObject.fromTime);
          console.log(stockHolderObject.toTime);

          if (
            stockHolderObject.fromTime &&
            stockHolderObject.fromTime !== "" &&
            stockHolderObject.toTime &&
            stockHolderObject.toTime !== ""
          ) {
            // Convert the input string to a Date object
            let dateFromTimeObj = new Date(stockHolderObject.fromTime);
            let dateToTimeObj = new Date(stockHolderObject.toTime);

            // Format the Date object into the desired format
            let formattedFromDate = `${dateFromTimeObj.getFullYear()}-${(
              dateFromTimeObj.getMonth() + 1
            )
              .toString()
              .padStart(2, "0")}-${dateFromTimeObj
                .getDate()
                .toString()
                .padStart(2, "0")}`;
            let formattedToDate = `${dateToTimeObj.getFullYear()}-${(
              dateToTimeObj.getMonth() + 1
            )
              .toString()
              .padStart(2, "0")}-${dateToTimeObj
                .getDate()
                .toString()
                .padStart(2, "0")}`;
            let formattedFromTime = `${dateFromTimeObj
              .getHours()
              .toString()
              .padStart(2, "0")}:${dateFromTimeObj
                .getMinutes()
                .toString()
                .padStart(2, "0")}:${dateFromTimeObj
                  .getSeconds()
                  .toString()
                  .padStart(2, "0")}`;
            let formattedToTime = `${dateToTimeObj
              .getHours()
              .toString()
              .padStart(2, "0")}:${dateToTimeObj
                .getMinutes()
                .toString()
                .padStart(2, "0")}:${dateToTimeObj
                  .getSeconds()
                  .toString()
                  .padStart(2, "0")}`;


            let formattedFromDateTime = `${formattedFromTime}`;
            let formattedToDateTime = ` ${formattedToTime}`;

            console.log("Converted time:", formattedFromTime + "" + formattedToTime);
            let stockHolderData = {
              stockStakeHolder: {
                id: stockHolderObject.id,
              },
              fromTime: formattedFromDateTime,
              toTime: formattedToDateTime,
            };
            dataToSave.push(stockHolderData);
          }
        }
      );
      console.log(dataToSave);
      if (dataToSave.length !== 0) {
        swal({
          title: "Are you sure?",
          text: saveWarningMsg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            this.onSave(dataToSave);
          }
        });
      }
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  };

  onSave = (dataToSave) => {
    const { showLoador, showNotification, navigate } = this.props;
    const { isLog, moduleObjDataObj } = this.state;

    const stockHolderIds = dataToSave.filter(row => row.stockStakeHolder && row.stockStakeHolder.id)
      .map(row => row.stockStakeHolder.id);

    if (navigator.onLine) {
      showLoador({ loador: true });
      apiPost({
        url: endpoint.stockHolderOrderingConfiguration + "/save-list",
        postBody: dataToSave,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {

          if (isLog) {
            if (data.data !== null) {

              let updatedJsonToSend = JSON.stringify(data.data);

              let dataToSaveForMLog = {
                module: {
                  moduleId: moduleObjDataObj.moduleId
                },
                subModule: {
                  subModuleId: moduleObjDataObj.subModuleId
                },
                operationType: 0,
                type: 1,
                operationPerformId: 0,
                subOperationPerformIds: stockHolderIds.join(", "),
                updatedJson: updatedJsonToSend,
              }

              showLoador({ loador: true });
              apiPost({
                url: endpoint.mlog,
                postBody: dataToSaveForMLog,
              }).then(({ success }) => {
                showLoador({ loador: false });
                if (success) {
                } else {
                  showNotification({
                    msg: saveFailedMsg,
                    severity: "error",
                  });
                }
              });
            }
          }

          navigate(-1);
          showNotification({ msg: savemsg });
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
        return success;
      });
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  };

  onCancle = () => {
    this.props.navigate(-1);
  };

  onSearch = (data) => {
    const { getStockHolderByStockIdsAndAreaIdsUpdated, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      let stockHolderByAreaAndTypesJsonData = {
        stockStakeHolderTypeIds: data.stockStakeHolderTypeIds,
        areaIds: data.areaIds.split(","),
      }
      showLoador({ loador: true });
      getStockHolderByStockIdsAndAreaIdsUpdated({
        stockHolderByAreaAndTypesJson: stockHolderByAreaAndTypesJsonData
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  checkValidationSubmit = (data) => {
    console.log(data);
    let formIsValid = true;
    let formErrors = {};

    if (data.fromTime == "") {
      formIsValid = false;
      formErrors["fromTimeError"] = "From Time Required";
    }

    if (data.toTime == "") {
      formIsValid = false;
      formErrors["toTimeError"] = "To Time Is Required";
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  };

  render() {
    const { dynamicMasterData, stakeholderTime } = this.state;
    const { stockHolderNameList } = this.props;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            showBackToList={true}
            showTitle={StockHolderOrderingConfigurationJson.showTitle}
            getListById={this.getListById}
            screenTitle={StockHolderOrderingConfigurationJson.screenTitle}
            onSearchData={this.onSearchData}
            apiBaseURL={StockHolderOrderingConfigurationJson.apiBaseURL}
            fieldMeta={StockHolderOrderingConfigurationJson.fieldMeta}
            dynamicMasterData={dynamicMasterData}
            showSaveNextBtn={false}
            saveBtnText={"Search"}
            showSaveBtnMain={false}
            showAddButton={false}
            showSaveBtn={true}
            onSave={this.onSearch}
          />
          <br />
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Sr. No.</TableCell>
                  <TableCell align="center" width="25%">
                    Stock Holder
                  </TableCell>
                  <TableCell align="center" width="25%">
                    Firm Name
                  </TableCell>
                  <TableCell align="center" width="20%">
                    <div>
                      {stockHolderNameList?.stockHolderStockIdsAndAreaIds
                        .length !== 0 && (
                          <div>
                            <TimePickerCompo
                              placeHolder={"From Time"}
                              color="primary"
                              name="fromTime"
                              size="small"
                              value={stakeholderTime}
                              fullWidth
                              onChange={(e) => {
                                this.onChangeAll(e, "fromTime");
                              }}
                              error={
                                stakeholderTime == "" ? "From Time Required" : " "
                              }
                            />
                          </div>
                        )}
                      <div>From Time</div>
                    </div>
                  </TableCell>

                  <TableCell align="center" width="20%">
                    <div>
                      {stockHolderNameList?.stockHolderStockIdsAndAreaIds
                        .length !== 0 && (
                          <div>
                            <TimePickerCompo
                              placeHolder={"From Time"}
                              color="primary"
                              name="toTime"
                              size="small"
                              value={stakeholderTime}
                              fullWidth
                              onChange={(e) => {
                                this.onChangeAll(e, "toTime");
                              }}
                              error={
                                stakeholderTime == "" ? "From Time Required" : " "
                              }
                            />
                          </div>
                        )}
                      <div>To Time</div>
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stockHolderNameList?.stockHolderStockIdsAndAreaIds.map(
                  (row, index) => (
                    <TableRow>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="left">
                        {row.stockStakeHolderType.name}
                      </TableCell>
                      <TableCell align="left">{row.firmName}</TableCell>
                      <TableCell>
                        {row.fromTimeError}
                        <TimePickerCompo
                          placeHolder={"From Time"}
                          color="primary"
                          name="name"
                          size="small"
                          value={row.fromTime}
                          fullWidth
                          onChange={(e) => {
                            this.onChange(e, row, "fromTime");
                          }}
                          error={row.fromTimeError ? "From Time Required" : " "}
                        />
                      </TableCell>

                      <TableCell>
                        <Grid item xs={12} sm={8} md={8}>
                          <TimePickerCompo
                            placeHolder={"To Time"}
                            color="primary"
                            name="name"
                            size="small"
                            value={row.toTime}
                            fullWidth
                            onChange={(e) => {
                              this.onChange(e, row, "toTime");
                            }}
                            error={row.toTimeError ? "To Time Required" : " "}
                          />
                        </Grid>
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {stockHolderNameList?.stockHolderStockIdsAndAreaIds && (
            <>
              <br />
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                container
                justifyContent="right"
              >
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Submit"
                  onClick={this.onSubmit}
                />
              </Grid>
            </>
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  stockStakeHolderTypeList: state.stockStakeHolderType,
  areaList: state.area,
  stockHolderNameList: state.stockHolderOrderConfiguration,
  areaList: state.area,
  regionList: state.region,
  stateList: state.states,
  zoneList: state.zone,
  districtList: state.district,
  talukaList: state.taluka,
});
const mapDispatchToProps = {
  showLoador,
  showNotification,
  getStockStakeHolderType,
  getStateByRegion,
  getZoneByState,
  getRegionNew,
  getDistrictByZone,
  getTalukaByDistrict,
  getAreaByTalukaPostBody,
  getStockHolderByStockIdsAndAreaIdsUpdated,
  setStockHolderByStockIdsAndAreaIds,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(StockHolderOrderingConfiguration);
