import endpoint from "../../config/endpoints";

export const stateJson = {
  formPath: "/state-form",
  apiBaseURL: endpoint.state,
  changeExcel: endpoint.exportData,
  screenTitle: "State",
  showAddButton: true,
  showPdfDownload: false,
  pdfFileName: "State",
  showExcelDownload: false,
  excelFileName: "State",
  tableColumnsToFilter: [
    {
      columnDisplayName: "Name",
      columnKeyName: "name",
      isChecked: true,
    },
    {
      columnDisplayName: "Code",
      columnKeyName: "code",
      isChecked: true,
    },
    {
      columnDisplayName: "Status",
      columnKeyName: "isActive",
      isChecked: true,
    },
  ],
  fieldMeta: [
    {
      label: "Region",
      controlType: "autocomplete",
      placeHolder: "Select Region",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "regionDetail",
      dataKey: "region",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Name",
      controlType: "textfield",
      placeHolder: "Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      autoFocus: true,
      dataKey: "name",
      isMandatory: true,
      uniqueValidation: true,
    },
    {
      label: "Short Name",
      controlType: "textfield",
      placeHolder: "Short Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "shortName",
      isMandatory: true,
    },
    {
      label: "State Code",
      controlType: "textfield",
      placeHolder: "State Code",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "code",
      isMandatory: true,
      inputType: "number",
    },
    {
      label: "Sort Order No",
      controlType: "textfield",
      placeHolder: "Sort Order Number",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "sortOrderNo",
      isMandatory: true,
    },
  ],
  fieldMetaForSalesStateAssignation: [
    {
      label: "Stock Holder Type",
      controlType: "multiSelect",
      placeHolder: "Select Stock Holder Type",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stockHolderTypeDetail",
      dataKey: "stockHolderType",
      getListId: "stockHolderType",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Stock Holder Name",
      controlType: "multiSelect",
      placeHolder: "Select Stock Holder Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stockHolderNameDetail",
      dataKey: "stockHolderName",
      getListId: "stockHolderName",
      isRootLevelKey: true,
      isMandatory: true,
    },
  ],
};
