import { Box, Grid, Paper, Tab, Tabs, Typography } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import SwipeableViews from "react-swipeable-views";
import * as msgConstant from "../../config/messageconstant";
import AppBar from "@mui/material/AppBar";
import {
  showLoador,
  showNotification,
} from "../../Pages/Landing/Landing.slice";
import SearchPopUp from "./SearchPopUp";

import { apiDelete, apiPost } from "../../utils/api_service";
import { generateExcel, generatePDF } from "../GeneratePDFExcel";
import LandingScreenHeader from "../Comman/LandingScreenHeader";
import DynamicTable from "../Comman/RT/MaterialUIRTTable";
import { withRouter } from "../withRouter";
import DynamicFormWithoutSave from "./DynamicFormWithoutSave";
import { LabelCompo } from "../Comman/Label";
import { redColor } from "../../config/ColorObj";

export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

class DynamicTabwiseTable extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: {},
      fieldData: {},
      fieldDataForSearch: {},
      showTable: true,
      showForm: false,
      currentOperationMode: "",
      searchValue: "",
      fromDate: "",
      toDate: "",
      tabValue: 0,
      fieldDataForModule: {},
    };
  }

  componentDidMount() {
    const { fieldDataForSearch, tabValue, tableColumnsToFilter } = this.state;
    const { tabList } = this.props;
    if (Object.keys(fieldDataForSearch).length == 0) {
      const dataToReset = {};
      tabList[tabValue].searchList &&
        tabList[tabValue].searchList.forEach((currentField) => {
          if (currentField.defaultValue) {
            dataToReset[currentField.dataKey] = currentField.defaultValue;
          }
        });
      this.setState({
        fieldDataForSearch: dataToReset,
      });
    }
    if (Object.keys(tableColumnsToFilter).length == 0) {
      const dataToReset = {};
      tabList.forEach((currentField) => {
        if (currentField.tableColumnsToFilter) {
          dataToReset[currentField.value] = currentField.tableColumnsToFilter;
        }
      });
      this.setState({
        tableColumnsToFilter: dataToReset,
      });
    }
    const moduleObjData = localStorage.getItem("moduleObj");
    this.setState({
      fieldDataForModule:
        moduleObjData != null ? JSON.parse(moduleObjData) : {},
    });
  }

  a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  onRTSelectMultipleChange = (dataKey) => (selectedOption) => {
    const { tableColumnsToFilter } = this.state;
    let tableDataKey =
      tableColumnsToFilter[dataKey] &&
      tableColumnsToFilter[dataKey].map((rowData) => {
        return {
          ...rowData,
          isChecked:
            rowData.columnKeyName === selectedOption.columnKeyName
              ? !rowData.isChecked
              : rowData.isChecked,
        };
      });
    this.setState({
      tableColumnsToFilter: {
        ...tableColumnsToFilter,
        [dataKey]: tableDataKey,
      },
    });
  };

  onDataChangeForSearch = (fieldName, newValue) => {
    this.setState({
      fieldDataForSearch: {
        ...this.state.fieldDataForSearch,
        [fieldName]: newValue,
      },
    });
  };
  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  onAddButtonClick = () => {
    const { formPath } = this.props;
    this.props.navigate(formPath);
  };
  rowAdd = (rowData) => {
    const { openFormPath } = this.props;
    this.props.navigate(openFormPath + "/" + rowData.id);
  };
  rowEdit = (rowData) => {
    const { formPath, rowEdit, staticEdit = true } = this.props;
    if (staticEdit) {
      rowEdit(rowData);
    } else {
      this.props.navigate(formPath + "/" + rowData.id);
    }
  };
  onSave = (dataToSave, isToggle = true) => {
    const { apiBaseURL } = this.props;

    this.props.showLoador({ loador: true });
    apiPost({
      url: apiBaseURL,
      postBody: dataToSave,
    }).then(({ success }) => {
      if (success) {
        this.props.showLoador({ loador: false });
        const { currentOperationMode } = this.state;
        this.props.showNotification({
          msg:
            currentOperationMode === OperationMode.Add
              ? msgConstant.savemsg
              : currentOperationMode === OperationMode.Status
                ? msgConstant.statusmsg
                : msgConstant.updateMsg,
        });
        const { getTableDataById = false } = this.props;
        if (getTableDataById) {
          this.props.onSearchData(this.state.fieldDataForSearch);
        } else {
          this.getData();
        }
        if (isToggle) {
          this.setState({
            fieldData: {},
            currentOperationMode: "",
          });
        }
        if (!isToggle) {
          let fieldData = {};
          const { fieldMeta } = this.props;
          fieldMeta.map((currentField) => {
            if (currentField.defaultValue) {
              fieldData[currentField.dataKey] = currentField.defaultValue;
            }
            if (
              currentField.controlType === "autocomplete" &&
              !currentField.isRootLevelKey
            ) {
              fieldData[currentField.dataKey] = dataToSave[
                currentField.dataKey
              ]["id"]
                ? dataToSave[currentField.dataKey]["id"]
                : "";
            } else if (
              currentField.controlType === "autocomplete" &&
              !currentField.isRootLevelKey
            ) {
              fieldData[currentField.dataKey] = dataToSave[currentField.dataKey]
                ? dataToSave[currentField.dataKey]
                : "";
            }
            return null;
          });

          this.setState({
            fieldData: fieldData,
            currentOperationMode: OperationMode.Add,
          });
        }
      } else {
        this.props.showNotification({
          msg: "Failed to save",
          severity: "error",
        });
        this.props.showLoador({ loador: false });
      }
    });
  };

  getFilteredTableData = (tabValue) => {
    const { rowList, tabList } = this.props;

    const { searchValue } = this.state;
    let tabData = tabList[tabValue] ? tabList[tabValue] : {};
    const columsFil =
      tabData.columns &&
      tabData.columns.filter((item) => {
        return item.canSearch;
      });
    return (
      rowList[tabData.value] &&
      rowList[tabData.value].filter((currentRow) => {
        let isValid = false;
        columsFil.some((col) => {
          if (
            currentRow[col.name] &&
            currentRow[col.name]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())
          ) {
            isValid = true;
            return true;
          }
          return null;
        });
        return isValid;
      })
    );
  };

  onChangeStatus = (rowData) => {
    const { rowStatusTrue = true, rowStatus } = this.props;
    const { fieldDataForModule } = this.state;

    if (fieldDataForModule.editReject == 1) {

      if (rowStatusTrue) {
        rowStatus(rowData);
      } else {
        swal(msgConstant.changeActive, {
          buttons: ["Cancel", true],
        }).then((value) => {
          if (value) {
            const { fieldMeta, isActiveURL = "" } = this.props;
            if (isActiveURL == "") {
              const dataToSave = {
                ...rowData,
                id: rowData["id"],
              };

              fieldMeta.forEach((currentField) => {
                if (
                  currentField.controlType === "autocomplete" &&
                  !currentField.isRootLevelKey
                ) {
                  dataToSave[currentField.dataKey] = {};
                  dataToSave[currentField.dataKey]["id"] = rowData[
                    currentField.dataKey
                  ]
                    ? rowData[currentField.dataKey]
                    : "";
                } else {
                  dataToSave[currentField.dataKey] = rowData[currentField.dataKey]
                    ? rowData[currentField.dataKey]
                    : "";
                }
              });

              dataToSave["isActive"] = rowData.isActive == 1 ? "0" : "1";
              this.setState({
                currentOperationMode: OperationMode.Status,
              });
              this.onSave(dataToSave, false);
            } else {
              const dataToSave = {
                id: rowData["id"],
              };
              dataToSave["isActive"] = rowData.isActive == 1 ? "0" : "1";
              this.setState({
                currentOperationMode: OperationMode.Status,
              });
              this.onStatusChange(dataToSave, false);
            }
          }
        });
      }
    }
  };

  onStatusChange = async (dataToSave) => {
    const { isActiveURL = "", getTableDataById = false } = this.props;
    let dataToSearchList = "";
    if (getTableDataById) {
      dataToSearchList = await this.setFromDataForSearch();
    }
    if (navigator.onLine) {
      this.props.showLoador({ loador: true });
      apiPost({
        url:
          isActiveURL +
          "?id=" +
          dataToSave.id +
          "&status=" +
          dataToSave.isActive,
      }).then(({ success }) => {
        if (success) {
          this.props.showLoador({ loador: false });
          this.props.showNotification({
            msg: "Status updated",
          });
          const { getTableDataById = false } = this.props;
          if (getTableDataById) {
            this.props.onSearchData(dataToSearchList);
          } else {
            const { callApi = true } = this.props;
            if (callApi) {
              this.getData();
            }
          }
          this.setState({
            fieldData: {},
            currentOperationMode: "",
          });
        } else {
          this.props.showNotification({
            msg: msgConstant.statusFailedMsg,
            severity: "error",
          });

          this.props.showLoador({ loador: false });
        }
      });
    } else {
      this.props.showNotification({
        msg: msgConstant.noInternetMsg,
        severity: "error",
      });
    }
  };

  rowLinkToShowData = async (row, tableRow) => {
    const { linkStatic = true, rowLinkToShowData } = this.props;
    if (linkStatic) {
      this.setState({
        popUpData: {
          row: row,
          tableRow: tableRow,
        },
      });
      this.showPopUpData(row, tableRow, false);
    } else {
      rowLinkToShowData(row, tableRow);
    }
  };

  showPopUpData = (row, tableRow, flag) => {
    const { showLoador, showNotification, showPopUpDataList = [] } = this.props;
    const filterTableCol = showPopUpDataList.filter(
      (row) => row.dataKey === tableRow.name
    );
    showLoador({ loador: true });
    apiGet({
      url: tableRow.url + row[tableRow.passId],
    }).then(({ data, success }) => {
      if (success) {
        let tableRowData = filterTableCol.length != 0 ? filterTableCol[0] : {};
        let col =
          filterTableCol.length != 0 && filterTableCol[0].columns
            ? filterTableCol[0].columns
            : [];
        let rowList = data.data.map((rowData, index) => {
          rowData = {
            ...rowData,
            index: index + 1,
          };
          let filterDataObj = tableRowData.filterData
            ? tableRowData.filterData
            : {};
          let rowInfo = { id: rowData.id };
          if (Object.keys(filterDataObj).length != 0) {
            let strData = filterDataObj
              ? filterDataObj.tableDataKey.split(".")
              : [filterDataObj.name];
            let strD = "";

            strData.map((objData, index) => {
              if (index == 0) {
                strD = rowData[objData] != null ? rowData[objData] : "";
              } else {
                strD = strD[objData] ? strD[objData] : "";
              }
            });
            rowInfo = { ...rowInfo, [filterDataObj.name]: strD };
          }

          col.map((calData) => {
            let strData = calData.tableDataKey
              ? calData.tableDataKey.split(".")
              : [calData.name];
            let strD = "";

            strData.map((objData, index) => {
              if (index == 0) {
                strD = rowData[objData] != null ? rowData[objData] : "";
              } else {
                strD = strD[objData] ? strD[objData] : "";
              }
            });
            rowInfo = {
              ...rowInfo,
              [calData.name]: strD,
            };
          });
          return { ...rowInfo, isActive: rowData.isActive };
        });
        this.setState({
          rowList: rowList,
          tableRow: tableRowData,
          rowData: row,
        });
        this.handleToggleClosed(flag);
        showLoador({ loador: false });
      } else {
        showLoador({ loador: false });
        showNotification({ msg: msgConstant.serverMsg, severity: "error" });
      }
      return { data: data.data, success };
    });
  };

  generateExcelMethod = (ColumnsList, filterCol) => {
    const { isExcelStaticUrl = false } = this.props;
    if (isExcelStaticUrl) {
      this.props.generateStaticExcelMethod();
    } else {
      const tableData = this.getFilteredTableData(this.state.tabValue);
      var columns = ["Sr. No."];
      let alignList = [];
      ColumnsList.map((reportColumnNamesData, index) => {
        let fData = filterCol.filter(
          (rowData) =>
            rowData.isChecked &&
            rowData.columnKeyName == reportColumnNamesData.name
        );
        if (fData.length != 0) {
          alignList.push({
            index: index + 1,
            allignment: reportColumnNamesData.align
              ? reportColumnNamesData.align
              : "left",
          });
          columns.push(reportColumnNamesData.title);
        }
      });
      let rows = tableData.map((reportData, index) => {
        let reportObj = [index + 1];
        const reportInfo = ColumnsList.map((reportColumnNamesData, index) => {
          let fData = filterCol.filter(
            (rowData) =>
              rowData.isChecked &&
              rowData.columnKeyName == reportColumnNamesData.name
          );
          if (fData.length != 0) {
            reportObj.push(
              reportColumnNamesData.name != "isActive"
                ? reportData[reportColumnNamesData.name]
                : reportColumnNamesData.name != "isActive" &&
                  reportData[reportColumnNamesData.name] == 1
                  ? "Active"
                  : "Inactive"
            );
          }
        });
        return reportObj;
      });

      let headerList = [];

      generateExcel({
        columns,
        rows,
        alignList,
        headerList,
        title: this.props.screenTitle,
      });
    }
  };

  generatePDFMethod = (ColumnsList, filterCol) => {
    const tableData = this.getFilteredTableData(this.state.tabValue);
    var columns = [{ title: "Sr. No.", dataKey: "index" }];
    var align = { 0: { halign: "center" } };
    let alignIndex = 0;
    ColumnsList.map((reportColumnNamesData, index) => {
      let fData = filterCol.filter(
        (rowData) =>
          rowData.isChecked &&
          rowData.columnKeyName == reportColumnNamesData.name
      );
      if (fData.length != 0) {
        alignIndex = alignIndex + 1;
        align = {
          ...align,
          [alignIndex]: { halign: reportColumnNamesData.align },
        };
        columns.push({
          title: reportColumnNamesData.title,
          dataKey: reportColumnNamesData.name,
        });
      }
    });
    let rows = tableData.map((reportData, index) => {
      let reportObj = { index: index + 1 };
      const reportInfo = ColumnsList.map((reportColumnNamesData, index) => {
        let fData = filterCol.filter(
          (rowData) =>
            rowData.isChecked &&
            rowData.columnKeyName == reportColumnNamesData.name
        );
        if (fData.length != 0) {
          reportObj = {
            ...reportObj,
            [reportColumnNamesData.name]:
              reportColumnNamesData.isTotal == 1
                ? (+reportData[reportColumnNamesData.name]).toFixed(2)
                : reportData[reportColumnNamesData.name],
          };
        }
      });

      return reportObj;
    });
    let headerList = [];
    if (columns.length > 6) {
      this.props.showNotification({
        msg: "Plase Select only 6 columns",
        severity: "error",
      });
    } else {
      generatePDF({
        rows,
        columns,
        columnStyles: align,
        headerList,
        pdfTitle: this.props.screenTitle,
      });
    }
  };
  handledChange = (e, newValue) => {
    // this.props.getTabValue(newValue);
    this.setState({
      tabValue: newValue,
    });
  };
  setFromDataForSearch = () => {
    const { fieldDataForSearch, tabValue } = this.state;
    const { tabList = [], today } = this.props;
    const dataToSave = {};
    tabList[tabValue].searchList &&
      tabList[tabValue].searchList.forEach((currentField) => {
        if (currentField.controlType === "datepicker") {
          if (fieldDataForSearch[currentField.dataKey]) {
            if (!today) {
              dataToSave[currentField.dataKey] = fieldDataForSearch[
                currentField.dataKey
              ]
                ? fieldDataForSearch[currentField.dataKey].format("DD-MM-YYYY")
                : "";
            } else {
              dataToSave[currentField.dataKey] =
                fieldDataForSearch[currentField.dataKey] !== ""
                  ? fieldDataForSearch[currentField.dataKey].format(
                    "DD-MM-YYYY"
                  )
                  : dayjs().format("DD-MM-YYYY");
            }
          } else {
            if (!today) {
              dataToSave[currentField.dataKey] = "";
            } else {
              dataToSave[currentField.dataKey] = dayjs().format("DD-MM-YYYY");
            }
          }
        } else {
          dataToSave[currentField.dataKey] = fieldDataForSearch[
            currentField.dataKey
          ]
            ? fieldDataForSearch[currentField.dataKey]
            : "";
        }
      });
    return dataToSave;
  };
  onFormSearch = async () => {
    const { tabValue } = this.state;

    const dataToSave = await this.setFromDataForSearch();
    // localStorage.setItem("formData", JSON.stringify(dataToSave));
    this.onOpenFilter();
    this.props.onSearchData({ ...dataToSave, flag: tabValue });
  };
  rowDelete = (rowData) => {
    const {
      deleteURLStatic = false,
      rowDelete,
      deleteStaticTrue = true,
      onDeleteStatic,
    } = this.props;
    if (deleteStaticTrue) {
      rowDelete(rowData);
    } else {
      swal({
        title: "Are you sure?",
        text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          if (deleteURLStatic) {
            onDeleteStatic(rowData);
          } else {
            this.onDelete(rowData);
          }
        }
      });
    }
  };

  rowTransfer = (rowData) => {
    const { openFormPathStatus } = this.props;
    this.props.navigate(openFormPathStatus + "/" + rowData.id);
  };

  rowCopy = (rowData) => {
    const { formPath, rowCopy, copyAuto = true } = this.props;
    if (copyAuto) {
      this.props.navigate(formPath + "/" + rowData.id + "/1");
    } else {
      rowCopy(rowData);
    }
  };

  rowViewData = (rowData) => {
    const { formPathView, rowViewData, staticView = false } = this.props;
    if (staticView) {
      rowViewData(rowData);
    } else {
      this.props.navigate(formPathView + "/" + rowData.id);
    }
  };

  onAddButtonClick = () => {
    const { formPath, onAddButtonClick, formPathStatic = true } = this.props;
    if (formPathStatic) {
      onAddButtonClick();
    } else {
      this.props.navigate(formPath);
    }
  };
  getDataStatic = async () => {
    const { getTableData } = this.props;
    await getTableData();
  };
  onDelete = async (rowdata) => {
    const { getTableDataById = false } = this.props;
    let dataToSearchList = "";
    if (getTableDataById) {
      dataToSearchList = await this.setFromDataForSearch();
    }
    const {
      apiBaseURL,
      deleteURL = "",
      deleteMsgAuto = false,
      deleteMapping = true,
    } = this.props;
    if (navigator.onLine) {
      if (deleteMapping) {
        this.props.showLoador({ loador: true });
        apiDelete({
          url:
            deleteURL != ""
              ? deleteURL + "/" + rowdata.id
              : apiBaseURL + "/" + rowdata.id,
        }).then(({ data, success }) => {
          if (success) {
            this.props.showLoador({ loador: false });
            this.props.showNotification({
              msg: deleteMsgAuto ? data.data.message : msgConstant.deletemsg,
              severity: data.data.error ? "error" : "success",
            });

            if (getTableDataById) {
              this.props.onSearchData(dataToSearchList);
            } else {
              const { callApi = true } = this.props;
              if (callApi) {
                this.getData();
              }
            }
            this.setState({
              currentOperationMode: "",
            });
          } else {
            this.props.showNotification({
              msg: msgConstant.deleteFailedMsg,
              severity: "error",
            });

            this.props.showLoador({ loador: false });
          }
        });
      } else {
        this.props.showLoador({ loador: true });
        apiGet({
          url: apiBaseURL + "/" + rowdata.id + "/delete-by-id",
        }).then(({ success }) => {
          if (success) {
            this.props.showLoador({ loador: false });
            this.props.showNotification({
              msg: deleteMsgAuto ? data.data.message : msgConstant.deletemsg,
              severity: data.data.error ? "error" : "success",
            });
            const { getTableDataById = false } = this.props;
            if (getTableDataById) {
              this.props.onSearchData(dataToSearchList);
            } else {
              const { callApi = true } = this.props;
              if (callApi) {
                this.getData();
              }
            }
            this.setState({
              currentOperationMode: "",
            });
          } else {
            this.props.showNotification({
              msg: msgConstant.deleteFailedMsg,
              severity: "error",
            });
            this.props.showLoador({ loador: false });
          }
        });
      }
    } else {
      this.props.showNotification({
        msg: msgConstant.noInternetMsg,
        severity: "error",
      });
    }
  };
  onOpenFilter = () => {
    this.setState({
      openFilter: !this.state.openFilter,
    });
  };
  onClearData = async () => {
    this.onOpenFilter();
    this.setState({
      fieldDataForSearch: {},
    });
    this.getDataStatic();
  };
  render() {
    const {
      screenTitle = "",
      showAddButton = true,
      showInPaper = true,
      saveBtnText = "Search",
      showTabs = true,
      showHeadEdit = false,
      showApplyCredintial = false,
      showTransferButtonInTable = false,
      rowStatus,
      showHeadDelete = true,
      showCopyIcon = false,
      rowCopy,
      rowList,
      tabList = [],
      onSave,
      dynamicMasterData,
      showAddButtonInTable = false,
      getListById,
      showSaveBtn = false,
      showSaveBtnMain = true,
      showPegination = false,
      showAcceptIcon,
      showCloseIcon,
      acceptDetails,
      rejectDetails,
      showView,
      showPdf = false,
      rowPDF,
      acceptConditionName = "",
      acceptConditionValue = "",
      showGenerateBillIcon,
      generateBillConditionName = "",
      generateBillConditionValue = "",
      rejectConditionValue = "",
      rejectConditionName = "",
      viewConditionValue = "",
      viewConditionName = "",
      generateBill,
      rowApply,
      today = false,
      showClear,
      searchButtonGrid,
      searchButton = true,
      showApplyTitle = "",
      getTabValue,
      filterDataExcel,
      isNote = false,
      isNoteValue = "",
    } = this.props;
    const {
      tableColumnsToFilter = {},
      fieldDataForSearch = {},
      openFilter,
      showTable,
      tabValue,
      fieldDataForModule,
    } = this.state;
    return (
      <>
        <Paper
          sx={{
            p: showInPaper ? 2 : 0,
            pt: showInPaper ? 2 : 0,
            borderRadius: showInPaper ? 2 : 0,
          }}
          elevation={0}
        >
          <LandingScreenHeader
            tableColumnsToFilter={[]}
            screenTitle={screenTitle}
            showSearchBox={false}
            showPdfDownload={false}
            showSearch={false}
            showExcelDownload={false}
            showAddButton={
              showAddButton && fieldDataForModule.addApproveConfig == 1
                ? true
                : false
            }
            onAddButtonClick={this.onAddButtonClick}
            onSearch={this.onSearch}
            showTable={showTable}
            onOpenFilter={this.onOpenFilter}
            showFilterIcon={
              tabList[tabValue].searchList &&
                tabList[tabValue].searchList.length !== 0
                ? true
                : false
            }
          />


          {isNote && (
            <Grid item xs={12} sm={12} md={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 14, color: redColor, paddingLeft: 5 }}
                label={"Note : " + isNoteValue}
              />
            </Grid>
          )}

          {tabList[tabValue].searchList &&
            tabList[tabValue].searchList.length !== 0 &&
            openFilter && (
              <>
                <SearchPopUp
                  open={openFilter}
                  handleCloses={this.onOpenFilter}
                  title={screenTitle}
                  searchList={
                    tabList[tabValue].searchList
                      ? tabList[tabValue].searchList
                      : []
                  }
                  dynamicMasterData={dynamicMasterData}
                  fieldDataForSearch={fieldDataForSearch}
                  onDataChangeForSearch={this.onDataChangeForSearch}
                  onFormSearch={this.onFormSearch}
                  showClear={showClear}
                  onClear={this.onClearData}
                  searchButton={searchButton}
                  today={today}
                  searchButtonGrid={searchButtonGrid}
                  getTabValue={getTabValue}
                />
              </>
            )}

          <br></br>
          {showTabs && (
            <>
              <Box sx={{ width: "100%" }}>
                <Tabs
                  onChange={this.handledChange}
                  value={tabValue}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  aria-label="full width tabs example"
                >
                  {tabList.length < 4 && (
                    <Tab
                      label={
                        tabList[0].label +
                        " (" +
                        this.getFilteredTableData(0).length +
                        ")"
                      }
                      {...this.a11yProps(tabList[0].a11yProps)}
                    />
                  )}
                  {tabList.length < 4 && (
                    <Tab
                      label={
                        tabList[1].label +
                        " (" +
                        this.getFilteredTableData(1).length +
                        ")"
                      }
                      {...this.a11yProps(tabList[1].a11yProps)}
                    />
                  )}

                  {tabList.length > 2 && tabList.length < 4 && (
                    <Tab
                      label={
                        tabList[2].label +
                        " (" +
                        this.getFilteredTableData(2).length +
                        ")"
                      }
                      {...this.a11yProps(tabList[2].a11yProps)}
                    />
                  )}
                </Tabs>
                <SwipeableViews
                  index={tabValue}
                  onChangeIndex={this.handleChange}
                >
                  {tabList.map((data) => {
                    return (
                      <>
                        <TabPanel value={tabValue} index={data.allyProps}>
                          <LandingScreenHeader
                            tableColumnsToFilter={
                              tableColumnsToFilter[data.value]
                            }
                            onRTSelectMultipleChange={this.onRTSelectMultipleChange(
                              data.value
                            )}
                            screenTitle={""}
                            showSearchBox={true}
                            showPdfDownload={
                              data.showPdfDownload
                                ? data.showPdfDownload
                                : false
                            }
                            showSearch={true}
                            generatePDF={() => {
                              this.generatePDFMethod(
                                data.columns,
                                tableColumnsToFilter[data.value]
                              );
                            }}
                            showExcelDownload={
                              data.showExcelDownload &&
                              (fieldDataForModule.editReject == 1 ? data.showExcelDownload : false)
                            }
                            generateExcel={() => {
                              this.generateExcelMethod(
                                data.columns,
                                tableColumnsToFilter[data.value]
                              );
                            }}
                            showAddButton={false}
                            onAddButtonClick={this.onAddButtonClick}
                            onSearch={this.onSearch}
                            showTable={true}
                            onOpenFilter={this.onOpenFilter}
                            showFilterIcon={false}
                            showSearchBox={false}
                          />
                          <br />
                          {data.fieldMeta && data.fieldMeta.length !== 0 && (
                            <>
                              <DynamicFormWithoutSave
                                dynamicMasterData={dynamicMasterData}
                                onSave={onSave}
                                saveBtnText={saveBtnText}
                                fieldMeta={data.fieldMeta}
                                showSaveBtn={showSaveBtn}
                                showSaveBtnMain={showSaveBtnMain}
                                getListById={getListById}
                              />
                            </>
                          )}
                          <DynamicTable
                            data={this.getFilteredTableData(data.allyProps)}
                            hiddenColumnNames={
                              tableColumnsToFilter[data.value]
                                ? tableColumnsToFilter[data.value].map(
                                  (item) => {
                                    return !item.isChecked
                                      ? item.columnKeyName
                                      : "";
                                  }
                                )
                                : []
                            }
                            tableHead={data.columns}
                            showPegination={showPegination}
                            showNotification={this.props.showNotification}
                            rowEdit={this.rowEdit}
                            rowAdd={this.rowAdd}
                            filterDataExcel={filterDataExcel}
                            screenTitle={screenTitle}
                            showHeadEdit={
                              showHeadEdit && fieldDataForModule.editReject == 1
                                ? true
                                : false
                            }
                            showApplyCredintial={
                              showApplyCredintial &&
                                fieldDataForModule.editReject == 1
                                ? true
                                : false
                            }

                            showTransferButtonInTable={
                              showTransferButtonInTable &&
                                fieldDataForModule.editReject == 1
                                ? true
                                : false
                            }
                            showCopyIcon={
                              showCopyIcon && fieldDataForModule.editReject == 1
                                ? true
                                : false
                            }

                            rowTransfer={this.rowTransfer}
                            rowCopy={rowCopy ? rowCopy : this.rowCopy}
                            rowDelete={this.rowDelete}
                            showHeadDelete={
                              showHeadDelete &&
                                fieldDataForModule.deleteRejectApprove == 1
                                ? true
                                : false
                            }
                            isActionColActive={data.isActionColActive}
                            rowStatus={this.onChangeStatus}
                            showAcceptIcon={showAcceptIcon}
                            showCloseIcon={
                              showCloseIcon &&
                                fieldDataForModule.deleteRejectApprove == 1
                                ? true
                                : false
                            }
                            acceptDetails={acceptDetails}
                            rejectDetails={rejectDetails}
                            acceptConditionName={acceptConditionName}
                            acceptConditionValue={acceptConditionValue}
                            showGenerateBillIcon={
                              showGenerateBillIcon &&
                                fieldDataForModule.editReject == 1
                                ? true
                                : false
                            }
                            generateBillConditionName={
                              generateBillConditionName
                            }
                            generateBillConditionValue={
                              generateBillConditionValue
                            }
                            rejectConditionValue={rejectConditionValue}
                            rejectConditionName={rejectConditionName}
                            viewConditionValue={viewConditionValue}
                            viewConditionName={viewConditionName}
                            generateBill={generateBill}
                            rowViewData={this.rowViewData}
                            rowLinkToShowData={this.rowLinkToShowData}
                            showView={
                              showView && fieldDataForModule.view == 1
                                ? true
                                : false
                            }
                            showPdf={
                              showPdf && fieldDataForModule.editReject == 1 ? true : false
                            }
                            rowPDF={rowPDF}
                            showAddButtonInTable={
                              showAddButtonInTable &&
                                fieldDataForModule.editReject == 1
                                ? true
                                : false
                            }
                            rowApply={rowApply}
                            showApplyTitle={showApplyTitle}
                          // pdfDetailsId={pdfDetailsId}
                          // pdfDetailsId={pdfDetailsId}
                          />
                        </TabPanel>
                      </>
                    );
                  })}
                </SwipeableViews>
              </Box>
            </>
          )}
        </Paper>

        {/* <pre>{JSON.stringify(fieldData, null, 2)}</pre> */}
      </>
    );
  }
}
const mapStateToProps = () => ({});
const mapDispatchToProps = {
  showNotification,
  showLoador,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(DynamicTabwiseTable);

// import * as React from 'react';
// import PropTypes from 'prop-types';
// import SwipeableViews from 'react-swipeable-views';
// import { useTheme } from '@mui/material/styles';
// import AppBar from '@mui/material/AppBar';
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
// import Typography from '@mui/material/Typography';
// import Box from '@mui/material/Box';

// function TabPanel(props) {
// const { children, value, index, ...other } = props;

// return (
// <div
// role="tabpanel"
// hidden={value !== index}
// id={`full-width-tabpanel-${index}`}
// aria-labelledby={`full-width-tab-${index}`}
// {...other}
// >
// {value === index && (
// <Box sx={{ p: 3 }}>
// <Typography>{children}</Typography>
// </Box>
// )}
// </div>
// );
// }

// TabPanel.propTypes = {
// children: PropTypes.node,
// index: PropTypes.number.isRequired,
// value: PropTypes.number.isRequired,
// };

// function a11yProps(index) {
// return {
// id: `full-width-tab-${index}`,
// 'aria-controls': `full-width-tabpanel-${index}`,
// };
// }

// export default function FullWidthTabs() {
// const [value, setValue] = React.useState(0);

// const handleChange = (event, newValue) => {
// setValue(newValue);
// };

// const handleChangeIndex = (index) => {
// setValue(index);
// };

// return (
// <Box sx={{ bgcolor: 'background.paper', width: 500 }}>
// <AppBar position="static">
// <Tabs
// value={value}
// onChange={handleChange}
// indicatorColor="secondary"
// textColor="inherit"
// variant="fullWidth"
// aria-label="full width tabs example"
// >
// <Tab label="Item One" {...a11yProps(0)} />
// <Tab label="Item Two" {...a11yProps(1)} />
// <Tab label="Item Three" {...a11yProps(2)} />
// </Tabs>
// </AppBar>
// <SwipeableViews
// index={value}
// onChangeIndex={handleChangeIndex}
// >
// <TabPanel value={value} index={0} >
// Item One
// </TabPanel>
// <TabPanel value={value} index={1} >
// Item Two
// </TabPanel>
// <TabPanel value={value} index={2} >
// Item Three
// </TabPanel>
// </SwipeableViews>
// </Box>
// );
// }
