import { Box, Grid, Paper, Typography, Checkbox } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import { withRouter } from "../../components/withRouter";
import { primary } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import { apiGet, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";

import swal from "sweetalert";

class RateDifferenceClaimForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      apiData: {},
      tabIndex: 0,
      rowList: [],
      fieldData: {},
      remark: "",
      dynamicMasterData: {},
      searchValue: "",
      selectedOffers: [],
      selectAllChecked: false,
    };
  }

  componentDidMount() {
    if (navigator.onLine) {
      this.props.showLoador({ loador: true });
      apiPost({
        url: `${endpoint.creditNoteOffer}/claim-for-rate-diff-outlet`,
        postBody: {},
      })
        .then(({ data, success }) => {
          console.log("API Response:", data?.data);
          this.props.showLoador({ loador: false });
          if (success) {
            this.setState({ apiData: this.transformApiData(data) });
            this.setState({ rowList: data?.data });
          } else {
            this.props.showNotification({
              msg: "An error occurred",
              severity: "error",
            });
          }
        })
        .catch((error) => {
          console.error("API Error:", error);
          this.props.showNotification({
            msg: "An error occurred",
            severity: "error",
          });
          this.props.showLoador({ loador: false });
        });
    } else {
      this.props.showNotification({
        msg: "No internet connection",
        severity: "error",
      });
    }
  }

  getListApi = async () => {
    if (navigator.onLine) {
      this.props.showLoador({ loador: true });
      apiPost({
        url: `${endpoint.creditNoteOffer}/claim-for-rate-diff-outlet`,
        postBody: {},
      })
        .then(({ data, success }) => {
          console.log("API Response:", data?.data);
          this.props.showLoador({ loador: false });
          if (success) {
            this.setState({ apiData: this.transformApiData(data) });
            this.setState({ rowList: data?.data });
          } else {
            this.props.showNotification({
              msg: "An error occurred",
              severity: "error",
            });
          }
        })
        .catch((error) => {
          console.error("API Error:", error);
          this.props.showNotification({
            msg: "An error occurred",
            severity: "error",
          });
          this.props.showLoador({ loador: false });
        });
    } else {
      this.props.showNotification({
        msg: "No internet connection",
        severity: "error",
      });
    }
  };

  onDataChange = (fieldName, newValue) => {
    this.setState((prevState) => ({
      fieldData: { ...prevState.fieldData, [fieldName]: newValue },
    }));
  };

  changeHandler = (e) => {
    this.setState({ remark: e.target.value });
  };

  onSubmitData = async () => {

    let dataToSubmit = [];

    if (this.state.selectedOffers.length > 0) {
      const selectedOutletIds = this.state.selectedOffers.map((offer) => offer.outletId);
      dataToSubmit = this.state.rowList.filter((row) => selectedOutletIds.includes(row.outletId));
    } else {
      dataToSubmit = this.state.rowList;
    }
    

    console.log("dataToSubmit",dataToSubmit)

    if (this.state.selectedOffers.length === 0) {
      this.props.showNotification({
        msg: "Please select at least one record to submit",
        severity: "warning",
      });
      return;
    }

    swal({
      title: "Are you sure?",
      text: "Do you want submit data?",
      // icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((isSubmit) => {
      if (isSubmit) {
        if (navigator.onLine) {
          this.props.showLoador({ loador: true });
          apiPost({
            url: `${endpoint.creditNoteOffer}/generate-grn-stackholder-rate-diff`,
            postBody: dataToSubmit,
          })
            .then(({ data, success }) => {
              console.log("API Response:", data);
              this.props.showLoador({ loador: false });
              if (success) {
                this.props.showNotification({
                  msg: "Submitted successfully",
                  severity: "success",
                });
                this.getListApi();
              } else {
                this.props.showNotification({
                  msg: "Failed to update status",
                  severity: "error",
                });
              }
            })
            .catch((error) => {
              console.error("API Error:", error);
              this.props.showNotification({
                msg: "An error occurred",
                severity: "error",
              });
              this.props.showLoador({ loador: false });
            });
        } else {
          this.props.showNotification({
            msg: "No internet connection",
            severity: "error",
          });
        }
      }
    });
  }; 

  getFilteredTableData = (tableData) => {
    const { searchValue } = this.state;

    if (!searchValue) return tableData;
    return tableData.filter((currentRow) => {
      const lowerSearchValue = searchValue.toLowerCase();

      const searchFields = [currentRow.outletName];

      return searchFields.some(
        (field) => field && field.toLowerCase().includes(lowerSearchValue)
      );
    });
  };

  onSearchData = async (data) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      if (data.outlet && data.outlet != "" && data.fromDate && data.toDate) {
        let outletBillHeaderJson = {
          outletIds: data.outlet.split(","),
          fromDate: data.fromDate,
          toDate: data.toDate,
        };
        showLoador({ loador: true });
        await apiGet({
          outletBillHeaderJson: outletBillHeaderJson,
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
          }
        });
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  handleChange = (event, newValue) => {
    this.setState({ tabIndex: newValue });
  };

  transformApiData = (apiData) => {
    console.log("apiData", apiData);

    if (!apiData || !apiData.data) {
      return apiData;
    }

    // Creating a new transformed list `offerListNew`
    const offerListNew = apiData.data.map((offer, index) => ({
      outletName: offer?.outletName || "-",
      ownerName: offer?.ownerName || "-",
      beatName: offer?.beatName || "-",
      taxableAmt: Number(offer?.taxableAmtTotal || 0).toFixed(2),
      taxAmt: Number(
        (offer?.cgstAmtTotal || 0) +
          (offer?.sgstAmtTotal || 0) +
          (offer?.igstAmtTotal || 0)
      ).toFixed(2),
      totalAmt: Number(offer?.totalAmtTotal || 0).toFixed(2),
      outletId: offer?.outletId || "-",

      items: Array.isArray(offer.itemList)
        ? offer.itemList.map((item, itemIndex) => ({
            index: itemIndex + 1,
            billNo: item?.billNo || "-",
            billDate: item?.billDate || "-",
            itemName: item?.productName || "-",
            qty: item?.qty || "0",
            rate: Number(item?.rate || 0).toFixed(2),
            taxableAmt: Number(item?.taxableAmt || 0).toFixed(2),
            taxAmt: Number(
              (item?.cgstAmt || 0) + (item?.sgstAmt || 0) + (item?.igstAmt || 0)
            ).toFixed(2),
            totalAmt: Number(item?.totalAmt || 0).toFixed(2),
          }))
        : [],
    }));

    return {
      ...apiData,
      data: {
        ...apiData.data,
        offerListNew,
      },
    };
  };

  handleSelectAllChange = (event) => {
    const isChecked = event.target.checked;

    this.setState((prevState) => {
      // Ensure offerListNew is an array before iterating
      const offers = Array.isArray(prevState.apiData?.data?.offerListNew)
        ? prevState.apiData.data.offerListNew
        : [];

      return {
        selectAllChecked: isChecked,
        selectedOffers: isChecked ? [...offers] : [], // Select all or clear
      };
    });
  };

  handleIndividualSelect = (event, selectedOffer) => {
    const isChecked = event.target.checked;

    this.setState((prevState) => {
      const offers = Array.isArray(prevState.apiData?.data?.offerListNew)
        ? prevState.apiData.data.offerListNew
        : [];

      let updatedSelection = isChecked
        ? [...prevState.selectedOffers, selectedOffer] // Add selected offer
        : prevState.selectedOffers.filter((offer) => offer !== selectedOffer); // Remove selected offer

      return {
        selectedOffers: updatedSelection,
        selectAllChecked: updatedSelection.length === offers.length, // Check if all selected
      };
    });
  };

  render() {
    const { apiData } = this.state;

    return (
      <Paper
        sx={{
          padding: 2,
          border: "1px solid #ddd", // Light grey border
          borderRadius: 2, // Rounded corners
          elevation: 3, // Shadow effect
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Custom shadow
        }}
      >
        <LandingScreenHeader
          screenTitle="Rate Difference Claim"
          showSearchBox={true}
          showAddButton={false}
        />
                {apiData?.data?.offerListNew?.length > 0 ? (

        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <Typography sx={{ fontSize: 16, fontWeight: "bold", marginRight: 1 }}>
            Select All
          </Typography>
          <Checkbox
            checked={this.state.selectAllChecked}
            onChange={this.handleSelectAllChange}
          />
        </Box> ) : null}

        <>
          <Box
            sx={{
              overflowY: "auto",
              maxHeight: "500px",
              borderRadius: "10px",
              "&::-webkit-scrollbar": { display: "none" }, // Hides scrollbar in Chrome, Safari, Edge
            }}
          >
            {/* <Divider /> */}

            <Grid container alignItems="center">
              <Grid item xs={12} md={12} sm={12}>
                {apiData?.data?.offerListNew?.length > 0 ? (
                  <>
                    {apiData?.data?.offerListNew?.map((dataRow, index) => (
                      <div
                        key={index}
                        style={{
                          marginBottom: "5px",
                          paddingBottom: "10px",
                        }}
                      >
                        <Box
                          sx={{
                            position: "relative", // Parent container for absolute positioning
                            padding: "1px",
                            border: "2px solid #ddd",
                            borderRadius: "15px",
                            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Soft shadow effect
                          }}
                        >
                          {/* Individual Checkbox */}
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-end"
                          >
                            <Checkbox
                              checked={this.state.selectedOffers.includes(
                                dataRow
                              )}
                              onChange={(event) =>
                                this.handleIndividualSelect(event, dataRow)
                              }
                            />
                            {/* <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
          Select Offer
        </Typography> */}
                          </Box>

                          <Grid container spacing={2} padding={4}>
                            {/*  */}

                            <Grid
                              container
                              spacing={2}
                              alignItems="center"
                              sx={{ marginLeft: 1 }}
                            >
                              <Grid
                                item
                                xs={12}
                                md={6}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <LabelCompo
                                  className="text-black"
                                  style={{
                                    fontSize: 16,
                                    color: primary,
                                    marginRight: 8,
                                  }}
                                  label="Outlet Name:"
                                />
                                <LabelCompo
                                  className="text-black"
                                  style={{ fontSize: 16 }}
                                  label={dataRow?.outletName || "-"}
                                />
                              </Grid>

                              <Grid
                                item
                                xs={12}
                                md={6}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <LabelCompo
                                  className="text-black"
                                  style={{
                                    fontSize: 16,
                                    color: primary,
                                    marginRight: 8,
                                  }}
                                  label="Outlet Owner Name:"
                                />
                                <LabelCompo
                                  className="text-black"
                                  style={{ fontSize: 16 }}
                                  label={dataRow?.ownerName || "-"}
                                />
                              </Grid>

                              <Grid
                                item
                                xs={12}
                                md={6}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <LabelCompo
                                  className="text-black"
                                  style={{
                                    fontSize: 16,
                                    color: primary,
                                    marginRight: 8,
                                  }}
                                  label="Beat Name:"
                                />
                                <LabelCompo
                                  className="text-black"
                                  style={{
                                    fontSize: 16,
                                    wordWrap: "break-word", // Breaks long words
                                    overflowWrap: "break-word", // Ensures wrapping
                                    whiteSpace: "normal", // Allows text to wrap
                                    maxWidth: "70%", // Ensures it stays within the container
                                  }}
                                  label={dataRow?.beatName || "-"}
                                />
                              </Grid>
                            </Grid>

                            {dataRow?.items?.length > 0 && (
                              <>
                                <Grid
                                  container
                                  item
                                  xs={12}
                                  md={12}
                                  sm={12}
                                  sx={{ marginTop: 1 }}
                                >
                                  <DynamicTable
                                    data={
                                      Array.isArray(dataRow?.items)
                                        ? this.getFilteredTableData(
                                            dataRow?.items
                                          )
                                        : []
                                    }
                                    tableHead={[
                                      {
                                        title: "Sr. No.",
                                        name: "index",
                                        positionCenter: true,
                                        showInscreen: true,
                                        alignCenter: "center",
                                        canSearch: true,
                                      },

                                      {
                                        title: "Bill No",
                                        name: "billNo",
                                        positionCenter: false,
                                        showInscreen: true,
                                        alignCenter: "center",
                                        canSearch: true,
                                      },
                                      {
                                        title: "Bill Date",
                                        name: "billDate",
                                        positionCenter: false,
                                        showInscreen: true,
                                        alignCenter: "center",
                                        canSearch: true,
                                      },
                                      {
                                        title: "Item Name",
                                        name: "itemName",
                                        positionCenter: false,
                                        showInscreen: true,
                                        alignCenter: "left",
                                        canSearch: true,
                                      },
                                      {
                                        title: "Quantity",
                                        name: "qty",
                                        positionCenter: false,
                                        showInscreen: true,
                                        alignCenter: "right",
                                        canSearch: true,
                                      },
                                      {
                                        title: "Rate",
                                        name: "rate",
                                        positionCenter: false,
                                        showInscreen: true,
                                        alignCenter: "right",
                                        canSearch: true,
                                      },
                                      {
                                        title: "Taxable Amount",
                                        name: "taxableAmt",
                                        positionCenter: false,
                                        showInscreen: true,
                                        alignCenter: "right",
                                        canSearch: true,
                                      },

                                      {
                                        title: "Tax Amount",
                                        name: "taxAmt",
                                        positionCenter: false,
                                        showInscreen: true,
                                        alignCenter: "right",
                                        canSearch: true,
                                      },

                                      {
                                        title: "Total Amount",
                                        name: "totalAmt",
                                        positionCenter: false,
                                        showInscreen: true,
                                        alignCenter: "right",
                                        canSearch: true,
                                      },
                                    ]}
                                    showPegination={false}
                                    isActionColActive={false}
                                  />
                                </Grid>

                                <Grid
                                  container
                                  spacing={2}
                                  sx={{ marginLeft: 1, marginTop: 1 }}
                                  justifyContent="space-between"
                                >
                                  <Grid item xs={12} md={4} lg={4}>
                                    <Typography
                                      variant="h6"
                                      sx={{ color: primary }}
                                    >
                                      ₹ Total Taxable Amount:{" "}
                                      {Number(dataRow?.taxableAmt || 0).toFixed(
                                        2
                                      )}
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={12} md={4} lg={4}>
                                    <Typography
                                      variant="h6"
                                      sx={{ color: primary }}
                                    >
                                      ₹ Total Tax Amount:{" "}
                                      {Number(dataRow?.taxAmt).toFixed(2)}
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={12} md={4} lg={4}>
                                    <Typography
                                      variant="h6"
                                      sx={{ color: primary }}
                                    >
                                      ₹ Total Amount:{" "}
                                      {Number(dataRow?.totalAmt).toFixed(2)}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </>
                            )}
                          </Grid>
                        </Box>
                      </div>
                    ))}
                  </>
                ) : (
                  <Typography
                    variant="h6"
                    color="textSecondary"
                    sx={{ textAlign: "center", mt: 2 }}
                  >
                    No data available for rate difference claim.
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Box>

          {apiData?.data?.offerListNew?.length > 0 && (
            <>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="right"
                sx={{ marginTop: 1, padding: 2 }}
              >
                <ButtonCompo
                  size="medium"
                  type="Submit"
                  variant="contained"
                  name="Submit"
                  onClick={this.onSubmitData}
                />
              </Grid>
            </>
          )}
        </>
      </Paper>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = { showLoador, showNotification };

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(RateDifferenceClaimForm);
