import endpoint from "../../config/endpoints";

export const outletOpeningJson = {
  formPath: "/manage-opening-outlet-form",
  formPathEdit: "/sub-admin-user-edit",
  apiBaseURL: endpoint.developerAdminUser,
  screenTitle: "Manage Opening - Outlet",
  openFormPathStatus: "/submit-access-role",
  isEditURL: endpoint.developerAdminUser + "/update",
  isActiveURL: endpoint.developerAdminUser + "/update-is-active",
  showAddButton: true,
  showSaveNextBtn:false,
  
 
  fieldMetaForAudit: [  
    {
      label: "Area",
      controlType: "multiSelect",
      placeHolder: "Select Area ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "areaDetail",
      getListId: "areaIds",
      dataKey: "areaIds",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Beat",
      controlType: "autocomplete",
      placeHolder: "Select Beat",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "beatIds",
      masterName: "beatDetail",
      getListFrom: "areaIds",
      getListId: "beatIds",
      isMandatory: true,
      isRootLevelKey: false,
    }
  ],
};
