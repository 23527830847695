import endpoint from "../../config/endpoints";

export const distributorOpeningJson = {
  formPath: "/manage-opening-distributor-form",
  formPathEdit: "/sub-admin-user-edit",
  apiBaseURL: endpoint.developerAdminUser,
  screenTitle: "Manage Opening - Distributor",
  openFormPathStatus: "/submit-access-role",
  isEditURL: endpoint.developerAdminUser + "/update",
  isActiveURL: endpoint.developerAdminUser + "/update-is-active",
  showAddButton: true,
  showSaveNextBtn: false,

  fieldMeta: [
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromDate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "toDate-le",
      format: "DD-MM-YYYY",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "toDate",
      onSubmit: "compare",
      onSubmitParameter: "fromDate-ge",
      isMandatory: true,
      onSubmit: "compare",
      format: "DD-MM-YYYY",
      maxDatekey: "fromDate",
    },
    {
      label: "Region",
      controlType: "autocomplete",
      placeHolder: "Select Region",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "regionDetail",
      dataKey: "regionIds",
      getListId: "regionIds",
      getListFrom: "regionIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "State",
      controlType: "autocomplete",
      placeHolder: "Select State",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stateDetail",
      dataKey: "stateIds",
      getListId: "stateIds",
      getListFrom: "stateIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Zone",
      controlType: "autocomplete",
      placeHolder: "Select Zone",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "zoneDetail",
      dataKey: "zoneIds",
      getListId: "zoneIds",
      getListFrom: "stateIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "District",
      controlType: "multiSelect",
      placeHolder: "Select District",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "districtDetail",
      dataKey: "districtIds",
      getListId: "districtIds",
      getListFrom: "zoneIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Taluka",
      controlType: "multiSelect",
      placeHolder: "Select Taluka",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "talukaDetail",
      dataKey: "talukaIds",
      getListId: "talukaIds",
      getListFrom: "districtIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Area",
      controlType: "multiSelect",
      placeHolder: "Select Area",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "areaDetail",
      dataKey: "areaIds",
      getListFrom: "talukaIds",
      getListId: "areaIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Stock Holder",
      controlType: "autocomplete",
      placeHolder: "Select Stock Holder",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "stockHolderDetail",
      dataKey: "stackholder",
      getListFrom: "areaIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
  ],
};
