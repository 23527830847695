import AddIcon from "@mui/icons-material/Add";
import CommentIcon from "@mui/icons-material/Comment";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import LinkIcon from "@mui/icons-material/Link";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ListTwoToneIcon from "@mui/icons-material/ListTwoTone";
import PaidIcon from "@mui/icons-material/Paid";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SchoolIcon from "@mui/icons-material/School";
import TouchAppRoundedIcon from "@mui/icons-material/TouchAppRounded";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import AttachFile from "../AttachFile";
import { imageUrl } from "../../../config/Url";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import * as React from "react";
import {
  ChipCompo,
  FontAwesomeIconCompo,
  MyComponentWithIconProps,
} from "../IconButton";
import { TextFieldCompo } from "../TextFieldCompo";
import LandingScreenHeader from "../LandingScreenHeader";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { isActionColActive, isConditionalActions, order, tableHead, orderBy } =
    props;

  return (
    <TableHead>
      <TableRow>
        {tableHead.map((headCell) => {
          if (headCell.showInscreen && headCell.title !== "Action") {
            return (
              <TableCell
                key={headCell.name}
                align={"center"}
                style={{ minWidth: headCell.width }}
              // padding={headCell.disablePadding ? 'none' : 'normal'}
              // sortDirection={orderBy === headCell.name ? order : false}
              >
                {headCell.title}
                {orderBy === headCell.name ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableCell>
            );
          } else if (
            headCell.showInscreen &&
            (isActionColActive || isConditionalActions) &&
            headCell.title === "Action"
          ) {
            return (
              <TableCell
                key={headCell.name}
                align={"center"}
                style={{ minWidth: headCell.width }}
              // padding={headCell.disablePadding ? 'none' : 'normal'}
              // sortDirection={orderBy === headCell.name ? order : false}
              >
                {headCell.title}
                {orderBy === headCell.name ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableCell>
            );
          }
          return null;
        })}
      </TableRow>
    </TableHead>
  );
}
function Row({
  row,
  rowList = [],
  setList,
  tableHead,
  rowStatus,
  statusChange,
  rowDelete,
  rowEdit,
  rowId,
  rowView,
  rowViewData,
  showHeadEdit,
  showHeadDelete,
  studListEmpty = false,
  msg,
  showHeadAdd,
  showApply,
  showView,
  showRoundDetails,
  showRoundDetailsNew,
  rounds,
  isConditionalActions,
  isActionColActive,
  showPayFees,
  showPaymentDetails,
  showAppliedList,
  showAppliedListNew,
  showDetails,
  showSchoolIcon,
  showLinkIcon,
  showPersonAdd,
  rowPersonAddDetails,
  rowFeesPaid,
  rowAppliedList,
  rowDetails,
  rowSchoolDetails,
  generateDetails,
  rowPaymentDetails,
  showHeadPdf,
  marginTop,
  showHeadExcel,
  condition = [],
  pdfDetailsId,
  excelDetailsId,
  showSelectCompo,
  rowSelectCompo,
  showComment,
  rowComment,
  alertMail,
  deleteConditionName = "",
  deleteConditionValue = "",
  editConditionName = "",
  editConditionValue = "",
  onChangeStatusStatic,
  credentialConditionName = "",
  credentialConditionValue = "",
  showSendRequestIcon,
  rowSendRequest,
  sendRequestIconName = "",
  sendRequestIconValue = "",
  showSend,
}) {
  const [role, setRole] = React.useState("");

  const changeStatus = (e, row) => {
    e.stopPropagation();
    rowStatus(row);
  };

  const onClickDelete = (e, id) => {
    e.stopPropagation();
    rowDelete(id);
  };
  const pdfDetails = (e, row) => {
    e.stopPropagation();
    pdfDetailsId(row);
  };
  const excelDetails = (e, row) => {
    e.stopPropagation();
    excelDetailsId(row);
  };
  const onClickEdit = (e, id) => {
    e.stopPropagation();
    rowEdit(id);
  };
  const onClickRowId = (e, id) => {
    e.stopPropagation();
    rowId(id);
  };
  const onClickApply = (e, id) => {
    e.stopPropagation();
    rowView(id);
  };

  const onClickViewPreview = (e, row) => {
    e.stopPropagation();
    const openUrl = row;
    window.open(imageUrl + openUrl, "_blank");
  };

  const onClickComment = (e, row) => {
    e.stopPropagation();
    rowComment(row);
  };

  const roundForm = (e, id) => {
    e.stopPropagation();
    rounds(id);
  };

  const feesPaid = (e, params) => {
    e.stopPropagation();
    rowFeesPaid(params);
  };
  const appliedList = (e, params) => {
    e.stopPropagation();
    rowAppliedList(params);
  };

  const details = (e, params) => {
    e.stopPropagation();
    rowDetails(params);
  };
  const schoolDetails = (e, params) => {
    e.stopPropagation();
    rowSchoolDetails(params);
  };
  const linkDetails = (e, params) => {
    e.stopPropagation();
    generateDetails(params);
  };

  const personAddDetails = (e, params) => {
    e.stopPropagation();
    rowPersonAddDetails(params);
  };

  const paymentDetails = (e, params) => {
    e.stopPropagation();
    rowPaymentDetails(params);
  };
  let conditionApplied = condition.filter((rowData) => {
    if (row[rowData.title]) {
      return row;
    } else {
      return [];
    }
  });
  const onTextFieldDataChange = (dataKey, rowObj) => (event) => {
    const { value } = event.target;
    const ListToSet = rowList.map((rowD) => {
      if (rowObj.index == rowD.index) {
        return {
          ...rowD,
          [dataKey]: value,
        };
      }
      console.log(rowD);
      return {
        ...rowD,
        [dataKey]: rowD[dataKey],
      };
    });
    setList({ row: ListToSet });
  };
  const onClickSelectCompo =
    (dataKey, rowData, nameToSave) => (fileName, name, index) => {
      const ListToSet = rowList.map((rowD) => {
        return {
          ...rowD,
          [dataKey]: rowData.id === rowD.id ? fileName.name : rowD[dataKey],
          [nameToSave]: rowData.id === rowD.id ? fileName : rowD[nameToSave],
        };
      });
      setList({ row: ListToSet });
    };
  React.useEffect(() => {
    let role = localStorage.getItem("role");
    if (role) {
      setRole(role);
    }
  }, []);
  return (
    <TableRow key={row.name}>
      {Object.keys(row).map(function (key, index) {
        const filteredList = tableHead.filter(
          (row) => row.name === key && row.showInscreen
        );

        if (filteredList.length !== 0) {
          {
          }
          if (
            filteredList[0].positionCenter &&
            key !== "isActive" &&
            key !== "isStatus"
          )
            return (
              <TableCell>
                <center>{row[key]}</center>
              </TableCell>
            );
          else if (key === "isActive") {
            return (
              <TableCell>
                {row[key] === 1 ? (
                  <center>
                    <ChipCompo
                      label="Active"
                      variant="outlined"
                      size="small"
                      color="success"
                      onClick={(e) => changeStatus(e, row)}
                    />
                  </center>
                ) : (
                  <center>
                    <ChipCompo
                      label="Inactive"
                      variant="outlined"
                      size="small"
                      color="error"
                      onClick={(e) => changeStatus(e, row)}
                    />
                  </center>
                )}
              </TableCell>
            );
          } else if (filteredList[0].textField) {
            return (
              <TableCell style={{ width: "13%" }}>
                <TextFieldCompo
                  placeHolder={filteredList[0].placeHolder}
                  size="small"
                  color="primary"
                  type="number"
                  // name={currentField.label}
                  id={"1"}
                  value={row[key]}
                  onChange={onTextFieldDataChange(key, row)}
                  fullWidth={false}
                  errorText={
                    row[filteredList[0].textFieldError] &&
                      row[filteredList[0].textFieldError] == 1
                      ? filteredList[0].error + " required"
                      : row[filteredList[0].textFieldError] &&
                        row[filteredList[0].textFieldError] == 2
                        ? filteredList[0].error + " invalid"
                        : row[filteredList[0].textFieldError] &&
                          row[filteredList[0].textFieldError] == 3
                          ? filteredList[0].error +
                          " cannot be greater than Bill Quantity"
                          : row[filteredList[0].textFieldError] &&
                            row[filteredList[0].textFieldError] == 4
                            ? filteredList[0].error + " cannot be zero"
                            : row[filteredList[0].textFieldError] &&
                              row[filteredList[0].textFieldError] == 5
                              ? filteredList[0].error +
                              " cannot be greater than product stock"
                              : ""
                  }
                />
              </TableCell>
            );
          } else if (filteredList[0].textField1) {
            return (
              <TableCell style={{ width: "13%" }}>
                <TextFieldCompo
                  placeHolder={filteredList[0].placeHolder}
                  size="small"
                  color="primary"
                  type="number"
                  // name={currentField.label}
                  id={"1"}
                  value={row[key]}
                  onChange={onTextFieldDataChange(key, row)}
                  fullWidth={false}
                  errorText={
                    row[filteredList[0].textFieldError] &&
                      row[filteredList[0].textFieldError] == 1
                      ? filteredList[0].error + " required"
                      : row[filteredList[0].textFieldError] &&
                        row[filteredList[0].textFieldError] == 2
                        ? filteredList[0].error + " invalid"
                        : row[filteredList[0].textFieldError] &&
                          row[filteredList[0].textFieldError] == 3
                          ? filteredList[0].error +
                          " cannot be greater than Bill Quantity"
                          : row[filteredList[0].textFieldError] &&
                            row[filteredList[0].textFieldError] == 4
                            ? filteredList[0].error + " cannot be zero"
                            : row[filteredList[0].textFieldError] &&
                              row[filteredList[0].textFieldError] == 5
                              ? filteredList[0].error +
                              " cannot be greater than product stock"
                              : ""
                  }
                />
              </TableCell>
            );
          } else if (filteredList[0].selectCompo) {
            return (
              <TableCell style={{ width: "13%" }}>
                <AttachFile
                  name={"file"}
                  fileName={onClickSelectCompo(
                    key,
                    row,
                    filteredList[0].nameToSave
                  )}
                />
                &nbsp;{" "}
                {row[key] ? (
                  <MyComponentWithIconProps
                    statusImage={VisibilityIcon}
                    color="primary"
                    fontSize="medium"
                    title="View"
                    sx={{ marginTop: marginTop }}
                    onClick={(e) => onClickViewPreview(e, row[key])}
                  />
                ) : (
                  ""
                )}
              </TableCell>
            );
          } else if (filteredList[0].openConditionAction) {
            return (
              <TableCell>
                {row[key] && (
                  <>
                    &nbsp;&nbsp;
                    <MyComponentWithIconProps
                      statusImage={VisibilityIcon}
                      color="primary"
                      fontSize="medium"
                      title="View"
                      sx={{ marginTop: marginTop }}
                      onClick={(e) => onClickViewPreview(e, row)}
                    />
                  </>
                )}
              </TableCell>
            );
          } else
            return (
              <TableCell align={filteredList[0].alignCenter}>
                {" "}
                {row[key]}
              </TableCell>
            );
        }
        return null;
      })}

      {isActionColActive && (
        <>
          <TableCell align={"center"} style={{ width: "13%" }}>
            {showHeadEdit && editConditionName == "" && (
              <>
                <MyComponentWithIconProps
                  statusImage={EditIcon}
                  color="primary"
                  fontSize="medium"
                  title="Edit"
                  onClick={(e) => onClickEdit(e, row)}
                />
              </>
            )}
            {showHeadEdit &&
              editConditionName != "" &&
              row[editConditionName] == editConditionValue && (
                <>
                  <MyComponentWithIconProps
                    statusImage={EditIcon}
                    color="primary"
                    fontSize="medium"
                    title="Edit"
                    onClick={(e) => onClickEdit(e, row)}
                  />
                </>
              )}
            {showHeadEdit &&
              editConditionName != "" &&
              row[editConditionName] != editConditionValue && <>&emsp;&emsp;</>}

            {showHeadDelete && deleteConditionName == "" && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={DeleteIcon}
                  color="error"
                  fontSize="medium"
                  title="Delete"
                  onClick={(e) => onClickDelete(e, row)}
                />
              </>
            )}
            {showHeadDelete &&
              deleteConditionName != "" &&
              row[deleteConditionName] == deleteConditionValue && (
                <>
                  &nbsp;&nbsp;
                  <MyComponentWithIconProps
                    statusImage={DeleteIcon}
                    color="error"
                    fontSize="medium"
                    title="Delete"
                    onClick={(e) => onClickDelete(e, row)}
                  />
                </>
              )}
            {showSendRequestIcon &&
              sendRequestIconName != "" &&
              row[sendRequestIconName] == sendRequestIconValue && (
                <>
                  &nbsp;&nbsp;
                  <MyComponentWithIconProps
                    statusImage={SendIcon}
                    color="primary"
                    fontSize="medium"
                    title="Send Request"
                    onClick={(e) => onClickSendRequest(e, row)}
                  />
                </>
              )}
            {showHeadDelete &&
              deleteConditionName != "" &&
              row[deleteConditionName] != deleteConditionValue && (
                <>&emsp;&emsp;</>
              )}

            {showSend && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={RateReviewIcon}
                  color="primary"
                  fontSize="medium"
                  title="Rate Configuration"
                  onClick={(e) => onClickSend(e, row)}
                />
              </>
            )}

            {/* {showApply && credentialConditionName != "" &&
              row[credentialConditionName] == credentialConditionValue && (
                <>
                  &nbsp;&nbsp;
                  <MyComponentWithIconProps
                    statusImage={TouchAppRoundedIcon}
                    color="primary"
                    fontSize="medium"
                    title={"Send Credential"}
                    onClick={(e) => onClickApply(e, row)}
                  />
                </>
              )} */}

            {showApply && credentialConditionName == "" && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={TouchAppRoundedIcon}
                  color="primary"
                  fontSize="medium"
                  title={"Send Credential"}
                  onClick={(e) => onClickApply(e, row)}
                />
              </>
            )}
            {showApply &&
              credentialConditionName != "" &&
              row[credentialConditionName] == credentialConditionValue && (
                <>
                  &nbsp;&nbsp;
                  <MyComponentWithIconProps
                    statusImage={TouchAppRoundedIcon}
                    color="primary"
                    fontSize="medium"
                    title={"Send Credential"}
                    onClick={(e) => onClickApply(e, row)}
                  />
                </>
              )}
            {showApply &&
              credentialConditionName != "" &&
              row[credentialConditionName] != credentialConditionValue && (
                <>&emsp;&emsp;</>
              )}
          </TableCell>
        </>
      )}
    </TableRow>
  );
}
EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function MaterialUITableWithTextField({
  tableHead = [],
  setList,
  rowStatus,
  statusChange,
  rowDelete,
  rowEdit,
  data = [],
  showHeadDelete,
  studListEmpty = false,
  msg,
  showHeadEdit,
  rowId,
  showHeadAdd,
  rowView,
  rowViewData,
  showApply,
  showView,
  showRoundDetails,
  showRoundDetailsNew,
  showPegination = false,
  rounds,
  isActionColActive = true,
  isConditionalActions = false,
  rowFeesPaid,
  rowAppliedList,
  rowPaymentDetails,
  showPayFees,
  showPaymentDetails,
  showAppliedList,
  showAppliedListNew,
  showDetails,
  showLinkIcon,
  showSchoolIcon,
  showPersonAdd,
  rowDetails,
  rowSchoolDetails,
  generateDetails,
  rowPersonAddDetails,
  showHeadPdf,
  marginTop,
  showHeadExcel,
  pdfDetailsId,
  excelDetailsId,
  condition,
  rowSelectCompo,
  showSelectCompo,
  showComment,
  rowComment,
  alertMail,
  deleteConditionName = "",
  deleteConditionValue = "",
  editConditionName = "",
  editConditionValue = "",
  onChangeStatusStatic,
  credentialConditionName = "",
  credentialConditionValue = "",
  showSendRequestIcon,
  rowSendRequest,
  sendRequestIconName = "",
  sendRequestIconValue = "",
  showSend,
  searchTexfield = false
}) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchValue, setsearchValue] = React.useState('');
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredTableData.length) : 0;

  const onSearch = (searchValue) => {
    setsearchValue(searchValue)
  };

  const filteredTableData = React.useMemo(() => {
    if (searchTexfield) {
      return data.filter((currentRow) => {
        let isValid = false;
        let productName = currentRow.name || "-";
        let stakeHolderUomName = currentRow.stakeHolderUomName || "-";

        if (productName.toLowerCase().includes(searchValue.toLowerCase())) {
          isValid = true;
        }
        if (stakeHolderUomName.toLowerCase().includes(searchValue.toLowerCase())) {
          isValid = true;
        }
        return isValid;
      });
    }
    return data
  }, [data, searchValue]);


  return (
    <>
      <LandingScreenHeader
        screenTitle={""}
        showSearchBox={true}
        showTable={true}
        showFilter={false}
        showPdfDownload={false}
        showExcelDownload={false}
        onSearch={onSearch}
      />
      <Box sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: 500 }}>
          <Table stickyHeader aria-label="sticky table">
            <EnhancedTableHead
              tableHead={tableHead}
              isConditionalActions={isConditionalActions}
              isActionColActive={isActionColActive}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={filteredTableData.length}
            />
            <TableBody>
              {showPegination
                ? stableSort(filteredTableData, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <Row
                        key={row.id}
                        row={row}
                        setList={setList}
                        rowList={data}
                        tableHead={tableHead}
                        showHeadDelete={showHeadDelete}
                        showHeadEdit={showHeadEdit}
                        rowEdit={rowEdit}
                        rowStatus={rowStatus}
                        statusChange={statusChange}
                        rowDelete={rowDelete}
                        showHeadAdd={showHeadAdd}
                        showHeadPdf={showHeadPdf}
                        marginTop={marginTop}
                        showHeadExcel={showHeadExcel}
                        condition={condition}
                        rowId={rowId}
                        rowView={rowView}
                        rowViewData={rowViewData}
                        showApply={showApply}
                        alertMail={alertMail}
                        showView={showView}
                        showComment={showComment}
                        rowComment={rowComment}
                        showRoundDetails={showRoundDetails}
                        showRoundDetailsNew={showRoundDetailsNew}
                        showPegination={showPegination}
                        rounds={rounds}
                        isConditionalActions={isConditionalActions}
                        isActionColActive={isActionColActive}
                        rowFeesPaid={rowFeesPaid}
                        rowAppliedList={rowAppliedList}
                        rowDetails={rowDetails}
                        rowPaymentDetails={rowPaymentDetails}
                        showPayFees={showPayFees}
                        showPaymentDetails={showPaymentDetails}
                        showAppliedList={showAppliedList}
                        showAppliedListNew={showAppliedListNew}
                        showDetails={showDetails}
                        showSchoolIcon={showSchoolIcon}
                        showLinkIcon={showLinkIcon}
                        rowSchoolDetails={rowSchoolDetails}
                        generateDetails={generateDetails}
                        pdfDetailsId={pdfDetailsId}
                        excelDetailsId={excelDetailsId}
                        showPersonAdd={showPersonAdd}
                        rowPersonAddDetails={rowPersonAddDetails}
                        rowSelectCompo={rowSelectCompo}
                        showSelectCompo={showSelectCompo}
                        deleteConditionName={deleteConditionName}
                        deleteConditionValue={deleteConditionValue}
                        editConditionName={editConditionName}
                        editConditionValue={editConditionValue}
                        credentialConditionName={credentialConditionName}
                        credentialConditionValue={credentialConditionValue}
                        onChangeStatusStatic={onChangeStatusStatic}
                        showSendRequestIcon={showSendRequestIcon}
                        rowSendRequest={rowSendRequest}
                        sendRequestIconName={sendRequestIconName}
                        sendRequestIconValue={sendRequestIconValue}
                        showSend={showSend}
                      //   tableSubHead={tableSubHead}
                      //   tableSubList={tableSubList}
                      />
                    );
                  })
                : stableSort(filteredTableData, getComparator(order, orderBy)).map(
                  (row, index) => {
                    return (
                      <Row
                        key={row.id}
                        rowList={data}
                        row={row}
                        setList={setList}
                        tableHead={tableHead}
                        showHeadDelete={showHeadDelete}
                        showHeadEdit={showHeadEdit}
                        rowEdit={rowEdit}
                        rowStatus={rowStatus}
                        statusChange={statusChange}
                        rowDelete={rowDelete}
                        showHeadAdd={showHeadAdd}
                        showHeadPdf={showHeadPdf}
                        marginTop={marginTop}
                        showHeadExcel={showHeadExcel}
                        condition={condition}
                        rowId={rowId}
                        rowView={rowView}
                        rowViewData={rowViewData}
                        showApply={showApply}
                        alertMail={alertMail}
                        showView={showView}
                        showComment={showComment}
                        rowComment={rowComment}
                        showRoundDetails={showRoundDetails}
                        showRoundDetailsNew={showRoundDetailsNew}
                        showPegination={showPegination}
                        rounds={rounds}
                        isConditionalActions={isConditionalActions}
                        isActionColActive={isActionColActive}
                        rowFeesPaid={rowFeesPaid}
                        rowAppliedList={rowAppliedList}
                        rowDetails={rowDetails}
                        rowPaymentDetails={rowPaymentDetails}
                        showPayFees={showPayFees}
                        showPaymentDetails={showPaymentDetails}
                        showAppliedList={showAppliedList}
                        showDetails={showDetails}
                        showSchoolIcon={showSchoolIcon}
                        showLinkIcon={showLinkIcon}
                        rowSchoolDetails={rowSchoolDetails}
                        generateDetails={generateDetails}
                        pdfDetailsId={pdfDetailsId}
                        excelDetailsId={excelDetailsId}
                        showPersonAdd={showPersonAdd}
                        rowPersonAddDetails={rowPersonAddDetails}
                        rowSelectCompo={rowSelectCompo}
                        showSelectCompo={showSelectCompo}
                        deleteConditionName={deleteConditionName}
                        deleteConditionValue={deleteConditionValue}
                        editConditionName={editConditionName}
                        editConditionValue={editConditionValue}
                        credentialConditionName={credentialConditionName}
                        credentialConditionValue={credentialConditionValue}
                        onChangeStatusStatic={onChangeStatusStatic}
                        showSendRequestIcon={showSendRequestIcon}
                        rowSendRequest={rowSendRequest}
                        sendRequestIconName={sendRequestIconName}
                        sendRequestIconValue={sendRequestIconValue}
                        showSend={showSend}
                      //   tableSubHead={tableSubHead}
                      //   tableSubList={tableSubList}
                      />
                    );
                  }
                )}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={tableHead.length} />
                </TableRow>
              )}
              {filteredTableData.length === 0 && (
                <TableRow
                  style={{
                    height: 53 * 1,
                  }}
                >
                  <TableCell align="center" colSpan={tableHead.length}>
                    No Record Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {showPegination && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredTableData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Box>
    </>

  );
}
