import { Grid, Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getYear } from "../../Slice/year.slice";
import { ProductWiseRUConfigJson } from "../../DynamicFormsJson/Transaction/productWiseRUConfigJson";
import { getRuWiseProductConfigurationById, setRuWiseProductConfigurationByIdDetails } from "../../Slice/ruWiseProductConfig.slice";
import { getAllActiveProductSortByBrand, setAllActiveProductSortByBrand } from "../../Slice/product.slice";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import DynamicFormWithoutValidation from "../../components/dynamicscreens/DynamicFormWithoutValidation";
import { withRouter } from "../../components/withRouter";
import { titleColor } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import { getRegionNew } from "../../Slice/region.slice";
import {
    checkedListEmptyMsg,
    nameAlreadyExistMsg,
    nameAlreadyPresentMsg,
    noInternetMsg,
    qtyGreaterThanBillQtyMsg,
    saveFailedMsg,
    saveWarningMsg,
    savemsg,
    serverMsg,
    updateData,
    updateMsg
} from "../../config/messageconstant";
import { apiGet, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import MaterialUITableWithTextField from "../../components/Comman/RT/MaterialUITableWithTextField";
import { mandatoryCheck, ValidationCheck } from "../../config/ValidationCheck";
import { OutletOpeningJson } from "../../DynamicFormsJson/MastersJSON/outletOpening";
import { getStateByRegion } from "../../Slice/state.slice";
import { getZoneByState } from "../../Slice/zone.slice";
import { getDistrictByZone } from "../../Slice/district.slice";
import { getTalukaByDistrict } from "../../Slice/taluka.slice";
import { getAreaByTalukaPostBodyUpdated } from "../../Slice/area.slice";
import { getBeatByArea } from "../../Slice/beat.slice";
import { getOutletByBeatIds } from "../../Slice/outlet.slice";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";

class OutletMovingForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // ruConfigurationList: [],
            formErrors: {},
            fieldData: {},
            rowData: {},
            searchValue: "",
            remark: "",
            dynamicMasterData: {
                regionDetail: this.props.regionList?.region,
                stateDetail: this.props.stateList?.stateByRegion,
                zoneDetail: this.props.zoneList?.zoneByState,
                districtDetail: this.props.districtList?.districtByZone,
                talukaDetail: this.props.talukaList?.talukaByDistrict,
                areaDetail: this.props.areaList?.areaByTaluka,
                beatDetail: this.props.beatList.beat,
                outletDetail: this.props.outletList.outlet,

            },
        };
    }

    async componentDidMount() {
        const { showLoador, showNotification, getRegionNew } = this.props;
        if (navigator.onLine) {

            showLoador({ loador: true });
            await getRegionNew().then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });

        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.regionList !== nextProps.regionList) {
            if (this.props.regionList?.region !== nextProps.regionList?.region) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        regionDetail: nextProps.regionList?.region,
                    },
                });
            }
        }
        if (this.props.stateList !== nextProps.stateList) {
            if (
                this.props.stateList?.stateByRegion !==
                nextProps.stateList?.stateByRegion
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        stateDetail: nextProps.stateList?.stateByRegion,
                    },
                });
            }
        }

        if (this.props.zoneList !== nextProps.zoneList) {
            if (
                this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        zoneDetail: nextProps.zoneList?.zoneByState,
                    },
                });
            }
        }

        if (this.props.districtList !== nextProps.districtList) {
            if (
                this.props.districtList?.districtByZone !==
                nextProps.districtList?.districtByZone
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        districtDetail: nextProps.districtList?.districtByZone,
                    },
                });
            }
        }

        if (this.props.talukaList !== nextProps.talukaList) {
            if (
                this.props.talukaList?.talukaByDistrict !==
                nextProps.talukaList?.talukaByDistrict
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        talukaDetail: nextProps.talukaList?.talukaByDistrict,
                    },
                });
            }
        }

        if (this.props.areaList !== nextProps.areaList) {
            if (
                this.props.areaList?.areaByTaluka !== nextProps.areaList?.areaByTaluka
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        areaDetail: nextProps.areaList?.areaByTaluka,
                    },
                });
            }
        }

        if (this.props.areaList !== nextProps.areaList) {
            if (this.props.areaList?.area !== nextProps.areaList?.area) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        areaUserTypeDetail: nextProps.areaList?.area,
                    },
                });
            }
        }
        if (this.props.beatList !== nextProps.beatList) {
            if (this.props.beatList?.beat !== nextProps.beatList?.beat) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        beatDetail: nextProps.beatList?.beat,
                    },
                });
            }
        }
        if (this.props.outletList !== nextProps.outletList) {
            if (this.props.outletList?.outlets !== nextProps.outletList?.outlets) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        outletDetail: nextProps.outletList?.outlets,
                    },
                });
            }
        }

    }

    getListById = async (data) => {
        const {
            getStateByRegion,
            getZoneByState,
            getDistrictByZone,
            getTalukaByDistrict,
            getAreaByTalukaPostBodyUpdated,
            showLoador,
            showNotification,
            getBeatByArea,
            getOutletByBeatIds,
        } = this.props;
        if (navigator.onLine) {
            if (data.region && data.region != "") {
                showLoador({ loador: true });
                await getStateByRegion({ regionIds: data.region }).then(
                    ({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    }
                );

            }
            if (data.state && data.state != "") {
                showLoador({ loador: true });
                await getZoneByState({ stateIds: data.state }).then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }
            if (data.zone && data.zone != "") {
                showLoador({ loador: true });
                await getDistrictByZone({ zoneIds: data.zone }).then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }
            if (data.district && data.district != "") {
                showLoador({ loador: true });
                await getTalukaByDistrict({ districtIds: data.district }).then(
                    ({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    }
                );
            }
            if (data.taluka && data.taluka != "") {
                let talukaIdsByPost = {
                    talukaIds: data.taluka,
                };
                showLoador({ loador: true });
                await getAreaByTalukaPostBodyUpdated({ talukaIds: talukaIdsByPost }).then(
                    ({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    }
                );
            }
            if (data.area) {
                showLoador({ loador: true });
                await getBeatByArea({ areaId: data.area ? data.area : 0 }).then(
                    ({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    }
                );
            }

            if (data.beat && data.beat != "") {
                if (this.state.searchBy == 0) {
                    showLoador({ loador: true });
                    await getOutletByBeatIds({ beatIds: data.beat.join() }).then(
                        ({ success }) => {
                            showLoador({ loador: false });
                            if (!success) {
                                showNotification({ msg: serverMsg, severity: "error" });
                            }
                        }
                    );
                } else {
                    showLoador({ loador: true });
                    await getOutletByBeatIds({ beatIds: data.beat.join() }).then(
                        ({ success }) => {
                            showLoador({ loador: false });
                            if (!success) {
                                showNotification({ msg: serverMsg, severity: "error" });
                            }
                        }
                    );
                }
            }
        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };

   
      

    onSearch = (searchValue) => {
        this.setState({
            searchValue: searchValue,
        });
    };
    onSearchMethod = () => {
        console.log("1")
    }


    render() {
        const { dynamicMasterData, formErrors, fieldData } =
            this.state;

        return (
            <>
                <Paper
                    sx={{
                        padding: 2,
                    }}
                >
                    {/* <DynamicFormWithoutValidation
                        dynamicMasterData={dynamicMasterData}
                        screenTitle={OutletOpeningJson.screenTitle}
                        fieldMeta={OutletOpeningJson.fieldMeta}
                        apiBaseURL={OutletOpeningJson.apiBaseURL}
                        formErrors={formErrors}
                        showSaveBtn={OutletOpeningJson.showSaveBtn}
                        showSaveBtnMain={false}
                        getListById={this.getListById}
                        fieldData={fieldData}
                        onDataChange={this.onDataChange}
                        showBackToList={true}
                    /> */}

                    <DynamicFormWithoutSave
                        dynamicMasterData={dynamicMasterData}
                        screenTitle={OutletOpeningJson.screenTitle}
                        fieldMeta={OutletOpeningJson.fieldMeta}
                        apiBaseURL={OutletOpeningJson.apiBaseURL}
                        formErrors={formErrors}
                        showSaveBtn={true}
                        showSaveBtnMain={false}
                        getListById={this.getListById}
                        showSaveNextBtn={true}
                        onSave={this.onSearchMethod}
                        saveBtnText={"search"}
                        showBackToList={true}
                    />
                    <br />
                    <LandingScreenHeader
                        screenTitle={""}
                        showSearchBox={true}
                        showTable={true}
                        showFilter={false}
                        showPdfDownload={false}
                        showExcelDownload={false}
                        onSearch={this.onSearch}
                    />
                    <MaterialUITableWithTextField
                        tableHead={[
                            {
                                title: "Sr.No.",
                                name: "index",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "center",
                            },
                            {
                                title: "Outlet Name",
                                name: "name",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "left",
                            },
                            {
                                title: "Outlet Opening",
                                name: "stakeHolderUomName",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "center",
                            },

                        ]}
                    // data={
                    //     this.getFilteredTableData(
                    //         ruConfigurationList.length !== 0 ?
                    //             ruConfigurationList : []
                    //     )
                    // }
                    // setList={this.setListDetails}
                    // isActionColActive={false}

                    />
                    <br />
                    <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        container
                        justifyContent="right"
                    >
                        <ButtonCompo
                            size="medium"
                            type="Submit"
                            variant="contained"
                            name="Submit"
                            onClick={this.onSubmitData}
                        />
                    </Grid>
                </Paper>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    areaList: state.area,
    regionList: state.region,
    stateList: state.states,
    zoneList: state.zone,
    districtList: state.district,
    talukaList: state.taluka,
    beatList: state.beat,
    outletList: state.outlet,
});
const mapDispatchToProps = {
    showLoador,
    showNotification,
    getRegionNew,
    getStateByRegion,
    getDistrictByZone,
    getTalukaByDistrict,
    getZoneByState,
    getAreaByTalukaPostBodyUpdated,
    getBeatByArea,
    getOutletByBeatIds,

};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(OutletMovingForm); 
