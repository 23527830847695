import { Box, Paper, Tab, Tabs, Typography } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { SalesExecutiveJson } from "../../../DynamicFormsJson/MastersJSON/salesExecutive";
import { getRegionNew } from "../../../Slice/region.slice";
import { getStateByRegion } from "../../../Slice/state.slice";
import { getZoneByState } from "../../../Slice/zone.slice";
import { getAreaByZoneIdsPost, getAreaByTalukaPostBody } from "../../../Slice/area.slice";
import { getStockStakeHolderType } from "../../../Slice/stockStakeHolderType.slice";
import LandingScreenHeader from "../../../components/Comman/LandingScreenHeader";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import SwipeableViews from 'react-swipeable-views';
import AssignBeat from "./AssignBeat";
import { withRouter } from "../../../components/withRouter";
import PropTypes from "prop-types";
import {

  setBeatBySalesTeamIdAreaId,
} from "../../../Slice/beat.slice";
import { beatAssignedMsg, beatsAssign, noInternetMsg, saveFailedMsg, serverMsg, updateData, updateMsg } from "../../../config/messageconstant";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import endpoint from "../../../config/endpoints";
import { apiGet, apiPost } from "../../../utils/api_service";
import { getSalesExecutiveByAreaAndStockHolderType } from "../../../Slice/salesExecutive.slice";
import { getDistrictByZone } from "../../../Slice/district.slice";
import { getTalukaByDistrict } from "../../../Slice/taluka.slice";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

class SalesExecutiveForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,

      dynamicMasterData: {
        regionDetail: this.props.regionList?.region,
        stateDetail: this.props.stateList?.stateByRegion,
        zoneDetail: this.props.zoneList?.zoneByRegionId,
        districtDetail: this.props.districtList?.districtByZone,
        talukaDetail: this.props.talukaList?.talukaByDistrict,
        areaByTalukaDetail: this.props.areaList?.areaByTaluka,
        areaDetail: this.props.areaList?.area,
        stockStakeHolderTypeDetail: this.props.stockStakeHolderTypeList?.stockStakeHolderType?.filter(item => item.userType.id === 4),
        reportingManagerTypeDetail: this.props.stockStakeHolderTypeList?.stockStakeHolderType?.filter(item => item.userType.id !== 4)
      },
      stockStakeHolderTypeId: "",
      showCompleteHierarchyToSalesTeam: "",
    };
  }
  a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  handledChange = (e, newValue) => {
    this.props.setBeatBySalesTeamIdAreaId({ row: [] });
    this.setState({
      tabValue: newValue,
    });
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.regionList !== nextProps.regionList) {
      if (this.props.regionList?.region !== nextProps.regionList?.region) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            regionDetail: nextProps.regionList?.region,
          },
        });
      }
    }


    if (this.props.stateList !== nextProps.stateList) {
      if (this.props.stateList?.stateByRegion !== nextProps.stateList?.stateByRegion) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stateDetail: nextProps.stateList?.stateByRegion,
          },
        });
      }
    }

    if (this.props.zoneList !== nextProps.zoneList) {
      if (this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            zoneDetail: nextProps.zoneList?.zoneByState,
          },
        });
      }
    }

    if (this.props.districtList !== nextProps.districtList) {
      if (this.props.districtList?.districtByZone !== nextProps.districtList?.districtByZone) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            districtDetail: nextProps.districtList?.districtByZone,
          },
        });
      }
    }

    if (this.props.talukaList !== nextProps.talukaList) {
      if (this.props.talukaList?.talukaByDistrict !== nextProps.talukaList?.talukaByDistrict) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            talukaDetail: nextProps.talukaList?.talukaByDistrict,
          },
        });
      }
    }

    if (this.props.areaList !== nextProps.areaList) {
      if (this.props.areaList?.areaByTaluka !== nextProps.areaList?.areaByTaluka) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.areaByTaluka,
          },
        });
      }
    }

    if (this.props.areaList !== nextProps.areaList) {
      if (this.props.areaList?.area !== nextProps.areaList?.area) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.area,
          },
        });
      }
    }

    if (this.props.salesExecutiveList !== nextProps.salesExecutiveList) {
      if (
        this.props.salesExecutiveList?.salesExecutiveByAreaAndStockHolderType !==
        nextProps.salesExecutiveList?.salesExecutiveByAreaAndStockHolderType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            authorityDetail:
              nextProps.salesExecutiveList?.salesExecutiveByAreaAndStockHolderType,
          },
        });
      }
    }

    if (
      this.props.stockStakeHolderTypeList?.stockStakeHolderType !==
      nextProps.stockStakeHolderTypeList?.stockStakeHolderType
    ) {
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          stockStakeHolderTypeDetail: nextProps.stockStakeHolderTypeList?.stockStakeHolderType.filter(
            (row) => row.userType.id === 4
          ),
          reportingManagerTypeDetail: nextProps.stockStakeHolderTypeList?.stockStakeHolderType.filter(
            (row) => row.userType.id !== 4
          ),
        },
      });
    }




  }

  async componentDidMount() {
    const { getStockStakeHolderType, getRegionNew, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {

      const showCompleteHierarchyToSalesTeam = localStorage.getItem("showCompleteHierarchyToSalesTeam");
      this.setState({ showCompleteHierarchyToSalesTeam: showCompleteHierarchyToSalesTeam })


      showLoador({ loador: true });
      await getRegionNew().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getStockStakeHolderType({ userTypeIds: "3,4" }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  getListById = async (data) => {
    const {
      getZoneByState,
      getDistrictByZone,
      getTalukaByDistrict,
      getAreaByZoneIdsPost,
      getAreaByTalukaPostBody,
      getSalesExecutiveByAreaAndStockHolderType,
      getStateByRegion,
      showLoador,
      showNotification } =
      this.props;
    if (navigator.onLine) {
      if (data.stockStakeHolderType && data.stockStakeHolderType.id) {
        this.setState({
          stockStakeHolderTypeId: data.stockStakeHolderType.id,
        });
      }

      if (data.reportingManagerTypeId && data.areaIds) {
        let saveData = {
          stockStakeHolderTypeId: data.reportingManagerTypeId,
          areaIds: data.areaIds,
        }

        showLoador({ loador: true });
        await getSalesExecutiveByAreaAndStockHolderType({ dataJson: saveData }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }

      if (data.region && data.region.id) {
        showLoador({ loador: true });
        await getStateByRegion({ regionIds: data.region.id }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }

      if (data.stateId && data.stateId != "") {
        showLoador({ loador: true });
        await getZoneByState({ stateIds: data.stateId }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }


      if (data.zoneIds && data.zoneIds != "") {
        let dataJson = {
          zoneIds: typeof data.zoneIds === "string"
            ? data.zoneIds.split(",")
            : data.zoneIds,
        }
        showLoador({ loador: true });
        await getAreaByZoneIdsPost({ zoneIds: dataJson }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }


      if (data.zoneIds && data.zoneIds != "") {
        showLoador({ loador: true });
        await getDistrictByZone({ zoneIds: data.zoneIds }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }

      if (data.districtIds && data.districtIds != "") {
        showLoador({ loador: true });
        await getTalukaByDistrict({ districtIds: data.districtIds }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }

      if (data.talukaIds && data.talukaIds != "") {
        let talukaJson = {
          talukaIds: typeof data.talukaIds === "string"
            ? data.talukaIds.split(",")
            : data.talukaIds,
        };
        showLoador({ loador: true });
        await getAreaByTalukaPostBody({ talukaIds: talukaJson }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }


    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  onCancel = () => {
    this.props.navigate(-1);
  };
  getFormData = (rowData) => {
    this.props.setBeatBySalesTeamIdAreaId({ row: [] });
    this.props.navigate(SalesExecutiveJson.formPath + "/" + rowData.data.id)
    this.setState({
      tabValue: 1,
    });

  };

  getSalsPersonData = () => {
    const { dynamicMasterData } = this.state;
    let stockStakeHolderType = null;

    if (Array.isArray(dynamicMasterData.stockStakeHolderTypeDetail) && dynamicMasterData.stockStakeHolderTypeDetail.length > 0) {
      stockStakeHolderType = {
        id: dynamicMasterData.stockStakeHolderTypeDetail[0].id
      };
    }

    return { stockStakeHolderType };
  };


  setDataToForm = (data) => {
    this.setState({ formData: data })
  };



  callBeforeSaveMethod = async (dataToSave, editData) => {

    if (
      Object.keys(editData).length == 0 ||
      (Object.keys(editData).length != 0 &&
        editData.areaIds != dataToSave.areaIds)
    ) {
      const { showLoador, showNotification } = this.props;
      if (navigator.onLine) {

        const unmatchedAreaIds = editData.areaIds.filter(
          areaId => !dataToSave.areaIds.includes(areaId)
        );


        let dataJson = {
          salesTeamId: editData.id,
          areaIds: unmatchedAreaIds,
        }

        showLoador({ loador: true });
        const response = await apiPost({
          url: endpoint.assignBeat + "/by-sales-team-and-areas",
          postBody: dataJson,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (success) {
            if (data.data.length !== 0) {
              showNotification({
                msg: beatsAssign,
                severity: "error",
              });
            } else {

              showLoador({ loador: true });
              apiPost({
                url: endpoint.salesExecutive + "/update",
                postBody: dataToSave,
              }).then(({ data, success }) => {
                showLoador({ loador: false });
                if (success) {
                  showNotification({ msg: updateMsg });
                  if (data && Object.keys(data).length !== 0) {
                    this.getFormData(data);
                  }
                } else {
                  showNotification({ msg: saveFailedMsg, severity: "error" });
                }
              });
            }
          }
          return false;
        });
        return response;
      } else {
        showNotification({ msg: noInternetMsg, severity: "error" });
      }
    }
    return true;
  };


  render() {
    const { dynamicMasterData, tabValue, showCompleteHierarchyToSalesTeam } = this.state;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            pt: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >

          <LandingScreenHeader
            screenTitle={SalesExecutiveJson.screenTitle}
            showTable={false}
            showBackButton={true}
            showAddButton={false}
            onCancel={this.onCancel}
          />
          <Tabs
            onChange={this.handledChange}
            value={tabValue}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example">



            <Tab label={"Form "} {...this.a11yProps(0)} />



            <Tab label={"Assign Beat"} {...this.a11yProps(1)} />


          </Tabs>
          <SwipeableViews

            index={tabValue}
            onChangeIndex={this.handleChange}
          >

            <TabPanel value={tabValue} index={0}>
              <DynamicForm
                padding={false}
                paddingTop={false}
                callBeforeSave={this.props.params.id ? true : false}
                callBeforeSaveMethod={this.callBeforeSaveMethod}
                formPath={SalesExecutiveJson.formPath}
                getListById={this.getListById}
                screenTitle={SalesExecutiveJson.screenTitle}
                fieldMeta={localStorage.getItem("showTargetTypeRadioGroup") &&
                  localStorage.getItem("showTargetTypeRadioGroup") == 1 ?
                  SalesExecutiveJson.fieldMetaHierarchyForTargetType :
                  SalesExecutiveJson.fieldMetaHierarchy}
                dynamicMasterData={dynamicMasterData}
                apiBaseURL={SalesExecutiveJson.apiBaseURL}
                showSaveNextBtn={SalesExecutiveJson.showSaveNextBtn}
                isEditURL={SalesExecutiveJson.isEditURL}
                showTitleAndBack={false}
                getExtraData={true}
                getData={this.getSalsPersonData}
                showSaveBtn={false}
                getFormData={this.getFormData}
                getFormDataStatic={true}
                getDataForm={true}
                setDataToForm={this.setDataToForm}
                isLog={true}
                type={1}
              />
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
              <AssignBeat
                padding={false}
                paddingTop={false}
                formPath={SalesExecutiveJson.formPath}
                getListById={this.getListById}
                screenTitle={SalesExecutiveJson.screenTitle}
                fieldMeta={SalesExecutiveJson.fieldMeta}
                dynamicMasterData={dynamicMasterData}
                apiBaseURL={SalesExecutiveJson.apiBaseURL}
                showSaveNextBtn={SalesExecutiveJson.showSaveNextBtn}
                isEditURL={SalesExecutiveJson.isEditURL}
                showTitleAndBack={false}
              />
            </TabPanel>


          </SwipeableViews>

        </Paper>

      </>
    );
  }
}
const mapStateToProps = (state) => ({
  stockStakeHolderTypeList: state.stockStakeHolderType,
  salesExecutiveList: state.salesExecutive,
  regionList: state.region,
  zoneList: state.zone,
  areaList: state.area,
  districtList: state.district,
  talukaList: state.taluka,
  stateList: state.states,
});

const mapDispatchToProps = {
  getStockStakeHolderType,
  getRegionNew,
  getZoneByState,
  getAreaByZoneIdsPost,
  showLoador,
  showNotification,
  setBeatBySalesTeamIdAreaId,
  getSalesExecutiveByAreaAndStockHolderType,
  getDistrictByZone,
  getTalukaByDistrict,
  getAreaByTalukaPostBody,
  getStateByRegion,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SalesExecutiveForm);
