import {
  BusinessOutlined,
  CalendarTodayOutlined,
  DescriptionOutlined,
  Inventory2Outlined,
  PersonOutlineOutlined,
  StorefrontOutlined,
} from "@mui/icons-material";
import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { pendingGrnForCreditNoteJson } from "../../DynamicFormsJson/Transaction/pendingGrnForCreditNote";
import { getStockStackHolders } from "../../Slice/StackHolder.slice";
import {
  getGRNByStakeholderIdForGrnToCrn,
  getGrnToCrn,
  setGrnToCrnData,
} from "../../Slice/requestGRN.slice";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { blackColor, titleColor } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import {
  checkedListEmptyMsg,
  genrateCRNMsg,
  genratedCRNMsg,
  noInternetMsg,
  saveFailedMsg,
  serverMsg,
} from "../../config/messageconstant";
import { apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import CheckBoxTable from "./GRNToCreditNoteTable";
class GRNToCreditNote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedGRN: [],
      fieldDataForModule: "",
      dynamicMasterData: {
        stakeholdersDetail: this.props.stakeholdersList.stockStakeHolders,
        grnDetails:
          this.props.grnByStakeholderIdList.grnByStakeholderIdForGrnToCrn,
      },
    };
  }

  async componentDidMount() {
    const {
      getStockStackHolders,
      showLoador,
      showNotification,
      setGrnToCrnData,
    } = this.props;
    if (navigator.onLine) {
      setGrnToCrnData({ row: [] });
      showLoador({ loador: true });
      await getStockStackHolders().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

      const moduleObjData = localStorage.getItem("moduleObj");

      this.setState({
        fieldDataForModule:
          moduleObjData != null ? JSON.parse(moduleObjData) : {},
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  getListById = async (data) => {
    const { getGRNByStakeholderIdForGrnToCrn, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      if (data.stackholder) {
        showLoador({ loador: true });
        await getGRNByStakeholderIdForGrnToCrn({
          holderId: data.stackholder,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.stakeholdersList !== nextProps.stakeholdersList) {
      if (
        this.props.stakeholdersList?.stockStakeHolders !==
        nextProps.stakeholdersList?.stockStakeHolders
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stakeholdersDetail: nextProps.stakeholdersList?.stockStakeHolders,
          },
        });
      }
    }

    if (
      this.props.grnByStakeholderIdList !== nextProps.grnByStakeholderIdList
    ) {
      if (
        this.props.grnByStakeholderIdList?.grnByStakeholderIdForGrnToCrn !==
        nextProps.grnByStakeholderIdList?.grnByStakeholderIdForGrnToCrn
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            grnDetails:
              nextProps.grnByStakeholderIdList?.grnByStakeholderIdForGrnToCrn,
          },
        });
      }
    }
  }

  onSearchList = async (data) => {
    const { getGrnToCrn, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      const areaListPost = data.requestGRN.split(",");
      let grnGvnHeaderIdsList = {
        grnGvnHeaderIds: areaListPost,
      };
      showLoador({ loador: true });
      await getGrnToCrn({
        grnGvnHeaderIds: grnGvnHeaderIdsList,
      }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onSubmit = () => {
    const { selectedGRN } = this.state;
    console.log(selectedGRN);
    const { showNotification, showLoador, grnByStakeholderIdList } = this.props;
    let error = false;
    let hasQtyError = false;
    const rowList = grnByStakeholderIdList.getGrnDataByGrnGvnHeaderIds.map(
      (rowObj) => {
        const selectedGRNIDs = selectedGRN.some((grn) => grn.id == rowObj.id);
        if (selectedGRNIDs) {
          hasQtyError = rowObj.grnGvnDetails.some(
            (detail) => detail.qtyError || detail.qtyError
          );
        }
      }
    );
    if (selectedGRN.length !== 0) {
      if (!hasQtyError) {
        swal({
          title: "Are you sure?",
          text: genrateCRNMsg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then(async (willDelete) => {
          if (willDelete) {
            if (navigator.onLine) {
              showLoador({ loador: true });
              await apiPost({
                url:
                  endpoint.holderCreditNoteHeader +
                  "/generate-list-and-approved-grn-gvns",
                postBody: selectedGRN,
              }).then(({ data, success }) => {
                if (success) {
                  showLoador({ loador: false });
                  showNotification({
                    msg: genratedCRNMsg,
                  });
                  window.location.reload();
                } else {
                  showNotification({
                    msg: saveFailedMsg,
                    severity: "error",
                  });
                  showLoador({ loador: false });
                }
              });
            } else {
              showNotification({ msg: noInternetMsg, severity: "error" });
            }
          }
        });
      }
    } else {
      showNotification({ msg: checkedListEmptyMsg, severity: "error" });
    }
  };

  handleCheckboxChange = (grn) => {
    this.setState((prevState) => {
      const { selectedGRN } = prevState;
      const isAlreadySelected = selectedGRN.some((item) => item.id === grn.id);

      return {
        selectedGRN: isAlreadySelected
          ? selectedGRN.filter((item) => item.id !== grn.id)
          : [...selectedGRN, grn],
      };
    });
  };

  handleSelectAllChange = (event) => {
    const isChecked = event.target.checked;
    this.setState({
      selectedGRN: isChecked
        ? [...this.props.grnByStakeholderIdList.getGrnDataByGrnGvnHeaderIds]
        : [],
    });
  };
  setListWithHeader =
    (mainData) =>
    ({ row }) => {
      const { grnByStakeholderIdList, setGrnToCrnData } = this.props;
      let listToSet = grnByStakeholderIdList?.getGrnDataByGrnGvnHeaderIds.map(
        (rowlist) => {
          if (rowlist.id === mainData.id) {
            rowlist = {
              ...rowlist,
              grnGvnDetails: row.map((rowObject, index) => {
                const addObj = {
                  ...rowObject,
                  totalQty:
                    +rowObject.primaryQty *
                      rowObject.product.uomConversionValue +
                    +rowObject.secQty,
                  totalAmtForPending: (
                    (+rowObject.primaryQty *
                      rowObject.product.uomConversionValue +
                      +rowObject.secQty) *
                    +rowObject.rate
                  ).toFixed(2),

                  qtyError:
                    +rowObject.primaryQty *
                      rowObject.product.uomConversionValue +
                      +rowObject.secQty >
                    +rowObject.secondaryQty
                      ? true
                      : false,
                  approvedQty:
                    +rowObject.primaryQty *
                      +rowObject.product.uomConversionValue +
                    +rowObject.secQty,
                  secondaryApprovedQty:
                    +rowObject.primaryQty *
                      +rowObject.product.uomConversionValue +
                    +rowObject.secQty,
                };
                return addObj;
              }),
            };
          }
          return rowlist;
        }
      );
      setGrnToCrnData({ row: listToSet });
    };
  render() {
    const { dynamicMasterData, fieldDataForModule, selectedGRN } = this.state;
    const { grnByStakeholderIdList } = this.props;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 0,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            getListById={this.getListById}
            dynamicMasterData={dynamicMasterData}
            showTitle={pendingGrnForCreditNoteJson.showTitle}
            screenTitle={"GRN For Credit Note"}
            fieldMeta={pendingGrnForCreditNoteJson.fieldMetaForGrnToCrn}
            apiBaseURL={pendingGrnForCreditNoteJson.apiBaseURL}
            showSaveBtn={true}
            showSaveBtnMain={false}
            saveBtnText={"search"}
            onSave={this.onSearchList}
            padding={true}
            paddingTop={true}
          />
          {grnByStakeholderIdList?.getGrnDataByGrnGvnHeaderIds?.length > 0 && (
            <>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <Box display="flex" alignItems="center">
                  <LabelCompo
                    className="text-black"
                    style={{
                      fontSize: 16,
                      fontWeight: "bold",
                      marginRight: 8,
                      color: blackColor,
                    }}
                    label="Select All GRN"
                  />
                  <Checkbox
                    checked={
                      selectedGRN.length ===
                        (grnByStakeholderIdList?.getGrnDataByGrnGvnHeaderIds
                          ?.length || 0) &&
                      grnByStakeholderIdList?.getGrnDataByGrnGvnHeaderIds
                        ?.length > 0
                    }
                    onChange={this.handleSelectAllChange}
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  overflowY: "auto",
                  maxHeight: "500px",
                  borderRadius: "10px",
                  "&::-webkit-scrollbar": { display: "none" },
                }}
              >
                <Grid container alignItems="center">
                  <Grid item xs={12} md={12} sm={12}>
                    {grnByStakeholderIdList?.getGrnDataByGrnGvnHeaderIds
                      ?.length > 0 ? (
                      <>
                        {grnByStakeholderIdList?.getGrnDataByGrnGvnHeaderIds?.map(
                          (dataRow, index) => (
                            <div
                              key={index}
                              style={{
                                marginBottom: "10px",
                                paddingBottom: "10px",
                              }}
                            >
                              <Box
                                sx={{
                                  position: "relative",
                                  padding: "1px",
                                  border: "2px solid #ddd",
                                  borderRadius: "20px",
                                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                                }}
                              >
                                <Box
                                  display="flex"
                                  justifyContent="flex-end"
                                  alignItems="center"
                                >
                                  {" "}
                                  <Checkbox
                                    checked={selectedGRN.some(
                                      (item) => item.id === dataRow.id
                                    )}
                                    onChange={() =>
                                      this.handleCheckboxChange(dataRow)
                                    }
                                  />
                                </Box>

                                <Grid container spacing={2} padding={2}>
                                  <Grid
                                    container
                                    spacing={2}
                                    alignItems="center"
                                    sx={{ marginLeft: 1 }}
                                  >
                                    <Grid
                                      item
                                      xs={12}
                                      md={6}
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <IconButton size="small" disabled>
                                        <DescriptionOutlined
                                          sx={{ color: blackColor }}
                                        />
                                      </IconButton>
                                      <LabelCompo
                                        className="text-black"
                                        style={{
                                          fontSize: 16,
                                          color: titleColor,
                                          marginRight: 8,
                                        }}
                                        label="GRN No:"
                                      />
                                      <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 16 }}
                                        label={dataRow?.grnGvnNo || "-"}
                                      />
                                    </Grid>

                                    <Grid
                                      item
                                      xs={12}
                                      md={6}
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <IconButton size="small" disabled>
                                        <CalendarTodayOutlined
                                          sx={{ color: blackColor }}
                                        />
                                      </IconButton>
                                      <LabelCompo
                                        className="text-black"
                                        style={{
                                          fontSize: 16,
                                          color: titleColor,
                                          marginRight: 8,
                                        }}
                                        label="GRN Date:"
                                      />
                                      <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 16 }}
                                        label={dataRow?.date || "-"}
                                      />
                                    </Grid>
                                  </Grid>

                                  <Grid
                                    container
                                    spacing={2}
                                    alignItems="center"
                                    sx={{ marginTop: 1, marginLeft: 1 }}
                                  >
                                    <Grid
                                      item
                                      xs={12}
                                      md={6}
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <IconButton size="small" disabled>
                                        <BusinessOutlined
                                          sx={{ color: blackColor }}
                                        />
                                      </IconButton>
                                      <LabelCompo
                                        className="text-black"
                                        style={{
                                          fontSize: 16,
                                          color: titleColor,
                                          marginRight: 8,
                                        }}
                                        label="Firm Name:"
                                      />
                                      <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 16 }}
                                        label={
                                          dataRow?.fromStockStakeHolderFirmName ||
                                          "-"
                                        }
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      md={6}
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <IconButton size="small" disabled>
                                        <PersonOutlineOutlined
                                          sx={{ color: blackColor }}
                                        />
                                      </IconButton>
                                      <LabelCompo
                                        style={{
                                          fontSize: 16,
                                          color: titleColor,
                                          marginRight: 8,
                                        }}
                                        label="Owner Name:"
                                      />
                                      <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 16 }}
                                        label={
                                          dataRow?.fromStockStakeHolderOwnerName ||
                                          "-"
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    container
                                    spacing={2}
                                    alignItems="center"
                                    sx={{ marginTop: 1, marginLeft: 1 }}
                                  >
                                    <Grid
                                      item
                                      xs={12}
                                      md={6}
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <IconButton size="small" disabled>
                                        <Inventory2Outlined
                                          sx={{ color: blackColor }}
                                        />
                                      </IconButton>
                                      <LabelCompo
                                        className="text-black"
                                        style={{
                                          fontSize: 16,
                                          color: titleColor,
                                          marginRight: 8,
                                        }}
                                        label="GRN Type:"
                                      />
                                      <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 16 }}
                                        label={
                                          dataRow?.grnGvnType === 0
                                            ? "Bill-Wise"
                                            : dataRow?.grnGvnType === 1
                                            ? "Category-Wise"
                                            : dataRow?.grnGvnType === 2
                                            ? "Claim Of Outlet Offer"
                                            : dataRow?.grnGvnType === 3
                                            ? "Claim Of Stock Holder Offer"
                                            : dataRow?.grnGvnType === 4
                                            ? "Primary Offer"
                                            : "Rate Difference GRN"
                                        }
                                      />
                                    </Grid>
                                    {dataRow?.grnGvnType === 2 && (
                                      <Grid
                                        item
                                        xs={12}
                                        md={6}
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <IconButton size="small" disabled>
                                          <StorefrontOutlined
                                            sx={{ color: blackColor }}
                                          />
                                        </IconButton>
                                        <LabelCompo
                                          style={{
                                            fontSize: 16,
                                            color: titleColor,
                                            marginRight: 8,
                                          }}
                                          label="Outlet CRN Type:"
                                        />
                                        <LabelCompo
                                          className="text-black"
                                          style={{ fontSize: 16 }}
                                          label={
                                            dataRow?.outletCrnType === 0
                                              ? "From Category-Wise"
                                              : dataRow?.outletCrnType === 1
                                              ? "From Bill-Wise"
                                              : dataRow?.outletCrnType === 2
                                              ? "From Offer"
                                              : dataRow?.outletCrnType === 3
                                              ? "From Expiry Product"
                                              : dataRow?.outletCrnType === 4
                                              ? "From Sampling"
                                              : dataRow?.outletCrnType === 5
                                              ? "Direct Credit Note"
                                              : "-"
                                          }
                                        />
                                      </Grid>
                                    )}
                                  </Grid>
                                </Grid>
                                <Grid
                                  container
                                  item
                                  xs={12}
                                  md={12}
                                  sm={12}
                                  sx={{ padding: 2 }}
                                >
                                  <CheckBoxTable
                                    tableHead={
                                      dataRow.grnGvnType == 0 ||
                                      dataRow.grnGvnType == 1
                                        ? [
                                            {
                                              title: "Sr.No.",
                                              name: "index",
                                              positionCenter: false,
                                              showInscreen: true,
                                              alignCenter: "center",
                                            },
                                            {
                                              title: "Product Name",
                                              name: "productName",
                                              positionCenter: false,
                                              showInscreen: true,
                                              alignCenter: "left",
                                            },
                                            {
                                              title: "UOM",
                                              name: "outletUomName",
                                              positionCenter: false,
                                              showInscreen: true,
                                              alignCenter: "center",
                                            },
                                            {
                                              title: "Rate",
                                              name: "rate",
                                              positionCenter: false,
                                              showInscreen: true,
                                              alignCenter: "right",
                                            },
                                            {
                                              title: "Quantity",
                                              name: "qtyForShow",
                                              positionCenter: false,
                                              showInscreen: true,
                                              alignCenter: "right",
                                            },
                                            {
                                              title: "Approve Primary Qty",
                                              name: "primaryQty",
                                              placeHolder:
                                                "Enter Approved Quantity",
                                              textFieldError:
                                                "approvedQtyError",
                                              error: "Approved Quantity",
                                              textField1: true,
                                              positionCenter: false,
                                              showInscreen: true,
                                              alignCenter: "left",
                                            },
                                            {
                                              title: "Approve Secondary Qty",
                                              name: "secQty",
                                              placeHolder:
                                                "Enter Approved Quantity",
                                              textFieldError:
                                                "approvedQtyError",
                                              error: "Approved Quantity",
                                              textField: true,
                                              positionCenter: false,
                                              showInscreen: true,
                                              alignCenter: "left",
                                            },
                                            {
                                              title:
                                                "Total Approved Sec Quantity",
                                              name: "totalQty",
                                              positionCenter: false,
                                              showInscreen: true,
                                              alignCenter: "right",
                                            },
                                            {
                                              title: "Total Amount",
                                              name: "totalAmtForPending",
                                              positionCenter: false,
                                              showInscreen: true,
                                              alignCenter: "right",
                                            },
                                          ]
                                        : [
                                            {
                                              title: "Sr.No.",
                                              name: "index",
                                              positionCenter: false,
                                              showInscreen: true,
                                              alignCenter: "center",
                                            },
                                            {
                                              title: "Product Name",
                                              name: "productName",
                                              positionCenter: false,
                                              showInscreen: true,
                                              alignCenter: "left",
                                            },
                                            {
                                              title: "UOM",
                                              name: "outletUomName",
                                              positionCenter: false,
                                              showInscreen: true,
                                              alignCenter: "center",
                                            },
                                            {
                                              title: "Tax %",
                                              name: "stakeHolderUomName",
                                              positionCenter: false,
                                              showInscreen: true,
                                              alignCenter: "center",
                                            },
                                            {
                                              title: "Taxable Amount",
                                              name: "taxableAmt",
                                              positionCenter: false,
                                              showInscreen: true,
                                              alignCenter: "right",
                                            },
                                            {
                                              title: "CGST Amount",
                                              name: "cgstAmt",
                                              positionCenter: false,
                                              showInscreen: true,
                                              alignCenter: "right",
                                            },
                                            {
                                              title: "SGST Amount",
                                              name: "sgstAmt",
                                              positionCenter: false,
                                              showInscreen: true,
                                              alignCenter: "right",
                                            },
                                            {
                                              title: "IGST Amount",
                                              name: "igstAmt",
                                              positionCenter: false,
                                              showInscreen: true,
                                              alignCenter: "right",
                                            },
                                            {
                                              title: "Total Amount",
                                              name: "totalAmt",
                                              positionCenter: false,
                                              showInscreen: true,
                                              alignCenter: "right",
                                            },
                                          ]
                                    }
                                    data={dataRow?.grnGvnDetails}
                                    rowList={dataRow?.grnGvnDetails}
                                    setList={this.setListWithHeader(dataRow)}
                                  />
                                </Grid>
                              </Box>
                            </div>
                          )
                        )}
                        {fieldDataForModule.editReject == 1 ? (
                          <Grid
                            item
                            sm={12}
                            xs={12}
                            md={12}
                            lg={12}
                            container
                            justifyContent="right"
                          >
                            <ButtonCompo
                              size="medium"
                              type="submit"
                              variant="contained"
                              name="Approve & Generate CRN"
                              onClick={this.onSubmit}
                            />
                          </Grid>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <Typography
                        variant="h6"
                        color="textSecondary"
                        sx={{ textAlign: "center" }}
                      >
                        No data available at the moment.
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  stakeholdersList: state.stackholder,
  grnByStakeholderIdList: state.requestGRN,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getStockStackHolders,
  getGRNByStakeholderIdForGrnToCrn,
  getGrnToCrn,
  setGrnToCrnData,
};
export default connect(mapStateToProps, mapDispatchToProps)(GRNToCreditNote);
