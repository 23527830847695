import { Grid, Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Button";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import CheckBoxSelectionTable from "../../components/Comman/RT/CheckBoxSelectionTable";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import { checkedListEmptyMsg, noInternetMsg, saveFailedMsg, saveWarningMsg, savemsg, serverMsg, updateData } from "../../config/messageconstant";
import { userAccessJson } from "../../DynamicFormsJson/MastersJSON/userAccess";
import { getStockHolderForAssignAccessRole, setStockHolderForAssignAccessRole } from "../../Slice/accessRole.slice";
import { getAreaByTaluka } from "../../Slice/area.slice";
import { getDistrictByZone } from "../../Slice/district.slice";
import { getRegion } from "../../Slice/region.slice";
import { getStateByRegion } from "../../Slice/state.slice";
import { getStockStakeHolderType } from "../../Slice/stockStakeHolderType.slice";
import { getTalukaByDistrict } from "../../Slice/taluka.slice";
import { getZoneByState } from "../../Slice/zone.slice";
import { apiGet, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import UserAccessAssignationPopup from "./UserAccessAssignationPopup";
import endpoint from "../../config/endpoints";

let stockHolderColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    align: "center",
  },
  {
    title: "Holder Name",
    name: "holderName",
    positionCenter: false,
    canSearch: true,
    align: "left",
  },
  {
    title: "Firm Name",
    name: "firmName",
    positionCenter: false,
    canSearch: true,
    align: "left",
    showInscreen: true,
  },
  {
    title: "Owner Name",
    name: "ownerName",
    positionCenter: false,
    canSearch: true,
    align: "left",
  },
  {
    title: "Area",
    name: "areaNames",
    positionCenter: false,
    canSearch: true,
    align: "left",
  },

];
class UserAccessAssignationForStockHolder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
      formData: {},
      moduleObjDataObj: {},
      isLog: true,
      dynamicMasterData: {
        regionDetail: this.props.regionList?.region,
        stateDetail: this.props.stateList?.stateByRegion,
        zoneDetail: this.props.zoneList?.zoneByState,
        districtDetail: this.props.districtList?.districtByZone,
        talukaDetail: this.props.talukaList?.talukaByDistrict,
        areaDetail: this.props.areaList?.areaByTaluka,
        stakeHolderTypeDetail:
          this.props.stakeHolderTypeList?.stockStakeHolderType,
      },
      showTableList: false,
      selection: [],
      DataToSet: [],
      openPopUp: false,
      stakeHolderId: "",
      stockStakeHolderTypeId: "",
      productStateList: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.regionList !== nextProps.regionList) {
      if (this.props.regionList?.region !== nextProps.regionList?.region) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            regionDetail: nextProps.regionList?.region,
          },
        });
      }
    }
    if (this.props.stateList !== nextProps.stateList) {
      if (
        this.props.stateList?.stateByRegion !==
        nextProps.stateList?.stateByRegion
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stateDetail: nextProps.stateList?.stateByRegion,
          },
        });
      }
    }
    if (this.props.zoneList !== nextProps.zoneList) {
      if (
        this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            zoneDetail: nextProps.zoneList?.zoneByState,
          },
        });
      }
    }
    if (this.props.districtList !== nextProps.districtList) {
      if (
        this.props.districtList?.districtByZone !==
        nextProps.districtList?.districtByZone
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            districtDetail: nextProps.districtList?.districtByZone,
          },
        });
      }
    }
    if (this.props.talukaList !== nextProps.talukaList) {
      if (
        this.props.talukaList?.talukaByDistrict !==
        nextProps.talukaList?.talukaByDistrict
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            talukaDetail: nextProps.talukaList?.talukaByDistrict,
          },
        });
      }
    }
    if (this.props.areaList !== nextProps.areaList) {
      if (
        this.props.areaList?.areaByTaluka !== nextProps.areaList?.areaByTaluka
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.areaByTaluka,
          },
        });
      }
    }
    if (this.props.stakeHolderTypeList !== nextProps.stakeHolderTypeList) {
      if (
        this.props.stakeHolderTypeList?.stockStakeHolderType !==
        nextProps.stakeHolderTypeList?.stockStakeHolderType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stakeHolderTypeDetail:
              nextProps.stakeHolderTypeList?.stockStakeHolderType,
          },
        });
      }
    }
  }
  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };
  async componentDidMount() {
    const { getRegion, getStockStakeHolderType, showLoador, showNotification } =
      this.props;

    if (navigator.onLine) {


      const moduleObjData = localStorage.getItem("moduleObj");
      const moduleObjDataObj = moduleObjData != null ? JSON.parse(moduleObjData) : {};

      this.setState({ moduleObjDataObj });

      if (this.props.params.id) {
        showLoador({ loador: true });
        await apiGet({
          url: userAccessJson.apiBaseURL + "/" + this.props.params.id,
        }).then(({ data, success }) => {
          console.log(data);
          showLoador({ loador: false });
          if (!success) {
          } else {
            this.setState({
              DataToSet: [
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Stock Holder Type",
                  value:
                    data.data.stockStakeHolderType !== null &&
                      data.data.stockStakeHolderType.name !== null
                      ? data.data.stockStakeHolderType.name
                      : "-",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Role Name",
                  value: data.data.roleName !== null ? data.data.roleName : "-",
                },
              ],
              stockStakeHolderTypeId: data.data.stockStakeHolderType.id,

            });
            showLoador({ loador: true });
            getStockStakeHolderType({
              userTypeIds: data.data.stockStakeHolderType.userType.id,
            }).then(({ success }) => {
              showLoador({ loador: false });
              if (!success) {
                showNotification({ msg: serverMsg, severity: "error" });
              }
            });
          }
        });
      }
      showLoador({ loador: true });
      await getRegion().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  getListById = async (data) => {
    const {
      getStateByRegion,
      getZoneByState,
      getDistrictByZone,
      getTalukaByDistrict,
      getAreaByTaluka,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      if (data.region && data.region.id) {
        showLoador({ loador: true });
        await getStateByRegion({ regionIds: data.region.id }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.state) {
        showLoador({ loador: true });
        await getZoneByState({ stateIds: data.state }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.zone) {
        showLoador({ loador: true });
        await getDistrictByZone({ zoneIds: data.zone }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.district) {
        showLoador({ loador: true });
        await getTalukaByDistrict({ districtIds: data.district }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.taluka) {
        showLoador({ loador: true });
        await getAreaByTaluka({ talukaIds: data.taluka }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onSearchList = async (data) => {
    const {
      getStockHolderForAssignAccessRole,
      stakeHolderTypeList,
      showLoador,
      showNotification,
    } = this.props;
    const { stockStakeHolderTypeId } = this.state;
    if (navigator.onLine) {
      let accessRoleId = stakeHolderTypeList?.stockStakeHolderType.filter(
        (row) => row.id == stockStakeHolderTypeId
      );
      showLoador({ loador: true });
      await getStockHolderForAssignAccessRole({
        accessRoleId: this.props.params.id,
        userType: accessRoleId[0].userType.id,
        stockStakeHolderTypeId: stockStakeHolderTypeId,
        areaIds: data.area,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  //   handledCheckedList = (productData) => {
  //     const { productList, setAllProductList } = this.props;
  //     let setList = [];
  //     productData.map((rowObject) => {
  //       setList.push(rowObject.product.id);
  //     });
  //     const rowList = productList.activeAllProduct.map((rowData) => {
  //       const filterData = productData.filter(
  //         (row) => rowData.id == row.product.id
  //       );

  //       return { ...rowData, checked: filterData.length != 0 ? true : false };
  //     });
  //     this.setState({
  //       selection: setList,
  //     });
  //     setAllProductList({ row: rowList });
  //   };

  getSelectedList = (e) => {
    this.setState({
      selection: e,
    });
    const rowList = this.props.stakeholderNameList?.stockHolderList.map((rowData) => {
      const filterData = e.filter((id) => rowData.id == id);
      return { ...rowData, checked: filterData.length != 0 ? true : false };
    });
    this.props.setStockHolderForAssignAccessRole({ row: rowList });
  };

  onApprove = () => {
    const { showNotification, stakeholderNameList } = this.props;
    const { selection } = this.state;
    if (selection.length === 0) {
      showNotification({
        msg: checkedListEmptyMsg,
        severity: "error",
      });
    } else {
      this.setState({ openPopUp: true });
      const rowList = stakeholderNameList.stockHolderList.map((rowData) => {
        const filterData = selection.filter((id) => rowData.id == id);
        return { ...rowData, checked: filterData.length != 0 ? true : false };
      });
      this.props.setStockHolderForAssignAccessRole({ row: rowList });
    }
  };
  handleCloses = () => {
    this.setState({ openPopUp: false });
  };

  saveAccessRole = () => {
    console.log(this.props.stakeholderNameList?.stockHolderList.filter((row) => row.checked));
    const { showNotification, showLoador, stakeHolderTypeList, stakeholderNameList } = this.props;
    const { stockStakeHolderTypeId, isLog, moduleObjDataObj } = this.state;
    swal({
      title: "Are you sure?",
      text: updateData,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (navigator.onLine) {
          let accessRoleId = stakeHolderTypeList?.stockStakeHolderType.filter(
            (row) => row.id == stockStakeHolderTypeId
          );
          const checkedIds = stakeholderNameList?.stockHolderList
            .filter((row) => row.checked)
            .map((row) => row.id);

          const checkedListData = stakeholderNameList?.stockHolderList.filter((row) => row.checked)

          showLoador({ loador: true });
          apiGet({
            url: endpoint.userAccess + "/assignation?accessRoleId=" + this.props.params.id + "&stockHolderSalesTeamIds=" + checkedIds + "&userType=" + accessRoleId[0].userType.id,
          }).then(({ data, success }) => {
            if (success) {

              if (isLog) {
                if (checkedListData.length !== 0) {

                  let updatedJsonToSend = JSON.stringify(checkedListData);

                  let dataToSaveForMLog = {
                    module: {
                      moduleId: moduleObjDataObj.moduleId
                    },
                    subModule: {
                      subModuleId: moduleObjDataObj.subModuleId
                    },
                    operationType: this.props.params.id ? 1 : 0,
                    type: 1,
                    operationPerformId: this.props.params.id,
                    updatedJson: updatedJsonToSend,
                  }

                  showLoador({ loador: true });
                  apiPost({
                    url: endpoint.mlog,
                    postBody: dataToSaveForMLog,
                  }).then(({ success }) => {
                    showLoador({ loador: false });
                    if (success) {
                    } else {
                      showNotification({
                        msg: saveFailedMsg,
                        severity: "error",
                      });
                    }
                  });
                }
              }


              this.handleCloses();
              showLoador({ loador: false });
              showNotification({
                msg: savemsg,
              });
              this.props.navigate(-1);
            } else {
              showNotification({
                msg: serverMsg,
                severity: "error",
              });
              showLoador({ loador: false });
            }
          });
        } else {
          showNotification({ msg: noInternetMsg, severity: "error" });
        }
      }
    });
  };

  render() {
    const {
      dynamicMasterData,
      selection,
      openPopUp,
      DataToSet,
      productStateList,
    } = this.state;
    const { productList, stakeholderNameList } = this.props;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <>
            <DynamicFormWithoutSave
              showBackToList={true}
              dynamicMasterData={dynamicMasterData}
              getListById={this.getListById}
              screenTitle={userAccessJson.screenTitle}
              fieldMeta={userAccessJson.fieldMeta}
              DataToSet={DataToSet}
              padding={true}
              paddingTop={true}
              saveBtnText={"Search"}
              onSave={this.onSearchList}
              showSaveBtnMain={false}
              showSaveBtn={true}
            />
            {/* <LandingScreenHeader
              screenTitle={""}
              showSearchBox={false}
              showTable={true}
              showFilter={false}
              showPdfDownload={false}
              showExcelDownload={false}
              onSearch={this.onSearch}
            /> */}
          </>

          <br />
          <CheckBoxSelectionTable
            selection={selection}
            onSelectionChange={this.getSelectedList}
            columns={stockHolderColumns}
            rows={stakeholderNameList?.stockHolderList}
            isActionColActive={false}
          />
          <br />

          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="right"
          >
            <ButtonCompo
              size="medium"
              type="submit"
              variant="contained"
              name={"Proceed"}
              onClick={this.onApprove}
            />
          </Grid>
        </Paper>
        {openPopUp && (
          <>
            <UserAccessAssignationPopup
              handleCloses={this.handleCloses}
              open={open}
              formData={{}}
              columns={[
                {
                  title: "Sr. No.",
                  name: "index",
                  positionCenter: true,
                  align: "center",
                  showInscreen: true,
                },
                {
                  title: "Stock Holder Name",
                  name: "holderName",
                  positionCenter: false,
                  canSearch: true,
                  align: "left",
                  showInscreen: true,
                },
                {
                  title: "Firm Name",
                  name: "firmName",
                  positionCenter: false,
                  canSearch: true,
                  align: "left",
                  showInscreen: true,
                },
              ]}
              onFormSave={this.saveAccessRole}
              rows={stakeholderNameList?.stockHolderList.filter((row) => row.checked)}
            />
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  regionList: state.region,
  stateList: state.states,
  zoneList: state.zone,
  districtList: state.district,
  talukaList: state.taluka,
  areaList: state.area,
  stakeHolderTypeList: state.stockStakeHolderType,
  stakeholderNameList: state.accessRole,
});
const mapDispatchToProps = {
  getAreaByTaluka,
  getDistrictByZone,
  getRegion,
  getStateByRegion,
  getTalukaByDistrict,
  getZoneByState,
  getStockStakeHolderType,
  getStockHolderForAssignAccessRole,
  showLoador,
  showNotification,
  setStockHolderForAssignAccessRole,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(UserAccessAssignationForStockHolder);
