import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import RTTable from "../../components/Comman/RT/RTTable";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import { getproductRateStructure } from "../../Slice/productRateStructure.slice";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { ProductRateStructureJson } from "../../DynamicFormsJson/Transaction/productRateStructure";
import { apiGet } from "../../utils/api_service";
import { getAllActiveProduct } from "../../Slice/product.slice";
import { getStockStakeHolderType } from "../../Slice/stockStakeHolderType.slice";
import endpoint from "../../config/endpoints";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";

class ProductRateStructureView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DataToSet: [],
      matrixDataList: [],
      searchValue: "",
    };
  }

  async componentDidMount() {
    const {
      getAllActiveProduct,
      getStockStakeHolderType,
      stakeHolderTypeList,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getAllActiveProduct().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          showLoador({ loador: true });
          getStockStakeHolderType({ userTypeIds: 2, reverseTrue: true }).then(({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            } else {
            }
          });
        }
      });
      if (this.props.params.id) {
        showLoador({ loador: true });
        apiGet({
          url: endpoint.productRateStructure + "/" + this.props.params.id,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (!success) {
          } else {
            this.matrixData(data.data);
            let rowData = data.data;
            this.setState({
              headerData: rowData,
              DataToSet: [
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Structure Name",
                  value: rowData.name !== null ? rowData.name : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Structure Date",
                  value:
                    rowData.structureDate !== null ? rowData.structureDate : "",
                },
              ],
            });
          }
        });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  matrixData = (data) => {
    const { productList, stakeHolderTypeList } = this.props;

    let newDataToSet = productList?.activeAllProduct.map((productObject) => {
      let filterData = data.productMrpHolderWiseRates.filter(
        (row) => row.product != null && row.product.id == productObject.id
      );
      if (filterData.length !== 0) {
        let matrixObject = {
          productId: productObject.id,
          brandName: productObject.group.brand.name,
          productName: productObject.name,
          categtoryName: productObject.group.subCategory.category.name,
          mrp: filterData[0].mrp,
          outletRate: filterData[0].outletRate,
          expiryRate: filterData[0].expiryRate !== null ? filterData[0].expiryRate : 0,
        };
        stakeHolderTypeList?.stockStakeHolderType.map(
          (stakeholderObejct, index) => {
            let stakeHolderData = filterData[0].dtoTypeWiseRates.filter(
              (row) => row.stockStakeHolderType.id == stakeholderObejct.id
            );
            if (stakeHolderData.length !== 0) {
              matrixObject = {
                ...matrixObject,
                [stakeholderObejct.name.replaceAll(" ", "").toLowerCase()]:
                  stakeHolderData[0].rate,
                [stakeholderObejct.name.replaceAll(" ", "").toLowerCase() +
                  "Id"]: stakeholderObejct.id,
                [index]: stakeHolderData[0].rate,
              };
              return matrixObject;
            } else {
              matrixObject = {
                ...matrixObject,
                [stakeholderObejct.name.replaceAll(" ", "").toLowerCase()]: 0,
                [stakeholderObejct.name.replaceAll(" ", "").toLowerCase() +
                  "Id"]: stakeholderObejct.id,
                [index]: 0,
              };
              return matrixObject;
            }
          }
        );
        return matrixObject;
      } else {
        let matrixNewObject = {
          productId: productObject.id,
          brandName: productObject.group.brand.name,
          productName: productObject.name,
          categtoryName: productObject.group.subCategory.category.name,
          mrp: 0,
          outletRate: 0,
          expiryRate: 0,
        };
        stakeHolderTypeList?.stockStakeHolderType.map(
          (stakeholderObejct, index) => {
            matrixNewObject = {
              ...matrixNewObject,
              [stakeholderObejct.name.replaceAll(" ", "").toLowerCase()]: 0,
              [stakeholderObejct.name.replaceAll(" ", "").toLowerCase() + "Id"]:
                stakeholderObejct.id,
              [index]: 0,
            };
            return matrixNewObject;
          }
        );
        return matrixNewObject;
      }
    });
    this.setState({
      matrixDataList: newDataToSet,
    });
  };

  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  getFilteredTableData = (searchData) => {
    const { searchValue } = this.state;
    return searchData.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["brandName"] &&
          currentRow["brandName"]
            .toString()
            .toLowerCase()
            .includes(searchValue.trim().toLowerCase())) ||
        (currentRow["productName"] &&
          currentRow["productName"]
            .toString()
            .toLowerCase()
            .includes(searchValue.trim().toLowerCase())) ||
        (currentRow["categtoryName"] &&
          currentRow["categtoryName"]
            .toString()
            .toLowerCase()
            .includes(searchValue.trim().toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
    });
  };

  render() {
    const { dynamicMasterData, DataToSet, matrixDataList } = this.state;
    const { productList, stakeHolderTypeList } = this.props;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 0,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            showBackToList={true}
            DataToSet={DataToSet}
            dynamicMasterData={dynamicMasterData}
            showSaveBtn={false}
            showTitle={true}
            screenTitle={"Product Rate Structure View"}
            fieldMeta={[]}
            showCancel={ProductRateStructureJson.showCancel}
            apiBaseURL={ProductRateStructureJson.apiBaseURL}
            showSaveNextBtn={false}
            saveBtnText={false}
            showSaveBtnMain={false}
            padding={true}
            paddingTop={true}
          />
          <br />
          <LandingScreenHeader
            screenTitle={""}
            showSearchBox={true}
            showTable={true}
            showFilter={false}
            showPdfDownload={false}
            showExcelDownload={false}
            onSearch={this.onSearch}
          />
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{ minWidth: "70px" }}>
                    Sr. No.
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: "150px" }}>
                    Brand Name
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: "150px" }}>
                    Product Name
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: "150px" }}>
                    Category Name
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: "150px" }}>
                    MRP Rate
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: "150px" }}>
                    Outlet Rate
                  </TableCell>
                  {stakeHolderTypeList?.stockStakeHolderType.map(
                    (stakeHolderObject, index) => {
                      return (
                        <TableCell align="center" style={{ minWidth: "150px" }}>
                          {stakeHolderObject.name}
                        </TableCell>
                      );
                    }
                  )}

                  {localStorage.getItem("radioPurchasereturnAndExpiryForRatestructure") &&
                    localStorage.getItem("radioPurchasereturnAndExpiryForRatestructure") == 1 ?
                    (<>
                      <TableCell align="center" style={{ minWidth: "150px" }}>
                        Expiry Rate
                      </TableCell>
                    </>) : (null)
                  }

                </TableRow>
              </TableHead>
              <TableBody>
                {this.getFilteredTableData(matrixDataList).map((productObject, indexRow) => {
                  return (
                    <TableRow>
                      <TableCell align="center" component="td" scope="row">
                        {indexRow + 1}
                      </TableCell>
                      <TableCell align="left" component="td" scope="row">
                        {productObject.brandName}
                      </TableCell>
                      <TableCell align="left" component="td" scope="row">
                        {productObject.productName}
                      </TableCell>
                      <TableCell align="left" component="td" scope="row">
                        {productObject.categtoryName}
                      </TableCell>
                      <TableCell align="right" component="td" scope="row">
                        {productObject.mrp}
                      </TableCell>
                      <TableCell align="right" component="td" scope="row">
                        {productObject.outletRate}
                      </TableCell>
                      {stakeHolderTypeList?.stockStakeHolderType.map(
                        (stakeHolderObject, index) => {
                          return (
                            <TableCell
                              align="right"
                              style={{ minWidth: "150px" }}
                            >
                              {[productObject[index]]}
                            </TableCell>
                          );
                        }
                      )}

                      {localStorage.getItem("radioPurchasereturnAndExpiryForRatestructure") &&
                        localStorage.getItem("radioPurchasereturnAndExpiryForRatestructure") == 1 ?
                        (<>
                          <TableCell align="right" component="td" scope="row">
                            {productObject.expiryRate}
                          </TableCell>
                        </>) : (null)
                      }

                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  multipleStockList: state.manageStockByDate,
  stakeHolderTypeList: state.stockStakeHolderType,
  productList: state.product,
});
const mapDispatchToProps = {
  getAllActiveProduct,
  getStockStakeHolderType,
  showNotification,
  showLoador,
  getproductRateStructure,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ProductRateStructureView);
