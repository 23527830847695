import Paper from "@mui/material/Paper";
import React from "react";
import { connect } from "react-redux";
import { ClaimStructureAllotmentJson } from "../../../DynamicFormsJson/MastersJSON/claimStructureAllotmentJson";

import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import {
    checkedListEmptyMsg,
    noInternetMsg,
    saveFailedMsg,
    saveWarningMsg,
    savemsg,
    serverMsg
} from "../../../config/messageconstant";

import { getAreaByTalukaPostBodyUpdated, getAreaByUserType } from "../../../Slice/area.slice";
import { getActiveClaimStructure } from "../../../Slice/claim.slice";
import { getDistrictByZone } from "../../../Slice/district.slice";
import { getRegionNew } from "../../../Slice/region.slice";
import { getSalesPersonByAreaAndStockHolderTypeForClaimStructure, setSalesPersonByAreaAndStockHolderTypeForClaimStructure } from "../../../Slice/salesExecutive.slice";
import { getStateByRegion } from "../../../Slice/state.slice";
import { getStockStakeHolderTypeForList } from "../../../Slice/stockStakeHolderType.slice";
import { getTalukaByDistrict } from "../../../Slice/taluka.slice";
import { getZoneByState } from "../../../Slice/zone.slice";
import CheckBoxSelectionTable from "../../../components/Comman/RT/CheckBoxSelectionTable";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import { apiGet, apiPost } from "../../../utils/api_service";
import endpoint from "../../../config/endpoints";
import { Grid } from "@mui/material";
import { ButtonCompo } from "../../../components/Comman/Button";
import { WifiTetheringErrorRoundedTwoTone } from "@mui/icons-material";
import DynamicFormWithoutSave from "../../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../../components/withRouter";
import { compose } from "recompose";

let columns = [
    {
        title: "Sr.No.",
        name: "index",
        positionCenter: true,
        showInscreen: true,
        canSearch: true,
        alignCenter: "center",
        align: "center",
    },
    {
        title: " Name",
        name: "name",
        positionCenter: true,
        showInscreen: true,
        canSearch: true,
        alignCenter: "left",
    },
    {
        title: "Area",
        name: "areaNames",
        positionCenter: true,
        showInscreen: true,
        canSearch: true,
        alignCenter: "left",
    },
];


class ClaimStructureAllotmentList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicMasterData: {
                regionDetail: this.props.regionList?.region,
                stateDetail: this.props.stateList?.stateByRegion,
                zoneDetail: this.props.zoneList?.zoneByState,
                districtDetail: this.props.districtList?.districtByZone,
                talukaDetail: this.props.talukaList?.talukaByDistrict,
                areaDetail: this.props.areaList?.areaByTaluka,
            },
            showCheckRecord: false,
            selection: [],
            claimStructure: "",
        };
    }

    async componentDidMount() {
        const { getActiveClaimStructure, setSalesPersonByAreaAndStockHolderTypeForClaimStructure, getStockStakeHolderTypeForList, showLoador, showNotification, getRegionNew } = this.props;
        if (navigator.onLine) {


            setSalesPersonByAreaAndStockHolderTypeForClaimStructure({ row: [] });

            showLoador({ loador: true });
            await getRegionNew().then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });


            showLoador({ loador: true });
            await getStockStakeHolderTypeForList().then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });

            showLoador({ loador: true });
            await getActiveClaimStructure().then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });

            if (this.props.params.id) {
                this.setState({ claimStructure: this.props.params.id });
            }

        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.regionList !== nextProps.regionList) {
            if (this.props.regionList?.region !== nextProps.regionList?.region) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        regionDetail: nextProps.regionList?.region,
                    },
                });
            }
        }

        if (this.props.stateList !== nextProps.stateList) {
            if (
                this.props.stateList?.stateByRegion !==
                nextProps.stateList?.stateByRegion
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        stateDetail: nextProps.stateList?.stateByRegion,
                    },
                });
            }
        }

        if (this.props.zoneList !== nextProps.zoneList) {
            if (
                this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        zoneDetail: nextProps.zoneList?.zoneByState,
                    },
                });
            }
        }

        if (this.props.districtList !== nextProps.districtList) {
            if (
                this.props.districtList?.districtByZone !==
                nextProps.districtList?.districtByZone
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        districtDetail: nextProps.districtList?.districtByZone,
                    },
                });
            }
        }

        if (this.props.talukaList !== nextProps.talukaList) {
            if (
                this.props.talukaList?.talukaByDistrict !==
                nextProps.talukaList?.talukaByDistrict
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        talukaDetail: nextProps.talukaList?.talukaByDistrict,
                    },
                });
            }
        }

        if (this.props.areaList !== nextProps.areaList) {
            if (
                this.props.areaList?.areaByTaluka !== nextProps.areaList?.areaByTaluka
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        areaDetail: nextProps.areaList?.areaByTaluka,
                    },
                });
            }
        }

        if (this.props.areaList !== nextProps.areaList) {
            if (this.props.areaList?.area !== nextProps.areaList?.area) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        areaUserTypeDetail: nextProps.areaList?.area,
                    },
                });
            }
        }

        if (this.props.stockStakeHolderType !== nextProps.stockStakeHolderType) {
            if (this.props.stockStakeHolderType?.stockStakeHolderTypeList !== nextProps.stockStakeHolderType?.stockStakeHolderTypeList) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        stockHolderTypeDetail: nextProps.stockStakeHolderType?.stockStakeHolderTypeList,
                    },
                });
            }
        }

        if (this.props.claimStructureList !== nextProps.claimStructureList) {
            if (this.props.claimStructureList?.claimStructure !== nextProps.claimStructureList?.claimStructure) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        claimStructureDetail: nextProps.claimStructureList?.claimStructure,
                    },
                });
            }
        }

    }
    getListById = async (data) => {
        const {
            getStateByRegion,
            getZoneByState,
            getDistrictByZone,
            getTalukaByDistrict,
            getAreaByTalukaPostBodyUpdated,
            showLoador,
            showNotification,
        } = this.props;
        if (navigator.onLine) {
            if (data.region && data.region != "") {
                showLoador({ loador: true });
                await getStateByRegion({ regionIds: data.region }).then(
                    ({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    }
                );
            }
            if (data.state && data.state != "") {
                showLoador({ loador: true });
                await getZoneByState({ stateIds: data.state }).then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }
            if (data.zone && data.zone != "") {
                showLoador({ loador: true });
                await getDistrictByZone({ zoneIds: data.zone }).then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }
            if (data.district && data.district != "") {
                showLoador({ loador: true });
                await getTalukaByDistrict({ districtIds: data.district }).then(
                    ({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    }
                );
            }
            if (data.taluka && data.taluka != "") {
                let talukaIdsByPost = {
                    talukaIds: data.taluka,
                };
                showLoador({ loador: true });
                await getAreaByTalukaPostBodyUpdated({ talukaIds: talukaIdsByPost }).then(
                    ({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    }
                );
            }
        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };


    onSearchList = (data) => {


        const { getSalesPersonByAreaAndStockHolderTypeForClaimStructure, showLoador, showNotification } =
            this.props;
        if (navigator.onLine) {

            let claimStructure = this.props.params.id ? this.props.params.id : data.claimStructure;


            this.setState({
                claimStructure: claimStructure,
            });

            let dataJson = {
                stockStakeHolderTypeId: data.stockHolderType,
                areaIds: data.area.split(","),
            };

            showLoador({ loador: true });
            getSalesPersonByAreaAndStockHolderTypeForClaimStructure({
                claimStructureHeaderId: claimStructure,
                dataJson: dataJson,
            }).then(({ response, success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };


    getSelectedList = (e) => {
        this.setState({
            selection: e,
        });
        const rowList = this.props.salesPersonList.salesPersonByAreaAndStockHolderTypeForClaimStructure.map(
            (rowData) => {
                const filterData = e.filter((id) => rowData.id == id);
                return {
                    ...rowData,
                    checked: filterData.length != 0 ? true : false,
                };
            }
        );
        this.props.setSalesPersonByAreaAndStockHolderTypeForClaimStructure({ row: rowList });
    };

    onSubmit = () => {
        const { selection, claimStructure } = this.state;
        const { showLoador, showNotification } = this.props;

        if (selection.length === 0) {
            showNotification({ msg: checkedListEmptyMsg, severity: "error" });
        } else {
            this.onSave();
        }

    }


    onSave = () => {
        const { selection, claimStructure } = this.state;
        const { showLoador, showNotification } = this.props;


        let saveJsonData = {
            salesTeamIds: selection,
            claimStructureHeaderId: claimStructure,
        }

        console.log(saveJsonData);

        swal({
            title: "Are you sure?",
            text: saveWarningMsg,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                showLoador({ loador: true });
                apiPost({
                    url: endpoint.claimStructureAllotment + "/save-by-sales-teams",
                    postBody: saveJsonData,
                }).then(({ data, success }) => {
                    showLoador({ loador: false });
                    if (success) {
                        showNotification({ msg: savemsg });
                        if (this.props.params.id) {
                            this.props.navigate(-1);
                        } else {
                            window.location.reload();
                        }
                    } else {
                        showNotification({ msg: saveFailedMsg, severity: "error" });
                    }
                });
            }
        });

    }

    render() {
        const {
            dynamicMasterData,
            selection,
            billwithOffer,
            offerBillData,
            billDate,
            flag,
            formData,
            searchBy,
            fieldData,
            openProductList,
            stockProductData,
            isLostOrder,
            isLostOrderFlag,
        } = this.state;
        const { salesPersonList } = this.props;
        return (
            <>
                <Paper
                    sx={{
                        p: 2,
                        borderRadius: 2,
                    }}
                    elevation={0}
                >
                    <DynamicFormWithoutSave
                        callApi={false}
                        showBackToList={this.props.params.id ? true : false}
                        showSearchBox={false}
                        showPdfDownload={false}
                        showExcelDownload={false}
                        showAddButton={false}
                        getListById={this.getListById}
                        dynamicMasterData={dynamicMasterData}
                        showTitle={ClaimStructureAllotmentJson.showTitle}
                        screenTitle={ClaimStructureAllotmentJson.screenTitle}
                        fieldMeta={this.props.params.id ?
                            ClaimStructureAllotmentJson.fieldMetaForGetById :
                            ClaimStructureAllotmentJson.fieldMeta}
                        showSaveNextBtn={false}
                        showSaveBtnMain={false}
                        showSaveBtn={true}
                        saveBtnText={"Search"}
                        onSave={this.onSearchList}
                        padding={true}
                        paddingTop={true}
                    />

                    <CheckBoxSelectionTable
                        selection={selection}
                        onSelectionChange={this.getSelectedList}
                        columns={columns}
                        rows={salesPersonList.salesPersonByAreaAndStockHolderTypeForClaimStructure}
                        isActionColActive={false}
                    />
                    <br />
                    {salesPersonList.salesPersonByAreaAndStockHolderTypeForClaimStructure.length !== 0 && (
                        <>
                            <Grid
                                item
                                sm={12}
                                xs={12}
                                md={12}
                                lg={12}
                                container
                                justifyContent="right"
                            >
                                <ButtonCompo
                                    size="medium"
                                    type="button"
                                    variant="contained"
                                    name="Submit"
                                    onClick={this.onSubmit}
                                />
                            </Grid>
                        </>
                    )
                    }
                </Paper>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    areaList: state.area,
    regionList: state.region,
    stateList: state.states,
    zoneList: state.zone,
    districtList: state.district,
    talukaList: state.taluka,
    claimStructureList: state.claim,
    stockStakeHolderType: state.stockStakeHolderType,
    salesPersonList: state.salesExecutive,
});
const mapDispatchToProps = {
    showNotification,
    showLoador,
    getRegionNew,
    getStateByRegion,
    getDistrictByZone,
    getTalukaByDistrict,
    getZoneByState,
    getAreaByTalukaPostBodyUpdated,
    getAreaByUserType,
    getActiveClaimStructure,
    getStockStakeHolderTypeForList,
    getSalesPersonByAreaAndStockHolderTypeForClaimStructure,
    setSalesPersonByAreaAndStockHolderTypeForClaimStructure,
};
export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ClaimStructureAllotmentList);
