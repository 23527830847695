import { Box, Paper, Tab, Tabs, Typography } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { AreaMasterJson } from "../../../DynamicFormsJson/MastersJSON/areaMasterJson";
import { getDistrictByZone } from "../../../Slice/district.slice";
import { getRegionNew } from "../../../Slice/region.slice";
import { getStateByRegion } from "../../../Slice/state.slice";
import { getTalukaByDistrict, getTalukaUpdated } from "../../../Slice/taluka.slice";
import { getZoneByState } from "../../../Slice/zone.slice";
import PropTypes from "prop-types";
import SwipeableViews from 'react-swipeable-views';
import {
    setBeatBySalesTeamIdAreaId,
} from "../../../Slice/beat.slice";
import LandingScreenHeader from "../../../components/Comman/LandingScreenHeader";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import { withRouter } from "../../../components/withRouter";
import endpoint from "../../../config/endpoints";
import { beatsAssign, noInternetMsg, saveFailedMsg, serverMsg, updateMsg } from "../../../config/messageconstant";
import { apiPost } from "../../../utils/api_service";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import AssignAreaToManager from "./AssignAreaToManager";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

class AreaMasterForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 0,

            dynamicMasterData: {
                regionDetail: this.props.regionList?.region,
                stateDetail: this.props.stateList?.stateByRegion,
                zoneDetail: this.props.zoneList?.zoneByRegionId,
                districtDetail: this.props.districtList?.districtByZone,
                talukaDetail: this.props.talukaList?.talukaByDistrict,
            },
            stockStakeHolderTypeId: "",
            formData: {},
            rowData: {},
        };
    }
    a11yProps(index) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }
    handledChange = (e, newValue) => {
        this.setState({
            tabValue: newValue,
        });
    };

    componentWillReceiveProps(nextProps) {
        if (this.props.regionList !== nextProps.regionList) {
            if (this.props.regionList?.region !== nextProps.regionList?.region) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        regionDetail: nextProps.regionList?.region,
                    },
                });
            }
        }


        if (this.props.stateList !== nextProps.stateList) {
            if (this.props.stateList?.stateByRegion !== nextProps.stateList?.stateByRegion) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        stateDetail: nextProps.stateList?.stateByRegion,
                    },
                });
            }
        }

        if (this.props.zoneList !== nextProps.zoneList) {
            if (this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        zoneDetail: nextProps.zoneList?.zoneByState,
                    },
                });
            }
        }

        if (this.props.districtList !== nextProps.districtList) {
            if (this.props.districtList?.districtByZone !== nextProps.districtList?.districtByZone) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        districtDetail: nextProps.districtList?.districtByZone,
                    },
                });
            }
        }

        if (this.props.talukaList !== nextProps.talukaList) {
            if (this.props.talukaList?.talukaByDistrict !== nextProps.talukaList?.talukaByDistrict) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        talukaDetail: nextProps.talukaList?.talukaByDistrict,
                    },
                });
            }
        }

        if (this.props.talukaList !== nextProps.talukaList) {
            if (this.props.talukaList?.taluka !== nextProps.talukaList?.taluka) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        talukaDetail: nextProps.talukaList?.taluka,
                    },
                });
            }
        }

    }

    async componentDidMount() {
        const { getRegionNew, getTalukaUpdated, showLoador, showNotification } =
            this.props;
        if (navigator.onLine) {

            showLoador({ loador: true });
            await getRegionNew().then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });

            showLoador({ loador: true });
            await getTalukaUpdated().then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });

        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }

    getListById = async (data) => {
        const {
            getZoneByState,
            getDistrictByZone,
            getTalukaByDistrict,
            getStateByRegion,
            showLoador,
            showNotification } =
            this.props;
        if (navigator.onLine) {

            if (data.region != "" && data.region) {
                showLoador({ loador: true });
                await getStateByRegion({ regionIds: data.region }).then(
                    ({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    }
                );
            }

            if (data.stateId && data.stateId != "") {
                showLoador({ loador: true });
                await getZoneByState({ stateIds: data.stateId }).then(
                    ({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    }
                );
            }


            if (data.zoneIds && data.zoneIds != "") {
                showLoador({ loador: true });
                await getDistrictByZone({ zoneIds: data.zoneIds }).then(
                    ({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    }
                );
            }

            if (data.districtIds && data.districtIds != "") {
                showLoador({ loador: true });
                await getTalukaByDistrict({ districtIds: data.districtIds }).then(
                    ({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    }
                );
            }




        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };
    onCancel = () => {
        this.props.navigate(-1);
    };
    getFormData = (rowData) => {

        this.setState({ rowData: rowData })
        this.props.navigate(AreaMasterJson.formPath + "/" + rowData.data.id)
        this.setState({
            tabValue: 1,
        });

    };

    setDataToForm = (data) => {
        this.setState({ formData: data })
    };

    render() {
        const { dynamicMasterData, tabValue, formData, rowData } = this.state;
        return (
            <>
                <Paper
                    sx={{
                        p: 2,
                        pt: 2,
                        borderRadius: 2,
                    }}
                    elevation={0}
                >

                    <LandingScreenHeader
                        screenTitle={AreaMasterJson.screenTitle}
                        showTable={false}
                        showBackButton={true}
                        showAddButton={false}
                        onCancel={this.onCancel}
                    />
                    <Tabs
                        onChange={this.handledChange}
                        value={tabValue}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="full width tabs example">

                        <Tab label={"Form "} {...this.a11yProps(0)} />

                        <Tab label={"Assign Area"} {...this.a11yProps(1)} />

                    </Tabs>
                    <SwipeableViews

                        index={tabValue}
                        onChangeIndex={this.handleChange}
                    >

                        <TabPanel value={tabValue} index={0}>
                            <DynamicForm
                                padding={false}
                                paddingTop={false}
                                formPath={AreaMasterJson.formPath}
                                getListById={this.getListById}
                                screenTitle={AreaMasterJson.screenTitle}
                                fieldMeta={AreaMasterJson.fieldMeta}
                                dynamicMasterData={dynamicMasterData}
                                apiBaseURL={AreaMasterJson.apiBaseURL}
                                showSaveNextBtn={AreaMasterJson.showSaveNextBtn}
                                isEditURL={AreaMasterJson.isEditURL}
                                showTitleAndBack={false}
                                showSaveBtn={false}
                                getFormData={this.getFormData}
                                getFormDataStatic={true}
                                getDataForm={true}
                                setDataToForm={this.setDataToForm}
                                isLog={true}
                                type={1}
                            />
                        </TabPanel>

                        <TabPanel value={tabValue} index={1}>
                            <AssignAreaToManager
                                padding={false}
                                paddingTop={false}
                                formPath={AreaMasterJson.formPath}
                                getListById={this.getListById}
                                screenTitle={AreaMasterJson.screenTitle}
                                fieldMeta={AreaMasterJson.fieldMeta}
                                dynamicMasterData={dynamicMasterData}
                                apiBaseURL={AreaMasterJson.apiBaseURL}
                                showSaveNextBtn={AreaMasterJson.showSaveNextBtn}
                                isEditURL={AreaMasterJson.isEditURL}
                                showTitleAndBack={false}
                                rowData={rowData}
                            />
                        </TabPanel>


                    </SwipeableViews>

                </Paper>

            </>
        );
    }
}
const mapStateToProps = (state) => ({
    stockStakeHolderTypeList: state.stockStakeHolderType,
    salesExecutiveList: state.salesExecutive,
    regionList: state.region,
    zoneList: state.zone,
    areaList: state.area,
    districtList: state.district,
    talukaList: state.taluka,
    stateList: state.states,
});

const mapDispatchToProps = {
    getRegionNew,
    getZoneByState,
    showLoador,
    showNotification,
    setBeatBySalesTeamIdAreaId,
    getDistrictByZone,
    getTalukaByDistrict,
    getStateByRegion,
    getTalukaUpdated,
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(AreaMasterForm);
