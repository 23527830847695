import { useEffect, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";

// third-party
import ReactApexChart from "react-apexcharts";
import React from "react";

class PieChart extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { options, type = "pie", series, width } = this.props;
    return (
      <ReactApexChart
        options={options}
        series={series}
        type={type}
        width={width ? width : 600}
      />
    );
  }
}

export default PieChart;
