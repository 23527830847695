import React from "react";
import { connect } from "react-redux";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";

import {
  DialogActions,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import { ButtonCompo } from "../../components/Comman/Button";
import CheckBoxSelectionTable from "../../components/Comman/RT/CheckBoxSelectionTable";
import { withRouter } from "../../components/withRouter";
import endpoint from "../../config/endpoints";
import {
  checkedListEmptyMsg,
  noInternetMsg,
  saveFailedMsg,
  savemsg,
  saveWarningMsg,
  serverMsg,
} from "../../config/messageconstant";
import { RUAssignJSON } from "../../DynamicFormsJson/Transaction/ruAssign";
import { getAreaByTalukaPostBodyUpdated } from "../../Slice/area.slice";
import {
  saveDistributorInquiry,
  setSaveDistributorInquiry,
} from "../../Slice/distributorInquiry.slice";
import { getDistrictByZone } from "../../Slice/district.slice";
import { getRegionNew } from "../../Slice/region.slice";
import {
  getSalesTeamByArea,
  getSalesTeamByStakeholderType,
  setSalesTeamByStakeholder,
} from "../../Slice/ruCreation.slice";
import { getStateByRegion } from "../../Slice/state.slice";
import { getStockStakeHolderType } from "../../Slice/stockStakeHolderType.slice";
import { getTalukaByDistrictPost } from "../../Slice/taluka.slice";
import { getZoneByState } from "../../Slice/zone.slice";
import { apiGet, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
const columns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: false,
    showInscreen: true,
    alignCell: "center",
    align: "center",
  },
  {
    title: "Name",
    name: "name",
    positionCenter: false,
    showInscreen: true,
    alignCell: "center",
    alignCenter: "left",
    canSearch: true,
  },
  {
    title: "Type",
    name: "type",
    positionCenter: false,
    showInscreen: true,
    alignCell: "center",
    alignCenter: "left",
    canSearch: true,
  },
  {
    title: "Area name",
    name: "areaName",
    positionCenter: false,
    showInscreen: true,
    alignCell: "center",
    alignCenter: "left",
    canSearch: true,
  },
];
class AssignationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterType: "1",
      setList: [],
      dynamicMasterData: {
        regionDetail: this.props.regionList?.regionNew,
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.regionList !== nextProps.regionList) {
      if (
        this.props.regionList?.regionNew !== nextProps.regionList?.regionNew
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            regionDetail: nextProps.regionList?.regionNew,
          },
        });
      }
    }
    if (this.props.stateList !== nextProps.stateList) {
      if (
        this.props.stateList?.stateByRegion !==
        nextProps.stateList?.stateByRegion
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stateDetail: nextProps.stateList?.stateByRegion,
          },
        });
      }
    }
    if (this.props.zoneList !== nextProps.zoneList) {
      if (
        this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            zoneDetail: nextProps.zoneList?.zoneByState,
          },
        });
      }
    }
    if (this.props.districtList !== nextProps.districtList) {
      if (
        this.props.districtList?.districtByZone !==
        nextProps.districtList?.districtByZone
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            districtDetail: nextProps.districtList?.districtByZone,
          },
        });
      }
    }
    if (this.props.talukaList !== nextProps.talukaList) {
      if (
        this.props.talukaList?.talukaByDistrict !==
        nextProps.talukaList?.talukaByDistrict
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            talukaDetail: nextProps.talukaList?.talukaByDistrict,
          },
        });
      }
    }

    if (this.props.stakeHolderTypeList !== nextProps.stakeHolderTypeList) {
      if (
        this.props.stakeHolderTypeList?.stockStakeHolderType !==
        nextProps.stakeHolderTypeList?.stockStakeHolderType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stakeHolderTypeDetail:
              nextProps.stakeHolderTypeList?.stockStakeHolderType,
          },
        });
      }
    }

    if (this.props.areaList !== nextProps.areaList) {
      if (
        this.props.areaList?.areaByTaluka !== nextProps.areaList?.areaByTaluka
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.areaByTaluka,
          },
        });
      }
    }
  }

  async componentDidMount() {
    const {
      getRegionNew,
      getStockStakeHolderType,
      showLoador,
      showNotification,
    } = this.props;

    if (navigator.onLine) {
      await getRegionNew().then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

      await getStockStakeHolderType({ userTypeIds: "3,4" }).then(
        ({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        }
      );
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  getDataById = (id) => {
    const { showLoador, showNotification, setSaveDistributorInquiry } =
      this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      return apiGet({
        url: endpoint.distributorInquiry + "/" + id,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          if (!data.error) {
            let rowData = data.data;
            setSaveDistributorInquiry({ rowObject: rowData });
            return {
              data: rowData,
              success,
            };
          } else {
            showNotification({ msg: serverMsg, severity: "error" });
          }
          return { data: {}, success: false };
        }
        return { response: {}, success: false };
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  getListById = async (data) => {
    const {
      getStateByRegion,
      getZoneByState,
      getDistrictByZone,
      getTalukaByDistrictPost,
      getAreaByTalukaPostBodyUpdated,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      if (data.currentRegionId && data.currentRegionId != "") {
        showLoador({ loador: true });
        await getStateByRegion({ regionIds: data.currentRegionId }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.currentStateId && data.currentStateId != "") {
        showLoador({ loador: true });
        await getZoneByState({ stateIds: data.currentStateId }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.currentZoneIds && data.currentZoneIds != "") {
        showLoador({ loador: true });
        await getDistrictByZone({ zoneIds: data.currentZoneIds }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.currentDistrictIds && data.currentDistrictIds != "") {
        let districtIdJson = {
          districtIds:
            typeof data.currentDistrictIds === "string"
              ? data.currentDistrictIds.split(",")
              : data.currentDistrictIds,
        };

        showLoador({ loador: true });
        await getTalukaByDistrictPost({ districtIds: districtIdJson }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.currentTalukaIds && data.currentTalukaIds != "") {
        let talukaJson = {
          talukaIds:
            typeof data.currentTalukaIds === "string"
              ? data.currentTalukaIds.split(",")
              : data.currentTalukaIds,
        };
        showLoador({ loador: true });
        await getAreaByTalukaPostBodyUpdated({ talukaIds: talukaJson }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  onSave = (dataToSave) => {
    const {
      showLoador,
      showNotification,
      getSalesTeamByStakeholderType,
      getSalesTeamByArea,
    } = this.props;
    const { filterType } = this.state;
    if (navigator.onLine) {
      console.debug(filterType);

      showLoador({ loador: true });
      if (filterType === "1") {
        const stakeholderIdsList = dataToSave.stakeholderIds.split(",");
        getSalesTeamByStakeholderType({
          stakeholderType: { stockStakeHolderTypeIds: stakeholderIdsList },
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
          }
        });
      } else {
        const areaIdsList = dataToSave.currentAreaIds.split(",");
        getSalesTeamByArea({
          areaIds: { areaIds: areaIdsList },
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
          }
        });
      }
      showLoador({ loador: false });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onRadioChange = (e) => {
    const { value } = e.target;
    this.setState({
      filterType: value,
    });
  };

  getRow = (e) => {
    const { salesTeamListByStakeholder, setSalesTeamByStakeholder } =
      this.props;
    this.setState({ setList: e });

    const setBeatList =
      salesTeamListByStakeholder?.salesTeamListByStakeholderType.map(
        (salesTeamObject) => {
          let filterData = e.filter((row) => row == salesTeamObject.id);
          if (filterData.length != 0) {
            salesTeamObject = {
              ...salesTeamObject,
              checked: true,
            };
            return salesTeamObject;
          }
          salesTeamObject = {
            ...salesTeamObject,
            checked: false,
          };
          return salesTeamObject;
        }
      );

    setSalesTeamByStakeholder({ row: setBeatList });
  };

  rowAppliedList = (rowData) => {
    this.setState({
      beatName: rowData.beatName,
    });
    const { getOutletByBeatId, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getOutletByBeatId({
        beatId: rowData.id,
      }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.setState({
            openOutletDialog: true,
          });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  submitAssignation = () => {
    const {
      salesTeamListByStakeholder,
      saveResponse,
      showLoador,
      showNotification,
    } = this.props;
    const list = salesTeamListByStakeholder?.salesTeamListByStakeholderType
      .filter((item) => item.checked)
      .map((item) => ({
        salesTeam: { id: item.id },
      }));
    if (navigator.onLine) {

      if (list.length !== 0) {
        swal({
          title: "Are you sure?",
          text: saveWarningMsg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            showLoador({ loador: true });
            apiPost({
              url:
                endpoint.ruTargetAssignation +
                "/save-list?retailUnitWiseTargetHeaderId=" +
                saveResponse.id,
              postBody: list,
            }).then(({ success, data }) => {
              if (data.data.error) {
                showNotification({
                  msg: saveFailedMsg,
                  severity: "error",
                });
              } else {
                showNotification({ msg: savemsg });
                window.location.replace("/ru-assignation-list");
              }
              showLoador({ loador: false });
            });
          }
        });
      } else {
        showNotification({ msg: checkedListEmptyMsg, severity: "error" });

      }

    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  render() {
    const { dynamicMasterData, filterType, setList } = this.state;
    const { salesTeamListByStakeholder } = this.props;
    return (
      <>
        <Grid item xs={12} sm={12} md={12}>
          <RadioGroup
            row
            aria-label={"Type"}
            name={"Type"}
            id={"Type"}
            value={filterType}
            onChange={this.onRadioChange}
            size="small"
          >
            <FormControlLabel
              value={"1"}
              control={<Radio />}
              label={"Stakeholder wise"}
            />
            <FormControlLabel
              value={"0"}
              control={<Radio />}
              label={"Area wise"}
            />
          </RadioGroup>
          <DynamicFormWithoutSave
            formPath={RUAssignJSON.formPath}
            fieldMeta={
              filterType === "0"
                ? RUAssignJSON.fieldMetaAreaWise
                : RUAssignJSON.fieldMetaStakeholderWise
            }
            tableColumnsToFilter={RUAssignJSON.tableColumnsToFilter}
            apiBaseURL={RUAssignJSON.apiBaseURL}
            baseIdColumn={RUAssignJSON.baseIdColumn}
            dynamicMasterData={dynamicMasterData}
            showSaveBtnMain={false}
            showSaveBtn={true}
            saveBtnText={"Search"}
            onSave={this.onSave}
            callApi={true}
            getByIdApi={true}
            getDataById={this.getDataById}
            getListById={this.getListById}
          />
          <CheckBoxSelectionTable
            disabled={true}
            rows={salesTeamListByStakeholder?.salesTeamListByStakeholderType}
            columns={columns}
            selection={setList}
            showDetailsIcon={false}
            rowAppliedList={this.rowAppliedList}
            isActionColActive={false}
            onSelectionChange={this.getRow}
          />
          <DialogActions>
            <Grid
              container
              justifyContent={"flex-end"}
              gap={2}
              xs={12}
              sm={12}
              md={1}
              flexDirection={"row"}
              display={"flex"}
            >
              <Grid justifyContent={"flex-end"} item>
                <ButtonCompo
                  size="medium"
                  onClick={this.submitAssignation}
                  variant="contained"
                  name={"Assign"}
                />
              </Grid>
            </Grid>
          </DialogActions>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  areaList: state.area,
  regionList: state.region,
  stateList: state.states,
  zoneList: state.zone,
  districtList: state.district,
  talukaList: state.taluka,
  stakeHolderTypeList: state.stockStakeHolderType,
  salesTeamListByStakeholder: state.ruCreation,
});
const mapDispatchToProps = {
  saveDistributorInquiry,
  getRegionNew,
  getStateByRegion,
  getZoneByState,
  getDistrictByZone,
  getTalukaByDistrictPost,
  getAreaByTalukaPostBodyUpdated,
  setSaveDistributorInquiry,
  getStockStakeHolderType,
  showLoador,
  showNotification,
  getSalesTeamByStakeholderType,
  setSalesTeamByStakeholder,
  getSalesTeamByArea,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AssignationForm);
