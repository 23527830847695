import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { outletOpeningJson } from "../../DynamicFormsJson/Transaction/outletOpeningJson";
import { getOpeningOutletList } from "../../Slice/StackHolder.slice";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import { withRouter } from "../../components/withRouter";
import {
    noInternetMsg
} from "../../config/messageconstant";
import { openingDistributorList } from "../../tableColumns/table-columns";
import { showLoador, showNotification } from "../Landing/Landing.slice";

class OpeningOutletList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {                
      isLog: true,
      moduleObjDataObj: {}
    };
  }



  async componentDidMount() {
    if (navigator.onLine) {
      const moduleObjData = localStorage.getItem("moduleObj");
      const moduleObjDataObj = moduleObjData != null ? JSON.parse(moduleObjData) : {};

      this.setState({ moduleObjDataObj });

    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }



  getTableData = async () => {
    const { showNotification,getOpeningOutletList } = this.props;
    let resData = { response: [], success: false }
    if (navigator.onLine) {
      showLoador({ loador: true });
      resData = await getOpeningOutletList().then(({ response, success }) => {
        console.log(resData)
        return { response, success };
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
      return { response: [], success: false };
    }
    return resData
  }




  render() {
    const { stakeholdersList } = this.props;
    return (
      <>
        <DynamicMainScreen
          formPath={outletOpeningJson.formPath}
          formPathEdit={outletOpeningJson.formPathEdit}
          screenTitle={outletOpeningJson.screenTitle}
          fieldMeta={outletOpeningJson.fieldMetaForAudit}
          showPdfDownload={false}
          showExcelDownload={false}
          showExtension={false}
          pdfFileName={outletOpeningJson.pdfFileName}
          excelFileName={outletOpeningJson.excelFileName}
          showAddButton={outletOpeningJson.showAddButton}
          tableColumnsToFilter={outletOpeningJson.tableColumnsToFilter}
          tableColumns={openingDistributorList}
          tableData={stakeholdersList?.opening}
          showTransferButtonInTable={false}
          openFormPathStatus={outletOpeningJson.openFormPathStatus}
          getTableData={this.getTableData}
          apiBaseURL={outletOpeningJson.apiBaseURL}
          baseIdColumn={outletOpeningJson.baseIdColumn}
          showApply={false}
          isActiveURL={false}
          isLog={false}
          type={1}
          isActionColActive={false}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  subAdminUserList: state.subAdminUser,
  stakeholdersList: state.stackholder,

});
const mapDispatchToProps = {
  getOpeningOutletList,
  showNotification,
  showLoador,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(OpeningOutletList);
