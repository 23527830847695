import endpoint from "../../config/endpoints";

export const RUAssignJSON = {
  formPath: "/ru-assign-form",
  apiBaseURL: endpoint.accountLedger,
  screenTitle: "RU Assign",
  showAddButton: true,
  showPdfDownload: false,
  showExcelDownload: false,

  fieldMetaAreaWise: [
    {
      label: "Region",
      controlType: "autocomplete",
      placeHolder: "Select Region",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "regionDetail",
      dataKey: "currentRegionId",
      getListId: "currentRegionId",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "State",
      controlType: "autocomplete",
      placeHolder: "Select State",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stateDetail",
      dataKey: "currentStateId",
      getListFrom: "currentRegionId",
      getListId: "currentStateId",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Zone",
      controlType: "multiSelect",
      placeHolder: "Select Zone",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "zoneDetail",
      dataKey: "currentZoneIds",
      getListFrom: "currentStateId",
      getListId: "currentZoneIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "District",
      controlType: "multiSelect",
      placeHolder: "Select District",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "districtDetail",
      dataKey: "currentDistrictIds",
      getListFrom: "currentZoneIds",
      getListId: "currentDistrictIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Taluka",
      controlType: "multiSelect",
      placeHolder: "Select Taluka",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "talukaDetail",
      dataKey: "currentTalukaIds",
      getListFrom: "currentDistrictIds",
      getListId: "currentTalukaIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Area",
      controlType: "multiSelect",
      placeHolder: "Select Area",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "areaDetail",
      dataKey: "currentAreaIds",
      getListFrom: "currentTalukaIds",
      getListId: "currentAreaIds",
      isRootLevelKey: true,
      isMandatory: true,
    }
  ],
  fieldMetaStakeholderWise: [
    {
      label: "Stakeholder",
      controlType: "multiSelect",
      placeHolder: "Select Stakeholder",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stakeHolderTypeDetail",
      dataKey: "stakeholderIds",
      getListId: "stakeholderIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
  ],
};
