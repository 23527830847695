import {
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AutoComplete from "../../components/Comman/AutoComplete";
import { LabelCompo } from "../../components/Comman/Label";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import { blackColor, redColor, titleColor } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import { noInternetMsg } from "../../config/messageconstant";
import { getAllActiveProduct } from "../../Slice/product.slice";
import { getYear } from "../../Slice/year.slice";
import { apiGet } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import RUCreationViewTable from "./RUCreationViewTable";
import ProductCountPopUpInView from "./ProductCountPopUpInView";

const RUCreationAndAssignationForm = ({
  showLoador,
  showNotification,
  getYear,
  yearList,
  productList,
  getAllActiveProduct,
}) => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [type, setType] = useState("0");
  const [yearName, setYearName] = useState("");
  const [rowList, setRowList] = useState([]);
  const [prodList, setProdList] = useState([]);
  const [dataById, setDataById] = useState([]);
  const [formErrors, setFormErrors] = React.useState({});
  const [productCountPopUp, setProductCountPopUp] = React.useState(false);
  const [productCountPopUpList, setProductCountPopUpList] = React.useState([]);
  const params = useParams();

  useEffect(() => {
    if (navigator.onLine) {
      showLoador({ loador: true });
      if (params.id) {
        apiGet({
          url: endpoint.ruTargetHeader + "/by-id?id=" + params.id,
        }).then((data) => {
          if (data) {

            if (data && data.data && data.data.data) {
              setDataById(data.data.data);
              setName(data.data.data.name !== null ? data.data.data.name : "-");
              setYearName(data.data.data.yearName !== null ? data.data.data.yearName : "-");
              setType(data.data.data.targetType !== null ? data.data.data.targetType.toString() : "-");
              setRowList(data.data.data.retailUnitWiseTargetDetail.length !== 0 ?
                data.data.data.retailUnitWiseTargetDetail : []);
            }

          }
        });
      }
      showLoador({ loador: false });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }, []);

  useEffect(() => {
    setProdList(productList?.activeAllProduct);
  }, [productList]);


  const onClose = () => {
    setProductCountPopUp(false);
  }

  const onClickProductCountHandler = (e, row) => {

    const productCountPopUpList = row.retailUnitWiseTargetSubDetail.map((dataObj, index) => (
      {
        index: index + 1,
        ...dataObj,

      }
    ))

    setProductCountPopUpList(productCountPopUpList);
    setProductCountPopUp(true);
  }

  const totalRuQty = useMemo(() => {
    return rowList.reduce(
      (sum, item) => sum + parseInt(item.ruQty || 0, 10),
      0
    );
  }, [rowList]);

  return (
    <>
      <Paper
        sx={{
          p: 2,
          borderRadius: 2,
        }}
        elevation={0}
      >
        <LandingScreenHeader
          screenTitle={"RU Creation View"}
          showTable={false}
          showBackButton={true}
          showAddButton={false}
          onCancel={() => window.location.replace("/ru-creation-list")}
        />
        <div>
          <br></br>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="space-between"
          >
            <Grid
              item
              sm={12}
              xs={12}
              md={4}
              lg={4}
              container
              justifyContent="space-between"
            >
              <Grid item xs={12} md={2.5} sm={2.5}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: blackColor }}
                  label="Name :"
                />
              </Grid>
              <Grid item xs={12} md={9.5} sm={9.5}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: titleColor }}
                  label={name ? name : ""}
                />
              </Grid>
            </Grid>

            <Grid
              item
              sm={12}
              xs={12}
              md={4}
              lg={4}
              container
              justifyContent="space-between"
            >
              <Grid item xs={12} md={2.5} sm={2.5}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: blackColor }}
                  label="Year :"
                />
              </Grid>
              <Grid item xs={12} md={9.5} sm={9.5}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: titleColor }}
                  label={yearName ? yearName : ""}
                />
              </Grid>
            </Grid>

            <Grid
              item
              sm={12}
              xs={12}
              md={4}
              lg={4}
              container
              justifyContent="space-between"
            >
              <Grid item xs={12} md={2.5} sm={2.5}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: blackColor }}
                  label="RU Qty :"
                />
              </Grid>
              <Grid item xs={12} md={9.5} sm={9.5}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: titleColor }}
                  label={totalRuQty}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="space-between"
          >
            {/* <Grid item xs={12} md={0.8} sm={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16, color: redColor }}
                label="* "
              />
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16, color: titleColor }}
                label="Type :"
              />
            </Grid> */}
            {/* <Grid item xs={12} sm={12} md={11.2}>
              <RadioGroup
                row
                aria-label={"Type"}
                name={"type"}
                id={"type"}
                value={type}
                size="small"
                aria-disabled
              >
                <FormControlLabel
                  value={"0"}
                  control={<Radio />}
                  label={"SKU & RU Wise"}
                  disabled={true}
                />
                <FormControlLabel
                  value={"1"}
                  control={<Radio />}
                  label={"RU Wise"}
                  disabled={true}

                />
              </RadioGroup>
            </Grid> */}
          </Grid>
          <Divider />
          <br />

          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="space-between"
          >
            <RUCreationViewTable
              rowList={rowList}
              formErrors={formErrors}
              type={type}
              onClickProductCountHandler={onClickProductCountHandler}
            />
          </Grid>
        </div>


        {productCountPopUp && (
          <ProductCountPopUpInView
            open={productCountPopUp}
            rowList={productCountPopUpList}
            columns={[
              {
                title: "Sr. No.",
                name: "index",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "center",
              },
              {
                title: "Product Name",
                name: "productName",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
                canSearch: true,
              },
              {
                title: "RU Quantity",
                name: "ruQty",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "right",
                canSearch: true,
              },
            ]}
            onCancel={onClose}
          />
        )}

      </Paper>
    </>
  );
};
const mapStateToProps = (state) => ({
  productList: state.product,
  yearList: state.year,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getAllActiveProduct,
  getYear,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RUCreationAndAssignationForm);
