import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import { RuWiseProductConfigColumns } from "../../tableColumns/table-columns";
import { ProductWiseRUConfigJson } from "../../DynamicFormsJson/Transaction/productWiseRUConfigJson";
import { getRuWiseProductConfiguration } from "../../Slice/ruWiseProductConfig.slice";
import { noInternetMsg, serverMsg, statusmsg } from "../../config/messageconstant";
import { apiGet } from "../../utils/api_service";
import endpoint from "../../config/endpoints";
import { showLoador, showNotification } from "../Landing/Landing.slice";
class ProductWiseRUConfigList extends React.Component {
    constructor(props) {
        super(props);
    }


    onChangeStatusStatic = (row) => {
        const { getRuWiseProductConfiguration, showLoador, showNotification } = this.props;

        if (row.id) {
            let status = "";
            if (row.statusField == 0) {
                status = 1
            } else {
                status = 0
            }

            swal({
                text: "Do You Want to change Stats?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then(async (willDelete) => {
                if (willDelete) {
                    if (navigator.onLine) {

                        showLoador({ loador: true });
                        apiGet({
                            url:
                                endpoint.ruProductConfigHeader +
                                "/update-status?id=" +
                                row.id +
                                "&status=" + status,
                        }).then(({ success }) => {
                            if (success) {
                                showLoador({ loador: false });
                                showNotification({
                                    msg: statusmsg,
                                });
                                getRuWiseProductConfiguration();
                            } else {
                                showNotification({
                                    msg: serverMsg,
                                    severity: "error",
                                });
                                showLoador({ loador: false });
                            }
                        });
                    } else {
                        showNotification({ msg: noInternetMsg, severity: "error" });
                    }

                }

            });
        }
    }

    render() {
        const { ruProductConfigHeaderList, getRuWiseProductConfiguration } = this.props;
        return (
            <>
                <DynamicMainScreen
                    formPath={ProductWiseRUConfigJson.formPath}
                    screenTitle={ProductWiseRUConfigJson.screenTitle}
                    fieldMeta={ProductWiseRUConfigJson.fieldMeta}
                    formPathView={ProductWiseRUConfigJson.formPathView}
                    showPdfDownload={false}
                    showExcelDownload={false}
                    changeExcelURL={ProductWiseRUConfigJson.excelUrl}
                    showExtension={false}
                    filterDataExcel={true}
                    pdfFileName={ProductWiseRUConfigJson.pdfFileName}
                    excelFileName={ProductWiseRUConfigJson.excelFileName}
                    showAddButton={ProductWiseRUConfigJson.showAddButton}
                    tableColumnsToFilter={ProductWiseRUConfigJson.tableColumnsToFilter}
                    tableColumns={RuWiseProductConfigColumns}
                    tableData={ruProductConfigHeaderList?.ruWiseHeader}
                    getTableData={getRuWiseProductConfiguration}
                    apiBaseURL={ProductWiseRUConfigJson.apiBaseURL}
                    baseIdColumn={ProductWiseRUConfigJson.baseIdColumn}
                    showDeleteIcon={true}
                    onChangeStatusStatic={this.onChangeStatusStatic}
                    editConditionName="isEditable"
                    editConditionValue="1"
                    deleteConditionName="isEditable"
                    deleteConditionValue="1"
                    chipColorSuccess={true}
                    showViewButtonInTable={true}
                />
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    ruProductConfigHeaderList: state.ruProductConfigHeader,
});
const mapDispatchToProps = {
    getRuWiseProductConfiguration,
    showLoador,
    showNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductWiseRUConfigList);
