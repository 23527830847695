import ClearIcon from "@mui/icons-material/Clear";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Grid, Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import RTTable from "../../components/Comman/RT/RTTable";

import AttachFile from "../../components/Comman/AttachFile";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
import { MyComponentWithIconProps } from "../../components/Comman/IconButton";
import { LabelCompo } from "../../components/Comman/Label";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { withRouter } from "../../components/withRouter";
import { redColor, titleColor } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import {
  exportmsg,
  noInternetMsg,
  savemsg,
  serverMsg,
} from "../../config/messageconstant";
import {
  apiGet,
  apiPost,
  apiPostForDownload,
  apiPostForImage,
} from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { downloadExcel, downloadF } from "../PDF/PDFDownload";

const ImportAndExport = ({ showLoador, showNotification, ...props }) => {
  const [state, setState] = useState({
    option: "1",
    isLog: true,
    optionList: [
      { id: "1", name: "Sales-team" },
      { id: "2", name: "Stake-holder" },
    ],
    fileName: "",
    salesTeamId: "",
    errorLines: [],
    errorType: [],
    inValidSalesTeamsList: [],
    incorrectLine: "",
    totalLines: "",
    validSalesTeamsList: [],
    showImportButtonFlag: false,
    showExportExcelButtonFlag: false,
    inValidStockStakeHolderList: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      if (navigator.onLine) {
      } else {
        showNotification({ msg: noInternetMsg, severity: "error" });
      }
    };
    fetchData();
  }, []);

  const onAutocompleteChange = () => (name, newValue) => {
    if (newValue != null) {
      setState((prevState) => ({
        ...prevState,
        [name]: newValue,
        salesTeamId: newValue.id,
        // fileName: "",
      }));
      onClearHandler();
    }
  };

  const onExportHandler = () => {
    const { option } = state;
    const id = parseInt(option, 10);
    showLoador({ loador: true });
    let exportUrl = "";
    if (id == 1) {
      exportUrl = endpoint.exportData + "/generate-sales-team-excel";
    } else if (id == 2) {
      exportUrl = endpoint.exportData + "/generate-stock-stake-holder-excel";
    } else {
      showNotification({
        msg: "Invalid export option",
        severity: "error",
      });
      showLoador({ loador: false });
      return;
    }
    downloadF({
      isGet: true,
      url: exportUrl,
      ext: "xls",
      openNewTab: false,
    }).then((response) => {
      if (!response) {
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }
      showLoador({ loador: false });
    });
  };

  const backToList = () => {
    props.navigate(-1);
  };

  const changeH = (fileName, name, index) => {
    setState((prevState) => ({
      ...prevState,
      fileName: fileName,
    }));
  };

  const onClickViewPreview = (e, row) => {
    e.stopPropagation();
    const url = URL.createObjectURL(row);
    window.open(url);
  };

  const onClearHandler = (event, value) => {
    setState((prevState) => ({
      ...prevState,
      fileName: "",
    }));
  };

  const onSubmit = (flag) => {
    const { fileName } = state;

    if (!fileName) {
      showNotification({
        msg: "Please select file",
        severity: "error",
      });
      return;
    }

    const formData = new FormData();
    const { option } = state;
    const id = parseInt(option, 10);
    let importUrl = "";
    if (id == 1) {
      importUrl =
        endpoint.importData + "/validate-sales-team-import?flag=" + flag;
    } else if (id == 2) {
      importUrl =
        endpoint.importData +
        "/validate-stock-stake-holder-import?flag=" +
        flag;
    }
    if (id == 1) {
      formData.append("salesTeamFile", fileName);
    } else if (id == 2) {
      formData.append("stakeHolder", fileName);
    }

    showLoador({ loador: true });
    apiPostForImage({
      url: importUrl,
      postBody: formData,
    }).then(({ data, success }) => {
      showLoador({ loador: false });
      if (success) {
        if (flag == 1) {
          setState((prevState) => ({
            ...prevState,
            showExportExcelButtonFlag: true,
            showImportButtonFlag: false,
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            showExportExcelButtonFlag: true,
            showImportButtonFlag: true,
          }));
        }

        setState((prevState) => ({
          ...prevState,
          errorLines: data.data.errorLines,
          inValidSalesTeamsList: data.data.inValidSalesTeamsList,
          incorrectLine: data.data.incorrectLine,
          totalLines: data.data.totalLines,
          validSalesTeamsList: data.data.validSalesTeamsList,
          inValidStockStakeHolderList: data.data.inValidStockStakeHolderList,
        }));
        showNotification({
          msg: "File validate Successfully",
          severity: "success",
        });
      }
    });
  };

  const onExportErrorHandler = () => {
    const {
      fileName,
      inValidSalesTeamsList,
      errorLines,
      option,
      inValidStockStakeHolderList,
    } = state;

    if (!fileName) {
      showNotification({
        msg: "Please select a file",
        severity: "error",
      });
      return;
    }

    if (errorLines.length === 0) {
      showNotification({
        msg: "No error found to export",
        severity: "success",
      });
      return;
    }

    showLoador({ loador: true });

    const id = parseInt(option, 10);
    let exportUrl = "";

    if (id == 1) {
      exportUrl = endpoint.exportData + "/incorrect-sales-team-info-excel";
    } else if (id == 2) {
      exportUrl =
        endpoint.exportData + "/incorrect-stock-stake-holder-info-excel";
    }

    const postBodyData =
      id == 1 ? inValidSalesTeamsList : inValidStockStakeHolderList;

    if (!postBodyData || postBodyData.length == 0) {
      showNotification({
        msg: "Invalid data to download",
        severity: "error",
      });
      showLoador({ loador: false });
      return;
    }

    downloadF({
      isGet: false,
      url: exportUrl,
      ext: "xls",
      openNewTab: false,
      postBody: postBodyData,
    }).then((response) => {
      if (!response) {
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }
      showLoador({ loador: false });
    });
  };

  const {
    option,
    optionList,
    fileName,
    errorLines,
    totalLines,
    incorrectLine,
    showImportButtonFlag,
    showExportExcelButtonFlag,
  } = state;
  return (
    <>
      <Paper
        sx={{
          borderRadius: 2,
          p: 2,
        }}
        elevation={0}
      >
        <LandingScreenHeader
          screenTitle={"Import Data"}
          showSaveBtnMain={false}
          showAddButton={false}
          showBackButton={false}
          onCancel={backToList}
        />
        <br />
        <Grid
          container
          display={"flex"}
          flexDirection={"row"}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          spacing={1}
        >
          <Grid item xs={12} sm={4} md={4}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 16 }}
              label={"Select"}
            />
            <AutoComplete
              keyColName={"id"}
              value={option}
              name="option"
              options={optionList}
              onChange={onAutocompleteChange()}
            />
          </Grid>
          <Grid
            style={{
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            display={"flex"}
            gap={2}
            container
            xs={8}
            sm={8}
            md={8}
          >
            {/* <Grid item xs={12} sm={2} md={2} lg={2}>
            <LabelCompo
              className="text-black"
              style={{
                fontSize: 16,
                color: titleColor,
              }}
              label={"Select file"}
            />
          </Grid> */}
            <Grid
              justifyContent={"left"}
              alignItems={"flex-end"}
              display={"flex"}
              item
              marginLeft={"3%"}
            >
              <AttachFile name={"file"} fileName={changeH} />
              &nbsp;
              {fileName !== "" ? (
                <>
                  {fileName.name.length > 30
                    ? fileName.name.substring(0, 27) + "..."
                    : fileName.name}
                </>
              ) : (
                ""
              )}
              &nbsp;{" "}
              {fileName !== "" ? (
                <>
                  <MyComponentWithIconProps
                    statusImage={VisibilityIcon}
                    color="primary"
                    fontSize="medium"
                    title="View"
                    onClick={(e) => onClickViewPreview(e, fileName)}
                  />
                  <MyComponentWithIconProps
                    statusImage={ClearIcon}
                    color={"error"}
                    fontSize="medium"
                    title="Clear"
                    onClick={(event) => onClearHandler(event, option)}
                  />
                </>
              ) : (
                ""
              )}
            </Grid>
            <Grid item justifyContent="left">
              <ButtonCompo
                size="small"
                type="button"
                variant="contained"
                name="Validate"
                onClick={() => onSubmit(0)}
              />
            </Grid>
            <Grid item display={"flex"} justifyContent="left">
              <ButtonCompo
                size="small"
                type="button"
                variant="contained"
                name="Validate & Import"
                onClick={() => onSubmit(1)}
              />
            </Grid>
          </Grid>
          <Grid
            container
            flexDirection={"row"}
            justifyContent={"space-between"}
            display={"flex"}
            sm={12}
            md={12}
            lg={12}
            paddingX={"0.5%"}
          >
            <Grid
              container
              justifyContent={"space-between"}
              flexDirection={"row"}
              sm={4}
              md={4}
              lg={4}
            >
              <Grid item>
                <LabelCompo
                  className="text-black"
                  style={{
                    fontSize: 16,
                    color: titleColor,
                  }}
                  label={"No. of line items: "}
                />
                &nbsp;
                <LabelCompo
                  className="text-black"
                  style={{
                    fontSize: 16,
                    color: titleColor,
                  }}
                  label={totalLines}
                />
              </Grid>
              <Grid item justifyContent={"left"}>
                <LabelCompo
                  className="text-black"
                  style={{
                    fontSize: 16,
                    color: titleColor,
                  }}
                  label={"No. of error lines: "}
                />
                &nbsp;
                <LabelCompo
                  className="text-black"
                  style={{
                    fontSize: 16,
                    color: redColor,
                  }}
                  label={incorrectLine}
                />
              </Grid>
            </Grid>
            <Grid container flexDirection={"row"} sm={4} md={4} lg={4} />
            <Grid
              container
              justifyContent="flex-end"
              flexDirection={"row"}
              sm={4}
              md={4}
              lg={4}
              gap={2}
              marginBottom={"0.5%"}
            >
              <Grid item justifyContent="right">
                <ButtonCompo
                  size="small"
                  type="button"
                  variant="contained"
                  name="Export excel"
                  onClick={onExportHandler}
                />
              </Grid>
              <Grid item display={"flex"} justifyContent="right">
                <ButtonCompo
                  size="small"
                  type="button"
                  variant="contained"
                  name="Export excel with data"
                  onClick={onExportHandler}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <RTTable
            columns={[
              {
                name: "lineNo",
                title: "Line No.",
                align: "center",
              },
              { name: "errorType", title: "Error type", width: "90%" },
            ]}
            tableData={errorLines}
            isActionColActive={false}
          />

          <Grid
            style={{
              justifyContent: "flex-end",
              alignItems: "center",
            }}
            display={"flex"}
            gap={2}
            container
            xs={12}
            sm={12}
            md={12}
          >
            {showImportButtonFlag && (
              <>
                <Grid item justifyContent="left">
                  <ButtonCompo
                    size="small"
                    type="button"
                    variant="contained"
                    name="Import"
                    onClick={onExportErrorHandler}
                  />
                </Grid>
              </>
            )}

            {showExportExcelButtonFlag && (
              <>
                <Grid item display={"flex"} justifyContent="left">
                  <ButtonCompo
                    size="small"
                    type="button"
                    variant="contained"
                    name="Export error excel"
                    onClick={onExportErrorHandler}
                    disable={errorLines.length == 0 ? true : false}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  showLoador,
  showNotification,
  downloadExcel,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ImportAndExport);
