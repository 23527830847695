import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { withRouter } from "../../components/withRouter";
import { compose } from "@reduxjs/toolkit";
import { connect } from "react-redux";

import { useEffect, useState } from "react";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import { ButtonCompo } from "../../components/Comman/Button";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import {
  noInternetMsg,
  savemsg,
  saveFailedMsg,
  saveWarningMsg,
} from "../../config/messageconstant";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import endpoint from "../../config/endpoints";
import swal from "sweetalert";
import { apiPost } from "../../utils/api_service";
import { LabelCompo } from "../../components/Comman/Label";
import { targetJson } from "../../DynamicFormsJson/Transaction/Targrt";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
const CalculateTarget = ({
  showLoador,
  showNotification,
  stockStakeHolderTypeName,
  year,
  setList,
  onCancel,
  notInsertedTargetList,
  monthList,
  freezFlag,
  totaloftargetDenomination,
}) => {
  console.log(freezFlag);
  const [month, setMonth] = useState([]);
  const [totaloftarget, setTotaloftarget] = useState(0);
  const [isLog, setIsLog] = useState(true);
  const [moduleObjDataObj, setModuleObjDataObj] = useState({});

  useEffect(() => {
    showLoador({ loador: false });


    const moduleObjData = localStorage.getItem("moduleObj");

    setModuleObjDataObj(moduleObjData != null ? JSON.parse(moduleObjData) : {});

    let result = monthList.map((yearObject) => {
      if (yearObject.month == "January") {
        return { label: "Jan", name: "jan" };
      } else if (yearObject.month == "February") {
        return { label: "Feb", name: "feb" };
      } else if (yearObject.month == "March") {
        return { label: "Mar", name: "march" };
      } else if (yearObject.month == "April") {
        return { label: "Apr", name: "april" };
      } else if (yearObject.month == "May") {
        return { label: "May", name: "may" };
      } else if (yearObject.month == "June") {
        return { label: "Jun", name: "jun" };
      } else if (yearObject.month == "July") {
        return { label: "July", name: "july" };
      } else if (yearObject.month == "August") {
        return { label: "Aug", name: "aug" };
      } else if (yearObject.month == "September") {
        return { label: "Sep", name: "sep" };
      } else if (yearObject.month == "October") {
        return { label: "Oct", name: "oct" };
      } else if (yearObject.month == "November") {
        return { label: "Nov", name: "nov" };
      } else {
        return { label: "Dec", name: "dec" };
      }
    });
    setMonth(result);
  }, []);

  const onTextFieldDataChange = (dataKey, index) => (event) => {
    const { value } = event.target;
    let totaloftarget = 0;
    const ListToSet = notInsertedTargetList.map((rowD) => {
      if (index == rowD.index) {
        rowD = {
          ...rowD,
          [dataKey]: value,
        };
        rowD = {
          ...rowD,
          totalTarget:
            +rowD.jan +
            +rowD.feb +
            +rowD.march +
            +rowD.april +
            +rowD.may +
            +rowD.jun +
            +rowD.july +
            +rowD.aug +
            +rowD.sep +
            +rowD.oct +
            +rowD.nov +
            +rowD.dec,
        };
        return rowD;
      }

      return {
        ...rowD,
        [dataKey]: rowD[dataKey],
      };
    });
    setList({ row: ListToSet });
  };

  const onSubmit = (isFreez) => () => {
    let saveTargetList = notInsertedTargetList.map((targetListObject) => {
      let targetListObjectToSave = {
        salesTeam: {
          id: targetListObject.salesTeamId,
        },
        yearMaster: {
          id: targetListObject.year,
        },
        isFreez: isFreez,
        totalTarget: targetListObject.totalTarget,
        jan: targetListObject.jan,
        feb: targetListObject.feb,
        march: targetListObject.march,
        april: targetListObject.april,
        may: targetListObject.may,
        jun: targetListObject.jun,
        july: targetListObject.july,
        aug: targetListObject.aug,
        sep: targetListObject.sep,
        oct: targetListObject.oct,
        nov: targetListObject.nov,
        dec: targetListObject.dec,
      };
      if (targetListObject.id != 0) {
        targetListObjectToSave = {
          ...targetListObjectToSave,
          id: targetListObject.id,
        };
      }
      return targetListObjectToSave;
    });

    swal({
      title: "Are you sure?",
      text: saveWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        onSave(saveTargetList);
      }
    });
  };
  const onSave = (saveTargetList) => {
    if (navigator.onLine) {

      let salesTeamIds = saveTargetList.map((listData) => listData.salesTeam && listData.salesTeam.id)

      showLoador({ loador: true });
      apiPost({
        url: endpoint.target + "/save-list",
        postBody: saveTargetList,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {

          if (data.data !== null) {
            if (isLog) {
              let updatedJsonToSend = JSON.stringify(data.data);

              let dataToSaveForMLog = {
                module: {
                  moduleId: moduleObjDataObj.moduleId
                },
                subModule: {
                  subModuleId: moduleObjDataObj.subModuleId
                },
                operationType: 0,
                type: 1,
                operationPerformId: year !== null ? year.id : 0,
                subOperationPerformIds: salesTeamIds.join(", "),
                updatedJson: updatedJsonToSend,
              }

              showLoador({ loador: true });
              apiPost({
                url: endpoint.mlog,
                postBody: dataToSaveForMLog,
              }).then(({ success }) => {
                showLoador({ loador: false });
                if (success) {
                } else {
                  showNotification({
                    msg: saveFailedMsg,
                    severity: "error",
                  });
                }
              });
            }

          }

          showNotification({ msg: savemsg });

          window.location.reload();
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
        return success;
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  const userTypeId = localStorage.getItem("userTypeId");

  const totalCurrentTarget = localStorage.getItem("totalTarget");

  return (
    <Paper
      sx={{
        p: 2,
        borderRadius: 2,
      }}
      elevation={0}
    >
      <LandingScreenHeader
        screenTitle={targetJson.screenTitle}
        showTable={false}
        showBackButton={true}
        showAddButton={false}
        onCancel={onCancel}
      />
      <br />
      <TableContainer>
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          rowSpacing={2}
          columnSpacing={1}
          container
          justifyContent="space-evenly"
        >
          <Grid item xs={12} sm={12} md={2}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 16 }}
              label="Stock Holder Type: "
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 16 }}
              label={stockStakeHolderTypeName}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 16 }}
              label="Year : "
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 16 }}
              label={year != "" ? year.name : "-"}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={2}>
          <LabelCompo
            className="text-black"
            style={{ fontSize: 16 }}
            label="Total Target: "
          />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;
          <LabelCompo
            className="text-black"
            style={{ fontSize: 16 }}
            label={totalCurrentTarget != "" ? totalCurrentTarget : "-"}
          />
        </Grid>

        <br />
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ minWidth: 80 }}>Sr. No</TableCell>
              <TableCell sx={{ minWidth: 100 }}>Name</TableCell>
              <TableCell sx={{ minWidth: 100 }}>Region</TableCell>
              <TableCell sx={{ minWidth: 100 }}>State</TableCell>
              <TableCell sx={{ minWidth: 100 }}>Zone</TableCell>
              <TableCell sx={{ minWidth: 100 }}>Area</TableCell>
              {monthList.map((monthData) => {
                return <TableCell sx={{ minWidth: 100 }}>{monthData.name}</TableCell>;
              })}
              <TableCell sx={{ minWidth: 80 }}>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {notInsertedTargetList.map((row, index) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row" align="center">
                  {index + 1}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.regionName}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.stateName}
                </TableCell>
                <TableCell component="th" scope="row" >
                  {row.zoneNames}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.areaName}
                </TableCell>
                {month.map((monthData) => {
                  return (
                    <TableCell align="right">
                      <TextFieldCompo
                        placeHolder={"Enter Percentage"}
                        size="small"
                        color="primary"
                        type="number"
                        id={"1"}
                        value={row[monthData.name]}
                        onChange={onTextFieldDataChange(
                          monthData.name,
                          index + 1
                        )}
                        fullWidth={false}
                      />
                    </TableCell>
                  );
                })}
                <TableCell component="th" scope="row">
                  {row.totalTarget}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer >
      <br />
      <DynamicDetails
        DataToSet={[
          {
            xs: 12,
            sm: 6,
            md: 6,
            lg: 6,
            label: "Total Target",
            value: totaloftargetDenomination,
          },
        ]}
      />
      <Grid container spacing={1}>
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          rowSpacing={2}
          columnSpacing={{ xs: 12, sm: 6, md: 1 }}
          container
          justifyContent="right"
        >
          <ButtonCompo
            size="medium"
            type="submit"
            variant="contained"
            name="Submit"
            onClick={onSubmit(0)}
          />
          &nbsp;&nbsp;
          {freezFlag &&
            (<ButtonCompo
              size="medium"
              type="submit"
              variant="contained"
              name="Freeze & Submit"
              onClick={onSubmit(1)}
            />)
          }

        </Grid>
      </Grid>

    </Paper >
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { showLoador, showNotification };
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CalculateTarget);
