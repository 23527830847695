import {
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { LabelCompo } from "../../components/Comman/Label";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { redColor, titleColor } from "../../config/ColorObj";
import { salesTeamCategoryWiseDataJson } from "../../DynamicFormsJson/Transaction/salesTeamCategoryWiseDataJson";
import CategoryWise from "./categoryWise";
import SubCategoryWise from "./subCategoryWise";
import { getSalesTeamCategoryWiseData, getSalesTeamSubCategoryWiseData } from "../../Slice/salesTeamCategoryWiseData.slice";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";


class SalesTeamCategoryWiseData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      radioValue: "0",
      matrixDataListForCat: [],
      dataResponse: {},
      dataResponseForSubCat: {},
      matrixDataListForSubCat: [],
      catsubCatMatrixData: [],
      fromDate: "",
      toDate: "",
    };
  }

  async componentDidMount() {



  }

  onSearch = (data) => {
    const { radioValue } = this.state;
    const { getSalesTeamCategoryWiseData, getSalesTeamSubCategoryWiseData, showLoador, showNotification } = this.props;


    if (navigator.onLine) {

      this.setState({ fromDate: data.fromDate, toDate: data.toDate });

      if (radioValue == "0") {
        let dataJson = {
          fromDate: data.fromDate,
          toDate: data.toDate,
        };

        showLoador({ loador: true });
        getSalesTeamCategoryWiseData({ dateJson: dataJson }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            this.setState({ dataResponse: response })
            this.matrixDataForCat(response);
          }
        });
      } else {

        let dataJson = {
          fromDate: data.fromDate,
          toDate: data.toDate,
        };

        showLoador({ loador: true });
        getSalesTeamSubCategoryWiseData({ dateJson: dataJson }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            console.log(response);
            this.setState({ dataResponseForSubCat: response })
            this.matrixDataForSubCat(response);
          }
        });
      }

    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }


  };


  matrixDataForCat = (data) => {
    const { categories, salesTeamList, salesTeamWithCategoryValues } = data;

    const matrixDataList = salesTeamList.map((salesTeamObj, index) => {
      const salesAmounts = categories.map((categoryObj) => {
        const match = salesTeamWithCategoryValues.find((salesTeamWithCategoryValuesObj) =>
          salesTeamWithCategoryValuesObj.salesTeamId == salesTeamObj.id &&
          salesTeamWithCategoryValuesObj.categoryId == categoryObj.id
        );
        return match ? match.totalAmt : '';
      });
      return {
        index: index + 1,
        salesTeamId: salesTeamObj.id,
        salesTeamName: salesTeamObj.name,
        grandTotalAmt: salesTeamObj.grandTotalAmt,
        salesAmounts
      };
    });

    console.log(matrixDataList);

    this.setState({ matrixDataListForCat: matrixDataList });


  };



  matrixDataForSubCat = (data) => {
    const { categories, subCategories, salesTeamList, salesTeamWithSubCategoryValues } = data;

    const groupedData = categories.map(category => {
      const subCategoriesForCategory = subCategories
        .filter(subcategory => subcategory.categoryId === category.id)
        .map(subcategory => ({
          subCatId: subcategory.id,
          subCatName: subcategory.name,
        }));

      return {
        catId: category.id,
        name: category.name,
        subCategory: subCategoriesForCategory,
      };
    });


    console.log(groupedData);




    const subCatListWithValue = salesTeamList.map((salesTeam) => {

      const salesDataForTeam = salesTeamWithSubCategoryValues.filter(
        (item) => item.salesTeamId == salesTeam.id
      );

      const categoryList = groupedData.map((category) => {

        const subCategoryWithValues = category.subCategory.map((subCategory) => {

          const matchingSalesData = salesDataForTeam.find(
            (sales) => sales.subCategoryId == subCategory.subCatId
          );

          return {
            subCatId: subCategory.subCatId,
            subCatName: subCategory.subCatName,
            totalAmt: matchingSalesData ? matchingSalesData.totalAmt : 0,
          };
        });

        return {
          catId: category.catId,
          name: category.name,
          subCategory: subCategoryWithValues,
        };
      });

      return {
        id: salesTeam.id,
        name: salesTeam.name,
        grandTotalAmt: salesTeam.grandTotalAmt,
        categoryList: categoryList,
      };
    });

    console.log(subCatListWithValue);




    console.log(subCatListWithValue);

    this.setState({ matrixDataListForSubCat: subCatListWithValue, catsubCatMatrixData: groupedData });

  };


  handleChange = (event, newValue) => {
    this.setState({
      radioValue: newValue,
    });
  };
  render() {
    const { } = this.props;
    const {
      radioValue,
      matrixDataListForCat,
      matrixDataListForSubCat,
      dataResponse,
      dataResponseForSubCat,
      catsubCatMatrixData,
      fromDate,
      toDate,
    } = this.state;

    return (
      <>
        <Paper
          sx={{
            padding: 2,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            dynamicMasterData={[]}
            getListById={this.getListById}
            searchList={salesTeamCategoryWiseDataJson.searchList}
            showSaveBtn={false}
            showSaveBtnMain={true}
            showTitle={salesTeamCategoryWiseDataJson.showTitle}
            screenTitle={salesTeamCategoryWiseDataJson.screenTitle}
            fieldMeta={salesTeamCategoryWiseDataJson.fieldMeta}
            showCancel={salesTeamCategoryWiseDataJson.showCancel}
            apiBaseURL={salesTeamCategoryWiseDataJson.apiBaseURL}
            showSaveNextBtn={salesTeamCategoryWiseDataJson.showSaveNextBtn}
            saveBtnText={"Search"}
            onSave={this.onSearch}
          />
          <Grid item xs={12} sm={12} md={12} lg={12} container direction="row">
            <Grid item xs={12} sm={1.5} md={1.5} lg={1.5}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16, color: redColor }}
                label={"*"}
              />
              <LabelCompo
                className="text-black"
                style={{
                  fontSize: 16,
                  color: titleColor,
                }}
                label={"Type"}
              />
            </Grid>
            <Grid item xs={12} sm={10} md={10} lg={10}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={radioValue}
                  onChange={this.handleChange}
                  row
                  defaultValue={radioValue}
                >
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="Category Wise"
                  />
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Sub-Category Wise"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

          <br />
          {radioValue == "0" ? (
            Object.keys(dataResponse).length !== 0 && (
              <CategoryWise
                categoryList={dataResponse.categories}
                matrixDataListForCat={matrixDataListForCat}
                fromDate={fromDate}
                toDate={toDate}
              />
            )
          ) : (
            Object.keys(dataResponseForSubCat).length !== 0 && (
              <SubCategoryWise
                subCatList={catsubCatMatrixData}
                subCatListWithValue={matrixDataListForSubCat}
                fromDate={fromDate}
                toDate={toDate}
              />
            )
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  salesTeamCategoryWiseData: state.salesTeamCategoryWiseData,
});
const mapDispatchToProps = {
  getSalesTeamCategoryWiseData,
  getSalesTeamSubCategoryWiseData,
  showLoador,
  showNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(SalesTeamCategoryWiseData);
