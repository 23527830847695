import { Grid, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { compose } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { ButtonCompo } from "../../components/Comman/Button";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import DynamicFormWithoutValidation from "../../components/dynamicscreens/DynamicFormWithoutValidation";
import { withRouter } from "../../components/withRouter";
import endpoint from "../../config/endpoints";
import {
  atleastOne,
  noInternetMsg,
  saveFailedMsg,
  savemsg,
  saveWarningMsg,
  serverMsg,
} from "../../config/messageconstant";
import { mandatoryCheck, ValidationCheck } from "../../config/ValidationCheck";
import { purchaseReturnJson } from "../../DynamicFormsJson/Transaction/purchaseReturn";
import { getBeatByArea } from "../../Slice/beat.slice";
import { setCategoryAndItemForCreditNote } from "../../Slice/category.slice";
import { getActiveOutletByBeatId } from "../../Slice/outlet.slice";
import { apiGet, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import PurchaseReturnTable from "./PurchaseReturnTable";
import SubmitPurchaseReturn from "./SubmitPurchaseReturn";
import { titleColor } from "../../config/ColorObj";
import { LabelCompo } from "../../components/Comman/Label";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

class PurchaseReturnForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
      dynamicMasterData: {
        orderDetail: this.props.stackHolderList.stackholder,
      },
      formErrors: {},
      fieldData: { instockOutstock: "1" },
      DataToSet: [],
      value: 0,
      remark: "",
      total: 0,
      totalAmt: 0,
      totalOfQty: 0,
      open: false,
      orderList: [],
      stockFlag: false,
    };
  }

  setCatList = ({ row }) => {
    const { setCategoryAndItemForCreditNote } = this.props;
    let dataTotal = 0;
    let totalOfTotalQty = 0;
    row.map((rowData) => {
      if (rowData.flag == 3) {
        rowData.productWithMarginAndMrps.map((productData) => {
          dataTotal =
            dataTotal +
            (+productData.primaryQty * productData.products.uomConversionValue +
              +productData.secondaryQty) *
              (+productData.rate / productData.products.uomConversionValue);
          totalOfTotalQty =
            totalOfTotalQty +
            +productData.primaryQty * productData.products.uomConversionValue +
            +productData.secondaryQty;
        });
        this.setState({
          totalOfQty: totalOfTotalQty,
        });
      }
    });
    const { fieldData } = this.state;
    const discount = fieldData.DiscountPercentage
      ? fieldData.DiscountPercentage
      : 0;
    let discountAmt = (dataTotal * +discount) / 100;
    this.setState({
      totalAmt: dataTotal - +discountAmt,
      total: dataTotal,
    });
    setCategoryAndItemForCreditNote({ row });
  };
  async componentDidMount() {
    const { showLoador, showNotification, params, remarkField } = this.props;

    if (navigator.onLine) {
      if (params.id) {
        this.setState({
          remark: remarkField,
        });
      }

      showLoador({ loador: true });
      await apiGet({
        url: endpoint.stackholder + "/get-to-order",
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success && data.data != null) {
          const rowData = data.data;
          this.setState({
            DataToSet: [
              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "Purchase Return To",
                value: rowData.name !== null ? rowData.name : "",
              },
              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "Date",
                value: dayjs().format("DD-MM-YYYY"),
              },
            ],
          });
        } else {
          this.setState({
            DataToSet: [
              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "Purchase Return To",
                value: " - ",
              },
              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "Date",
                value: dayjs().format("DD-MM-YYYY"),
              },
            ],
          });
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

      showLoador({ loador: true });
      await apiGet({
        url:
          endpoint.categoryLatest +
          "/and-items-with-margin-and-mrp-for-stake-holder",
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success && data.data != null) {
          const rowItemData = data.data;
          if (rowItemData.errorMessage.error) {
            showNotification({
              msg: rowItemData.errorMessage.message,
              severity: "error",
            });
          } else {
            let row = [];
            const stockCalAtBill = localStorage.getItem("stockCalAtBill");
            this.setState({
              stockFlag: stockCalAtBill === "1" ? true : false,
            });
            if (stockCalAtBill === "1") {
              row = rowItemData.categoryAndItems.map((categoryProducObject) => {
                let rowData = [];
                let i;
                let j;
                let rsLength =
                  categoryProducObject.productWithMarginAndMrps.length;
                for (i = 0; i < rsLength; i++) {
                  let orderObject = {
                    ...categoryProducObject.productWithMarginAndMrps[i],
                    rate: categoryProducObject.productWithMarginAndMrps[i].rate,
                    primaryClosingQty:
                      categoryProducObject.productWithMarginAndMrps[
                        i
                      ].productStockForMobile.primaryClosingQty.toFixed(2),
                    secondaryClosingQty:
                      categoryProducObject.productWithMarginAndMrps[
                        i
                      ].productStockForMobile.secondaryClosingQty.toFixed(2),
                    qty: "",
                    freeQty: 0,
                    itemDiscPer: 0,
                    total: 0,
                    primaryQty: "",
                    secondaryQty: "",
                    totalQty: 0,
                  };
                  rowData.push(orderObject);
                }
                return {
                  ...categoryProducObject,
                  total: "",
                  qty: 0,
                  productWithMarginAndMrps: rowData,
                };
              });
              this.setCatList({ row });
            } else {
              row = rowItemData.categoryAndItems.map((rowObj) => {
                return {
                  ...rowObj,
                  total: 0,
                  qty: 0,
                  productWithMarginAndMrps: rowObj.productWithMarginAndMrps.map(
                    (product) => {
                      return {
                        ...product,
                        uomConversionValue: product.products.uomConversionValue,
                        qty: 0,
                        freeQty: 0,
                        itemDiscPer: 0,
                        total: 0,
                        primaryQty: "",
                        secondaryQty: "",
                        totalQty: 0,
                      };
                    }
                  ),
                };
              });
              this.setCatList({ row });
            }
          }
        } else {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.stackHolderList !== nextProps.stackHolderList) {
      if (
        this.props.stackHolderList?.stackholder !==
        nextProps.stackHolderList?.stackholder
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            orderDetail: nextProps.stackHolderList?.stackholder,
          },
        });
      }
    }
  }
  handleChange = (event, newValue) => {
    this.setState({
      value: newValue,
    });
  };
  submitHandler = () => {
    if (this.checkValidationOnSubmit()) {
      const { category } = this.props;
      let rowList = [];
      category.category.map((catData) => {
        if (catData.flag == 3) {
          const filterData = catData.productWithMarginAndMrps.filter(
            (row) => row.totalQty != 0 && !row.qtyError
          );
          rowList = [...rowList, ...filterData];
          return catData;
        }
      });

      if (rowList.length != 0) {
        this.setState({
          orderList: rowList.map((rowData, index) => {
            return {
              index: index + 1,
              product: rowData.products !== null ? rowData.products : "",
              subCategory:
                rowData.products !== null &&
                rowData.products.group !== null &&
                rowData.products.group.subCategory !== null &&
                rowData.products.group.subCategory.id
                  ? rowData.products.group.subCategory.id
                  : "",
              category:
                rowData.products !== null &&
                rowData.products.group !== null &&
                rowData.products.group.subCategory !== null &&
                rowData.products.group.subCategory.category !== null &&
                rowData.products.group.subCategory.category.id
                  ? rowData.products.group.subCategory.category.id
                  : "",
              categoryName:
                rowData.products !== null &&
                rowData.products.group !== null &&
                rowData.products.group.subCategory !== null &&
                rowData.products.group.subCategory.category !== null &&
                rowData.products.group.subCategory.category.name
                  ? rowData.products.group.subCategory.category.name
                  : "",
              categoryShortName:
                rowData.products !== null &&
                rowData.products.group !== null &&
                rowData.products.group.subCategory !== null &&
                rowData.products.group.subCategory.category !== null &&
                rowData.products.group.subCategory.category.shortName
                  ? rowData.products.group.subCategory.category.shortName
                  : "",
              categorySortOrder:
                rowData.products !== null &&
                rowData.products.group !== null &&
                rowData.products.group.subCategory !== null &&
                rowData.products.group.subCategory.category !== null &&
                rowData.products.group.subCategory.category.sortOrder
                  ? rowData.products.group.subCategory.category.sortOrder
                  : "",
              isActive:
                rowData.products !== null &&
                rowData.products.group !== null &&
                rowData.products.group.subCategory !== null &&
                rowData.products.group.subCategory.category !== null &&
                rowData.products.group.subCategory.category.isActive
                  ? rowData.products.group.subCategory.category.isActive
                  : "",
              insertDateTime:
                rowData.products !== null &&
                rowData.products.group !== null &&
                rowData.products.group.subCategory !== null &&
                rowData.products.group.subCategory.category !== null &&
                rowData.products.group.subCategory.category.insertDateTime
                  ? rowData.products.group.subCategory.category.insertDateTime
                  : "",
              taxPercentage:
                rowData.products !== null &&
                rowData.products.taxPercentage !== null &&
                rowData.products.taxPercentage.id
                  ? rowData.products.taxPercentage.id
                  : "",
              taxName:
                rowData.products !== null &&
                rowData.products.taxPercentage !== null &&
                rowData.products.taxPercentage.name
                  ? rowData.products.taxPercentage.name
                  : "",
              taxSgst:
                rowData.products !== null &&
                rowData.products.taxPercentage !== null &&
                rowData.products.taxPercentage.sgst
                  ? rowData.products.taxPercentage.sgst
                  : "",
              taxCgst:
                rowData.products !== null &&
                rowData.products.taxPercentage !== null &&
                rowData.products.taxPercentage.cgst
                  ? rowData.products.taxPercentage.cgst
                  : "",
              taxIgst:
                rowData.products !== null &&
                rowData.products.taxPercentage !== null &&
                rowData.products.taxPercentage.igst
                  ? rowData.products.taxPercentage.igst
                  : "",
              taxCess:
                rowData.products !== null &&
                rowData.products.taxPercentage !== null &&
                rowData.products.taxPercentage.cess
                  ? rowData.products.taxPercentage.cess
                  : "",
              hsnCode:
                rowData.products !== null &&
                rowData.products.taxPercentage !== null &&
                rowData.products.taxPercentage.hsnCode
                  ? rowData.products.taxPercentage.hsnCode
                  : "",
              taxInsertDateTime:
                rowData.products !== null &&
                rowData.products.taxPercentage !== null &&
                rowData.products.taxPercentage.insertDateTime
                  ? rowData.products.taxPercentage.insertDateTime
                  : "",
              stakeHolderUom:
                rowData.products !== null &&
                rowData.products.stakeHolderUom !== null &&
                rowData.products.stakeHolderUom.id
                  ? rowData.products.stakeHolderUom.id
                  : "",
              stakeHolderUomName:
                rowData.products !== null &&
                rowData.products.stakeHolderUom !== null &&
                rowData.products.stakeHolderUom.id
                  ? rowData.products.stakeHolderUom.name
                  : "",
              productName:
                rowData.products !== null && rowData.products.name
                  ? rowData.products.name
                  : "",
              outletName:
                rowData.products !== null &&
                rowData.products.stakeHolderUom !== null &&
                rowData.products.stakeHolderUom.name
                  ? rowData.products.stakeHolderUom.name
                  : "",
              outletisActive:
                rowData.products !== null &&
                rowData.products.stakeHolderUom !== null &&
                rowData.products.stakeHolderUom.isActive
                  ? rowData.products.stakeHolderUom.isActive
                  : "",
              outletInsertDateTime:
                rowData.products !== null &&
                rowData.products.stakeHolderUom !== null &&
                rowData.products.stakeHolderUom.insertDateTime
                  ? rowData.products.stakeHolderUom.insertDateTime
                  : "",
              outletUom:
                rowData.products !== null &&
                rowData.products.outletUom !== null &&
                rowData.products.outletUom.id
                  ? rowData.products.outletUom.id
                  : "",
              outletUomName:
                rowData.products !== null &&
                rowData.products.outletUom !== null &&
                rowData.products.outletUom.name
                  ? rowData.products.outletUom.name
                  : "",
              outletUomIsActive:
                rowData.products !== null &&
                rowData.products.outletUom !== null &&
                rowData.products.outletUom.isActive
                  ? rowData.products.outletUom.isActive
                  : "",
              outletUomInsertDateTime:
                rowData.products !== null &&
                rowData.products.outletUom !== null &&
                rowData.products.outletUom.insertDateTime
                  ? rowData.products.outletUom.insertDateTime
                  : "",
              uomConversionValue:
                rowData.products !== null && rowData.products.uomConversionValue
                  ? rowData.products.uomConversionValue
                  : 0,
              productCode:
                rowData.products !== null && rowData.products.code
                  ? rowData.products.code
                  : "",

              shortName:
                rowData.products !== null && rowData.products.shortName
                  ? rowData.products.shortName
                  : "",
              weight:
                rowData.products !== null && rowData.products.weight
                  ? rowData.products.weight
                  : "",
              description:
                rowData.products !== null && rowData.products.description
                  ? rowData.products.description
                  : "",
              selfLife:
                rowData.products !== null && rowData.products.selfLife
                  ? rowData.products.selfLife
                  : "",
              image:
                rowData.products !== null && rowData.products.image
                  ? rowData.products.image
                  : "",
              sortOrder:
                rowData.products !== null && rowData.products.sortOrder
                  ? rowData.products.sortOrder
                  : "",
              isActive:
                rowData.products !== null && rowData.products.isActive
                  ? rowData.products.isActive
                  : "",
              insertDateTime:
                rowData.products !== null && rowData.products.insertDateTime
                  ? rowData.products.insertDateTime
                  : "",
              mrp:
                rowData.products !== null && rowData.products.mrp
                  ? rowData.products.mrp
                  : "",
              marginPercentage:
                rowData.marginPercentage !== null
                  ? (+rowData.marginPercentage).toFixed(2)
                  : 0,
              mrp: rowData.mrp !== null ? (+rowData.mrp).toFixed(2) : 0,
              qty: rowData.qty !== null ? rowData.qty : 0,
              rate:
                rowData.rate !== null
                  ? (+rowData.rate).toFixed(2) +
                    "/" +
                    (
                      +rowData.rate / +rowData.products.uomConversionValue
                    ).toFixed(2)
                  : 0,
              totalQty: rowData.totalQty,
              rateForSave:
                rowData.rate !== null
                  ? (
                      +rowData.rate / +rowData.products.uomConversionValue
                    ).toFixed(2)
                  : 0,
              total: rowData.total.toFixed(2),
            };
          }),
        });
        this.toggle();
      } else {
        this.props.showNotification({ msg: atleastOne, severity: "error" });
      }
    }
  };

  checkValidationOnSubmit = () => {
    const { fieldData } = this.state;
    const mandatoryCheckErrors = mandatoryCheck({
      fieldData,
      fieldMeta: purchaseReturnJson.fieldMeta,
    });
    this.setState({
      formErrors: mandatoryCheckErrors.formErrors,
    });
    if (mandatoryCheckErrors.formValid) {
      const ValidationCheckErrors = ValidationCheck({
        fieldData,
        fieldMeta: purchaseReturnJson.fieldMeta,
      });
      this.setState({
        formErrors: ValidationCheckErrors.formErrors,
      });
      return ValidationCheckErrors.formValid;
    } else {
      return false;
    }
  };
  getOrder = () => {
    const { orderList, fieldData, remark } = this.state;
    const { requestGRNList } = this.props;
    const grnGvnDetails = orderList.map((orderData) => {
      return {
        product: orderData.product,
        mrp: orderData.mrp,
        rate: orderData.rateForSave,
        secondaryQty: orderData.totalQty,
        qty: orderData.totalQty / orderData.product.uomConversionValue,
        batchCode: "0",
        billDetailId: "0",
        hsnCode: orderData.product.taxPercentage.hsnCode,
        stockStakeHolderBillDetailId: "0",
      };
    });
    let dataToSave = "";
    if (this.props.params.id) {
      let existingDetailList = [];
      if (
        requestGRNList.requestGRNHeaderDetailsData.grnGvnDetails.length !== 0
      ) {
        existingDetailList =
          requestGRNList.requestGRNHeaderDetailsData.grnGvnDetails.map(
            (grnDetailObject) => {
              grnDetailObject = {
                ...grnDetailObject,
                secondaryQty: grnDetailObject.secQty,
                qty: grnDetailObject.secQty,
              };
              return grnDetailObject;
            }
          );
      }

      dataToSave = {
        id: this.props.params.id,
        remark: remark,
        grnGvnType:1,
        instockOutstock:
          requestGRNList.requestGRNHeaderDetailsData.instockOutstock,
        grnGvnDetails: [...existingDetailList, ...grnGvnDetails],
        date: requestGRNList.requestGRNHeaderDetailsData.date,
      };
    } else {
      dataToSave = {
        remark: remark,
        grnGvnType:1,
        instockOutstock: "0",
        grnGvnDetails: grnGvnDetails,
        date: dayjs().format("DD-MM-YYYY"),
      };
    }
    console.log(dataToSave);
    swal({
      title: "Are you sure?",
      text: saveWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onSave(dataToSave);
      }
    });
  };
  onSave = (dataToSave) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      this.toggle();
      showLoador({ loador: true });
      apiPost({
        url: endpoint.grnGVNHeader,
        postBody: dataToSave,
      }).then(({ data, success }) => {
        if (success) {
          showLoador({ loador: false });
          this.props.navigate("/request-grn-list");
          showNotification({ msg: savemsg });
        } else {
          showLoador({ loador: false });
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  onDataChange = (fieldName, newValue) => {
    if (fieldName == "DiscountPercentage") {
      let discountAmt = (this.state.total * +newValue) / 100;
      this.setState({
        totalAmt: +this.state.total - +discountAmt,
      });
    }
    const { fieldData } = this.state;
    const dataToSearch = {};
    const dataToReset = { ...this.state.fieldData };
    let listError = false;
    purchaseReturnJson.fieldMeta.map((currentField) => {
      if (currentField.getDate && currentField.dataKey === fieldName) {
        dataToReset[currentField.getDate] = newValue.add(365, "day");
      }
      if (currentField.dataKey === fieldName && currentField.getListId) {
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
          return null;
        });
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key == fieldName && dataToReset[key]) {
            delete dataToReset[currentField.dataKey];
          }
        });
      }
      if (
        !listError &&
        fieldData[currentField.dataKey] &&
        currentField.controlType != "datepicker" &&
        currentField.getListFrom == undefined
      ) {
        dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
      }
      return null;
    });
    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
    });
  };
  toggle = () => {
    this.setState({
      open: !this.state.open,
    });
  };
  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };
  changeHandler = (e) => {
    const { name, value } = e.target;
    if (name === "remark") {
      this.setState({ remark: value });
    }
  };

  getFilteredTableData = (tableData) => {
    const { searchValue } = this.state;
    return tableData.filter((currentRow) => {
      let isValid = false;
      let productName =
        currentRow.products != null ? currentRow.products.name : "-";
      let stakeHolderUom =
        currentRow.products.stakeHolderUom != null
          ? currentRow.products.stakeHolderUom.name
          : "-";
      // let rate = currentRow.rate != null ? currentRow.rate : "-";
      // let qty = currentRow.qty != null ? currentRow.qty : "-";
      if (
        productName &&
        productName.toLowerCase().includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      if (
        stakeHolderUom &&
        stakeHolderUom.toLowerCase().includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      // if (rate && rate.toLowerCase().includes(searchValue.toLowerCase())) {
      //   isValid = true;
      //   return true;
      // }
      // if (qty && qty.toLowerCase().includes(searchValue.toLowerCase())) {
      //   isValid = true;
      //   return true;
      // }
      return isValid;
    });
  };
  render() {
    const {
      dynamicMasterData,
      DataToSet,
      value,
      total,
      totalAmt,
      open,
      orderList,
      formErrors,
      fieldData,
      stockFlag,
      remark,
      totalOfQty,
    } = this.state;
    const { category, remarkField, params } = this.props;

    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <DynamicFormWithoutValidation
            showBackToList={false}
            getListById={this.getListById}
            dynamicMasterData={dynamicMasterData}
            showTitle={false}
            screenTitle={false}
            fieldMeta={purchaseReturnJson.fieldMeta}
            apiBaseURL={purchaseReturnJson.apiBaseURL}
            formErrors={formErrors}
            showSaveBtn={purchaseReturnJson.showSaveBtn}
            showSaveBtnMain={false}
            fieldData={fieldData}
            onDataChange={this.onDataChange}
            DataToSet={DataToSet}
          />

          {category.category.length != 0 && (
            <>
              <Tabs
                value={value}
                onChange={this.handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="scrollable force tabs example"
              >
                {category.category.map((catData) => {
                  return (
                    <Tab
                      label={
                        catData.category.name +
                        " ( " +
                        catData.productWithMarginAndMrps.length +
                        " )"
                      }
                    />
                  );
                })}
              </Tabs>
              {category.category.map((catData, index) => {
                return (
                  <TabPanel value={value} index={index}>
                    <LandingScreenHeader
                      screenTitle={""}
                      showSearchBox={true}
                      showTable={true}
                      showFilter={false}
                      showPdfDownload={false}
                      showExcelDownload={false}
                      onSearch={this.onSearch}
                    />
                    <PurchaseReturnTable
                      total={total}
                      tableHead={[
                        {
                          title: "Sr.No.",
                          name: "index",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "center",
                        },
                        {
                          title: "Product Name",
                          name: "name",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "left",
                        },
                        {
                          title: "UOM",
                          name: "name",
                          positionCenter: true,
                          showInscreen: true,
                        },
                        {
                          title: "Primary Rate / Rate Per Unit",
                          name: "name",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "left",
                        },
                        {
                          title: "Stock",
                          name: "primaryClosingQty",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "left",
                        },
                        {
                          title: "Primary UOM",
                          name: "target",
                          placeHolder: "Enter Target",
                          textFieldError: "targetErr",
                          positionCenter: false,
                          showInscreen: true,
                        },
                        {
                          title: "Secondary UOM",
                          name: "target",
                          placeHolder: "Enter Target",
                          textFieldError: "targetErr",
                          positionCenter: false,
                          showInscreen: true,
                        },
                        {
                          title: "Total Qty",
                          name: "target",
                          placeHolder: "Enter Target",
                          textFieldError: "targetErr",
                          positionCenter: false,
                          showInscreen: true,
                        },
                        {
                          title: "Total",
                          name: "name",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "left",
                        },
                      ]}
                      data={this.getFilteredTableData(
                        catData.productWithMarginAndMrps
                      )}
                      rowList={category.category}
                      setList={this.setCatList}
                      flag={stockFlag}
                    />
                  </TabPanel>
                );
              })}
              <br />
              <DynamicDetails
                DataToSet={[
                  {
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 6,
                    label: "Total",
                    value: total.toFixed(2),
                  },
                  {
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 6,
                    label: "Total Quantity",
                    value: totalOfQty,
                  },
                ]}
              />

              <Grid container alignItems="center">
                <Grid item xs={12} md={2.5} sm={2.5}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: titleColor, marginLeft: 15 }}
                    label="Add remark:"
                  />
                </Grid>
                <Grid item xs={12} md={9.5} sm={9.5}>
                  <TextFieldCompo
                    placeholder="Add remark"
                    color="primary"
                    name="remark"
                    size="small"
                    value={remark}
                    fullWidth
                    onChange={this.changeHandler}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  sx={{ justifyContent: "flex-end" }}
                >
                  <ButtonCompo
                    size="medium"
                    type="submit"
                    variant="contained"
                    name={"Proceed"}
                    onClick={this.submitHandler}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Paper>
        {open && (
          <SubmitPurchaseReturn
            formData={{
              total: total,
              discount: fieldData.DiscountPercentage
                ? fieldData.DiscountPercentage
                : 0,
              totalAmt: totalAmt,
              totalOfQty: totalOfQty,
            }}
            columns={[
              {
                title: "Sr. No.",
                name: "index",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "center",
              },
              {
                title: "Product Name",
                name: "productName",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
                canSearch: true,
              },
              {
                title: "UOM",
                name: "outletName",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "center",
                canSearch: true,
              },
              {
                title: "Primary Rate / Rate Per Unit",
                name: "rate",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "right",
                canSearch: true,
              },
              {
                title: "Quantity",
                name: "totalQty",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "right",
                canSearch: true,
              },

              {
                title: "Total",
                name: "total",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "right",
                canSearch: true,
              },
            ]}
            open={open}
            handleCloses={this.toggle}
            rows={orderList}
            getOrder={this.getOrder}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  stackHolderList: state.stackholder,
  areaList: state.area,
  outletList: state.outlet,
  beatList: state.beat,
  outletOrderDetail: state.outletOrderDetail,
  salesExecutive: state.salesExecutive,
  category: state.category,
  requestGRNList: state.requestGRN,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getBeatByArea,
  getActiveOutletByBeatId,
  setCategoryAndItemForCreditNote,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PurchaseReturnForm);
