import {
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import { redColor, titleColor } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import {
  checkedListEmptyMsg,
  deleteErrorMsg,
  incompleteAssignErrorMsg,
  maxRecordMsg,
  nameMsg,
  noInternetMsg,
  saveFailedMsg,
  savemsg,
  saveWarningMsg,
  serverMsg,
} from "../../config/messageconstant";
import { getAllActiveProduct } from "../../Slice/product.slice";
import { getYear } from "../../Slice/year.slice";
import { apiGet, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import RUCreationAndAssignationDetailTable from "./RUCreationAndAssignationDetailTable";
import RUProductPopup from "./RUProductPopup";
import AssignationForm from "./AssignationForm";

const RUCreationAndAssignationForm = ({
  showLoador,
  showNotification,
  getYear,
  yearList,
  productList,
  getAllActiveProduct,
}) => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [type, setType] = useState("1");
  const [openProductPopup, setOpenProductPopup] = useState(false);
  const [showAssignForm, setShowAssignForm] = useState(false);
  const [clearRuQtyOnProdPopUp, setClearRuQtyOnProdPopUp] = useState(false);
  const [yearObj, setYearObj] = useState({});
  const [rowList, setRowList] = useState([]);
  const [prodList, setProdList] = useState([]);
  const [dataById, setDataById] = useState([]);
  const [_minDate, setMinDate] = useState(null);
  const [_maxDate, setMaxDate] = useState(null);
  const [toMinDate, setToMinDate] = useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [toMaxDate, setToMaxDate] = useState(null);
  const [formErrors, setFormErrors] = React.useState({});
  const saveResponse = useRef(null);
  const params = useParams();

  const changeHandler = (e) => {
    const { name, value } = e.target;


    if (name === "name") {
      setName(value);
    } else if (name === "type") {

      if (value == 0) {
        setClearRuQtyOnProdPopUp(true);
      }

      setType(value);
      const updatedRows = rowList.filter(row => row.index == 1).map((row) => {
        return {
          ...row,
          ruQty: value == 0 ? "0" : row.ruQty,
          productCount: "0",
        };
      });
      setRowList(updatedRows);
    }
  };

  const changeHandlerForDropdown = (name, dropdown, newValue) => {
    setFormErrors({});
    if (name === "year") {
      if (newValue) {
        setYearObj(newValue);
        const [day, month, year] = newValue.fromDate?.split("-").map(Number);
        const [toDay, toMonth, toYear] = newValue.toDate?.split("-").map(Number);
        const newFromDate = dayjs(new Date(year, month - 1, day));
        const newToDate = dayjs(new Date(toYear, toMonth - 1, toDay));
        const newToMinDate = dayjs(new Date(year, month - 1, day + 1));
        setMinDate(newFromDate);
        setToMinDate(newToMinDate);
        setMaxDate(newToDate);
        setToMaxDate(newToDate);
        if (
          params.id &&
          dataById.yearId === newValue.id &&
          dataById.retailUnitWiseTargetDetail.length !== 0
        ) {
          const [day, month, year] = newValue.fromDate?.split("-").map(Number);
          const [toDay, toMonth, toYear] = newValue.toDate
            ?.split("-")
            .map(Number);
          const newFromDate = dayjs(new Date(year, month - 1, day));
          const newToDate = dayjs(new Date(toYear, toMonth - 1, toDay));
          setMinDate(newFromDate);
          setMaxDate(newToDate);
          setToMaxDate(newToDate);
          const editRowList = dataById.retailUnitWiseTargetDetail.map(
            (editItem) => {
              const [toDay, toMonth, toYear] = editItem.toDate
                ?.split("-")
                .map(Number);
              const [day, month, year] = editItem.fromDate
                ?.split("-")
                .map(Number);
              const newFromDate = dayjs(new Date(year, month - 1, day + 1));
              setToMinDate(newFromDate);
              const editToDate = dayjs(new Date(toYear, toMonth - 1, toDay));
              return {
                ...editItem,
                frmDt: editItem.fromDate,
                toDt: editToDate,
                index: editItem.sortOrder,
              };
            }
          );
          setRowList(editRowList);
        } else {
          setRowList([
            {
              index: 1,
              name: "",
              frmDt: newValue.fromDate,
              fromDate: newValue.fromDate,
              toDt: dayjs(newFromDate).add(1, 'day'),
              ruQty: type === "1" ? "1" : "0",
              productCount: "0",
              sortOrder: 1,
            },
          ]);
        }
      }

    }
  };

  const checkValidation = () => {
    let formErrors = {};
    let formIsValid = true;

    if (name === "") {
      formIsValid = false;
      formErrors["nameError"] = nameMsg;
    }
    setFormErrors(formErrors);
    return formIsValid;
  };

  useEffect(() => {
    if (navigator.onLine) {
      showLoador({ loador: true });
      getYear().then(({ success, response }) => {
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          if (params.id) {
            apiGet({
              url: endpoint.ruTargetHeader + "/by-id?id=" + params.id,
            }).then((data) => {
              if (data) {
                if (data && data.data && data.data.data) {

                  setDataById(data.data.data);
                  setName(data.data.data.name !== null ? data.data.data.name : "-");
                  setType(data.data.data.targetType !== null ?
                    data.data.data.targetType.toString() : "-");


                  const editYearObj = response.find(
                    (yearItem) => yearItem.id === data.data.data.yearId
                  );
                  setYearObj(editYearObj);
                  const [day, month, year] = editYearObj.fromDate
                    ?.split("-")
                    .map(Number);
                  const [toDay, toMonth, toYear] = editYearObj.toDate
                    ?.split("-")
                    .map(Number);
                  const newFromDate = dayjs(new Date(year, month - 1, day));
                  const newToDate = dayjs(new Date(toYear, toMonth - 1, toDay));
                  setMinDate(newFromDate);
                  setMaxDate(newToDate);
                  setToMaxDate(newToDate);
                  const editRowList =
                    data.data.data.retailUnitWiseTargetDetail.map(
                      (editItem, index) => {
                        const [day, month, year] = editItem.fromDate
                          ?.split("-")
                          .map(Number);
                        const newFromDate = dayjs(
                          new Date(year, month - 1, day + 1)
                        );
                        setToMinDate(newFromDate);
                        const [toDay, toMonth, toYear] = editItem.toDate
                          ?.split("-")
                          .map(Number);
                        const editToDate = dayjs(
                          new Date(toYear, toMonth - 1, toDay)
                        );
                        return {
                          ...editItem,
                          index: index + 1,
                          frmDt: editItem.fromDate,
                          toDt: editToDate,
                        };
                      }
                    );
                  setRowList(editRowList);
                }
              }
            });
          }
        }
      });

      getAllActiveProduct().then(({ success }) => {
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: false });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }, []);

  useEffect(() => {
    setProdList(productList?.activeAllProduct);
  }, [productList]);

  const onCancel = () => {
    navigate(-1);
  };

  const totalRuQty = useMemo(() => {
    return rowList.reduce(
      (sum, item) => sum + parseInt(item.ruQty || 0, 10),
      0
    );
  }, [rowList]);

  const handleRowChange = (index, field, value) => {
    const updatedRows = rowList.map((row) => {
      if (row.index === index) {
        if (field === "toDt") {
          value = value ? value : null;
        }
        return {
          ...row,
          [field]: value,
          toDate: field === "toDt" && dayjs(value).format("DD-MM-YYYY"),
        };
      }
      return row;
    });
    setRowList(updatedRows);
  };

  const handlePopupRowChange = (index, field, value) => {
    const updatedRows = prodList.map((row) => {
      if (row.index === index) {
        return {
          ...row,
          [field]: value,
        };
      }
      return row;
    });
    setProdList(updatedRows);
  };

  const validateRow = (row) => {
    let errors = {};

    console.log(row);

    if (!row.name || row.name.trim() === "") {
      errors.nameError = "Name is required.";
    }

    if (!row.toDt || row.toDt === "") {
      errors.toDtError = "To Date is required.";
    }

    if (type != 0) {
      if (row.ruQty === "") {
        errors.ruQtyError = "RU qty is required.";
      } else if (row.ruQty < 1) {
        errors.ruQtyError = "RU qty should be more than zero.";
      }

    }

    return errors;
  };

  const handleAddRow = () => {
    const lastRow = rowList[rowList.length - 1];

    const errors = validateRow(lastRow);

    let newFromDateObj = null;

    if (Object.keys(errors).length > 0) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [lastRow.index]: errors,
      }));
      return;
    }

    let newFromDate = "";
    if (lastRow && lastRow.toDt) {
      newFromDateObj = dayjs(lastRow.toDt).add(1, "day");
      newFromDate = newFromDateObj.format("DD-MM-YYYY");
      const [toDay, toMonth, toYear] = newFromDate?.split("-").map(Number);
      const newToMinDate = dayjs(new Date(toYear, toMonth - 1, toDay + 1));
      setToMinDate(newToMinDate);
      if (dayjs(lastRow.toDt).format("DD-MM-YYYY") === yearObj.toDate) {
        showNotification({ msg: maxRecordMsg, severity: "error" });
        return;
      }
    }

    setRowList((prevRowList) => [
      ...prevRowList,
      {
        index: prevRowList.length + 1,
        name: "",
        frmDt: newFromDate,
        fromDate: newFromDate,
        toDt: dayjs(newFromDateObj).add(1, "day"),
        toDate: dayjs(lastRow.toDt).format("DD-MM-YYYY"),
        ruQty: type === "1" ? "1" : "0",
        productCount: "0",
        sortOrder: prevRowList.length + 1,
      },
    ]);
  };

  const handleDeleteRow = () => {
    if (rowList.length > 1) {
      const updatedRowList = rowList.slice(0, -1);
      setRowList(updatedRowList);
    } else {
      showNotification({ msg: deleteErrorMsg, severity: "error" });
    }
  };

  const handleOpenProductPopup = (index, row) => {
    setSelectedRowIndex(index);

    if (!row.retailUnitWiseTargetSubDetail) {
      setProdList(productList?.activeAllProduct);
    } else {

      const updatedProdList = productList?.activeAllProduct.map(product => {
        const updatedProduct = row.retailUnitWiseTargetSubDetail.find(
          subDetail => subDetail.product.id === product.id
        );
        if (updatedProduct) {
          return {
            ...product,
            ruQty: clearRuQtyOnProdPopUp ? "" : updatedProduct.ruQty
          };
        }
        return product;
      });

      setProdList(updatedProdList);
    }

    setOpenProductPopup(true);
  };


  const handleCloses = () => {
    setOpenProductPopup(false);
  };

  const submitData = () => {

    setClearRuQtyOnProdPopUp(false)
    const totalQty = prodList.reduce(
      (sum, product) => sum + parseInt(product.ruQty || 0, 10),
      0
    );
    const productCount = prodList.filter((product) => product.ruQty > 0).length;

    const formattedProductList = prodList
      .filter((product) => product.ruQty > 0)
      .map((product) => ({
        product: { id: product.id },
        ruQty: product.ruQty,
      }));

    if (productCount > "0" && totalQty > "0") {
      const updatedRows = rowList.map((row) => {
        if (row.index === selectedRowIndex) {
          return {
            ...row,
            ruQty: totalQty.toString(),
            productCount: productCount.toString(),
            retailUnitWiseTargetSubDetail: formattedProductList,
          };
        }
        return row;
      });
      setRowList(updatedRows);
      setOpenProductPopup(false);
    } else {
      showNotification({ msg: checkedListEmptyMsg, severity: "error" });
    }
  };
  console.debug(params.copyFlag);
  const saveOfferData = (flag) => {
    const lastRow = rowList[rowList.length - 1];
    if (navigator.onLine) {
      if (rowList.length !== 0) {
        if (yearObj.toDate === dayjs(lastRow.toDt).format("DD-MM-YYYY")) {
          const errors = validateRow(lastRow);

          if (Object.keys(errors).length > 0) {
            setFormErrors((prevErrors) => ({
              ...prevErrors,
              [lastRow.index]: errors,
            }));
            return;
          }
          let dataToSave = {};
          if (params.id && !params.copyFlag) {
            dataToSave = {
              id: params.id,
              name: name,
              year: {
                id: yearObj.id,
              },
              targetType: type,
              ruQty: totalRuQty,
              retailUnitWiseTargetDetail: rowList.map((rowItem) => {
                return {
                  ...rowItem,
                  toDate: dayjs(rowItem.toDt).format("DD-MM-YYYY"),
                };
              }),
            };
          } else {
            dataToSave = {
              name: name,
              year: {
                id: yearObj.id,
              },
              targetType: type,
              ruQty: totalRuQty,
              retailUnitWiseTargetDetail: rowList.map((rowItem) => {
                const { id, retailUnitWiseTargetHeaderId, ...restRowItem } =
                  rowItem;
                return {
                  ...restRowItem,
                  toDate: dayjs(rowItem.toDt).format("DD-MM-YYYY"),
                  retailUnitWiseTargetSubDetail:
                    rowItem.retailUnitWiseTargetSubDetail &&
                    rowItem.retailUnitWiseTargetSubDetail.map((subItem) => {
                      const {
                        id,
                        retailUnitWiseTargetDetailId,
                        ...restSubItem
                      } = subItem;
                      return restSubItem;
                    }),
                };
              }),
            };
          }
          console.debug(dataToSave);
          if (checkValidation()) {
            swal({
              title: "Are you sure?",
              text: saveWarningMsg,
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                showLoador({ loador: true });
                apiPost({
                  url: endpoint.ruTargetHeader + "/save",
                  postBody: dataToSave,
                }).then(({ success, data }) => {
                  showLoador({ loador: false });
                  if (success) {
                    saveResponse.current = data.data;
                    showNotification({ msg: savemsg });
                    setRowList([]);
                    setName("");
                    setYearObj({});
                    if (flag === 2) {
                      onCancel();
                    } else {
                      setShowAssignForm(true);
                    }
                  } else {
                    showNotification({ msg: saveFailedMsg, severity: "error" });
                  }
                });
              }
            });
          }
        } else {
          showNotification({
            msg: incompleteAssignErrorMsg,
            severity: "error",
          });
        }
      } else {
        showNotification({ msg: checkedListEmptyMsg, severity: "error" });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  return (
    <>
      <Paper
        sx={{
          p: 2,
          borderRadius: 2,
        }}
        elevation={0}
      >
        <LandingScreenHeader
          screenTitle={"RU Creation & Assignation"}
          showTable={false}
          showBackButton={true}
          showAddButton={false}
          onCancel={onCancel}
        />
        <div>
          <br></br>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="space-between"
          >
            <Grid
              item
              sm={12}
              xs={12}
              md={4}
              lg={4}
              container
              justifyContent="space-between"
            >
              <Grid item xs={12} md={2.5} sm={2.5}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: redColor }}
                  label="* "
                />
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: titleColor }}
                  label="Name :"
                />
              </Grid>
              <Grid item xs={12} md={9.5} sm={9.5}>
                <TextFieldCompo
                  placeHolder={"Name"}
                  color="primary"
                  name="name"
                  size="small"
                  value={name}
                  fullWidth
                  onChange={changeHandler}
                  errorText={
                    formErrors["nameError"] ? formErrors["nameError"] : ""
                  }
                />
              </Grid>
            </Grid>

            <Grid
              item
              sm={12}
              xs={12}
              md={4}
              lg={4}
              container
              justifyContent="space-between"
            >
              <Grid item xs={12} md={2.5} sm={2.5}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: redColor }}
                  label="* "
                />
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: titleColor }}
                  label="Year :"
                />
              </Grid>
              <Grid item xs={12} md={9.5} sm={9.5}>
                <AutoComplete
                  keyColName={"id"}
                  placeHolder="Select Year"
                  value={yearObj ? yearObj.id : ""}
                  name={"year"}
                  options={yearList?.year}
                  errorText={
                    formErrors["yearError"] ? formErrors["yearError"] : ""
                  }
                  isError={
                    formErrors["yearError"] ? formErrors["yearError"] : ""
                  }
                  onChange={changeHandlerForDropdown}
                />
              </Grid>
            </Grid>

            <Grid
              item
              sm={12}
              xs={12}
              md={4}
              lg={4}
              container
              justifyContent="space-between"
            >
              <Grid item xs={12} md={2.5} sm={2.5}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: titleColor }}
                  label="RU Qty :"
                />
              </Grid>
              <Grid item xs={12} md={9.5} sm={9.5}>
                <TextFieldCompo
                  placeHolder={"RU Qty"}
                  color="primary"
                  name="totalRuQty"
                  size="small"
                  value={totalRuQty}
                  fullWidth
                  onChange={changeHandler}
                  disabled={true}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="space-between"
          >
            <Grid item xs={12} md={0.8} sm={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16, color: redColor }}
                label="* "
              />
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16, color: titleColor }}
                label="Type :"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={11.2}>
              <RadioGroup
                row
                aria-label={"Type"}
                name={"type"}
                id={"type"}
                value={type}
                onChange={changeHandler}
                size="small"
              >
                <FormControlLabel
                  value={"0"}
                  control={<Radio />}
                  label={"SKU & RU Wise"}
                />
                <FormControlLabel
                  value={"1"}
                  control={<Radio />}
                  label={"RU Wise"}
                />
              </RadioGroup>
            </Grid>
          </Grid> */}
          <Divider />
          <br />

          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="space-between"
          >
            <RUCreationAndAssignationDetailTable
              rowList={rowList}
              handleRowChange={handleRowChange}
              handleAddRow={handleAddRow}
              toMinDate={toMinDate}
              toMaxDate={toMaxDate}
              formErrors={formErrors}
              type={type}
              handleDeleteRow={handleDeleteRow}
              handleOpenProductPopup={handleOpenProductPopup}
            />
          </Grid>

          <DialogActions>
            <Grid
              item
              justifyContent={"flex-end"}
              gap={2}
              xs={12}
              sm={12}
              md={1}
              flexDirection={"row"}
              display={"flex"}
            >
              {!params.id && (
                <Grid justifyContent={"flex-end"} item>
                  <ButtonCompo
                    size="medium"
                    onClick={() => saveOfferData(1)}
                    variant="contained"
                    name="Save & Assign"
                  />
                </Grid>
              )}

              <Grid justifyContent={"flex-end"} item>
                <ButtonCompo
                  size="medium"
                  onClick={() => saveOfferData(2)}
                  variant="contained"
                  name={params.id && !params.copyFlag ? "Update" : "Save"}
                />
              </Grid>
            </Grid>
          </DialogActions>
        </div>

        <RUProductPopup
          openProductPopup={openProductPopup}
          data={prodList}
          handleCloses={handleCloses}
          handlePopupRowChange={handlePopupRowChange}
          submitData={submitData}
        />
        {showAssignForm && (
          <>
            <Divider
              style={{
                marginTop: "0.7%",
              }}
            />
            <br />
            <AssignationForm saveResponse={saveResponse.current} />
          </>
        )}
      </Paper>
    </>
  );
};
const mapStateToProps = (state) => ({
  productList: state.product,
  yearList: state.year,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getAllActiveProduct,
  getYear,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RUCreationAndAssignationForm);
