import { Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import DynamicFormWithoutSave from "../../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../../components/withRouter";
import endpoint from "../../../config/endpoints";
import {
    noInternetMsg,
    saveFailedMsg,
    savemsg,
    saveWarningMsg,
    serverMsg,
} from "../../../config/messageconstant";
import { getAreaByUserType } from "../../../Slice/area.slice";
import {
    getBeatBySalesTeamIdForAssign,
    setBeatBySalesTeamIdAreaId,
} from "../../../Slice/beat.slice";
import { getOutletByBeatId } from "../../../Slice/outlet.slice";
import { getSalesTeamByUserTypeAndTaluka } from "../../../Slice/salesExecutive.slice";
import { getStockStakeHolderType } from "../../../Slice/stockStakeHolderType.slice";
import { apiGet, apiPost } from "../../../utils/api_service";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import { AreaMasterJson } from "../../../DynamicFormsJson/MastersJSON/areaMasterJson";

class AssignAreaToManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            moduleObjDataObj: {},
            isLog: true,
            areaId: "",
            talukaId: "",
            dynamicMasterData: {
                stockHolderTypeDetail:
                    this.props.stakeHolderTypeList?.stockStakeHolderType,
                stockHolderNameDetail:
                    this.props.salesExecutiveList?.salesTeamByUserTypeAndTaluka,
            },
        };
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.stakeHolderTypeList !== nextProps.stakeHolderTypeList) {
            if (
                this.props.stakeHolderTypeList?.stockStakeHolderType !==
                nextProps.stakeHolderTypeList?.stockStakeHolderType
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        stockHolderTypeDetail:
                            nextProps.stakeHolderTypeList?.stockStakeHolderType,
                    },
                });
            }
        }
        if (this.props.salesExecutiveList !== nextProps.salesExecutiveList) {
            if (
                this.props.salesExecutiveList?.salesTeamByUserTypeAndTaluka !==
                nextProps.salesExecutiveList?.salesTeamByUserTypeAndTaluka
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        stockHolderNameDetail:
                            nextProps.salesExecutiveList?.salesTeamByUserTypeAndTaluka,
                    },
                });
            }
        }
    }

    async componentDidMount() {
        const { getStockStakeHolderType } = this.props;

        const moduleObjData = localStorage.getItem("moduleObj");
        const moduleObjDataObj = moduleObjData != null ? JSON.parse(moduleObjData) : {};

        this.setState({ moduleObjDataObj });

        if (this.props.params.id) {

            showLoador({ loador: true });
            await apiGet({
                url: AreaMasterJson.apiBaseURL + "/" + this.props.params.id,
            }).then(({ data, success }) => {
                showLoador({ loador: false });
                if (success) {
                    if (data && data.data) {
                        this.setState({
                            areaId: data.data.id !== null ? data.data.id : "",
                            talukaId: data.data.taluka !== null && data.data.taluka.id !== null ?
                                data.data.taluka.id : "",
                        })
                    }
                }
            });
        }

        showLoador({ loador: true });
        await getStockStakeHolderType({ userTypeIds: "3,4,6" }).then(({ success }) => {
            showLoador({ loador: false });
            if (!success) {
                showNotification({ msg: serverMsg, severity: "error" });
            }
        });
    }

    getListById = async (data) => {
        const { getSalesTeamByUserTypeAndTaluka, showLoador, showNotification, } =
            this.props;

        const { talukaId } = this.state;

        if (navigator.onLine) {


            if (data.stockHolderType != "" && data.stockHolderType) {

                let dataJson = {
                    stockStakeHolderTypeIds: typeof data.stockHolderType === "string" ?
                        data.stockHolderType.split(",") : data.stockHolderType,
                    talukaIds: talukaId.split(",")
                }
                showLoador({ loador: true });
                await getSalesTeamByUserTypeAndTaluka({ jsonForSave: dataJson }).then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                }
                );
            }
        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };

    onSubmit = (data) => {
        const { areaId } = this.state;

        let dataToSaveOnSubmit = {
            salesTeamIds: data.stockHolderName.split(","),
            areaId: areaId,
        };

        swal({
            title: "Are you sure?",
            text: saveWarningMsg,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {

                this.onSave(dataToSaveOnSubmit);
            }
        });
    };
    onSave = (dataToSaveOnSubmit) => {
        const { showLoador, showNotification } = this.props;
        const { moduleObjDataObj, isLog } = this.state;

        if (navigator.onLine) {
            showLoador({ loador: true });
            apiPost({
                url: endpoint.salesExecutive + "/update-areas",
                postBody: dataToSaveOnSubmit,
            }).then(({ data, success }) => {
                showLoador({ loador: false });
                if (success) {
                    if (data && data.data && data.data.statusCode == 200) {
                        if (isLog) {
                            if (data && data.data !== null) {

                                let updatedJsonToSend = JSON.stringify(data.data);
                                let oldJsonToSend = JSON.stringify(dataToSaveOnSubmit);

                                let dataToSaveForMLog = {
                                    module: {
                                        moduleId: moduleObjDataObj.moduleId
                                    },
                                    subModule: {
                                        subModuleId: moduleObjDataObj.subModuleId
                                    },
                                    operationType: this.props.params.id ? 1 : 0,
                                    type: 1,
                                    operationPerformId: dataToSaveOnSubmit.areaId,
                                    subOperationPerformIds: dataToSaveOnSubmit.salesTeamIds.join(","),
                                    updatedJson: updatedJsonToSend,
                                    oldJson: oldJsonToSend,
                                }

                                showLoador({ loador: true });
                                apiPost({
                                    url: endpoint.mlog,
                                    postBody: dataToSaveForMLog,
                                }).then(({ success }) => {
                                    showLoador({ loador: false });
                                    if (success) {
                                    } else {
                                        showNotification({
                                            msg: saveFailedMsg,
                                            severity: "error",
                                        });
                                    }
                                });
                            }
                        }
                        this.props.navigate("/area-master");
                        showNotification({ msg: data.data.message });
                    } else {
                        showNotification({ msg: data.data.message });
                    }

                } else {
                    showNotification({ msg: saveFailedMsg, severity: "error" });
                }
                return success;
            });
        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };

    render() {
        const { dynamicMasterData } = this.state;

        return (
            <>
                <Paper
                    sx={{
                        p: 2,
                        borderRadius: 2,
                    }}
                    elevation={0}
                >

                    <DynamicFormWithoutSave
                        showBackToList={true}
                        getListById={this.getListById}
                        dynamicMasterData={dynamicMasterData}
                        showTitle={false}
                        screenTitle={""}
                        fieldMeta={AreaMasterJson.fieldMetaForManagerAreaAssignation}
                        showCancel={AreaMasterJson.showCancel}
                        apiBaseURL={AreaMasterJson.apiBaseURL}
                        showSaveNextBtn={AreaMasterJson.showSaveNextBtn}
                        onSave={this.onSubmit}
                        showSaveBtnMain={false}
                        showSaveBtn={this.props.params.id ? true : false}
                    />
                </Paper >

            </>
        );
    }
}
const mapStateToProps = (state) => ({
    stakeHolderTypeList: state.stockStakeHolderType,
    salesExecutiveList: state.salesExecutive,
});
const mapDispatchToProps = {
    getAreaByUserType,
    getBeatBySalesTeamIdForAssign,
    getOutletByBeatId,
    showLoador,
    showNotification,
    setBeatBySalesTeamIdAreaId,
    getStockStakeHolderType,
    getSalesTeamByUserTypeAndTaluka,
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(AssignAreaToManager);
