import { Checkbox, FormControlLabel, Paper } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { LabelCompo } from "../../components/Comman/Label";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import endpoint from "../../config/endpoints";
import {
  noInternetMsg,
  serverMsg,
  saveWarningMsg,
  saveFailedMsg,
  savemsg,
  beatNotAssignMsg,
} from "../../config/messageconstant";
import { roasterJson } from "../../DynamicFormsJson/Transaction/roaster";
import { getRegionNew } from "../../Slice/region.slice";
import { getStateByRegion } from "../../Slice/state.slice";
import { getZoneByState } from "../../Slice/zone.slice";
import { getDistrictByZone } from "../../Slice/district.slice";
import { getTalukaByDistrict } from "../../Slice/taluka.slice";
import { getAreaByTaluka, getAreaByUserType } from "../../Slice/area.slice";
import { getBeatBySalesTeamId } from "../../Slice/beat.slice";
import { getRoaster, getRoasterDateWise } from "../../Slice/projection.slice";
import { apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import BeatPopup from "./beatPopup";
import {
  blackColor,
  yellowishBrownColor,
  white,
  checkedColor,
  LightYellowColor,
  LightGreenColor,
} from "../../config/ColorObj";
class MatrixForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      matrixData: [],
      DataToSet: [],
      allocationList: [],
      setList: [],
      matrixData: {},
      beatData: [],
      beatDate: "",
      adminFlag: false,
      beatDataSalesPersonWise: "",
      salesTeamId: "",
      salesTeamHeaderId: "0",
      dynamicMasterData: {
        regionDetail: this.props.regionList?.region,
        stateDetail: this.props.stateList?.stateByRegion,
        zoneDetail: this.props.zoneList?.zoneByState,
        districtDetail: this.props.districtList?.districtByZone,
        talukaDetail: this.props.talukaList?.talukaByDistrict,
        areaDetail: this.props.areaList?.areaByTaluka,
        areaUserTypeDetail: this.props.areaList?.area,
      },
      fieldDataForModule: {},
    };
  }

  async componentDidMount() {
    const { getAreaByUserType, getRegionNew, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      const userTypeId = localStorage.getItem("userTypeId");
      if (userTypeId == 1 || userTypeId == 6) {
        this.setState({
          adminFlag: true,
        });

        showLoador({ loador: true });
        getRegionNew().then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      } else {
        showLoador({ loador: true });
        getAreaByUserType().then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }

      const moduleObjData = localStorage.getItem("moduleObj");
      this.setState({
        fieldDataForModule:
          moduleObjData != null ? JSON.parse(moduleObjData) : {},
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.regionList !== nextProps.regionList) {
      if (this.props.regionList?.region !== nextProps.regionList?.region) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            regionDetail: nextProps.regionList?.region,
          },
        });
      }
    }
    if (this.props.stateList !== nextProps.stateList) {
      if (
        this.props.stateList?.stateByRegion !==
        nextProps.stateList?.stateByRegion
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stateDetail: nextProps.stateList?.stateByRegion,
          },
        });
      }
    }
    if (this.props.zoneList !== nextProps.zoneList) {
      if (
        this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            zoneDetail: nextProps.zoneList?.zoneByState,
          },
        });
      }
    }
    if (this.props.districtList !== nextProps.districtList) {
      if (
        this.props.districtList?.districtByZone !==
        nextProps.districtList?.districtByZone
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            districtDetail: nextProps.districtList?.districtByZone,
          },
        });
      }
    }
    if (this.props.talukaList !== nextProps.talukaList) {
      if (
        this.props.talukaList?.talukaByDistrict !==
        nextProps.talukaList?.talukaByDistrict
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            talukaDetail: nextProps.talukaList?.talukaByDistrict,
          },
        });
      }
    }
    if (this.props.areaList !== nextProps.areaList) {
      if (
        this.props.areaList?.areaByTaluka !== nextProps.areaList?.areaByTaluka
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.areaByTaluka,
          },
        });
      }
    }
    if (this.props.areaList !== nextProps.areaList) {
      if (this.props.areaList?.area !== nextProps.areaList?.area) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaUserTypeDetail: nextProps.areaList?.area,
          },
        });
      }
    }
  }
  handleCloses = () => {
    this.setState({
      showPopup: false,
    });
  };

  getRow = (row, indexRow, e) => {
    this.setState({ setList: e });
  };

  onSearch = async (data) => {
    const { getRoaster, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getRoaster({
        area: data.area,
        fromDate: data.fromDate,
        toDate: data.toDate,
      }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.setState({
            matrixData: response,
          });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  changeBeatAssignation = (e, row) => {
    const { fieldDataForModule } = this.state;
    if (fieldDataForModule.editReject == 1) {
      var d = new Date(),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = "" + d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      let today = day + "-" + month + "-" + year;
      let splitDate = row.date.split("-");

      if (splitDate[2] >= year) {
        if (splitDate[1] == month) {
          if (splitDate[0] >= day) {
            const { getRoasterDateWise, showLoador, showNotification } =
              this.props;
            if (navigator.onLine) {
              showLoador({ loador: true });
              getRoasterDateWise({
                salesTeamId: row.salesTeam.id,
                date: row.date,
              }).then(({ response, success }) => {
                showLoador({ loador: false });
                if (!success) {
                  showNotification({ msg: serverMsg, severity: "error" });
                } else {
                  let beatCheckedData = [];
                  let beatCompareData = [];
                  if (
                    response.salesExcutiveBeatProjectionHeader !== null &&
                    Object.keys(response.salesExcutiveBeatProjectionHeader)
                      .length !== 0
                  ) {
                    response.beats.map((beatObject) => {
                      beatCompareData =
                        response.salesExcutiveBeatProjectionHeader.salesExcutiveBeatProjectionDetails.filter(
                          (rowData) => rowData.beat.id === beatObject.id
                        );
                      if (beatCompareData.length !== 0) {
                        let object = {
                          ...beatObject,
                          checked: true,
                        };
                        beatCheckedData.push(object);
                      } else {
                        let object = {
                          ...beatObject,
                          checked: false,
                        };
                        beatCheckedData.push(object);
                      }
                    });
                    this.setState({
                      showPopup: true,
                      beatDate: row.date,
                      beatDataSalesPersonWise: row,
                      salesTeamHeaderId:
                        response.salesExcutiveBeatProjectionHeader.id,
                      salesTeamId: row.salesTeam.id,
                      beatData: beatCheckedData,
                    });
                  } else {
                    if (response.beats.length != 0) {
                      this.setState({
                        showPopup: true,
                        beatData: response.beats,
                        salesTeamId: row.salesTeam.id,
                        beatDate: row.date,
                        beatDataSalesPersonWise: row,
                      });
                    } else {
                      showNotification({
                        msg: beatNotAssignMsg,
                        severity: "error",
                      });
                    }
                  }
                }
              });
            } else {
              showNotification({
                msg: noInternetMsg,
                severity: "error",
              });
            }
          }
        } else if (splitDate[1] > month) {
          const { getRoasterDateWise, showLoador, showNotification } =
            this.props;
          if (navigator.onLine) {
            showLoador({ loador: true });
            getRoasterDateWise({
              salesTeamId: row.salesTeam.id,
              date: row.date,
            }).then(({ response, success }) => {
              showLoador({ loador: false });
              if (!success) {
                showNotification({ msg: serverMsg, severity: "error" });
              } else {
                let beatCheckedData = [];
                let beatCompareData = [];
                if (
                  response.salesExcutiveBeatProjectionHeader !== null &&
                  Object.keys(response.salesExcutiveBeatProjectionHeader)
                    .length !== 0
                ) {
                  response.beats.map((beatObject) => {
                    beatCompareData =
                      response.salesExcutiveBeatProjectionHeader.salesExcutiveBeatProjectionDetails.filter(
                        (rowData) => rowData.beat.id === beatObject.id
                      );
                    if (beatCompareData.length !== 0) {
                      let object = {
                        ...beatObject,
                        checked: true,
                      };
                      beatCheckedData.push(object);
                    } else {
                      let object = {
                        ...beatObject,
                        checked: false,
                      };
                      beatCheckedData.push(object);
                    }
                  });
                  this.setState({
                    showPopup: true,
                    beatDate: row.date,
                    beatDataSalesPersonWise: row,
                    salesTeamHeaderId:
                      response.salesExcutiveBeatProjectionHeader.id,
                    salesTeamId: row.salesTeam.id,
                    beatData: beatCheckedData,
                  });
                } else {
                  this.setState({
                    showPopup: true,
                    beatData: response.beats,
                    salesTeamId: row.salesTeam.id,
                    beatDate: row.date,
                    beatDataSalesPersonWise: row,
                  });
                }
              }
            });
          } else {
            showNotification({
              msg: noInternetMsg,
              severity: "error",
            });
          }
        } else {
        }
      }
    } else {
    }
  };

  onSubmit = (e) => {
    const {
      beatData,
      beatDate,
      salesTeamId,
      salesTeamHeaderId,
      matrixData,
      beatDataSalesPersonWise,
    } = this.state;
    let checkedData = [];
    beatData.map((beatObject) => {
      if (beatObject.checked) {
        let object = {
          beat: {
            id: beatObject.id,
          },
        };
        checkedData.push(object);
      }
    });
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      if (salesTeamHeaderId === "0") {
        let beatToSave = {
          salesTeam: {
            id: salesTeamId,
          },
          date: beatDate,
          salesExcutiveBeatProjectionDetails: checkedData,
        };

        swal({
          title: "Are you sure?",
          text: saveWarningMsg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            showLoador({ loador: true });
            apiPost({
              url: endpoint.projection,
              postBody: beatToSave,
            }).then(({ data, success }) => {
              if (success) {
                let newPersonData = matrixData.projectionPersonAndDateList.map(
                  (newPersonObject) => {
                    if (
                      newPersonObject.date === beatDate &&
                      newPersonObject.salesTeam.id === salesTeamId
                    ) {
                      let newPersonObjectData = {
                        id: beatDataSalesPersonWise.id,
                        salesTeam: beatDataSalesPersonWise.salesTeam,
                        date: beatDataSalesPersonWise.date,
                        beatIds: "",
                        beatNames: data.data.beatNames,
                        beatWithOutletCounts: null,
                        flag: 1,
                      };
                      return newPersonObjectData;
                    } else {
                      return newPersonObject;
                    }
                  }
                );
                let matrixNewData = {
                  projectionDates: matrixData.projectionDates,
                  projectionPersonAndDateList: newPersonData,
                  projectionPersons: matrixData.projectionPersons,
                };
                this.setState({
                  matrixData: matrixNewData,
                  showPopup: false,
                });
                showLoador({ loador: false });
                showNotification({
                  msg: savemsg,
                });
              } else {
                showNotification({
                  msg: saveFailedMsg,
                  severity: "error",
                });
                showLoador({ loador: false });
              }
            });
          }
        });
      } else {
        let beatToSave = {
          id: salesTeamHeaderId,
          salesTeam: {
            id: salesTeamId,
          },
          date: beatDate,
          salesExcutiveBeatProjectionDetails: checkedData,
        };
        swal({
          title: "Are you sure?",
          text: saveWarningMsg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            showLoador({ loador: true });
            apiPost({
              url: endpoint.projection + "/update",
              postBody: beatToSave,
            }).then(({ data, success }) => {
              if (success) {
                let newPersonData = matrixData.projectionPersonAndDateList.map(
                  (newPersonObject) => {
                    if (
                      newPersonObject.date === beatDate &&
                      newPersonObject.salesTeam.id === salesTeamId
                    ) {
                      if (
                        data.data.salesExcutiveBeatProjectionDetails.length !==
                        0
                      ) {
                        let newPersonObjectData = {
                          id: beatDataSalesPersonWise.id,
                          salesTeam: beatDataSalesPersonWise.salesTeam,
                          date: beatDataSalesPersonWise.date,
                          beatIds: "",
                          beatNames: data.data.beatNames,
                          beatWithOutletCounts: null,
                          flag: 1,
                        };
                        return newPersonObjectData;
                      } else {
                        let newPersonObjectData = {
                          id: beatDataSalesPersonWise.id,
                          salesTeam: beatDataSalesPersonWise.salesTeam,
                          date: beatDataSalesPersonWise.date,
                          beatIds: "",
                          beatNames: "Not assign any beat",
                          beatWithOutletCounts: null,
                          flag: 3,
                        };
                        return newPersonObjectData;
                      }
                    } else {
                      return newPersonObject;
                    }
                  }
                );
                let matrixNewData = {
                  projectionDates: matrixData.projectionDates,
                  projectionPersonAndDateList: newPersonData,
                  projectionPersons: matrixData.projectionPersons,
                };
                this.setState({
                  matrixData: matrixNewData,
                  showPopup: false,
                });
                showLoador({ loador: false });
                showNotification({
                  msg: savemsg,
                });
              } else {
                showNotification({
                  msg: saveFailedMsg,
                  severity: "error",
                });
                showLoador({ loador: false });
              }
            });
          }
        });
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  onCheckBoxClick = (row, index, e) => {
    const { beatData } = this.state;
    let changedBeatData = beatData.map((beatObject) => {
      if (row.id === beatObject.id) {
        if (e.target.checked) {
          if (beatObject.checked) {
            let object = {
              ...beatObject,
              checked: false,
            };
            return object;
          } else {
            let object = {
              ...beatObject,
              checked: true,
            };
            return object;
          }
        } else {
          let object = {
            ...beatObject,
            checked: false,
          };
          return object;
        }
      } else {
        return beatObject;
      }
    });
    this.setState({
      beatData: changedBeatData,
    });
  };
  getListById = async (data) => {
    const {
      getStateByRegion,
      getZoneByState,
      getDistrictByZone,
      getTalukaByDistrict,
      getAreaByTaluka,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      if (data.region && data.region != "") {
        showLoador({ loador: true });
        await getStateByRegion({ regionIds: data.region }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.state && data.state != "") {
        showLoador({ loador: true });
        await getZoneByState({ stateIds: data.state }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.zone && data.zone != "") {
        showLoador({ loador: true });
        await getDistrictByZone({ zoneIds: data.zone }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.district && data.district != "") {
        showLoador({ loador: true });
        await getTalukaByDistrict({ districtIds: data.district }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.taluka && data.taluka != "") {
        showLoador({ loador: true });
        await getAreaByTaluka({ talukaIds: data.taluka }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  render() {
    const {
      beatData,
      matrixData,
      dynamicMasterData,
      DataToSet,
      setList,
      showPopup,
      adminFlag,
      beatDataSalesPersonWise,
      fieldDataForModule,
    } = this.state;

    return (
      <>
        <Paper sx={{ p: 2, pt: 2, borderRadius: 2 }} elevation={0}>
          <DynamicFormWithoutSave
            showBackToList={false}
            DataToSet={DataToSet}
            dynamicMasterData={dynamicMasterData}
            showSaveBtn={roasterJson.showSaveBtn}
            showTitle={roasterJson.showTitle}
            screenTitle={roasterJson.screenTitle}
            fieldMeta={
              adminFlag
                ? roasterJson.fieldMeta
                : roasterJson.fieldMetaForStockHolder
            }
            showCancel={roasterJson.showCancel}
            apiBaseURL={roasterJson.apiBaseURL}
            showSaveNextBtn={roasterJson.showSaveNextBtn}
            saveBtnText={"Search"}
            showSaveBtnMain={false}
            getListById={this.getListById}
            onSave={this.onSearch}
          />
          <br />

          {Object.keys(matrixData).length !== 0 && (
            <>
              <FormControlLabel
                value="end"
                control={
                  <Checkbox
                    sx={{
                      color: checkedColor,
                      "&.Mui-checked": {
                        color: checkedColor,
                      },
                    }}
                    checked={true}
                  />
                }
                label="Absent"
                labelPlacement="end"
              />
              <FormControlLabel
                value="end"
                control={
                  <Checkbox
                    sx={{
                      color: yellowishBrownColor,
                      "&.Mui-checked": {
                        color: yellowishBrownColor,
                      },
                    }}
                    checked={true}
                  />
                }
                label="Previous"
                labelPlacement="end"
              />
              <FormControlLabel
                value="end"
                control={
                  <Checkbox
                    sx={{
                      color: LightYellowColor,
                      "&.Mui-checked": {
                        color: LightYellowColor,
                      },
                    }}
                    checked={true}
                  />
                }
                label="Day wise record"
                labelPlacement="end"
              />
              <FormControlLabel
                value="end"
                control={
                  <Checkbox
                    sx={{
                      color: LightGreenColor,
                      "&.Mui-checked": {
                        color: LightGreenColor,
                      },
                    }}
                    checked={true}
                  />
                }
                label="Date wise record"
                labelPlacement="end"
              />
              <FormControlLabel
                value="end"
                control={
                  <Checkbox
                    sx={{
                      color: blackColor,
                      "&.Mui-checked": {
                        color: blackColor,
                      },
                    }}
                    checked={false}
                  />
                }
                label="Not assigned any beat"
                labelPlacement="end"
              />

              <br />
              <TableContainer>
                <Table aria-label="simple table" size="medium">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" style={{ minWidth: "30px" }}>
                        Sr. No.
                      </TableCell>
                      <TableCell align="center" style={{ minWidth: "100px" }}>
                        Name
                      </TableCell>
                      {Object.keys(matrixData).length !== 0 &&
                        matrixData.projectionDates.map((data, index) => {
                          return (
                            <TableCell
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              {data.dates}
                              {"   "}
                              {data.weekDay}
                            </TableCell>
                          );
                        })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(matrixData).length !== 0 &&
                      matrixData.projectionPersons.map(
                        (matrixDataRow, indexRow) => {
                          return (
                            <TableRow>
                              <TableCell component="td" scope="row">
                                {indexRow + 1}
                              </TableCell>
                              <TableCell component="td" scope="row">
                                {matrixDataRow.salesTeam.name +
                                  " (" +
                                  matrixDataRow.salesTeam.stockStakeHolderType
                                    .prefix +
                                  ")"}
                              </TableCell>

                              {Object.keys(matrixData).length !== 0 &&
                                matrixData.projectionDates.map(
                                  (columnName, index) => {
                                    let newDayData =
                                      matrixData.projectionPersonAndDateList
                                        .length !== 0 &&
                                      matrixData.projectionPersonAndDateList.find(
                                        (item) =>
                                          item.date == columnName.dates &&
                                          item.salesTeam.id ==
                                            matrixDataRow.salesTeam.id
                                      );
                                    return (
                                      <TableCell
                                        sx={{
                                          height: 100,
                                          background:
                                            Object.keys(newDayData).length !==
                                              0 && newDayData !== undefined
                                              ? newDayData.flag === "0" &&
                                                newDayData.beatNames === "AB"
                                                ? checkedColor
                                                : newDayData.flag == "0"
                                                ? yellowishBrownColor
                                                : newDayData.flag == "1"
                                                ? LightGreenColor
                                                : newDayData.flag == "2"
                                                ? LightYellowColor
                                                : white
                                              : white,
                                          cursor: "pointer",
                                        }}
                                        onClick={(e) =>
                                          this.changeBeatAssignation(
                                            e,
                                            Object.keys(newDayData).length !==
                                              0 && newDayData !== undefined
                                              ? newDayData
                                              : {}
                                          )
                                        }
                                      >
                                        <LabelCompo
                                          className="text-black"
                                          style={{ fontSize: 12 }}
                                          label={
                                            Object.keys(newDayData).length !==
                                              0 && newDayData !== undefined
                                              ? newDayData.beatNames
                                              : ""
                                          }
                                        />
                                      </TableCell>
                                    );
                                    // }
                                  }
                                )}
                            </TableRow>
                          );
                        }
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
          {showPopup && fieldDataForModule.editReject == 1 ? (
            <>
              <BeatPopup
                open={showPopup}
                matrixData={beatData}
                handleCloses={this.handleCloses}
                onCheckBoxClick={this.onCheckBoxClick}
                onSubmit={this.onSubmit}
                getRow={this.getRow}
                beatSalesPersonWise={beatDataSalesPersonWise}
              />
            </>
          ) : (
            ""
          )}
        </Paper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  projectionList: state.roaster,
  projectionList: state.projection,
  beatList: state.beat,
  areaList: state.area,
  regionList: state.region,
  stateList: state.states,
  zoneList: state.zone,
  districtList: state.district,
  talukaList: state.taluka,
});
const mapDispatchToProps = {
  showNotification,
  getRoaster,
  showLoador,
  getBeatBySalesTeamId,
  getRegionNew,
  getZoneByState,
  getDistrictByZone,
  getTalukaByDistrict,
  getAreaByTaluka,
  getStateByRegion,
  getAreaByUserType,
  getRoasterDateWise,
};
export default connect(mapStateToProps, mapDispatchToProps)(MatrixForm);
