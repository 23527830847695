import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  ruWiseHeader: [],
  ruWiseConfigById: {},
  ruWiseConfigByIdDetails: [],
};
let URL = endpoints.ruProductConfigHeader;
const ruProductConfigHeaderSlice = createSlice({
  name: "ruProductConfigHeader",
  initialState,
  reducers: {
    ruWiseHeaderSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        ruWiseHeader: row,
      };
    },

    ruWiseConfigByIdSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        ruWiseConfigById: row,
      };
    },

    ruWiseConfigByIdDetailsSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        ruWiseConfigByIdDetails: row,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  resetState,
  ruWiseConfigByIdSuccess,
  ruWiseConfigByIdDetailsSuccess,
  ruWiseHeaderSuccess,
} = ruProductConfigHeaderSlice.actions;

export default ruProductConfigHeaderSlice.reducer;

export const getRuWiseProductConfiguration = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/get-all",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((ruWiseConfigObj, index) => {
          let ruWiseConfigData = {
            index: index + 1,
            id: ruWiseConfigObj.id === null ? "" : ruWiseConfigObj.id,
            name: ruWiseConfigObj.name === null ? "" : ruWiseConfigObj.name,
            yearId:
              ruWiseConfigObj.yearId === null ? "" : ruWiseConfigObj.yearId,

            yearName:
              ruWiseConfigObj.yearName === null ? "" : ruWiseConfigObj.yearName,

            publishDate:
              ruWiseConfigObj.publishDate === null
                ? ""
                : ruWiseConfigObj.publishDate,

            isEditable:
              ruWiseConfigObj.isEditable === null
                ? ""
                : ruWiseConfigObj.isEditable,
            statusName:
              ruWiseConfigObj.status === null
                ? ""
                : ruWiseConfigObj.status == 0
                ? "Freeze"
                : "UnFreeze",
            statusField:
              ruWiseConfigObj.status === null ? "" : ruWiseConfigObj.status,
            fromDate:
              ruWiseConfigObj.fromDate === null ? "" : ruWiseConfigObj.fromDate,
            toDate:
              ruWiseConfigObj.toDate === null ? "" : ruWiseConfigObj.toDate,
          };
          return ruWiseConfigData;
        });
        dispatch(ruWiseHeaderSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getRuWiseProductConfigurationById =
  ({ id }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/by-id?id=" + id,
      }).then(({ data, success }) => {
        if (success) {
          let ruWiseConfigObj = data.data;
          let ruWiseConfigData = {
            id: ruWiseConfigObj.id === null ? "" : ruWiseConfigObj.id,
            name: ruWiseConfigObj.name === null ? "" : ruWiseConfigObj.name,
            publishDate:
              ruWiseConfigObj.publishDate === null
                ? ""
                : ruWiseConfigObj.publishDate,
            yearId:
              ruWiseConfigObj.yearId === null ? "" : ruWiseConfigObj.yearId,
            yearName:
              ruWiseConfigObj.yearName === null ? "" : ruWiseConfigObj.yearName,
            isEditable:
              ruWiseConfigObj.isEditable === null
                ? ""
                : ruWiseConfigObj.isEditable,
            statusField:
              ruWiseConfigObj.status === null ? "" : ruWiseConfigObj.status,
            fromDate:
              ruWiseConfigObj.fromDate === null ? "" : ruWiseConfigObj.fromDate,
            toDate:
              ruWiseConfigObj.toDate === null ? "" : ruWiseConfigObj.toDate,

            retailUnitProductConfigurationDetails:
              ruWiseConfigObj.retailUnitProductConfigurationDetails === null
                ? ""
                : ruWiseConfigObj.retailUnitProductConfigurationDetails.map(
                    (dataObj, index) => {
                      let detailData = {
                        index: index + 1,
                        id: dataObj.id !== null ? dataObj.id : "",
                        productId:
                          dataObj.productId !== null ? dataObj.productId : "",
                        name:
                          dataObj.productName !== null
                            ? dataObj.productName
                            : "",
                        retailUnitProductConfigurationHeaderId:
                          dataObj.retailUnitProductConfigurationHeaderId !==
                          null
                            ? dataObj.retailUnitProductConfigurationHeaderId
                            : "",
                        stakeHolderUomName:
                          dataObj.uomName !== null ? dataObj.uomName : "",
                        primaryQty:
                          dataObj.primaryQty !== null ? dataObj.primaryQty : "",
                        ruQty: dataObj.ruQty !== null ? dataObj.ruQty : "",
                        stakeHolderUomId:
                          dataObj.uomId !== null ? dataObj.uomId : "",
                      };
                      return detailData;
                    }
                  ),
          };
          dispatch(ruWiseConfigByIdSuccess({ row: ruWiseConfigData }));
          dispatch(
            ruWiseConfigByIdDetailsSuccess({
              row: ruWiseConfigData.retailUnitProductConfigurationDetails,
            })
          );
          return { response: ruWiseConfigData, success };
        }
        return { response: {}, success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const setRuWiseProductConfigurationByIdDetails =
  ({ row }) =>
  async (dispatch) => {
    dispatch(ruWiseConfigByIdDetailsSuccess({ row }));
  };
