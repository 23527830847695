import React from "react";
import { connect } from "react-redux";
import { creditNoteJSON } from "../../DynamicFormsJson/Transaction/creditNote";
import { getAreaByUserType } from "../../Slice/area.slice";
import { getBeatByArea } from "../../Slice/beat.slice";
import {
  getCreditNoteHeaderPost,
  getTodaysCreditNoteForOutlet,
} from "../../Slice/creditNote.slice";
import {
  getOutletByBeatsAndAssignedStockHolder,
  getOutletByUserType,
} from "../../Slice/outlet.slice";
import { compose } from "@reduxjs/toolkit";
import { withRouter } from "../../components/withRouter";
import { Grid, Paper } from "@mui/material";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import { redColor, titleColor } from "../../config/ColorObj";
import CheckBoxSelectionTable from "../../components/Comman/RT/CheckBoxSelectionTable";
import { getActiveLanguage } from "../../Slice/multilingual.slice";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import endpoint from "../../config/endpoints";
import {
  noInternetMsg,
  serverMsg,
  checkedListEmptyMsg,
} from "../../config/messageconstant";
import { CreditNoteColumns } from "../../tableColumns/table-columns";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { downloadF } from "../PDF/PDFDownload";
import { apiGet, apiPost, apiPostForDownload } from "../../utils/api_service";
import jsPDF from "jspdf";
import logo from "../../Images/gokulImg.png";
import ofc from "../../Images/OFC_logo.png";

import { langugae } from "../../config/languageJSON";
import moment from "moment";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
class OrderHistoryTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: "2",
      outletIds: "",
      fromDate: "",
      toDate: "",
      totalQty: "",
      totalTaxable: "",
      totalAmount: "",
      selection: [],
      dynamicMasterData: {
        areaDetail: this.props.areaList.area,
        beatDetail: this.props.beatList.beat,
        outletDetail: this.props.outletList.outletByUserType,
      },
      fieldDataForModule: {},
    };
  }
  async componentDidMount() {
    const {
      getTodaysCreditNoteForOutlet,
      getAreaByUserType,
      getActiveLanguage,
      showLoador,
      showNotification,
      creditNoteHeader,
    } = this.props;
    if (navigator.onLine) {

      let totalCrnQty = 0;
      let totalTaxableAmt = 0;
      let totalAmt = 0;

      showLoador({ loador: true });
      getTodaysCreditNoteForOutlet().then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          if (response && response.length !== 0) {
            response.map((todaysCrnObj) => {
              totalCrnQty = totalCrnQty + +todaysCrnObj.totalQty;
              totalTaxableAmt = totalTaxableAmt + +todaysCrnObj.taxableAmt;
              totalAmt = totalAmt + +todaysCrnObj.totalAmt;
            });
            this.setState({
              totalQty: totalCrnQty,
              totalTaxable: totalTaxableAmt.toFixed(2),
              totalAmount: totalAmt,
            });
          } else {
            this.setState({
              totalQty: "",
              totalTaxable: "",
              totalAmount: "",
            });
          }
        }
      });

      showLoador({ loador: true });
      getAreaByUserType().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getActiveLanguage().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      const moduleObjData = localStorage.getItem("moduleObj");
      this.setState({
        fieldDataForModule:
          moduleObjData != null ? JSON.parse(moduleObjData) : {},
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.areaList !== nextProps.areaList) {
      if (this.props.areaList?.area !== nextProps.areaList?.area) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.area,
          },
        });
      }
    }
    if (this.props.beatList !== nextProps.beatList) {
      if (this.props.beatList?.beat !== nextProps.beatList?.beat) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            beatDetail: nextProps.beatList?.beat,
          },
        });
      }
    }

    if (this.props.outletList !== nextProps.outletList) {
      if (this.props.outletList?.outlets !== nextProps.outletList?.outlets) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            outletDetail: nextProps.outletList?.outlets,
          },
        });
      }
    }
  }

  getListById = async (data) => {
    const { getBeatByArea, getOutletByBeatsAndAssignedStockHolder, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      if (data.area && data.area != "") {
        showLoador({ loador: true });
        await getBeatByArea({ areaId: data.area }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.beat && data.beat != "") {
        let beatIdsJson = {
          beatIds: data.beat,
        }
        showLoador({ loador: true });
        await getOutletByBeatsAndAssignedStockHolder({ beatIds: beatIdsJson }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onSearchData = async (data) => {
    const { getCreditNoteHeaderPost, creditNoteHeader, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {

      this.setState({
        outletIds: data.outletIds,
        fromDate: data.fromdate,
        toDate: data.todate,
      });

      let creditNoteHeaderData = {
        outletIds: data.outletIds,
        fromDate: data.fromdate,
        toDate: data.todate,
      };
      let totalCrnQty = 0;
      let totalTaxableAmt = 0;
      let totalAmt = 0;
      showLoador({ loador: true });
      await getCreditNoteHeaderPost({
        creditNoteData: creditNoteHeaderData,
      }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          if (response && response.length !== 0) {
            response.map((onSearchObj) => {
              totalCrnQty = totalCrnQty + +onSearchObj.totalQty;
              totalTaxableAmt = totalTaxableAmt + +onSearchObj.taxableAmt;
              totalAmt = totalAmt + +onSearchObj.totalAmt;
            });
            this.setState({
              totalQty: totalCrnQty,
              totalTaxable: totalTaxableAmt.toFixed(2),
              totalAmount: totalAmt,
            });
          }
          else {
            this.setState({
              totalQty: "",
              totalTaxable: "",
              totalAmount: "",
            });
          }
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  // rowPDF = (rowData) => {
  //   if (navigator.onLine) {
  //     this.props.showLoador({ loador: true });
  //     downloadF({
  //       url: endpoint.creditNote + "/by-ids?ids=" + rowData.id,
  //       ext: "pdf",
  //       openNewTab: true,
  //     }).then((response) => {
  //       this.props.showLoador({ loador: false });
  //       if (!response) {
  //         this.props.showNotification({
  //           msg: serverMsg,
  //           severity: "error",
  //         });
  //       } else {
  //       }
  //     });
  //   }
  // };
  rowPDF = (rowData) => {
    const { lang } = this.state;
    if (navigator.onLine) {
      let creditNotePdfData = {
        creditNoteHeaderIds: rowData.id.split(","),
        languageId: lang,
      };
      this.props.showLoador({ loador: true });
      apiPost({
        url: endpoint.creditNote + "/report-by-ids",
        postBody: creditNotePdfData,
      }).then(({ data, success }) => {
        this.props.showLoador({ loador: false });
        if (!success) {
          this.props.showNotification({
            msg: serverMsg,
            severity: "error",
          });
        } else {
          console.log(data.data);
          this.generatePDFHeader(data.data);
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  generatePDFHeader = (rowList) => {
    var doc = new jsPDF();
    const { lang } = this.state;
    rowList.map((dataToSet, indexMain) => {
      let indexInfoData = 0;
      var line = 10; // Line height to start text at
      var lineHeight = 5;
      var leftMargin = 5;
      var wrapWidth = 120;
      doc.setFontSize(12);
      var DelerText = dataToSet.stockStakeHolderFirmName
        ? dataToSet.stockStakeHolderFirmName
        : "-";
      var splitText = doc.splitTextToSize(DelerText, wrapWidth + 10);
      for (var i = 0, length = splitText.length; i < length; i++) {
        // loop thru each line and increase
        doc.text(splitText[i], leftMargin, line);
        line = lineHeight + line;
      }
      doc.setFontSize(10);
      line = line + 2;
      var DelerText = dataToSet.stockStakeHolderOwnerName
        ? "Owner Name : " + dataToSet.stockStakeHolderOwnerName
        : "-";
      var splitText = doc.splitTextToSize(DelerText, wrapWidth + 10);
      for (var i = 0, length = splitText.length; i < length; i++) {
        // loop thru each line and increase
        doc.text(splitText[i], leftMargin, line);
        line = lineHeight + line;
      }
      line = line + 2;

      doc.setFont(undefined, "normal");
      let address = langugae[lang].address
        ? langugae[lang].address + " : "
        : "";

      var addressText = dataToSet.stockStakeHolderAddress
        ? address + dataToSet.stockStakeHolderAddress
        : "-";
      var splitTextAddr = doc.splitTextToSize(addressText, wrapWidth);
      for (var i = 0, length = splitTextAddr.length; i < length; i++) {
        // loop thru each line and increase
        if (i == 0) {
          doc.text(splitTextAddr[i], leftMargin, line);
        } else {
          doc.text(splitTextAddr[i], leftMargin, line);
        }

        line = lineHeight + line;
      }
      let yAxis = line + 2;
      var pageWidth = doc.internal.pageSize.getWidth();
      var xPos = pageWidth - 50;
      var xPos1 = pageWidth - 55;
      var top = 0;

      let fromBillContactPersonNo = dataToSet.stockStakeHolderContactPersonNo
        ? dataToSet.stockStakeHolderContactPersonNo
        : "-";
      let mobileNo = langugae[lang].mobileNo
        ? langugae[lang].mobileNo + " : "
        : "";
      doc.text(mobileNo + fromBillContactPersonNo, leftMargin, yAxis, {
        maxWidth: wrapWidth,
      });

      yAxis = yAxis + 7;
      let fromBillFssiFdiNo = dataToSet.stockStakeHolderFssiFdiNo
        ? dataToSet.stockStakeHolderFssiFdiNo
        : "-";
      let fromBillGstNo = dataToSet.stockStakeHolderGstNo
        ? dataToSet.stockStakeHolderGstNo
        : "-";
      let gSTNo = langugae[lang].gSTNo ? langugae[lang].gSTNo + " : " : "";
      let FSSAINo = langugae[lang].FSSAINo
        ? langugae[lang].FSSAINo + " : "
        : "";

      doc.text(
        gSTNo + fromBillGstNo + " , " + FSSAINo + fromBillFssiFdiNo,
        leftMargin,
        yAxis,
        { maxWidth: wrapWidth }
      );
      doc.line(150, 5, 150, 50);
      let pdfLogo =
        localStorage.getItem("compId") == "5c140f80-3929-46dc-83b1-592b7bc30d1f"
          ? logo
          : ofc;
      doc.addImage(pdfLogo, "JPEG", xPos1, 5, 45, 35);
      yAxis = yAxis + 5;
      doc.line(3, yAxis, pageWidth - 3, yAxis);
      yAxis = yAxis + 7;
      var xPosTax = pageWidth - 57;
      let yAxisTax = yAxis;
      doc.setFontSize(10);
      doc.setFont(undefined, "bold");
      let purchaseReturn = langugae[lang].purchaseReturn
        ? langugae[lang].purchaseReturn + " : "
        : "";
      doc.text("Credit Note Invoice", xPos, yAxisTax, { maxWidth: wrapWidth });
      yAxisTax = yAxisTax + 7;
      let billNoLan = langugae[lang].billNO
        ? langugae[lang].billNO + " : "
        : "";
      doc.text(billNoLan, xPosTax, yAxisTax, { maxWidth: wrapWidth });
      doc.setFont(undefined, "normal");
      let transactionNo = dataToSet.transactionNo
        ? dataToSet.transactionNo
        : "-";

      doc.text(transactionNo, xPosTax + 17, yAxisTax, { maxWidth: 100 });
      yAxisTax = yAxisTax + 7;
      doc.setFont(undefined, "bold");
      let date = langugae[lang].date ? langugae[lang].date + " : " : "";
      doc.text(date, xPosTax, yAxisTax, { maxWidth: wrapWidth });
      doc.setFont(undefined, "normal");
      let creditNoteDate = dataToSet.date ? dataToSet.date : "-";

      doc.text(creditNoteDate, xPosTax + 17, yAxisTax, { maxWidth: wrapWidth });
      yAxisTax = yAxisTax + 7;
      doc.setFont(undefined, "bold");
      let insertDateTime = dataToSet.insertDateTime
        ? dataToSet.insertDateTime.split(" ")
        : [];
      let time = langugae[lang].time ? langugae[lang].time + " : " : "";
      doc.text(time, xPosTax, yAxisTax, { maxWidth: wrapWidth });
      doc.setFont(undefined, "normal");
      let insertDateTimeData =
        insertDateTime.length == 2 ? insertDateTime[1] : "";
      doc.text(insertDateTimeData, xPosTax + 17, yAxisTax, {
        maxWidth: wrapWidth,
      });
      line = yAxis;
      top = 10;
      doc.setFontSize(10);
      let billTo = langugae[lang].billTo ? langugae[lang].billTo + " : " : "";
      doc.text(billTo, leftMargin, line);
      let toBill = dataToSet.outLet ? dataToSet.outLet : {};
      var DText = toBill.firmName ? toBill.firmName : "";
      var splitTextDText = doc.splitTextToSize(DText, wrapWidth);
      for (var i = 0, length = splitTextDText.length; i < length; i++) {
        if (i == 0) {
          doc.text(splitTextDText[i], leftMargin + 15, line);
        } else {
          doc.text(splitTextDText[i], leftMargin, line);
        }
        line = lineHeight + line;
        top = top + lineHeight;
      }
      line = line + 2;
      var DelerText = toBill.ownerName
        ? "Owner Name : " + toBill.ownerName
        : "-";
      var splitText = doc.splitTextToSize(DelerText, wrapWidth + 10);
      for (var i = 0, length = splitText.length; i < length; i++) {
        // loop thru each line and increase
        doc.text(splitText[i], leftMargin, line);
        line = lineHeight + line;
      }
      doc.setFont(undefined, "normal");

      doc.setFontSize(10);
      line = line + 2;
      top = top + 2;
      let taluka =
        toBill.beat != null &&
          toBill.beat.area != null &&
          toBill.beat.area.taluka != null
          ? toBill.beat.area.taluka
          : "";
      let talukaName = taluka != null ? taluka.name : "";
      let districtName =
        taluka != null && taluka.district != null ? taluka.district.name : "";
      let addr = toBill.address
        ? toBill.address + " - " + talukaName + " - " + districtName
        : "";
      var DAddrText = address + addr;
      var splitTextDAddrText = doc.splitTextToSize(DAddrText, wrapWidth);
      for (var i = 0, length = splitTextDAddrText.length; i < length; i++) {
        // loop thru each line and increase
        doc.text(splitTextDAddrText[i], leftMargin, line);
        line = lineHeight + line;
        top = top + lineHeight;
      }
      yAxis = line + 2;
      top = top + 2;
      let contactPersonNo = toBill.mobileNo ? toBill.mobileNo : "";
      let gstNo = toBill.gstNo != null ? toBill.gstNo : "-";

      let phoneNo = langugae[lang].phoneNo
        ? langugae[lang].phoneNo + " : "
        : "";
      doc.text(
        phoneNo + contactPersonNo + " , " + gSTNo + gstNo,
        leftMargin,
        yAxis,
        { maxWidth: wrapWidth }
      );

      yAxis = yAxis + 7;
      top = top + 7;
      let fssiFdiNo =
        toBill.fssiFdiNo != null && toBill.fssiFdiNo != ""
          ? toBill.fssiFdiNo
          : "-";

      doc.text(FSSAINo + fssiFdiNo, leftMargin, yAxis, { maxWidth: 100 });

      yAxis = yAxis + 7;
      top = top + 7;
      doc.line(150, 3, 150, yAxis);
      doc.line(3, yAxis, pageWidth - 3, yAxis);
      let toBillState = toBill.state != null ? toBill.state : {};
      let dataToSetState = dataToSet.state != null ? dataToSet.state : {};
      let headData = [
        { content: "#" },
        { content: "HSN" },
        { content: "Item Name" },
        { content: "UOM" },
        { content: "Qty" },
        { content: "Rate" },
      ];

      var align = {
        1: { halign: "center" },
        4: { halign: "right" },
        5: { halign: "right" },
      };
      let headData2 = [];
      if (toBillState.id == dataToSetState.id) {
        headData = [
          ...headData,
          { content: "CGST", colSpan: 2 },
          { content: "SGST", colSpan: 2 },
          { content: "Amount" },
        ];
        headData2 = ["", "", "", "", "", "", "%", "Amt", "%", "Amt", ""];
        align = {
          ...align,
          6: { halign: "right" },
          7: { halign: "right" },
          8: { halign: "right" },
          9: { halign: "right" },
          10: { halign: "right" },
        };
      } else {
        headData = [
          ...headData,
          { content: "IGST", colSpan: 2 },
          { content: "Amount" },
        ];
        headData2 = ["", "", "", "", "", "", "%", "Amt", ""];
        align = {
          ...align,
          6: { halign: "right" },
          7: { halign: "right" },
          8: { halign: "right" },
        };
      }

      let head = [headData, headData2];
      let stockStakeHolderBillDetails =
        dataToSet.dtoCreditNoteDetailAndHeaders != null
          ? dataToSet.dtoCreditNoteDetailAndHeaders
          : [];
      let productList = [];
      // dataToSet.packagingTypes.map((packaginType)=>{})

      console.log(productList);
      var data = [];
      let totalAmt = 0;
      let qtyTot = 0;
      dataToSet.packagingTypes.map((packagingType) => {
        data.push([
          {
            content: packagingType.name,
            colSpan: 11,

            styles: { fontWeight: 900, fontSize: 10, fontColor: "#000" },
          },
        ]);
        let indexInfo = 0;
        stockStakeHolderBillDetails.map((stockDetails, indexData) => {
          let packagingTypeDetails =
            stockDetails.product != null &&
              stockDetails.product.packagingType != null
              ? stockDetails.product.packagingType
              : {};

          if (packagingType.id == packagingTypeDetails.id) {
            let productData = [];
            let product =
              stockDetails.product != null ? stockDetails.product : {};
            indexInfo = indexInfo + 1;
            productData.push(indexInfo);
            productData.push(stockDetails.hsnCode);
            productData.push(product.name);
            productData.push(
              stockDetails.uom != null ? stockDetails.uom.name : "-"
            );
            productData.push(stockDetails.qty);
            qtyTot = qtyTot + stockDetails.qty;
            productData.push(stockDetails.rate.toFixed(2));
            if (toBillState.id == dataToSetState.id) {
              productData.push(stockDetails.cgstPer.toFixed(2));
              productData.push(stockDetails.cgstAmt.toFixed(2));
              productData.push(stockDetails.sgstPer.toFixed(2));
              productData.push(stockDetails.sgstAmt.toFixed(2));
            } else {
              productData.push(stockDetails.igstPer.toFixed(2));
              productData.push(stockDetails.igstAmt.toFixed(2));
            }
            totalAmt = totalAmt + stockDetails.totalAmt;
            productData.push(stockDetails.totalAmt.toFixed(2));
            data.push(productData);
          }
        });
      });

      if (toBillState.id == dataToSetState.id) {
        data.push([
          {
            content: "Invoice Total",
            colSpan: 4,
            styles: { fontWeight: 900, fontSize: 12, fontColor: "#000" },
          },
          {
            content: qtyTot,
            styles: { fontWeight: 900, fontSize: 12, fontColor: "#000" },
          },
          "",
          "",
          "",
          "",
          "",
          {
            content: totalAmt.toFixed(2),
            styles: { fontWeight: 900, fontSize: 12, fontColor: "#000" },
          },
        ]);
      } else {
        data.push([
          {
            content: "Invoice Total",
            colSpan: 4,
            styles: { fontWeight: "bold" },
          },
          qtyTot,
          "",
          "",
          "",
          totalAmt.toFixed(2),
        ]);
      }
      var fontSize = 8; // Set your desired font size
      var styles = {
        fontSize: fontSize,
        fontColor: "#000",
      };
      doc.autoTable({
        head: head,
        body: data,
        startY: yAxis + 5,
        columnStyles: align,
        margin: { right: 5, left: 5, top: 20 },
        headStyles: {
          halign: "center",
          lineWidth: 0.2,
          lineColor: "#fff",
          fillColor: [3, 169, 244],
          textColor: [255, 255, 255],
        },
        styles: styles,
        theme: "grid",

        showHead: "everyPage",

        didDrawPage: function (data) {
          doc.setTextColor(40);
          var pageWidth = doc.internal.pageSize.getWidth();
          if (data.pageNumber > 1) {
            var xPos1 = pageWidth - 45;
            var line1 = 15;
            var yAxisTax = 10;
            doc.setFontSize(10);
            doc.text("Credit Note Invoice", 90, 10);
            var lineHeight1 = 5;
            var wrapWidth1 = 130;
            doc.setFontSize(10);
            doc.text("Credit Note To", leftMargin, line1);
            doc.setFontSize(10);
            let toBill = dataToSet.outLet ? dataToSet.outLet : {};

            var DText = toBill.ownerName ? toBill.ownerName : "";
            var splitTextDText = doc.splitTextToSize(DText, wrapWidth1);
            for (var i = 0, length = splitTextDText.length; i < length; i++) {
              // loop thru each line and increase

              if (i == 0) {
                doc.text(splitTextDText[i], leftMargin + 15, line1);
              } else {
                doc.text(splitTextDText[i], leftMargin, line1);
              }
              line1 = lineHeight1 + line1;
            }
            let creditNoteDate = dataToSet.date ? dataToSet.date : "-";

            let text = dataToSet.transactionNo
              ? dataToSet.transactionNo + " (" + creditNoteDate + ")"
              : "-";
            var textWidth1 =
              (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
              doc.internal.scaleFactor;

            var xPos1 = pageWidth - textWidth1 - 10;
            doc.setFont(undefined, "normal");
            doc.text(text, xPos1, 15, { maxWidth: 100 });
          }
          indexInfoData = indexInfoData + 1;
          var str = "Page " + indexInfoData;

          // jsPDF 1.4+ uses getWidth, <1.4 uses .width
          var pageSize = doc.internal.pageSize;
          var pageHeight = pageSize.height
            ? pageSize.height
            : pageSize.getHeight();
          let pb = "Powered By Bizintel DMS";
          const compName = localStorage.getItem("compName");
          var textWidthCN =
            (doc.getStringUnitWidth(compName) * doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textWidth =
            (doc.getStringUnitWidth(pb) * doc.internal.getFontSize()) /
            doc.internal.scaleFactor;

          // Calculate the X position to align to the right of the page

          doc.setLineWidth(0.2);
          if (data.pageNumber > 1) {
            doc.rect(3, 3, pageWidth - 6, pageHeight - 8);
          } else {
            doc.rect(3, 3, pageWidth - 6, pageHeight - 8);
          }
          // Adjust for a margin if needed
          doc.setFontSize(10);
          var xPosCN = (pageWidth - textWidthCN) / 2;
          var xPos = pageWidth - textWidth - 10;
          doc.text(compName, 10, pageHeight - 10);

          doc.setFontSize(10);
          doc.text(pb, xPos, pageHeight - 10);
          doc.text(str, xPosCN, pageHeight - 10);
        },
      });

      let productHeadWithPack =
        dataToSet.packagingTypes.length != 0 ? ["Filling Type"] : [];
      let productListWithPack = [];
      let productListalign = { 0: { cellWidth: 50 } };
      dataToSet.categories.map((categoryData, index) => {
        productHeadWithPack.push(categoryData.name);
        productListalign = {
          ...productListalign,
          [index + 1]: { halign: "right" },
        };
      });

      dataToSet.packagingTypes.map((packagingTypeData) => {
        let GruopInfo = [packagingTypeData.name];
        dataToSet.categories.map((categoryData) => {
          let flag = 0;
          dataToSet.dtoCategoryWithPackagingTypes.map((rowData) => {
            if (
              categoryData.id == rowData.category.id &&
              rowData.packagingType.id == packagingTypeData.id
            ) {
              flag = 1;
              GruopInfo.push(rowData.totalQty);
            }
          });
          if (flag == 0) {
            GruopInfo.push("");
          }
        });
        productListWithPack.push(GruopInfo);
      });
      console.log(productListWithPack);
      console.log(productHeadWithPack);
      doc.autoTable({
        head: [productHeadWithPack],
        body: productListWithPack,

        startY: doc.autoTableEndPosY() + 5,
        columnStyles: productListalign,
        margin: { right: 6, left: 6, top: 20 },
        headStyles: {
          halign: "center",
          lineWidth: 0.2,
          lineColor: "#fff",
          fillColor: [3, 169, 244],
          textColor: [255, 255, 255],
        },
        theme: "grid",

        showHead: "everyPage",

        didDrawPage: function (data) { },
      });
      var line = doc.autoTableEndPosY() + 5; // Line height to start text at
      var lineHeight = 5;
      var leftMargin = 5;
      var leftMarginData = 152;
      var wrapWidth = 180;
      // let note = langugae[lang].note ? langugae[lang].note + " : " : "";
      // doc.text(note, leftMargin, line);
      var DelerText =
        dataToSet.orderRemarks != null ? dataToSet.orderRemarks : "";
      var splitText = doc.splitTextToSize(DelerText, wrapWidth + 10);
      for (var i = 0, length = splitText.length; i < length; i++) {
        // loop thru each line and increase
        doc.text(splitText[i], leftMargin + 10, line);
        line = lineHeight + line;
      }

      // let orderBy = langugae[lang].orderBy
      //   ? langugae[lang].orderBy + " : "
      //   : "";

      doc.line(3, line, pageWidth - 3, line);
      let lineData = line;
      var line = line + 5;
      let subjectToJurisdiction = langugae[lang].subjectToJurisdiction
        ? langugae[lang].subjectToJurisdiction + " : "
        : "";
      let distName = localStorage.getItem("districtName");
      doc.text("1. " + subjectToJurisdiction + distName, leftMargin, line);

      // let salesTeamNames = dataToSet.salesTeamNames
      //   ? dataToSet.salesTeamNames
      //   : "";
      // doc.text("1." + orderBy + salesTeamNames, leftMargin, line);
      let lineOrder = line;
      var wrapWidth = 50;
      var DelerText = dataToSet.stockStakeHolderFirmName
        ? dataToSet.stockStakeHolderFirmName
        : "-";
      var splitText = doc.splitTextToSize(DelerText, wrapWidth + 10);
      for (var i = 0, length = splitText.length; i < length; i++) {
        // loop thru each line and increase
        doc.text(splitText[i], leftMarginData, lineOrder);
        lineOrder = lineHeight + lineOrder;
      }
      var line = line + 6;
      doc.line(3, line, 90, line);
      var line = line + 5;

      let aurthorisedSign = langugae[lang].aurthorisedSign
        ? langugae[lang].aurthorisedSign + " : "
        : "";
      // doc.text("2. Goods Check Before Received : ", leftMargin, line);
      // var line = line + 2;
      // doc.line(3, line, 90, line);
      // var line = line + 5;

      doc.text(aurthorisedSign, leftMarginData, line);
      var line = line + 5;
      doc.line(90, lineData, 90, line);
      doc.line(150, lineData, 150, line);
      doc.line(3, line, pageWidth - 3, line);
      if (indexMain + 1 != rowList.length) {
        doc.addPage();
      }
    });

    doc.save("Credit Note " + moment().format("DD-MM-YYYY") + ".pdf");
  };
  // rowPDF = (rowData) => {
  //   if (navigator.onLine) {
  //     this.props.showLoador({ loador: true });
  //     downloadF({
  //       url:
  //         endpoint.creditNote +
  //         "/report-by-id?id=" +
  //         rowData.id +
  //         "&documentType=1",
  //       ext: "pdf",
  //       openNewTab: true,
  //     }).then((response) => {
  //       this.props.showLoador({ loador: false });
  //       if (!response) {
  //         this.props.showNotification({
  //           msg: serverMsg,
  //           severity: "error",
  //         });
  //       } else {
  //       }
  //     });
  //   }
  // };

  onAutocompleteChange = (name, newValue) => {
    if (newValue != null) {
      this.setState({
        lang: newValue,
        langNew: newValue,
      });
    } else {
      this.setState({
        lang: "",
        langNew: "",
      });
    }
  };

  onPDFBill = () => {
    const { showNotification, creditNoteHeader } = this.props;
    const { selection } = this.state;
    if (selection.length === 0) {
      showNotification({
        msg: checkedListEmptyMsg,
        severity: "error",
      });
    } else {
      const rowList = creditNoteHeader.creditNoteHeader.map((rowData) => {
        const filterData = selection.filter((id) => rowData.id == id);
        return { ...rowData, checked: filterData.length != 0 ? true : false };
      });
      console.log(selection);
      this.rowPDF1({
        id: selection.join(),
      });
    }
  };

  getSelectedList = (e) => {
    const { creditNoteHeader } = this.props;
    this.setState({
      selection: e,
    });
    const rowList = creditNoteHeader.creditNoteHeader.map((rowData) => {
      const filterData = e.filter((id) => rowData.id == id);
      return { ...rowData, checked: filterData.length != 0 ? true : false };
    });
  };
  rowView = (rowData) => {
    this.props.navigate(creditNoteJSON.formPathView + "/" + rowData.id);
  };
  rowPDF1 = (rowData) => {
    const { showNotification, showLoador } = this.props;
    const { lang } = this.state;
    localStorage.setItem("lang", lang);
    localStorage.setItem("creditNoteID", rowData.id);
    window.open("./creditNote-pdf", "_blank");
  };


  onExportHandler = () => {
    const { fromDate, outletIds, toDate } = this.state;
    const { showLoador } = this.props;

    let creditNoteJson = {
      outletIds: outletIds,
      fromDate: fromDate,
      toDate: toDate,
    }

    console.log(creditNoteJson);

    showLoador({ loador: true });
    apiPostForDownload({
      url:
        endpoint.creditNote +
        "/excel-by-date-and-outlets",
      postBody: creditNoteJson,
    }).then(({ data, success }) => {
      showLoador({ loador: false });
      const blb = new Blob([data], { type: "text/plain" });
      const reader = new FileReader();
      reader.addEventListener("loadend", (e) => {
        if (success) {
          var blob = new Blob([data], {
            type: "application/xls",
          });
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "Report_" + new Date() + ".xls";
          link.click();
        }
      });
      reader.readAsText(blb);
    });
  };

  render() {
    const { dynamicMasterData, lang, selection, fieldDataForModule, totalAmount, totalQty, totalTaxable } =
      this.state;
    const { creditNoteHeader, getTodaysCreditNoteForOutlet, multilingualList } =
      this.props;
    return (
      <Paper>
        <>
          <DynamicMainScreen
            maxDateValid={true}
            showDeleteIcon={false}
            dynamicMasterData={dynamicMasterData}
            searchList={creditNoteJSON.searchList}
            onSearchData={this.onSearchData}
            formPathView={creditNoteJSON.formPathView}
            formPath={creditNoteJSON.formPath}
            screenTitle={creditNoteJSON.screenTitle}
            fieldMeta={creditNoteJSON.fieldMeta}
            getListById={this.getListById}
            showPdfDownload={false}
            isActiveURL={creditNoteJSON.isActiveURL}
            showAddButton={creditNoteJSON.showAddButton}
            tableColumnsToFilter={creditNoteJSON.tableColumnsToFilter}
            tableColumns={[]}
            tableData={creditNoteHeader.creditNoteHeader}
            apiBaseURL={creditNoteJSON.apiBaseURL}
            callApi={false}
            getTableDataById={true}
            showSearchBox={false}
            rowPDF={this.rowPDF}
            showPdf={true}
            showEditIcon={false}
            getTableData={getTodaysCreditNoteForOutlet}
            showPegination={false}
            isActionColActive={true}
            showViewButtonInTable={true}
            staticExcel={true}
            generateExcelStatic={this.onExportHandler}
          />

          <CheckBoxSelectionTable
            selection={selection}
            onSelectionChange={this.getSelectedList}
            columns={CreditNoteColumns}
            rows={(CreditNoteColumns, creditNoteHeader.creditNoteHeader)}
            isActionColActive={true}
            rowPrint={this.rowPDF1}
            SummarizeRoundedShow={true}
            showViewButtonInTable={true}
            rowView={this.rowView}
            showDelete={false}
            // rowDelete={this.onDeleteStatic}
            showSearchBox={true}
          // showApply={true}
          // showApplyTitle={"Export CreditNote"}
          // rowApply={this.rowPDF}
          // showAdd={true}
          // showAddTitle={"Import Bill"}
          // rowAdd={this.onClickAdd}
          />
          <br />
          <DynamicDetails
            DataToSet={[
              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "Total Quantity",
                value: totalQty,
              },
              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "Total Taxable Amount",
                value: totalTaxable,
              },
              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "Total  Amount",
                value: totalAmount,
              },
            ]}
          />
          <br />
          {fieldDataForModule.view == 1 ? (
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              container
              justifyContent="right"
            >
              <Grid container xs={12} sm={3} md={3} lg={3}>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label={"*"}
                  />
                  <LabelCompo
                    className="text-black"
                    style={{
                      fontSize: 16,
                      color: titleColor,
                    }}
                    label={"Language" + " :"}
                  />
                </Grid>
                <Grid item xs={12} sm={7} md={7} lg={7}>
                  <AutoComplete
                    name={"Language"}
                    label={"Language"}
                    placeHolder={"Select Language"}
                    keyColName={"id"}
                    value={lang}
                    options={multilingualList.language}
                    onChange={this.onAutocompleteChange}
                    errorText={""}
                  />
                </Grid>
              </Grid>
              &nbsp;&nbsp;
              <ButtonCompo
                size="medium"
                type="submit"
                variant="contained"
                name="Credit Note PDF"
                onClick={this.onPDFBill}
              />
            </Grid>
          ) : (
            ""
          )}
        </>
      </Paper>
    );
  }
}
const mapStateToProps = (state) => ({
  areaList: state.area,
  outletList: state.outlet,
  beatList: state.beat,
  creditNoteHeader: state.creditNoteHeader,
  multilingualList: state.multilingual,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getBeatByArea,
  getActiveLanguage,
  getAreaByUserType,
  getOutletByBeatsAndAssignedStockHolder,
  getOutletByUserType,
  getCreditNoteHeaderPost,
  getTodaysCreditNoteForOutlet,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(OrderHistoryTable);
