import { Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import RTTable from "../../components/Comman/RT/RTTable";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import { ProductRateStructureJson } from "../../DynamicFormsJson/Transaction/productRateStructure";
import { getRuWiseProductConfigurationById } from "../../Slice/ruWiseProductConfig.slice";
import { showLoador, showNotification } from "../Landing/Landing.slice";

import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";

class ProductWiseRUConfigView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DataToSet: [],
      searchValue: "",
    };
  }

  async componentDidMount() {
    const { getRuWiseProductConfigurationById, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      if (this.props.params.id) {
        showLoador({ loador: true });
        getRuWiseProductConfigurationById({ id: this.props.params.id }).then(
          ({ response, success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            } else {
              let rowData = response;
              this.setState({
                headerData: rowData,
                DataToSet: [
                  {
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 6,
                    label: "Year",
                    value: rowData.yearName !== null ? rowData.yearName : "",
                  },
                  {
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 6,
                    label: "Name",
                    value: rowData.name !== null ? rowData.name : "",
                  },
                ],
              });
            }
          }
        );
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  getFilteredTableData = (searchData) => {
    const { searchValue } = this.state;
    return searchData.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["name"] &&
          currentRow["name"]
            .toString()
            .toLowerCase()
            .includes(searchValue.trim().toLowerCase())) ||
        (currentRow["stakeHolderUomName"] &&
          currentRow["stakeHolderUomName"]
            .toString()
            .toLowerCase()
            .includes(searchValue.trim().toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
    });
  };

  render() {
    const { dynamicMasterData, DataToSet } = this.state;
    const { ruWiseConfigByIdList } = this.props;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 0,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            showBackToList={true}
            DataToSet={DataToSet}
            dynamicMasterData={dynamicMasterData}
            showSaveBtn={false}
            showTitle={true}
            screenTitle={"Product Wise RU Configuration View"}
            fieldMeta={[]}
            showCancel={ProductRateStructureJson.showCancel}
            apiBaseURL={ProductRateStructureJson.apiBaseURL}
            showSaveNextBtn={false}
            saveBtnText={false}
            showSaveBtnMain={false}
            padding={true}
            paddingTop={true}
          />
          <LandingScreenHeader
            screenTitle={""}
            showSearchBox={true}
            showTable={true}
            showFilter={false}
            showPdfDownload={false}
            showExcelDownload={false}
            onSearch={this.onSearch}
          />
          <RTTable
            showPegination={false}
            columns={[
              { name: "index", title: "Sr. No.", align: "center" },
              { name: "name", title: "Product Name" },
              { name: "stakeHolderUomName", title: "Primary UOM", align: "center" },
              { name: "primaryQty", title: "Primary Quantity", align: "right" },
              { name: "ruQty", title: "RU Quantity", align: "right" },
            ]}
            tableData={this.getFilteredTableData(
              ruWiseConfigByIdList?.ruWiseConfigByIdDetails
            )}
            isActionColActive={false}
            showEditIcon={false}
            showDeleteIcon={false}
          />
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  ruWiseConfigByIdList: state.ruProductConfigHeader,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getRuWiseProductConfigurationById,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ProductWiseRUConfigView);
