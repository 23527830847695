import {
  Box,
  Grid,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import { withRouter } from "../../components/withRouter";
import { blackColor, primary } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import { apiGet, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";

import {
  CalendarTodayOutlined,
  DateRangeOutlined,
  DescriptionOutlined,
  LocalOfferOutlined,
} from "@mui/icons-material"; // Import Outlined icons

import swal from "sweetalert";
import offerImg from "../../Images/offer.png";

class MyPrimaryOffersForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      apiData: {},
      tabIndex: 0,
      rowList: [],
      fieldData: {},
      remark: "",
      dynamicMasterData: {},
      headerData: [
        {
          firmName: "ABC Supermart",
          ownerName: "John Doe",
          outletCode: "OUT123",
          mobileNo: "9876543210",
          beatName: "Downtown Beat",
          villageCity: "New York",
          details: [
            {
              index: 1,
              outletName: "ABC Supermart",
              orderNo: "ORD12345",
              orderDate: "2024-02-17",
              action: "View",
            },
          ],
        },
        {
          firmName: "XYZ Wholesale",
          ownerName: "Jane Smith",
          outletCode: "OUT456",
          mobileNo: "9876543222",
          beatName: "Uptown Beat",
          villageCity: "Los Angeles",
          details: [
            {
              index: 1,
              outletName: "XYZ Wholesale",
              orderNo: "ORD98765",
              orderDate: "2024-02-16",
              action: "View",
            },
          ],
        },
        {
          firmName: "XYZ Wholesale",
          ownerName: "Jane Smith",
          outletCode: "OUT456",
          mobileNo: "9876543222",
          beatName: "Uptown Beat",
          villageCity: "Los Angeles",
          details: [
            {
              index: 1,
              outletName: "XYZ Wholesale",
              orderNo: "ORD98765",
              orderDate: "2024-02-16",
              action: "View",
            },
          ],
        },
      ],
      searchValue: "",
    };
  }

  componentDidMount() {
    if (navigator.onLine) {
      this.props.showLoador({ loador: true });
      apiPost({
        url: `${endpoint.creditNoteOffer}/claim-for-offer-stackholder`,
        postBody: {},
      })
        .then(({ data, success }) => {
          console.log("API Response:", data?.data);

          this.props.showLoador({ loador: false });
          if (success) {
            this.setState({ apiData: this.transformApiData(data) });
          } else {
            this.props.showNotification({
              msg: "An error occurred",
              severity: "error",
            });
          }
        })
        .catch((error) => {
          console.error("API Error:", error);
          this.props.showNotification({
            msg: "An error occurred",
            severity: "error",
          });
          this.props.showLoador({ loador: false });
        });
    } else {
      this.props.showNotification({
        msg: "No internet connection",
        severity: "error",
      });
    }
  }

  getListApi = async () => {
    if (navigator.onLine) {
      this.props.showLoador({ loador: true });
      apiPost({
        url: `${endpoint.creditNoteOffer}/claim-for-offer-stackholder`,
        postBody: {},
      })
        .then(({ data, success }) => {
          console.log("API Response:", data?.data);
          this.props.showLoador({ loador: false });
          if (success) {
            this.setState({ apiData: this.transformApiData(data) });
          } else {
            this.props.showNotification({
              msg: "Failed to update status",
              severity: "error",
            });
          }
        })
        .catch((error) => {
          console.error("API Error:", error);
          this.props.showNotification({
            msg: "An error occurred",
            severity: "error",
          });
          this.props.showLoador({ loador: false });
        });
    } else {
      this.props.showNotification({
        msg: "No internet connection",
        severity: "error",
      });
    }
  };

  onDataChange = (fieldName, newValue) => {
    this.setState((prevState) => ({
      fieldData: { ...prevState.fieldData, [fieldName]: newValue },
    }));
  };

  changeHandler = (e) => {
    this.setState({ remark: e.target.value });
  };

  onSubmitData = async () => {
    swal({
      title: "Are you sure?",
      text: "Do you want to request it for clearance?",
      // icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((isSubmit) => {
      if (isSubmit) {
        if (navigator.onLine) {
          this.props.showLoador({ loador: true });
          apiPost({
            url: `${endpoint.creditNoteOffer}/generate-grn-stackholder`,
            postBody: this.state.apiData?.data?.offerList,
          })
            .then(({ data, success }) => {
              console.log("API Response:", data);
              this.props.showLoador({ loador: false });
              if (success) {
                this.props.showNotification({
                  msg: "Submitted successfully",
                  severity: "success",
                });
                this.getListApi();
              } else {
                this.props.showNotification({
                  msg: "Failed to update status",
                  severity: "error",
                });
              }
            })
            .catch((error) => {
              console.error("API Error:", error);
              this.props.showNotification({
                msg: "An error occurred",
                severity: "error",
              });
              this.props.showLoador({ loador: false });
            });
        } else {
          this.props.showNotification({
            msg: "No internet connection",
            severity: "error",
          });
        }
      }
    });
  };

  getFilteredTableData = (tableData) => {
    const { searchValue } = this.state;

    if (!searchValue) return tableData;
    return tableData.filter((currentRow) => {
      const lowerSearchValue = searchValue.toLowerCase();

      const searchFields = [currentRow.outletName];

      return searchFields.some(
        (field) => field && field.toLowerCase().includes(lowerSearchValue)
      );
    });
  };

  onSearchData = async (data) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      if (data.outlet && data.outlet != "" && data.fromDate && data.toDate) {
        let outletBillHeaderJson = {
          outletIds: data.outlet.split(","),
          fromDate: data.fromDate,
          toDate: data.toDate,
        };
        showLoador({ loador: true });
        await apiGet({
          outletBillHeaderJson: outletBillHeaderJson,
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
          }
        });
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  handleChange = (event, newValue) => {
    this.setState({ tabIndex: newValue });
  };

  transformApiData = (apiData) => {
    console.log("apiData", apiData);

    if (!apiData || !apiData.data) {
      return apiData;
    }

    // Creating a new transformed list `offerListNew`
    const offerListNew = apiData.data.offerList.map((offer, index) => ({
      name: offer.OfferDetail?.name || "-",
      description: offer.OfferDetail?.discription || "-",
      fromDate: offer.OfferDetail?.fromDate || "-",
      toDate: offer.OfferDetail?.toDate || "-",
      taxableAmt: Number(offer.taxableAmt || 0).toFixed(2),
      taxAmt: Number(
        (offer.cgstAmt || 0) + (offer.sgstAmt || 0) + (offer.igstAmt || 0)
      ).toFixed(2),
      totalAmt: Number(offer.totalAmt || 0).toFixed(2),
    billNo: offer.billNo || "-",
    billDate: offer.billDate || "-",

      // Transforming `itemList` within each offer
      items: Array.isArray(offer.itemList)
        ? offer.itemList.map((item, itemIndex) => ({
            index: itemIndex + 1,
            itemName: item?.name || "-",
            hsnCode: item?.hsnCode || "-",
            taxableAmt: Number(item?.texableAmt || 0).toFixed(2),
            taxAmt: Number(
              (item?.cgstAmt || 0) + (item?.sgstAmt || 0) + (item?.igstAmt || 0)
            ).toFixed(2),
            totalAmt: Number(item?.total || 0).toFixed(2),
          }))
        : [],
    }));

    // Transforming `notEgligibleOffer` list
    const noEligibleListNew = Array.isArray(apiData.data.notEgligibleOffer)
      ? apiData.data.notEgligibleOffer.map((offer, index) => ({
          name: offer?.name || "-",
          description: offer?.discription || "-",
          fromDate: offer?.fromDate || "-",
          toDate: offer?.toDate || "-",
          items:
            offer?.OfferDetail && Array.isArray(offer.OfferDetail)
              ? offer.OfferDetail.map((detail, detailIndex) => ({
                  index: detailIndex + 1,
                  itemName: detail?.itemName || "-",
                  hsnCode: detail?.hsnCode || "-",
                  taxableAmt: Number(detail?.taxableAmt || 0).toFixed(2),
                  taxAmt: Number(
                    (detail?.cgstAmt || 0) +
                      (detail?.sgstAmt || 0) +
                      (detail?.igstAmt || 0)
                  ).toFixed(2),
                  totalAmt: Number(detail?.totalAmt || 0).toFixed(2),
                }))
              : [],
        }))
      : [];

    return {
      ...apiData,
      data: {
        ...apiData.data,
        offerListNew,
        noEligibleListNew,
      },
    };
  };

  render() {
    const { apiData } = this.state;

    return (
      <Paper
        sx={{
          padding: 2,
          border: "1px solid #ddd", // Light grey border
          borderRadius: 2, // Rounded corners
          elevation: 3, // Shadow effect
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Custom shadow
        }}
      >
        <LandingScreenHeader
          screenTitle="My Primary offer"
          showSearchBox={true}
          showAddButton={false}
        />

        <Tabs
          value={this.state.tabIndex}
          onChange={this.handleChange}
          indicatorColor="primary"
          textColor="primary"
          sx={{ marginBottom: 2 }}
        >
          <Tab
            label={
              <Box
                sx={{
                  fontWeight: "bold",
                }}
              >
                {`Eligible Offers (${
                  apiData?.data?.offerListNew?.length || 0
                })`}
              </Box>
            }
          />

          <Tab
            label={`Not Eligible Offers (${
              apiData?.data?.noEligibleListNew?.length || 0
            })`}
          />
        </Tabs>

        {/* Not Eligible */}

        {this.state.tabIndex === 0 && (
          <>
            <Box
              sx={{
                overflowY: "auto",
                maxHeight: "500px",
                borderRadius: "10px",
                "&::-webkit-scrollbar": { display: "none" }, // Hides scrollbar in Chrome, Safari, Edge
              }}
            >
              {/* <Divider /> */}

              <Grid container alignItems="center">
                <Grid item xs={12} md={12} sm={12}>
                  {apiData?.data?.offerListNew?.length > 0 ? (
                    <>
                      {apiData?.data?.offerListNew?.map((dataRow, index) => (
                        <div
                          key={index}
                          style={{
                            marginBottom: "5px",
                            paddingBottom: "10px",
                          }}
                        >
                          <Box
                            sx={{
                              position: "relative", // Parent container for absolute positioning
                              padding: "1px",
                              border: "2px solid #ddd",
                              borderRadius: "15px",
                              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Soft shadow effect
                            }}
                          >
                            <Box
                              sx={{
                                position: "absolute",
                                top: "15px",
                                right: "30px",
                                width: "70px",
                                height: "70px",
                                animation: "blinker 1.0s linear infinite",
                                "@keyframes blinker": {
                                  "50%": { opacity: 0.2 }, // Fades in and out
                                },
                              }}
                            >
                              <img
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  borderRadius: "15%",
                                  objectFit: "contain",
                                  padding: "5px",
                                }}
                                src={offerImg}
                                alt="Offer Icon"
                              />
                            </Box>
                            <Grid container spacing={2} padding={4}>
                              {/*  */}

                              <Grid
                                container
                                spacing={2}
                                alignItems="center"
                                sx={{ marginLeft: 1 }}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  md={6}
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <IconButton size="small" disabled>
                                    <LocalOfferOutlined
                                      sx={{ color: blackColor }}
                                    />
                                  </IconButton>
                                  <LabelCompo
                                    className="text-black"
                                    style={{
                                      fontSize: 16,
                                      color: primary,
                                      marginRight: 8,
                                    }}
                                    label="Offer Name:"
                                  />
                                  <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16 }}
                                    label={dataRow?.name || "-"}
                                  />
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  md={6}
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <IconButton size="small" disabled>
                                    <DescriptionOutlined
                                      sx={{ color: blackColor }}
                                    />
                                  </IconButton>
                                  <LabelCompo
                                    className="text-black"
                                    style={{
                                      fontSize: 16,
                                      color: primary,
                                      marginRight: 8,
                                    }}
                                    label="Description:"
                                  />
                                  <LabelCompo
                                    className="text-black"
                                    style={{
                                      fontSize: 16,
                                      wordWrap: "break-word", // Breaks long words
                                      overflowWrap: "break-word", // Ensures wrapping
                                      whiteSpace: "normal", // Allows text to wrap
                                      maxWidth: "70%", // Ensures it stays within the container
                                    }}
                                    label={dataRow?.description || "-"}
                                  />
                                </Grid>
                              </Grid>

                              {/* Second Row: From Date & To Date */}
                              <Grid
                                container
                                spacing={2}
                                alignItems="center"
                                sx={{ marginTop: 1, marginLeft: 1 }}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  md={6}
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <IconButton size="small" disabled>
                                    <CalendarTodayOutlined
                                      sx={{ color: blackColor }}
                                    />
                                  </IconButton>
                                  <LabelCompo
                                    className="text-black"
                                    style={{
                                      fontSize: 16,
                                      color: primary,
                                      marginRight: 8,
                                    }}
                                    label="From Date:"
                                  />
                                  <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16 }}
                                    label={dataRow?.fromDate || "-"}
                                  />
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  md={6}
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <IconButton size="small" disabled>
                                    <DateRangeOutlined
                                      sx={{ color: blackColor }}
                                    />
                                  </IconButton>
                                  <LabelCompo
                                    className="text-black"
                                    style={{
                                      fontSize: 16,
                                      color: primary,
                                      marginRight: 8,
                                    }}
                                    label="To Date:"
                                  />
                                  <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16 }}
                                    label={dataRow?.toDate || "-"}
                                  />
                                </Grid>
                              </Grid>
                              {/*  */}


                                  {/* Second Row: From Date & To Date */}
                                  <Grid
                                  container
                                  spacing={2}
                                  alignItems="center"
                                  sx={{ marginTop: 1, marginLeft: 1 }}
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <IconButton size="small" disabled>
                                      <CalendarTodayOutlined
                                        sx={{ color: blackColor }}
                                      />
                                    </IconButton>
                                    <LabelCompo
                                      className="text-black"
                                      style={{
                                        fontSize: 16,
                                        color: primary,
                                        marginRight: 8,
                                      }}
                                      label="Bill No:"
                                    />
                                    <LabelCompo
                                      className="text-black"
                                      style={{ fontSize: 16 }}
                                      label={dataRow?.billNo || "-"}
                                    />
                                  </Grid>

                                  <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <IconButton size="small" disabled>
                                      <DateRangeOutlined
                                        sx={{ color: blackColor }}
                                      />
                                    </IconButton>
                                    <LabelCompo
                                      className="text-black"
                                      style={{
                                        fontSize: 16,
                                        color: primary,
                                        marginRight: 8,
                                      }}
                                      label="Bill Date:"
                                    />
                                    <LabelCompo
                                      className="text-black"
                                      style={{ fontSize: 16 }}
                                      label={dataRow?.billDate || "-"}
                                    />
                                  </Grid>
                                </Grid>
                                {/*  */}

                              {/* Table for Details */}

                              {dataRow?.items?.length > 0 && (
                                <>
                                  <Grid
                                    container
                                    item
                                    xs={12}
                                    md={12}
                                    sm={12}
                                    sx={{ marginTop: 1 }}
                                  >
                                    <DynamicTable
                                      data={
                                        Array.isArray(dataRow?.items)
                                          ? this.getFilteredTableData(
                                              dataRow?.items
                                            )
                                          : []
                                      }
                                      tableHead={[
                                        {
                                          title: "Item Name",
                                          name: "itemName",
                                          positionCenter: false,
                                          showInscreen: true,
                                          alignCenter: "left",
                                          canSearch: true,
                                        },
                                        {
                                          title: "HSN Code",
                                          name: "hsnCode",
                                          positionCenter: false,
                                          showInscreen: true,
                                          alignCenter: "center",
                                          canSearch: true,
                                        },
                                        {
                                          title: "Taxable Amount",
                                          name: "taxableAmt",
                                          positionCenter: false,
                                          showInscreen: true,
                                          alignCenter: "right",
                                          canSearch: true,
                                        },

                                        {
                                          title: "Tax Amount",
                                          name: "taxAmt",
                                          positionCenter: false,
                                          showInscreen: true,
                                          alignCenter: "right",
                                          canSearch: true,
                                        },

                                        {
                                          title: "Total Amount",
                                          name: "totalAmt",
                                          positionCenter: false,
                                          showInscreen: true,
                                          alignCenter: "right",
                                          canSearch: true,
                                        },
                                      ]}
                                      showPegination={false}
                                      isActionColActive={false}
                                    />
                                  </Grid>

                                  <Grid
                                    container
                                    spacing={2}
                                    sx={{ marginLeft: 1, marginTop: 1 }}
                                    justifyContent="space-between"
                                  >
                                    <Grid item xs={12} md={4} lg={4}>
                                      <Typography
                                        variant="h6"
                                        sx={{ color: primary }}
                                      >
                                        ₹ Total Taxable Amount:{" "}
                                        {Number(
                                          dataRow?.taxableAmt || 0
                                        ).toFixed(2)}
                                      </Typography>
                                    </Grid>

                                    <Grid item xs={12} md={4} lg={4}>
                                      <Typography
                                        variant="h6"
                                        sx={{ color: primary }}
                                      >
                                        ₹ Total Tax Amount:{" "}
                                        {Number(dataRow?.taxAmt).toFixed(2)}
                                      </Typography>
                                    </Grid>

                                    <Grid item xs={12} md={4} lg={4}>
                                      <Typography
                                        variant="h6"
                                        sx={{ color: primary }}
                                      >
                                        ₹ Total Amount:{" "}
                                        {Number(dataRow?.totalAmt).toFixed(2)}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </>
                              )}
                            </Grid>
                          </Box>
                        </div>
                      ))}
                    </>
                  ) : (
                    <Typography
                      variant="h6"
                      color="textSecondary"
                      sx={{ textAlign: "center", mt: 2 }}
                    >
                      No offers available at the moment.
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Box>

            {apiData?.data?.offerListNew?.length > 0 && (
              <>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="right"
                  sx={{ marginTop: 1, padding: 2 }}
                >
                  <ButtonCompo
                    size="medium"
                    type="Submit"
                    variant="contained"
                    name="Request for claim"
                    onClick={this.onSubmitData}
                  />
                </Grid>
              </>
            )}
          </>
        )}

        {/* Not Eligible end*/}

        {/* Not Eligible Offer */}
        {this.state.tabIndex === 1 && (
          <>
            <Box
              sx={{
                overflowY: "auto",
                maxHeight: "500px",
                borderRadius: "8px",
                "&::-webkit-scrollbar": { display: "none" }, // Hides scrollbar in Chrome, Safari, Edge
              }}
            >
              {/* <Divider style={{ marginBottom: 15 }} /> */}

              <Grid container alignItems="center">
                <Grid item xs={12} md={12} sm={12}>
                  {apiData?.data?.noEligibleListNew?.length > 0 ? (
                    <>
                      {apiData?.data?.noEligibleListNew?.map(
                        (dataRow, index) => (
                          <div
                            key={index}
                            style={{
                              marginBottom: "5px",
                              paddingBottom: "10px",
                            }}
                          >
                            <Box
                              sx={{
                                position: "relative", // Parent container for absolute positioning
                                padding: "1px",
                                border: "2px solid #ddd",
                                borderRadius: "15px",
                                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Soft shadow effect
                              }}
                            >
                              <Box
                                sx={{
                                  position: "absolute",
                                  top: "15px",
                                  right: "30px",
                                  width: "70px",
                                  height: "70px",
                                  animation: "blinker 1.0s linear infinite",
                                  "@keyframes blinker": {
                                    "50%": { opacity: 0.2 }, // Fades in and out
                                  },
                                }}
                              >
                                <img
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "15%",
                                    objectFit: "contain",
                                    padding: "5px",
                                  }}
                                  src={offerImg}
                                  alt="Offer Icon"
                                />
                              </Box>
                              <Grid container spacing={2} padding={4}>
                                {/*  */}

                                <Grid
                                  container
                                  spacing={2}
                                  alignItems="center"
                                  sx={{ marginLeft: 1 }}
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <IconButton size="small" disabled>
                                      <LocalOfferOutlined
                                        sx={{ color: blackColor }}
                                      />
                                    </IconButton>
                                    <LabelCompo
                                      className="text-black"
                                      style={{
                                        fontSize: 16,
                                        color: primary,
                                        marginRight: 8,
                                      }}
                                      label="Offer Name:"
                                    />
                                    <LabelCompo
                                      className="text-black"
                                      style={{ fontSize: 16 }}
                                      label={dataRow?.name || "-"}
                                    />
                                  </Grid>

                                  <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <IconButton size="small" disabled>
                                      <DescriptionOutlined
                                        sx={{ color: blackColor }}
                                      />
                                    </IconButton>
                                    <LabelCompo
                                      className="text-black"
                                      style={{
                                        fontSize: 16,
                                        color: primary,
                                        marginRight: 8,
                                      }}
                                      label="Description:"
                                    />
                                    <LabelCompo
                                      className="text-black"
                                      style={{
                                        fontSize: 16,
                                        wordWrap: "break-word", // Breaks long words
                                        overflowWrap: "break-word", // Ensures wrapping
                                        whiteSpace: "normal", // Allows text to wrap
                                        maxWidth: "70%", // Ensures it stays within the container
                                      }}
                                      label={dataRow?.description || "-"}
                                    />
                                  </Grid>
                                </Grid>

                                {/* Second Row: From Date & To Date */}
                                <Grid
                                  container
                                  spacing={2}
                                  alignItems="center"
                                  sx={{ marginTop: 1, marginLeft: 1 }}
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <IconButton size="small" disabled>
                                      <CalendarTodayOutlined
                                        sx={{ color: blackColor }}
                                      />
                                    </IconButton>
                                    <LabelCompo
                                      className="text-black"
                                      style={{
                                        fontSize: 16,
                                        color: primary,
                                        marginRight: 8,
                                      }}
                                      label="From Date:"
                                    />
                                    <LabelCompo
                                      className="text-black"
                                      style={{ fontSize: 16 }}
                                      label={dataRow?.fromDate || "-"}
                                    />
                                  </Grid>

                                  <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <IconButton size="small" disabled>
                                      <DateRangeOutlined
                                        sx={{ color: blackColor }}
                                      />
                                    </IconButton>
                                    <LabelCompo
                                      className="text-black"
                                      style={{
                                        fontSize: 16,
                                        color: primary,
                                        marginRight: 8,
                                      }}
                                      label="To Date:"
                                    />
                                    <LabelCompo
                                      className="text-black"
                                      style={{ fontSize: 16 }}
                                      label={dataRow?.toDate || "-"}
                                    />
                                  </Grid>
                                </Grid>
                                {/*  */}




                                {/* Table for Details */}

                                {dataRow?.items?.length > 0 && (
                                  <>
                                    <Grid
                                      container
                                      item
                                      xs={12}
                                      md={12}
                                      sm={12}
                                      sx={{ marginTop: 1 }}
                                    >
                                      <DynamicTable
                                        data={
                                          Array.isArray(dataRow?.items)
                                            ? this.getFilteredTableData(
                                                dataRow?.items
                                              )
                                            : []
                                        }
                                        tableHead={[
                                          {
                                            title: "Item Name",
                                            name: "itemName",
                                            positionCenter: false,
                                            showInscreen: true,
                                            alignCenter: "left",
                                            canSearch: true,
                                          },
                                          {
                                            title: "HSN Code",
                                            name: "hsnCode",
                                            positionCenter: false,
                                            showInscreen: true,
                                            alignCenter: "center",
                                            canSearch: true,
                                          },
                                          {
                                            title: "Taxable Amount",
                                            name: "taxableAmt",
                                            positionCenter: false,
                                            showInscreen: true,
                                            alignCenter: "right",
                                            canSearch: true,
                                          },

                                          {
                                            title: "Tax Amount",
                                            name: "taxAmt",
                                            positionCenter: false,
                                            showInscreen: true,
                                            alignCenter: "right",
                                            canSearch: true,
                                          },

                                          {
                                            title: "Total Amount",
                                            name: "totalAmt",
                                            positionCenter: false,
                                            showInscreen: true,
                                            alignCenter: "right",
                                            canSearch: true,
                                          },
                                        ]}
                                        showPegination={false}
                                        isActionColActive={false}
                                      />
                                    </Grid>
                                  </>
                                )}
                              </Grid>
                            </Box>
                          </div>
                        )
                      )}
                    </>
                  ) : (
                    <Typography
                      variant="h6"
                      color="textSecondary"
                      sx={{ textAlign: "center", marginBottom: 5 }}
                    >
                      No offers available at the moment.
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Box>
          </>
        )}

        {/* Not Eligible Offer end*/}
      </Paper>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = { showLoador, showNotification };

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(MyPrimaryOffersForm);
