import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import { GroupProductColumns } from "../../../tableColumns/table-columns";
import { groupProductJson } from "../../../DynamicFormsJson/MastersJSON/groupProduct";
import { getGroupProductList } from "../../../Slice/groupProduct.slice";
import endpoint from "../../../config/endpoints";
import { deleteFailedMsg, deletemsg, saveFailedMsg, serverMsg } from "../../../config/messageconstant";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import { apiDelete, apiGet, apiPost } from "../../../utils/api_service";

class GroupProductTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLog: true,
      moduleObjDataObj: {},
      showStaticDeleteToGroupProduct: "",
    };
  }

  async componentDidMount() {
    if (navigator.onLine) {

      const moduleObjData = localStorage.getItem("moduleObj");
      const moduleObjDataObj = moduleObjData != null ? JSON.parse(moduleObjData) : {};

      const showStaticDeleteToGroupProduct = localStorage.getItem("showStaticDeleteToGroupProduct");
      this.setState({ moduleObjDataObj: moduleObjDataObj, showStaticDeleteToGroupProduct: showStaticDeleteToGroupProduct })

    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }


  onDeleteStatic = (rowData) => {

    console.log(rowData);

    const { showLoador, showNotification } = this.props;
    const { moduleObjDataObj, isLog } = this.state;

    showLoador({ loador: true });
    apiGet({
      url:
        endpoint.product + "/check-present-or-not-by-group?groupId=" + rowData.id
    }).then(({ data, success }) => {
      showLoador({ loador: false });
      if (!success) {
        showNotification({ msg: serverMsg, severity: "error" });
      } else {
        if (data && data.data && Object.keys(data.data).length !== 0) {
          if (data.data.statusCode == 500) {
            showNotification({ msg: data.data.message, severity: "error" });
          } else {
            showLoador({ loador: true });
            apiDelete({
              url: endpoint.group + "/" + rowData.id,
            }).then(({ data, success }) => {
              showLoador({ loador: false });
              if (data.data.error == false) {
                if (isLog) {
                  if (rowData !== null) {

                    let updatedJsonToSend = JSON.stringify(rowData);

                    let dataToSaveForMLog = {
                      module: {
                        moduleId: moduleObjDataObj.moduleId
                      },
                      subModule: {
                        subModuleId: moduleObjDataObj.subModuleId
                      },
                      operationType: 2,
                      type: 0,
                      operationPerformId: rowData.id,
                      updatedJson: updatedJsonToSend,
                    }

                    showLoador({ loador: true });
                    apiPost({
                      url: endpoint.mlog,
                      postBody: dataToSaveForMLog,
                    }).then(({ success }) => {
                      showLoador({ loador: false });
                      if (success) {
                      } else {
                        showNotification({
                          msg: saveFailedMsg,
                          severity: "error",
                        });
                      }
                    });
                  }
                }
                showNotification({ msg: deletemsg, severity: "success" });
                window.location.reload();
              } else {
                showNotification({ msg: deleteFailedMsg, severity: "error" });
              }
            });
          }
        }
      }
    });
  }

  render() {
    const { groupProductList, getGroupProductList } = this.props;
    const { showStaticDeleteToGroupProduct } = this.state;
    return (
      <>
        <DynamicMainScreen
          formPath={groupProductJson.formPath}
          screenTitle={groupProductJson.screenTitle}
          showViewButtonInTable={groupProductJson.formPathView}
          fieldMeta={groupProductJson.fieldMeta}
          showAddButton={groupProductJson.showAddButton}
          tableColumns={GroupProductColumns}
          tableData={groupProductList?.groupProduct}
          getTableData={getGroupProductList}
          apiBaseURL={groupProductJson.apiBaseURL}
          baseIdColumn={groupProductJson.baseIdColumn}
          showEditIcon={true}
          showPdfDownload={false}
          showExcelDownload={true}
          filterDataExcel={true}
          changeExcelURL={groupProductJson.excelUrl}
          showExtension={false}
          formPathView={groupProductJson.formPathView}
          showDeleteIcon={showStaticDeleteToGroupProduct == 1 ? true : false}
          deleteURLStatic={showStaticDeleteToGroupProduct == 1 ? true : false}
          onDeleteStatic={this.onDeleteStatic}
          isNote={true}
          isNoteValue={"Add Group & Products"}
          isActiveURL={groupProductJson.isActiveURL}
          isLog={true}
          type={0}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  groupProductList: state.groupProduct,
});
const mapDispatchToProps = {
  getGroupProductList,
  showLoador,
  showNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(GroupProductTable);
