import endpoint from "../../config/endpoints";

export const creditNoteJSON = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.area,
  screenTitle: "Outlet Credit Note",
  formPath: "/credit-note-for-stakeholder",
  formPathView: "/credit-note-outlet-view",
  showBackToList: true,
  showTitle: false,
  searchList: [
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromdate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "todate-le",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "todate",
      onSubmit: "compare",
      onSubmitParameter: "fromdate-ge",
      isMandatory: true,
      onSubmit: "compare",
      format: "DD-MM-YYYY",
      maxDatekey: 'fromdate',
      days: 31
    },
    {
      label: "Area",
      controlType: "autocomplete",
      placeHolder: "Select Area",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "areaDetail",
      dataKey: "area",
      getListFrom: "taluka",
      getListId: "area",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Beat",
      controlType: "multiSelect",
      placeHolder: "Select Beat",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      dataKey: "beat",
      masterName: "beatDetail",
      getListFrom: "area",
      getListId: "beat",
      isMandatory: true,
      isRootLevelKey: true,
    },
    {
      label: "Outlet",
      controlType: "multiSelect",
      placeHolder: "Select Outlet",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      dataKey: "outletIds",
      masterName: "outletDetail",
      getListFrom: "beat",
      isRootLevelKey: true,
      isMandatory: true,
    },
  ],

  fieldMeta: [
    {
      label: "Area",
      controlType: "autocomplete",
      placeHolder: "Select Area ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "areaDetail",
      dataKey: "area",
      isRootLevelKey: true,
      isMandatory: true,
      getListId: "area",
    },
    {
      label: "Beat ",
      controlType: "autocomplete",
      placeHolder: "Select Beat",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "beatDetail",
      dataKey: "beat",
      getListFrom: "area",
      isRootLevelKey: false,
      isMandatory: true,
      getListId: "beat",
    },
    {
      label: "Outlet ",
      controlType: "autocomplete",
      placeHolder: "Select Outlet",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "outletDetail",
      dataKey: "outlet",
      getListFrom: "beat",
      isRootLevelKey: false,
      isMandatory: true,
      getListId: "outlet",
    },
    {
      label: "Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "date",
      isMandatory: true,
    },
  ],
  fieldMetaDetails: [
    {
      label: "Category",
      controlType: "autocomplete",
      placeHolder: "Select Category ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "categoryDetail",
      dataKey: "category",
      isRootLevelKey: true,
      isMandatory: true,
      getListFrom: "outlet",
      getListId: "category",
    },
    {
      label: "Product ",
      controlType: "autocomplete",
      placeHolder: "Select Product",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "productDetail",
      dataKey: "product",
      getListFrom: "category",
      isRootLevelKey: true,
      isMandatory: true,
      getData: true,
    },
    {
      label: "Batch",
      controlType: "textfield",
      placeHolder: "Enter Batch",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "batch",
      isMandatory: true,
    },
    {
      label: "Quantity",
      controlType: "textfield",
      placeHolder: "Enter Quantity",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      inputType: "number",
      dataKey: "qty",
      isMandatory: true,
    },
    {
      label: "Rate",
      controlType: "textfield",
      placeHolder: "Enter Rate",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      inputType: "number",
      dataKey: "rate",
      isMandatory: true,
      spValid: "float",
      disableTrue: true,
    },
    {
      label: "UOM",
      controlType: "textfield",
      placeHolder: "Enter Rate",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "uom",
      isMandatory: true,
      disableTrue: true,
    },
    {
      label: "Add To Scrap",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "calStock",
      tableDataKey: "calStock",
      defaultValue: "Yes",
      radioGroupItems: [
        {
          label: "Yes",
          value: "Yes",
        },
        {
          label: "No",
          value: "No",
        },
      ],
      isMandatory: true,
    },
  ],
};
