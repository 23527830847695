import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
const initialState = {
  manager: [],
  allNSM: [],
  salesTeamData: [],
  salesTeamUserSummary: {},
  salesTeamWorkingSummary: [],
};

let URL = endpoints.manager;
const managerSlice = createSlice({
  name: "manager",
  initialState,
  reducers: {
    managerSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        manager: row,
      };
    },

    allNSMSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        allNSM: row,
      };
    },

    salesTeamBelowReportingIdSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        salesTeamData: row,
      };
    },

    salesTeamUserSummarySuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        salesTeamUserSummary: row,
      };
    },

    salesTeamWorkingSummarySuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        salesTeamWorkingSummary: row,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  managerSuccess,
  allNSMSuccess,
  salesTeamBelowReportingIdSuccess,
  salesTeamUserSummarySuccess,
  salesTeamWorkingSummarySuccess,
  resetState,
} = managerSlice.actions;

export default managerSlice.reducer;

export const getManager =
  ({ areaIds, stockStakeHolderTypeId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL +
            "/get-sales-manager-by-areas-and-stock-stake_holder-type?areaIds=" +
            areaIds +
            "&stockStakeHolderTypeId=" +
            stockStakeHolderTypeId,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((managerObject, index) => {
              let managerData = {
                index: index + 1,
                id: managerObject.id === null ? "" : managerObject.id,
                userName:
                  managerObject.userName === null ? "" : managerObject.userName,
                name: managerObject.name === null ? "" : managerObject.name,
                address:
                  managerObject.address === null ? "" : managerObject.address,
                region:
                  managerObject.region !== null && managerObject.region.id
                    ? managerObject.region.id
                    : "",
                regionName:
                  managerObject.region !== null && managerObject.region.name
                    ? managerObject.region.name
                    : "",
                zoneNames:
                  managerObject.zoneNames === null ? "" : managerObject.zoneNames,
                areaNames:
                  managerObject.areaNames === null ? "" : managerObject.areaNames,
                emailId:
                  managerObject.emailId === null || managerObject.emailId === "-"
                    ? "-"
                    : managerObject.emailId,
                contactNo:
                  managerObject.contactNo === null ? "" : managerObject.contactNo,
                whatsAppNo:
                  managerObject.whatsAppNo === null
                    ? ""
                    : managerObject.whatsAppNo,
                isActive:
                  managerObject.isActive === null ? "" : managerObject.isActive,
              };
              return managerData;
            });
            dispatch(managerSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getUpdatedManager =
  ({ managerJson }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          // http://localhost:8088/api/distribution/v1/sales-team/sales-manager-by-areas-and-stock-stake_holder-type
          url: URL + "/sales-manager-by-areas-and-stock-stake_holder-type",
          postBody: managerJson,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((managerObject, index) => {
              let managerData = {
                index: index + 1,
                id: managerObject.id === null ? "" : managerObject.id,
                userName:
                  managerObject.userName === null ? "" : managerObject.userName,
                name: managerObject.name === null ? "" : managerObject.name,
                stockStakeHolderType:
                  managerObject.stockStakeHolderType === null
                    ? ""
                    : managerObject.stockStakeHolderType.name,
                address:
                  managerObject.address === null ? "" : managerObject.address,
                region:
                  managerObject.regionId !== null ? managerObject.regionId : "",
                regionName:
                  managerObject.regionName !== null
                    ? managerObject.regionName
                    : "",
                zoneNames:
                  managerObject.zoneNames === null ? "" : managerObject.zoneNames,
                areaNames:
                  managerObject.areaNames === null ? "" : managerObject.areaNames,
                emailId:
                  managerObject.emailId === null || managerObject.emailId === "-"
                    ? "-"
                    : managerObject.emailId,
                contactNo:
                  managerObject.contactNo === null ? "" : managerObject.contactNo,
                whatsAppNo:
                  managerObject.whatsAppNo === null
                    ? ""
                    : managerObject.whatsAppNo,
                isActive:
                  managerObject.isActive === null ? "" : managerObject.isActive,
              };
              return managerData;
            });
            dispatch(managerSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getManagerAll = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/get-sales-manager-by-areas",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((managerObject, index) => {
          let managerData = {
            index: index + 1,
            id: managerObject.id === null ? "" : managerObject.id,
            userId: managerObject.userId === null ? "" : managerObject.userId,
            userName:
              managerObject.userName === null ? "" : managerObject.userName,
            stockStakeHolderType:
              managerObject.stockStakeHolderType === null
                ? ""
                : managerObject.stockStakeHolderType.name,
            userType:
              managerObject.stockStakeHolderType.userType === null
                ? ""
                : managerObject.stockStakeHolderType.userType.name,
            name: managerObject.name === null ? "" : managerObject.name,
            address:
              managerObject.address === null ? "" : managerObject.address,
            regionName:
              managerObject.region === null ? "" : managerObject.region.name,
            zoneNames:
              managerObject.zoneNames === null ? "" : managerObject.zoneNames,
            areaNames:
              managerObject.areaNames === null ? "" : managerObject.areaNames,
            emailId:
              managerObject.emailId === null ? "" : managerObject.emailId,
            contactNo:
              managerObject.contactNo === null ? "" : managerObject.contactNo,
            whatsAppNo:
              managerObject.whatsAppNo === null ? "" : managerObject.whatsAppNo,
            employeeCode:
              managerObject.employeeCode === null
                ? ""
                : managerObject.employeeCode,

            accessRoleId:
              managerObject.accessRoleId === null
                ? ""
                : managerObject.accessRoleId,
            isActive:
              managerObject.isActive === null ? "" : managerObject.isActive,
          };
          return managerData;
        });
        dispatch(managerSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getManagerAllUpdated = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/get-manager-by-areas",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((managerObject, index) => {
          let managerData = {
            index: index + 1,
            id: managerObject.id === null ? "" : managerObject.id,
            userId: managerObject.userId === null ? "" : managerObject.userId,
            userName:
              managerObject.userName === null ? "" : managerObject.userName,
            stockStakeHolderType:
              managerObject.stockStakeHolderType === null
                ? ""
                : managerObject.stockStakeHolderType.name,
            userType:
              managerObject.stockStakeHolderType.userType === null
                ? ""
                : managerObject.stockStakeHolderType.userType.name,
            name: managerObject.name === null ? "" : managerObject.name,
            address:
              managerObject.address === null ? "" : managerObject.address,
            regionName:
              managerObject.regionName === null ? "" : managerObject.regionName,
            zoneNames:
              managerObject.zoneNames === null ? "" : managerObject.zoneNames,
            areaNames:
              managerObject.areaNames === null ? "" : managerObject.areaNames,
            emailId:
              managerObject.emailId === null ? "" : managerObject.emailId,
            contactNo:
              managerObject.contactNo === null ? "" : managerObject.contactNo,
            whatsAppNo:
              managerObject.whatsAppNo === null ? "" : managerObject.whatsAppNo,
            employeeCode:
              managerObject.employeeCode === null
                ? ""
                : managerObject.employeeCode,

            accessRoleId:
              managerObject.accessRoleId === null
                ? ""
                : managerObject.accessRoleId,
            isActive:
              managerObject.isActive === null ? "" : managerObject.isActive,
          };
          return managerData;
        });
        dispatch(managerSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getTopOrderSalesTeam = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/get-top-order-sales-team",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((managerObject, index) => {
          let managerData = {
            index: index + 1,
            id: managerObject.id === null ? "" : managerObject.id,
            userId: managerObject.userId === null ? "" : managerObject.userId,
            userName:
              managerObject.userName === null ? "" : managerObject.userName,

            stockStakeHolderTypeId:
              managerObject.stockStakeHolderType !== null &&
                managerObject.stockStakeHolderType.id !== null
                ? managerObject.stockStakeHolderType.id
                : "",

            stockStakeHolderTypeName:
              managerObject.stockStakeHolderType !== null &&
                managerObject.stockStakeHolderType.name !== null
                ? managerObject.stockStakeHolderType.name
                : "",

            userTypeId:
              managerObject.stockStakeHolderType !== null &&
                managerObject.stockStakeHolderType.userType !== null
                ? managerObject.stockStakeHolderType.userType.id
                : "",

            userTypeName:
              managerObject.stockStakeHolderType !== null &&
                managerObject.stockStakeHolderType.userType !== null
                ? managerObject.stockStakeHolderType.userType.name
                : "",

            name: managerObject.name === null ? "" : managerObject.name,
            address:
              managerObject.address === null ? "" : managerObject.address,
            regionId:
              managerObject.regionId === null ? "" : managerObject.regionId,
            zoneIds:
              managerObject.zoneIds === null ? "" : managerObject.zoneIds,
            areaIds:
              managerObject.areaIds === null ? "" : managerObject.areaIds,
            emailId:
              managerObject.emailId === null ? "" : managerObject.emailId,
            contactNo:
              managerObject.contactNo === null ? "" : managerObject.contactNo,
            whatsAppNo:
              managerObject.whatsAppNo === null ? "" : managerObject.whatsAppNo,
            employeeCode:
              managerObject.employeeCode === null
                ? ""
                : managerObject.employeeCode,

            accessRoleId:
              managerObject.accessRoleId === null
                ? ""
                : managerObject.accessRoleId,
            isActive:
              managerObject.isActive === null ? "" : managerObject.isActive,
          };
          return managerData;
        });
        dispatch(allNSMSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getBelowSalesTeamByReportingId =
  ({ stockStakeHolderTypeId, salesTeamId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL +
            "/get-below-sales-team-by-reporting-id?stockStakeHolderTypeId=" +
            stockStakeHolderTypeId +
            "&salesTeamId=" +
            salesTeamId,
        }).then(({ data, success }) => {
          if (success) {
            dispatch(salesTeamBelowReportingIdSuccess({ row: data.data }));
            return { response: data, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getSalesTeamUserSummary =
  ({ stockStakeHolderTypeId, salesTeamId, userTypeId, fromDate, toDate }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL +
            "/user-summary?stockStakeHolderTypeId=" +
            stockStakeHolderTypeId +
            "&salesTeamId=" +
            salesTeamId +
            "&userTypeId=" +
            userTypeId +
            "&fromDate=" +
            fromDate +
            "&toDate=" +
            toDate,
        }).then(({ data, success }) => {
          if (success) {
            const managerObject = data.data;

            let managerData = {
              index: 1,
              flag: managerObject.flag === null ? "" : managerObject.flag,
              id: managerObject.id === null ? "" : managerObject.id,
              userId: managerObject.userId === null ? "" : managerObject.userId,
              userName:
                managerObject.userName === null ? "" : managerObject.userName,

              name: managerObject.name === null ? "" : managerObject.name,

              stockStakeHolderTypeId:
                managerObject.stockStakeHolderType !== null &&
                  managerObject.stockStakeHolderType.id !== null
                  ? managerObject.stockStakeHolderType.id
                  : "",

              stockStakeHolderTypeName:
                managerObject.stockStakeHolderType !== null &&
                  managerObject.stockStakeHolderType.name !== null
                  ? managerObject.stockStakeHolderType.name
                  : "",

              userTypeId:
                managerObject.stockStakeHolderType !== null &&
                  managerObject.stockStakeHolderType.userType !== null
                  ? managerObject.stockStakeHolderType.userType.id
                  : "",

              userTypeName:
                managerObject.stockStakeHolderType !== null &&
                  managerObject.stockStakeHolderType.userType !== null
                  ? managerObject.stockStakeHolderType.userType.name
                  : "",

              address:
                managerObject.address === null ? "" : managerObject.address,
              reportingManagerTypeId:
                managerObject.reportingManagerTypeId === null
                  ? ""
                  : managerObject.reportingManagerTypeId,
              reportingManagerId:
                managerObject.reportingManagerId === null
                  ? ""
                  : managerObject.reportingManagerId,
              reportingManagerName:
                managerObject.reportingManagerName === null
                  ? ""
                  : managerObject.reportingManagerName,

              regionId:
                managerObject.regionId === null ? "" : managerObject.regionId,

              stateId:
                managerObject.stateId === null ? "" : managerObject.stateId,

              districtIds:
                managerObject.districtIds === null
                  ? ""
                  : managerObject.districtIds,

              talukaIds:
                managerObject.talukaIds === null ? "" : managerObject.talukaIds,

              zoneIds:
                managerObject.zoneIds === null ? "" : managerObject.zoneIds,

              areaIds:
                managerObject.areaIds === null ? "" : managerObject.areaIds,

              areaNames:
                managerObject.areaNames === null ? "" : managerObject.areaNames,

              emailId:
                managerObject.emailId === null ? "" : managerObject.emailId,

              contactNo:
                managerObject.contactNo === null ? "" : managerObject.contactNo,

              whatsAppNo:
                managerObject.whatsAppNo === null ? "" : managerObject.whatsAppNo,

              employeeCode:
                managerObject.employeeCode === null
                  ? ""
                  : managerObject.employeeCode,

              type: managerObject.type === null ? "" : managerObject.type,

              beatCounts:
                managerObject.beatCounts === null ? "" : managerObject.beatCounts,

              checkInTime:
                managerObject.checkInTime === null ? "-" : managerObject.checkInTime,

              checkOutTime:
                managerObject.checkOutTime === null ? "-" : managerObject.checkOutTime,

              firstCallTime:
                managerObject.firstCallTime === null ? "-" : managerObject.firstCallTime,

              lastCallTime:
                managerObject.lastCallTime === null ? "-" : managerObject.lastCallTime,

              totalRetailCallTime:
                managerObject.totalRetailCallTime === null ? "-" : managerObject.totalRetailCallTime,

              workingHrs:
                managerObject.workingHrs === null ? "-" : managerObject.workingHrs,

              totalOutlet:
                managerObject.totalOutlet === null
                  ? ""
                  : managerObject.totalOutlet,

              productiveOrderCall:
                managerObject.productiveOrderCall === null
                  ? ""
                  : managerObject.productiveOrderCall,

              remoteOrderCall:
                managerObject.remoteOrderCall === null
                  ? ""
                  : managerObject.remoteOrderCall,

              zeroOrdeCall:
                managerObject.zeroOrdeCall === null
                  ? ""
                  : managerObject.zeroOrdeCall,

              totalOrderCall:
                managerObject.totalOrderCall === null
                  ? ""
                  : managerObject.totalOrderCall,

              unvisitedOutletCount:
                managerObject.unvisitedOutletCount === null
                  ? ""
                  : managerObject.unvisitedOutletCount,

              totalOrderAmt:
                managerObject.totalOrderAmt === null
                  ? ""
                  : managerObject.totalOrderAmt,

              accessRoleId:
                managerObject.accessRoleId === null
                  ? ""
                  : managerObject.accessRoleId,
              isActive:
                managerObject.isActive === null ? "" : managerObject.isActive,
            };

            dispatch(salesTeamUserSummarySuccess({ row: managerData }));
            return { response: managerData, success };
          }
          return { response: {}, success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const setSalesTeamUserSummary =
  ({ row }) =>
    async (dispatch) => {
      dispatch(salesTeamUserSummarySuccess({ row }));
    };

export const getSalesTeamWorkingSummary =
  ({ dataJson }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          url: URL + "/working-summary",
          postBody: dataJson,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((salesTeamObj, index) => {
              let salesTeamWorkingData = {
                index: index + 1,
                id: salesTeamObj.id === null ? "" : salesTeamObj.id,
                date: salesTeamObj.date === null ? "" : salesTeamObj.date,
                salesTeamId: salesTeamObj.salesTeamId === null ? "" : salesTeamObj.salesTeamId,
                userTypeId: salesTeamObj.userTypeId === null ? "" : salesTeamObj.userTypeId,
                salesTeamName: salesTeamObj.salesTeamName === null ? "" : salesTeamObj.salesTeamName,
                stockStakeHolderTypeName: salesTeamObj.stockStakeHolderTypeName === null ? "" : salesTeamObj.stockStakeHolderTypeName,
                areaId: salesTeamObj.areaId === null ? "" : salesTeamObj.areaId,
                areaName: salesTeamObj.areaName === null ? "" : salesTeamObj.areaName,
                workingTypeName: salesTeamObj.workingTypeName === null ? "" : salesTeamObj.workingTypeName,
                otherTaskName: salesTeamObj.otherTaskName === null ? "" : salesTeamObj.otherTaskName,
                fromTime: salesTeamObj.fromTime === null ? "" : salesTeamObj.fromTime,
                toTime: salesTeamObj.toTime === null ? "" : salesTeamObj.toTime,
                reason: salesTeamObj.reason === null ? "" : salesTeamObj.reason,
                firmName: salesTeamObj.outletName === null ? "" : salesTeamObj.outletName,
              };
              return salesTeamWorkingData;
            });
            dispatch(salesTeamWorkingSummarySuccess({ row }));
            return { response: row, success };
          }
          return { response: {}, success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };


export const setSalesTeamWorkingSummary =
  ({ row }) =>
    async (dispatch) => {
      dispatch(salesTeamWorkingSummarySuccess({ row }));
    };