import endpoint from "../../config/endpoints";

export const claimPdfJSON = {
    showSaveNextBtn: true,
    apiBaseURL: endpoint.claimApplyHeader,
    screenTitle: "Claim PDF",
    fieldMeta: [
        {
            label: "From Date",
            controlType: "datepicker",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "fromDate",
            isMandatory: true,
            onSubmit: "compare",
            onSubmitParameter: "toDate-le",
        },
        {
            label: "To Date",
            controlType: "datepicker",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "toDate",
            onSubmit: "compare",
            onSubmitParameter: "fromDate-ge",
            isMandatory: true,
            onSubmit: "compare",
        },
        {
            label: "Stake Holder Type",
            controlType: "multiSelect",
            placeHolder: "Select Stake Holder ",
            md: 12,
            lg: 12,
            sm: 12,
            xs: 12,
            labelSM: 2,
            labelMD: 2,
            labelLG: 2,
            valueSM: 10,
            valueMD: 10,
            valueLG: 10,
            masterName: "stockHolderTypeDetail",
            dataKey: "stockStakeHolderType",
            getListId: "stockStakeHolderType",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Stake Holder Name",
            controlType: "multiSelect",
            placeHolder: "Select Stake Holder Name",
            md: 12,
            lg: 12,
            sm: 12,
            xs: 12,
            labelSM: 2,
            labelMD: 2,
            labelLG: 2,
            valueSM: 10,
            valueMD: 10,
            valueLG: 10,
            masterName: "salesPersonDetail",
            getListFrom: "stockStakeHolderType",
            dataKey: "salesTeamIds",
            isRootLevelKey: true,
            isMandatory: true,
        },
    ],
};
