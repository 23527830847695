import { Grid, Paper, Typography } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Button";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import CheckBoxSelectionTable from "../../components/Comman/RT/CheckBoxSelectionTable";
import { withRouter } from "../../components/withRouter";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import ClaimOfOutletOfferPopup from "./ClaimOfOutletOfferPopup";
import MyUnderOffersPopup from "./MyUnderOffersPopup";
import { apiGet } from "../../utils/api_service";
import endpoint from "../../config/endpoints";

class MyUnderOffersForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowList: [],
      fieldData: {},
      remark: "",
      selection: [],
      openGenerate: false,
      dataRow: {},
      apiData: [],
     
    };
  }

  toggle = () => {
    this.setState({ openGenerate: false });
  };

  // componentDidMount() {
  //   this.setState({ rowList: [] });
  //   if (!this.props.value) {
  //     console.error("No value selected");
  //     return;
  //   }
  //   if (navigator.onLine) {
  //     this.props.showLoador({ loador: true });
  //     apiGet({
  //       url: `${endpoint.grnGVNHeader}`,
  //     })
  //       .then(({ data, success }) => {
  //         this.props.showLoador({ loador: false });
  //         if (success) {
  //           this.setState({ apiData: data });
  //         } else {
  //           this.props.showNotification({
  //             msg: "Failed to update status",
  //             severity: "error",
  //           });
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("API Error:", error);
  //         this.props.showNotification({
  //           msg: "An error occurred",
  //           severity: "error",
  //         });
  //         this.props.showLoador({ loador: false });
  //       });
  //   } else {
  //     this.props.showNotification({
  //       msg: "No internet connection",
  //       severity: "error",
  //     });
  //   }
  // }

  getOutletOfferData = () => {
    if (navigator.onLine) {
      this.props.showLoador({ loador: true });
      apiGet({
        url: `${endpoint.holderCreditNoteHeader}/get-holder-crn-for-grn-gvn`,
      })
        .then(({ data, success }) => {
          console.log("API Response:", data);

          this.props.showLoador({ loador: false });
        

          if (success) {
            const formattedData = (data?.data || []).map((item, index) => {
              console.log("Item Date:", item.date); // Log the date value
              return {
                id: item.id,
                index: index + 1,
                creditNoteNo: item.creditNoteNo || "-",
                // fromStockStakeHolderFirmName: item.fromStockStakeHolderFirmName || "-",
                toStockStakeHolderFirmName: item?.toStockStakeHolderFirmName || "-",
                type:
                item.grnGvnType === 0 ? "Purchase Return From Bill-Wise" :
                item.grnGvnType === 1 ? "Purchase Return From Category-Wise" :
                item.grnGvnType === 2 ? "Claim of outlet CRN" :
                item.grnGvnType === 3 ? "Claim of stock holder" :
                item.grnGvnType === 4 ? "My primary offer" :
                item.grnGvnType === 5 ? "Rate difference claim" :
                "-",
                dateToShow: item.date, // Convert date to string
                taxableAmount: item.taxableAmt?.toFixed(2) || "0.00",
                taxAmount:(item.cgstAmt + item.sgstAmt + item.igstAmt)?.toFixed(2) || "0.00", // Tax Amount (Sum of CGST, SGST, IGST)
                totalAmount: item.totalAmt?.toFixed(2) || "0.00", // Total Amount
              };
            });
            console.log("Formatted Data:", formattedData);
            this.setState({ apiData: formattedData,selection:[] });
          } else {
            this.props.showNotification({
              msg: "Failed to update status",
              severity: "error",
            });
          }
        })
        .catch((error) => {
          console.error("API Error:", error);
          this.props.showNotification({
            msg: "An error occurred",
            severity: "error",
          });
          this.props.showLoador({ loador: false });
        });
    } else {
      this.props.showNotification({
        msg: "No internet connection",
        severity: "error",
      });
    }

  }

  componentDidMount() {
    if (navigator.onLine) {
      this.props.showLoador({ loador: true });
      apiGet({
        url: `${endpoint.holderCreditNoteHeader}/get-holder-crn-for-grn-gvn`,
      })
        .then(({ data, success }) => {
          console.log("API Response:", data);

          this.props.showLoador({ loador: false });
        

          if (success) {
            const formattedData = (data?.data || []).map((item, index) => {
              console.log("Item Date:", item.date); // Log the date value
              return {
                id: item.id,
                index: index + 1,
                creditNoteNo: item.creditNoteNo || "-",
                // fromStockStakeHolderFirmName: item.fromStockStakeHolderFirmName || "-",
                toStockStakeHolderFirmName: item?.toStockStakeHolderFirmName || "-",

                type:
                item.grnGvnType === 0 ? "Purchase Return From Bill-Wise" :
                item.grnGvnType === 1 ? "Purchase Return From Category-Wise" :
                item.grnGvnType === 2 ? "outlet's CRN" :
                item.grnGvnType === 3 ? "stake holder's CRN" :
                item.grnGvnType === 4 ? "My primary offer" :
                item.grnGvnType === 5 ? "Rate difference claim" :
                "-",

                dateToShow: item.date, // Convert date to string
                taxableAmount: item.taxableAmt?.toFixed(2) || "0.00",
                taxAmount:(item.cgstAmt + item.sgstAmt + item.igstAmt)?.toFixed(2) || "0.00", // Tax Amount (Sum of CGST, SGST, IGST)
                totalAmount: item.totalAmt?.toFixed(2) || "0.00", // Total Amount
              };
            });

            console.log("Formatted Data:", formattedData);
         
            this.setState({ apiData: formattedData });
          } else {
            this.props.showNotification({
              msg: "An error occurred",
              severity: "error",
            });
          }
        })
        .catch((error) => {
          console.error("API Error:", error);
          this.props.showNotification({
            msg: "An error occurred",
            severity: "error",
          });
          this.props.showLoador({ loador: false });
        });
    } else {
      this.props.showNotification({
        msg: "No internet connection",
        severity: "error",
      });
    }
  }

  onDataChange = (fieldName, newValue) => {
    this.setState((prevState) => ({
      fieldData: { ...prevState.fieldData, [fieldName]: newValue },
    }));
  };

  changeHandler = (e) => {
    this.setState({ remark: e.target.value });
  };

  getFilteredTableData = (tableData) => {

    const { searchValue } = this.state;

    if (!searchValue) return tableData; // If no search value, return full data

    return tableData.filter((currentRow) => {
      // Convert search input to lowercase for case-insensitive search
      const lowerSearchValue = searchValue.toLowerCase();

      // List of fields to search in
      const searchFields = [
        currentRow.creditNoteNo,
        currentRow.taxableAmount?.toString(),
        currentRow.taxAmount?.toString(),
        currentRow.totalAmount?.toString(),
      ];

      // Check if any field includes the search value
      return searchFields.some(
        (field) => field && field.toLowerCase().includes(lowerSearchValue)
      );
    });
  };

  getSelectedList = (selectedIds) => {
    this.setState({ selection: selectedIds });

    // Ensure `outletOrderDetail.outletOrderDetail` exists before mapping
    const rowList = (this.state?.apiData || []).map((rowData) => ({
      ...rowData,
      checked: selectedIds.includes(rowData.id), // ✅ Efficient way to check selection
    }));

    // Update parent component with the new selected list
    if (this.props.setOutletOrderDetail) {
      this.props.setOutletOrderDetail({ row: rowList });
    }
  };

  onSubmitData = () => {
    const { selection } = this.state;
    const {  showNotification } = this.props;

    if (selection.length === 0) {
      console.log("No items selected");
      showNotification({
        msg: "Please select at least one record",
        severity: "warning",
      });
      return;
    } else {
      this.setState({ openGenerate: true });
    }
    console.log("Selected IDs:", selection);
  };


  onSubmitDataTest = () => {
    this.setState({ openGenerate: true });
  };

  render() {
    const { selection, openGenerate, dataRow } = this.state;
    const { setOutletOrderDetail } = this.props;

    return (
      // <Paper sx={{ padding: 2 }}>

      <Paper
        sx={{
          padding: 2,
          border: "1px solid #ddd", // Light grey border
          borderRadius: 2, // Rounded corners
          elevation: 3, // Shadow effect
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Custom shadow
        }}
      >
        <LandingScreenHeader
          screenTitle="Stake Holder's CRN"
          showSearchBox={true}
          showAddButton={false}
        />

{this.state.apiData?.length > 0 ? (


        <Grid container alignItems="center">
          <Grid item xs={12} md={12} sm={12} sx={{ marginTop: 4 }}>
            <CheckBoxSelectionTable
              selection={selection}
              onSelectionChange={this.getSelectedList}
              columns={[
                {
                  title: "Sr.No.",
                  name: "index",
                  positionCenter: true,
                  showInscreen: true,
                  align: "center",
                },
                {
                  title: "Credit Note No",
                  name: "creditNoteNo",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "center",
                  canSearch: true,
                },
                {
                  title: "Firm Name",
                  name: "toStockStakeHolderFirmName",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "left",
                  canSearch: true,
                },
                {
                  title: "Type",
                  name: "type",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "center",
                  canSearch: true,
                },
                {
                  title: "Date",
                  name: "dateToShow",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "center",
                  canSearch: true,
                },
                {
                  title: "Taxable Amount",
                  name: "taxableAmount",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "right",
                  canSearch: true,
                },
                {
                  title: "Tax Amount",
                  name: "taxAmount",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "right",
                  canSearch: true,
                },
                {
                  title: "Total Amount",
                  name: "totalAmount",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "right",
                  canSearch: true,
                },
              ]}
              rows={this.getFilteredTableData(this.state.apiData)} // ✅ Pass rows through getFilteredTableData
              setList={setOutletOrderDetail}
              isActionColActive={false}
            />
          </Grid>
        </Grid>

            ): (

              <Typography
              variant="h6"
              color="textSecondary"
              sx={{ textAlign: "center"}}
            >
              No data available for stake holder's CRN
                
            </Typography>
            )}
        
        {this.state.apiData.length > 0 && (

        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          container
          justifyContent="right"
          sx={{ marginTop: 2, padding: 2 }}
        >
          <ButtonCompo
            size="medium"
            type="Submit"
            variant="contained"
            name="Request for claim"
            onClick={this.onSubmitData}
          />
        </Grid> 
        )}

        {openGenerate && (
          <MyUnderOffersPopup
            open={openGenerate}
            dataRow={dataRow}
            handleCloses={this.toggle}
            getOutletOfferData={this.getOutletOfferData}
            selection={this.state.selection}
          />
        )}
      </Paper>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = { showLoador, showNotification };

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(MyUnderOffersForm);
