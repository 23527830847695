import endpoint from "../../config/endpoints";

export const DistrictJson = {
  showSaveNextBtn: true,
  formPath: "/district-form",
  apiBaseURL: endpoint.district,
  changeExcel: endpoint.exportData,
  screenTitle: "District",
  showAddButton: true,
  showPdfDownload: false,
  pdfFileName: "District",
  showExcelDownload: false,
  excelFileName: "District",
  tableColumnsToFilter: [
    {
      columnDisplayName: "Name",
      columnKeyName: "name",
      isChecked: true,
    },
    {
      columnDisplayName: "Zone",
      columnKeyName: "zoneName",
      isChecked: true,
    },
    {
      columnDisplayName: "Sort Order Number",
      columnKeyName: "sortOrder",
      isChecked: true,
    },
  ],
  fieldMeta: [
    {
      label: "Zone",
      controlType: "autocomplete",
      placeHolder: "Select Zone",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "zoneDetail",
      dataKey: "zone",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Name",
      controlType: "textfield",
      placeHolder: "Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      autoFocus: true,
      dataKey: "name",
      isMandatory: true,
      uniqueValidation: true,
    },
    {
      label: "Short Name",
      controlType: "textfield",
      placeHolder: "Short Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "shortName",
      isMandatory: true,
    },
    {
      label: "Sort Order No",
      controlType: "textfield",
      placeHolder: "Sort Order Number",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "sortOrder",
      isMandatory: true,
    },
  ],
  fieldMetaForSalesDistrictAssignation: [
    {
      label: "Stock Holder Type",
      controlType: "multiSelect",
      placeHolder: "Select Stock Holder Type",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stockHolderTypeDetail",
      dataKey: "stockHolderType",
      getListId: "stockHolderType",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Stock Holder Name",
      controlType: "multiSelect",
      placeHolder: "Select Stock Holder Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stockHolderNameDetail",
      dataKey: "stockHolderName",
      getListId: "stockHolderName",
      isRootLevelKey: true,
      isMandatory: true,
    },
  ],
};
