import React from "react";
import { connect } from "react-redux";
import { pendingGrnForCreditNoteJson } from "../../DynamicFormsJson/Transaction/pendingGrnForCreditNote";
import { getStockStackHolders } from "../../Slice/StackHolder.slice";
import {
  getGRNByStakeholderId,
  getGrnData,
  setGrnData,
} from "../../Slice/requestGRN.slice";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import {
  noInternetMsg,
  updateData,
  serverMsg,
  updateMsg,
  saveFailedMsg,
  checkedListEmptyMsg,
} from "../../config/messageconstant";
import CheckBoxTable from "./PendingGrnForCreditNoteTextfeild";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import {
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import { ButtonCompo } from "../../components/Comman/Button";
import { apiPost } from "../../utils/api_service";
import swal from "sweetalert";
import { onlyNumber } from "../../components/Comman/Util/Validations";
import endpoint from "../../config/endpoints";
import { redColor, titleColor } from "../../config/ColorObj";
import { LabelCompo } from "../../components/Comman/Label";
class PendingGrnForCreditNote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      calStock: 1,
      remark: "",
      fieldDataForModule: "",
      isLog: true,
      dynamicMasterData: {
        stakeholdersDetail: this.props.stakeholdersList.stockStakeHolders,
        grnDetails: this.props.grnByStakeholderIdList.grnByStakeholderId,
      },
    };
  }

  async componentDidMount() {
    const { getStockStackHolders, showLoador, showNotification, setGrnData } = this.props;
    if (navigator.onLine) {

      setGrnData({ row: [] });

      showLoador({ loador: true });
      await getStockStackHolders().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

      const moduleObjData = localStorage.getItem("moduleObj");

      this.setState({
        fieldDataForModule:
          moduleObjData != null ? JSON.parse(moduleObjData) : {},
      });

    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  getListById = async (data) => {
    const { getGRNByStakeholderId, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      if (data.stackholder) {
        showLoador({ loador: true });
        await getGRNByStakeholderId({ holderId: data.stackholder }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.stakeholdersList !== nextProps.stakeholdersList) {
      if (
        this.props.stakeholdersList?.stockStakeHolders !==
        nextProps.stakeholdersList?.stockStakeHolders
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stakeholdersDetail: nextProps.stakeholdersList?.stockStakeHolders,
          },
        });
      }
    }

    if (
      this.props.grnByStakeholderIdList !== nextProps.grnByStakeholderIdList
    ) {
      if (
        this.props.grnByStakeholderIdList?.grnByStakeholderId !==
        nextProps.grnByStakeholderIdList?.grnByStakeholderId
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            grnDetails: nextProps.grnByStakeholderIdList?.grnByStakeholderId,
          },
        });
      }
    }
  }

  onSearchList = async (data) => {
    const { getGrnData, showLoador, showNotification } = this.props;
    const { remark } = this.state;

    if (navigator.onLine) {
      this.setState({
        formData: data,
      });
      showLoador({ loador: true });
      await getGrnData({
        grnGvnHeaderIds: data.requestGRN,
      }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.setState({
            remark: response[0] && response[0].grnGvnHeader ? response[0].grnGvnHeader.remark : "-",
          });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onSubmit = () => {
    const { grnDetails, formData, calStock, remark, isLog, fieldDataForModule } = this.state;
    const { showNotification, showLoador, grnByStakeholderIdList } = this.props;
    let error = false;

    const rowList = grnByStakeholderIdList.getGrnDataById.map((rowObj) => {

      if (rowObj.qtyError) {
        error = true;
      }

      return {
        ...rowObj,
        primaryQty: +rowObj.primaryQty,
        secondaryQty: (+rowObj.primaryQty * +rowObj.product.uomConversionValue)
          + +rowObj.secondaryQty,
        secondaryApprovedQty: (+rowObj.primaryQty * +rowObj.product.uomConversionValue)
          + +rowObj.secondaryQty,
        stockStakeHolderBillDetailId: rowObj.stockStakeHolderBillDetailId,
      };

    });


    if (rowList.length !== 0) {
      if (!error) {

        // this.props.setGrnData({ row: rowList });

        swal({
          title: "Are you sure?",
          text: updateData,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then(async (willDelete) => {
          if (willDelete) {
            if (navigator.onLine) {
              showLoador({ loador: true });
              await apiPost({
                url:
                  endpoint.holderCreditNoteHeader +
                  "/save?toStockStakeHolderId=" +
                  formData.stackholder +
                  "&instockOutstock=" +
                  calStock + "&remark=" + remark,
                postBody: rowList,
              }).then(({ data, success }) => {
                if (success) {

                  if (isLog) {
                    let updatedJsonToSend = JSON.stringify(data.data);

                    let dataToSaveForMLog = {
                      module: {
                        moduleId: fieldDataForModule.moduleId
                      },
                      subModule: {
                        subModuleId: fieldDataForModule.subModuleId
                      },
                      operationType: 0,
                      type: 1,
                      operationPerformId: formData.stackholder,
                      subOperationPerformIds: formData.requestGRN,
                      updatedJson: updatedJsonToSend,
                    }

                    showLoador({ loador: true });
                    apiPost({
                      url: endpoint.mlog,
                      postBody: dataToSaveForMLog,
                    }).then(({ success }) => {
                      showLoador({ loador: false });
                      if (success) {
                      } else {
                        showNotification({
                          msg: saveFailedMsg,
                          severity: "error",
                        });
                      }
                    });
                  }

                  showLoador({ loador: false });
                  showNotification({
                    msg: updateMsg,
                  });
                  window.location.reload();
                } else {
                  showNotification({
                    msg: saveFailedMsg,
                    severity: "error",
                  });
                  showLoador({ loador: false });
                }
              });
            } else {
              showNotification({ msg: noInternetMsg, severity: "error" });
            }
          }
        });
      }
    } else {
      showNotification({ msg: checkedListEmptyMsg, severity: "error" });
    }
  };
  handleChange = (event) => {
    this.setState({
      calStock: event.target.value,
    });
  };

  render() {
    const { dynamicMasterData, calStock, fieldDataForModule } = this.state;
    const { grnByStakeholderIdList, setGrnData } = this.props;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 0,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            getListById={this.getListById}
            dynamicMasterData={dynamicMasterData}
            showTitle={pendingGrnForCreditNoteJson.showTitle}
            screenTitle={pendingGrnForCreditNoteJson.screenTitle}
            fieldMeta={pendingGrnForCreditNoteJson.fieldMeta}
            apiBaseURL={pendingGrnForCreditNoteJson.apiBaseURL}
            saveBtnText={"search"}
            onSave={this.onSearchList}
            padding={true}
            paddingTop={true}
          />

          <CheckBoxTable
            tableHead={[
              {
                title: "Sr.No.",
                name: "index",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "center",
              },
              {
                title: "Product Name",
                name: "productName",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
              },
              {
                title: "UOM",
                name: "outletUomName",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "center",
              },
              {
                title: "Rate",
                name: "rate",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "right",
              },
              {
                title: "Quantity",
                name: "qtyForShow",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "right",
              },
              {
                title: "Approve Primary Qty",
                name: "primaryQty",
                placeHolder: "Enter Approved Quantity",
                textFieldError: "approvedQtyError",
                error: "Approved Quantity",
                textField1: true,
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
              },
              {
                title: "Approve Secondary Qty",
                name: "secondaryQty",
                placeHolder: "Enter Approved Quantity",
                textFieldError: "approvedQtyError",
                error: "Approved Quantity",
                textField: true,
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
              },
              {
                title: "Total Approved Sec Quantity",
                name: "totalQty",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "right",
              },
              {
                title: "Total Amount",
                name: "totalAmtForPending",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "right",
              },
            ]}
            data={grnByStakeholderIdList.getGrnDataById}
            rowList={grnByStakeholderIdList.getGrnDataById}
            setList={setGrnData}
          />

          <br />
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="space-between"
          >
            <Grid item xs={12} md={2.5} sm={2.5}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16, color: redColor }}
                label="* "
              />
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16, color: titleColor }}
                label="Is calculate in stock :"
              />
            </Grid>
            <Grid item xs={12} sm={9.5} md={9.5}>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="calStock"
                value={calStock}
                onChange={this.handleChange}
                defaultValue={calStock}
                row
              >
                <FormControlLabel value={1} control={<Radio />} label="Yes" />
                <FormControlLabel value={0} control={<Radio />} label="No" />
              </RadioGroup>
            </Grid>
          </Grid>
          <br />
          {fieldDataForModule.editReject == 1 ? (
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="right"
            >
              <ButtonCompo
                size="medium"
                type="submit"
                variant="contained"
                name="Generate Credit Note"
                onClick={this.onSubmit}
              />
            </Grid>
          ) : ("")}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  stakeholdersList: state.stackholder,
  grnByStakeholderIdList: state.requestGRN,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getStockStackHolders,
  getGRNByStakeholderId,
  getGrnData,
  setGrnData,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PendingGrnForCreditNote);
