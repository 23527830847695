import React from "react";
import ReactApexChart from "react-apexcharts";
import "./CompanyDashboard.css"; // Ensure this matches your CSS file path

const CompanyDashboard = () => {
  const user = {
    name: "John Doe",
    bio: "Web developer passionate about building amazing user interfaces.",
    email: "johndoe@example.com",
    location: "New York, USA",
    profilePicture: "https://www.w3schools.com/w3images/avatar2.png", // Replace with your own image URL
  };
  return (
    <>
      <div className="profile-container">
        <div className="profile-header">
          <img
            src={user.profilePicture}
            alt="Profile"
            className="profile-image"
          />
          <h1 className="profile-name">
            {"Byte Elephants Technologies Pvt. Ltd"}
          </h1>
          <p className="profile-bio">
            {
              "BETs total cloud ERP platforms offers exact solution empowering you to drive your business in your way!!"
            }
          </p>
        </div>
        <div className="profile-info">
          <p className="info-item">
            <strong>Email:</strong> {"connect@byteelephants.com"}
          </p>
          <p className="info-item">
            <strong>Location:</strong>{" "}
            {
              "1st Floor, Raj Apartment, Above Arham Eye Hospital, Rachana Vidyalaya Lane, Sharanpur Rd, Canada Corner, Nashik, Maharashtra 422005"
            }
          </p>
          <p className="info-item">
            <strong>Contact:</strong> {user.location}
          </p>
          <p className="info-item">
            <strong>Website:</strong> {user.location}
          </p>
          <p className="info-item">
            <strong>Motive:</strong> {user.location}
          </p>
        </div>
      </div>
    </>
  );
};

export default CompanyDashboard;
