import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import { ChannelColumns } from "../../../tableColumns/table-columns";
import { getCountry } from "../../../Slice/country.slice";
import endpoint from "../../../config/endpoints";
import { ChannelJson } from "../../../DynamicFormsJson/MastersJSON/channel";
import { getChannel } from "../../../Slice/channel.slice";

class ChannelTable extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { channel, getChannel } = this.props;
    return (
      <>
        <DynamicMainScreen
          formPath={ChannelJson.formPath}
          screenTitle={ChannelJson.screenTitle}
          fieldMeta={ChannelJson.fieldMeta}
          showPdfDownload={ChannelJson.showPdfDownload}
          showExcelDownload={true}
          changeExcelURL={"contry-data" + '?sort={"sortOrder": "ASC"}'}
          showExtension={false}
          pdfFileName={ChannelJson.pdfFileName}
          excelFileName={ChannelJson.excelFileName}
          showAddButton={ChannelJson.showAddButton}
          tableColumnsToFilter={ChannelJson.tableColumnsToFilter}
          tableColumns={ChannelColumns}
          tableData={channel?.channel}
          getTableData={getChannel}
          apiBaseURL={ChannelJson.apiBaseURL}
          baseIdColumn={ChannelJson.baseIdColumn}
          isNote={true}
          isNoteValue={"Channel"}
          showDeleteIcon={true}
          isActiveURL={ChannelJson.isActiveURL}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  channel: state.channel,
});
const mapDispatchToProps = {
  getChannel,
};
export default connect(mapStateToProps, mapDispatchToProps)(ChannelTable);
