import { Grid, Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { ButtonCompo } from "../../components/Comman/Button";
import MaterialUITableWithTextField from "../../components/Comman/RT/MaterialUITableWithTextField";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import endpoint from "../../config/endpoints";
import {
  noInternetMsg,
  saveFailedMsg,
  savemsg,
  saveWarningMsg,
  serverMsg,
} from "../../config/messageconstant";
import { regionAndTargetJson } from "../../DynamicFormsJson/Transaction/RegionAndTarget";
import { getMonthsFromYearId } from "../../Slice/regionAndTarget.slice";
import { RegionAndTargetColumns } from "../../tableColumns/table-columns";
import { apiGet, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";

class RegionAndTargetEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldData: {},
      dataToSave: {},
      janIndex: 0,
      febIndex: 0,
      marchIndex: 0,
      aprilIndex: 0,
      mayIndex: 0,
      junIndex: 0,
      julyIndex: 0,
      augIndex: 0,
      sepIndex: 0,
      octIndex: 0,
      novIndex: 0,
      decIndex: 0,
      totalPercentage: 100,
      dynamicMasterData: {},
      regionAndTargetList: [],
      isLog: true,
      moduleObjDataObj: {},
    };
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.regionAndTargetDataList !== nextProps.regionAndTargetDataList
    ) {
      if (
        this.props.regionAndTargetDataList?.monthsFromYearId !==
        nextProps.regionAndTargetDataList?.monthsFromYearId
      ) {
        this.setState({
          ...this.state,
          regionAndTargetList:
            nextProps.regionAndTargetDataList?.monthsFromYearId,
        });
      }
    }
  }
  async componentDidMount() {
    const { showLoador, showNotification, getMonthsFromYearId } = this.props;
    if (navigator.onLine) {

      const moduleObjData = localStorage.getItem("moduleObj");

      this.setState({
        moduleObjDataObj:
          moduleObjData != null ? JSON.parse(moduleObjData) : {},
      });


      if (this.props.params.id) {
        showLoador({ loador: true });
        await apiGet({
          url: endpoint.regionAndTarget + "/" + this.props.params.id,
        }).then(async ({ data, success }) => {
          showLoador({ loador: false });
          if (success) {
            if (!data.error) {
              showLoador({ loador: true });
              getMonthsFromYearId({
                yearId: data.data.yearMaster.id,
              }).then(({ response, success }) => {
                showLoador({ loador: false });
                if (!success) {
                  showNotification({ msg: serverMsg, severity: "error" });
                  return {};
                } else {
                  let rowData = data.data;
                  const percentageValues = {
                    janIndex: rowData.jan,
                    febIndex: rowData.feb,
                    marchIndex: rowData.march,
                    aprilIndex: rowData.april,
                    mayIndex: rowData.may,
                    junIndex: rowData.jun,
                    julyIndex: rowData.july,
                    augIndex: rowData.aug,
                    sepIndex: rowData.sep,
                    octIndex: rowData.oct,
                    novIndex: rowData.nov,
                    decIndex: rowData.dec,
                  };

                  const listData = this.state.regionAndTargetList.map(
                    (regionTarget) => {
                      return {
                        ...regionTarget,
                        percentage:
                          regionTarget.month == "January"
                            ? rowData.jan
                            : regionTarget.month == "February"
                              ? rowData.feb
                              : regionTarget.month == "March"
                                ? rowData.march
                                : regionTarget.month == "April"
                                  ? rowData.april
                                  : regionTarget.month == "May"
                                    ? rowData.may
                                    : regionTarget.month == "June"
                                      ? rowData.jun
                                      : regionTarget.month == "July"
                                        ? rowData.july
                                        : regionTarget.month == "August"
                                          ? rowData.aug
                                          : regionTarget.month == "September"
                                            ? rowData.sep
                                            : regionTarget.month == "October"
                                              ? rowData.oct
                                              : regionTarget.month == "November"
                                                ? rowData.nov
                                                : rowData.dec,
                      };
                    }
                  );
                  this.setState({
                    fieldData: {
                      year: rowData.yearMaster.id,
                      region: rowData.region.id,
                    },
                    regionAndTargetList: listData,
                    DataToSet: [
                      {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        label: "Region",
                        value:
                          rowData.region.name !== null
                            ? rowData.region.name
                            : "",
                      },
                      {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        label: "Year",
                        value:
                          rowData.yearMaster.yearName !== null
                            ? rowData.yearMaster.yearName
                            : "",
                      },
                    ],
                    ...percentageValues,
                  });
                }
              });
            }
          }
          return success;
        });
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  onSubmit = async () => {
    const {
      fieldData,
      regionAndTargetList,
      janIndex,
      febIndex,
      marchIndex,
      aprilIndex,
      mayIndex,
      junIndex,
      julyIndex,
      augIndex,
      sepIndex,
      octIndex,
      novIndex,
      decIndex,
    } = this.state;

    if (navigator.onLine) {
      const totalPercentage = regionAndTargetList.reduce(
        (sum, item) => sum + Number(item.percentage),
        0
      );
      if (totalPercentage !== 100) {
        swal(
          "Validation Error",
          "Percentage total must be equal to 100 %",
          "warning"
        );
        return;
      }

      const validateIndex = (index) =>
        index >= 0 && index < regionAndTargetList.length;

      const getPercentageAtIndex = (index) =>
        validateIndex(index) ? regionAndTargetList[index].percentage : 0;

      if (this.props.params.id) {
        let dataToSave = {
          id: this.props.params.id,
          region: {
            id: fieldData.region,
          },
          yearMaster: {
            id: fieldData.year,
          },
          jan: getPercentageAtIndex(janIndex),
          feb: getPercentageAtIndex(febIndex),
          march: getPercentageAtIndex(marchIndex),
          april: getPercentageAtIndex(aprilIndex),
          may: getPercentageAtIndex(mayIndex),
          jun: getPercentageAtIndex(junIndex),
          july: getPercentageAtIndex(julyIndex),
          aug: getPercentageAtIndex(augIndex),
          sep: getPercentageAtIndex(sepIndex),
          oct: getPercentageAtIndex(octIndex),
          nov: getPercentageAtIndex(novIndex),
          dec: getPercentageAtIndex(decIndex),
        };

        swal({
          title: "Are you sure?",
          text: saveWarningMsg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            this.onSave(dataToSave);
          }
        });
      }
    } else {
      this.props.showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  };

  onSave = async (dataToSave) => {
    const { showLoador, setProductList, showNotification, category } =
      this.props;
    const { fieldData, isLog, moduleObjDataObj } = this.state;
    let error = false;
    let productList = [];
    if (!error) {
      if (navigator.onLine) {
        showLoador({ loador: true });
        await apiPost({
          url: endpoint.regionAndTarget,
          postBody: dataToSave,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (success) {
            if (data.data !== null) {
              if (isLog) {
                let updatedJsonToSend = JSON.stringify(data.data);

                let dataToSaveForMLog = {
                  module: {
                    moduleId: moduleObjDataObj.moduleId
                  },
                  subModule: {
                    subModuleId: moduleObjDataObj.subModuleId
                  },
                  operationType: 1,
                  type: 1,
                  operationPerformId: data.data.id,
                  updatedJson: updatedJsonToSend,
                }

                showLoador({ loador: true });
                apiPost({
                  url: endpoint.mlog,
                  postBody: dataToSaveForMLog,
                }).then(({ success }) => {
                  showLoador({ loador: false });
                  if (success) {
                  } else {
                    showNotification({
                      msg: saveFailedMsg,
                      severity: "error",
                    });
                  }
                });
              }

            }

            showNotification({ msg: savemsg });
            this.props.navigate(-1);
          } else {
            showNotification({ msg: saveFailedMsg, severity: "error" });
          }
          return success;
        });
      } else {
        this.props.showNotification({ msg: noInternetMsg, severity: "error" });
      }
    } else {
      setProductList({ row: productList });
    }
  };

  setRegionAndTargetList = ({ row }) => {

    let totalPercentage = 0;

    row.map((yearObject, index) => {

      totalPercentage = totalPercentage + +yearObject.percentage;

      this.setState({
        totalPercentage: totalPercentage,
      });

      if (yearObject.month == "January") {
        this.setState({
          janIndex: index,
        });
      } else if (yearObject.month == "February") {
        this.setState({
          febIndex: index,
        });
      } else if (yearObject.month == "March") {
        this.setState({
          marchIndex: index,
        });
      } else if (yearObject.month == "April") {
        this.setState({
          aprilIndex: index,
        });
      } else if (yearObject.month == "May") {
        this.setState({
          mayIndex: index,
        });
      } else if (yearObject.month == "June") {
        this.setState({
          junIndex: index,
        });
      } else if (yearObject.month == "July") {
        this.setState({
          julyIndex: index,
        });
      } else if (yearObject.month == "August") {
        this.setState({
          augIndex: index,
        });
      } else if (yearObject.month == "September") {
        this.setState({
          sepIndex: index,
        });
      } else if (yearObject.month == "October") {
        this.setState({
          octIndex: index,
        });
      } else if (yearObject.month == "November") {
        this.setState({
          novIndex: index,
        });
      } else {
        this.setState({
          decIndex: index,
        });
      }
    });

    this.setState({
      regionAndTargetList: row,
    });
  };
  render() {
    const { dynamicMasterData, regionAndTargetList, DataToSet, fieldData, totalPercentage } =
      this.state;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            showBackToList={true}
            showSaveBtnMain={false}
            dynamicMasterData={dynamicMasterData}
            showSaveBtn={regionAndTargetJson.showSaveBtn}
            showTitle={regionAndTargetJson.showTitle}
            screenTitle={regionAndTargetJson.screenTitle}
            fieldMeta={
              this.props.params.id ? [] : regionAndTargetJson.fieldMeta
            }
            showCancel={regionAndTargetJson.showCancel}
            apiBaseURL={regionAndTargetJson.apiBaseURL}
            showSaveNextBtn={regionAndTargetJson.showSaveNextBtn}
            tableColumns={RegionAndTargetColumns}
            tableData={[]}
            saveBtnText={"Search"}
            DataToSet={DataToSet}
          />
          {Object.keys(fieldData).length !== 0 && (
            <>
              <MaterialUITableWithTextField
                tableHead={[
                  {
                    title: "Sr.No.",
                    name: "index",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                  },
                  {
                    title: "Month",
                    name: "name",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                  },
                  {
                    title: "Percentage",
                    name: "percentage",
                    placeHolder: "Enter Percentage",
                    textFieldError: "percentageErr",
                    error: "Percentage",
                    textField: true,
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                  },
                ]}
                data={regionAndTargetList}
                setList={this.setRegionAndTargetList}
                isActionColActive={false}
              />
              <br />
              <DynamicDetails
                DataToSet={[
                  {
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 6,
                    label: "Total Percentage",
                    value: totalPercentage,
                  },
                ]}
              />
              <Grid container spacing={1}>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                  container
                  justifyContent="right"
                >
                  <ButtonCompo
                    size="medium"
                    type="submit"
                    variant="contained"
                    name="Submit"
                    onClick={this.onSubmit}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  regionAndTargetDataList: state.regionAndTarget,
});
const mapDispatchToProps = {
  showLoador,
  showNotification,
  getMonthsFromYearId,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(RegionAndTargetEdit);
