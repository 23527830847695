import endpoint from "../../config/endpoints";

export const requestForDistributorJson = {
    showSaveNextBtn: false,
    apiBaseURL: endpoint.room,
    screenTitle: "Room",
    showTitle: true,

    fieldMeta: [
        {
            label: "Stock Holder Type",
            controlType: "autocomplete",
            placeHolder: "Select Stock Holder Type",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "stockStakeHolderTypeDetail",
            dataKey: "stockStakeHolderType",
            getListId: "stockStakeHolderType",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Stock Holder Name",
            controlType: "autocomplete",
            placeHolder: "Select Stock Holder Name",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "salesTeamDetail",
            dataKey: "salesTeam",
            getListFrom: "stockStakeHolderType",
            isRootLevelKey: true,
            isMandatory: true,
        },
    ],
};
