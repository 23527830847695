import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { noInternetMsg } from "../../config/messageconstant";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { downloadF } from "../PDF/PDFDownload";
import endpoint from "../../config/endpoints";

class CategoryWise extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
    };
  }


  generateExcel = () => {
    const { fromDate, toDate, showLoador, showNotification } = this.props;

    if (navigator.onLine) {

      let dateJson = {
        fromDate: fromDate,
        toDate: toDate,
      }

      showLoador({ loador: true });
      downloadF({
        isGet: false,
        url:
          endpoint.reportForExcelReports + "/sales-team-wise-category-report-excel",
        postBody: dateJson,
        ext: "xlsx",
        openNewTab: true,
      }).then(({ response, success }) => {
        showLoador({ loador: false });
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }

  }


  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  getFilteredTableData = (searchData) => {
    const { searchValue } = this.state;

    if (!searchData || searchData.length === 0) {
      return [];
    }
    return searchData.filter((currentRow) => {
      let isValid = false;
      if (
        currentRow["salesTeamName"] &&
        currentRow["salesTeamName"]
          .toString()
          .toLowerCase()
          .includes(searchValue.trim().toLowerCase())
      ) {
        isValid = true;
        return true;
      }
    });
  };

  render() {
    const { categoryList, matrixDataListForCat } = this.props;
    return (
      <>
        <LandingScreenHeader
          screenTitle={""}
          showSearchBox={true}
          showTable={true}
          showFilter={false}
          showPdfDownload={false}
          showExcelDownload={true}
          generateExcel={this.generateExcel}
          onSearch={this.onSearch}
        />
        <TableContainer>
          <Table aria-label="simple table" size="medium">
            <TableHead>
              <TableRow>
                <TableCell align="center" style={{ minWidth: "70px" }}>
                  Sr. No.
                </TableCell>
                <TableCell align="center" style={{ minWidth: "150px" }}>
                  Sales Team
                </TableCell>
                {categoryList.map((categoryObject) => {
                  return (
                    <>
                      <TableCell align="center" style={{ minWidth: "150px" }}>
                        {categoryObject.name}
                      </TableCell>
                    </>
                  );
                })}
                <TableCell align="center" style={{ minWidth: "120px" }}>
                  Total
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.getFilteredTableData(matrixDataListForCat).map((matrixDataListObj, index) => {
                return (
                  <>
                    <TableRow key={matrixDataListObj.index}>
                      <TableCell align="center" component="td" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ minWidth: "70px" }}
                        component="td"
                        scope="row"
                      >
                        {matrixDataListObj.salesTeamName}
                      </TableCell>

                      {matrixDataListObj.salesAmounts.map((salesAmountsObj, idx) => (
                        <TableCell align="right" key={idx}>{salesAmountsObj !== '' ? salesAmountsObj.toFixed(2) : '-'}</TableCell>
                      ))}

                      <TableCell component="td" align="right" scope="row">
                        {matrixDataListObj.grandTotalAmt.toFixed(2)}
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  showLoador,
  showNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(CategoryWise);
