import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { connect } from "react-redux";
import { StockBetweenDateJSON } from "../../DynamicFormsJson/Transaction/stockBetweenDate";
import {
  getDetailUpdated,
  getUpdatedStockBetweenDateList,
} from "../../Slice/manageStock.slice";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { cyanBlue } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { downloadF } from "../PDF/PDFDownload";
// import PopUpStockBetweenDate from "./PopUpStockBetweenDate";
import { auditJson } from "../../DynamicFormsJson/MastersJSON/audit";
import { generateExcel } from "../../components/GeneratePDFExcel";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";

import { getAreaByUserType } from "../../Slice/area.slice";
import { getBeatsByAreaIds } from "../../Slice/beat.slice";
import { getOutletByBeatIds } from "../../Slice/outlet.slice";
import { apiGet } from "../../utils/api_service";

class OutletLedgerReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      excelData: {},
      ledgerData: [],
      // totalBalance: 0,
      dynamicMasterData: {
        areaDetail: this.props.areaList?.area,
        beatDetail: this.props.beatList?.beat,
        stockStakeHolderTypeDetail:
          this.props.stockStakeHolderType?.stockStakeHolderType,
        stockStakeHolderTypelistDetail:
          this.props.stockStakeHolderType?.stockStakeHolderTypeList,
      },
    };
  }

  getListById = async (data) => {
    const {
      getBeatsByAreaIds,
      getOutletByBeatIds,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      if (data.areaIds) {
        showLoador({ loador: true });
        await getBeatsByAreaIds({ areaIds: data.areaIds?.id }).then(
          ({ success }) => {
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
        showLoador({ loador: false });
      }

      if (data.beatIds) {
        showLoador({ loador: true });
        await getOutletByBeatIds({ beatIds: data.beatIds?.id }).then(
          ({ success }) => {
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
        showLoador({ loador: false });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.areaList !== nextProps.areaList) {
      if (this.props.areaList?.area !== nextProps.areaList?.area) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.area,
          },
        });
      }
    }

    if (this.props.beatList !== nextProps.beatList) {
      if (this.props.beatList?.beat !== nextProps.beatList?.beat) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            beatDetail: nextProps.beatList?.beat,
          },
        });
      }
    }

    if (this.props.outletList !== nextProps.outletList) {
      if (this.props.outletList?.outlets !== nextProps.outletList?.outlets) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            outletDetail: nextProps.outletList?.outlets,
          },
        });
      }
    }

    if (this.props.stockStakeHolderType !== nextProps.stockStakeHolderType) {
      if (
        this.props.stockStakeHolderType?.stockStakeHolderType !==
        nextProps.stockStakeHolderType?.stockStakeHolderType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stockStakeHolderTypeDetail:
              nextProps.stockStakeHolderType?.stockStakeHolderType,
          },
        });
      }
    }

    if (
      this.props.stockStakeHolderType?.stockStakeHolderTypeList !==
      nextProps.stockStakeHolderType?.stockStakeHolderTypeList
    ) {
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          stockStakeHolderTypelistDetail:
            nextProps.stockStakeHolderType?.stockStakeHolderTypeList,
        },
      });
    }
  }

  async componentDidMount() {
    const {
      getStockStakeHolderType,
      getAreaByUserType,
      showLoador,
      showNotification,
    } = this.props;

    const { value } = this.state;

    if (navigator.onLine) {
      showLoador({ loador: true });
      await getAreaByUserType().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  onSearchData = async (data) => {
    const { getUpdatedStockBetweenDateList, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getUpdatedStockBetweenDateList({
        fromdate: data.fromdate,
        todate: data.todate,
        stockType: "0",
      }).then(({ success, response }) => {
        showLoador({ loador: false });
        this.setState({
          fromdate: data.fromdate,
          todate: data.todate,
        });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.columnTotal(response);
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onLoadList = () => {
    const { getUpdatedStockBetweenDateList, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getUpdatedStockBetweenDateList({
        fromdate: dayjs().format("DD-MM-YYYY"),
        todate: dayjs().format("DD-MM-YYYY"),
        stockType: "0",
      }).then(({ success, response }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onSearchForPerformingOutlets = (searchValueForOutlets) => {
    console.log("searchValueForOutlets", searchValueForOutlets);
    this.setState({
      searchValueForOutlets: searchValueForOutlets,
    });
  };

  getFilteredTableData = (searchData) => {
    const { searchValueForOutlets } = this.state;
    return searchData.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["name"] &&
          currentRow["name"]
            .toString()
            .toLowerCase()
            .includes(searchValueForOutlets.trim().toLowerCase())) ||
        (currentRow["stakeHolderUomName"] &&
          currentRow["stakeHolderUomName"]
            .toString()
            .toLowerCase()
            .includes(searchValueForOutlets.trim().toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
    });
  };

  handleCloses = () => {
    this.setState({
      popUpFlag: false,
    });
  };

  onClickHandler = (e, row, flag) => {
    const { getDetailUpdated, showLoador, showNotification } = this.props;
    const { fromdate, todate, columnFlag } = this.state;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getDetailUpdated({
        fromdate: fromdate ? fromdate : dayjs().format("DD-MM-YYYY"),
        todate: todate ? todate : dayjs().format("DD-MM-YYYY"),
        productId: row.product,
        flag: flag,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.setState({
            popUpFlag: true,
            columnFlag: flag,
          });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  columnTotal(response) {
    let openingQTyTotal = 0,
      purchaseQtyTotal = 0,
      primarySellQtyTotal = 0,
      secondarySellTotalQty = 0,
      grnQtyTotal = 0,
      crnQtyTotal = 0,
      inStockQtyTotal = 0,
      outStockQtyTotal = 0,
      closingQtyTotal = 0,
      stockInHandTotal = 0,
      primaryStockTotal = 0;
    response.productStocks.map((qtyData) => {
      openingQTyTotal =
        openingQTyTotal + (+qtyData.openingQty > 0 ? +qtyData.openingQty : 0);
      purchaseQtyTotal =
        purchaseQtyTotal +
        (+qtyData.purchaseQty > 0 ? +qtyData.purchaseQty : 0);
      primarySellQtyTotal =
        primarySellQtyTotal +
        (+qtyData.distributorSellQty > 0 ? +qtyData.distributorSellQty : 0);
      secondarySellTotalQty =
        secondarySellTotalQty +
        (+qtyData.outletSellQty > 0 ? +qtyData.outletSellQty : 0);
      grnQtyTotal = grnQtyTotal + (+qtyData.grnQty > 0 ? +qtyData.grnQty : 0);
      crnQtyTotal = crnQtyTotal + (+qtyData.crnQty > 0 ? +qtyData.crnQty : 0);
      inStockQtyTotal =
        inStockQtyTotal + (+qtyData.inStockQty > 0 ? +qtyData.inStockQty : 0);
      outStockQtyTotal =
        outStockQtyTotal +
        (+qtyData.outStockQty > 0 ? +qtyData.outStockQty : 0);
      closingQtyTotal =
        closingQtyTotal + (+qtyData.closingQty > 0 ? +qtyData.closingQty : 0);
      stockInHandTotal =
        stockInHandTotal +
        (+qtyData.stockInHand > 0 ? +qtyData.stockInHand : 0);
      primaryStockTotal =
        primaryStockTotal +
        (+qtyData.primaryClosingQty > 0 ? +qtyData.primaryClosingQty : 0);
    });

    this.setState({
      openingQTyTotal: openingQTyTotal.toFixed(2),
      purchaseQtyTotal: purchaseQtyTotal.toFixed(2),
      primarySellQtyTotal: primarySellQtyTotal.toFixed(2),
      secondarySellTotalQty: secondarySellTotalQty.toFixed(2),
      grnQtyTotal: grnQtyTotal.toFixed(2),
      crnQtyTotal: crnQtyTotal.toFixed(2),
      inStockQtyTotal: inStockQtyTotal.toFixed(2),
      outStockQtyTotal: outStockQtyTotal.toFixed(2),
      closingQtyTotal: closingQtyTotal.toFixed(2),
      stockInHandTotal: stockInHandTotal.toFixed(2),
      primaryStockTotal: primaryStockTotal.toFixed(0),
    });
  }
  generateExcelStatic = () => {
    const { excelData } = this.state;
    console.log(excelData);
    const { showLoador, showNotification } = this.props;

    if (navigator.onLine) {
      showLoador({ loador: true });
      downloadF({
        isGet: true,
        url:
          endpoint.transactionOutlet +
          "/ledger-excel-by-date-and-outlet?outletId=" +
          excelData?.outletIds +
          "&fromDate=" +
          excelData?.fromDate +
          "&toDate=" +
          excelData?.toDate,
        ext: "xls",
        openNewTab: false,
      }).then((response) => {
        if (!response) {
          showNotification({
            msg: serverMsg,
            severity: "error",
          });
        }
        showLoador({ loador: false });
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  filterExcel = () => {
    const {
      openingQTyTotal,
      purchaseQtyTotal,
      primarySellQtyTotal,
      secondarySellTotalQty,
      grnQtyTotal,
      crnQtyTotal,
      inStockQtyTotal,
      outStockQtyTotal,
      closingQtyTotal,
      stockInHandTotal,
      primaryStockTotal,
    } = this.state;

    const filteredData = this.getFilteredTableData(
      this.props.manageStockList.stockBetweenDate.productStocks
    );

    if (filteredData.length === 0) {
      this.props.showNotification({
        msg: "No data available for export",
        severity: "error",
      });
      return;
    }

    const searchableColumns = ["SR.No", "Product Name"];

    const formattedData = filteredData.map((row, index) => {
      console.log("row this is filtered", row);

      openingQTyTotal || 0;
      purchaseQtyTotal || 0;
      primarySellQtyTotal || 0;
      secondarySellTotalQty || 0;
      grnQtyTotal || 0;
      crnQtyTotal || 0;
      inStockQtyTotal || 0;
      outStockQtyTotal || 0;
      closingQtyTotal || 0;
      stockInHandTotal || 0;
      primaryStockTotal || 0;

      return [
        index + 1,
        row.name || "",
        row.stakeHolderUomName || "",
        row.openingQty || 0,
        row.purchaseQty || 0,
        row.distributorSellQty || 0,
        row.outletSellQty || 0,
        row.grnQty || 0,
        row.crnQty || 0,
        row.inStockQty || 0,
        row.outStockQty || 0,
        row.closingQty || 0,
        row.stockInHand || 0,
        row.total || 0,
      ];
    });

    formattedData.push([
      "Total",
      "",
      "",
      openingQTyTotal,
      purchaseQtyTotal,
      primarySellQtyTotal,
      secondarySellTotalQty,
      grnQtyTotal,
      crnQtyTotal,
      inStockQtyTotal,
      outStockQtyTotal,
      closingQtyTotal,
      stockInHandTotal,
      primaryStockTotal + " Primary Stock",
    ]);

    generateExcel({
      columns: searchableColumns,
      rows: formattedData,
      alignList: [],
      headerList: [],
      title: StockBetweenDateJSON.screenTitle,
      reportName: StockBetweenDateJSON.screenTitle,
    });
  };

  onSearchAudit = (data) => {
    console.log(data);
    const { showLoador } = this.props;
    this.setState({
      excelData: data,
    });

    if (navigator.onLine) {
      showLoador({ loador: true });
      apiGet({
        url:
          endpoint.transactionOutlet +
          "/ledger-list-by-date-and-outlet?outletId=" +
          data?.outletIds +
          "&fromDate=" +
          data?.fromDate +
          "&toDate=" +
          data?.toDate,
      }).then((response) => {
        showLoador({ loador: false });
        console.log("response", response);
        if (response) {
          // let totalBalance = 0;
          // response?.data?.data?.forEach((item) => {
          //   totalBalance = parseFloat(item.balanceAmt);
          // });
          this.setState({
            ledgerData: response?.data?.data,
            //  totalBalance
          });
        } else {
          this.props.showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  render() {
    const {
      dynamicMasterData,
      ledgerData,
      DataToSet,
      //  totalBalance
    } = this.state;
    return (
      <>
        <Paper
          sx={{
            padding: 2,
          }}
          elevation={0}
        >
          <LandingScreenHeader
            showAddButton={false}
            screenTitle={"Outlet Ledger Report"}
            showSearchBox={false}
            showTable={true}
            showFilter={false}
            showPdfDownload={false}
            generateExcel={this.generateExcelStatic}
            staticExcel={false}
            showExcelDownload={false}
            onSearch={this.onSearchForPerformingOutlets}
          />

          <DynamicFormWithoutSave
            getListById={this.getListById}
            padding={true}
            paddingTop={true}
            showBackToList={true}
            DataToSet={DataToSet}
            dynamicMasterData={dynamicMasterData}
            getByIdApi={true}
            callApi={true}
            getDataById={this.getDataById}
            showTitle={false}
            screenTitle={false}
            fieldMeta={auditJson.fieldMetaForLedgerReportOutlet}
            showCancel={auditJson.showCancel}
            apiBaseURL={auditJson.apiBaseURL}
            showSaveNextBtn={auditJson.showSaveNextBtn}
            showSaveBtnMain={false}
            showSaveBtn={true}
            showSubmit={false}
            onSubmit={this.onSubmitAudit}
            saveBtnText={"Search"}
            onSave={this.onSearchAudit}
          />
          <br />
          <LandingScreenHeader
            showAddButton={false}
            screenTitle={""}
            showSearchBox={false}
            showTable={true}
            showFilter={false}
            showPdfDownload={false}
            generateExcel={this.generateExcelStatic}
            staticExcel={true}
            showExcelDownload={ledgerData.length > 0 ? true : false}
            onSearch={this.onSearchForPerformingOutlets}
          />

          <TableContainer style={{ maxHeight: "550px", overflow: "auto" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Sr. No</TableCell>
                  <TableCell align="center">Transaction Type</TableCell>
                  <TableCell align="center">Transaction No</TableCell>
                  <TableCell align="center">Date</TableCell>
                  <TableCell align="center">Credit Amount</TableCell>
                  <TableCell align="center">Debit Amount</TableCell>
                  <TableCell align="center">Balance Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {ledgerData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{row.date}</TableCell>
                    <TableCell>{row.taransactionType}</TableCell>
                    <TableCell align="right" style={{ color: "green" }}>
                      {row?.creditAmt !== undefined && row?.creditAmt !== null
                        ? (+row.creditAmt).toFixed(2)
                        : "-"}
                    </TableCell>
                    <TableCell align="right" style={{ color: "red" }}>
                      {row?.debitAmt !== undefined && row?.debitAmt !== null
                        ? (+row.debitAmt).toFixed(2)
                        : "-"}
                    </TableCell>
                    <TableCell align="right">
                      {row?.balanceAmt !== undefined && row?.balanceAmt !== null
                        ? (+row.balanceAmt).toFixed(2)
                        : "-"}
                    </TableCell>
                  </TableRow>
                ))} */}
                {/* <TableRow>
                  <TableCell align="right" colSpan={5}>
                    <strong>Total Balance</strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>{totalBalance}</strong>
                  </TableCell>
                </TableRow> */}

                {ledgerData.length > 0 ? (
                  ledgerData.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {row.taransactionType}
                      </TableCell>
                      <TableCell align="center">{row.taransactionNo}</TableCell>
                      <TableCell align="center">{row.date}</TableCell>
                      <TableCell align="right" style={{ color: "green" }}>
                        {row?.creditAmt !== undefined && row?.creditAmt !== null
                          ? (+row.creditAmt).toFixed(2)
                          : "-"}
                      </TableCell>
                      <TableCell align="right" style={{ color: "red" }}>
                        {row?.debitAmt !== undefined && row?.debitAmt !== null
                          ? (+row.debitAmt).toFixed(2)
                          : "-"}
                      </TableCell>
                      <TableCell align="right">
                        {row?.balanceAmt !== undefined &&
                        row?.balanceAmt !== null
                          ? (+row.balanceAmt).toFixed(2)
                          : "-"}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      No Data Available.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  manageStockList: state.manageStockByDate,
  stockStakeHolderType: state.stockStakeHolderType,
  auditList: state.audit,
  areaList: state.area,
  outletList: state.outlet,
  beatList: state.beat,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getUpdatedStockBetweenDateList,
  getDetailUpdated,
  getAreaByUserType,
  getBeatsByAreaIds,
  getOutletByBeatIds,
};
export default connect(mapStateToProps, mapDispatchToProps)(OutletLedgerReport);
