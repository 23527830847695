import endpoint from "../../config/endpoints";

export const ChannelJson = {
  showSaveNextBtn: true,
  formPath: "/channel-form",
  apiBaseURL: endpoint.channel,
  changeExcel: endpoint.exportData,
  screenTitle: "Channel",
  showAddButton: true,
  showPdfDownload: false,
  pdfFileName: "Channel",
  showExcelDownload: false,
  excelFileName: "Channel",
  isActiveURL: endpoint.channel + "/update-is-active",
  tableColumnsToFilter: [
    {
      columnDisplayName: "Sub Channel Count",
      columnKeyName: "name",
      isChecked: true,
    },
    {
      columnDisplayName: "Sort Order Number",
      columnKeyName: "sortOrder",
      isChecked: true,
    },
    {
      columnDisplayName: "Status",
      columnKeyName: "isActive",
      isChecked: true,
    },
  ],
  fieldMeta: [
    {
      label: "Channel Name",
      controlType: "textfield",
      placeHolder: "channel Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      autoFocus: true,
      dataKey: "name",
      isMandatory: true,
      uniqueValidation: true,
    },
    {
      label: "Short Name",
      controlType: "textfield",
      placeHolder: "Short Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "shortName",
      isMandatory: true,
    },
    {
      label: "Sort Order No",
      controlType: "textfield",
      placeHolder: "Sort Order Number",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "sortOrder",
      isMandatory: true,
    },
  ],
};
