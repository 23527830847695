import CloseIcon from "@mui/icons-material/Close";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Button";
import { MyComponentWithIconProps } from "../../components/Comman/IconButton";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { showLoador, showNotification } from "../Landing/Landing.slice";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const RUProductPopup = ({
  handleCloses,
  openProductPopup,
  tableName,
  data,
  formErrors,
  handlePopupRowChange,
  submitData,
}) => {
  const [searchValue, setSearchValue] = useState("");

  const onSearch = (searchValue) => {
    setSearchValue(searchValue);
  };

  const getFilteredTableData = (searchData) => {
    return searchData.filter((currentRow) => {
      let isValid = false;
      if (
        currentRow["name"] &&
        currentRow["name"]
          .toString()
          .toLowerCase()
          .includes(searchValue.trim().toLowerCase())
      ) {
        isValid = true;
        return true;
      }
    });
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleCloses}
        aria-labelledby="customized-dialog-title"
        open={openProductPopup}
        fullWidth
        maxWidth="lg"
      >
        <BootstrapDialogTitle id="customized-dialog-title">
          <MyComponentWithIconProps
            statusImage={KeyboardBackspaceIcon}
            color="primary"
            fontSize="medium"
            title="Back"
            onClick={handleCloses}
          />{" "}
          &nbsp;&nbsp;
          {tableName}{" "}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <LandingScreenHeader
            screenTitle=""
            showTable={true}
            showBackToList={false}
            showSaveBtnMain={false}
            showPdfDownload={false}
            showExcelDownload={false}
            onSearch={onSearch}
          />
          <TableContainer
            sx={{
              maxHeight: 440,
              marginTop: 0,
              padding: 0,
            }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead
                style={{
                  backgroundColor: "#fff",
                }}
              >
                <TableRow>
                  <TableCell
                    color="#000"
                    align="center"
                    sx={{ width: "2%", color: "#000" }}
                  >
                    Sr.No
                  </TableCell>
                  <TableCell align="center" sx={{ width: "8%", color: "#000" }}>
                    Name
                  </TableCell>
                  <TableCell align="center" sx={{ width: "5%", color: "#000" }}>
                    RU Qty
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getFilteredTableData(data).map((row, index) => (
                  <TableRow key={row.index}>
                    <TableCell width={"5%"} align="center">
                      {row.index}
                    </TableCell>
                    <TableCell width={"40%"}>{row.name}</TableCell>
                    <TableCell width={"10%"} align="center">
                      <input
                        className={"customTextfield"}
                        placeHolder={"RU Qty"}
                        value={row.ruQty}
                        name="ruQty"
                        onChange={(e) =>
                          handlePopupRowChange(
                            row.index,
                            "ruQty",
                            e.target.value
                          )
                        }
                        type="number"
                        id="outletRate"
                      />
                      {/* <TextFieldCompo
                        placeHolder={"RU Qty"}
                        size="small"
                        color="primary"
                        type="number"
                        id={"1"}
                        value={row.ruQty}
                        onChange={(e) =>
                          handlePopupRowChange(
                            row.index,
                            "ruQty",
                            e.target.value
                          )
                        }
                        fullWidth={false}
                        // errorText={
                        //   formErrors[row.index]?.ruQtyError
                        //     ? formErrors[row.index].ruQtyError
                        //     : ""
                        // }
                        // isError={
                        //   formErrors[row.index]?.ruQtyError
                        //     ? formErrors[row.index]?.ruQtyError
                        //     : ""
                        // }
                      /> */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid
            item
            justifyContent={"flex-end"}
            gap={2}
            xs={12}
            sm={12}
            md={1}
            flexDirection={"row"}
            display={"flex"}
          >
            <Grid justifyContent={"flex-end"} item>
              <ButtonCompo
                size="medium"
                onClick={submitData}
                variant="contained"
                name="Submit"
              />
            </Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  targetList: state.target,
});
const mapDispatchToProps = { showLoador, showNotification };
export default connect(mapStateToProps, mapDispatchToProps)(RUProductPopup);
