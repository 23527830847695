import endpoint from "../../config/endpoints";

export const auditJson = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.audit,
  apiBaseURL2: endpoint.auditQuestion,
  formPath: "/audit-form",
  formPathView: "/audit-view",
  openFormPathStatus: "/audit-survey-data",
  openFormPathStatusSurveyDetails: "/audit-question-answer",
  listPath: "/audit",
  screenTitle: "Audit / Survey / Area Exploration Questionary",
  showTitle: true,
  showCancel: false,
  showBackToList: true,
  showSaveBtn: false,
  showAddButton: true,
  showPdfDownload: false,
  pdfFileName: "Beat",
  showExcelDownload: false,
  excelFileName: "Beat",

  fieldMetaForAudit: [
    {
      label: "Area",
      controlType: "multiSelect",
      placeHolder: "Select Area ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "areaDetail",
      getListId: "areaIds",
      dataKey: "areaIds",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Beat",
      controlType: "multiSelect",
      placeHolder: "Select Beat",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "beatIds",
      masterName: "beatDetail",
      getListFrom: "areaIds",
      getListId: "beatIds",
      isMandatory: true,
      isRootLevelKey: false,
    },
    {
      label: "Outlet",
      controlType: "multiSelect",
      placeHolder: "Select Outlet",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "outletIds",
      getListFrom: "beatIds",
      masterName: "outletDetail",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Who Can Take",
      controlType: "autocomplete",
      placeHolder: "Select Who Can Take",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stockStakeHolderTypeDetail",
      dataKey: "whoCanTake",
      isRootLevelKey: true,
      isMandatory: true,
    },
    // {
    //   label: "Type",
    //   controlType: "radiogroup",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   dataKey: "type",
    //   tableDataKey: "type",
    //   defaultValue: "0",
    //   radioGroupItems: [
    //     {
    //       label: "Outlet",
    //       value: "0",
    //     },
    //     {
    //       label: "StakeHolder",
    //       value: "1",
    //     },
    //   ],
    //   isMandatory: true,
    // },

    // {
    //   label: "Stock Holder Type",
    //   controlType: "hideMultiSelect",
    //   placeHolder: "Select Stock Holder Type",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   masterName: "stockStakeHolderTypelistDetail",
    //   dataKey: "stockStakeHolderTypeId",
    //   tableDataKey: "type",
    //   open: "1",
    // },
    {
      label: "Name",
      controlType: "textfield",
      placeHolder: "Name",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      autoFocus: true,
      dataKey: "name",
      isMandatory: true,
      uniqueValidation: true,
    },
    {
      label: "Remark",
      controlType: "textfield",
      placeHolder: "Remark",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      dataKey: "remark",
      isMandatory: false,
    },
  ],

  fieldMetaForSurvey: [
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromDate",
      isMandatory: true,
      isMinDate: true,
      minDate: new Date(),
      onSubmit: "compare",
      onSubmitParameter: "toDate-le",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "toDate",
      onSubmit: "compare",
      onSubmitParameter: "fromDate-ge",
      isMandatory: true,
      isMinDate: true,
      minDate: new Date(),
      onSubmit: "compare",
    },
    // {
    //   label: "Area",
    //   controlType: "multiSelect",
    //   placeHolder: "Select Area",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   masterName: "areaDetail",
    //   dataKey: "areaIds",
    //   isRootLevelKey: true,
    //   isMandatory: true,
    // },
    // {
    //   label: "Who Can Take",
    //   controlType: "autocomplete",
    //   placeHolder: "Select Who Can Take",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   masterName: "stockStakeHolderTypeDetail",
    //   dataKey: "whoCanTake",
    //   isRootLevelKey: true,
    //   isMandatory: true,
    // },
    // // {
    // //   label: "Type",
    // //   controlType: "radiogroup",
    // //   md: 6,
    // //   lg: 6,
    // //   sm: 6,
    // //   xs: 12,
    // //   dataKey: "type",
    // //   tableDataKey: "type",
    // //   defaultValue: "0",
    // //   radioGroupItems: [
    // //     {
    // //       label: "Outlet",
    // //       value: "0",
    // //     },
    // //     {
    // //       label: "Stock Holder",
    // //       value: "1",
    // //     },
    // //   ],
    // //   isMandatory: true,
    // // },

    // // {
    // //   label: "Stock Holder Type",
    // //   controlType: "hideMultiSelect",
    // //   placeHolder: "Select Stock Holder Type",
    // //   md: 6,
    // //   lg: 6,
    // //   sm: 6,
    // //   xs: 12,
    // //   masterName: "stockStakeHolderTypelistDetail",
    // //   dataKey: "stockStakeHolderTypeId",
    // //   tableDataKey: "type",
    // //   open: "1",
    // // },
    // {
    //   label: "Name",
    //   controlType: "textfield",
    //   placeHolder: "Name",
    //   md: 12,
    //   lg: 12,
    //   sm: 12,
    //   xs: 12,
    //   autoFocus: true,
    //   dataKey: "name",
    //   isMandatory: true,
    //   uniqueValidation: true,
    // },
    // {
    //   label: "Remark",
    //   controlType: "textfield",
    //   placeHolder: "Remark",
    //   md: 12,
    //   lg: 12,
    //   sm: 12,
    //   xs: 12,
    //   dataKey: "remark",
    //   isMandatory: false,
    // },
  ],

  fieldMetaForOption: [
    {
      label: "Option",
      controlType: "textfield",
      placeHolder: "Option",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "optionTitle",
      isMandatory: true,
      inputType: "numberText"

    },

    {
      label: "Sort Order Number",
      controlType: "textfield",
      placeHolder: "Sort Order Number",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "orderNo",
      isMandatory: true,
    },
    {
      label: "Points",
      controlType: "textfield",
      placeHolder: "Points",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "points",
      valid: true,
      validName: "validPointName",
      isMandatory: true,
    },
  ],
  fieldMetaForQestion: [
    {
      label: "Question",
      controlType: "textfield",
      placeHolder: "Question",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "question",
      isMandatory: true,
    },
    {
      label: "Option type",
      controlType: "autocomplete",
      placeHolder: "Select Option Type",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "optionTypeList",
      dataKey: "optionType",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Sort Order Number",
      controlType: "textfield",
      placeHolder: "Sort Order Number",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "orderNo",
      isMandatory: true,
    },
    // {
    //   label: "Points",
    //   controlType: "textfield",
    //   placeHolder: "Points",
    //   inputType: "number",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   dataKey: "points",
    //   isMandatory: true,
    //   valid: true,
    //   validName: "validPoint",
    // },
    {
      label: "Image",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "imageUpload",
      tableDataKey: "imageUpload",
      defaultValue: "0",
      radioGroupItems: [
        {
          label: "No",
          value: "0",
        },
        {
          label: "Yes",
          value: "1",
        },
      ],
      isMandatory: true,
    },
    {
      label: "Remark",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "remark",
      tableDataKey: "remark",
      defaultValue: "0",
      radioGroupItems: [
        {
          label: "Yes",
          value: "0",
        },
        {
          label: "No",
          value: "1",
        },
      ],
      isMandatory: true,
    },
    {
      label: "Is Mandatory?",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      disable: true,
      dataKey: "remarkMandatory",
      tableDataKey: "remarkMandatory",
      defaultValue: "0",
      radioGroupItems: [
        {
          label: "Yes",
          value: "0",
        },
        {
          label: "No",
          value: "1",
        },
      ],
      isMandatory: true,
    },
  ],

  fieldMetaForQestionForAreaExploration: [
    {
      label: "Question",
      controlType: "textfield",
      placeHolder: "Question",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "question",
      isMandatory: true,
    },
    {
      label: "Option type",
      controlType: "autocomplete",
      placeHolder: "Select Option Type",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "optionTypeList",
      dataKey: "optionType",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Sort Order Number",
      controlType: "textfield",
      placeHolder: "Sort Order Number",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "orderNo",
      isMandatory: true,
    },
    // {
    //   label: "Points",
    //   controlType: "textfield",
    //   placeHolder: "Points",
    //   inputType: "number",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   dataKey: "points",
    //   isMandatory: true,
    //   valid: true,
    //   validName: "validPoint",
    // },
    {
      label: "Image",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "imageUpload",
      tableDataKey: "imageUpload",
      defaultValue: "0",
      disableTrue: true,
      radioGroupItems: [
        {
          label: "No",
          value: "0",
        },
        {
          label: "Yes",
          value: "1",
        },
      ],
      isMandatory: true,
    },
    {
      label: "Remark",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "remark",
      tableDataKey: "remark",
      defaultValue: "0",
      radioGroupItems: [
        {
          label: "Yes",
          value: "0",
        },
        {
          label: "No",
          value: "1",
        },
      ],
      isMandatory: true,
    },
    {
      label: "Is Mandatory?",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      disable: true,
      dataKey: "remarkMandatory",
      tableDataKey: "remarkMandatory",
      defaultValue: "0",
      radioGroupItems: [
        {
          label: "Yes",
          value: "0",
        },
        {
          label: "No",
          value: "1",
        },
      ],
      isMandatory: true,
    },
  ],



  fieldMetaForLedgerReportOutlet: [
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromDate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "toDate-le",
      format: "DD-MM-YYYY",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "toDate",
      onSubmit: "compare",
      onSubmitParameter: "fromDate-ge",
      isMandatory: true,
      onSubmit: "compare",
      format: "DD-MM-YYYY",
      maxDatekey: "fromDate",
    },
    {
      label: "Area",
      controlType: "autocomplete",
      placeHolder: "Select Area ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "areaDetail",
      getListId: "areaIds",
      dataKey: "areaIds",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Beat",
      controlType: "autocomplete",
      placeHolder: "Select Beat",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "beatIds",
      masterName: "beatDetail",
      getListFrom: "areaIds",
      getListId: "beatIds",
      isMandatory: true,
      isRootLevelKey: false,
    },
    {
      label: "Outlet",
      controlType: "autocomplete",
      placeHolder: "Select Outlet",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "outletIds",
      getListFrom: "areaIds",
      masterName: "outletDetail",
      isRootLevelKey: true,
      isMandatory: true,
    }
  ]


};
