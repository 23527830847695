import endpoint from "../../config/endpoints";

export const ProductWiseRUConfigJson = {
    screenTitle: "Product Wise RU Configuration",
    showSaveBtn: false,
    apiBaseURL: endpoint.ruProductConfigHeader,
    formPath: "/product-wise-ru-config-disabled-ru",
    formPathView: "/product-wise-ru-config-view",

    fieldMeta: [
        {
            label: "Year",
            controlType: "autocomplete",
            placeHolder: "Select Year",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "yearDetail",
            dataKey: "year",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Name",
            controlType: "textfield",
            placeHolder: "Name",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "name",
            isMandatory: true,
        },
    ],
};
