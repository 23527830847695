import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import {
  OutletTypeColumns,
  OutletCountColumnsForOutletTypePopup,
  AreaColumnsForPopup
} from "../../../tableColumns/table-columns";
import { outletTypeJson } from "../../../DynamicFormsJson/MastersJSON/outletType";
import { getOutletType } from "../../../Slice/outletType.slice";

class OutletTypeTable extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { outletTypeList, getOutletType } = this.props;
    return (
      <>
        <DynamicMainScreen
          formPath={outletTypeJson.formPath}
          screenTitle={outletTypeJson.screenTitle}
          fieldMeta={outletTypeJson.fieldMeta}
          showPdfDownload={false}
          showExcelDownload={true}
          filterDataExcel={true}
          changeExcelURL={"outlet-type-data" + '?sort={"insertDateTime": "DESC"}'}
          showExtension={false}
          pdfFileName={outletTypeJson.pdfFileName}
          excelFileName={outletTypeJson.excelFileName}
          showAddButton={outletTypeJson.showAddButton}
          tableColumnsToFilter={outletTypeJson.tableColumnsToFilter}
          tableColumns={OutletTypeColumns}
          tableData={outletTypeList?.outletType}
          getTableData={getOutletType}
          apiBaseURL={outletTypeJson.apiBaseURL}
          baseIdColumn={outletTypeJson.baseIdColumn}
          isNote={true}
          showDeleteIcon={false}
          isNoteValue={"Add Outlet Categories"}
          showPopUpDataList={[
            {
              dataKey: "outletCount",
              columns: OutletCountColumnsForOutletTypePopup,
              title: "Outlet List",
              header: {
                dataKey: "name",
                label: "Outlet Type",
              },
            },
          ]}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  outletTypeList: state.outletType,
});
const mapDispatchToProps = {
  getOutletType,
};
export default connect(mapStateToProps, mapDispatchToProps)(OutletTypeTable);
