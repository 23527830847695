import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import { TaxColumns } from "../../../tableColumns/table-columns";
import { taxJson } from "../../../DynamicFormsJson/MastersJSON/Tax";
import { getTax } from "../../../Slice/Tax.slice";


class TaxTable extends React.Component {
  render() {
    const { taxList, getTax } = this.props;
    return (
      <>
        <DynamicMainScreen
          formPath={taxJson.formPath}
          screenTitle={taxJson.screenTitle}
          fieldMeta={taxJson.fieldMeta}
          showPdfDownload={false}
          showExcelDownload={true}
          changeExcelURL={taxJson.excelUrl}
          showExtension={false}
          filterDataExcel={true}
          pdfFileName={taxJson.pdfFileName}
          excelFileName={taxJson.excelFileName}
          showAddButton={taxJson.showAddButton}
          tableColumnsToFilter={taxJson.tableColumnsToFilter}
          tableColumns={TaxColumns}
          tableData={taxList?.tax}
          getTableData={getTax}
          apiBaseURL={taxJson.apiBaseURL}
          isNote={true}
          isNoteValue={"Types Of Tax Applicable On Product's"}
          showDeleteIcon={false}
          isLog={true}
          type={0}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  taxList: state.tax,
});
const mapDispatchToProps = {
  getTax,
};
export default connect(mapStateToProps, mapDispatchToProps)(TaxTable);
