import React, { useState } from "react";
import "./VerticalTabs.css"; // Import the CSS file for styling

function VerticalTabs({ tabs }) {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <>
      <div className="vertical-timeline-tabs-container">
        <div className="vertical-tabs">
          {tabs.map((tab) => (
            <div
              key={tab.id}
              className={`vertical-tab-item ${
                activeTab === tab.id ? "active" : ""
              }`}
              onClick={() => setActiveTab(tab.id)}
            >
              <div
                className={`vertical-tab-indicator ${
                  activeTab === tab.id ? "active" : ""
                }`}
              >
                {tab.label.substring(0, 1)}
              </div>
              <div className="vertical-tab-label">{tab.label}</div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default VerticalTabs;
