import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import { withRouter } from "../../components/withRouter";
import { blackColor, titleColor } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import { apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";

import {
  CalendarTodayOutlined,
  DateRangeOutlined,
  DescriptionOutlined,
  LocalOfferOutlined,
} from "@mui/icons-material"; // Import Outlined icons
import swal from "sweetalert";
import offerImg from "../../Images/offer.png";
import {
  noInternetMsg,
  offerSelectionMsg,
  saveFailedMsg,
  saveWarningMsg,
  savemsg,
  serverMsg,
} from "../../config/messageconstant";
import ClaimRequestPopup from "./ClaimRequestPopup";

class CreditNoteClaimForOffer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOffers: [],
      apiData: {},
      tabIndex: 0,
      rowList: [],
      fieldData: {},
      remark: "",
      dynamicMasterData: {},
      headerData: [],
      searchValue: "",
      isPopupOpen: false,
    };
  }
  async componentDidMount() {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await apiPost({
        url: endpoint.creditNoteOffer + "/claim-for-offer-outlet",
      }).then(({ data, success }) => {
        console.log(data);
        if (success) {
          const transformedData = this.transformApiData(data);
          this.setState({ apiData: transformedData });
        } else {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: false });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  onSubmitData = () => {
    const { selectedOffers } = this.state;
    const { showNotification } = this.props;
    console.log(selectedOffers);
    if (selectedOffers.length === 0) {
      showNotification({ msg: offerSelectionMsg, severity: "error" });
    } else {
      this.setState({ isPopupOpen: true });
    }
  };

  getFilteredTableData = (tableData) => {
    const { searchValue } = this.state;

    if (!searchValue) return tableData;
    return tableData.filter((currentRow) => {
      const lowerSearchValue = searchValue.toLowerCase();
      const searchFields = [currentRow.outletName];
      return searchFields.some(
        (field) => field && field.toLowerCase().includes(lowerSearchValue)
      );
    });
  };
  transformApiData = (apiData) => {
    const { flagToSave } = this.props;

    if (!apiData || !apiData.data || !Array.isArray(apiData.data)) {
      return apiData;
    }
    const offerListNew = apiData.data.map((offer) => {

        console.log("offer",offer)
      return {
        id: offer.offerDetail?.id,
        name: offer.offerDetail?.name || "-",
        description: offer.offerDetail?.discription || "-",
        fromDate: offer.offerDetail?.fromDate || "-",
        toDate: offer.offerDetail?.toDate || "-",
        ...offer,
        crnType: flagToSave,
        outlets: offer.applicableOutletList.map((outlet, index) => {
          const totalTaxAmt =
            Number(outlet.cgstAmt || 0) +
            Number(outlet.sgstAmt || 0) +
            Number(outlet.igstAmt || 0);
          return {
            index: index + 1,
            outletName: outlet.firm_name || "-",
            beatName: outlet.beatName || "-",
            billNo: outlet.billNo || "-",
            billDate: outlet.billDate || "-",
            totalSaleQty: outlet.totalSaleQty || 0,
            taxableAmount: outlet.taxableAmt.toFixed(2),
            totalTaxAmt: totalTaxAmt.toFixed(2),
            totalAmount: outlet.totalAmt.toFixed(2),
            outletId: outlet.outletId || "-",
            items: outlet.itemList.map((item, itemIndex) => ({
              index: itemIndex + 1,
              itemName: item.name || "-",
              hsnCode: item.hsnCode || "-",
              qty: item.qty || 0,
              taxableAmt: Number(item.texableAmt || 0).toFixed(2),
              taxAmt: Number(
                (item.cgstAmt || 0) + (item.sgstAmt || 0) + (item.igstAmt || 0)
              ).toFixed(2),
              totalAmt: Number(item.total || 0).toFixed(2),
              productId: item.productId,
            })),
          };
        }),
      };
    });
    console.log("offerListNew", offerListNew);

    return { offerListNew };
  };
  handleCheckboxChange = (offer) => {
    this.setState((prevState) => {
      const { selectedOffers } = prevState;
      const isAlreadySelected = selectedOffers.some(
        (item) => item.id === offer.id
      );

      const newSelectedOffers = isAlreadySelected
        ? selectedOffers.filter((item) => item.id !== offer.id) // Remove
        : [...selectedOffers, offer]; // Add

      return {
        selectedOffers: newSelectedOffers,
      };
    });
  };

  handlePopupSubmit = (selectedOffers) => {
    console.log("Submitting claim request for:", selectedOffers);
    swal({
      title: "Are you sure?",
      text: saveWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        if (navigator.onLine) {
          showLoador({ loador: true });
          await apiPost({
            url: endpoint.creditNoteOffer + "/generate-credit-note-outlet",
            postBody: selectedOffers,
          }).then(({ data, success }) => {
            if (success) {
              this.setState({ isPopupOpen: false, selectedOffers: [] });
              showNotification({ msg: savemsg });
              this.props.navigate(-1);
            } else {
              showNotification({ msg: saveFailedMsg, severity: "error" });
            }
            return success;
          });
          showLoador({ loador: false });
        } else {
          showNotification({ msg: noInternetMsg, severity: "error" });
        }
      }
    });
  };
  handleSelectAllChange = (event) => {
    const isChecked = event.target.checked;
    this.setState((prevState) => ({
      selectedOffers: isChecked ? [...prevState.apiData.offerListNew] : [],
    }));
  };

  render() {
    const { apiData, selectedOffers, isPopupOpen } = this.state;
    return (
      <>
        <Paper
          sx={{
            padding: 2,
            border: "1px solid #ddd", // Light grey border
            borderRadius: 2, // Rounded corners
            elevation: 3, // Shadow effect
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Custom shadow
          }}
        >
          {/* <LandingScreenHeader
            screenTitle="CRN Claim For Offer (Outlet)"
            showSearchBox={true}
            showAddButton={false}
          /> */}

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <LandingScreenHeader
              screenTitle="CRN From Offer (Outlet)"
              showSearchBox={true}
              showAddButton={false}
            />

            {/* Select All Offers Checkbox */}
            <Box display="flex" alignItems="center">
              <Typography
                sx={{ fontSize: 16, fontWeight: "bold", marginRight: 1 }}
              >
                Select All Offers
              </Typography>
              <Checkbox
                checked={
                  this.state.selectedOffers.length ===
                    (this.state.apiData?.offerListNew?.length || 0) &&
                  this.state.apiData?.offerListNew?.length > 0
                }
                onChange={this.handleSelectAllChange}
              />
            </Box>
          </Box>

          <>
            <Box
              sx={{
                overflowY: "auto",
                maxHeight: "500px",
                borderRadius: "10px",
                "&::-webkit-scrollbar": { display: "none" }, // Hides scrollbar in Chrome, Safari, Edge
              }}
            >
              {/* <Divider /> */}

              <Grid container alignItems="center">
                <Grid item xs={12} md={12} sm={12}>
                  {apiData?.offerListNew?.length > 0 ? (
                    <>
                      {apiData?.offerListNew?.map((dataRow, index) => (
                        <div
                          key={index}
                          style={{
                            marginBottom: "10px",
                            paddingBottom: "10px",
                          }}
                        >
                          <Box
                            sx={{
                              position: "relative", // Parent container for absolute positioning
                              padding: "1px",
                              border: "2px solid #ddd",
                              borderRadius: "20px",
                              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Soft shadow effect
                            }}
                          >
                            <Checkbox
                              checked={selectedOffers.some(
                                (item) => item.id === dataRow.id
                              )}
                              onChange={() =>
                                this.handleCheckboxChange(dataRow)
                              }
                            />

                            <Box
                              sx={{
                                position: "absolute",
                                top: "8px",
                                right: "30px",
                                width: "70px",
                                height: "60px",
                                animation: "blinker 1.0s linear infinite",
                                "@keyframes blinker": {
                                  "50%": { opacity: 0.2 }, // Fades in and out
                                },
                              }}
                            >
                              <img
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  borderRadius: "15%",
                                  objectFit: "contain",
                                  padding: "5px",
                                }}
                                src={offerImg}
                                alt="Offer Icon"
                              />
                            </Box>
                            <Grid container spacing={2} padding={4}>
                              {/* */}

                              <Grid
                                container
                                spacing={2}
                                alignItems="center"
                                sx={{ marginLeft: 1 }}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  md={6}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <IconButton size="small" disabled>
                                    <LocalOfferOutlined
                                      sx={{ color: blackColor }}
                                    />
                                  </IconButton>
                                  <LabelCompo
                                    className="text-black"
                                    style={{
                                      fontSize: 16,
                                      color: titleColor,
                                      marginRight: 8,
                                    }}
                                    label="Offer Name:"
                                  />
                                  <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16 }}
                                    label={dataRow?.name || "-"}
                                  />
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  md={6}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <IconButton size="small" disabled>
                                    <DescriptionOutlined
                                      sx={{ color: blackColor }}
                                    />
                                  </IconButton>
                                  <LabelCompo
                                    className="text-black"
                                    style={{
                                      fontSize: 16,
                                      color: titleColor,
                                      marginRight: 8,
                                    }}
                                    label="Description:"
                                  />
                                  <LabelCompo
                                    className="text-black"
                                    style={{
                                      fontSize: 16,
                                      wordWrap: "break-word", // Breaks long words
                                      overflowWrap: "break-word", // Ensures wrapping
                                      whiteSpace: "normal", // Allows text to wrap
                                      maxWidth: "70%", // Ensures it stays within the container
                                    }}
                                    label={dataRow?.description || "-"}
                                  />
                                </Grid>
                              </Grid>

                              {/* Second Row: From Date & To Date */}
                              <Grid
                                container
                                spacing={2}
                                alignItems="center"
                                sx={{ marginTop: 1, marginLeft: 1 }}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  md={6}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <IconButton size="small" disabled>
                                    <CalendarTodayOutlined
                                      sx={{ color: blackColor }}
                                    />
                                  </IconButton>
                                  <LabelCompo
                                    className="text-black"
                                    style={{
                                      fontSize: 16,
                                      color: titleColor,
                                      marginRight: 8,
                                    }}
                                    label="From Date:"
                                  />
                                  <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16 }}
                                    label={dataRow?.fromDate || "-"}
                                  />
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  md={6}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <IconButton size="small" disabled>
                                    <DateRangeOutlined
                                      sx={{ color: blackColor }}
                                    />
                                  </IconButton>
                                  <LabelCompo
                                    className="text-black"
                                    style={{
                                      fontSize: 16,
                                      color: titleColor,
                                      marginRight: 8,
                                    }}
                                    label="To Date:"
                                  />
                                  <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16 }}
                                    label={dataRow?.toDate || "-"}
                                  />
                                </Grid>
                              </Grid>
                              {/* */}
                              {/* Table for Details */}

                              {dataRow?.outlets?.length > 0 && (
                                <>
                                  <Grid
                                    container
                                    item
                                    xs={12}
                                    md={12}
                                    sm={12}
                                    sx={{ marginTop: 1 }}
                                  >
                                    <DynamicTable
                                      data={
                                        Array.isArray(dataRow?.outlets)
                                          ? this.getFilteredTableData(
                                              dataRow?.outlets
                                            )
                                          : []
                                      }
                                      tableHead={[
                                        {
                                          title: "Sr.No.",
                                          name: "index",
                                          positionCenter: true,
                                          showInscreen: true,
                                          align: "center",
                                        },
                                        {
                                          title: "Outlet Name",
                                          name: "outletName",
                                          positionCenter: false,
                                          showInscreen: true,
                                          alignCenter: "left",
                                          canSearch: true,
                                        },
                                        {
                                          title: "Beat Name",
                                          name: "beatName",
                                          positionCenter: false,
                                          showInscreen: true,
                                          alignCenter: "left",
                                          canSearch: true,
                                        },
                                        {
                                          title: "Bill No",
                                          name: "billNo",
                                          positionCenter: false,
                                          showInscreen: true,
                                          alignCenter: "center",
                                          canSearch: true,
                                        },
                                        {
                                          title: "Bill Date",
                                          name: "billDate",
                                          positionCenter: false,
                                          showInscreen: true,
                                          alignCenter: "center",
                                          canSearch: true,
                                        },
                                        {
                                          title: "Taxable Amount",
                                          name: "taxableAmount",
                                          positionCenter: false,
                                          showInscreen: true,
                                          alignCenter: "right",
                                          canSearch: true,
                                        },
                                        {
                                          title: "Tax Amount",
                                          name: "totalTaxAmt",
                                          positionCenter: false,
                                          showInscreen: true,
                                          alignCenter: "right",
                                          canSearch: true,
                                        },
                                        {
                                          title: "Total Amount",
                                          name: "totalAmount",
                                          positionCenter: false,
                                          showInscreen: true,
                                          alignCenter: "right",
                                          canSearch: true,
                                        },
                                      ]}
                                      showPegination={false}
                                      isActionColActive={false}
                                    />
                                  </Grid>

                                  <Grid
                                    container
                                    spacing={2}
                                    sx={{ marginLeft: 1, marginTop: 1 }}
                                    justifyContent="space-between"
                                  >
                                    <Grid item xs={12} md={4} lg={4}>
                                      <Typography
                                        variant="h6"
                                        sx={{ color: titleColor }}
                                      >
                                        ₹ Total Taxable Amount:{" "}
                                        {Number(
                                          dataRow?.outlets?.reduce(
                                            (sum, outlet) =>
                                              sum +
                                              Number(outlet.taxableAmount || 0),
                                            0
                                          )
                                        ).toFixed(2)}
                                      </Typography>
                                    </Grid>

                                    <Grid item xs={12} md={4} lg={4}>
                                      <Typography
                                        variant="h6"
                                        sx={{ color: titleColor }}
                                      >
                                        ₹ Total Tax Amount:{" "}
                                        {Number(
                                          dataRow?.outlets?.reduce(
                                            (sum, outlet) =>
                                              sum +
                                              Number(outlet.totalTaxAmt || 0),
                                            0
                                          )
                                        ).toFixed(2)}
                                      </Typography>
                                    </Grid>

                                    <Grid item xs={12} md={4} lg={4}>
                                      <Typography
                                        variant="h6"
                                        sx={{ color: titleColor }}
                                      >
                                        ₹ Total Amount:{" "}
                                        {Number(
                                          dataRow?.outlets?.reduce(
                                            (sum, outlet) =>
                                              sum +
                                              Number(outlet.totalAmount || 0),
                                            0
                                          )
                                        ).toFixed(2)}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </>
                              )}
                            </Grid>
                          </Box>
                        </div>
                      ))}
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        container
                        justifyContent="right"
                        sx={{ padding: 1 }}
                      >
                        <ButtonCompo
                          size="medium"
                          type="Submit"
                          variant="contained"
                          name="Generate CRN"
                          onClick={this.onSubmitData}
                        />
                      </Grid>
                    </>
                  ) : (
                    <Typography
                      variant="h6"
                      color="textSecondary"
                      sx={{ textAlign: "center" }}
                    >
                      No offers available at the moment.
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Box>
          </>
        </Paper>
        {isPopupOpen && (
          <>
            <ClaimRequestPopup
              open={this.state.isPopupOpen}
              onClose={() => this.setState({ isPopupOpen: false })}
              selectedOffers={this.state.selectedOffers}
              onSubmit={this.handlePopupSubmit}
            />
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = { showLoador, showNotification };

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CreditNoteClaimForOffer);
