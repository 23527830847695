import React from "react";
import { connect } from "react-redux";
import { talukaJson } from "../../../DynamicFormsJson/MastersJSON/taluka";
import { getTalukaUpdated } from "../../../Slice/taluka.slice";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import {
    AreaColumnsForPopup,
    BeatColumnsForPopup,
    OutletCountColumnsForPopup,
    TalukaColumns,
} from "../../../tableColumns/table-columns";

class TalukaTable extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { talukaList, getTalukaUpdated } = this.props;
        return (
            <>
                <DynamicMainScreen
                    screenTitle={talukaJson.screenTitle}
                    fieldMeta={talukaJson.fieldMeta}
                    formPath={talukaJson.formPath}
                    showPdfDownload={talukaJson.showPdfDownload}
                    filterDataExcel={true}
                    showExcelDownload={true}
                    changeExcelURL={"taluka-data-new" + '?sort={"sortOrder": "ASC"}'}
                    showExtension={false}
                    pdfFileName={talukaJson.pdfFileName}
                    excelFileName={talukaJson.excelFileName}
                    showAddButton={talukaJson.showAddButton}
                    tableColumnsToFilter={talukaJson.tableColumnsToFilter}
                    tableColumns={TalukaColumns}
                    tableData={talukaList?.taluka}
                    getTableData={getTalukaUpdated}
                    apiBaseURL={talukaJson.apiBaseURL}
                    baseIdColumn={talukaJson.baseIdColumn}
                    isNote={true}
                    showDeleteIcon={false}
                    type={0}
                    isLog={true}
                    isNoteValue={"Taluka Is The Second Largest Entity Of The System"}
                    showPopUpDataList={[
                        {
                            dataKey: "areaCount",
                            columns: AreaColumnsForPopup,
                            title: "Area List",
                            header: {
                                dataKey: "name",
                                label: "Taluka",
                            },
                        },
                        {
                            dataKey: "beatCount",
                            columns: BeatColumnsForPopup,
                            title: "Beat List",
                            header: {
                                dataKey: "name",
                                label: "Taluka",
                            },
                        },
                        {
                            dataKey: "outletCount",
                            columns: OutletCountColumnsForPopup,
                            title: "Outlet List",
                            header: {
                                dataKey: "name",
                                label: "Taluka",
                            },
                        },
                    ]}
                />
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    talukaList: state.taluka,
});
const mapDispatchToProps = {
    getTalukaUpdated,
};
export default connect(mapStateToProps, mapDispatchToProps)(TalukaTable);
