import endpoint from "../../config/endpoints";

export const pendingGrnForCreditNoteJson = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.grnGVNHeader,
  screenTitle: "Pending GRN For Credit Note",
  fieldMeta: [
    {
      label: "Stock Holder",
      controlType: "autocomplete",
      placeHolder: "Select Stock Holder",
      md: 5.5,
      lg: 5.5,
      sm: 5.5,
      xs: 12,
      masterName: "stakeholdersDetail",
      dataKey: "stackholder",
      getListId: "stackholder",
      isRootLevelKey: true,
      isMandatory: true,
    },

    {
      label: "GRN",
      controlType: "multiSelect",
      placeHolder: "Select GRN",
      md: 5.5,
      lg: 5.5,
      sm: 5.5,
      xs: 12,
      masterName: "grnDetails",
      getListFrom: "stackholder",
      dataKey: "requestGRN",
      isRootLevelKey: false,
      isMandatory: true,
    },
  ],
  fieldMetaForGrnToCrn: [
    {
      label: "Stock Holder",
      controlType: "autocomplete",
      placeHolder: "Select Stock Holder",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "stakeholdersDetail",
      dataKey: "stackholder",
      getListId: "stackholder",
      isRootLevelKey: true,
      isMandatory: true,
    },

    {
      label: "GRN",
      controlType: "multiSelect",
      placeHolder: "Select GRN",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      labelXS:2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      valueXS: 10,
      masterName: "grnDetails",
      getListFrom: "stackholder",
      dataKey: "requestGRN",
      isRootLevelKey: true,
      isMandatory: true,
    },
  ],
};
