import endpoint from "../../config/endpoints";
export const offerJson = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.offer,
  isActiveURL: endpoint.offer + "/update-status",
  formPath: "/offer",
  formPathView: "/offer-view",
  screenTitle: "Offer",
  showAddButton: true,
  showPdfDownload: false,
  pdfFileName: "Offer",
  changeExcel: endpoint.exportData,
  showExcelDownload: false,
  excelFileName: "Offer",
  searchListForPast: [
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromDate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "toDate-le",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "toDate",
      onSubmit: "compare",
      onSubmitParameter: "fromDate-ge",
      isMandatory: true,
      onSubmit: "compare",
    },
  ],

  searchList: [
    {
      label: "Level",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "level",
      tableDataKey: "level",
      defaultValue: "0",
      radioGroupItems: [
        {
          label: "Bill Level",
          value: "0",
        },
        {
          label: "Item Level",
          value: "1",
        },
      ],
      isMandatory: false,
    },

    {
      label: "Level Wise Type",
      controlType: "autocomplete",
      placeHolder: "Select Level",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "levelWiseTypeDetail",
      dataKey: "levelWiseType",
      isRootLevelKey: true,
      isMandatory: false,
    },
  ],

  tableColumnsToFilter: [
    {
      columnDisplayName: "Offer Name",
      columnKeyName: "name",
      isChecked: true,
    },
    {
      columnDisplayName: "From Date",
      columnKeyName: "fromDate",
      isChecked: true,
    },
    {
      columnDisplayName: "To Date",
      columnKeyName: "toDate",
      isChecked: true,
    },
    {
      columnDisplayName: "Offer For",
      columnKeyName: "offerFor",
      isChecked: true,
    },
    {
      columnDisplayName: "Status",
      columnKeyName: "isActive",
      isChecked: true,
    },
  ],

  tableColumnsToFilterFutureAndPast: [
    {
      columnDisplayName: "Offer Name",
      columnKeyName: "name",
      isChecked: true,
    },
    {
      columnDisplayName: "From Date",
      columnKeyName: "fromDate",
      isChecked: true,
    },
    {
      columnDisplayName: "To Date",
      columnKeyName: "toDate",
      isChecked: true,
    },
    {
      columnDisplayName: "Offer For",
      columnKeyName: "offerFor",
      isChecked: true,
    },
  ],
};
