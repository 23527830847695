import React from "react";
import { connect } from "react-redux";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import { talukaJson } from "../../../DynamicFormsJson/MastersJSON/taluka";
import { getDistrictUpdated } from "../../../Slice/district.slice";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import { serverMsg, noInternetMsg } from "../../../config/messageconstant";
import AssignTalukaToSales from "./AssignTalukaToSales";
import PropTypes from "prop-types";
import LandingScreenHeader from "../../../components/Comman/LandingScreenHeader";
import { Box, Paper, Tab, Tabs, Typography } from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import { compose } from "@reduxjs/toolkit";
import { withRouter } from "../../../components/withRouter";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

class StateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,

      dynamicMasterData: {
        districtDetail: this.props.districtList?.district,
      },
      formData: {},
      rowData: {},
    };
  }

  a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  handledChange = (e, newValue) => {
    this.setState({
      tabValue: newValue,
    });
  };
  componentWillReceiveProps(nextProps) {
    if (this.props.districtList !== nextProps.districtList) {
      if (
        this.props.districtList?.district !== nextProps.districtList?.district
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            districtDetail: nextProps.districtList?.district,
          },
        });
      }
    }
  }
  async componentDidMount() {
    const { getDistrictUpdated, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getDistrictUpdated().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  onCancel = () => {
    this.props.navigate(-1);
  };

  getFormData = (rowData) => {
    this.setState({ rowData: rowData });
    this.props.navigate(talukaJson.formPath + "/" + rowData.data.id);
    this.setState({
      tabValue: 1,
    });
  };
  setDataToForm = (data) => {
    this.setState({ formData: data });
  };

  render() {
    const { dynamicMasterData, tabValue, formData, rowData } = this.state;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            pt: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <LandingScreenHeader
            screenTitle={talukaJson.screenTitle}
            showTable={false}
            showBackButton={true}
            showAddButton={false}
            onCancel={this.onCancel}
          />
          <Tabs
            onChange={this.handledChange}
            value={tabValue}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label={"Form "} {...this.a11yProps(0)} />

            <Tab label={"Assign Taluka "} {...this.a11yProps(1)} />
          </Tabs>
          <SwipeableViews index={tabValue} onChangeIndex={this.handleChange}>
            <TabPanel value={tabValue} index={0}>
              <DynamicForm
                padding={false}
                paddingTop={false}
                formPath={talukaJson.formPath}
                getListById={this.getListById}
                screenTitle={talukaJson.screenTitle}
                fieldMeta={talukaJson.fieldMeta}
                dynamicMasterData={dynamicMasterData}
                apiBaseURL={talukaJson.apiBaseURL}
                showSaveNextBtn={talukaJson.showSaveNextBtn}
                isEditURL={talukaJson.isEditURL}
                showTitleAndBack={false}
                showSaveBtn={false}
                getFormData={this.getFormData}
                getFormDataStatic={true}
                getDataForm={true}
                setDataToForm={this.setDataToForm}
                isLog={true}
                type={1}
              />
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
              <AssignTalukaToSales
                padding={false}
                paddingTop={false}
                formPath={talukaJson.formPath}
                getListById={this.getListById}
                screenTitle={talukaJson.screenTitle}
                fieldMeta={talukaJson.fieldMeta}
                dynamicMasterData={dynamicMasterData}
                apiBaseURL={talukaJson.apiBaseURL}
                showSaveNextBtn={talukaJson.showSaveNextBtn}
                isEditURL={talukaJson.isEditURL}
                showTitleAndBack={false}
                rowData={rowData}
              />
            </TabPanel>
          </SwipeableViews>
        </Paper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  districtList: state.district,
});
const mapDispatchToProps = {
  getDistrictUpdated,
  showLoador,
  showNotification,
};
// export default connect(mapStateToProps, mapDispatchToProps)(StateForm);
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(StateForm);
