import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import moment from "moment";
import dayjs from "dayjs";
import { apiGet } from "../utils/api_service";
const initialState = {
  audit: [],
  auditQuestion: [],
  auditHeadById: [],
  auditHeadTransaction: [],
  surveyPendingDetails: [],
  auditOutletTransaction: {},
};
let URL = endpoints.audit;
let URL2 = endpoints.auditQuestion;
let URL3 = endpoints.auditTransaction;
const auditSlice = createSlice({
  name: "audit",
  initialState,
  reducers: {
    auditSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        audit: row,
      };
    },


    auditOutletTransactionSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        auditOutletTransaction: row,
      };
    },

    auditQestionSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        auditQuestion: row,
      };
    },

    auditHeadByIdSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        auditHeadById: row,
      };
    },

    auditHeadTransactionSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        auditHeadTransaction: row,
      };
    },


    surveyPendingDetailsSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        surveyPendingDetails: row,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
    resetStateQuestion: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        auditQuestion: [],
      };
    },
  },
});

export const {
  auditSuccess,
  auditQestionSuccess,
  auditHeadByIdSuccess,
  auditOutletTransactionSuccess,
  auditHeadTransactionSuccess,
  surveyPendingDetailsSuccess,
  resetState,
  resetStateQuestion,
} = auditSlice.actions;

export default auditSlice.reducer;

export const getAudit = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL,
    }).then(({ data, success }) => {
      if (success) {
        const dateArr = moment(new Date()).format("DD-MM-YYYY");
        let changedFromDate = "";
        const toDateArr = "";
        let changedToDate = "";
        const { content } = data.data;
        const row = content.map((auditObject, index) => {
          let auditdata = {
            fromDateArr:
              auditObject.fromDate === null
                ? ""
                : auditObject.fromDate?.split("-"),
            toDateArr:
              auditObject.toDate === null ? "" : auditObject.toDate?.split("-"),
            index: index + 1,
            id: auditObject.id === null ? "" : auditObject.id,
            name: auditObject.name === null ? "" : auditObject.name,
            isAuditSurvey:
              auditObject.isAuditSurvey === null
                ? ""
                : auditObject.isAuditSurvey == 1
                  ? "Survey" : auditObject.isAuditSurvey == 2 ? "Area Exploration Questionary"
                    : "Audit",
            isAuditSurveyCond:
              auditObject.isAuditSurvey == 0 || auditObject.isAuditSurvey == 2
                ? 0
                : dayjs(auditObject.toDate, "DD-MM-YYYY").isAfter(dayjs(), "day")
                  ? 0
                  : 1,
            remark: auditObject.remark === null ? "" : auditObject.remark,
            whoCanTake:
              auditObject.whoCanTake === null ? "" : auditObject.whoCanTake,
            type:
              auditObject.type !== null && auditObject.isAuditSurvey !== null
                ? (auditObject.isAuditSurvey == 2 ? "-"
                  : (auditObject.type == 1 ? "StakeHolder" : "Outlet"))
                : "",
            stockStakeHolderTypeId:
              auditObject.stockStakeHolderTypeId === null
                ? ""
                : auditObject.stockStakeHolderTypeId,
            isActive: auditObject.isActive === null ? "" : auditObject.isActive,
            areaIds: auditObject.areaIds === null ? "" : auditObject.areaIds,
            beatIds: auditObject.beatIds === null ? "" : auditObject.beatIds,
            delStatus:
              auditObject.delStatus === null ? "" : auditObject.delStatus,
            editDelConditionStatus:
              auditObject.isAuditSurvey == 0 || auditObject.isAuditSurvey == 2
                ? 1
                : dayjs(auditObject.toDate, "DD-MM-YYYY").isAfter(dayjs(), "day")
                  ? 1
                  : 0,


            fromDate: auditObject.fromDate === null ? "" : auditObject.fromDate,
            toDate: auditObject.toDate === null ? "" : auditObject.toDate,
            outletIds:
              auditObject.outletIds === null ? "" : auditObject.outletIds,
            insertDateTime:
              auditObject.insertDateTime === null
                ? ""
                : auditObject.insertDateTime,
          };
          return auditdata;
        });
        dispatch(auditSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getAuditQestion =
  ({ id }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL2 + "/by-audit-head?auditHeadId=" + id,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((auditQuestionObject, index) => {
              let auditQuestiondata = {
                index: index + 1,
                id:
                  auditQuestionObject.auditHead !== null &&
                    auditQuestionObject.auditHead.id
                    ? auditQuestionObject.auditHead.id
                    : "",
                questionId:
                  auditQuestionObject.id === null ? "" : auditQuestionObject.id,
                question:
                  auditQuestionObject.question === null
                    ? ""
                    : auditQuestionObject.question,
                optionTypeName:
                  auditQuestionObject.optionType === null
                    ? ""
                    : auditQuestionObject.optionType == "0"
                      ? "Description"
                      : auditQuestionObject.optionType == "1"
                        ? "Optional"
                        : "Multiselect",
                optionType:
                  auditQuestionObject.optionType === null
                    ? ""
                    : auditQuestionObject.optionType,
                points:
                  auditQuestionObject.points === null
                    ? ""
                    : auditQuestionObject.points == 0 ? "-" : auditQuestionObject.points,
                orderNo:
                  auditQuestionObject.orderNo === null
                    ? ""
                    : auditQuestionObject.orderNo,
                remark:
                  auditQuestionObject.remark === null
                    ? ""
                    : auditQuestionObject.remark,
                remarkMandatory:
                  auditQuestionObject.remarkMandatory === null
                    ? ""
                    : auditQuestionObject.remarkMandatory,
                imageUpload:
                  auditQuestionObject.imageUpload === null
                    ? ""
                    : auditQuestionObject.imageUpload,
                auditQuestionOption:
                  auditQuestionObject.auditQuestionOption === null
                    ? ""
                    : auditQuestionObject.auditQuestionOption.map(
                      (optionData, indexData) => {
                        return { index: indexData + 1, ...optionData };
                      }
                    ),
              };
              return auditQuestiondata;
            });
            dispatch(auditQestionSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };


export const getAuditHeadById =
  ({ auditHeadId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/survey-done-remain-count?auditHeadId=" + auditHeadId,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((auditHeadByIdObj, index) => {
              let auditQuestiondata = {
                index: index + 1,
                id:
                  auditHeadByIdObj.auditHead !== null
                    ? auditHeadByIdObj.id
                    : "",
                areaId:
                  auditHeadByIdObj.areaId === null ? "" : auditHeadByIdObj.areaId,
                areaName: auditHeadByIdObj.areaName === null ? "" : auditHeadByIdObj.areaName,
                auditHeadId:
                  auditHeadByIdObj.auditHeadId === null
                    ? ""
                    : auditHeadByIdObj.auditHeadId,
                surveyDoneCount:
                  auditHeadByIdObj.surveyDoneCount === null
                    ? ""
                    : auditHeadByIdObj.surveyDoneCount,
                surveyRemainCount:
                  auditHeadByIdObj.surveyRemainCount === null
                    ? ""
                    : auditHeadByIdObj.surveyRemainCount,


              };
              return auditQuestiondata;
            });
            dispatch(auditHeadByIdSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };


export const setAuditQuestion =
  ({ row }) =>
    async (dispatch) => {
      dispatch(auditQestionSuccess({ row }));
    };

export const resetDataAddQuestion = () => async (dispatch) => {
  try {
    dispatch(resetStateQuestion());
  } catch (e) {
    return console.error(e.message);
  }
};


export const getAuditTransactionData = ({ auditHeadId, areaId }) => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL3 + "/by-audit-head-and-area?auditHeadId=" + auditHeadId + "&areaId=" + areaId,
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((auditTransactionObj, index) => {
          let auditTransactionData = {
            index: index + 1,
            id:
              auditTransactionObj.id === null
                ? ""
                : auditTransactionObj.id,
            auditHeadId:
              auditTransactionObj.auditHead === null ? "" : auditTransactionObj.auditHead.id,
            auditHeadName: auditTransactionObj.auditHead === null ? "" : auditTransactionObj.auditHead.name,
            auditHeadRemark: auditTransactionObj.auditHead === null ? "" : auditTransactionObj.auditHead.remark,
            auditHeadIsAuditSurvey: auditTransactionObj.auditHead === null ? "" : auditTransactionObj.auditHead.isAuditSurvey,
            auditHeadFromDate: auditTransactionObj.auditHead === null ? "" : auditTransactionObj.auditHead.auditHead,
            auditHeadToDate: auditTransactionObj.auditHead === null ? "" : auditTransactionObj.auditHead.toDate,
            type: auditTransactionObj.type === null ? "" : auditTransactionObj.type,
            date: auditTransactionObj.date === null ? "" : auditTransactionObj.date,
            totalPoint: auditTransactionObj.totalPoint === null ? "" : auditTransactionObj.totalPoint,
            totalScore: auditTransactionObj.totalScore === null ? "" : auditTransactionObj.totalScore,
            auditHeadStockholderIdOutletId: auditTransactionObj.stockholderIdOutletId === null ? ""
              : auditTransactionObj.stockholderIdOutletId,
            outletFirmName: auditTransactionObj.outletFirmName === null ? "" : auditTransactionObj.outletFirmName,
            outletOwnerName: auditTransactionObj.outletOwnerName === null ? "" : auditTransactionObj.outletOwnerName,
            status: auditTransactionObj.status === null ? "" : auditTransactionObj.status,
            isAuditSurvey: auditTransactionObj.isAuditSurvey === null ? "" : auditTransactionObj.isAuditSurvey,
            auditTransactionDetails: auditTransactionObj.dtoAuditTransactionDetails === null ? "" : auditTransactionObj.dtoAuditTransactionDetails.map((obj, index) => {
              let auditTransactionDetailsData = {
                index: index + 1,
                auditTransactionDetailsId: obj.id === null ? "" : obj.id,
                auditQuestionId: obj.auditQuestion === null ? "" : obj.auditQuestion.id,
                question: obj.auditQuestion === null ? "" : obj.auditQuestion.question,
                auditQuestionPoint: obj.auditQuestion === null ? "" : obj.auditQuestion.points,
                auditQuestionOptionType: obj.auditQuestion === null ? "" : obj.auditQuestion.optionType,
                auditQuestionRmark: obj.auditQuestion === null ? "" : obj.auditQuestion.remark,
                auditQuestionOrderNo: obj.auditQuestion === null ? "" : obj.auditQuestion.orderNo,
                auditTransactionTotalPoints: obj.totalPoint === null ? "" : obj.totalPoint,
                auditTransactionQueScore: obj.queScore === null ? "" : obj.queScore,
                auditQuestionOptionValue: obj.auditQuestionOptionValue === null ? "" : obj.auditQuestionOptionValue,
                answer: obj.auditQuestion && obj.auditQuestion.optionType === null ? ""
                  : obj.auditQuestion.optionType == 0 ? obj.auditQuestionOptionValue
                    : obj.auditQuestion.auditQuestionOption.find((row) => row.id === obj.auditQuestionOptionValue)?.optionTitle || "",
                answerForMultiSelect: obj.auditQuestion && obj.auditQuestion.auditQuestionOption === null ? ""
                  : obj.auditQuestion.auditQuestionOption.map((object) => {
                    const auditQuestionOptionValueList = obj.auditQuestionOptionValue.split(",")
                    const matchedValue = auditQuestionOptionValueList.filter((row) => row === object.id);
                    return matchedValue.length !== 0 ? object.optionTitle : '';
                  }),
                auditTransactionDetailsRemark: obj.remark === null ? "" : obj.remark,
                auditQuestionOption: obj.auditQuestion && obj.auditQuestion.auditQuestionOption === null ? "" :
                  obj.auditQuestion.auditQuestionOption.map((optionData, indexData) => {
                    return { index: indexData + 1, ...optionData };
                  }
                  ),
              };
              return auditTransactionDetailsData;
            }),
          };
          return auditTransactionData;
        });
        dispatch(auditHeadTransactionSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};





export const getOutletAuditTransactionData = ({ auditHeadId }) => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/outlet-audit-transactions?auditHeadId=" + auditHeadId,
    }).then(({ data, success }) => {
      if (success) {
        let auditOutletTransactionData = {



          auditQuestions: data.data.auditQuestions.map((auditQuestionObj, indexOne) => {
            let auditQuestionsData = {
              index: indexOne + 1,
              auditQuestionId: auditQuestionObj.id !== null ? auditQuestionObj.id : "",
              auditQuestion: auditQuestionObj.question !== null ? auditQuestionObj.question : "",
              auditQuestionPoint: auditQuestionObj.points !== null ? auditQuestionObj.points : "",
              auditQuestionOptionType: auditQuestionObj.optionType !== null ? auditQuestionObj.optionType : "",
              auditQuestionImageUpload: auditQuestionObj.imageUpload !== null ? auditQuestionObj.imageUpload : "",
              auditQuestionRemark: auditQuestionObj.remark !== null ? auditQuestionObj.remark : "",
              auditQuestionRemarkMandatory: auditQuestionObj.remarkMandatory !== null ? auditQuestionObj.remarkMandatory : "",
              auditQuestionOrderNo: auditQuestionObj.orderNo !== null ? auditQuestionObj.orderNo : "",
              auditQuestionOption: auditQuestionObj.auditQuestionOption === null ? "" : auditQuestionObj.auditQuestionOption.map((auditOptionObj, auditOptionIndex) => {
                let auditQuestionOptionData = {
                  index: auditOptionIndex + 1,
                  auditQuestionOptionId: auditOptionObj.id !== null ? auditOptionObj.id : "",
                  auditQuestionId: auditOptionObj.auditQuestionId !== null ? auditOptionObj.auditQuestionId : "",
                  auditQuestionOptionTitle: auditOptionObj.optionTitle !== null ? auditOptionObj.optionTitle : "",
                  auditQuestionOptionPoints: auditOptionObj.points !== null ? auditOptionObj.points : "",
                  auditQuestionOptionOrderNo: auditOptionObj.orderNo !== null ? auditOptionObj.orderNo : "",
                }
                return auditQuestionOptionData;
              })
            }
            return auditQuestionsData;
          }),




          outlets: data.data.outlets.map((outletsObj, indexTwo) => {
            let outletsData = {
              index: indexTwo + 1,
              outletId: outletsObj.id !== null ? outletsObj.id : "",
              firmName: outletsObj.firmName !== null ? outletsObj.firmName : "",
              ownerName: outletsObj.ownerName !== null ? outletsObj.ownerName : "",
              villageCity: outletsObj.villageCity !== null ? outletsObj.villageCity : "",
              beatName: outletsObj.beatName !== null ? outletsObj.beatName : "",
              outletCode: outletsObj.outletCode !== null ? outletsObj.outletCode : "",
              stateName: outletsObj.stateName !== null ? outletsObj.stateName : "",
              mobileNo: outletsObj.mobileNo !== null ? outletsObj.mobileNo : "",
            }
            return outletsData;
          }),

          auditTransactions: data.data.auditTransactions.map((auditTransactionsObj, indexThree) => {
            let auditTransactionsJson = {
              index: indexThree + 1,
              auditTransactionId: auditTransactionsObj.id !== null ? auditTransactionsObj.id : "",
              auditHeadId: auditTransactionsObj.auditHead !== null ? auditTransactionsObj.auditHead.id : "",
              auditTransactionType: auditTransactionsObj.type !== null ? auditTransactionsObj.type : "",
              stockholderIdOutletId: auditTransactionsObj.stockholderIdOutletId !== null ? auditTransactionsObj.stockholderIdOutletId : "",
              date: auditTransactionsObj.date !== null ? auditTransactionsObj.date : "",
              totalPoint: auditTransactionsObj.totalPoint !== null ? auditTransactionsObj.totalPoint : "",
              totalScore: auditTransactionsObj.totalScore !== null ? auditTransactionsObj.totalScore : "",
              status: auditTransactionsObj.status !== null ? auditTransactionsObj.status : "",
              isAuditSurvey: auditTransactionsObj.isAuditSurvey !== null ? auditTransactionsObj.isAuditSurvey : "",

              auditTransactionDetails: auditTransactionsObj.auditTransactionDetails === null ? ""
                : auditTransactionsObj.auditTransactionDetails.map((txnDetails, index) => {

                  let auditTransactionDetailsJson = {
                    index: index + 1,
                    auditTransactionDetailsId: txnDetails.id !== null ? txnDetails.id : "",
                    auditTransactionDetailsAuditQuestionObj: txnDetails.auditQuestion !== null ? txnDetails.auditQuestion : "",
                    auditTransactionDetailsAuditQuestionId: txnDetails.auditQuestion !== null ? txnDetails.auditQuestion.id : "",
                    auditTransactionDetailsAuditQuestionAuditHeadId: txnDetails.auditQuestion !== null && txnDetails.auditQuestion.auditHead !== null
                      ? txnDetails.auditQuestion.auditHead.id : "",
                    auditTransactionAuditQuestion: txnDetails.auditQuestion !== null ? txnDetails.auditQuestion.question : "",
                    auditTransactionAuditQuestionPoints: txnDetails.auditQuestion !== null ? txnDetails.auditQuestion.points : "",
                    auditTransactionAuditQuestionOptionType: txnDetails.auditQuestion !== null ? txnDetails.auditQuestion.optionType : "",
                    auditTransactionAuditQuestionImageUpload: txnDetails.auditQuestion !== null ? txnDetails.auditQuestion.imageUpload : "",
                    auditTransactionAuditQuestionRemark: txnDetails.auditQuestion !== null ? txnDetails.auditQuestion.remark : "",
                    auditTransactionAuditQuestionOrderNo: txnDetails.auditQuestion !== null ? txnDetails.auditQuestion.orderNo : "",
                    auditQuestionOption: txnDetails.auditQuestion !== null
                      ? (txnDetails.auditQuestion.auditQuestionOption === null ? "" :
                        txnDetails.auditQuestion.auditQuestionOption.map((auditQuestionOptionObj, index) => {
                          return {
                            index: index + 1,
                            auditQuestionOptionId: auditQuestionOptionObj.id !== null ? auditQuestionOptionObj.id : "",
                            auditQuestionOptionTitle: auditQuestionOptionObj.optionTitle !== null ? auditQuestionOptionObj.optionTitle : "",
                            auditQuestionOptionPoints: auditQuestionOptionObj.points !== null ? auditQuestionOptionObj.points : "",
                            auditQuestionOptionOrderNo: auditQuestionOptionObj.orderNo !== null ? auditQuestionOptionObj.orderNo : "",
                          };
                        })
                      ) : "",
                    totalPoint: txnDetails.totalPoint !== null ? txnDetails.totalPoint : "",
                    queScore: txnDetails.queScore !== null ? txnDetails.queScore : "",
                    auditQuestionOptionValue: txnDetails.auditQuestionOptionValue !== null ? txnDetails.auditQuestionOptionValue : "",
                    images: txnDetails.images !== null ? txnDetails.images : "",
                    remark: txnDetails.remark !== null ? txnDetails.remark : "",
                  }
                  return auditTransactionDetailsJson;
                })
            }
            return auditTransactionsJson;
          }),
        };
        dispatch(auditOutletTransactionSuccess({ row: auditOutletTransactionData }));
        return { response: auditOutletTransactionData, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};



export const setOutletAuditTransactionData =
  ({ row }) =>
    async (dispatch) => {
      dispatch(auditOutletTransactionSuccess({ row }));
    };

export const getSurveyPendingDetails = ({ auditHeadId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/survey-remain-outlets?auditHeadId=" + auditHeadId,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((surveyPendingObj, index) => {
            let auditQuestiondata = {
              index: index + 1,
              id:
                surveyPendingObj.id !== null
                  ? surveyPendingObj.id
                  : "",
              firmName:
                surveyPendingObj.firmName === null ? "" : surveyPendingObj.firmName,
              ownerName: surveyPendingObj.ownerName === null ? "" : surveyPendingObj.ownerName,
              areaName:
                surveyPendingObj.areaName === null
                  ? ""
                  : surveyPendingObj.areaName,
              outLetTypeName:
                surveyPendingObj.outLetTypeName === null
                  ? ""
                  : surveyPendingObj.outLetTypeName,
              outletCode:
                surveyPendingObj.outletCode === null
                  ? ""
                  : surveyPendingObj.outletCode,


            };
            return auditQuestiondata;
          });
          dispatch(surveyPendingDetailsSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };