import { Grid, Paper } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { ButtonCompo } from "../../components/Comman/Button";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import endpoint from "../../config/endpoints";
import {
  noInternetMsg,
  saveFailedMsg,
  savemsg,
  saveWarningMsg,
  serverMsg,
} from "../../config/messageconstant";
import { getStakeholderTypeListForAllowanceConfig } from "../../Slice/stakeholderAllowance.slice";
import { apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import StakeholderTypeAllowanceConfigTable from "./StakeholderTypeAllowanceConfigTable";
const RUCreationAndAssignationForm = ({
  showLoador,
  showNotification,
  getStakeholderTypeListForAllowanceConfig,
}) => {
  const [rowList, setRowList] = useState([]);
  const [formErrors, setFormErrors] = React.useState({});

  useEffect(() => {
    if (navigator.onLine) {
      showLoador({ loador: true });
      getStakeholderTypeListForAllowanceConfig().then(
        ({ success, response }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            setRowList(response);
          }
        }
      );
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }, []);

  const handleRowChange = (id, field, value) => {

    const updatedRows = rowList.map((row) => {
      if (row.id === id) {
        return {
          ...row,
          [field]: value,
        };
      }
      return row;
    });

    setRowList(updatedRows);
  };

  const validateRow = (row) => {
    let errors = {};

    if (!row.daLocal || row.daLocal.toString().trim() === "") {
      errors.daLocalError = "DA is required.";
    }

    if (!row.daOutstation || row.daOutstation.toString().trim() === "") {
      errors.daOutstationError = "TA is required.";
    }

    if (!row.ratePerKm || row.ratePerKm.toString().trim() === "") {
      errors.ratePerKmError = "Rate/KM is required.";
    }

    return errors;
  };

  const saveAllowanceConfig = () => {
    let hasErrors = false;
    const newFormErrors = {};

    rowList.forEach((row) => {
      const errors = validateRow(row);
      if (Object.keys(errors).length > 0) {
        hasErrors = true;
        newFormErrors[row.id] = errors;
      }
    });

    if (hasErrors) {
      setFormErrors(newFormErrors);
      return;
    } else {
      setFormErrors({});
    }

    if (!navigator.onLine) {
      showNotification({ msg: noInternetMsg, severity: "error" });
      return;
    }

    swal({
      title: "Are you sure?",
      text: saveWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        showLoador({ loador: true });
        apiPost({
          url: endpoint.stakeholderAllowance + "/save-list",
          postBody: rowList,
        }).then(({ success, data }) => {
          showLoador({ loador: false });
          if (success) {
            window.location.reload();
            showNotification({ msg: savemsg });
          } else {
            showNotification({ msg: saveFailedMsg, severity: "error" });
          }
        });
      }
    });
  };


  return (
    <>
      <Paper
        sx={{
          p: 2,
          borderRadius: 2,
        }}
        elevation={0}
      >
        <LandingScreenHeader
          screenTitle={"Stakeholder Type Allowance Configuration"}
          showTable={false}
          showBackButton={false}
          showAddButton={false}
        />

        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          container
          justifyContent="space-between"
        >
          <StakeholderTypeAllowanceConfigTable
            rowList={rowList}
            handleRowChange={handleRowChange}
            formErrors={formErrors}
          />
        </Grid>

        <DialogActions>
          <Grid
            item
            justifyContent={"flex-end"}
            gap={2}
            xs={12}
            sm={12}
            md={1}
            flexDirection={"row"}
            display={"flex"}
          >
            <Grid justifyContent={"flex-end"} item>
              <ButtonCompo
                size="medium"
                onClick={saveAllowanceConfig}
                variant="contained"
                name="Submit"
              />
            </Grid>
          </Grid>
        </DialogActions>
      </Paper>
    </>
  );
};
const mapStateToProps = (state) => ({
  stakeholderTypeListForAllowanceConfig: state.stakeholderAllowance,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getStakeholderTypeListForAllowanceConfig,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RUCreationAndAssignationForm);
