import { Grid, Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { outletOpeningJson } from "../../DynamicFormsJson/Transaction/outletOpeningJson";
import { getAreaByTalukaPostBodyUpdated } from "../../Slice/area.slice";
import { getDistrictByZone } from "../../Slice/district.slice";
import { getRegionNew } from "../../Slice/region.slice";
import { getStateByRegion } from "../../Slice/state.slice";
import { getStockStakeHolderType } from "../../Slice/stockStakeHolderType.slice";
import { getTalukaByDistrictPost } from "../../Slice/taluka.slice";
import { getZoneByState } from "../../Slice/zone.slice";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import {
  noInternetMsg,
  saveFailedMsg,
  savemsg,
  serverMsg,
} from "../../config/messageconstant";
import { showLoador, showNotification } from "../Landing/Landing.slice";

import endpoint from "../../config/endpoints";
import { apiPost } from "../../utils/api_service";
import { getStockholderByAreaIdsForFilterPost } from "../../Slice/StackHolder.slice";
import { getAreaByUserType } from "../../Slice/area.slice";
import { getBeatByArea } from "../../Slice/beat.slice";
import { getUpdatedBeatsByAreaIds } from "../../Slice/beat.slice";
import { getOutletByBeatIdsPost } from "../../Slice/outlet.slice";
import OpeningManageOutletTable from "./OpeningManageOutletTable";


class OpeningManageOutletForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      dynamicMasterData: {
        areaDetail: this.props.areaList.area,
        beatDetail: this.props.beatList?.beat,
      },
    };
  }


    componentWillReceiveProps(nextProps) {

   

        if (this.props.beatList !== nextProps.beatList) {
            if (this.props.beatList?.beat !== nextProps.beatList?.beat) {
              this.setState({
                dynamicMasterData: {
                  ...this.state.dynamicMasterData,
                  beatDetail: nextProps.beatList?.beat,
                },
              });
            }
          }

        if (this.props.areaList !== nextProps.areaList) {
            if (this.props.areaList?.area !== nextProps.areaList?.area) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        areaDetail: nextProps.areaList?.area,
                    },
                });
            }
        }

    }



  async componentDidMount() {
    const { getAreaByUserType, showLoador, showNotification } =
        this.props;
    if (navigator.onLine) {
        showLoador({ loador: true });
        await getAreaByUserType().then(({ success }) => {
            showLoador({ loador: false });
            if (!success) {
                showNotification({ msg: serverMsg, severity: "error" });
            }
        });

    } else {
        this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
}




 getListById = async (data) => {
    const {
      getUpdatedBeatsByAreaIds,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      console.log(data);
    
      if (data.areaIds && data.areaIds != "") {
        let outletJson = {
          areaIds: data.areaIds,
        }
        showLoador({ loador: true });
        await getUpdatedBeatsByAreaIds({ areaIds: outletJson }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onSave = async (data) => {
    console.log(data)
    const {
      getOutletByBeatIdsPost,
      showLoador,
      showNotification,
    } = this.props;


    if (navigator.onLine) {
        let beatJson = {
            beatIds: Array.isArray(data.beatIds)
              ? data.beatIds 
              : typeof data.beatIds === "string"
              ? [data.beatIds] 
              : typeof data.beatIds === "object" && data.beatIds.id
              ? [data.beatIds.id] 
              : [], 
          };
        
      showLoador({ loador: true });
      await getOutletByBeatIdsPost({
        beatIds: beatJson,
    }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  passTheData = (data) => {
    const { showLoador, showNotification } = this.props;

    console.log("data", data);
    swal({
      title: "Are you sure?",
      text: "Do you want to submit this data?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willSave) => {
      if (willSave) {
        if (navigator.onLine) {
          showLoador({ loador: true });
          apiPost({
            url: endpoint.outletOpening + "/save-list",
            postBody: data,
          }).then(({ data, success }) => {
            showLoador({ loador: false });
            if (success) {
              showNotification({ msg: savemsg });
              window.location.replace("/manage-opening-outlet-list");
            } else {
              showNotification({ msg: saveFailedMsg, severity: "error" });
            }
            return success;
          });
        } else {
          this.props.showNotification({
            msg: noInternetMsg,
            severity: "error",
          });
        }
      }
    });
  };

  render() {
    const { dynamicMasterData } = this.state;
    const { stackHolderByAreaList,outletList } = this.props;
    return (
      <>
        <Paper sx={{ padding: 2 }} elevation={0}>
          <DynamicFormWithoutSave
            apiBaseURL={outletOpeningJson.formPath}
            getListById={this.getListById}
            screenTitle={outletOpeningJson.screenTitle}
            fieldMeta={outletOpeningJson.fieldMetaForAudit}
            dynamicMasterData={dynamicMasterData}
            showSaveNextBtn={outletOpeningJson.showSaveNextBtn}
            showSaveBtnMain={false}
            showSaveBtn={true}
            saveBtnText={"Search"}
            onSave={this.onSave}
            showTitle={true}
            showBackToList={true}
          />
          <Grid sx={{ marginTop: 2 }}>
            <OpeningManageOutletTable
              passTheData={this.passTheData}
              initialData={outletList?.outlets}
            />
          </Grid>
        </Paper>
      </>
    );  
  }
}
const mapStateToProps = (state) => ({
  stackHolderByAreaList: state.stockholder,
  stakeHolderTypeList: state.stockStakeHolderType,
  regionList: state.region,
  stateList: state.states,
  zoneList: state.zone,
  districtList: state.district,
  talukaList: state.taluka,
  areaList: state.area,
  beatList: state.beat,
  outletList: state.outlet,

});
const mapDispatchToProps = {
  getStockStakeHolderType,
  getAreaByTalukaPostBodyUpdated,
  getTalukaByDistrictPost,
  getDistrictByZone,
  getZoneByState,
  getStateByRegion,
  getRegionNew,
  showLoador,
  showNotification,
  getStockholderByAreaIdsForFilterPost,
  getAreaByUserType,
  getBeatByArea,
  getUpdatedBeatsByAreaIds,
  getOutletByBeatIdsPost
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(OpeningManageOutletForm);
