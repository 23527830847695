import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import { dayWiseBitAssignColumns } from "../../tableColumns/table-columns";
import { dayWiseBeatAssignJson } from "../../DynamicFormsJson/Transaction/DayWiseBeatAssign";
import {
  getUpdatedDayWiseBeatFromAreas,
  setDayWiseBitAssign,
} from "../../Slice/dayWiseBitAssign.slice";
import { getRegionNew } from "../../Slice/region.slice";
import { getStateByRegion } from "../../Slice/state.slice";
import { getZoneByState } from "../../Slice/zone.slice";
import { getDistrictByZone } from "../../Slice/district.slice";
import { getTalukaByDistrict } from "../../Slice/taluka.slice";
import { getAreaByTalukaPostBody, getAreaByUserType } from "../../Slice/area.slice";
import { serverMsg, noInternetMsg } from "../../config/messageconstant";
import { showLoador, showNotification } from "../Landing/Landing.slice";
class DayWiseBeatAssignTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        regionDetail: this.props.regionList?.region,
        stateDetail: this.props.stateList?.stateByRegion,
        zoneDetail: this.props.zoneList?.zoneByState,
        districtDetail: this.props.districtList?.districtByZone,
        talukaDetail: this.props.talukaList?.talukaByDistrict,
        areaDetail: this.props.areaList?.areaByTaluka,
        areaByUserDetail: this.props.areaList?.area,
      },
      adminFlag: false,

    };
  }
  async componentDidMount() {
    const { getRegionNew, setDayWiseBitAssign, getAreaByUserType, showLoador, showNotification } = this.props;
    const userTypeId = localStorage.getItem("userTypeId");

    if (navigator.onLine) {

      setDayWiseBitAssign({ row: [] })

      if (userTypeId == 1 || userTypeId == 6) {
        showLoador({ loador: true });
        getRegionNew().then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            this.setState({
              adminFlag: true,
            });
          }
        });
      } else {
        showLoador({ loador: true });
        getAreaByUserType().then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }



    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.regionList !== nextProps.regionList) {
      if (this.props.regionList?.region !== nextProps.regionList?.region) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            regionDetail: nextProps.regionList?.region,
          },
        });
      }
    }
    if (this.props.stateList !== nextProps.stateList) {
      if (
        this.props.stateList?.stateByRegion !==
        nextProps.stateList?.stateByRegion
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stateDetail: nextProps.stateList?.stateByRegion,
          },
        });
      }
    }
    if (this.props.zoneList !== nextProps.zoneList) {
      if (
        this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            zoneDetail: nextProps.zoneList?.zoneByState,
          },
        });
      }
    }
    if (this.props.districtList !== nextProps.districtList) {
      if (
        this.props.districtList?.districtByZone !==
        nextProps.districtList?.districtByZone
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            districtDetail: nextProps.districtList?.districtByZone,
          },
        });
      }
    }
    if (this.props.talukaList !== nextProps.talukaList) {
      if (
        this.props.talukaList?.talukaByDistrict !==
        nextProps.talukaList?.talukaByDistrict
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            talukaDetail: nextProps.talukaList?.talukaByDistrict,
          },
        });
      }
    }
    if (this.props.areaList !== nextProps.areaList) {
      if (
        this.props.areaList?.areaByTaluka !== nextProps.areaList?.areaByTaluka
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.areaByTaluka,
          },
        });
      }
    }
    if (this.props.areaList !== nextProps.areaList) {
      if (
        this.props.areaList?.area !== nextProps.areaList?.area
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaByUserDetail: nextProps.areaList?.area,
          },
        });
      }
    }
  }

  onSearchData = async (data) => {
    const { getUpdatedDayWiseBeatFromAreas, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      let beatByAreasJsonData = {
        areaIds: data.area,
      };


      localStorage.setItem("beatByAreasForDayWiseBeat", JSON.stringify(data.area));

      showLoador({ loador: true });
      await getUpdatedDayWiseBeatFromAreas({ areaIdsData: beatByAreasJsonData }).then(
        ({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        }
      );
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  getListById = async (data) => {
    const {
      getStateByRegion,
      getZoneByState,
      getDistrictByZone,
      getTalukaByDistrict,
      getAreaByTalukaPostBody,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      console.log(data);
      if (data.region && data.region != "") {
        showLoador({ loador: true });
        await getStateByRegion({ regionIds: data.region }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.state && data.state != "") {
        showLoador({ loador: true });
        await getZoneByState({ stateIds: data.state }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.zone && data.zone != "") {
        showLoador({ loador: true });
        await getDistrictByZone({ zoneIds: data.zone }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.district && data.district != "") {
        showLoador({ loador: true });
        await getTalukaByDistrict({ districtIds: data.district }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.taluka && data.taluka != "") {
        let talukaIdsByPost = {
          talukaIds: data.taluka,
        };
        showLoador({ loador: true });
        await getAreaByTalukaPostBody({ talukaIds: talukaIdsByPost }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  render() {
    const { dayWiseBitAssign, setDayWiseBitAssign } = this.props;
    const { dynamicMasterData, adminFlag } = this.state;
    return (
      <>
        <DynamicMainScreen
          showViewButtonInTable={true}
          showDeleteIcon={false}
          dynamicMasterData={dynamicMasterData}
          searchList={adminFlag ? dayWiseBeatAssignJson.searchList : dayWiseBeatAssignJson.searchListForStockHolder}
          onSearchData={this.onSearchData}
          getTableData={setDayWiseBitAssign}
          formPathView={dayWiseBeatAssignJson.formPathView}
          formPath={dayWiseBeatAssignJson.formPath}
          screenTitle={dayWiseBeatAssignJson.screenTitle}
          fieldMeta={dayWiseBeatAssignJson.fieldMeta}
          showPdfDownload={false}
          showExcelDownload={false}
          isActiveURL={dayWiseBeatAssignJson.isActiveURL}
          showAddButton={dayWiseBeatAssignJson.showAddButton}
          tableColumnsToFilter={dayWiseBeatAssignJson.tableColumnsToFilter}
          tableColumns={dayWiseBitAssignColumns}
          tableData={dayWiseBitAssign?.dayWiseBitAssign}
          apiBaseURL={dayWiseBeatAssignJson.apiBaseURL}
          callApi={false}
          getTableDataById={true}
          showPegination={false}
          getListById={this.getListById}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  dayWiseBitAssign: state.dayWiseBitAssign,
  areaList: state.area,
  regionList: state.region,
  stateList: state.states,
  zoneList: state.zone,
  districtList: state.district,
  talukaList: state.taluka,
});
const mapDispatchToProps = {
  getUpdatedDayWiseBeatFromAreas,
  getRegionNew,
  getZoneByState,
  getDistrictByZone,
  getTalukaByDistrict,
  getAreaByTalukaPostBody,
  getStateByRegion,
  getAreaByUserType,
  showLoador,
  showNotification,
  setDayWiseBitAssign,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DayWiseBeatAssignTable);
