import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import DynamicTabwiseTable from "../../components/dynamicscreens/DynamicTabwiseTable";
import { withRouter } from "../../components/withRouter";
import { receivedOrderListJSON } from "../../DynamicFormsJson/Transaction/ReceivedOrderList";

import swal from "sweetalert";
import {
  noInternetMsg,
  serverMsg,
  acceptMsg,
  rejectMsg,
} from "../../config/messageconstant";
import {
  getReceivedOrderListNew,
  getReceivedBillHeaderById,
  getReceivedOrderListByDateUpdated,
} from "../../Slice/receivedOrderList.slice";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import endpoint from "../../config/endpoints";
import { apiGet } from "../../utils/api_service";
import ProductListDialogBox from "./ProductListDialogBox";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { Paper } from "@mui/material";
import FilterPopUp from "./FilterPopUp";
import dayjs from "dayjs";

class ReceivedOrderList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        pendingOrderList: [],
        orderBillForGeneratedList: [],
      },
      openDialogBox: false,
      productList: [],
      ids: "",
      comment: "",
      filterPopUpFlag: false,
      tabList: [
        {
          label: "Pending Order List",
          allyProps: 0,
          showExcelDownload: false,
          columns: [
            {
              name: "index",
              title: "Sr. No.",
              width: "10%",
              alignCenter: "center",
              showInscreen: true,
            },
            {
              title: "Order No.",
              name: "orderNo",
              showInscreen: true,
              alignCenter: "center",
              canSearch: true,
            },
            {
              title: "Order Date",
              name: "orderDate",
              showInscreen: true,
              alignCenter: "center",
              canSearch: true,
            },
            {
              title: "Order Placed Time",
              name: "dayDiff",
              showInscreen: true,
              alignCenter: "center",
              canSearch: true,
            },
            {
              title: "SH Code",
              name: "fromOrderShCode",
              showInscreen: true,
              alignCenter: "center",
              canSearch: true,
            },
            {
              title: "Firm Name",
              name: "fromOrderOwnerName",
              showInscreen: true,
              align: "left",
              canSearch: true,
            },
            {
              title: "State Name",
              name: "fromOrderStateName",
              showInscreen: true,
              align: "left",
              canSearch: true,
            },
            {
              title: "Area Name",
              name: "fromOrderAreaNames",
              showInscreen: true,
              align: "left",
              canSearch: true,
            },
            {
              title: "Total Quantity",
              name: "totalQty",
              showInscreen: true,
              alignCenter: "right",
              canSearch: true,
            },
            {
              title: "CGST",
              name: "cgstAmt",
              alignCenter: "right",
              showInscreen: true,
              canSearch: true,
            },
            {
              title: "SGST",
              name: "sgstAmt",
              alignCenter: "right",
              showInscreen: true,
              canSearch: true,
            },
            {
              title: "IGST",
              name: "igstAmt",
              alignCenter: "right",
              showInscreen: true,
              canSearch: true,
            },
            {
              title: "Taxable Amt",
              name: "taxableAmt",
              alignCenter: "right",
              showInscreen: true,
              canSearch: true,
            },
            {
              title: "Total",
              name: "totalAmt",
              alignCenter: "right",
              showInscreen: true,
              canSearch: true,
            },
            {
              title: "Action",
              name: "action",
              alignCenter: "center",
              showInscreen: true,
            },
          ],
          isActionColActive: true,
          value: "pendingOrderList",
        },
        {
          label: "Order list for Generate Bill",
          allyProps: 1,
          showExcelDownload: false,
          columns: [
            {
              name: "index",
              title: "Sr. No.",
              width: "10%",
              alignCenter: "center",
              showInscreen: true,
            },
            {
              title: "Order No.",
              name: "orderNo",
              showInscreen: true,
              alignCenter: "center",
              canSearch: true,
            },
            {
              title: "Order Date",
              name: "orderDate",
              showInscreen: true,
              alignCenter: "center",
              canSearch: true,
            },
            {
              title: "Order Placed Time",
              name: "dayDiff",
              showInscreen: true,
              alignCenter: "center",
              canSearch: true,
            },
            {
              title: "SH Code",
              name: "fromOrderShCode",
              showInscreen: true,
              alignCenter: "center",
              canSearch: true,
            },
            {
              title: "Firm Name",
              name: "fromOrderOwnerName",
              showInscreen: true,
              alignCenter: "left",
              canSearch: true,
            },
            {
              title: "State Name",
              name: "fromOrderStateName",
              showInscreen: true,
              align: "left",
              canSearch: true,
            },
            {
              title: "Area Name",
              name: "fromOrderAreaNames",
              showInscreen: true,
              align: "left",
              canSearch: true,
            },
            {
              title: "Total Quantity",
              name: "totalQty",
              showInscreen: true,
              alignCenter: "right",
              canSearch: true,
            },
            {
              title: "CGST",
              name: "cgstAmt",
              alignCenter: "right",
              showInscreen: true,
              canSearch: true,
            },
            {
              title: "SGST",
              name: "sgstAmt",
              alignCenter: "right",
              showInscreen: true,
              canSearch: true,
            },
            {
              title: "IGST",
              name: "igstAmt",
              alignCenter: "right",
              showInscreen: true,
              canSearch: true,
            },
            {
              title: "Taxable Amt",
              name: "taxableAmt",
              alignCenter: "right",
              showInscreen: true,
              canSearch: true,
            },
            {
              title: "Total",
              name: "totalAmt",
              alignCenter: "right",
              showInscreen: true,
              canSearch: true,
            },
            // {
            //   title: "Status",
            //   name: "status",
            //   alignCenter: "center",
            //   showInscreen: true,
            // },

            {
              title: "Action",
              name: "action",
              alignCenter: "center",
              showInscreen: true,
              width: 120,
            },
          ],
          isActionColActive: true,
          value: "orderBillForGeneratedList",
        },
      ],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.receivedOrderBillList !== nextProps.receivedOrderBillList) {
      if (
        this.props.receivedOrderBillList?.receivedOrderBill !==
        nextProps.receivedOrderBillList?.receivedOrderBill &&
        Object.keys(nextProps.receivedOrderBillList?.receivedOrderBill)
          .length != 0
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            pendingOrderList:
              nextProps.receivedOrderBillList?.receivedOrderBill.pendingList,
            orderBillForGeneratedList:
              nextProps.receivedOrderBillList?.receivedOrderBill
                .billingOrderList,
          },
        });
      }
    }
  }

  getData = async () => {
    const { showLoador, showNotification, getReceivedOrderListNew } = this.props;
    if (navigator.onLine) {

      showLoador({ loador: true });
      await getReceivedOrderListNew().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };



  componentDidMount() {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {

      if (
        localStorage.getItem("showFilterOnReceivedOrder")
        && localStorage.getItem("showFilterOnReceivedOrder") != 1
      ) {
        this.getData();
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }

  }

  openGenerateBillForm = (rowData) => {
    this.props.navigate(receivedOrderListJSON.formPath + "/" + rowData.id);
  };

  changeHandler = (e) => {
    const { name, value } = e.target;
    if (name === "comment") {
      this.setState({ comment: value });
    }
  };

  acceptOrder = () => {
    const { showLoador, showNotification } = this.props;
    const { ids, comment } = this.state;
    console.log(comment);
    swal({
      text: "Do you want to accept the order",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        if (navigator.onLine) {
          showLoador({ loador: true });
          await apiGet({
            url:
              endpoint.stockStakeholderOrderHeader +
              "/update-status?ids=" +
              ids +
              "&status=2" +
              "&remark=" +
              comment,
          }).then(({ data, success }) => {
            showLoador({ loador: false });
            if (success) {
              showNotification({ msg: acceptMsg });
              this.toggle();
              if (
                localStorage.getItem("showFilterOnReceivedOrder") &&
                localStorage.getItem("showFilterOnReceivedOrder") != 1
              ) {
                this.getData();
              } else {
                this.onSearch(dayjs(), dayjs());
              }
            } else {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          });
        } else {
          showNotification({ msg: noInternetMsg, severity: "error" });
        }
      }
    });
  };

  rejectOrder = (row) => {
    const { showLoador, showNotification } = this.props;
    const { tabList, ids, comment } = this.state;

    const selectedTab = tabList.find(tab => tab.value === "pendingOrderList");

    const allyProps = selectedTab?.allyProps;

    let rowId = ids != "" ? ids : row.id;

    swal({
      text: "Do you want to reject the order",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        if (navigator.onLine) {
          showLoador({ loador: true });
          await apiGet({
            url:
              endpoint.stockStakeholderOrderHeader +
              "/update-status?ids=" +
              rowId +
              "&status=4&remark=" +
              comment,
          }).then(({ data, success }) => {
            showLoador({ loador: false });
            if (success) {
              showNotification({ msg: rejectMsg });
              if (ids != "" && allyProps == 0) {
                this.toggle();
              }
              if (
                localStorage.getItem("showFilterOnReceivedOrder") &&
                localStorage.getItem("showFilterOnReceivedOrder") != 1
              ) {
                this.getData();
              } else {
                this.onSearch(dayjs(), dayjs());
              }
            } else {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          });
        } else {
          showNotification({
            msg: noInternetMsg,
            severity: "error",
          });
        }
      }
    });
  };



  toggle = () => {
    this.setState({
      openDialogBox: !this.state.openDialogBox,
    });
  };


  handleCloses = () => {
    this.setState({
      filterPopUpFlag: false,
    });
  };


  openProductListView = async (rowData) => {
    console.log(rowData);
    const { showLoador, showNotification, getReceivedBillHeaderById } =
      this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getReceivedBillHeaderById({ billHeaderId: rowData.id }).then(
        ({ response, success, prodList }) => {
          console.log(response);
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            this.setState({
              ids: rowData.id,
              productList: prodList,
              comment: response.remark,
              openDialogBox: true,
            });
          }
        }
      );
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  rowEdit = (rowData) => {
    this.props.navigate(receivedOrderListJSON.formPathEdit + "/" + rowData.id);
  };

  rowPDF = (rowData) => {
    const { lang } = this.state;
    const { showLoador, showNotification } = this.props;
    localStorage.setItem("lang", lang);
    localStorage.setItem("myReceivedOrderPdfId", rowData.id);
    window.open("./receivedOrder-pdf", "_blank");


  };

  onOpenFilter = () => {
    this.setState({
      filterPopUpFlag: true,
    })
  }

  onSearch = (fromDate, toDate) => {
    const { getReceivedOrderListByDateUpdated, showLoador, showNotification } = this.props;

    if (navigator.onLine) {
      showLoador({ loador: true });
      getReceivedOrderListByDateUpdated({ fromDate: fromDate.format("DD-MM-YYYY"), toDate: toDate.format("DD-MM-YYYY") }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.handleCloses();
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }

  }


  render() {
    const { tabList, dynamicMasterData, openDialogBox, productList, comment, filterPopUpFlag } =
      this.state;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <LandingScreenHeader
            showFilterIcon={
              localStorage.getItem("showFilterOnReceivedOrder") &&
                localStorage.getItem("showFilterOnReceivedOrder") == 1
                ? true : false
            }
            screenTitle={receivedOrderListJSON.screenTitle}
            showAddButton={false}
            onOpenFilter={this.onOpenFilter}
          />
          <DynamicTabwiseTable
            rowList={dynamicMasterData}
            searchList={receivedOrderListJSON.searchList}
            formPath={receivedOrderListJSON.formPath}
            screenTitle={""}
            rowDelete={this.rowDelete}
            rowStatus={this.changeStatus}
            showHeadDelete={false}
            showAddButton={false}
            filterDataExcel={true}
            onSave={this.onSave}
            apiBaseURL={receivedOrderListJSON.apiBaseURL}
            baseIdColumn={receivedOrderListJSON.baseIdColumn}
            tabList={tabList}
            getListById={this.getListById}
            showApply={true}
            showPdf={true}
            staticView={true}
            rowPDF={this.rowPDF}
            rowViewData={this.openProductListView}
            showView={true}
            viewConditionValue="1"
            viewConditionName="status"
            showAcceptIcon={false}
            showCloseIcon={true}
            acceptDetails={this.acceptOrder}
            rejectDetails={this.rejectOrder}
            acceptConditionName="status"
            acceptConditionValue="1"
            showGenerateBillIcon={true}
            rejectConditionValue="2"
            rejectConditionName="status"
            generateBillConditionName="status"
            generateBillConditionValue="2"
            generateBill={this.openGenerateBillForm}
            showHeadEdit={true}
            rowEdit={this.rowEdit}
          />
          {openDialogBox && (
            <ProductListDialogBox
              // formData={{
              //   total: total,
              //   discount: fieldData.DiscountPercentage
              //     ? fieldData.DiscountPercentage
              //     : 0,
              //   totalAmt: totalAmt,
              // }}
              columns={[
                {
                  title: "Sr.No.",
                  name: "index",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "center",
                  canSearch: "true",
                },
                {
                  title: "Product Name",
                  name: "name",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "left",
                  canSearch: "true",
                },
                {
                  title: "UOM",
                  name: "stakeHolderUom",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "center",
                  canSearch: "true",
                },
                {
                  title: "Rate",
                  name: "rate",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "right",
                  canSearch: "true",
                },
                {
                  title: "Order Quantity",
                  name: "billQty",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "right",
                  canSearch: "true",
                },
                {
                  title: "Bill Quantity",
                  name: "qty",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "right",
                  canSearch: "true",
                },
                {
                  title: "Free Quantity",
                  name: "freeQty",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "right",
                  canSearch: "true",
                },
                {
                  title: "Discount % On Item",
                  name: "itemDiscPer",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "right",
                  canSearch: "true",
                },
                {
                  title: "Total Amount",
                  name: "total",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "right",
                  canSearch: "true",
                },
                {
                  title: "Action",
                  name: "action",
                  alignCenter: "center",
                  showInscreen: true,
                  canSearch: "true",
                },
              ]}
              open={openDialogBox}
              handleCloses={this.toggle}
              rows={productList}
              rejectOrder={this.rejectOrder}
              acceptOrder={this.acceptOrder}
              comment={comment}
              changeHandler={this.changeHandler}
            />
          )}

          {filterPopUpFlag && (
            <FilterPopUp
              open={filterPopUpFlag}
              handleCloses={this.handleCloses}
              onSearch={this.onSearch}
            />
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  receivedOrderBillList: state.receivedOrderList,
});
const mapDispatchToProps = {
  getReceivedOrderListNew,
  showLoador,
  showNotification,
  getReceivedBillHeaderById,
  getReceivedOrderListByDateUpdated,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ReceivedOrderList);
