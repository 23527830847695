import React from "react";
import { connect } from "react-redux";
import { historyOfCreditNoteJson } from "../../DynamicFormsJson/Transaction/historyOfCreditNote";
import { getStockholderByAreaIds } from "../../Slice/StackHolder.slice";
import {
  getHistoryOfCreditNoteByDateUpdated,
  setHistoryOfCrn,
} from "../../Slice/creditNote.slice";
import jsPDF from "jspdf";
import logo from "../../Images/gokulImg.png";
import ofc from "../../Images/OFC_logo.png";
import TiroDevanagariHindi from "../../config/TiroDevanagariHindi-Regular-normal"; // Replace with the actual path to your font file
import Aakar from "../../config/Aakar-normal";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
import { compose } from "@reduxjs/toolkit";
import { withRouter } from "../../components/withRouter";
import { redColor, titleColor } from "../../config/ColorObj";
import CheckBoxSelectionTable from "../../components/Comman/RT/CheckBoxSelectionTable";
import { getActiveLanguage } from "../../Slice/multilingual.slice";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import endpoint from "../../config/endpoints";
import {
  noInternetMsg,
  serverMsg,
  checkedListEmptyMsg,
} from "../../config/messageconstant";
import { historyOfCreditNoteColumns } from "../../tableColumns/table-columns";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { downloadF } from "../PDF/PDFDownload";
import { Grid, Paper } from "@mui/material";
import { getStateByRegion } from "../../Slice/state.slice";
import { getZoneByState } from "../../Slice/zone.slice";
import { getDistrictByZone } from "../../Slice/district.slice";
import { getTalukaByDistrict } from "../../Slice/taluka.slice";
import { getAreaByTaluka } from "../../Slice/area.slice";
import { LabelCompo } from "../../components/Comman/Label";
import { apiGet, apiPost } from "../../utils/api_service";
import { langugae } from "../../config/languageJSON";
import moment from "moment";
class HistoryOfCreditNote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      dynamicMasterData: {
        stakeholdersDetail: this.props.stakeholdersList.stockholderByArea,
      },
      lang: "2",
      selection: [],
      fieldDataForModule: {},
    };
  }

  async componentDidMount() {

    const {
      getActiveLanguage,
      showLoador,
      showNotification,
    } = this.props;

    if (navigator.onLine) {


      const moduleObjData = localStorage.getItem("moduleObj");
      this.setState({
        fieldDataForModule:
          moduleObjData != null ? JSON.parse(moduleObjData) : {},
      });


      showLoador({ loador: true });
      await getActiveLanguage().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

      const showCompanyFlag = localStorage.getItem("showCompanyFlag");

      console.log(showCompanyFlag);

      if (showCompanyFlag == 0) {
        this.getData();
        this.setState({
          companyFlagOfc: true,
        });
      }

    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  getData = async () => {
    const {
      getHistoryOfCreditNoteByDateUpdated,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {

      showLoador({ loador: true });
      await getHistoryOfCreditNoteByDateUpdated({ fromDate: "", toDate: "" }).then(
        ({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        }
      );

    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.regionList !== nextProps.regionList) {
      if (this.props.regionList?.region !== nextProps.regionList?.region) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            regionDetail: nextProps.regionList?.region,
          },
        });
      }
    }
    if (this.props.stateList !== nextProps.stateList) {
      if (
        this.props.stateList?.stateByRegion !==
        nextProps.stateList?.stateByRegion
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stateDetail: nextProps.stateList?.stateByRegion,
          },
        });
      }
    }
    if (this.props.zoneList !== nextProps.zoneList) {
      if (
        this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            zoneDetail: nextProps.zoneList?.zoneByState,
          },
        });
      }
    }
    if (this.props.districtList !== nextProps.districtList) {
      if (
        this.props.districtList?.districtByZone !==
        nextProps.districtList?.districtByZone
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            districtDetail: nextProps.districtList?.districtByZone,
          },
        });
      }
    }
    if (this.props.talukaList !== nextProps.talukaList) {
      if (
        this.props.talukaList?.talukaByDistrict !==
        nextProps.talukaList?.talukaByDistrict
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            talukaDetail: nextProps.talukaList?.talukaByDistrict,
          },
        });
      }
    }
    if (this.props.areaList !== nextProps.areaList) {
      if (
        this.props.areaList?.areaByTaluka !== nextProps.areaList?.areaByTaluka
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.areaByTaluka,
          },
        });
      }
    }
    if (this.props.stakeholdersList !== nextProps.stakeholdersList) {
      if (
        this.props.stakeholdersList?.stockholderByArea !==
        nextProps.stakeholdersList?.stockholderByArea
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stakeholdersDetail: nextProps.stakeholdersList?.stockholderByArea,
          },
        });
      }
    }
  }

  getListById = async (data) => {
    const {
      getStateByRegion,
      getZoneByState,
      getDistrictByZone,
      getTalukaByDistrict,
      getAreaByTaluka,
      getStockholderByAreaIds,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      console.log(data);
      if (data.region && data.region != "") {
        showLoador({ loador: true });
        await getStateByRegion({ regionIds: data.region }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.state && data.state != "") {
        showLoador({ loador: true });
        await getZoneByState({ stateIds: data.state }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.zone && data.zone != "") {
        showLoador({ loador: true });
        await getDistrictByZone({ zoneIds: data.zone }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.district && data.district != "") {
        showLoador({ loador: true });
        await getTalukaByDistrict({ districtIds: data.district }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.taluka && data.taluka != "") {
        showLoador({ loador: true });
        await getAreaByTaluka({ talukaIds: data.taluka }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.area && data.area != "") {
        showLoador({ loador: true });
        await getStockholderByAreaIds({ areaIds: data.area }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  generatePDFHeader = (rowList) => {
    var doc = new jsPDF();
    const { lang } = this.state;
    if (lang != 3) {
      doc.addFileToVFS(
        "TiroDevanagariHindi-Regular-normal.ttf",
        TiroDevanagariHindi
      );
      doc.addFont(
        "TiroDevanagariHindi-Regular-normal.ttf",
        "TiroDevanagariHindi-Regular",
        "normal"
      );
      doc.setFont("TiroDevanagariHindi-Regular");
    } else {
      doc.addFileToVFS("Aakar-normal.ttf", Aakar);
      doc.addFont("Aakar-normal.ttf", "Aakar", "normal");
      doc.setFont("Aakar");
    }
    let fontName = lang != 3 ? "TiroDevanagariHindi-Regular" : "Aakar";

    rowList.map((dataToSet, indexMain) => {
      let indexInfoData = 0;
      var line = 10; // Line height to start text at
      var lineHeight = 5;
      var leftMargin = 5;
      var wrapWidth = 120;
      doc.setFontSize(12);
      var DelerText = dataToSet.fromStockStakeHolderFirmName
        ? dataToSet.fromStockStakeHolderFirmName
        : "-";
      var splitText = doc.splitTextToSize(DelerText, wrapWidth + 10);
      for (var i = 0, length = splitText.length; i < length; i++) {
        // loop thru each line and increase
        doc.text(splitText[i], leftMargin, line);
        line = lineHeight + line;
      }
      let ownerName = "Owner Name :";

      doc.setFontSize(10);
      line = line + 2;
      var DelerText =
        dataToSet.fromStockStakeHolderOwnerName != null
          ? ownerName + dataToSet.fromStockStakeHolderOwnerName
          : "-";
      var splitText = doc.splitTextToSize(DelerText, wrapWidth + 10);
      for (var i = 0, length = splitText.length; i < length; i++) {
        // loop thru each line and increase
        doc.text(splitText[i], leftMargin, line);
        line = lineHeight + line;
      }
      line = line + 2;

      doc.setFont(fontName, "normal");
      let address = "address :";

      var addressText = dataToSet.fromStockStakeHolderAddress
        ? address + dataToSet.fromStockStakeHolderAddress
        : "-";

      // var splitTextAddr = doc.splitTextToSize(addressText, wrapWidth);
      // for (var i = 0, length = splitTextAddr.length; i < length; i++) {
      //   // loop thru each line and increase
      //   if (i == 0) {
      //     doc.text(splitTextAddr[i], leftMargin, line);
      //   } else {
      //     doc.text(splitTextAddr[i], leftMargin, line);
      //   }

      //   line = lineHeight + line;
      // }
      let yAxis = line + 2;
      var pageWidth = doc.internal.pageSize.getWidth();
      var xPos = pageWidth - 50;
      var xPos1 = pageWidth - 55;
      var top = 0;

      let fromBillContactPersonNo =
        dataToSet.fromStockStakeHolderContactPersonNo
          ? dataToSet.fromStockStakeHolderContactPersonNo
          : "-";
      let mobileNo = "Mobile No :";
      doc.text(mobileNo + fromBillContactPersonNo, leftMargin, yAxis, {
        maxWidth: wrapWidth,
      });

      yAxis = yAxis + 7;
      let fromBillFssiFdiNo = dataToSet.fromStockStakeHolderFssiFdiNo
        ? dataToSet.fromStockStakeHolderFssiFdiNo
        : "-";
      let fromBillGstNo = dataToSet.fromStockStakeHolderGstNo
        ? dataToSet.fromStockStakeHolderGstNo
        : "-";
      let gSTNo = " GST No: ";
      let FSSAINo = "FSSAI NO :";

      doc.text(
        gSTNo + fromBillGstNo + " , " + FSSAINo + fromBillFssiFdiNo,
        leftMargin,
        yAxis,
        { maxWidth: wrapWidth }
      );
      doc.line(150, 5, 150, 50);
      let pdfLogo =
        localStorage.getItem("compId") == "5c140f80-3929-46dc-83b1-592b7bc30d1f"
          ? logo
          : ofc;
      doc.addImage(pdfLogo, "JPEG", xPos1, 5, 45, 35);
      yAxis = yAxis + 5;
      doc.line(3, yAxis, pageWidth - 3, yAxis);
      yAxis = yAxis + 7;
      var xPosTax = pageWidth - 57;
      let yAxisTax = yAxis;
      doc.setFontSize(10);
      doc.setFont(fontName, "bold");

      doc.text("Tax Invoice", xPos, yAxisTax, { maxWidth: wrapWidth });
      yAxisTax = yAxisTax + 7;
      let billNoLan = "Bill No :";
      doc.text("Bill No.", xPosTax, yAxisTax, { maxWidth: wrapWidth });
      doc.setFont(fontName, "normal");
      let billNo = dataToSet.creditNoteNo ? dataToSet.creditNoteNo : "-";

      doc.text(billNo, xPosTax + 17, yAxisTax, { maxWidth: 100 });
      yAxisTax = yAxisTax + 7;
      doc.setFont(fontName, "bold");
      let date = "Date :";
      doc.text("Date", xPosTax, yAxisTax, { maxWidth: wrapWidth });
      doc.setFont(fontName, "normal");
      let billDate = dataToSet.date ? dataToSet.date : "-";

      doc.text(billDate, xPosTax + 17, yAxisTax, { maxWidth: wrapWidth });
      yAxisTax = yAxisTax + 7;
      doc.setFont(fontName, "bold");
      // let insertDateTime = dataToSet.insertDateTime
      //   ? (dataToSet.insertDateTime).split(" ")
      //   : [];
      let time = "Time";
      // doc.text("Time", xPosTax, yAxisTax, { maxWidth: wrapWidth });
      // doc.setFont(fontName, "normal");
      // let insertDateTimeData =
      //   insertDateTime.length == 2 ? insertDateTime[1] : "";
      // doc.text(insertDateTimeData, xPosTax + 17, yAxisTax, {
      //   maxWidth: wrapWidth,
      // });
      line = yAxis;
      top = 10;
      doc.setFontSize(10);
      let billTo = "Bill To";
      doc.text(billTo, leftMargin, line);
      // let toBill = dataToSet.toBill != null ? dataToSet.toBill : {};
      var DText = dataToSet.toStockStakeHolderFirmName
        ? dataToSet.toStockStakeHolderFirmName
        : "";
      var splitTextDText = doc.splitTextToSize(DText, wrapWidth);
      for (var i = 0, length = splitTextDText.length; i < length; i++) {
        if (i == 0) {
          doc.text(splitTextDText[i], leftMargin + 15, line);
        } else {
          doc.text(splitTextDText[i], leftMargin, line);
        }
        line = lineHeight + line;
        top = top + lineHeight;
      }

      line = line + 2;
      var DelerText = dataToSet.toStockStakeHolderOwnerName
        ? ownerName + dataToSet.toStockStakeHolderOwnerName
        : "-";

      var splitText = doc.splitTextToSize(DelerText, wrapWidth + 10);
      for (var i = 0, length = splitText.length; i < length; i++) {
        // loop thru each line and increase
        doc.text(splitText[i], leftMargin, line);
        line = lineHeight + line;
      }
      doc.setFont(fontName, "normal");

      doc.setFontSize(10);
      line = line + 2;
      top = top + 2;
      // let taluka =
      //   toBill.beat != null &&
      //   toBill.beat.area != null &&
      //   toBill.beat.area.taluka != null
      //     ? toBill.beat.area.taluka
      //     : "";
      // let talukaName = taluka != null ? taluka.name : "";
      // let districtName =
      // taluka != null && taluka.district != null ? taluka.district.name : "";
      let addr = dataToSet.toStockStakeHolderAddress
        ? dataToSet.toStockStakeHolderAddress
        : "";
      var DAddrText = address + addr;
      var splitTextDAddrText = doc.splitTextToSize(DAddrText, wrapWidth);
      for (var i = 0, length = splitTextDAddrText.length; i < length; i++) {
        // loop thru each line and increase
        doc.text(splitTextDAddrText[i], leftMargin, line);
        line = lineHeight + line;
        top = top + lineHeight;
      }
      yAxis = line + 2;
      top = top + 2;
      let contactPersonNo = dataToSet.toStockStakeHolderContactPersonNo
        ? dataToSet.toStockStakeHolderContactPersonNo
        : "";
      let gstNo =
        dataToSet.toStockStakeHolderGstNo != null
          ? dataToSet.toStockStakeHolderGstNo
          : "-";

      let phoneNo = "Phone No";
      doc.text(
        phoneNo + contactPersonNo + " , " + gSTNo + gstNo,
        leftMargin,
        yAxis,
        { maxWidth: wrapWidth }
      );

      yAxis = yAxis + 7;
      top = top + 7;
      let fssiFdiNo =
        dataToSet.toStockStakeHolderFssiFdiNo != null &&
          dataToSet.toStockStakeHolderFssiFdiNo != ""
          ? dataToSet.toStockStakeHolderFssiFdiNo
          : "-";

      doc.text(FSSAINo + fssiFdiNo, leftMargin, yAxis, { maxWidth: 100 });

      yAxis = yAxis + 7;
      top = top + 7;
      doc.line(150, 5, 150, yAxis);
      doc.line(3, yAxis, pageWidth - 3, yAxis);
      let toBillState =
        dataToSet.fromStockHolderState != null
          ? dataToSet.fromStockHolderState
          : {};
      let dataToSetState =
        dataToSet.toStockHolderState != null
          ? dataToSet.toStockHolderState
          : {};
      let HSN = "HSN";

      let itemName = "Item Name";

      let uom = "UOm";

      let qty = "qty";

      let rate = "rate";

      let CGST = "CGST";

      let SGST = "SGST ";

      let amount = "Amount ";

      let IGST = "IGST";

      let total = "Total";

      let headData = [
        { content: "#" },
        { content: HSN },
        { content: itemName },
        { content: uom },
        { content: qty },
        { content: rate },
      ];

      var align = {
        1: { halign: "center" },
        4: { halign: "right" },
        5: { halign: "right" },
      };
      let headData2 = [];
      if (toBillState.id == dataToSetState.id) {
        headData = [
          ...headData,
          { content: CGST, colSpan: 2 },
          { content: SGST, colSpan: 2 },
          { content: amount },
        ];
        headData2 = ["", "", "", "", "", "", "%", amount, "%", amount, ""];
        align = {
          ...align,
          6: { halign: "right" },
          7: { halign: "right" },
          8: { halign: "right" },
          9: { halign: "right" },
          10: { halign: "right" },
        };
      } else {
        headData = [
          ...headData,
          { content: IGST, colSpan: 2 },
          { content: amount },
        ];
        headData2 = ["", "", "", "", "", "", "%", amount, ""];
        align = {
          ...align,
          6: { halign: "right" },
          7: { halign: "right" },
          8: { halign: "right" },
        };
      }

      let head = [headData, headData2];
      let stockStakeHolderBillDetails =
        dataToSet.dtoHolderCreditNoteDetailAndHeaders != null
          ? dataToSet.dtoHolderCreditNoteDetailAndHeaders
          : [];
      let productList = [];
      // dataToSet.packagingTypes.map((packaginType)=>{})

      console.log(productList);
      var data = [];
      let totalAmt = 0;
      let qtyTot = 0;
      dataToSet.packagingTypes.map((packagingType) => {
        data.push([
          {
            content: packagingType.name,
            colSpan: 11,

            styles: { fontWeight: 900, fontSize: 10, fontColor: "#000" },
          },
        ]);
        let indexInfo = 0;
        stockStakeHolderBillDetails.map((stockDetails, indexData) => {
          let packagingTypeDetails =
            stockDetails.product != null &&
              stockDetails.product.packagingType != null
              ? stockDetails.product.packagingType
              : {};

          if (packagingType.id == packagingTypeDetails.id) {
            let productData = [];
            let product =
              stockDetails.product != null ? stockDetails.product : {};
            indexInfo = indexInfo + 1;
            productData.push(indexInfo);
            productData.push(stockDetails.hsnCode);
            productData.push(product.name);
            productData.push(
              stockDetails.uom != null ? stockDetails.uom.name : "-"
            );
            productData.push(stockDetails.qty);
            qtyTot = qtyTot + stockDetails.qty;
            productData.push(stockDetails.rate.toFixed(2));
            if (toBillState.id == dataToSetState.id) {
              productData.push(stockDetails.cgstPer.toFixed(2));
              productData.push(stockDetails.cgstAmt.toFixed(2));
              productData.push(stockDetails.sgstPer.toFixed(2));
              productData.push(stockDetails.sgstAmt.toFixed(2));
            } else {
              productData.push(stockDetails.igstPer.toFixed(2));
              productData.push(stockDetails.igstAmt.toFixed(2));
            }
            totalAmt = totalAmt + stockDetails.totalAmt;
            productData.push(stockDetails.totalAmt.toFixed(2));
            data.push(productData);
          }
        });
      });

      if (toBillState.id == dataToSetState.id) {
        data.push([
          {
            content: total,
            colSpan: 4,
            styles: { fontWeight: 900, fontSize: 12, fontColor: "#000" },
          },
          {
            content: qtyTot,
            styles: { fontWeight: 900, fontSize: 12, fontColor: "#000" },
          },
          "",
          "",
          "",
          "",
          "",
          {
            content: totalAmt.toFixed(2),
            styles: { fontWeight: 900, fontSize: 12, fontColor: "#000" },
          },
        ]);
      } else {
        data.push([
          {
            content: total,
            colSpan: 4,
            styles: { fontWeight: "bold" },
          },
          qtyTot,
          "",
          "",
          "",
          totalAmt.toFixed(2),
        ]);
      }
      var fontSize = 8; // Set your desired font size
      var styles = {
        fontSize: fontSize,
        fontColor: "#000",
        font: lang != 3 ? "TiroDevanagariHindi-Regular" : "Aakar",
      };
      doc.autoTable({
        head: head,
        body: data,
        startY: yAxis + 5,
        columnStyles: align,
        margin: { right: 5, left: 5, top: 20 },
        headStyles: {
          halign: "center",
          lineWidth: 0.2,
          lineColor: "#fff",
          fillColor: [3, 169, 244],
          textColor: [255, 255, 255],
        },
        styles: styles,
        theme: "grid",

        showHead: "everyPage",

        didDrawPage: function (data) {
          doc.setTextColor(40);
          var pageWidth = doc.internal.pageSize.getWidth();
          if (data.pageNumber > 1) {
            var xPos1 = pageWidth - 45;
            var line1 = 15;
            var yAxisTax = 10;
            doc.setFontSize(10);
            doc.setFont(fontName);
            let taxInvoice = "Tax Invoice";
            doc.text(taxInvoice, 90, 10);
            var lineHeight1 = 5;
            var wrapWidth1 = 130;
            doc.setFontSize(10);
            let billTO = "Bill To";
            doc.text(billTO, leftMargin, line1);
            doc.setFontSize(10);
            // let toBill = dataToSet.outLet ? dataToSet.outLet : {};

            var DText = dataToSet.toStockStakeHolderOwnerName
              ? dataToSet.toStockStakeHolderOwnerName
              : "";
            var splitTextDText = doc.splitTextToSize(DText, wrapWidth1);
            for (var i = 0, length = splitTextDText.length; i < length; i++) {
              // loop thru each line and increase

              if (i == 0) {
                doc.text(splitTextDText[i], leftMargin + 15, line1);
              } else {
                doc.text(splitTextDText[i], leftMargin, line1);
              }
              line1 = lineHeight1 + line1;
            }
            let billDate = dataToSet.date ? dataToSet.date : "-";

            let text = dataToSet.creditNoteNo
              ? dataToSet.creditNoteNo + " (" + billDate + ")"
              : "-";
            var textWidth1 =
              (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
              doc.internal.scaleFactor;

            var xPos1 = pageWidth - textWidth1 - 10;
            doc.setFont(fontName, "normal");
            doc.text(text, xPos1, 15, { maxWidth: 100 });
          }
          indexInfoData = indexInfoData + 1;
          var str = "Page " + indexInfoData;

          // jsPDF 1.4+ uses getWidth, <1.4 uses .width
          var pageSize = doc.internal.pageSize;
          var pageHeight = pageSize.height
            ? pageSize.height
            : pageSize.getHeight();
          let pb = "Powered By Bizintel DMS";
          const compName = localStorage.getItem("compName");
          var textWidthCN =
            (doc.getStringUnitWidth(compName) * doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textWidth =
            (doc.getStringUnitWidth(pb) * doc.internal.getFontSize()) /
            doc.internal.scaleFactor;

          // Calculate the X position to align to the right of the page

          doc.setLineWidth(0.2);
          if (data.pageNumber > 1) {
            doc.rect(3, 3, pageWidth - 6, pageHeight - 8);
          } else {
            doc.rect(3, 3, pageWidth - 6, pageHeight - 8);
          }
          // Adjust for a margin if needed
          doc.setFontSize(10);
          var xPosCN = (pageWidth - textWidthCN) / 2;
          var xPos = pageWidth - textWidth - 10;
          doc.text(compName, 10, pageHeight - 10);

          doc.setFontSize(10);
          doc.text(pb, xPos, pageHeight - 10);
          doc.text(str, xPosCN, pageHeight - 10);
        },
      });

      let fillingType = "Fill Type";

      let productHeadWithPack =
        dataToSet.packagingTypes.length != 0 ? [fillingType] : [];
      let productListWithPack = [];
      let productListalign = { 0: { cellWidth: 50 } };
      dataToSet.categories.map((categoryData, index) => {
        productHeadWithPack.push(categoryData.name);
        productListalign = {
          ...productListalign,
          [index + 1]: { halign: "right" },
        };
      });

      dataToSet.packagingTypes.map((packagingTypeData) => {
        let GruopInfo = [packagingTypeData.name];
        dataToSet.categories.map((categoryData) => {
          let flag = 0;
          dataToSet.dtoCategoryWithPackagingTypes.map((rowData) => {
            if (
              categoryData.id == rowData.category.id &&
              rowData.packagingType.id == packagingTypeData.id
            ) {
              flag = 1;
              GruopInfo.push(rowData.totalQty);
            }
          });
          if (flag == 0) {
            GruopInfo.push("");
          }
        });
        productListWithPack.push(GruopInfo);
      });
      var styles1 = {
        font: lang != 3 ? "TiroDevanagariHindi-Regular" : "Aakar",
      };
      console.log(productListWithPack);
      console.log(productHeadWithPack);
      doc.autoTable({
        head: [productHeadWithPack],
        body: productListWithPack,
        styles: styles1,
        startY: doc.autoTableEndPosY() + 5,
        columnStyles: productListalign,
        margin: { right: 6, left: 6, top: 20 },
        headStyles: {
          halign: "center",
          lineWidth: 0.2,
          lineColor: "#fff",
          fillColor: [3, 169, 244],
          textColor: [255, 255, 255],
        },
        theme: "grid",

        showHead: "everyPage",

        didDrawPage: function (data) { },
      });
      var line = doc.autoTableEndPosY() + 5; // Line height to start text at
      var lineHeight = 5;
      var leftMargin = 5;
      var leftMarginData = 152;
      var wrapWidth = 180;
      // let note = "note : "
      // doc.text(note, leftMargin, line);
      // var DelerText =
      //   dataToSet.orderRemarks != null ? dataToSet.orderRemarks : "";
      // // var splitText = doc.splitTextToSize(DelerText, wrapWidth + 10);
      // for (var i = 0, length = splitText.length; i < length; i++) {
      //   // loop thru each line and increase
      //   doc.text(splitText[i], leftMargin + 10, line);
      //   line = lineHeight + line;
      // }
      let orderBy = "Order By : ";

      // doc.line(3, line, pageWidth - 3, line);
      // let lineData = line;
      // var line = line + 5;
      // let salesTeamNames = dataToSet.salesTeamNames
      //   ? dataToSet.salesTeamNames
      //   : "";
      doc.text("1." + orderBy, leftMargin, line);
      let lineOrder = line;
      var wrapWidth = 50;
      var DelerText = dataToSet.fromStockStakeHolderFirmName
        ? dataToSet.fromStockStakeHolderFirmName
        : "-";
      var splitText = doc.splitTextToSize(DelerText, wrapWidth + 10);
      for (var i = 0, length = splitText.length; i < length; i++) {
        // loop thru each line and increase
        doc.text(splitText[i], leftMarginData, lineOrder);
        lineOrder = lineHeight + lineOrder;
      }
      var line = line + 2;
      doc.line(3, line, 90, line);
      var line = line + 5;
      let subjectToJurisdiction = "Subject to Jurisdiction";
      let distName = localStorage.getItem("districtName");
      let aurthorisedSign = "Aurthorised Sign : ";

      let goodsCheckBeforeReceived = "Goods Check Before Received : ";
      doc.text("2." + goodsCheckBeforeReceived, leftMargin, line);
      var line = line + 2;
      doc.line(3, line, 90, line);
      var line = line + 5;
      doc.text("3. " + subjectToJurisdiction + distName, leftMargin, line);

      doc.text(aurthorisedSign, leftMarginData, line);
      var line = line + 5;
      // doc.line(90, lineData, 90, line);
      // doc.line(150, lineData, 150, line);
      doc.line(3, line, pageWidth - 3, line);
      if (indexMain + 1 != rowList.length) {
        doc.addPage();
      }
    });

    let bill = "Bill";
    doc.save(bill + moment(new Date()).format("DD-MM-YYYY") + ".pdf");
  };
  onSearchList = async (data) => {
    const { getHistoryOfCreditNoteByDateUpdated, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      this.setState({
        formData: data,
      });
      showLoador({ loador: true });
      await getHistoryOfCreditNoteByDateUpdated({
        fromDate: data.fromDate,
        toDate: data.toDate,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  rowPDF = (rowData) => {
    console.log("rowData");
    console.log(rowData);
    const { lang } = this.state;
    localStorage.setItem("lang", lang);
    // localStorage.setItem("lang", '2');
    const showPdfType = localStorage.getItem("showPdf");
    console.log("showPdfType");
    console.log(showPdfType);
    if (showPdfType == 0) {
      localStorage.setItem("creditNoteBillID", rowData.id);
      window.open("./credit-note-bill-pdf", "_blank");
    }
    if (showPdfType == 1) {
      localStorage.setItem("creditNoteBillNewID", rowData.id);
      window.open("./credit-note-bill-pdf-new", "_blank");
    }
    if (showPdfType == 2) {
      if (navigator.onLine) {
        let creditNoteData = {
          holderCreditNoteHeaderIds: rowData.id.split(","),
          languageId: lang,
        };
        showLoador({ loador: true });
        apiPost({
          url: endpoint.holderCreditNoteHeader + "/report-by-ids",
          postBody: creditNoteData,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({
              msg: serverMsg,
              severity: "error",
            });
          } else {
            console.log(data.data);
            if (data.data.length != 0) {
              this.generatePDFHeader(data.data);
            }
          }
        });
      } else {
        showNotification({ msg: noInternetMsg, severity: "error" });
      }
    }
  };

  onAutocompleteChange = (name, newValue) => {
    if (newValue != null) {
      this.setState({
        lang: newValue,
        langNew: newValue,
      });
    } else {
      this.setState({
        lang: "",
        langNew: "",
      });
    }
  };

  onPDFBill = () => {
    const { showNotification, historyOfCreditNoteList } = this.props;
    const { selection } = this.state;
    if (selection.length === 0) {
      showNotification({
        msg: checkedListEmptyMsg,
        severity: "error",
      });
    } else {
      const rowList = historyOfCreditNoteList.historyOfCreditNote.map(
        (rowData) => {
          const filterData = selection.filter((id) => rowData.id == id);
          return { ...rowData, checked: filterData.length != 0 ? true : false };
        }
      );
      console.log(selection);
      this.rowPDF({
        id: selection.join(),
      });
    }
  };

  getSelectedList = (e) => {
    const { historyOfCreditNoteList } = this.props;
    this.setState({
      selection: e,
    });
    const rowList = this.props.historyOfCreditNoteList.historyOfCreditNote.map(
      (rowData) => {
        const filterData = e.filter((id) => rowData.id == id);
        return { ...rowData, checked: filterData.length != 0 ? true : false };
      }
    );
  };

  rowView = (rowData) => {
    this.props.navigate(
      historyOfCreditNoteJson.formPathView + "/" + rowData.id
    );
  };
  render() {
    const { historyOfCreditNoteList, multilingualList, setHistoryOfCrn } =
      this.props;
    const { dynamicMasterData, selection, lang, fieldDataForModule } =
      this.state;
    return (
      <Paper>
        <>
          <DynamicMainScreen
            showViewButtonInTable={true}
            getListById={this.getListById}
            showDeleteIcon={false}
            dynamicMasterData={dynamicMasterData}
            searchList={historyOfCreditNoteJson.searchList}
            onSearchData={this.onSearchList}
            formPathView={historyOfCreditNoteJson.formPathView}
            formPath={historyOfCreditNoteJson.formPath}
            screenTitle={historyOfCreditNoteJson.screenTitle}
            fieldMeta={historyOfCreditNoteJson.fieldMeta}
            showPdfDownload={false}
            showExcelDownload={false}
            isActiveURL={historyOfCreditNoteJson.isActiveURL}
            showAddButton={false}
            tableColumnsToFilter={historyOfCreditNoteJson.tableColumnsToFilter}
            tableColumns={[]}
            tableData={historyOfCreditNoteList.historyOfCreditNote}
            getTableData={this.getData}
            apiBaseURL={historyOfCreditNoteJson.apiBaseURL}
            callApi={false}
            getTableDataById={true}
            showSearchBox={false}
            rowPDF={this.rowPDF}
            showPdf={true}
            deleteURL={historyOfCreditNoteJson.deleteURL}
            deleteURLStatic={true}
            showPegination={false}
            showSearch={false}
            showEditIcon={false}
          />
          <CheckBoxSelectionTable
            selection={selection}
            onSelectionChange={this.getSelectedList}
            columns={historyOfCreditNoteColumns}
            rows={
              (historyOfCreditNoteColumns,
                historyOfCreditNoteList?.historyOfCreditNote)
            }
            isActionColActive={true}
            rowPrint={this.rowPDF}
            SummarizeRoundedShow={true}
            showViewButtonInTable={true}
            rowView={this.rowView}
            showDelete={false}
            // rowDelete={this.onDeleteStatic}
            showSearchBox={true}
          // showApply={true}
          // showApplyTitle={"Export CreditNote"}
          // rowApply={this.rowPDF}
          // showAdd={true}
          // showAddTitle={"Import Bill"}
          // rowAdd={this.onClickAdd}
          />
          <br />
          <br />

          {fieldDataForModule.view == 1 ? (
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              container
              justifyContent="right"
            >
              <Grid container xs={12} sm={3} md={3} lg={3}>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label={"*"}
                  />
                  <LabelCompo
                    className="text-black"
                    style={{
                      fontSize: 16,
                      color: titleColor,
                    }}
                    label={"Language" + " :"}
                  />
                </Grid>
                <Grid item xs={12} sm={7} md={7} lg={7}>
                  <AutoComplete
                    name={"Language"}
                    label={"Language"}
                    placeHolder={"Select Language"}
                    keyColName={"id"}
                    value={lang}
                    options={multilingualList.language}
                    onChange={this.onAutocompleteChange}
                    errorText={""}
                  />
                </Grid>
              </Grid>
              &nbsp;&nbsp;
              <ButtonCompo
                size="medium"
                type="submit"
                variant="contained"
                name="Credit Note PDF"
                onClick={this.onPDFBill}
              />
            </Grid>
          ) : (
            ""
          )}
        </>
      </Paper>
    );
  }
}
const mapStateToProps = (state) => ({
  stakeholdersList: state.stackholder,
  historyOfCreditNoteList: state.creditNoteHeader,
  areaList: state.area,
  regionList: state.region,
  stateList: state.states,
  zoneList: state.zone,
  districtList: state.district,
  talukaList: state.taluka,
  multilingualList: state.multilingual,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getStockholderByAreaIds,
  getHistoryOfCreditNoteByDateUpdated,
  setHistoryOfCrn,
  getStateByRegion,
  getZoneByState,
  getActiveLanguage,
  getDistrictByZone,
  getTalukaByDistrict,
  getAreaByTaluka,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(HistoryOfCreditNote);
