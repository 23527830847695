import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { redColor } from "../../config/ColorObj";

const StakeholderTypeAllowanceConfigTable = ({
  rowList,
  handleRowChange,
  formErrors,
}) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">Sr. No.</TableCell>
            <TableCell align="center">Stakeholder type</TableCell>
            <TableCell align="center">Traveling allowance (TA)</TableCell>
            <TableCell align="center">Daily Allowance (DA)</TableCell>
            <TableCell align="center">Rate/KM</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowList.map((row, index) => (
            <TableRow key={row.index}>
              <TableCell width={"5%"} align="center">
                {row.index}
              </TableCell>
              <TableCell width={"40%"}>{row.name}</TableCell>
              <TableCell width={"10%"}>
                <input
                  className={"customTextfield"}
                  placeHolder={"TA"}
                  value={row.daOutstation}
                  name="daOutstation"
                  onChange={(e) =>
                    handleRowChange(row.id, "daOutstation", e.target.value)
                  }
                  type="number"
                  id="outletRate"
                  min="0"
                />
                <span style={{ color: redColor, fontSize: "12px" }}>
                  {formErrors[row.id]?.daOutstationError
                    ? formErrors[row.id].daOutstationError
                    : ""}
                </span>
              </TableCell>
              <TableCell width={"12%"}>
                <input
                  className={"customTextfield"}
                  placeHolder={"DA"}
                  value={row.daLocal}
                  name="daLocal"
                  onChange={(e) =>
                    handleRowChange(row.id, "daLocal", e.target.value)
                  }
                  type="number"
                  id="outletRate"
                />
                <span style={{ color: redColor, fontSize: "12px" }}>
                  {formErrors[row.id]?.daLocalError
                    ? formErrors[row.id].daLocalError
                    : ""}
                </span>
              </TableCell>
              <TableCell width={"10%"} align="center">
                <input
                  className={"customTextfield"}
                  placeHolder={"Rate/KM"}
                  value={row.ratePerKm}
                  name="ratePerKm"
                  onChange={(e) =>
                    handleRowChange(row.id, "ratePerKm", e.target.value)
                  }
                  type="number"
                  id="outletRate"
                />
                <span style={{ color: redColor, fontSize: "12px" }}>
                  {formErrors[row.id]?.ratePerKmError
                    ? formErrors[row.id].ratePerKmError
                    : ""}
                </span>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default StakeholderTypeAllowanceConfigTable;
