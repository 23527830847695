import endpoint from "../../config/endpoints";

export const ConfigurationPendingJson = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.area,
  screenTitle: "Configuration Pending",
  fieldMeta: [
    {
      label: "Search by",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "type",
      tableDataKey: "type",
      defaultValue: "0",
      radioGroupItems: [
        {
          label: "Sales Team",
          value: "0",
        },
        {
          label: "Stock Holder",
          value: "1",
        },
        {
          label: "Centralized Outlet",
          value: "2",
        },
      ],
      isMandatory: true,
    },
  ],

  searchListBySalesTeam: [
    {
      label: "search by",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "type",
      tableDataKey: "type",
      defaultValue: "0",
      radioGroupItems: [
        {
          label: "Sales Team",
          value: "0",
        },
        {
          label: "Stock Holder",
          value: "1",
        },
        {
          label: "Centralized Outlet",
          value: "2",
        },
      ],
      isMandatory: true,
    },

    {
      label: "Region",
      controlType: "autocomplete",
      placeHolder: "Select Region",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "regionDetail",
      dataKey: "region",
      getListId: "region",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "State",
      controlType: "autocomplete",
      placeHolder: "Select State",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "stateDetail",
      dataKey: "state",
      getListFrom: "region",
      getListId: "state",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Zone",
      controlType: "multiSelect",
      placeHolder: "Select Zone",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "zoneDetail",
      dataKey: "zone",
      getListFrom: "state",
      getListId: "zone",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "District",
      controlType: "multiSelect",
      placeHolder: "Select District",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "districtDetail",
      dataKey: "district",
      getListFrom: "zone",
      getListId: "district",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Taluka",
      controlType: "multiSelect",
      placeHolder: "Select Taluka",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "talukaDetail",
      dataKey: "taluka",
      getListFrom: "district",
      getListId: "taluka",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Area",
      controlType: "multiSelect",
      placeHolder: "Select Area ",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "areaDetail",
      dataKey: "area",
      getListFrom: "taluka",
      isRootLevelKey: false,
      isMandatory: true,
      getListId: "area",
    },
  ],

};
