import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
const initialState = {
    salesTeamCategoryWiseData: {},
};
let URL = endpoints.reportForExcelReports;
const salesTeamCategoryWiseDataSlice = createSlice({
    name: "salesTeamCategoryWiseData",
    initialState,
    reducers: {
        salesTeamCategoryWiseDataSuccess: (state = cloneDeep(initialState), action) => {
            const { row } = action.payload;
            return {
                ...state,
                salesTeamCategoryWiseData: row,
            };
        },

        salesTeamSubCategoryWiseDataSuccess: (state = cloneDeep(initialState), action) => {
            const { row } = action.payload;
            return {
                ...state,
                salesTeamSubCategoryWiseData: row,
            };
        },

        resetState: (state = cloneDeep(initialState), action) => {
            return {
                ...state,
                ...initialState,
            };
        },
    },
});

export const { salesTeamCategoryWiseDataSuccess, salesTeamSubCategoryWiseDataSuccess, resetState } = salesTeamCategoryWiseDataSlice.actions;

export default salesTeamCategoryWiseDataSlice.reducer;

export const getSalesTeamCategoryWiseData = ({ dateJson }) => async (dispatch) => {
    try {
        const response = apiPost({
            url: URL + "/sales-team-wise-category-report",
            postBody: dateJson
        }).then(({ data, success }) => {
            if (success) {

                const objectData = data.data;
                let responseDataObj = {
                    categories:
                        objectData.categories === null
                            ? ""
                            : objectData.categories.map((categoryListObj, index) => {

                                let categoryListData = {
                                    index: index + 1,
                                    id: categoryListObj.id === null ? "" : categoryListObj.id,
                                    name:
                                        categoryListObj.name === null ? "" : categoryListObj.name,
                                    shortName:
                                        categoryListObj.shortName === null
                                            ? ""
                                            : categoryListObj.shortName,
                                    sortOrder:
                                        categoryListObj.sortOrder === null ? "" : categoryListObj.sortOrder,
                                    isActive:
                                        categoryListObj.isActive === null
                                            ? ""
                                            : categoryListObj.isActive,

                                    subCategoryCount:
                                        categoryListObj.subCategoryCount === null
                                            ? ""
                                            : categoryListObj.subCategoryCount,
                                    groupCount:
                                        categoryListObj.groupCount === null
                                            ? ""
                                            : categoryListObj.groupCount,
                                    productCount:
                                        categoryListObj.productCount === null
                                            ? ""
                                            : categoryListObj.productCount,
                                };
                                return categoryListData;
                            }),

                    salesTeamList:
                        objectData.salesTeamList === null
                            ? ""
                            : objectData.salesTeamList.map((salesTeamListObj, index) => {
                                let salesTeamListData = {
                                    index: index + 1,
                                    id:
                                        salesTeamListObj.id === null
                                            ? ""
                                            : salesTeamListObj.id,
                                    userId:
                                        salesTeamListObj.userId === null
                                            ? ""
                                            : salesTeamListObj.userId,
                                    userName:
                                        salesTeamListObj.userName === null
                                            ? ""
                                            : salesTeamListObj.userName,
                                    stockStakeHolderTypeId:
                                        salesTeamListObj.stockStakeHolderTypeId === null
                                            ? ""
                                            : salesTeamListObj.stockStakeHolderTypeId,
                                    name:
                                        salesTeamListObj.name === null
                                            ? ""
                                            : salesTeamListObj.name,

                                    grandTotalAmt: salesTeamListObj.grandTotalAmt === null
                                        ? ""
                                        : salesTeamListObj.grandTotalAmt,

                                    grandTotalQty: salesTeamListObj.grandTotalQty === null
                                        ? ""
                                        : salesTeamListObj.grandTotalQty,

                                    whatsAppNo:
                                        salesTeamListObj.whatsAppNo === null
                                            ? ""
                                            : salesTeamListObj.whatsAppNo,
                                    contactNo:
                                        salesTeamListObj.contactNo === null
                                            ? ""
                                            : salesTeamListObj.contactNo,
                                    address:
                                        salesTeamListObj.address === null
                                            ? ""
                                            : salesTeamListObj.address,
                                    employeeCode:
                                        salesTeamListObj.employeeCode === null
                                            ? ""
                                            : salesTeamListObj.employeeCode,
                                    accessRoleId:
                                        salesTeamListObj.accessRoleId === null
                                            ? ""
                                            : salesTeamListObj.accessRoleId,
                                    type:
                                        salesTeamListObj.type === null
                                            ? ""
                                            : salesTeamListObj.type,
                                    isActive:
                                        salesTeamListObj.isActive === null
                                            ? ""
                                            : salesTeamListObj.isActive,
                                    insertDateTime:
                                        salesTeamListObj.insertDateTime === null
                                            ? ""
                                            : salesTeamListObj.insertDateTime,
                                };
                                return salesTeamListData;
                            }),

                    salesTeamWithCategoryValues:
                        objectData.salesTeamWithCategoryValues === null
                            ? ""
                            : objectData.salesTeamWithCategoryValues.map(
                                (salesTeamWithCategoryValuesObj, index) => {
                                    let salesTeamWithCategoryValuesData = {
                                        index: index + 1,
                                        id:
                                            salesTeamWithCategoryValuesObj.id === null
                                                ? ""
                                                : salesTeamWithCategoryValuesObj.id,
                                        salesTeamId:
                                            salesTeamWithCategoryValuesObj.salesTeamId === null
                                                ? ""
                                                : salesTeamWithCategoryValuesObj.salesTeamId,
                                        salesTeamName:
                                            salesTeamWithCategoryValuesObj.salesTeamName === null
                                                ? ""
                                                : salesTeamWithCategoryValuesObj.salesTeamName,
                                        categoryId:
                                            salesTeamWithCategoryValuesObj.categoryId === null
                                                ? ""
                                                : salesTeamWithCategoryValuesObj.categoryId,
                                        categoryName:
                                            salesTeamWithCategoryValuesObj.categoryName === null
                                                ? ""
                                                : salesTeamWithCategoryValuesObj.categoryName,
                                        totalAmt:
                                            salesTeamWithCategoryValuesObj.totalAmt === null
                                                ? ""
                                                : salesTeamWithCategoryValuesObj.totalAmt,
                                        totalQty:
                                            salesTeamWithCategoryValuesObj.totalQty === null
                                                ? ""
                                                : salesTeamWithCategoryValuesObj.totalQty,
                                    };
                                    return salesTeamWithCategoryValuesData;
                                }
                            ),
                };

                dispatch(salesTeamCategoryWiseDataSuccess({ row: responseDataObj }));
                return { response: responseDataObj, success };
            }
            return { response: {}, success };
        });
        return response;
    } catch (e) {
        return console.error(e.message);
    }
};


export const getSalesTeamSubCategoryWiseData = ({ dateJson }) => async (dispatch) => {
    try {
        const response = apiPost({
            url: URL + "/sales-team-wise-sub-category-report",
            postBody: dateJson
        }).then(({ data, success }) => {
            if (success) {

                const objectData = data.data;
                let responseDataObj = {
                    categories:
                        objectData.categories === null
                            ? ""
                            : objectData.categories.map((categoryListObj, index) => {

                                let categoryListData = {
                                    index: index + 1,
                                    id: categoryListObj.id === null ? "" : categoryListObj.id,
                                    name:
                                        categoryListObj.name === null ? "" : categoryListObj.name,
                                    shortName:
                                        categoryListObj.shortName === null
                                            ? ""
                                            : categoryListObj.shortName,
                                    sortOrder:
                                        categoryListObj.sortOrder === null ? "" : categoryListObj.sortOrder,
                                    isActive:
                                        categoryListObj.isActive === null
                                            ? ""
                                            : categoryListObj.isActive,

                                    subCategoryCount:
                                        categoryListObj.subCategoryCount === null
                                            ? ""
                                            : categoryListObj.subCategoryCount,
                                    groupCount:
                                        categoryListObj.groupCount === null
                                            ? ""
                                            : categoryListObj.groupCount,
                                    productCount:
                                        categoryListObj.productCount === null
                                            ? ""
                                            : categoryListObj.productCount,
                                };
                                return categoryListData;
                            }),


                    subCategories:
                        objectData.subCategories === null
                            ? ""
                            : objectData.subCategories.map((subCategoryListObj, index) => {

                                let subCategoryListData = {
                                    index: index + 1,
                                    id: subCategoryListObj.id === null ? "" : subCategoryListObj.id,
                                    categoryId:
                                        subCategoryListObj.categoryId === null ? "" : subCategoryListObj.categoryId,
                                    categoryName:
                                        subCategoryListObj.categoryName === null
                                            ? ""
                                            : subCategoryListObj.categoryName,
                                    name:
                                        subCategoryListObj.name === null ? "" : subCategoryListObj.name,
                                    shortName:
                                        subCategoryListObj.shortName === null
                                            ? ""
                                            : subCategoryListObj.shortName,

                                    sortOrder:
                                        subCategoryListObj.sortOrder === null
                                            ? ""
                                            : subCategoryListObj.sortOrder,
                                    isActive:
                                        subCategoryListObj.isActive === null
                                            ? ""
                                            : subCategoryListObj.isActive,
                                    insertDateTime:
                                        subCategoryListObj.insertDateTime === null
                                            ? ""
                                            : subCategoryListObj.insertDateTime,
                                };
                                return subCategoryListData;
                            }),

                    salesTeamList:
                        objectData.salesTeamList === null
                            ? ""
                            : objectData.salesTeamList.map((salesTeamListObj, index) => {
                                let salesTeamListData = {
                                    index: index + 1,
                                    id:
                                        salesTeamListObj.id === null
                                            ? ""
                                            : salesTeamListObj.id,
                                    userId:
                                        salesTeamListObj.userId === null
                                            ? ""
                                            : salesTeamListObj.userId,
                                    userName:
                                        salesTeamListObj.userName === null
                                            ? ""
                                            : salesTeamListObj.userName,
                                    stockStakeHolderTypeId:
                                        salesTeamListObj.stockStakeHolderTypeId === null
                                            ? ""
                                            : salesTeamListObj.stockStakeHolderTypeId,
                                    name:
                                        salesTeamListObj.name === null
                                            ? ""
                                            : salesTeamListObj.name,
                                    grandTotalAmt: salesTeamListObj.grandTotalAmt === null
                                        ? ""
                                        : salesTeamListObj.grandTotalAmt,

                                    grandTotalQty: salesTeamListObj.grandTotalQty === null
                                        ? ""
                                        : salesTeamListObj.grandTotalQty,
                                    whatsAppNo:
                                        salesTeamListObj.whatsAppNo === null
                                            ? ""
                                            : salesTeamListObj.whatsAppNo,
                                    contactNo:
                                        salesTeamListObj.contactNo === null
                                            ? ""
                                            : salesTeamListObj.contactNo,
                                    address:
                                        salesTeamListObj.address === null
                                            ? ""
                                            : salesTeamListObj.address,
                                    employeeCode:
                                        salesTeamListObj.employeeCode === null
                                            ? ""
                                            : salesTeamListObj.employeeCode,
                                    accessRoleId:
                                        salesTeamListObj.accessRoleId === null
                                            ? ""
                                            : salesTeamListObj.accessRoleId,
                                    type:
                                        salesTeamListObj.type === null
                                            ? ""
                                            : salesTeamListObj.type,
                                    isActive:
                                        salesTeamListObj.isActive === null
                                            ? ""
                                            : salesTeamListObj.isActive,
                                    insertDateTime:
                                        salesTeamListObj.insertDateTime === null
                                            ? ""
                                            : salesTeamListObj.insertDateTime,
                                };
                                return salesTeamListData;
                            }),

                    salesTeamWithSubCategoryValues:
                        objectData.salesTeamWithSubCategoryValues === null
                            ? ""
                            : objectData.salesTeamWithSubCategoryValues.map(
                                (salesTeamWithSubCategoryValuesObj, index) => {
                                    let salesTeamWithCategoryValuesData = {
                                        index: index + 1,
                                        id:
                                            salesTeamWithSubCategoryValuesObj.id === null
                                                ? ""
                                                : salesTeamWithSubCategoryValuesObj.id,
                                        salesTeamId:
                                            salesTeamWithSubCategoryValuesObj.salesTeamId === null
                                                ? ""
                                                : salesTeamWithSubCategoryValuesObj.salesTeamId,
                                        salesTeamName:
                                            salesTeamWithSubCategoryValuesObj.salesTeamName === null
                                                ? ""
                                                : salesTeamWithSubCategoryValuesObj.salesTeamName,
                                        categoryId:
                                            salesTeamWithSubCategoryValuesObj.categoryId === null
                                                ? ""
                                                : salesTeamWithSubCategoryValuesObj.categoryId,
                                        categoryName:
                                            salesTeamWithSubCategoryValuesObj.categoryName === null
                                                ? ""
                                                : salesTeamWithSubCategoryValuesObj.categoryName,
                                        subCategoryId:
                                            salesTeamWithSubCategoryValuesObj.subCategoryId === null
                                                ? ""
                                                : salesTeamWithSubCategoryValuesObj.subCategoryId,
                                        subCategoryName:
                                            salesTeamWithSubCategoryValuesObj.subCategoryName === null
                                                ? ""
                                                : salesTeamWithSubCategoryValuesObj.subCategoryName,
                                        totalAmt:
                                            salesTeamWithSubCategoryValuesObj.totalAmt === null
                                                ? ""
                                                : salesTeamWithSubCategoryValuesObj.totalAmt,
                                        totalQty:
                                            salesTeamWithSubCategoryValuesObj.totalQty === null
                                                ? ""
                                                : salesTeamWithSubCategoryValuesObj.totalQty,
                                    };
                                    return salesTeamWithCategoryValuesData;
                                }
                            ),
                };

                dispatch(salesTeamSubCategoryWiseDataSuccess({ row: responseDataObj }));
                return { response: responseDataObj, success };
            }
            return { response: {}, success };
        });
        return response;
    } catch (e) {
        return console.error(e.message);
    }
};

