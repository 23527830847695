import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  offer: [],
  offerComplete: [],
  offerList: {},
  offerPastData: [],
  offerCurrentData: [],
  offerFutureData: [],
};
let URL = endpoints.offer;
const offerSlice = createSlice({
  name: "offer",
  initialState,
  reducers: {
    offerSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        offer: row,
      };
    },

    offerByIdSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        offerList: row,
      };
    },

    offerPastDataSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        offerPastData: row,
      };
    },

    offerCurrentDataSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        offerCurrentData: row,
      };
    },

    offerFutureDataSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        offerFutureData: row,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  offerSuccess,
  offerByIdSuccess,
  offerPastDataSuccess,
  offerCurrentDataSuccess,
  offerFutureDataSuccess,
  resetState,
} = offerSlice.actions;

export default offerSlice.reducer;

export const getOffer = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL,
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        const row = content.map((offerObject, index) => {
          let offerData = {
            index: index + 1,
            id: offerObject.id === null ? "" : offerObject.id,
            name: offerObject.name === null ? "" : offerObject.name,
            discription:
              offerObject.discription === null ? "" : offerObject.discription,
            fromDate: offerObject.fromDate === null ? "" : offerObject.fromDate,
            toDate: offerObject.toDate === null ? "" : offerObject.toDate,
            type: offerObject.type === null ? "" : offerObject.type,
            fromRangeDate:
              offerObject.fromRangeDate === null
                ? ""
                : offerObject.fromRangeDate,
            toRangeDate:
              offerObject.toRangeDate === null ? "" : offerObject.toRangeDate,
            offerFor:
              offerObject.offerFor === null
                ? ""
                : offerObject.offerFor == 0
                ? "Outlet"
                : "Stock Holder",
            stockStakeHolderType:
              offerObject.stockStakeHolderType === null
                ? ""
                : offerObject.stockStakeHolderType,
            level: offerObject.level === null ? "" : offerObject.level,
            levelWiseType:
              offerObject.levelWiseType === null
                ? ""
                : offerObject.levelWiseType,
            applicableAmount:
              offerObject.applicableAmount === null
                ? ""
                : offerObject.applicableAmount,
            discountAmount:
              offerObject.discountAmount === null
                ? ""
                : offerObject.discountAmount,
            discountPercentage:
              offerObject.discountPercentage === null
                ? ""
                : offerObject.discountPercentage,
            product: offerObject.product === null ? "" : offerObject.product,
            qty: offerObject.qty === null ? "" : offerObject.qty,
            isActive: offerObject.isActive === null ? "" : offerObject.isActive,
          };
          return offerData;
        });
        dispatch(offerSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getOfferList =
  ({ fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/by-dates?fromDate=" + fromDate + "&toDate=" + toDate,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((offerListObject, index) => {
            let offerListData = {
              index: index + 1,
              id: offerListObject.id === null ? "" : offerListObject.id,
              name: offerListObject.name === null ? "" : offerListObject.name,
              discription:
                offerListObject.discription === null
                  ? ""
                  : offerListObject.discription,
              fromDate:
                offerListObject.fromDate === null
                  ? ""
                  : offerListObject.fromDate,
              toDate:
                offerListObject.toDate === null ? "" : offerListObject.toDate,
              type: offerListObject.type === null ? "" : offerListObject.type,
              fromRangeDate:
                offerListObject.fromRangeDate === null
                  ? ""
                  : offerListObject.fromRangeDate,
              toRangeDate:
                offerListObject.toRangeDate === null
                  ? ""
                  : offerListObject.toRangeDate,
              offerFor:
                offerListObject.offerFor === null
                  ? ""
                  : offerListObject.offerFor == 0
                  ? "Outlet"
                  : "Stock Holder",
              stockStakeHolderType:
                offerListObject.stockStakeHolderType === null
                  ? ""
                  : offerListObject.stockStakeHolderType,
              level:
                offerListObject.level === null ? "" : offerListObject.level,
              levelWiseType:
                offerListObject.levelWiseType === null
                  ? ""
                  : offerListObject.levelWiseType,
              applicableAmount:
                offerListObject.applicableAmount === null
                  ? ""
                  : offerListObject.applicableAmount,
              discountAmount:
                offerListObject.discountAmount === null
                  ? ""
                  : offerListObject.discountAmount,
              discountPercentage:
                offerListObject.discountPercentage === null
                  ? ""
                  : offerListObject.discountPercentage,
              product:
                offerListObject.product === null ? "" : offerListObject.product,
              qty: offerListObject.qty === null ? "" : offerListObject.qty,
              isActive:
                offerListObject.isActive === null
                  ? ""
                  : offerListObject.isActive,
            };
            return offerListData;
          });
          dispatch(offerSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getOfferListComplete =
  ({ fromDate, toDate, level, levelWiseType, flag }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          flag == 0
            ? URL +
              "/by-level-and-level-wise-type-date?fromDate=" +
              fromDate +
              "&toDate=" +
              toDate +
              "&level=" +
              level +
              "&levelWiseType=" +
              levelWiseType +
              "&flag=" +
              flag
            : flag == 1
            ? URL +
              "/by-level-and-level-wise-type-date?fromDate=" +
              fromDate +
              "&toDate=" +
              toDate +
              "&level=" +
              level +
              "&levelWiseType=" +
              levelWiseType +
              "&flag=" +
              flag
            : URL +
              "/by-level-and-level-wise-type-date?fromDate=" +
              fromDate +
              "&toDate=" +
              toDate +
              "&level=" +
              level +
              "&levelWiseType=" +
              levelWiseType +
              "&flag=" +
              flag,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((offerListObject, index) => {
            let offerListData = {
              index: index + 1,
              id: offerListObject.id === null ? "" : offerListObject.id,
              name: offerListObject.name === null ? "" : offerListObject.name,
              discription:
                offerListObject.discription === null
                  ? ""
                  : offerListObject.discription,
              fromDate:
                offerListObject.fromDate === null
                  ? ""
                  : offerListObject.fromDate,
              toDate:
                offerListObject.toDate === null ? "" : offerListObject.toDate,
              type: offerListObject.type === null ? "" : offerListObject.type,
              fromRangeDate:
                offerListObject.fromRangeDate === null
                  ? ""
                  : offerListObject.fromRangeDate,
              toRangeDate:
                offerListObject.toRangeDate === null
                  ? ""
                  : offerListObject.toRangeDate,
              offerFor:
                offerListObject.offerFor === null
                  ? ""
                  : offerListObject.offerFor == 0
                  ? "Outlet"
                  : "Stock Holder",
              stockStakeHolderType:
                offerListObject.stockStakeHolderType === null
                  ? ""
                  : offerListObject.stockStakeHolderType,
              level:
                offerListObject.level === null ? "" : offerListObject.level,
              levelWiseType:
                offerListObject.levelWiseType === null
                  ? ""
                  : offerListObject.levelWiseType,
              applicableAmount:
                offerListObject.applicableAmount === null
                  ? ""
                  : offerListObject.applicableAmount,
              discountAmount:
                offerListObject.discountAmount === null
                  ? ""
                  : offerListObject.discountAmount,
              discountPercentage:
                offerListObject.discountPercentage === null
                  ? ""
                  : offerListObject.discountPercentage,
              product:
                offerListObject.product === null ? "" : offerListObject.product,
              qty: offerListObject.qty === null ? "" : offerListObject.qty,
              isActive:
                offerListObject.isActive === null
                  ? ""
                  : offerListObject.isActive,
              isApplied:
                offerListObject.isApplied === null
                  ? ""
                  : offerListObject.isApplied,
              isShow:
                offerListObject.isShow === null ? "" : offerListObject.isShow,
            };
            return offerListData;
          });
          if (flag == 0) {
            dispatch(offerCurrentDataSuccess({ row }));
          } else if (flag == 1) {
            dispatch(offerFutureDataSuccess({ row }));
          } else {
            dispatch(offerPastDataSuccess({ row }));
          }
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getOfferHeaderById =
  ({ offerHeaderId }) =>
  async (dispatch) => {
    try {
      const data = apiGet({
        url: URL + "/" + offerHeaderId,
      }).then(({ data, success }) => {
        let row;
        if (success) {
          const offerHeaderObject = data.data;
          let offerHeaderData = {
            id: offerHeaderObject.id === null ? "" : offerHeaderObject.id,
            name: offerHeaderObject.name === null ? "" : offerHeaderObject.name,
            discription:
              offerHeaderObject.discription === null
                ? ""
                : offerHeaderObject.discription,
            fromDate:
              offerHeaderObject.fromDate === null
                ? ""
                : offerHeaderObject.fromDate,
            toDate:
              offerHeaderObject.toDate === null ? "" : offerHeaderObject.toDate,
            type:
              offerHeaderObject.type === null
                ? ""
                : offerHeaderObject.type == 0
                ? "Regular"
                : "Date Range",
            fromRangeDate:
              offerHeaderObject.fromRangeDate === null
                ? "-"
                : offerHeaderObject.fromRangeDate,
            toRangeDate:
              offerHeaderObject.toRangeDate === null
                ? "-"
                : offerHeaderObject.toRangeDate,
            offerFor:
              offerHeaderObject.offerFor === null
                ? ""
                : offerHeaderObject.offerFor == 0
                ? "Outlet"
                : "Stock Holder",
            stockStakeHolderType:
              offerHeaderObject.stockStakeHolderType === null
                ? ""
                : offerHeaderObject.stockStakeHolderType,
            stockStakeHolderTypeName:
              offerHeaderObject.stockStakeHolderType === null
                ? "-"
                : offerHeaderObject.stockStakeHolderType.name,
            level:
              offerHeaderObject.level === null ? "" : offerHeaderObject.level,
            levelName:
              offerHeaderObject.level === null
                ? "-"
                : offerHeaderObject.level == 0
                ? "Bill Level"
                : "Item Level",
            levelWiseType:
              offerHeaderObject.levelWiseType === null
                ? ""
                : offerHeaderObject.levelWiseType,
            levelWiseTypeName:
              offerHeaderObject.levelWiseType === null
                ? "-"
                : offerHeaderObject.levelWiseType == 0
                ? "Flat %"
                : offerHeaderObject.levelWiseType == 1
                ? "Flat Amount"
                : offerHeaderObject.levelWiseType == 2
                ? "% Up To Given Amount"
                : offerHeaderObject.levelWiseType == 3
                ? "Bill-Wise Free Item"
                : offerHeaderObject.levelWiseType == 4
                ? "% Wise Item"
                : offerHeaderObject.levelWiseType == 5
                ? "Item-Wise Free Item"
                : offerHeaderObject.levelWiseType == 6
                ? "Free Item On Item"
                : offerHeaderObject.levelWiseType == 7
                ? "Free Item On Item"
                : offerHeaderObject.levelWiseType == 8
                ? "Flat Amount Off"
                : offerHeaderObject.levelWiseType == 9
                ? "Per Unit Value"
                : "",
            applicableAmount:
              offerHeaderObject.applicableAmount === null
                ? "-"
                : offerHeaderObject.applicableAmount.toFixed(2),
            discountAmount:
              offerHeaderObject.discountAmount === null
                ? "-"
                : offerHeaderObject.discountAmount.toFixed(2),
            discountPercentage:
              offerHeaderObject.discountPercentage === null
                ? "-"
                : offerHeaderObject.discountPercentage.toFixed(2),
            productIds:
              offerHeaderObject.productIds === null
                ? ""
                : offerHeaderObject.productIds,
            product:
              offerHeaderObject.product === null
                ? ""
                : offerHeaderObject.product,
            productNames:
              offerHeaderObject.productNames === null
                ? "-"
                : offerHeaderObject.productNames,
            qty:
              offerHeaderObject.qty === null
                ? "-"
                : offerHeaderObject.qty.toFixed(2),
            isActive:
              offerHeaderObject.isActive === null
                ? ""
                : offerHeaderObject.isActive,
            insertDateTime:
              offerHeaderObject.insertDateTime === null
                ? ""
                : offerHeaderObject.insertDateTime,
            offerTypeName:
              offerHeaderObject.offerType == 0
                ? "Bill-Wise"
                : "Credit Note-Wise",
            calculatedByName:
              offerHeaderObject.calculatedBy == 0
                ? "Secondary Qty"
                : "Primary Qty",
            maxValueName:
              offerHeaderObject.maxValue === null
                ? "-"
                : offerHeaderObject.maxValue.toFixed(2),
            flatAmountValue:
              offerHeaderObject.flatAmount === null
                ? "-"
                : offerHeaderObject.flatAmount.toFixed(2),
            offerDetails:
              offerHeaderObject.offerDetails === null
                ? ""
                : offerHeaderObject.offerDetails.map(
                    (offerDetailsObject, index) => {
                      let offerDetailsData = {
                        index: index + 1,
                        id:
                          offerDetailsObject.id === null
                            ? ""
                            : offerDetailsObject.id,
                        product:
                          offerDetailsObject.product === null
                            ? ""
                            : offerDetailsObject.product,
                        productName:
                          offerDetailsObject.product === null
                            ? "-"
                            : offerDetailsObject.product.name,
                        qty:
                          offerDetailsObject.qty === null
                            ? "-"
                            : offerDetailsObject.qty.toFixed(2),
                        percentage:
                          offerDetailsObject.percentage === null
                            ? ""
                            : offerDetailsObject.percentage.toFixed(2),
                        insertDateTime:
                          offerDetailsObject.insertDateTime === null
                            ? ""
                            : offerDetailsObject.insertDateTime,
                      };
                      return offerDetailsData;
                    }
                  ),
          };
          dispatch(offerByIdSuccess({ row: offerHeaderData }));
          return { data: offerHeaderData, success };
        }
        return { data: {}, success };
      });
      return data;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getOfferListPast =
  ({ flag, fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/past?flag=" + flag + "&fromDate=" + fromDate + "&toDate",
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((offerPastObject, index) => {
            let offerPastData = {
              index: index + 1,
              id: offerPastObject.id === null ? "" : offerPastObject.id,
              name: offerPastObject.name === null ? "" : offerPastObject.name,
              discription:
                offerPastObject.discription === null
                  ? ""
                  : offerPastObject.discription,
              fromDate:
                offerPastObject.fromDate === null
                  ? ""
                  : offerPastObject.fromDate,
              toDate:
                offerPastObject.toDate === null ? "" : offerPastObject.toDate,
              type: offerPastObject.type === null ? "" : offerPastObject.type,
              fromRangeDate:
                offerPastObject.fromRangeDate === null
                  ? ""
                  : offerPastObject.fromRangeDate,
              toRangeDate:
                offerPastObject.toRangeDate === null
                  ? ""
                  : offerPastObject.toRangeDate,
              offerFor:
                offerPastObject.offerFor === null
                  ? ""
                  : offerPastObject.offerFor == 0
                  ? "Outlet"
                  : "Stock Holder",
              stockStakeHolderType:
                offerPastObject.stockStakeHolderType === null
                  ? ""
                  : offerPastObject.stockStakeHolderType,
              level:
                offerPastObject.level === null ? "" : offerPastObject.level,
              levelWiseType:
                offerPastObject.levelWiseType === null
                  ? ""
                  : offerPastObject.levelWiseType,
              applicableAmount:
                offerPastObject.applicableAmount === null
                  ? ""
                  : offerPastObject.applicableAmount,
              discountAmount:
                offerPastObject.discountAmount === null
                  ? ""
                  : offerPastObject.discountAmount,
              discountPercentage:
                offerPastObject.discountPercentage === null
                  ? ""
                  : offerPastObject.discountPercentage,
              product:
                offerPastObject.product === null ? "" : offerPastObject.product,
              qty: offerPastObject.qty === null ? "" : offerPastObject.qty,
              isActive:
                offerPastObject.isActive === null
                  ? ""
                  : offerPastObject.isActive,
              isApplied:
                offerPastObject.isApplied === null
                  ? ""
                  : offerPastObject.isApplied,
              insertDateTime:
                offerPastObject.insertDateTime === null
                  ? ""
                  : offerPastObject.insertDateTime,
              isShow:
                offerPastObject.isShow === null ? "" : offerPastObject.isShow,
            };
            return offerPastData;
          });
          dispatch(offerPastDataSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getOfferListCurrent =
  ({ flag, level, levelWiseType }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/current?flag=" +
          flag +
          "&level=" +
          level +
          "&levelWiseType=" +
          levelWiseType,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((offerCurrentDataObject, index) => {
            let offerCurrentData = {
              index: index + 1,
              id:
                offerCurrentDataObject.id === null
                  ? ""
                  : offerCurrentDataObject.id,
              name:
                offerCurrentDataObject.name === null
                  ? ""
                  : offerCurrentDataObject.name,
              discription:
                offerCurrentDataObject.discription === null
                  ? ""
                  : offerCurrentDataObject.discription,
              fromDate:
                offerCurrentDataObject.fromDate === null
                  ? ""
                  : offerCurrentDataObject.fromDate,
              toDate:
                offerCurrentDataObject.toDate === null
                  ? ""
                  : offerCurrentDataObject.toDate,
              type:
                offerCurrentDataObject.type === null
                  ? ""
                  : offerCurrentDataObject.type,
              fromRangeDate:
                offerCurrentDataObject.fromRangeDate === null
                  ? ""
                  : offerCurrentDataObject.fromRangeDate,
              toRangeDate:
                offerCurrentDataObject.toRangeDate === null
                  ? ""
                  : offerCurrentDataObject.toRangeDate,
              offerFor:
                offerCurrentDataObject.offerFor === null
                  ? ""
                  : offerCurrentDataObject.offerFor == 0
                  ? "Outlet"
                  : "Stock Holder",
              stockStakeHolderType:
                offerCurrentDataObject.stockStakeHolderType === null
                  ? ""
                  : offerCurrentDataObject.stockStakeHolderType,
              level:
                offerCurrentDataObject.level === null
                  ? ""
                  : offerCurrentDataObject.level,
              levelWiseType:
                offerCurrentDataObject.levelWiseType === null
                  ? ""
                  : offerCurrentDataObject.levelWiseType,
              applicableAmount:
                offerCurrentDataObject.applicableAmount === null
                  ? ""
                  : offerCurrentDataObject.applicableAmount,
              discountAmount:
                offerCurrentDataObject.discountAmount === null
                  ? ""
                  : offerCurrentDataObject.discountAmount,
              discountPercentage:
                offerCurrentDataObject.discountPercentage === null
                  ? ""
                  : offerCurrentDataObject.discountPercentage,
              product:
                offerCurrentDataObject.product === null
                  ? ""
                  : offerCurrentDataObject.product,
              qty:
                offerCurrentDataObject.qty === null
                  ? ""
                  : offerCurrentDataObject.qty,
              isActive:
                offerCurrentDataObject.isActive === null
                  ? ""
                  : offerCurrentDataObject.isActive,
              isApplied:
                offerCurrentDataObject.isApplied === null
                  ? ""
                  : offerCurrentDataObject.isApplied,
              insertDateTime:
                offerCurrentDataObject.insertDateTime === null
                  ? ""
                  : offerCurrentDataObject.insertDateTime,
              isShow:
                offerCurrentDataObject.isShow === null
                  ? ""
                  : offerCurrentDataObject.isShow,
            };
            return offerCurrentData;
          });
          dispatch(offerCurrentDataSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getOfferListFuture =
  ({ flag, level, levelWiseType }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/future?flag=" +
          flag +
          "&level=" +
          level +
          "&levelWiseType=" +
          levelWiseType,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((offerFutureDataObject, index) => {
            let offerFutureData = {
              index: index + 1,
              id:
                offerFutureDataObject.id === null
                  ? ""
                  : offerFutureDataObject.id,
              name:
                offerFutureDataObject.name === null
                  ? ""
                  : offerFutureDataObject.name,
              discription:
                offerFutureDataObject.discription === null
                  ? ""
                  : offerFutureDataObject.discription,
              fromDate:
                offerFutureDataObject.fromDate === null
                  ? ""
                  : offerFutureDataObject.fromDate,
              toDate:
                offerFutureDataObject.toDate === null
                  ? ""
                  : offerFutureDataObject.toDate,
              type:
                offerFutureDataObject.type === null
                  ? ""
                  : offerFutureDataObject.type,
              fromRangeDate:
                offerFutureDataObject.fromRangeDate === null
                  ? ""
                  : offerFutureDataObject.fromRangeDate,
              toRangeDate:
                offerFutureDataObject.toRangeDate === null
                  ? ""
                  : offerFutureDataObject.toRangeDate,
              offerFor:
                offerFutureDataObject.offerFor === null
                  ? ""
                  : offerFutureDataObject.offerFor == 0
                  ? "Outlet"
                  : "Stock Holder",
              stockStakeHolderType:
                offerFutureDataObject.stockStakeHolderType === null
                  ? ""
                  : offerFutureDataObject.stockStakeHolderType,
              level:
                offerFutureDataObject.level === null
                  ? ""
                  : offerFutureDataObject.level,
              levelWiseType:
                offerFutureDataObject.levelWiseType === null
                  ? ""
                  : offerFutureDataObject.levelWiseType,
              applicableAmount:
                offerFutureDataObject.applicableAmount === null
                  ? ""
                  : offerFutureDataObject.applicableAmount,
              discountAmount:
                offerFutureDataObject.discountAmount === null
                  ? ""
                  : offerFutureDataObject.discountAmount,
              discountPercentage:
                offerFutureDataObject.discountPercentage === null
                  ? ""
                  : offerFutureDataObject.discountPercentage,
              product:
                offerFutureDataObject.product === null
                  ? ""
                  : offerFutureDataObject.product,
              qty:
                offerFutureDataObject.qty === null
                  ? ""
                  : offerFutureDataObject.qty,
              isActive:
                offerFutureDataObject.isActive === null
                  ? ""
                  : offerFutureDataObject.isActive,
              isApplied:
                offerFutureDataObject.isApplied === null
                  ? ""
                  : offerFutureDataObject.isApplied,
              insertDateTime:
                offerFutureDataObject.insertDateTime === null
                  ? ""
                  : offerFutureDataObject.insertDateTime,
              isShow:
                offerFutureDataObject.isShow === null
                  ? ""
                  : offerFutureDataObject.isShow,
            };
            return offerFutureData;
          });
          dispatch(offerFutureDataSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
