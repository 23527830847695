import { Grid, Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { CreditNoteForOutletNewJson } from "../../DynamicFormsJson/Transaction/creditNoteForOutletNew";
import { getAreaByUserType } from "../../Slice/area.slice";
import { getBeatByArea } from "../../Slice/beat.slice";
import {
  getBillFromOutlet,
  getBillHistoryByIdUpdated,
  setBillwiseProductList,
} from "../../Slice/billingHistory.slice";
import {
  getOutletByBeatIds,
  getOutletByUserType,
} from "../../Slice/outlet.slice";
import { getProductByCategory } from "../../Slice/product.slice";
import { ButtonCompo } from "../../components/Comman/Button";
import { withRouter } from "../../components/withRouter";
import endpoint from "../../config/endpoints";
import {
  checkedListEmptyMsg,
  noInternetMsg,
  qtyGreaterThanBillQtyMsg,
  saveFailedMsg,
  saveWarningMsg,
  savemsg,
  serverMsg,
} from "../../config/messageconstant";
import { apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import CreditNoteHeaderForm from "./CreditNoteHeaderForm";
import CreditNoteTableForBillWise from "./CreditNoteTableForBillwise";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";

class CreditNoteByBillWise extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowList: [],
      formErrors: {},
      fieldData: { instockOutstock: "1" },
      searchValue: "",
      selection: [],
      dynamicMasterData: {
        areaDetail: this.props.areaList.area,
        beatDetail: this.props.beatList.beat,
        outletDetail: this.props.outletList.outlets,
        billDetail: this.props.billList.billingByOutlet,
        productDetail: this.props.billList.billProductListById,
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.areaList !== nextProps.areaList) {
      if (
        this.props.areaList?.areaByTaluka !== nextProps.areaList?.areaByTaluka
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.areaByTaluka,
          },
        });
      }
    }
    if (this.props.beatList !== nextProps.beatList) {
      if (this.props.beatList?.beat !== nextProps.beatList?.beat) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            beatDetail: nextProps.beatList?.beat,
          },
        });
      }
    }
    if (this.props.outletList !== nextProps.outletList) {
      if (this.props.outletList?.outlets !== nextProps.outletList?.outlets) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            outletDetail: nextProps.outletList?.outlets,
          },
        });
      }
    }
    if (this.props.billList !== nextProps.billList) {
      if (
        this.props.billList.billingByOutlet !==
        nextProps.billList.billingByOutlet
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            billDetail: nextProps.billList.billingByOutlet,
          },
        });
      }
    }
    if (this.props.billList !== nextProps.billList) {
      if (
        this.props.billList.billProductListById !==
        nextProps.billList.billProductListById
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            productDetail: nextProps.billList.billProductListById,
          },
        });
      }
    }
  }
  getBill = async (outletIds, fromdate, todate) => {
    const {
      getBeatByArea,
      getBillFromOutlet,
      getProductByCategory,
      showLoador,
      showNotification,
    } = this.props;
    showLoador({ loador: true });
    await getBillFromOutlet({
      outletId: outletIds,
      fromDate: fromdate,
      toDate: todate,
    }).then(({ success }) => {
      if (!success) {
        showLoador({ loador: false });
        showNotification({ msg: serverMsg, severity: "error" });
      } else {
        showLoador({ loador: false });
      }
    });
  };
  getListById = async (data) => {
    const {
      getBeatByArea,
      getOutletByBeatIds,
      getBillHistoryByIdUpdated,
      showLoador,
      showNotification,
    } = this.props;
    const { fieldData } = this.state;
    if (navigator.onLine) {
      if (data.area && data.area != "") {
        showLoador({ loador: true });
        await getBeatByArea({ areaId: data.area.id }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.beat && data.beat != "") {
        showLoador({ loador: true });
        await getOutletByBeatIds({ beatIds: data.beat.id }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (fieldData.fromdate && fieldData.todate && data.outletIds) {
        await this.getBill(
          data.outletIds,
          fieldData.fromdate.format("DD-MM-YYYY"),
          fieldData.todate.format("DD-MM-YYYY")
        );
      }
      if (data.billNo) {
        showLoador({ loador: true });
        await getBillHistoryByIdUpdated({
          outletBillHeaderId: data.billNo,
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            showLoador({ loador: false });
          }
        });
      }
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  };

  async componentDidMount() {
    this.setState({
      rowList: [],
    });
    const { getAreaByUserType, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getAreaByUserType().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  }

  onSearch = (data) => {
    const { rowList, dynamicMasterData } = this.state;

    let productList = dynamicMasterData["productDetail"]
      ? dynamicMasterData["productDetail"].filter(
        (row) => row.id == data.product
      )
      : [];
    let billList = dynamicMasterData["billDetail"]
      ? dynamicMasterData["billDetail"].filter((row) => row.id == data.bill)
      : [];
    this.setState({
      rowList: [
        ...rowList,
        {
          index: rowList.length + 1,
          billName: billList.length != 0 ? billList[0].name : "-",
          productName: productList.length != 0 ? productList[0].name : "-",
          uom: productList.length != 0 ? productList[0].outletUom : "-",
          calStock: data.calStock,
          // ...data,

          total: (data.rate * +data.qty).toFixed(2),
        },
      ],
    });
  };

  onSubmitData = () => {
    const { fieldData } = this.state;
    const { billList, showNotification ,crnType} = this.props;
    if (billList.length !== 0) {
      const creditNoteDetail = [];
      let flag = 0;
      let checkedFlag = 0;
      billList?.billProductListById.map((rowData) => {
        if (rowData.checked) {
          checkedFlag++;
        }
      });
      if (checkedFlag > 0) {
        billList?.billProductListById.map((rowData) => {
          if (+rowData.qty > 0 && rowData.checked) {
            flag = rowData.qtyError ? flag + 1 : flag;
            const rowObj = {
              product: {
                id: rowData.product.id,
              },
              qty: rowData.qty,
              hsnCode: rowData.product.taxPercentage.hsnCode,
              rate: rowData.rate,
              billDetailId: "0",
              batchCode: "B1",
              outletBillDetailId: rowData.id,
            };
            creditNoteDetail.push(rowObj);
          }
        });
        if (flag <= 0 && creditNoteDetail.length !== 0) {
          const dataToSave = {
            crnType: crnType,
            outLet: {
              id: fieldData.outletIds,
            },
            remark: "NA",
            instockOutstock: fieldData.instockOutstock,
            creditNoteDetail: creditNoteDetail,
            date: dayjs().format("DD-MM-YYYY"),
          };
          swal({
            title: "Are you sure?",
            text: saveWarningMsg,
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              this.onSave(dataToSave);
            }
          });
        } else {
          showNotification({
            msg: qtyGreaterThanBillQtyMsg,
            severity: "error",
          });
        }
      } else {
        showNotification({ msg: checkedListEmptyMsg, severity: "error" });
      }
    }
  };
  onSave = (dataToSave) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiPost({
        url: endpoint.creditNote,
        postBody: dataToSave,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          this.props.navigate(-1);
          showNotification({ msg: savemsg });
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  mandatoryCheck = () => {
    let formValid = true;
    const { fieldData } = this.state;

    let formErrors = {};
    CreditNoteForOutletNewJson.fieldMetaBill.forEach((currentField) => {
      if (currentField.isMandatory) {
        if (
          (currentField.controlType === "datepicker" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === "")) ||
          (currentField.controlType !== "datepicker" &&
            currentField.controlType !== "autocomplete" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === null ||
              fieldData[currentField.dataKey].toString().trim() === ""))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        } else if (
          currentField.controlType === "autocomplete" &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey] === "" ||
            (!currentField.isRootLevelKey &&
              fieldData[currentField.dataKey] != undefined &&
              fieldData[currentField.dataKey] != null &&
              fieldData[currentField.dataKey].id == null) ||
            fieldData[currentField.dataKey].id == "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideTextfield") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.error} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideDatepicker") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideAutocomplete") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideMultiSelect") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (fieldData[currentField.dataKey] &&
              fieldData[currentField.dataKey].length == 0))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  ValidationCheck = () => {
    let formValid = true;
    const { fieldData } = this.state;

    let formErrors = {};
    CreditNoteForOutletNewJson.fieldMetaBill.forEach((currentField) => {
      if (currentField.valid) {
        if (
          currentField.validName === "email" &&
          !validEmail.test(fieldData[currentField.dataKey])
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
        if (
          currentField.validName === "zero" &&
          fieldData[currentField.dataKey] === 0
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  checkValidationOnSubmit = () => {
    if (this.mandatoryCheck()) {
      return this.ValidationCheck();
    } else {
      return false;
    }
  };
  onDataChange = async (fieldName, newValue) => {
    const { fieldData } = this.state;
    const dataToSearch = {};
    const dataToReset = { ...this.state.fieldData };
    let listError = false;
    if (
      fieldName == "fromdate" &&
      dataToReset.todate &&
      dataToReset.outletIds
    ) {
      await this.getBill(
        dataToReset.outletIds,
        newValue.format("DD-MM-YYYY"),
        fieldData.todate.format("DD-MM-YYYY")
      );
    }
    if (
      fieldName == "todate" &&
      dataToReset.fromdate &&
      dataToReset.outletIds
    ) {
      await this.getBill(
        dataToReset.outletIds,
        fieldData.fromdate.format("DD-MM-YYYY"),
        newValue.format("DD-MM-YYYY")
      );
    }
    CreditNoteForOutletNewJson.fieldMetaBill.map((currentField) => {
      if (currentField.getDate && currentField.dataKey === fieldName) {
        dataToReset[currentField.getDate] = newValue.add(365, "day");
      }
      if (currentField.dataKey === fieldName && currentField.getListId) {
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
          return null;
        });

        this.getListById(dataToSearch);
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key == fieldName && dataToReset[key]) {
            delete dataToReset[currentField.dataKey];
          }
        });
      }
      if (
        !listError &&
        fieldData[currentField.dataKey] &&
        currentField.controlType != "datepicker" &&
        currentField.getListFrom == undefined
      ) {
        dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
      }
      return null;
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
    });
  };
  setCatList = ({ row }) => {
    const { setBillwiseProductList } = this.props;
    const { fieldData } = this.state;
    setBillwiseProductList({ row });
  };

  getDataForDisabled = (value) => {
    const { dynamicMasterData } = this.state;

    let productList = dynamicMasterData["productDetail"]
      ? dynamicMasterData["productDetail"].filter((row) => row.id == value)
      : [];
    return { rate: productList[0].rate, uom: productList[0].outletUomName };
  };
  onSearchBox = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  getFilteredTableData = (tableData) => {
    const { searchValue } = this.state;
    return tableData.filter((currentRow) => {
      console.log(currentRow);
      let isValid = false;
      if (
        (currentRow["name"] &&
          currentRow["name"]
            .toString()
            .toLowerCase()
            .includes(
              searchValue.trim().toLowerCase()
            )) ||
        (currentRow["outletUom"] &&
          currentRow["outletUom"]
            .toString()
            .toLowerCase()
            .includes(searchValue.trim().toLowerCase())) || (currentRow["rate"] &&
              currentRow["rate"]
                .toString()
                .toLowerCase()
                .includes(searchValue.trim().toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
    });
  };

  getSelectedList = (e) => {
    this.setState({
      selection: e,
    });
    const rowList = this.props.billList?.billProductListById.map((rowData) => {
      const filterData = e.filter((id) => rowData.id == id);
      return { ...rowData, checked: filterData.length != 0 ? true : false };
    });
    this.props.setBillwiseProductList({ row: rowList });
  };


  render() {
    const { dynamicMasterData, rowList, formErrors, fieldData, selection } = this.state;
    const { billList,crnType } = this.props;

    return (
      <>
        <
        >
          <CreditNoteHeaderForm
            getListById={this.getListById}
            dynamicMasterData={dynamicMasterData}
            screenTitle={""}
            fieldMeta={crnType==4?CreditNoteForOutletNewJson.fieldMetaBillWise:CreditNoteForOutletNewJson.fieldMetaBill}
            apiBaseURL={CreditNoteForOutletNewJson.apiBaseURL}
            formErrors={formErrors}
            showSaveBtn={CreditNoteForOutletNewJson.showSaveBtn}
            showSaveBtnMain={false}
            fieldData={fieldData}
            onDataChange={this.onDataChange}
            showBackToList={false}
          />
          <br />
          <LandingScreenHeader
            screenTitle={""}
            showSearchBox={true}
            showTable={true}
            showFilter={false}
            showPdfDownload={false}
            showExcelDownload={false}
            onSearch={this.onSearchBox}
          />
          <CreditNoteTableForBillWise
            selection={selection}
            onSelectionChange={this.getSelectedList}
            tableHead={[
              {
                title: "Sr.No.",
                name: "index",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "center",
              },
              {
                title: "Product Name",
                name: "name",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
              },
              {
                title: "UOM",
                name: "name",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "center",
              },
              {
                title: "Rate",
                name: "name",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
              },
              {
                title: "Bill Quantity",
                name: "billQty",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "right",
              },
              {
                title: "Quantity",
                name: "qty",
                placeHolder: "Enter Target",
                textFieldError: "qtyErr",
                positionCenter: false,
                showInscreen: true,
              },
              {
                title: "Total",
                name: "total",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
              },
            ]}
            data={billList?.billProductListById}
            rowList={billList?.billProductListById}
            setList={this.setCatList}
            rows={this.getFilteredTableData(billList?.billProductListById)}
          />

          <br />
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="right"
          >
            <ButtonCompo
              size="medium"
              type="Submit"
              variant="contained"
              name="Submit"
              onClick={this.onSubmitData}
            />
          </Grid>
        </>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  areaList: state.area,
  beatList: state.beat,
  outletList: state.outlet,
  billList: state.billingHistory,
  productList: state.billingHistory,
});
const mapDispatchToProps = {
  getBeatByArea,
  getOutletByBeatIds,
  showLoador,
  showNotification,
  getBillFromOutlet,
  getProductByCategory,
  getOutletByUserType,
  getBillHistoryByIdUpdated,
  setBillwiseProductList,
  getAreaByUserType,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CreditNoteByBillWise);
