import { Paper } from "@mui/material";
import React from "react";
import HorizontalTabs from "./HorizontalTabs";
import CompanyDashboard from "./CompanyDashboard";

function FloatingTabs() {
  return (
    <>
      <HorizontalTabs />
      <CompanyDashboard />
    </>
  );
}

export default FloatingTabs;
