import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import { DocumentColumns } from "../../../tableColumns/table-columns";
import { documentJson } from "../../../DynamicFormsJson/MastersJSON/document";
import { getDocument } from "../../../Slice/document.slice";
class DocumentTable extends React.Component {
  render() {
    const { documentList, getDocument } = this.props;
    return (
      <>
        <DynamicMainScreen
          formPath={documentJson.formPath}
          screenTitle={documentJson.screenTitle}
          fieldMeta={documentJson.fieldMeta}
          showPdfDownload={false}
          showExcelDownload={true}
          changeExcelURL={"document-data" + '?sort={"insertDateTime": "DESC"}'}
          filterDataExcel={true}
          showExtension={false}
          pdfFileName={documentJson.pdfFileName}
          excelFileName={documentJson.excelFileName}
          showAddButton={documentJson.showAddButton}
          tableColumnsToFilter={documentJson.tableColumnsToFilter}
          tableColumns={DocumentColumns}
          tableData={documentList?.document}
          getTableData={getDocument}
          apiBaseURL={documentJson.apiBaseURL}
          isNote={true}
          showDeleteIcon={false}
          isNoteValue={"Documents That Are To Be Collected From The Stock Holders"}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  documentList: state.document,
});
const mapDispatchToProps = {
  getDocument,
};
export default connect(mapStateToProps, mapDispatchToProps)(DocumentTable);
