import React from "react";
import { connect } from "react-redux";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { AddDistributorJson } from "../../DynamicFormsJson/Transaction/addDistributor";

import { showLoador, showNotification } from "../Landing/Landing.slice";
import {
    serverMsg,
    noInternetMsg,
    saveWarningMsg,
    saveFailedMsg,
    savemsg,
} from "../../config/messageconstant";
import endpoint from "../../config/endpoints";
import * as msgConstant from "../../config/messageconstant";
import { compose } from "@reduxjs/toolkit";
import { withRouter } from "../../components/withRouter";
import { apiGet } from "../../utils/api_service";
import { getRegionNew } from "../../Slice/region.slice";
import { getStateByRegion } from "../../Slice/state.slice";
import { getZoneByState } from "../../Slice/zone.slice";
import { getDistrictByZone } from "../../Slice/district.slice";
import { getTalukaByDistrictPost } from "../../Slice/taluka.slice";
import { getAreaByTalukaPostBodyUpdated, getAreaByUserType } from "../../Slice/area.slice";
import { saveDistributorInquiry, setSaveDistributorInquiry } from "../../Slice/distributorInquiry.slice";

class PartnerDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fieldData: {},
            userTypeId: "",
            dynamicMasterData: {
                locationDetail: this.props.accountLedger?.location,
                accountLedgerGroupMasterDetail:
                    this.props.accountLedger?.accountLedgerGroupMaster,
            },
        };
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.regionList !== nextProps.regionList) {
            if (this.props.regionList?.regionNew !== nextProps.regionList?.regionNew) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        regionDetail: nextProps.regionList?.regionNew,
                    },
                });
            }
        }
        if (this.props.stateList !== nextProps.stateList) {
            if (
                this.props.stateList?.stateByRegion !==
                nextProps.stateList?.stateByRegion
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        stateDetail: nextProps.stateList?.stateByRegion,
                    },
                });
            }
        }
        if (this.props.zoneList !== nextProps.zoneList) {
            if (
                this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        zoneDetail: nextProps.zoneList?.zoneByState,
                    },
                });
            }
        }
        if (this.props.districtList !== nextProps.districtList) {
            if (
                this.props.districtList?.districtByZone !==
                nextProps.districtList?.districtByZone
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        districtDetail: nextProps.districtList?.districtByZone,
                    },
                });
            }
        }
        if (this.props.talukaList !== nextProps.talukaList) {
            if (
                this.props.talukaList?.talukaByDistrict !==
                nextProps.talukaList?.talukaByDistrict
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        talukaDetail: nextProps.talukaList?.talukaByDistrict,
                    },
                });
            }
        }
        if (this.props.areaList !== nextProps.areaList) {
            if (this.state.userTypeId == 1 || this.state.userTypeId == 6) {
                if (
                    this.props.areaList?.areaByTaluka !== nextProps.areaList?.areaByTaluka
                ) {
                    this.setState({
                        dynamicMasterData: {
                            ...this.state.dynamicMasterData,
                            areaDetail: nextProps.areaList?.areaByTaluka,
                        },
                    });
                }
            } else {
                if (
                    this.props.areaList?.area !== nextProps.areaList?.area
                ) {
                    this.setState({
                        dynamicMasterData: {
                            ...this.state.dynamicMasterData,
                            areaDetail: nextProps.areaList?.area,
                        },
                    });
                }
            }

        }

    }

    async componentDidMount() {
        const {
            getRegionNew,
            showLoador,
            showNotification,
            getAreaByUserType,
        } = this.props;

        if (navigator.onLine) {

            const userTypeId = localStorage.getItem("userTypeId");
            this.setState({ userTypeId: userTypeId });

            if (userTypeId == 1 || userTypeId == 6) {
                await getRegionNew().then(({ response, success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            } else {
                await getAreaByUserType().then(({ response, success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }

        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }

    onSubmitData = (dataToSave) => {
        const { distributorInquiryList } = this.props;

        dataToSave = {
            ...distributorInquiryList.distributorInquiryData,
            ...dataToSave,
        };
        console.log("dataToSave");
        console.log(dataToSave);
        swal({
            title: "Are you sure?",
            text: msgConstant.saveWarningMsg,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                console.log(dataToSave);
                this.onSave(dataToSave);
            }
        });
    };

    onSave = (dataToSave) => {
        const { showLoador, showNotification, saveDistributorInquiry } = this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            saveDistributorInquiry({ dataToSave }).then(({ response, success }) => {
                showLoador({ loador: false });
                if (success) {
                    showNotification({
                        msg: msgConstant.savemsg,
                    });
                    this.props.changeTab("2");
                } else {
                    showNotification({ msg: saveFailedMsg, severity: "error" });
                }
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };

    getDataById = (id) => {
        const { showLoador, setSaveDistributorInquiry, showNotification } = this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            const response = apiGet({
                url: endpoint.distributorInquiry + "/" + id,
            }).then(({ data, success }) => {
                showLoador({ loador: false });
                if (success) {
                    if (!data.error) {
                        let rowData = data.data;

                        setSaveDistributorInquiry({ rowObject: rowData });

                        return {
                            data: rowData,
                            success,
                        };
                    } else {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                    return { data: {}, success: false };
                }
                return { response: {}, success: false };
            });
            return response;
        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };



    getListById = async (data) => {
        const {
            getStateByRegion,
            getZoneByState,
            getDistrictByZone,
            getTalukaByDistrictPost,
            getAreaByTalukaPostBodyUpdated,
            showLoador,
            showNotification,
        } = this.props;
        if (navigator.onLine) {
            if (data.partnerCurrentRegionId && data.partnerCurrentRegionId != "") {
                showLoador({ loador: true });
                await getStateByRegion({ regionIds: data.partnerCurrentRegionId }).then(
                    ({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    }
                );
            }
            if (data.partnerCurrentStateId && data.partnerCurrentStateId != "") {
                showLoador({ loador: true });
                await getZoneByState({ stateIds: data.partnerCurrentStateId }).then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }
            if (data.partnerCurrentZoneIds && data.partnerCurrentZoneIds != "") {
                showLoador({ loador: true });
                await getDistrictByZone({ zoneIds: data.partnerCurrentZoneIds }).then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }
            if (data.partnerCurrentDistrictIds && data.partnerCurrentDistrictIds != "") {
                let districtIdJson = {
                    districtIds: typeof data.partnerCurrentDistrictIds === "string"
                        ? data.partnerCurrentDistrictIds.split(",")
                        : data.partnerCurrentDistrictIds,
                }

                showLoador({ loador: true });
                await getTalukaByDistrictPost({ districtIds: districtIdJson }).then(
                    ({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    }
                );
            }
            if (data.partnerCurrentTalukaIds && data.partnerCurrentTalukaIds != "") {
                let talukaJson = {
                    talukaIds: typeof data.partnerCurrentTalukaIds === "string"
                        ? data.partnerCurrentTalukaIds.split(",")
                        : data.partnerCurrentTalukaIds,
                }
                showLoador({ loador: true });
                await getAreaByTalukaPostBodyUpdated({ talukaIds: talukaJson }).then(
                    ({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    }
                );
            }
        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };

    render() {
        const { dynamicMasterData, userTypeId } = this.state;
        return (
            <>
                <DynamicFormWithoutSave
                    formPath={AddDistributorJson.formPath}
                    fieldMeta={userTypeId == 1 || userTypeId == 6 ?
                        AddDistributorJson.fieldMetaForPartnerDetails :
                        AddDistributorJson.fieldMetaForPartnerDetailsWithoutHierarchy
                    }
                    tableColumnsToFilter={AddDistributorJson.tableColumnsToFilter}
                    apiBaseURL={AddDistributorJson.apiBaseURL}
                    baseIdColumn={AddDistributorJson.baseIdColumn}
                    dynamicMasterData={dynamicMasterData}
                    showSaveBtnMain={false}
                    showSaveBtn={true}
                    saveBtnText={"Next"}
                    onSave={this.onSubmitData}
                    callApi={true}
                    getByIdApi={true}
                    getDataById={this.getDataById}
                    getListById={this.getListById}
                />
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    distributorInquiryList: state.distributorInquiry,
    areaList: state.area,
    regionList: state.region,
    stateList: state.states,
    zoneList: state.zone,
    districtList: state.district,
    talukaList: state.taluka,
});
const mapDispatchToProps = {
    getRegionNew,
    getStateByRegion,
    getZoneByState,
    getDistrictByZone,
    getTalukaByDistrictPost,
    getAreaByTalukaPostBodyUpdated,
    setSaveDistributorInquiry,
    saveDistributorInquiry,
    getAreaByUserType,
    showLoador,
    showNotification,
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(PartnerDetails);
