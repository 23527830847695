import endpoint from "../../config/endpoints";

export const claimFormJson = {
  formPath: "/claim-form",
  // apiBaseURL: endpoint.category,
  // changeExcel: endpoint.exportData,
  screenTitle: "Employee Claim Details",
  showAddButton: true,
  showPdfDownload: true,
  pdfFileName: "Category",
  showExcelDownload: true,
  excelFileName: "Category",
  //excelUrl: "category-data" + '?sort={"sortOrder": "ASC"}',
  fieldMeta: [
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromDate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "toDate-le",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "toDate",
      onSubmit: "compare",
      onSubmitParameter: "fromDate-ge",
      isMandatory: true,
      onSubmit: "compare",
    },
    {
      label: "Select Claim Type",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      disable: true,
      dataKey: "type",
      tableDataKey: "type",
      defaultValue: "0",
      radioGroupItems: [
        {
          label: "Regular Claim",
          value: "0",
        },
        {
          label: "Allowance Wise",
          value: "1",
        },
        {
          label: "All",
          value: "2",
        },
      ],
      isMandatory: true,
    },
  ],
};
