import CloseIcon from "@mui/icons-material/Close";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { compose } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { ConfigurationPendingJson } from "../../../DynamicFormsJson/MastersJSON/configurationPending";
import { getStockHoldersByNotAssignStructureWithOutletCount } from "../../../Slice/StackHolder.slice";
import {
  getAreaByTalukaPostBody,
  getAreaByTalukaPostBodyNew,
  getAreaByTalukaPostBodyUpdated,
} from "../../../Slice/area.slice";
import { getActiveBeatByArea } from "../../../Slice/beat.slice";
import { getDistrictByZone } from "../../../Slice/district.slice";
import {
  getOutletByAreaNew,
  getOutletByBeatIdsPost,
  getOutletByNotAssignCentralStructure,
} from "../../../Slice/outlet.slice";
import { getRegionNew } from "../../../Slice/region.slice";
import { getSalesTeamWithoutConfigurationByArea, setSalesTeamWithoutConfigurationByArea } from "../../../Slice/salesExecutive.slice";
import { getStateByRegion } from "../../../Slice/state.slice";
import { getTalukaByDistrict } from "../../../Slice/taluka.slice";
import { getZoneByState } from "../../../Slice/zone.slice";
import LandingScreenHeader from "../../../components/Comman/LandingScreenHeader";
import DynamicTable from "../../../components/Comman/RT/MaterialUIRTTable";
import DynamicDetails from "../../../components/dynamicscreens/DynamicDetails";
import DynamicFormWithoutValidation from "../../../components/dynamicscreens/DynamicFormWithoutValidation";
import { withRouter } from "../../../components/withRouter";
import { cyanBlue } from "../../../config/ColorObj";
import endpoint from "../../../config/endpoints";
import { dataNotFoundMsg, noInternetMsg, serverMsg } from "../../../config/messageconstant";
import { apiGet, apiPost } from "../../../utils/api_service";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import { mandatoryCheck } from "../../../config/ValidationCheck";
import { ValidationCheck } from "../../../config/ValidationCheck";
import { downloadF } from "../../../Pages/PDF/PDFDownload";
import { generateExcel } from "../../../components/GeneratePDFExcel";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

class ConfigurationPending extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataRow: "",
      autoCompleteSelectedId: "",
      searchValue: "",
      listClearFlag: false,
      allChecked: false,
      fieldData: { type: "0" },
      formData: {},
      formErrors: {},
      dynamicMasterData: {
        regionDetail: this.props.regionList.region,
      },
      selection: [],
      selectedAreaList: [],
      beatPopup: false,
      outletPopUp: false,
      salesTeamObject: {},
      stockHolderObjet: {},
      beatList: [],
      outletListForPopUp: [],
    };
  }
  async componentDidMount() {
    const { getRegionNew, setSalesTeamWithoutConfigurationByArea, showLoador, showNotification } = this.props;
    if (navigator.onLine) {

      setSalesTeamWithoutConfigurationByArea({ row: [] });

      showLoador({ loador: true });
      await getRegionNew().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.regionList !== nextProps.regionList) {
      if (this.props.regionList?.region !== nextProps.regionList?.region) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            regionDetail: nextProps.regionList?.region,
          },
        });
      }
    }
    if (this.props.stateList !== nextProps.stateList) {
      if (
        this.props.stateList?.stateByRegion !==
        nextProps.stateList?.stateByRegion
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stateDetail: nextProps.stateList?.stateByRegion,
          },
        });
      }
    }
    if (this.props.zoneList !== nextProps.zoneList) {
      if (
        this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            zoneDetail: nextProps.zoneList?.zoneByState,
          },
        });
      }
    }
    if (this.props.districtList !== nextProps.districtList) {
      if (
        this.props.districtList?.districtByZone !==
        nextProps.districtList?.districtByZone
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            districtDetail: nextProps.districtList?.districtByZone,
          },
        });
      }
    }
    if (this.props.talukaList !== nextProps.talukaList) {
      if (
        this.props.talukaList?.talukaByDistrict !==
        nextProps.talukaList?.talukaByDistrict
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            talukaDetail: nextProps.talukaList?.talukaByDistrict,
          },
        });
      }
    }
    if (this.props.areaList !== nextProps.areaList) {
      if (
        this.props.areaList?.areaByTaluka !== nextProps.areaList?.areaByTaluka
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.areaByTaluka,
          },
        });
      }
    }
    if (this.props.areaList !== nextProps.areaList) {
      if (this.props.areaList?.area !== nextProps.areaList?.area) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.area,
          },
        });
      }
    }
  }

  getListById = async (data) => {
    const {
      getStateByRegion,
      getZoneByState,
      getDistrictByZone,
      getTalukaByDistrict,
      getAreaByTalukaPostBodyNew,
      getAreaByTalukaPostBody,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      if (data.region && data.region != "") {
        showLoador({ loador: true });
        await getStateByRegion({ regionIds: data.region }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }

      if (data.state && data.state != "") {
        showLoador({ loador: true });
        await getZoneByState({ stateIds: data.state }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.zone && data.zone != "") {
        showLoador({ loador: true });
        await getDistrictByZone({ zoneIds: data.zone }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.district && data.district != "") {
        showLoador({ loador: true });
        await getTalukaByDistrict({ districtIds: data.district }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.taluka && data.taluka != "") {
        let talukaJson = {
          talukaIds: data.taluka,
        };
        showLoador({ loador: true });
        await getAreaByTalukaPostBodyNew({ talukaIds: talukaJson }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.area && data.area != "") {
        console.log(data.area);
        this.setState({
          selectedAreaList: data.area,
        });
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onSearchBox = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };


  onDistributorClick = () => {
    const { getStockHoldersByNotAssignStructureWithOutletCount, showLoador, showNotification } = this.props;

    if (navigator.onLine) {
      showLoador({ loador: true });
      getStockHoldersByNotAssignStructureWithOutletCount().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }


  onCentralizedOutletClick = () => {
    const { getOutletByNotAssignCentralStructure, showLoador, showNotification } = this.props;

    if (navigator.onLine) {
      showLoador({ loador: true });
      getOutletByNotAssignCentralStructure().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  onDataChange = (fieldName, newValue) => {
    const { fieldData } = this.state;
    const dataToSearch = {};

    if (fieldName === "type") {
      if (newValue == 1) {
        this.onDistributorClick();
      } else if (newValue == 2) {
        this.onCentralizedOutletClick();
      }
    }

    const tableData =
      fieldData.type === "0"
        ? ConfigurationPendingJson.searchListBySalesTeam
        : ConfigurationPendingJson.fieldMeta;
    const dataToReset = { ...this.state.fieldData };
    let listError = false;
    tableData.map((currentField) => {
      if (currentField.getDate && currentField.dataKey === fieldName) {
        dataToReset[currentField.getDate] = newValue.add(365, "day");
      }
      if (currentField.dataKey === fieldName && currentField.getListId) {
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
          return null;
        });

        this.getListById(dataToSearch);
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key == fieldName && dataToReset[key]) {
            delete dataToReset[currentField.dataKey];
          }
        });
      }
      if (
        !listError &&
        fieldData[currentField.dataKey] &&
        currentField.controlType != "datepicker" &&
        currentField.getListFrom == undefined
      ) {
        dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
      }
      return null;
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
    });
  };
  getFilteredTableData = (searchData) => {
    const { searchValue } = this.state;
    return searchData.filter((currentRow) => {
      let isValid = false;
      if (
        currentRow["name"] &&
        currentRow["name"]
          .toString()
          .toLowerCase()
          .includes(searchValue.trim().toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      if (
        currentRow["dayEndClaimForTable"] &&
        currentRow["dayEndClaimForTable"]
          .toString()
          .toLowerCase()
          .includes(searchValue.trim().toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      if (
        currentRow["ruConfigForTable"] &&
        currentRow["ruConfigForTable"]
          .toString()
          .toLowerCase()
          .includes(searchValue.trim().toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      if (
        currentRow["areaNames"] &&
        currentRow["areaNames"]
          .toString()
          .toLowerCase()
          .includes(searchValue.trim().toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      if (
        currentRow["firmName"] &&
        currentRow["firmName"]
          .toString()
          .toLowerCase()
          .includes(searchValue.trim().toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      if (
        currentRow["beatName"] &&
        currentRow["beatName"]
          .toString()
          .toLowerCase()
          .includes(searchValue.trim().toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      if (
        currentRow["stockStakeHolderType"] &&
        currentRow["stockStakeHolderType"]
          .toString()
          .toLowerCase()
          .includes(searchValue.trim().toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      if (
        currentRow["areaName"] &&
        currentRow["areaName"]
          .toString()
          .toLowerCase()
          .includes(searchValue.trim().toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      if (
        currentRow["stockStakeHolderFirmName"] &&
        currentRow["stockStakeHolderFirmName"]
          .toString()
          .toLowerCase()
          .includes(searchValue.trim().toLowerCase())
      ) {
        isValid = true;
        return true;
      }
    });
  };



  checkValidationOnSubmit = () => {
    const { fieldData } = this.state;

    const mandatoryCheckErrors = mandatoryCheck({
      fieldData,
      fieldMeta: ConfigurationPendingJson.searchListBySalesTeam,
    });
    this.setState({ formErrors: mandatoryCheckErrors.formErrors })


    if (mandatoryCheckErrors.formValid) {
      const ValidationCheckErrors = ValidationCheck({
        fieldData,
        fieldMeta: ConfigurationPendingJson.searchListBySalesTeam,
      });

      this.setState({ formErrors: ValidationCheckErrors.formErrors })

      return ValidationCheckErrors.formValid;
    } else {
      return false;
    }
  };

  getSalesTeamDataFunction = async () => {
    const {
      getSalesTeamWithoutConfigurationByArea,
      showLoador,
      showNotification,
    } = this.props;
    const { selectedAreaList } = this.state;

    if (this.checkValidationOnSubmit()) {
      let jsonToSave = {
        areaIds: selectedAreaList,
      };
      showLoador({ loador: true });
      await getSalesTeamWithoutConfigurationByArea({
        jsonForSave: jsonToSave,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    }

  };

  outletCountHandler = (row) => {
    const { showLoador, showNotification } = this.props;

    console.debug(row)

    showLoador({ loador: true });
    apiGet({
      url: endpoint.outlet + "/by-stock-stake-holder?stockStakeHolderId=" + row.id
    }).then(async ({ data, success }) => {
      showLoador({ loador: false });
      console.debug(data.data)
      if (success) {
        this.setState({
          outletPopUp: true,
          stockHolderObjet: row,
          outletListForPopUp: data.data
        });
      } else {
        showNotification({ msg: serverMsg, severity: "error" });
      }
    });

  };


  beatCountHandler = (row) => {
    const { showLoador, showNotification } = this.props;

    console.debug(row)

    let areaIds = {
      areaIds: row.areaIds.split(","),
    }

    showLoador({ loador: true });
    apiPost({
      url: endpoint.beat + "/by-area-and-sales-team?salesTeamId=" + row.id,
      postBody: areaIds,
    }).then(async ({ data, success }) => {
      showLoador({ loador: false });
      console.debug(data.data)
      if (success) {
        this.setState({
          beatPopup: true,
          salesTeamObject: row,
          beatList: data.data
        });
      } else {
        showNotification({ msg: serverMsg, severity: "error" });
      }
    });

  };


  handleClose = () => {
    this.setState({
      beatPopup: false,
      outletPopUp: false,
    });
  };


  filterExcel = () => {
    const { fieldData } = this.state;

    const filteredData = this.getFilteredTableData(
      fieldData.type == 0 ? this.props.salesTeamList?.salesTeamWithoutConfigurationByArea :
        fieldData.type == 1 ? this.props.stockHolderList?.stockHolderByNoStructureWithOutletCount :
          this.props.outletList?.outlets
    );

    if (filteredData.length === 0) {
      this.props.showNotification({
        msg: "No data available for export",
        severity: "error",
      });
      return;
    }

    let searchableColumnsForSalesTeam = [];
    let searchableColumnsForStockHolder = [];
    let searchableColumnsForOutlet = [];

    searchableColumnsForSalesTeam = [
      "SR.No",
      "Sales Team Name",
      "Area Name",
      "Beat Count",
      "Day End Claims",
      "RU Config",
    ]

    searchableColumnsForStockHolder = [
      "SR.No",
      "Stock Holder Name",
      "Type",
      "Area Name",
      "Outlet Count",
    ]

    searchableColumnsForOutlet = [
      "SR.No",
      "Outlet Name",
      "Beat Name",
      "Area Name",
      "Sales Person Name",
      "Distributor Name",
    ]


    const formattedData = filteredData.map((row, index) => {

      if (fieldData.type == 0) {
        return [
          index + 1,
          row.salesTeamName || "",
          row.areaNames || "",
          row.beatCount || 0,
          row.dayEndClaimForTable || "",
          row.ruConfigForTable || "",
        ];
      } else if (fieldData.type == 1) {
        return [
          index + 1,
          row.firmName || "",
          row.stockStakeHolderType || "",
          row.areaName || "",
          row.outletCount || 0,
        ];
      } else {
        return [
          index + 1,
          row.firmName || "",
          row.beatName || "",
          row.areaName || "",
          row.salesPersonNames || "",
          row.stockStakeHolderFirmName || "",
        ];
      }
    });

    generateExcel({
      columns: fieldData.type == 0 ? searchableColumnsForSalesTeam :
        fieldData.type == 1 ? searchableColumnsForStockHolder :
          searchableColumnsForOutlet,
      rows: formattedData,
      alignList: [],
      headerList: [],
      title: fieldData.type == 0 ? "Sales Team" :
        fieldData.type == 1 ? "Stock Holder" : "Outlet",
      reportName: fieldData.type == 0 ? "Sales Team" :
        fieldData.type == 1 ? "Stock Holder" : "Outlet",
    });
  };

  render() {
    const { outletList, salesTeamList, stockHolderList } = this.props;
    const {
      dynamicMasterData,
      beatList,
      outletListForPopUp,
      fieldData,
      formErrors,
      salesTeamObject,
      stockHolderObjet,
      beatPopup,
      outletPopUp,
    } = this.state;

    const tableData =
      fieldData.type === "0"
        ? ConfigurationPendingJson.searchListBySalesTeam
        : ConfigurationPendingJson.fieldMeta;

    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 0,
          }}
          elevation={0}
        >
          <DynamicFormWithoutValidation
            getListById={this.getListById}
            dynamicMasterData={dynamicMasterData}
            showTitle={"Configuration Pending"}
            screenTitle={ConfigurationPendingJson.screenTitle}
            fieldMeta={tableData}
            apiBaseURL={ConfigurationPendingJson.apiBaseURL}
            formErrors={formErrors}
            showSaveBtn={fieldData.type === "0"}
            showSaveBtnMain={false}
            fieldData={fieldData}
            onDataChange={this.onDataChange}
            saveBtnText={"Search"}
            onFormSave={this.getSalesTeamDataFunction}
          />
          <br />
          <LandingScreenHeader
            screenTitle={""}
            showSearchBox={true}
            showTable={true}
            showFilter={false}
            showPdfDownload={false}
            showExcelDownload={true}
            generateExcel={this.filterExcel}
            onSearch={this.onSearchBox}
          />

          {fieldData.type === "0" && (
            <>
              <TableContainer style={{ maxHeight: "550px", overflow: "auto" }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" width="5%">
                        Sr. No.
                      </TableCell>
                      <TableCell align="center" width="18%">
                        Sales Team Name
                      </TableCell>
                      <TableCell align="center" width="18%">
                        Area Name
                      </TableCell>
                      <TableCell align="center" width="18%">
                        Beat Count
                      </TableCell>
                      <TableCell align="center" width="10%">
                        Day End Claims
                      </TableCell>
                      <TableCell align="center" width="15%">
                        RU Config
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.getFilteredTableData(
                      salesTeamList?.salesTeamWithoutConfigurationByArea
                    ).map((row, index) => (
                      <TableRow key={row.id}>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="left">{row.salesTeamName}</TableCell>
                        <TableCell align="left">{row.areaNames}</TableCell>
                        <TableCell align="center">
                          {row.beatCount <= 0 ? (
                            row.beatCount
                          ) : (
                            <div
                              style={{
                                color: cyanBlue,
                                cursor: "pointer",
                              }}
                              onClick={(e) => this.beatCountHandler(row)}
                            >
                              {row.beatCount}
                            </div>
                          )}
                        </TableCell>
                        <TableCell align="center">{row.dayEndClaimForTable}</TableCell>
                        <TableCell align="center">{row.ruConfigForTable}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}

          {fieldData.type === "1" && (
            <>
              <TableContainer style={{ maxHeight: "550px", overflow: "auto" }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" width="10%">
                        Sr. No.
                      </TableCell>
                      <TableCell align="center" width="25%">
                        Stock Holder Name
                      </TableCell>
                      <TableCell align="center" width="25%">
                        Type
                      </TableCell>
                      <TableCell align="center" width="20%">
                        Area Name
                      </TableCell>
                      <TableCell align="center" width="20%">
                        Outlet Count
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.getFilteredTableData(stockHolderList?.stockHolderByNoStructureWithOutletCount)?.map((row, index) => (
                      <TableRow key={row.id}>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="left">{row.firmName}</TableCell>
                        <TableCell align="left">{row.stockStakeHolderType}</TableCell>
                        <TableCell align="left">{row.areaName}</TableCell>
                        <TableCell align="center">
                          {row.outletCount <= 0 ? (
                            row.outletCount
                          ) : (
                            <div
                              style={{
                                color: cyanBlue,
                                cursor: "pointer",
                              }}
                              onClick={(e) => this.outletCountHandler(row)}
                            >
                              {row.outletCount}
                            </div>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}

          {fieldData.type === "2" && (
            <>
              <TableContainer style={{ maxHeight: "550px", overflow: "auto" }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" width="10%">
                        Sr. No.
                      </TableCell>
                      <TableCell align="center" width="15%">
                        Outlet Name
                      </TableCell>
                      <TableCell align="center" width="15%">
                        Beat Name
                      </TableCell>
                      <TableCell align="center" width="15%">
                        Area Name
                      </TableCell>
                      <TableCell align="center" width="25%">
                        Sales Person Name
                      </TableCell>
                      <TableCell align="center" width="25%">
                        Distributor Name
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.getFilteredTableData(outletList?.outlets)?.map((row, index) => (
                      <TableRow key={row.id}>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="left">{row.firmName}</TableCell>
                        <TableCell align="left">{row.beatName}</TableCell>
                        <TableCell align="left">{row.areaName}</TableCell>
                        <TableCell align="left">{row.salesPersonNames}</TableCell>
                        <TableCell align="left">{row.stockStakeHolderFirmName}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}

          {beatPopup && (
            <>
              <BootstrapDialog
                onClose={this.handleClose}
                aria-labelledby="customized-dialog-title"
                open={beatPopup}
                fullWidth
                maxWidth="lg"
              >
                <BootstrapDialogTitle
                  id="customized-dialog-title"
                  onClose={this.handleClose}
                >
                  Beat List
                </BootstrapDialogTitle>
                <DialogContent dividers>
                  <DynamicDetails
                    DataToSet={[
                      {
                        xs: 6,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        label: "Sales Team Name ",
                        value: salesTeamObject.salesTeamName,
                      },
                      {
                        xs: 6,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        label: "Area Name",
                        value: salesTeamObject.areaNames,
                      },
                    ]}
                  />

                  <DialogContent dividers>
                    <DynamicTable
                      data={beatList}
                      tableHead={[
                        {
                          title: "Beat",
                          name: "name",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "left",
                          canSearch: true,
                        }
                      ]}
                      showPegination={false}
                      isActionColActive={false}
                    />
                    <br />
                  </DialogContent>
                </DialogContent>
              </BootstrapDialog>
            </>
          )}


          {outletPopUp && (
            <>
              <BootstrapDialog
                onClose={this.handleClose}
                aria-labelledby="customized-dialog-title"
                open={outletPopUp}
                fullWidth
                maxWidth="lg"
              >
                <BootstrapDialogTitle
                  id="customized-dialog-title"
                  onClose={this.handleClose}
                >
                  Outlet List
                </BootstrapDialogTitle>
                <DialogContent dividers>
                  <DynamicDetails
                    DataToSet={[
                      {
                        xs: 6,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        label: "Stock Holder Name ",
                        value: stockHolderObjet.name,
                      },
                      {
                        xs: 6,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        label: "Area Name",
                        value: stockHolderObjet.areaName,
                      },
                    ]}
                  />

                  <DialogContent dividers>
                    <DynamicTable
                      data={outletListForPopUp}
                      tableHead={[
                        {
                          title: "Outlet",
                          name: "firmName",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "left",
                          canSearch: true,
                        },
                        {
                          title: "Beat",
                          name: "beatName",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "left",
                          canSearch: true,
                        }
                      ]}
                      showPegination={false}
                      isActionColActive={false}
                    />
                    <br />
                  </DialogContent>
                </DialogContent>
              </BootstrapDialog>
            </>
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  assetsList: state.assets,
  areaList: state.area,
  outletList: state.outlet,
  regionList: state.region,
  stateList: state.states,
  zoneList: state.zone,
  districtList: state.district,
  talukaList: state.taluka,
  beatList: state.beat,
  salesTeamList: state.salesExecutive,
  stockHolderList: state.stackholder,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getRegionNew,
  getStateByRegion,
  getZoneByState,
  getDistrictByZone,
  getTalukaByDistrict,
  getAreaByTalukaPostBody,
  getAreaByTalukaPostBodyNew,
  getAreaByTalukaPostBodyUpdated,
  getOutletByAreaNew,
  getActiveBeatByArea,
  getOutletByBeatIdsPost,
  getSalesTeamWithoutConfigurationByArea,
  setSalesTeamWithoutConfigurationByArea,
  getStockHoldersByNotAssignStructureWithOutletCount,
  getOutletByNotAssignCentralStructure,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ConfigurationPending);
