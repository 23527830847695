import endpoint from "../../config/endpoints";

export const SubChannelJson = {
  showSaveNextBtn: true,
  formPath: "/sub-Channel-form",
  apiBaseURL: endpoint.subChannel,
  changeExcel: endpoint.exportData,
  screenTitle: "Sub Channel",
  showAddButton: true,
  showPdfDownload: false,
  pdfFileName: "SubChannel",
  showExcelDownload: false,
  excelFileName: "SubChannel",
  isActiveURL: endpoint.subChannel + "/update-is-active",

  tableColumnsToFilter: [
    {
      columnDisplayName: "Name",
      columnKeyName: "name",
      isChecked: true,
    },
    // {
    //   columnDisplayName: "Sort Order Number",
    //   columnKeyName: "sortOrder",
    //   isChecked: true,
    // },
    {
      columnDisplayName: "Status",
      columnKeyName: "isActive",
      isChecked: true,
    },
  ],
  fieldMeta: [
    {
      label: "Channel",
      controlType: "autocomplete",
      placeHolder: "Select Channel",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "channelDetail",
      dataKey: "channel",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Name",
      controlType: "textfield",
      placeHolder: "Sub Channel Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      autoFocus: true,
      dataKey: "name",
      isMandatory: true,
      uniqueValidation: true,
    },
    {
      label: "Short Name",
      controlType: "textfield",
      placeHolder: "Short Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "shortName",
      isMandatory: true,
    },
    {
      label: "Sort Order Number",
      controlType: "textfield",
      placeHolder: "Sort Order Number",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "sortOrder",
      isMandatory: true,
    },
  ],
};
