import React from "react";
import {
  Paper,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
} from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Button";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import {
  noInternetMsg,
  saveFailedMsg,
  savemsg,
  saveWarningMsg,
} from "../../config/messageconstant";
import { apiGet, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { stockHolderTargetJson } from "../../DynamicFormsJson/Transaction/stockholderTarget";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import endpoint from "../../config/endpoints";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
class StockHolderTargetEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
      DataToSet: [],
      beatTargetList: [],
      totalOftarget: 0,
      isLog: true,
      moduleObjDataObj: {},
    };
    this.columns = [
      {
        title: "Sr.No.",
        name: "index",
        align: "center",
      },
      {
        title: "Region Name",
        name: "regionName",
        align: "left",
      },
      {
        title: "State Name",
        name: "stateName",
        align: "left",
      },
      {
        title: "Zone Name",
        name: "zoneName",
        align: "left",
      },
      {
        title: "Area Name",
        name: "areaName",
        align: "left",
      },
      {
        title: "Beat Name",
        name: "beatName",
        align: "right",
      },
      {
        title: "Jan",
        name: "jan",
        align: "right",
      },
      {
        title: "Feb",
        name: "feb",
        align: "right",
      },
      {
        title: "March",
        name: "march",
        align: "right",
      },
      {
        title: "April",
        name: "april",
        align: "right",
      },
      {
        title: "May",
        name: "may",
        align: "right",
      },
      {
        title: "June",
        name: "jun",
        align: "right",
      },
      {
        title: "July",
        name: "july",
        align: "right",
      },
      {
        title: "Aug",
        name: "aug",
        align: "right",
      },
      {
        title: "Sep",
        name: "sep",
        align: "right",
      },
      {
        title: "Oct",
        name: "oct",
        align: "right",
      },
      {
        title: "Nov",
        name: "nov",
        align: "right",
      },
      {
        title: "Dec",
        name: "dec",
        align: "right",
      },
    ];
  }

  async componentDidMount() {
    const { showLoador, showNotification } = this.props;
    const { totalOftarget } = this.state;
    if (navigator.onLine) {

      const moduleObjData = localStorage.getItem("moduleObj");

      this.setState({
        moduleObjDataObj:
          moduleObjData != null ? JSON.parse(moduleObjData) : {},
      });


      if (this.props.params.id) {
        showLoador({ loador: true });
        try {
          const response = await apiGet({
            url:
              stockHolderTargetJson.apiBaseURL +
              "/list-by-year?yearId=" +
              this.props.params.id,
          });
          showLoador({ loador: false });
          const { data, success } = response;
          if (success) {
            const stockHolderTargetData = data.data[0];
            const yearMaster = stockHolderTargetData.yearMaster;
            const targetData = {
              yearName: yearMaster ? yearMaster.yearName : "-",
              fromDate: yearMaster ? yearMaster.fromDate : "-",
              toDate: yearMaster ? yearMaster.toDate : "-",
            };
            const DataToSet = [
              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "Year Name",
                value: targetData.yearName,
              },
              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "From Date",
                value: targetData.fromDate,
              },
              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "To Date",
                value: targetData.toDate,
              },
            ];
            const beatTargetList = data.data.map((item, index) => ({
              index: index + 1,
              id: item.id,
              beatId: item.beat.id,
              beatName: item.beat.beatName,
              regionName: item.regionName,
              stateName: item.stateName,
              zoneName: item.zoneName,
              areaName: item.areaName,
              jan: item.jan,
              id: item.id,
              jan: item.jan,
              feb: item.feb,
              march: item.march,
              april: item.april,
              may: item.may,
              jun: item.jun,
              july: item.july,
              aug: item.aug,
              sep: item.sep,
              oct: item.oct,
              nov: item.nov,
              dec: item.dec,
              totalTarget: item.totalTarget,
            }));
            this.setState({ DataToSet, beatTargetList });

            let totalOftarget = 0;
            data.data.map((rowD) => {
              totalOftarget = totalOftarget + rowD.totalTarget;
            });

            this.setState({
              totalOftarget: totalOftarget,
            });

          } else {
          }
        } catch (error) {
          showLoador({ loador: false });
        }


      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };


  getFilteredTableData = (beatTargetList) => {
    const { searchValue } = this.state;
    return beatTargetList.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["beatName"] &&
          currentRow["beatName"]
            .toString()
            .toLowerCase()
            .includes(
              searchValue.trim().toLowerCase()
            )) ||
        (currentRow["areaName"] &&
          currentRow["areaName"]
            .toString()
            .toLowerCase()
            .includes(searchValue.trim().toLowerCase())) ||
        (currentRow["zoneName"] &&
          currentRow["zoneName"]
            .toString()
            .toLowerCase()
            .includes(searchValue.trim().toLowerCase())) ||
        (currentRow["stateName"] &&
          currentRow["stateName"]
            .toString()
            .toLowerCase()
            .includes(searchValue.trim().toLowerCase())) ||
        (currentRow["regionName"] &&
          currentRow["regionName"]
            .toString()
            .toLowerCase()
            .includes(searchValue.trim().toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
    });
  };


  onTextFieldDataChange = (monthName, beatId) => (event) => {
    const { beatTargetList } = this.state;
    const { value } = event.target;
    let totalOftarget = 0;

    const updatedBeatList = beatTargetList.map((beat) => {
      if (beat.id === beatId) {
        const oldValue = beat[monthName] || 0;
        beat = { ...beat, [monthName]: value };
        beat.totalTarget = +beat.totalTarget - +oldValue + +value;
      }
      return beat;
    });

    updatedBeatList.map((rowD) => {
      totalOftarget = totalOftarget + rowD.totalTarget;
    });

    this.setState({
      beatTargetList: updatedBeatList,
      totalOftarget: totalOftarget,
    });
  };




  onSubmit = () => {
    const { beatTargetList } = this.state;

    const saveTargetList = beatTargetList.map((row, index) => {
      let targetListObjectToSave = {
        ...row,
        id: row.id,
        beat: {
          id: row.beatId,
        },
        yearMaster: {
          id: this.props.params.id,
        },
        totalTarget: row.totalTarget,
      };
      return targetListObjectToSave;
    });

    swal({
      title: "Are you sure?",
      text: saveWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onSave(saveTargetList);
      }
    });
  };

  onSave = (saveTargetList) => {
    const { showLoador, showNotification } = this.props;
    const { isLog, moduleObjDataObj } = this.state;
    if (navigator.onLine) {

      let beatIds = saveTargetList.map((listObj) => listObj.beat && listObj.beat.id);


      showLoador({ loador: true });
      apiPost({
        url: endpoint.beatTarget + "/save-list",
        postBody: saveTargetList,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {

          if (data.data !== null) {
            if (isLog) {
              let updatedJsonToSend = JSON.stringify(data.data);

              let dataToSaveForMLog = {
                module: {
                  moduleId: moduleObjDataObj.moduleId
                },
                subModule: {
                  subModuleId: moduleObjDataObj.subModuleId
                },
                operationType: 1,
                type: 1,
                operationPerformId: this.props.params.id,
                subOperationPerformIds: beatIds.join(", "),
                updatedJson: updatedJsonToSend,
              }

              showLoador({ loador: true });
              apiPost({
                url: endpoint.mlog,
                postBody: dataToSaveForMLog,
              }).then(({ success }) => {
                showLoador({ loador: false });
                if (success) {
                } else {
                  showNotification({
                    msg: saveFailedMsg,
                    severity: "error",
                  });
                }
              });
            }

          }

          showNotification({ msg: savemsg });
          this.props.navigate(-1);
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
        return success;
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  render() {
    const { DataToSet, beatTargetList, totalOftarget } = this.state;

    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 0,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            showBackToList={true}
            DataToSet={DataToSet}
            showSaveBtn={stockHolderTargetJson.showSaveBtn}
            showTitle={true}
            screenTitle={"Stock Holder Target Assignation"}
            fieldMeta={[]}
            showCancel={stockHolderTargetJson.showCancel}
            apiBaseURL={stockHolderTargetJson.apiBaseURL}
            showSaveNextBtn={stockHolderTargetJson.showSaveNextBtn}
            showSaveBtnMain={false}
            padding={true}
            paddingTop={true}
          />
          <LandingScreenHeader
            screenTitle={""}
            showSearchBox={true}
            showTable={true}
            showFilter={false}
            showPdfDownload={false}
            showExcelDownload={false}
            onSearch={this.onSearch}
          />
          <TableContainer>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  {this.columns.map((column) => (
                    <TableCell
                      key={column.name}
                      align={"center"}
                      sx={{ minWidth: 65 }}
                    >
                      {column.title}
                    </TableCell>
                  ))}
                  <TableCell align={"center"} sx={{ minWidth: 80 }}>
                    Total Target
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.getFilteredTableData(beatTargetList).map((row, index) => (
                  <TableRow key={index} align="center">
                    {this.columns.map((column) => (
                      <TableCell key={column.name} align="left">
                        {column.name === "regionName" ? (
                          row.regionName
                        ) : column.name === "stateName" ? (
                          row.stateName
                        ) : column.name === "zoneName" ? (
                          row.zoneName
                        ) : column.name === "areaName" ? (
                          row.areaName
                        ) : column.name === "beatName" ? (
                          row.beatName
                        ) : column.name === "index" ? (
                          row.index
                        ) : (
                          <TextFieldCompo
                            placeHolder={"Target"}
                            size="small"
                            color="primary"
                            type="number"
                            id={row.id}
                            value={row[column.name]}
                            onChange={this.onTextFieldDataChange(
                              column.name,
                              row.id
                            )}
                            fullWidth={false}
                          />
                        )}
                      </TableCell>
                    ))}
                    <TableCell align="right" component="th" scope="row">
                      {row.totalTarget}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <DynamicDetails
            DataToSet={[
              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "Total target",
                value: totalOftarget,
              },
            ]}
          />
          <Grid container spacing={1}>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="right"
            >
              <ButtonCompo
                size="medium"
                type="submit"
                variant="contained"
                name="Submit"
                onClick={this.onSubmit}
              />
            </Grid>
          </Grid>

        </Paper >
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  beatTargetList: state.beatTarget,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(StockHolderTargetEdit);
