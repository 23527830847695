import { Grid, Paper } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { ButtonCompo } from "../../components/Comman/Button";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import endpoint from "../../config/endpoints";
import {
  beatAssignedMsg,
  noInternetMsg,
  saveFailedMsg,
  savemsg,
  saveWarningMsg,
  serverMsg,
} from "../../config/messageconstant";
import { apiGet, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { claimFormJson } from "../../DynamicFormsJson/MastersJSON/claimForm";
import { getClaim } from "../../Slice/claim.slice";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import ClaimViewPopup from "./ClaimViewPopup";
import { getClaimDetailsForDayEndBeats } from "../../Slice/beat.slice";

const columns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: false,
    showInscreen: true,
    alignCell: "center",
    align: "center",
  },
  {
    title: "Date",
    name: "date",
    positionCenter: false,
    showInscreen: true,
    alignCell: "center",
    alignCenter: "center",
    canSearch: true,
  },
  {
    title: "Claim From",
    name: "salesTeamName",
    positionCenter: false,
    showInscreen: true,
    alignCell: "center",
    alignCenter: "left",
    canSearch: true,
  },
  {
    title: "Claim To",
    name: "authoritySalesPersonName",
    positionCenter: false,
    showInscreen: true,
    alignCell: "center",
    alignCenter: "left",
    canSearch: true,
  },
  {
    title: "Amount",
    name: "claimAmt",
    positionCenter: false,
    showInscreen: true,
    alignCell: "center",
    alignCenter: "right",
    canSearch: true,
  },
  {
    title: "Status",
    name: "status",
    positionCenter: false,
    showInscreen: true,
    alignCell: "center",
    alignCenter: "left",
    canSearch: true,
  },
  {
    title: "Action",
    name: "action",
    positionCenter: false,
    showInscreen: true,
    alignCell: "center",
    alignCenter: "left",
    canSearch: true,
  },
];

const columnsForAll = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: false,
    showInscreen: true,
    alignCell: "center",
    align: "center",
  },
  {
    title: "Date",
    name: "date",
    positionCenter: false,
    showInscreen: true,
    alignCell: "center",
    alignCenter: "center",
    canSearch: true,
  },
  {
    title: "Claim From",
    name: "salesTeamName",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    canSearch: true,
  },
  {
    title: "Claim To",
    name: "authoritySalesPersonName",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    canSearch: true,
  },
  {
    title: "Claim Type",
    name: "claimType",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    canSearch: true,
  },
  {
    title: "Amount",
    name: "claimAmt",
    positionCenter: false,
    showInscreen: true,
    alignCell: "center",
    alignCenter: "right",
    canSearch: true,
  },
  {
    title: "Status",
    name: "status",
    positionCenter: true,
    showInscreen: true,
    alignCell: "center",
    alignCenter: "center",
    canSearch: true,
  },
  {
    title: "Action",
    name: "action",
    positionCenter: false,
    showInscreen: true,
    alignCell: "center",
    alignCenter: "center",
    canSearch: true,
  },
];
class ClaimForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewPopup: false,
      viewData: {},
      claimApplyDetails: [],
      DataToSet: [],
      fixedCalculation: "",
      formData: { type: "0" },
    };
  }

  onSearch = (data) => {
    const { getClaim, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      this.setState({ formData: data });
      showLoador({ loador: true });
      getClaim({
        fromDate: data.fromDate,
        toDate: data.toDate,
        type: data.type,
      }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  rowViewData = (rowData) => {
    const { getClaimDetailsForDayEndBeats, showLoador, showNotification } =
      this.props;

    this.setState({
      fixedCalculation:
        rowData.claimStructureHeader &&
        rowData.claimStructureHeader.fixedCalculation,
    });

    if (
      rowData.claimStructureHeader &&
      rowData.claimStructureHeader.fixedCalculation == 1
    ) {
      showLoador({ loador: true });
      getClaimDetailsForDayEndBeats({
        date: rowData.date,
        salesTeamId: rowData.salesTeamId,
      }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.setState({
            DataToSet: [
              {
                xs: 12,
                sm: 4,
                md: 4,
                lg: 4,
                label: "Claim Status",
                value: response.claimStatus,
              },
              {
                xs: 12,
                sm: 4,
                md: 4,
                lg: 4,
                label: "Final Total",
                value: response.finalTotal,
              },
            ],
            viewPopup: true,
          });
        }
      });
    } else {
      let viewDataList = [];
      let claimApplyDetails = [];
      showLoador({ loador: true });
      apiGet({
        url: endpoint.claimApplyHeader + "/" + rowData.id,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          claimApplyDetails = data.data.claimApplyDetails.map(
            (dataObj, index) => {
              let dataJson = {
                index: index + 1,
                claimAmt:
                  dataObj.claimAmt !== null ? dataObj.claimAmt.toFixed(2) : "",
                claimType:
                  dataObj.claimType !== null && dataObj.claimType.name !== null
                    ? dataObj.claimType.name
                    : "",
                remark: dataObj.remark !== null ? dataObj.remark : "",
                attachFile:
                  dataObj.attachFile !== null ? dataObj.attachFile : "",
              };
              return dataJson;
            }
          );
          viewDataList.push(data.data);
          this.setState({
            DataToSet: [
              {
                xs: 12,
                sm: 4,
                md: 4,
                lg: 4,
                label: "Date",
                value: data.data.date,
              },
              {
                xs: 12,
                sm: 4,
                md: 4,
                lg: 4,
                label: "Name",
                value: data.data.name,
              },
              {
                xs: 12,
                sm: 4,
                md: 4,
                lg: 4,
                label: "Claim Amount",
                value: data.data.claimAmt,
              },
            ],
            claimApplyDetails: claimApplyDetails,
            viewData: viewDataList,
            viewPopup: true,
          });
        }
      });
    }
  };

  handleClosesCategory = () => {
    this.setState({
      viewPopup: false,
    });
  };

  render() {
    const {
      dynamicMasterData,
      claimApplyDetails,
      viewPopup,
      viewData,
      DataToSet,
      fixedCalculation,
      formData,
    } = this.state;
    const { claimList, beatList } = this.props;
    return (
      <>
        <Paper
          sx={{
            padding: 2,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            dynamicMasterData={dynamicMasterData}
            getListById={this.getListById}
            searchList={claimFormJson.searchList}
            showSaveBtn={false}
            showSaveBtnMain={true}
            showTitle={claimFormJson.showTitle}
            screenTitle={claimFormJson.screenTitle}
            fieldMeta={claimFormJson.fieldMeta}
            showCancel={claimFormJson.showCancel}
            apiBaseURL={claimFormJson.apiBaseURL}
            showSaveNextBtn={claimFormJson.showSaveNextBtn}
            saveBtnText={"Search"}
            onSave={this.onSearch}
          />

          <DynamicTable
            data={claimList?.claimApplyHeader}
            tableHead={formData.type === "2" ? columnsForAll : columns}
            isActionColActive={true}
            showPegination={false}
            showView={true}
            rowViewData={this.rowViewData}
          />
        </Paper>
        {viewPopup && (
          <ClaimViewPopup
            open={viewPopup}
            handleCloses={this.handleClosesCategory}
            columns={
              fixedCalculation == 0
                ? [
                  {
                    title: "Sr. No.",
                    name: "index",
                    positionCenter: false,
                    showInscreen: true,
                    alignCell: "center",
                    align: "center",
                  },
                  {
                    title: "Claim Amount",
                    name: "claimAmt",
                    positionCenter: false,
                    showInscreen: true,
                    alignCell: "right",
                    alignCenter: "right",
                    canSearch: true,
                  },
                  {
                    title: "Claim Type",
                    name: "claimType",
                    positionCenter: false,
                    showInscreen: true,
                    alignCell: "left",
                    alignCenter: "left",
                    canSearch: true,
                  },
                  {
                    title: "Remark",
                    name: "remark",
                    positionCenter: false,
                    showInscreen: true,
                    alignCell: "left",
                    alignCenter: "left",
                    canSearch: true,
                  },
                  {
                    title: "Attchment",
                    name: "action",
                    positionCenter: false,
                    showInscreen: true,
                    alignCell: "center",
                    alignCenter: "center",
                    canSearch: true,
                  },
                ]
                : [
                  {
                    title: "Sr. No.",
                    name: "index",
                    positionCenter: false,
                    showInscreen: true,
                    alignCell: "center",
                    align: "center",
                  },
                  {
                    title: "Beat Name",
                    name: "beatName",
                    positionCenter: false,
                    showInscreen: true,
                    alignCell: "left",
                    alignCenter: "left",
                    canSearch: true,
                  },
                  {
                    title: "Daily Allowance",
                    name: "dailyAllowanceAmt",
                    positionCenter: false,
                    showInscreen: true,
                    alignCell: "right",
                    alignCenter: "right",
                    canSearch: true,
                  },
                  {
                    title: "Travel Allowance",
                    name: "travelAllowanceAmt",
                    positionCenter: false,
                    showInscreen: true,
                    alignCell: "right",
                    alignCenter: "right",
                    canSearch: true,
                  },
                ]
            }
            rows={
              fixedCalculation == 1
                ? beatList?.beatClaim.beatWorkInfo
                : claimApplyDetails
            }
            viewHeaderData={viewData}
            DataToSet={DataToSet}
            fixedCalculation={fixedCalculation}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  claimList: state.claim,
  beatList: state.beat,
});
const mapDispatchToProps = {
  getClaim,
  getClaimDetailsForDayEndBeats,
  showLoador,
  showNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(ClaimForm);
