import { Grid, Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { companyCategoryConfigurationJson } from "../../DynamicFormsJson/Transaction/companyCategoryConfiguration";
import { getRegion } from "../../Slice/region.slice";
import { getStateByRegion } from "../../Slice/state.slice";
import { getZoneByState } from "../../Slice/zone.slice";
import { getDistrictByZone } from "../../Slice/district.slice";
import { getTalukaByDistrict } from "../../Slice/taluka.slice";
import { getAreaByTalukaPostBodyUpdated, getAreaByUserType } from "../../Slice/area.slice";
import { getCategory, getCategoryByProductRateStructure } from "../../Slice/category.slice";
import { getProductByCategory } from "../../Slice/product.slice";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import RTTable from "../../components/Comman/RT/RTTable";
import DynamicFormWithoutValidation from "../../components/dynamicscreens/DynamicFormWithoutValidation";

import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import { DarkBlue } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import {
    atleastOne,
    deleteWarningMsg,
    noInternetMsg,
    saveFailedMsg,
    saveWarningMsg,
    savemsg,
    serverMsg
} from "../../config/messageconstant";
import { apiGet, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import dayjs from "dayjs";
class CompanyCategoryConfigurationForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rowList: [],
            formErrors: {},
            fieldData: {},
            adminFlag: false,
            dynamicMasterData: {
                areaDetail: this.props.areaList.area,
                categoryDetail: this.props.categoryList.category,
                productDetail:
                    this.props.productList.productByCategory,
            },
        };
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.regionList !== nextProps.regionList) {
            if (this.props.regionList?.region !== nextProps.regionList?.region) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        regionDetail: nextProps.regionList?.region,
                    },
                });
            }
        }
        if (this.props.stateList !== nextProps.stateList) {
            if (this.props.stateList?.stateByRegion !== nextProps.stateList?.stateByRegion) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        stateDetail: nextProps.stateList?.stateByRegion,
                    },
                });
            }
        }
        if (this.props.zoneList !== nextProps.zoneList) {
            if (this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        zoneDetail: nextProps.zoneList?.zoneByState,
                    },
                });
            }
        }
        if (this.props.districtList !== nextProps.districtList) {
            if (this.props.districtList?.districtByZone !== nextProps.districtList?.districtByZone) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        districtDetail: nextProps.districtList?.districtByZone,
                    },
                });
            }
        }
        if (this.props.talukaList !== nextProps.talukaList) {
            if (this.props.talukaList?.talukaByDistrict !== nextProps.talukaList?.talukaByDistrict) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        talukaDetail: nextProps.talukaList?.talukaByDistrict,
                    },
                });
            }
        }
        if (this.props.areaList !== nextProps.areaList) {
            if (this.props.areaList?.areaByTaluka !== nextProps.areaList?.areaByTaluka) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        areaDetail: nextProps.areaList?.areaByTaluka,
                    },
                });
            }
        }

        if (this.props.areaList !== nextProps.areaList) {
            if (this.props.areaList?.area !== nextProps.areaList?.area) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        areaDetail: nextProps.areaList?.area,
                    },
                });
            }
        }

        if (this.props.categoryList !== nextProps.categoryList) {
            if (this.props.categoryList?.category !== nextProps.categoryList?.category) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        categoryDetail: nextProps.categoryList?.category,
                    },
                });
            }
        }

        if (this.props.productList !== nextProps.productList) {
            if (
                this.props.productList?.productByCategory !==
                nextProps.productList?.productByCategory
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        productDetail:
                            nextProps.productList?.productByCategory,
                    },
                });
            }
        }
    }

    getListById = async (data) => {
        const {
            getProductByCategory,
            getStateByRegion,
            getZoneByState,
            getDistrictByZone,
            getTalukaByDistrict,
            getAreaByTalukaPostBodyUpdated,
            showLoador,
            showNotification,
        } = this.props;
        if (navigator.onLine) {
            if (data.category) {
                showLoador({ loador: true });
                await getProductByCategory({
                    categoryId: data.category,
                }).then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }

            if (data.region && data.region != "") {
                showLoador({ loador: true });
                await getStateByRegion({ regionIds: data.region }).then(
                    ({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    }
                );
            }
            if (data.state && data.state != "") {
                showLoador({ loador: true });
                await getZoneByState({ stateIds: data.state }).then(
                    ({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    }
                );
            }
            if (data.zoneIds && data.zoneIds != "") {
                showLoador({ loador: true });
                await getDistrictByZone({ zoneIds: data.zoneIds }).then(
                    ({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    }
                );
            }
            if (data.districtIds && data.districtIds != "") {
                showLoador({ loador: true });
                await getTalukaByDistrict({ districtIds: data.districtIds }).then(
                    ({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    }
                );
            }
            if (data.talukaIds && data.talukaIds != "") {
                let talukaIdsByPost = {
                    talukaIds: data.talukaIds,
                };
                showLoador({ loador: true });
                await getAreaByTalukaPostBodyUpdated({ talukaIds: talukaIdsByPost }).then(
                    ({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    }
                );
            }
        } else {
            showNotification({
                msg: noInternetMsg,
                severity: "error",
            });
        }
    };

    async componentDidMount() {
        this.setState({
            rowList: [],
        });

        const { showLoador, showNotification, getRegion, getAreaByUserType, getCategory, getCategoryByProductRateStructure } = this.props;
        if (navigator.onLine) {

            const userTypeId = localStorage.getItem("userTypeId");


            if (this.props.params.id) {
                this.getDataById(this.props.params.id)
            }

            if (userTypeId == 1 || userTypeId == 6) {

                this.setState({
                    adminFlag: true,
                })

                showLoador({ loador: true });
                await getRegion().then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });

                showLoador({ loador: true });
                await getCategory().then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });

            } else {
                showLoador({ loador: true });
                await getAreaByUserType().then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });

                showLoador({ loador: true });
                await getCategoryByProductRateStructure().then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }



        }
        else {
            showNotification({
                msg: noInternetMsg,
                severity: "error",
            });
        }
    }

    onSearch = (data) => {
        const { rowList, dynamicMasterData } = this.state;

        let productList = dynamicMasterData["productDetail"]
            ? dynamicMasterData["productDetail"].filter(
                (row) => row.id == data.product
            )
            : [];

        let catList = dynamicMasterData["categoryDetail"]
            ? dynamicMasterData["categoryDetail"].filter(
                (row) => row.id == data.category
            )
            : [];
        this.setState({
            rowList: [
                ...rowList,
                {
                    index: rowList.length + 1,
                    categoryName: catList.length != 0 ? catList[0].name : "-",
                    productName: productList.length != 0 ? productList[0].name : "-",
                    // outletOrderDetail:
                    //     productList.length != 0 ? productList[0].outletOrderDetail : "-",
                    ...data,
                },
            ],
        });
    };

    onSubmitData = () => {
        const { showNotification } = this.props;
        const { rowList, fieldData } = this.state;
        if (rowList.length != 0) {
            const companyCategoryConfigurationDetails = rowList.map((rowData) => {
                const rowObj = {
                    product: {
                        id: rowData.product,
                    },
                };
                return rowObj;
            });
            let dataToSave = "";
            if (this.props.params.id) {
                dataToSave = {
                    id: this.props.params.id,
                    name: fieldData.name,
                    description: fieldData.description,
                    region: fieldData.region ? { id: fieldData.region } : null,
                    state: fieldData.state ? { id: fieldData.state } : null,
                    zoneIds: fieldData.zoneIds ? fieldData.zoneIds.join() : null,
                    districtIds: fieldData.districtIds ? fieldData.districtIds.join() : null,
                    talukaIds: fieldData.talukaIds ? fieldData.talukaIds.join() : null,
                    areaIds: fieldData.areaIds.join(),
                    fromDate:
                        fieldData.type == 0
                            ? null
                            : dayjs(fieldData.fromDate).format("DD-MM-YYYY"),
                    toDate:
                        fieldData.type == 0
                            ? null
                            : dayjs(fieldData.toDate).format("DD-MM-YYYY"),
                    type: fieldData.type ? fieldData.type : null,
                    isActive: 1,
                    companyCategoryConfigurationDetails:
                        companyCategoryConfigurationDetails,
                };
            } else {
                dataToSave = {
                    name: fieldData.name,
                    description: fieldData.description,
                    region: fieldData.region ? { id: fieldData.region } : null,
                    state: fieldData.state ? { id: fieldData.state } : null,
                    zoneIds: fieldData.zoneIds ? fieldData.zoneIds.join() : null,
                    districtIds: fieldData.districtIds ? fieldData.districtIds.join() : null,
                    talukaIds: fieldData.talukaIds ? fieldData.talukaIds.join() : null,
                    areaIds: fieldData.areaIds.join(),
                    fromDate:
                        fieldData.type == 0
                            ? null
                            : dayjs(fieldData.fromDate).format("DD-MM-YYYY"),
                    toDate:
                        fieldData.type == 0
                            ? null
                            : dayjs(fieldData.toDate).format("DD-MM-YYYY"),
                    type: fieldData.type ? fieldData.type : null,
                    isActive: 1,
                    companyCategoryConfigurationDetails:
                        companyCategoryConfigurationDetails,
                };
            }
            swal({
                title: "Are you sure?",
                text: saveWarningMsg,
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    if (this.props.params.id) {
                        this.onEdit(dataToSave);
                    } else {
                        this.onSave(dataToSave);
                    }
                }
            });
        } else {
            showNotification({
                msg: atleastOne,
                severity: "error",
            });
        }
    };



    onSave = (dataToSave) => {
        const { showLoador, showNotification } = this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            apiPost({
                url:
                    endpoint.companyCategoryConfiguration,
                postBody: dataToSave,
            }).then(({ data, success }) => {
                showLoador({ loador: false });
                if (success) {
                    showNotification({ msg: savemsg });
                    this.props.navigate(-1);
                } else {
                    showNotification({ msg: saveFailedMsg, severity: "error" });
                }
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };


    onEdit = (dataToSave) => {
        const { showLoador, showNotification } = this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            apiPost({
                url: endpoint.companyCategoryConfiguration + "/update",
                postBody: dataToSave,
            }).then(({ data, success }) => {
                showLoador({ loador: false });
                if (success) {
                    showNotification({ msg: savemsg });
                    this.props.navigate(-1);
                } else {
                    showNotification({ msg: saveFailedMsg, severity: "error" });
                }
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };



    onDataChange = (fieldName, newValue) => {
        const { fieldData, adminFlag } = this.state;
        const dataToSearch = {};
        const dataToReset = { ...this.state.fieldData };
        companyCategoryConfigurationJson.fieldMetaAdmin.map((currentField) => {
            if (currentField.dataKey === fieldName && currentField.getListId) {
                const masterKeyList = currentField.getListId.split(",");

                masterKeyList.map((key) => {
                    if (fieldName === key) {
                        dataToSearch[key] = newValue;
                    } else if (fieldData[key]) {
                        dataToSearch[key] = fieldData[key];
                    }
                    return null;
                });

                this.getListById(dataToSearch);
            }
            if (currentField.getListFrom) {
                const masterKeyList = currentField.getListFrom.split(",");
                masterKeyList.map((key) => {
                    if (key == fieldName && dataToReset[key]) {
                        delete dataToReset[currentField.dataKey];
                    }
                });
            }
            return null;
        });

        dataToReset[fieldName] = newValue;
        this.setState({
            fieldData: dataToReset,
        });
    };
    getDataForDisabled = () => {

    }


    rowDelete = (rowData) => {
        swal({
            title: "Are you sure?",
            text: deleteWarningMsg,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                const rowListData = this.state.rowList.filter(
                    (row) => row.index != rowData.index
                );
                const row = rowListData.map((rowListDataObj, index) => {
                    return { ...rowListDataObj, index: index + 1 };
                });
                this.setState({
                    rowList: row,
                });
            }
        });
    };

    getDataById = (id) => {
        const { showLoador, showNotification } = this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            const response = apiGet({
                url: companyCategoryConfigurationJson.apiBaseURL + "/" + id,
            }).then(({ data, success }) => {
                console.log(data.data);
                showLoador({ loador: false });
                if (success) {
                    if (!data.error) {
                        let rowData = {
                            ...data.data,
                            region: data.data.region && data.data.region.id ? data.data.region.id : "",
                            state: data.data.state && data.data.state.id ? data.data.state.id : "",
                            zoneIds: data.data.zoneIds ? data.data.zoneIds.split(",") : "",
                            districtIds: data.data.districtIds ? data.data.districtIds.split(",") : "",
                            talukaIds: data.data.talukaIds ? data.data.talukaIds.split(",") : "",
                            areaIds: data.data.areaIds ? data.data.areaIds.split(",") : "",
                        }
                        this.getListById(rowData);
                        this.setState({
                            fieldData: rowData,
                            rowList: rowData.companyCategoryConfigurationDetails.map((companyObject, index) => {
                                let newList = {
                                    index: index + 1,
                                    product: companyObject.product.id,
                                    productName: companyObject.product.name,
                                }
                                return newList

                            }),
                        });

                        return { data: rowData, success };
                    }
                    return { data: {}, success: false };
                } else {
                    showNotification({ msg: serverMsg, severity: "error" });
                }

                return { data: {}, success: false };
            });
            return response;
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };

    render() {
        const {
            dynamicMasterData,
            rowList,
            formErrors,
            fieldData,
            open,
            adminFlag,
        } = this.state;
        console.log(fieldData)

        return (
            <>
                {!open && (
                    <>
                        <Paper
                            sx={{
                                padding: 2,
                            }}
                            elevation={0}
                        >
                            <DynamicFormWithoutValidation
                                dynamicMasterData={dynamicMasterData}
                                screenTitle={companyCategoryConfigurationJson.screenTitle}
                                fieldMeta={adminFlag ? companyCategoryConfigurationJson.fieldMetaAdmin : companyCategoryConfigurationJson.fieldMeta}
                                apiBaseURL={companyCategoryConfigurationJson.apiBaseURL}
                                formErrors={formErrors}
                                showSaveBtn={companyCategoryConfigurationJson.showSaveBtn}
                                showSaveBtnMain={false}
                                getDataById={this.getDataById}
                                getListById={this.getListById}
                                getByIdApi={true}
                                callApi={true}
                                fieldData={fieldData}
                                onDataChange={this.onDataChange}
                                showBackToList={true}
                            />
                            <LabelCompo
                                className="text-black"
                                style={{ fontSize: "20px", color: DarkBlue }}
                                label={"Add Product"}
                            />
                            <DynamicFormWithoutSave
                                showSaveBtn={true}
                                showSaveBtnMain={false}
                                dynamicMasterData={dynamicMasterData}
                                getListById={this.getListById}
                                searchList={companyCategoryConfigurationJson.searchList}
                                showTitle={companyCategoryConfigurationJson.showTitle}
                                screenTitle={companyCategoryConfigurationJson.screenTitle}
                                fieldMeta={companyCategoryConfigurationJson.fieldMetaDetails}
                                showCancel={companyCategoryConfigurationJson.showCancel}
                                showBackToList={companyCategoryConfigurationJson.showBackToList}
                                apiBaseURL={companyCategoryConfigurationJson.apiBaseURL}
                                showSaveNextBtn={companyCategoryConfigurationJson.showSaveNextBtn}
                                saveBtnText={"ADD"}
                                onSave={this.onSearch}
                                resetAfterSave={true}
                                getDataForDisabled={this.getDataForDisabled}


                            />

                            <br />
                            <RTTable
                                showPegination={false}
                                columns={[
                                    { name: "index", title: "Sr. No.", align: "center" },
                                    { name: "productName", title: "Product" },
                                    { name: "action", title: "Action" },
                                ]}
                                tableData={rowList}
                                isActionColActive={true}
                                showEditIcon={false}
                                showDeleteIcon={true}
                                rowDelete={this.rowDelete}
                            />
                            <br />
                            <Grid
                                item
                                sm={12}
                                xs={12}
                                md={12}
                                lg={12}
                                container
                                justifyContent="right"
                            >
                                <ButtonCompo
                                    size="medium"
                                    type="button"
                                    variant="contained"
                                    name="Save"
                                    onClick={this.onSubmitData}
                                />
                            </Grid>
                        </Paper>
                    </>
                )}
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    areaList: state.area,
    categoryList: state.category,
    productList: state.product,
    regionList: state.region,
    zoneList: state.zone,
    stateList: state.states,
    districtList: state.district,
    talukaList: state.taluka,
});
const mapDispatchToProps = {
    showLoador,
    showNotification,
    getProductByCategory,
    getCategory,
    getRegion,
    getStateByRegion,
    getZoneByState,
    getDistrictByZone,
    getTalukaByDistrict,
    getAreaByTalukaPostBodyUpdated,
    getAreaByUserType,
    getCategoryByProductRateStructure,
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(CompanyCategoryConfigurationForm);
