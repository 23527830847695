import {
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Button";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import {
  serverMsg,
  saveFailedMsg,
  savemsg,
  deletemsg,
  deleteFailedMsg,
  saveWarningMsg,
  deleteConfirmMsg,
  cancelledMsg,
  nameAlreadyExistMsg,
  noInternetMsg,
  fromdateMsg,
  todateMsg,
  areaMsg,
  whoCanTakeMsg,
} from "../../config/messageconstant";
import swal from "sweetalert";
import { auditJson } from "../../DynamicFormsJson/MastersJSON/audit";
import {
  getStockStakeHolderType,
  getStockStakeHolderTypeForList,
} from "../../Slice/stockStakeHolderType.slice";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { withRouter } from "../../components/withRouter";
import { compose } from "@reduxjs/toolkit";
import { apiPost, apiGet, apiDelete } from "../../utils/api_service";
import { getAuditQestion, resetDataAddQuestion } from "../../Slice/audit.slice";
import { getAreaByUserType } from "../../Slice/area.slice";
import { getBeatsByAreaIds } from "../../Slice/beat.slice";
import { getOutletByBeatIds } from "../../Slice/outlet.slice";
import RTTable from "../../components/Comman/RT/RTTable";
import endpoint from "../../config/endpoints";
import AddQuestion from "./AddQuestion";
import AddOptionForm from "./AddOptionForm";
import { WindowsFilled } from "@ant-design/icons";
import { FormControl } from "@mui/base";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { LabelCompo } from "../../components/Comman/Label";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import { redColor, titleColor } from "../../config/ColorObj";
import AutoComplete from "../../components/Comman/AutoComplete";
import NewMultipleSelect from "../../components/Comman/NewMultipleSelect";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import dayjs from "dayjs";
import moment from "moment";


class AuditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "1",
      fieldData: {},
      dataToSave: {},
      openAddQuestionForm: false,
      openOptions: false,
      formStatus: {},
      fromDate: "",
      toDate: "",
      areaAll: [],
      whoCanTake: "",
      name: "",
      remark: "",
      formErrors: {},
      whoCanTakeForTypeTwo: "",
      nameFocus: false,
      showQuestion: false,
      areaExplorationQuestionaryStatus: "",
      dynamicMasterData: {
        areaDetail: this.props.areaList.area,
        beatDetail: this.props.beatList.beat,
        stockStakeHolderTypeDetail:
          this.props.stockStakeHolderType?.stockStakeHolderType,
        stockStakeHolderTypelistDetail:
          this.props.stockStakeHolderType?.stockStakeHolderTypeList,
      },
    };
    this.columns = [
      { name: "index", title: "Sr. No.", align: "center" },
      { name: "question", title: "Question" },
      { name: "optionTypeName", title: "Question Type" },
      { name: "points", title: "Points", align: "center" },
      { name: "action", title: "Action" },
    ];
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.areaList !== nextProps.areaList) {
      if (this.props.areaList?.area !== nextProps.areaList?.area) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.area,
          },
        });
      }
    }

    if (this.props.beatList !== nextProps.beatList) {
      if (this.props.beatList?.beat !== nextProps.beatList?.beat) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            beatDetail: nextProps.beatList?.beat,
          },
        });
      }
    }

    if (this.props.outletList !== nextProps.outletList) {
      if (this.props.outletList?.outlets !== nextProps.outletList?.outlets) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            outletDetail: nextProps.outletList?.outlets,
          },
        });
      }
    }

    if (this.props.stockStakeHolderType !== nextProps.stockStakeHolderType) {
      if (
        this.props.stockStakeHolderType?.stockStakeHolderType !==
        nextProps.stockStakeHolderType?.stockStakeHolderType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stockStakeHolderTypeDetail:
              nextProps.stockStakeHolderType?.stockStakeHolderType,
          },
        });
      }
    }

    if (
      this.props.stockStakeHolderType?.stockStakeHolderTypeList !==
      nextProps.stockStakeHolderType?.stockStakeHolderTypeList
    ) {
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          stockStakeHolderTypelistDetail:
            nextProps.stockStakeHolderType?.stockStakeHolderTypeList,
        },
      });
    }
  }

  async componentDidMount() {
    const {
      getStockStakeHolderType,
      getStockStakeHolderTypeForList,
      getAreaByUserType,
      showLoador,
      showNotification,
      resetDataAddQuestion,
    } = this.props;

    const { value } = this.state;

    if (navigator.onLine) {

      if (this.props.params.id) {
        //getStockStakeHolderType called in the success of getDataById API for getting current isAuditSurvey value.
        this.getDataById();
      } else {
        showLoador({ loador: true });
        getStockStakeHolderType({ userTypeIds: value == 2 ? "1" : "4" }).then(
          ({ response, success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            } else {
              const whoCanTakeForType2 = response.find(row => row.id);
              this.setState({
                whoCanTakeForTypeTwo:
                  whoCanTakeForType2 && whoCanTakeForType2 !== null && whoCanTakeForType2.id !== null ? whoCanTakeForType2.id : ""
              });
            }
          }
        );
      }


      resetDataAddQuestion();

      showLoador({ loador: true });
      apiGet({
        url: endpoint.audit + "/check-audit-present-or-not",
      }).then((data) => {
        showLoador({ loador: false });
        console.log("Status" + "" + data.data.data);
        if (data) {
          let status = data.data.data;
          this.setState({
            formStatus: status,
          });
        }
      });


      showLoador({ loador: true });
      apiGet({
        url: endpoint.audit + "/check-area-exploration-audit-present-or-not",
      }).then((data) => {
        showLoador({ loador: false });
        console.log("Status" + "" + data.data.data);
        if (data) {
          let status = data.data.data;
          this.setState({
            areaExplorationQuestionaryStatus: status,
          });
        }
      });



      showLoador({ loador: true });
      await getAreaByUserType().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });






      showLoador({ loador: true });
      await getStockStakeHolderTypeForList().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });


    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  getListById = async (data) => {
    const {
      getBeatsByAreaIds,
      getOutletByBeatIds,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      if (data.areaIds) {
        showLoador({ loador: true });
        await getBeatsByAreaIds({ areaIds: data.areaIds }).then(
          ({ success }) => {
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
        showLoador({ loador: false });
      }


      if (data.beatIds) {
        showLoador({ loador: true });
        await getOutletByBeatIds({ beatIds: data.beatIds }).then(
          ({ success }) => {
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
        showLoador({ loador: false });

      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };



  handleChange = (event) => {
    const { getStockStakeHolderType, showLoador, showNotification } = this.props;
    console.log(event.target.value);

    showLoador({ loador: true });
    getStockStakeHolderType({ userTypeIds: event.target.value == 2 ? "1" : "4" }).then(
      ({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          const whoCanTakeForType2 = response.find(row => row.id);
          this.setState({
            whoCanTakeForTypeTwo:
              whoCanTakeForType2 && whoCanTakeForType2 !== null && whoCanTakeForType2.id !== null ? whoCanTakeForType2.id : ""
          });
        }
      }
    );


    this.setState({
      value: event.target.value,
      formErrors: {},
      fieldData: {},
    });
  };

  onSearch = () => {
    const {
      fieldData,
      value,
      areaAll,
      whoCanTake,
      name,
      remark,
      fromDate,
      toDate,
      beatIds,
      outletIds,
      stockStakeHolderTypeId,
      whoCanTakeForTypeTwo,
    } = this.state;

    let dataToSend = {}

    if (this.props.params.id) {
      dataToSend = {
        id: this.props.params.id,
        fromDate:
          value == 2 ? dayjs().format("DD-MM-YYYY") : dayjs(fromDate).format("DD-MM-YYYY"),
        toDate:
          value == 2 ? dayjs().format("DD-MM-YYYY") : dayjs(toDate).format("DD-MM-YYYY"),
        areaIds: areaAll.join(),
        beatIds: beatIds ? beatIds : null,
        outletIds: outletIds ? outletIds : null,
        stockStakeHolderTypeId: stockStakeHolderTypeId ? stockStakeHolderTypeId : null,
        whoCanTake: value == 2 ? whoCanTakeForTypeTwo : whoCanTake,
        name: name,
        remark: remark,
        isActive: 1,
        isAuditSurvey: value,
      };
    } else {
      dataToSend = {
        fromDate:
          value == 2 ? dayjs().format("DD-MM-YYYY") : dayjs(fromDate).format("DD-MM-YYYY"),
        toDate:
          value == 2 ? dayjs().format("DD-MM-YYYY") : dayjs(toDate).format("DD-MM-YYYY"),
        areaIds: areaAll.join(),
        beatIds: beatIds ? beatIds : null,
        outletIds: outletIds ? outletIds : null,
        stockStakeHolderTypeId: stockStakeHolderTypeId ? stockStakeHolderTypeId : null,
        whoCanTake: value == 2 ? whoCanTakeForTypeTwo : whoCanTake,
        name: name,
        remark: remark,
        isActive: 1,
        isAuditSurvey: value,
      };
    }

    console.log(dataToSend);

    if (Object.keys(fieldData).length != 0 && fieldData["id"]) {
      this.setState({
        openAddQuestionForm: true,
        dataToSave: {},
      });
    } else {
      this.saveHead(dataToSend, false);
    }
  };

  saveOnsubmit = (dataToSave, showNot) => {
    if (navigator.onLine) {
      const { showLoador, showNotification } = this.props;
      const { name } = this.state;
      showLoador({ loador: true });
      apiGet({
        url: endpoint.audit + "/survey-name-exist-or-not?surveyName=" + name,
      }).then(({ success, data }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          if (data && data.data && Object.keys(data.data).length != 0) {
            if (data.data.statusCode == 500) {
              showNotification({ msg: nameAlreadyExistMsg, severity: "error" });
            } else {
              showLoador({ loador: true });
              apiPost({
                url: endpoint.audit,
                postBody: dataToSave,
              }).then(({ data, success }) => {
                showLoador({ loador: false });
                if (success) {
                  if (showNot) {
                    this.props.navigate(-1);
                    showNotification({ msg: savemsg });
                  }
                  if (dataToSave.id) {
                    this.setState({
                      dataToSave: {},
                      fieldData: data.data,
                      openAddQuestionForm: showNot ? false : true,
                    });
                  } else {
                    if (!showNot) {
                      window.location.replace("/audit-form/" + data.data.id);
                    }
                  }
                } else {
                  showNotification({ msg: saveFailedMsg, severity: "error" });
                }
              });
            }
          }
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onSearchAudit = (data) => {
    console.log(data);
    const { fieldData, value } = this.state;
    let dataToSend = {
      ...data,
      isAuditSurvey: value,
    };
    if (Object.keys(fieldData).length != 0 && fieldData["id"]) {
      this.setState({
        openAddQuestionForm: true,
        dataToSave: {},
      });
    } else {
      this.saveHead(dataToSend, false);
    }
  };


  saveHead = (dataToSave, showNot) => {


    if (navigator.onLine) {
      const { showLoador, showNotification } = this.props;
      const { nameFocus } = this.state;
      if (nameFocus) {
        this.saveOnsubmit(dataToSave, showNot);
      } else if (this.props.params.id && nameFocus) {
        this.saveOnsubmit(dataToSave, showNot);
      } else if (this.props.params.id) {
        showLoador({ loador: true });
        apiPost({
          url: endpoint.audit,
          postBody: dataToSave,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (success) {
            if (showNot) {
              this.props.navigate(-1);
              showNotification({ msg: savemsg });
            }
            if (dataToSave.id) {
              this.setState({
                dataToSave: {},
                fieldData: data.data,
                openAddQuestionForm: showNot ? false : true,
              });
            } else {
              if (!showNot) {
                window.location.replace("/audit-form/" + data.data.id);
              }
            }
          } else {
            showNotification({ msg: saveFailedMsg, severity: "error" });
          }
        });
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  getDataById = () => {
    const { showLoador, showNotification, getStockStakeHolderType } = this.props;
    showLoador({ loador: true });

    if (navigator.onLine) {
      if (this.props.params.id) {
        const response = apiGet({
          url: auditJson.apiBaseURL + "/" + this.props.params.id,
        }).then(({ data, success }) => {
          if (success) {
            if (!data.error) {
              let rowData = data.data;


              showLoador({ loador: true });
              getStockStakeHolderType({ userTypeIds: rowData.isAuditSurvey == 2 ? "1" : "4" }).then(
                ({ response, success }) => {
                  showLoador({ loador: false });
                  if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                  } else {
                    const whoCanTakeForType2 = response.find(row => row.id);
                    this.setState({
                      whoCanTakeForTypeTwo:
                        whoCanTakeForType2 && whoCanTakeForType2 !== null && whoCanTakeForType2.id !== null ? whoCanTakeForType2.id : ""
                    });
                  }
                }
              );

              this.setState({
                value: rowData.isAuditSurvey,
                fieldData: rowData,
                fromDate: dayjs(rowData.fromDate, 'DD-MM-YYYY'),
                toDate: dayjs(rowData.toDate, 'DD-MM-YYYY'),
                areaAll: rowData.areaIds ? rowData.areaIds.split(",") : [],
                whoCanTake: rowData.whoCanTake ? rowData.whoCanTake : [],
                name: rowData.name ? rowData.name : "",
                remark: rowData.remark ? rowData.remark : "",
              });
              this.getAuditQestionList(this.props.params.id);

              return { data: rowData, success };
            }
            return { data: {}, success: false };
          } else {
            showNotification({ msg: serverMsg, severity: "error" });
          }

          return { data: {}, success: false };
        });
        return response;
      }

    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
    showLoador({ loador: false });

  };

  handleClosesForQuestion = () => {
    const { openAddQuestionForm, openOptions } = this.state;
    this.setState({
      openAddQuestionForm: !openAddQuestionForm,
    });
  };

  handleClosesForOptions = () => {
    const { openAddQuestionForm, openOptions } = this.state;
    this.setState({
      openOptions: !openOptions,
    });
  };
  OnSaveQuestion = (dataToSave) => {
    if (dataToSave.optionType == 0) {
      const { fieldData } = this.state;
      let questionHeadDataToSave = {
        auditHead: {
          id: fieldData.id,
        },
        question: dataToSave.question,
        points: dataToSave.points,
        optionType: dataToSave.optionType,
        orderNo: dataToSave.orderNo,
        imageUpload: dataToSave.imageUpload,
        remark: dataToSave.remark,
        remarkMandatory: dataToSave.remarkMandatory,
        auditQuestionOption: [],
      };
      if (dataToSave.id) {
        questionHeadDataToSave = {
          ...questionHeadDataToSave,
          id: dataToSave.id,
        };
      }


      console.log(questionHeadDataToSave);
      swal({
        title: "Are you sure?",
        text: saveWarningMsg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.saveQuestion(questionHeadDataToSave);
        }
      });
    }
    this.setState({
      dataToSave: dataToSave.id
        ? {
          ...this.state.dataToSave,
          question: dataToSave.question,
          points: dataToSave.points,
          optionType: dataToSave.optionType,
          orderNo: dataToSave.orderNo,
          imageUpload: dataToSave.imageUpload,
          remark: dataToSave.remark,
          remarkMandatory: dataToSave.remarkMandatory,
        }
        : dataToSave,
      openAddQuestionForm: false,
      openOptions: dataToSave.optionType != 0 ? true : false,
    });
  };
  OnSaveOptions = (optionList, dataToSave) => {
    const { fieldData } = this.state;

    let questionHeadDataToSave = {
      auditHead: {
        id: fieldData.id,
      },
      question: dataToSave.question,
      imageUpload: dataToSave.imageUpload,
      remark: dataToSave.remark,
      remarkMandatory: dataToSave.remarkMandatory,
      points: dataToSave.points,
      optionType: dataToSave.optionType,
      orderNo: dataToSave.orderNo,
      auditQuestionOption: optionList,
    };
    if (dataToSave.id) {
      questionHeadDataToSave = {
        id: dataToSave.id,
        ...questionHeadDataToSave,
      };
    }

    console.log(questionHeadDataToSave)

    swal({
      title: "Are you sure?",
      text: saveWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.saveQuestion(questionHeadDataToSave);
      }
    });
    this.setState({
      openAddQuestionForm: false,
      openOptions: false,
    });
  };
  saveQuestion = (questionHeadDataToSave) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiPost({
        url: endpoint.auditQuestion,
        postBody: questionHeadDataToSave,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          showNotification({ msg: savemsg });
          this.getAuditQestionList();
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: deleteConfirmMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onDelete(rowData);
      }
    });
  };
  onDelete = (row) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiDelete({
        url: endpoint.auditQuestion + "/" + row.questionId,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          this.getAuditQestionList();
          showNotification({ msg: deletemsg });
        } else {
          showNotification({ msg: deleteFailedMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  rowEdit = (row) => {
    console.log(row);
    this.setState({
      openAddQuestionForm: true,
      dataToSave: { ...row, id: row.questionId },
    });
  };
  getAuditQestionList = (id = 0) => {
    const { showLoador, getAuditQestion, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getAuditQestion({ id: id == 0 ? this.state.fieldData.id : id }).then(
        ({ data, success }) => {
          showLoador({ loador: false });
          if (success) {
          } else {
            showNotification({ msg: saveFailedMsg, severity: "error" });
          }
        }
      );
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };


  onSubmitAudit = (dataToSave) => {
    let dataToSend = {
      ...dataToSave,
      isAuditSurvey: this.state.value,
    };
    swal({
      title: "Are you sure?",
      text: saveWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.saveHead(dataToSend, true);
      }
    });
  };


  onSubmit = (dataToSave) => {
    const {
      fieldData,
      value,
      areaAll,
      whoCanTake,
      name,
      remark,
      fromDate,
      toDate,
      beatIds,
      outletIds,
      stockStakeHolderTypeId,
      whoCanTakeForTypeTwo
    } = this.state;
    if (this.checkValidation()) {
      let dataToSend = {}

      if (this.props.params.id) {
        dataToSend = {
          id: this.props.params.id,
          fromDate: value == 2 ? dayjs().format("DD-MM-YYYY")
            : dayjs(fromDate).format("DD-MM-YYYY"),
          toDate: value == 2 ? dayjs().format("DD-MM-YYYY")
            : dayjs(toDate).format("DD-MM-YYYY"),
          areaIds: areaAll ? areaAll.join() : null,
          beatIds: beatIds ? beatIds : null,
          outletIds: outletIds ? outletIds : null,
          stockStakeHolderTypeId: stockStakeHolderTypeId ? stockStakeHolderTypeId : null,
          whoCanTake: value == 2 ? whoCanTakeForTypeTwo : whoCanTake,
          name: name,
          remark: remark,
          isActive: 1,
          isAuditSurvey: value,
        };
      } else {
        dataToSend = {
          fromDate:
            value == 2 ? dayjs().format("DD-MM-YYYY")
              : dayjs(fromDate).format("DD-MM-YYYY"),
          toDate:
            value == 2 ? dayjs().format("DD-MM-YYYY")
              : dayjs(toDate).format("DD-MM-YYYY"),
          areaIds: areaAll ? areaAll.join() : null,
          beatIds: beatIds ? beatIds : null,
          outletIds: outletIds ? outletIds : null,
          stockStakeHolderTypeId: stockStakeHolderTypeId ? stockStakeHolderTypeId : null,
          whoCanTake: value == 2 ? whoCanTakeForTypeTwo : whoCanTake,
          name: name,
          remark: remark,
          isActive: 1,
          isAuditSurvey: value,
        };
      }


      swal({
        title: "Are you sure?",
        text: saveWarningMsg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.saveHead(dataToSend, true);
        }
      });
    }

  };

  backToList = () => {
    this.props.navigate(-1);
  };


  changeDate = (field, date) => {
    const formattedDate = date ? dayjs(date).format('DD-MM-YYYY') : null;
    this.setState({ [field]: formattedDate ? dayjs(formattedDate, 'DD-MM-YYYY') : null });
  };

  checkValidation = () => {
    const { fromDate, toDate, areaAll, whoCanTake, value } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (fromDate === "") {
      formIsValid = false;
      formErrors["fromDateError"] = fromdateMsg;
    }
    if (toDate === "") {
      formIsValid = false;
      formErrors["toDateError"] = todateMsg;
    }

    if (value != 2) {
      if (areaAll.length === 0) {
        formIsValid = false;
        formErrors["areaError"] = areaMsg;
      }
    }

    if (whoCanTake.length === 0) {
      formIsValid = false;
      formErrors["whoCanTakeError"] = whoCanTakeMsg;
    }


    this.setState({
      formErrors: formErrors,
    });
    return formIsValid;
  };

  onAutocompleteChange = (name, newValue) => {

    if (name == "whoCanTake") {
      console.log(newValue);
      this.setState({
        whoCanTake: newValue,
      });
    }
  };

  multiSelectHandler = (value, name) => {
    const { areaList } = this.props;
    const filterValue = value.filter((rowValue) => rowValue === "all");
    if (filterValue.length !== 0) {
      if (name === "area") {
        let areaListAll = [];
        areaList.area.map((data) => {
          if (data.id !== "all") {
            areaListAll.push(data.id);
          }
        });
        this.setState({
          areaAll: areaListAll
        });
      }
    } else {
      if (name === "area") {
        this.setState({
          areaAll: value
        });
      }
    };
  }


  changeHandler = async (e) => {
    const { name, value } = e.target;

    if (name === "name") {
      this.setState({
        name: value,
        nameFocus: true,
      })
    } else if (name === "remark") {
      this.setState({
        remark: value,
      });
      // const response = await fetch("https://ifsc.razorpay.com/" + value);
      // const dataOfResponse = await response.json();
      // if (Object.keys(dataOfResponse).length !== 0) {
      //   console.log(dataOfResponse);
      //   this.setState({
      //     state: dataOfResponse.STATE,
      //     city: dataOfResponse.CITY,
      //     address: dataOfResponse.ADDRESS,
      //     bankName: dataOfResponse.BANK,
      //   });
      // }
    }
  }

  render() {
    const {
      DataToSet,
      dataToSave,
      value,
      formStatus,
      areaExplorationQuestionaryStatus,
      openAddQuestionForm,
      openOptions,
      dynamicMasterData,
      fieldData,
      fromDate,
      toDate,
      formErrors,
      areaAll,
      whoCanTake,
      name,
      remark,
      nameFocus,
    } = this.state;
    const { auditList, areaList, stockStakeHolderType } = this.props;
    return (
      <>
        <Paper
          sx={{
            borderRadius: 2,
            p: 2,
          }}
          elevation={0}
        >
          <LandingScreenHeader
            screenTitle={value == 0 ? "Audit / Survey" : value == 1 ? "Survey" : "Area Exploration Questionary"}
            showSaveBtnMain={false}
            showAddButton={false}
            showBackButton={true}
            onCancel={this.backToList}
          />
          <br />
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={value}
              disabled={fieldData["id"] ? true : false}
              onChange={this.handleChange}
              row
              defaultValue={value}
            >

              {fieldData["id"] ? (
                <FormControlLabel
                  disabled={true}
                  value="2"
                  control={<Radio />}
                  label="Area Exploration Questionary"
                />
              ) : (
                areaExplorationQuestionaryStatus === "0" && (
                  <FormControlLabel
                    disabled={false}
                    value="2"
                    control={<Radio />}
                    label="Area Exploration Questionary"
                  />
                )
              )}

              {(areaExplorationQuestionaryStatus === "0" || formStatus === "0") && (
                <FormControlLabel
                  disabled={fieldData["id"] ? true : false}
                  value="1"
                  control={<Radio />}
                  label="Survey"
                />
              )}


              {formStatus === "0" && (
                <FormControlLabel
                  disabled={fieldData["id"] ? true : false}
                  value="0"
                  control={<Radio />}
                  label="Audit"
                />
              )}

            </RadioGroup>
            <br />
          </FormControl>



          {value == 0 ? (
            <DynamicFormWithoutSave
              getListById={this.getListById}
              padding={true}
              paddingTop={true}
              showBackToList={true}
              DataToSet={DataToSet}
              dynamicMasterData={dynamicMasterData}
              getByIdApi={true}
              callApi={true}
              getDataById={this.getDataById}
              showTitle={false}
              screenTitle={false}
              fieldMeta={auditJson.fieldMetaForAudit}
              showCancel={auditJson.showCancel}
              apiBaseURL={auditJson.apiBaseURL}
              showSaveNextBtn={auditJson.showSaveNextBtn}
              showSaveBtnMain={false}
              showSaveBtn={true}
              showSubmit={true}
              onSubmit={this.onSubmitAudit}
              saveBtnText={"Add Question"}
              onSave={this.onSearchAudit}
            />

          ) : (
            <>

              {value != 2 && (
                <>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="space-between"
                  >
                    <Grid
                      item
                      sm={5.5}
                      xs={5.5}
                      md={5.5}
                      lg={5.5}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} md={5} sm={5}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16, color: redColor }}
                          label="* "
                        />
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16, color: titleColor }}
                          label="From Date :"
                        />
                      </Grid>
                      <Grid item xs={12} md={7} sm={7}>
                        <BasicDatePicker
                          color="primary"
                          name="fromDate"
                          size="small"
                          value={fromDate}
                          minDate={dayjs()}
                          disabled={false}
                          fullWidth
                          onChange={(newDate) => this.changeDate('fromDate', newDate)}
                          errorText={
                            formErrors["fromDateError"]
                              ? formErrors["fromDateError"]
                              : ""
                          }
                          isError={
                            formErrors["fromDateError"]
                              ? formErrors["fromDateError"]
                              : ""
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      sm={5.5}
                      xs={5.5}
                      md={5.5}
                      lg={5.5}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} md={5} sm={5}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16, color: redColor }}
                          label="* "
                        />
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16, color: titleColor }}
                          label="To Date :"
                        />
                      </Grid>
                      <Grid item xs={12} md={7} sm={7}>
                        <BasicDatePicker
                          color="primary"
                          name="toDate"
                          size="small"
                          value={toDate}
                          minDate={fromDate}
                          disabled={false}
                          fullWidth
                          onChange={(newDate) => this.changeDate('toDate', newDate)}
                          errorText={
                            formErrors["toDateError"] ? formErrors["toDateError"] : ""
                          }
                          isError={
                            formErrors["toDateError"] ? formErrors["toDateError"] : ""
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <br />

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="space-between"
                  >
                    <Grid
                      sm={5.5}
                      xs={5.5}
                      md={5.5}
                      lg={5.5}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} md={5} sm={5}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16, color: redColor }}
                          label={"*"}
                        />
                        <LabelCompo
                          className="text-black"
                          style={{
                            fontSize: 16,
                            color: titleColor,
                          }}
                          label={"Area" + " :"}
                        />
                      </Grid>
                      <Grid item xs={12} md={7} sm={7}>
                        <NewMultipleSelect
                          er={formErrors["areaError"] ? formErrors["areaError"] : ""}
                          label={"Select Area"}
                          name1="area"
                          value1={areaAll}
                          names={[{ id: "all", name: "All" }, ...areaList?.area]}
                          onChange={this.multiSelectHandler}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      sm={5.5}
                      xs={5.5}
                      md={5.5}
                      lg={5.5}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} md={5} sm={5}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16, color: redColor }}
                          label={"*"}
                        />
                        <LabelCompo
                          className="text-black"
                          style={{
                            fontSize: 16,
                            color: titleColor,
                          }}
                          label={"Who can take" + " :"}
                        />
                      </Grid>
                      <Grid item xs={12} md={7} sm={7}>
                        <AutoComplete
                          name={"whoCanTake"}
                          label={"Who can take"}
                          placeHolder={"Select Who can take"}
                          keyColName={"id"}
                          value={whoCanTake}
                          options={stockStakeHolderType?.stockStakeHolderType}
                          onChange={this.onAutocompleteChange}
                          errorText={
                            formErrors["whoCanTakeError"] ? formErrors["whoCanTakeError"] : ""
                          }
                          isError={
                            formErrors["whoCanTakeError"] ? formErrors["whoCanTakeError"] : ""
                          } />
                      </Grid>
                    </Grid>
                  </Grid>
                  <br />
                </>
              )}


              <Grid
                container
                alignItems="center"
                justifyContent="flex-start"
                sm={12}
                xs={12}
                md={12}
                lg={12}
              >
                <Grid
                  item xs={12} md={2.3} sm={2.3}
                  style={{ display: "flex", alignItems: "center", flexWrap: "nowrap", gap: "4px" }}
                >
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label="*"
                  />
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: titleColor }}
                    label="Name :"
                  />
                </Grid>
                <Grid item xs={12} md={6.6} sm={6.6}>
                  <TextFieldCompo
                    placeHolder={"Enter Name"}
                    color="primary"
                    name="name"
                    value={name}
                    fullWidth
                    onChange={this.changeHandler}
                    errorText={formErrors["nameError"] ? formErrors["nameError"] : ""}
                  />
                </Grid>
              </Grid>


              &nbsp;&nbsp;
              <Grid
                container
                alignItems="center"
                justifyContent="flex-start"
                sm={12}
                xs={12}
                md={12}
                lg={12}
              >
                <Grid
                  item xs={12} md={2.3} sm={2.3}
                  style={{ display: "flex", alignItems: "center", flexWrap: "nowrap", gap: "4px" }}
                >
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: titleColor }}
                    label="Remark :"
                  />
                </Grid>
                <Grid item xs={12} md={6.6} sm={6.6}>
                  <TextFieldCompo
                    placeHolder={"Enter Remark"}
                    color="primary"
                    name="remark"
                    value={remark}
                    fullWidth
                    onChange={this.changeHandler}
                    errorText={
                      formErrors["remarkError"] ? formErrors["remarkError"] : ""
                    }
                  />
                </Grid>
              </Grid>


              &nbsp;
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                container
                justifyContent="right"
              >
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Add Question"
                  // fullWidth={true}
                  onClick={this.onSearch}
                />
                &nbsp;&nbsp;&nbsp;
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Submit"
                  // fullWidth={true}
                  onClick={this.onSubmit}
                />
              </Grid>
            </>
          )}



          {auditList?.auditQuestion.length != 0 && (
            <>
              <Paper
                sx={{
                  padding: 2,
                  borderRadius: 2,
                }}
                elevation={0}
              >
                <RTTable
                  columns={this.columns}
                  tableData={auditList?.auditQuestion}
                  rowEdit={this.rowEdit}
                  rowDelete={this.rowDelete}
                />
              </Paper>
            </>
          )}
        </Paper >

        {openAddQuestionForm && (
          <AddQuestion
            open={openAddQuestionForm}
            handleCloses={this.handleClosesForQuestion}
            onSave={this.OnSaveQuestion}
            dataToSave={dataToSave}
            value={value}
          />
        )
        }
        {
          openOptions && (
            <AddOptionForm
              getAuditQestionList={this.getAuditQestionList}
              dataToSave={dataToSave}
              open={openOptions}
              handleCloses={this.handleClosesForOptions}
              onSave={this.OnSaveOptions}
            />
          )
        }
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  stockStakeHolderType: state.stockStakeHolderType,
  auditList: state.audit,
  areaList: state.area,
  outletList: state.outlet,
  beatList: state.beat,
});
const mapDispatchToProps = {
  showLoador,
  showNotification,
  getStockStakeHolderType,
  getStockStakeHolderTypeForList,
  getAuditQestion,
  getAreaByUserType,
  getBeatsByAreaIds,
  getOutletByBeatIds,
  resetDataAddQuestion,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AuditForm);
