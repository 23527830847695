import React from "react";
import { connect } from "react-redux";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import { packagingTypeJson } from "../../../DynamicFormsJson/MastersJSON/PackagingType";
import { Grid, Paper } from "@mui/material";
import LandingScreenHeader from "../../../components/Comman/LandingScreenHeader";
import { LabelCompo } from "../../../components/Comman/Label";
import { TextFieldCompo } from "../../../components/Comman/TextFieldCompo";
import { redColor, titleColor } from "../../../config/ColorObj";
import { descriptionMsg, nameMsg, noInternetMsg, saveFailedMsg, saveWarningMsg, savemsg, serverMsg, shortNameMsg, sortOrderMsg, updateData } from "../../../config/messageconstant";
import { onlyNumber } from "../../../components/Comman/Util/Validations";
import { ButtonCompo } from "../../../components/Comman/Button";
import { apiGet, apiPost } from "../../../utils/api_service";
import endpoint from "../../../config/endpoints";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import { compose } from "@reduxjs/toolkit";
import { withRouter } from "../../../components/withRouter";

class PackagingTypeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formErrors: {},
      name: "",
      shortName: "",
      description: "",
      sortOrderNo: "",
      isLog: true,
      moduleObjDataObj: {},
    };
  }


  async componentDidMount() {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {

      const moduleObjData = localStorage.getItem("moduleObj");
      const moduleObjDataObj = moduleObjData != null ? JSON.parse(moduleObjData) : {};

      this.setState({ moduleObjDataObj });

      if (this.props.params.id) {
        this.getDataById(this.props.params.id)
      }

    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  }


  getDataById = (id) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      const response = apiGet({
        url: packagingTypeJson.apiBaseURL + "/" + id,
      }).then(({ data, success }) => {
        console.log(data.data);
        showLoador({ loador: false });
        if (success) {
          if (!data.error) {
            if (data && data.data) {
              this.setState({
                name: data.data.name ? data.data.name : "",
                shortName: data.data.shortName ? data.data.shortName : "",
                description: data.data.description ? data.data.description : "",
                sortOrderNo: data.data.sortOrderNo ? data.data.sortOrderNo : "",
              });
            }
          }
        } else {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      return response;
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };



  changeHandler = (e) => {
    const { name, value } = e.target;
    const { formErrors } = this.state;
    if (name === "name") {
      this.setState({
        name: value,
      })
    } else if (name === "shortName") {
      this.setState({
        shortName: value,
      })
    } else if (name === "description") {
      this.setState({
        description: value,
      })
    } else if (name === 'sortOrderNo') {
      if (value === "" || onlyNumber.test(value)) {
        this.setState({
          sortOrderNo: value,
          formErrors,
        })
      }
    }
  }


  checkValidation = () => {
    const { name, shortName, description, sortOrderNo } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (name === "") {
      formIsValid = false;
      formErrors["nameError"] = nameMsg;
    }
    if (shortName === "") {
      formIsValid = false;
      formErrors["shortNameError"] = shortNameMsg;
    }
    if (description === "") {
      formIsValid = false;
      formErrors["descriptionError"] = descriptionMsg;
    }
    if (sortOrderNo === "") {
      formIsValid = false;
      formErrors["sortOrderNoError"] = sortOrderMsg;
    }
    this.setState({
      formErrors: formErrors,
    })
    return formIsValid;
  };

  goBack = () => {
    window.location.replace("/packaging-type-list");
  };

  submitHandler = () => {
    const { showLoador, showNotification } = this.props;
    const { name, shortName, description, sortOrderNo } = this.state;

    if (navigator.onLine) {
      if (this.checkValidation()) {
        if (this.props.params.id) {
          let packagingTypeObject = {
            id: this.props.params.id,
            name: name,
            shortName: shortName,
            description: description,
            sortOrderNo: sortOrderNo,
          };
          swal({
            title: "Are you sure?",
            text: updateData,
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              this.onSave(packagingTypeObject);
            }
          });
        } else {
          let packagingTypeObject = {
            name: name,
            shortName: shortName,
            description: description,
            sortOrderNo: sortOrderNo,
          };
          swal({
            title: "Are you sure?",
            text: saveWarningMsg,
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              this.onSave(packagingTypeObject);
            }
          });
        }

      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onSave = (packagingTypeObject) => {
    const { showLoador, showNotification } = this.props;
    const { moduleObjDataObj, isLog } = this.state;

    if (navigator.onLine) {
      showLoador({ loador: true });
      apiPost({
        url: endpoint.packagingType,
        postBody: packagingTypeObject,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          if (isLog) {
            if (data && data.data !== null) {

              let updatedJsonToSend = JSON.stringify(data.data);

              let dataToSaveForMLog = {
                module: {
                  moduleId: moduleObjDataObj.moduleId
                },
                subModule: {
                  subModuleId: moduleObjDataObj.subModuleId
                },
                operationType: this.props.params.id ? 1 : 0,
                type: 0,
                operationPerformId: data.data.id,
                updatedJson: updatedJsonToSend,
              }

              showLoador({ loador: true });
              apiPost({
                url: endpoint.mlog,
                postBody: dataToSaveForMLog,
              }).then(({ success }) => {
                showLoador({ loador: false });
                if (success) {
                } else {
                  showNotification({
                    msg: saveFailedMsg,
                    severity: "error",
                  });
                }
              });
            }
          }

          showNotification({ msg: savemsg });
          window.location.replace("/packaging-type-list");
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  render() {
    const { formErrors, name, shortName, description, sortOrderNo } = this.state;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <LandingScreenHeader
            screenTitle={"Packaging Type"}
            showTable={false}
            showBackButton={false}
            showAddButton={false}
          />
          <Grid container justifyContent="space-between" direction="row">
            <Grid
              item
              xs={12}
              md={12}
              size="small"
              container
              justifyContent="flex-end"
            >

              <ButtonCompo
                size="medium"
                type="button"
                variant="outlined"
                name=" Back to List"
                onClick={this.goBack}
              />
            </Grid>
          </Grid>


          <br></br>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="space-between"
          >
            <br></br>
            <br /> <br />
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              container
              justifyContent="space-between"
            >
              <Grid
                item
                sm={6}
                xs={7}
                md={8}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} md={5} sm={5}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label="* "
                  />
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: titleColor }}
                    label="Name :"
                  />
                </Grid>
                <Grid item xs={12} md={7} sm={7}>
                  <TextFieldCompo
                    placeHolder={"Packaging Type Name"}
                    color="primary"
                    name="name"
                    size="small"
                    value={name}
                    fullWidth
                    onChange={this.changeHandler}
                    errorText={
                      formErrors["nameError"] ? formErrors["nameError"] : ""
                    }
                  />
                </Grid>
              </Grid>

              <Grid
                item
                sm={6}
                xs={7}
                md={8}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} md={5} sm={5}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label="* "
                  />
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: titleColor }}
                    label="Short Name :"
                  />
                </Grid>
                <Grid item xs={12} md={7} sm={7}>
                  <TextFieldCompo
                    placeHolder={"Short Name"}
                    color="primary"
                    name="shortName"
                    size="small"
                    value={shortName}
                    fullWidth
                    onChange={this.changeHandler}
                    errorText={
                      formErrors["shortNameError"] ? formErrors["shortNameError"] : ""
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <br /> <br />
            <Grid
              item
              sm={6}
              xs={8}
              md={6}
              lg={6}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} md={5} sm={5}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: redColor }}
                  label="* "
                />
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: titleColor }}
                  label="Description :"
                />
              </Grid>
              <Grid item xs={12} md={7} sm={7}>
                <TextFieldCompo
                  placeHolder={"Enter Description"}
                  color="primary"
                  name="description"
                  size="small"
                  value={description}
                  fullWidth
                  onChange={this.changeHandler}
                  errorText={
                    formErrors["descriptionError"]
                      ? formErrors["descriptionError"]
                      : ""
                  }
                />
              </Grid>
            </Grid>
            <Grid
              item
              sm={6}
              xs={7}
              md={8}
              lg={6}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} md={5} sm={5}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: redColor }}
                  label="* "
                />
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: titleColor }}
                  label="Sort Order No :"
                />
              </Grid>
              <Grid item xs={12} md={7} sm={7}>
                <TextFieldCompo
                  placeHolder={"Sort Order Number "}
                  color="primary"
                  name="sortOrderNo"
                  size="small"
                  value={sortOrderNo}
                  fullWidth
                  onChange={this.changeHandler}
                  errorText={
                    formErrors["sortOrderNoError"]
                      ? formErrors["sortOrderNoError"]
                      : ""
                  }
                  type="number"
                />
              </Grid>
            </Grid>
          </Grid>
          &nbsp;&nbsp; &nbsp;&nbsp;
          <div align="right">
            <ButtonCompo
              size="button"
              type="button"
              variant="outlined"
              name="Cancel"
              onClick={this.goBack}
            />
            &nbsp;&nbsp;
            <ButtonCompo
              size="button"
              type="button"
              variant="contained"
              name="Submit"
              onClick={this.submitHandler}
            />
            {/* &nbsp;&nbsp;
            {!id == 0 && (
              <ButtonCompo
                size="button"
                type="button"
                variant="contained"
                name="Submit and Update"
                onClick={this.submitAndUpdateHandler}
              />
            )} */}
          </div>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
});
const mapDispatchToProps = {
  showLoador,
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PackagingTypeForm);
