import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";

const initialState = {
  stakeholderTypeListForAllowanceConfig: [],
};
let URL = endpoints.stakeholderAllowance;
const stakeholderAllowanceSlice = createSlice({
  name: "stakeholderAllowance",
  initialState,
  reducers: {
    stakeholderTypeListForAllowanceConfigSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      console.log(row);
      return {
        ...state,
        stakeholderTypeListForAllowanceConfig: row,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const { stakeholderTypeListForAllowanceConfigSuccess, resetState } =
  stakeholderAllowanceSlice.actions;

export default stakeholderAllowanceSlice.reducer;

export const getStakeholderTypeListForAllowanceConfig =
  () => async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/get-all",
      }).then(({ data, success }) => {
        if (success) {
          console.log(data);
          const row = data.data.map((stakeholderTypeItem, index) => {
            let stakeholderTypeObj = {
              id: stakeholderTypeItem.id === null ? "" : stakeholderTypeItem.id,
              index: index + 1,
              stockStakeHolderType: {
                id:
                  stakeholderTypeItem.stockStakeHolderType.id === null
                    ? ""
                    : stakeholderTypeItem.stockStakeHolderType.id,
              },
              name:
                stakeholderTypeItem.stockStakeHolderType.name === null
                  ? ""
                  : stakeholderTypeItem.stockStakeHolderType.name,
              daLocal:
                stakeholderTypeItem.daLocal === null
                  ? ""
                  : stakeholderTypeItem.daLocal,
              daOutstation:
                stakeholderTypeItem.daOutstation === null
                  ? ""
                  : stakeholderTypeItem.daOutstation,
              ratePerKm:
                stakeholderTypeItem.ratePerKm === null
                  ? ""
                  : stakeholderTypeItem.ratePerKm,
            };
            return stakeholderTypeObj;
          });
          dispatch(stakeholderTypeListForAllowanceConfigSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
