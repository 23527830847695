import React from "react";
import { connect } from "react-redux";
// import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import DynamicTabwiseTable from "../../../components/dynamicscreens/DynamicTabwiseTable";
import {
  OfferColumnsCurrent,
  OfferColumns,
} from "../../../tableColumns/table-columns";
import { offerJson } from "../../../DynamicFormsJson/MastersJSON/offer";
import {
  getOfferList,
  getOfferListComplete,
  getOfferListPast,
  getOfferListCurrent,
  getOfferListFuture,
} from "../../../Slice/offer.slice";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import {
  changeActive,
  deleteConfirmMsg,
  deleteFailedMsg,
  deletemsg,
  noInternetMsg,
  saveFailedMsg,
  serverMsg,
  statusFailedMsg,
  statusmsg,
} from "../../../config/messageconstant";
import { apiDelete, apiGet, apiPost } from "../../../utils/api_service";
import endpoint from "../../../config/endpoints";
import { withRouter } from "../../../components/withRouter";
import { compose } from "recompose";
export const OperationMode = {
  Status: "Status",
};
class offerTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDate: "",
      toDate: "",
      currentOperationMode: "",
      isLog: true,
      moduleObjDataObj: {},
      dynamicMasterData: {
        currentOfferList: this.props.offerList?.offerCurrentData,
        futureOfferList: this.props.offerList?.offerFutureData,
        pastOfferList: this.props.offerList?.offerPastData,
      },

      levelWiseType: [
        {
          id: 0,
          name: "Flat %",
        },
        {
          id: 1,
          name: "Flat amount",
        },
        {
          id: 2,
          name: " % upto given amount",
        },
        {
          id: 3,
          name: "Bill wise free item",
        },
        {
          id: 4,
          name: " % wise",
        },
        {
          id: 5,
          name: "Item wise free item",
        },
        {
          id: 6,
          name: "On Bill 1 on 1 Free",
        },
        {
          id: 7,
          name: "On Item 1 on 1 Free",
        },
        { id: 8, name: "Flat Amount Off" },
        { id: 9, name: "Per Unit Value" },
      ],

      tabList: [
        {
          label: "Current Offer Data",
          allyProps: 0,
          columns: OfferColumnsCurrent,
          isActionColActive: true,
          value: "currentOfferList",
          searchList: offerJson.searchList,
          tableColumnsToFilter: offerJson.tableColumnsToFilter,
          showExcelDownload: false,
          showExtension: false,
          changeExcelURL: "offer-data",
        },
        {
          label: "Future Offer Data",
          allyProps: 1,
          columns: OfferColumns,
          tableColumnsToFilter: offerJson.tableColumnsToFilterFutureAndPast,
          isActionColActive: true,
          value: "futureOfferList",
          searchList: offerJson.searchList,
          showExcelDownload: false,
        },
        {
          label: "Past Offer Data",
          allyProps: 2,
          columns: OfferColumns,
          isActionColActive: true,
          value: "pastOfferList",
          searchList: offerJson.searchListForPast,
          tableColumnsToFilter: offerJson.tableColumnsToFilterFutureAndPast,
          showExcelDownload: false,
        },
      ],
    };
  }

  getCurrent = async () => {
    const { getOfferListCurrent, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getOfferListCurrent({
        flag: "",
        level: "",
        levelWiseType: "",
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  getFuture = async () => {
    const { getOfferListFuture, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getOfferListFuture({ flag: "", level: "", levelWiseType: "" }).then(
        ({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        }
      );
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  getPast = async () => {
    const { getOfferListPast, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getOfferListPast({ flag: "", level: "", levelWiseType: "" }).then(
        ({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        }
      );
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  async componentDidMount() {
    const moduleObjData = localStorage.getItem("moduleObj");
    const moduleObjDataObj =
      moduleObjData != null ? JSON.parse(moduleObjData) : {};

    this.setState({ moduleObjDataObj });

    await this.getTableData();
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.levelWiseType !== nextProps.levelWiseType) {
      if (this.state.levelWiseType !== nextProps.levelWiseType) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            levelWiseTypeDetail: this.state.levelWiseType,
          },
        });
      }
    }

    if (this.props.offerList !== nextProps.offerList) {
      if (
        this.props.offerList?.offerCurrentData !==
        nextProps.offerList?.offerCurrentData
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            currentOfferList: nextProps.offerList?.offerCurrentData,
          },
        });
      }
    }

    if (this.props.offerList !== nextProps.offerList) {
      if (
        this.props.offerList?.offerFutureData !==
        nextProps.offerList?.offerFutureData
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            futureOfferList: nextProps.offerList?.offerFutureData,
          },
        });
      }
    }

    if (this.props.offerList !== nextProps.offerList) {
      if (
        this.props.offerList?.offerPastData !==
        nextProps.offerList?.offerPastData
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            pastOfferList: nextProps.offerList?.offerPastData,
          },
        });
      }
    }
  }

  onSearchData = async (data) => {
    console.log(data);
    const { getOfferListComplete, showLoador, showNotification } = this.props;
    const { dynamicMasterData } = this.state;

    this.setState({
      fromDate: data.fromDate,
      toDate: data.toDate,
    });
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getOfferListComplete({
        fromDate: data.fromDate,
        toDate: data.toDate,
        level: data.level,
        levelWiseType: data.levelWiseType,
        flag: data.flag,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  rowStatus = (row) => {
    const { moduleObjDataObj, isLog } = this.state;
    const { showLoador, showNotification } = this.props;

    if (navigator.onLine) {
      swal(changeActive, {
        buttons: ["Cancel", true],
      }).then((value) => {
        if (value) {
          showLoador({ loador: true });
          let status = row.isActive == 1 ? 0 : 1;
          apiPost({
            url:
              endpoint.offer +
              "/update-status?id=" +
              row.id +
              "&status=" +
              status,
          }).then(({ data, success }) => {
            showLoador({ loador: false });
            if (success) {
              if (isLog) {
                if (row !== null) {
                  let updatedJsonToSend = JSON.stringify(row);

                  let dataToSaveForMLog = {
                    module: {
                      moduleId: moduleObjDataObj.moduleId,
                    },
                    subModule: {
                      subModuleId: moduleObjDataObj.subModuleId,
                    },
                    operationType: 1,
                    type: 0,
                    operationPerformId: row.id,
                    updatedJson: updatedJsonToSend,
                  };

                  showLoador({ loador: true });
                  apiPost({
                    url: endpoint.mlog,
                    postBody: dataToSaveForMLog,
                  }).then(({ success }) => {
                    showLoador({ loador: false });
                    if (success) {
                    } else {
                      showNotification({
                        msg: msgConstant.saveFailedMsg,
                        severity: "error",
                      });
                    }
                  });
                }
              }

              this.getCurrent();
              showNotification({
                msg: statusmsg,
              });
            } else {
              showNotification({
                msg: statusFailedMsg,
                severity: "error",
              });
            }
          });
        }
      });
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  };

  rowDelete = (row) => {
    if (navigator.onLine) {
      swal({
        title: "Are you sure?",
        text: deleteConfirmMsg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.onDelete(row);
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onDelete = async (row) => {
    const { moduleObjDataObj, isLog } = this.state;

    if (navigator.onLine) {
      this.props.showLoador({ loador: true });
      await apiDelete({
        url: endpoint.offer + "/" + row.id,
      }).then(({ success }) => {
        if (success) {
          if (isLog) {
            if (row !== null) {
              let updatedJsonToSend = JSON.stringify(row);

              let dataToSaveForMLog = {
                module: {
                  moduleId: moduleObjDataObj.moduleId,
                },
                subModule: {
                  subModuleId: moduleObjDataObj.subModuleId,
                },
                operationType: 2,
                type: 0,
                operationPerformId: row.id,
                updatedJson: updatedJsonToSend,
              };

              this.props.showLoador({ loador: true });
              apiPost({
                url: endpoint.mlog,
                postBody: dataToSaveForMLog,
              }).then(({ success }) => {
                this.props.showLoador({ loador: false });
                if (success) {
                } else {
                  this.props.showNotification({
                    msg: saveFailedMsg,
                    severity: "error",
                  });
                }
              });
            }
          }

          this.props.showLoador({ loador: false });
          this.props.showNotification({ msg: deletemsg });
          this.getCurrent(), this.getFuture(), this.getPast();
        } else {
          this.props.showNotification({
            msg: deleteFailedMsg,
            severity: "error",
          });
          this.props.showLoador({ loador: false });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  rowViewData = (row) => {
    this.props.navigate("/offer-view/" + row.id);
  };

  onAddButtonClick = () => {
    this.props.navigate(offerJson.formPath);
  };

  getTableData = async () => {
    this.getCurrent(), this.getFuture(), this.getPast();
  };

  getExcelUrl = () => {
    const { fromDate, toDate } = this.state;
    "notice?fromDate=" + fromDate + "&toDate=" + toDate;
  };

  rowEdit = (row) => {
    this.props.navigate("/offer-edit-form/" + row.id);
  };

  render() {
    const { offerList } = this.props;
    const { tabList, dynamicMasterData, fromDate, toDate } = this.state;
    return (
      <>
        <DynamicTabwiseTable
          rowList={dynamicMasterData}
          showHeadEdit={true}
          dynamicMasterData={dynamicMasterData}
          showView={true}
          formPath={offerJson.formPath}
          searchList={offerJson.searchList}
          screenTitle={offerJson.screenTitle}
          showPdfDownload={offerJson.showPdfDownload}
          showExcelDownload={false}
          changeExcelURL={"offer-data"}
          showExtension={false}
          formPathView={offerJson.formPathView}
          pdfFileName={offerJson.pdfFileName}
          excelFileName={offerJson.excelFileName}
          showAddButton={offerJson.showAddButton}
          tableColumnsToFilter={offerJson.tableColumnsToFilter}
          tableData={offerList?.offer}
          apiBaseURL={offerJson.apiBaseURL}
          rowEdit={this.rowEdit}
          onSearchData={this.onSearchData}
          callApi={false}
          getTableDataById={true}
          isActiveURL={offerJson.isActiveURL}
          tabList={tabList}
          isNote={true}
          isLog={true}
          type={0}
          isNoteValue={"To check Inactive offers, kindly user filter"}
          rowStatus={this.rowStatus}
          rowDelete={this.rowDelete}
          rowViewData={this.rowViewData}
          getTableData={this.getTableData}
          onAddButtonClick={this.onAddButtonClick}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  offerList: state.offer,
});
const mapDispatchToProps = {
  getOfferList,
  getOfferListComplete,
  showNotification,
  showLoador,
  getOfferListPast,
  getOfferListCurrent,
  getOfferListFuture,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(offerTable);
