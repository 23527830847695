import { Grid, Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { ButtonCompo } from "../../../components/Comman/Button";
import MaterialUITableWithTextField from "../../../components/Comman/RT/MaterialUITableWithTextField";
import DynamicFormWithoutSave from "../../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../../components/withRouter";
import endpoint from "../../../config/endpoints";
import {
  imageMsg,
  noInternetMsg,
  saveFailedMsg,
  savemsg,
  saveWarningMsg,
  serverMsg,
} from "../../../config/messageconstant";
import { StakeHolderDocumentJson } from "../../../DynamicFormsJson/Transaction/StakeHolderDocumentJson";
import { setStakeDocList } from "../../../Slice/StackHolder.slice";
import { apiGet, apiPost, apiPostForImage } from "../../../utils/api_service";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
class StakeHolderDocument extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productList: [],
      showDate: false,
      DataToSet: [],
      stockStakeHolderId: "",
      isLog: true,
      moduleObjDataObj: {},
    };
  }

  async componentDidMount() {
    const { showNotification, setStakeDocList } = this.props;
    if (navigator.onLine) {

      const moduleObjData = localStorage.getItem("moduleObj");
      const moduleObjDataObj = moduleObjData != null ? JSON.parse(moduleObjData) : {};

      this.setState({ moduleObjDataObj });

      showLoador({ loador: true });
      const rowData = await apiGet({
        url:
          endpoint.stockDocumentDetail +
          "/with-all-document?stockStakeHolderId=" +
          this.props.params.id,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
          return [];
        }

        return data.data.map((rowInfo, index) => {
          rowInfo = {
            ...rowInfo,
            index: index + 1,
            mainId: rowInfo.id === null ? "" : rowInfo.id,
            documentName:
              rowInfo.documentTable.docName === null
                ? ""
                : rowInfo.documentTable.docName,
            id:
              rowInfo.documentTable.id === null ? "" : rowInfo.documentTable.id,
            imagePath: rowInfo.fileName === null ? "" : rowInfo.fileName,
            imagePathToSave: "",
          };
          return rowInfo;
        });
      });
      if (this.props.params.id) {
        await this.getStakeHolderById(this.props.params.id);
        setStakeDocList({
          row: rowData,
        });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  getStakeHolderById = async (id) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await apiGet({
        url: endpoint.stackholder + "/" + id,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          if (!data.error) {
            this.setState({
              stockStakeHolderId: data.data.id !== null ? data.data.id : "",
              DataToSet: [
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Firm Name",
                  value: data.data.firmName !== null ? data.data.firmName : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Owner Name",
                  value:
                    data.data.ownerName !== null ? data.data.ownerName : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Credit Limit",
                  value:
                    data.data.creditLimit !== null ? data.data.creditLimit : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "SPOC",
                  value:
                    data.data.contactPersonName !== null
                      ? data.data.contactPersonName
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "SPOC Number",
                  value:
                    data.data.contactPersonNo !== null
                      ? data.data.contactPersonNo
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Email Id",
                  value: data.data.emailId !== null ? data.data.emailId : "",
                },
                {
                  xs: 12,
                  sm: 12,
                  md: 12,
                  lg: 12,
                  labelSM: 2,
                  labelMD: 2,
                  labelLG: 2,
                  valueSM: 10,
                  valueMD: 10,
                  valueLG: 10,
                  label: "Address",
                  value: data.data.address !== null ? data.data.address : "",
                },
              ],
            });
          }
        } else {
          showNotification({ msg: serverMsg, severity: "error" });
        }

        return success;
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onFormSave = async () => {
    const { stockStakeHolderId } = this.state;
    const { stakeHolderTypeList, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      const documentForStakeHolderList =
        stakeHolderTypeList?.documentForStakeHolder.filter(
          (row) => row.imagePathToSave != ""
        );
      if (documentForStakeHolderList.length != 0) {
        await documentForStakeHolderList.map(async (imageData) => {
          const formData = new FormData();
          formData.append("file", imageData.imagePathToSave);
          formData.append("imageName", imageData.imagePath);
          showLoador({ loador: true });
          await apiPostForImage({
            url: endpoint.uploadImage,
            postBody: formData,
          }).then(({ data, success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: imageMsg, severity: "error" });
            }
          });

          return null;
        });
        const dataToSave = await documentForStakeHolderList.map(
          (imageData, data) => {
            let documentForStakeHolderObj = {
              documentTable: {
                id: imageData.id,
              },
              stockStakeHolder: {
                id: stockStakeHolderId,
              },
              fileName: imageData.imagePath,
            };
            if (imageData.mainId != 0) {
              documentForStakeHolderObj = {
                ...documentForStakeHolderObj,
                id: imageData.mainId,
              };
            }
            return documentForStakeHolderObj;
          }
        );
        this.submitHandler(dataToSave);
      } else {
        this.props.navigate(-1);
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  submitHandler = async (dataToSave) => {
    swal({
      title: "Are you sure?",
      text: saveWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onSubmit(dataToSave);
      }
    });
  };

  onSubmit = async (dataToSave) => {
    const { showLoador, showNotification } = this.props;
    const { isLog, moduleObjDataObj, stockStakeHolderId } = this.state;

    if (navigator.onLine) {

      const documentTableId = dataToSave.map((dataObj) => dataObj.documentTable && dataObj.documentTable.id);
      showLoador({ loador: true });
      await apiPost({
        url: endpoint.stockDocumentDetail + "/save-list",
        postBody: dataToSave,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {

          if (isLog) {
            if (data.data !== null) {

              let updatedJsonToSend = JSON.stringify(data.data);

              let dataToSaveForMLog = {
                module: {
                  moduleId: moduleObjDataObj.moduleId
                },
                subModule: {
                  subModuleId: moduleObjDataObj.subModuleId
                },
                operationType: this.props.params.id ? 1 : 0,
                type: 1,
                operationPerformId: stockStakeHolderId,
                subOperationPerformIds: documentTableId.join(", "),
                updatedJson: updatedJsonToSend,
              }

              showLoador({ loador: true });
              apiPost({
                url: endpoint.mlog,
                postBody: dataToSaveForMLog,
              }).then(({ success }) => {
                showLoador({ loador: false });
                if (success) {
                } else {
                  showNotification({
                    msg: saveFailedMsg,
                    severity: "error",
                  });
                }
              });
            }
          }

          showNotification({ msg: savemsg });
          this.props.navigate(-1);
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  render() {
    const { DataToSet } = this.state;
    const { setStakeDocList, stakeHolderTypeList } = this.props;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            showBackToList={true}
            DataToSet={DataToSet}
            formPath={StakeHolderDocumentJson.formPath}
            showSaveBtn={StakeHolderDocumentJson.showSaveBtn}
            showTitle={StakeHolderDocumentJson.showTitle}
            screenTitle={StakeHolderDocumentJson.screenTitle}
            fieldMeta={StakeHolderDocumentJson.fieldMeta}
            showCancel={StakeHolderDocumentJson.showCancel}
            apiBaseURL={StakeHolderDocumentJson.apiBaseURL}
            showSaveNextBtn={StakeHolderDocumentJson.showSaveNextBtn}
            isActionColActive={false}
            showSaveBtnMain={StakeHolderDocumentJson.showSaveBtn}
          />

          <MaterialUITableWithTextField
            tableHead={[
              {
                title: "#",
                name: "index",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "center",
              },
              {
                title: "Document Name",
                name: "documentName",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
              },

              {
                title: "Image",
                name: "imagePath",
                nameToSave: "imagePathToSave",
                selectCompo: true,
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
                placeHolder: "Enter Remark",
              },
            ]}
            data={stakeHolderTypeList?.documentForStakeHolder}
            setList={setStakeDocList}
            isActionColActive={false}
          />
          <br />
          {stakeHolderTypeList?.documentForStakeHolder.length != 0 && (
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="right"
            >
              <ButtonCompo
                size="medium"
                type="submit"
                variant="contained"
                name={"Submit"}
                onClick={this.onFormSave}
              />
            </Grid>
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  stakeHolderTypeList: state.stackholder,
});
const mapDispatchToProps = {
  showLoador,
  setStakeDocList,
  showNotification,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(StakeHolderDocument);
