import {
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import LandingScreenHeader from "../../../components/Comman/LandingScreenHeader";
import DynamicFormForDocumentUpload from "../../../components/dynamicscreens/DynamicFormForDocumentUpload";
import { withRouter } from "../../../components/withRouter";
import { redColor, titleColor } from "../../../config/ColorObj";
import { noInternetMsg, serverMsg } from "../../../config/messageconstant";
import { outletJson } from "../../../DynamicFormsJson/MastersJSON/outlet";
import { getUpdatedBeatByUserType } from "../../../Slice/beat.slice";
import { getOutletType } from "../../../Slice/outletType.slice";
// import { getStateList } from "../../../Slice/state.slice";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import GoogleMap from "./GoogleMap";
import { getSubChannelByChannel } from "../../../Slice/channel.slice";
import { getStockHoldersByBeats } from "../../../Slice/StackHolder.slice";
import { getChannel } from "../../../Slice/channel.slice";
class OutletForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLocation: false,
      latitute: "",
      longitute: "",
      locationValue: "0",
      openMap: false,
      dynamicMasterData: {
        outletTypeDetail: this.props.outletTypeList?.outletType,
        beatDetail: this.props.beatList?.beatByUserType,
        stateDetails: this.props.stateList?.stateList,
        subChannelDetail: this.props.subChannelList?.subChannel,
        stockHolderDetail: this.props.stockHolderList?.stockHolderByBeatIds,
        channelDetail: this.props.channelList?.channel,
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    const { openMap } = this.state;
    if (this.props.beatList !== nextProps.beatList) {
      if (
        this.props.beatList?.beatByUserType !==
        nextProps.beatList?.beatByUserType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            beatDetail: nextProps.beatList?.beatByUserType,
          },
        });
      }
    }

    if (this.props.subChannelList !== nextProps.subChannelList) {
      if (
        this.props.subChannelList?.subChannel !==
        nextProps.subChannelList?.subChannel
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            subChannelDetail: nextProps.subChannelList?.subChannel,
          },
        });
      }
    }

    if (this.props.channelList !== nextProps.channelList) {
      if (
        this.props.channelList?.channel !==
        nextProps.channelList?.channel
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            channelDetail: nextProps.channelList?.channel,
          },
        });
      }
    }

    if (this.props.stockHolderList !== nextProps.stockHolderList) {
      if (
        this.props.stockHolderList?.stockHolderByBeatIds !==
        nextProps.stockHolderList?.stockHolderByBeatIds
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stockHolderDetail: nextProps.stockHolderList?.stockHolderByBeatIds,
          },
        });
      }
    }

    if (this.props.outletTypeList !== nextProps.outletTypeList) {
      if (
        this.props.outletTypeList?.outletType !==
        nextProps.outletTypeList?.outletType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            outletTypeDetail: nextProps.outletTypeList?.outletType,
          },
        });
      }
    }
    if (this.props.stateList !== nextProps.stateList) {
      if (this.props.stateList?.states !== nextProps.stateList?.states) {
        if (openMap) {
          this.setState({
            dynamicMasterData: {
              stateDetails: nextProps.stateList?.states,
              ...this.state.dynamicMasterData,
            },
          });
        } else {
          this.setState({
            dynamicMasterData: {
              ...this.state.dynamicMasterData,
              stateDetails: nextProps.stateList?.states,
            },
          });
        }
      }
    }
  }

  getCurrentLocationData = () => {
    const success = (position) => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      this.setState({
        latitute: latitude,
        longitute: longitude,
      });
      return { latitute: latitude, longitute: longitude };
    };

    const error = () => {
      console.log("Unable to retrieve your location");
      return "";
    };

    navigator.geolocation.getCurrentPosition(success, error);
  };

  async componentDidMount() {
    if (this.props.params.id == undefined) {
      this.setState({
        showLocation: true,
      });
      this.getCurrentLocationData();
    }
    const {
      getOutletType,
      getUpdatedBeatByUserType,
      getChannel,
      // getStateList,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getUpdatedBeatByUserType().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

      showLoador({ loador: true });
      await getChannel().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });


      showLoador({ loador: true });
      await getOutletType().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

      // showLoador({ loador: true });
      // await getStateList().then(({ success }) => {
      //   showLoador({ loador: false });
      //   if (!success) {
      //     showNotification({ msg: serverMsg, severity: "error" });
      //   }
      // });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  getListById = async (data) => {

    const {
      getSubChannelByChannel,
      getStockHoldersByBeats,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      if (data.channelId && data.channelId != "") {
        showLoador({ loador: true });
        await getSubChannelByChannel({
          channelId: data.channelId,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }

      if (data.beat && data.beat.id && data.beat.id != "") {

        let beatJson = {
          beatIds: data.beat.id.split(","),
        }

        showLoador({ loador: true });
        await getStockHoldersByBeats({ beatIds: beatJson }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });

      }
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  }

  getLocation = (latitute, longitute) => {
    this.setState({
      openMap: false,
      latitute: latitute,
      longitute: longitute,
    });
  };

  onCancel = () => {
    this.props.navigate(-1);
  };

  onSaveLocation = () => {
    this.setState({
      openMap: true,
    });
  };

  onRadioChange = (e) => {
    const { value } = e.target;
    this.setState({
      locationValue: value,
    });
    if (value == 1) {
      this.getCurrentLocationData();
    }
  };

  getLocationData = () => {
    let locationData = "";
    const { latitute, longitute } = this.state;
    if (
      this.props.params.id == undefined &&
      latitute == "" &&
      longitute == ""
    ) {
      this.props.showNotification({
        msg: "Please allow location",
        severity: "error",
      });
    } else if (latitute != "" && longitute != "") {
      locationData = {
        latitude: this.state.latitute,
        longitude: this.state.longitute,
      };
    }

    return locationData;
  };

  changeLocation = () => {
    const { latitute, longitute } = this.state;
    if (latitute == "" && longitute == "") {
      this.getCurrentLocationData();
    }
    this.setState({
      showLocation: true,
    });
  };

  setDataToForm = (data) => {
    const { latitute, longitute } = this.state;
    if (latitute == "" && longitute == "") {
      this.setState({
        latitute: data.latitude,
        longitute: data.longitude,
      });
    }
  };

  render() {
    const {
      dynamicMasterData,
      openMap,
      latitute,
      longitute,
      showLocation,
      locationValue,
    } = this.state;

    return (
      <>
        {!openMap ? (
          <>
            <Paper
              sx={{
                p: 2,
                pt: 2,
                borderRadius: 2,
              }}
              elevation={0}
            >
              <LandingScreenHeader
                screenTitle={outletJson.screenTitle}
                showTable={false}
                showBackButton={true}
                showAddButton={false}
                onCancel={this.onCancel}
              />
              <br />
              <Grid container spacing={1}>
                {this.props.params.id != undefined && !showLocation && (
                  <>
                    <Grid container xs={12} sm={6} md={6}>
                      <ButtonCompo
                        size="medium"
                        type="submit"
                        variant="contained"
                        name={"Change Location"}
                        onClick={this.changeLocation}
                      />
                    </Grid>
                    <Grid container xs={12} sm={6} md={6}></Grid>
                  </>
                )}

                {showLocation && (
                  <>
                    <Grid container xs={12} sm={6} md={6}>
                      <Grid item xs={12} sm={4} md={4}>
                        &nbsp;&nbsp;
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16, color: redColor }}
                          label={"*"}
                        />
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16, color: titleColor }}
                          label={"Location"}
                        />
                      </Grid>
                      <Grid item xs={12} sm={8} md={8}>
                        <RadioGroup
                          row
                          aria-label={"Location"}
                          name={"Location"}
                          id={"Location"}
                          value={locationValue}
                          onChange={this.onRadioChange}
                          size="small"
                        >
                          <FormControlLabel
                            value={"1"}
                            control={<Radio />}
                            label={"Current"}
                          />
                          <FormControlLabel
                            value={"0"}
                            control={<Radio />}
                            label={"From Map"}
                          />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                    <Grid container xs={12} sm={6} md={6}>
                      {locationValue != 1 && (
                        <ButtonCompo
                          size="medium"
                          type="submit"
                          variant="contained"
                          name={"Get Location"}
                          onClick={this.onSaveLocation}
                        />
                      )}
                    </Grid>
                  </>
                )}

                {latitute != "" && longitute != "" && (
                  <>
                    <Grid container xs={12} sm={6} md={6}>
                      <Grid container xs={12} sm={4} md={4}>
                        &nbsp;&nbsp;
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16, color: titleColor }}
                          label={"Address : "}
                        />
                      </Grid>
                      <Grid container xs={12} sm={8} md={8}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label={latitute + "," + longitute}
                        />
                      </Grid>
                    </Grid>
                    <Grid container xs={12} sm={6} md={6}></Grid>
                  </>
                )}
              </Grid>
              <DynamicFormForDocumentUpload
                getDataForm={true}
                setDataToForm={this.setDataToForm}
                getExtraData={true}
                getData={this.getLocationData}
                getListById={this.getListById}
                padding={false}
                paddingTop={false}
                showBackToList={false}
                formPath={outletJson.formPath}
                fieldMeta={
                  localStorage.getItem("isOutletMappedToDistributor") &&
                    localStorage.getItem("isOutletMappedToDistributor") == 1 ?
                    (
                      localStorage.getItem("showIsRateApplicableOutlet") &&
                        localStorage.getItem("showIsRateApplicableOutlet") == 1 ?
                        outletJson.fieldMetaOfDistributorMappedAndSubChannelWithAdditionalRate :
                        outletJson.fieldMetaOfDistributorMappedAndSubChanel
                    ) : (
                      localStorage.getItem("showIsRateApplicableOutlet") &&
                        localStorage.getItem("showIsRateApplicableOutlet") == 1 ?
                        outletJson.fieldMetaForAdditionalRateApplicable :
                        outletJson.fieldMeta
                    )
                }
                dynamicMasterData={dynamicMasterData}
                apiBaseURL={outletJson.apiBaseURL}
                showSaveNextBtn={outletJson.showSaveNextBtn}
              />
            </Paper>
          </>
        ) : (
          <GoogleMap
            getLocation={this.getLocation}
            setLocation={{
              latitute: latitute,
              longitute: longitute,
            }}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  outletTypeList: state.outletType,
  beatList: state.beat,
  subChannelList: state.channel,
  stockHolderList: state.stackholder,
  channelList: state.channel,
});
const mapDispatchToProps = {
  getOutletType,
  getUpdatedBeatByUserType,
  showLoador,
  showNotification,
  getSubChannelByChannel,
  getStockHoldersByBeats,
  getChannel,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(OutletForm);
