import CloseIcon from "@mui/icons-material/Close";
import {
    Checkbox,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { ButtonCompo } from "../../components/Comman/Button";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import endpoint from "../../config/endpoints";
import { checkedListEmptyMsg, dateMsg, saveFailedMsg, savemsg, saveWarningMsg, serverMsg } from "../../config/messageconstant";
import { getStockholderByUserArea } from "../../Slice/StackHolder.slice";
import { apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const MultipleStructurePopUp = ({
    handleClose,
    open,
    showNotification,
    showLoador,
    rows,
    productRateStructureList,
    getStockholderByUserArea,
    stakeholdersList,
}) => {
    const [rowData, setRowData] = React.useState([]);
    const [allChecked, setAllChecked] = React.useState(false);
    const [popUpOpenFlag, setPopUpOpenFlag] = React.useState(false);
    const [publishDate, setPublishDate] = React.useState("");
    const [formErrors, setFormErrors] = React.useState({});
    const [searchValueForProductRateStructure, setSearchValueForProductRateStructure] = React.useState("");
    const [isLog, setIsLog] = React.useState(true);
    const [moduleObjDataObj, setModuleObjDataObj] = React.useState({});


    React.useEffect(() => {

        const moduleObjData = localStorage.getItem("moduleObj");
        const moduleObjDataObj = moduleObjData != null ? JSON.parse(moduleObjData) : {};

        setModuleObjDataObj(moduleObjDataObj);

        fetchProductRateStructure();
    }, []);


    const fetchProductRateStructure = async () => {

        showLoador({ loador: true });
        await getStockholderByUserArea().then(({ response, success }) => {
            showLoador({ loador: false });
            if (!success) {
                showNotification({ msg: serverMsg, severity: "error" });
            } else {
                setRowData(response);
            }
        });

        setPopUpOpenFlag(true)

    };

    const handleCloses = () => {
        handleClose();
    };



    const onDateChange = (name) => (date) => {
        if (name === "publishDate") {
            setPublishDate(date);
        }
    };

    const checkValidation = () => {
        let formErrors = {};
        let formIsValid = true;

        if (publishDate === "") {
            formIsValid = false;
            formErrors["publishDateError"] = dateMsg;
        }

        setFormErrors(formErrors);
        return formIsValid;
    };


    const handleAllCheckedClick = (event) => {
        const { checked } = event.target;
        setAllChecked(checked);

        const updatedData = rowData.map((publishObject) => ({
            ...publishObject,
            isChecked: checked,
        }));

        setRowData(updatedData);
    };

    const handleClick = (e, id) => {
        const updatedData = rowData.map((publishObject) =>
            publishObject.id === id
                ? { ...publishObject, isChecked: e.target.checked }
                : publishObject
        );
        setRowData(updatedData);
    };


    const onSearchProductRateStructure = (searchValueForProductRateStructure) => {
        setSearchValueForProductRateStructure(searchValueForProductRateStructure)
    };

    const getFilteredTableData = (searchData) => {
        console.log(searchData);
        return searchData.filter((currentRow) => {
            let isValid = false;
            if (
                (currentRow["name"] &&
                    currentRow["name"]
                        .toString()
                        .toLowerCase()
                        .includes(searchValueForProductRateStructure.trim().toLowerCase())) ||
                (currentRow["userName"] &&
                    currentRow["userName"]
                        .toString()
                        .toLowerCase()
                        .includes(searchValueForProductRateStructure.trim().toLowerCase())) ||
                (currentRow["stateName"] &&
                    currentRow["stateName"]
                        .toString()
                        .toLowerCase()
                        .includes(searchValueForProductRateStructure.trim().toLowerCase())) ||
                (currentRow["brandName"] &&
                    currentRow["brandName"]
                        .toString()
                        .toLowerCase()
                        .includes(searchValueForProductRateStructure.trim().toLowerCase())) ||
                (currentRow["name"] &&
                    currentRow["name"]
                        .toString()
                        .toLowerCase()
                        .includes(searchValueForProductRateStructure.trim().toLowerCase()))
            ) {
                isValid = true;
                return true;
            }
        });
    };

    const onSubmitHandler = () => {
        console.log(rows);
        const saveList = rowData.filter((row) => row.isChecked).map((rowDataObj) => rowDataObj.id);


        let saveData = {
            stockStakeHolderIds: saveList,
            productMrpHolderWiseRates: rows.productMrpHolderWiseRates,
        }

        console.log(saveData);


        if (saveList.length !== 0) {
            swal({
                title: "Are you sure?",
                text: saveWarningMsg,
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    showLoador({ loador: true });
                    apiPost({
                        url: endpoint.productRateStructureAssignation + "/and-product-rate-structure-edit",
                        postBody: saveData,
                    }).then(({ data, success }) => {
                        showLoador({ loador: false });
                        if (success) {
                            if (data.data !== null) {
                                if (isLog) {
                                    let updatedJsonToSend = JSON.stringify(data.data);

                                    let dataToSaveForMLog = {
                                        module: {
                                            moduleId: moduleObjDataObj.moduleId
                                        },
                                        subModule: {
                                            subModuleId: moduleObjDataObj.subModuleId
                                        },
                                        operationType: 1,
                                        type: 1,
                                        operationPerformId: 0,
                                        subOperationPerformIds: saveList.join(", "),
                                        updatedJson: updatedJsonToSend,
                                    }

                                    showLoador({ loador: true });
                                    apiPost({
                                        url: endpoint.mlog,
                                        postBody: dataToSaveForMLog,
                                    }).then(({ success }) => {
                                        showLoador({ loador: false });
                                        if (success) {
                                        } else {
                                            showNotification({
                                                msg: saveFailedMsg,
                                                severity: "error",
                                            });
                                        }
                                    });
                                }
                            }

                            showNotification({ msg: savemsg });
                            handleCloses();
                            window.location.reload();
                        } else {
                            showNotification({ msg: saveFailedMsg, severity: "error" });
                        }
                    });
                }
            });

        } else {
            showNotification({ msg: checkedListEmptyMsg, severity: "error" });
        }

    };

    return (
        <>
            <BootstrapDialog
                onClose={handleCloses}
                aria-labelledby="customized-dialog-title"
                open={popUpOpenFlag}
                fullWidth
                maxWidth="lg"
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleCloses}
                >
                    Stock Holder List
                </BootstrapDialogTitle>

                <DialogContent dividers>
                    <LandingScreenHeader
                        screenTitle={""}
                        showSearchBox={true}
                        showTable={true}
                        showFilter={false}
                        showPdfDownload={false}
                        showExcelDownload={false}
                        onSearch={onSearchProductRateStructure}
                    />
                    <TableContainer sx={{ maxHeight: 400 }}>
                        <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            color="error"
                                            onClick={handleAllCheckedClick}
                                            checked={allChecked}
                                            inputProps={{
                                                "aria-label": "select all structures",
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell align="center">Sr. No.</TableCell>
                                    <TableCell align="center">User Name</TableCell>
                                    <TableCell align="center">State</TableCell>
                                    <TableCell align="center">Brand</TableCell>
                                    <TableCell align="center">Stock Holder</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {getFilteredTableData(rowData).map((row, index) => (
                                    <TableRow key={row.id}>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                onClick={(event) => handleClick(event, row.id)}
                                                checked={row.isChecked}
                                                inputProps={{
                                                    "aria-labelledby": `enhanced-table-checkbox-${index}`,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell align="center">{index + 1}</TableCell>
                                        <TableCell align="center">{row.userName}</TableCell>
                                        <TableCell align="left">{row.stateName}</TableCell>
                                        <TableCell align="left">{row.brandName}</TableCell>
                                        <TableCell align="left">{row.name}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br />

                    <Grid container justifyContent="flex-end" spacing={2} alignItems="center">
                        {/* <Grid item>
                            <BasicDatePicker
                                color="primary"
                                name="publishDate"
                                size="small"
                                value={publishDate}
                                disabled={false}
                                onChange={onDateChange("publishDate")}
                                isError={
                                    formErrors["publishDateError"]
                                        ? formErrors["publishDateError"]
                                        : ""
                                }
                                errorText={
                                    formErrors["publishDateError"]
                                        ? formErrors["publishDateError"]
                                        : ""
                                }
                            />
                        </Grid>
                        &nbsp;&nbsp; */}
                        <Grid item>
                            <ButtonCompo
                                size="medium"
                                type="submit"
                                variant="contained"
                                name="Submit"
                                onClick={onSubmitHandler}
                            />
                        </Grid>
                    </Grid>

                </DialogContent>
            </BootstrapDialog>
        </>
    );
};

const mapStateToProps = (state) => ({
    productRateStructureList: state.productRateStructure,
    stakeholdersList: state.stackholder,
});

const mapDispatchToProps = {
    getStockholderByUserArea,
    showNotification,
    showLoador,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MultipleStructurePopUp);
