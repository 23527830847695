import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { ProductRateStructureJson } from "../../DynamicFormsJson/Transaction/productRateStructure";
import { getAllActiveProductForAssignation } from "../../Slice/product.slice";
import { withRouter } from "../../components/withRouter";
import {
  noInternetMsg,
  saveFailedMsg,
  saveWarningMsg,
  savemsg,
  serverMsg,
  updateData,
  updateMsg,
} from "../../config/messageconstant";

import dayjs from "dayjs";
import swal from "sweetalert";
import { getStockStakeHolderType } from "../../Slice/stockStakeHolderType.slice";
import { ButtonCompo } from "../../components/Comman/Button";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import DynamicFormWithoutValidation from "../../components/dynamicscreens/DynamicFormWithoutValidation";
import { mandatoryCheck } from "../../config/ValidationCheck";
import endpoint from "../../config/endpoints";
import { apiGet, apiPost } from "../../utils/api_service";
import "./product.css";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { redColor } from "../../config/ColorObj";
class ProductRateStructure extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      matrixDataList: [],
      fieldData: {},
      editData: "",
      formErrors: {},
      isLog: true,
      moduleObjDataObj: {},
      searchValueForProductRateStructure: "",
    };
  }

  async componentDidMount() {
    const {
      getAllActiveProductForAssignation,
      getStockStakeHolderType,
      stakeHolderTypeList,
      showLoador,
      showNotification,
      productList,
    } = this.props;
    if (navigator.onLine) {


      const moduleObjData = localStorage.getItem("moduleObj");
      const moduleObjDataObj = moduleObjData != null ? JSON.parse(moduleObjData) : {};

      this.setState({ moduleObjDataObj });

      showLoador({ loador: true });
      await getAllActiveProductForAssignation().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          showLoador({ loador: true });
          getStockStakeHolderType({ userTypeIds: 2, reverseTrue: true }).then(
            ({ success }) => {
              showLoador({ loador: false });
              if (!success) {
                showNotification({ msg: serverMsg, severity: "error" });
              } else {
                if (this.props.params.id) {
                  showLoador({ loador: true });
                  apiGet({
                    url:
                      endpoint.productRateStructure +
                      "/" +
                      this.props.params.id,
                  }).then(({ data, success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                      showNotification({ msg: serverMsg, severity: "error" });
                    } else {
                      this.matrixEditData(data.data);
                    }
                  });
                } else {
                  this.matrixData();
                }
              }
            }
          );
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  matrixEditData = (data) => {
    const { productList, stakeHolderTypeList } = this.props;
    let newDataToSet = productList?.activeAllProduct.map((productObject) => {
      let filterData = data.productMrpHolderWiseRates.filter(
        (row) => row.product != null && row.product.id == productObject.id
      );
      if (filterData.length !== 0) {

        let matrixObject = {
          productId: productObject.id,
          brandName: productObject.group.brand.name,
          productName: productObject.name,
          categtoryName: productObject.group.subCategory.category.name,
          mrp: filterData[0].mrp,
          outletRate: filterData[0].outletRate,
          expiryRate: filterData[0].expiryRate !== null ? filterData[0].expiryRate : 0,


          productObject: productObject,
        };
        stakeHolderTypeList?.stockStakeHolderType.map((stakeholderObejct) => {
          let stakeHolderData = filterData[0].dtoTypeWiseRates.filter(
            (row) => row.stockStakeHolderType.id == stakeholderObejct.id
          );
          if (stakeHolderData.length !== 0) {
            matrixObject = {
              ...matrixObject,
              [stakeholderObejct.name.replaceAll(" ", "").toLowerCase()]:
                stakeHolderData[0].rate,
              [stakeholderObejct.name.replaceAll(" ", "").toLowerCase() + "Id"]:
                stakeholderObejct.id,
            };
            return matrixObject;
          } else {
            matrixObject = {
              ...matrixObject,
              [stakeholderObejct.name.replaceAll(" ", "").toLowerCase()]: 0,
              [stakeholderObejct.name.replaceAll(" ", "").toLowerCase() + "Id"]:
                stakeholderObejct.id,
            };
            return matrixObject;
          }
        });
        return matrixObject;
      } else {
        let matrixNewObject = {
          productId: productObject.id,
          brandName: productObject.group.brand.name,
          productName: productObject.name,
          categtoryName: productObject.group.subCategory.category.name,
          mrp: 0,
          outletRate: 0,
          expiryRate: 0,
          productObject: productObject,
        };
        stakeHolderTypeList?.stockStakeHolderType.map((stakeholderObejct) => {
          matrixNewObject = {
            ...matrixNewObject,
            [stakeholderObejct.name.replaceAll(" ", "").toLowerCase()]: 0,
            [stakeholderObejct.name.replaceAll(" ", "").toLowerCase() + "Id"]:
              stakeholderObejct.id,
          };
          return matrixNewObject;
        });
        return matrixNewObject;
      }
    });
    this.setState({
      matrixDataList: newDataToSet,
      fieldData:
        this.props.copyFlag == 1
          ? {
            ...data,
            structureName: data.name,
            structureDate: dayjs(data.structureDate, "DD-MM-YYYY"),
          }
          : {
            ...data,
            structureName: data.name,
            structureDate: dayjs(data.structureDate, "DD-MM-YYYY"),
          },
      editData: data,
      DataToSet: [
        {
          xs: 12,
          sm: 6,
          md: 6,
          lg: 6,
          label: "Structure Name",
          value: data.name !== null ? data.name : "",
        },
        {
          xs: 12,
          sm: 6,
          md: 6,
          lg: 6,
          label: "Structure Date",
          value: data.structureDate !== null ? data.structureDate : "",
        },
      ],
    });
  };
  matrixData = () => {
    const { productList, stakeHolderTypeList } = this.props;
    let newDataToSet = productList?.activeAllProduct.map((productObject) => {
      let matrixObject = {
        productId: productObject.id,
        brandName: productObject.group.brand.name,
        productName: productObject.name,
        categtoryName: productObject.group.subCategory.category.name,
        mrp: 0,
        outletRate: 0,
        expiryRate: 0,
        productObject: productObject,
      };
      stakeHolderTypeList?.stockStakeHolderType.map((stakeholderObejct) => {
        matrixObject = {
          ...matrixObject,
          [stakeholderObejct.name.replaceAll(" ", "").toLowerCase()]: 0,
          [stakeholderObejct.name.replaceAll(" ", "").toLowerCase() + "Id"]:
            stakeholderObejct.id,
        };
        return matrixObject;
      });
      return matrixObject;
    });
    this.setState({
      matrixDataList: newDataToSet,
    });
  };
  checkValidationOnSubmit = () => {
    const { fieldData, flag } = this.state;
    const { category } = this.props;
    const mandatoryCheckErrors = mandatoryCheck({
      fieldData,
      fieldMeta: ProductRateStructureJson.fieldMeta,
    });
    this.setState({
      formErrors: mandatoryCheckErrors.formErrors,
    });

    return mandatoryCheckErrors.formValid;
  };
  onSubmitHandler = () => {
    const { matrixDataList, fieldData, editData, isLog, moduleObjDataObj } = this.state;
    if (
      (editData != "" && this.props.copyFlag == 0) ||
      this.checkValidationOnSubmit()
    ) {
      const { stakeHolderTypeList, showLoador, showNotification } = this.props;
      let isError = false;
      let newData = [];
      let matData = matrixDataList.map((matrixObject) => {
        if (matrixObject.mrpError) {
          isError = true;
        }

        if (matrixObject.mrp != 0) {
          let dataToSave = {
            mrp: matrixObject.mrp === "" ? 0 : matrixObject.mrp,
            product: matrixObject.productObject,
            outletRate:
              matrixObject.outletRate === "" ? 0 : matrixObject.outletRate,
            expiryRate:
              localStorage.getItem("radioPurchasereturnAndExpiryForRatestructure") &&
                localStorage.getItem("radioPurchasereturnAndExpiryForRatestructure") == 1 ?
                (matrixObject.expiryRate !== "" && matrixObject.expiryRate !== null ? matrixObject.expiryRate : 0)
                : 0,
            dtoTypeWiseRates: stakeHolderTypeList?.stockStakeHolderType.map(
              (stakeholderObejct) => {
                return {
                  rate: matrixObject[
                    stakeholderObejct.name.replaceAll(" ", "").toLowerCase()
                  ] || '0', // Handle rate by stakeholder
                  stockStakeHolderType: {
                    id: stakeholderObejct.id,
                  },
                };
              }
            ),
          };
          newData.push(dataToSave);
        } else {
          matrixObject = {
            ...matrixObject,
            mrpError: false,
          };
          isError = false;
        }

        return matrixObject;
      });

      console.log(matData);

      this.setState({
        matrixDataList: matData,
      });

      console.log(newData);
      if (newData.length != 0) {
        let jsonToSave = {};
        console.log(newData);
        if (this.props.params.id && this.props.copyFlag == 0) {
          jsonToSave = {
            name: fieldData.structureName,
            structureDate: fieldData.structureDate.format("DD-MM-YYYY"),
            id: this.props.params.id,
            json: JSON.stringify(newData),
          };
        } else {
          jsonToSave = {
            name: fieldData.structureName,
            structureDate: fieldData.structureDate.format("DD-MM-YYYY"),
            json: JSON.stringify(newData),
          };
        }

        swal({
          title: "Are you sure?",
          text: this.props.params.id ? updateData : saveWarningMsg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            showLoador({ loador: true });
            apiPost({
              url: endpoint.productRateStructure,
              postBody: jsonToSave,
            }).then(({ data, success }) => {
              showLoador({ loador: false });
              if (success) {

                if (isLog) {

                  if (data.data !== null) {

                    let updatedJsonToSend = JSON.stringify(data.data);

                    let dataToSaveForMLog = {
                      module: {
                        moduleId: moduleObjDataObj.moduleId
                      },
                      subModule: {
                        subModuleId: moduleObjDataObj.subModuleId
                      },
                      operationType: this.props.params.id ? 1 : 0,
                      type: 1,
                      operationPerformId: data.data.id,
                      updatedJson: updatedJsonToSend,
                    }

                    showLoador({ loador: true });
                    apiPost({
                      url: endpoint.mlog,
                      postBody: dataToSaveForMLog,
                    }).then(({ success }) => {
                      showLoador({ loador: false });
                      if (success) {
                      } else {
                        showNotification({
                          msg: saveFailedMsg,
                          severity: "error",
                        });
                      }
                    });
                  }
                }

                if (this.props.params.id) {
                  this.props.navigate(-1);
                  showNotification({
                    msg: updateMsg,
                  });
                } else {
                  this.props.changeTab(1, data);
                  showNotification({
                    msg: savemsg,
                  });
                }
              } else {
                showNotification({ msg: saveFailedMsg, severity: "error" });
              }
            });
          }
        });
      } else {
        if (newData.length == 0) {
          showNotification({
            msg: "Atleast one record Add",
            severity: "error",
          });
        }
      }
    }
  };

  onDataChange = (fieldName, newValue) => {
    const { fieldData } = this.state;
    const dataToSearch = {};
    const dataToReset = { ...this.state.fieldData };
    ProductRateStructureJson.fieldMeta.map((currentField) => {
      if (currentField.dataKey === fieldName && currentField.getListId) {
        const masterKeyList = currentField.getListId.split(",");

        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
          return null;
        });

        this.getListById(dataToSearch);
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key == fieldName && dataToReset[key]) {
            delete dataToReset[currentField.dataKey];
          }
        });
      }
      return null;
    });
    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
    });
  };

  onSearchProductRateStructure = (searchValueForProductRateStructure) => {
    this.setState({
      searchValueForProductRateStructure: searchValueForProductRateStructure,
    });
  };

  getFilteredTableData = (searchData) => {
    const { searchValueForProductRateStructure } = this.state;
    return searchData.filter((currentRow) => {
      let isValid = false;
      if (currentRow["brandName"] &&
        currentRow["brandName"]
          .toString()
          .toLowerCase()
          .includes(searchValueForProductRateStructure.trim().toLowerCase()) ||
        currentRow["productName"] &&
        currentRow["productName"]
          .toString()
          .toLowerCase()
          .includes(searchValueForProductRateStructure.trim().toLowerCase()) ||
        currentRow["categtoryName"] &&
        currentRow["categtoryName"]
          .toString()
          .toLowerCase()
          .includes(searchValueForProductRateStructure.trim().toLowerCase())
      ) {
        isValid = true;
        return true;
      }
    });
  };

  onChange = (e, productObject) => {
    const { matrixDataList } = this.state;
    const { name, value } = e.target;
    const regex = /^\d*\.?\d{0,2}$/;
    if (regex.test(value)) {
      let newMatrixData = matrixDataList.map((matrixObject) => {
        if (matrixObject.productId == productObject.productId) {
          let newData = {
            ...matrixObject,
            [name]: value,
          };
          return newData;
        } else {
          return matrixObject;
        }
      });
      console.log(newMatrixData);
      this.setState({
        matrixDataList: newMatrixData,
      });
    }
  };

  render() {
    const { formErrors, DataToSet, matrixDataList, fieldData } = this.state;
    const { productList, stakeHolderTypeList } = this.props;
    return (
      <>
        <DynamicFormWithoutValidation
          showBackToList={true}
          showTitle={ProductRateStructureJson.showTitle}
          screenTitle={""}
          apiBaseURL={ProductRateStructureJson.apiBaseURL}
          fieldMeta={ProductRateStructureJson.fieldMeta}
          showCancel={ProductRateStructureJson.showCancel}
          showSaveNextBtn={ProductRateStructureJson.showSaveNextBtn}
          saveBtnText={"Search"}
          showSaveBtnMain={false}
          showSaveBtn={false}
          fieldData={fieldData}
          formErrors={formErrors}
          onDataChange={this.onDataChange}
        />

        <br />
        <LandingScreenHeader
          screenTitle={""}
          showSearchBox={true}
          showTable={true}
          showFilter={false}
          showPdfDownload={false}
          showExcelDownload={false}
          onSearch={this.onSearchProductRateStructure}
        />
        <br />
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  style={{
                    minWidth: "70px",
                    position: "sticky",
                    left: 0,
                    background: "#fff",
                    zIndex: 11,
                  }}
                >
                  Sr. No.
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    minWidth: "150px",
                    position: "sticky",
                    left: 70,
                    background: "#fff",
                    zIndex: 11,
                  }}
                >
                  Brand
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    minWidth: "150px",
                    position: "sticky",
                    left: 220,
                    background: "#fff",
                    zIndex: 11,
                  }}
                >
                  Product Name
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    minWidth: "150px",
                    position: "sticky",
                    left: 370,
                    background: "#fff",
                    zIndex: 11,
                  }}
                >
                  Category
                </TableCell>
                <TableCell align="center" style={{ minWidth: "150px" }}>
                  MRP Rate
                </TableCell>
                <TableCell align="center" style={{ minWidth: "150px" }}>
                  Outlet Rate
                </TableCell>
                {stakeHolderTypeList?.stockStakeHolderType.map((stakeHolderObject) => {
                  return (
                    <TableCell
                      align="center"
                      style={{ minWidth: "150px" }}
                      key={stakeHolderObject.name}
                    >
                      {stakeHolderObject.name}
                    </TableCell>
                  );
                })}

                {localStorage.getItem("radioPurchasereturnAndExpiryForRatestructure") &&
                  localStorage.getItem("radioPurchasereturnAndExpiryForRatestructure") == 1 ?
                  (<>
                    <TableCell align="center" style={{ minWidth: "150px" }}>
                      Expiry Rate
                    </TableCell>
                  </>) : (null)
                }

              </TableRow>
            </TableHead>
            <TableBody>
              {this.getFilteredTableData(matrixDataList).map(
                (productObject, indexRow) => {
                  return (
                    <TableRow>
                      <TableCell
                        align="center"
                        component="td"
                        scope="row"
                        style={{
                          position: "sticky",
                          left: 0,
                          background: "white",
                          zIndex: 1,
                        }}
                      >
                        {indexRow + 1}
                      </TableCell>
                      <TableCell
                        component="td"
                        scope="row"
                        style={{
                          position: "sticky",
                          left: "70px",
                          background: "white",
                          zIndex: 1,
                        }}
                      >
                        {productObject.brandName}
                      </TableCell>
                      <TableCell
                        component="td"
                        scope="row"
                        style={{
                          position: "sticky",
                          left: "220px",
                          background: "white",
                          zIndex: 1,
                        }}
                      >
                        {productObject.productName}
                      </TableCell>
                      <TableCell
                        component="td"
                        scope="row"
                        style={{
                          minWidth: "150px",
                          position: "sticky",
                          left: 370,
                          background: "#fff",
                        }}
                      >
                        {productObject.categtoryName}
                      </TableCell>
                      <TableCell align="right">
                        <input
                          className={"customTextfield"}
                          min="0"
                          step=".01"
                          placeHolder={"MRP Rate"}
                          value={productObject.mrp}
                          name="mrp"
                          onChange={(e) => this.onChange(e, productObject)}
                          type="number"
                          id="mrp"
                        ></input>
                        <span style={{ color: redColor, fontSize: "12px" }}>
                          {productObject.mrp === "" ||
                            (productObject.mrp == 0 && productObject.mrpError)
                            ? "Rate Required "
                            : ""}
                        </span>
                      </TableCell>
                      <TableCell align="right">
                        <input
                          className={"customTextfield"}
                          placeHolder={"Outlet Rate"}
                          value={productObject.outletRate}
                          name="outletRate"
                          onChange={(e) => this.onChange(e, productObject)}
                          type="text"
                          id="outletRate"
                        ></input>

                        <span style={{ color: redColor, fontSize: "12px" }}>
                          {productObject.outletRate === "" ||
                            (productObject.stockist == 0 &&
                              productObject.outletRateError)
                            ? "Rate Required"
                            : productObject.outletRateError
                              ? "Rate Should be smaller than MRP"
                              : ""}
                        </span>
                      </TableCell>
                      {stakeHolderTypeList?.stockStakeHolderType.map(
                        (stakeHolderObject, index) => {
                          return (
                            <TableCell align="right">
                              <input
                                className={"customTextfield"}
                                placeHolder={stakeHolderObject.name + " Rate"}
                                value={
                                  productObject[
                                  stakeHolderObject.name
                                    .replaceAll(" ", "")
                                    .toLowerCase()
                                  ]
                                }
                                name={stakeHolderObject.name
                                  .replaceAll(" ", "")
                                  .toLowerCase()}
                                onChange={(e) =>
                                  this.onChange(e, productObject)
                                }
                                type="text"
                                id={stakeHolderObject.name
                                  .replaceAll(" ", "")
                                  .toLowerCase()}
                              ></input>

                              <span
                                style={{ color: redColor, fontSize: "12px" }}
                              >
                                {productObject[
                                  stakeHolderObject.name.replaceAll(" ", "").toLowerCase()
                                ] === ""
                                  ? "Rate Required"
                                  : ""}
                              </span>
                            </TableCell>
                          );
                        }
                      )}

                      {localStorage.getItem("radioPurchasereturnAndExpiryForRatestructure") &&
                        localStorage.getItem("radioPurchasereturnAndExpiryForRatestructure") == 1 ?
                        (<>
                          <TableCell align="right">
                            <input
                              className={"customTextfield"}
                              placeHolder={"Expiry Rate"}
                              value={productObject.expiryRate}
                              name="expiryRate"
                              onChange={(e) => this.onChange(e, productObject)}
                              type="text"
                              id="expiryRate"
                            ></input>

                            <span style={{ color: redColor, fontSize: "12px" }}>
                              {productObject.expiryRate === "" ? "Rate Required"
                                : " "}
                            </span>
                          </TableCell>
                        </>) : (null)
                      }
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <br />
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          rowSpacing={2}
          columnSpacing={{ xs: 12, sm: 6, md: 1 }}
          container
          justifyContent="right"
        >
          <ButtonCompo
            size="medium"
            type="submit"
            variant="contained"
            name="Submit"
            onClick={this.onSubmitHandler}
          />
        </Grid>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  multipleStockList: state.manageStockByDate,
  stakeHolderTypeList: state.stockStakeHolderType,
  productList: state.product,
});
const mapDispatchToProps = {
  getAllActiveProductForAssignation,
  getStockStakeHolderType,
  showLoador,
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ProductRateStructure);
