import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import { RUCreationColumns, RUQuantityColumnsForPopup, SalesTeamCountColumnsForPopup } from "../../tableColumns/table-columns";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { getRuCreation } from "../../Slice/ruCreation.slice";
import { creationListJson } from "../../DynamicFormsJson/MastersJSON/creation";
import AssignationFormPopUp from "./AssignationFormPopUp";
import { apiGet, apiPost } from "../../utils/api_service";
import endpoint from "../../config/endpoints";
import { changeActive, changeActiveForRuCreationList, noInternetMsg, statusmsg } from "../../config/messageconstant";

class RUCreationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      assignationPopUp: false,
      assignationPopUpData: "",
    };
  }

  rowView = (rowData) => {
    window.location.replace("/ru-creation-assignation-view/" + rowData.id);
  };

  rowTransfer = (rowData) => {
    this.setState({
      assignationPopUpData: rowData,
      assignationPopUp: true
    })
  };

  handleClose = () => {
    this.setState({
      assignationPopUp: false,
    })
  }


  onChangeStatus = (rowData) => {
    const { showLoador, showNotification, getRuCreation } = this.props;
    if (navigator.onLine) {
      let status = "";
      if (rowData.isActive == 0) {
        status = 1;
      } else {
        status = 0;
      }


      swal({
        title: "Are you sure?",
        text: status == 0 ? changeActiveForRuCreationList : changeActive,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          showLoador({ loador: true });
          apiGet({
            url: endpoint.ruTargetHeader +
              "/update-active-status?retailUnitWiseTargetHeaderId="
              + rowData.id + "&activeStatus=" + status,
          }).then(({ data, success }) => {
            showLoador({ loador: false });
            if (success) {
              showNotification({ msg: statusmsg });
              getRuCreation();
            }
          });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  onStatusChangePopUpStatic = (row, rowData) => {

    const { showLoador, showNotification, getRuCreation } = this.props;
    if (navigator.onLine) {
      let status = 0;

      showLoador({ loador: true });
      apiGet({
        url: endpoint.ruTargetAssignation +
          "/update-active-status?id="
          + row.id + "&activeStatus=" + status,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          showNotification({ msg: statusmsg });
          window.location.reload();
        }
      });

    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }

  }

  render() {
    const { creationList, getRuCreation } = this.props;
    const { assignationPopUp, assignationPopUpData } = this.state;
    return (
      <>
        <DynamicMainScreen
          formPath={creationListJson.formPath}
          screenTitle={creationListJson.screenTitle}
          fieldMeta={creationListJson.fieldMeta}
          showPdfDownload={false}
          showExcelDownload={true}
          changeExcelURL={creationListJson.excelUrl}
          showExtension={false}
          filterDataExcel={true}
          pdfFileName={creationListJson.pdfFileName}
          excelFileName={creationListJson.excelFileName}
          showAddButton={creationListJson.showAddButton}
          tableColumnsToFilter={creationListJson.tableColumnsToFilter}
          tableColumns={RUCreationColumns}
          tableData={creationList?.creationList}
          getTableData={getRuCreation}
          apiBaseURL={creationListJson.apiBaseURL}
          baseIdColumn={creationListJson.baseIdColumn}
          isNote={true}
          isNoteValue={"Add RU Creation"}
          showDeleteIcon={false}
          showCopyIcon={true}
          showViewButtonInTable={false}
          showTransferButtonInTable={true}
          staticView={true}
          rowView={this.rowView}
          autoTransfer={false}
          rowTransfer={this.rowTransfer}
          showTransferTitle={"Assign Target"}
          isActiveURLFalse={true}
          isActiveURLFalseMethod={this.onChangeStatus}
          onChangeStatusStaticFlag={true}
          onStatusChangePopUpStatic={this.onStatusChangePopUpStatic}
          showPopUpDataList={
            [
              {
                dataKey: "ruQty",
                columns: RUQuantityColumnsForPopup,
                title: "RU Quantity",
                header: {
                  dataKey: "name",
                  label: "Target Name",
                },
              },
              {
                dataKey: "salesTeamCount",
                columns: SalesTeamCountColumnsForPopup,
                title: "Sales Team Count",
                header: {
                  dataKey: "name",
                  label: "Target Name",
                },
              },
            ]}
        />

        {assignationPopUp && (
          <AssignationFormPopUp
            open={assignationPopUp}
            assignationPopUpData={assignationPopUpData}
            handleClose={this.handleClose}
          />
        )}



      </>
    );
  }
}
const mapStateToProps = (state) => ({
  creationList: state.ruCreation,
});
const mapDispatchToProps = {
  getRuCreation,
  showLoador,
  showNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(RUCreationList);
