import { Paper } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import {
    noInternetMsg,
    serverMsg
} from "../../config/messageconstant";
import { claimPdfJSON } from "../../DynamicFormsJson/Transaction/claimPdfJson";
import { getClaimApply } from "../../Slice/claim.slice";
import { getSalesExecutive } from "../../Slice/salesExecutive.slice";
import { getStockStakeHolderType } from "../../Slice/stockStakeHolderType.slice";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import jsPDF from "jspdf";
import moment from "moment";
import { imageUrl } from "../../config/Url";

class ClaimPdfMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [
                {
                    "id": "aa3bae37-63f1-4e7a-aa2f-e406305e040f",
                    "name": "Hemant Test DSR",
                    "claimApplyHeader": [
                        {
                            "id": "16874170-c648-4844-a1d3-1e443a13b17c",
                            "claimTitle": "Test Claim ",
                            "claimAmount": 2350,
                            "date": "26-02-2025",
                            "insertedUserId": "aa3bae37-63f1-4e7a-aa2f-e406305e040f",
                            "claimApplyDetail": [
                                {
                                    "id": "64a44b7d-bf74-410b-b9d8-9c49c25c79a8",
                                    "claimApplyHeaderId": "16874170-c648-4844-a1d3-1e443a13b17c",
                                    "claimAmt": 250,
                                    "attachFile": "26_02_2025_16_38_57_5e0c948f-d764-4ec5-99c7-0343d349b51a.jpg"
                                },
                                {
                                    "id": "a827afc3-2fd5-4f4a-8281-e1f1a220c40f",
                                    "claimApplyHeaderId": "16874170-c648-4844-a1d3-1e443a13b17c",
                                    "claimAmt": 900,
                                    "attachFile": "26_02_2025_16_38_31_8aebd1e2-0b7a-4e9f-8605-b64d5fe9aa80.jpg"
                                },
                                {
                                    "id": "6e62dac7-4517-4108-b032-183ed7338167",
                                    "claimApplyHeaderId": "16874170-c648-4844-a1d3-1e443a13b17c",
                                    "claimAmt": 1200,
                                    "attachFile": "26_02_2025_16_37_41_ef174cbd-1ad2-4d8d-a0fc-f48aa2ccf330.jpg"
                                }
                            ]
                        }
                    ]
                },
                {
                    "id": "aa3bae37-63f1-4e7a-aa2f-e406305e040f",
                    "name": "Chaitanya DSR",
                    "claimApplyHeader": [
                        {
                            "id": "16874170-c648-4844-a1d3-1e443a13b17c",
                            "claimTitle": "Test Claim ",
                            "claimAmount": 1050,
                            "date": "26-02-2025",
                            "insertedUserId": "aa3bae37-63f1-4e7a-aa2f-e406305e040f",
                            "claimApplyDetail": [
                                {
                                    "id": "64a44b7d-bf74-410b-b9d8-9c49c25c79a8",
                                    "claimApplyHeaderId": "16874170-c648-4844-a1d3-1e443a13b17c",
                                    "claimAmt": 250,
                                    "attachFile": "26_02_2025_16_38_57_5e0c948f-d764-4ec5-99c7-0343d349b51a.jpg"
                                },
                                {
                                    "id": "a827afc3-2fd5-4f4a-8281-e1f1a220c40f",
                                    "claimApplyHeaderId": "16874170-c648-4844-a1d3-1e443a13b17c",
                                    "claimAmt": 300,
                                    "attachFile": "26_02_2025_16_38_31_8aebd1e2-0b7a-4e9f-8605-b64d5fe9aa80.jpg"
                                },
                                {
                                    "id": "6e62dac7-4517-4108-b032-183ed7338167",
                                    "claimApplyHeaderId": "16874170-c648-4844-a1d3-1e443a13b17c",
                                    "claimAmt": 500,
                                    "attachFile": "26_02_2025_16_37_41_ef174cbd-1ad2-4d8d-a0fc-f48aa2ccf330.jpg"
                                }
                            ]
                        }
                    ]
                }
            ],
            dynamicMasterData: {
                stockHolderTypeDetail: this.props.stakeHolderTypeList?.stockStakeHolderType,
                salesPersonDetail: this.props.salesTeamList?.salesExecutive
            },
        };
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.stakeHolderTypeList !== nextProps.stakeHolderTypeList) {
            if (
                this.props.stakeHolderTypeList?.stockStakeHolderType !==
                nextProps.stakeHolderTypeList?.stockStakeHolderType
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        stockHolderTypeDetail:
                            nextProps.stakeHolderTypeList?.stockStakeHolderType,
                    },
                });
            }
        }

        if (this.props.salesTeamList !== nextProps.salesTeamList) {
            if (
                this.props.salesTeamList?.salesExecutive !==
                nextProps.salesTeamList?.salesExecutive
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        salesPersonDetail:
                            nextProps.salesTeamList?.salesExecutive,
                    },
                });
            }
        }
    }

    async componentDidMount() {
        const { showLoador, getStockStakeHolderType, showNotification } = this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            await getStockStakeHolderType({ userTypeIds: "3,4" }).then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });
        } else {
            showNotification({
                msg: noInternetMsg,
                severity: "error",
            });
        }
    }


    getListById = async (data) => {

        const {
            showNotification,
            showLoador,
            getSalesExecutive,
        } = this.props;
        if (navigator.onLine) {
            if (data.stockStakeHolderType && data.stockStakeHolderType != "") {

                showLoador({ loador: true });
                await getSalesExecutive({
                    stockStakeHolderId: data.stockStakeHolderType,
                }).then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }
        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };


    onSearch = async (data) => {
        const { showLoador, getClaimApply, showNotification } = this.props;


        let salesTeamIds = {
            salesTeamIds: data.salesTeamIds.split(","),
        }

        console.log(salesTeamIds)

        showLoador({ loador: true });
        await getClaimApply({
            fromDate: data.fromDate,
            toDate: data.toDate,
            salesTeamIds: salesTeamIds,
        }).then(({ response, success }) => {
            showLoador({ loador: false });
            if (!success) {
                showNotification({ msg: serverMsg, severity: "error" });
            } else {

                this.generatePDF(response)
            }
        });


    };
    generatePDF = (response) => {
        const { list } = this.state;

        var doc = new jsPDF();
        let lang = 2;
        var line = 10;

        let pdfTitle = "Claim Information";
        var pageWidth = doc.internal.pageSize.getWidth();
        var pageHeight = doc.internal.pageSize.getHeight();
        const margin = 50; // Space to leave at the bottom of the page before page break
        const borderPadding = 5; // Padding for the border

        // Function to draw the border
        const drawBorder = () => {
            doc.rect(borderPadding, borderPadding, pageWidth - 2 * borderPadding, pageHeight - 2 * borderPadding);
        };

        // Draw the border on the first page
        drawBorder();

        const textWidth = doc.getTextWidth(pdfTitle);
        const titleX = (pageWidth - textWidth) / 2;
        doc.text(pdfTitle, titleX, 15);

        doc.setFontSize(10);
        let yAxis = 25;

        response.map((row, rowIndex) => {
            // Add a new page for each record except the first one

            let reportingPersonName = row.reportingPersonName;

            if (rowIndex > 0) {
                doc.addPage(); // Start a new page
                drawBorder(); // Redraw the border
                yAxis = 15; // Reset y-axis for new page
            }

            doc.setFontSize(14);
            doc.setFont("helvetica", "normal");
            doc.text(row.name, 10, yAxis);
            yAxis += 10; // Space below the salesperson's name

            row.claimApplyHeader.map((claim) => {
                let claimAmount = "Rs." + claim.claimAmount.toFixed(2);
                doc.setFontSize(12);
                doc.setFont("helvetica", "normal");

                // First row: Claim title on the left, Total on the right
                doc.text(`Claim: ${claim.claimTitle} (${claim.date})`, 10, yAxis);
                doc.text(`Total: ${claimAmount}`, 165, yAxis);

                yAxis += 8; // Adjust spacing as needed

                // Second row: Reporting To
                doc.text(`Reporting To: ${reportingPersonName}`, 10, yAxis);
                doc.text(`Status: ${claim.status}`, 165, yAxis);

                yAxis += 10;
                claim.claimApplyDetail.map((detail, index) => {
                    let claimAmt = "Rs." + detail.claimAmt.toFixed(2);
                    let claimTypeName = detail.claimTypeName;
                    let flag = index % 2;
                    let xPosition = flag ? 130 : 15;
                    let imageWidth = 65;
                    let imageHeight = 55;
                    let textSpacing = 8; // Space between image and text

                    // Draw the image
                    doc.addImage(imageUrl + detail.attachFile, "JPEG", xPosition, yAxis, imageWidth, imageHeight);
                    doc.link(xPosition, yAxis, 45, 35, { url: imageUrl + detail.attachFile });

                    // Calculate y position for text
                    let textYPosition = yAxis + imageHeight + textSpacing;

                    doc.setFontSize(12);
                    doc.setFont("helvetica", "bold");

                    // First line: Claim amount
                    doc.text(`${claimAmt} (${claimTypeName})`, xPosition, textYPosition);

                    // Second line: Remark
                    doc.setFont("helvetica", "normal");
                    doc.text(`Remark: ${detail.remark}`, xPosition, textYPosition + textSpacing);

                    // Adjust yAxis for the next image row
                    if (flag || index === claim.claimApplyDetail.length - 1) {
                        yAxis += imageHeight + textSpacing * 3;
                    }
                });

                yAxis += 5; // Extra space after each claim
            });

            yAxis += 5; // Extra space after each salesperson
        });


        doc.save(pdfTitle + " " + moment().format("DD-MM-YYYY") + ".pdf");
    };



    render() {
        const { dynamicMasterData } = this.state;

        console.log(dynamicMasterData)
        return (
            <>
                <Paper
                    sx={{
                        p: 2,
                        borderRadius: 2,
                    }}
                    elevation={0}
                >
                    <DynamicFormWithoutSave
                        showBackToList={true}
                        getListById={this.getListById}
                        dynamicMasterData={dynamicMasterData}
                        showTitle={claimPdfJSON.showTitle}
                        screenTitle={claimPdfJSON.screenTitle}
                        fieldMeta={claimPdfJSON.fieldMeta}
                        showCancel={claimPdfJSON.showCancel}
                        apiBaseURL={claimPdfJSON.apiBaseURL}
                        showSaveNextBtn={
                            claimPdfJSON.showSaveNextBtn
                        }
                        onSave={this.onSearch}
                        saveBtnText={"Download"}
                        showSaveBtnMain={false}
                        showSaveBtn={true}
                    />
                </Paper>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    stakeHolderTypeList: state.stockStakeHolderType,
    salesTeamList: state.salesExecutive,
});
const mapDispatchToProps = {
    showLoador,
    showNotification,
    getStockStakeHolderType,
    getClaimApply,
    getSalesExecutive,
};
export default connect(mapStateToProps, mapDispatchToProps)(ClaimPdfMain);
