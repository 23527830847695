import React, { useState } from "react";
import "./FloatingTabs.css"; // Import the CSS file for styling
import VerticalTabs from "./VerticalTabs";

function HorizontalTabs() {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = ["Company", "Primary", "Secondary", "Analytics"];
  const tabs1 = [
    { id: "profile", label: "Profile" },
    { id: "target", label: "Target" },
    { id: "achievement", label: "Achievement" },
  ];
  const tabs2 = [
    { id: "porder", label: "Primary Order" },
    { id: "psell", label: "Primary Sell" },
    { id: "pgrn", label: "GRN/GRN" },
  ];
  const tabs3 = [
    { id: "sorder", label: "Secondary Order" },
    { id: "ssell", label: "Secondary Sell" },
    { id: "credit", label: "Outlet Purchase Return" },
  ];
  const tabs4 = [
    { id: "area", label: "Area exploration" },
    { id: "mar", label: "Marchadising" },
    { id: "sur", label: "Survey" },
  ];
  return (
    <>
      <div className="floating-tabs-container">
        <div className="tabs">
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={`tab ${activeTab === index ? "active" : ""}`}
              onClick={() => setActiveTab(index)}
            >
              {tab}
            </div>
          ))}
        </div>
      </div>
      {/* <VerticalTabs
        tabs={
          activeTab == 0
            ? tabs1
            : activeTab == 1
            ? tabs2
            : activeTab == 2
            ? tabs3
            : tabs4
        }
      /> */}
    </>
  );
}

export default HorizontalTabs;
