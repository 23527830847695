import React from "react";
import { connect } from "react-redux";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import { serverMsg, noInternetMsg } from "../../../config/messageconstant";
import { AreaJson } from "../../../DynamicFormsJson/MastersJSON/area";
import { getTalukaUpdated } from "../../../Slice/taluka.slice";
import { showLoador, showNotification } from "../../Landing/Landing.slice";

class AreaForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        talukaDetail: this.props.talukaList?.taluka,
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.talukaList !== nextProps.talukaList) {
      if (this.props.talukaList?.taluka !== nextProps.talukaList?.taluka) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            talukaDetail: nextProps.talukaList?.taluka,
          },
        });
      }
    }
  }

  async componentDidMount() {
    const { getTalukaUpdated, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getTalukaUpdated().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    }
    else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  render() {
    const { dynamicMasterData } = this.state;
    return (
      <>
        <DynamicForm
          screenTitle={AreaJson.screenTitle}
          fieldMeta={AreaJson.fieldMeta}
          formPath={AreaJson.formPath}
          tableColumnsToFilter={AreaJson.tableColumnsToFilter}
          apiBaseURL={AreaJson.apiBaseURL}
          dynamicMasterData={dynamicMasterData}
          baseIdColumn={AreaJson.baseIdColumn}
          showSaveNextBtn={AreaJson.showSaveNextBtn}
          type={0}
          isLog={true}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  talukaList: state.taluka,
});
const mapDispatchToProps = {
  getTalukaUpdated,
  showLoador,
  showNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(AreaForm);
