import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import PersonIcon from '@mui/icons-material/Person';
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Checkbox, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { compose } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getDistributorInquiry, setDistributorInquiry } from "../../Slice/distributorInquiry.slice";
import { ButtonCompo } from "../../components/Comman/Button";
import { MyComponentWithIconProps } from "../../components/Comman/IconButton";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { withRouter } from "../../components/withRouter";
import endpoint from "../../config/endpoints";
import {
    aaproveMessage,
    changeActive,
    checkedListEmptyMsg,
    checkedListMsg,
    closeMessage,
    noInternetMsg,
    selectOneRecordOnlyMsg,
    serverMsg,
    statusFailedMsg,
    statusmsg
} from "../../config/messageconstant";
import { apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import AddWatcherPopUp from "./AddWatcherPopUp";
import ComparePopUp from "./ComparePopUp";
import MinutesOfMeetingPopUp from "./MinutesOfMeetingPopUp";
import QuestionViewPopUp from "./QuestionViewPopUp";
import AddDistributorForm from "./AddDistributorForm";
import ConvertDataPopUp from "./ConvertDataPopUp";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

class RequestForDistributor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            comment: "",
            searchValue: "",
            dynamicMasterData: {
            },
            formErrors: {},
            fieldData: {},
            value: "0",
            total: 0,
            totalAmt: 0,
            open: false,
            orderList: [],
            selection: [],
            qty: 0,
            allChecked: false,
            distcountAmt: 0,
            headerData: {},
            addWatcherFlag: false,
            momFlag: false,
            tableData: {},
            fileName: "",
            rows: [],
            dataRow: "",
            assetsRequestDetailsId: "",
            QuestionViewPopUpFlag: false,
            comparePopUpFlag: false,
            convertDataFlag: false,
            compareData: {},
            editFlagForHideChecklist: false,
            editFormOpenFlag: false,
            selectedRecord: [],
        };
    }


    async componentDidMount() {
        const { showLoador, showNotification, getDistributorInquiry } = this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            await getDistributorInquiry().then(({ response, success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }

    handleChange = (event, newValue) => {

        this.setState({
            value: newValue,
        });
    };

    onSearch = (searchValue) => {
        this.setState({
            searchValue: searchValue,
        });
    };




    getFilteredTableData = (searchData) => {
        const { searchValue } = this.state;
        return searchData.filter((currentRow) => {
            let isValid = false;
            if (
                (currentRow["businessName"] &&
                    currentRow["businessName"]
                        .toString()
                        .toLowerCase()
                        .includes(searchValue.trim().toLowerCase()))
            ) {
                isValid = true;
                return true;
            }
        });
    };

    onAddButtonClick = () => {
        window.location.replace("/add-distributor-form")
    }

    onCancel = () => {
        this.props.navigate(-1);
    };


    handleAllCheckedClick = (e) => {
        const { distributorInquiryList, setDistributorInquiry } = this.props;
        const { value } = this.state;

        this.setState({ selection: e });

        this.setState({ allChecked: e.target.checked });

        let dataList = distributorInquiryList.distributorInquiry.map((dataObj) => {
            return {
                ...dataObj,
                isChecked: dataObj.status == value ? e.target.checked : dataObj.isChecked,
            };
        });
        setDistributorInquiry({ row: dataList });
    };

    handleClick = (e, id) => {
        const { distributorInquiryList, setDistributorInquiry } = this.props;

        this.setState({ selection: e });

        let data = distributorInquiryList.distributorInquiry.map((dataObj) => {
            if (dataObj.id == id) {
                return {
                    ...dataObj,
                    isChecked: e.target.checked,
                };
            } else {
                return dataObj;
            }
        });
        setDistributorInquiry({ row: data });
    };

    toggleAddWatcher = () => {
        this.setState({
            addWatcherFlag: false,
        });
    };


    toggleMom = () => {
        this.setState({
            momFlag: false,
        });
    }

    toggleQuestionView = () => {
        this.setState({
            QuestionViewPopUpFlag: false,
        });
    }


    toggleCompare = () => {
        this.setState({
            comparePopUpFlag: false,
        });
    }

    toggleConvertDataPopUp = () => {
        this.setState({
            convertDataFlag: false,
        })
    }


    onApproveHandler = () => {
        const { distributorInquiryList, showNotification, showLoador } = this.props;
        const { selection } = this.state;

        if (selection.length !== 0) {
            let approveJSon = {};
            let flag = "";

            distributorInquiryList.distributorInquiry.filter((rowData) => rowData.isChecked)
                .map((dataObj) => {
                    flag = dataObj.flag;
                    approveJSon = {
                        distributorInquiryIds: dataObj.id.split(","),
                        status: "1",
                        stockStakeHolderTypeId: "",
                    }
                });

            if (flag == 1) {
                return showNotification({
                    msg: "Since you are not a watcher, you cannot approve this request.",
                    severity: "error",
                });
            }

            swal({
                title: "Are you sure?",
                text: aaproveMessage,
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    if (navigator.onLine) {
                        showLoador({ loador: true });
                        apiPost({
                            url: endpoint.distributorInquiry + "/update-status-and-stock-holder-save",
                            postBody: approveJSon,
                        }).then(({ success }) => {
                            if (success) {
                                showLoador({ loador: false });
                                showNotification({
                                    msg: statusmsg,
                                });
                                window.location.reload();
                            } else {
                                showLoador({ loador: false });
                                showNotification({
                                    msg: statusFailedMsg,
                                    severity: "error",
                                });
                            }
                        });
                    } else {
                        showNotification({ msg: noInternetMsg, severity: "error" });
                    }
                }
            });

        } else {
            showNotification({
                msg: checkedListEmptyMsg,
                severity: "error",
            });
        }
    }

    onConvert = () => {
        const { distributorInquiryList, showNotification, showLoador } = this.props;
        const { selection } = this.state;

        if (selection.length === 0) {
            return showNotification({
                msg: checkedListEmptyMsg,
                severity: "error",
            });
        }


        const selectedRecord = distributorInquiryList.distributorInquiry.filter((rowData) => rowData.isChecked);

        if (selectedRecord.length > 1) {
            return showNotification({
                msg: selectOneRecordOnlyMsg,
                severity: "error",
            });
        }

        if (selectedRecord[0].flag == 1) {
            return showNotification({
                msg: "Since you are not a watcher, you cannot convert this request.",
                severity: "error",
            });
        }


        this.setState({
            convertDataFlag: true,
            selectedRecord,
        });

    }

    onClosedHandler = () => {
        const { distributorInquiryList, showNotification, showLoador } = this.props;
        const { selection } = this.state;

        if (selection.length !== 0) {
            let approveJSon = {};
            let flag = "";

            distributorInquiryList.distributorInquiry.filter((rowData) => rowData.isChecked)
                .map((dataObj) => {
                    flag = dataObj.flag;
                    approveJSon = {
                        distributorInquiryIds: dataObj.id.split(","),
                        status: "3",
                        stockStakeHolderTypeId: "",
                    }
                });

            if (flag == 1) {
                return showNotification({
                    msg: "Since you are not a watcher, you cannot close this request.",
                    severity: "error",
                })
            }

            swal({
                title: "Are you sure?",
                text: closeMessage,
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    if (navigator.onLine) {
                        showLoador({ loador: true });
                        apiPost({
                            url: endpoint.distributorInquiry + "/update-status-and-stock-holder-save",
                            postBody: approveJSon,
                        }).then(({ success }) => {
                            if (success) {
                                showLoador({ loador: false });
                                showNotification({
                                    msg: statusmsg,
                                });
                                window.location.reload();
                            } else {
                                showLoador({ loador: false });
                                showNotification({
                                    msg: statusFailedMsg,
                                    severity: "error",
                                });
                            }
                        });
                    } else {
                        showNotification({ msg: noInternetMsg, severity: "error" });
                    }
                }
            });

        } else {
            showNotification({
                msg: checkedListEmptyMsg,
                severity: "error",
            });
        }
    }

    onCompare = () => {
        const { distributorInquiryList, showLoador, showNotification } = this.props;
        const { selection } = this.state;

        if (navigator.onLine) {
            if (selection.length !== 0) {

                let distributorInquiryIdsData = {
                    distributorInquiryIds: distributorInquiryList.distributorInquiry.filter((rowData) => rowData.isChecked)
                        .map((dataObj) => dataObj.id),
                };


                if (distributorInquiryIdsData.distributorInquiryIds.length < 2) {
                    showNotification({
                        msg: "Please select at least two records to compare.",
                        severity: "error",
                    });
                    return;
                }

                showLoador({ loador: true });
                apiPost({
                    url: endpoint.distributorInquiry + "/with-audit-head-quetion-and-questionary-by-distributor-inquiries",
                    postBody: distributorInquiryIdsData,
                }).then(({ data, success }) => {
                    showLoador({ loador: false });
                    if (success) {
                        this.setState({ compareData: data.data, comparePopUpFlag: true })
                    } else {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            } else {
                showNotification({
                    msg: checkedListMsg,
                    severity: "error",
                });
            }

        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }


    onClickAddWatcher = (e, row) => {
        this.setState({ addWatcherFlag: true, dataRow: row })
    }

    onClickAdd = (e, row) => {
        this.setState({ momFlag: true, dataRow: row })
    }

    onClickEdit = (e, row) => {
        this.setState({ dataRow: row, editFormOpenFlag: true, editFlagForHideChecklist: true });


        const editFlagForHideChecklist = true;
        const url = `/add-distributor-form/${row.id}?editFlagForHideChecklist=${editFlagForHideChecklist}`;

        window.location.replace(url);
    }


    onClickView = (e, row) => {
        this.setState({ QuestionViewPopUpFlag: true, dataRow: row })
    }

    render() {
        const {
            value,
            allChecked,
            addWatcherFlag,
            momFlag,
            dataRow,
            QuestionViewPopUpFlag,
            comparePopUpFlag,
            compareData,
            editFormOpenFlag,
            editFlagForHideChecklist,
            convertDataFlag,
            selection,
            selectedRecord,
        } = this.state;
        const { distributorInquiryList } = this.props;
        return (
            <>
                <Paper
                    sx={{
                        p: 2,
                        borderRadius: 2,
                    }}
                    elevation={0}
                >
                    <LandingScreenHeader
                        showTitle={true}
                        screenTitle={"Area Exploration"}
                        showSaveBtnMain={false}
                        showAddButton={true}
                        onAddButtonClick={this.onAddButtonClick}
                        showBackButton={false}
                        onCancel={this.onCancel}

                    />
                    <br />
                    <>
                        <Tabs
                            value={value}
                            onChange={this.handleChange}
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                            aria-label="scrollable force tabs example"
                        >
                            <Tab value="0" label={"Pending For Approval" + " ( " + distributorInquiryList?.distributorInquiry.filter((row) => row.status == 0).length + " ) "} />
                            <Tab value="1" label={"Approved" + " ( " + distributorInquiryList?.distributorInquiry.filter((row) => row.status == 1).length + " ) "} />
                            <Tab value="2" label={"Converted" + " ( " + distributorInquiryList?.distributorInquiry.filter((row) => row.status == 2).length + " ) "} />
                            <Tab value="3" label={"Closed" + " ( " + distributorInquiryList?.distributorInquiry.filter((row) => row.status == 3).length + " ) "} />
                        </Tabs>

                        <br />

                        <TabPanel value={value} index={value}>
                            <LandingScreenHeader
                                screenTitle=""
                                showSearchBox={true}
                                showTable={true}
                                showFilter={false}
                                showPdfDownload={false}
                                showExcelDownload={false}
                                onSearch={this.onSearch}
                            />

                            <TableContainer style={{ maxHeight: "550px", overflow: "auto" }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell padding="checkbox" align="center" width="5%">
                                                <Checkbox
                                                    color="error"
                                                    onClick={(event) => this.handleAllCheckedClick(event)}
                                                    checked={allChecked}
                                                    disabled={false}
                                                    inputProps={{
                                                        "aria-label": "select all desserts",
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell align="center" width="5%">#</TableCell>
                                            <TableCell align="center" width="17%">Lead Generated By</TableCell>
                                            <TableCell align="center" width="17%">Business Name</TableCell>
                                            <TableCell align="center" width="17%">Owner Name</TableCell>
                                            <TableCell align="center" width="12%">Contact Number</TableCell>
                                            <TableCell align="center" width="15%">Email Id</TableCell>
                                            <TableCell align="center" width="20%">Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.getFilteredTableData(value == 0 ? distributorInquiryList?.distributorInquiry.filter((row) => row.status == 0)
                                            : value == 1 ? distributorInquiryList?.distributorInquiry.filter((row) => row.status == 1)
                                                : value == 2 ? distributorInquiryList?.distributorInquiry.filter((row) => row.status == 2)
                                                    : distributorInquiryList?.distributorInquiry.filter((row) => row.status == 3))?.map((row, index) => (
                                                        <TableRow key={row.id}>
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    color="primary"
                                                                    onClick={(event) => this.handleClick(event, row.id)}
                                                                    checked={row.isChecked}
                                                                    disabled={false}
                                                                    inputProps={{
                                                                        "aria-labelledby": `enhanced-table-checkbox-${index}`,
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell align="center">{index + 1}</TableCell>
                                                            <TableCell align="left">{row.insertedName}</TableCell>
                                                            <TableCell align="left">{row.businessName}</TableCell>
                                                            <TableCell width="left" >{row.fullName} </TableCell>
                                                            <TableCell align="center">{row.contactNo}</TableCell>
                                                            <TableCell align="left">{row.emailId}</TableCell>
                                                            <TableCell align="center">
                                                                <MyComponentWithIconProps
                                                                    statusImage={PersonIcon}
                                                                    color="primary"
                                                                    fontSize="medium"
                                                                    title="Add Watcher"
                                                                    onClick={(e) => this.onClickAddWatcher(e, row)}
                                                                />
                                                                &nbsp;
                                                                <MyComponentWithIconProps
                                                                    statusImage={AddIcon}
                                                                    color="primary"
                                                                    fontSize="medium"
                                                                    title="MOM"
                                                                    onClick={(e) => this.onClickAdd(e, row)}
                                                                />
                                                                &nbsp;
                                                                {((row.flag == 1 || row.flag == 2) && row.status != 2) && (
                                                                    <MyComponentWithIconProps
                                                                        statusImage={EditIcon}
                                                                        color="primary"
                                                                        fontSize="medium"
                                                                        title="Edit"
                                                                        onClick={(e) => this.onClickEdit(e, row)}
                                                                    />
                                                                )}

                                                                &nbsp;
                                                                <MyComponentWithIconProps
                                                                    statusImage={VisibilityIcon}
                                                                    color="primary"
                                                                    fontSize="medium"
                                                                    title="Question View"
                                                                    onClick={(e) => this.onClickView(e, row)}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </TabPanel>
                        <br />
                        {addWatcherFlag && (
                            <AddWatcherPopUp
                                open={addWatcherFlag}
                                handleCloses={this.toggleAddWatcher}
                                dataRow={dataRow}
                            />
                        )}

                        {momFlag && (
                            <MinutesOfMeetingPopUp
                                open={momFlag}
                                handleCloses={this.toggleMom}
                                dataRow={dataRow}
                            />
                        )}



                        {QuestionViewPopUpFlag && (
                            <QuestionViewPopUp
                                open={QuestionViewPopUpFlag}
                                handleCloses={this.toggleQuestionView}
                                dataRow={dataRow}
                            />
                        )}


                        {comparePopUpFlag && (
                            <ComparePopUp
                                open={comparePopUpFlag}
                                handleCloses={this.toggleCompare}
                                compareData={compareData}
                            />
                        )}

                        {convertDataFlag && (
                            <ConvertDataPopUp
                                open={convertDataFlag}
                                handleCloses={this.toggleConvertDataPopUp}
                                distributorInquiryList={distributorInquiryList}
                                selectedRecord={selectedRecord}
                            />

                        )}


                        {value == 0 && (
                            <Grid container spacing={1}>
                                <Grid
                                    container
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    sx={{ justifyContent: "flex-end" }}
                                >
                                    <ButtonCompo
                                        size="medium"
                                        type="submit"
                                        variant="contained"
                                        name={"Compare"}
                                        onClick={this.onCompare}
                                    />
                                    &nbsp;&nbsp;&nbsp;
                                    <ButtonCompo
                                        size="medium"
                                        type="submit"
                                        variant="contained"
                                        name={"Approve"}
                                        onClick={this.onApproveHandler}
                                    />
                                    &nbsp;&nbsp;&nbsp;
                                    <ButtonCompo
                                        size="medium"
                                        type="submit"
                                        variant="contained"
                                        name={"Close"}
                                        onClick={this.onClosedHandler}
                                    />
                                </Grid>
                            </Grid>
                        )
                        }

                        {value == 1 && (
                            <Grid container spacing={1}>
                                <Grid
                                    container
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    sx={{ justifyContent: "flex-end" }}
                                >
                                    <ButtonCompo
                                        size="medium"
                                        type="submit"
                                        variant="contained"
                                        name={"Compare"}
                                        onClick={this.onCompare}
                                    />
                                    &nbsp;&nbsp;&nbsp;
                                    <ButtonCompo
                                        size="medium"
                                        type="submit"
                                        variant="contained"
                                        name={"Convert"}
                                        onClick={this.onConvert}
                                    />
                                    &nbsp;&nbsp;&nbsp;
                                    <ButtonCompo
                                        size="medium"
                                        type="submit"
                                        variant="contained"
                                        name={"Closed"}
                                        onClick={this.onClosedHandler}
                                    />
                                </Grid>
                            </Grid>
                        )
                        }


                        {value == 2 && (
                            <Grid container spacing={1}>
                                <Grid
                                    container
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    sx={{ justifyContent: "flex-end" }}
                                >
                                    <ButtonCompo
                                        size="medium"
                                        type="submit"
                                        variant="contained"
                                        name={"Compare"}
                                        onClick={this.onCompare}
                                    />
                                </Grid>
                            </Grid>
                        )
                        }

                        {value == 3 && (
                            <Grid container spacing={1}>
                                <Grid
                                    container
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    sx={{ justifyContent: "flex-end" }}
                                >
                                    <ButtonCompo
                                        size="medium"
                                        type="submit"
                                        variant="contained"
                                        name={"Compare"}
                                        onClick={this.onCompare}
                                    />
                                    &nbsp;&nbsp;&nbsp;
                                    <ButtonCompo
                                        size="medium"
                                        type="submit"
                                        variant="contained"
                                        name={"Approve"}
                                        onClick={this.onApproveHandler}
                                    />
                                </Grid>
                            </Grid>
                        )
                        }

                    </>

                </Paper>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    distributorInquiryList: state.distributorInquiry,
});
const mapDispatchToProps = {
    showNotification,
    showLoador,
    setDistributorInquiry,
    getDistributorInquiry,
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(RequestForDistributor);
