import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import { NoticeMsgColumns } from "../../tableColumns/table-columns";
import { noticeMessageJSON } from "../../DynamicFormsJson/Transaction/noticeMessage";
import {
  getNoticeMsg,
  getActiveNoticeMsgByArea,
  setNoticeMsg,
} from "../../Slice/noticeMsg.slice";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import dayjs from "dayjs";

class NoticeMessageList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDate: "",
      toDate: "",
    };
  }

  componentDidMount() {
    this.onLoad();
  }
  onSearchData = async (data) => {
    const { getNoticeMsg, showLoador, showNotification } = this.props;
    this.setState({
      fromDate: data.fromDate,
      toDate: data.toDate,
    });
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getNoticeMsg({
        fromDate: data.fromDate,
        toDate: data.toDate,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onLoad = () => {
    const { getActiveNoticeMsgByArea, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getActiveNoticeMsgByArea().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  render() {
    const { noticeMsgList, setNoticeMsg } = this.props;
    const { fromDate, toDate } = this.state;
    return (
      <>
        <DynamicMainScreen
          showViewButtonInTable={true}
          formPath={noticeMessageJSON.formPath}
          getTableData={this.onLoad}
          searchList={noticeMessageJSON.searchList}
          screenTitle={noticeMessageJSON.screenTitle}
          fieldMeta={noticeMessageJSON.fieldMeta}
          showPdfDownload={noticeMessageJSON.showPdfDownload}
          showExcelDownload={true}
          filterDataExcel={true}
          changeExcelURL={
            "notice-data?fromDate=" + fromDate + "&toDate=" + toDate
          }
          showExtension={false}
          formPathView={noticeMessageJSON.formPathView}
          pdfFileName={noticeMessageJSON.pdfFileName}
          excelFileName={noticeMessageJSON.excelFileName}
          showAddButton={noticeMessageJSON.showAddButton}
          tableColumnsToFilter={noticeMessageJSON.tableColumnsToFilter}
          tableColumns={NoticeMsgColumns}
          tableData={noticeMsgList?.noticeMsg}
          apiBaseURL={noticeMessageJSON.apiBaseURL}
          onSearchData={this.onSearchData}
          callApi={false}
          getTableDataById={true}
          isActiveURL={noticeMessageJSON.isActiveURL}
          showDeleteIcon={false}
          isLog={true}
          type={0}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  noticeMsgList: state.noticeMsg,
});
const mapDispatchToProps = {
  getNoticeMsg,
  getActiveNoticeMsgByArea,
  showNotification,
  setNoticeMsg,
  showLoador,
};
export default connect(mapStateToProps, mapDispatchToProps)(NoticeMessageList);
