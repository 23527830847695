import {
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../../components/withRouter";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import PurchaseReturnByBillWise from "./PurchaseReturnByBillWise";

import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import PurchaseReturnForm from "./PurchaseReturnForm";
import ClaimOfOutletOfferForm from "./ClaimOfOutletOfferForm";
import MyPrimaryOffersForm from "./MyPrimaryOffersForm";
import MyUnderOffersForm from "./MyUnderOffersForm";
import RateDifferenceClaimForm from "./RateDifferenceClaimForm";

class PurchaseReturn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "0",
      stockData: [],
    };
  }
  handleChange = (event, newValue) => {
    this.setState({
      value: newValue,
    });
  };

  onCancel = () => {
    this.props.navigate(-1);
  };
  render() {
    const { value, stockData } = this.state;
    return (
      <>
        <Paper
          sx={{
            padding: 2,
          }}
          elevation={0}
        >
          <LandingScreenHeader
            screenTitle={"Purchase Return"}
            showTable={false}
            showBackButton={true}
            showAddButton={false}
            onCancel={this.onCancel}
          />
          <br />
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={value}
              onChange={this.handleChange}
              row
              defaultValue={value}
            >
              <FormControlLabel
                value="0"
                control={<Radio />}
                label="Purchase Return From Bill-Wise"
              />
              <FormControlLabel
                value="1"
                control={<Radio />}
                label="Purchase Return From Category-Wise"
              />

              {localStorage.getItem("radioPurchasereturnAndExpiryForRatestructure") &&
                localStorage.getItem("radioPurchasereturnAndExpiryForRatestructure") == 1 ?
                (
                  <>
                    <FormControlLabel
                      value="2"
                      control={<Radio />}
                      label="Outlet's CRN"
                    />

                    <FormControlLabel
                      value="3"
                      control={<Radio />}
                      label="My Primary offer"
                    />

                    <FormControlLabel
                      value="4"
                      control={<Radio />}
                      label="Stake Holder's CRN"
                    />

                    <FormControlLabel
                      value="5"
                      control={<Radio />}
                      label="Rate Difference Claim"
                    />
                  </>
                ) : (null)
              }
            </RadioGroup>

          </FormControl>

          {/* {value == "1" ? <PurchaseReturnForm /> : <PurchaseReturnByBillWise />} */}

          {value === "0" && <PurchaseReturnByBillWise />}
          {value === "1" && <PurchaseReturnForm />}
          {value === "2" && <ClaimOfOutletOfferForm value={value} />}
          {value === "3" && <MyPrimaryOffersForm value={value} />}
          {value === "4" && <MyUnderOffersForm value={value} />}
          {value === "5" && <RateDifferenceClaimForm value={value} />}

        </Paper >
      </>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  showLoador,
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PurchaseReturn);
