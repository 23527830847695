import React from "react";
import { connect } from "react-redux";
import { stateJson } from "../../../DynamicFormsJson/MastersJSON/state";
import { getUpdatedStateList } from "../../../Slice/state.slice";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import {
  StateColumns,
  ZoneColumnsForPopup,
  AreaColumnsForPopup,
  DistrictColumnsForPopup,
  TalukaColumnsForPopup,
} from "../../../tableColumns/table-columns";

class StateTable extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { stateList, getUpdatedStateList } = this.props;
    return (
      <>
        <DynamicMainScreen
          screenTitle={stateJson.screenTitle}
          fieldMeta={stateJson.fieldMeta}
          formPath={stateJson.formPath}
          showPdfDownload={stateJson.showPdfDownload}
          showExcelDownload={true}
          changeExcelURL={"state-data" + '?sort={"sortOrderNo": "ASC"}'}
          filterDataExcel={true}
          showExtension={false}
          pdfFileName={stateJson.pdfFileName}
          excelFileName={stateJson.excelFileName}
          showAddButton={stateJson.showAddButton}
          tableColumnsToFilter={stateJson.tableColumnsToFilter}
          tableColumns={StateColumns}
          tableData={stateList?.states}
          getTableData={getUpdatedStateList}
          apiBaseURL={stateJson.apiBaseURL}
          baseIdColumn={stateJson.baseIdColumn}
          isNote={true}
          showDeleteIcon={false}
          isNoteValue={"State Is The Second Largest Entity Of The System"}
          type={0}
          isLog={true}
          showPopUpDataList={[
            {
              dataKey: "zoneCount",
              columns: ZoneColumnsForPopup,
              title: "Zone List",
              header: {
                dataKey: "name",
                label: "State",
              },
            },
            {
              dataKey: "districtCount",
              columns: DistrictColumnsForPopup,
              title: "District List",
              header: {
                dataKey: "name",
                label: "State",
              },
            },
            {
              dataKey: "talukaCount",
              columns: TalukaColumnsForPopup,
              title: "Taluka List",
              header: {
                dataKey: "name",
                label: "State",
              },
            },
            {
              dataKey: "areaCount",
              columns: AreaColumnsForPopup,
              title: "Area List",
              header: {
                dataKey: "name",
                label: "State",
              },
            },
          ]}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  stateList: state.states,
});
const mapDispatchToProps = {
  getUpdatedStateList,
};
export default connect(mapStateToProps, mapDispatchToProps)(StateTable);
