import React from "react";
import { connect } from "react-redux";
import { distributorOpeningJson } from "../../DynamicFormsJson/Transaction/distributorOpeningForm";
import { getOpeningList } from "../../Slice/StackHolder.slice";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import { openingDistributorList } from "../../tableColumns/table-columns";
import { showLoador } from "../Landing/Landing.slice";
import { withRouter } from "../../components/withRouter";
import { compose } from "recompose";
import {
  FailTogenerateCredentialMsg,
  generateCredentialMsg,
  noInternetMsg,
  saveFailedMsg,
  sendCredentialMsg,
  serverMsg,
} from "../../config/messageconstant";
import { showNotification } from '../Landing/Landing.slice'
import { apiGet, apiPost } from "../../utils/api_service";
import endpoint from "../../config/endpoints";

class OpeningDistrubutorList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLog: true,
      moduleObjDataObj: {},
    };
  }



  async componentDidMount() {
    if (navigator.onLine) {
      const moduleObjData = localStorage.getItem("moduleObj");
      const moduleObjDataObj = moduleObjData != null ? JSON.parse(moduleObjData) : {};

      this.setState({ moduleObjDataObj });

    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }



  getTableData = async () => {
    const { showNotification,getOpeningList } = this.props;
    let resData = { response: [], success: false }
    if (navigator.onLine) {
      
      showLoador({ loador: true });
      resData = await getOpeningList().then(({ response, success }) => {
        console.log(resData)
        return { response, success };
      });
      
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
      return { response: [], success: false };
    }
    return resData
  }




  render() {
    const { stakeholdersList } = this.props;
    return (
      <>
        <DynamicMainScreen
          formPath={distributorOpeningJson.formPath}
          formPathEdit={distributorOpeningJson.formPathEdit}
          screenTitle={distributorOpeningJson.screenTitle}
          fieldMeta={distributorOpeningJson.fieldMeta}
          showPdfDownload={false}
          showExcelDownload={false}
          showExtension={false}
          pdfFileName={distributorOpeningJson.pdfFileName}
          excelFileName={distributorOpeningJson.excelFileName}
          showAddButton={distributorOpeningJson.showAddButton}
          tableColumnsToFilter={distributorOpeningJson.tableColumnsToFilter}
          tableColumns={openingDistributorList}
          tableData={stakeholdersList?.opening}
          showTransferButtonInTable={false}
          openFormPathStatus={distributorOpeningJson.openFormPathStatus}
          getTableData={this.getTableData}
          apiBaseURL={distributorOpeningJson.apiBaseURL}
          baseIdColumn={distributorOpeningJson.baseIdColumn}
          showApply={false}
          isActiveURL={false}
          isLog={false}
          type={1}
          isActionColActive={false}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  subAdminUserList: state.subAdminUser,
  stakeholdersList: state.stackholder,

});
const mapDispatchToProps = {
  getOpeningList,
  showNotification,
  showLoador,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(OpeningDistrubutorList);
