import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import styled from "styled-components";
import "./maincss.css";
import { connect } from "react-redux";
import AutoComplete from "./Autocomplete";
import Marker from "./Marker";
import { ButtonCompo } from "../../components/Comman/Button";
import { withRouter } from "../../components/withRouter";
import { compose } from "@reduxjs/toolkit";

const Wrapper = styled.main`
  width: 75%;
  height: 70%;
`;

class GoogleMapForAreaExploration extends Component {
    state = {
        mapApiLoaded: false,
        mapInstance: null,
        mapApi: null,
        center: { lat: 0, lng: 0 },
        zoom: 9,
        address: "",
        lat: null,
        lng: null,
    };

    componentDidMount() {
        const { setLocation, address } = this.props;


        if (setLocation.latitude && setLocation.longitude) {
            this.setState({
                center: { lat: +setLocation.latitude, lng: +setLocation.longitude },
                lat: +setLocation.latitude,
                lng: +setLocation.longitude,
                address: address,
            });
        } else {
            this.setCurrentLocation();
        }
    }

    onMapClick = ({ lat, lng }) => {
        this.setState(
            {
                lat,
                lng,
                draggable: false,
            },
            () => {
                this._generateAddress();
            }
        );
    };

    apiHasLoaded = (map, maps) => {
        this.setState({ mapApiLoaded: true, mapInstance: map, mapApi: maps });
    };

    addPlace = (place) => {
        this.setState({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
            center: { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() },
        }, this._generateAddress);
    };

    _generateAddress = () => {
        const { mapApi, lat, lng } = this.state;
        if (!mapApi || lat === null || lng === null) return;

        const geocoder = new mapApi.Geocoder();
        geocoder.geocode({ location: { lat, lng } }, (results, status) => {
            if (status === "OK" && results[0]) {
                this.setState({ address: results[0].formatted_address });
            }
        });
    };

    setCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.setState({
                    center: { lat: position.coords.latitude, lng: position.coords.longitude },
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                }, this._generateAddress);
            });
        }
    };

    getLocationData = () => {
        this.props.getLocation(this.state.lat, this.state.lng);
    };

    backToForm = () => {
        this.props.closeMap();
    };

    render() {
        const { mapApiLoaded, mapInstance, mapApi, lat, lng, address, center, zoom } = this.state;

        return (
            <div className="main-wrapper">
                <Wrapper>

                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        {mapApiLoaded && <AutoComplete map={mapInstance} mapApi={mapApi} addplace={this.addPlace} />}

                        <ButtonCompo
                            size="small"
                            type="button"
                            variant="contained"
                            name="Back"
                            onClick={this.backToForm}
                        />
                    </div>
                    <div style={{ height: '350px' }}>
                        <GoogleMapReact
                            center={center}
                            zoom={zoom}
                            onClick={this.onMapClick}
                            bootstrapURLKeys={{ key: "AIzaSyDvKXkZ-S03qxR-8b8HLnCOKr36eYRxC2k", libraries: ["places"] }}
                            onGoogleApiLoaded={({ map, maps }) => this.apiHasLoaded(map, maps)}
                            yesIWantToUseGoogleMapApiInternals
                        >
                            {lat && lng && <Marker lat={lat} lng={lng} text={address} pinColor="green" />}
                        </GoogleMapReact>
                    </div>
                    <div
                        className="info-wrapper"
                        style={{
                            marginTop: "10px",
                        }}
                    >
                        <div className="map-details">Latitude: <span>{lat}</span>, Longitude: <span>{lng}</span></div>
                        <div className="map-details">Address: <span>{address}</span></div>
                    </div>

                    <ButtonCompo size="medium" type="button" variant="contained" name="Get Location" fullWidth onClick={this.getLocationData} />
                </Wrapper>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(GoogleMapForAreaExploration);
