import { Divider, Dialog, DialogContent, Grid, Paper, styled, IconButton } from "@mui/material";
import { DialogTitle, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Button";
import { withRouter } from "../../components/withRouter";
import { convertMessage, noInternetMsg, saveFailedMsg, serverMsg, statusFailedMsg, statusmsg } from "../../config/messageconstant";
import NoDataFound from "../DashbourdChanges/NoDataFound";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { apiGet, apiPost } from "../../utils/api_service";
import endpoint from "../../config/endpoints";
import CloseIcon from "@mui/icons-material/Close";
import { blackColor } from "../../config/ColorObj";
import { getStockStakeHolderType } from "../../Slice/stockStakeHolderType.slice";
import { getBrand } from "../../Slice/brand.slice";
import { getPaymentTerm } from "../../Slice/paymentTerm.Slice";
import { AddDistributorJson } from "../../DynamicFormsJson/Transaction/addDistributor";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { saveDistributorInquiry } from "../../Slice/distributorInquiry.slice";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { classes, children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};


class ConvertDataPopUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicMasterData: {
                stakeHolderTypeDetail:
                    this.props.stakeHolderTypeList?.stockStakeHolderType,
                brandDetail: this.props.brandList?.brand,
                paymentTermDetail: this.props.paymentTermList?.paymentTerm,

            },
            openPopUp: false,
            fullName: "",
            areaHierarchyData: {},
            userTypeId: "",
        };

    }
    async componentDidMount() {
        const { selectedRecord, getBrand, getPaymentTerm, getStockStakeHolderType, showLoador, showNotification } = this.props;
        if (navigator.onLine) {

            const userTypeId = localStorage.getItem("userTypeId");
            this.setState({ userTypeId });

            let fullName = "";
            let areaId = "";

            selectedRecord.map((dataObj) => {
                fullName = dataObj.fullName;
                areaId = dataObj.currentAreaIds
            });

            this.setState({ fullName });

            if (userTypeId && userTypeId == 3) {

                showLoador({ loador: true });
                await apiGet({
                    url:
                        endpoint.area + "/hierarchy-by-area?areaId=" + areaId
                }).then(({ data, success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        this.props.showNotification({
                            msg: serverMsg,
                            severity: "error",
                        });
                    } else {
                        this.setState({ areaHierarchyData: data.data })
                    }
                });
            }


            showLoador({ loador: true });
            await getBrand().then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });

            showLoador({ loador: true });
            getPaymentTerm().then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });

            showLoador({ loador: true });
            await getStockStakeHolderType({ userTypeIds: 2 }).then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                } else {
                    this.setState({ openPopUp: true })
                }
            });


        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }

    componentWillReceiveProps(nextProps) {

        if (this.props.brandList !== nextProps.brandList) {
            if (this.props.brandList?.brand !== nextProps.brandList?.brand) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        brandDetail: nextProps.brandList?.brand,
                    },
                });
            }
        }

        if (this.props.paymentTermList !== nextProps.paymentTermList) {
            if (this.props.paymentTermList?.paymentTerm !== nextProps.paymentTermList?.paymentTerm) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        paymentTermDetail: nextProps.paymentTermList?.paymentTerm,
                    },
                });
            }
        }

        if (this.props.stakeHolderTypeList !== nextProps.stakeHolderTypeList) {
            if (
                this.props.stakeHolderTypeList?.stockStakeHolderType !==
                nextProps.stakeHolderTypeList?.stockStakeHolderType
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        stakeHolderTypeDetail:
                            nextProps.stakeHolderTypeList?.stockStakeHolderType,
                    },
                });
            }
        }

    }

    getListById = () => {

    }

    handleClose = () => {
        const { handleCloses } = this.props;
        handleCloses();
    }


    onSave = (dataToSave) => {
        const { selectedRecord, showLoador, showNotification, saveDistributorInquiry } = this.props;
        const { areaHierarchyData, userTypeId } = this.state;

        if (userTypeId == 3) {
            dataToSave = {
                ...selectedRecord[0],
                currentRegionId: areaHierarchyData.regionId,
                currentStateId: areaHierarchyData.stateId,
                currentZoneIds: areaHierarchyData.zoneId,
                currentDistrictIds: areaHierarchyData.districtId,
                currentTalukaIds: areaHierarchyData.talukaId,
                ...dataToSave,
            };
        } else {
            dataToSave = {
                ...selectedRecord[0],
                ...dataToSave,
            };
        }

        console.log("dataToSave");
        console.log(dataToSave)

        let approveJSon = {};

        selectedRecord.map((dataObj) => {
            approveJSon = {
                distributorInquiryIds: dataObj.id.split(","),
                status: "2",
                stockStakeHolderTypeId: dataToSave.stockStakeHolderType,
            }
        });

        if (navigator.onLine) {


            swal({
                title: "Are you sure?",
                text: convertMessage,
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    showLoador({ loador: true });
                    saveDistributorInquiry({ dataToSave }).then(({ response, success }) => {
                        showLoador({ loador: false });
                        if (success) {
                            showLoador({ loador: true });
                            apiPost({
                                url: endpoint.distributorInquiry + "/update-status-and-stock-holder-save",
                                postBody: approveJSon,
                            }).then(({ data, success }) => {
                                if (success) {
                                    showLoador({ loador: false });
                                    if (data.data.statusCode == 500) {
                                        showNotification({ msg: data.data.message, severity: "error" });
                                    } else {
                                        this.handleClose();
                                        window.location.reload();
                                        showNotification({ msg: statusmsg });
                                    }

                                } else {
                                    showLoador({ loador: false });
                                    showNotification({
                                        msg: statusFailedMsg,
                                        severity: "error",
                                    });
                                }
                            });
                        } else {
                            showNotification({ msg: saveFailedMsg, severity: "error" });
                        }
                    });
                }
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };


    onSaveData = (data) => {

        const { selectedRecord, showNotification, showLoador } = this.props;




        swal({
            title: "Are you sure?",
            text: convertMessage,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                if (navigator.onLine) {
                    showLoador({ loador: true });
                    apiPost({
                        url: endpoint.distributorInquiry + "/update-status-and-stock-holder-save",
                        postBody: approveJSon,
                    }).then(({ success }) => {
                        if (success) {
                            showLoador({ loador: false });
                            showNotification({
                                msg: statusmsg,
                            });
                            window.location.reload();
                        } else {
                            showLoador({ loador: false });
                            showNotification({
                                msg: statusFailedMsg,
                                severity: "error",
                            });
                        }
                    });
                } else {
                    showNotification({ msg: noInternetMsg, severity: "error" });
                }
            }
        });


    }

    render() {
        const { openPopUp, dynamicMasterData, fullName } = this.state;

        return (
            <>
                <BootstrapDialog
                    onClose={this.handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={openPopUp}
                    fullWidth
                    maxWidth="lg"
                >
                    <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={this.handleClose}
                    >
                        Add Below Details
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <Typography
                            style={{
                                fontSize: 18,
                                color: blackColor,
                                marginLeft: "9px"
                            }}
                        >
                            {"Name :" + " " + fullName}
                        </Typography>

                        <Paper
                            sx={{
                                padding: 2,
                            }}
                            elevation={0}
                        >
                            <DynamicFormWithoutSave
                                formPath={AddDistributorJson.formPath}
                                showPdfDownload={false}
                                showExcelDownload={false}
                                showBackToList={true}
                                getListById={this.getListById}
                                screenTitle={""}
                                fieldMeta={AddDistributorJson.fieldMetaForConvert}
                                dynamicMasterData={dynamicMasterData}
                                apiBaseURL={AddDistributorJson.apiBaseURL}
                                showSaveNextBtn={AddDistributorJson.showSaveNextBtn}
                                isEditURL={AddDistributorJson.isEditURL}
                                showSaveBtnMain={false}
                                showSaveBtn={true}
                                onSave={this.onSave}
                                showCancel={false}
                            />
                        </Paper>

                    </DialogContent>
                </BootstrapDialog >
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    stakeHolderTypeList: state.stockStakeHolderType,
    brandList: state.brand,
    paymentTermList: state.paymentTerm,
});
const mapDispatchToProps = {
    showNotification,
    showLoador,
    getStockStakeHolderType,
    getBrand,
    getPaymentTerm,
    saveDistributorInquiry,
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConvertDataPopUp);
