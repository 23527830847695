import React from "react";
import { connect } from "react-redux";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import { SubChannelJson } from "../../../DynamicFormsJson/MastersJSON/subChannel";
import { getChannel } from "../../../Slice/channel.slice";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import { serverMsg, noInternetMsg } from "../../../config/messageconstant";
class SubChannelForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        channelDetail: this.props.channelList?.channel,
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.channelList !== nextProps.channelList) {
      if (this.props.channelList?.channel !== nextProps.channelList?.channel) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            channelDetail: nextProps.channelList?.channel,
          },
        });
      }
    }
  }
  async componentDidMount() {
    const { getChannel, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getChannel().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  render() {
    const { dynamicMasterData } = this.state;
    return (
      <>
        <DynamicForm
          formPath={SubChannelJson.formPath}
          screenTitle={SubChannelJson.screenTitle}
          fieldMeta={SubChannelJson.fieldMeta}
          tableColumnsToFilter={SubChannelJson.tableColumnsToFilter}
          apiBaseURL={SubChannelJson.apiBaseURL}
          baseIdColumn={SubChannelJson.baseIdColumn}
          dynamicMasterData={dynamicMasterData}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  channelList: state.channel,

});
const mapDispatchToProps = {
  getChannel,
  showLoador,
  showNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(SubChannelForm);
