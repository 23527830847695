import Add from "@mui/icons-material/Add";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import { MyComponentWithIconProps } from "../../components/Comman/IconButton";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import { redColor } from "../../config/ColorObj";

const RUCreationAndAssignationDetailTable = ({
  rowList,
  handleRowChange,
  handleAddRow,
  handleDeleteRow,
  handleOpenProductPopup,
  toMinDate,
  toMaxDate,
  formErrors,
  type,
}) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">Sr. No.</TableCell>
            <TableCell align="center">Name</TableCell>
            <TableCell align="center">From Date</TableCell>
            <TableCell align="center">To Date</TableCell>
            <TableCell align="center">RU Qty</TableCell>
            {type === "0" && (
              <TableCell align="center">Product count</TableCell>
            )}
            <TableCell align="center">Action</TableCell>{" "}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowList.map((row, index) => (
            <TableRow key={row.index}>
              <TableCell width={"5%"} align="center">
                {row.index}
              </TableCell>
              <TableCell width={"40%"}>
                <input
                  className={"customTextfield"}
                  placeHolder={"Name"}
                  value={row.name}
                  name="name"
                  onChange={(e) =>
                    handleRowChange(row.index, "name", e.target.value)
                  }
                  type="text"
                  id="outletRate"
                />
                <span style={{ color: redColor, fontSize: "12px" }}>
                  {formErrors[row.index]?.nameError
                    ? formErrors[row.index].nameError
                    : ""}
                </span>
                {/* <TextFieldCompo
                  placeHolder={"Name"}
                  size="small"
                  color="primary"
                  type="text"
                  id={"1"}
                  value={row.name}
                  onChange={(e) =>
                    handleRowChange(row.index, "name", e.target.value)
                  }
                  fullWidth={false}
                  errorText={
                    formErrors[row.index]?.nameError
                      ? formErrors[row.index].nameError
                      : ""
                  }
                /> */}
              </TableCell>
              <TableCell width={"10%"}>{row.frmDt}</TableCell>
              <TableCell width={"12%"}>
                <BasicDatePicker
                  color="primary"
                  name="toDt"
                  size="small"
                  value={row.toDt}
                  disabled={index !== rowList.length - 1}
                  fullWidth
                  onChange={(newDate) =>
                    handleRowChange(row.index, "toDt", newDate)
                  }
                  errorText={
                    formErrors[row.index]?.toDtError
                      ? formErrors[row.index].toDtError
                      : ""
                  }
                  isError={
                    formErrors[row.index]?.toDtError
                      ? formErrors[row.index]?.toDtError
                      : ""
                  }
                  minDate={toMinDate}
                  maxDate={toMaxDate}
                />
              </TableCell>
              {type === "0" ? (
                <TableCell width={"10%"} align="center">
                  {row.ruQty}
                </TableCell>
              ) : (
                <TableCell width={"10%"} align="center">
                  <input
                    className={"customTextfield"}
                    placeHolder={"RU Qty"}
                    value={row.ruQty}
                    name="ruQty"
                    onChange={(e) =>
                      handleRowChange(row.index, "ruQty", e.target.value)
                    }
                    type="number"
                    id="outletRate"
                  />
                  <span style={{ color: redColor, fontSize: "12px" }}>
                    {formErrors[row.index]?.ruQtyError
                      ? formErrors[row.index].ruQtyError
                      : ""}
                  </span>
                  {/* <TextFieldCompo
                    placeHolder={"RU Qty"}
                    size="small"
                    color="primary"
                    type="number"
                    id={"1"}
                    value={row.ruQty}
                    onChange={(e) =>
                      handleRowChange(row.index, "ruQty", e.target.value)
                    }
                    fullWidth={false}
                    errorText={
                      formErrors[row.index]?.ruQtyError
                        ? formErrors[row.index].ruQtyError
                        : ""
                    }
                    isError={
                      formErrors[row.index]?.ruQtyError
                        ? formErrors[row.index]?.ruQtyError
                        : ""
                    }
                  /> */}
                </TableCell>
              )}
              {type === "0" && (
                <TableCell width={"12%"} align="center">
                  {row.productCount}
                </TableCell>
              )}
              <TableCell width={"12%"} align="center">
                {index === rowList.length - 1 && (
                  <MyComponentWithIconProps
                    statusImage={DeleteIcon}
                    color="error"
                    fontSize="medium"
                    title="Delete"
                    onClick={handleDeleteRow}
                  />
                )}
                {index === rowList.length - 1 && row.toDt !== toMaxDate && (
                  <MyComponentWithIconProps
                    statusImage={AddCircleIcon}
                    color="primary"
                    fontSize="medium"
                    title="Add Row"
                    onClick={handleAddRow}
                  />
                )}
                {type === "0" && (
                  <MyComponentWithIconProps
                    statusImage={Add}
                    color="primary"
                    fontSize="medium"
                    title="Add Product"
                    onClick={(e) => handleOpenProductPopup(row.index, row)}
                  />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RUCreationAndAssignationDetailTable;
