import React from "react";
import { connect } from "react-redux";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import { regionJson } from "../../../DynamicFormsJson/MastersJSON/region";
import { getCountry } from "../../../Slice/country.slice";
import {
  showLoador,
  showNotification,
} from "../../Landing/Landing.slice";
import { serverMsg, noInternetMsg, } from "../../../config/messageconstant";

class RegionForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        countryDetail: this.props.countryList?.country,
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.countryList !== nextProps.countryList) {
      if (this.props.countryList?.country !== nextProps.countryList?.country) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            countryDetail: nextProps.countryList?.country,
          },
        });
      }
    }
  }

  async componentDidMount() {
    const { getCountry, showLoador, showNotification } = this.props;
    if (navigator.onLine) {

      showLoador({ loador: true });
      await getCountry().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    }
    else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  render() {
    const { dynamicMasterData } = this.state;
    return (
      <>
        <DynamicForm
          formPath={regionJson.formPath}
          screenTitle={regionJson.screenTitle}
          fieldMeta={regionJson.fieldMeta}
          apiBaseURL={regionJson.apiBaseURL}
          dynamicMasterData={dynamicMasterData}
          type={0}
          isLog={true}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  countryList: state.country,

});
const mapDispatchToProps = {
  getCountry,
  showLoador,
  showNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(RegionForm);
