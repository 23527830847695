import { Grid, Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { ButtonCompo } from "../../components/Comman/Button";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import endpoint from "../../config/endpoints";
import {
  setCategoryAndItem,
  getCategoryItemList,
} from "../../Slice/category.slice";
import { ValidationCheck, mandatoryCheck } from "../../config/ValidationCheck";
import { apiGet, apiPost } from "../../utils/api_service";
import {
  atleastOne,
  creditLimitMsg,
  noInternetMsg,
  saveFailedMsg,
  savemsg,
  saveWarningMsg,
  serverMsg,
} from "../../config/messageconstant";
import MaterialUITableWithTextField from "../../components/Comman/RT/MaterialUITableWithTextField";
import {
  getPlaceOrderHeaderById,
  setOrderList,
} from "../../Slice/OrderHeaderList.slice";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { PlaceOrderTableJson } from "../../DynamicFormsJson/MastersJSON/PlaceOrderTable";
import DynamicFormWithoutValidation from "../../components/dynamicscreens/DynamicFormWithoutValidation";
import SubmitOrderUpdate from "./SubmitOrderUpdate";
import PlacedOrderTableUpdate from "./PlacedOrderTableUpdate";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { LabelCompo } from "../../components/Comman/Label";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import { titleColor } from "../../config/ColorObj";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
class PlaceOrderEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
      formErrors: {},
      fieldData: {},
      DataToSet: [],
      value: 0,
      total: 0,
      remark: "",
      totalAmt: 0,
      creditLimit: 0,
      open: false,
      orderList: [],
      qty: 0,
      placedOrderDetails: [],
      showSapCredtLimit: "",
      flag: false,
    };
  }
  setCatList = ({ row }) => {
    const { setCategoryAndItem } = this.props;
    let dataTotal = 0;
    let qtyTotal = 0;
    row.map((rowData) => {
      if (rowData.flag == 3) {
        rowData.productWithMarginAndMrps.map((productData) => {
          dataTotal = dataTotal + +productData.total;
          qtyTotal = qtyTotal + +productData.qty;
        });
      }
    });
    const { fieldData } = this.state;
    this.setState({
      total: dataTotal,
      qty: qtyTotal,
    });
    setCategoryAndItem({ row });
  };
  async componentDidMount() {
    const {
      showLoador,
      showNotification,
      getPlaceOrderHeaderById,
      getCategoryItemList,
    } = this.props;
    const { companyFlag } = this.state;
    if (navigator.onLine) {
      const showCompanyFlag = localStorage.getItem("showCompanyFlag");
      const showSapCredtLimit = localStorage.getItem("showSapCredtLimit");
      const stockCalAtBill = localStorage.getItem("stockCalAtBill");

      this.setState({
        companyFlag: showCompanyFlag,
        showSapCredtLimit: showSapCredtLimit,
        flag: stockCalAtBill === "1" ? true : false,
      });

      if (showSapCredtLimit == 1) {
        showLoador({ loador: true });
        apiGet({
          url: endpoint.sapCreditLimit,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: creditLimitMsg, severity: "error" });
          } else {
            if (Object.keys(data.data).length !== 0) {
              if (data.error) {
                showNotification({ msg: serverMsg, severity: "error" });
              } else {
                this.setState({
                  creditLimit: data.data.balance * -1,
                });
              }
            }
          }
        });
      } else {
        showLoador({ loador: true });
        apiGet({
          url: endpoint.stackholder + "/get-credit-limit-by-user",
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: creditLimitMsg, severity: "error" });
          } else {
            if (Object.keys(data.data).length !== 0) {
              if (data.error) {
                showNotification({ msg: serverMsg, severity: "error" });
              } else {
                this.setState({
                  creditLimit: data.data.creditLimit !== null ? data.data.creditLimit : 0,
                });
              }
            }
          }
        });
      }


      showLoador({ loador: true });
      await getPlaceOrderHeaderById({
        orderHeaderId: this.props.params.id,
      }).then(({ response, success }) => {
        console.log(response);
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          let totalQty = 0;
          response.stockStakeHolderOrderDetails.length !== 0 &&
            response.stockStakeHolderOrderDetails.map((orderDetailsObject) => {
              totalQty = totalQty + orderDetailsObject.qty;
            });
          this.setState({
            headerData: response,
            remark: response.remark !== null ? response.remark : "",
            DataToSet: [
              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "Order Number",
                value: response.orderNo !== null ? response.orderNo : "",
              },
              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "Order Date",
                value: response.orderDate !== null ? response.orderDate : "",
              },
              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "Taxable Amount",
                value: response.taxableAmt !== null ? response.taxableAmt : "",
              },
              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "Total Amount",
                value: response.totalAmt !== null ? response.totalAmt : "",
              },

              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "Total Quantity",
                value: totalQty,
              },
            ],
            placedOrderDetails:
              response.stockStakeHolderOrderDetails.length !== 0
                ? response.stockStakeHolderOrderDetails
                : [],
          });

          showLoador({ loador: true });
          getCategoryItemList().then(({ data, success }) => {
            console.log(data);
            showLoador({ loador: false });
            if (success) {
              console.log(data);
              if (data.errorMessage.error) {
                showNotification({
                  msg: data.errorMessage.message,
                  severity: "error",
                });
              } else {
                const row = data.categoryAndItems.map((rowObj) => {
                  return {
                    ...rowObj,
                    total: 0,
                    qty: 0,
                    productWithMarginAndMrps:
                      rowObj.productWithMarginAndMrps.map((product) => {
                        console.log(product);
                        let editRowData =
                          response.stockStakeHolderOrderDetails.filter(
                            (row) => row.product.id == product.products.id
                          );
                        return {
                          ...product,
                          uomConversionValue:
                            product.products.uomConversionValue,
                          rate: product.rate,
                          qty:
                            editRowData.length !== 0 ? editRowData[0].qty : "",
                          freeQty: 0,
                          itemDiscPer: 0,
                          productStock: (
                            product.productStockForMobile.secondaryClosingQty /
                            product.products.uomConversionValue
                          ).toFixed(2),
                          total: (editRowData.length !== 0
                            ? +editRowData[0].qty * +product.rate
                            : +product.qty * +product.rate
                          ).toFixed(2),
                        };
                      }),
                  };
                });
                console.log(row);
                this.setCatList({ row });
              }
            } else {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          });
        }
      });



    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  handleChange = (event, newValue) => {
    this.setState({
      value: newValue,
    });
  };
  submitHandler = () => {
    if (this.checkValidationOnSubmit()) {
      const { category } = this.props;
      let rowList = [];
      category.category.map((catData) => {
        if (catData.flag == 3) {
          const filterData = catData.productWithMarginAndMrps.filter(
            (row) => row.qty != 0
          );
          rowList = [...rowList, ...filterData];
          return catData;
        }
      });
      if (rowList.length != 0) {
        this.setState({
          orderList: rowList.map((rowData, index) => {
            return {
              index: index + 1,
              id: rowData.id,
              product: rowData.products !== null ? rowData.products : "",
              subCategory:
                rowData.products !== null &&
                  rowData.products.group !== null &&
                  rowData.products.group.subCategory !== null &&
                  rowData.products.group.subCategory.id
                  ? rowData.products.group.subCategory.id
                  : "",
              category:
                rowData.products !== null &&
                  rowData.products.group !== null &&
                  rowData.products.group.subCategory !== null &&
                  rowData.products.group.subCategory.category !== null &&
                  rowData.products.group.subCategory.category.id
                  ? rowData.products.group.subCategory.category.id
                  : "",
              categoryName:
                rowData.products !== null &&
                  rowData.products.group !== null &&
                  rowData.products.group.subCategory !== null &&
                  rowData.products.group.subCategory.category !== null &&
                  rowData.products.group.subCategory.category.name
                  ? rowData.products.group.subCategory.category.name
                  : "",
              categoryShortName:
                rowData.products !== null &&
                  rowData.products.group !== null &&
                  rowData.products.group.subCategory !== null &&
                  rowData.products.group.subCategory.category !== null &&
                  rowData.products.group.subCategory.category.shortName
                  ? rowData.products.group.subCategory.category.shortName
                  : "",
              categorySortOrder:
                rowData.products !== null &&
                  rowData.products.group !== null &&
                  rowData.products.group.subCategory !== null &&
                  rowData.products.group.subCategory.category !== null &&
                  rowData.products.group.subCategory.category.sortOrder
                  ? rowData.products.group.subCategory.category.sortOrder
                  : "",
              isActive:
                rowData.products !== null &&
                  rowData.products.group !== null &&
                  rowData.products.group.subCategory !== null &&
                  rowData.products.group.subCategory.category !== null &&
                  rowData.products.group.subCategory.category.isActive
                  ? rowData.products.group.subCategory.category.isActive
                  : "",
              insertDateTime:
                rowData.products !== null &&
                  rowData.products.group !== null &&
                  rowData.products.group.subCategory !== null &&
                  rowData.products.group.subCategory.category !== null &&
                  rowData.products.group.subCategory.category.insertDateTime
                  ? rowData.products.group.subCategory.category.insertDateTime
                  : "",

              taxPercentage:
                rowData.products !== null &&
                  rowData.products.taxPercentage !== null &&
                  rowData.products.taxPercentage.id
                  ? rowData.products.taxPercentage.id
                  : "",
              taxName:
                rowData.products !== null &&
                  rowData.products.taxPercentage !== null &&
                  rowData.products.taxPercentage.name
                  ? rowData.products.taxPercentage.name
                  : "",
              taxSgst:
                rowData.products !== null &&
                  rowData.products.taxPercentage !== null &&
                  rowData.products.taxPercentage.sgst
                  ? rowData.products.taxPercentage.sgst
                  : "",
              taxCgst:
                rowData.products !== null &&
                  rowData.products.taxPercentage !== null &&
                  rowData.products.taxPercentage.cgst
                  ? rowData.products.taxPercentage.cgst
                  : "",
              taxIgst:
                rowData.products !== null &&
                  rowData.products.taxPercentage !== null &&
                  rowData.products.taxPercentage.igst
                  ? rowData.products.taxPercentage.igst
                  : "",
              taxCess:
                rowData.products !== null &&
                  rowData.products.taxPercentage !== null &&
                  rowData.products.taxPercentage.cess
                  ? rowData.products.taxPercentage.cess
                  : "",
              hsnCode:
                rowData.products !== null &&
                  rowData.products.taxPercentage !== null &&
                  rowData.products.taxPercentage.hsnCode
                  ? rowData.products.taxPercentage.hsnCode
                  : "",
              taxInsertDateTime:
                rowData.products !== null &&
                  rowData.products.taxPercentage !== null &&
                  rowData.products.taxPercentage.insertDateTime
                  ? rowData.products.taxPercentage.insertDateTime
                  : "",
              stakeHolderUom:
                rowData.products !== null &&
                  rowData.products.stakeHolderUom !== null &&
                  rowData.products.stakeHolderUom.id
                  ? rowData.products.stakeHolderUom.id
                  : "",
              stakeHolderUom:
                rowData.products !== null &&
                  rowData.products.stakeHolderUom !== null &&
                  rowData.products.stakeHolderUom.id
                  ? rowData.products.stakeHolderUom.id
                  : "",
              productName:
                rowData.products !== null && rowData.products.name
                  ? rowData.products.name
                  : "",
              outletName:
                rowData.products !== null &&
                  rowData.products.stakeHolderUom !== null &&
                  rowData.products.stakeHolderUom.name
                  ? rowData.products.stakeHolderUom.name
                  : "",
              outletisActive:
                rowData.products !== null &&
                  rowData.products.stakeHolderUom !== null &&
                  rowData.products.stakeHolderUom.isActive
                  ? rowData.products.stakeHolderUom.isActive
                  : "",
              outletInsertDateTime:
                rowData.products !== null &&
                  rowData.products.stakeHolderUom !== null &&
                  rowData.products.stakeHolderUom.insertDateTime
                  ? rowData.products.stakeHolderUom.insertDateTime
                  : "",
              outletUom:
                rowData.products !== null &&
                  rowData.products.outletUom !== null &&
                  rowData.products.outletUom.id
                  ? rowData.products.outletUom.id
                  : "",
              outletUomName:
                rowData.products !== null &&
                  rowData.products.outletUom !== null &&
                  rowData.products.outletUom.name
                  ? rowData.products.outletUom.name
                  : "",
              outletUomIsActive:
                rowData.products !== null &&
                  rowData.products.outletUom !== null &&
                  rowData.products.outletUom.isActive
                  ? rowData.products.outletUom.isActive
                  : "",
              outletUomInsertDateTime:
                rowData.products !== null &&
                  rowData.products.outletUom !== null &&
                  rowData.products.outletUom.insertDateTime
                  ? rowData.products.outletUom.insertDateTime
                  : "",
              uomConversionValue:
                rowData.products !== null && rowData.products.uomConversionValue
                  ? rowData.products.uomConversionValue
                  : 0,
              productCode:
                rowData.products !== null && rowData.products.code
                  ? rowData.products.code
                  : "",

              shortName:
                rowData.products !== null && rowData.products.shortName
                  ? rowData.products.shortName
                  : "",
              weight:
                rowData.products !== null && rowData.products.weight
                  ? rowData.products.weight
                  : "",
              description:
                rowData.products !== null && rowData.products.description
                  ? rowData.products.description
                  : "",
              selfLife:
                rowData.products !== null && rowData.products.selfLife
                  ? rowData.products.selfLife
                  : "",
              image:
                rowData.products !== null && rowData.products.image
                  ? rowData.products.image
                  : "",
              sortOrder:
                rowData.products !== null && rowData.products.sortOrder
                  ? rowData.products.sortOrder
                  : "",
              isActive:
                rowData.products !== null && rowData.products.isActive
                  ? rowData.products.isActive
                  : "",
              insertDateTime:
                rowData.products !== null && rowData.products.insertDateTime
                  ? rowData.products.insertDateTime
                  : "",

              mrp:
                rowData.products !== null && rowData.products.mrp
                  ? rowData.products.mrp
                  : "",
              marginPercentage:
                rowData.marginPercentage !== null
                  ? (+rowData.marginPercentage).toFixed(2)
                  : 0,
              mrp: rowData.mrp !== null ? (+rowData.mrp).toFixed(2) : 0,

              qty: rowData.qty !== null ? rowData.qty : 0,
              rate: rowData.rate !== null ? (+rowData.rate).toFixed(2) : 0,
              total: rowData.total,
            };
          }),
        });
        this.toggle();
      } else {
        this.props.showNotification({ msg: atleastOne, severity: "error" });
      }
    }
  };
  checkValidationOnSubmit = () => {
    const { fieldData } = this.state;
    const mandatoryCheckErrors = mandatoryCheck({
      fieldData,
      fieldMeta: PlaceOrderTableJson.fieldMeta,
    });
    this.setState({
      formErrors: mandatoryCheckErrors.formErrors,
    });
    if (mandatoryCheckErrors.formValid) {
      const ValidationCheckErrors = ValidationCheck({
        fieldData,
        fieldMeta: PlaceOrderTableJson.fieldMeta,
      });
      this.setState({
        formErrors: ValidationCheckErrors.formErrors,
      });
      return ValidationCheckErrors.formValid;
    } else {
      return false;
    }
  };
  getOrder = () => {
    const { orderList, headerData, fieldData, remark } = this.state;
    console.log(orderList);
    console.log(headerData);
    const outletBillDetails = orderList.map((orderData) => {
      let editData = headerData.stockStakeHolderOrderDetails.filter(
        (row) => row.product.id == orderData.product.id
      );
      if (editData.length !== 0) {
        return {
          ...orderData,
          id: editData[0].id,
          uom: {
            id: orderData.outletUom,
          },
          inserteUserId: headerData.insertedUserId,
          product: orderData.product,
          qty: orderData.qty,
        };
      } else {
        return {
          ...orderData,
          uom: {
            id: orderData.outletUom,
          },
          inserteUserId: headerData.insertedUserId,
          product: orderData.product,
          qty: orderData.qty,
        };
      }
    });
    console.log(outletBillDetails);
    const dataToSave = {
      ...headerData,
      discountOnBillPer: fieldData.DiscountPercentage
        ? fieldData.DiscountPercentage
        : 0,
      stockStakeHolderOrderDetails: outletBillDetails,
      remark: remark,
    };
    console.log(dataToSave);
    swal({
      title: "Are you sure?",
      text: saveWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onSave(dataToSave);
      }
    });
  };
  // onSubmit = () => {
  //   const { orderHeaderList } = this.props;
  //   let error = false;
  //   const rowList = {
  //     ...orderHeaderList.placeOrderList,
  //     stockStakeHolderOrderDetails:
  //       orderHeaderList.placeOrderList.stockStakeHolderOrderDetails.map(
  //         (rowObj) => {
  //           if (rowObj.qty.toString() == "") {
  //             error = true;
  //           }
  //           rowObj = {
  //             ...rowObj,
  //             qtyError: rowObj.qty.toString() == "" ? 1 : 0,
  //           };
  //           return rowObj;
  //         }
  //       ),
  //   };
  //   this.props.setOrderList({ row: rowList });
  //   if (!error) {
  //     swal({
  //       title: "Are you sure?",
  //       text: saveWarningMsg,
  //       icon: "warning",
  //       buttons: true,
  //       dangerMode: true,
  //     }).then((willDelete) => {
  //       if (willDelete) {
  //         this.onSave(orderHeaderList.placeOrderList);
  //       }
  //     });
  //   }
  // };

  onSave = (beatNewObjectToSave) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      this.toggle();
      showLoador({ loador: true });
      apiPost({
        url: endpoint.stockStakeholderOrderHeader + "/edite",
        postBody: beatNewObjectToSave,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          showNotification({ msg: savemsg });
          window.location.replace("/place-order");
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
        return success;
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  onCancel = () => {
    this.props.navigate(-1);
  };
  setListDetails = ({ row }) => {
    const { orderHeaderList, setOrderList } = this.props;

    let data = {
      ...orderHeaderList.placeOrderList,
      stockStakeHolderOrderDetails: row,
    };

    setOrderList({ row: data });
  };
  onDataChange = (fieldName, newValue) => {
    if (fieldName == "DiscountPercentage") {
      let discountAmt = (this.state.total * +newValue) / 100;
      this.setState({
        totalAmt: +this.state.total - +discountAmt,
      });
    }
    const { fieldData } = this.state;
    const dataToSearch = {};
    const dataToReset = { ...this.state.fieldData };
    let listError = false;
    PlaceOrderTableJson.fieldMeta.map((currentField) => {
      if (currentField.getDate && currentField.dataKey === fieldName) {
        dataToReset[currentField.getDate] = newValue.add(365, "day");
      }
      if (currentField.dataKey === fieldName && currentField.getListId) {
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
          return null;
        });
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key == fieldName && dataToReset[key]) {
            delete dataToReset[currentField.dataKey];
          }
        });
      }
      if (
        !listError &&
        fieldData[currentField.dataKey] &&
        currentField.controlType != "datepicker" &&
        currentField.getListFrom == undefined
      ) {
        dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
      }
      return null;
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
    });
  };
  toggle = () => {
    this.setState({
      open: !this.state.open,
    });
  };
  // render() {
  //   const { DataToSet } = this.state;

  //   const { orderHeaderList } = this.props;
  //   return (
  //     <>
  //       <Paper
  //         sx={{
  //           p: 2,
  //           borderRadius: 0,
  //         }}
  //         elevation={0}
  //       >
  //         <DynamicFormWithoutSave
  //           getListById={this.getListById}
  //           showBackToList={true}
  //           showTitle={PlaceOrderTableJson.showTitle}
  //           screenTitle={"Edit Order Details"}
  //           DataToSet={DataToSet}
  //           apiBaseURL={PlaceOrderTableJson.apiBaseURL}
  //           showSaveBtnMain={false}
  //           showAddButton={false}
  //           showBackButton={true}
  //           onCancel={this.onCancel}
  //           padding={true}
  //           paddingTop={true}
  //           resetAfterSave={true}
  //         />
  //         <MaterialUITableWithTextField
  //           tableHead={[
  //             {
  //               title: "Sr.No.",
  //               name: "index",
  //               positionCenter: false,
  //               showInscreen: true,
  //               alignCenter: "center",
  //             },
  //             {
  //               title: "Product Name",
  //               name: "productName",
  //               positionCenter: false,
  //               showInscreen: true,
  //               alignCenter: "left",
  //             },

  //             {
  //               title: "UOM",
  //               name: "stakeHolderUom",
  //               positionCenter: false,
  //               showInscreen: true,
  //               alignCenter: "center",
  //             },
  //             {
  //               title: "Rate",
  //               name: "rate",
  //               positionCenter: false,
  //               showInscreen: true,
  //               alignCenter: "right",
  //             },

  //             {
  //               title: "Order Quantity",
  //               name: "qty",
  //               placeHolder: "Enter Order Quantity",
  //               textFieldError: "qtyError",
  //               error: "Order Quantity",
  //               textField: true,
  //               positionCenter: false,
  //               showInscreen: true,
  //               alignCenter: "left",
  //             },
  //             {
  //               title: "Taxable Amount",
  //               name: "taxableAmt",
  //               positionCenter: false,
  //               showInscreen: true,
  //               alignCenter: "right",
  //             },
  //             {
  //               title: "Total Amount",
  //               name: "totalAmt",
  //               positionCenter: false,
  //               showInscreen: true,
  //               alignCenter: "right",
  //             },
  //           ]}
  //           data={
  //             Object.keys(orderHeaderList.placeOrderList).length != 0
  //               ? orderHeaderList.placeOrderList.stockStakeHolderOrderDetails
  //               : []
  //           }
  //           setList={this.setListDetails}
  //           isActionColActive={false}
  //         />

  //         <br />
  //         <Grid
  //           item
  //           sm={12}
  //           xs={12}
  //           md={12}
  //           lg={12}
  //           rowSpacing={2}
  //           columnSpacing={{ xs: 12, sm: 6, md: 1 }}
  //           container
  //           justifyContent="right"
  //         >
  //           <ButtonCompo
  //             size="medium"
  //             type="submit"
  //             variant="contained"
  //             name="Cancel"
  //             onClick={() => this.props.navigate(-1)}
  //           />
  //           &nbsp;&nbsp;
  //           <ButtonCompo
  //             size="medium"
  //             type="submit"
  //             variant="contained"
  //             name="Update"
  //             onClick={this.onSubmit}
  //           />
  //         </Grid>
  //       </Paper>
  //     </>
  //   );
  // }

  changeHandler = (e) => {
    const { name, value } = e.target;
    if (name === "remark") {
      this.setState({ remark: value });
    }
  };

  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };
  getFilteredTableData = (tableData) => {
    const { searchValue } = this.state;
    return tableData.filter((currentRow) => {
      let isValid = false;
      let productName =
        currentRow.products != null ? currentRow.products.name : "-";
      let stakeHolderUom =
        currentRow.products.stakeHolderUom != null
          ? currentRow.products.stakeHolderUom.name
          : "-";
      let rate = currentRow.rate != null ? currentRow.rate : "-";
      let qty = currentRow.qty != null ? currentRow.qty : "-";
      if (
        productName &&
        productName.toLowerCase().includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      if (
        stakeHolderUom &&
        stakeHolderUom.toLowerCase().includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      if (rate && rate.toLowerCase().includes(searchValue.toLowerCase())) {
        isValid = true;
        return true;
      }
      // if (qty && qty.toLowerCase().includes(searchValue.toLowerCase())) {
      //   isValid = true;
      //   return true;
      // }
      return isValid;
    });
  };
  render() {
    const {
      dynamicMasterData,
      DataToSet,
      value,
      total,
      qty,
      totalAmt,
      open,
      orderList,
      formErrors,
      remark,
      fieldData,
      creditLimit,
      flag,
    } = this.state;
    const { category } = this.props;
    console.log(category.category);
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <DynamicFormWithoutValidation
            showBackToList={true}
            getListById={this.getListById}
            dynamicMasterData={dynamicMasterData}
            showTitle={PlaceOrderTableJson.showTitle}
            screenTitle={PlaceOrderTableJson.screenTitle}
            fieldMeta={PlaceOrderTableJson.fieldMeta}
            apiBaseURL={PlaceOrderTableJson.apiBaseURL}
            formErrors={formErrors}
            showSaveBtn={PlaceOrderTableJson.showSaveBtn}
            showSaveBtnMain={false}
            fieldData={fieldData}
            onDataChange={this.onDataChange}
            DataToSet={DataToSet}
          />

          {category.category.length != 0 && (
            <>
              <Tabs
                value={value}
                onChange={this.handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="scrollable force tabs example"
              >
                {category.category.map((catData) => {
                  return (
                    <Tab
                      label={
                        catData.category.name +
                        " ( " +
                        catData.productWithMarginAndMrps.length +
                        " )"
                      }
                    />
                  );
                })}
              </Tabs>
              {category.category.map((catData, index) => {
                return (
                  <TabPanel value={value} index={index}>
                    <LandingScreenHeader
                      screenTitle={""}
                      showSearchBox={true}
                      showTable={true}
                      showFilter={false}
                      showPdfDownload={false}
                      showExcelDownload={false}
                      onSearch={this.onSearch}
                    />
                    <PlacedOrderTableUpdate
                      total={total}
                      qty={qty}
                      tableHead={
                        flag
                          ?
                          [
                            {
                              title: "Sr.No.",
                              name: "index",
                              positionCenter: false,
                              showInscreen: true,
                              alignCenter: "center",
                            },
                            {
                              title: "Product Name",
                              name: "name",
                              positionCenter: false,
                              showInscreen: true,
                              alignCenter: "left",
                            },
                            {
                              title: "UOM",
                              name: "name",
                              positionCenter: true,
                              showInscreen: true,
                            },
                            {
                              title: "Rate",
                              name: "name",
                              positionCenter: false,
                              showInscreen: true,
                              alignCenter: "left",
                            },
                            {
                              title: "Product Stock",
                              name: "name",
                              positionCenter: false,
                              showInscreen: true,
                              alignCenter: "left",
                            },
                            {
                              title: "Quantity",
                              name: "target",
                              placeHolder: "Enter Target",
                              textFieldError: "targetErr",
                              positionCenter: false,
                              showInscreen: true,
                            },
                            {
                              title: "Total",
                              name: "name",
                              positionCenter: false,
                              showInscreen: true,
                              alignCenter: "left",
                            },
                          ] :
                          [
                            {
                              title: "Sr.No.",
                              name: "index",
                              positionCenter: false,
                              showInscreen: true,
                              alignCenter: "center",
                            },
                            {
                              title: "Product Name",
                              name: "name",
                              positionCenter: false,
                              showInscreen: true,
                              alignCenter: "left",
                            },
                            {
                              title: "UOM",
                              name: "name",
                              positionCenter: true,
                              showInscreen: true,
                            },
                            {
                              title: "Rate",
                              name: "name",
                              positionCenter: false,
                              showInscreen: true,
                              alignCenter: "left",
                            },
                            {
                              title: "Quantity",
                              name: "target",
                              placeHolder: "Enter Target",
                              textFieldError: "targetErr",
                              positionCenter: false,
                              showInscreen: true,
                            },
                            {
                              title: "Total",
                              name: "name",
                              positionCenter: false,
                              showInscreen: true,
                              alignCenter: "left",
                            },
                          ]}
                      // data={catData.productWithMarginAndMrps}
                      data={this.getFilteredTableData(
                        catData.productWithMarginAndMrps
                      )}
                      rowList={category.category}
                      setList={this.setCatList}
                      flag={flag}

                    />
                  </TabPanel>
                );
              })}
              <br />
              <DynamicDetails
                DataToSet={[
                  {
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 6,
                    label: "Credit Limit",
                    value: creditLimit,
                  },
                  {
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 6,
                    label: "Total Amount",
                    value: total.toFixed(2),
                  },
                  {
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 6,
                    label: "Total Quantity",
                    value: qty,
                  },
                  {
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 6,
                    label: "Balance",
                    value: creditLimit - +total.toFixed(2),
                  },
                ]}
              />
              <Grid container alignItems="center">
                <Grid item xs={12} md={2.5} sm={2.5}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: titleColor, marginLeft: 15 }}
                    label=" Add Comment :"
                  />
                </Grid>
                <Grid item xs={12} md={9.5} sm={9.5}>
                  <TextFieldCompo
                    placeholder="Add Comment"
                    color="primary"
                    name="remark"
                    size="small"
                    value={remark}
                    fullWidth
                    onChange={this.changeHandler}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  sx={{ justifyContent: "flex-end" }}
                >
                  <ButtonCompo
                    size="medium"
                    type="submit"
                    variant="contained"
                    name={"Proceed"}
                    onClick={this.submitHandler}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Paper>
        {open && (
          <SubmitOrderUpdate
            formData={{
              total: total,
              qty: qty,
              discount: fieldData.DiscountPercentage
                ? fieldData.DiscountPercentage
                : 0,
              totalAmt: totalAmt,
            }}
            columns={[
              {
                title: "Sr. No.",
                name: "index",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "center",
              },
              {
                title: "Product Name",
                name: "productName",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
                canSearch: true,
              },
              {
                title: "UOM",
                name: "outletName",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "center",
                canSearch: true,
              },

              {
                title: "Quantity",
                name: "qty",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "right",
                canSearch: true,
              },

              {
                title: "Rate",
                name: "rate",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "right",
                canSearch: true,
              },
              {
                title: "Total",
                name: "total",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "right",
                canSearch: true,
              },
            ]}
            open={open}
            handleCloses={this.toggle}
            rows={orderList}
            getOrder={this.getOrder}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  orderHeaderList: state.orderHeaderList,
  category: state.category,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getPlaceOrderHeaderById,
  setOrderList,
  setCategoryAndItem,
  getCategoryItemList,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PlaceOrderEdit);
