import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import { ButtonCompo } from "../../components/Comman/Button";
import SearchBar from "../../components/Search";
import moment from "moment";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { compose } from "@reduxjs/toolkit";
import { withRouter } from "../../components/withRouter";
import { connect } from "react-redux";

const OpeningManageDistributorTable = ({ passTheData, initialData,showNotification }) => {

  const [rows, setRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [search, setSearch] = useState("");

  // Effect to update rows when `initialData` changes
  useEffect(() => {
    if (initialData && Array.isArray(initialData)) {
      const formattedData = initialData.map((item) => ({
        id: item?.id,
        firmName:item?.firmName,
        date: null,
        totalAmt: "",
        type: "0",
        checked: false,
        touched: false,
        showError: false,
        stockStakeHolderId:item?.id,
        remark:""
      }));
      setRows(formattedData);
    }
  }, [initialData]); // Runs whenever initialData changes

  // Filter rows based on search input
  const filteredRows = rows.filter((row) =>
    row.firmName.toLowerCase().includes(search.toLowerCase())
  );

  const handleChange = (id, field, value) => {
    setRows((prevRows) =>
      prevRows.map((row) => {
        if (row.id === id) {
          const updatedRow = {
            ...row,
            [field]: value,
            touched: true,
            showError: false,
          };

          if (updatedRow.date && updatedRow.totalAmt !== "") {
            updatedRow.checked = true;
          } else if (!updatedRow.date && updatedRow.totalAmt === "") {
            updatedRow.checked = false;
          }
          return updatedRow;
        }
        return row;
      })
    );
  };

  const handleCheck = (id) => {
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.id === id ? { ...row, checked: !row.checked } : row
      )
    );
  };

  const handleSelectAll = () => {
    const newCheckedState = !selectAll;
    setSelectAll(newCheckedState);
    setRows((prevRows) =>
      prevRows.map((row) => ({ ...row, checked: newCheckedState }))
    );
  };

  const handleSubmit = () => {

    const isAnyChecked = rows.some((row) => row.checked); // Check if at least one checkbox is selected

    if (!isAnyChecked) {
        showNotification({
        msg: "Please select at least one record",
        severity: "error",
      });
        return;
      }
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.checked && (!row.date || row.totalAmt === "")
          ? { ...row, showError: true }
          : row
      )
    );

    const hasError = rows.some(
      (row) => row.checked && (!row.date || row.totalAmt === "")
    );
  
    if (!hasError) {
      const selectedRows = rows
        .filter((row) => row.checked)
        .map(({ id, ...rest }) => rest); 
      passTheData(selectedRows);
    }
  };

  return (
    <>
      {initialData.length > 0 && (
        <>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
      {/* Search bar placed at top right corner */}
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        sx={{ marginBottom: 2 }}
      >
        <SearchBar
          filteredRows={setSearch}
          searchValue={search}
          placeHolder="Search..."
        />
      </Grid>

      <TableContainer
        component={Paper}
        sx={{ maxHeight: 400, overflowY: "auto" }}
      >
        <Table>
          <TableHead
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 2, // Ensure header stays above the content
              backgroundColor: "white", // Ensure visibility
            }}
          >
            {" "}
            <TableRow>
              <TableCell>
                <Checkbox
                  checked={selectAll}
                  onChange={handleSelectAll}
                  sx={{
                    color: "white", // Ensures visibility in the blue header
                    "&.Mui-checked": {
                      color: "white", // Ensures color remains white when checked
                    },
                  }}
                />
              </TableCell>

              <TableCell
                align="center"
                sx={{ width: "30%", position: "sticky" }}
              >
                Name
              </TableCell>
              <TableCell
                align="center"
                sx={{ width: "20%", position: "sticky" }}
              >
                Date
              </TableCell>
              <TableCell
                align="center"
                sx={{ width: "20%", position: "sticky" }}
              >
                Amount
              </TableCell>
              <TableCell
                align="center"
                sx={{ width: "15%", position: "sticky" }}
              >
                Type
              </TableCell>
              <TableCell
                align="center"
                sx={{ width: "30%", position: "sticky" }}
              >
                Final Amount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows.length > 0 ? (
              filteredRows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <Checkbox
                      checked={row.checked}
                      onChange={() => handleCheck(row.id)}
                    />
                  </TableCell>
                  <TableCell>{row?.firmName || "NA"}</TableCell>
                  <TableCell>
                    <DatePicker
                      label=""
                      value={
                        row.date
                          ? dayjs(moment(row.date, "DD-MM-YYYY").toDate()) // Format correctly
                          : null
                      }
                      onChange={(newValue) =>
                        handleChange(
                          row.id,
                          "date",
                          newValue ? moment(newValue.toDate()).format("DD-MM-YYYY") : ""
                        )
                      }
                      format="DD-MM-YYYY" // Ensure the picker displays correctly
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          variant: "standard",
                          error: row.showError && !row.date,
                          helperText:
                            row.showError && !row.date ? "Required" : "",
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextFieldCompo
                      sx={{ marginTop: 10 }}
                      type="number"
                      value={row.totalAmt}
                      onChange={(e) =>
                        handleChange(row.id, "totalAmt", e.target.value)
                      }
                      errorText={row.showError && !row.totalAmt ? "Required" : ""}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <RadioGroup
                      row
                      value={row.type}
                      onChange={(e) =>
                        handleChange(row.id, "type", e.target.value)
                      }
                    >
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label="Credit"
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="Debit"
                      />
                    </RadioGroup>
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      color:
                        row.totalAmt && row.type === "1" ? "red" : "green",
                    }}
                  >
                    {row.totalAmt && !isNaN(parseFloat(row.totalAmt))
                      ? row.type === "0"
                        ? `+${parseFloat(row.totalAmt).toFixed(2)}`
                        : `-${parseFloat(row.totalAmt).toFixed(2)}`
                      : "0.00"}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <Typography variant="subtitle1" color="textSecondary">
                    No data available
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* {rows.some((row) => row.checked) && ( */}
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          container
          justifyContent="right"
          sx={{ marginTop: 1, padding: "16px" }} // ✅ Ensure padding is a valid number or string

        >
          <ButtonCompo
            size="medium"
            type="Submit"
            variant="contained"
            name="Submit"
            onClick={handleSubmit}
          />
        </Grid>
      {/* )} */}
    </LocalizationProvider>
        </>
      )}
</>
  );
};



const mapStateToProps = (state) => ({

});
const mapDispatchToProps = {
  showLoador,
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(OpeningManageDistributorTable);
