import React, { useState, memo, useCallback, useEffect, useMemo } from "react";
import { Grid, Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getYear } from "../../Slice/year.slice";
import { ProductWiseRUConfigJson } from "../../DynamicFormsJson/Transaction/productWiseRUConfigJson";
import {
    getRuWiseProductConfigurationById,
    setRuWiseProductConfigurationByIdDetails,
} from "../../Slice/ruWiseProductConfig.slice";
import {
    getAllActiveProductSortByBrand,
    setAllActiveProductSortByBrand,
} from "../../Slice/product.slice";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import DynamicFormWithoutValidation from "../../components/dynamicscreens/DynamicFormWithoutValidation";
import { withRouter } from "../../components/withRouter";
import { titleColor } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import {
    checkedListEmptyMsg,
    nameAlreadyExistMsg,
    nameAlreadyPresentMsg,
    noInternetMsg,
    qtyGreaterThanBillQtyMsg,
    saveFailedMsg,
    saveWarningMsg,
    savemsg,
    serverMsg,
    updateData,
    updateMsg,
} from "../../config/messageconstant";
import { apiGet, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { mandatoryCheck, ValidationCheck } from "../../config/ValidationCheck";
import MaterialUITableWithTextField from "../../components/Comman/RT/MaterialUITableWithTextField";
import { useNavigate, useParams } from "react-router-dom";

const MemoizedMaterialUITableWithTextField = React.memo(MaterialUITableWithTextField);

// Child component wrapped with React.memo to prevent re-renders
const ChildComponent = memo(({ onClick }) => {
    console.log("Child Component Rendered"); // Debugging log
    return <button onClick={onClick}>Click Me</button>;
});

const ProductWiseRUConfigFormForDisabledRU = ({
    showLoador,
    showNotification,
    getAllActiveProductSortByBrand,
    getYear,
    setAllActiveProductSortByBrand,
    getRuWiseProductConfigurationById,
    setRuWiseProductConfigurationByIdDetails,
    yearList,
    productList,
}) => {

    const [formErrors, setformErrors] = useState({});
    const [fieldData, setfieldData] = useState({});
    const [rowData, setRowData] = useState({});
    const [searchValue, setsearchValue] = useState('');
    const [ruConfigurationList, setruConfigurationList] = useState([]);
    const params = useParams();
    const navigate = useNavigate();

    const dynamicMasterData = useMemo(() => ({
        yearDetail: yearList.year
    }), [yearList.year]);


    useEffect(() => {
        (async () => {
            showLoador({ loador: true });
            await getYear().then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });

            showLoador({ loador: true });
            await getAllActiveProductSortByBrand().then(({ response, success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                } else {
                    setruConfigurationList(response)
                }
            });

            if (params.id) {
                getById(params.id);
            }
        })()
    }, [])


    const getById = async (id) => {
        showLoador({ loador: true });
        await getRuWiseProductConfigurationById({ id: id }).then(({ response, success }) => {
            showLoador({ loador: false });
            if (!success) {
                showNotification({ msg: serverMsg, severity: "error" });
            } else {
                let rowData = response;
                editData(rowData);
            }
        });
    };


    const onDataChange = async (fieldName, newValue) => {

        const dataToSearch = {};
        const dataToReset = { ...fieldData };
        let listError = false;

        if (fieldName == "year") {
            const updatedProductList = ruConfigurationList.map((dataObj) => ({
                ...dataObj,
                ruQty: 1,
                primaryQty: 1,
            }));
            setruConfigurationList(updatedProductList)
        }

        if (fieldName == "fromdate" && dataToReset.todate) {
            await getBill(
                newValue.format("DD-MM-YYYY"),
                fieldData.todate.format("DD-MM-YYYY")
            );
        }
        if (fieldName == "todate" && dataToReset.fromdate) {
            await getBill(
                fieldData.fromdate.format("DD-MM-YYYY"),
                newValue.format("DD-MM-YYYY")
            );
        }
        ProductWiseRUConfigJson.fieldMeta.map((currentField) => {
            if (currentField.getDate && currentField.dataKey === fieldName) {
                dataToReset[currentField.getDate] = newValue.add(365, "day");
            }
            if (currentField.dataKey === fieldName && currentField.getListId) {
                const masterKeyList = currentField.getListId.split(",");
                masterKeyList.map((key) => {
                    if (fieldName === key) {
                        dataToSearch[key] = newValue;
                    } else if (fieldData[key]) {
                        dataToSearch[key] = fieldData[key];
                    }
                    return null;
                });

                getListById(dataToSearch);
            }
            if (currentField.getListFrom) {
                const masterKeyList = currentField.getListFrom.split(",");
                masterKeyList.map((key) => {
                    if (key == fieldName && dataToReset[key]) {
                        delete dataToReset[currentField.dataKey];
                    }
                });
            }
            if (
                !listError &&
                fieldData[currentField.dataKey] &&
                currentField.controlType != "datepicker" &&
                currentField.getListFrom == undefined
            ) {
                dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
            }
            return null;
        });

        dataToReset[fieldName] = newValue;

        setfieldData(dataToReset)
    };

    const editData = (data) => {

        let newDataToSet = productList?.activeAllProductSortByBrand.map((dataObj) => {
            let filterData =
                data.retailUnitProductConfigurationDetails.length !== 0
                && data.retailUnitProductConfigurationDetails.find(
                    (row) => row.productId === dataObj.id
                )

            let dataObject = filterData
                ? {
                    ...filterData,
                    id: filterData.id,
                    productId: dataObj.id,
                    name: dataObj.name,
                    ruQty: filterData.ruQty,
                }
                : { ...dataObj };

            return dataObject;
        });


        setruConfigurationList(newDataToSet);

        setRowData(data);


        let fieldData = {
            name: data.name !== null ? data.name : "",
            year: data.yearId !== null ? data.yearId : "",
        }

        setfieldData(fieldData);
    };

    const setListDetails = useCallback(({ row }) => {
        setruConfigurationList(row);
    }, []);

    const onSubmitData = () => {

        if (checkValidationOnSubmit()) {

            let dataToSend = {};

            let error = false;


            const rowList = ruConfigurationList.map(
                (rowObj) => {
                    if (
                        (rowObj.ruQty.toString() == "") ||
                        (rowObj.primaryQty.toString() == "")
                    ) {
                        error = true;
                    }
                    rowObj = {
                        ...rowObj,
                        primaryQtyErr: rowObj.primaryQty.toString() == "" ? 1 : 0,
                        ruPerPrimaryQtyErr: rowObj.ruQty.toString() == "" ? 1 : 0,
                    };
                    return rowObj;
                }
            )

            setruConfigurationList(rowList);

            if (params.id) {
                dataToSend = {
                    id: params.id,
                    name: fieldData.name,
                    year: { id: fieldData.year },
                    retailUnitProductConfigurationDetails: ruConfigurationList.map((dataObj) => ({
                        id: dataObj.id,
                        product: { id: dataObj.productId },
                        uom: { id: dataObj.stakeHolderUomId },
                        primaryQty: +dataObj.primaryQty,
                        ruQty: +dataObj.ruQty,
                    })),
                };
            } else {
                dataToSend = {
                    name: fieldData.name,
                    year: { id: fieldData.year },
                    retailUnitProductConfigurationDetails: ruConfigurationList.map((dataObj) => ({
                        product: { id: dataObj.productId },
                        uom: { id: dataObj.stakeHolderUomId },
                        primaryQty: +dataObj.primaryQty,
                        ruQty: +dataObj.ruQty,
                    })),
                };
            }

            console.log(dataToSend);


            if (!error) {
                swal({
                    title: "Are you sure?",
                    text: params.id ? updateData : saveWarningMsg,
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                }).then((willDelete) => {
                    if (willDelete) {
                        callBeforeSaveMethod(dataToSend, rowData);
                    }
                });
            }

        }

    };


    const callBeforeSaveMethod = (dataToSave, editData) => {

        if (navigator.onLine) {
            if (
                Object.keys(editData).length == 0 ||
                (Object.keys(editData).length != 0 &&
                    editData.name != dataToSave.name)
            ) {

                showLoador({ loador: true });
                apiGet({
                    url: endpoint.ruProductConfigHeader + "/check-name-present-or-not?name=" + dataToSave.name,
                }).then(({ data, success }) => {
                    showLoador({ loador: false });
                    if (success) {
                        if (data.data.error) {
                            showNotification({
                                msg: nameAlreadyPresentMsg,
                                severity: "error",
                            });
                        } else {
                            onSave(dataToSave);
                        }
                    }
                });

            } else {
                onSave(dataToSave);
            }
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };



    const onSave = (dataToSave) => {
        if (navigator.onLine) {

            showLoador({ loador: true });
            apiPost({
                url: endpoint.ruProductConfigHeader,
                postBody: dataToSave,
            }).then(({ data, success }) => {
                showLoador({ loador: false });

                if (success) {
                    showNotification({ msg: savemsg });
                    navigate(-1);
                } else {
                    showNotification({ msg: saveFailedMsg, severity: "error" });
                }
            });


        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };


    const checkValidationOnSubmit = () => {
        const mandatoryCheckErrors = mandatoryCheck({
            fieldData,
            fieldMeta: ProductWiseRUConfigJson.fieldMeta,
        });
        setformErrors(mandatoryCheckErrors.formErrors);

        if (mandatoryCheckErrors.formValid) {
            const ValidationCheckErrors = ValidationCheck({
                fieldData,
                fieldMeta: ProductWiseRUConfigJson.fieldMeta,
            });
            setformErrors(ValidationCheckErrors.formErrors);

            return ValidationCheckErrors.formValid;
        } else {
            return false;
        }
    };





    const onSearch = (searchValue) => {
        setsearchValue(searchValue)
    };

    const filteredTableData = useMemo(() => {
        return ruConfigurationList.filter((currentRow) => {
            let isValid = false;
            let productName = currentRow.name || "-";
            let stakeHolderUomName = currentRow.stakeHolderUomName || "-";

            if (productName.toLowerCase().includes(searchValue.toLowerCase())) {
                isValid = true;
            }
            if (stakeHolderUomName.toLowerCase().includes(searchValue.toLowerCase())) {
                isValid = true;
            }
            return isValid;
        });
    }, [ruConfigurationList, searchValue]);



    const tableHeadConfig = useMemo(() => [
        {
            title: "Sr.No.",
            name: "index",
            positionCenter: false,
            showInscreen: true,
            alignCenter: "center",
        },
        {
            title: "Product Name",
            name: "name",
            positionCenter: false,
            showInscreen: true,
            alignCenter: "left",
        },
        {
            title: "Primary UOM",
            name: "stakeHolderUomName",
            positionCenter: false,
            showInscreen: true,
            alignCenter: "center",
        },
        {
            title: "Primary Quantity",
            name: "primaryQty",
            placeHolder: "Enter Primary Quantity",
            textFieldError: "primaryQtyErr",
            error: "Primary Quantity",
            textField: true,
            positionCenter: false,
            showInscreen: true,
            alignCenter: "right",
        },
        {
            title: "RU Quantity",
            name: "ruQty",
            positionCenter: false,
            showInscreen: true,
            alignCenter: "center",
        }
    ], []);


    return (
        <Paper
            sx={{
                padding: 2,
            }}
        >
            <DynamicFormWithoutValidation
                dynamicMasterData={dynamicMasterData}
                screenTitle={ProductWiseRUConfigJson.screenTitle}
                fieldMeta={ProductWiseRUConfigJson.fieldMeta}
                apiBaseURL={ProductWiseRUConfigJson.apiBaseURL}
                formErrors={formErrors}
                showSaveBtn={ProductWiseRUConfigJson.showSaveBtn}
                showSaveBtnMain={false}
                fieldData={fieldData}
                onDataChange={onDataChange}
                showBackToList={true}
            />
            <br />

            {console.log(filteredTableData)}
            <MemoizedMaterialUITableWithTextField
                tableHead={tableHeadConfig}
                data={
                    filteredTableData
                }
                searchTexfield={true}
                setList={setListDetails}
                isActionColActive={false}

            />
            <br />
            <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="right"
            >
                <ButtonCompo
                    size="medium"
                    type="Submit"
                    variant="contained"
                    name="Submit"
                    onClick={onSubmitData}
                />
            </Grid>
        </Paper>
    );
};


const mapStateToProps = (state) => ({
    yearList: state.year,
    productList: state.product,
    ruWiseConfigByIdList: state.ruProductConfigHeader,
});
const mapDispatchToProps = {
    showLoador,
    showNotification,
    getAllActiveProductSortByBrand,
    getYear,
    setAllActiveProductSortByBrand,
    getRuWiseProductConfigurationById,
    setRuWiseProductConfigurationByIdDetails,
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ProductWiseRUConfigFormForDisabledRU);
