import { Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import RTTable from "../../components/Comman/RT/RTTable";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import endpoint from "../../config/endpoints";
import {
  deleteFailedMsg,
  deleteWarningMsg,
  noInternetMsg,
  saveFailedMsg,
  savemsg,
  saveWarningMsg,
  serverMsg,
} from "../../config/messageconstant";
import { SalesTeamOrderConfigFormJSON } from "../../DynamicFormsJson/Transaction/SalesTeamOrderConfigForm";
import { getSalesTeamOrderConfigList } from "../../Slice/salesTeamOrderConfig.slice";
import {
  getSalesPersonListToConfig,
  getStockStakeholderListToConfig,
  getStockStakeHolderType,
} from "../../Slice/stockStakeHolderType.slice";
import { apiDelete, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";

class SalesTeamOrderConfigForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formErrors: {},
      fieldData: {},
      flag: false,
      dynamicMasterData: {
        salesPersonDetail:
          this.props.stockStakeholderList.salesPersonListToConfigList,
        stakeholderDetail:
          this.props.stockStakeholderList.stockStakeHolderType,
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.stockStakeholderList.salesPersonListToConfigList !==
      nextProps.stockStakeholderList.salesPersonListToConfigList
    ) {
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          salesPersonDetail:
            nextProps.stockStakeholderList.salesPersonListToConfigList,
        },
      });
    }
    if (this.props.stockStakeholderList !== nextProps.stockStakeholderList) {
      if (
        this.props.stockStakeholderList?.stockStakeHolderType !==
        nextProps.stockStakeholderList?.stockStakeHolderType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stakeholderDetail:
              nextProps.stockStakeholderList?.stockStakeHolderType,
          },
        });
      }
    }
  }

  getData = async (data) => {
    const {
      showLoador,
      showNotification,
      getSalesTeamOrderConfigList,
      getSalesPersonListToConfig,
      getStockStakeholderListToConfig,
      getStockStakeHolderType,
    } = this.props;
    if (navigator.onLine) {
      if (!data) {
        showLoador({ loador: true });
        await getSalesTeamOrderConfigList().then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
          }
        });
      }
      showLoador({ loador: true });
      await getSalesPersonListToConfig({
        stockStakeHolderTypeId: data ? data.salesPerson : "",
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
        }
      });

      showLoador({ loador: true });
      await getStockStakeHolderType({ userTypeIds: "2" }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
        }
      });

    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  };
  async componentDidMount() {
    this.getData();
  }

  onSearch = (data) => {
    const { flag } = this.state;
    let dataToSave = {
      stockStakeHolderType: {
        id: data.salesPerson,
      },
      orderToIds: data.stakeholderIds,
    };
    if (data.id) {
      dataToSave = {
        ...dataToSave,
        id: data.id,
      };
    }
    swal({
      title: "Are you sure?",
      text: saveWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onSave(dataToSave, data.id);
      }
    });
  };

  onSave = (dataToSave, id) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiPost({
        url: endpoint.salesTeamOrderConfig,
        postBody: dataToSave,
      }).then(({ data, success }) => {
        this.setState({ flag: id !== "" ? false : true, formErrors: {}, fieldData: {} });
        showLoador({ loador: false });
        if (success) {
          this.getData();
          showNotification({ msg: savemsg });
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  rowEdit = (rowData) => {
    const dataToSend = {
      salesPerson: rowData.stockStakeHolderType,
      stakeholderIds: rowData.orderToIds.split(","),
      id: rowData.id,
    };
    this.getData(dataToSend);
    this.setState({
      fieldData: dataToSend,
    });
  };

  rowDelete = (rowData) => {
    const { showLoador, showNotification } = this.props;
    swal({
      title: "Are you sure?",
      text: deleteWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (navigator.onLine) {
          showLoador({ loador: true });
          apiDelete({
            url: endpoint.salesTeamOrderConfig + "/" + rowData.id,
          }).then(({ data, success }) => {
            showLoador({ loador: false });
            if (success) {
              this.getData();
            } else {
              showNotification({ msg: deleteFailedMsg, severity: "error" });
            }
          });
        } else {
          showNotification({ msg: noInternetMsg, severity: "error" });
        }
      }
    });
  };

  getDataForDisabled = () => { };
  render() {
    const { dynamicMasterData, flag, formErrors, fieldData } = this.state;
    const { salesTeamOrderConfigList } = this.props;
    return (
      <>
        <Paper
          sx={{
            padding: 2,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            showSaveBtn={false}
            showSaveBtnMain={true}
            dynamicMasterData={dynamicMasterData}
            getListById={this.getListById}
            searchList={SalesTeamOrderConfigFormJSON.searchList}
            screenTitle={SalesTeamOrderConfigFormJSON.screenTitle}
            fieldMeta={SalesTeamOrderConfigFormJSON.fieldMeta}
            showCancel={SalesTeamOrderConfigFormJSON.showCancel}
            showBackToList={SalesTeamOrderConfigFormJSON.showBackToList}
            apiBaseURL={SalesTeamOrderConfigFormJSON.apiBaseURL}
            showSaveNextBtn={false}
            saveBtnText={"Submit"}
            onSave={this.onSearch}
            resetAfterSave={flag}
            setFieldData={fieldData}
          />

          <br />
          <RTTable
            showPegination={false}
            columns={[
              { name: "index", title: "Sr. No.", align: "center" },
              { name: "salesPersonName", title: "Sales Person" },
              { name: "stakeHolderName", title: "Stock Holder Name" },
              { name: "action", title: "Action" },
            ]}
            hiddenColumnNames={[]}
            tableData={salesTeamOrderConfigList.configList}
            showEditIcon={true}
            rowEdit={this.rowEdit}
            rowDelete={this.rowDelete}
            showDeleteIcon={true}
            deleteConditionName=""
            editConditionName=""
          />
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  stockStakeholderList: state.stockStakeHolderType,
  salesTeamOrderConfigList: state.salesTeamOrderConfig,
});
const mapDispatchToProps = {
  showLoador,
  showNotification,
  getSalesPersonListToConfig,
  getStockStakeholderListToConfig,
  getStockStakeHolderType,
  getSalesTeamOrderConfigList,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SalesTeamOrderConfigForm);
