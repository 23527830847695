import VisibilityIcon from "@mui/icons-material/Visibility";
import { Grid, Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { importDataJson } from "../../DynamicFormsJson/MastersJSON/importData";
import { getAreaByTaluka, getAreaByUserType } from "../../Slice/area.slice";
import { getBeatByArea } from "../../Slice/beat.slice";
import { getDistrictByZone } from "../../Slice/district.slice";
import { getRegionNew } from "../../Slice/region.slice";
import { getStateByRegion } from "../../Slice/state.slice";
import { getStockStakeHolderType } from "../../Slice/stockStakeHolderType.slice";
import { getTalukaByDistrict } from "../../Slice/taluka.slice";
import { getZoneByState } from "../../Slice/zone.slice";
import AttachFile from "../../components/Comman/AttachFile";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
import { MyComponentWithIconProps } from "../../components/Comman/IconButton";
import { LabelCompo } from "../../components/Comman/Label";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import { titleColor } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import {
  exportmsg,
  noInternetMsg,
  saveFailedMsg,
  serverMsg,
} from "../../config/messageconstant";
import { apiPost, apiPostForDownload, apiPostForImage } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { downloadExcel } from "../PDF/PDFDownload";

class ImportData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: "0",
      salesTeamId: "",
      stakeholderId: "",
      areaIds: "",
      beatIds: "",
      isLog: true,
      moduleObjDataObj: {},
      dynamicMasterData: {
        regionDetail: this.props.regionList?.region,
        stateDetail: this.props.stateList?.stateByRegion,
        zoneDetail: this.props.zoneList?.zoneByState,
        districtDetail: this.props.districtList?.districtByZone,
        talukaDetail: this.props.talukaList?.talukaByDistrict,
        areaDetail: this.props.areaList?.areaByTaluka,
        salesTeamDetail: this.props.stakeHolderTypeList?.stockStakeHolderType,
        beatDetail: this.props.beatList.beat,
      },
      optionList: [
        {
          id: "0",
          name: "Outlet",
        },
        {
          id: "1",
          name: "Sales-team",
        },
        {
          id: "2",
          name: "Stakeholder",
        },
        {
          id: "3",
          name: "Bill",
        },
        {
          id: "4",
          name: "Product Language",
        },
        {
          id: "5",
          name: "Beat Language",
        },
        {
          id: "6",
          name: "Outlet Language",
        },
        {
          id: "7",
          name: "Group",
        },
      ],

      fileName: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.regionList !== nextProps.regionList) {
      if (this.props.regionList?.region !== nextProps.regionList?.region) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            regionDetail: nextProps.regionList?.region,
          },
        });
      }
    }
    if (this.props.stateList !== nextProps.stateList) {
      if (
        this.props.stateList?.stateByRegion !==
        nextProps.stateList?.stateByRegion
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stateDetail: nextProps.stateList?.stateByRegion,
          },
        });
      }
    }
    if (this.props.zoneList !== nextProps.zoneList) {
      if (
        this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            zoneDetail: nextProps.zoneList?.zoneByState,
          },
        });
      }
    }
    if (this.props.districtList !== nextProps.districtList) {
      if (
        this.props.districtList?.districtByZone !==
        nextProps.districtList?.districtByZone
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            districtDetail: nextProps.districtList?.districtByZone,
          },
        });
      }
    }
    if (this.props.talukaList !== nextProps.talukaList) {
      if (
        this.props.talukaList?.talukaByDistrict !==
        nextProps.talukaList?.talukaByDistrict
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            talukaDetail: nextProps.talukaList?.talukaByDistrict,
          },
        });
      }
    }
    if (this.props.areaList !== nextProps.areaList) {
      if (
        this.props.areaList?.areaByTaluka !== nextProps.areaList?.areaByTaluka
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.areaByTaluka,
          },
        });
      }
    }
    if (this.props.stakeHolderTypeList !== nextProps.stakeHolderTypeList) {
      if (
        this.props.stakeHolderTypeList?.stockStakeHolderType !==
        nextProps.stakeHolderTypeList?.stockStakeHolderType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            salesTeamDetail:
              nextProps.stakeHolderTypeList?.stockStakeHolderType,
          },
        });
      }
    }
    if (this.props.beatList !== nextProps.beatList) {
      if (this.props.beatList?.beat !== nextProps.beatList?.beat) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            beatDetail: nextProps.beatList?.beat,
          },
        });
      }
    }
  }

  async componentDidMount() {
    const { getRegionNew, showLoador, showNotification } = this.props;
    if (navigator.onLine) {

      const moduleObjData = localStorage.getItem("moduleObj");
      const moduleObjDataObj = moduleObjData != null ? JSON.parse(moduleObjData) : {};

      this.setState({ moduleObjDataObj });

      showLoador({ loador: true });
      getRegionNew().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  getListById = async (data) => {
    const {
      getStateByRegion,
      getZoneByState,
      getDistrictByZone,
      getTalukaByDistrict,
      getAreaByTaluka,
      showLoador,
      showNotification,
      getBeatByArea,
    } = this.props;
    const { option } = this.state;
    if (navigator.onLine) {
      console.log(data);

      if (data.region && data.region != "") {
        showLoador({ loador: true });
        await getStateByRegion({ regionIds: data.region }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.state && data.state != "") {
        showLoador({ loador: true });
        await getZoneByState({ stateIds: data.state }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.zone && data.zone != "") {
        showLoador({ loador: true });
        await getDistrictByZone({ zoneIds: data.zone }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.district && data.district != "") {
        showLoador({ loador: true });
        await getTalukaByDistrict({ districtIds: data.district }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.taluka && data.taluka != "") {
        showLoador({ loador: true });
        await getAreaByTaluka({ talukaIds: data.taluka }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.area && data.area != "") {
        // if (option == "6") {
        //   showLoador({ loador: true });
        //   await getBeatByArea({ areaId: data.area }).then(({ success }) => {
        //     showLoador({ loador: false });
        //     if (!success) {
        //       showNotification({ msg: serverMsg, severity: "error" });
        //     }
        //   });
        // }
        this.setState({
          areaIds: data.area,
        });
      }
      if (data.salesTeamId && data.salesTeamId != "") {
        this.setState({
          salesTeamId: data.salesTeamId,
        });
      }
      if (data.beat && data.beat != "") {
        this.setState({
          beatIds: data.beat,
        });
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onAutocompleteChange = () => (name, newValue) => {
    const { showLoador, getStockStakeHolderType, showNotification } =
      this.props;
    if (newValue != null) {
      this.setState({
        [name]: newValue,
        fileName: "",
      });
      if (newValue == "1") {
        if (navigator.onLine) {
          showLoador({ loador: true });
          getStockStakeHolderType({
            userTypeIds: "3,4",
          }).then(({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            } else {
            }
          });
        } else {
          showNotification({
            msg: noInternetMsg,
            severity: "error",
          });
        }
      }
      if (newValue == "2") {
        if (navigator.onLine) {
          showLoador({ loador: true });
          getStockStakeHolderType({
            userTypeIds: "2",
          }).then(({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            } else {
            }
          });
        } else {
          showNotification({
            msg: noInternetMsg,
            severity: "error",
          });
        }
      }
    }
  };

  onExportHandler = () => {
    const { option, areaIds, beatIds, salesTeamId } = this.state;
    const { showLoador } = this.props;
    showLoador({ loador: true });
    apiPostForDownload({
      url:
        option == "0"
          ? endpoint.exportDataNew + "/outlet?areaIds=" + areaIds
          : option == "1"
            ? endpoint.exportDataNew +
            "/sales-team?stockStakeHolderTypeId=" +
            salesTeamId +
            "&areaIds=" +
            areaIds
            : option == "2"
              ? endpoint.exportDataNew +
              "/stake-holder?stockStakeHolderTypeId=" +
              salesTeamId
              : option == "3"
                ? endpoint.stockStakeholderBillHeader + "/export-excel"
                : option == "4"
                  ? endpoint.exportDataNew + "/language-json-product"
                  : option == "5"
                    ? endpoint.exportDataNew + "/language-json-beat?areaBeatId=" + areaIds
                    : option == "6"
                      ? endpoint.exportDataNew + "/language-json-outlet?areaBeatId=" + areaIds
                      : option == "7"
                        ? endpoint.exportDataNew + "/language-json-group"
                        : "",
      postBody: {},
    }).then(({ data, success }) => {
      showLoador({ loador: false });
      const blb = new Blob([data], { type: "text/plain" });
      const reader = new FileReader();
      reader.addEventListener("loadend", (e) => {
        if (success) {
          var blob = new Blob([data], {
            type: "application/xls",
          });
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "Report_" + new Date() + ".xls";
          link.click();
          showNotification({
            msg: "Outlet " + exportmsg,
          });
        }
      });
      reader.readAsText(blb);
    });
  };

  backToList = () => {
    this.props.navigate(-1);
  };

  changeH = (fileName, name, index) => {
    this.setState({
      ...this.state,
      fileName: fileName,
    });
  };

  onClickViewPreview = (e, row) => {
    e.stopPropagation();
    const url = URL.createObjectURL(row);
    window.open(url);
  };

  onClearHandler = (event, value) => {
    this.setState({
      ...this.state,
      fileName: "",
    });
  };

  onSubmit = () => {
    const { fileName, salesTeamId, option, moduleObjDataObj, isLog } = this.state;
    const { showNotification, showLoador } = this.props;

    const formData = new FormData();
    if (option == "0") {
      formData.append("outletFile", fileName);
    } else if (option == "1") {
      formData.append("salesTeamFile", fileName);
    } else if (option == "2") {
      formData.append("stakeHolderFile", fileName);
    } else if (option == "3") {
      formData.append("stockStakeHolderBillFile", fileName);
    } else if (
      option == "4" ||
      option == "5" ||
      option == "6" ||
      option == "7"
    ) {
      formData.append("languageFile", fileName);
    } else {
    }

    showLoador({ loador: true });
    apiPostForImage({
      url:
        option == "0"
          ? endpoint.importData + "/outlet"
          : option == "1"
            ? endpoint.importData +
            "/sales-team?stockStakeHolderTypeId=" +
            salesTeamId
            : option == "2"
              ? endpoint.importData +
              "/stake-holder?stockStakeHolderTypeId=" +
              salesTeamId
              : option == "3"
                ? endpoint.stockStakeholderBillHeader + "/import-excel"
                : option == "4"
                  ? endpoint.importData + "/language-json-product"
                  : option == "5"
                    ? endpoint.importData + "/language-json-beat"
                    : option == "6"
                      ? endpoint.importData + "/language-json-outlet"
                      : option == "7"
                        ? endpoint.importData + "/language-json-group"
                        : "",
      postBody: formData,
    }).then(({ data, success }) => {
      showLoador({ loador: false });
      if (success) {

        if (isLog) {
          let updatedJsonToSend = JSON.stringify(fileName.name);

          let dataToSaveForMLog = {
            module: {
              moduleId: moduleObjDataObj.moduleId
            },
            subModule: {
              subModuleId: moduleObjDataObj.subModuleId
            },
            operationType: 1,
            type: 2,
            operationPerformId: option,
            updatedJson: updatedJsonToSend,
          }

          showLoador({ loador: true });
          apiPost({
            url: endpoint.mlog,
            postBody: dataToSaveForMLog,
          }).then(({ success }) => {
            showLoador({ loador: false });
            if (success) {
            } else {
              showNotification({
                msg: saveFailedMsg,
                severity: "error",
              });
            }
          });
        }

        showNotification({
          msg: data.data.message,
        });
      }
    });
  };

  render() {
    const { dynamicMasterData, option, optionList, fileName } = this.state;
    return (
      <>
        <Paper
          sx={{
            borderRadius: 2,
            p: 2,
          }}
          elevation={0}
        >
          <LandingScreenHeader
            screenTitle={"Import Data"}
            showSaveBtnMain={false}
            showAddButton={false}
            showBackButton={false}
            onCancel={this.backToList}
          />
          <br />
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={6}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={"Select"}
              />
              <AutoComplete
                keyColName={"id"}
                value={option}
                name="option"
                options={optionList}
                onChange={this.onAutocompleteChange()}
              />
            </Grid>
          </Grid>
          <DynamicFormWithoutSave
            dynamicMasterData={dynamicMasterData}
            getListById={this.getListById}
            searchList={[]}
            showSaveBtn={false}
            showSaveBtnMain={false}
            showTitle={false}
            screenTitle={importDataJson.screenTitle}
            fieldMeta={
              option == "0"
                ? importDataJson.fieldMetaForOutlet
                : option == "1"
                  ? importDataJson.fieldMetaForSalesTeam
                  : option == "2"
                    ? importDataJson.fieldMetaForStakeholder
                    : option == "5"
                      ? importDataJson.fieldMetaForOutlet
                      : option == "6"
                        ? importDataJson.fieldMetaForBeat
                        : []
            }
            showCancel={importDataJson.showCancel}
            apiBaseURL={importDataJson.apiBaseURL}
            showSaveNextBtn={importDataJson.showSaveNextBtn}
          />
          <Grid container xs={12} sm={12} md={12}>
            <Grid item xs={12} sm={2} md={2} lg={2}>
              <LabelCompo
                className="text-black"
                style={{
                  fontSize: 16,
                  color: titleColor,
                }}
                label={"Select file"}
              />
            </Grid>
            <Grid item xs={12} sm={7} md={7} lg={7}>
              <AttachFile name={"file"} fileName={this.changeH} />
              &nbsp; {fileName !== "" ? fileName.name : ""}
              &nbsp;{" "}
              {fileName !== "" ? (
                <MyComponentWithIconProps
                  statusImage={VisibilityIcon}
                  color="primary"
                  fontSize="medium"
                  title="View"
                  onClick={(e) => this.onClickViewPreview(e, fileName)}
                />
              ) : (
                ""
              )}
            </Grid>
            {fileName !== "" ? (
              <Grid
                item
                xs={12}
                sm={2}
                md={2}
                lg={2}
                container
                justifyContent="right"
              >
                <ButtonCompo
                  size="small"
                  type="button"
                  variant="outlined"
                  name="Clear"
                  onClick={(event) => this.onClearHandler(event, option)}
                // fullWidth={true}
                />
              </Grid>
            ) : (
              <Grid item xs={12} sm={2} md={2} lg={2}></Grid>
            )}

            {fileName !== "" ? (
              <Grid
                item
                xs={12}
                sm={1}
                md={1}
                lg={1}
                container
                justifyContent="right"
              >
                <ButtonCompo
                  size="small"
                  type="button"
                  variant="contained"
                  name="Import"
                  onClick={this.onSubmit}
                // fullWidth={true}
                />
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                sm={1}
                md={1}
                lg={1}
                container
                justifyContent="right"
              >
                <ButtonCompo
                  size="small"
                  type="button"
                  variant="contained"
                  name="Export"
                  onClick={this.onExportHandler}
                // fullWidth={true}
                />
              </Grid>
            )}
          </Grid>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  stakeHolderTypeList: state.stockStakeHolderType,
  areaList: state.area,
  regionList: state.region,
  stateList: state.states,
  zoneList: state.zone,
  districtList: state.district,
  talukaList: state.taluka,
  beatList: state.beat,
});
const mapDispatchToProps = {
  showLoador,
  showNotification,
  getStockStakeHolderType,
  downloadExcel,
  getAreaByUserType,
  getZoneByState,
  getRegionNew,
  getDistrictByZone,
  getTalukaByDistrict,
  getAreaByTaluka,
  getStateByRegion,
  getBeatByArea,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ImportData);
