import { Paper } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import CheckBoxSelectionTable from "../../components/Comman/RT/CheckBoxSelectionTable";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import endpoint from "../../config/endpoints";
import {
  checkedListEmptyMsg,
  noInternetMsg,
  saveFailedMsg, savemsg,
  saveWarningMsg,
  serverMsg,
  updateMsg
} from "../../config/messageconstant";
import { OutletMovingForStockHolderFooterJson } from "../../DynamicFormsJson/MastersJSON/outletMovingForStockholderFooterJson";
import { OutletMovingJsonForStockHolder } from "../../DynamicFormsJson/MastersJSON/outletMovingForStockholderJson";
import { getAreaByTaluka } from "../../Slice/area.slice";
import { getBeatByArea } from "../../Slice/beat.slice";
import { getDistrictByZone } from "../../Slice/district.slice";
import {
  getOutletByBeatIdsPost,
  getOutletByStockStakeHolderAndBeats,
  setOutletByStockStakeHolder,
} from "../../Slice/outlet.slice";
import { getRegionNew } from "../../Slice/region.slice";
import { getStockHolderByBeats } from "../../Slice/StackHolder.slice";
import { getStateByRegion } from "../../Slice/state.slice";
import { getTalukaByDistrict } from "../../Slice/taluka.slice";
import { getZoneByState } from "../../Slice/zone.slice";
import { apiGet, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import OutletMovingPopUp from "../Outlet Moving/OutletMovingPopUp";

class OutletMovingForStockHolder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        regionDetail: this.props.regionList?.region,
        stateDetail: this.props.stateList?.stateByRegion,
        zoneDetail: this.props.zoneList?.zoneByState,
        districtDetail: this.props.districtList?.districtByZone,
        talukaDetail: this.props.talukaList?.talukaByDistrict,
        areaDetail: this.props.areaList?.areaByTaluka,
        stockHolderDetail: this.props.stockHolderList?.stackholder,
      },
      userType: "",
      openPopUp: false,
      selection: [],
      beatIds: [],
      isLog: true,
      moduleObjDataObj: {},
      stockStakeHolderIds: [],
    };
  }
  async componentDidMount() {
    const { getRegionNew, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {

      this.props.setOutletByStockStakeHolder({ row: [] });

      const moduleObjData = localStorage.getItem("moduleObj");
      const moduleObjDataObj =
        moduleObjData != null ? JSON.parse(moduleObjData) : {};

      this.setState({ moduleObjDataObj });

      const userTypeId = localStorage.getItem("userTypeId");

      if (userTypeId == 1 || userTypeId == 6) {
        this.setState({
          userType: true,
        });
      }

      showLoador({ loador: true });
      getRegionNew().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      ;
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.regionList !== nextProps.regionList) {
      if (this.props.regionList?.region !== nextProps.regionList?.region) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            regionDetail: nextProps.regionList?.region,
          },
        });
      }
    }
    if (this.props.stateList !== nextProps.stateList) {
      if (
        this.props.stateList?.stateByRegion !==
        nextProps.stateList?.stateByRegion
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stateDetail: nextProps.stateList?.stateByRegion,
          },
        });
      }
    }
    if (this.props.zoneList !== nextProps.zoneList) {
      if (
        this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            zoneDetail: nextProps.zoneList?.zoneByState,
          },
        });
      }
    }
    if (this.props.districtList !== nextProps.districtList) {
      if (
        this.props.districtList?.districtByZone !==
        nextProps.districtList?.districtByZone
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            districtDetail: nextProps.districtList?.districtByZone,
          },
        });
      }
    }
    if (this.props.talukaList !== nextProps.talukaList) {
      if (
        this.props.talukaList?.talukaByDistrict !==
        nextProps.talukaList?.talukaByDistrict
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            talukaDetail: nextProps.talukaList?.talukaByDistrict,
          },
        });
      }
    }
    if (this.props.areaList !== nextProps.areaList) {
      if (
        this.props.areaList?.areaByTaluka !== nextProps.areaList?.areaByTaluka
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.areaByTaluka,
          },
        });
      }
    }

    if (this.props.beatList !== nextProps.beatList) {
      if (this.props.beatList?.beat !== nextProps.beatList?.beat) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            beatDetail: nextProps.beatList?.beat,
          },
        });
      }
    }

    if (this.props.stockHolderList !== nextProps.stockHolderList) {
      if (
        this.props.stockHolderList?.stackholder !==
        nextProps.stockHolderList?.stackholder
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stockHolderDetail: nextProps.stockHolderList?.stackholder,
          },
        });
      }
    }
  }

  onSearchData = async (data) => {
    const { getOutletByStockStakeHolderAndBeats, showLoador, showNotification } =
      this.props;
    this.setState({
      stockStakeHolderIds: data.stackHolder.split(","),
    });
    if (navigator.onLine) {
      let dataToSend = {
        stockStakeHolderIds: data.stackHolder.split(","),
        beatIds: data.beat.split(","),
      };

      showLoador({ loador: true });
      await getOutletByStockStakeHolderAndBeats({
        parametersJson: dataToSend,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  getListById = async (data) => {
    const {
      getStateByRegion,
      getZoneByState,
      getDistrictByZone,
      getTalukaByDistrict,
      getAreaByTaluka,
      getBeatByArea,
      getStockHolderByBeats,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      console.log(data);
      if (data.region && data.region != "") {
        showLoador({ loador: true });
        await getStateByRegion({ regionIds: data.region }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.state && data.state != "") {
        showLoador({ loador: true });
        await getZoneByState({ stateIds: data.state }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.zone && data.zone != "") {
        showLoador({ loador: true });
        await getDistrictByZone({ zoneIds: data.zone }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.district && data.district != "") {
        showLoador({ loador: true });
        await getTalukaByDistrict({ districtIds: data.district }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.taluka && data.taluka != "") {
        showLoador({ loador: true });
        await getAreaByTaluka({ talukaIds: data.taluka }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.area && data.area != "") {
        showLoador({ loador: true });
        await getBeatByArea({ areaId: data.area }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.beat && data.beat != "") {
        let beat = {
          beatIds: data.beat,
        };
        showLoador({ loador: true });
        await getStockHolderByBeats({ beatIds: beat }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  getSelectedList = (e) => {
    this.setState({
      selection: e,
    });
    const rowList = this.props.outletList?.outlets.map((rowData) => {
      const filterData = e.filter((id) => rowData.id == id);
      return { ...rowData, checked: filterData.length != 0 ? true : false };
    });
    this.props.setOutletByStockStakeHolder({ row: rowList });
  };

  handleCloses = () => {
    this.setState({ openPopUp: false });
  };

  onSubmit = (data) => {

    const { showLoador, showNotification, outletList } = this.props;
    const { selection } = this.state;


    if (navigator.onLine) {


      if (selection.length === 0) {
        showNotification({
          msg: checkedListEmptyMsg,
          severity: "error",
        });
      } else {

        let selectedOutletList =
          outletList?.outlets.filter((row) => row.checked).map((outletObj) => {
            return outletObj.id;
          });

        let outletIdsJson = {
          outletIds: selectedOutletList
        }

        console.debug(data.stockHolderFooter)
        swal({
          title: "Are you sure?",
          text: saveWarningMsg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            showLoador({ loador: true });
            apiPost({
              url:
                endpoint.outlet + "/update-stock-stake-holder?stockStakeHolderId=" +
                data.stockHolderFooter,
              postBody: outletIdsJson,
            }).then(({ data, success }) => {
              showLoador({ loador: false });
              if (success) {
                showNotification({ msg: updateMsg });
                window.location.replace("/outlet-moving-for-stockholder");
              } else {
                showNotification({
                  msg: serverMsg, severity: "error"
                });
              }
            });
          }
        });
      }
    }
    else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  setDynamicMasterData = () => {
    const { stockStakeHolderIds } = this.state;

    let stockHolderDetail = this.state.dynamicMasterData.stockHolderDetail
      ? this.state.dynamicMasterData.stockHolderDetail
      : [];

    let list = [];

    stockHolderDetail.map((rowData) => {
      let filterData = stockStakeHolderIds.filter((row) => row == rowData.id);
      if (filterData == 0) {
        list.push(rowData);
      }
    });
    return {
      stockHolderDetail: list,
    };
  };

  rowStatus = () => {
  }

  render() {
    const { outletList } = this.props;
    const { dynamicMasterData, userType, selection, openPopUp } = this.state;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            showBackToList={false}
            dynamicMasterData={dynamicMasterData}
            showTitle={true}
            getListById={this.getListById}
            screenTitle={OutletMovingJsonForStockHolder.screenTitle}
            fieldMeta={
              userType == 1
                ? OutletMovingJsonForStockHolder.fieldMeta
                : OutletMovingJsonForStockHolder.fieldMetaWithoutHierarchy
            }
            showSaveBtnMain={false}
            showSaveNextBtn={false}
            showSaveBtn={true}
            saveBtnText={"Search"}
            onSave={this.onSearchData}
            padding={true}
            paddingTop={true}
          />

          <CheckBoxSelectionTable
            selection={selection}
            onSelectionChange={this.getSelectedList}
            columns={[
              {
                title: "Sr.No.",
                name: "index",
                showInExcel: true,
                align: "center",
              },
              {
                title: "Outlet Name",
                name: "firmName",
                formDataKey: "firmName",
                canSearch: true,
              },
              {
                title: "Beat Name",
                name: "beatName",
                formDataKey: "beatName",
                canSearch: true,
                alignCenter: "left",
              },
              {
                title: "Stock Holder",
                name: "stockStakeHolderFirmName",
                formDataKey: "stockStakeHolderFirmName",
                canSearch: true,
                alignCenter: "left",
              },
              {
                title: "Channel Name",
                name: "channelName",
                formDataKey: "channelName",
                canSearch: true,
                alignCenter: "left",
              },
              {
                title: "Sub Channel Name",
                name: "subChannelName",
                formDataKey: "subChannelName",
                canSearch: true,
                alignCenter: "left",
              },
              {
                title: "Village",
                name: "villageCity",
                formDataKey: "villageCity",
                canSearch: true,
              },
              {
                title: "Status",
                name: "isActive",
                formDataKey: "isActive",
                canSearch: true,
              },
            ]}
            rows={outletList?.outlets}
            isActionColActive={false}
            rowStatus={this.rowStatus}
          />
          <br />
          {outletList?.outlets.length != 0 && (
            <>
              <br />
              <DynamicFormWithoutSave
                showBackToList={false}
                dynamicMasterData={this.setDynamicMasterData()}
                showSaveBtn={false}
                showTitle={OutletMovingForStockHolderFooterJson.showTitle}
                screenTitle={OutletMovingForStockHolderFooterJson.screenTitle}
                fieldMeta={OutletMovingForStockHolderFooterJson.fieldMeta}
                showCancel={false}
                showSaveNextBtn={false}
                saveBtnText={"Submit"}
                onSave={this.onSubmit}
              />
            </>
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  stackHolderByAreaList: state.stockholder,
  areaList: state.area,
  regionList: state.region,
  stateList: state.states,
  zoneList: state.zone,
  districtList: state.district,
  talukaList: state.taluka,
  beatList: state.beat,
  outletList: state.outlet,
  stockHolderList: state.stackholder,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getZoneByState,
  getDistrictByZone,
  getTalukaByDistrict,
  getAreaByTaluka,
  getStateByRegion,
  getRegionNew,
  getBeatByArea,
  getOutletByBeatIdsPost,
  getOutletByStockStakeHolderAndBeats,
  setOutletByStockStakeHolder,
  getStockHolderByBeats,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OutletMovingForStockHolder);
