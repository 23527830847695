import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import endpoint from "../../config/endpoints";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import { paymentListJSON } from "../../DynamicFormsJson/Transaction/paymentList";
import { getPaymentReciept } from "../../Slice/paymentReciept.slice";
import { getStockStackHolders } from "../../Slice/StackHolder.slice";
import { PaymentListColumns } from "../../tableColumns/table-columns";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { downloadF } from "../PDF/PDFDownload";
class PaymentListForStockist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      dynamicMasterData: {
        stakeholdersDetail: this.props.stakeholdersList.stockStakeHolders,
      },
    };
  }

  async componentDidMount() {
    const { getStockStackHolders, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getStockStackHolders().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.stakeholdersList !== nextProps.stakeholdersList) {
      if (
        this.props.stakeholdersList?.stockStakeHolders !==
        nextProps.stakeholdersList?.stockStakeHolders
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stakeholdersDetail: nextProps.stakeholdersList?.stockStakeHolders,
          },
        });
      }
    }
  }
  onSearchList = async (data) => {
    const { getPaymentReciept, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      this.setState({
        formData: data,
      });
      showLoador({ loador: true });
      await getPaymentReciept({
        holderId: data.stakeholderId,
        fromDate: data.fromDate,
        toDate: data.toDate,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  rowPDF = (rowData) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      downloadF({
        url:
          endpoint.paymentReceipt +
          "/report-by-id?documentType=1" +
          "&id=" +
          rowData.id,
        ext: "pdf",
        openNewTab: true,
      }).then((response) => {
        showLoador({ loador: false });
        if (!response) {
          this.props.showNotification({
            msg: serverMsg,
            severity: "error",
          });
        } else {
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  render() {
    const { paymentList, getPaymentReciept } = this.props;
    const { dynamicMasterData } = this.state;
    return (
      <>
        <DynamicMainScreen
          showViewButtonInTable={false}
          dynamicMasterData={dynamicMasterData}
          searchList={paymentListJSON.searchList}
          onSearchData={this.onSearchList}
          formPathView={paymentListJSON.formPathView}
          formPath={paymentListJSON.formPath}
          screenTitle={paymentListJSON.screenTitle}
          fieldMeta={paymentListJSON.fieldMeta}
          showPdfDownload={false}
          showExcelDownload={false}
          isActiveURL={paymentListJSON.isActiveURL}
          tableColumnsToFilter={paymentListJSON.tableColumnsToFilter}
          tableColumns={PaymentListColumns}
          tableData={paymentList.paymentRecieptData}
          getTableData={getPaymentReciept}
          apiBaseURL={paymentListJSON.apiBaseURL}
          callApi={false}
          getTableDataById={true}
          showSearchBox={false}
          rowPDF={this.rowPDF}
          showPdf={true}
          showSearch={false}
          showEditIcon={false}
          deleteConditionName="deleteStatus"
          deleteConditionValue="0"
          showPegination={false}

        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  stakeholdersList: state.stackholder,
  paymentList: state.paymentReciept,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getStockStackHolders,
  getPaymentReciept,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentListForStockist);
