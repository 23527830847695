import endpoint from "../../config/endpoints";

export const zoneJson = {
  formPath: "/zone-form",
  apiBaseURL: endpoint.zone,
  changeExcel: endpoint.exportData,
  screenTitle: "Zone",
  showAddButton: true,
  showPdfDownload: true,
  pdfFileName: "Zone",
  showExcelDownload: true,
  excelFileName: "Zone",
  tableColumnsToFilter: [
    {
      columnDisplayName: "Name",
      columnKeyName: "zoneName",
      isChecked: true,
    },
    {
      columnDisplayName: "Region",
      columnKeyName: "regionName",
      isChecked: true,
    },
    {
      columnDisplayName: "Area Count",
      columnKeyName: "areaCount",
      isChecked: true,
    },
    {
      columnDisplayName: "Beat Count",
      columnKeyName: "beatCount",
      isChecked: true,
    },
    {
      columnDisplayName: "Outlet Count",
      columnKeyName: "outletCount",
      isChecked: true,
    },
    {
      columnDisplayName: "Sort Order Number",
      columnKeyName: "sortOrder",
      isChecked: true,
    },
  ],
  fieldMeta: [
    {
      label: "State",
      controlType: "autocomplete",
      placeHolder: "Select State",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stateDetail",
      dataKey: "state",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Name",
      controlType: "textfield",
      placeHolder: "Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      autoFocus: true,
      dataKey: "name",
      isMandatory: true,
      uniqueValidation: true,
    },
    {
      label: "Short Name",
      controlType: "textfield",
      placeHolder: "Short Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "shortName",
      isMandatory: true,
    },
    {
      label: "Sort Order No",
      controlType: "textfield",
      placeHolder: "Sort Order Number",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "sortOrder",
      isMandatory: true,
    },
  ],
  fieldMetaForSalesZoneAssignation: [
    {
      label: "Stock Holder Type",
      controlType: "multiSelect",
      placeHolder: "Select Stock Holder Type",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stockHolderTypeDetail",
      dataKey: "stockHolderType",
      getListId: "stockHolderType",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Stock Holder Name",
      controlType: "multiSelect",
      placeHolder: "Select Stock Holder Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stockHolderNameDetail",
      dataKey: "stockHolderName",
      getListId: "stockHolderName",
      isRootLevelKey: true,
      isMandatory: true,
    },
  ],
};
