import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import { imageUrl } from "../../config/Url";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ClaimViewPopup = ({
  handleCloses,
  open,
  columns,
  rows,
  viewHeaderData,
  DataToSet,
  fixedCalculation,
}) => {
  const handleClose = () => {
    handleCloses();
  };

  const rowViewData = (rowData) => {
    if (rowData.attachFile) {
      window.open(imageUrl + rowData.attachFile, "_blank");
    }
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="lg"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Claim Details
        </BootstrapDialogTitle>

        <DynamicDetails DataToSet={DataToSet} />

        <DialogContent dividers>
          <DynamicTable
            data={rows}
            tableHead={columns}
            showPegination={false}
            isActionColActive={fixedCalculation == 0 ? true : false}
            showView={fixedCalculation == 0 ? true : false}
            rowViewData={rowViewData}
          />
          <br />
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ClaimViewPopup);
