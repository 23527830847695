// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-wrapper {
    /* height:max-content ; */
    height: fit-content;
    margin: 10px 50px;
    filter: drop-shadow(-1px 5px 3px #ccc);
    display: flex;
    flex: 1 1;
  }
  .info-wrapper {
    margin-top: 1px;
  }
  .map-details {
    text-align: center;
    font-size: 1.2em;
  }
  .map-details span {
    font-weight: bold;
  }
  .search-input {
    font-size: 1.2em;
    width: 80%;
  }`, "",{"version":3,"sources":["webpack://./src/Pages/Request For Distributor/maincss.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,iBAAiB;IACjB,sCAAsC;IACtC,aAAa;IACb,SAAO;EACT;EACA;IACE,eAAe;EACjB;EACA;IACE,kBAAkB;IAClB,gBAAgB;EAClB;EACA;IACE,iBAAiB;EACnB;EACA;IACE,gBAAgB;IAChB,UAAU;EACZ","sourcesContent":[".main-wrapper {\n    /* height:max-content ; */\n    height: fit-content;\n    margin: 10px 50px;\n    filter: drop-shadow(-1px 5px 3px #ccc);\n    display: flex;\n    flex: 1;\n  }\n  .info-wrapper {\n    margin-top: 1px;\n  }\n  .map-details {\n    text-align: center;\n    font-size: 1.2em;\n  }\n  .map-details span {\n    font-weight: bold;\n  }\n  .search-input {\n    font-size: 1.2em;\n    width: 80%;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
