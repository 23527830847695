import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  category: [],
  categoryByOutlet: [],
  subCategory: [],
  categoryForStakeholder: [],
  categoryOutletAndDateWise: [],
  product: [],
  categoryNew: [],
  categtoryItem: {},
};
let URL = endpoints.category;
let URL1 = endpoints.categoryLatest;
let productRateConfigurationDetailsOutletURL =
  endpoints.productRateConfigurationDetailsOutlet;

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    categorySuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        category: row,
      };
    },

    categoryNewSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        categoryNew: row,
      };
    },

    categoryByOutletSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        categoryByOutlet: row,
      };
    },
    categoryForStakeHolderSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        categoryForStakeholder: row,
      };
    },
    categoryOutletAndDateWiseSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        categoryOutletAndDateWise: row,
      };
    },
    subCategorySuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        subCategory: row,
      };
    },
    productSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        product: row,
      };
    },
    categtoryItemSucess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        categtoryItem: row,
      };
    },
    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  categorySuccess,
  categoryNewSuccess,
  subCategorySuccess,
  productSuccess,
  resetState,
  categoryByOutletSuccess,
  categoryForStakeHolderSuccess,
  categtoryItemSucess,
  categoryOutletAndDateWiseSuccess,
} = categorySlice.actions;

export default categorySlice.reducer;

export const getCategory = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?sort={"sortOrder": "ASC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        const row = content.map((categoryObject, index) => {
          let categoryData = {
            index: index + 1,
            id: categoryObject.id === null ? "" : categoryObject.id,
            name: categoryObject.name === null ? "" : categoryObject.name,
            categoryName:
              categoryObject.name !== null && categoryObject.shortName !== null ?
                categoryObject.name + " ( " + categoryObject.shortName + " )" : "",
            shortName:
              categoryObject.shortName === null ? "" : categoryObject.shortName,
            subCategoryCount:
              categoryObject.subCategoryCount === null
                ? ""
                : categoryObject.subCategoryCount,
            productCount:
              categoryObject.productCount === null
                ? ""
                : categoryObject.productCount,
            sortOrder:
              categoryObject.sortOrder === null ? "" : categoryObject.sortOrder,
            isActive:
              categoryObject.isActive === null ? "" : categoryObject.isActive,
          };
          return categoryData;
        });
        dispatch(categorySuccess({ row }));
        dispatch(categoryNewSuccess({ row: content }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};


export const getCategoryByProductRateStructure = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/get-by-product-structure" + '?sort={"sortOrder": "ASC"}',
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((categoryObject, index) => {
          let categoryData = {
            index: index + 1,
            id: categoryObject.id === null ? "" : categoryObject.id,
            name: categoryObject.name === null ? "" : categoryObject.name,
            categoryName:
              categoryObject.name !== null && categoryObject.shortName !== null ?
                categoryObject.name + " ( " + categoryObject.shortName + " )" : "",
            shortName:
              categoryObject.shortName === null ? "" : categoryObject.shortName,
            subCategoryCount:
              categoryObject.subCategoryCount === null
                ? ""
                : categoryObject.subCategoryCount,
            productCount:
              categoryObject.productCount === null
                ? ""
                : categoryObject.productCount,
            sortOrder:
              categoryObject.sortOrder === null ? "" : categoryObject.sortOrder,
            isActive:
              categoryObject.isActive === null ? "" : categoryObject.isActive,
          };
          return categoryData;
        });
        dispatch(categorySuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};


export const getCategoryByAssinedBrand = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/by-assigned-brand" + '?sort={"sortOrder": "ASC"}',
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((categoryObject, index) => {
          let categoryData = {
            index: index + 1,
            id: categoryObject.id === null ? "" : categoryObject.id,
            name: categoryObject.name === null ? "" : categoryObject.name,
            categoryName:
              categoryObject.name !== null && categoryObject.shortName !== null ?
                categoryObject.name + " ( " + categoryObject.shortName + " )" : "",
            shortName:
              categoryObject.shortName === null ? "" : categoryObject.shortName,
            subCategoryCount:
              categoryObject.subCategoryCount === null
                ? ""
                : categoryObject.subCategoryCount,
            productCount:
              categoryObject.productCount === null
                ? ""
                : categoryObject.productCount,
            sortOrder:
              categoryObject.sortOrder === null ? "" : categoryObject.sortOrder,
            isActive:
              categoryObject.isActive === null ? "" : categoryObject.isActive,
          };
          return categoryData;
        });
        dispatch(categorySuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};


export const setCategory =
  ({ row }) =>
    async (dispatch) => {
      dispatch(categoryNewSuccess({ row }));
    };

export const getCategoryByOutlet =
  ({ outletId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/by-outlet?outletId=" + outletId,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((categoryObject, index) => {
              let categoryData = {
                index: index + 1,
                id: categoryObject.id === null ? "" : categoryObject.id,
                name: categoryObject.name === null ? "" : categoryObject.name,
                categoryName:
                  categoryObject.name + " ( " + categoryObject.shortName + " )",
                shortName:
                  categoryObject.shortName === null
                    ? ""
                    : categoryObject.shortName,
                sortOrder:
                  categoryObject.sortOrder === null
                    ? ""
                    : categoryObject.sortOrder,
                isActive:
                  categoryObject.isActive === null ? "" : categoryObject.isActive,
              };
              return categoryData;
            });
            dispatch(categoryByOutletSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getCategoryForStakeholder = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/for-stake-holder",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((categoryObject, index) => {
          let categoryData = {
            index: index + 1,
            id: categoryObject.id === null ? "" : categoryObject.id,
            name: categoryObject.name === null ? "" : categoryObject.name,
            categoryName:
              categoryObject.name + " ( " + categoryObject.shortName + " )",
            shortName:
              categoryObject.shortName === null ? "" : categoryObject.shortName,
            sortOrder:
              categoryObject.sortOrder === null ? "" : categoryObject.sortOrder,
            isActive:
              categoryObject.isActive === null ? "" : categoryObject.isActive,
          };
          return categoryData;
        });
        dispatch(categoryForStakeHolderSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getCategoryForStakeholderByStockHolderId =
  ({ stockStakeHolderId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL +
            "/for-stake-holder-by-stake-holder?stockStakeHolderId=" +
            stockStakeHolderId,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((categoryObject, index) => {
              let categoryData = {
                index: index + 1,
                id: categoryObject.id === null ? "" : categoryObject.id,
                name: categoryObject.name === null ? "" : categoryObject.name,
                categoryName:
                  categoryObject.name + " ( " + categoryObject.shortName + " )",
                shortName:
                  categoryObject.shortName === null
                    ? ""
                    : categoryObject.shortName,
                sortOrder:
                  categoryObject.sortOrder === null
                    ? ""
                    : categoryObject.sortOrder,
                isActive:
                  categoryObject.isActive === null ? "" : categoryObject.isActive,
              };
              return categoryData;
            });
            dispatch(categoryForStakeHolderSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getCategoryOutletAndDateWise =
  ({ orderDate, outletId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL +
            "/for-batch-billing?orderDate=" +
            orderDate +
            "&outletId=" +
            outletId,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((categoryObject, index) => {
              let categoryData = {
                index: index + 1,
                id: categoryObject.id === null ? "" : categoryObject.id,
                name: categoryObject.name === null ? "" : categoryObject.name,
                categoryName:
                  categoryObject.name + " ( " + categoryObject.shortName + " )",
                shortName:
                  categoryObject.shortName === null
                    ? ""
                    : categoryObject.shortName,
                sortOrder:
                  categoryObject.sortOrder === null
                    ? ""
                    : categoryObject.sortOrder,
                isActive:
                  categoryObject.isActive === null ? "" : categoryObject.isActive,
              };
              return categoryData;
            });
            dispatch(categoryOutletAndDateWiseSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getSubCategoryByCategoryId =
  ({ categoryId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/" + categoryId + "/get-sub-category",
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((subCategoryObject, index) => {
              let categoryData = {
                index: index + 1,
                id: subCategoryObject.id === null ? "" : subCategoryObject.id,
                name:
                  subCategoryObject.name === null ? "" : subCategoryObject.name,
                subCategoryName:
                  subCategoryObject.name +
                  " ( " +
                  subCategoryObject.shortName +
                  " )",
                categoryName:
                  subCategoryObject.category != null &&
                    subCategoryObject.category.name
                    ? subCategoryObject.category.name
                    : "",
                category:
                  subCategoryObject.category != null &&
                    subCategoryObject.category.id
                    ? subCategoryObject.category.id
                    : "",
                shortName:
                  subCategoryObject.shortName === null
                    ? ""
                    : subCategoryObject.shortName,
                sortOrder:
                  subCategoryObject.sortOrder === null
                    ? ""
                    : subCategoryObject.sortOrder,
              };
              return categoryData;
            });
            dispatch(subCategorySuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getProductByCategoryId =
  ({ catId, marginPercentage }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/" + catId + "/get-product-with-current-mrp",
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((productObject, index) => {
              productObject = {
                index: index + 1,
                code: productObject.code === null ? "" : productObject.code,
                description:
                  productObject.description === null
                    ? ""
                    : productObject.description,
                productId: productObject.id === null ? "" : productObject.id,
                image: productObject.image === null ? "" : productObject.image,
                isActive:
                  productObject.isActive === null ? "" : productObject.isActive,
                name: productObject.name === null ? "" : productObject.name,
                itemName:
                  productObject.itemName === null ? "" : productObject.itemName,
                mrp:
                  productObject.mrp === null ? "" : productObject.mrp.toFixed(2),
                outletUom:
                  productObject.outletUom === null ? "" : productObject.outletUom,
                selfLife:
                  productObject.selfLife === null ? "" : productObject.selfLife,
                shortName:
                  productObject.shortName === null ? "" : productObject.shortName,
                sortOrder:
                  productObject.sortOrder === null ? "" : productObject.sortOrder,
                stakeHolderUom:
                  productObject.stakeHolderUom === null
                    ? ""
                    : productObject.stakeHolderUom,
                subCategory:
                  productObject.subCategory === null
                    ? ""
                    : productObject.subCategory,
                taxPercentage:
                  productObject.taxPercentage === null
                    ? ""
                    : productObject.taxPercentage,
                uomConversionValue:
                  productObject.uomConversionValue === null
                    ? ""
                    : productObject.uomConversionValue,
                weight: productObject.weight === null ? "" : productObject.weight,
                marginPercentage: marginPercentage,
                marginPercentageErr: 0,
                rate:
                  productObject.mrp !== null
                    ? (
                      productObject.mrp -
                      (productObject.mrp * marginPercentage) / 100
                    ).toFixed(2)
                    : "",
              };
              return productObject;
            });
            dispatch(productSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getProductByOutletId =
  ({ outletId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            productRateConfigurationDetailsOutletURL +
            "/get-with-not-inserted?productRateConfigurationOutletId=" +
            outletId,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((productObject, index) => {
              productObject = {
                index: index + 1,
                id: productObject.id === null ? "" : productObject.id,
                name:
                  productObject.product !== null && productObject.product.name
                    ? productObject.product.name
                    : "",
                productId:
                  productObject.product !== null && productObject.product.id
                    ? productObject.product.id
                    : "",
                mrp: productObject.mrp.toFixed(2),
                marginPercentage:
                  productObject.marginPercentage !== null
                    ? productObject.marginPercentage
                    : "",
                marginPercentageErr: 0,
                rate:
                  productObject.mrp !== null &&
                    productObject.marginPercentage !== null
                    ? (
                      productObject.mrp -
                      (productObject.mrp * productObject.marginPercentage) / 100
                    ).toFixed(2)
                    : "",
              };
              return productObject;
            });
            dispatch(productSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };
export const setProductList =
  ({ row }) =>
    async (dispatch) => {
      const productList = row.map((productObj) => {
        return {
          ...productObj,
          rate:
            productObj.mrp !== null
              ? (
                +productObj.mrp -
                (+productObj.mrp * +productObj.marginPercentage) / 100
              ).toFixed(2)
              : "",
        };
      });
      dispatch(productSuccess({ row: productList }));
    };

export const setCategoryAndItem =
  ({ row }) =>
    async (dispatch) => {
      dispatch(categorySuccess({ row }));
    };

export const setCategoryAndItemForCreditNote =
  ({ row }) =>
    async (dispatch) => {
      console.log(row);
      let rowLit = row.map((rowObj) => {
        return {
          ...rowObj,
          productWithMarginAndMrps: rowObj.productWithMarginAndMrps.map(
            (product) => {
              return {
                ...product,
                totalQty:
                  +product.primaryQty * product.products.uomConversionValue +
                  +product.secondaryQty,
                total:
                  (+product.primaryQty * product.products.uomConversionValue +
                    +product.secondaryQty) *
                  (+product.rate / product.products.uomConversionValue),
                qtyError:
                  +product.primaryQty * product.products.uomConversionValue +
                    +product.secondaryQty >
                    +product.secondaryClosingQty
                    ? true
                    : false,
              };
            }
          ),
        };
      });
      dispatch(categorySuccess({ row: rowLit }));
    };
export const getCategoryItem = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/items-with-margin-and-mrp-for-stake-holder",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((categoryObject, index) => {
          let categoryData = {
            index: index + 1,
            id: categoryObject.id === null ? "" : categoryObject.id,
            name: categoryObject.name === null ? "" : categoryObject.name,
            categoryName:
              categoryObject.name + " ( " + categoryObject.shortName + " )",
            shortName:
              categoryObject.shortName === null ? "" : categoryObject.shortName,
            sortOrder:
              categoryObject.sortOrder === null ? "" : categoryObject.sortOrder,
            isActive:
              categoryObject.isActive === null ? "" : categoryObject.isActive,
          };
          return categoryData;
        });
        dispatch(categoryForStakeHolderSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
// export const getCategoryItemList = () => async (dispatch) => {
//   const { data, success } = await apiGet({
//     url: URL + "/items-with-margin-and-mrp-for-stake-holder",
//   });
//   if (success) {
//     const categoryData = data.data;
//     const categoryItemData = {
//       categoryAndItems:
//         categoryData.categoryAndItems === null
//           ? []
//           : categoryData.categoryAndItems.map((categoryObject) => {
//               const { category, flag, productWithMarginAndMrps } =
//                 categoryObject;
//               const products = productWithMarginAndMrps.map((product) => {
//                 const { products, marginPercentage, mrp } = product;
//                 return {
//                   id: products.id,
//                   qty: 0,
//                   total: 0,
//                   marginPercentage,
//                   mrp,
//                 };
//               });
//               return {
//                 category,
//                 flag,
//                 products,
//               };
//             }),
//     };
//     dispatch(categtoryItemSucess({ row: categoryItemData }));
//     return { response: categoryItemData, success };
//   } else {
//     console.error("Error: ", data.errorMessage.message);
//     return { response: {}, success: false };
//   }
// };

export const getCategoryItemList = () => async (dispatch) => {
  const { data, success } = await apiGet({
    url: URL1 + "/and-items-with-margin-and-mrp-for-stake-holder",
  });
  if (success) {
    console.log(data);
    let categoryData = {
      errorMessage: data.data.errorMessage,
      categoryAndItems: data.data.categoryAndItems.map((categoryObject) => {
        categoryObject = {
          ...categoryObject,
          productWithMarginAndMrps: categoryObject.productWithMarginAndMrps.map(
            (productObject) => {
              productObject = {
                ...productObject,
                total: 0,
                qty: 0,
              };
              return productObject;
            }
          ),
        };
        return categoryObject;
      }),
    };

    dispatch(categtoryItemSucess({ row: categoryData }));
    return { data: categoryData, success };
  } else {
    return { data: {}, success: false };
  }
};
