import {
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { compose } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { purchaseReturnJson } from "../../DynamicFormsJson/Transaction/purchaseReturn";
import {
  getCategoryForStakeholder,
  getCategoryItemList,
  setCategoryAndItem,
} from "../../Slice/category.slice";
import { getProductByCategoryForStakeHolder } from "../../Slice/product.slice";
import {
  getGRNHeaderAndDetailByIdUpdated,
  setNewRequestGRNDetails,
} from "../../Slice/requestGRN.slice";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { withRouter } from "../../components/withRouter";
import {
  noInternetMsg,
  saveFailedMsg,
  saveWarningMsg,
  savemsg,
  updateData,
} from "../../config/messageconstant";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import PurchaseReturnByBillWise from "../Purchase Return/PurchaseReturnByBillWise";
import PurchaseReturnForm from "../Purchase Return/PurchaseReturnForm";
import PurchaseReturnTableForBillWise from "./PurchaseReturnTableUpdate";
import { ButtonCompo } from "../../components/Comman/Button";
import endpoint from "../../config/endpoints";
import { apiPost } from "../../utils/api_service";
import { LabelCompo } from "../../components/Comman/Label";
import { titleColor } from "../../config/ColorObj";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

class RequestGRNEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
      remark: "",
      dynamicMasterData: {
        categoryDetail: this.props.category.categoryForStakeholder,
        productDetail: this.props.productList.productByCategoryForStakeholder,
      },
      headerData: {},
      DataToSet: [],
      formErrors: {},
      fieldData: {},
      DataToSet: [],
      value: "0",
      total: 0,
      qty: 0,
      totalAmt: 0,
      open: false,
      orderList: [],
      editDetails: [],
    };
  }

  setCatList = ({ row }) => {
    const { setNewRequestGRNDetails } = this.props;
    setNewRequestGRNDetails({ row: row });
  };

  async componentDidMount() {
    const {
      getGRNHeaderAndDetailByIdUpdated,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      if (this.props.params.id) {
        showLoador({ loador: true });
        await getGRNHeaderAndDetailByIdUpdated({
          grnGvnHeaderId: this.props.params.id,
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
          } else {
            this.setState({
              headerData: response,
              editDetails:
                response.grnGvnDetails.length !== 0
                  ? response.grnGvnDetails
                  : [],
              remark: response.remark !== null ? response.remark : "",
              DataToSet: [
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "GRN Number",
                  value: response.grnGvnNo !== null ? response.grnGvnNo : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Date",
                  value: response.date !== null ? response.date : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Taxable Amount",
                  value:
                    response.taxableAmt !== null ? response.taxableAmt : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Total Amount",
                  value: response.totalAmt !== null ? response.totalAmt : "",
                },

                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Total Tax",
                  value:
                    response.totalAmt !== null && response.taxableAmt !== null
                      ? (+response.totalAmt - +response.taxableAmt).toFixed(2)
                      : "",
                },
              ],
            });
          }
        });

      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  componentWillReceiveProps(nextProps) { }
  handleChange = (event, newValue) => {
    this.setState({
      value: newValue,
    });
  };
  submitHandler = () => {
    const { requestGRNList } = this.props;
    const { remark } = this.state;


    const updatedList = requestGRNList.requestGRNHeaderDetails.map((listData) => {

      return {
        ...listData,
        mrp: listData.mrp,
        rate: listData.rate,
        secondaryQty: listData.totalQty,
        batchCode: listData.batchCode,
        billDetailId: listData.billDetailId,
        hsnCode: listData.product.taxPercentage.hsnCode,
      };
    });


    let newHeaderData = {
      ...requestGRNList.requestGRNHeaderDetailsData,
      remark: remark,
      grnGvnDetails: updatedList,
    };

    swal({
      title: "Are you sure?",
      text: updateData,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onSave(newHeaderData);
      }
    });
  };

  onSave = (dataToSave) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      this.toggle();
      showLoador({ loador: true });
      apiPost({
        url: endpoint.grnGVNHeader + "/update",
        postBody: dataToSave,
      }).then(({ data, success }) => {
        if (success) {
          showLoador({ loador: false });
          this.props.navigate("/request-grn-list");
          showNotification({ msg: savemsg });
        } else {
          showLoador({ loador: false });
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  toggle = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  changeHandler = (e) => {
    const { name, value } = e.target;
    if (name === "remark") {
      this.setState({ remark: value });
    }
  };

  rowDelete = (rowData) => { };

  onCancel = () => {
    window.replace.location("/request-grn-list");
  };
  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  getFilteredTableData = (tableData) => {
    const { searchValue } = this.state;
    return tableData.filter((currentRow) => {
      let isValid = false;
      let productName =
        currentRow.product != null ? currentRow.product.name : "-";
      let stakeHolderUom =
        currentRow.product.stakeHolderUom != null
          ? currentRow.product.stakeHolderUom.name
          : "-";
      if (
        productName &&
        productName.toLowerCase().includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      if (
        stakeHolderUom &&
        stakeHolderUom.toLowerCase().includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };
  render() {
    const { value, remark } = this.state;
    const { requestGRNList } = this.props;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <LandingScreenHeader
            showBackButton={true}
            onCancel={() => this.props.navigate("/request-grn-list")}
            showSaveBtnMain={false}
            showAddButton={false}
            screenTitle={purchaseReturnJson.screenTitle}
          />
          <br />
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={value}
              onChange={this.handleChange}
              row
              defaultValue={value}
            >
              <FormControlLabel
                value="0"
                control={<Radio />}
                label="Change In Existing"
              />
              {/* <FormControlLabel
                value="1"
                control={<Radio />}
                label="Add New From Bill-Wise"
              /> */}
              <FormControlLabel
                value="2"
                control={<Radio />}
                label="Add New From Category-Wise"
              />
            </RadioGroup>
          </FormControl>
          {value == "0" ? (
            <>
              <PurchaseReturnTableForBillWise
                tableHead={[
                  {
                    title: "Sr.No.",
                    name: "index",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                  },
                  {
                    title: "Product Name",
                    name: "name",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                  },
                  {
                    title: "UOM",
                    name: "name",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                  },
                  {
                    title: "Rate",
                    name: "name",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                  },
                  {
                    title: "Bill Quantity",
                    name: "secondaryBillQty",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "right",
                  },
                  {
                    title: "Primary UOM",
                    name: "primaryQty",
                    placeHolder: "Enter Target",
                    textFieldError: "qtyErr",
                    positionCenter: false,
                    showInscreen: true,
                  },
                  {
                    title: "Secondary UOM",
                    name: "qty",
                    placeHolder: "Enter Target",
                    textFieldError: "qtyErr",
                    positionCenter: false,
                    showInscreen: true,
                  },
                  {
                    title: "Total Qty",
                    name: "totalQty",
                    placeHolder: "Enter Target",
                    textFieldError: "qtyErr",
                    positionCenter: false,
                    showInscreen: true,
                  },
                  {
                    title: "Total",
                    name: "total",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                  },
                ]}
                data={this.getFilteredTableData(
                  requestGRNList.requestGRNHeaderDetails
                )}
                rowList={requestGRNList.requestGRNHeaderDetails}
                setList={this.setCatList}
                selection={requestGRNList.requestGRNHeaderDetails}
                rows={requestGRNList.requestGRNHeaderDetails}
              />
              <br />
              <Grid container alignItems="center">
                <Grid item xs={12} md={2.5} sm={2.5}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: titleColor, marginLeft: 15 }}
                    label="Add remark :"
                  />
                </Grid>
                <Grid item xs={12} md={9.5} sm={9.5}>
                  <TextFieldCompo
                    placeholder="Add remark"
                    color="primary"
                    name="remark"
                    size="small"
                    value={remark}
                    fullWidth
                    onChange={this.changeHandler}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  sx={{ justifyContent: "flex-end" }}
                >
                  <ButtonCompo
                    size="medium"
                    type="submit"
                    variant="contained"
                    name={"Proceed"}
                    onClick={this.submitHandler}
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            <PurchaseReturnForm remarkField={remark} />
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  stackHolderList: state.stackholder,
  areaList: state.area,
  outletList: state.outlet,
  beatList: state.beat,
  outletOrderDetail: state.outletOrderDetail,
  salesExecutive: state.salesExecutive,
  category: state.category,
  productList: state.product,
  requestGRNList: state.requestGRN,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  setCategoryAndItem,
  getGRNHeaderAndDetailByIdUpdated,
  setNewRequestGRNDetails,
  getProductByCategoryForStakeHolder,
  getCategoryForStakeholder,
  getCategoryItemList,
};

// value == "1" ? (
//   <PurchaseReturnByBillWise
//     detailsList={requestGRNList.requestGRNHeaderDetails[0]}
//   />
// ) :

// const mapStateToProps = (state) => ({
//   category: state.category,
//   productList: state.product,
//   requestGRNList: state.requestGRN,
// });
// const mapDispatchToProps = {
//   showNotification,
//   showLoador,
//   getCategoryForStakeholder,
//   getProductByCategoryForStakeHolder,
//   getGRNHeaderDetailsById,
//   getGRNHeaderById,
//   setNewRequestGRNDetails,
// };

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(RequestGRNEdit);
