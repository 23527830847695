import { Grid, Paper, Typography } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Button";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import CheckBoxSelectionTable from "../../components/Comman/RT/CheckBoxSelectionTable";
import { withRouter } from "../../components/withRouter";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import ClaimOfOutletOfferPopup from "./ClaimOfOutletOfferPopup";
import endpoint from "../../config/endpoints";
import { apiGet } from "../../utils/api_service";
import { id } from "date-fns/locale";

class ClaimOfOutletOfferForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowList: [],
      fieldData: {},
      remark: "",
      selection: [],
      openGenerate: false,
      dataRow: {},
      apiData: [],
    };
  }

  toggle = () => {
    this.setState({ openGenerate: false });
  };


  componentDidMount() {
    if (navigator.onLine) {
      this.props.showLoador({ loador: true });
      apiGet({
        url: `${endpoint.creditNote}/get-outlet-crn-for-grn-gvn`,
      })
        .then(({ data, success }) => {
          // console.log("API Response:", data);

          this.props.showLoador({ loador: false });
          if (success) {
            const formattedData = (data?.data || []).map((item, index) => ({
              id: item.id,
              index: index + 1,
              beatName: item.beatName || "-",
              outletName: item.firmName || "-",
              creditNoteNo: item.transactionNo || "-",
              type:
              item.crnType === 0 ? "CRN From Category-Wise" :
              item.crnType === 1 ? "CRN From Bill-Wise" :
              item.crnType === 2 ? "CRN From Offer" :
              item.crnType === 3 ? "CRN From Expiry Product" :
              item.crnType === 4 ? "CRN From Sampling" :
              item.crnType === 5 ? "Direct CRN" :
              "Unknown",
              date: item?.date || "-",
              billNo: item?.billNo || "-",
              billDate: item?.billDate || "-",
              taxableAmount: item.taxableAmt?.toFixed(2) || "0.00",
              taxAmount:
                (item.cgstAmt + item.sgstAmt + item.igstAmt)?.toFixed(2) ||
                "0.00", // Tax Amount (Sum of CGST, SGST, IGST)
              totalAmount: item.totalAmt?.toFixed(2) || "0.00", // Total Amount
            }));

            this.setState({ apiData: formattedData });
          } else {
            this.props.showNotification({
              msg: "An error occurred",
              severity: "error",
            });
          }
        })
        .catch((error) => {
          console.error("API Error:", error);
          this.props.showNotification({
            msg: "An error occurred",
            severity: "error",
          });
          this.props.showLoador({ loador: false });
        });
    } else {
      this.props.showNotification({
        msg: "No internet connection",
        severity: "error",
      });
    }
  }

  getOutletOfferData = () => {
    if (navigator.onLine) {
      this.props.showLoador({ loador: true });
      apiGet({
        url: `${endpoint.creditNote}/get-outlet-crn-for-grn-gvn`,
      })
        .then(({ data, success }) => {
          // console.log("API Response:", data);

          this.props.showLoador({ loador: false });
          if (success) {
            // const formattedData = (data?.data || []).map((item, index) => ({
            //   id: item.id,
            //   index: index + 1,
            //   beatName: item.beatName || "-",
            //   outletName: item.firmName || "-",
            //   creditNoteNo: item.transactionNo || "-",
            //   type:
            //   item.crnType === 0 ? "CRN From Category-Wise" :
            //   item.crnType === 1 ? "CRN From Bill-Wise" :
            //   item.crnType === 2 ? "CRN From Offer" :
            //   item.crnType === 3 ? "CRN From Expiry Product" :
            //   item.crnType === 4 ? "CRN From Sampling" :
            //   item.crnType === 5 ? "Direct CRN" :
            //   "Unknown",
            //   taxableAmount: item.taxableAmt?.toFixed(2) || "0.00",
            //   taxAmount:
            //     (item.cgstAmt + item.sgstAmt + item.igstAmt)?.toFixed(2) ||
            //     "0.00", // Tax Amount (Sum of CGST, SGST, IGST)
            //   totalAmount: item.totalAmt?.toFixed(2) || "0.00", // Total Amount
            // }));



            const formattedData = (data?.data || []).map((item, index) => ({
              id: item.id,
              index: index + 1,
              beatName: item.beatName || "-",
              outletName: item.firmName || "-",
              creditNoteNo: item.transactionNo || "-",
              type:
              item.crnType === 0 ? "CRN From Category-Wise" :
              item.crnType === 1 ? "CRN From Bill-Wise" :
              item.crnType === 2 ? "CRN From Offer" :
              item.crnType === 3 ? "CRN From Expiry Product" :
              item.crnType === 4 ? "CRN From Sampling" :
              item.crnType === 5 ? "Direct CRN" :
              "Unknown",
              date: item?.date || "-",
              billNo: item?.billNo || "-",
              billDate: item?.billDate || "-",
              taxableAmount: item.taxableAmt?.toFixed(2) || "0.00",
              taxAmount:
                (item.cgstAmt + item.sgstAmt + item.igstAmt)?.toFixed(2) ||
                "0.00", // Tax Amount (Sum of CGST, SGST, IGST)
              totalAmount: item.totalAmt?.toFixed(2) || "0.00", // Total Amount
            }));

            this.setState({ apiData: formattedData, selection: [] });
          } else {
            this.props.showNotification({
              msg: "Failed to update status",
              severity: "error",
            });
          }
        })
        .catch((error) => {
          console.error("API Error:", error);
          this.props.showNotification({
            msg: "An error occurred",
            severity: "error",
          });
          this.props.showLoador({ loador: false });
        });
    } else {
      this.props.showNotification({
        msg: "No internet connection",
        severity: "error",
      });
    }
  };

  onDataChange = (fieldName, newValue) => {
    this.setState((prevState) => ({
      fieldData: { ...prevState.fieldData, [fieldName]: newValue },
    }));
  };

  changeHandler = (e) => {
    this.setState({ remark: e.target.value });
  };

  getFilteredTableData = (tableData) => {
    const { searchValue } = this.state;

    if (!searchValue) return tableData; // If no search value, return full data

    return tableData.filter((currentRow) => {
      // Convert search input to lowercase for case-insensitive search
      const lowerSearchValue = searchValue.toLowerCase();

      // List of fields to search in
      const searchFields = [
        currentRow.beatName,
        currentRow.outletName,
        currentRow.creditNoteNo,
        currentRow.taxableAmount?.toString(),
        currentRow.taxAmount?.toString(),
        currentRow.totalAmount?.toString(),
      ];

      // Check if any field includes the search value
      return searchFields.some(
        (field) => field && field.toLowerCase().includes(lowerSearchValue)
      );
    });
  };

  getSelectedList = (selectedIds) => {
    this.setState({ selection: selectedIds });

    // Ensure `outletOrderDetail.outletOrderDetail` exists before mapping
    const rowList = (this.state?.apiData || []).map((rowData) => ({
      ...rowData,
      checked: selectedIds.includes(rowData.id), // ✅ Efficient way to check selection
    }));

    // Update parent component with the new selected list
    if (this.props.setOutletOrderDetail) {
      this.props.setOutletOrderDetail({ row: rowList });
    }
  };

  onSubmitData = () => {
    const { selection } = this.state;
    const { showNotification } = this.props;

    if (selection.length === 0) {
      console.log("No items selected");
      showNotification({
        msg: "Please select at least one record",
        severity: "warning",
      });
      return;
    } else {
      this.setState({ openGenerate: true });
    }
  };

  onSubmitDataTest = () => {
    this.setState({ openGenerate: true });
  };

  render() {
    const { selection, openGenerate, dataRow } = this.state;
    const { setOutletOrderDetail } = this.props;

    return (
      <Paper
        sx={{
          padding: 2,
          border: "1px solid #ddd", // Light grey border
          borderRadius: 2, // Rounded corners
          elevation: 3, // Shadow effect
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Custom shadow
        }}
      >
        <LandingScreenHeader
          screenTitle="Outlet's CRN"
          showSearchBox={true}
          showAddButton={false}
        />

        {this.state.apiData?.length > 0 ? (
          <Grid container alignItems="center">
            <Grid item xs={12} md={12} sm={12} sx={{ marginTop: 4 }}>
              <CheckBoxSelectionTable
                selection={selection}
                onSelectionChange={this.getSelectedList}
                columns={  [
                  {
                    title: "Sr.No.",
                    name: "index",
                    positionCenter: true,
                    showInscreen: true,
                    align: "center",
                  },
                  {
                    title: "Beat Name",
                    name: "beatName",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                    canSearch: true,
                  },
                  {
                    title: "Outlet Name",
                    name: "outletName",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                    canSearch: true,
                  },
                  {
                    title: "Credit Note No",
                    name: "creditNoteNo",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                    canSearch: true,
                  },

                  {
                    title: "Credit Note Type",
                    name: "type",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                    canSearch: true,
                  },
                  
                  {
                    title: "Credit Note Date",
                    name: "date",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                    canSearch: true,
                  },
                  {
                    title: "Bill No",
                    name: "billNo",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                    canSearch: true,
                  },
                  {
                    title: "Bill Date",
                    name: "billDate",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                    canSearch: true,
                  },
                  {
                    title: "Taxable Amount",
                    name: "taxableAmount",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "right",
                    canSearch: true,
                  },
                  {
                    title: "Tax Amount",
                    name: "taxAmount",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "right",
                    canSearch: true,
                  },
                  {
                    title: "Total Amount",
                    name: "totalAmount",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "right",
                    canSearch: true,
                  },
                ]}
                rows={this.getFilteredTableData(
                  this.state.apiData ? this.state.apiData : []
                )}
                setList={setOutletOrderDetail}
                isActionColActive={false}
              />
            </Grid>
          </Grid>
        ) : (
          <Typography
            variant="h6"
            color="textSecondary"
            sx={{ textAlign: "center" }}
          >
            No data available for outlet's CRN.
          </Typography>
        )}

        {this.state.apiData.length > 0 && (
          <>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              container
              justifyContent="right"
              sx={{ marginTop: 2, padding: 2 }}
            >
              <ButtonCompo
                size="medium"
                type="Submit"
                variant="contained"
                name="Request for claim"
                onClick={this.onSubmitData}
              />
            </Grid>
          </>
        )}

        {openGenerate && (
          <ClaimOfOutletOfferPopup
            open={openGenerate}
            dataRow={dataRow}
            handleCloses={this.toggle}
            selection={selection}
            getOutletOfferData={this.getOutletOfferData}
          />
        )}
      </Paper>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = { showLoador, showNotification };

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ClaimOfOutletOfferForm);
