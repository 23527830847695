import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { imageUrl } from "../../config/Url";
import endpoint from "../../config/endpoints";
import { langugae } from "../../config/languageJSON";
import { apiGet, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import "../Billing History/billPDFCss.css";
import moment from "moment";

const PurchaseOrderPDF = ({ showLoador, showNotification }) => {
    const params = useParams();
    const [billList, setBill] = useState({});
    let lang = localStorage.getItem("lang");
    let loadSheetStockHolderOrderID = localStorage.getItem("loadSheetStockHolderOrderID");

    useEffect(() => {
        // window.print();
        if (navigator.onLine) {
            lang = localStorage.getItem("lang");
            loadSheetStockHolderOrderID = localStorage.getItem("loadSheetStockHolderOrderID");

            let loadSheetOrderData = {
                stockStakeHolderOrderHeaderIds: loadSheetStockHolderOrderID.split(","),
                languageId: lang,
            };
            showLoador({ loador: true });
            apiPost({
                url: endpoint.stockStakeholderOrderHeader + "/load-sheet",
                postBody: loadSheetOrderData,
            }).then(({ data, success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({
                        msg: serverMsg,
                        severity: "error",
                    });
                } else {
                    console.log(data.data);

                    setBill(data.data);
                    window.print();
                }
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }, []);
    if (Object.keys(billList).length != 0) {
        let date;
        let dateForPDF = localStorage.getItem("dateForPDF");
        let dateToSet = JSON.parse(dateForPDF);
        let fromDate =
            dateToSet != null && dateToSet.fromdate
                ? dateToSet.fromdate
                : moment(new Date()).format("DD-MM-YYYY");
        let toDate =
            dateToSet != null && dateToSet.todate
                ? dateToSet.todate
                : moment(new Date()).format("DD-MM-YYYY");
        date = fromDate + " To " + toDate;
        let dataToSet = billList.dtoLoadSheet;
        let categoryWithGroups =
            dataToSet.categoryWithGroups != null ? dataToSet.categoryWithGroups : [];
        let packagingTypeList =
            dataToSet.packagingType != null ? dataToSet.packagingType : [];
        let orderSummaries =
            dataToSet.orderSummaries != null ? dataToSet.orderSummaries : [];
        let finalTotalObj = {};
        return (
            <>
                <div style={{ marginRight: "20px", marginLeft: "20px" }}>
                    <table style={{ width: "100%" }}>
                        <tr>
                            <table style={{ width: "100%" }}>
                                <tr>
                                    <td
                                        style={{
                                            textAlign: "left",
                                            width: "70%",
                                            borderLeft: "1px solid black",
                                            borderTop: "1px solid black",
                                            borderBottom: "1px solid black",
                                            borderRight: "1px solid black",
                                            borderCollapse: "collapse",
                                            padding: "8px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                paddingBottom: "10px",
                                                fontSize: 20,
                                                fontWeight: 600,
                                            }}
                                        >
                                            {langugae[2].purchaseOrderSummary
                                                ? langugae[2].purchaseOrderSummary
                                                : ""}
                                        </div>
                                        <div className="headPadding">
                                            {langugae[2].distName ? langugae[2].distName + " : " : ""}
                                            {localStorage.getItem("distributorName")}
                                        </div>
                                        <div className="headPadding">
                                            {langugae[2].amount ? langugae[2].amount + " : " : ""}
                                            {billList.amt ? billList.amt.toFixed(2) : "-"}
                                        </div>
                                        <div className="headPadding">
                                            {langugae[2].date ? langugae[2].date + " : " : ""}
                                            {date}
                                        </div>
                                    </td>

                                    {/* <td
                    align={"center"}
                    style={{
                      padding: "6px",
                      borderTop: "1px solid black",
                      borderBottom: "1px solid black",
                      borderRight: "1px solid black",
                      borderCollapse: "collapse",
                    }}
                  >
                    <img
                      style={{
                        width: "95%",
                        height: "175px",

                        // objectPosition: '15% 100%'
                      }}
                      src={imageUrl + localStorage.getItem("complogo")}
                      alt="Logo"
                    />
                  </td> */}
                                </tr>
                            </table>
                        </tr>
                    </table>

                    <div style={{ borderCollapse: "collapse", marginTop: "8px" }}>
                        <table style={{ borderCollapse: "collapse", width: "100%" }}>
                            <thead>
                                <tr>
                                    <td colSpan={dataToSet.packagingType.length * 3 + 1}>
                                        <table
                                            style={{ borderCollapse: "collapse", width: "100%" }}
                                        >
                                            <tr>
                                                <td>
                                                    <div style={{ fontSize: "18px" }}>
                                                        <div style={{ display: "inline", fontWeight: 600 }}>
                                                            {langugae[2].amount
                                                                ? langugae[2].amount + " : "
                                                                : ""}
                                                        </div>{" "}
                                                        {billList.amt ? billList.amt.toFixed(2) : "-"}
                                                    </div>
                                                </td>

                                                <td align={"right"}>
                                                    <div style={{ fontSize: "18px" }}>
                                                        <div style={{ display: "inline", fontWeight: 600 }}>
                                                            {langugae[2].date ? langugae[2].date + " : " : ""}
                                                        </div>

                                                        {date}
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>

                                <tr style={{ borderTop: "2px solid black" }}>
                                    <th
                                        className="thsmallcell"
                                        style={{
                                            borderLeft: "2px solid black",
                                            borderRight: "2px solid black",
                                        }}
                                    >
                                        {langugae[2].productName ? langugae[2].productName : ""}
                                    </th>

                                    {dataToSet.packagingType
                                        ? dataToSet.packagingType.map((row) => {
                                            return (
                                                <th
                                                    colSpan={3}
                                                    className="thsmallcell"
                                                    style={{ borderRight: "2px solid black" }}
                                                >
                                                    {row.name}
                                                </th>
                                            );
                                        })
                                        : []}
                                </tr>
                                <tr style={{ borderBottom: "2px solid black" }}>
                                    <th
                                        className="thsmallcell"
                                        style={{
                                            borderLeft: "2px solid black",
                                            borderRight: "2px solid black",
                                        }}
                                    ></th>
                                    {dataToSet.packagingType
                                        ? dataToSet.packagingType.map((row) => {
                                            let all = langugae[2].all ? langugae[2].all : "";

                                            let strip = langugae[2].strip ? langugae[2].strip : "";

                                            let cartoon = langugae[2].cartoon
                                                ? langugae[2].cartoon
                                                : "";
                                            return (
                                                <>
                                                    <th className="thsmallcell">{all}</th>
                                                    <th className="thsmallcell">{strip}</th>
                                                    <th
                                                        className="thsmallcell"
                                                        style={{ borderRight: "2px solid black" }}
                                                    >
                                                        {cartoon}
                                                    </th>
                                                </>
                                            );
                                        })
                                        : []}
                                </tr>
                            </thead>

                            <tbody style={{ width: "100%" }}>
                                {categoryWithGroups.map((categoryData) => {
                                    let totalObj = {};
                                    return (
                                        <>
                                            <tr>
                                                <td
                                                    align={"left"}
                                                    style={{
                                                        borderLeft: "2px solid black",
                                                        fontWeight: 600,
                                                        borderRight: "2px solid black",
                                                    }}
                                                    className="tdsmallcell"
                                                >
                                                    {categoryData.category.name +
                                                        " ( " +
                                                        categoryData.category.shortName +
                                                        " ) "}
                                                </td>

                                                {packagingTypeList.map((packType) => {
                                                    return (
                                                        <td
                                                            align={"left"}
                                                            colSpan={3}
                                                            style={{
                                                                fontWeight: 400,
                                                                borderRight: "2px solid black",
                                                            }}
                                                            className="tdsmallcell"
                                                        ></td>
                                                    );
                                                })}
                                            </tr>
                                            {categoryData.groups.map((groupData) => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td
                                                                align={"left"}
                                                                style={{
                                                                    borderLeft: "2px solid black",
                                                                    fontWeight: 400,
                                                                    borderRight: "2px solid black",
                                                                }}
                                                                className="tdsmallcell"
                                                            >
                                                                {groupData.name}
                                                            </td>
                                                            {packagingTypeList.map((packagingTypeData) => {
                                                                let flag = 0;

                                                                return (
                                                                    <>
                                                                        {orderSummaries.map((rowData) => {
                                                                            if (
                                                                                groupData.id == rowData.groupId &&
                                                                                rowData.packagingTypeId ==
                                                                                packagingTypeData.id
                                                                            ) {
                                                                                flag = 1;

                                                                                totalObj = {
                                                                                    ...totalObj,
                                                                                    [packagingTypeData.id + "-totalQty"]:
                                                                                        totalObj[
                                                                                            packagingTypeData.id + "-totalQty"
                                                                                        ]
                                                                                            ? totalObj[
                                                                                            packagingTypeData.id +
                                                                                            "-totalQty"
                                                                                            ] + rowData.totalQty
                                                                                            : rowData.totalQty,
                                                                                    [packagingTypeData.id +
                                                                                        "-secondaryUomValue"]: totalObj[
                                                                                            packagingTypeData.id +
                                                                                            "-secondaryUomValue"
                                                                                        ]
                                                                                            ? totalObj[
                                                                                            packagingTypeData.id +
                                                                                            "-secondaryUomValue"
                                                                                            ] + rowData.secondaryUomValue
                                                                                            : rowData.secondaryUomValue,
                                                                                    [packagingTypeData.id +
                                                                                        "-primaryUomValue"]: totalObj[
                                                                                            packagingTypeData.id +
                                                                                            "-primaryUomValue"
                                                                                        ]
                                                                                            ? totalObj[
                                                                                            packagingTypeData.id +
                                                                                            "-primaryUomValue"
                                                                                            ] + rowData.primaryUomValue
                                                                                            : rowData.primaryUomValue,
                                                                                };
                                                                                return (
                                                                                    <>
                                                                                        <td
                                                                                            align={"right"}
                                                                                            style={{ fontWeight: 400 }}
                                                                                            className="tdsmallcell"
                                                                                        >
                                                                                            {rowData.totalQty != 0
                                                                                                ? rowData.totalQty
                                                                                                : ""}
                                                                                        </td>
                                                                                        <td
                                                                                            align={"right"}
                                                                                            style={{ fontWeight: 400 }}
                                                                                            className="tdsmallcell"
                                                                                        >
                                                                                            {rowData.secondaryUomValue != 0
                                                                                                ? rowData.secondaryUomValue
                                                                                                : ""}
                                                                                        </td>
                                                                                        <td
                                                                                            align={"right"}
                                                                                            style={{
                                                                                                fontWeight: 400,
                                                                                                borderRight: "2px solid black",
                                                                                            }}
                                                                                            className="tdsmallcell"
                                                                                        >
                                                                                            {rowData.primaryUomValue != 0
                                                                                                ? rowData.primaryUomValue
                                                                                                : ""}
                                                                                        </td>
                                                                                    </>
                                                                                );
                                                                            }
                                                                        })}
                                                                        {flag == 0 && (
                                                                            <>
                                                                                <td
                                                                                    align={"right"}
                                                                                    style={{ fontWeight: 400 }}
                                                                                    className="tdsmallcell"
                                                                                ></td>
                                                                                <td
                                                                                    align={"right"}
                                                                                    style={{ fontWeight: 400 }}
                                                                                    className="tdsmallcell"
                                                                                ></td>
                                                                                <td
                                                                                    align={"right"}
                                                                                    style={{
                                                                                        fontWeight: 400,
                                                                                        borderRight: "2px solid black",
                                                                                    }}
                                                                                    className="tdsmallcell"
                                                                                ></td>
                                                                            </>
                                                                        )}
                                                                    </>
                                                                );
                                                            })}
                                                        </tr>
                                                    </>
                                                );
                                            })}
                                            <tr>
                                                <td
                                                    align={"left"}
                                                    style={{
                                                        borderLeft: "2px solid black",
                                                        fontWeight: 600,
                                                        borderRight: "2px solid black",
                                                    }}
                                                    className="tdsmallcell"
                                                >
                                                    {langugae[2].total ? langugae[2].total : ""}
                                                </td>
                                                {packagingTypeList.map((row) => {
                                                    let totalQty = totalObj[row.id + "-totalQty"]
                                                        ? totalObj[row.id + "-totalQty"]
                                                        : 0;
                                                    let secondaryUomValue = totalObj[
                                                        row.id + "-secondaryUomValue"
                                                    ]
                                                        ? totalObj[row.id + "-secondaryUomValue"]
                                                        : 0;
                                                    let primaryUomValue = totalObj[
                                                        row.id + "-primaryUomValue"
                                                    ]
                                                        ? totalObj[row.id + "-primaryUomValue"]
                                                        : 0;
                                                    finalTotalObj = {
                                                        ...finalTotalObj,
                                                        [row.id + "-totalQty"]: finalTotalObj[
                                                            row.id + "-totalQty"
                                                        ]
                                                            ? finalTotalObj[row.id + "-totalQty"] + totalQty
                                                            : totalQty,
                                                        [row.id + "-secondaryUomValue"]: finalTotalObj[
                                                            row.id + "-secondaryUomValue"
                                                        ]
                                                            ? finalTotalObj[row.id + "-secondaryUomValue"] +
                                                            secondaryUomValue
                                                            : secondaryUomValue,
                                                        [row.id + "-primaryUomValue"]: finalTotalObj[
                                                            row.id + "-primaryUomValue"
                                                        ]
                                                            ? finalTotalObj[row.id + "-primaryUomValue"] +
                                                            primaryUomValue
                                                            : primaryUomValue,
                                                    };
                                                    return (
                                                        <>
                                                            <td
                                                                align={"right"}
                                                                style={{ fontWeight: 600 }}
                                                                className="tdsmallcell"
                                                            >
                                                                {totalObj[row.id + "-totalQty"] &&
                                                                    totalObj[row.id + "-totalQty"] != 0
                                                                    ? totalObj[row.id + "-totalQty"]
                                                                    : ""}
                                                            </td>
                                                            <td
                                                                align={"right"}
                                                                style={{ fontWeight: 600 }}
                                                                className="tdsmallcell"
                                                            >
                                                                {totalObj[row.id + "-secondaryUomValue"] &&
                                                                    totalObj[row.id + "-secondaryUomValue"] != 0
                                                                    ? totalObj[row.id + "-secondaryUomValue"]
                                                                    : ""}
                                                            </td>
                                                            <td
                                                                align={"right"}
                                                                style={{
                                                                    fontWeight: 600,
                                                                    borderRight: "2px solid black",
                                                                }}
                                                                className="tdsmallcell"
                                                            >
                                                                {totalObj[row.id + "-primaryUomValue"] &&
                                                                    totalObj[row.id + "-primaryUomValue"] != 0
                                                                    ? totalObj[row.id + "-primaryUomValue"]
                                                                    : ""}
                                                            </td>
                                                        </>
                                                    );
                                                })}
                                            </tr>
                                        </>
                                    );
                                })}
                                <tr style={{ borderBottom: "2px solid black" }}>
                                    <td
                                        align={"left"}
                                        style={{
                                            borderLeft: "2px solid black",
                                            fontWeight: 600,
                                            borderRight: "2px solid black",
                                        }}
                                        className="tdsmallcell"
                                    >
                                        {langugae[2].finalTotal ? langugae[2].finalTotal : ""}
                                    </td>
                                    {packagingTypeList.map((row) => {
                                        return (
                                            <>
                                                <td
                                                    align={"right"}
                                                    style={{ fontWeight: 600 }}
                                                    className="tdsmallcell"
                                                >
                                                    {finalTotalObj[row.id + "-totalQty"] &&
                                                        finalTotalObj[row.id + "-totalQty"] != 0
                                                        ? finalTotalObj[row.id + "-totalQty"]
                                                        : ""}
                                                </td>
                                                <td
                                                    align={"right"}
                                                    style={{ fontWeight: 600 }}
                                                    className="tdsmallcell"
                                                >
                                                    {finalTotalObj[row.id + "-secondaryUomValue"] &&
                                                        finalTotalObj[row.id + "-secondaryUomValue"] != 0
                                                        ? finalTotalObj[row.id + "-secondaryUomValue"]
                                                        : ""}
                                                </td>
                                                <td
                                                    align={"right"}
                                                    style={{
                                                        fontWeight: 600,
                                                        borderRight: "2px solid black",
                                                    }}
                                                    className="tdsmallcell"
                                                >
                                                    {finalTotalObj[row.id + "-primaryUomValue"] &&
                                                        finalTotalObj[row.id + "-primaryUomValue"] != 0
                                                        ? finalTotalObj[row.id + "-primaryUomValue"]
                                                        : ""}
                                                </td>
                                            </>
                                        );
                                    })}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    }
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
    showNotification,
    showLoador,
};
export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderPDF);
