import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import { TagColumns } from "../../../tableColumns/table-columns";
import { TagJson } from "../../../DynamicFormsJson/MastersJSON/tag";
import { getTag } from "../../../Slice/tag.slice";
class TagTable extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { tagList, getTag } = this.props;
        return (
            <>
                <DynamicMainScreen
                    formPath={TagJson.formPath}
                    screenTitle={TagJson.screenTitle}
                    fieldMeta={TagJson.fieldMeta}
                    showPdfDownload={false}
                    showExcelDownload={true}
                    changeExcelURL={TagJson.excelUrl}
                    showExtension={false}
                    pdfFileName={TagJson.pdfFileName}
                    excelFileName={TagJson.excelFileName}
                    showAddButton={TagJson.showAddButton}
                    tableColumnsToFilter={TagJson.tableColumnsToFilter}
                    tableColumns={TagColumns}
                    tableData={tagList?.tag}
                    getTableData={getTag}
                    apiBaseURL={TagJson.apiBaseURL}
                    baseIdColumn={TagJson.baseIdColumn}
                    isNote={true}
                    isNoteValue={"Tag"}
                    showDeleteIcon={false}
                    isLog={true}
                    type={0}
                />
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    tagList: state.tag,
});
const mapDispatchToProps = {
    getTag,
};
export default connect(mapStateToProps, mapDispatchToProps)(TagTable);
