import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";

const initialState = {
  creationList: [],
  salesTeamListByStakeholderType: [],
  product: [],
  targetAssignation: [],
  targetHeader:[]
};
let URL = endpoints.ruTargetHeader;
let URL1 = endpoints.salesExecutive;
let URL2 = endpoints.product;
let URL3 = endpoints.ruTargetAssignation;
const ruCreationSlice = createSlice({
  name: "ruCreation",
  initialState,
  reducers: {
    ruCreationSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      console.log(row);
      return {
        ...state,
        creationList: row,
      };
    },

    salesTeamListByStakeholderTypeSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      console.log(row);
      return {
        ...state,
        salesTeamListByStakeholderType: row,
      };
    },

    ruProductSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      console.log(row)
      return {
        ...state,
        product: row,
      };
    },
    targetAssignationSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      console.log(row);
      return {
        ...state,
        targetAssignation: row,
      };
    },

    ruTargetSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      console.log(row);
      return {
        ...state,
        targetHeader: row,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  ruCreationSuccess,
  salesTeamListByStakeholderTypeSuccess,
  ruProductSuccess,
  targetAssignationSuccess,
  ruTargetSuccess,
  resetState,
} = ruCreationSlice.actions;

export default ruCreationSlice.reducer;

export const getRuCreation = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/list",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((ruCreationObject, index) => {
          let ruCreationData = {
            index: index + 1,
            id: ruCreationObject.id === null ? "" : ruCreationObject.id,
            name: ruCreationObject.name === null ? "" : ruCreationObject.name,
            targetTypeWithName:
              ruCreationObject.targetType === null
                ? ""
                : ruCreationObject.targetType == 0 ?
                  "SKU & RU Wise" : "RU Wise",
            year: ruCreationObject.year === null ? "" : ruCreationObject.year,
            ruQty:
              ruCreationObject.ruQty === null ? "" : ruCreationObject.ruQty,
            salesTeamCount:
              ruCreationObject.salesTeamCount === null
                ? ""
                : ruCreationObject.salesTeamCount,
            targetType:
              ruCreationObject.targetType === null
                ? ""
                : ruCreationObject.targetType,
            isLinkClick: ruCreationObject.targetType == 0
              ? true
              : false,
            isRUWiseOnly: ruCreationObject.targetType == 1
              ? true
              : false,
            isActive:
              ruCreationObject.isActive === null
                ? ""
                : ruCreationObject.isActive,
          };
          return ruCreationData;
        });
        dispatch(ruCreationSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getSalesTeamByStakeholderType =
  ({ stakeholderType }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          url: URL1 + "/by-stock-stake-holder-type",
          postBody: stakeholderType,
        }).then(({ data, success }) => {
          if (success) {
            console.debug(data);
            const row = data.data.map((salesTeamObj, index) => {
              let ruCreationData = {
                id: salesTeamObj.id === null ? "" : salesTeamObj.id,
                index: index + 1,
                name: salesTeamObj.name === null ? "" : salesTeamObj.name,
                type:
                  salesTeamObj.stockStakeHolderType &&
                    salesTeamObj.stockStakeHolderType !== null
                    ? salesTeamObj.stockStakeHolderType.name
                    : "",
                areaName:
                  salesTeamObj.areaNames === null ? "" : salesTeamObj.areaNames,
                checked: false,
              };
              return ruCreationData;
            });
            dispatch(salesTeamListByStakeholderTypeSuccess({ row }));
            return { response: [], success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getSalesTeamByArea =
  ({ areaIds }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          url: URL1 + "/by-areas-ru-wise",
          postBody: areaIds,
        }).then(({ data, success }) => {
          if (success) {
            console.debug(data);
            const row = data.data.map((salesTeamObj, index) => {
              let ruCreationData = {
                // ...salesTeamObj,
                id: salesTeamObj.id === null ? "" : salesTeamObj.id,
                index: index + 1,
                name: salesTeamObj.name === null ? "" : salesTeamObj.name,
                type:
                  salesTeamObj.stockStakeHolderType &&
                    salesTeamObj.stockStakeHolderType !== null
                    ? salesTeamObj.stockStakeHolderType.name
                    : "",
                areaName:
                  salesTeamObj.areaNames === null ? "" : salesTeamObj.areaNames,
                checked: false,
              };
              return ruCreationData;
            });
            dispatch(salesTeamListByStakeholderTypeSuccess({ row }));
            return { response: [], success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const setSalesTeamByStakeholder =
  ({ row }) =>
    async (dispatch) => {
      dispatch(salesTeamListByStakeholderTypeSuccess({ row }));
    };


export const getRuProductList = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL2 + '/by-retail-unit-wise-target-header?retailUnitWiseTargetHeaderId=',
    }).then(({ data, success }) => {
      if (success) {
        console.log(data)
        // const { data } = data;
        const row = data.data.map((ruProductObject, index) => {
          let ruProductData = {
            index: index + 1,
            id: ruProductObject.id === null ? "" : ruProductObject.id,
            name: ruProductObject.name === null ? "" : ruProductObject.name,
            ruQty:
              ruProductObject.ruQty === null ? "" : ruProductObject.ruQty,
            isActive:
              ruProductObject.isActive === null ? "" : ruProductObject.isActive,
          };
          return ruProductData;
        });
        dispatch(ruProductSuccess({ row }))
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const gettargetAssignation = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL3 + "/by-retail-unit-wise-target-header-and-active-target-assignation?retailUnitWiseTargetHeader=",
    }).then(({ data, success }) => {
      ``;
      if (success) {
        console.log(data);
        // const { data } = data;
        const row = data.data.map((targetAssignationObj, index) => {
          let ruCreationData = {
            //...ruCreationObject,
            index: index + 1,
            id: targetAssignationObj.id === null ? "" : targetAssignationObj.id,
            salesTeamName: targetAssignationObj.salesTeamName === null ? "" : targetAssignationObj.salesTeamName,
            isActive:
              targetAssignationObj.isActive === null
                ? ""
                : targetAssignationObj.isActive,
          };
          return ruCreationData;
        });
        dispatch(targetAssignationSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getTarget = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/details-by-header?id=",
    }).then(({ data, success }) => {
      console.log(data)
      if (success) {
        const row = data.data.targetHeader.map((ruTargetObject, index) => {
          let ruTargetData = {
            index: index + 1,
            id: ruTargetObject.id === null ? "" : ruTargetObject.id,
            name: ruTargetObject.name === null ? "" : ruTargetObject.name,
            fromDate:ruTargetObject.fromDate=== null? "": ruTargetObject.fromDate,
            toDate:ruTargetObject.toDate=== null? "": ruTargetObject.toDate,
            ruQty:
            ruTargetObject.ruQty === null ? "" : ruTargetObject.ruQty,

          };
          return ruTargetData;
        });
        dispatch(ruTargetSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
