import endpoint from "../../config/endpoints";

export const OutletOpeningJson = {
    screenTitle: "Outlet Opening",
    showSaveBtn: false,
    apiBaseURL: endpoint.ruProductConfigHeader,
    formPath: "/outlet-opening-form",
    showBackToList: true,
    showTitle: false,

    fieldMeta: [
        {
            label: "Date",
            controlType: "datepicker",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "date",
            isMandatory: true,
        },
    {
            label: "Region",
            controlType: "autocomplete",
            placeHolder: "Select Region",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            labelSM: 2,
            labelMD: 2,
            labelLG: 2,
            valueSM: 10,
            valueMD: 10,
            valueLG: 10,
            masterName: "regionDetail",
            dataKey: "region",
            getListId: "region",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "State",
            controlType: "autocomplete",
            placeHolder: "Select State",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            labelSM: 2,
            labelMD: 2,
            labelLG: 2,
            valueSM: 10,
            valueMD: 10,
            valueLG: 10,
            masterName: "stateDetail",
            dataKey: "state",
            getListFrom: "region",
            getListId: "state",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Zone",
            controlType: "autocomplete",
            placeHolder: "Select Zone",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            labelSM: 2,
            labelMD: 2,
            labelLG: 2,
            valueSM: 10,
            valueMD: 10,
            valueLG: 10,
            masterName: "zoneDetail",
            dataKey: "zone",
            getListFrom: "state",
            getListId: "zone",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "District",
            controlType: "autocomplete",
            placeHolder: "Select District",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            labelSM: 2,
            labelMD: 2,
            labelLG: 2,
            valueSM: 10,
            valueMD: 10,
            valueLG: 10,
            masterName: "districtDetail",
            dataKey: "district",
            getListFrom: "zone",
            getListId: "district",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Taluka",
            controlType: "autocomplete",
            placeHolder: "Select Taluka",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            labelSM: 2,
            labelMD: 2,
            labelLG: 2,
            valueSM: 10,
            valueMD: 10,
            valueLG: 10,
            masterName: "talukaDetail",
            dataKey: "taluka",
            getListFrom: "district",
            getListId: "taluka",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Area",
            controlType: "autocomplete",
            placeHolder: "Select Area",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            labelSM: 2,
            labelMD: 2,
            labelLG: 2,
            valueSM: 10,
            valueMD: 10,
            valueLG: 10,
            masterName: "areaDetail",
            dataKey: "area",
            getListFrom: "taluka",
            getListId: "area",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Beat",
            controlType: "multiSelect",
            placeHolder: "Select Beat",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            labelSM: 2,
            labelMD: 2,
            labelLG: 2,
            valueSM: 10,
            valueMD: 10,
            valueLG: 10,
            masterName: "beatDetail",
            dataKey: "beat",
            getListFrom: "area",
            getListId: "beat",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Outlet",
            controlType: "multiSelect",
            placeHolder: "Select Outlet",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            labelSM: 2,
            labelMD: 2,
            labelLG: 2,
            valueSM: 10,
            valueMD: 10,
            valueLG: 10,
            masterName: "outletDetail",
            dataKey: "outlet",
            getListFrom: "beat",
            getListId: "outlet",
            isRootLevelKey: true,
            isMandatory: true,
        },
    ],
};
