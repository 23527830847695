import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
const initialState = {
  billEdit: [],
  billByDate: [],
  genratedBillNew: [],
  newBillDetailsById: [],
  genratedBillHeader: {},
  primaryLatestBill: [],
  getOutletBillDetailFromBeatAndDate: [],
  outletBillDetailFromVehicleAndDate: [],
  billingByDate: [],
  billListbyId: [],
};
let URL = endpoints.stockStakeholderBillHeader;
let outletBillHeader = endpoints.outletBillHeader;
let UrlLatest = endpoints.outletBillHeaderLastest;

const billHeaderListSlice = createSlice({
  name: "billHeaderList",
  initialState,
  reducers: {
    billBydateSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        billByDate: row,
      };
    },

    billHeaderByIdSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        billEdit: row,
      };
    },
    billDetailsByIdSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        newBillDetailsById: row,
        isFetch: true,
      };
    },

    genratedBillSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        genratedBillNew: row,
        isFetch: true,
      };
    },
    primaryLatestBillSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        primaryLatestBill: row,
      };
    },
    getOutletBillDetailFromBeatAndDateSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        getOutletBillDetailFromBeatAndDate: row,
      };
    },

    getOutletBillDetailFromVehicleAndDateSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        outletBillDetailFromVehicleAndDate: row,
      };
    },
    billingByDateSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        billingByDate: row,
      };
    },

    billListByIdSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        billListbyId: row,
        isFetch: true,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});
export const {
  billBydateSuccess,
  billHeaderByIdSuccess,
  billDetailsByIdSuccess,
  genratedBillSuccess,
  resetState,
  primaryLatestBillSuccess,
  getOutletBillDetailFromBeatAndDateSuccess,
  getOutletBillDetailFromVehicleAndDateSuccess,
  billingByDateSuccess,
  billListByIdSuccess,
} = billHeaderListSlice.actions;
export default billHeaderListSlice.reducer;
export const getLatestBillDetails = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/latest-bill",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((billListObject, index) => {
          let totalQty = 0;
          const stockStakeHolderBillDetails =
            billListObject.stockStakeHolderBillDetails.map(
              (stockStakeHolderBillDetailsObj) => {
                let stockStakeHolderBillDetailsData = {
                  qty:
                    stockStakeHolderBillDetailsObj.qty === null
                      ? ""
                      : stockStakeHolderBillDetailsObj.qty,
                };
                totalQty += stockStakeHolderBillDetailsData.qty;
                return stockStakeHolderBillDetailsData;
              }
            );

          let billListData = {
            index: index + 1,
            id: billListObject.id === null ? "" : billListObject.id,
            billNo: billListObject.billNo === null ? "" : billListObject.billNo,
            fromBill:
              billListObject.fromBill === null ? "" : billListObject.fromBill,
            toBill: billListObject.toBill === null ? "" : billListObject.toBill,
            billDate:
              billListObject.billDate === null ? "" : billListObject.billDate,
            totalQty: totalQty,
            taxableAmt:
              billListObject.taxableAmt === null
                ? ""
                : billListObject.taxableAmt.toFixed(2),
            discountOnbillPer:
              billListObject.discountOnbillPer === null
                ? ""
                : billListObject.discountOnbillPer,
            discountOnbillPerAmt:
              billListObject.discountOnbillPerAmt === null
                ? ""
                : billListObject.discountOnbillPerAmt.toFixed(2),
            discountOnbillAmt:
              billListObject.discountOnbillAmt === null
                ? ""
                : billListObject.discountOnbillAmt.toFixed(2),
            cessAmt:
              billListObject.cessAmt === null
                ? ""
                : (+billListObject.cessAmt).toFixed(2),
            payoutAmt:
              billListObject.payoutAmt === null
                ? ""
                : (+billListObject.payoutAmt).toFixed(2),
            cgstAmt:
              billListObject.cgstAmt === null
                ? ""
                : billListObject.cgstAmt.toFixed(2),
            sgstAmt:
              billListObject.sgstAmt === null
                ? ""
                : billListObject.sgstAmt.toFixed(2),
            igstAmt:
              billListObject.igstAmt === null
                ? ""
                : billListObject.igstAmt.toFixed(2),
            totalAmt:
              billListObject.totalAmt === null
                ? ""
                : billListObject.totalAmt.toFixed(2),
            itemDiscountAmt:
              billListObject.itemDiscountAmt === null
                ? ""
                : billListObject.itemDiscountAmt.toFixed(2),
            isRecievedStatus:
              billListObject.isRecieved === null
                ? ""
                : billListObject.isRecieved === 0
                  ? "NO"
                  : "YES",
            isRecieved:
              billListObject.isRecieved === null
                ? ""
                : billListObject.isRecieved,
            acknoledgeNo:
              billListObject.acknoledgeNo === null
                ? ""
                : billListObject.acknoledgeNo,
            payoutIsSettle:
              billListObject.payoutIsSettle === null
                ? ""
                : billListObject.payoutIsSettle,
            remainingAmt:
              billListObject.remainingAmt === null
                ? ""
                : billListObject.remainingAmt,
            isSettle:
              billListObject.isSettle === null ? "" : billListObject.isSettle,
            roundOff:
              billListObject.roundOff === null ? "" : billListObject.roundOff,
          };
          return billListData;
        });
        dispatch(billBydateSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getBillByDateSearchList =
  ({ fromdate, todate }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/bill-by-date?fromDate=" + fromdate + "&toDate=" + todate,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((billListObject, index) => {
              let totalQty = 0;
              const stockStakeHolderBillDetails =
                billListObject.stockStakeHolderBillDetails.map(
                  (stockStakeHolderBillDetailsObj) => {
                    let stockStakeHolderBillDetailsData = {
                      qty:
                        stockStakeHolderBillDetailsObj.qty === null
                          ? ""
                          : stockStakeHolderBillDetailsObj.qty,
                    };
                    totalQty += stockStakeHolderBillDetailsData.qty;
                    return stockStakeHolderBillDetailsData;
                  }
                );

              let billListData = {
                index: index + 1,
                id: billListObject.id === null ? "" : billListObject.id,
                billNo:
                  billListObject.billNo === null ? "" : billListObject.billNo,
                fromBill:
                  billListObject.fromBill === null ? "" : billListObject.fromBill,
                toBill:
                  billListObject.toBill === null ? "" : billListObject.toBill,
                billDate:
                  billListObject.billDate === null ? "" : billListObject.billDate,
                totalQty: totalQty,
                taxableAmt:
                  billListObject.taxableAmt === null
                    ? ""
                    : billListObject.taxableAmt.toFixed(2),

                discountOnbillPer:
                  billListObject.discountOnbillPer === null
                    ? ""
                    : billListObject.discountOnbillPer,
                discountOnbillPerAmt:
                  billListObject.discountOnbillPerAmt === null
                    ? ""
                    : billListObject.discountOnbillPerAmt.toFixed(2),

                cessAmt:
                  billListObject.cessAmt === null
                    ? ""
                    : (+billListObject.cessAmt).toFixed(2),
                payoutAmt:
                  billListObject.payoutAmt === null
                    ? ""
                    : (+billListObject.payoutAmt).toFixed(2),
                cgstAmt:
                  billListObject.cgstAmt === null
                    ? ""
                    : billListObject.cgstAmt.toFixed(2),
                sgstAmt:
                  billListObject.sgstAmt === null
                    ? ""
                    : billListObject.sgstAmt.toFixed(2),
                igstAmt:
                  billListObject.igstAmt === null
                    ? ""
                    : billListObject.igstAmt.toFixed(2),
                totalAmt:
                  billListObject.totalAmt === null
                    ? ""
                    : billListObject.totalAmt.toFixed(2),
                itemDiscountAmt:
                  billListObject.itemDiscountAmt === null
                    ? ""
                    : billListObject.itemDiscountAmt.toFixed(2),
                discountOnbillAmt:
                  billListObject.discountOnbillAmt === null
                    ? ""
                    : billListObject.discountOnbillAmt.toFixed(2),
                isRecievedStatus:
                  billListObject.isRecieved === null
                    ? ""
                    : billListObject.isRecieved === 0
                      ? "NO"
                      : "YES",
                isRecieved:
                  billListObject.isRecieved === null
                    ? ""
                    : billListObject.isRecieved,
                acknoledgeNo:
                  billListObject.acknoledgeNo === null
                    ? ""
                    : billListObject.acknoledgeNo,
                payoutIsSettle:
                  billListObject.payoutIsSettle === null
                    ? ""
                    : billListObject.payoutIsSettle,
                remainingAmt:
                  billListObject.remainingAmt === null
                    ? ""
                    : billListObject.remainingAmt,
                isSettle:
                  billListObject.isSettle === null ? "" : billListObject.isSettle,
                roundOff:
                  billListObject.roundOff === null ? "" : billListObject.roundOff,
              };

              return billListData;
            });
            dispatch(billBydateSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const setBillByDateSearchList = () => async (dispatch) => {
  dispatch(billBydateSuccess({ row: [] }));
};
export const getBillHeaderId =
  ({ billHeaderId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/" + billHeaderId,
        }).then(({ data, success }) => {
          if (success) {
            const billHeaderObject = data.data;
            let billHeaderData = {
              id: billHeaderObject.id === null ? "" : billHeaderObject.id,
              billNo:
                billHeaderObject.billNo === null ? "" : billHeaderObject.billNo,
              fromBill:
                billHeaderObject.fromBill === null
                  ? ""
                  : billHeaderObject.fromBill,
              toBill:
                billHeaderObject.toBill === null ? "" : billHeaderObject.toBill,
              billDate:
                billHeaderObject.billDate === null
                  ? ""
                  : billHeaderObject.billDate,
              itemDiscountAmt:
                billHeaderObject.itemDiscountAmt === null
                  ? ""
                  : billHeaderObject.itemDiscountAmt,
              discountOnbillPer:
                billHeaderObject.discountOnbillPer === null
                  ? ""
                  : billHeaderObject.discountOnbillPer,
              discountOnbillPerAmt:
                billHeaderObject.discountOnbillPerAmt === null
                  ? ""
                  : billHeaderObject.discountOnbillPerAmt,
              discountOnbillAmt:
                billHeaderObject.discountOnbillAmt === null
                  ? ""
                  : billHeaderObject.discountOnbillAmt,
              cessAmt:
                billHeaderObject.cessAmt === null ? "" : billHeaderObject.cessAmt,
              payoutAmt:
                billHeaderObject.payoutAmt === null
                  ? ""
                  : billHeaderObject.payoutAmt,
              taxableAmt:
                billHeaderObject.taxableAmt === null
                  ? ""
                  : billHeaderObject.taxableAmt.toFixed(2),
              totalAmt:
                billHeaderObject.totalAmt === null
                  ? ""
                  : billHeaderObject.totalAmt.toFixed(2),
              cgstAmt:
                billHeaderObject.cgstAmt === null ? "" : billHeaderObject.cgstAmt,
              sgstAmt:
                billHeaderObject.sgstAmt === null ? "" : billHeaderObject.sgstAmt,
              igstAmt:
                billHeaderObject.igstAmt === null ? "" : billHeaderObject.igstAmt,
              isRecieved:
                billHeaderObject.isRecieved === null
                  ? ""
                  : billHeaderObject.isRecieved,
              acknoledgeNo:
                billHeaderObject.acknoledgeNo === null
                  ? ""
                  : billHeaderObject.acknoledgeNo,
              payoutIsSettle:
                billHeaderObject.payoutIsSettle === null
                  ? ""
                  : billHeaderObject.payoutIsSettle,
              remainingAmt:
                billHeaderObject.remainingAmt === null
                  ? ""
                  : billHeaderObject.remainingAmt,
              isSettle:
                billHeaderObject.isSettle === null
                  ? ""
                  : billHeaderObject.isSettle,
              roundOff:
                billHeaderObject.roundOff === null
                  ? ""
                  : billHeaderObject.roundOff,
              stockStakeHolderBillDetails:
                billHeaderObject.stockStakeHolderBillDetails === null
                  ? ""
                  : billHeaderObject.stockStakeHolderBillDetails.map(
                    (billObject, index) => {
                      let billData = {
                        index: index + 1,
                        id: billObject.id === null ? "" : billObject.id,
                        product:
                          billObject.product === null ? "" : billObject.product,
                        name:
                          billObject.product === null
                            ? ""
                            : billObject.product.name,
                        productName:
                          billObject.product === null
                            ? ""
                            : billObject.product.name,
                        stakeHolderUom:
                          billObject.product === null
                            ? ""
                            : billObject.product.stakeHolderUom == null
                              ? ""
                              : billObject.product.stakeHolderUom.name,
                        rate:
                          billObject.rate === null
                            ? ""
                            : billObject.rate.toFixed(2),
                        billQty:
                          billObject.qty === null
                            ? ""
                            : billObject.qty.toString(),
                        qty: billObject.qty === null ? "" : billObject.qty,
                        freeQty:
                          billObject.freeQty === null ? "" : billObject.freeQty,
                        itemDiscPer:
                          billObject.itemDiscPer === null
                            ? ""
                            : billObject.itemDiscPer,
                        totalAmt:
                          billObject.totalAmt === null
                            ? ""
                            : billObject.totalAmt.toFixed(2),
                        uom: billObject.uom === null ? "" : billObject.uom,
                        orderDate:
                          billObject.orderDate === null
                            ? ""
                            : billObject.orderDate,
                        cgstAmt:
                          billObject.cgstAmt === null ? "" : billObject.cgstAmt,
                        sgstAmt:
                          billObject.sgstAmt === null
                            ? ""
                            : billObject.sgstAmt.toFixed(2),
                        distcountAmt:
                          billObject.distcountAmt === null
                            ? ""
                            : billObject.distcountAmt,

                        igstAmt:
                          billObject.igstAmt === null
                            ? ""
                            : billObject.igstAmt.toFixed(2),

                        stockStakeHolder:
                          billObject.stockStakeHolder === null
                            ? ""
                            : billObject.stockStakeHolder,
                        type: billObject.type === null ? "" : billObject.type,
                        batchCode:
                          billObject.batchCode === null
                            ? "0"
                            : billObject.batchCode,
                        billDetailId:
                          billObject.billDetailId === null
                            ? "0"
                            : billObject.billDetailId,
                        hsnCode:
                          billObject.hsnCode === null ? "" : billObject.hsnCode,
                        stockStakeHolderOrderDetail:
                          billObject.stockStakeHolderOrderDetail === null
                            ? ""
                            : billObject.stockStakeHolderOrderDetail,
                      };
                      return billData;
                    }
                  ),
            };

            dispatch(billHeaderByIdSuccess({ row: billHeaderData }));
            dispatch(
              billDetailsByIdSuccess({
                row: billHeaderData.stockStakeHolderBillDetails,
              })
            );
            return { response: billHeaderData, success };
          }
          return { response: {}, success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };
export const setReceivedBillData =
  ({ row }) =>
    async (dispatch) => {
      // const rowList = row.map((rowObject, index) => {
      //   const objTotal = +rowObject.qty * +rowObject.rate;
      //   const addObj = {
      //     ...rowObject,
      //     totalAmt:
      //       rowObject.itemDiscPer > 0
      //         ? (
      //             objTotal -
      //             (+rowObject.qty * +rowObject.rate * +rowObject.itemDiscPer) /
      //               100
      //           ).toFixed(2)
      //         : (+rowObject.qty * +rowObject.rate).toFixed(2),
      //   };
      //   return addObj;
      // });
      dispatch(billDetailsByIdSuccess({ row }));
    };

export const getGenratedBillListByDate =
  ({ fromDate, toDate }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL +
            "/generated-bills-by-date?fromDate=" +
            fromDate +
            "&toDate=" +
            toDate,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((genratedBillListObject, index) => {
              let genratedBillListData = {
                index: index + 1,
                id:
                  genratedBillListObject.id === null
                    ? ""
                    : genratedBillListObject.id,
                billNo:
                  genratedBillListObject.billNo === null
                    ? ""
                    : genratedBillListObject.billNo,
                billDate:
                  genratedBillListObject.billDate === null
                    ? ""
                    : genratedBillListObject.billDate,
                ownerAndFirmName:
                  genratedBillListObject.toBillFirmName === null
                    ? ""
                    : genratedBillListObject.toBillFirmName,
                toBillStateName:
                  genratedBillListObject.toBillStateName === null
                    ? ""
                    : genratedBillListObject.toBillStateName,
                toBillAreaNames:
                  genratedBillListObject.toBillAreaNames === null
                    ? ""
                    : genratedBillListObject.toBillAreaNames,
                toBillOwnerName:
                  genratedBillListObject.toBillOwnerName === null
                    ? ""
                    : genratedBillListObject.toBillOwnerName,
                toBillFirmName:
                  genratedBillListObject.toBillFirmName === null
                    ? ""
                    : genratedBillListObject.toBillFirmName,
                toBillGstNo:
                  genratedBillListObject.toBillGstNo === null
                    ? ""
                    : genratedBillListObject.toBillGstNo,
                toBillGstType:
                  genratedBillListObject.toBillGstType === null
                    ? ""
                    : genratedBillListObject.toBillGstType,
                toBillStateName:
                  genratedBillListObject.toBillStateName === null
                    ? ""
                    : genratedBillListObject.toBillStateName,
                taxableAmt:
                  genratedBillListObject.taxableAmt === null
                    ? ""
                    : genratedBillListObject.taxableAmt.toFixed(2),
                vehicleNo:
                  genratedBillListObject.vehicleNo === null
                    ? ""
                    : genratedBillListObject.vehicleNo,
                cgstAmt:
                  genratedBillListObject.cgstAmt === null
                    ? ""
                    : genratedBillListObject.cgstAmt.toFixed(2),
                sgstAmt:
                  genratedBillListObject.sgstAmt === null
                    ? ""
                    : genratedBillListObject.sgstAmt.toFixed(2),
                igstAmt:
                  genratedBillListObject.igstAmt === null
                    ? ""
                    : genratedBillListObject.igstAmt.toFixed(2),
                totalAmt:
                  genratedBillListObject.totalAmt === null
                    ? ""
                    : genratedBillListObject.totalAmt.toFixed(2),
                isRecievedName:
                  genratedBillListObject.isRecieved === null
                    ? ""
                    : genratedBillListObject.isRecieved == 0
                      ? "No"
                      : "Yes",
                isRecieved:
                  genratedBillListObject.isRecieved === null
                    ? ""
                    : genratedBillListObject.isRecieved,
                fromBill:
                  genratedBillListObject.fromBill === null
                    ? ""
                    : genratedBillListObject.fromBill,
                toBill:
                  genratedBillListObject.toBill === null
                    ? ""
                    : genratedBillListObject.toBill,

                itemDiscountAmt:
                  genratedBillListObject.itemDiscountAmt === null
                    ? ""
                    : genratedBillListObject.itemDiscountAmt,
                discountOnbillPer:
                  genratedBillListObject.discountOnbillPer === null
                    ? ""
                    : genratedBillListObject.discountOnbillPer,
                discountOnbillPerAmt:
                  genratedBillListObject.discountOnbillPerAmt === null
                    ? ""
                    : genratedBillListObject.discountOnbillPerAmt,
                discountOnbillAmt:
                  genratedBillListObject.discountOnbillAmt === null
                    ? ""
                    : genratedBillListObject.discountOnbillAmt,
                cessAmt:
                  genratedBillListObject.cessAmt === null
                    ? ""
                    : genratedBillListObject.cessAmt,
                payoutAmt:
                  genratedBillListObject.payoutAmt === null
                    ? ""
                    : genratedBillListObject.payoutAmt,

                igstAmt:
                  genratedBillListObject.igstAmt === null
                    ? ""
                    : genratedBillListObject.igstAmt.toFixed(2),

                acknoledgeNo:
                  genratedBillListObject.acknoledgeNo === null
                    ? ""
                    : genratedBillListObject.acknoledgeNo,
                payoutIsSettle:
                  genratedBillListObject.payoutIsSettle === null
                    ? ""
                    : genratedBillListObject.payoutIsSettle,
                remainingAmt:
                  genratedBillListObject.remainingAmt === null
                    ? ""
                    : genratedBillListObject.remainingAmt,
                isSettle:
                  genratedBillListObject.isSettle === null
                    ? ""
                    : genratedBillListObject.isSettle,
                roundOff:
                  genratedBillListObject.roundOff === null
                    ? ""
                    : genratedBillListObject.roundOff,
                einvoiceJson:
                  genratedBillListObject.einvoiceJson === null
                    ? ""
                    : genratedBillListObject.einvoiceJson,
                fromBillOwnerName:
                  genratedBillListObject.fromBillOwnerName === null
                    ? ""
                    : genratedBillListObject.fromBillOwnerName,
                fromBillFirmName:
                  genratedBillListObject.fromBillFirmName === null
                    ? ""
                    : genratedBillListObject.fromBillFirmName,
                fromBillEinvoicePassword:
                  genratedBillListObject.fromBillEinvoicePassword === null
                    ? ""
                    : genratedBillListObject.fromBillEinvoicePassword,
                fromBillEinvoiceUsername:
                  genratedBillListObject.fromBillEinvoiceUsername === null
                    ? ""
                    : genratedBillListObject.fromBillEinvoiceUsername,
                fromBillIsEinvoiceApplicable:
                  genratedBillListObject.fromBillIsEinvoiceApplicable === null
                    ? ""
                    : genratedBillListObject.fromBillIsEinvoiceApplicable,
                insertedUserId:
                  genratedBillListObject.insertedUserId === null
                    ? ""
                    : genratedBillListObject.insertedUserId,
                insertDateTime:
                  genratedBillListObject.insertDateTime === null
                    ? ""
                    : genratedBillListObject.insertDateTime,
                showGeneratedInvoice:
                  genratedBillListObject.toBillGstType === null &&
                    genratedBillListObject.toBillGstNo === null &&
                    genratedBillListObject.fromBillEinvoiceUsername === null &&
                    genratedBillListObject.fromBillIsEinvoiceApplicable === null &&
                    genratedBillListObject.fromBillEinvoicePassword === null
                    ? ""
                    : genratedBillListObject.einvoiceJson === "" ||
                      (genratedBillListObject.einvoiceJson === null &&
                        genratedBillListObject.toBillGstNo !== "" &&
                        genratedBillListObject.fromBillIsEinvoiceApplicable ==
                        1 &&
                        genratedBillListObject.fromBillEinvoiceUsername !== "" &&
                        genratedBillListObject.fromBillEinvoicePassword !== "")
                      ? "0"
                      : "1",

                stockStakeHolderBillDetails:
                  genratedBillListObject.stockStakeHolderBillDetails === null
                    ? ""
                    : genratedBillListObject.stockStakeHolderBillDetails,
                status:
                  genratedBillListObject.status === null
                    ? ""
                    : genratedBillListObject.status,
                statusForColumn:
                  genratedBillListObject.status === null
                    ? ""
                    : genratedBillListObject.status == 0
                      ? "Generated"
                      : "Cancel",
              };
              return genratedBillListData;
            });
            dispatch(genratedBillSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getGenratedBillListByDateNew =
  ({ fromDate, toDate }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL +
            "/get-generated-bills-by-date?fromDate=" +
            fromDate +
            "&toDate=" +
            toDate,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((genratedBillListObject, index) => {
              let genratedBillListData = {
                index: index + 1,
                id:
                  genratedBillListObject.id === null
                    ? ""
                    : genratedBillListObject.id,
                billNo:
                  genratedBillListObject.billNo === null
                    ? ""
                    : genratedBillListObject.billNo,
                billDate:
                  genratedBillListObject.billDate === null
                    ? ""
                    : genratedBillListObject.billDate,
                ownerAndFirmName:
                  genratedBillListObject.toBillFirmName === null
                    ? ""
                    : genratedBillListObject.toBillFirmName,
                toBillStateName:
                  genratedBillListObject.toBillStateName === null
                    ? ""
                    : genratedBillListObject.toBillStateName,
                toBillAreaNames:
                  genratedBillListObject.toBillAreaNames === null
                    ? ""
                    : genratedBillListObject.toBillAreaNames,
                toBillOwnerName:
                  genratedBillListObject.toBillOwnerName === null
                    ? ""
                    : genratedBillListObject.toBillOwnerName,
                toBillFirmName:
                  genratedBillListObject.toBillFirmName === null
                    ? ""
                    : genratedBillListObject.toBillFirmName,
                toBillGstNo:
                  genratedBillListObject.toBillGstNo === null
                    ? ""
                    : genratedBillListObject.toBillGstNo,
                toBillGstType:
                  genratedBillListObject.toBillGstType === null
                    ? ""
                    : genratedBillListObject.toBillGstType,
                toBillStateName:
                  genratedBillListObject.toBillStateName === null
                    ? ""
                    : genratedBillListObject.toBillStateName,
                taxableAmt:
                  genratedBillListObject.taxableAmt === null
                    ? ""
                    : genratedBillListObject.taxableAmt.toFixed(2),
                vehicleNo:
                  genratedBillListObject.vehicleNo === null
                    ? ""
                    : genratedBillListObject.vehicleNo,
                cgstAmt:
                  genratedBillListObject.cgstAmt === null
                    ? ""
                    : genratedBillListObject.cgstAmt.toFixed(2),
                sgstAmt:
                  genratedBillListObject.sgstAmt === null
                    ? ""
                    : genratedBillListObject.sgstAmt.toFixed(2),
                igstAmt:
                  genratedBillListObject.igstAmt === null
                    ? ""
                    : genratedBillListObject.igstAmt.toFixed(2),
                totalAmt:
                  genratedBillListObject.totalAmt === null
                    ? ""
                    : genratedBillListObject.totalAmt.toFixed(2),
                isRecievedName:
                  genratedBillListObject.isRecieved === null
                    ? ""
                    : genratedBillListObject.isRecieved == 0
                      ? "No"
                      : "Yes",
                isRecieved:
                  genratedBillListObject.isRecieved === null
                    ? ""
                    : genratedBillListObject.isRecieved,
                fromBill:
                  genratedBillListObject.fromBill === null
                    ? ""
                    : genratedBillListObject.fromBill,
                toBill:
                  genratedBillListObject.toBill === null
                    ? ""
                    : genratedBillListObject.toBill,

                itemDiscountAmt:
                  genratedBillListObject.itemDiscountAmt === null
                    ? ""
                    : genratedBillListObject.itemDiscountAmt,
                discountOnbillPer:
                  genratedBillListObject.discountOnbillPer === null
                    ? ""
                    : genratedBillListObject.discountOnbillPer,
                discountOnbillPerAmt:
                  genratedBillListObject.discountOnbillPerAmt === null
                    ? ""
                    : genratedBillListObject.discountOnbillPerAmt,
                discountOnbillAmt:
                  genratedBillListObject.discountOnbillAmt === null
                    ? ""
                    : genratedBillListObject.discountOnbillAmt,
                cessAmt:
                  genratedBillListObject.cessAmt === null
                    ? ""
                    : genratedBillListObject.cessAmt,
                payoutAmt:
                  genratedBillListObject.payoutAmt === null
                    ? ""
                    : genratedBillListObject.payoutAmt,

                igstAmt:
                  genratedBillListObject.igstAmt === null
                    ? ""
                    : genratedBillListObject.igstAmt.toFixed(2),

                acknoledgeNo:
                  genratedBillListObject.acknoledgeNo === null
                    ? ""
                    : genratedBillListObject.acknoledgeNo,
                payoutIsSettle:
                  genratedBillListObject.payoutIsSettle === null
                    ? ""
                    : genratedBillListObject.payoutIsSettle,
                remainingAmt:
                  genratedBillListObject.remainingAmt === null
                    ? ""
                    : genratedBillListObject.remainingAmt,
                isSettle:
                  genratedBillListObject.isSettle === null
                    ? ""
                    : genratedBillListObject.isSettle,
                roundOff:
                  genratedBillListObject.roundOff === null
                    ? ""
                    : genratedBillListObject.roundOff,
                einvoiceJson:
                  genratedBillListObject.einvoiceJson === null
                    ? ""
                    : genratedBillListObject.einvoiceJson,
                fromBillOwnerName:
                  genratedBillListObject.fromBillOwnerName === null
                    ? ""
                    : genratedBillListObject.fromBillOwnerName,
                fromBillFirmName:
                  genratedBillListObject.fromBillFirmName === null
                    ? ""
                    : genratedBillListObject.fromBillFirmName,
                fromBillEinvoicePassword:
                  genratedBillListObject.fromBillEinvoicePassword === null
                    ? ""
                    : genratedBillListObject.fromBillEinvoicePassword,
                fromBillEinvoiceUsername:
                  genratedBillListObject.fromBillEinvoiceUsername === null
                    ? ""
                    : genratedBillListObject.fromBillEinvoiceUsername,
                fromBillIsEinvoiceApplicable:
                  genratedBillListObject.fromBillIsEinvoiceApplicable === null
                    ? ""
                    : genratedBillListObject.fromBillIsEinvoiceApplicable,
                insertedUserId:
                  genratedBillListObject.insertedUserId === null
                    ? ""
                    : genratedBillListObject.insertedUserId,
                insertDateTime:
                  genratedBillListObject.insertDateTime === null
                    ? ""
                    : genratedBillListObject.insertDateTime,
                showGeneratedInvoice:
                  genratedBillListObject.toBillGstType === null &&
                    genratedBillListObject.toBillGstNo === null &&
                    genratedBillListObject.fromBillEinvoiceUsername === null &&
                    genratedBillListObject.fromBillIsEinvoiceApplicable === null &&
                    genratedBillListObject.fromBillEinvoicePassword === null
                    ? ""
                    : genratedBillListObject.einvoiceJson === "" ||
                      (genratedBillListObject.einvoiceJson === null &&
                        genratedBillListObject.toBillGstNo !== "" &&
                        genratedBillListObject.fromBillIsEinvoiceApplicable ==
                        1 &&
                        genratedBillListObject.fromBillEinvoiceUsername !== "" &&
                        genratedBillListObject.fromBillEinvoicePassword !== "")
                      ? "0"
                      : "1",

                stockStakeHolderBillDetails:
                  genratedBillListObject.stockStakeHolderBillDetails === null
                    ? ""
                    : genratedBillListObject.stockStakeHolderBillDetails,
                status:
                  genratedBillListObject.status === null
                    ? ""
                    : genratedBillListObject.status,
                statusForColumn:
                  genratedBillListObject.status === null
                    ? ""
                    : genratedBillListObject.status == 0
                      ? "Generated"
                      : "Cancel",
              };
              return genratedBillListData;
            });
            dispatch(genratedBillSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getGenratedBillList =
  ({ billHolderIds, fromDate, toDate }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL +
            "/generated-bills?billHolderIds=" +
            billHolderIds +
            "&fromDate=" +
            fromDate +
            "&toDate=" +
            toDate,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((genratedBillListObject, index) => {
              let genratedBillListData = {
                index: index + 1,
                id:
                  genratedBillListObject.id === null
                    ? ""
                    : genratedBillListObject.id,
                billNo:
                  genratedBillListObject.billNo === null
                    ? ""
                    : genratedBillListObject.billNo,
                billDate:
                  genratedBillListObject.billDate === null
                    ? ""
                    : genratedBillListObject.billDate,
                ownerAndFirmName:
                  genratedBillListObject.toBillFirmName === null
                    ? ""
                    : genratedBillListObject.toBillFirmName,
                toBillStateName:
                  genratedBillListObject.toBillStateName === null
                    ? ""
                    : genratedBillListObject.toBillStateName,
                toBillAreaNames:
                  genratedBillListObject.toBillAreaNames === null
                    ? ""
                    : genratedBillListObject.toBillAreaNames,
                toBillOwnerName:
                  genratedBillListObject.toBillOwnerName === null
                    ? ""
                    : genratedBillListObject.toBillOwnerName,
                toBillFirmName:
                  genratedBillListObject.toBillFirmName === null
                    ? ""
                    : genratedBillListObject.toBillFirmName,
                taxableAmt:
                  genratedBillListObject.taxableAmt === null
                    ? ""
                    : genratedBillListObject.taxableAmt.toFixed(2),
                cgstAmt:
                  genratedBillListObject.cgstAmt === null
                    ? ""
                    : genratedBillListObject.cgstAmt.toFixed(2),
                sgstAmt:
                  genratedBillListObject.sgstAmt === null
                    ? ""
                    : genratedBillListObject.sgstAmt.toFixed(2),
                totalAmt:
                  genratedBillListObject.totalAmt === null
                    ? ""
                    : genratedBillListObject.totalAmt.toFixed(2),
                isRecievedName:
                  genratedBillListObject.isRecieved === null
                    ? ""
                    : genratedBillListObject.isRecieved == 0
                      ? "No"
                      : "Yes",
                isRecieved:
                  genratedBillListObject.isRecieved === null
                    ? ""
                    : genratedBillListObject.isRecieved,
                fromBill:
                  genratedBillListObject.fromBill === null
                    ? ""
                    : genratedBillListObject.fromBill,
                toBill:
                  genratedBillListObject.toBill === null
                    ? ""
                    : genratedBillListObject.toBill,

                itemDiscountAmt:
                  genratedBillListObject.itemDiscountAmt === null
                    ? ""
                    : genratedBillListObject.itemDiscountAmt,
                discountOnbillPer:
                  genratedBillListObject.discountOnbillPer === null
                    ? ""
                    : genratedBillListObject.discountOnbillPer,
                discountOnbillPerAmt:
                  genratedBillListObject.discountOnbillPerAmt === null
                    ? ""
                    : genratedBillListObject.discountOnbillPerAmt,
                discountOnbillAmt:
                  genratedBillListObject.discountOnbillAmt === null
                    ? ""
                    : genratedBillListObject.discountOnbillAmt,
                cessAmt:
                  genratedBillListObject.cessAmt === null
                    ? ""
                    : genratedBillListObject.cessAmt,
                payoutAmt:
                  genratedBillListObject.payoutAmt === null
                    ? ""
                    : genratedBillListObject.payoutAmt,

                igstAmt:
                  genratedBillListObject.igstAmt === null
                    ? ""
                    : genratedBillListObject.igstAmt.toFixed(2),

                acknoledgeNo:
                  genratedBillListObject.acknoledgeNo === null
                    ? ""
                    : genratedBillListObject.acknoledgeNo,
                payoutIsSettle:
                  genratedBillListObject.payoutIsSettle === null
                    ? ""
                    : genratedBillListObject.payoutIsSettle,
                remainingAmt:
                  genratedBillListObject.remainingAmt === null
                    ? ""
                    : genratedBillListObject.remainingAmt,
                isSettle:
                  genratedBillListObject.isSettle === null
                    ? ""
                    : genratedBillListObject.isSettle,
                roundOff:
                  genratedBillListObject.roundOff === null
                    ? ""
                    : genratedBillListObject.roundOff,
                fromBillOwnerName:
                  genratedBillListObject.fromBillOwnerName === null
                    ? ""
                    : genratedBillListObject.fromBillOwnerName,
                fromBillFirmName:
                  genratedBillListObject.fromBillFirmName === null
                    ? ""
                    : genratedBillListObject.fromBillFirmName,
                insertedUserId:
                  genratedBillListObject.insertedUserId === null
                    ? ""
                    : genratedBillListObject.insertedUserId,
                insertDateTime:
                  genratedBillListObject.insertDateTime === null
                    ? ""
                    : genratedBillListObject.insertDateTime,
              };
              return genratedBillListData;
            });
            dispatch(genratedBillSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };
export const setGenratedBillList = () => async (dispatch) => {
  dispatch(genratedBillSuccess({ row: [] }));
};
export const genratedBillHeader =
  ({ billHeaderId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/by-id?stockStakeHolderBillHeaderId=" + billHeaderId,
        }).then(({ data, success }) => {
          if (success) {
            const genratedBillObject = data.data;
            let billHeaderData = {
              id: genratedBillObject.id === null ? "" : genratedBillObject.id,
              billNo:
                genratedBillObject.billNo === null
                  ? ""
                  : genratedBillObject.billNo,
              fromBill:
                genratedBillObject.fromBill === null
                  ? ""
                  : genratedBillObject.fromBill,
              fromBillFirmName:
                genratedBillObject.fromBillFirmName === null
                  ? ""
                  : genratedBillObject.fromBillFirmName,
              fromBillOwnerName:
                genratedBillObject.fromBillOwnerName === null
                  ? ""
                  : genratedBillObject.fromBillOwnerName,
              toBill:
                genratedBillObject.toBill === null
                  ? ""
                  : genratedBillObject.toBill,
              toBillFirmName:
                genratedBillObject.toBillFirmName === null
                  ? ""
                  : genratedBillObject.toBillFirmName,
              toBillOwnerName:
                genratedBillObject.toBillOwnerName === null
                  ? ""
                  : genratedBillObject.toBillOwnerName,
              billDate:
                genratedBillObject.billDate === null
                  ? ""
                  : genratedBillObject.billDate,
              itemDiscountAmt:
                genratedBillObject.itemDiscountAmt === null
                  ? ""
                  : genratedBillObject.itemDiscountAmt,
              discountOnbillPer:
                genratedBillObject.discountOnbillPer === null
                  ? ""
                  : genratedBillObject.discountOnbillPer,
              discountOnbillPerAmt:
                genratedBillObject.discountOnbillPerAmt === null
                  ? ""
                  : genratedBillObject.discountOnbillPerAmt,
              discountOnbillAmt:
                genratedBillObject.discountOnbillAmt === null
                  ? ""
                  : genratedBillObject.discountOnbillAmt,
              cessAmt:
                genratedBillObject.cessAmt === null
                  ? ""
                  : genratedBillObject.cessAmt,
              payoutAmt:
                genratedBillObject.payoutAmt === null
                  ? ""
                  : genratedBillObject.payoutAmt,
              taxableAmt:
                genratedBillObject.taxableAmt === null
                  ? ""
                  : genratedBillObject.taxableAmt.toFixed(2),
              totalAmt:
                genratedBillObject.totalAmt === null
                  ? ""
                  : genratedBillObject.totalAmt.toFixed(2),
              cgstAmt:
                genratedBillObject.cgstAmt === null
                  ? ""
                  : genratedBillObject.cgstAmt,
              sgstAmt:
                genratedBillObject.sgstAmt === null
                  ? ""
                  : genratedBillObject.sgstAmt,
              igstAmt:
                genratedBillObject.igstAmt === null
                  ? ""
                  : genratedBillObject.igstAmt,

              remainingAmt:
                genratedBillObject.remainingAmt === null
                  ? ""
                  : genratedBillObject.remainingAmt,
              roundOff:
                genratedBillObject.roundOff === null
                  ? ""
                  : genratedBillObject.roundOff,
              totalTax: (
                (genratedBillObject.totalAmt === null
                  ? ""
                  : genratedBillObject.totalAmt.toFixed(2)) -
                (genratedBillObject.taxableAmt === null
                  ? ""
                  : genratedBillObject.taxableAmt.toFixed(2))
              ).toFixed(2),
              status:
                genratedBillObject.status === null
                  ? ""
                  : genratedBillObject.status,

              payoutIsSettle:
                genratedBillObject.payoutIsSettle === null
                  ? ""
                  : genratedBillObject.payoutIsSettle,
              isSettle:
                genratedBillObject.isSettle === null
                  ? ""
                  : genratedBillObject.isSettle,
            };
            // dispatch(
            //   orderHeaderByIdSuccess({
            //     row: billHeaderData.stockStakeHolderOrderDetails,
            //   })
            // );
            return { response: billHeaderData, success };
          }
          return { response: {}, success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };
export const getPrimaryLatestBill = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/latest-bill",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((rowData, index) => {
          return {
            index: index + 1,
            billNo: rowData.billNo,
            billDate: rowData.billDate,
            totalAmt: rowData.totalAmt,
            ...rowData,
          };
        });
        dispatch(primaryLatestBillSuccess({ row }));
        return { response: data.data, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getPrimaryLatestBillByFilter =
  ({ fromDate, toDate }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/by-date?fromDate=" + fromDate + "&toDate=" + toDate,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((rowData, index) => {
              return { index: index + 1, ...rowData };
            });
            dispatch(primaryLatestBillSuccess({ row }));
            return { response: data.data, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getOutletBillDetailFromBeatAndDate =
  ({ beatIds, fromDate, toDate }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            outletBillHeader +
            "/by-beats-and-date?beatIds=" +
            beatIds +
            "&fromDate=" +
            fromDate +
            "&toDate=" +
            toDate,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((genratedBillListObject, index) => {
              let billHeaderData = {
                index: index + 1,
                id:
                  genratedBillListObject.id === null
                    ? ""
                    : genratedBillListObject.id,
                billNo:
                  genratedBillListObject.billNo === null
                    ? ""
                    : genratedBillListObject.billNo,
                billDate:
                  genratedBillListObject.billDate === null
                    ? ""
                    : genratedBillListObject.billDate,
                toBillFirmName:
                  genratedBillListObject.toBillFirmName === null
                    ? ""
                    : genratedBillListObject.toBillFirmName,
                taxableAmt:
                  genratedBillListObject.taxableAmt === null
                    ? ""
                    : genratedBillListObject.taxableAmt.toFixed(2),
                cgstAmt:
                  genratedBillListObject.cgstAmt === null
                    ? ""
                    : genratedBillListObject.cgstAmt.toFixed(2),
                sgstAmt:
                  genratedBillListObject.sgstAmt === null
                    ? ""
                    : genratedBillListObject.sgstAmt.toFixed(2),
                totalAmt:
                  genratedBillListObject.totalAmt === null
                    ? ""
                    : genratedBillListObject.totalAmt.toFixed(2),
                isRecievedName:
                  genratedBillListObject.isRecieved === null
                    ? ""
                    : genratedBillListObject.isRecieved == 0
                      ? "No"
                      : "Yes",
                isRecieved:
                  genratedBillListObject.isRecieved === null
                    ? ""
                    : genratedBillListObject.isRecieved,
                fromBill:
                  genratedBillListObject.fromBill === null
                    ? ""
                    : genratedBillListObject.fromBill,
                toBill:
                  genratedBillListObject.toBill === null
                    ? ""
                    : genratedBillListObject.toBill,

                itemDiscountAmt:
                  genratedBillListObject.itemDiscountAmt === null
                    ? ""
                    : genratedBillListObject.itemDiscountAmt,
                discountOnbillPer:
                  genratedBillListObject.discountOnbillPer === null
                    ? ""
                    : genratedBillListObject.discountOnbillPer,
                discountOnbillPerAmt:
                  genratedBillListObject.discountOnbillPerAmt === null
                    ? ""
                    : genratedBillListObject.discountOnbillPerAmt,
                discountOnbillAmt:
                  genratedBillListObject.discountOnbillAmt === null
                    ? ""
                    : genratedBillListObject.discountOnbillAmt,
                cessAmt:
                  genratedBillListObject.cessAmt === null
                    ? ""
                    : genratedBillListObject.cessAmt,
                payoutAmt:
                  genratedBillListObject.payoutAmt === null
                    ? ""
                    : genratedBillListObject.payoutAmt,

                igstAmt:
                  genratedBillListObject.igstAmt === null
                    ? ""
                    : genratedBillListObject.igstAmt.toFixed(2),

                acknoledgeNo:
                  genratedBillListObject.acknoledgeNo === null
                    ? ""
                    : genratedBillListObject.acknoledgeNo,
                payoutIsSettle:
                  genratedBillListObject.payoutIsSettle === null
                    ? ""
                    : genratedBillListObject.payoutIsSettle,
                remainingAmt:
                  genratedBillListObject.remainingAmt === null
                    ? ""
                    : genratedBillListObject.remainingAmt.toFixed(2),
                roundOff:
                  genratedBillListObject.roundOff === null
                    ? ""
                    : genratedBillListObject.isSettle,
                roundOff:
                  genratedBillListObject.roundOff === null
                    ? ""
                    : genratedBillListObject.roundOff,
                fromBillOwnerName:
                  genratedBillListObject.fromBillOwnerName === null
                    ? ""
                    : genratedBillListObject.fromBillOwnerName,
                fromBillFirmName:
                  genratedBillListObject.fromBillFirmName === null
                    ? ""
                    : genratedBillListObject.fromBillFirmName,
                insertedUserId:
                  genratedBillListObject.insertedUserId === null
                    ? ""
                    : genratedBillListObject.insertedUserId,
                insertDateTime:
                  genratedBillListObject.insertDateTime === null
                    ? ""
                    : genratedBillListObject.isSettle,
                firmName:
                  genratedBillListObject.outLet === null
                    ? ""
                    : genratedBillListObject.outLet.firmName,
                beatName:
                  genratedBillListObject.outLet === null
                    ? ""
                    : genratedBillListObject.outLet.beat === null
                      ? ""
                      : genratedBillListObject.outLet.beat.beatName,
                outlet:
                  genratedBillListObject.outLet === null
                    ? ""
                    : genratedBillListObject.outLet,
                amount: 0,
                payType: "0",
                remark: "",
                amountError: false,
                amountErrorMsg: "",
              };
              return billHeaderData;
            });
            dispatch(getOutletBillDetailFromBeatAndDateSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getOutletBillDetailFromOutletAndDate =
  ({ outletIds, fromDate, toDate }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            outletBillHeader +
            "/get-generated-bill-by-date-and-outlets?outletIds=" +
            outletIds +
            "&fromDate=" +
            fromDate +
            "&toDate=" +
            toDate,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((genratedBillListObject, index) => {
              let billHeaderData = {
                index: index + 1,
                id:
                  genratedBillListObject.id === null
                    ? ""
                    : genratedBillListObject.id,
                billNo:
                  genratedBillListObject.billNo === null
                    ? ""
                    : genratedBillListObject.billNo,
                billDate:
                  genratedBillListObject.billDate === null
                    ? ""
                    : genratedBillListObject.billDate,
                toBillFirmName:
                  genratedBillListObject.toBillFirmName === null
                    ? ""
                    : genratedBillListObject.toBillFirmName,
                taxableAmt:
                  genratedBillListObject.taxableAmt === null
                    ? ""
                    : genratedBillListObject.taxableAmt.toFixed(2),
                cgstAmt:
                  genratedBillListObject.cgstAmt === null
                    ? ""
                    : genratedBillListObject.cgstAmt.toFixed(2),
                sgstAmt:
                  genratedBillListObject.sgstAmt === null
                    ? ""
                    : genratedBillListObject.sgstAmt.toFixed(2),
                totalAmt:
                  genratedBillListObject.totalAmt === null
                    ? ""
                    : genratedBillListObject.totalAmt.toFixed(2),
                isRecievedName:
                  genratedBillListObject.isRecieved === null
                    ? ""
                    : genratedBillListObject.isRecieved == 0
                      ? "No"
                      : "Yes",
                isRecieved:
                  genratedBillListObject.isRecieved === null
                    ? ""
                    : genratedBillListObject.isRecieved,
                fromBill:
                  genratedBillListObject.fromBill === null
                    ? ""
                    : genratedBillListObject.fromBill,
                toBill:
                  genratedBillListObject.toBill === null
                    ? ""
                    : genratedBillListObject.toBill,

                itemDiscountAmt:
                  genratedBillListObject.itemDiscountAmt === null
                    ? ""
                    : genratedBillListObject.itemDiscountAmt,
                discountOnbillPer:
                  genratedBillListObject.discountOnbillPer === null
                    ? ""
                    : genratedBillListObject.discountOnbillPer,
                discountOnbillPerAmt:
                  genratedBillListObject.discountOnbillPerAmt === null
                    ? ""
                    : genratedBillListObject.discountOnbillPerAmt,
                discountOnbillAmt:
                  genratedBillListObject.discountOnbillAmt === null
                    ? ""
                    : genratedBillListObject.discountOnbillAmt,
                cessAmt:
                  genratedBillListObject.cessAmt === null
                    ? ""
                    : genratedBillListObject.cessAmt,
                payoutAmt:
                  genratedBillListObject.payoutAmt === null
                    ? ""
                    : genratedBillListObject.payoutAmt,

                igstAmt:
                  genratedBillListObject.igstAmt === null
                    ? ""
                    : genratedBillListObject.igstAmt.toFixed(2),

                acknoledgeNo:
                  genratedBillListObject.acknoledgeNo === null
                    ? ""
                    : genratedBillListObject.acknoledgeNo,
                payoutIsSettle:
                  genratedBillListObject.payoutIsSettle === null
                    ? ""
                    : genratedBillListObject.payoutIsSettle,
                remainingAmt:
                  genratedBillListObject.remainingAmt === null
                    ? ""
                    : genratedBillListObject.remainingAmt.toFixed(2),
                roundOff:
                  genratedBillListObject.roundOff === null
                    ? ""
                    : genratedBillListObject.isSettle,
                roundOff:
                  genratedBillListObject.roundOff === null
                    ? ""
                    : genratedBillListObject.roundOff,
                fromBillOwnerName:
                  genratedBillListObject.fromBillOwnerName === null
                    ? ""
                    : genratedBillListObject.fromBillOwnerName,
                fromBillFirmName:
                  genratedBillListObject.fromBillFirmName === null
                    ? ""
                    : genratedBillListObject.fromBillFirmName,
                insertedUserId:
                  genratedBillListObject.insertedUserId === null
                    ? ""
                    : genratedBillListObject.insertedUserId,
                insertDateTime:
                  genratedBillListObject.insertDateTime === null
                    ? ""
                    : genratedBillListObject.isSettle,
                firmName:
                  genratedBillListObject.outLet === null
                    ? ""
                    : genratedBillListObject.outLet.firmName,
                beatName:
                  genratedBillListObject.outLet === null
                    ? ""
                    : genratedBillListObject.outLet.beat === null
                      ? ""
                      : genratedBillListObject.outLet.beat.beatName,
                outlet:
                  genratedBillListObject.outLet === null
                    ? ""
                    : genratedBillListObject.outLet,
                amount:
                  genratedBillListObject.remainingAmt === null
                    ? ""
                    : genratedBillListObject.remainingAmt,
                payType: "0",
                remark: "",
                amountError: false,
                amountErrorMsg: "",
              };
              return billHeaderData;
            });
            dispatch(getOutletBillDetailFromBeatAndDateSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getOutletBillDetailFromOutletAndDateUpdated =
  ({ outletBillHeaderJson }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          url: outletBillHeader + "/generated-bill-by-date-and-outlets",
          postBody: outletBillHeaderJson,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((genratedBillListObject, index) => {
              let billHeaderData = {
                index: index + 1,
                id:
                  genratedBillListObject.id === null
                    ? ""
                    : genratedBillListObject.id,
                billNo:
                  genratedBillListObject.billNo === null
                    ? ""
                    : genratedBillListObject.billNo,
                billDate:
                  genratedBillListObject.billDate === null
                    ? ""
                    : genratedBillListObject.billDate,
                toBillFirmName:
                  genratedBillListObject.toBillFirmName === null
                    ? ""
                    : genratedBillListObject.toBillFirmName,
                taxableAmt:
                  genratedBillListObject.taxableAmt === null
                    ? ""
                    : genratedBillListObject.taxableAmt.toFixed(2),
                cgstAmt:
                  genratedBillListObject.cgstAmt === null
                    ? ""
                    : genratedBillListObject.cgstAmt.toFixed(2),
                sgstAmt:
                  genratedBillListObject.sgstAmt === null
                    ? ""
                    : genratedBillListObject.sgstAmt.toFixed(2),
                totalAmt:
                  genratedBillListObject.totalAmt === null
                    ? ""
                    : genratedBillListObject.totalAmt.toFixed(2),
                isRecievedName:
                  genratedBillListObject.isRecieved === null
                    ? ""
                    : genratedBillListObject.isRecieved == 0
                      ? "No"
                      : "Yes",
                isRecieved:
                  genratedBillListObject.isRecieved === null
                    ? ""
                    : genratedBillListObject.isRecieved,
                fromBill:
                  genratedBillListObject.fromBill === null
                    ? ""
                    : genratedBillListObject.fromBill,
                toBill:
                  genratedBillListObject.toBill === null
                    ? ""
                    : genratedBillListObject.toBill,

                itemDiscountAmt:
                  genratedBillListObject.itemDiscountAmt === null
                    ? ""
                    : genratedBillListObject.itemDiscountAmt,
                discountOnbillPer:
                  genratedBillListObject.discountOnbillPer === null
                    ? ""
                    : genratedBillListObject.discountOnbillPer,
                discountOnbillPerAmt:
                  genratedBillListObject.discountOnbillPerAmt === null
                    ? ""
                    : genratedBillListObject.discountOnbillPerAmt,
                discountOnbillAmt:
                  genratedBillListObject.discountOnbillAmt === null
                    ? ""
                    : genratedBillListObject.discountOnbillAmt,
                cessAmt:
                  genratedBillListObject.cessAmt === null
                    ? ""
                    : genratedBillListObject.cessAmt,
                payoutAmt:
                  genratedBillListObject.payoutAmt === null
                    ? ""
                    : genratedBillListObject.payoutAmt,

                igstAmt:
                  genratedBillListObject.igstAmt === null
                    ? ""
                    : genratedBillListObject.igstAmt.toFixed(2),

                acknoledgeNo:
                  genratedBillListObject.acknoledgeNo === null
                    ? ""
                    : genratedBillListObject.acknoledgeNo,
                payoutIsSettle:
                  genratedBillListObject.payoutIsSettle === null
                    ? ""
                    : genratedBillListObject.payoutIsSettle,
                remainingAmt:
                  genratedBillListObject.remainingAmt === null
                    ? ""
                    : genratedBillListObject.remainingAmt.toFixed(2),
                roundOff:
                  genratedBillListObject.roundOff === null
                    ? ""
                    : genratedBillListObject.isSettle,
                roundOff:
                  genratedBillListObject.roundOff === null
                    ? ""
                    : genratedBillListObject.roundOff,
                fromBillOwnerName:
                  genratedBillListObject.fromBillOwnerName === null
                    ? ""
                    : genratedBillListObject.fromBillOwnerName,
                fromBillFirmName:
                  genratedBillListObject.fromBillFirmName === null
                    ? ""
                    : genratedBillListObject.fromBillFirmName,
                insertedUserId:
                  genratedBillListObject.insertedUserId === null
                    ? ""
                    : genratedBillListObject.insertedUserId,
                insertDateTime:
                  genratedBillListObject.insertDateTime === null
                    ? ""
                    : genratedBillListObject.isSettle,
                firmName:
                  genratedBillListObject.firmName === null
                    ? ""
                    : genratedBillListObject.firmName,
                beatName:
                  genratedBillListObject.beatName === null
                    ? ""
                    : genratedBillListObject.beatName,

                outlet:
                  genratedBillListObject.outletId === null
                    ? ""
                    : genratedBillListObject.outletId,
                amount:
                  genratedBillListObject.remainingAmt === null
                    ? ""
                    : genratedBillListObject.remainingAmt,
                payType: "0",
                remark: "",
                amountError: false,
                amountErrorMsg: "",
                isChecked: false,
              };
              return billHeaderData;
            });
            dispatch(getOutletBillDetailFromBeatAndDateSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const setOutletBillDetailFromBeatAndDate =
  ({ row }) =>
    async (dispatch) => {
      console.log(row);
      dispatch(getOutletBillDetailFromBeatAndDateSuccess({ row }));
    };

export const getBillFromDate =
  ({ fromDate, toDate }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL +
            "/get-generated-bill-by-date?fromDate=" +
            fromDate +
            "&toDate=" +
            toDate,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((billingHistoryObject, index) => {
              let billingHistoryData = {
                index: index + 1,
                id:
                  billingHistoryObject.id === null ? "" : billingHistoryObject.id,
                billNo:
                  billingHistoryObject.billNo === null
                    ? ""
                    : billingHistoryObject.billNo,
                fromBill:
                  billingHistoryObject.fromBill === null
                    ? ""
                    : billingHistoryObject.fromBill,

                fromBillFirmName:
                  billingHistoryObject.fromBillFirmName === null
                    ? ""
                    : billingHistoryObject.fromBillFirmName,
                fromBillOwnerName:
                  billingHistoryObject.fromBillOwnerName === null
                    ? ""
                    : billingHistoryObject.fromBillOwnerName,
                name:
                  billingHistoryObject.billNo === null
                    ? ""
                    : billingHistoryObject.billNo +
                    " ( " +
                    billingHistoryObject.billDate +
                    " )",
                billDate:
                  billingHistoryObject.billDate === null
                    ? ""
                    : billingHistoryObject.billDate,
                taxableAmt:
                  billingHistoryObject.taxableAmt === null
                    ? ""
                    : billingHistoryObject.taxableAmt.toFixed(2),
                cgstAmt:
                  billingHistoryObject.cgstAmt === null
                    ? ""
                    : billingHistoryObject.cgstAmt.toFixed(2),
                sgstAmt:
                  billingHistoryObject.sgstAmt === null
                    ? ""
                    : billingHistoryObject.sgstAmt.toFixed(2),
                totalAmt:
                  billingHistoryObject.totalAmt === null
                    ? ""
                    : billingHistoryObject.totalAmt.toFixed(2),
                statusName:
                  billingHistoryObject.status === null
                    ? ""
                    : billingHistoryObject.status === 0
                      ? "Generated"
                      : billingHistoryObject.status === 1
                        ? "Cancelled"
                        : billingHistoryObject.status === 0 &&
                          billingHistoryObject.payoutIsSettle === 1
                          ? "Paid"
                          : "Pending",
                payoutIsSettle:
                  billingHistoryObject.payoutIsSettle === null
                    ? ""
                    : billingHistoryObject.payoutIsSettle,
                status:
                  billingHistoryObject.status === null
                    ? ""
                    : billingHistoryObject.status,
                itemDistcountAmt:
                  billingHistoryObject.itemDistcountAmt === null
                    ? ""
                    : (+billingHistoryObject.itemDistcountAmt).toFixed(2),
                discountOnBillPer:
                  billingHistoryObject.discountOnBillPer === null
                    ? ""
                    : billingHistoryObject.discountOnBillPer,
                discountOnBillAmt:
                  billingHistoryObject.discountOnBillAmt === null
                    ? ""
                    : (+billingHistoryObject.discountOnBillAmt).toFixed(2),

                payoutAmt:
                  billingHistoryObject.payoutAmt === null
                    ? ""
                    : (+billingHistoryObject.payoutAmt).toFixed(2),

                igstAmt:
                  billingHistoryObject.igstAmt === null
                    ? ""
                    : +billingHistoryObject.igstAmt.toFixed(2),
                cessAmt:
                  billingHistoryObject.cessAmt === null
                    ? ""
                    : (+billingHistoryObject.cessAmt).toFixed(2),
                remainingAmt:
                  billingHistoryObject.remainingAmt === null
                    ? ""
                    : (+billingHistoryObject.remainingAmt).toFixed(2),
                acknoledgeNo:
                  billingHistoryObject.acknoledgeNo === null
                    ? ""
                    : billingHistoryObject.acknoledgeNo,
                payoutIsSettle:
                  billingHistoryObject.payoutIsSettle === null
                    ? ""
                    : billingHistoryObject.payoutIsSettle,
                isSettle:
                  billingHistoryObject.isSettle === null
                    ? ""
                    : billingHistoryObject.isSettle,
                insertedUserId:
                  billingHistoryObject.insertedUserId === null
                    ? ""
                    : billingHistoryObject.insertedUserId,
                insertDateTime:
                  billingHistoryObject.insertDateTime === null
                    ? ""
                    : billingHistoryObject.insertDateTime,
              };

              return billingHistoryData;
            });
            dispatch(billingByDateSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getBillFromDateWithoutOrderCondition =
  ({ fromDate, toDate }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL +
            "/generated-bill-by-date?fromDate=" +
            fromDate +
            "&toDate=" +
            toDate,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((billingHistoryObject, index) => {
              let billingHistoryData = {
                index: index + 1,
                id:
                  billingHistoryObject.id === null ? "" : billingHistoryObject.id,
                billNo:
                  billingHistoryObject.billNo === null
                    ? ""
                    : billingHistoryObject.billNo,
                fromBill:
                  billingHistoryObject.fromBill === null
                    ? ""
                    : billingHistoryObject.fromBill,

                fromBillFirmName:
                  billingHistoryObject.fromBillFirmName === null
                    ? ""
                    : billingHistoryObject.fromBillFirmName,
                fromBillOwnerName:
                  billingHistoryObject.fromBillOwnerName === null
                    ? ""
                    : billingHistoryObject.fromBillOwnerName,
                name:
                  billingHistoryObject.billNo !== null &&
                    billingHistoryObject.billDate !== null ?
                    billingHistoryObject.billNo +
                    " ( " +
                    billingHistoryObject.billDate +
                    " )" : "",
                billDate:
                  billingHistoryObject.billDate === null
                    ? ""
                    : billingHistoryObject.billDate,
                taxableAmt:
                  billingHistoryObject.taxableAmt === null
                    ? ""
                    : billingHistoryObject.taxableAmt.toFixed(2),
                cgstAmt:
                  billingHistoryObject.cgstAmt === null
                    ? ""
                    : billingHistoryObject.cgstAmt.toFixed(2),
                sgstAmt:
                  billingHistoryObject.sgstAmt === null
                    ? ""
                    : billingHistoryObject.sgstAmt.toFixed(2),
                totalAmt:
                  billingHistoryObject.totalAmt === null
                    ? ""
                    : billingHistoryObject.totalAmt.toFixed(2),
                statusName:
                  billingHistoryObject.status === null
                    ? ""
                    : billingHistoryObject.status === 0
                      ? "Generated"
                      : billingHistoryObject.status === 1
                        ? "Cancelled"
                        : billingHistoryObject.status === 0 &&
                          billingHistoryObject.payoutIsSettle === 1
                          ? "Paid"
                          : "Pending",
                payoutIsSettle:
                  billingHistoryObject.payoutIsSettle === null
                    ? ""
                    : billingHistoryObject.payoutIsSettle,
                status:
                  billingHistoryObject.status === null
                    ? ""
                    : billingHistoryObject.status,
                itemDistcountAmt:
                  billingHistoryObject.itemDistcountAmt === null
                    ? ""
                    : (+billingHistoryObject.itemDistcountAmt).toFixed(2),
                discountOnBillPer:
                  billingHistoryObject.discountOnBillPer === null
                    ? ""
                    : billingHistoryObject.discountOnBillPer,
                discountOnBillAmt:
                  billingHistoryObject.discountOnBillAmt === null
                    ? ""
                    : (+billingHistoryObject.discountOnBillAmt).toFixed(2),

                payoutAmt:
                  billingHistoryObject.payoutAmt === null
                    ? ""
                    : (+billingHistoryObject.payoutAmt).toFixed(2),

                igstAmt:
                  billingHistoryObject.igstAmt === null
                    ? ""
                    : +billingHistoryObject.igstAmt.toFixed(2),
                cessAmt:
                  billingHistoryObject.cessAmt === null
                    ? ""
                    : (+billingHistoryObject.cessAmt).toFixed(2),
                remainingAmt:
                  billingHistoryObject.remainingAmt === null
                    ? ""
                    : (+billingHistoryObject.remainingAmt).toFixed(2),
                acknoledgeNo:
                  billingHistoryObject.acknoledgeNo === null
                    ? ""
                    : billingHistoryObject.acknoledgeNo,
                payoutIsSettle:
                  billingHistoryObject.payoutIsSettle === null
                    ? ""
                    : billingHistoryObject.payoutIsSettle,
                isSettle:
                  billingHistoryObject.isSettle === null
                    ? ""
                    : billingHistoryObject.isSettle,
                insertedUserId:
                  billingHistoryObject.insertedUserId === null
                    ? ""
                    : billingHistoryObject.insertedUserId,
                insertDateTime:
                  billingHistoryObject.insertDateTime === null
                    ? ""
                    : billingHistoryObject.insertDateTime,
              };

              return billingHistoryData;
            });
            dispatch(billingByDateSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };



export const getBillFromDateWithConcateBillandDate =
  ({ fromDate, toDate }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL +
            "/generated-bill-by-date-with-concate-bill-and-date?fromDate="
            + fromDate + "&toDate=" + toDate,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((billingHistoryObject, index) => {
              let billingHistoryData = {
                index: index + 1,
                id:
                  billingHistoryObject.id === null ? "" : billingHistoryObject.id,
                billNo:
                  billingHistoryObject.billNo === null
                    ? ""
                    : billingHistoryObject.billNo,
                fromBill:
                  billingHistoryObject.fromBill === null
                    ? ""
                    : billingHistoryObject.fromBill,

                fromBillFirmName:
                  billingHistoryObject.fromBillFirmName === null
                    ? ""
                    : billingHistoryObject.fromBillFirmName,
                fromBillOwnerName:
                  billingHistoryObject.fromBillOwnerName === null
                    ? ""
                    : billingHistoryObject.fromBillOwnerName,
                name:
                  billingHistoryObject.name === null
                    ? ""
                    : billingHistoryObject.name,
                billDate:
                  billingHistoryObject.billDate === null
                    ? ""
                    : billingHistoryObject.billDate,
                taxableAmt:
                  billingHistoryObject.taxableAmt === null
                    ? ""
                    : billingHistoryObject.taxableAmt.toFixed(2),
                cgstAmt:
                  billingHistoryObject.cgstAmt === null
                    ? ""
                    : billingHistoryObject.cgstAmt.toFixed(2),
                sgstAmt:
                  billingHistoryObject.sgstAmt === null
                    ? ""
                    : billingHistoryObject.sgstAmt.toFixed(2),
                totalAmt:
                  billingHistoryObject.totalAmt === null
                    ? ""
                    : billingHistoryObject.totalAmt.toFixed(2),
                statusName:
                  billingHistoryObject.status === null
                    ? ""
                    : billingHistoryObject.status === 0
                      ? "Generated"
                      : billingHistoryObject.status === 1
                        ? "Cancelled"
                        : billingHistoryObject.status === 0 &&
                          billingHistoryObject.payoutIsSettle === 1
                          ? "Paid"
                          : "Pending",
                payoutIsSettle:
                  billingHistoryObject.payoutIsSettle === null
                    ? ""
                    : billingHistoryObject.payoutIsSettle,
                status:
                  billingHistoryObject.status === null
                    ? ""
                    : billingHistoryObject.status,
                itemDistcountAmt:
                  billingHistoryObject.itemDistcountAmt === null
                    ? ""
                    : (+billingHistoryObject.itemDistcountAmt).toFixed(2),
                discountOnBillPer:
                  billingHistoryObject.discountOnBillPer === null
                    ? ""
                    : billingHistoryObject.discountOnBillPer,
                discountOnBillAmt:
                  billingHistoryObject.discountOnBillAmt === null
                    ? ""
                    : (+billingHistoryObject.discountOnBillAmt).toFixed(2),

                payoutAmt:
                  billingHistoryObject.payoutAmt === null
                    ? ""
                    : (+billingHistoryObject.payoutAmt).toFixed(2),

                igstAmt:
                  billingHistoryObject.igstAmt === null
                    ? ""
                    : +billingHistoryObject.igstAmt.toFixed(2),
                cessAmt:
                  billingHistoryObject.cessAmt === null
                    ? ""
                    : (+billingHistoryObject.cessAmt).toFixed(2),
                remainingAmt:
                  billingHistoryObject.remainingAmt === null
                    ? ""
                    : (+billingHistoryObject.remainingAmt).toFixed(2),
                acknoledgeNo:
                  billingHistoryObject.acknoledgeNo === null
                    ? ""
                    : billingHistoryObject.acknoledgeNo,
                payoutIsSettle:
                  billingHistoryObject.payoutIsSettle === null
                    ? ""
                    : billingHistoryObject.payoutIsSettle,
                isSettle:
                  billingHistoryObject.isSettle === null
                    ? ""
                    : billingHistoryObject.isSettle,
                insertedUserId:
                  billingHistoryObject.insertedUserId === null
                    ? ""
                    : billingHistoryObject.insertedUserId,
                insertDateTime:
                  billingHistoryObject.insertDateTime === null
                    ? ""
                    : billingHistoryObject.insertDateTime,
              };

              return billingHistoryData;
            });
            dispatch(billingByDateSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getBillListById =
  ({ billHeaderId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/" + billHeaderId + "/get-by-id",
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.stockStakeHolderBillDetails.map(
              (billHistoryObject, index) => {
                let billHistoryData = {
                  index: index + 1,
                  id: billHistoryObject.id === null ? "" : billHistoryObject.id,
                  name:
                    billHistoryObject.product === null
                      ? ""
                      : billHistoryObject.product.name,
                  product:
                    billHistoryObject.product === null
                      ? ""
                      : billHistoryObject.product,
                  productName:
                    billHistoryObject.product === null
                      ? ""
                      : billHistoryObject.product.name,
                  outletUom:
                    billHistoryObject.product === null
                      ? ""
                      : billHistoryObject.product.outletUom.name,
                  uom:
                    billHistoryObject.uom === null
                      ? ""
                      : billHistoryObject.uom.name,
                  billQty:
                    billHistoryObject.qty === null
                      ? ""
                      : billHistoryObject.qty.toString(),
                  secondaryBillQty:
                    billHistoryObject.qty === null
                      ? ""
                      : +billHistoryObject.qty *
                      +billHistoryObject.product.uomConversionValue,

                  secondaryQty: billHistoryObject.secondaryQty === null
                    ? ""
                    : billHistoryObject.secondaryQty,

                  primaryQty: "",
                  qty: "",
                  totalQty: 0,
                  freeQty:
                    billHistoryObject.freeQty === null
                      ? ""
                      : billHistoryObject.freeQty,
                  secondaryFreeQty:
                    billHistoryObject.secondaryFreeQty === null
                      ? ""
                      : billHistoryObject.secondaryFreeQty,
                  secondaryMrp:
                    billHistoryObject.secondaryMrp === null
                      ? ""
                      : billHistoryObject.secondaryMrp,
                  conversionValue:
                    billHistoryObject.conversionValue === null
                      ? ""
                      : billHistoryObject.conversionValue,
                  hsnCode:
                    billHistoryObject.hsnCode === null
                      ? ""
                      : billHistoryObject.hsnCode,
                  mrp:
                    billHistoryObject.mrp === null ? "" : billHistoryObject.mrp,
                  rate:
                    billHistoryObject.rate === null ? "" : billHistoryObject.rate,
                  discountOnbillPer:
                    billHistoryObject.discountOnbillPer === null
                      ? ""
                      : billHistoryObject.discountOnbillPer,
                  discountOnbillPerAmt:
                    billHistoryObject.discountOnbillPerAmt === null
                      ? ""
                      : billHistoryObject.discountOnbillPerAmt,
                  discountOnbillamt:
                    billHistoryObject.discountOnbillamt === null
                      ? ""
                      : billHistoryObject.discountOnbillamt,
                  itemDiscPer:
                    billHistoryObject.itemDiscPer === null
                      ? ""
                      : billHistoryObject.itemDiscPer,
                  itemDiscAmt:
                    billHistoryObject.itemDiscAmt === null
                      ? ""
                      : billHistoryObject.itemDiscAmt,
                  taxableAmt:
                    billHistoryObject.taxableAmt === null
                      ? ""
                      : billHistoryObject.taxableAmt.toFixed(2),
                  total: 0,
                  cessAmt:
                    billHistoryObject.cessAmt === null
                      ? ""
                      : billHistoryObject.cessAmt,
                  cgstAmt:
                    billHistoryObject.cgstAmt === null
                      ? ""
                      : billHistoryObject.cgstAmt,
                  sgstAmt:
                    billHistoryObject.sgstAmt === null
                      ? ""
                      : billHistoryObject.sgstAmt.toFixed(2),
                  igstAmt:
                    billHistoryObject.igstAmt === null
                      ? ""
                      : billHistoryObject.igstAmt.toFixed(2),
                  cessPer:
                    billHistoryObject.cessPer === null
                      ? ""
                      : billHistoryObject.cessPer,

                  cgstPer:
                    billHistoryObject.cgstPer === null
                      ? ""
                      : billHistoryObject.cgstPer,
                  sgstPer:
                    billHistoryObject.sgstPer === null
                      ? ""
                      : billHistoryObject.sgstPer,
                  igstPer:
                    billHistoryObject.igstPer === null
                      ? ""
                      : billHistoryObject.igstPer,
                  billDetailId:
                    billHistoryObject.billDetailId === null
                      ? ""
                      : billHistoryObject.billDetailId,
                  batchCode:
                    billHistoryObject.batchCode === null
                      ? ""
                      : billHistoryObject.batchCode,
                  stockStakeHolderBillDetailId:
                    billHistoryObject.stockStakeHolderBillDetailId === null
                      ? ""
                      : billHistoryObject.stockStakeHolderBillDetailId,
                  checked: false,
                };
                return billHistoryData;
              }
            );
            dispatch(billListByIdSuccess({ row }));
            return { response: row, success };
          }
          return { response: {}, success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const setBillwiseList =
  ({ row }) =>
    async (dispatch) => {
      const rowList = row.map((rowObject, index) => {
        const addObj = {
          ...rowObject,
          totalQty:
            +rowObject.primaryQty * rowObject.product.uomConversionValue +
            +rowObject.qty,
          total: (
            ((+rowObject.primaryQty *
              rowObject.product.uomConversionValue) +
              +rowObject.qty) *
            (+rowObject.rate / rowObject.product.uomConversionValue)
          ).toFixed(2),
          qtyError:
            +rowObject.primaryQty * rowObject.product.uomConversionValue +
              +rowObject.qty >
              +rowObject.billQty * rowObject.product.uomConversionValue
              ? true
              : false,
        };
        return addObj;
      });
      dispatch(billListByIdSuccess({ row: rowList }));
    };
export const getOutletBillDetailFromVehicleAndDate =
  ({ vehicleNo, fromDate, toDate }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            UrlLatest +
            "/for-payment?vehicleNo=" +
            vehicleNo +
            "&fromDate=" +
            fromDate +
            "&toDate=" +
            toDate,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((genratedBillListObject, index) => {
              let billHeaderData = {
                index: index + 1,
                id:
                  genratedBillListObject.id === null
                    ? ""
                    : genratedBillListObject.id,
                billNo:
                  genratedBillListObject.billNo === null
                    ? ""
                    : genratedBillListObject.billNo,
                billDate:
                  genratedBillListObject.billDate === null
                    ? ""
                    : genratedBillListObject.billDate,
                toBillFirmName:
                  genratedBillListObject.toBillFirmName === null
                    ? ""
                    : genratedBillListObject.toBillFirmName,
                taxableAmt:
                  genratedBillListObject.taxableAmt === null
                    ? ""
                    : genratedBillListObject.taxableAmt.toFixed(2),
                cgstAmt:
                  genratedBillListObject.cgstAmt === null
                    ? ""
                    : genratedBillListObject.cgstAmt.toFixed(2),
                sgstAmt:
                  genratedBillListObject.sgstAmt === null
                    ? ""
                    : genratedBillListObject.sgstAmt.toFixed(2),
                totalAmt:
                  genratedBillListObject.totalAmt === null
                    ? ""
                    : genratedBillListObject.totalAmt.toFixed(2),
                isRecievedName:
                  genratedBillListObject.isRecieved === null
                    ? ""
                    : genratedBillListObject.isRecieved == 0
                      ? "No"
                      : "Yes",
                isRecieved:
                  genratedBillListObject.isRecieved === null
                    ? ""
                    : genratedBillListObject.isRecieved,
                fromBill:
                  genratedBillListObject.fromBill === null
                    ? ""
                    : genratedBillListObject.fromBill,
                toBill:
                  genratedBillListObject.toBill === null
                    ? ""
                    : genratedBillListObject.toBill,

                itemDiscountAmt:
                  genratedBillListObject.itemDiscountAmt === null
                    ? ""
                    : genratedBillListObject.itemDiscountAmt,
                discountOnbillPer:
                  genratedBillListObject.discountOnbillPer === null
                    ? ""
                    : genratedBillListObject.discountOnbillPer,
                discountOnbillPerAmt:
                  genratedBillListObject.discountOnbillPerAmt === null
                    ? ""
                    : genratedBillListObject.discountOnbillPerAmt,
                discountOnbillAmt:
                  genratedBillListObject.discountOnbillAmt === null
                    ? ""
                    : genratedBillListObject.discountOnbillAmt,
                cessAmt:
                  genratedBillListObject.cessAmt === null
                    ? ""
                    : genratedBillListObject.cessAmt,
                payoutAmt:
                  genratedBillListObject.payoutAmt === null
                    ? ""
                    : genratedBillListObject.payoutAmt,

                igstAmt:
                  genratedBillListObject.igstAmt === null
                    ? ""
                    : genratedBillListObject.igstAmt.toFixed(2),

                acknoledgeNo:
                  genratedBillListObject.acknoledgeNo === null
                    ? ""
                    : genratedBillListObject.acknoledgeNo,
                payoutIsSettle:
                  genratedBillListObject.payoutIsSettle === null
                    ? ""
                    : genratedBillListObject.payoutIsSettle,
                remainingAmt:
                  genratedBillListObject.remainingAmt === null
                    ? ""
                    : genratedBillListObject.remainingAmt.toFixed(2),
                roundOff:
                  genratedBillListObject.roundOff === null
                    ? ""
                    : genratedBillListObject.isSettle,
                roundOff:
                  genratedBillListObject.roundOff === null
                    ? ""
                    : genratedBillListObject.roundOff,
                fromBillOwnerName:
                  genratedBillListObject.fromBillOwnerName === null
                    ? ""
                    : genratedBillListObject.fromBillOwnerName,
                fromBillFirmName:
                  genratedBillListObject.fromBillFirmName === null
                    ? ""
                    : genratedBillListObject.fromBillFirmName,
                insertedUserId:
                  genratedBillListObject.insertedUserId === null
                    ? ""
                    : genratedBillListObject.insertedUserId,
                insertDateTime:
                  genratedBillListObject.insertDateTime === null
                    ? ""
                    : genratedBillListObject.isSettle,
                firmName:
                  genratedBillListObject.outLet === null
                    ? ""
                    : genratedBillListObject.outLet.firmName,
                beatName:
                  genratedBillListObject.outLet === null
                    ? ""
                    : genratedBillListObject.outLet.beat === null
                      ? ""
                      : genratedBillListObject.outLet.beat.beatName,
                outlet:
                  genratedBillListObject.outLet === null
                    ? ""
                    : genratedBillListObject.outLet,
                amount:
                  genratedBillListObject.remainingAmt === null
                    ? ""
                    : genratedBillListObject.remainingAmt,
                payType: "0",
                remark: "",
                amountError: false,
                amountErrorMsg: "",
              };
              return billHeaderData;
            });
            dispatch(getOutletBillDetailFromVehicleAndDateSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const setOutletBillDetailFromVehicleAndDate =
  ({ row }) =>
    async (dispatch) => {
      dispatch(getOutletBillDetailFromVehicleAndDateSuccess({ row }));
    };
