import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
const initialState = {
  currentYearTarget: {},
  tillMonthYearTarget: {},
  monthYearTarget: [],
  regionListFromYearAndMonth: [],
  regionListFromYearAndMonthTotal: 0,
  stateListFromYearAndMonthList: [],
  stateListFromYearAndMonthListTotal: 0,
  zoneListFromYearAndMonth: [],
  zoneListFromYearAndMonthTotal: 0,
  districtListFromYearAndMonth: [],
  districtListFromYearAndMonthTotal: 0,
  talukaListFromYearAndMonth: [],
  talukaListFromYearAndMonthTotal: 0,
  areaListFromYearAndMonth: [],
  areaListFromYearAndMonthTotal: 0,
  salesListFromYearAndMonth: [],
  salesListFromYearAndMonthTotal: 0,
  outletListFromYearAndMonth: [],
  outletListFromYearAndMonthTotal: 0,
  productListFromYearAndMonth: [],
  productListFromYearAndMonthTotal: 0,
  salesInfo: [],
  monthYearTargetForSale: [],
  topProductListFromYearAndMonth: [],
  topProductListFromYearAndMonthTotal: 0,
  topOutletListFromYearAndMonth: [],
  topOutletListFromYearAndMonthTotal: 0,
  salesGraph: [],
};
let URL = endpoints.adminDashboard;
let URLLatestNew = endpoints.adminDashboardLatest;
let URL1 = endpoints.region;
let URLLatest = endpoints.regionLatest;
let URL2 = endpoints.zone;
let URL3 = endpoints.area;
let URL4 = endpoints.manager;
let URL5 = endpoints.outletOrderHeader;
let URL6 = endpoints.state;
let URL7 = endpoints.district;
let URL8 = endpoints.taluka;
const adminDashboardSlice = createSlice({
  name: "adminDashboard",
  initialState,
  reducers: {
    currentYearTargetSuccess: (state = cloneDeep(initialState), action) => {
      const { currentTargetData } = action.payload;
      return {
        ...state,
        currentYearTarget: currentTargetData,
      };
    },

    tillMonthYearTargetSuccess: (state = cloneDeep(initialState), action) => {
      const { tillMonthTargetData } = action.payload;
      return {
        ...state,
        tillMonthYearTarget: tillMonthTargetData,
      };
    },

    monthYearTargetSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        monthYearTarget: row,
      };
    },
    monthYearTargetForSalesSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        monthYearTargetForSale: row,
      };
    },
    regionListFromYearAndMonthSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row, total } = action.payload;
      return {
        ...state,
        regionListFromYearAndMonth: row,
        regionListFromYearAndMonthTotal: total,
        stateListFromYearAndMonthList: row,
        stateListFromYearAndMonthListTotal: total,
        zoneListFromYearAndMonth: row,
        zoneListFromYearAndMonthTotal: total,
        districtListFromYearAndMonth: row,
        districtListFromYearAndMonthTotal: total,
        talukaListFromYearAndMonth: row,
        talukaListFromYearAndMonthTotal: total,
        salesGraph: row,
      };
    },
    // zoneListFromYearAndMonthSuccess: (
    //   state = cloneDeep(initialState),
    //   action
    // ) => {
    //   const { row, total } = action.payload;
    //   return {
    //     ...state,
    //     zoneListFromYearAndMonth: row,
    //     zoneListFromYearAndMonthTotal: total,
    //   };
    // },
    areaListFromYearAndMonthSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row, total } = action.payload;
      return {
        ...state,
        areaListFromYearAndMonth: row,
        areaListFromYearAndMonthTotal: total,
      };
    },
    salesListFromYearAndMonthSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row, total } = action.payload;
      return {
        ...state,
        salesListFromYearAndMonth: row,
        salesListFromYearAndMonthTotal: total,
      };
    },
    outletListFromYearAndMonthSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row, total } = action.payload;
      return {
        ...state,
        outletListFromYearAndMonth: row,
        outletListFromYearAndMonthTotal: total,
      };
    },

    topOutletListFromYearAndMonthSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row, total } = action.payload;
      return {
        ...state,
        topOutletListFromYearAndMonth: row,
        topOutletListFromYearAndMonthTotal: total,
      };
    },

    salesInfoSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        salesInfo: row,
      };
    },

    // salesGraphSuccess: (state = cloneDeep(initialState), action) => {
    //   const { row } = action.payload;
    //   return {
    //     ...state,
    //     salesGraph: row,
    //   };
    // },

    productListFromYearAndMonthSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row, total } = action.payload;
      return {
        ...state,
        productListFromYearAndMonth: row,
        productListFromYearAndMonthTotal: total,
      };
    },

    topProductListFromYearAndMonthSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row, total } = action.payload;
      return {
        ...state,
        topProductListFromYearAndMonth: row,
        topProductListFromYearAndMonthTotal: total,
      };
    },
  },
});

export const {
  currentYearTargetSuccess,
  tillMonthYearTargetSuccess,
  monthYearTargetSuccess,
  monthYearTargetForSalesSuccess,
  regionListFromYearAndMonthSuccess,
  zoneListFromYearAndMonthSuccess,
  areaListFromYearAndMonthSuccess,
  salesListFromYearAndMonthSuccess,
  outletListFromYearAndMonthSuccess,
  productListFromYearAndMonthSuccess,
  salesInfoSuccess,
  topProductListFromYearAndMonthSuccess,
  topOutletListFromYearAndMonthSuccess,
  salesGraphSuccess,
} = adminDashboardSlice.actions;

export default adminDashboardSlice.reducer;

export const getCurrentYearTarget = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/curr-year-target-amt",
    }).then(({ data, success }) => {
      if (success) {
        const yearTarget = data.data;
        const currentTargetData = {
          currentOrderAmt:
            yearTarget.currentOrderAmt === null
              ? ""
              : yearTarget.currentOrderAmt,
          previousOrderAmt:
            yearTarget.previousOrderAmt === null
              ? ""
              : yearTarget.previousOrderAmt.toFixed(2),
          currentTargetAmt:
            yearTarget.currentTargetAmt === null
              ? ""
              : yearTarget.currentTargetAmt.toFixed(2),
          whoCanTake:
            yearTarget.whoCanTake === null ? "" : yearTarget.whoCanTake,
          previousTargetAmt:
            yearTarget.previousTargetAmt === null
              ? ""
              : yearTarget.previousTargetAmt.toFixed(2),
          currentAchievedAmt:
            yearTarget.currentAchievedAmt === null
              ? ""
              : yearTarget.currentAchievedAmt.toFixed(2),
          previousAchievedAmt:
            yearTarget.previousAchievedAmt === null
              ? ""
              : yearTarget.previousAchievedAmt.toFixed(2),
          currentRequiredAmt:
            yearTarget.currentRequiredAmt === null
              ? ""
              : yearTarget.currentRequiredAmt.toFixed(2),
          previousRequiredAmt:
            yearTarget.previousRequiredAmt === null
              ? ""
              : yearTarget.previousRequiredAmt.toFixed(2),
          forcast: yearTarget.forcast === null ? "" : yearTarget.forcast,
          totalOutletCount:
            yearTarget.totalOutletCount === null
              ? ""
              : yearTarget.totalOutletCount,
          totalStakeHolderCount:
            yearTarget.totalStakeHolderCount === null
              ? ""
              : yearTarget.totalStakeHolderCount,
          totalSalesTeamCount:
            yearTarget.totalSalesTeamCount === null
              ? ""
              : yearTarget.totalSalesTeamCount,
        };
        dispatch(currentYearTargetSuccess({ currentTargetData }));
        return { response: currentTargetData, success };
      }
      return { response: {}, success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getTillMonthYearTarget = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/till-month-year-target-amt",
    }).then(({ data, success }) => {
      if (success) {
        const MonthYearTarget = data.data;
        const tillMonthTargetData = {
          dateMonth:
            MonthYearTarget.dateMonth === null ? "" : MonthYearTarget.dateMonth,
          month: MonthYearTarget.month === null ? "" : MonthYearTarget.month,
          year: MonthYearTarget.year === null ? "" : MonthYearTarget.year,
          targetAmt:
            MonthYearTarget.targetAmt === null
              ? ""
              : MonthYearTarget.targetAmt.toFixed(2),
          achivedAmt:
            MonthYearTarget.achivedAmt === null
              ? ""
              : MonthYearTarget.achivedAmt.toFixed(2),
          contribution: (
            (+MonthYearTarget.achivedAmt / +MonthYearTarget.targetAmt) *
            100
          ).toFixed(0),
        };
        dispatch(tillMonthYearTargetSuccess({ tillMonthTargetData }));
        return { response: tillMonthTargetData, success };
      }
      return { response: {}, success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getMonthYearTarget = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URLLatestNew + "/month-year-wise-target-amt",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((monthObject, index) => {
          let monthTargetData = {
            dateMonth:
              monthObject.dateMonth === null ? "" : monthObject.dateMonth,
            month: monthObject.month === null ? "" : monthObject.month,
            year: monthObject.year === null ? "" : monthObject.year,
            targetAmt:
              monthObject.targetAmt === null
                ? ""
                : monthObject.targetAmt.toFixed(2),
            achivedAmt:
              monthObject.achivedAmt === null
                ? ""
                : monthObject.achivedAmt.toFixed(2),
          };
          return monthTargetData;
        });
        dispatch(monthYearTargetSuccess({ row: data.data }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
export const getMonthYearTargetForSales =
  ({ salesTeamId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/sales-teams-target-amt-and-total-amt-month-wise?salesTeamId=" +
          salesTeamId,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((monthObject, index) => {
            let monthTargetData = {
              dateMonth:
                monthObject.dateMonth === null ? "" : monthObject.dateMonth,
              month: monthObject.month === null ? "" : monthObject.month,
              year: monthObject.year === null ? "" : monthObject.year,
              targetAmt:
                monthObject.targetAmt === null
                  ? ""
                  : monthObject.targetAmt.toFixed(2),
              achivedAmt:
                monthObject.achivedAmt === null
                  ? ""
                  : monthObject.achivedAmt.toFixed(2),
            };
            return monthTargetData;
          });
          dispatch(monthYearTargetForSalesSuccess({ row: data.data }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getRegionListFromYearAndMonth =
  ({ month, year }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URLLatest +
          "/with-actual-amt-by-month-year?month=" +
          month +
          "&year=" +
          year,
      }).then(({ data, success }) => {
        if (success) {
          let finalTotal = 0;
          data.data.map((regionObject) => {
            finalTotal = finalTotal + regionObject.total;
          });
          const row = data.data.map((regionObject, index) => {
            let regionListData = {
              index: index + 1,
              id: regionObject.id === null ? "" : regionObject.id,
              name: regionObject.name === null ? "" : regionObject.name,
              shortName:
                regionObject.shortName === null ? "" : regionObject.shortName,
              sortOrder:
                regionObject.sortOrder === null ? "" : regionObject.sortOrder,
              total:
                regionObject.total === null
                  ? ""
                  : regionObject.total.toFixed(2),
              salesTeamCount:
                regionObject.salesTeamCount === null
                  ? ""
                  : regionObject.salesTeamCount,
              salesExcutiveCount:
                regionObject.salesExcutiveCount === null
                  ? ""
                  : regionObject.salesExcutiveCount,
              finalTotal: finalTotal.toFixed(2),
              contribution:
                regionObject.total == 0
                  ? "-"
                  : ((+regionObject.total / +finalTotal) * 100).toFixed(0),
            };
            return regionListData;
          });
          dispatch(
            regionListFromYearAndMonthSuccess({
              row,
              total: finalTotal.toFixed(2),
            })
          );
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const setRegionListFromYearAndMonth =
  ({ row, total }) =>
  async (dispatch) => {
    dispatch(regionListFromYearAndMonthSuccess({ row, total }));
  };

export const getZoneRegionwiseListFromYearAndMonth =
  ({ month, year, regionId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL2 +
          "/by-month-year-and-region?month=" +
          month +
          "&year=" +
          year +
          "&regionId=" +
          regionId,
      }).then(({ data, success }) => {
        if (success) {
          let finalTotal = 0;
          data.data.map((zoneObject) => {
            finalTotal = finalTotal + zoneObject.total;
          });
          const row = data.data.map((zoneObject, index) => {
            let zoneListData = {
              index: index + 1,
              id: zoneObject.id === null ? "" : zoneObject.id,
              name: zoneObject.name === null ? "" : zoneObject.name,
              shortName:
                zoneObject.shortName === null ? "" : zoneObject.shortName,
              sortOrder:
                zoneObject.sortOrder === null ? "" : zoneObject.sortOrder,
              total:
                zoneObject.total === null ? "" : zoneObject.total.toFixed(2),
              contribution: ((+zoneObject.total / +finalTotal) * 100).toFixed(
                0
              ),
              outletCount:
                zoneObject.outletCount === null ? "" : zoneObject.outletCount,
              stockStakeHolderCount:
                zoneObject.stockStakeHolderCount === null
                  ? ""
                  : zoneObject.stockStakeHolderCount,
              salesTeamCount:
                zoneObject.salesTeamCount === null
                  ? ""
                  : zoneObject.salesTeamCount,
              salesExcutiveCount:
                zoneObject.salesExcutiveCount === null
                  ? ""
                  : zoneObject.salesExcutiveCount,
              region: zoneObject.region === null ? "" : zoneObject.region,
            };
            return zoneListData;
          });
          dispatch(
            regionListFromYearAndMonthSuccess({
              row,
              total: finalTotal.toFixed(2),
            })
          );
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getStateRegionwiseListFromYearAndMonth =
  ({ month, year, regionId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL6 +
          "/with-actual-amt-by-month-year-region?month=" +
          month +
          "&year=" +
          year +
          "&regionId=" +
          regionId,
      }).then(({ data, success }) => {
        if (success) {
          let finalTotal = 0;
          data.data.map((stateObject) => {
            finalTotal = finalTotal + stateObject.total;
          });
          const row = data.data.map((stateObject, index) => {
            let stateListData = {
              index: index + 1,
              id: stateObject.id === null ? "" : stateObject.id,
              name: stateObject.name === null ? "" : stateObject.name,
              shortName:
                stateObject.shortName === null ? "" : stateObject.shortName,
              code: stateObject.code === null ? "" : stateObject.code,
              region: stateObject.region === null ? "" : stateObject.region,
              sortOrder:
                stateObject.sortOrder === null ? "" : stateObject.sortOrder,
              isActive:
                stateObject.isActive === null ? "" : stateObject.isActive,
              finalTotal: finalTotal,
              total:
                stateObject.total === null ? "" : stateObject.total.toFixed(2),
              contribution: (+(stateObject.total / finalTotal) * 100).toFixed(
                0
              ),
              salesExcutiveCount:
                stateObject.salesExcutiveCount === null
                  ? ""
                  : stateObject.salesExcutiveCount,
              salesTeamCount:
                stateObject.salesTeamCount === null
                  ? ""
                  : stateObject.salesTeamCount,
              insertDateTime:
                stateObject.insertDateTime === null
                  ? ""
                  : stateObject.insertDateTime,
            };
            return stateListData;
          });
          dispatch(
            regionListFromYearAndMonthSuccess({
              row,
              total: finalTotal.toFixed(2),
            })
          );
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getZoneStatewiseListFromYearAndMonth =
  ({ month, year, stateId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL2 +
          "/with-actual-amt-by-month-year-state?month=" +
          month +
          "&year=" +
          year +
          "&stateId=" +
          stateId,
      }).then(({ data, success }) => {
        if (success) {
          let finalTotal = 0;
          data.data.map((zoneObject) => {
            finalTotal = finalTotal + zoneObject.total;
          });
          const row = data.data.map((zoneObject, index) => {
            let zoneListData = {
              index: index + 1,
              id: zoneObject.id === null ? "" : zoneObject.id,
              name: zoneObject.name === null ? "" : zoneObject.name,
              shortName:
                zoneObject.shortName === null ? "" : zoneObject.shortName,
              sortOrder:
                zoneObject.sortOrder === null ? "" : zoneObject.sortOrder,
              finalTotal: finalTotal,
              total:
                zoneObject.total === null ? "" : zoneObject.total.toFixed(2),
              contribution: ((+zoneObject.total / +finalTotal) * 100).toFixed(
                0
              ),
              salesExcutiveCount:
                zoneObject.salesExcutiveCount === null
                  ? ""
                  : zoneObject.salesExcutiveCount,
              salesTeamCount:
                zoneObject.salesTeamCount === null
                  ? ""
                  : zoneObject.salesTeamCount,
              state: zoneObject.state === null ? "" : zoneObject.state,
            };
            return zoneListData;
          });
          dispatch(
            regionListFromYearAndMonthSuccess({
              row,
              total: finalTotal.toFixed(2),
            })
          );
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getDistrictListFromYearAndMonth =
  ({ month, year, zoneId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL7 +
          "/with-actual-amt-by-month-year-zone?month=" +
          month +
          "&year=" +
          year +
          "&zoneId=" +
          zoneId,
      }).then(({ data, success }) => {
        if (success) {
          let finalTotal = 0;
          data.data.map((districtObject) => {
            finalTotal = finalTotal + districtObject.total;
          });
          const row = data.data.map((districtObject, index) => {
            let districtListData = {
              index: index + 1,
              id: districtObject.id === null ? "" : districtObject.id,
              name: districtObject.name === null ? "" : districtObject.name,
              shortName:
                districtObject.shortName === null
                  ? ""
                  : districtObject.shortName,
              sortOrder:
                districtObject.sortOrder === null
                  ? ""
                  : districtObject.sortOrder,
              finalTotal: finalTotal,
              total:
                districtObject.total === null
                  ? ""
                  : districtObject.total.toFixed(2),
              contribution:
                districtObject.total === null
                  ? ""
                  : +((districtObject.total / finalTotal) * 100).toFixed(0),
              salesExcutiveCount:
                districtObject.salesExcutiveCount === null
                  ? ""
                  : districtObject.salesExcutiveCount,
              salesTeamCount:
                districtObject.salesTeamCount === null
                  ? ""
                  : districtObject.salesTeamCount,
              zone: districtObject.zone === null ? "" : districtObject.zone,
            };
            return districtListData;
          });
          dispatch(
            regionListFromYearAndMonthSuccess({
              row,
              total: finalTotal.toFixed(2),
            })
          );
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getTalukaListFromYearAndMonth =
  ({ month, year, districtId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL8 +
          "/with-actual-amt-by-month-year-district?month=" +
          month +
          "&year=" +
          year +
          "&districtId=" +
          districtId,
      }).then(({ data, success }) => {
        if (success) {
          let finalTotal = 0;
          data.data.map((talukaObject) => {
            finalTotal = finalTotal + talukaObject.total;
          });
          const row = data.data.map((talukaObject, index) => {
            let talukaListData = {
              index: index + 1,
              id: talukaObject.id === null ? "" : talukaObject.id,
              name: talukaObject.name === null ? "" : talukaObject.name,
              shortName:
                talukaObject.shortName === null ? "" : talukaObject.shortName,
              sortOrder:
                talukaObject.sortOrder === null ? "" : talukaObject.sortOrder,
              finalTotal: finalTotal,
              total:
                talukaObject.total === null
                  ? ""
                  : talukaObject.total.toFixed(2),
              contribution: +((talukaObject.total / finalTotal) * 100).toFixed(
                0
              ),
              salesExcutiveCount:
                talukaObject.salesExcutiveCount === null
                  ? ""
                  : talukaObject.salesExcutiveCount,
              salesTeamCount:
                talukaObject.salesTeamCount === null
                  ? ""
                  : talukaObject.salesTeamCount,
              district:
                talukaObject.district === null ? "" : talukaObject.district,
            };
            return talukaListData;
          });
          dispatch(
            regionListFromYearAndMonthSuccess({
              row,
              total: finalTotal.toFixed(2),
            })
          );
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getZoneListFromYearAndMonth =
  ({ month, year, regionId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL2 +
          "/by-month-year-and-region?month=" +
          month +
          "&year=" +
          year +
          "&regionId=" +
          regionId,
      }).then(({ data, success }) => {
        if (success) {
          let finalTotal = 0;
          data.data.map((zoneObject) => {
            finalTotal = finalTotal + zoneObject.total;
          });
          const row = data.data.map((zoneObject, index) => {
            let zoneListData = {
              index: index + 1,
              id: zoneObject.id === null ? "" : zoneObject.id,
              name: zoneObject.name === null ? "" : zoneObject.name,
              shortName:
                zoneObject.shortName === null ? "" : zoneObject.shortName,
              sortOrder:
                zoneObject.sortOrder === null ? "" : zoneObject.sortOrder,
              finalTotal: finalTotal,
              total:
                zoneObject.total === null ? "" : zoneObject.total.toFixed(2),
              contribution: +((zoneObject.total / finalTotal) * 100).toFixed(0),
              outletCount:
                zoneObject.outletCount === null ? "" : zoneObject.outletCount,
              stockStakeHolderCount:
                zoneObject.stockStakeHolderCount === null
                  ? ""
                  : zoneObject.stockStakeHolderCount,
              salesTeamCount:
                zoneObject.salesTeamCount === null
                  ? ""
                  : zoneObject.salesTeamCount,
              salesExcutiveCount:
                zoneObject.salesExcutiveCount === null
                  ? ""
                  : zoneObject.salesExcutiveCount,
              region: zoneObject.region === null ? "" : zoneObject.region,
            };
            return zoneListData;
          });
          dispatch(
            regionListFromYearAndMonthSuccess({
              row,
              total: finalTotal.toFixed(2),
            })
          );
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getAreaListFromYearAndMonth =
  ({ month, year, zoneId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL3 +
          "/with-actual-amt-by-month-year?month=" +
          month +
          "&year=" +
          year +
          "&zoneId=" +
          zoneId,
      }).then(({ data, success }) => {
        if (success) {
          let finalTotal = 0;
          data.data.map((areaObject) => {
            finalTotal = finalTotal + areaObject.total;
          });
          const row = data.data.map((areaObject, index) => {
            let areaListData = {
              index: index + 1,
              id: areaObject.id === null ? "" : areaObject.id,
              name: areaObject.name === null ? "" : areaObject.name,
              shortName:
                areaObject.shortName === null ? "" : areaObject.shortName,
              sortOrder:
                areaObject.sortOrder === null ? "" : areaObject.sortOrder,
              finalTotal: finalTotal,
              total:
                areaObject.total === null ? "" : areaObject.total.toFixed(2),
              contribution: +((areaObject.total / finalTotal) * 100).toFixed(0),
            };
            return areaListData;
          });
          dispatch(
            areaListFromYearAndMonthSuccess({
              row,
              total: finalTotal.toFixed(2),
            })
          );
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getSalesListFromYearAndMonth =
  ({ month, year, stockStakeHolderTypeId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL4 +
          "/with-target-and-actual-amt-by-month-year-stock-stake-holder-type?month=" +
          month +
          "&year=" +
          year +
          "&stockStakeHolderTypeId=" +
          stockStakeHolderTypeId,
      }).then(({ data, success }) => {
        if (success) {
          let finalTotal = 0;
          data.data.map((salesObject) => {
            finalTotal = finalTotal + salesObject.actualAmt;
          });
          const row = data.data.map((salesObject, index) => {
            let salesListData = {
              index: index + 1,
              id: salesObject.id === null ? "" : salesObject.id,
              name:
                salesObject.name === null
                  ? ""
                  : salesObject.name +
                    " (" +
                    salesObject.stockStakeHolderType.prefix +
                    ")",
              // prefix: salesObject.prefix === null ? "" : salesObject.prefix,
              targetAmt:
                salesObject.targetAmt === null
                  ? ""
                  : salesObject.targetAmt.toFixed(2),
              actualAmt:
                salesObject.actualAmt === null
                  ? ""
                  : salesObject.actualAmt.toFixed(2),
              contribution:
                salesObject.actualAmt == 0
                  ? "-"
                  : +((salesObject.actualAmt / finalTotal) * 100).toFixed(0),
              divisional:
                salesObject.targetAmt == 0
                  ? "-"
                  : (
                      (salesObject.actualAmt / salesObject.targetAmt) *
                      100
                    ).toFixed(2),
            };
            return salesListData;
          });
          dispatch(
            salesListFromYearAndMonthSuccess({
              row,
              total: finalTotal.toFixed(2),
            })
          );
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getSalesListFromYearAndMonthNew =
  ({ month, year, dataJson }) =>
  async (dispatch) => {
    try {
      const response = apiPost({
        url:
          URL4 +
          "/with-target-and-actual-amt-by-month-year-stock-stake-holder-type-new?month=" +
          month +
          "&year=" +
          year,
        postBody: dataJson,
      }).then(({ data, success }) => {
        if (success) {
          let finalTotal = 0;
          data.data.map((salesObject) => {
            finalTotal = finalTotal + salesObject.actualAmt;
          });
          const row = data.data.map((salesObject, index) => {
            let salesListData = {
              index: index + 1,
              id: salesObject.id === null ? "" : salesObject.id,
              name:
                salesObject.name === null
                  ? ""
                  : salesObject.name +
                    " (" +
                    salesObject.stockStakeHolderType.prefix +
                    ")",
              // prefix: salesObject.prefix === null ? "" : salesObject.prefix,
              targetAmt:
                salesObject.targetAmt === null
                  ? ""
                  : salesObject.targetAmt.toFixed(2),
              actualAmt:
                salesObject.actualAmt === null
                  ? ""
                  : salesObject.actualAmt.toFixed(2),
              contribution:
                salesObject.actualAmt == 0
                  ? "-"
                  : +((salesObject.actualAmt / finalTotal) * 100).toFixed(0),
              divisional:
                salesObject.targetAmt == 0
                  ? "-"
                  : (
                      (salesObject.actualAmt / salesObject.targetAmt) *
                      100
                    ).toFixed(2),
            };
            return salesListData;
          });
          dispatch(
            salesListFromYearAndMonthSuccess({
              row,
              total: finalTotal.toFixed(2),
            })
          );
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getSalesListFromYearAndMonthUpdated =
  ({ month, year, stockStakeHolderTypeId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL4 +
          "/with-actual-and-target-amt-by-month-year-stock-stake-holder-type?month=" +
          month +
          "&year=" +
          year +
          "&stockStakeHolderTypeId=" +
          stockStakeHolderTypeId,
      }).then(({ data, success }) => {
        if (success) {
          let finalTotal = 0;
          data.data.map((salesObject) => {
            finalTotal = finalTotal + salesObject.actualAmt;
          });
          const row = data.data.map((salesObject, index) => {
            let salesListData = {
              index: index + 1,
              id: salesObject.id === null ? "" : salesObject.id,
              name: salesObject.name === null ? "" : salesObject.name,
              targetAmt:
                salesObject.targetAmt === null
                  ? ""
                  : salesObject.targetAmt.toFixed(2),
              actualAmt:
                salesObject.actualAmt === null
                  ? ""
                  : salesObject.actualAmt.toFixed(2),
              contribution:
                salesObject.actualAmt == 0
                  ? "-"
                  : +((salesObject.actualAmt / finalTotal) * 100).toFixed(0),
              divisional:
                salesObject.targetAmt == 0
                  ? "-"
                  : (
                      (salesObject.actualAmt / salesObject.targetAmt) *
                      100
                    ).toFixed(2),
            };
            return salesListData;
          });
          dispatch(
            salesListFromYearAndMonthSuccess({
              row,
              total: finalTotal.toFixed(2),
            })
          );
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getOrderListFromYearAndMonth =
  ({ month, year }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/top-outlet-order-value-by-month-year?month=" +
          month +
          "&year=" +
          year,
      }).then(({ data, success }) => {
        if (success) {
          let finalTotal = 0;
          data.data.map((outletObject) => {
            finalTotal = finalTotal + outletObject.orderValue;
          });
          const row = data.data.map((outletObject, index) => {
            let outletListData = {
              index: index + 1,
              id: outletObject.id === null ? "" : outletObject.id,
              firmName:
                outletObject.firmName === null ? "" : outletObject.firmName,
              area:
                outletObject.beat !== null &&
                outletObject.beat.area !== null &&
                outletObject.beat.area.name
                  ? outletObject.beat.area.name
                  : "",
              actualSale:
                outletObject.orderValue === null
                  ? ""
                  : outletObject.orderValue.toFixed(2),
              contribution: +(
                (outletObject.orderValue / finalTotal) *
                100
              ).toFixed(0),
              salePersonName:
                outletObject.salesTeam === null
                  ? ""
                  : outletObject.salesTeam.name,
            };
            return outletListData;
          });
          dispatch(
            outletListFromYearAndMonthSuccess({
              row,
              total: finalTotal.toFixed(2),
            })
          );
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getTopOutletOrderListFromYearAndMonth =
  ({ month, year }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/top-outlet-order-value-by-month-year-assign-area?month=" +
          month +
          "&year=" +
          year,
      }).then(({ data, success }) => {
        if (success) {
          let finalTotal = 0;
          data.data.map((outletObject) => {
            finalTotal = finalTotal + outletObject.orderValue;
          });
          const row = data.data.map((outletObject, index) => {
            let outletListData = {
              index: index + 1,
              id: outletObject.id === null ? "" : outletObject.id,
              firmName:
                outletObject.firmName === null ? "" : outletObject.firmName,
              beatName:
                outletObject.beatName === null
                  ? ""
                  : outletObject.beat.beatName,
              area:
                outletObject.beat !== null &&
                outletObject.beat.area !== null &&
                outletObject.beat.area.name
                  ? outletObject.beat.area.name
                  : "",
              salesExective: outletObject.salesTeam === null ? "-" : "0",
              outLetType:
                outletObject.outLetType === null
                  ? ""
                  : outletObject.outLetType.name,
              orderValue:
                outletObject.orderValue === null
                  ? ""
                  : +outletObject.orderValue.toFixed(0),

              address:
                outletObject.address === null ? "" : outletObject.address,
              state: outletObject.state === null ? "" : outletObject.state.name,
              mobileNo:
                outletObject.mobileNo === null ? "" : outletObject.mobileNo,
              emailId:
                outletObject.emailId === null ? "" : outletObject.emailId,
              gstNo: outletObject.gstNo === null ? "" : outletObject.gstNo,
              panNo: outletObject.panNo === null ? "" : outletObject.panNo,
              gstType:
                outletObject.gstType === null ? "" : outletObject.gstType,
              fssiFdiNo:
                outletObject.fssiFdiNo === null ? "" : outletObject.fssiFdiNo,
              isActive:
                outletObject.isActive === null ? "" : outletObject.isActive,
              status: outletObject.status === null ? "" : outletObject.status,
              image: outletObject.image === null ? "" : outletObject.image,
              referenceName:
                outletObject.referenceName === null
                  ? ""
                  : outletObject.referenceName,
              contribution: +(
                (outletObject.orderValue / +finalTotal) *
                100
              ).toFixed(0),
            };
            return outletListData;
          });
          dispatch(
            topOutletListFromYearAndMonthSuccess({
              row,
              total: finalTotal.toFixed(2),
            })
          );
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getProductListFromYearAndMonth =
  ({ month, year }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/top-product-order-value-by-month-year?month=" +
          month +
          "&year=" +
          year,
      }).then(({ data, success }) => {
        if (success) {
          let finalTotal = 0;
          data.data.map((productObject) => {
            finalTotal = finalTotal + productObject.orderVale;
          });
          const row = data.data.map((productObject, index) => {
            let productListData = {
              index: index + 1,
              id: productObject.id === null ? "" : productObject.id,
              name: productObject.name === null ? "" : productObject.name,
              code: productObject.code === null ? "" : productObject.code,
              actualSale:
                productObject.orderVale === null
                  ? ""
                  : productObject.orderVale.toFixed(2),
              contribution: +(
                (productObject.orderVale / +finalTotal) *
                100
              ).toFixed(0),
            };
            return productListData;
          });
          dispatch(
            productListFromYearAndMonthSuccess({
              row,
              total: finalTotal.toFixed(2),
            })
          );
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getTopProductOrderListFromYearAndMonth =
  ({ month, year }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/top-product-order-value-by-month-year-assign-area?month=" +
          month +
          "&year=" +
          year,
      }).then(({ data, success }) => {
        if (success) {
          let finalTotal = 0;
          data.data.map((productObject) => {
            finalTotal = finalTotal + productObject.orderVale;
          });
          const row = data.data.map((productObject, index) => {
            let productListData = {
              index: index + 1,
              id: productObject.id === null ? "" : productObject.id,
              name: productObject.name === null ? "" : productObject.name,
              code: productObject.code === null ? "" : productObject.code,
              uomConversionValue:
                productObject.uomConversionValue === null
                  ? ""
                  : productObject.uomConversionValue,
              orderValue:
                productObject.orderVale === null
                  ? ""
                  : +productObject.orderVale.toFixed(0),
              qty: productObject.qty === null ? "" : productObject.qty,
              isActive:
                productObject.isActive === null ? "" : productObject.isActive,
              sortOrder:
                productObject.sortOrder === null ? "" : productObject.sortOrder,
              shortName:
                productObject.shortName === null ? "" : productObject.shortName,
              weight: productObject.weight === null ? "" : productObject.weight,
              description:
                productObject.description === null
                  ? ""
                  : productObject.description,
              selfLife:
                productObject.selfLife === null ? "" : productObject.selfLife,
              group: productObject.group === null ? "" : productObject.group,
              category:
                productObject.group.subCategory.category === null &&
                productObject.group.subCategory.category.name
                  ? ""
                  : productObject.group.subCategory.category.name,
              subCategory:
                productObject.group.subCategory === null &&
                productObject.group.subCategory.name === null
                  ? ""
                  : productObject.group.subCategory.name,
              taxPercentage:
                productObject.taxPercentage === null
                  ? ""
                  : productObject.taxPercentage.name,
              stakeHolderUom:
                productObject.stakeHolderUom === null
                  ? ""
                  : productObject.stakeHolderUom.name,
              outletUom:
                productObject.outletUom === null
                  ? ""
                  : productObject.outletUom.name,
              contribution: +(
                (productObject.orderVale / finalTotal) *
                100
              ).toFixed(0),
            };
            return productListData;
          });
          dispatch(
            topProductListFromYearAndMonthSuccess({
              row,
              total: finalTotal.toFixed(2),
            })
          );
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getSalesTeamInfo = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/get-sales-teams",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data;
        dispatch(
          salesInfoSuccess({
            row,
          })
        );
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getSalesTeamInfoByDates =
  ({ fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-sales-teams-by-date?fromDate=" +
          fromDate +
          "&toDate=" +
          toDate,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data;
          dispatch(
            salesInfoSuccess({
              row,
            })
          );
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getSalesTeamInfoByDatesNew =
  ({ fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URLLatestNew +
          "/sales-teams-by-date?fromDate=" +
          fromDate +
          "&toDate=" +
          toDate,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data;
          dispatch(
            salesInfoSuccess({
              row,
            })
          );
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getSalesTeamInfoByDatesWithAttendance =
  ({ fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URLLatestNew +
          "/sales-teams-by-date-with-attendence-status?fromDate=" +
          fromDate +
          "&toDate=" +
          toDate,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data;
          dispatch(
            salesInfoSuccess({
              row,
            })
          );
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getSalesGraph =
  ({ regionId, month, year }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL5 +
          "/month-wise-amt-by-region?regionId=" +
          regionId +
          "&month=" +
          month +
          "&year=" +
          year,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((regionObject, index) => {
            let regionListData = {
              index: index + 1,
              id: regionObject.id === null ? "" : regionObject.id,
              dateMonth:
                regionObject.dateMonth === null ? "" : regionObject.dateMonth,
              monthString:
                regionObject.monthString === null
                  ? ""
                  : regionObject.monthString,
              month: regionObject.month === null ? "" : regionObject.month,
              year: regionObject.year === null ? "" : regionObject.year,
              targetAmt:
                regionObject.targetAmt === null ? "" : regionObject.targetAmt,
              achivedAmt:
                regionObject.achivedAmt === null ? "" : regionObject.achivedAmt,
            };
            return regionListData;
          });
          dispatch(
            regionListFromYearAndMonthSuccess({
              row,
            })
          );
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getAreaListFromTalukaYearAndMonth =
  ({ month, year, talukaId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL3 +
          "/with-actual-amt-by-month-year-taluka?month=" +
          month +
          "&year=" +
          year +
          "&talukaId=" +
          talukaId,
      }).then(({ data, success }) => {
        if (success) {
          let finalTotal = 0;
          data.data.map((areaObject) => {
            finalTotal = finalTotal + areaObject.total;
          });
          const row = data.data.map((areaObject, index) => {
            let areaListData = {
              index: index + 1,
              id: areaObject.id === null ? "" : areaObject.id,
              name: areaObject.name === null ? "" : areaObject.name,
              shortName:
                areaObject.shortName === null ? "" : areaObject.shortName,
              sortOrder:
                areaObject.sortOrder === null ? "" : areaObject.sortOrder,
              finalTotal: finalTotal,
              total:
                areaObject.total === null ? "" : areaObject.total.toFixed(2),
              contribution: +((areaObject.total / finalTotal) * 100).toFixed(0),
              taluka: areaObject.taluka === null ? "" : areaObject.taluka,
            };
            return areaListData;
          });
          dispatch(
            areaListFromYearAndMonthSuccess({
              row,
              total: finalTotal.toFixed(2),
            })
          );
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
