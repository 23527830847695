import { DialogTitle, Typography } from "@material-ui/core";
import { Dialog, DialogContent, Grid, IconButton } from "@mui/material";
import { green } from "@mui/material/colors";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { apiGet, apiPost } from "../../utils/api_service";
import { LabelCompo } from "../../components/Comman/Label";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import { blackColor, figmaTitleColor, redColor, titleColor, white } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import { addAtleastOneMomMsg, noInternetMsg, savemsg, saveWarningMsg, scrollMsg, serverMsg } from "../../config/messageconstant";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { ButtonCompo } from "../../components/Comman/Button";
import CloseIcon from "@mui/icons-material/Close";
import { Add } from "@mui/icons-material";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = ({ children, onClose, ...other }) => (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose && (
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
        )}
    </DialogTitle>
);

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node.isRequired,
    onClose: PropTypes.func.isRequired,
};

const ViewAndAddMomPopUp = ({ showNotification, meetingHeaderData, dataRow, open, handleCloses, showLoador }) => {
    const [meetingData, setMeetingData] = useState({});
    const [detailCommentList, setDetailCommentList] = useState([]);
    const [viewAndAddMomOpen, setViewAndAddMomOpen] = useState(false);
    const [scrollMsgFlag, setScrollMsgFlag] = useState(false)

    useEffect(() => {
        showList();
        setScrollMsgFlag(false);
    }, []);


    const handleClose = () => {
        handleCloses();
    }

    const showList = async () => {
        if (navigator.onLine) {
            showLoador({ loador: true });
            await apiGet({
                url: endpoint.minutesOfMeetingHeader + "/with-name?minutesOfMeetingHeaderId=" + meetingHeaderData.id,
            }).then(({ data, success }) => {
                console.log(data);
                if (success && data?.data) {
                    const meetingData = {
                        ...data.data,
                        initial: data.data.name.split("")[0],
                        date: moment(data.data.insertDateTime, "DD-MM-YYYY HH:mm:ss").format("dddd DD MMM - hh:mm A"),
                    };
                    setMeetingData(meetingData);
                    setDetailCommentList(
                        data.data.minutesOfMeetingDetails.map((row, index) => ({
                            ...row,
                            minutesOfMeetingDetailsComments: [
                                ...row.minutesOfMeetingDetailsComments,
                                { index: row.minutesOfMeetingDetailsComments.length + 1, comments: "", headerIndex: index + 1, minutesOfMeetingDetailsId: row.id },
                            ],
                        }))
                    );

                    viewAndAddMomOpenFlag();

                } else {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });
            showLoador({ loador: false });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };

    const viewAndAddMomOpenFlag = () => {
        setViewAndAddMomOpen(true);
    }

    const onSaveDataNew = () => {

        let detailList = [];
        let commentList = [];

        detailCommentList.map((dataObjOfDetail) => {
            if (dataObjOfDetail.meetingDescription !== "") {
                const list = dataObjOfDetail.minutesOfMeetingDetailsComments.filter((row) => row.comments !== "" && row.comments !== null && row.index)
                detailList.push({
                    ...dataObjOfDetail,
                    meetingDescription: dataObjOfDetail.meetingDescription,
                    minutesOfMeetingHeaderId: meetingHeaderData.id,
                    minutesOfMeetingDetailsComments: list,
                    flag: 1
                });

                commentList.push({
                    meetingDescription: dataObjOfDetail.meetingDescription,
                    minutesOfMeetingDetailsComments: list,
                    minutesOfMeetingHeaderId: meetingHeaderData.id,
                    id: dataObjOfDetail !== null && dataObjOfDetail.id ? dataObjOfDetail.id : "",
                });
            }
        });


        if (detailList.length !== 0 || commentList.length !== 0) {

            let mettingDetail = []
            mettingDetail = detailList.filter((detail) => detail.index)

            let mettingcomments = []
            mettingcomments = commentList.filter((detail) => detail.id != "")

            swal({
                title: "Are you sure?",
                text: saveWarningMsg,
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    if (navigator.onLine) {
                        onSave(mettingDetail, mettingcomments);
                    } else {
                        showNotification({ msg: noInternetMsg, severity: "error" });
                    }
                }
            });
        } else {
            showNotification({ msg: addAtleastOneMomMsg, severity: "error" });
        }
    };

    const onSave = async (detailList, commentList) => {
        if (navigator.onLine) {

            if (detailList.length !== 0) {
                showLoador({ loador: true });
                apiPost({
                    url: endpoint.minutesOfMeetingHeader + "/save-details?minutesOfMeetingHeaderId=" + meetingHeaderData.id + "&flag=1",
                    postBody: detailList,
                }).then(({ success }) => {
                    if (success) {
                        showLoador({ loador: false });
                        showNotification({ msg: savemsg });
                        handleClose();
                    } else {
                        showLoador({ loador: false });
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }

            if (commentList.length !== 0) {
                showLoador({ loador: true });
                apiPost({
                    url: endpoint.minutesOfMeetingHeader + "/save-details?minutesOfMeetingHeaderId=" + meetingHeaderData.id + "&flag=0",
                    postBody: commentList,
                }).then(({ success }) => {
                    if (success) {
                        showLoador({ loador: false });
                        showNotification({ msg: savemsg });
                        handleClose();
                    } else {
                        showLoador({ loador: false });
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }

        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };


    const onPressAdd = () => {


        setDetailCommentList((prevList) => [
            ...prevList,
            {
                index: prevList.length + 1,
                minutesOfMeetingHeaderId: "",
                meetingDescription: "",
                minutesOfMeetingDetailsComments: [{ index: 1, headerIndex: prevList.length + 1, comments: "", minutesOfMeetingDetailsId: "" }],
            },
        ]);

        setScrollMsgFlag(true)
    };

    const onPressAddComment = (item) => {
        const list = detailCommentList.map((row, index) => {
            index = index + 1

            if (item.headerIndex) {
                if (index === item.headerIndex) {
                    return {
                        ...row,
                        minutesOfMeetingDetailsComments: [
                            ...row.minutesOfMeetingDetailsComments,
                            { index: row.minutesOfMeetingDetailsComments.length + 1, comments: "", headerIndex: index, minutesOfMeetingDetailsId: row.id },
                        ],
                    }

                } else {
                    return row
                }

            } else if (row.id === item.minutesOfMeetingDetailsId) {
                return {
                    ...row,
                    minutesOfMeetingDetailsComments: [
                        ...row.minutesOfMeetingDetailsComments,
                        { index: row.minutesOfMeetingDetailsComments.length + 1, comments: "", headerIndex: index, minutesOfMeetingDetailsId: row.id },
                    ],
                }

            } else {
                return row
            }
        })

        setDetailCommentList(list);
    }

    const onChangeTextField = (newValue, name, data) => {
        console.log(data)
        if (name == "meetingDescription") {
            const list = detailCommentList.map((item, index) => {
                index = index + 1

                if (index === data.index) {
                    return {
                        ...item,
                        [name]: newValue
                    }
                } else {
                    return item
                }
            })
            setDetailCommentList(list)
        } else if (name == "minutesOfMeetingDetailsComments") {
            const list = detailCommentList.map((item, index) => {
                index = index + 1

                if (index === data.headerIndex) {
                    return {
                        ...item,
                        minutesOfMeetingDetailsComments: item.minutesOfMeetingDetailsComments.map((comment, idx) => {
                            idx = idx + 1
                            if (idx === data.index) {
                                return {
                                    ...comment,
                                    comments: newValue
                                }

                            } else {
                                return comment
                            }

                        }),
                    }

                } else {
                    return item
                }
            })
            setDetailCommentList(list)
        }
    };

    return (
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={viewAndAddMomOpen}
            fullWidth
            maxWidth="xl"
        >
            <BootstrapDialogTitle
                id="customized-dialog-title"
                onClose={handleClose}
                style={{
                    borderBottom: "2px solid rgba(0, 0, 0, 0.2)", // Persistent underline
                    paddingBottom: "8px",            // Adds spacing between title text and underline
                    marginBottom: "10px",            // Adds spacing below the underline
                }}
            >
                Minutes Of Meeting
            </BootstrapDialogTitle>

            <BootstrapDialogTitle id="customized-dialog-title" >
                <Typography style={{ marginBottom: "10px", fontSize: 24, color: blackColor }}>{"Name :" + " " + dataRow.fullName}</Typography>
                <Grid item display={"flex"} flexDirection={"row"} justifyContent={"space-between"} paddingRight={"5px"}>
                    <Typography style={{ fontSize: 25, color: blackColor }}>{meetingHeaderData.meetingPurpose}</Typography>
                    <Grid item display={"flex"} flexDirection={"column"} textAlign={"left"}>
                        <Typography style={{ fontSize: 20, color: figmaTitleColor }}>{meetingData.name}</Typography>
                        <Typography style={{ fontSize: 16, color: blackColor }}>{meetingData.date}</Typography>
                    </Grid>
                </Grid>
                <br />

                <Grid container justifyContent="right">
                    <ButtonCompo
                        size="medium"
                        type="button"
                        variant="contained"
                        name="Add Details"
                        onClick={onPressAdd}
                    />
                </Grid>
                {scrollMsgFlag && (
                    <Grid container justifyContent="right">
                        <span style={{ marginTop: "10px", fontSize: "14px", color: "lightcoral" }}>
                            {scrollMsg}
                        </span>
                    </Grid>
                )}
            </BootstrapDialogTitle>

            <DialogContent dividers style={{ borderColor: 'rgba(0, 0, 0, 0.2)' }}>
                {detailCommentList.map((detail) => {
                    let name = detail.name || "";
                    let initials = name.split("");
                    let date = detail.insertDateTime || "";
                    const parsedDate = moment(date, "DD-MM-YYYY HH:mm:ss");
                    const formattedDate = parsedDate.format("dddd DD MMM - hh:mm A");
                    return (

                        <DialogContent dividers style={{ borderColor: 'rgba(0, 0, 0, 0.2)' }}>
                            <div
                                key={detail.index}
                                style={{
                                    maxHeight: "auto",
                                    overflowY: "auto",
                                }}
                            >
                                {detail.name ? (
                                    <>
                                        <br />
                                        <Grid item display={"flex"} flexDirection={"row"} justifyContent={"space-between"} paddingRight={"5px"}>
                                            <Typography style={{ fontSize: 20, color: figmaTitleColor }}>{detail.name}</Typography>
                                            <Typography style={{ fontSize: 16, color: blackColor }}>{formattedDate}</Typography>
                                        </Grid>
                                        <Typography style={{ fontSize: 20, color: blackColor }}>{detail.meetingDescription ? detail.meetingDescription : ""}</Typography>
                                        <br />
                                    </>

                                ) : (
                                    <Grid container justifyContent="space-between">
                                        <Grid item xs={12} md={3} sm={3}>
                                            <LabelCompo
                                                className="text-black"
                                                style={{ fontSize: 16, color: redColor }}
                                                label="* "
                                            />
                                            <LabelCompo
                                                className="text-black"
                                                style={{ fontSize: 16, color: titleColor }}
                                                label={"Details " + detail.index + ":"}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={10} sm={10}>
                                            <TextFieldCompo
                                                placeHolder="Enter Details"
                                                color="primary"
                                                name="meetingDescription"
                                                size="small"
                                                value={detail.meetingDescription}
                                                multiline
                                                onChange={(e) =>
                                                    onChangeTextField(e.target.value, "meetingDescription", detail)
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                )}




                                {detail.minutesOfMeetingDetailsComments.map((comment) => {
                                    let commentDate = comment.insertDateTime || "";
                                    const commentParsedDate = moment(commentDate, "DD-MM-YYYY HH:mm:ss");
                                    const commentFormattedDate = commentParsedDate.format("dddd DD MMM - hh:mm A");

                                    return comment.name ? (
                                        <>
                                            <div style={{ display: "flex", flexDirection: "row", paddingRight: 24, paddingLeft: 24 }}>
                                                <Typography style={{ fontSize: 16, color: figmaTitleColor }}>{comment.name}</Typography>
                                                &nbsp;&nbsp;&nbsp;
                                                <Typography style={{ fontSize: 13, color: blackColor }}>
                                                    {commentFormattedDate}
                                                </Typography>
                                            </div>
                                            <div style={{ paddingRight: 24, paddingLeft: 24 }}>
                                                <Typography style={{ fontSize: 16, color: blackColor }}>{comment.comments}</Typography>
                                            </div>
                                            <br />
                                        </>
                                    ) : (

                                        <Grid item sm={8} xs={12} md={8} lg={8} container justifyContent="space-evenly" key={comment.index} >

                                            <Grid item xs={12} md={4} sm={4}>
                                                <LabelCompo
                                                    className="text-black"
                                                    style={{ fontSize: 16, color: redColor }}
                                                    label="* "
                                                />
                                                <LabelCompo
                                                    className="text-black"
                                                    style={{ fontSize: 16, color: titleColor }}
                                                    label={"Comment " + comment.index + ":"}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={7} sm={7} display={"flex"} flexDirection={"row"}>
                                                <TextFieldCompo
                                                    placeHolder="Enter comment"
                                                    value={comment.comments}
                                                    onChange={(e) =>
                                                        onChangeTextField(e.target.value, "minutesOfMeetingDetailsComments", comment)
                                                    }
                                                    multiline
                                                />
                                                <IconButton
                                                    onClick={() => onPressAddComment(comment)}
                                                    color="primary"
                                                >
                                                    <Add />
                                                </IconButton>
                                            </Grid>
                                        </Grid>

                                    )
                                })
                                }
                            </div>
                        </DialogContent >

                    )
                })}
            </DialogContent >

            <BootstrapDialogTitle
                id="customized-dialog-title"

            >
                <Grid container justifyContent="right">
                    <ButtonCompo
                        size="medium"
                        type="submit"
                        variant="contained"
                        name="Submit"
                        onClick={onSaveDataNew}
                    />
                </Grid>
            </BootstrapDialogTitle>
        </BootstrapDialog >
    );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    showNotification,
    showLoador,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewAndAddMomPopUp);
