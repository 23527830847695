import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import { CompanyTargetColumns } from "../../tableColumns/table-columns";
import { companyTargetJson } from "../../DynamicFormsJson/Transaction/companyTarget";
import { serverMsg, noInternetMsg } from "../../config/messageconstant";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { getComapnyTarget } from "../../Slice/companyTarget.slice";

class CompanyAndTargetTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        yearDetail: this.props.yearList?.year,
      },
    };
  }

  render() {
    const { companyTargetList, getComapnyTarget } = this.props;
    const { dynamicMasterData } = this.state;
    return (
      <>
        <DynamicMainScreen
          showDeleteIcon={true}
          dynamicMasterData={dynamicMasterData}
          formPath={companyTargetJson.formPath}
          screenTitle={companyTargetJson.screenTitle}
          fieldMeta={companyTargetJson.fieldMeta}
          showPdfDownload={companyTargetJson.showPdfDownload}
          showExcelDownload={companyTargetJson.showExcelDownload}
          pdfFileName={companyTargetJson.pdfFileName}
          excelFileName={companyTargetJson.excelFileName}
          showAddButton={companyTargetJson.showAddButton}
          tableColumnsToFilter={companyTargetJson.tableColumnsToFilter}
          tableColumns={CompanyTargetColumns}
          tableData={companyTargetList?.companyTarget}
          apiBaseURL={companyTargetJson.apiBaseURL}
          getTableData={getComapnyTarget}
          isNote={true}
          isNoteValue={"Add Monthly Target For Assigned Year"}
          isLog={true}
          type={1}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  companyTargetList: state.companyTarget,
});

const mapDispatchToProps = {
  getComapnyTarget,
  showLoador,
  showNotification,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyAndTargetTable);
