import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import {
    AreaColumns,
    BeatColumnsForPopup,
    OutletCountColumnsForPopup,
} from "../../../tableColumns/table-columns";
import { getRegionNew } from "../../../Slice/region.slice";
import { getStateByRegion } from "../../../Slice/state.slice";
import { getZoneByState } from "../../../Slice/zone.slice";
import { getDistrictByZone } from "../../../Slice/district.slice";
import { getTalukaByDistrict } from "../../../Slice/taluka.slice";
import { AreaMasterJson } from "../../../DynamicFormsJson/MastersJSON/areaMasterJson";
import { getAreaByTalukaPostBodyUpdated, getArea, setAreaByTalukaUpdated } from "../../../Slice/area.slice";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import { deleteFailedMsg, deletemsg, noInternetMsg, serverMsg } from "../../../config/messageconstant";
import { apiDelete, apiGet, apiPost } from "../../../utils/api_service";
import endpoint from "../../../config/endpoints";

class AreaMasterList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showStaticDeleteToArea: "",
            fieldDataForModule: {},
            dynamicMasterData: {
                regionDetail: this.props.regionList?.region,
                stateDetail: this.props.stateList?.stateByRegion,
                zoneDetail: this.props.zoneList?.zoneByState,
                districtDetail: this.props.districtList?.districtByZone,
                talukaDetail: this.props.talukaList?.talukaByDistrict,
            },
        };
    }

    async componentDidMount() {
        const { getRegionNew, setAreaByTalukaUpdated, showLoador, showNotification } = this.props;
        if (navigator.onLine) {


            setAreaByTalukaUpdated({ row: [] });

            const showStaticDeleteToArea = localStorage.getItem("showStaticDeleteToArea");
            this.setState({ showStaticDeleteToArea: showStaticDeleteToArea })


            const moduleObjData = localStorage.getItem("moduleObj");

            this.setState({
                fieldDataForModule:
                    moduleObjData != null ? JSON.parse(moduleObjData) : {},
            });


            showLoador({ loador: true });
            await getRegionNew().then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });
        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.regionList !== nextProps.regionList) {
            if (this.props.regionList?.region !== nextProps.regionList?.region) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        regionDetail: nextProps.regionList?.region,
                    },
                });
            }
        }
        if (this.props.stateList !== nextProps.stateList) {
            if (
                this.props.stateList?.stateByRegion !==
                nextProps.stateList?.stateByRegion
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        stateDetail: nextProps.stateList?.stateByRegion,
                    },
                });
            }
        }
        if (this.props.zoneList !== nextProps.zoneList) {
            if (
                this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        zoneDetail: nextProps.zoneList?.zoneByState,
                    },
                });
            }
        }
        if (this.props.districtList !== nextProps.districtList) {
            if (
                this.props.districtList?.districtByZone !==
                nextProps.districtList?.districtByZone
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        districtDetail: nextProps.districtList?.districtByZone,
                    },
                });
            }
        }
        if (this.props.talukaList !== nextProps.talukaList) {
            if (
                this.props.talukaList?.talukaByDistrict !==
                nextProps.talukaList?.talukaByDistrict
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        talukaDetail: nextProps.talukaList?.talukaByDistrict,
                    },
                });
            }
        }
    }

    onSearchData = async (data) => {
        const { getAreaByTalukaPostBodyUpdated, showLoador, showNotification } = this.props;
        if (navigator.onLine) {
            if (data.taluka && data.taluka != "") {
                let talukaJson = {
                    talukaIds: data.taluka,
                }
                showLoador({ loador: true });
                await getAreaByTalukaPostBodyUpdated({ talukaIds: talukaJson }).then(
                    ({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    }
                );
            } else {
                await this.getList();
            }
        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };

    getListById = async (data) => {
        const {
            getStateByRegion,
            getZoneByState,
            getDistrictByZone,
            getTalukaByDistrict,
            showLoador,
            showNotification,
        } = this.props;
        if (navigator.onLine) {
            console.log(data);
            if (data.region && data.region != "") {
                showLoador({ loador: true });
                await getStateByRegion({ regionIds: data.region }).then(
                    ({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    }
                );
            }
            if (data.state && data.state != "") {
                showLoador({ loador: true });
                await getZoneByState({ stateIds: data.state }).then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }
            if (data.zone && data.zone != "") {
                showLoador({ loador: true });
                await getDistrictByZone({ zoneIds: data.zone }).then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }
            if (data.district && data.district != "") {
                showLoador({ loador: true });
                await getTalukaByDistrict({ districtIds: data.district }).then(
                    ({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    }
                );
            }
        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };


    onDeleteStatic = (rowData) => {
        const { showLoador, showNotification } = this.props;
        const { fieldDataForModule } = this.state;

        showLoador({ loador: true });
        apiGet({
            url:
                endpoint.area + "/check-assign-or-not?id=" + rowData.id
        }).then(({ data, success }) => {
            showLoador({ loador: false });
            if (!success) {
                showNotification({ msg: serverMsg, severity: "error" });
            } else {
                if (data && data.data && Object.keys(data.data).length !== 0) {
                    if (data.data.statusCode == 500) {
                        showNotification({ msg: data.data.message, severity: "error" });
                    } else {

                        showLoador({ loador: true });
                        apiDelete({
                            url: endpoint.area + "/" + rowData.id,
                        }).then(({ data, success }) => {
                            showLoador({ loador: false });
                            if (data.data.error == false) {

                                let dataToSaveForMLog = {
                                    module: {
                                        moduleId: fieldDataForModule.moduleId
                                    },
                                    subModule: {
                                        subModuleId: fieldDataForModule.subModuleId
                                    },
                                    operationType: 2,
                                    type: 0,
                                    operationPerformId: rowData.id,
                                    updatedJson: rowData ? JSON.stringify(rowData) : {},
                                }

                                showLoador({ loador: true });
                                apiPost({
                                    url: endpoint.mlog,
                                    postBody: dataToSaveForMLog,
                                }).then(({ success }) => {
                                    showLoador({ loador: false });
                                    if (success) {
                                    } else {
                                        showNotification({
                                            msg: msgConstant.saveFailedMsg,
                                            severity: "error",
                                        });
                                    }
                                });

                                showNotification({ msg: deletemsg, severity: "success" });

                                window.location.reload();

                            } else {
                                showNotification({ msg: deleteFailedMsg, severity: "error" });
                            }
                        });
                    }
                }
            }
        });
    }


    render() {
        const { areaList, getArea } = this.props;
        const { dynamicMasterData, showStaticDeleteToArea } = this.state;
        return (
            <>
                <DynamicMainScreen
                    dynamicMasterData={dynamicMasterData}
                    formPath={AreaMasterJson.formPath}
                    onSearchData={this.onSearchData}
                    getListById={this.getListById}
                    screenTitle={AreaMasterJson.screenTitle}
                    fieldMeta={AreaMasterJson.fieldMeta}
                    searchList={AreaMasterJson.searchList}
                    showPdfDownload={false}
                    showExcelDownload={true}
                    filterDataExcel={true}
                    showExtension={false}
                    pdfFileName={false}
                    excelFileName={AreaMasterJson.excelFileName}
                    showAddButton={AreaMasterJson.showAddButton}
                    tableColumnsToFilter={AreaMasterJson.tableColumnsToFilter}
                    tableColumns={AreaColumns}
                    tableData={areaList?.areaByTaluka}
                    getTableData={getArea}
                    callApi={false}
                    getTableDataById={true}
                    apiBaseURL={AreaMasterJson.apiBaseURL}
                    baseIdColumn={AreaMasterJson.baseIdColumn}
                    isNote={true}
                    showDeleteIcon={showStaticDeleteToArea == 1 ? true : false}
                    deleteURLStatic={showStaticDeleteToArea == 1 ? true : false}
                    onDeleteStatic={this.onDeleteStatic}
                    type={0}
                    isLog={true}
                    isNoteValue={"Area Is The Lowest Entity Of The System"}
                    showPopUpDataList={[
                        {
                            dataKey: "beatCount",
                            columns: BeatColumnsForPopup,
                            title: "Beat List",
                            header: {
                                dataKey: "name",
                                label: "Area",
                            },
                        },
                        {
                            dataKey: "outletCount",
                            columns: OutletCountColumnsForPopup,
                            title: "Outlet List",
                            header: {
                                dataKey: "name",
                                label: "Area",
                            },
                        },
                    ]}
                />
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    areaList: state.area,
    regionList: state.region,
    stateList: state.states,
    zoneList: state.zone,
    districtList: state.district,
    talukaList: state.taluka,
});
const mapDispatchToProps = {
    showNotification,
    showLoador,
    getArea,
    getAreaByTalukaPostBodyUpdated,
    getZoneByState,
    getRegionNew,
    getDistrictByZone,
    getTalukaByDistrict,
    getStateByRegion,
    setAreaByTalukaUpdated,
};
export default connect(mapStateToProps, mapDispatchToProps)(AreaMasterList);
