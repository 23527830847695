import endpoint from "../../config/endpoints";

export const creationListJson = {
  showSaveNextBtn: true,
  formPath: "/ru-creation-assignation",
  apiBaseURL: endpoint.ruTargetHeader,
  changeExcel: endpoint.exportData,
  isActiveURL: endpoint.ruTargetHeader + "/update-active-status",
  //excelUrl: "year-data" + '?sort={"fromDate": "ASC"}',
  screenTitle: "RU Creation List",
  showAddButton: true,
  showPdfDownload: true,
  pdfFileName: "RU Creation List",
  showExcelDownload: true,
  excelFileName: "RU Creation List",
  tableColumnsToFilter: [
    {
      columnDisplayName: "Target Name",
      columnKeyName: "name",
      isChecked: true,
    },
    {
      columnDisplayName: "RU Quantity",
      columnKeyName: "ruQty",
      isChecked: true,
    },
    {
      columnDisplayName: "Sales Team Count",
      columnKeyName: "salesTeamCount",
      isChecked: true,
    },
    {
      columnDisplayName: "Status",
      columnKeyName: "isActive",
      isChecked: true,
    },
  ],
};
