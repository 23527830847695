import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  subCategory: [],
  packagingType: [],
  subCategoryNew: [],
  categoryByPackagingType: [],
};
let URL = endpoints.subCategory;
const subCategorySlice = createSlice({
  name: "subCategory",
  initialState,
  reducers: {
    subCategorySuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        subCategory: row,
      };
    },

    subCategoryNewSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        subCategoryNew: row,
      };
    },

    packagingTypeSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        packagingType: row,
      };
    },
    categoryByPackagingTypeSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        categoryByPackagingType: row,
      };
    },
    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  subCategorySuccess,
  packagingTypeSuccess,
  categoryByPackagingTypeSuccess,
  subCategoryNewSuccess,
  resetState,
} = subCategorySlice.actions;

export default subCategorySlice.reducer;

export const getSubCategory = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?sort={"sortOrder": "ASC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        const row = content.map((subCategoryObject, index) => {
          let subCategoryData = {
            index: index + 1,
            id: subCategoryObject.id === null ? "" : subCategoryObject.id,
            name: subCategoryObject.name === null ? "" : subCategoryObject.name,
            subCategoryName:
              subCategoryObject.name +
              " ( " +
              subCategoryObject.shortName +
              " )",
            shortName:
              subCategoryObject.shortName === null
                ? ""
                : subCategoryObject.shortName,
            categoryName:
              subCategoryObject.category !== null &&
                subCategoryObject.category.name
                ? subCategoryObject.category.name
                : "",
            category:
              subCategoryObject.category !== null &&
                subCategoryObject.category.id
                ? subCategoryObject.category.id
                : "",
            productCount:
              subCategoryObject.productCount === null
                ? ""
                : subCategoryObject.productCount,
            sortOrder:
              subCategoryObject.sortOrder === null
                ? ""
                : subCategoryObject.sortOrder,
            isActive:
              subCategoryObject.isActive === null
                ? ""
                : subCategoryObject.isActive,
          };
          return subCategoryData;
        });
        dispatch(subCategorySuccess({ row }));
        dispatch(subCategoryNewSuccess({ row: content }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const setSubCategory =
  ({ row }) =>
    async (dispatch) => {
      dispatch(subCategoryNewSuccess({ row }));
    };
export const getSubCategoryPackagingType =
  ({ year, month, regionId, categoryId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL +
            "/with-packaging-type-and-total-sale-by-region-and-category?year=" +
            year +
            "&month=" +
            month +
            "&categoryId=" +
            categoryId,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((packagingTypeObject, index) => {
              let packagingTypeData = {
                index: index + 1,
                id: packagingTypeObject.id === null ? "" : packagingTypeObject.id,
                subCategory:
                  packagingTypeObject.subCategory === null
                    ? ""
                    : packagingTypeObject.subCategory.name,
                packagingType:
                  packagingTypeObject.packagingType === null
                    ? ""
                    : packagingTypeObject.packagingType.name,
                totalAmt:
                  packagingTypeObject.totalAmt === null
                    ? ""
                    : packagingTypeObject.totalAmt.toFixed(2),
              };

              return packagingTypeData;
            });
            dispatch(packagingTypeSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };
export const getCategoryByPackagingType =
  ({ year, month, pacakingTypeId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL +
            "/with-total-sale-by-region-and-packaging-type?year=" +
            year +
            "&month=" +
            month +
            "&pacakingTypeId=" +
            pacakingTypeId,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((categoryByPackagingTypeObject, index) => {
              let categoryByPackagingTypeData = {
                index: index + 1,
                id:
                  categoryByPackagingTypeObject.id === null
                    ? ""
                    : categoryByPackagingTypeObject.id,
                packagingType:
                  categoryByPackagingTypeObject.packagingType === null
                    ? ""
                    : categoryByPackagingTypeObject.packagingType,
                category:
                  categoryByPackagingTypeObject.subCategory === null
                    ? ""
                    : categoryByPackagingTypeObject.subCategory.category.name,
                subCategory:
                  categoryByPackagingTypeObject.subCategory === null
                    ? ""
                    : categoryByPackagingTypeObject.subCategory.name,
                totalAmt:
                  categoryByPackagingTypeObject.totalAmt === null
                    ? ""
                    : categoryByPackagingTypeObject.totalAmt.toFixed(2),
              };

              return categoryByPackagingTypeData;
            });
            dispatch(categoryByPackagingTypeSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getSubCategoryByCategory =
  ({ categoryId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/by-category?categoryId=" + categoryId,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((subCategoryObject, index) => {
              let subCategoryData = {
                index: index + 1,
                id: subCategoryObject.id === null ? "" : subCategoryObject.id,
                name:
                  subCategoryObject.name === null ? "" : subCategoryObject.name,

                shortName:
                  subCategoryObject.shortName === null
                    ? ""
                    : subCategoryObject.shortName,
                categoryName:
                  subCategoryObject.category !== null &&
                    subCategoryObject.category.name
                    ? subCategoryObject.category.name
                    : "",
                category:
                  subCategoryObject.category !== null &&
                    subCategoryObject.category.id
                    ? subCategoryObject.category.id
                    : "",
                productCount:
                  subCategoryObject.productCount === null
                    ? ""
                    : subCategoryObject.productCount,
                sortOrder:
                  subCategoryObject.sortOrder === null
                    ? ""
                    : subCategoryObject.sortOrder,
                isActive:
                  subCategoryObject.isActive === null
                    ? ""
                    : subCategoryObject.isActive,
              };
              return subCategoryData;
            });
            dispatch(subCategorySuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };
