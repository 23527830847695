import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { ButtonCompo } from "../../components/Comman/Button";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import endpoint from "../../config/endpoints";
import {
  noInternetMsg,
  saveFailedMsg,
  savemsg,
  saveWarningMsg,
  serverMsg,
} from "../../config/messageconstant";
import { stockHolderTargetJson } from "../../DynamicFormsJson/Transaction/stockholderTarget";
import { getAreaByTaluka } from "../../Slice/area.slice";
import { getNotAssignBeat } from "../../Slice/beatTarget.slice";
import { getDistrictByZone } from "../../Slice/district.slice";
import { getRegionNew } from "../../Slice/region.slice";
import { getMonthsFromYearId } from "../../Slice/regionAndTarget.slice";
import { getStateByRegion } from "../../Slice/state.slice";
import { getTalukaByDistrict } from "../../Slice/taluka.slice";
import { getTargetByStakeHolderTypeIdAndYearId } from "../../Slice/target.slice";
import { getActiveYear } from "../../Slice/year.slice";
import { getZoneByState } from "../../Slice/zone.slice";
import { apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import DynamicFormWithoutValidation from "../../components/dynamicscreens/DynamicFormWithoutValidation";
class StockHolderTarget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        regionDetail: this.props.regionList?.region,
        stateDetail: this.props.stateList?.stateByRegion,
        zoneDetail: this.props.zoneList?.zoneByState,
        districtDetail: this.props.districtList?.districtByZone,
        talukaDetail: this.props.talukaList?.talukaByDistrict,
        areaDetail: this.props.areaList?.areaByTaluka,
        yearDetail: this.props.yearList?.activeYear,
      },
      beatList: [],
      yearNameList: [],
      conversionFactorText: "",
      fieldData: { type: "0" },
      formErrors: {},
      matrixData: [],
      yearId: "",
      totalOftarget: 0,
      totalTarget: 0,
      error: false,
      isLog: true,
      moduleObjDataObj: {},
    };
  }
  async componentDidMount() {
    const { getRegionNew, getActiveYear, showLoador, showNotification } =
      this.props;
    const { fieldData } = this.state;
    if (navigator.onLine) {
      // this.onSearchList({
      //   name: "a38616c0-283c-4121-a6bd-b766dafcd163",
      //   area: "a8edf651-4d04-4b41-b240-6fe42172a0ae",
      // });
      let conversionFactor = "";
      if (fieldData.type === "0") {
        conversionFactor = "K";
      }

      const moduleObjData = localStorage.getItem("moduleObj");

      this.setState({
        moduleObjDataObj:
          moduleObjData != null ? JSON.parse(moduleObjData) : {},
        conversionFactorText: conversionFactor,
      });


      showLoador({ loador: true });
      await getRegionNew().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getActiveYear().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.regionList !== nextProps.regionList) {
      if (this.props.regionList?.region !== nextProps.regionList?.region) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            regionDetail: nextProps.regionList?.region,
          },
        });
      }
    }
    if (this.props.stateList !== nextProps.stateList) {
      if (
        this.props.stateList?.stateByRegion !==
        nextProps.stateList?.stateByRegion
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stateDetail: nextProps.stateList?.stateByRegion,
          },
        });
      }
    }
    if (this.props.zoneList !== nextProps.zoneList) {
      if (
        this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            zoneDetail: nextProps.zoneList?.zoneByState,
          },
        });
      }
    }
    if (this.props.districtList !== nextProps.districtList) {
      if (
        this.props.districtList?.districtByZone !==
        nextProps.districtList?.districtByZone
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            districtDetail: nextProps.districtList?.districtByZone,
          },
        });
      }
    }
    if (this.props.talukaList !== nextProps.talukaList) {
      if (
        this.props.talukaList?.talukaByDistrict !==
        nextProps.talukaList?.talukaByDistrict
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            talukaDetail: nextProps.talukaList?.talukaByDistrict,
          },
        });
      }
    }
    if (this.props.areaList !== nextProps.areaList) {
      if (
        this.props.areaList?.areaByTaluka !== nextProps.areaList?.areaByTaluka
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.areaByTaluka,
          },
        });
      }
    }
    if (this.props.yearList !== nextProps.yearList) {
      if (this.props.yearList?.activeYear !== nextProps.yearList?.activeYear) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearDetail: nextProps.yearList?.activeYear,
          },
        });
      }
    }
  }
  getListById = async (data) => {
    const {
      getStateByRegion,
      getZoneByState,
      getDistrictByZone,
      getTalukaByDistrict,
      getAreaByTaluka,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      if (data.region && data.region.id) {
        showLoador({ loador: true });
        await getStateByRegion({ regionIds: data.region.id }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.state) {
        showLoador({ loador: true });
        await getZoneByState({ stateIds: data.state }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.zone) {
        showLoador({ loador: true });
        await getDistrictByZone({ zoneIds: data.zone }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.district) {
        showLoador({ loador: true });
        await getTalukaByDistrict({ districtIds: data.district }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.taluka) {
        showLoador({ loador: true });
        await getAreaByTaluka({ talukaIds: data.taluka }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  onSearchList = async (data) => {
    const {
      getMonthsFromYearId,
      getNotAssignBeat,
      showLoador,
      showNotification,
    } = this.props;
    const { fieldData } = this.state;

    if (navigator.onLine) {
      let conversionFactorOnSearchList = "";
      if (fieldData.type === "0") {
        conversionFactorOnSearchList = "K";
      }

      this.setState({
        conversionFactorText: conversionFactorOnSearchList,
      });

      showLoador({ loador: true });

      let yearNameList = await getMonthsFromYearId({ yearId: fieldData.name }).then(
        ({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
            return [];
          } else {
            return response;
          }
        }
      );
      this.setState({ yearNameList: yearNameList, yearId: fieldData.name });
      if (fieldData.area && fieldData.area != "") {
        showLoador({ loador: true });
        let beatList = await getNotAssignBeat({
          yearId: fieldData.name,
          areaId: fieldData.area,
        }).then(({ success, response }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
            return [];
          } else {
            return response;
          }
        });
        if (yearNameList.length != 0) {
          this.setMatrix(beatList, yearNameList);
        }
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  setMatrix = (beatList, yearNameList) => {
    const matList = beatList.map((dayObj) => {
      let matObj = {
        ...dayObj,
      };

      const beatListData = yearNameList.map((beatObj) => {
        beatObj = {
          ...beatObj,
          target: 0,
        };

        return beatObj;
      });
      matObj = {
        ...matObj,
        beatListData: beatListData,
        totalTarget: 0,
      };

      return matObj;
    });
    this.setState({
      matrixData: matList,
    });

  };

  onTextFieldDataChange = (monthId, beatId) => (event) => {
    const { value } = event.target;
    const { matrixData, type } = this.state;
    let totalOftarget = 0;
    const updatedMatrixData = matrixData.map((rowD) => {
      if (rowD.id === beatId) {
        let totalTarget = 0;

        const updatedBeatListData = rowD.beatListData.map((monthData) => {
          if (monthData.name === monthId) {
            monthData = { ...monthData, target: value };
          }
          totalTarget = totalTarget + +monthData.target;
          return monthData;
        });
        return { ...rowD, beatListData: updatedBeatListData, totalTarget };
      }
      return rowD;
    });

    // let conversionFactorOnChange = "";
    // if (data.type === "0") {
    //   conversionFactorOnChange = "K";
    // } else if (data.type === "1") {
    //   conversionFactorOnChange = "L";
    // } else if (data.type === "2") {
    //   conversionFactorOnChange = "CR";
    // }

    updatedMatrixData.map((rowD) => {
      totalOftarget = totalOftarget + rowD.totalTarget;
    });

    this.setState({
      matrixData: updatedMatrixData,
      totalOftarget: totalOftarget,
      // conversionFactorText: conversionFactorOnChange,
    });
  };


  onSubmit = () => {
    const { matrixData, beatListData, yearId, fieldData } = this.state;


    let conversionFactor = 1;
    if (fieldData.type === "0") {
      conversionFactor = 1000;
    } else if (fieldData.type === "1") {
      conversionFactor = 100000;
    } else if (fieldData.type === "2") {
      conversionFactor = 10000000;
    }


    const saveTargetList = matrixData.map((row, index) => {
      let targetListObjectToSave = {
        beat: {
          id: row.id,
        },
        yearMaster: {
          id: yearId,
        },
        totalTarget: (+row.totalTarget * conversionFactor),
      };


      row.beatListData.map((beat, index) => {
        let month =
          beat.month != "March" && beat.month != "April" && beat.month != "July"
            ? beat.month.substring(0, 3)
            : beat.month;
        targetListObjectToSave = {
          ...targetListObjectToSave,
          [month.toLowerCase()]: (+beat.target * conversionFactor),
        };
      });
      return targetListObjectToSave;
    });

    console.log(saveTargetList);
    swal({
      title: "Are you sure?",
      text: saveWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onSave(saveTargetList);
      }
    });
  };
  onSave = (saveTargetList) => {
    const { showLoador, showNotification } = this.props;
    const { isLog, moduleObjDataObj, yearId } = this.state;

    if (navigator.onLine) {

      let beatIds = saveTargetList.map((listObj) => listObj.beat && listObj.beat.id)

      showLoador({ loador: true });
      apiPost({
        url: endpoint.beatTarget + "/save-list",
        postBody: saveTargetList,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {

          if (data.data !== null) {
            if (isLog) {
              let updatedJsonToSend = JSON.stringify(data.data);

              let dataToSaveForMLog = {
                module: {
                  moduleId: moduleObjDataObj.moduleId
                },
                subModule: {
                  subModuleId: moduleObjDataObj.subModuleId
                },
                operationType: 0,
                type: 1,
                operationPerformId: yearId,
                subOperationPerformIds: beatIds.join(", "),
                updatedJson: updatedJsonToSend,
              }

              showLoador({ loador: true });
              apiPost({
                url: endpoint.mlog,
                postBody: dataToSaveForMLog,
              }).then(({ success }) => {
                showLoador({ loador: false });
                if (success) {
                } else {
                  showNotification({
                    msg: saveFailedMsg,
                    severity: "error",
                  });
                }
              });
            }

          }

          showNotification({ msg: savemsg });
          this.props.navigate(-1);
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
        return success;
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onDataChange = (fieldName, newValue) => {
    const { fieldData } = this.state;
    const dataToSearch = {};
    const dataToReset = { ...this.state.fieldData };
    let listError = false;
    stockHolderTargetJson.fieldMeta.map((currentField) => {
      if (currentField.getDate && currentField.dataKey === fieldName) {
        dataToReset[currentField.getDate] = newValue.add(365, "day");
      }
      if (currentField.dataKey === fieldName && currentField.getListId) {
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
          return null;
        });

        this.getListById(dataToSearch);
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key == fieldName && dataToReset[key]) {
            delete dataToReset[currentField.dataKey];
          }
        });
      }
      if (
        !listError &&
        fieldData[currentField.dataKey] &&
        currentField.controlType != "datepicker" &&
        currentField.getListFrom == undefined
      ) {
        dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
      }
      return null;
    });

    let conversionFactorOnChange = "";
    if (newValue === "0") {
      conversionFactorOnChange = "K";
    } else if (newValue === "1") {
      conversionFactorOnChange = "L";
    } else if (newValue === "2") {
      conversionFactorOnChange = "CR";
    }

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
      conversionFactorText: conversionFactorOnChange,
    });
  };

  render() {
    const { yearNameList, matrixData, DataToSet, dynamicMasterData, totalOftarget, conversionFactorText, fieldData, formErrors } =
      this.state;
    return (
      <>
        <Paper
          sx={{
            padding: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <br />
          <DynamicFormWithoutValidation
            showSaveBtnMain={false}
            showBackToList={true}
            dynamicMasterData={dynamicMasterData}
            screenTitle={stockHolderTargetJson.screenTitle}
            fieldMeta={stockHolderTargetJson.fieldMeta}
            apiBaseURL={stockHolderTargetJson.apiBaseURL}
            tableColumns={[]}
            tableData={[]}
            onDataChange={this.onDataChange}
            saveBtnText={"Search"}
            onSave={this.onSearchList}
            padding={true}
            paddingTop={true}
            DataToSet={DataToSet}
            fieldData={fieldData}
            formErrors={formErrors}
          />
          <br />
          <Grid container spacing={1}>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="right"
            >
              <ButtonCompo
                size="medium"
                type="submit"
                variant="contained"
                name="Search"
                onClick={this.onSearchList}
              />
            </Grid>
          </Grid>
          <br />
          <TableContainer>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align={"center"} sx={{ minWidth: 65 }}>
                    Sr. No
                  </TableCell>
                  <TableCell align={"center"} sx={{ minWidth: 100 }}>
                    Beat Name
                  </TableCell>
                  {yearNameList.map((monthData) => {
                    return <TableCell>{monthData.name}</TableCell>;
                  })}
                  <TableCell align={"center"} sx={{ minWidth: 80 }}>
                    Total Target
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {matrixData.map((row, index) => (
                  <TableRow key={row.name}>
                    <TableCell align="center" component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.beatName}
                    </TableCell>

                    {row.beatListData.map((monthData) => {
                      return (
                        <TableCell align="right">
                          <TextFieldCompo
                            placeHolder={"Target"}
                            size="small"
                            color="primary"
                            type="number"
                            id={"1"}
                            value={monthData.target}
                            onChange={this.onTextFieldDataChange(
                              monthData.name,
                              row.id
                            )}
                            fullWidth={false}
                          />
                        </TableCell>
                      );
                    })}
                    <TableCell align="right" component="th" scope="row">
                      {row.totalTarget}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <DynamicDetails
            DataToSet={[
              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "Total target",
                value: totalOftarget + "" + conversionFactorText,
              },
            ]}
          />
          <Grid container spacing={1}>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="right"
            >
              <ButtonCompo
                size="medium"
                type="submit"
                variant="contained"
                name="Submit"
                onClick={this.onSubmit}
              />
            </Grid>
          </Grid>

        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  targetList: state.target,
  regionList: state.region,
  stateList: state.states,
  zoneList: state.zone,
  districtList: state.district,
  talukaList: state.taluka,
  beatList: state.beatTarget,
  areaList: state.area,
  yearList: state.year,
});
const mapDispatchToProps = {
  getRegionNew,
  getStateByRegion,
  getZoneByState,
  getDistrictByZone,
  getTalukaByDistrict,
  getAreaByTaluka,
  getNotAssignBeat,
  getActiveYear,
  getMonthsFromYearId,
  getTargetByStakeHolderTypeIdAndYearId,
  showLoador,
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(StockHolderTarget);
