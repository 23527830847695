import { Grid, Paper } from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import { downloadF } from "../../Pages/PDF/PDFDownload";
import { LabelCompo } from "../Comman/Label";
import { serverMsg } from "../../config/messageconstant";
import { getAreaByUserType } from "../../Slice/area.slice";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { compose } from "@reduxjs/toolkit";
import ColumnFilterTablePopUp from "../Comman/ColumnFilterPopup";
import { Loading1 } from "../Loading1";
import LandingScreenHeader from "../Comman/LandingScreenHeader";
import DynamicForm from "./DynamicReportFormMain";
import RTTable from "../Comman/RT/TableForReport";
import RTTableHeaderDetails from "../Comman/RT/RTTableHeaderDetails";

import { labelColor, titleColor } from "../../config/ColorObj";
import AutoComplete from "../../components/Comman/AutoComplete";
import { getReportName } from "../../Slice/report.slice";
import {
  showLoador,
  showNotification,
} from "../../Pages/Landing/Landing.slice";
import { ButtonCompo } from "../Comman/Button";
import { apiGet, apiPost } from "../../utils/api_service";
import {
  FontAwesomeIconCompo,
  MyComponentWithIconProps,
} from "../Comman/IconButton";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import AssessmentIcon from "@mui/icons-material/Assessment";
import {
  generateExcel,
  generateHeaderPDF,
  generateHeaderExcel,
  generatePDF,
} from "../GeneratePDFExcel";
import PieChart from "../../Pages/DashbourdChanges/PieChart";
import jsPDF from "jspdf";

import BarChartMultiple from "../../Pages/DashbourdChanges/BarChartMultiple";
import endpoint from "../../config/endpoints";
import { withRouter } from "../withRouter";
export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

class DynamicMainScreen extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      fieldData: {},
      searchValue: "",
      reportName: {},
      graphName: {},
      formErrors: {},
      dynamicMasterData: {},
      tableData: [],
      reportTableCheckedInfo: {},
      openFilter: false,
      fieldDataForModule: {},
    };
  }

  onDataChange = (fieldName, newValue) => {
    const { fieldData, reportName } = this.state;
    const fieldMeta = JSON.parse(reportName.parameterRequired);

    const dataToSearch = {};
    const dataToReset = { ...this.state.fieldData };
    let listError = false;
    fieldMeta.map((currentField) => {
      if (currentField.dataKey === fieldName && currentField.getListId && currentField.controlType !== "multiSelect") {
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
          return null;
        });
        console.log(dataToSearch);
        this.getListById(dataToSearch);
      } else if (currentField.dataKey === fieldName && currentField.getListId && currentField.controlType === "multiSelect" && newValue.length != 0) {
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
          return null;
        });
        console.log(dataToSearch);
        this.getListById(dataToSearch);
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key == fieldName && dataToReset[key]) {
            delete dataToReset[currentField.dataKey];
          }
        });
      }
      if (!listError && fieldData[currentField.dataKey]) {
        dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
      }
    });

    dataToReset[fieldName] = newValue;

    this.setState({
      fieldData: dataToReset,
    });
  };
  getListById = async (dataToSearch) => {
    const {
      // getAreaByUserType,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      const { reportName } = this.state;
      const fieldMeta = JSON.parse(reportName.parameterRequired);
      console.log(fieldMeta);

      let filterList = fieldMeta.filter(
        (fieldObj) => fieldObj.getListFrom && fieldObj.getListFrom != ""
      );
      Promise.all(
        filterList.map((fieldObj) =>
          this.getDynamicListById(fieldObj, dataToSearch)
        )
      );
    } else {
      showNotification({ msg: msgConstant.noInternetMsg, severity: "error" });
    }
  };
  async componentDidMount() {
    this.getReportInfo(this.props.params.id);

    const moduleObjData = localStorage.getItem("moduleObj");

    this.setState({
      fieldDataForModule:
        moduleObjData != null ? JSON.parse(moduleObjData) : {},
    });
  }

  getReportInfo = (id) => {
    this.setState({
      fieldData: {},
      searchValue: "",
      reportName: {},
      graphName: {},
      formErrors: {},
      dynamicMasterData: {},
      tableData: [],
      reportTableCheckedInfo: {},
      openFilter: false,
    });
    const { getReportName, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getReportName({ locationPath: id }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  componentWillReceiveProps(nextProps) {
    if (
      this.props.params != nextProps.params &&
      this.props.params.id != nextProps.params.id
    ) {
      this.getReportInfo(nextProps.params.id);
    }
  }
  getDropdownList = async (report) => {
    const {
      // getAreaByUserType,
      showLoador,
      showNotification,
    } = this.props;
    const { dynamicMasterData } = this.state;
    if (navigator.onLine) {
      const fieldMeta = JSON.parse(report.parameterRequired);
      console.log(fieldMeta);

      let filterList = fieldMeta.filter(
        (fieldObj) =>
          (fieldObj.url && fieldObj.url != "" && fieldObj.getListFrom == "") ||
          (fieldObj.localList && fieldObj.localList.length != 0)
      );
      Promise.all(filterList.map((fieldObj) => this.getDynamicList(fieldObj)));
    } else {
      showNotification({ msg: msgConstant.noInternetMsg, severity: "error" });
    }
  };
  getDynamicList = async (fieldObj) => {
    if (fieldObj.localList && fieldObj.localList.length != 0) {
      let LocalList = fieldObj.localList.map((dataObj) => {
        return { id: dataObj.key, name: dataObj.label };
      });

      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          [fieldObj.masterName]: LocalList,
        },
      });

      return true;
    } else {
      const { showLoador, showNotification } = this.props;
      showLoador({ loador: true });
      let response = await apiGet({
        url: "/api/" + fieldObj.url,
      }).then(({ data, success }) => {
        if (success) {
          showLoador({ loador: false });
        } else {
          showLoador({ loador: false });
          showNotification({ msg: serverMsg, severity: "error" });
        }
        return { data: data.data, success };
      });
      if (response.success) {
        let masterListData =
          fieldObj.isContent == 1 && response.data && response.data.content
            ? response.data.content
            : response.data
              ? response.data
              : [];

        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            [fieldObj.masterName]: masterListData.map((dataObj) => {
              return {
                ...dataObj,
                id:
                  fieldObj.selectKey && dataObj[fieldObj.selectKey]
                    ? dataObj[fieldObj.selectKey]
                    : dataObj.id,
                name:
                  fieldObj.selectLabel && dataObj[fieldObj.selectLabel]
                    ? dataObj[fieldObj.selectLabel]
                    : dataObj.name,
              };
            }),
          },
        });
      }

      return response;
    }
  };
  onFormSearch = (flag) => {
    const { fieldData, reportName } = this.state;
    const fieldMeta = JSON.parse(reportName.parameterRequired);
    if (fieldMeta != null) {
      const dataToSave = {};
      fieldMeta.forEach((currentField) => {
        if (currentField.controlType === "datepicker") {
          if (fieldData[currentField.dataKey]) {
            dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
              ? fieldData[currentField.dataKey].format("DD-MM-YYYY")
              : "";
          } else {
            dataToSave[currentField.dataKey] = "";
          }
        } else {
          dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
            ? fieldData[currentField.dataKey]
            : "";
        }
      });
      if ((flag = 1)) {
        this.getSearchList(dataToSave, reportName);
      }
      return dataToSave;
    }
  };
  getDynamicListById = async (fieldObj, dataToSearch) => {
    let str = "?";
    fieldObj.filterListParameter &&
      fieldObj.filterListParameter.map((dataToSet) => {
        if (dataToSearch[dataToSet.value]) {
          str = str + dataToSet.key + "=" + dataToSearch[dataToSet.value] + "&";
        }
      });
    let strCheck = str.split("&");
    console.log();
    if (strCheck.length == fieldObj.filterListParameter.length + 1) {
      const { showLoador, showNotification } = this.props;
      showLoador({ loador: true });
      let response = {};
      if (fieldObj.isApi == 0) {
        if (fieldObj.isPostBody == undefined || fieldObj.isPostBody == 0) {
          response = await apiPost({
            url: "/api/" + fieldObj.url + str,
          }).then(({ data, success }) => {
            if (success) {
              showLoador({ loador: false });
            } else {
              showLoador({ loador: false });
              showNotification({ msg: serverMsg, severity: "error" });
            }
            return { data: data.data, success };
          });
        } else {
          let postObj = {};
          fieldObj.filterListParameter &&
            fieldObj.filterListParameter.map((dataToSet) => {
              if (dataToSearch[dataToSet.value]) {
                postObj = {
                  ...postObj,
                  [dataToSet.key]:
                    typeof dataToSearch[dataToSet.value] != "string"
                      ? dataToSearch[dataToSet.value]
                      : dataToSearch[dataToSet.value].split(","),
                };
              }
            });
          response = await apiPost({
            url: "/api/" + fieldObj.url,
            postBody: postObj,
          }).then(({ data, success }) => {
            if (success) {
              showLoador({ loador: false });
            } else {
              showLoador({ loador: false });
              showNotification({ msg: serverMsg, severity: "error" });
            }
            return { data: data.data, success };
          });
        }
      } else {
        response = await apiGet({
          url: "/api/" + fieldObj.url + str,
        }).then(({ data, success }) => {
          if (success) {
            showLoador({ loador: false });
          } else {
            showLoador({ loador: false });
            showNotification({ msg: serverMsg, severity: "error" });
          }
          return { data: data.data, success };
        });
      }

      if (response.success) {
        let masterListData =
          fieldObj.isContent == 1 && response.data && response.data.content
            ? response.data.content
            : response.data
              ? response.data
              : [];

        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            [fieldObj.masterName]: masterListData.map((dataObj) => {
              return {
                ...dataObj,
                id:
                  fieldObj.selectKey && dataObj[fieldObj.selectKey]
                    ? dataObj[fieldObj.selectKey]
                    : dataObj.id,
                name:
                  fieldObj.selectLabel && dataObj[fieldObj.selectLabel]
                    ? dataObj[fieldObj.selectLabel]
                    : dataObj.name,
              };
            }),
          },
        });
      }

      return response;
    }
    return true;
  };

  getSearchList = (dataToSearch, reportName) => {
    const { showLoador, showNotification } = this.props;
    let str = "";

    Object.keys(dataToSearch).forEach((k, i) => {
      console.log(k);
      if (str == "") {
        str = str + k + "=" + dataToSearch[k] + "&";
      } else {
        str = str + k + "=" + dataToSearch[k] + "&";
      }
    });


    if (reportName.methodType == 1) {

      //Tejas - Extract Object dataToSearch and return inside object's key-value for apiGet Parameter
      const queryStringForDataToSearch = new URLSearchParams(dataToSearch).toString();
      console.log(queryStringForDataToSearch);

      showLoador({ loador: true });
      apiGet({
        url: "/api/" + reportName.apiName + "?" + queryStringForDataToSearch,
      }).then(({ data, success }) => {
        if (!success) {
          showLoador({ loador: false });
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          showLoador({ loador: false });
          let tableData = [];
          // if (reportName.isSubreport == 0) {
          tableData = data.data.map((reportData, index) => {
            let reportObj = { index: index + 1, ...reportData };
            const reportInfo = reportName.reportColumnNames.map(
              (reportColumnNamesData, index) => {
                let strData = reportColumnNamesData.pojoFiledName.split(".");
                let strD = "";
                strData.map((objData, index) => {
                  if (index == 0) {
                    strD = reportData[objData];
                  } else {
                    strD = strD[objData];
                  }
                });
                reportObj = {
                  ...reportObj,
                  [reportColumnNamesData.fieldName]:
                    reportColumnNamesData.isTotal &&
                      reportColumnNamesData.isTotal == 1
                      ? (+strD).toFixed(
                        reportColumnNamesData.fixedValue
                          ? reportColumnNamesData.fixedValue
                          : 2
                      )
                      : strD,
                };
              }
            );

            return reportObj;
          });

          if (tableData.length != 0) {
            this.setState({
              tableData: [
                {
                  reportName: reportName,
                  graphName: {},
                  openGraph: false,
                  tableData: tableData,
                },
              ],
            });
          }
        }
      });

    } else {

      showLoador({ loador: true });
      apiPost({
        url: "/api/" + reportName.apiName,
        postBody: dataToSearch
      }).then(({ data, success }) => {
        if (!success) {
          showLoador({ loador: false });
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          showLoador({ loador: false });
          let tableData = [];
          // if (reportName.isSubreport == 0) {
          tableData = data.data.map((reportData, index) => {
            let reportObj = { index: index + 1, ...reportData };
            const reportInfo = reportName.reportColumnNames.map(
              (reportColumnNamesData, index) => {
                let strData = reportColumnNamesData.pojoFiledName.split(".");
                let strD = "";
                strData.map((objData, index) => {
                  if (index == 0) {
                    strD = reportData[objData];
                  } else {
                    strD = strD[objData];
                  }
                });
                reportObj = {
                  ...reportObj,
                  [reportColumnNamesData.fieldName]:
                    reportColumnNamesData.isTotal &&
                      reportColumnNamesData.isTotal == 1
                      ? (+strD).toFixed(
                        reportColumnNamesData.fixedValue
                          ? reportColumnNamesData.fixedValue
                          : 2
                      )
                      : strD,
                };
              }
            );

            return reportObj;
          });

          if (tableData.length != 0) {
            this.setState({
              tableData: [
                {
                  reportName: reportName,
                  graphName: {},
                  openGraph: false,
                  tableData: tableData,
                },
              ],
            });
          }
        }
      });

    }


  };

  getFilteredTableData = (tableData, reportName) => {
    const { searchValue } = this.state;

    // const columsFil = tableColumns.filter((item) => {
    //     return item.canSearch;
    // });
    return tableData.filter((currentRow) => {
      let isValid = false;
      reportName.reportColumnNames.some((col) => {
        if (
          currentRow[col.fieldName] &&
          currentRow[col.fieldName]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
          return true;
        }
        return null;
      });
      return isValid;
    });
  };

  getTotalData = (tableData, reportName) => {
    let totalList = [];
    reportName.reportColumnNames.map((row) => {
      if (row.isChecked && row.isList != 1) {
        if (row.isTotal == 1) {
          let total = 0;
          tableData.map((rowData) => {
            total = total + +rowData[row.name];
          });
          totalList.push(total.toFixed(row.fixedValue ? row.fixedValue : 2));
        } else {
          totalList.push("");
        }
      }
    });
    return totalList;
  };
  resetData = () => {
    this.setState({
      fieldData: {},
    });
  };
  onAutocompleteChange = (name, newValue) => {
    if (newValue != null) {
      if (name == "reportName") {
        const filterData = this.props.report.report.filter(
          (row) => row.id == newValue
        );
        let parameter =
          JSON.parse(filterData[0].parameterRequired) != null
            ? JSON.parse(filterData[0].parameterRequired)
            : [];
        this.setState({
          tableData: [],
          fieldData: {},
          formErrors: {},
          [name]: filterData.length != 0 ? filterData[0] : {},
        });
        if (parameter.length != 0) {
          this.getDropdownList(filterData[0]);
        } else {
          this.getSearchList({}, filterData[0]);
        }
      } else {
        const filterData = this.state.reportName.graphColumns.filter(
          (row) => row.id == newValue
        );
        this.setState({
          [name]: filterData.length != 0 ? filterData[0] : {},
        });
      }
    } else {
      this.setState({
        [name]: {},
        tableData: [],
        fieldData: {},
      });
    }
  };
  onAutocompleteChangeGraph = (reportName) => (name, newValue) => {
    if (newValue != null) {
      const filterData = reportName.graphColumns.filter(
        (row) => row.graphId == newValue
      );
      this.setState({
        tableData: this.state.tableData.map((tableData) => {
          return {
            ...tableData,
            graphName:
              tableData.reportName.id != reportName.id
                ? tableData.graphName
                : filterData[0],
          };
        }),
      });
    } else {
      this.setState({
        tableData: this.state.tableData.map((tableData) => {
          return {
            ...tableData,
            graphName:
              tableData.reportName.id != reportName.id
                ? tableData.graphName
                : {},
          };
        }),
      });
    }
  };
  onClickFilter = (reportData) => {
    this.setState({
      reportTableCheckedInfo: reportData.reportName,
      openFilter: !this.state.openFilter,
    });
  };
  onClickGraph = (reportData) => {
    const tableInfo = this.state.tableData.map((reportInfo) => {
      if (reportInfo.reportName.id == reportData.reportName.id) {
        return {
          ...reportInfo,
          openGraph: !reportInfo.openGraph,
          graphName: {},
        };
      }
      return reportInfo;
    });
    this.setState({
      tableData: tableInfo,
    });
  };
  checkedIsList = (reportName, tableList, type) => {
    const tableData = this.getFilteredTableData(tableList, reportName);
    let filterForList = reportName.reportColumnNames.filter(
      (rowData) => rowData.isList == 1
    );
    if (type == "pdf") {
      if (filterForList.length != 0) {
        this.generatePDFHeaderDetailsMethod(
          reportName,
          filterForList[0],
          tableList,
          tableData
        );
      } else {
        this.generatePDFMethod(reportName, tableList, tableData);
      }
    } else {
      if (filterForList.length != 0) {
        this.generateExcelHeaderDetailsMethod(
          reportName,
          filterForList[0],
          tableList,
          tableData
        );
      } else {
        this.generateExcelMethod(reportName, tableList, tableData);
      }
    }
  };
  generatePDFHeaderDetailsMethod = (
    reportName,
    filterForList,
    tableList,
    tableData
  ) => {
    let rows = tableData.map((reportData, index) => {
      let reportObj = [];
      let reportList = [];
      let reportColumns = [];
      const reportInfo = reportName.reportColumnNames.map(
        (reportColumnNamesData, index) => {
          if (reportColumnNamesData.isList != 1) {
            reportObj.push({
              label: reportColumnNamesData.columnName,
              value: reportData[reportColumnNamesData.fieldName],
            });
          } else {
            let getSubData = this.getList(reportColumnNamesData, reportData);
            reportObj = [...reportObj, ...getSubData.reportObj];
            reportList = [...reportList, ...getSubData.reportList];
            reportColumns = [...reportColumns, ...getSubData.reportColumns];
          }
        }
      );

      return {
        reportObj: reportObj,
        reportColumns: reportColumns,
        reportList: reportList,
      };
    });
    console.log(rows);

    generateHeaderPDF({ rows, pdfTitle: reportName.name });
  };
  getList = (reportColumnNames, tableData) => {
    let reportObj = [];
    let reportList = [];
    let reportColumns = [];
    const filterData =
      reportColumnNames.subList &&
      reportColumnNames.subList.filter((col) => col.isList == 1);

    let suListData =
      tableData[reportColumnNames.fieldName] &&
      tableData[reportColumnNames.fieldName].map((reportData, index) => {
        if (filterData.length != 0) {
          reportColumnNames.subList.map((reportColumnNamesData, index) => {
            if (reportColumnNamesData.isList != 1) {
              reportObj.push({
                label: reportColumnNamesData.columnName,
                value: reportData[reportColumnNamesData.fieldName],
              });
            } else {
              let getSubData = this.getList(reportColumnNamesData, reportData);
              reportObj = [...reportObj, ...getSubData.reportObj];
              reportList = [...reportList, ...getSubData.reportList];
            }
          });
        } else {
          let reportOBJ = [];
          reportColumnNames.subList.map((reportColumnNamesData, index) => {
            if (reportColumnNamesData.isList != 1) {
              reportOBJ.push(reportData[reportColumnNamesData.fieldName]);
            }
          });
          reportList = [...reportList, reportOBJ];
        }
      });
    if (filterData.length == 0) {
      reportColumnNames.subList.map((reportColumnNamesData, index) => {
        if (reportColumnNamesData.isList != 1) {
          reportColumns = [...reportColumns, reportColumnNamesData.columnName];
        }
      });
    }
    return {
      reportObj: reportObj,
      reportColumns: reportColumns,
      reportList: reportList,
    };
  };
  generatePDFMethod = (reportName, tableList, tableData) => {
    var columns = [{ title: "Sr. No.", dataKey: "index" }];
    var align = { 0: { halign: "center" } };
    let alignIndex = 0;
    reportName.reportColumnNames.map((reportColumnNamesData, index) => {
      if (
        reportColumnNamesData.isChecked &&
        reportColumnNamesData.isList != 1
      ) {
        alignIndex = alignIndex + 1;
        align = {
          ...align,
          [alignIndex]: { halign: reportColumnNamesData.align },
        };
        columns.push({
          title: reportColumnNamesData.title,
          dataKey: reportColumnNamesData.name,
        });
      }
    });
    console.log(align);
    let rows = tableData.map((reportData, index) => {
      let reportObj = { index: index + 1 };
      const reportInfo = reportName.reportColumnNames.map(
        (reportColumnNamesData, index) => {
          if (reportColumnNamesData.isChecked) {
            reportObj = {
              ...reportObj,
              [reportColumnNamesData.fieldName]:
                reportColumnNamesData.isTotal == 1
                  ? (+reportData[reportColumnNamesData.fieldName]).toFixed(
                    reportColumnNamesData.fixedValue
                      ? reportColumnNamesData.fixedValue
                      : 2
                  )
                  : reportData[reportColumnNamesData.fieldName],
            };
          }
        }
      );

      return reportObj;
    });
    console.log(rows);
    let TotalList = this.getTotalData(tableList, reportName);
    if (TotalList.filter((rowData) => rowData != "").length != 0) {
      rows = [
        ...rows,
        [
          { content: `Total` },
          ...TotalList.map((totalObj) => {
            return { content: totalObj };
          }),
        ],
      ];
    }

    const fieldMeta = JSON.parse(this.state.reportName.parameterRequired);
    let headerList = fieldMeta.map((fieldInfo) => {
      return {
        label: fieldInfo.label,
        value:
          fieldInfo.controlType == "datepicker" &&
            this.state.fieldData[fieldInfo.dataKey]
            ? this.state.fieldData[fieldInfo.dataKey].format("DD-MM-YYYY")
            : fieldInfo.controlType == "multiSelect" &&
              this.state.fieldData[fieldInfo.dataKey]
              ? this.getName(
                this.state.fieldData[fieldInfo.dataKey],
                fieldInfo.dataKey
              )
              : fieldInfo.controlType == "autocomplete" &&
                this.state.fieldData[fieldInfo.dataKey]
                ? this.getName(
                  [this.state.fieldData[fieldInfo.dataKey]],
                  fieldInfo.dataKey
                )
                : this.state.fieldData[fieldInfo.dataKey],
      };
    });
    if (columns.length > 6) {
      this.props.showNotification({
        msg: "Plase Select only 6 columns",
        severity: "error",
      });
    } else {
      generatePDF({
        rows,
        columns,
        columnStyles: align,
        headerList,
        pdfTitle: reportName.name,
      });
    }
  };
  getName = (List, fieldName) => {
    const nameList = List.map((ListData) => {
      const filterData = this.state.dynamicMasterData[fieldName].filter(
        (dynamicList) => dynamicList.id == ListData
      );
      if (filterData.length != 0) {
        return filterData[0].name;
      }
      return "";
    });
    return nameList.join(", ");
  };
  generateExcelHeaderDetailsMethod = (
    reportName,
    filterForList,
    tableList,
    tableData
  ) => {
    let rows = tableData.map((reportData, index) => {
      let reportObj = [];
      let reportList = [];
      let reportColumns = [];
      const reportInfo = reportName.reportColumnNames.map(
        (reportColumnNamesData, index) => {
          if (reportColumnNamesData.isList != 1) {
            reportObj.push({
              label: reportColumnNamesData.columnName,
              value: reportData[reportColumnNamesData.fieldName],
            });
          } else {
            let getSubData = this.getListForExcel(
              reportColumnNamesData,
              reportData
            );
            reportObj = [...reportObj, ...getSubData.reportObj];
            reportList = [...reportList, ...getSubData.reportList];
            reportColumns = [...reportColumns, ...getSubData.reportColumns];
          }
        }
      );

      return {
        reportObj: reportObj,
        reportColumns: reportColumns,
        reportList: reportList,
      };
    });
    console.log(rows);
    generateHeaderExcel({ rows, title: reportName.name });
  };
  getListForExcel = (reportColumnNames, tableData) => {
    let reportObj = [];
    let reportList = [];
    let reportColumns = [];
    const filterData =
      reportColumnNames.subList &&
      reportColumnNames.subList.filter((col) => col.isList == 1);

    let suListData =
      tableData[reportColumnNames.fieldName] &&
      tableData[reportColumnNames.fieldName].map((reportData, index) => {
        if (filterData.length != 0) {
          reportColumnNames.subList.map((reportColumnNamesData, index) => {
            if (reportColumnNamesData.isList != 1) {
              reportObj.push({
                label: reportColumnNamesData.columnName,
                value: reportData[reportColumnNamesData.fieldName],
              });
            } else {
              let getSubData = this.getListForExcel(
                reportColumnNamesData,
                reportData
              );
              reportObj = [...reportObj, ...getSubData.reportObj];
              reportList = [...reportList, ...getSubData.reportList];
            }
          });
        } else {
          let reportOBJ = [];
          reportColumnNames.subList.map((reportColumnNamesData, index) => {
            if (reportColumnNamesData.isList != 1) {
              reportOBJ.push(reportData[reportColumnNamesData.fieldName]);
            }
          });
          reportList = [...reportList, reportOBJ];
        }
      });
    if (filterData.length == 0) {
      reportColumnNames.subList.map((reportColumnNamesData, index) => {
        if (reportColumnNamesData.isList != 1) {
          reportColumns = [...reportColumns, reportColumnNamesData.columnName];
        }
      });
    }
    return {
      reportObj: reportObj,
      reportColumns: reportColumns,
      reportList: reportList,
    };
  };
  generateExcelMethod = (reportName, tableList, tableData) => {
    var columns = ["Sr. No."];
    let alignList = [];
    reportName.reportColumnNames.map((reportColumnNamesData, index) => {
      if (reportColumnNamesData.isChecked) {
        alignList.push({
          index: index + 1,
          allignment: reportColumnNamesData.allignment,
        });
        columns.push(reportColumnNamesData.columnName);
      }
    });
    let rows = tableData.map((reportData, index) => {
      let reportObj = [index + 1];
      const reportInfo = reportName.reportColumnNames.map(
        (reportColumnNamesData, index) => {
          if (reportColumnNamesData.isChecked) {
            reportObj.push(reportData[reportColumnNamesData.fieldName]);
          }
        }
      );
      return reportObj;
    });
    let TotalList = this.getTotalData(tableList, reportName);
    rows = [...rows, [`Total`, ...TotalList]];
    const fieldMeta = JSON.parse(this.state.reportName.parameterRequired);

    let headerList = [""];
    fieldMeta.map((fieldInfo) => {
      headerList.push(fieldInfo.label);
      if (fieldInfo.controlType == "datepicker") {
        headerList.push(
          this.state.fieldData[fieldInfo.dataKey]
            ? this.state.fieldData[fieldInfo.dataKey].format("DD-MM-YYYY")
            : ""
        );
      } else if (fieldInfo.controlType == "multiSelect") {
        headerList.push(
          this.state.fieldData[fieldInfo.dataKey]
            ? this.getName(
              this.state.fieldData[fieldInfo.dataKey],
              fieldInfo.dataKey
            )
            : ""
        );
      } else if (fieldInfo.controlType == "autocomplete") {
        headerList.push(
          this.state.fieldData[fieldInfo.dataKey]
            ? this.getName(
              [this.state.fieldData[fieldInfo.dataKey]],
              fieldInfo.dataKey
            )
            : ""
        );
      } else {
        headerList.push(
          this.state.fieldData[fieldInfo.dataKey]
            ? this.state.fieldData[fieldInfo.dataKey]
            : ""
        );
      }
    });
    generateExcel({
      columns,
      rows,
      alignList,
      headerList,
      title: reportName.name,
      reportName: reportName,
    });
  };
  rowReport = (reportName) => async (row) => {
    if (navigator.onLine) {
      const { showLoador, showNotification } = this.props;
      showLoador({ loador: true });
      const reportDetails = await apiGet({
        url: endpoint.report + "/" + reportName.flag + "/by-id",
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          if (!data.error) {
            let reportObject = data.data;
            const reportColumnNames =
              JSON.parse(reportObject.tableColumns) != null
                ? JSON.parse(reportObject.tableColumns)
                : [];
            const graphColumns =
              JSON.parse(reportObject.graphColumns) != null
                ? JSON.parse(reportObject.graphColumns)
                : [];
            const ColumnNames = reportColumnNames.filter(
              (row) => row.isShow == 1
            );
            reportObject = {
              ...reportObject,
              name: reportObject.reportName,
              reportColumnNames: ColumnNames.map((columns) => {
                return {
                  ...columns,
                  title: columns.columnName,
                  name: columns.fieldName,
                  formDataKey: columns.fieldName,
                  align: columns.allignment,
                  isTotal: columns.isTotal ? columns.isTotal : 0,
                  showInExcel: true,
                  canSearch: true,
                  isChecked: true,
                };
              }),
              graphColumns: graphColumns,
              reportParams: reportObject.params,
            };
            return reportObject;
          }
          return {};
        }

        return {};
      });
      if (Object.keys(reportDetails).length != 0) {
        console.log(reportDetails.params);
        let params = JSON.parse(reportDetails.params)
          ? JSON.parse(reportDetails.params)
          : {};

        let strData = params.paramsValue ? params.paramsValue.split(".") : [];
        let valueData = params.paramsKey ? params.paramsKey : "";

        let strD = "";
        strData.map((objData, index) => {
          if (index == 0) {
            strD = row[objData];
          } else {
            strD = strD[objData];
          }
        });
        apiGet({
          url: "/api/" + reportDetails.apiName + "?" + valueData + "=" + strD,
        }).then(({ data, success }) => {
          if (!success) {
            showLoador({ loador: false });
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            showLoador({ loador: false });
            let tableData = [];
            tableData = data.data.map((reportData, index) => {
              let reportObj = { index: index + 1 };
              const reportInfo = reportDetails.reportColumnNames.map(
                (reportColumnNamesData, index) => {
                  let strData = reportColumnNamesData.pojoFiledName.split(".");
                  let strD = "";
                  strData.map((objData, index) => {
                    if (index == 0) {
                      strD = reportData[objData];
                    } else {
                      strD = strD[objData];
                    }
                  });
                  reportObj = {
                    ...reportObj,
                    [reportColumnNamesData.fieldName]:
                      reportColumnNamesData.isTotal &&
                        reportColumnNamesData.isTotal == 1
                        ? (+strD).toFixed(
                          reportColumnNamesData.fixedValue
                            ? reportColumnNamesData.fixedValue
                            : 2
                        )
                        : strD,
                  };
                }
              );

              return reportObj;
            });
            let filterReport = this.state.tableData.filter(
              (row) => row.reportName.id == reportDetails.id
            );

            let reportInfo = {
              reportName: reportDetails,
              graphName: {},
              openGraph: false,
              tableData: tableData,
            };
            this.setState({
              tableData:
                filterReport.length == 0
                  ? [...this.state.tableData, reportInfo]
                  : this.state.tableData.map((tableReport) => {
                    if (tableReport.reportName.id == reportDetails.id) {
                      return reportInfo;
                    }
                    return tableReport;
                  }),
            });
          }
        });
      }
    } else {
      this.props.showNotification({
        msg: msgConstant.noInternetMsg,
        severity: "error",
      });
    }
  };
  setReportFilterList = (reportList) => {
    const tableList = this.state.tableData.map((reportData) => {
      if (this.state.reportTableCheckedInfo.id == reportData.reportName.id) {
        return {
          ...reportData,
          reportName: {
            ...reportData.reportName,
            reportColumnNames: reportList,
          },
        };
      }
      return reportData;
    });
    console.log(tableList);
    this.setState({
      openFilter: false,
      tableData: tableList,
      reportTableCheckedInfo: {},
    });
  };
  render() {
    const {
      screenTitle = "",
      PDFButton,
      buttonCenter,
      EXCELButton,
      marginTop,
      searchButton,
      report,
    } = this.props;
    const {
      fieldData,
      formErrors,
      reportName,
      reportTableCheckedInfo,
      openFilter,
      dynamicMasterData,
      tableData,
      fieldDataForModule,
    } = this.state;
    return (
      <>
        <Paper sx={{ p: 2, pt: 2, borderRadius: 2 }} elevation={0}>
          <LandingScreenHeader
            tableColumnsToFilter={[]}
            screenTitle={screenTitle}
            showPdfDownload={false}
            showExcelDownload={false}
            showAddButton={false}
            showTable={false}
          />
          <br />

          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={6}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={"Select Report"}
              />

              <AutoComplete
                keyColName={"id"}
                value={Object.keys(reportName).length != 0 && reportName.id}
                name="reportName"
                options={report.report}
                onChange={this.onAutocompleteChange}
              // isError={formErrors[currentField.dataKey]?.isError}
              // errorText={formErrors[currentField.dataKey]?.errorText}
              />
            </Grid>
          </Grid>
          {Object.keys(reportName).length != 0 && (
            <>
              {JSON.parse(reportName.parameterRequired) != null &&
                JSON.parse(reportName.parameterRequired).length != 0 && (
                  <DynamicForm
                    reportDetails={reportName}
                    dynamicMasterData={dynamicMasterData}
                    fieldData={fieldData}
                    formErrors={formErrors}
                    onDataChange={this.onDataChange}
                    buttonCenter={buttonCenter}
                    searchButton={searchButton}
                    PDFButton={PDFButton}
                    EXCELButton={EXCELButton}
                    marginTop={marginTop}
                    onFormSave={this.onFormSearch}
                  />
                )}
              {tableData.length != 0 &&
                tableData.map((tableInfoData, index) => {
                  return (
                    <>
                      {index != 0 ? (
                        <>
                          <Grid
                            item
                            sm={12}
                            xs={12}
                            md={12}
                            lg={12}
                            sx={{ marginTop: 3 }}
                            container
                            justifyContent="left"
                          >
                            <Grid item xs={12} sm={6} md={6}>
                              <LabelCompo
                                className="text-black"
                                style={{
                                  fontSize: 16,
                                  color: titleColor,
                                }}
                                label={"Report Name : "}
                              />
                              <LabelCompo
                                className="text-black"
                                style={{ fontSize: 16 }}
                                label={tableInfoData.reportName.reportName}
                              />
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <br />
                          <br />
                        </>
                      )}

                      <>
                        <Grid
                          item
                          sm={12}
                          xs={12}
                          md={12}
                          lg={12}
                          rowSpacing={2}
                          columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                          container
                          justifyContent="right"
                        >
                          {tableInfoData.reportName.isGraph ? (
                            <>
                              {tableInfoData.openGraph ? (
                                <>
                                  <MyComponentWithIconProps
                                    statusImage={VisibilityIcon}
                                    color="primary"
                                    fontSize="medium"
                                    title="View"
                                    onClick={() => {
                                      this.onClickGraph(tableInfoData);
                                    }}
                                  />
                                </>
                              ) : (
                                <>
                                  <MyComponentWithIconProps
                                    statusImage={AssessmentIcon}
                                    color="primary"
                                    fontSize="medium"
                                    title="Graph"
                                    onClick={() => {
                                      this.onClickGraph(tableInfoData);
                                    }}
                                  />
                                </>
                              )}
                            </>
                          ) : null}
                          &nbsp; &nbsp;
                          <MyComponentWithIconProps
                            statusImage={FilterAltIcon}
                            color="secondary"
                            fontSize="medium"
                            title="Filter"
                            onClick={() => {
                              this.onClickFilter(tableInfoData);
                            }}
                          />
                          {fieldDataForModule.editReject == 1 && tableInfoData.reportName.isExcel ? (
                            <>
                              &nbsp; &nbsp;
                              <FontAwesomeIconCompo
                                color="secondary"
                                fontSize="small"
                                title="Excel"
                                baseClassName="fas"
                                className="fa-file-excel"
                                onClick={() => {
                                  this.checkedIsList(
                                    tableInfoData.reportName,
                                    tableInfoData.tableData,
                                    "excel"
                                  );
                                }}
                              />
                            </>
                          ) : null}
                          {fieldDataForModule.editReject == 1 && tableInfoData.reportName.isPdf ? (
                            <>
                              &nbsp; &nbsp;
                              <FontAwesomeIconCompo
                                color="error"
                                fontSize="small"
                                title="PDF"
                                baseClassName="fas"
                                className="fa-file-pdf"
                                onClick={() => {
                                  this.checkedIsList(
                                    tableInfoData.reportName,
                                    tableInfoData.tableData,
                                    "pdf"
                                  );
                                }}
                              />
                            </>
                          ) : null}
                        </Grid>
                      </>
                      {tableInfoData.openGraph ? (
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={6} md={6}>
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 16 }}
                              label={"Select Type"}
                            />
                            <AutoComplete
                              keyColName={"id"}
                              value={
                                Object.keys(tableInfoData.graphName).length !=
                                0 && tableInfoData.graphName.graphId
                              }
                              name="graphName"
                              options={tableInfoData.reportName.graphColumns.map(
                                (grphCol) => {
                                  return {
                                    id: grphCol.graphId,
                                    name: grphCol.graphName,
                                  };
                                }
                              )}
                              onChange={this.onAutocompleteChangeGraph(
                                tableInfoData.reportName
                              )}
                            // isError={formErrors[currentField.dataKey]?.isError}
                            // errorText={formErrors[currentField.dataKey]?.errorText}
                            />
                          </Grid>

                          {Object.keys(tableInfoData.graphName).length != 0 &&
                            tableInfoData.graphName.xAxis &&
                            tableInfoData.graphName.yAxis &&
                            tableInfoData.graphName.graphId == 1 ? (
                            tableInfoData.graphName.yAxis.map((yAxisData) => {
                              return (
                                <>
                                  <Grid item xs={12} sm={12} md={12}>
                                    <PieChart
                                      series={tableInfoData.tableData.map(
                                        (tableInfo) => {
                                          return tableInfo[yAxisData.name]
                                            ? +tableInfo[yAxisData.name]
                                            : 0;
                                        }
                                      )}
                                      options={{
                                        chart: {
                                          width: 380,
                                          type: "pie",
                                        },
                                        animations: {
                                          enabled: true,
                                          easing: "easeinout",
                                          speed: 1000,
                                          animateGradually: {
                                            enabled: true,
                                            delay: 150,
                                          },
                                          dynamicAnimation: {
                                            enabled: true,
                                            speed: 100,
                                          },
                                        },
                                        labels: tableInfoData.tableData.map(
                                          (tableInfo) => {
                                            return tableInfo[
                                              tableInfoData.graphName.xAxis.name
                                            ];
                                          }
                                        ),
                                        responsive: [
                                          {
                                            breakpoint: 480,
                                            options: {
                                              chart: {
                                                width: 300,
                                              },
                                              legend: {
                                                position: "bottom",
                                              },
                                            },
                                          },
                                        ],
                                      }}
                                      width={500}
                                    />
                                  </Grid>
                                </>
                              );
                            })
                          ) : Object.keys(tableInfoData.graphName).length !=
                            0 &&
                            tableInfoData.graphName.xAxis &&
                            tableInfoData.graphName.yAxis &&
                            tableInfoData.graphName.graphId == 2 ? (
                            <Grid item xs={12} sm={12} md={12}>
                              <BarChartMultiple
                                series={tableInfoData.graphName.yAxis.map(
                                  (yAxisData) => {
                                    return {
                                      name: yAxisData.label,
                                      data: tableInfoData.tableData.map(
                                        (tableInfo) => {
                                          return tableInfo[yAxisData.name];
                                        }
                                      ),
                                    };
                                  }
                                )}
                                options={{
                                  chart: {
                                    type: "bar",
                                    height: 350,
                                  },
                                  plotOptions: {
                                    bar: {
                                      horizontal: false,
                                      columnWidth: "55%",
                                      endingShape: "rounded",
                                    },
                                  },
                                  dataLabels: {
                                    enabled: false,
                                  },
                                  stroke: {
                                    show: true,
                                    width: 2,
                                    colors: ["transparent"],
                                  },
                                  xaxis: {
                                    categories: tableInfoData.tableData.map(
                                      (tableInfo) => {
                                        return tableInfo[
                                          tableInfoData.graphName.xAxis.name
                                        ];
                                      }
                                    ),
                                  },
                                  // yaxis: {
                                  //     title: {
                                  //         text: '$ (thousands)'
                                  //     }
                                  // },
                                  fill: {
                                    opacity: 1,
                                  },
                                  // tooltip: {
                                  //     y: {
                                  //         formatter: function (val) {
                                  //             return  val + " ₹"
                                  //         }
                                  //     }
                                  // }
                                }}
                              />
                            </Grid>
                          ) : null}
                        </Grid>
                      ) : (
                        <>
                          <RTTable
                            columns={
                              tableInfoData.reportName.flag == 0
                                ? [
                                  ...tableInfoData.reportName
                                    .reportColumnNames,
                                ]
                                : [
                                  ...tableInfoData.reportName
                                    .reportColumnNames,
                                  {
                                    title: "Action",
                                    name: "action",
                                    formDataKey: "action",
                                    showInExcel: true,
                                    canSearch: true,
                                  },
                                ]
                            }
                            hiddenColumnNames={tableInfoData.reportName.reportColumnNames.map(
                              (item) => {
                                return !item.isChecked ? item.name : "";
                              }
                            )}
                            showEditIcon={false}
                            showDeleteIcon={false}
                            tableData={this.getFilteredTableData(
                              tableInfoData.tableData,
                              tableInfoData.reportName
                            )}
                            totalList={this.getTotalData(
                              tableInfoData.tableData,
                              tableInfoData.reportName
                            )}
                            isActionColActive={
                              tableInfoData.reportName.flag == 0 ? false : true
                            }
                            showReportIcon={
                              tableInfoData.reportName.flag == 0 ? false : true
                            }
                            showPegination={false}
                            rowReport={this.rowReport(tableInfoData.reportName)}
                          />
                        </>
                      )}
                    </>
                  );
                })}
            </>
          )}
        </Paper>

        {openFilter && (
          <ColumnFilterTablePopUp
            columnList={reportTableCheckedInfo.reportColumnNames}
            setReportData={this.setReportFilterList}
            openDialog={openFilter}
            setOpenDialouge={(openDia) => {
              this.setState({
                openFilter: openDia,
              });
            }}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({ report: state.report });
const mapDispatchToProps = {
  downloadF,
  getReportName,
  getAreaByUserType,
  showLoador,
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(DynamicMainScreen);
