import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import { withRouter } from "../../components/withRouter";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import { ProductRateStructureJson } from "../../DynamicFormsJson/Transaction/productRateStructure";
import {
  getproductRateStructureAssignationNewAPI,
  getProductRateStructureGetAll,
  getProductRateStructureNew,
  getProductRateStructureOptimised,
  getProductRateStructurePublishList,
  getTopProductRateStructure,
  setProductRateStructure,
} from "../../Slice/productRateStructure.slice";
import { ProductRateStructureOptimisedColumns } from "../../tableColumns/table-columns";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import AssignationPopUp from "./AssignationPopUp";
import PublishPopUp from "./PublishPopUp";
import StakeholderPopUp from "./StakeholderPopUp";
import { getStockStakeHolderType } from "../../Slice/stockStakeHolderType.slice";
import { downloadF } from "../PDF/PDFDownload";
import endpoint from "../../config/endpoints";

class ProductRateStructureOptimisedList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 1,
      dynamicMasterData: {
        productRateStructureActive:
          this.props.productRateStructureList?.productRateStructure.filter(
            (row) => row.isActive == 1
          ),
      },
      assignPopUp: false,
      publishPopUp: false,
      strucureNameForAssign: "",
      strucureNameForPublish: "",
      productRateStructureIdFlag: "",
      companyFlagOfc: false,
      stakeholderData: [],
      stockHolderTypeIds: [],
    };
  }
  async componentDidMount() {
    const { getStockStakeHolderType } = this.props;

    showLoador({ loador: true });
    await getStockStakeHolderType({ userTypeIds: "2" }).then(
      ({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          const stockHolderTypeIds = response.map((row) => row.id);
          this.setState({ stockHolderTypeIds });
        }
      }
    );

    this.getData();
  }

  getData() {
    const {
      getProductRateStructureNew,
      getTopProductRateStructure,
      showLoador,
      showNotification,
      getProductRateStructureOptimised,
    } = this.props;
    if (navigator.onLine) {
      const showCompanyFlag = localStorage.getItem("showCompanyFlag");

      if (showCompanyFlag == 0) {
        showLoador({ loador: true });
        getProductRateStructureNew().then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });

        this.setState({
          companyFlagOfc: true,
        });
      } else {
        showLoador({ loador: true });
        getProductRateStructureOptimised().then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            this.setState({
              dynamicMasterData: {
                productRateStructureActive:
                  this.props.productRateStructureList?.productRateStructure.filter(
                    (row) => row.isActive == 1
                  ),
              },
            });
          }
        });
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  rowCopy = (rowData) => {
    this.props.navigate("/copy-product-rate-structure/" + rowData.id);
  };
  handleClosesStakeholder = () => { };

  handleCloses = (id) => {
    this.setState({
      assignPopUp: false,
      publishPopUp: false,
    });
  };

  stakeholderHandler = (row) => {
    this.setState({
      strucureNameForAssign: row.name,
    });
    const {
      getproductRateStructureAssignationNewAPI,
      showLoador,
      showNotification,
    } = this.props;
    const { productRateStructureIdFlag, stockHolderTypeIds } = this.state;
    showLoador({ loador: true });
    getproductRateStructureAssignationNewAPI({
      productRateStructureId: row.id,
      stockStakeHolderTypeIds: stockHolderTypeIds,
    }).then(({ response, success }) => {
      showLoador({ loador: false });
      if (!success) {
        showNotification({ msg: serverMsg, severity: "error" });
      } else {
        this.setState({
          assignPopUp: true,
          strucureNameForAssign:
            response.length != 0 ? response[0].strucureName : "",
        });
      }
    });
  };
  rowHandler = (row, id) => {
    const {
      getproductRateStructureAssignationNewAPI,
      getProductRateStructurePublishList,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      if (id.name == "assignCount") {
        this.setState({
          stakeholderData: row.stakeholderTypeList,
          strucureNameForAssign: row.name,
          productRateStructureIdFlag: row.id,
        });
      }

      if (id.name == "publishCount") {
        showLoador({ loador: true });
        getProductRateStructurePublishList({
          productRateStructureId: row.id,
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            this.setState({
              publishPopUp: true,
              strucureNameForPublish:
                response.length != 0 ? response[0].strucureName : "",
            });
          }
        });
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onClickTransfer = (rowData) => {
    this.props.navigate("/structure-assignation/" + rowData.id);
  };
  onSearchList = (data) => {
    const { getProductRateStructureGetAll, showLoador, showNotification } =
      this.props;
    showLoador({ loador: true });
    getProductRateStructureGetAll({
      fromDate: data.fromdate,
      toDate: data.todate,
    }).then(({ response, success }) => {
      showLoador({ loador: false });
      if (!success) {
        showNotification({ msg: serverMsg, severity: "error" });
      }
    });
  };

  getTabValue = (rowValue) => {
    console.log(rowValue);
    this.setState({
      tabValue: rowValue == 0 ? 1 : 0,
    });
  };

  getTabValue = (rowValue) => {
    console.log(rowValue);
    this.setState({
      tabValue: rowValue == 0 ? 1 : 0,
    });
  };

  rowEWay = (row) => {
    const { showLoador, showNotification } = this.props;
    const postBodyData = {
      productRateStructureId: row.id,
    };
    if (navigator.onLine) {
      showLoador({ loador: true });
      downloadF({
        isGet: false,
        url: endpoint.exportData + "/product-rate-structure",
        postBody: postBodyData,
        ext: "xls",
        openNewTab: false,
      }).then((response) => {
        if (!response) {
          showNotification({
            msg: serverMsg,
            severity: "error",
          });
        }
        showLoador({ loador: false });
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  render() {
    const {
      productRateStructureList,
      getProductRateStructureNew,
      assignList,
      getProductRateStructureOptimised,
    } = this.props;
    const {
      assignPopUp,
      publishPopUp,
      strucureNameForAssign,
      strucureNameForPublish,
      productRateStructureIdFlag,
      stakeholderData,
      companyFlagOfc,
      tabList,
      dynamicMasterData,
    } = this.state;

    return (
      <>
        <DynamicMainScreen
          searchList={ProductRateStructureJson.searchList}
          onSearchData={this.onSearchList}
          formPath={ProductRateStructureJson.formPath}
          showExcelDownload={false}
          showPdfDownload={false}
          showDeleteIcon={false}
          formPathView={ProductRateStructureJson.formPathView}
          showViewButtonInTable={true}
          screenTitle={ProductRateStructureJson.screenTitle}
          showAddButton={ProductRateStructureJson.showAddButton}
          tableColumns={ProductRateStructureOptimisedColumns}
          tableData={productRateStructureList?.productRateStructure}
          apiBaseURL={ProductRateStructureJson.apiBaseURL}
          isActiveURL={ProductRateStructureJson.isActiveURL}
          callApi={false}
          getTableDataById={true}
          showApply={true}
          showApplyTitle={"Publish Structure"}
          rowApply={this.stakeholderHandler}
          isNote={true}
          isNoteValue={"Add Product Rate Structure"}
          searchButtonGrid={ProductRateStructureJson.searchButtonGrid}
          getTableData={
            companyFlagOfc
              ? getProductRateStructureNew
              : getProductRateStructureOptimised
          }
          showCopyIcon={true}
          copyAuto={false}
          rowCopy={this.rowCopy}
          linkStatic={false}
          rowLinkToShowData={this.rowHandler}
          showTransferButtonInTable={true}
          openFormPathStatus={ProductRateStructureJson.openFormPathStatus}
          onClickTransfer={this.onClickTransfer}
          isLog={true}
          type={1}
          staticExcel={true}
          rowExcel={this.rowEWay}
          showExcel={
            localStorage.getItem("showExcelInProductRateStructureList") &&
              localStorage.getItem("showExcelInProductRateStructureList") == 1 ?
              true : false
          }
        />
        {assignPopUp && (
          <AssignationPopUp
            open={assignPopUp}
            handleCloses={this.handleCloses}
            DataToSet={[
              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                labelXS: 12,
                labelSM: 3,
                labelMD: 3,
                labelLG: 3,
                valueXS: 12,
                valueSM: 9,
                valueMD: 9,
                valueLG: 9,
                label: "Structure",
                value: strucureNameForAssign,
              },
            ]}
            columns={[
              {
                title: "Sr. No.",
                name: "index",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "center",
              },
              {
                title: "Stock Holder",
                name: "ownerName",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
                canSearch: "true",
              },
              {
                title: "Firm Name",
                name: "firmName",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
                canSearch: "true",
              },
            ]}
            rows={assignList?.productRateStructureAssignation}
          />
        )}
        {publishPopUp && (
          <PublishPopUp
            open={publishPopUp}
            handleCloses={this.handleCloses}
            productRateStructureIdFlag={productRateStructureIdFlag}
            DataToSet={[
              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                labelXS: 12,
                labelSM: 3,
                labelMD: 3,
                labelLG: 3,
                valueXS: 12,
                valueSM: 9,
                valueMD: 9,
                valueLG: 9,
                label: "Structure Name",
                value: strucureNameForPublish,
              },
            ]}
            columns={[
              {
                title: "Sr. No.",
                name: "index",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "center",
              },
              {
                title: "Firm Name",
                name: "firmName",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
                canSearch: "true",
              },
              {
                title: "Owner Name",
                name: "ownerName",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
                canSearch: "true",
              },
              {
                title: "Area Name",
                name: "areaName",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
                canSearch: "true",
              },
              {
                title: "Publish Date",
                name: "publishDate",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "center",
                canSearch: "true",
              },
            ]}
            rows={assignList?.productRateStructurePublish}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  productRateStructureList: state.productRateStructure,
  assignList: state.productRateStructure,
  stockStakeHolderTypeList: state.stockStakeHolderType,
});

const mapDispatchToProps = {
  getProductRateStructureGetAll,
  setProductRateStructure,
  showLoador,
  showNotification,
  getproductRateStructureAssignationNewAPI,
  getProductRateStructurePublishList,
  getProductRateStructureNew,
  getTopProductRateStructure,
  getProductRateStructureOptimised,
  getStockStakeHolderType,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ProductRateStructureOptimisedList);
