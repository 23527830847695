import CloseIcon from "@mui/icons-material/Close";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { auditJson } from "../../DynamicFormsJson/MastersJSON/audit";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const AddQuestion = ({ dataToSave, value, open, handleCloses, onSave }) => {
  console.log(value);
  const [dynamicMasterData, setDynamicMasterData] = React.useState(value == 2 ? {
    optionTypeList: [
      {
        id: "1",
        name: "Optional",
      },
      {
        id: "2",
        name: "Multi Select",
      },
    ],
  } : {
    optionTypeList: [
      {
        id: "0",
        name: "Description",

      },
      {
        id: "1",
        name: "Optional",
      },
      {
        id: "2",
        name: "Multi Select",
      },
    ],
  });


  const handleClose = () => {
    handleCloses();
  };
  useEffect(() => { }, []);

  const submitHandler = (dataToSave) => {
    console.log(dataToSave);
    onSave(dataToSave);
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="lg"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Add Question
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <DynamicFormWithoutSave

            showTitle={false}
            showBackToList={true}
            DataToSet={[]}
            defaultData={dataToSave}
            dynamicMasterData={dynamicMasterData}
            getByIdApi={false}
            callApi={false}
            screenTitle={auditJson.screenTitle}
            fieldMeta={value == 2 ? auditJson.fieldMetaForQestionForAreaExploration : auditJson.fieldMetaForQestion}
            showCancel={auditJson.showCancel}
            apiBaseURL={auditJson.apiBaseURL}
            showSaveNextBtn={auditJson.showSaveNextBtn}
            showSaveBtnMain={false}
            showSaveBtn={true}
            saveBtnText={"Add"}
            onSave={submitHandler}
          />
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};
const mapStateToProps = (state) => ({ feedBack: state.feedBack });

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AddQuestion);
