import React from "react";
import { connect } from "react-redux";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import { ManagerJson } from "../../../DynamicFormsJson/MastersJSON/manager";
import { getRegionNew, getZoneByRegion } from "../../../Slice/region.slice";
import { getStateByRegion } from "../../../Slice/state.slice";
import { getZoneByState } from "../../../Slice/zone.slice";
import { getDistrictByZone } from "../../../Slice/district.slice";
import { getTalukaByDistrict } from "../../../Slice/taluka.slice";
import { getAreaByTalukaPostBody } from "../../../Slice/area.slice";
import { getStockStakeHolderType } from "../../../Slice/stockStakeHolderType.slice";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import { serverMsg, noInternetMsg } from "../../../config/messageconstant";
import {
  getAreaByStockStakeHolderTypeIdAndZoneIds,
  getAreaByStockStakeHolderTypeIdAndZoneIdsEdit,
} from "../../../Slice/salesExecutive.slice";
import { withRouter } from "../../../components/withRouter";
import { compose } from "@reduxjs/toolkit";
import { apiGet } from "../../../utils/api_service";
import { getAboveOrderAndSelfById } from "../../../Slice/stockStakeHolderType.slice";
import { getSalesExecutiveByAreaAndStockHolderType } from "../../../Slice/salesExecutive.slice";

class ManagerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLog: true,
      dynamicMasterData: {
        regionDetail: this.props.regionList?.region,
        stateDetail: this.props.stateList?.stateByRegion,
        zoneDetail: this.props.zoneList?.zoneByRegionId,
        districtDetail: this.props.districtList?.districtByZone,
        talukaDetail: this.props.talukaList?.talukaByDistrict,
        areaDetail: this.props.areaList?.areaByTaluka,
        stockStakeHolderTypeDetail:
          this.props.stockStakeHolderTypeList?.stockStakeHolderType,
      },
      stockStakeHolderTypeId: "",
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.regionList !== nextProps.regionList) {
      if (this.props.regionList?.region !== nextProps.regionList?.region) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            regionDetail: nextProps.regionList?.region,
          },
        });
      }
    }

    if (this.props.stockStakeHolderTypeList !== nextProps.stockStakeHolderTypeList) {
      if (
        this.props.stockStakeHolderTypeList?.stockStakeHolderType !==
        nextProps.stockStakeHolderTypeList?.stockStakeHolderType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stockStakeHolderTypeDetail:
              nextProps.stockStakeHolderTypeList?.stockStakeHolderType,
          },
        });
      }
    }
    if (this.props.stockStakeHolderTypeList !== nextProps.stockStakeHolderTypeList) {
      if (
        this.props.stockStakeHolderTypeList?.aboveOrderById !==
        nextProps.stockStakeHolderTypeList?.aboveOrderById
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            authorityTypeDetail:
              nextProps.stockStakeHolderTypeList?.aboveOrderById,
          },
        });
      }
    }
    if (this.props.salesExecutiveList !== nextProps.salesExecutiveList) {
      if (
        this.props.salesExecutiveList?.salesExecutiveByAreaAndStockHolderType !==
        nextProps.salesExecutiveList?.salesExecutiveByAreaAndStockHolderType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            authorityDetail:
              nextProps.salesExecutiveList?.salesExecutiveByAreaAndStockHolderType,
          },
        });
      }
    }

    if (this.props.stateList !== nextProps.stateList) {
      if (this.props.stateList?.stateByRegion !== nextProps.stateList?.stateByRegion) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stateDetail: nextProps.stateList?.stateByRegion,
          },
        });
      }
    }

    if (this.props.zoneList !== nextProps.zoneList) {
      if (this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            zoneDetail: nextProps.zoneList?.zoneByState,
          },
        });
      }
    }

    if (this.props.districtList !== nextProps.districtList) {
      if (this.props.districtList?.districtByZone !== nextProps.districtList?.districtByZone) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            districtDetail: nextProps.districtList?.districtByZone,
          },
        });
      }
    }

    if (this.props.talukaList !== nextProps.talukaList) {
      if (this.props.talukaList?.talukaByDistrict !== nextProps.talukaList?.talukaByDistrict) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            talukaDetail: nextProps.talukaList?.talukaByDistrict,
          },
        });
      }
    }

    if (this.props.areaList !== nextProps.areaList) {
      if (this.props.areaList?.areaByTaluka !== nextProps.areaList?.areaByTaluka) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.areaByTaluka,
          },
        });
      }
    }

  }

  async componentDidMount() {
    const { getStockStakeHolderType, getRegionNew, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getRegionNew().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      getStockStakeHolderType({ userTypeIds: 3 }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  getListById = async (data) => {
    const { stockStakeHolderTypeId } = this.state;
    const {
      getAreaByStockStakeHolderTypeIdAndZoneIds,
      getAreaByStockStakeHolderTypeIdAndZoneIdsEdit,
      getZoneByRegion,
      getAboveOrderAndSelfById,
      getSalesExecutiveByAreaAndStockHolderType,
      getStateByRegion,
      getZoneByState,
      getDistrictByZone,
      getTalukaByDistrict,
      getAreaByTalukaPostBody,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      if (data.stockStakeHolderTypeList && data.stockStakeHolderTypeList.id) {
        this.setState({
          stockStakeHolderTypeId: data.stockStakeHolderTypeList.id,
        });

      }


      if (data.stockStakeHolderType && data.stockStakeHolderType.id != "") {

        showLoador({ loador: true });
        await getAboveOrderAndSelfById({
          typeId: data.stockStakeHolderType.id,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }


      if (data.reportingManagerTypeId && data.areaIds) {
        let saveData = {
          stockStakeHolderTypeId: data.reportingManagerTypeId,
          areaIds: data.areaIds,
        }
        showLoador({ loador: true });
        await getSalesExecutiveByAreaAndStockHolderType({ dataJson: saveData }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }

      if (data.zoneIds && data.stockStakeHolderType && data.stockStakeHolderType.id != "") {
        if (this.props.params.id) {
          showLoador({ loador: true });
          await getAreaByStockStakeHolderTypeIdAndZoneIdsEdit({
            saleExcId: this.props.params.id,
            StockStakeHolderTypeId: data.stockStakeHolderType.id,
            zoneIds: data.zoneIds,
          }).then(({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          });
        } else {
          showLoador({ loador: true });
          await getAreaByStockStakeHolderTypeIdAndZoneIds({
            StockStakeHolderTypeId: data.stockStakeHolderType.id,
            zoneIds: data.zoneIds,
          }).then(({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          });
        }
      }
      if (data.region && data.region.id && data.region.id != "") {
        showLoador({ loador: true });
        await getStateByRegion({ regionIds: data.region.id }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }

      if (data.stateId && data.stateId != "") {
        showLoador({ loador: true });
        await getZoneByState({ stateIds: data.stateId }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }

      if (data.zoneIds && data.zoneIds != "") {
        showLoador({ loador: true });
        await getDistrictByZone({ zoneIds: data.zoneIds }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }

      if (data.districtIds && data.districtIds != "") {
        showLoador({ loador: true });
        await getTalukaByDistrict({ districtIds: data.districtIds }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }

      if (data.talukaIds && data.talukaIds != "") {

        let talukaJson = {
          talukaIds: typeof data.talukaIds === "string"
            ? data.talukaIds.split(",")
            : data.talukaIds,
        };

        showLoador({ loador: true });
        await getAreaByTalukaPostBody({ talukaIds: talukaJson }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }

    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  callBeforeSaveMethod = async (dataToSave, editData) => {
    if (
      Object.keys(editData).length == 0 ||
      (Object.keys(editData).length != 0 &&
        editData.employeeCode != dataToSave.employeeCode)
    ) {
      const { showLoador, showNotification } = this.props;
      if (navigator.onLine) {
        showLoador({ loador: true });
        const response = await apiGet({
          url:
            ManagerJson.apiBaseURL +
            "/employee-code-exist-or-not?employeeCode=" +
            dataToSave.employeeCode,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (success) {
            if (!data.data.error) {
              showNotification({
                msg: data.data.message,
                severity: "error",
              });
            }
            return data.data.error;
          }
          return false;
        });
        return response;
      } else {
        showNotification({ msg: noInternetMsg, severity: "error" });
      }
    }
    return true;
  };


  render() {
    const { dynamicMasterData } = this.state;
    return (
      <>
        <DynamicForm
          callBeforeSave={true}
          callBeforeSaveMethod={this.callBeforeSaveMethod}
          formPath={ManagerJson.formPath}
          getListById={this.getListById}
          screenTitle={ManagerJson.screenTitle}
          fieldMeta={localStorage.getItem("showTargetTypeRadioGroup") &&
            localStorage.getItem("showTargetTypeRadioGroup") == 1 ?
            ManagerJson.fieldMetaForTargetType :
            ManagerJson.fieldMeta}
          dynamicMasterData={dynamicMasterData}
          apiBaseURL={ManagerJson.apiBaseURL}
          showSaveNextBtn={ManagerJson.showSaveNextBtn}
          isEditURL={ManagerJson.isEditURL}
          isLog={true}
          type={1}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  zoneList: state.zone,
  stockStakeHolderTypeList: state.stockStakeHolderType,
  salesExecutiveList: state.salesExecutive,
  regionList: state.region,
  stateList: state.states,
  districtList: state.district,
  talukaList: state.taluka,
  areaList: state.area,
});

const mapDispatchToProps = {
  getRegionNew,
  getZoneByRegion,
  getAreaByStockStakeHolderTypeIdAndZoneIds,
  getAreaByStockStakeHolderTypeIdAndZoneIdsEdit,
  getStockStakeHolderType,
  getAboveOrderAndSelfById,
  getSalesExecutiveByAreaAndStockHolderType,
  getStateByRegion,
  getZoneByState,
  getDistrictByZone,
  getTalukaByDistrict,
  getAreaByTalukaPostBody,
  showLoador,
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ManagerForm);