import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import { UomColumns } from "../../../tableColumns/table-columns";
import { UomJson } from "../../../DynamicFormsJson/MastersJSON/Uom";
import { getUom } from "../../../Slice/uom.slice";

class Uom extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { uomList } = this.props;
    return (
      <>
        <DynamicMainScreen
          formPath={UomJson.formPath}
          screenTitle={UomJson.screenTitle}
          fieldMeta={UomJson.fieldMeta}
          showPdfDownload={false}
          showExcelDownload={true}
          filterDataExcel={true}
          changeExcelURL={UomJson.excelUrl}
          showExtension={false}
          pdfFileName={UomJson.pdfFileName}
          excelFileName={UomJson.excelFileName}
          showAddButton={UomJson.showAddButton}
          tableColumnsToFilter={UomJson.tableColumnsToFilter}
          tableColumns={UomColumns}
          tableData={uomList?.uom}
          getTableData={this.props.getUom}
          apiBaseURL={UomJson.apiBaseURL}
          baseIdColumn={UomJson.baseIdColumn}
          isNote={true}
          isNoteValue={"Add Various Types Of UOM That Are Used In System"}
          showDeleteIcon={false}
          isLog={true}
          type={0}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  uomList: state.uom,
});
const mapDispatchToProps = {
  getUom,
};
export default connect(mapStateToProps, mapDispatchToProps)(Uom);
