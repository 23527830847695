import { Grid, Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { ButtonCompo } from "../../components/Comman/Button";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import CheckBoxSelectionTable from "../../components/Comman/RT/CheckBoxSelectionTable";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import endpoint from "../../config/endpoints";
import {
  checkedListEmptyMsg,
  noInternetMsg,
  saveFailedMsg,
  savemsg,
  saveWarningMsg,
  serverMsg,
} from "../../config/messageconstant";
import { RateConfigurationEditingForStockHolderJson } from "../../DynamicFormsJson/Transaction/stockholderProduct";
import { getAreaByTaluka } from "../../Slice/area.slice";
import { getDistrictByZone } from "../../Slice/district.slice";
import {
  getAllActiveProduct,
  setAllProductList,
} from "../../Slice/product.slice";
import { getRegionNew } from "../../Slice/region.slice";
import { getStakeHolderNameByStakeholderTypeAndArea } from "../../Slice/StackHolder.slice";
import { getStateByRegion } from "../../Slice/state.slice";

import { getStockStakeHolderType } from "../../Slice/stockStakeHolderType.slice";
import { getTalukaByDistrict } from "../../Slice/taluka.slice";
import { getZoneByState } from "../../Slice/zone.slice";
import { apiGet, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import RateConfigurationPopup from "./RateConfigurationPopup";
let productColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    align: "center",
  },
  {
    title: "Product",
    name: "name",
    positionCenter: false,
    canSearch: true,
    align: "left",
  },
  {
    title: "Code",
    name: "code",
    positionCenter: true,
    canSearch: true,
    align: "center",
  },
  {
    title: "UOM",
    name: "stakeHolderUomName",
    positionCenter: true,
    canSearch: true,
    align: "center",
  },
];
class StockHolderProductForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
      formData: {},
      dynamicMasterData: {
        regionDetail: this.props.regionList?.region,
        stateDetail: this.props.stateList?.stateByRegion,
        zoneDetail: this.props.zoneList?.zoneByState,
        districtDetail: this.props.districtList?.districtByZone,
        talukaDetail: this.props.talukaList?.talukaByDistrict,
        areaDetail: this.props.areaList?.areaByTaluka,
        stakeHolderTypeDetail:
          this.props.stakeHolderTypeList?.stockStakeHolderType,
        stakeHolderName: this.props.stakeholderList?.stackholderNameFromType,
      },
      showTableList: false,
      selection: [],
      DataToSet: [],
      openPopUp: false,
      stakeHolderId: "",
      areaIds: "",
      productStateList: [],
      isLog: true,
      moduleObjDataObj: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.regionList !== nextProps.regionList) {
      if (this.props.regionList?.region !== nextProps.regionList?.region) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            regionDetail: nextProps.regionList?.region,
          },
        });
      }
    }
    if (this.props.stateList !== nextProps.stateList) {
      if (
        this.props.stateList?.stateByRegion !==
        nextProps.stateList?.stateByRegion
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stateDetail: nextProps.stateList?.stateByRegion,
          },
        });
      }
    }
    if (this.props.zoneList !== nextProps.zoneList) {
      if (
        this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            zoneDetail: nextProps.zoneList?.zoneByState,
          },
        });
      }
    }
    if (this.props.districtList !== nextProps.districtList) {
      if (
        this.props.districtList?.districtByZone !==
        nextProps.districtList?.districtByZone
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            districtDetail: nextProps.districtList?.districtByZone,
          },
        });
      }
    }
    if (this.props.talukaList !== nextProps.talukaList) {
      if (
        this.props.talukaList?.talukaByDistrict !==
        nextProps.talukaList?.talukaByDistrict
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            talukaDetail: nextProps.talukaList?.talukaByDistrict,
          },
        });
      }
    }
    if (this.props.areaList !== nextProps.areaList) {
      if (
        this.props.areaList?.areaByTaluka !== nextProps.areaList?.areaByTaluka
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.areaByTaluka,
          },
        });
      }
    }
    if (this.props.stakeHolderTypeList !== nextProps.stakeHolderTypeList) {
      if (
        this.props.stakeHolderTypeList?.stockStakeHolderType !==
        nextProps.stakeHolderTypeList?.stockStakeHolderType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stakeHolderTypeDetail:
              nextProps.stakeHolderTypeList?.stockStakeHolderType,
          },
        });
      }
    }
    if (this.props.stakeholderList !== nextProps.stakeholderList) {
      if (
        this.props.stakeholderList?.stackholderNameFromType !==
        nextProps.stakeholderList?.stackholderNameFromType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stakeHolderName: nextProps.stakeholderList?.stackholderNameFromType,
          },
        });
      }
    }
  }
  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };
  async componentDidMount() {
    const {
      getRegionNew,
      getStockStakeHolderType,
      getAllActiveProduct,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {

      const moduleObjData = localStorage.getItem("moduleObj");
      const moduleObjDataObj = moduleObjData != null ? JSON.parse(moduleObjData) : {};

      this.setState({ moduleObjDataObj });


      showLoador({ loador: true });
      await getRegionNew().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      getAllActiveProduct().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      if (this.props.params.id) {
        showLoador({ loador: true });
        await apiGet({
          url:
            endpoint.stockHolderAndProductConfiguration +
            "/by-stock-stake-holder?stockStakeHolderId=" +
            this.props.params.id,
        }).then((response) => {
          showLoador({ loador: false });
          if (!response) {
          } else {
            this.setState({
              DataToSet: [
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Stock Holder Name",
                  value:
                    response.data.data.stockStakeHolder.ownerName !== null
                      ? response.data.data.stockStakeHolder.ownerName
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Stock Holder Type",
                  value:
                    response.data.data.stockStakeHolder.stockStakeHolderType
                      .name !== null
                      ? response.data.data.stockStakeHolder.stockStakeHolderType
                        .name
                      : "",
                },
              ],
            });
            this.handledCheckedList(
              response.data.data.stockHolderAndProductConfiguration
            );
          }
        });
      } else {
        showLoador({ loador: true });
        await getStockStakeHolderType({ userTypeIds: 2 }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  getListById = async (data) => {
    console.log(data);
    const { areaIds, stakeHolderId } = this.state;
    const {
      showNotification,
      showLoador,
      getStateByRegion,
      getZoneByState,
      getDistrictByZone,
      getTalukaByDistrict,
      getAreaByTaluka,
      getStakeHolderNameByStakeholderTypeAndArea,
      stakeHolderTypeList,
    } = this.props;
    if (navigator.onLine) {
      if (data.area) {
        this.setState({
          areaIds: data.area,
        });
      }
      if (data.region && data.region.id) {
        showLoador({ loador: true });
        await getStateByRegion({ regionIds: data.region.id }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.state) {
        showLoador({ loador: true });
        await getZoneByState({ stateIds: data.state }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.zone) {
        showLoador({ loador: true });
        await getDistrictByZone({ zoneIds: data.zone }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.district) {
        showLoador({ loador: true });
        await getTalukaByDistrict({ districtIds: data.district }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.taluka) {
        showLoador({ loador: true });
        await getAreaByTaluka({ talukaIds: data.taluka }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.stockStakeHolderType) {
        showLoador({ loador: true });
        await getStakeHolderNameByStakeholderTypeAndArea({
          type: data.stockStakeHolderType.id,
          areaIds: areaIds,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.stockStakeHolder) {
        this.setState({ stakeHolderId: data.stockStakeHolder });
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  handledCheckedList = (productData) => {
    const { productList, setAllProductList } = this.props;
    let setList = [];
    productData.map((rowObject) => {
      setList.push(rowObject.product.id);
    });
    const rowList = productList.activeAllProduct.map((rowData) => {
      const filterData = productData.filter(
        (row) => rowData.id == row.product.id
      );

      return {
        ...rowData,
        mainId: filterData.length != 0 ? filterData[0].id : 0,
        checked: filterData.length != 0 ? true : false,
      };
    });
    this.setState({
      selection: setList,
    });
    setAllProductList({ row: rowList });
  };

  getSelectedList = (e) => {
    const { productList, setAllProductList } = this.props;
    this.setState({
      selection: e,
    });
    const rowList = this.props.productList.activeAllProduct.map((rowData) => {
      const filterData = e.filter((id) => rowData.id == id);
      return { ...rowData, checked: filterData.length != 0 ? true : false };
    });
    this.props.setAllProductList({ row: rowList });
  };

  onApprove = () => {
    const { showNotification, productList } = this.props;
    const { selection } = this.state;

    // if (selection.length === 0) {
    //   showNotification({
    //     msg: checkedListEmptyMsg,
    //     severity: "error",
    //   });
    // } else {

    const rowList = productList.activeAllProduct.map((rowData) => {
      const filterData = selection.filter((id) => rowData.id == id);
      return { ...rowData, checked: filterData.length != 0 ? true : false };
    });
    console.log(selection);
    if (selection.length !== 0) {
      this.setState({ openPopUp: true });
      this.props.setAllProductList({ row: rowList });
    } else {
      this.setState({ openPopUp: false });
      this.saveRateConfigtation();
    }
    // }
  };
  handleCloses = () => {
    this.setState({ openPopUp: false });
  };
  getFilteredTableData = (tableColumns, tableData) => {
    const { searchValue } = this.state;
    const columsFil = tableColumns.filter((item) => {
      return item.canSearch;
    });
    return tableData.filter((currentRow) => {
      let isValid = false;
      columsFil.some((col) => {
        if (
          currentRow[col.name] &&
          currentRow[col.name]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
          return true;
        }
        return null;
      });
      return isValid;
    });
  };
  saveRateConfigtation = () => {
    const { stakeHolderId, isLog, moduleObjDataObj } = this.state;
    const { showNotification, params, showLoador } = this.props;
    let configurationCheckedList =
      this.props.productList.activeAllProduct.filter((row) => row.checked);

    let configurationDataToSave = configurationCheckedList.map(
      (configurationObject) => {
        console.log(configurationObject);
        let configurationData = {
          stockStakeHolder: {
            id: params.id ? params.id : stakeHolderId,
          },
          product: {
            id: configurationObject.id,
          },
          type: 0,
        };
        if (configurationObject.mainId && configurationObject.mainId != 0) {
          configurationData = {
            ...configurationData,
            id: configurationObject.mainId,
          };
        }
        return configurationData;
      }
    );
    console.log(configurationDataToSave);
    swal({
      title: "Are you sure?",
      text: saveWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (navigator.onLine) {
          let stockStakeHolderId = params.id ? params.id : stakeHolderId;
          showLoador({ loador: true });
          apiPost({
            url:
              endpoint.stockHolderAndProductConfiguration +
              "/save-list?stockStakeHolderId=" +
              stockStakeHolderId,
            postBody: configurationDataToSave,
          }).then(({ data, success }) => {
            if (success) {

              if (data.data !== null) {
                if (isLog) {

                  let updatedJsonToSend = JSON.stringify(data.data);

                  let dataToSaveForMLog = {
                    module: {
                      moduleId: moduleObjDataObj.moduleId
                    },
                    subModule: {
                      subModuleId: moduleObjDataObj.subModuleId
                    },
                    operationType: params.id ? 1 : 0,
                    type: 1,
                    operationPerformId: stockStakeHolderId,
                    updatedJson: updatedJsonToSend,
                  }

                  showLoador({ loador: true });
                  apiPost({
                    url: endpoint.mlog,
                    postBody: dataToSaveForMLog,
                  }).then(({ success }) => {
                    showLoador({ loador: false });
                    if (success) {
                    } else {
                      showNotification({
                        msg: saveFailedMsg,
                        severity: "error",
                      });
                    }
                  });
                }
              }
              this.handleCloses();
              showLoador({ loador: false });
              showNotification({
                msg: savemsg,
              });
              this.props.navigate(-1);
            } else {
              showNotification({
                msg: serverMsg,
                severity: "error",
              });
              showLoador({ loador: false });
            }
          });
        } else {
          showNotification({ msg: noInternetMsg, severity: "error" });
        }
      }
    });
  };
  render() {
    const {
      dynamicMasterData,
      selection,
      openPopUp,
      DataToSet,
      productStateList,
    } = this.state;
    const { productList } = this.props;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          {!this.props.params.id ? (
            <>
              <DynamicFormWithoutSave
                showBackToList={true}
                dynamicMasterData={dynamicMasterData}
                getListById={this.getListById}
                showTitle={RateConfigurationEditingForStockHolderJson.showTitle}
                screenTitle={
                  RateConfigurationEditingForStockHolderJson.screenTitle
                }
                fieldMeta={RateConfigurationEditingForStockHolderJson.fieldMeta}
                showSaveBtnMain={false}
                padding={true}
                paddingTop={true}
              />
              <LandingScreenHeader
                screenTitle={""}
                showSearchBox={false}
                showTable={true}
                showFilter={false}
                showPdfDownload={false}
                showExcelDownload={false}
                onSearch={this.onSearch}
              />
            </>
          ) : (
            <>
              <LandingScreenHeader
                screenTitle={"Stock Holder Rate Configuration Edit"}
                showSearchBox={false}
                showTable={true}
                showFilter={false}
                onSearch={this.onSearch}
                showBackButton={true}
                onCancel={() => this.props.navigate(-1)}
                showAddButton={false}
                showPdfDownload={false}
                showExcelDownload={false}
              />
              <br />
              <DynamicDetails DataToSet={DataToSet} screenTitle={""} />
            </>
          )}
          <br />
          <CheckBoxSelectionTable
            selection={selection}
            onSelectionChange={this.getSelectedList}
            columns={productColumns}
            rows={this.getFilteredTableData(
              productColumns,
              productList?.activeAllProduct
            )}
            isActionColActive={false}
          />
          <br />

          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="right"
          >
            <ButtonCompo
              size="medium"
              type="submit"
              variant="contained"
              name={"Proceed"}
              onClick={this.onApprove}
            />
          </Grid>
        </Paper>
        {openPopUp && (
          <>
            <RateConfigurationPopup
              handleCloses={this.handleCloses}
              open={open}
              formData={{}}
              columns={[
                {
                  title: "Sr. No.",
                  name: "index",
                  positionCenter: true,
                  align: "center",
                  showInscreen: true,
                },
                {
                  title: "Product",
                  name: "name",
                  positionCenter: false,
                  canSearch: true,
                  align: "left",
                  showInscreen: true,
                },
                {
                  title: "Code",
                  name: "code",
                  positionCenter: true,
                  canSearch: true,
                  align: "center",
                  showInscreen: true,
                },
                {
                  title: "UOM",
                  name: "stakeHolderUomName",
                  positionCenter: true,
                  canSearch: true,
                  align: "center",
                  showInscreen: true,
                },
              ]}
              onFormSave={this.saveRateConfigtation}
              rows={productList.activeAllProduct.filter((row) => row.checked)}
            />
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  regionList: state.region,
  stateList: state.states,
  zoneList: state.zone,
  districtList: state.district,
  talukaList: state.taluka,
  areaList: state.area,
  stakeHolderTypeList: state.stockStakeHolderType,
  stakeholderList: state.stockholder,
  productList: state.product,
});
const mapDispatchToProps = {
  getRegionNew,
  getStateByRegion,
  getZoneByState,
  getDistrictByZone,
  getTalukaByDistrict,
  getAreaByTaluka,
  getStockStakeHolderType,
  getStakeHolderNameByStakeholderTypeAndArea,
  getAllActiveProduct,
  setAllProductList,
  showLoador,
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(StockHolderProductForm);
