import React from "react";
import { connect } from "react-redux";
import { langugae } from "../../config/languageJSON";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import endpoint from "../../config/endpoints";
import TiroDevanagariHindi from "../../config/TiroDevanagariHindi-Regular-normal"; // Replace with the actual path to your font file
import Aakar from "../../config/Aakar-normal";
import {
  billAlreadyGeneratedMsg,
  checkedListEmptyMsg,
  deleteConfirmMsg,
  deleteFailedMsg,
  deletemsg,
  noInternetMsg,
  saveFailedMsg,
  saveWarningMsg,
  savemsg,
  serverMsg,
} from "../../config/messageconstant";
import { OrderHistoryTableJson } from "../../DynamicFormsJson/MastersJSON/orderHistoryTable";
import { getAreaByUserType } from "../../Slice/area.slice";
import { getBeatByArea, getBeatByUserType } from "../../Slice/beat.slice";
import CheckBoxSelectionTable from "../../components/Comman/RT/CheckBoxSelectionTable";
import { getActiveLanguage } from "../../Slice/multilingual.slice";
import {
  getTodaysOrderUpdated,
  getOrderHistoryUpdated,
  getOrderHistoryBySalesPersonUpdated,
} from "../../Slice/orderHistory.slice";
import {
  getOutletByBeatsAndAssignedStockHolder,
} from "../../Slice/outlet.slice";
import jsPDF from "jspdf";
import { getSalesExecutiveByBeatIds } from "../../Slice/salesExecutive.slice";
import logo from "../../Images/gokulImg.png";
import ofc from "../../Images/OFC_logo.png";

import { compose } from "@reduxjs/toolkit";
import { OrderHistoryColumns } from "../../tableColumns/table-columns";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { downloadF } from "../PDF/PDFDownload";
import dayjs from "dayjs";
import { apiDelete, apiGet, apiPost } from "../../utils/api_service";
import { Paper, Grid, Autocomplete } from "@mui/material";
import { ButtonCompo } from "../../components/Comman/Button";
import { withRouter } from "../../components/withRouter";
import moment from "moment";
import { LabelCompo } from "../../components/Comman/Label";
import { redColor, titleColor } from "../../config/ColorObj";
import AutoComplete from "../../components/Comman/AutoComplete";
import OrderBilling from "./OrderBilling";

let orderHistoryColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    showInscreen: true,
    align: "center",
  },
  {
    title: "Outlet Name",
    name: "outletName",
    positionCenter: false,
    canSearch: true,
    width: 100,
  },
  {
    title: "Beat Name",
    name: "beatName",
    positionCenter: false,

    canSearch: true,
    width: 100,
  },

  {
    title: "Order No",
    name: "orderNo",
    positionCenter: true,
    canSearch: true,
    align: "center",
  },
  {
    title: "Order Date",
    name: "orderDate",
    positionCenter: true,
    canSearch: true,
    align: "center",
  },
  {
    title: "Order By",
    name: "type",
    positionCenter: true,
    canSearch: true,
    align: "center",
  },
  {
    title: "Taxable Amount",
    name: "taxableAmt",
    positionCenter: true,
    canSearch: true,
    align: "right",
    width: 50,
  },
  {
    title: "CGST",
    name: "cgstAmt",
    positionCenter: true,
    canSearch: true,
    align: "right",
    width: 50,
  },
  {
    title: "SGST",
    name: "sgstAmt",
    positionCenter: true,
    canSearch: true,
    align: "right",
    width: 50,
  },

  {
    title: "Total Amount",
    name: "totalAmt",
    positionCenter: true,
    canSearch: true,
    align: "right",
    width: 50,
  },
  {
    title: "Action",
    name: "action",
    positionCenter: true,
    showInscreen: true,
    alignCell: "center",
    alignCenter: "left",
    minWidth: 110,
  },
];
class OrderHistoryTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldData: {},
      formData: {},
      productStockData: {},
      langNew: "",
      flag: 1,
      stockFlag: true,
      offerBillData: "",
      searchBy: "0",
      lang: "2",
      open: false,
      proceedForBillingFlag: false,
      totalQty: "",
      dynamicMasterData: {
        outletDetail: this.props.outletList.outletByUserType,
      },
      selection: [],
      fieldDataForModule: {},
      languageList: [],
      beatList: [],
    };
  }
  async componentDidMount() {
    const {
      getTodaysOrderUpdated,
      getBeatByUserType,
      getActiveLanguage,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      const showCompanyFlag = localStorage.getItem("showCompanyFlag");

      if (showCompanyFlag == 2) {
        this.setState({
          proceedForBillingFlag: true,
        });
      }

      const stockCalAtBill = localStorage.getItem("stockCalAtBill");

      this.setState({
        stockFlag: stockCalAtBill === "1" ? true : false,
      });

      showLoador({ loador: true });
      await getTodaysOrderUpdated().then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

      if (showCompanyFlag == 2) {
        showLoador({ loador: true });
        apiGet({
          url: endpoint.manageStockHeader + "/stock-by-current-date",
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (success) {
            this.setState({ productStockData: data.data });
          } else {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }

      const languageConvertedList = localStorage.getItem("activeLanguageList");
      const beatConvertedList = localStorage.getItem("activeBeatList");
      const moduleObjData = localStorage.getItem("moduleObj");


      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          beatDetail:
            beatConvertedList && beatConvertedList != null ? JSON.parse(beatConvertedList) : [],
        },
        beatList:
          beatConvertedList && beatConvertedList != null ? JSON.parse(beatConvertedList) : [],
        languageList:
          languageConvertedList != null
            ? JSON.parse(languageConvertedList)
            : [],
        fieldDataForModule:
          moduleObjData != null ? JSON.parse(moduleObjData) : {},
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      dynamicMasterData: {
        ...this.state.dynamicMasterData,
        beatDetail: this.state.beatList,
      },
    });

    if (this.props.salesExecutive !== nextProps.salesExecutive) {
      if (
        this.props.salesExecutive?.salesExecutive !==
        nextProps.salesExecutive?.salesExecutive
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            salesPersonDetail: nextProps.salesExecutive?.salesExecutive,
          },
        });
      }
    }

    if (this.props.outletList !== nextProps.outletList) {
      if (this.props.outletList?.outlets !== nextProps.outletList?.outlets) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            outletDetail: nextProps.outletList?.outlets,
          },
        });
      }
    }
  }
  getListById = async (data) => {
    const {
      getOutletByBeatsAndAssignedStockHolder,
      getSalesExecutiveByBeatIds,
      showLoador,
      showNotification,
    } = this.props;
    if (data.flag) {
      this.setState({
        searchBy: data.flag,
        formData: {
          flag: data.flag,
          fromdate: dayjs(),
          todate: dayjs(),
          beat: [],
          salesPersonIds: [],
          outletIds: [],
        },
      });
    }

    if (navigator.onLine) {
      if (data.beat && data.beat != "") {
        if (this.state.searchBy == 0) {
          let beatIdsJson = {
            beatIds: data.beat,
          };
          showLoador({ loador: true });
          await getOutletByBeatsAndAssignedStockHolder({ beatIds: beatIdsJson }).then(
            ({ success }) => {
              showLoador({ loador: false });
              if (!success) {
                showNotification({ msg: serverMsg, severity: "error" });
              }
            }
          );
        } else {
          showLoador({ loador: true });
          await getSalesExecutiveByBeatIds({ beatIds: data.beat.join() }).then(
            ({ success }) => {
              showLoador({ loador: false });
              if (!success) {
                showNotification({ msg: serverMsg, severity: "error" });
              }
            }
          );
        }
      }
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  };

  onAutocompleteChange = (name, newValue) => {
    if (newValue != null) {
      this.setState({
        lang: newValue,
        langNew: newValue,
      });
    } else {
      this.setState({
        lang: "",
        langNew: "",
      });
    }
  };

  onSearchList = async (data) => {
    this.setState({
      fieldData: data,
    });
    const {
      getOrderHistoryUpdated,
      getOrderHistoryBySalesPersonUpdated,
      showLoador,
      showNotification,
    } = this.props;

    const { stockFlag } = this.state;

    if (navigator.onLine) {
      if (data.flag == 0) {
        let orderHistoryData = {
          outletIds: data.outletIds,
          fromDate: data.fromdate,
          toDate: data.todate,
        };
        showLoador({ loador: true });
        await getOrderHistoryUpdated({
          orderHistoryListData: orderHistoryData,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      } else {
        showLoador({ loador: true });
        await getOrderHistoryBySalesPersonUpdated({
          salesTeamIds: data.salesPersonIds,
          fromdate: data.fromdate,
          todate: data.todate,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  rowPDF = (rowData) => {
    const { lang, fieldData } = this.state;
    let date = {
      fromdate: fieldData.fromdate
        ? fieldData.fromdate
        : moment(new Date()).format("DD-MM-YYYY"),
      todate: fieldData.todate
        ? fieldData.todate
        : moment(new Date()).format("DD-MM-YYYY"),
    };
    localStorage.setItem("dateForPDF", JSON.stringify(date));
    localStorage.setItem("lang", lang);
    localStorage.setItem("loadSheetOrderID", rowData.id);
    window.open("./loadsheet-order-pdf", "_blank");
  };
  generatePDF1 = (response, rowData) => {
    var doc = new jsPDF();
    const { lang } = this.state;
    if (lang != 3) {
      doc.addFileToVFS(
        "TiroDevanagariHindi-Regular-normal.ttf",
        TiroDevanagariHindi
      );
      doc.addFont(
        "TiroDevanagariHindi-Regular-normal.ttf",
        "TiroDevanagariHindi-Regular",
        "normal"
      );
      doc.setFont("TiroDevanagariHindi-Regular");
    } else {
      doc.addFileToVFS("Aakar-normal.ttf", Aakar);
      doc.addFont("Aakar-normal.ttf", "Aakar", "normal");
      doc.setFont("Aakar");
    }
    var line = 10; // Line height to start text at
    var lineHeight = 5;
    var leftMargin = 5;
    var wrapWidth = 120;
    let dataToSet = response.dtoLoadSheet;

    let pdfTitle = langugae[lang].orderDispatch
      ? langugae[lang].orderDispatch
      : "";

    var textWidth =
      (doc.getStringUnitWidth(pdfTitle) * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;

    var pageWidth = doc.internal.pageSize.getWidth();
    var xPos = (pageWidth - textWidth) / 2;
    doc.text(pdfTitle, 50, 10, { maxWidth: 540 });
    line = line + 7;
    doc.setFontSize(10);
    let fromBillContactPersonNo = localStorage.getItem("distributorName");
    let distName = langugae[lang].distName
      ? langugae[lang].distName + " : "
      : "";
    doc.text(distName + fromBillContactPersonNo, leftMargin, line, {
      maxWidth: wrapWidth,
    });
    line = line + 7;

    let yAxis = line;
    doc.text(
      langugae[lang].beatName ? langugae[lang].beatName + " : " : "",
      leftMargin,
      line
    );
    let fontName = lang != 3 ? "TiroDevanagariHindi-Regular" : "Aakar";

    doc.setFont(fontName, "normal");
    // doc.text("Beat Name : ", leftMargin, line);
    var addressText = response.beatName ? response.beatName : "-";
    var splitTextAddr = doc.splitTextToSize(addressText, wrapWidth);
    for (var i = 0, length = splitTextAddr.length; i < length; i++) {
      // loop thru each line and increase
      if (i == 0) {
        doc.text(splitTextAddr[i], leftMargin + 22, line);
      } else {
        doc.text(splitTextAddr[i], leftMargin, line);
      }

      line = lineHeight + line;
    }

    var pageWidth = doc.internal.pageSize.getWidth();
    var xPos = pageWidth - 51;
    var xPos1 = pageWidth - 55;

    yAxis = yAxis + 7;
    let amt = response.amt ? response.amt : "-";
    let date;
    const { fieldData } = this.state;
    let fromDate =
      fieldData.fromdate || moment(new Date()).format("DD-MM-YYYY");
    let toDate = fieldData.todate || moment(new Date()).format("DD-MM-YYYY");

    date = fromDate + " To " + toDate;

    let amount = langugae[lang].amount ? langugae[lang].amount + " : " : "";
    doc.text(amount + amt.toFixed(2), leftMargin, yAxis, {
      maxWidth: wrapWidth,
    });
    yAxis = yAxis + 7;
    let dateData = langugae[lang].date ? langugae[lang].date + " : " : "";
    doc.text(dateData + date, leftMargin, yAxis, { maxWidth: wrapWidth });

    yAxis = yAxis + 5;

    doc.line(150, 3, 150, yAxis);
    let pdfLogo =
      localStorage.getItem("compId") == "5c140f80-3929-46dc-83b1-592b7bc30d1f"
        ? logo
        : ofc;
    doc.addImage(pdfLogo, "JPEG", xPos1, 5, 45, 35);

    doc.line(3, yAxis, pageWidth - 3, yAxis);

    let headData = [
      langugae[lang].productName ? langugae[lang].productName + " : " : "",
    ];
    dataToSet.packagingType
      ? dataToSet.packagingType.map((row) => {
        headData.push({ content: row.name, colSpan: 3 });
      })
      : [];

    var align = {
      1: { halign: "right" },
      4: { halign: "right" },
      5: { halign: "right" },
    };
    let headData2 = [""];
    let index = 0;
    dataToSet.packagingType
      ? dataToSet.packagingType.map((row) => {
        index = index + 1;
        let first = index;
        index = index + 1;
        let second = index;
        index = index + 1;
        let third = index;
        align = {
          ...align,
          [first]: { halign: "right" },
          [second]: { halign: "right" },
          [third]: { halign: "right" },
        };
        headData2.push("All");

        headData2.push("Strip");
        headData2.push("Cartoon");
      })
      : [];
    let head = [headData, headData2];
    let categoryWithGroups =
      dataToSet.categoryWithGroups != null ? dataToSet.categoryWithGroups : [];
    let packagingTypeList =
      dataToSet.packagingType != null ? dataToSet.packagingType : [];
    let orderSummaries =
      dataToSet.orderSummaries != null ? dataToSet.orderSummaries : [];
    let productList = [];
    let finalTotal = [
      {
        content: [langugae[lang].finalTotal ? langugae[lang].finalTotal : ""],
        styles: { fontWeight: 900, fontSize: 12, fontColor: "#000" },
      },
    ];
    let finalTotalObj = {};
    categoryWithGroups.map((categoryData) => {
      let catList = [
        {
          content:
            categoryData.category.name +
            " ( " +
            categoryData.category.shortName +
            " ) ",

          styles: { fontWeight: 900, fontSize: 12, fontColor: "#000" },
        },
      ];
      packagingTypeList.map((packType) => {
        catList = [
          ...catList,
          {
            content: "",
            colSpan: 3,
            styles: { fontWeight: 900, fontSize: 12, fontColor: "#000" },
          },
        ];
      });
      productList.push(catList);
      let totalList = [
        {
          content: langugae[lang].total ? langugae[lang].total : "",
          styles: { fontWeight: 900, fontSize: 12, fontColor: "#000" },
        },
      ];
      let totalObj = {};
      categoryData.groups.map((groupData) => {
        let GruopInfo = [groupData.name];
        packagingTypeList.map((packagingTypeData) => {
          let flag = 0;
          orderSummaries.map((rowData) => {
            if (
              groupData.id == rowData.groupId &&
              rowData.packagingTypeId == packagingTypeData.id
            ) {
              flag = 1;
              GruopInfo.push(rowData.totalQty);
              GruopInfo.push(rowData.secondaryUomValue);
              GruopInfo.push(rowData.primaryUomValue);
              totalObj = {
                ...totalObj,
                [packagingTypeData.id + "-totalQty"]: totalObj[
                  packagingTypeData.id + "-totalQty"
                ]
                  ? totalObj[packagingTypeData.id + "-totalQty"] +
                  rowData.totalQty
                  : rowData.totalQty,
                [packagingTypeData.id + "-secondaryUomValue"]: totalObj[
                  packagingTypeData.id + "-secondaryUomValue"
                ]
                  ? totalObj[packagingTypeData.id + "-secondaryUomValue"] +
                  rowData.secondaryUomValue
                  : rowData.secondaryUomValue,
                [packagingTypeData.id + "-primaryUomValue"]: totalObj[
                  packagingTypeData.id + "-primaryUomValue"
                ]
                  ? totalObj[packagingTypeData.id + "-primaryUomValue"] +
                  rowData.primaryUomValue
                  : rowData.primaryUomValue,
              };
            }
          });
          if (flag == 0) {
            GruopInfo.push("");
            GruopInfo.push("");
            GruopInfo.push("");
          }
        });
        productList.push(GruopInfo);
      });
      packagingTypeList.map((row) => {
        let totalQty = totalObj[row.id + "-totalQty"]
          ? totalObj[row.id + "-totalQty"]
          : 0;
        let secondaryUomValue = totalObj[row.id + "-secondaryUomValue"]
          ? totalObj[row.id + "-secondaryUomValue"]
          : 0;
        let primaryUomValue = totalObj[row.id + "-primaryUomValue"]
          ? totalObj[row.id + "-primaryUomValue"]
          : 0;
        finalTotalObj = {
          ...finalTotalObj,
          [row.id + "-totalQty"]: finalTotalObj[row.id + "-totalQty"]
            ? finalTotalObj[row.id + "-totalQty"] + totalQty
            : totalQty,
          [row.id + "-secondaryUomValue"]: finalTotalObj[
            row.id + "-secondaryUomValue"
          ]
            ? finalTotalObj[row.id + "-secondaryUomValue"] + secondaryUomValue
            : secondaryUomValue,
          [row.id + "-primaryUomValue"]: finalTotalObj[
            row.id + "-primaryUomValue"
          ]
            ? finalTotalObj[row.id + "-primaryUomValue"] + primaryUomValue
            : primaryUomValue,
        };
        totalList.push({
          content: totalObj[row.id + "-totalQty"]
            ? totalObj[row.id + "-totalQty"]
            : 0,
          styles: { fontWeight: 900, fontSize: 12, fontColor: "#000" },
        });
        totalList.push({
          content: totalObj[row.id + "-secondaryUomValue"]
            ? totalObj[row.id + "-secondaryUomValue"]
            : 0,

          styles: { fontWeight: 900, fontSize: 12, fontColor: "#000" },
        });
        totalList.push({
          content: totalObj[row.id + "-primaryUomValue"]
            ? totalObj[row.id + "-primaryUomValue"]
            : 0,

          styles: { fontWeight: 900, fontSize: 12, fontColor: "#000" },
        });
      });
      productList.push(totalList);
    });
    packagingTypeList.map((row) => {
      finalTotal.push({
        content: finalTotalObj[row.id + "-totalQty"]
          ? finalTotalObj[row.id + "-totalQty"]
          : 0,
        styles: { fontWeight: 900, fontSize: 12, fontColor: "#000" },
      });
      finalTotal.push({
        content: finalTotalObj[row.id + "-secondaryUomValue"]
          ? finalTotalObj[row.id + "-secondaryUomValue"]
          : 0,
        styles: { fontWeight: 900, fontSize: 12, fontColor: "#000" },
      });
      finalTotal.push({
        content: finalTotalObj[row.id + "-primaryUomValue"]
          ? finalTotalObj[row.id + "-primaryUomValue"]
          : 0,
        styles: { fontWeight: 900, fontSize: 12, fontColor: "#000" },
      });
    });
    productList.push(finalTotal);
    var fontSize = 8; // Set your desired font size
    var styles = {
      fontSize: fontSize,
      fontColor: "#000",
      font: lang != 3 ? "TiroDevanagariHindi-Regular" : "Aakar",
    };
    doc.autoTable({
      head: head,
      body: productList,
      styles: styles,
      startY: yAxis + 5,
      columnStyles: align,
      margin: { right: 5, left: 5, top: 20 },
      headStyles: {
        halign: "center",
        lineWidth: 0.2,
        lineColor: "#fff",
        fillColor: [3, 169, 244],
        textColor: [255, 255, 255],
      },
      theme: "grid",

      showHead: "everyPage",

      didDrawPage: function (data) {
        let lineN = 10;
        doc.setTextColor(40);
        if (data.pageNumber > 1) {
          let fromBillContactPersonNo = localStorage.getItem("distributorName");
          let distName = langugae[lang].distName
            ? langugae[lang].distName + " : "
            : "";
          doc.text(distName + fromBillContactPersonNo, leftMargin, lineN, {
            maxWidth: wrapWidth,
          });

          let date = langugae[lang].date ? langugae[lang].date + " : " : "";

          doc.text(date + date, 150, lineN, {
            maxWidth: wrapWidth,
          });
          lineN = lineN + 7;
          let beatName = langugae[lang].beatName
            ? langugae[lang].beatName + " : "
            : "";

          doc.text(beatName, leftMargin, lineN, {
            maxWidth: wrapWidth,
          });

          var addressText = response.beatName ? response.beatName : "-";
          var splitTextAddr = doc.splitTextToSize(addressText, wrapWidth);
          for (var i = 0, length = splitTextAddr.length; i < length; i++) {
            // loop thru each line and increase
            if (i == 0) {
              doc.text(splitTextAddr[i], leftMargin + 22, lineN);
            } else {
              doc.text(splitTextAddr[i], leftMargin, lineN);
            }

            lineN = lineHeight + lineN;
          }
        }
        var pageWidth = doc.internal.pageSize.getWidth();

        var str = "Page " + doc.internal.getNumberOfPages();

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        let pb = "Powered By Bizintel DMS";
        const compName = localStorage.getItem("compName");
        var textWidthCN =
          (doc.getStringUnitWidth(compName) * doc.internal.getFontSize()) /
          doc.internal.scaleFactor;
        var textWidth =
          (doc.getStringUnitWidth(pb) * doc.internal.getFontSize()) /
          doc.internal.scaleFactor;

        // Calculate the X position to align to the right of the page

        doc.setLineWidth(0.2);
        if (data.pageNumber > 1) {
          doc.rect(3, 3, pageWidth - 6, pageHeight - 8);
        } else {
          doc.rect(3, 3, pageWidth - 6, pageHeight - 8);
        }
        // Adjust for a margin if needed
        doc.setFontSize(10);
        var xPosCN = (pageWidth - textWidthCN) / 2;
        var xPos = pageWidth - textWidth - 10;
        doc.text(compName, 10, pageHeight - 10);

        doc.setFontSize(10);
        doc.text(pb, xPos, pageHeight - 10);
        doc.text(str, xPosCN, pageHeight - 10);
      },
    });
    doc.save(pdfTitle + " " + moment().format("DD-MM-YYYY") + ".pdf");
  };

  getSelectedList = (e) => {
    const { orderHistoryList } = this.props;
    this.setState({
      selection: e,
    });
    const rowList = this.props.orderHistoryList.orderHistory.map((rowData) => {
      const filterData = e.filter((id) => rowData.id == id);
      return { ...rowData, checked: filterData.length != 0 ? true : false };
    });
  };
  onLoadSheet = () => {
    const { showNotification, orderHistoryList } = this.props;
    const { selection, fieldData } = this.state;

    if (fieldData.fromdate && fieldData.todate) {


      const timeDiff = Math.abs(dayjs(fieldData.todate, "DD-MM-YYYY") - dayjs(fieldData.fromdate, "DD-MM-YYYY"));

      const diffDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

      if (diffDays > 31) {
        showNotification({
          msg: "Select date between one month",
          severity: "error",
        });
        return;
      }
    }



    if (selection.length === 0) {
      showNotification({
        msg: checkedListEmptyMsg,
        severity: "error",
      });
    } else {
      const rowList = orderHistoryList.orderHistory.map((rowData) => {
        const filterData = selection.filter((id) => rowData.id == id);
        return { ...rowData, checked: filterData.length != 0 ? true : false };
      });
      this.rowPDF({
        id: selection.join(),
      });
    }
  };

  onProceedForBilling = () => {
    const { selection, productStockData } = this.state;
    const { showNotification, showLoador, orderHistoryList } = this.props;

    if (selection.length === 0) {
      showNotification({
        msg: checkedListEmptyMsg,
        severity: "error",
      });
    } else {
      let stockList = [];
      let dataToSaveForGenerateBill = {};
      const rowList = orderHistoryList.orderHistory.map((rowData) => {
        const filterData = selection.filter((id) => rowData.id == id);

        return { ...rowData, checked: filterData.length != 0 ? true : false };
      });

      const saveJsonList = rowList
        .filter((rowDataObj) => rowDataObj.checked)
        .filter((rowDataObj) => rowDataObj.billCount === 0)
        .map((rowDataObj) => ({
          id: rowDataObj.id,
          outLet: {
            id: rowDataObj.outletId,
            firmName: rowDataObj.outletName,
            ownerName: rowDataObj.ownerName,
            state: { id: rowDataObj.stateId, name: rowDataObj.stateName },
          },
          orderNo: rowDataObj.orderNo,
          orderDate: rowDataObj.orderDate,
          distcountAmt: rowDataObj.distcountAmt,
          taxableAmt: rowDataObj.taxableAmt,
          cgstAmt: rowDataObj.cgstAmt,
          sgstAmt: rowDataObj.sgstAmt,
          igstAmt: rowDataObj.igstAmt,
          totalAmt: rowDataObj.totalAmt,
          stockStakeHolder: { id: rowDataObj.stockStakeHolderId },
          type: rowDataObj.typeForJson,
          remark: rowDataObj.remark,
          saleManagerId: rowDataObj.saleManagerId
            ? rowDataObj.saleManagerId
            : null,
          insertedUserId: rowDataObj.insertedUserId,
          insertDateTime: rowDataObj.insertDateTime,
        }));

      stockList = productStockData.productStocks.map((stockObject) => {
        let dataToReturn = {
          productId: stockObject.product.id,
          stock: stockObject.secondaryClosingQty,
        };
        return dataToReturn;
      });

      localStorage.setItem("stockList", JSON.stringify(stockList));

      dataToSaveForGenerateBill = {
        outletOrderHeraders: saveJsonList,
        productAndStocks: stockList,
      };

      console.log(dataToSaveForGenerateBill);

      swal({
        title: "Are you sure?",
        text: saveWarningMsg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.onSave(dataToSaveForGenerateBill);
        }
      });
    }
  };

  onSave = (dataToSaveForGenerateBill) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      if (dataToSaveForGenerateBill.outletOrderHeraders.length !== 0) {
        showLoador({ loador: true });
        apiPost({
          url: endpoint.outletBillHeader + "/generate-with-applied-offer-order",
          postBody: dataToSaveForGenerateBill,
        }).then(({ data, success }) => {
          if (success) {
            this.setState({ open: true, offerBillData: data.data });

            showNotification({ msg: savemsg });
          } else {
            showNotification({ msg: saveFailedMsg, severity: "error" });
          }
          showLoador({ loador: false });
        });
      } else {
        showNotification({ msg: billAlreadyGeneratedMsg, severity: "error" });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  rowView = (rowData) => {
    this.props.navigate(OrderHistoryTableJson.formPathView + "/" + rowData.id);
  };

  rowAppliedList = (rowData) => {
    this.props.navigate(OrderHistoryTableJson.formPath + "/" + rowData.id);
  };

  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: deleteConfirmMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onDelete(rowData);
      }
    });
  };
  onDelete = (row) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiDelete({
        url: endpoint.adminDashboard + "/by-is-bill-generate/" + row.id,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          if (data !== null && data.data.error) {
            showNotification({ msg: data.data.message, severity: "error" });
          } else {
            showNotification({ msg: deletemsg });
          }
        } else {
          showNotification({ msg: deleteFailedMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  toggle = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const { orderHistoryList, getTodaysOrderUpdated, multilingualList } =
      this.props;
    const {
      dynamicMasterData,
      searchBy,
      formData,
      selection,
      lang,
      fieldDataForModule,
      open,
      offerBillData,
      proceedForBillingFlag,
      languageList,
      beatList,
      stockFlag,
    } = this.state;
    // console.log(beatList);
    return (
      <>
        {!open && (
          <>
            <Paper
              sx={{
                p: 2,
                borderRadius: 0,
              }}
              elevation={0}
            >
              {" "}
              <DynamicMainScreen
                deleteMsgAuto={true}
                maxDateValid={true}
                showViewButtonInTable={true}
                showDeleteIcon={true}
                dynamicMasterData={dynamicMasterData}
                searchList={
                  searchBy == 0
                    ? OrderHistoryTableJson.searchList
                    : OrderHistoryTableJson.searchListBySalsPerson
                }
                onSearchData={this.onSearchList}
                formPathView={OrderHistoryTableJson.formPathView}
                formPath={OrderHistoryTableJson.formPath}
                screenTitle={OrderHistoryTableJson.screenTitle}
                fieldMeta={OrderHistoryTableJson.fieldMeta}
                getListById={this.getListById}
                showPdfDownload={false}
                showExcelDownload={false}
                formData={formData}
                isActiveURL={OrderHistoryTableJson.isActiveURL}
                showAddButton={OrderHistoryTableJson.showAddButton}
                tableColumnsToFilter={
                  OrderHistoryTableJson.tableColumnsToFilter
                }
                tableColumns={[]}
                tableData={orderHistoryList?.orderHistory}
                apiBaseURL={OrderHistoryTableJson.apiBaseURL}
                callApi={false}
                getTableDataById={true}
                showSearchBox={false}
                showPdf={true}
                deleteURL={OrderHistoryTableJson.deleteURL}
                showPegination={false}
                showEditIcon={false}
                getTableData={getTodaysOrderUpdated}
              />
              <CheckBoxSelectionTable
                selection={selection}
                onSelectionChange={this.getSelectedList}
                columns={orderHistoryColumns}
                rows={(orderHistoryColumns, orderHistoryList?.orderHistory)}
                isActionColActive={true}
                rowPrint={this.rowPDF}
                SummarizeRoundedShow={true}
                showViewButtonInTable={true}
                rowView={this.rowView}
                showDelete={false}
                rowDelete={this.rowDelete}
                showSearchBox={true}
                showDetailsIcon={true}
                rowAppliedList={this.rowAppliedList}
                detailConditionName={"isGenerated"}
                detailConditionValue={"0"}
              />
              <br />
              {fieldDataForModule.view == 1 ? (
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="right"
                >
                  <Grid container xs={12} sm={3} md={3} lg={3}>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16, color: redColor }}
                        label={"*"}
                      />
                      <LabelCompo
                        className="text-black"
                        style={{
                          fontSize: 16,
                          color: titleColor,
                        }}
                        label={"Language" + " :"}
                      />
                    </Grid>
                    <Grid item xs={12} sm={8} md={8} lg={8}>
                      <AutoComplete
                        name={"Language"}
                        label={"Language"}
                        placeHolder={"Select Language"}
                        keyColName={"id"}
                        value={lang}
                        options={languageList}
                        onChange={this.onAutocompleteChange}
                        errorText={""}
                      />
                    </Grid>
                  </Grid>
                  &nbsp;&nbsp;
                  <ButtonCompo
                    size="medium"
                    type="submit"
                    variant="contained"
                    name={"Order Dispatch Summary"}
                    onClick={this.onLoadSheet}
                  />
                  &nbsp;&nbsp;
                  {proceedForBillingFlag && (
                    <ButtonCompo
                      size="medium"
                      type="submit"
                      variant="contained"
                      name={"Proceed For Billing"}
                      onClick={this.onProceedForBilling}
                    />
                  )}
                </Grid>
              ) : (
                ""
              )}
            </Paper>
          </>
        )}

        {open && (
          <>
            <OrderBilling
              offerBillOutletData={offerBillData}
              onCancel={this.toggle}
              stockFlag={stockFlag}
            />
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  areaList: state.area,
  outletList: state.outlet,
  beatList: state.beat,
  orderHistoryList: state.orderHistory,
  salesExecutive: state.salesExecutive,
  multilingualList: state.multilingual,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getAreaByUserType,
  getBeatByArea,
  getBeatByUserType,
  getOutletByBeatsAndAssignedStockHolder,
  getOrderHistoryUpdated,
  getOrderHistoryBySalesPersonUpdated,
  getSalesExecutiveByBeatIds,
  getTodaysOrderUpdated,
  getActiveLanguage,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(OrderHistoryTable);
