import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { CentralizedRateStructureJson } from "../../DynamicFormsJson/MastersJSON/centralizedRateStructure";
import {
    getAllActiveProductSortByBrand,
} from "../../Slice/product.slice";
import { withRouter } from "../../components/withRouter";
import {
    checkedListForProductMsg,
    noInternetMsg,
    saveFailedMsg,
    saveWarningMsg,
    savemsg,
    serverMsg,
    updateData,
    updateMsg,
} from "../../config/messageconstant";

import dayjs from "dayjs";
import swal from "sweetalert";
import { ButtonCompo } from "../../components/Comman/Button";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import DynamicFormWithoutValidation from "../../components/dynamicscreens/DynamicFormWithoutValidation";
import { mandatoryCheck } from "../../config/ValidationCheck";
import endpoint from "../../config/endpoints";
import { apiGet, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { redColor } from "../../config/ColorObj";
import { getCentralizedRateStructureById } from "../../Slice/centralizedRateStructure.slice";
class CentralizedRateStructureForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fieldData: {
                uomType: "0",
                entityType: "0",
            },
            rowData: {},
            formErrors: {},
            isLog: true,
            searchValueForProductRateStructure: "",
            setProductList: [],
        };
    }

    async componentDidMount() {
        const {
            getAllActiveProductSortByBrand,
            stakeHolderTypeList,
            showLoador,
            showNotification,
            productList,
        } = this.props;
        if (navigator.onLine) {
            if (this.props.params.id) {
                this.getById(this.props.params.id);
            } else {
                showLoador({ loador: true });
                await getAllActiveProductSortByBrand().then(({ response, success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    } else {
                        if (response.length !== 0) {
                            this.setState({ setProductList: response })
                        }
                    }
                });
            }
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }

    getById = async (id) => {
        const {
            showLoador,
            showNotification,
            getCentralizedRateStructureById,
            getAllActiveProductSortByBrand,
        } = this.props;
        showLoador({ loador: true });
        await getAllActiveProductSortByBrand().then(({ success }) => {
            showLoador({ loador: false });
            if (!success) {
                showNotification({ msg: serverMsg, severity: "error" });
            } else {
                getCentralizedRateStructureById({ id: id }).then(
                    ({ response, success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        } else {
                            let rowData = response;

                            if (rowData && Object.keys(rowData).length !== 0) {
                                this.editData(rowData);
                            }
                        }
                    }
                );
            }
        });
    };

    editData = (data) => {
        const { productList } = this.props;
        const { fieldData } = this.state;

        let dataObject = {};

        let fieldDataName = {
            ...fieldData,
            structureName: data.name !== null ? data.name : "",
        };

        let newDataToSet = productList?.activeAllProductSortByBrand.map(
            (dataObj) => {
                let filterData =
                    data.centralRateStructureDetail != 0 &&
                    data.centralRateStructureDetail.filter(
                        (row) => row.productId == dataObj.id
                    );

                if (filterData.length !== 0) {
                    filterData.map((filterObj) => {
                        dataObject = {
                            ...filterObj,
                            productId: dataObj.id,
                            name: dataObj.name,
                            rateForCentralizedStructure:
                                filterObj.rateForCentralizedStructure,
                        };
                    });
                } else {
                    dataObject = {
                        ...dataObj,
                    };
                }
                return dataObject;
            }
        );

        console.log(newDataToSet);

        this.setState({
            fieldData: fieldDataName,
            rowData: data,
            setProductList: newDataToSet,
        });
    };

    checkValidationOnSubmit = () => {
        const { fieldData, flag } = this.state;
        const mandatoryCheckErrors = mandatoryCheck({
            fieldData,
            fieldMeta: CentralizedRateStructureJson.fieldMeta,
        });
        this.setState({
            formErrors: mandatoryCheckErrors.formErrors,
        });

        return mandatoryCheckErrors.formValid;
    };

    onDataChange = (fieldName, newValue) => {
        const { fieldData } = this.state;
        const dataToSearch = {};
        const dataToReset = { ...this.state.fieldData };
        CentralizedRateStructureJson.fieldMeta.map((currentField) => {
            if (currentField.dataKey === fieldName && currentField.getListId) {
                const masterKeyList = currentField.getListId.split(",");

                masterKeyList.map((key) => {
                    if (fieldName === key) {
                        dataToSearch[key] = newValue;
                    } else if (fieldData[key]) {
                        dataToSearch[key] = fieldData[key];
                    }
                    return null;
                });

                this.getListById(dataToSearch);
            }
            if (currentField.getListFrom) {
                const masterKeyList = currentField.getListFrom.split(",");
                masterKeyList.map((key) => {
                    if (key == fieldName && dataToReset[key]) {
                        delete dataToReset[currentField.dataKey];
                    }
                });
            }
            return null;
        });
        dataToReset[fieldName] = newValue;
        this.setState({
            fieldData: dataToReset,
        });
    };

    onSearchProductRateStructure = (searchValueForProductRateStructure) => {
        this.setState({
            searchValueForProductRateStructure: searchValueForProductRateStructure,
        });
    };

    getFilteredTableData = (searchData) => {
        const { searchValueForProductRateStructure } = this.state;
        return searchData.filter((currentRow) => {
            let isValid = false;
            if (
                currentRow["name"] &&
                currentRow["name"]
                    .toString()
                    .toLowerCase()
                    .includes(searchValueForProductRateStructure.trim().toLowerCase())
            ) {
                isValid = true;
                return true;
            }
        });
    };

    onChange = (e, productObject) => {
        const { name, value } = e.target;
        const regex = /^\d*\.?\d{0,2}$/;

        if (regex.test(value)) {
            this.setState((prevState) => ({
                setProductList: prevState.setProductList.map((produObj) =>
                    produObj.id === productObject.id
                        ? { ...produObj, [name]: value }
                        : produObj
                ),
            }));
        }
    };


    onSubmitHandler = () => {
        const { fieldData, rowData, setProductList } = this.state;
        const { productList, showNotification, showLoador } = this.props;

        if (this.checkValidationOnSubmit()) {
            let saveList = [];
            let dataToSave = {};

            saveList = setProductList
                .filter((row) => row.rateForCentralizedStructure > 0)
                .map((dataObj) => {
                    let jsonData = {
                        product: { id: dataObj.productId },
                        rate: dataObj.rateForCentralizedStructure,
                        uomType: 0,
                        entityType: 0,
                    };
                    return jsonData;
                });

            if (saveList.length !== 0) {
                if (this.props.params.id) {
                    dataToSave = {
                        id: this.props.params.id,
                        name: fieldData.structureName,
                        structureDate: rowData.structureDate,
                        centralRateStructureDetail: saveList,
                    };
                } else {
                    dataToSave = {
                        name: fieldData.structureName,
                        structureDate: dayjs().format("DD-MM-YYYY"),
                        centralRateStructureDetail: saveList,
                    };
                }

                console.log(dataToSave);
                swal({
                    title: "Are you sure?",
                    text: saveWarningMsg,
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                }).then((willDelete) => {
                    if (willDelete) {
                        this.onSave(dataToSave);
                    }
                });
            } else {
                showNotification({ msg: checkedListForProductMsg, severity: "error" });
            }
        }
    };

    onSave = (dataToSave) => {
        const { showNotification, showLoador } = this.props;

        showLoador({ loador: true });
        apiPost({
            url: endpoint.centralRateStructureHeader + "/save",
            postBody: dataToSave,
        }).then(({ success }) => {
            showLoador({ loador: false });
            if (success) {
                showNotification({
                    msg: savemsg,
                });
                this.props.navigate(-1);
            } else {
                showNotification({
                    msg: saveFailedMsg,
                    severity: "error",
                });
            }
        });
    };

    render() {
        const { formErrors, fieldData, setProductList } = this.state;
        const { productList } = this.props;
        return (
            <>
                <Paper
                    sx={{
                        p: 2,
                        borderRadius: 2,
                    }}
                    elevation={0}
                >
                    <DynamicFormWithoutValidation
                        showBackToList={true}
                        showTitle={CentralizedRateStructureJson.showTitle}
                        screenTitle={CentralizedRateStructureJson.screenTitle}
                        apiBaseURL={CentralizedRateStructureJson.apiBaseURL}
                        fieldMeta={
                            localStorage.getItem("showRadioGroupForCentralizedStructureForm") &&
                                localStorage.getItem("showRadioGroupForCentralizedStructureForm") == 1 ?
                                CentralizedRateStructureJson.fieldMetaWithRadioGroup :
                                CentralizedRateStructureJson.fieldMeta
                        }
                        showCancel={CentralizedRateStructureJson.showCancel}
                        showSaveNextBtn={CentralizedRateStructureJson.showSaveNextBtn}
                        saveBtnText={"Search"}
                        showSaveBtnMain={false}
                        showSaveBtn={false}
                        fieldData={fieldData}
                        formErrors={formErrors}
                        onDataChange={this.onDataChange}
                    />

                    <br />
                    <LandingScreenHeader
                        screenTitle={""}
                        showSearchBox={true}
                        showTable={true}
                        showFilter={false}
                        showPdfDownload={false}
                        showExcelDownload={false}
                        onSearch={this.onSearchProductRateStructure}
                    />
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        align="center"
                                        style={{
                                            minWidth: "70px",
                                            position: "sticky",
                                            left: 0,
                                            background: "#fff",
                                            zIndex: 11,
                                        }}
                                    >
                                        Sr. No.
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        style={{
                                            minWidth: "150px",
                                            position: "sticky",
                                            left: 70,
                                            background: "#fff",
                                            zIndex: 11,
                                        }}
                                    >
                                        Product Name
                                    </TableCell>
                                    <TableCell align="center" style={{ minWidth: "150px" }}>
                                        Rate
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.getFilteredTableData(setProductList).map((productObject, indexRow) => (
                                    <TableRow key={productObject.id}>
                                        <TableCell
                                            align="center"
                                            component="td"
                                            scope="row"
                                            style={{
                                                position: "sticky",
                                                left: 0,
                                                background: "white",
                                                zIndex: 1,
                                            }}
                                        >
                                            {indexRow + 1}
                                        </TableCell>

                                        <TableCell
                                            component="td"
                                            scope="row"
                                            style={{
                                                position: "sticky",
                                                left: "70px",
                                                background: "white",
                                                zIndex: 1,
                                            }}
                                        >
                                            {productObject.name}
                                        </TableCell>

                                        <TableCell align="right">
                                            <input
                                                className="customTextfield"
                                                min="0"
                                                step=".01"
                                                placeholder="Rate"
                                                value={productObject.rateForCentralizedStructure}
                                                name="rateForCentralizedStructure"
                                                onChange={(e) => this.onChange(e, productObject)}
                                                type="number"
                                            />
                                            <span style={{ color: redColor, fontSize: "12px" }}>
                                                {(!productObject.rateForCentralizedStructure || productObject.rateForCentralizedStructure == 0) &&
                                                    productObject.mrpError
                                                    ? "Rate Required"
                                                    : ""}
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                ))}

                            </TableBody>
                        </Table>
                    </TableContainer>

                    <br />
                    <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        rowSpacing={2}
                        columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                        container
                        justifyContent="right"
                    >
                        <ButtonCompo
                            size="medium"
                            type="submit"
                            variant="contained"
                            name="Submit"
                            onClick={this.onSubmitHandler}
                        />
                    </Grid>
                </Paper>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    multipleStockList: state.manageStockByDate,
    stakeHolderTypeList: state.stockStakeHolderType,
    productList: state.product,
    centralizedRateStructList: state.centralizedRateStruct,
});
const mapDispatchToProps = {
    getAllActiveProductSortByBrand,
    showLoador,
    showNotification,
    getCentralizedRateStructureById,
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(CentralizedRateStructureForm);
