import endpoint from "../../config/endpoints";

export const CentralizedRateStructureJson = {
    showSaveNextBtn: true,
    formPath: "/centralized-rate-structure-form",
    apiBaseURL: endpoint.centralRateStructureHeader,
    isActiveURL: endpoint.centralRateStructureHeader + "/update-status",
    openFormPathStatus: "/centralized-rate-structure-assignation",
    formPathView: "/centralized-rate-structure-view",
    screenTitle: "Centralized Rate Structure",
    showAddButton: true,
    showPdfDownload: true,
    pdfFileName: "Centralized Rate Structure",
    showExcelDownload: true,
    excelFileName: "Centralized Rate Structure",
    tableColumnsToFilter: [
        {
            columnDisplayName: "Structure Name",
            columnKeyName: "name",
            isChecked: true,
        },
        {
            columnDisplayName: "Total Outlets",
            columnKeyName: "outletCount",
            isChecked: true,
        },
        {
            columnDisplayName: "Status",
            columnKeyName: "isActive",
            isChecked: true,
        },
    ],


    fieldMeta: [

        {
            label: "Structure Name",
            controlType: "textfield",
            placeHolder: "Structure Name",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "structureName",
            isMandatory: true,
        }
    ],

    fieldMetaWithRadioGroup: [

        {
            label: "Structure Name",
            controlType: "textfield",
            placeHolder: "Structure Name",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "structureName",
            isMandatory: true,
        },

        {
            label: "UOM",
            controlType: "radiogroup",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "uomType",
            tableDataKey: "uomType",
            defaultValue: "0",
            radioGroupItems: [
                {
                    label: "Secondary",
                    value: "0",
                },
                {
                    label: "Primary",
                    value: "1",
                }
            ],
            isMandatory: true,
        },
        {
            label: "Structure For",
            controlType: "radiogroup",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "entityType",
            tableDataKey: "entityType",
            defaultValue: "1",
            radioGroupItems: [
                {
                    label: "Outlet",
                    value: "0",
                },
                {
                    label: "Stock Holder",
                    value: "1",
                },
            ],
            isMandatory: true,
        },
    ],

    fieldMetaForAssignation: [
        {
            label: "Region",
            controlType: "autocomplete",
            placeHolder: "Select Region",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            labelSM: 2,
            labelMD: 2,
            labelLG: 2,
            valueSM: 10,
            valueMD: 10,
            valueLG: 10,
            masterName: "regionDetail",
            dataKey: "region",
            getListId: "region",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "State",
            controlType: "autocomplete",
            placeHolder: "Select State",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            labelSM: 2,
            labelMD: 2,
            labelLG: 2,
            valueSM: 10,
            valueMD: 10,
            valueLG: 10,
            masterName: "stateDetail",
            dataKey: "state",
            getListFrom: "region",
            getListId: "state",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Zone",
            controlType: "multiSelect",
            placeHolder: "Select Zone",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            labelSM: 2,
            labelMD: 2,
            labelLG: 2,
            valueSM: 10,
            valueMD: 10,
            valueLG: 10,
            masterName: "zoneDetail",
            dataKey: "zone",
            getListFrom: "state",
            getListId: "zone",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "District",
            controlType: "multiSelect",
            placeHolder: "Select District",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            labelSM: 2,
            labelMD: 2,
            labelLG: 2,
            valueSM: 10,
            valueMD: 10,
            valueLG: 10,
            masterName: "districtDetail",
            dataKey: "district",
            getListFrom: "zone",
            getListId: "district",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Taluka",
            controlType: "multiSelect",
            placeHolder: "Select Taluka",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            labelSM: 2,
            labelMD: 2,
            labelLG: 2,
            valueSM: 10,
            valueMD: 10,
            valueLG: 10,
            masterName: "talukaDetail",
            dataKey: "taluka",
            getListFrom: "district",
            getListId: "taluka",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Area",
            controlType: "autocomplete",
            placeHolder: "Select Area",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            labelSM: 2,
            labelMD: 2,
            labelLG: 2,
            valueSM: 10,
            valueMD: 10,
            valueLG: 10,
            masterName: "areaDetail",
            dataKey: "area",
            getListFrom: "taluka",
            getListId: "area",
            isRootLevelKey: true,
            isMandatory: true,
        },
    ],

    footerFieldMeta: [],
};
