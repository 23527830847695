import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, }
    from "@material-ui/core";
import { Grid } from "@mui/material";
import React, { Component } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ButtonCompo } from "../../components/Comman/Button";
import { apiPost } from "../../utils/api_service";
import endpoint from "../../config/endpoints";
import { saveFailedMsg, saveWarningMsg, savemsg } from "../../config/messageconstant";
import swal from "sweetalert";


const getItemStyle = (isDragging, draggableStyle) => (
    {
        ...draggableStyle,
        ...(isDragging && { background: "rgb(235,235,235)", }),
    });
export default class DndTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataList: [],
            data: [],
            fieldDataForModule: {},
        };
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    componentDidMount() {

        const moduleObjData = localStorage.getItem("moduleObj");
        this.setState({
            fieldDataForModule:
                moduleObjData != null ? JSON.parse(moduleObjData) : {},
        });

    }

    onDragEnd(result) {
        const { data, set } = this.props;
        console.log(result);
        if (!result.destination) {
            return;
        }
        const dataListReOrder = this.reorder
            (
                data,
                result.source.index,
                result.destination.index
            );

        set({ row: dataListReOrder })

        this.setState({
            dataList: dataListReOrder,
        })
    }

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    onSubmit = () => {
        const { showNotification, showLoador, set, option, adminFlag } = this.props;
        const { dataList } = this.state;

        let regionList = [];
        let stateList = [];
        let zoneList = [];
        let districtList = [];
        let talukaList = [];
        let areaList = [];
        let documentList = [];
        let outletTypeList = [];
        let brandList = [];
        let categoryList = [];
        let subCategoryList = [];
        let packagingTypeList = [];
        let groupProductList = [];
        let beatList = [];
        let outletList = [];

        if (option == "0") {
            regionList = dataList.map((dataListObj, index) => {
                return {
                    ...dataListObj,
                    index: index,
                    sortOrder: index + 1,
                };
            });
            console.log(regionList);
        } else if (option == "1") {
            stateList = dataList.map((dataListObj, index) => {
                return {
                    ...dataListObj,
                    index: index,
                    sortOrderNo: index + 1,
                };
            });
            console.log(stateList);
        } else if (option == "2") {
            zoneList = dataList.map((dataListObj, index) => {
                return {
                    ...dataListObj,
                    index: index,
                    sortOrder: index + 1,
                };
            });
            console.log(zoneList);
        } else if (option == "3") {
            districtList = dataList.map((dataListObj, index) => {
                return {
                    ...dataListObj,
                    index: index,
                    sortOrder: index + 1,
                };
            });
            console.log(districtList);
        } else if (option == "4") {
            talukaList = dataList.map((dataListObj, index) => {
                return {
                    ...dataListObj,
                    index: index,
                    sortOrder: index + 1,
                };
            });
            console.log(talukaList);
        } else if (option == "5") {
            areaList = dataList.map((dataListObj, index) => {
                return {
                    ...dataListObj,
                    index: index,
                    sortOrder: index + 1,
                };
            });
            console.log(areaList);
        } else if (option == "18") {
            beatList = dataList.map((dataListObj, index) => {
                return {
                    ...dataListObj,
                    index: index,
                    sortOrderNo: index + 1,
                };
            });
            console.log(beatList);
        } else if (option == "19") {
            outletList = dataList.map((dataListObj, index) => {
                return {
                    ...dataListObj,
                    index: index,
                    sortOrder: index + 1,
                };
            });
            console.log(outletList);
        } else if (option == "6") {
            documentList = dataList.map((dataListObj, index) => {
                return {
                    ...dataListObj,
                    index: index,
                    sortOrder: index + 1,
                };
            });
            console.log(documentList);
        } else if (option == "7") {
            outletTypeList = dataList.map((dataListObj, index) => {
                return {
                    ...dataListObj,
                    index: index,
                    sortOrder: index + 1,
                };
            });
            console.log(outletTypeList);
        } else if (option == "8") {
            yearList = dataList.map((dataListObj, index) => {
                return {
                    ...dataListObj,
                    index: index,
                    sortOrder: index + 1,
                };
            });
            console.log(yearList);
        } else if (option == "10") {
            brandList = dataList.map((dataListObj, index) => {
                return {
                    ...dataListObj,
                    index: index,
                    sortOrder: index + 1,
                };
            });
            console.log(brandList);
        } else if (option == "11") {
            categoryList = dataList.map((dataListObj, index) => {
                return {
                    ...dataListObj,
                    index: index,
                    sortOrder: index + 1,
                };
            });
            console.log(categoryList);
        } else if (option == "12") {
            subCategoryList = dataList.map((dataListObj, index) => {
                return {
                    ...dataListObj,
                    index: index,
                    sortOrder: index + 1,
                };
            });
            console.log(subCategoryList);
        } else if (option == "13") {
            taxList = dataList.map((dataListObj, index) => {
                return {
                    ...dataListObj,
                    index: index,
                    sortOrder: index + 1,
                };
            });
            console.log(taxList);
        } else if (option == "14") {
            uomList = dataList.map((dataListObj, index) => {
                return {
                    ...dataListObj,
                    index: index,
                    sortOrder: index + 1,
                };
            });
            console.log(uomList);
        } else if (option == "15") {
            packagingTypeList = dataList.map((dataListObj, index) => {
                return {
                    ...dataListObj,
                    index: index,
                    sortOrderNo: index + 1,
                };
            });
            console.log(packagingTypeList);
        } else if (option == "16") {
            tagList = dataList.map((dataListObj, index) => {
                return {
                    ...dataListObj,
                    index: index,
                    sortOrder: index + 1,
                };
            });
            console.log(tagList);
        } else if (option == "17") {
            groupProductList = dataList.map((dataListObj, index) => {
                return {
                    ...dataListObj,
                    index: index,
                    sortOrder: index + 1,
                };
            });
            console.log(groupProductList);
        }

        swal({
            title: "Are you sure?",
            text: saveWarningMsg,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                showLoador({ loador: true });
                apiPost({
                    url:
                        option == "0" ? endpoint.region + "/save-list"
                            : option == "1" ? endpoint.state + "/save-list"
                                : option == "2" ? endpoint.zone + "/save-list"
                                    : option == "3" ? endpoint.district + "/save-list"
                                        : option == "4" ? endpoint.taluka + "/save-list"
                                            : option == "5" ? endpoint.area + "/save-list"
                                                : option == "18" ? endpoint.beat + "/save-list"
                                                    : option == "19" ? endpoint.outlet + "/save-list"
                                                        : option == "6" ? endpoint.document + "/save-list"
                                                            : option == "7" ? endpoint.outletType + "/save-list"
                                                                : option == "8" ? endpoint.year + "/save-list"
                                                                    : option == "10" ? endpoint.brand + "/save-list"
                                                                        : option == "11" ? endpoint.category + "/save-list"
                                                                            : option == "12" ? endpoint.subCategory + "/save-list"
                                                                                : option == "13" ? endpoint.tax + "/save-list"
                                                                                    : option == "14" ? endpoint.unitOfMesurement + "/save-list"
                                                                                        : option == "15" ? endpoint.packagingType + "/save-list"
                                                                                            : option == "16" ? endpoint.tag + "/save-list"
                                                                                                : option == "17" ? endpoint.group + "/save-list"
                                                                                                    : "",
                    postBody: option == "0" ? regionList
                        : option == "1" ? stateList
                            : option == "2" ? zoneList
                                : option == "3" ? districtList
                                    : option == "4" ? talukaList
                                        : option == "5" ? areaList
                                            : option == "18" ? beatList
                                                : option == "19" ? outletList
                                                    : option == "6" ? documentList
                                                        : option == "7" ? outletTypeList
                                                            : option == "8" ? yearList
                                                                : option == "10" ? brandList
                                                                    : option == "11" ? categoryList
                                                                        : option == "12" ? subCategoryList
                                                                            : option == "13" ? taxList
                                                                                : option == "14" ? uomList
                                                                                    : option == "15" ? packagingTypeList
                                                                                        : option == "16" ? tagList
                                                                                            : option == "17" ? groupProductList
                                                                                                : "",
                }).then(({ data, success }) => {
                    showLoador({ loador: false });
                    if (success) {
                        showNotification({ msg: savemsg });
                        window.location.reload();
                    } else {
                        showNotification({ msg: saveFailedMsg, severity: "error" });
                    }
                });
            }
        });


    };

    render() {
        const { data, option, adminFlag } = this.props;
        const { fieldDataForModule } = this.state;
        console.log(adminFlag);
        console.log(data);
        return (
            <>
                <TableContainer component={Paper}>
                    {option == "0" ? (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Sr. No.</TableCell>
                                    <TableCell align="center">Name</TableCell>
                                    <TableCell align="center">Country</TableCell>
                                    <TableCell align="center">Sort Order No</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody component={DroppableComponent(this.onDragEnd)}>
                                {data.map((regionObj, index) => (
                                    <TableRow component={DraggableComponent(regionObj.id, index)}
                                        key={regionObj.id} >
                                        <TableCell scope="row" align="center">{index + 1}</TableCell>
                                        <TableCell>{regionObj.name}</TableCell>
                                        <TableCell>{regionObj.country && regionObj.country.name ? regionObj.country.name : ""}</TableCell>
                                        <TableCell align="center">{regionObj.sortOrder}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    ) : option == "1" ? (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Sr. No.</TableCell>
                                    <TableCell align="center">Name</TableCell>
                                    <TableCell align="center">Code</TableCell>
                                    <TableCell align="center">Region Name</TableCell>
                                    <TableCell align="center">Sort Order No</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody component={DroppableComponent(this.onDragEnd)}>
                                {data.map((regionObj, index) => (
                                    <TableRow component={DraggableComponent(regionObj.id, index)}
                                        key={regionObj.id} >
                                        <TableCell scope="row" align="center">{index + 1}</TableCell>
                                        <TableCell>{regionObj.name}</TableCell>
                                        <TableCell align="center">{regionObj.code}</TableCell>
                                        <TableCell>{regionObj.region && regionObj.region.name ? regionObj.region.name : ""}</TableCell>
                                        <TableCell align="center">{regionObj.sortOrderNo}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    ) : option == "2" ? (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Sr. No.</TableCell>
                                    <TableCell align="center">Name</TableCell>
                                    <TableCell align="center">State</TableCell>
                                    <TableCell align="center">Sort Order No</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody component={DroppableComponent(this.onDragEnd)}>
                                {data.map((regionObj, index) => (
                                    <TableRow component={DraggableComponent(regionObj.id, index)}
                                        key={regionObj.id} >
                                        <TableCell align="center" scope="row">{index + 1}</TableCell>
                                        <TableCell>{regionObj.name}</TableCell>
                                        <TableCell>{regionObj.state && regionObj.state.name ? regionObj.state.name : ""}</TableCell>
                                        <TableCell align="center">{regionObj.sortOrder}</TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    ) : option == "3" && data.length != 0 ? (
                        < Table >
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Sr. No.</TableCell>
                                    <TableCell align="center">Name</TableCell>
                                    <TableCell align="center">Zone</TableCell>
                                    <TableCell align="center">Sort Order No</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody component={DroppableComponent(this.onDragEnd)}>
                                {data.map((regionObj, index) => (
                                    <TableRow component={DraggableComponent(regionObj.id, index)}
                                        key={regionObj.id} >
                                        <TableCell align="center" scope="row">{index + 1}</TableCell>
                                        <TableCell>{regionObj.name}</TableCell>
                                        <TableCell>{regionObj.zone && regionObj.zone.name ? regionObj.zone.name : ""}</TableCell>
                                        <TableCell align="center">{regionObj.sortOrder}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>

                    ) : option == "4" && data.length != 0 ? (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Sr. No.</TableCell>
                                    <TableCell align="center">Name</TableCell>
                                    <TableCell align="center">District</TableCell>
                                    <TableCell align="center">Sort Order No</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody component={DroppableComponent(this.onDragEnd)}>
                                {data.map((regionObj, index) => (
                                    <TableRow component={DraggableComponent(regionObj.id, index)}
                                        key={regionObj.id} >
                                        <TableCell align="center" scope="row">{index + 1}</TableCell>
                                        <TableCell>{regionObj.name}</TableCell>
                                        <TableCell>{regionObj.district && regionObj.district.name ? regionObj.district.name : ""}</TableCell>
                                        <TableCell align="center">{regionObj.sortOrder}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    ) : option == "5" && data.length != 0 ? (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Sr. No.</TableCell>
                                    <TableCell align="center">Name</TableCell>
                                    <TableCell align="center">Taluka</TableCell>
                                    <TableCell align="center">Sort Order No</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody component={DroppableComponent(this.onDragEnd)}>
                                {data.map((regionObj, index) => (
                                    <TableRow component={DraggableComponent(regionObj.id, index)}
                                        key={regionObj.id} >
                                        <TableCell align="center" scope="row">{index + 1}</TableCell>
                                        <TableCell>{regionObj.name}</TableCell>
                                        <TableCell>
                                            {adminFlag ?
                                                (regionObj.taluka && regionObj.taluka.name ? regionObj.taluka.name : "") :
                                                regionObj.taluka}
                                        </TableCell>
                                        <TableCell align="center">{regionObj.sortOrder}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    ) : option == "18" && data.length != 0 ? (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Sr. No.</TableCell>
                                    <TableCell align="center">Name</TableCell>
                                    <TableCell align="center">Area</TableCell>
                                    <TableCell align="center">Sort Order No</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody component={DroppableComponent(this.onDragEnd)}>
                                {data.map((regionObj, index) => (
                                    <TableRow component={DraggableComponent(regionObj.id, index)}
                                        key={regionObj.id} >
                                        <TableCell align="center" scope="row">{index + 1}</TableCell>
                                        <TableCell>{regionObj.beatName}</TableCell>
                                        <TableCell>{regionObj.area && regionObj.area.name ? regionObj.area.name : ""}</TableCell>
                                        <TableCell align="center">{regionObj.sortOrderNo}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    ) :
                        option == "19" && data.length != 0 ? (
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Sr. No.</TableCell>
                                        <TableCell align="center">Firm Name</TableCell>
                                        <TableCell align="center">Owner Name</TableCell>
                                        <TableCell align="center">Area</TableCell>
                                        <TableCell align="center">Beat</TableCell>
                                        <TableCell align="center">Outlet Type</TableCell>
                                        <TableCell align="center">Sort Order No</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody component={DroppableComponent(this.onDragEnd)}>
                                    {data.map((regionObj, index) => (
                                        <TableRow component={DraggableComponent(regionObj.id, index)}
                                            key={regionObj.id} >
                                            <TableCell align="center" scope="row">{index + 1}</TableCell>
                                            <TableCell>{regionObj.firmName}</TableCell>
                                            <TableCell>{regionObj.ownerName}</TableCell>
                                            <TableCell>
                                                {regionObj.beat && regionObj.beat.area &&
                                                    regionObj.beat.area.name ? regionObj.beat.area.name : ""}
                                            </TableCell>
                                            <TableCell>{regionObj.beat && regionObj.beat.beatName ? regionObj.beat.beatName : ""}</TableCell>
                                            <TableCell>{regionObj.outLetType && regionObj.outLetType.name ? regionObj.outLetType.name : ""}</TableCell>
                                            <TableCell align="center">{regionObj.sortOrder}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        ) :
                            option == "6" ? (
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Sr. No.</TableCell>
                                            <TableCell align="center">Name</TableCell>
                                            <TableCell align="center">Type</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody component={DroppableComponent(this.onDragEnd)}>
                                        {data.map((regionObj, index) => (
                                            <TableRow component={DraggableComponent(regionObj.id, index)}
                                                key={regionObj.id} >
                                                <TableCell align="center" scope="row">{index + 1}</TableCell>
                                                <TableCell>{regionObj.docName}</TableCell>
                                                <TableCell>{regionObj.type}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            ) : option == "7" ? (
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Sr. No.</TableCell>
                                            <TableCell align="center">Name</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody component={DroppableComponent(this.onDragEnd)}>
                                        {data.map((regionObj, index) => (
                                            <TableRow component={DraggableComponent(regionObj.id, index)}
                                                key={regionObj.id} >
                                                <TableCell align="center" scope="row">{index + 1}</TableCell>
                                                <TableCell>{regionObj.name}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            ) : option == "8" ? (
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Sr. No.</TableCell>
                                            <TableCell align="center">Name</TableCell>
                                            <TableCell align="center">From Date</TableCell>
                                            <TableCell align="center">To Date</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody component={DroppableComponent(this.onDragEnd)}>
                                        {data.map((regionObj, index) => (
                                            <TableRow component={DraggableComponent(regionObj.id, index)}
                                                key={regionObj.id} >
                                                <TableCell align="center" scope="row">{index + 1}</TableCell>
                                                <TableCell>{regionObj.name}</TableCell>
                                                <TableCell>{regionObj.fromDate}</TableCell>
                                                <TableCell>{regionObj.toDate}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            ) : option == "10" ? (
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Sr. No.</TableCell>
                                            <TableCell align="center">Name</TableCell>
                                            <TableCell align="center">Sort Order No</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody component={DroppableComponent(this.onDragEnd)}>
                                        {data.map((regionObj, index) => (
                                            <TableRow component={DraggableComponent(regionObj.id, index)}
                                                key={regionObj.id} >
                                                <TableCell align="center" scope="row">{index + 1}</TableCell>
                                                <TableCell>{regionObj.name}</TableCell>
                                                <TableCell align="center">{regionObj.sortOrder}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            ) : option == "11" ? (
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Sr. No.</TableCell>
                                            <TableCell align="center">Name</TableCell>
                                            <TableCell align="center">Sort Order No</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody component={DroppableComponent(this.onDragEnd)}>
                                        {data.map((regionObj, index) => (
                                            <TableRow component={DraggableComponent(regionObj.id, index)}
                                                key={regionObj.id} >
                                                <TableCell align="center" scope="row">{index + 1}</TableCell>
                                                <TableCell>{regionObj.name}</TableCell>
                                                <TableCell align="center">{regionObj.sortOrder}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            ) : option == "12" ? (
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Sr. No.</TableCell>
                                            <TableCell align="center">Name</TableCell>
                                            <TableCell align="center">Sort Order No</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody component={DroppableComponent(this.onDragEnd)}>
                                        {data.map((regionObj, index) => (
                                            <TableRow component={DraggableComponent(regionObj.id, index)}
                                                key={regionObj.id} >
                                                <TableCell align="center" scope="row">{index + 1}</TableCell>
                                                <TableCell>{regionObj.name}</TableCell>
                                                <TableCell align="center">{regionObj.sortOrder}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            ) : option == "13" ? (
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Sr. No.</TableCell>
                                            <TableCell align="center">Name</TableCell>
                                            <TableCell align="center">HSN Code</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody component={DroppableComponent(this.onDragEnd)}>
                                        {data.map((regionObj, index) => (
                                            <TableRow component={DraggableComponent(regionObj.id, index)}
                                                key={regionObj.id} >
                                                <TableCell align="center" scope="row">{index + 1}</TableCell>
                                                <TableCell>{regionObj.name}</TableCell>
                                                <TableCell align="center" >{regionObj.hsnCode}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            ) : option == "14" ? (
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Sr. No.</TableCell>
                                            <TableCell align="center">Name</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody component={DroppableComponent(this.onDragEnd)}>
                                        {data.map((regionObj, index) => (
                                            <TableRow component={DraggableComponent(regionObj.id, index)}
                                                key={regionObj.id} >
                                                <TableCell align="center" scope="row">{index + 1}</TableCell>
                                                <TableCell>{regionObj.name}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            ) : option == "15" ? (
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Sr. No.</TableCell>
                                            <TableCell align="center">Name</TableCell>
                                            <TableCell align="center">Sort Order No</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody component={DroppableComponent(this.onDragEnd)}>
                                        {data.map((regionObj, index) => (
                                            <TableRow component={DraggableComponent(regionObj.id, index)}
                                                key={regionObj.id} >
                                                <TableCell align="center" scope="row">{index + 1}</TableCell>
                                                <TableCell>{regionObj.name}</TableCell>
                                                <TableCell align="center">{regionObj.sortOrderNo}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            ) : option == "16" ? (<Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Sr. No.</TableCell>
                                        <TableCell align="center">Name</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody component={DroppableComponent(this.onDragEnd)}>
                                    {data.map((regionObj, index) => (
                                        <TableRow component={DraggableComponent(regionObj.id, index)}
                                            key={regionObj.id} >
                                            <TableCell align="center" scope="row">{index + 1}</TableCell>
                                            <TableCell>{regionObj.name}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            ) : option == "17" ? (
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Sr. No.</TableCell>
                                            <TableCell align="center">Group Name</TableCell>
                                            <TableCell align="center">Category</TableCell>
                                            <TableCell align="center">Brand</TableCell>
                                            <TableCell align="center">Sort Order No</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody component={DroppableComponent(this.onDragEnd)}>
                                        {data.map((regionObj, index) => (
                                            <TableRow component={DraggableComponent(regionObj.id, index)}
                                                key={regionObj.id} >
                                                <TableCell align="center" scope="row">{index + 1}</TableCell>
                                                <TableCell>{regionObj.name}</TableCell>
                                                <TableCell>{regionObj.subCategory.category.name}</TableCell>
                                                <TableCell>{regionObj.brand.name}</TableCell>
                                                <TableCell align="center">{regionObj.sortOrder}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            )
                                : ""

                    }


                </TableContainer >
                <br />
                {fieldDataForModule.editReject == 1 && (
                    <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        rowSpacing={2}
                        container justifyContent="right"
                    >
                        <Grid
                            item
                            sm={2}
                            xs={12}
                            md={2}
                            lg={2}
                            container justifyContent="space-evenly"
                        >
                            <ButtonCompo
                                size="medium"
                                type="button"
                                variant="contained"
                                name="Save"
                                fullWidth={true}
                                onClick={this.onSubmit}
                            />
                        </Grid>
                    </Grid>
                )}

            </>
        );
    }
}

const DraggableComponent = (id, index) => (props) => {
    return (
        <Draggable draggableId={id} index={index}>
            {(provided, snapshot) => (
                <TableRow
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                    )}
                    {...props}
                >
                    {props.children}
                </TableRow>
            )}
        </Draggable>
    );
};

const DroppableComponent = (onDragEnd) => (props) => {
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={"1"} direction="vertical">
                {
                    (provided) => {
                        return (
                            <TableBody ref={provided.innerRef}
                                {...provided.droppableProps}
                                {...props}
                            >
                                {props.children}
                                {provided.placeholder}
                            </TableBody>
                        );
                    }
                }
            </Droppable>
        </DragDropContext>
    );
};