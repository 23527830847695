import endpoint from "../../config/endpoints";

export const OutletMovingForStockHolderFooterJson = {
  showSaveNextBtn: false,
  formPath: "",
  screenTitle: "",
  showTitle: false,
  showCancel: false,
  showSaveBtn: false,
  fieldMeta: [
    {
      label: "Stock Holder",
      controlType: "autocomplete",
      placeHolder: "Select Stock Holder",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "stockHolderFooter",
      masterName: "stockHolderDetail",
      isMandatory: true,
      isRootLevelKey: true,
    },
  ],
};
