import endpoint from "../../config/endpoints";

export const AreaMasterJson = {
    formPath: "/area-master-form",
    apiBaseURL: endpoint.area,
    changeExcel: endpoint.area,
    isEditURL: endpoint.area,
    screenTitle: "Area",
    searchButtonGrid: 1,
    showAddButton: true,
    showPdfDownload: false,
    pdfFileName: "Area",
    showExcelDownload: true,
    excelFileName: "Area",
    tableColumnsToFilter: [
        {
            columnDisplayName: "Name",
            columnKeyName: "name",
            isChecked: true,
        },
        {
            columnDisplayName: "Zone",
            columnKeyName: "zoneName",
            isChecked: true,
        },
        {
            columnDisplayName: "Beat Count",
            columnKeyName: "beatCount",
            isChecked: true,
        },
        {
            columnDisplayName: "Outlet Count",
            columnKeyName: "outletCount",
            isChecked: true,
        },
        {
            columnDisplayName: "Sort Order Number",
            columnKeyName: "sortOrder",
            isChecked: true,
        },
    ],

    fieldMeta: [
        {
            label: "Region",
            controlType: "autocomplete",
            placeHolder: "Select Region",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "regionDetail",
            dataKey: "region",
            getListId: "region",
            isRootLevelKey: true,
            isMandatory: false,
        },
        {
            label: "State",
            controlType: "autocomplete",
            placeHolder: "Select State",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "stateDetail",
            dataKey: "stateId",
            getListId: "stateId",
            isRootLevelKey: true,
            isMandatory: false,
        },
        {
            label: "Zone",
            controlType: "autocomplete",
            placeHolder: "Select Zone",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "zoneDetail",
            dataKey: "zoneIds",
            getListFrom: "stateId",
            getListId: "zoneIds",
            isRootLevelKey: true,
            isMandatory: false,
        },
        {
            label: "District",
            controlType: "autocomplete",
            placeHolder: "Select District",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "districtDetail",
            dataKey: "districtIds",
            getListFrom: "zoneIds",
            getListId: "districtIds",
            isRootLevelKey: true,
            isMandatory: false,
        },
        {
            label: "Taluka",
            controlType: "autocomplete",
            placeHolder: "Select Taluka",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "talukaDetail",
            dataKey: "taluka",
            getListFrom: "districtIds",
            getListId: "taluka",
            isRootLevelKey: false,
            isMandatory: true,
        },
        {
            label: "Name",
            controlType: "textfield",
            placeHolder: "Name",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            autoFocus: true,
            dataKey: "name",
            isMandatory: true,
            uniqueValidation: true,
        },
        {
            label: "Short Name",
            controlType: "textfield",
            placeHolder: "Short Name",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "shortName",
            isMandatory: true,
        },
        {
            label: "Sort Order No",
            controlType: "textfield",
            placeHolder: "Sort Order Number",
            inputType: "number",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "sortOrder",
            isMandatory: true,
        },
    ],

    fieldMetaForManagerAreaAssignation: [
        {
            label: "Stock Holder Type",
            controlType: "multiSelect",
            placeHolder: "Select Stock Holder Type",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "stockHolderTypeDetail",
            dataKey: "stockHolderType",
            getListId: "stockHolderType",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Stock Holder Name",
            controlType: "multiSelect",
            placeHolder: "Select Stock Holder Name",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "stockHolderNameDetail",
            dataKey: "stockHolderName",
            getListId: "stockHolderName",
            isRootLevelKey: true,
            isMandatory: true,
        }
    ],

    searchList: [
        {
            label: "Region",
            controlType: "autocomplete",
            placeHolder: "Select Region",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            labelSM: 2,
            labelMD: 2,
            labelLG: 2,
            valueSM: 10,
            valueMD: 10,
            valueLG: 10,
            masterName: "regionDetail",
            dataKey: "region",
            getListId: "region",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "State",
            controlType: "autocomplete",
            placeHolder: "Select State",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            labelSM: 2,
            labelMD: 2,
            labelLG: 2,
            valueSM: 10,
            valueMD: 10,
            valueLG: 10,
            masterName: "stateDetail",
            dataKey: "state",
            getListFrom: "region",
            getListId: "state",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Zone",
            controlType: "multiSelect",
            placeHolder: "Select Zone",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            labelSM: 2,
            labelMD: 2,
            labelLG: 2,
            valueSM: 10,
            valueMD: 10,
            valueLG: 10,
            masterName: "zoneDetail",
            dataKey: "zone",
            getListFrom: "state",
            getListId: "zone",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "District",
            controlType: "multiSelect",
            placeHolder: "Select District",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            labelSM: 2,
            labelMD: 2,
            labelLG: 2,
            valueSM: 10,
            valueMD: 10,
            valueLG: 10,
            masterName: "districtDetail",
            dataKey: "district",
            getListFrom: "zone",
            getListId: "district",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Taluka",
            controlType: "multiSelect",
            placeHolder: "Select Taluka",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            labelSM: 2,
            labelMD: 2,
            labelLG: 2,
            valueSM: 10,
            valueMD: 10,
            valueLG: 10,
            masterName: "talukaDetail",
            dataKey: "taluka",
            getListFrom: "district",
            getListId: "taluka",
            isRootLevelKey: true,
            isMandatory: true,
        },
    ],
};
