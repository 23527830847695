import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
const initialState = {
  dayWiseBitAssign: [],
};
let URL = endpoints.dayWiseBitAssign;

const dayWiseBitAssignSlice = createSlice({
  name: "dayWiseBitAssign",
  initialState,
  reducers: {
    dayWiseBitAssignSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        dayWiseBitAssign: row,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const { dayWiseBitAssignSuccess, resetState } =
  dayWiseBitAssignSlice.actions;

export default dayWiseBitAssignSlice.reducer;

export const getDayWiseBitAssign =
  ({ areaId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/beat-and-outlet-count-by-area?areaId=" + areaId,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((categoryObject, index) => {
            let categoryData = {
              index: index + 1,
              id: categoryObject.id === null ? "" : categoryObject.id,
              salesTeamName:
                categoryObject.salesTeam !== null &&
                categoryObject.salesTeam.name !== null
                  ? categoryObject.salesTeam.name
                  : "",
              noOfBeat:
                categoryObject.noOfBeat === null ? "" : categoryObject.noOfBeat,
              noOfOutLet:
                categoryObject.noOfOutLet === null
                  ? ""
                  : categoryObject.noOfOutLet,
            };
            return categoryData;
          });
          dispatch(dayWiseBitAssignSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getDayWiseBeatFromAreas =
  ({ areaIdsData }) =>
  async (dispatch) => {
    try {
      const response = apiPost({
        url: URL + "/beat-and-count-by-areas",
        postBody: areaIdsData,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((categoryObject, index) => {
            let categoryData = {
              index: index + 1,
              id: categoryObject.id === null ? "" : categoryObject.id,
              salesTeamName:
                categoryObject.salesTeam !== null &&
                categoryObject.salesTeam.name !== null
                  ? categoryObject.salesTeam.name
                  : "",
              noOfBeat:
                categoryObject.noOfBeat === null ? "" : categoryObject.noOfBeat,
              noOfOutLet:
                categoryObject.noOfOutLet === null
                  ? ""
                  : categoryObject.noOfOutLet,
            };
            return categoryData;
          });
          dispatch(dayWiseBitAssignSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getUpdatedDayWiseBeatFromAreas =
  ({ areaIdsData }) =>
  async (dispatch) => {
    try {
      const response = apiPost({
        // http://localhost:8088/api/distribution/v1/beat-assign-daywise-header/beat-and-count-by-areas-new
        url: URL + "/beat-and-count-by-areas-new",
        postBody: areaIdsData,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((categoryObject, index) => {
            let categoryData = {
              index: index + 1,
              id: categoryObject.id === null ? "" : categoryObject.id,
              salesTeamName:
                categoryObject.personName !== null
                  ? categoryObject.personName +
                    " (" +
                    categoryObject.stockStakeHolderPrefix +
                    ")"
                  : "",
              noOfBeat:
                categoryObject.noOfBeat === null ? "" : categoryObject.noOfBeat,
              noOfOutLet:
                categoryObject.noOfOutLet === null
                  ? ""
                  : categoryObject.noOfOutLet,
            };
            return categoryData;
          });
          dispatch(dayWiseBitAssignSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const setDayWiseBitAssign = () => async (dispatch) => {
  dispatch(dayWiseBitAssignSuccess({ row: [] }));
};
