import {
  CalendarTodayOutlined,
  DateRangeOutlined,
  DescriptionOutlined,
  LocalOfferOutlined,
  LocationOnOutlined,
  StorefrontOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import offerImg from "../../Images/offer.png";
import { LabelCompo } from "../../components/Comman/Label";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import { ButtonCompo } from "../../components/Comman/Button";
import { blackColor, titleColor } from "../../config/ColorObj";
import DescriptionIcon from "@mui/icons-material/Description";

const ClaimRequestPopup = ({ open, onClose, selectedOffers, onSubmit }) => {
  const [searchValue, setSearchValue] = useState("");

  const getFilteredTableData = (tableData) => {
    if (!searchValue) return tableData || [];
    return (tableData || []).filter((currentRow) => {
      const lowerSearchValue = searchValue.toLowerCase();
      return (
        currentRow.outletName &&
        currentRow.outletName.toLowerCase().includes(lowerSearchValue)
      );
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        <LandingScreenHeader
          screenTitle="Confirm CRN"
          showSearchBox={true}
          showAddButton={false}
        />
      </DialogTitle>
      <DialogContent>
        <Paper
          sx={{
            padding: 2,
            border: "1px solid #ddd",
            borderRadius: 2,
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Box
            sx={{
              overflowY: "auto",
              maxHeight: "500px",
              borderRadius: "10px",
              "&::-webkit-scrollbar": { display: "none" },
            }}
          >
            <Grid container alignItems="center">
              <Grid item xs={12}>
                {selectedOffers?.length > 0 ? (
                  <>
                    {selectedOffers.map((dataRow, index) => (
                      <div
                        key={index}
                        // style={{ marginBottom: "5px", paddingBottom: "5px" }}
                      >
                        {/* Offer Details */}
                        <Box
                          sx={{
                            maxHeight: "65px",
                            marginBottom: "10px",
                            position: "relative",
                            border: "2px solid #ddd",
                            borderRadius: "10px",
                            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                            display: "flex", // Centering horizontally
                            flexDirection: "column",
                            alignItems: "center", // Centering content
                            textAlign: "center", // Centering text
                          }}
                        >
                          <Box
                            sx={{
                              position: "absolute",
                              // top: "15px",
                              right: "10px",
                              width: "70px",
                              height: "60px",
                              animation: "blinker 1.0s linear infinite",
                              "@keyframes blinker": { "50%": { opacity: 0.2 } },
                            }}
                          >
                            <img
                              style={{
                                width: "100%",
                                height: "100%",
                                borderRadius: "15%",
                                objectFit: "contain",
                                padding: "5px",
                              }}
                              src={offerImg}
                              alt="Offer Icon"
                            />
                          </Box>
                          <Grid container spacing={2} padding={4}>
                            {/* Offer Name, From Date, To Date */}
                            <Grid container spacing={2} alignItems="center">
                              <Grid
                                item
                                xs={12}
                                md={6}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <IconButton size="small" disabled>
                                  <LocalOfferOutlined
                                    sx={{ color: blackColor }}
                                  />
                                </IconButton>
                                <LabelCompo
                                  className="text-black"
                                  style={{
                                    fontSize: 16,
                                    color: titleColor,
                                    marginRight: 8,
                                  }}
                                  label="Offer Name:"
                                />
                                <LabelCompo
                                  className="text-black"
                                  style={{ fontSize: 16 }}
                                  label={dataRow?.name || "-"}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={3}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <IconButton size="small" disabled>
                                  <CalendarTodayOutlined
                                    sx={{ color: blackColor }}
                                  />
                                </IconButton>
                                <LabelCompo
                                  className="text-black"
                                  style={{
                                    fontSize: 16,
                                    color: titleColor,
                                    marginRight: 8,
                                  }}
                                  label="From Date:"
                                />
                                <LabelCompo
                                  className="text-black"
                                  style={{ fontSize: 16 }}
                                  label={dataRow?.fromDate || "-"}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={3}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <IconButton size="small" disabled>
                                  <DateRangeOutlined
                                    sx={{ color: blackColor }}
                                  />
                                </IconButton>
                                <LabelCompo
                                  className="text-black"
                                  style={{
                                    fontSize: 16,
                                    color: titleColor,
                                    marginRight: 8,
                                  }}
                                  label="To Date:"
                                />
                                <LabelCompo
                                  className="text-black"
                                  style={{ fontSize: 16 }}
                                  label={dataRow?.toDate || "-"}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                        {/* Outlets and Items */}
                        {dataRow?.outlets?.length > 0 &&
                          getFilteredTableData(dataRow.outlets).map(
                            (outlet, outletIndex) => (
                              <div
                                key={outlet.outletId}
                                style={{
                                  marginBottom: "10px",
                                  padding: "15px",
                                  border: "2px solid #ddd",
                                  borderRadius: "10px",
                                  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                                }}
                              >
                                {/* Outlet Details */}
                                <Grid container spacing={2} alignItems="center">
                                  <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <IconButton size="small" disabled>
                                      <StorefrontOutlined
                                        sx={{ color: blackColor }}
                                      />
                                    </IconButton>
                                    <LabelCompo
                                      className="text-black"
                                      style={{
                                        fontSize: 16,
                                        color: titleColor,
                                        marginRight: 8,
                                      }}
                                      label="Outlet Name:"
                                    />
                                    <LabelCompo
                                      className="text-black"
                                      style={{ fontSize: 16 }}
                                      label={outlet.outletName || "-"}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <IconButton size="small" disabled>
                                      <LocationOnOutlined
                                        sx={{ color: blackColor }}
                                      />
                                    </IconButton>
                                    <LabelCompo
                                      className="text-black"
                                      style={{
                                        fontSize: 16,
                                        color: titleColor,
                                        marginRight: 8,
                                      }}
                                      label="Beat Name:"
                                    />
                                    <LabelCompo
                                      className="text-black"
                                      style={{ fontSize: 16 }}
                                      label={outlet.beatName || "-"}
                                    />
                                  </Grid>
                                </Grid>

                                {/* Bill details */}
                                <Grid container spacing={2} alignItems="center">
                                  <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <IconButton size="small" disabled>
                                      <DescriptionIcon
                                        sx={{ color: blackColor }}
                                      />
                                    </IconButton>
                                    <LabelCompo
                                      className="text-black"
                                      style={{
                                        fontSize: 16,
                                        color: titleColor,
                                        marginRight: 8,
                                      }}
                                      label="Bill No:"
                                    />
                                    <LabelCompo
                                      className="text-black"
                                      style={{ fontSize: 16 }}
                                      label={outlet.billNo || "-"}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <IconButton size="small" disabled>
                                      <DateRangeOutlined
                                        sx={{ color: blackColor }}
                                      />
                                    </IconButton>
                                    <LabelCompo
                                      className="text-black"
                                      style={{
                                        fontSize: 16,
                                        color: titleColor,
                                        marginRight: 8,
                                      }}
                                      label="Bill Date:"
                                    />
                                    <LabelCompo
                                      className="text-black"
                                      style={{ fontSize: 16 }}
                                      label={outlet.billDate || "-"}
                                    />
                                  </Grid>
                                </Grid>

                                {/* Items Table */}
                                {outlet.items?.length > 0 && (
                                  <Grid
                                    container
                                    item
                                    xs={12}
                                    sx={{ marginTop: 2 }}
                                  >
                                    <DynamicTable
                                      data={outlet.items}
                                      tableHead={[
                                        {
                                          title: "Sr.No.",
                                          name: "index",
                                          positionCenter: true,
                                          showInscreen: true,
                                          align: "center",
                                        },
                                        {
                                          title: "Item Name",
                                          name: "itemName",
                                          positionCenter: false,
                                          showInscreen: true,
                                          align: "left",
                                          canSearch: true,
                                        },
                                        {
                                          title: "Taxable Amount",
                                          name: "taxableAmt",
                                          positionCenter: false,
                                          showInscreen: true,
                                          alignCenter: "right",
                                        },
                                        {
                                          title: "Tax Amount",
                                          name: "taxAmt",
                                          positionCenter: false,
                                          showInscreen: true,
                                          alignCenter: "right",
                                        },

                                        {
                                          title: "Total Amount",
                                          name: "totalAmt",
                                          positionCenter: false,
                                          showInscreen: true,
                                          alignCenter: "right",
                                        },
                                      ]}
                                      showPagination={false}
                                      isActionColActive={false}
                                    />
                                  </Grid>
                                )}
                              </div>
                            )
                          )}
                      </div>
                    ))}
                  </>
                ) : (
                  <Typography
                    variant="h6"
                    color="textSecondary"
                    sx={{ textAlign: "center", mt: 2 }}
                  >
                    No offers available at the moment.
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </DialogContent>
      <DialogActions>
        <ButtonCompo
          size="medium"
          variant="outlined"
          name="Cancel"
          onClick={onClose}
        />
        <ButtonCompo
          size="medium"
          variant="contained"
          name="Submit"
          onClick={() => onSubmit(selectedOffers)}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ClaimRequestPopup;
