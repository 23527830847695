import endpoint from "../../config/endpoints";

export const ClaimStructureJson = {
    showSaveNextBtn: true,
    formPath: "/claim-structure-form",
    apiBaseURL: endpoint.claimStructure,
    openFormPathStatus: "/claim-structure-allotment",
    screenTitle: "Claim Structure",
    showAddButton: true,
    showPdfDownload: false,
    pdfFileName: "Claim Structure",
    showExcelDownload: false,
    excelFileName: "Claim Structure",
    tableColumnsToFilter: [
        {
            columnDisplayName: "Claim Structure",
            columnKeyName: "name",
            isChecked: true,
        },
        {
            columnDisplayName: "Status",
            columnKeyName: "isActive",
            isChecked: true,
        },
    ],
    fieldMetaHeader: [
        {
            label: "Claim Structure",
            controlType: "textfield",
            placeHolder: "Enter Claim Structure",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "name",
            isMandatory: true,
        },
    ],
};
