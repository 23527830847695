import endpoint from "../../config/endpoints";

export const StockHolderOrderingConfigurationJson = {
  showSaveNextBtn: true,
  screenTitle: "Stock Holder Ordering Configuration",
  formPath: "/stock-holder-ordering-configuration",
  formPathView: "/stock-holder-ordering-configuration-view",
  backToList: "/stock-holder-ordering-configuration-list",
  apiBaseURL: endpoint.stockHolderOrderingConfiguration,
  showTitle: true,
  showCancel: false,
  showSaveBtn: true,

  searchList: [
    {
      label: "Stock Holder",
      controlType: "multiSelect",
      placeHolder: "Select Stock Holder",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "stakeHolderTypeDetail",
      dataKey: "stockStakeHolderTypeIds",
      isRootLevelKey: true,
      isMandatory: true,
      disable: true,
    },

    {
      label: "From Time",
      controlType: "timePicker",
      placeHolder: "",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      dataKey: "fromTime",
      isMandatory: true,
    },

    {
      label: "To Time",
      controlType: "timePicker",
      placeHolder: "",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      dataKey: "toTime",
      isMandatory: true,
    },
  ],

  fieldMeta: [
    {
      label: "Stock Holder",
      controlType: "autocomplete",
      placeHolder: "Select Stock Holder",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stockStakeHolderTypeDetail",
      dataKey: "stockStakeHolderTypeIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Region",
      controlType: "autocomplete",
      placeHolder: "Select Region",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "regionDetail",
      dataKey: "region",
      getListId: "region",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "State",
      controlType: "autocomplete",
      placeHolder: "Select State",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stateDetail",
      dataKey: "state",
      getListFrom: "region",
      getListId: "state",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Zone",
      controlType: "multiSelect",
      placeHolder: "Select Zone",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "zoneDetail",
      dataKey: "zone",
      getListFrom: "state",
      getListId: "zone",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "District",
      controlType: "multiSelect",
      placeHolder: "Select District",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "districtDetail",
      dataKey: "district",
      getListFrom: "zone",
      getListId: "district",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Taluka",
      controlType: "multiSelect",
      placeHolder: "Select Taluka",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "talukaDetail",
      dataKey: "taluka",
      getListFrom: "district",
      getListId: "taluka",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Area",
      controlType: "multiSelect",
      placeHolder: "Select Area",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "areaDetail",
      dataKey: "areaIds",
      getListFrom: "taluka",
      getListId: "areaIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
  ],
};
