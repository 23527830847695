import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
const initialState = {
  setting: [],
  settingGetAll: [],
  settingSaveGetAll: [],
};
let URL = endpoints.setting;
const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    settingSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        setting: row,
      };
    },
    settingGetAllSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        settingGetAll: row,
      };
    },
    settingSaveGetAllSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        settingSaveGetAll: row,
      };
    },
    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  settingSuccess,
  settingGetAllSuccess,
  settingSaveGetAllSuccess,
} = settingSlice.actions;

export default settingSlice.reducer;

export const getSetting = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL,
    }).then(({ data, success }) => {
      if (success) {
        localStorage.setItem("setting", JSON.stringify(data.data));
        dispatch(settingSuccess({ row: data.data }));
        return { response: data.data, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getAllSetting = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL,
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((settingObj, index) => {
          let settingData = {
            index: index + 1,
            id: settingObj.id === null ? "" : settingObj.id,
            settingKey:
              settingObj.settingKey === null ? "" : settingObj.settingKey,
            settingValue:
              settingObj.settingValue === null ? "" : settingObj.settingValue,
            description:
              settingObj.description === null ? "" : settingObj.description,
            isChecked: false,
          };
          return settingData;
        });
        dispatch(settingGetAllSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getAllSettingSave = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/save-list",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((settingSaveObj, index) => {
          let settingSaveData = {
            index: index + 1,
            id: settingSaveObj.id === null ? "" : settingSaveObj.id,
            settingKey:
              settingSaveObj.settingKey === null
                ? ""
                : settingSaveObj.settingKey,
            settingValue:
              settingSaveObj.settingValue === null
                ? ""
                : settingSaveObj.settingValue,
            description:
              settingSaveObj.description === null
                ? ""
                : settingSaveObj.description,
            isChecked:
              settingSaveObj.isChecked === null ? "" : settingSaveObj.isChecked,
          };
          return settingSaveData;
        });
        dispatch(settingSaveGetAllSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const setSettingKeyDetails =
  ({ row }) =>
    async (dispatch) => {
      dispatch(settingGetAllSuccess({ row }));
    };
