import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
const initialState = {
    distributorInquiryWatcher: [],

};
let URL = endpoints.distributorInquiryWatcher;
const distributorInquiryWatcherSlice = createSlice({
    name: "distributorInquiryWatcher",
    initialState,
    reducers: {
        distributorInquiryWatcherSuccess: (state = cloneDeep(initialState), action) => {
            const { row } = action.payload;
            return {
                ...state,
                distributorInquiryWatcher: row,
            };
        },



        resetState: (state = cloneDeep(initialState), action) => {
            return {
                ...state,
                ...initialState,
            };
        },
    },
});

export const {
    distributorInquiryWatcherSuccess,
    resetState,
} = distributorInquiryWatcherSlice.actions;

export default distributorInquiryWatcherSlice.reducer;

export const getDistributorInquiryWatcher = ({ distributorInquiryId }) => async (dispatch) => {
    try {
        const response = apiGet({
            url: URL + "/by-distributor_inquiry?distributorInquiryId=" + distributorInquiryId,
        }).then(({ data, success }) => {
            if (success) {
                const row = data.data.map((inquiryWatcherObj, index) => {
                    let watcherData = {
                        index: index + 1,
                        id: inquiryWatcherObj.id === null ? "" : inquiryWatcherObj.id,
                        distributorInquiryId:
                            inquiryWatcherObj.distributorInquiryId === null
                                ? ""
                                : inquiryWatcherObj.distributorInquiryId,
                        distributorInquiryName:
                            inquiryWatcherObj.distributorInquiryName === null ? "" : inquiryWatcherObj.distributorInquiryName,
                        stockStakeHolderTypeId:
                            inquiryWatcherObj.stockStakeHolderTypeId
                                ? inquiryWatcherObj.stockStakeHolderTypeId
                                : "",
                        stockStakeHolderTypeName:
                            inquiryWatcherObj.stockStakeHolderTypeName === null ? "" : inquiryWatcherObj.stockStakeHolderTypeName,
                        watcherId:
                            inquiryWatcherObj.watcherId === null
                                ? ""
                                : inquiryWatcherObj.watcherId,
                        watcherName:
                            inquiryWatcherObj.wactherName === null ? "" : inquiryWatcherObj.wactherName,
                        isActive:
                            inquiryWatcherObj.isActive === null ? "" : inquiryWatcherObj.isActive,
                    };
                    return watcherData;
                });
                dispatch(distributorInquiryWatcherSuccess({ row }));
                return { response: row, success };
            }
            return { response: [], success };
        });
        return response;
    } catch (e) {
        return console.error(e.message);
    }
};



export const setDistributorInquiryWatcher =
    ({ row }) =>
        async (dispatch) => {
            dispatch(distributorInquiryWatcherSuccess({ row }));
        };

