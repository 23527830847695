import { Grid, Paper } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { ButtonCompo } from "../../components/Comman/Button";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import CheckBoxSelectionTable from "../../components/Comman/RT/CheckBoxSelectionTable";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import endpoint from "../../config/endpoints";
import {
  checkedListEmptyMsg,
  noInternetMsg,
  publishDateMsg,
  saveFailedMsg,
  savemsg,
  saveWarningMsg,
  serverMsg,
} from "../../config/messageconstant";
import dayjs from "dayjs";
import { AssignStructureJson } from "../../DynamicFormsJson/MastersJSON/assignStructureJson";
import { getStakeHolderNameByStakeholderType } from "../../Slice/rateConfigurationEditingForStockholder.slice";
import { getStockStakeHolderType } from "../../Slice/stockStakeHolderType.slice";
import { apiGet, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
// import RateConfigurationPopup from "./RateConfigurationPopup";
import { withRouter } from "../../components/withRouter";
import { compose } from "@reduxjs/toolkit";
import { getAreaByTaluka } from "../../Slice/area.slice";
import { getTalukaByDistrict } from "../../Slice/taluka.slice";
import { getDistrictByZone } from "../../Slice/district.slice";
import { getRegion } from "../../Slice/region.slice";
import { getStateByRegion } from "../../Slice/state.slice";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import AssignStructurePopUp from "./AssignStructurePopUp";
import DynamicFormWithoutValidation from "../../components/dynamicscreens/DynamicFormWithoutValidation";
import {
  getStructureAssignation,
  setStructureAssignation,
} from "../../Slice/productRateStructure.slice";
import { getZoneByState } from "../../Slice/zone.slice";

// let tableColumns = [
//   {
//     title: "Sr.No.",
//     name: "index",
//     positionCenter: true,
//     showInscreen: true,
//     align: "center",
//   },
//   {
//     title: "Stock Holder",
//     name: "name",
//     align: "left",
//     canSearch: true,
//     align: "left",
//   },
// ];

let tableColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: false,
    showInscreen: true,
    alignCell: "center",
    align: "center",
  },
  {
    title: "Structure",
    name: "productRateStructureName",
    positionCenter: false,
    showInscreen: true,
    alignCell: "center",
    alignCenter: "left",
    canSearch: true,
  },
  {
    title: "Stock Holder",
    name: "stockStakeHolderName",
    positionCenter: false,
    showInscreen: true,
    alignCell: "center",
    alignCenter: "left",
    canSearch: true,
  },
  {
    title: "Publish Date",
    name: "publishDate",
    positionCenter: false,
    showInscreen: true,
    alignCell: "center",
    alignCenter: "center",
    canSearch: true,
  },
];

class AssignStructure extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
      formData: {},
      dynamicMasterData: {
        stakeHolderTypeDetail:
          this.props.stakeHolderTypeList?.stockStakeHolderType,
      },
      showTableList: false,
      selection: [],
      DataToSet: [],
      openPopUp: false,
      publishDate: dayjs().format("DD-MM-YYYY"),
      productStateList: [],
      fieldData: {},
      formErrors: {},
      getJson: "",
      isLog: true,
      moduleObjDataObj: {},
    };
  }

  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  async componentDidMount() {
    const { getStockStakeHolderType, getRegion, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {

      const moduleObjData = localStorage.getItem("moduleObj");
      const moduleObjDataObj = moduleObjData != null ? JSON.parse(moduleObjData) : {};

      this.setState({ moduleObjDataObj });

      showLoador({ loador: true });
      await getStockStakeHolderType({ userTypeIds: 2 }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getRegion().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.stockStakeHolderType !== nextProps.stockStakeHolderType) {
      if (
        this.props.stockStakeHolderType?.stockStakeHolderType !==
        nextProps.stockStakeHolderType?.stockStakeHolderType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stockStakeHolderTypeDetail:
              nextProps.stockStakeHolderType?.stockStakeHolderType,
          },
        });
      }
    }
    if (this.props.regionList !== nextProps.regionList) {
      if (this.props.regionList?.region !== nextProps.regionList?.region) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            regionDetail: nextProps.regionList?.region,
          },
        });
      }
    }
    if (this.props.stateList !== nextProps.stateList) {
      if (
        this.props.stateList?.stateByRegion !==
        nextProps.stateList?.stateByRegion
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stateDetail: nextProps.stateList?.stateByRegion,
          },
        });
      }
    }
    if (this.props.zoneList !== nextProps.zoneList) {
      if (
        this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            zoneDetail: nextProps.zoneList?.zoneByState,
          },
        });
      }
    }
    if (this.props.districtList !== nextProps.districtList) {
      if (
        this.props.districtList?.districtByZone !==
        nextProps.districtList?.districtByZone
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            districtDetail: nextProps.districtList?.districtByZone,
          },
        });
      }
    }
    if (this.props.talukaList !== nextProps.talukaList) {
      if (
        this.props.talukaList?.talukaByDistrict !==
        nextProps.talukaList?.talukaByDistrict
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            talukaDetail: nextProps.talukaList?.talukaByDistrict,
          },
        });
      }
    }
    if (this.props.areaList !== nextProps.areaList) {
      if (
        this.props.areaList?.areaByTaluka !== nextProps.areaList?.areaByTaluka
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.areaByTaluka,
          },
        });
      }
    }
  }

  onDataChange = (fieldName, newValue) => {
    if (fieldName == "date") {
      let date = dayjs(newValue).format("DD-MM-YYYY");
      this.setState({
        publishDate: date != "" && date != null ? date : "",
      });
    }
    const { fieldData } = this.state;
    const dataToSearch = {};
    const dataToReset = { ...this.state.fieldData };
    let listError = false;
    AssignStructureJson.fieldMeta.map((currentField) => {
      if (currentField.getDate && currentField.dataKey === fieldName) {
        dataToReset[currentField.getDate] = newValue.add(365, "day");
      }
      if (currentField.dataKey === fieldName && currentField.getListId) {
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
          return null;
        });

        this.getListById(dataToSearch);
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key == fieldName && dataToReset[key]) {
            delete dataToReset[currentField.dataKey];
          }
        });
      }
      if (
        !listError &&
        fieldData[currentField.dataKey] &&
        currentField.controlType != "datepicker" &&
        currentField.getListFrom == undefined
      ) {
        dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
      }
      return null;
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
    });
  };
  // getListById = async (data) => {
  //   const {
  //     showNotification,
  //     showLoador,
  //     getStakeHolderNameByStakeholderType,
  //   } = this.props;
  //   const { stakeHolderId } = this.state;
  //   if (navigator.onLine) {

  //     if (data.stockStakeHolderType && data.stockStakeHolderType.id) {
  //       showLoador({ loador: true });
  //       await getStakeHolderNameByStakeholderType({
  //         stockStakeHolderTypeIds: data.stockStakeHolderType.id,
  //       }).then(({ success }) => {
  //         showLoador({ loador: false });
  //         if (!success) {
  //           showNotification({ msg: serverMsg, severity: "error" });
  //         }
  //       });
  //       if (this.props.params.id) {
  //         showLoador({ loador: true });
  //         await apiGet({
  //           url:
  //             endpoint.productRateStructureAssignation +
  //             "/by-stock-stake-holder-type-and-product-rate-structure?stockStakeHolderTypeId=" +
  //             data.stockStakeHolderType.id +
  //             "&productRateStructureId=" +
  //             this.props.params.id,
  //         }).then((data, success) => {
  //           console.log(data);
  //           showLoador({ loador: false });
  //           if (!success) {
  //             showNotification({ msg: serverMsg, severity: "error" });
  //           } else {
  //             this.setState({
  //               getJson: data.data.json,
  //             });
  //           }
  //         });
  //       }
  //     }
  //   } else {
  //     this.props.showNotification({ msg: noInternetMsg, severity: "error" });
  //   }
  // };

  getListById = async (data) => {
    const {
      getStateByRegion,
      getZoneByState,
      getDistrictByZone,
      getTalukaByDistrict,
      getAreaByTaluka,
      getStructureAssignation,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      console.log(data);
      if (data.region && data.region != "") {
        showLoador({ loador: true });
        await getStateByRegion({ regionIds: data.region }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.state && data.state != "") {
        showLoador({ loador: true });
        await getZoneByState({ stateIds: data.state }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.zone && data.zone != "") {
        showLoador({ loador: true });
        await getDistrictByZone({ zoneIds: data.zone }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.district && data.district != "") {
        showLoador({ loador: true });
        await getTalukaByDistrict({ districtIds: data.district }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.taluka && data.taluka != "") {
        showLoador({ loador: true });
        await getAreaByTaluka({ talukaIds: data.taluka }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onSearchData = (data) => {
    const {
      getStructureAssignation,
      showLoador,
      showNotification,
      saveDataObject,
    } = this.props;
    console.log(saveDataObject);

    if (navigator.onLine) {
      let structureJsonData = {
        stockStakeHolderTypeId: data.stockStakeHolderType.id,
        productRateStructureId: saveDataObject.data.id,
        areaIds: data.area.split(","),
      };
      showLoador({ loador: true });
      getStructureAssignation({
        structureData: structureJsonData,
      }).then(({ response, success }) => {
        console.log(response);
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  saveAssignStructure = () => {
    const { publishDate, getJson, moduleObjDataObj, isLog } = this.state;
    const { showNotification, saveDataObject, showLoador } = this.props;
    let assignStructureList =
      this.props.structureList.structureAssignation.filter(
        (row) => row.checked
      );

    let listOfCheckedStockIds = [];


    listOfCheckedStockIds = assignStructureList.map(
      (assignStructureObject) => assignStructureObject.stockStakeHolderId,
    );

    let assignStructureDataToSave = assignStructureList.map(
      (assignStructureObject) => {
        let assignStructureData = {
          productRateStructure: {
            id: this.props.params.id
              ? this.props.params.id
              : saveDataObject.data.id,
          },
          stockStakeHolder: {
            id: assignStructureObject.stockStakeHolderId,
          },
          publishDate: publishDate,
          json: this.props.params.id ? getJson : saveDataObject.data.json,
        };
        return assignStructureData;
      }
    );
    swal({
      title: "Are you sure?",
      text: saveWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (navigator.onLine) {
          showLoador({ loador: true });
          apiGet({
            url: endpoint.productRateStructureAssignation + "/by-stock-stake-holder-isd-and-publlish-date?stockStakeHolderIds=" + listOfCheckedStockIds + "&publishDate=" + publishDate,
          }).then(({ data, success }) => {
            showLoador({ loador: false });
            if (success) {
              if (data.data.length !== 0) {
                showNotification({
                  msg: publishDateMsg, severity: "error",
                });
              } else {

                showLoador({ loador: true });
                apiPost({
                  url: endpoint.productRateStructureAssignation + "/save-list",
                  postBody: assignStructureDataToSave,
                }).then(({ data, success }) => {
                  if (success) {

                    if (isLog) {

                      if (data.data !== null) {

                        let updatedJsonToSend = JSON.stringify(data.data);

                        let dataToSaveForMLog = {
                          module: {
                            moduleId: moduleObjDataObj.moduleId
                          },
                          subModule: {
                            subModuleId: moduleObjDataObj.subModuleId
                          },
                          operationType: 1,
                          type: 1,
                          operationPerformId: this.props.params.id !== null && this.props.params.id ?
                            this.props.params.id : saveDataObject.data.id,
                          updatedJson: updatedJsonToSend,
                        }

                        showLoador({ loador: true });
                        apiPost({
                          url: endpoint.mlog,
                          postBody: dataToSaveForMLog,
                        }).then(({ success }) => {
                          showLoador({ loador: false });
                          if (success) {
                          } else {
                            showNotification({
                              msg: saveFailedMsg,
                              severity: "error",
                            });
                          }
                        });
                      }
                    }


                    this.handleCloses();
                    showLoador({ loador: false });
                    showNotification({
                      msg: savemsg,
                    });
                    this.props.navigate(-1);
                  } else {
                    showNotification({
                      msg: serverMsg,
                      severity: "error",
                    });
                    showLoador({ loador: false });
                  }
                });
              }
            }
          });

        } else {
          showNotification({ msg: noInternetMsg, severity: "error" });
        }
      }
    });
  };

  getSelectedList = (e) => {
    this.setState({
      selection: e,
    });

    const rowList = this.props.structureList?.structureAssignation.map(
      (rowData) => {
        const filterData = e.filter((id) => rowData.id == id);
        return { ...rowData, checked: filterData.length != 0 ? true : false };
      }
    );
    this.props.setStructureAssignation({ row: rowList });
  };

  handleCloses = () => {
    this.setState({ openPopUp: false });
  };

  onApprove = () => {
    const { showNotification, structureList } = this.props;
    const { selection } = this.state;
    if (selection.length === 0) {
      showNotification({
        msg: checkedListEmptyMsg,
        severity: "error",
      });
    } else {
      this.setState({ openPopUp: true });
      const rowList = structureList?.structureAssignation.map((rowData) => {
        const filterData = selection.filter((id) => rowData.id == id);
        return { ...rowData, checked: filterData.length != 0 ? true : false };
      });
      this.props.setStructureAssignation({ row: rowList });
    }
  };

  render() {
    const {
      dynamicMasterData,
      selection,
      openPopUp,
      DataToSet,
      fieldData,
      formErrors,
    } = this.state;
    const { stakeHolderTypeListData, structureList } = this.props;
    return (
      <>
        {/* <DynamicFormWithoutValidation
          showBackToList={false}
          dynamicMasterData={dynamicMasterData}
          showTitle={false}
          getListById={this.getListById}
          screenTitle={""}
          fieldMeta={AssignStructureJson.fieldMeta}
          showSaveBtnMain={true}
          showSaveBtn={false}
          padding={true}
          paddingTop={true}
          fieldData={fieldData}
          formErrors={formErrors}
          onDataChange={this.onDataChange}
          saveBtnText={"Search"}
          onFormSave={this.onSearchData}
        /> */}
        <DynamicFormWithoutSave
          dynamicMasterData={dynamicMasterData}
          showBackToList={true}
          getListById={this.getListById}
          showSaveBtn={true}
          showSaveBtnMain={false}
          showTitle={AssignStructureJson.showTitle}
          screenTitle={""}
          fieldMeta={AssignStructureJson.fieldMeta}
          showCancel={AssignStructureJson.showCancel}
          apiBaseURL={AssignStructureJson.apiBaseURL}
          showSaveNextBtn={false}
          onDataChange={this.onDataChange}
          saveBtnText={"Search"}
          onSave={this.onSearchData}
        />

        <CheckBoxSelectionTable
          selection={selection}
          onSelectionChange={this.getSelectedList}
          columns={tableColumns}
          rows={structureList.structureAssignation}
          isActionColActive={false}
        />
        <br />

        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          container
          justifyContent="right"
        >
          <ButtonCompo
            size="medium"
            type="submit"
            variant="contained"
            name={"Proceed"}
            onClick={this.onApprove}
          />
        </Grid>
        {openPopUp && (
          <>
            <AssignStructurePopUp
              handleCloses={this.handleCloses}
              open={open}
              formData={{}}
              columns={[
                {
                  title: "Sr. No.",
                  name: "index",
                  positionCenter: true,
                  align: "center",
                  showInscreen: true,
                  canSearch: true,
                },
                {
                  title: "Stock Holder",
                  name: "stockStakeHolderName",
                  positionCenter: false,
                  canSearch: true,
                  align: "left",
                  showInscreen: true,
                  canSearch: true,
                },
              ]}
              onFormSave={this.saveAssignStructure}
              rows={structureList?.structureAssignation.filter(
                (row) => row.checked
              )}
            // rows={[]}
            />
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  stakeHolderTypeListData: state.stockHolderAndProductConfiguration,
  stakeHolderTypeList: state.stockStakeHolderType,
  structureList: state.productRateStructure,
  areaList: state.area,
  regionList: state.region,
  stateList: state.states,
  zoneList: state.zone,
  districtList: state.district,
  talukaList: state.taluka,
});
const mapDispatchToProps = {
  getStockStakeHolderType,
  getStakeHolderNameByStakeholderType,
  setStructureAssignation,
  showLoador,
  showNotification,
  getStructureAssignation,
  getStateByRegion,
  getZoneByState,
  getRegion,
  getDistrictByZone,
  getTalukaByDistrict,
  getAreaByTaluka,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AssignStructure);
