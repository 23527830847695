import {
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../../components/withRouter";
import CreditNoteByBillWise from "./CreditNoteByBillWise";
import CreditNoteFormNew from "./CreditNoteFormNew";
import { showLoador, showNotification } from "../Landing/Landing.slice";

import { CreditNoteForOutletNewJson } from "../../DynamicFormsJson/Transaction/creditNoteForOutletNew";
import DynamicFormWithoutValidation from "../../components/dynamicscreens/DynamicFormWithoutValidation";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import endpoint from "../../config/endpoints";
import { apiGet } from "../../utils/api_service";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import CreditNoteClaimForOffer from "./CreditNoteClaimForOffer";
class CreditNote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "0",
      stockData: [],
    };
  }
  handleChange = (event, newValue) => {
    this.setState({
      value: newValue,
    });
  };

  async componentDidMount() {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await apiGet({
        url: endpoint.manageStockHeaderLatest + "/stock-by-current-date",
      }).then(({ data, success }) => {
        console.log(data);
        if (success) {
          this.setState({
            stockData: data.data,
          });
        } else {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  onCancel = () => {
    this.props.navigate(-1);
  };
  render() {
    const { value, stockData } = this.state;
    return (
      <>
        <Paper
          sx={{
            padding: 2,
          }}
          elevation={0}
        >
          <LandingScreenHeader
            screenTitle={"Credit Note"}
            showTable={false}
            showBackButton={true}
            showAddButton={false}
            onCancel={this.onCancel}
          />
          <br />

          {localStorage.getItem("radioPurchasereturnAndExpiryForRatestructure") &&
            localStorage.getItem("radioPurchasereturnAndExpiryForRatestructure") == 1 ?
            (
              <>
                <Grid item sm={12} xs={12} md={12} lg={12} container>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={value}
                      onChange={this.handleChange}
                      row
                      defaultValue={value}
                    >
                      <Grid item sm={2} xs={2} md={2} lg={2} container>
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label="CRN From Category-Wise"
                        />
                      </Grid>
                      <Grid item sm={2.1} xs={12} md={2.1} lg={2.1}>
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="CRN From Bill-Wise"
                        />
                      </Grid>

                      <Grid item sm={1.8} xs={12} md={1.8} lg={1.8}>
                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label="CRN From Offer"
                        />
                      </Grid>
                      <Grid item sm={2.4} xs={12} md={2.4} lg={2.4}>
                        <FormControlLabel
                          value="3"
                          control={<Radio />}
                          label="CRN From Expiry Product"
                        />
                      </Grid>
                      <Grid item sm={2.2} xs={12} md={2.2} lg={2.2}>
                        <FormControlLabel
                          value="4"
                          control={<Radio />}
                          label="CRN From Sampling"
                        />
                      </Grid>
                      <Grid item sm={1.5} xs={12} md={1.5} lg={1.5}>
                        <FormControlLabel
                          value="5"
                          control={<Radio />}
                          label="Direct CRN"
                        />
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </>
            ) : (
              <>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={value}
                    onChange={this.handleChange}
                    row
                    defaultValue={value}
                  >
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="CRN From Category-Wise"
                    />
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="CRN From Bill-Wise"
                    />
                  </RadioGroup>
                </FormControl>
              </>
            )}


          {value == "0" || value == "3" || value == "5" ? (
            <CreditNoteFormNew crnType={value == "3" ? "1" : value} stockData={stockData} flagToSave={value} />
          ) : value == "4" || value == "1" ? (
            <CreditNoteByBillWise crnType={value} />
          ) : (
            <CreditNoteClaimForOffer flagToSave={value} />
          )}
        </Paper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  showLoador,
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CreditNote);
