import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
    centralizedRate: [],
    centralStructureById: {},
    centralStructureByIdDetail: [],
};
let URL = endpoints.centralRateStructureHeader;
const centralizedRateSlice = createSlice({
    name: "centralizedRateStruct",
    initialState,
    reducers: {
        centralizedRateSuccess: (state = cloneDeep(initialState), action) => {
            const { row } = action.payload;
            return {
                ...state,
                centralizedRate: row,

            };
        },

        centralStructureByIdSuccess: (state = cloneDeep(initialState), action) => {
            const { row } = action.payload;
            return {
                ...state,
                centralStructureById: row,
            };
        },

        centralStructureByIdDetailSuccess: (state = cloneDeep(initialState), action) => {
            const { row } = action.payload;
            return {
                ...state,
                centralStructureByIdDetail: row,
            };
        },

        resetState: (state = cloneDeep(initialState), action) => {
            return {
                ...state,
                ...initialState,
            };
        },
    },
});

export const {
    centralizedRateSuccess,
    centralStructureByIdSuccess,
    centralStructureByIdDetailSuccess,
    resetState } = centralizedRateSlice.actions;

export default centralizedRateSlice.reducer;

export const getCentralizedRateStructure = () => async (dispatch) => {
    try {
        const response = apiGet({
            url: URL + "/all-header",
        }).then(({ data, success }) => {
            if (success) {
                const row = data.data.map((centralizedRateStructureObject, index) => {
                    let centralizedRateStructureData = {
                        index: index + 1,
                        id: centralizedRateStructureObject.id === null ? "" : centralizedRateStructureObject.id,
                        name: centralizedRateStructureObject.name === null ? "" : centralizedRateStructureObject.name,
                        outletCount: centralizedRateStructureObject.outletCount === null ? "" : centralizedRateStructureObject.outletCount,
                        isActive: centralizedRateStructureObject.isActive === null ? "" : centralizedRateStructureObject.isActive
                    };
                    return centralizedRateStructureData;
                });
                dispatch(centralizedRateSuccess({ row }));
                return { response: row, success };
            }
            return { response: [], success };
        });
        return response;
    } catch (e) {
        return console.error(e.message);
    }
};


export const getCentralizedRateStructureById = ({ id }) => async (dispatch) => {
    try {
        const response = apiGet({
            url: URL + "/by-id?centralRateStructureHeaderId=" + id,
        }).then(({ data, success }) => {
            if (success) {
                let byIdDataObj = data.data;
                let byIdData = {
                    id: byIdDataObj.id === null ? "" : byIdDataObj.id,
                    name:
                        byIdDataObj.name === null
                            ? ""
                            : byIdDataObj.name,
                    structureDate:
                        byIdDataObj.structureDate === null ? "" : byIdDataObj.structureDate,
                    isActive:
                        byIdDataObj.isActive === null ? "" : byIdDataObj.isActive,
                    extra1:
                        byIdDataObj.extra1 === null ? "" : byIdDataObj.extra1,
                    extra2:
                        byIdDataObj.extra2 === null ? "" : byIdDataObj.extra2,
                    centralRateStructureDetail: byIdDataObj.centralRateStructureDetail === null
                        ? ""
                        : byIdDataObj.centralRateStructureDetail.map((dataObj, index) => {
                            let detailData = {
                                index: index + 1,
                                id: dataObj.id !== null ? dataObj.id : "",
                                productId:
                                    dataObj.product !== null && dataObj.product.id !== null ? dataObj.product.id : "",
                                name: dataObj.product !== null && dataObj.product.name ? dataObj.product.name : "",
                                rateForCentralizedStructure:
                                    dataObj.rate !== null ? dataObj.rate : "",
                                uomType: dataObj.uomType !== null ? dataObj.uomType : "",
                                entityType: dataObj.entityType !== null ? dataObj.entityType : "",
                            }
                            return detailData;
                        })

                };
                dispatch(centralStructureByIdSuccess({ row: byIdData }));
                dispatch(centralStructureByIdDetailSuccess({ row: byIdData.centralRateStructureDetail }));
                return { response: byIdData, success };
            }
            return { response: {}, success };
        });
        return response;
    } catch (e) {
        return console.error(e.message);
    }
};

export const setCentralizedRateStructureByIdDetails =
    ({ row }) =>
        async (dispatch) => {
            dispatch(centralStructureByIdDetailSuccess({ row }));
        };



