import {
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../components/Comman/Button";
import BasicDatePicker from "../../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../../components/Comman/Label";
import LandingScreenHeader from "../../../components/Comman/LandingScreenHeader";
import RTTable from "../../../components/Comman/RT/RTTable";
import { TextFieldCompo } from "../../../components/Comman/TextFieldCompo";
import { DarkBlue, redColor, titleColor } from "../../../config/ColorObj";
import endpoint from "../../../config/endpoints";
import {
  applicableAmountGreaterThanZeroMsg,
  applicableAmountMsg,
  areaMsg,
  deleteWarningMsg,
  descriptionMsg,
  discountAmountErrorMsg,
  discountAmountGreaterThanZeroMsg,
  discountAmountMsg,
  discountPercentageErrorMsg,
  discountPercentageGreaterThanZeroMsg,
  discountPercentageMsg,
  districtMsg,
  flatAmountMsg,
  flatAmtGreaterThanZeroMsg,
  fromdateMsg,
  levelwiseTypeMsg,
  maxQtyGreaterThanZeroMsg,
  maxQtyMsg,
  minQtyGreaterThanZeroMsg,
  minQtyMsg,
  nameMsg,
  noInternetMsg,
  percentageMsg,
  productByCategoryMsg,
  productMsg,
  qtyForItemMsg,
  qtyGreaterThanZeroMsg,
  qtyMsg,
  regionMsg,
  saveWarningMsg,
  serverMsg,
  stackHolderTypeMsg,
  stateMsg,
  talukaMsg,
  todateMsg,
  unitValueGreaterThanZeroMsg,
  unitValueMsg,
  updateData,
  zoneMsg,
} from "../../../config/messageconstant";
import {
  getActiveProduct,
  getActiveProductByCategory,
  getActiveProductByCategoryForAll,
} from "../../../Slice/product.slice";
import { getStockStakeHolderType } from "../../../Slice/stockStakeHolderType.slice";
import { getRegionNew } from "../../../Slice/region.slice";
import { getStateByRegion } from "../../../Slice/state.slice";
import { getZoneByState } from "../../../Slice/zone.slice";
import { getDistrictByZone } from "../../../Slice/district.slice";
import { getTalukaByDistrict } from "../../../Slice/taluka.slice";
import { getAreaByTalukaPostBodyUpdated } from "../../../Slice/area.slice";
import { apiPost } from "../../../utils/api_service";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import NewMultipleSelect from "../../../components/Comman/NewMultipleSelect";
import MultipleSelect from "../../../components/Comman/MultipleSelect";

const OfferForm = ({
  showLoador,
  showNotification,
  getStockStakeHolderType,
  getRegionNew,
  getStateByRegion,
  getZoneByState,
  getDistrictByZone,
  getTalukaByDistrict,
  getAreaByTalukaPostBodyUpdated,
  stakeHolderTypeList,
  regionList,
  stateList,
  zoneList,
  districtList,
  talukaList,
  areaList,
  getActiveProduct,
  productList,
  getActiveProductByCategory,
  getActiveProductByCategoryForAll,
  activeProductList,
}) => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [fromdate, setFromdate] = useState("");
  const [todate, setTodate] = useState("");
  const [type, setType] = useState("0");
  const [isLog, setIsLog] = useState(true);
  const [rangeVisible, setRangeVisible] = useState(false);
  const [rangeFromdate, setRangeFromdate] = useState("");
  const [rangeTodate, setRangeTodate] = useState("");
  const [offerFor, setOfferFor] = useState("0");
  const [calculatedBy, setCalculatedBy] = useState("0");
  const [offerType, setOfferType] = useState("0");
  const [stakeholderVisible, setStakeholderVisible] = useState(false);
  const [stakeholderType, setStakeholderType] = useState("");
  const [region, setRegion] = useState("");
  const [state, setStates] = useState("");
  const [zone, setZone] = useState([]);
  const [district, setDistrict] = useState([]);
  const [taluka, setTaluka] = useState([]);
  const [area, setArea] = useState([]);
  const [areaListOfAll, setAreaListOfAll] = useState([]);
  const [level, setLevel] = useState("0");
  const [isOfferFor, setIsOfferFor] = useState("1");
  const [levelWiseType, setlevelWiseType] = useState("");
  const [levelWiseTypeList, setlevelWiseTypeList] = React.useState([
    {
      id: 0,
      name: "Flat Percentage (%)",
    },
    {
      id: 1,
      name: "Flat Amount",
    },
    {
      id: 2,
      name: "Percentage (%) Upto Given Amount",
    },
    {
      id: 3,
      name: "Bill-Wise Free Item",
    },
    {
      id: 6,
      name: "Free Item On Item",
    },
  ]);
  const [applicableAmount, setApplicableAmount] = useState("");
  const [flatAmount, setFlatAmount] = useState("");
  const [discountAmount, setDiscountAmount] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState("");
  const [product, setProduct] = useState("");
  const [productMultiple, setProductMultiple] = useState([]);
  const [qty, setQty] = useState("");
  const [maxValue, setMaxValue] = useState("");
  const [productByCategory, setProductByCategory] = useState("");
  const [qtyForItem, setQtyForItem] = useState("");
  const [percentage, setPercentage] = useState("");
  const [rowList, setRowList] = useState([]);
  const [listToSave, setListToSave] = useState([]);
  const [formErrors, setFormErrors] = React.useState({});
  const [formErrors1, setFormErrors1] = React.useState({});
  const [moduleObjDataObj, setModuleDataObj] = React.useState({});
  const updateLevelWiseType = (level, offerType) => {
    if (level === "1" && offerType === "1") {
      setlevelWiseTypeList([
        { id: 8, name: "Flat Amount Off" },
        { id: 9, name: "Per Unit Value" },
      ]);
    } else if (level === "0" && offerType === "1") {
      setlevelWiseTypeList([
        { id: 0, name: "Flat Percentage (%)" },
        { id: 1, name: "Flat Amount" },
      ]);
    } else if (level === "0") {
      setlevelWiseTypeList([
        { id: 0, name: "Flat Percentage (%)" },
        { id: 1, name: "Flat Amount" },
        { id: 2, name: "Percentage (%) Upto Given Amount" },
        { id: 3, name: "Bill-Wise Free Item" },
        { id: 6, name: "Free Item On Item" },
      ]);
    } else {
      setlevelWiseTypeList([
        { id: 4, name: "Percentage (%) Wise" },
        { id: 5, name: "Item-Wise Free Item" },
        { id: 7, name: "Free Item On Item" },
      ]);
    }
  };
  const changeHandler = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      setName(value);
    } else if (name === "description") {
      setDescription(value);
    } else if (name === "type") {
      if (value === "0") {
        setRangeVisible(false);
      } else {
        setRangeVisible(true);
        setRangeFromdate("");
        setRangeTodate("");
      }
      setType(value);
    } else if (name === "rangeFromdate") {
      setRangeFromdate(value);
    } else if (name === "rangeTodate") {
      setRangeTodate(value);
    } else if (name === "offerFor") {
      if (value === "0") {
        setStakeholderVisible(false);
      } else {
        setStakeholderVisible(true);
        setStakeholderType("");
      }
      setOfferFor(value);
    } else if (name === "offerType") {
      setOfferType(value);
      updateLevelWiseType(level, value);
    } else if (name === "calculatedBy") {
      setCalculatedBy(value);
    } else if (name === "level") {
      setLevel(value);
      setRowList([]);
      updateLevelWiseType(value, offerType);
    } else if (name === "applicableAmount") {
      setApplicableAmount(value);
    } else if (name === "discountAmount") {
      if (value <= 0 || value >= "applicableAmount") {
        setFormErrors({
          ...formErrors,
          discountAmountError: "Invalid Amount",
        });
      } else {
        setFormErrors({
          ...formErrors,
          discountAmountError: "",
        });
      }
      setDiscountAmount(value);
    } else if (name === "discountPercentage") {
      if (value == 0 || value > 100) {
        setFormErrors({
          ...formErrors,
          discountPercentageError: "Discount Percentage is invalid",
        });
      } else {
        setFormErrors({
          ...formErrors,
          discountPercentageError: "",
        });
      }
      setDiscountPercentage(value);
    } else if (name === "qty") {
      setQty(value);
    } else if (name === "minQty") {
      setQty(value);
    } else if (name === "maxValue") {
      setMaxValue(value);
    } else if (name === "qtyForItem") {
      setQtyForItem(value);
    } else if (name === "flatAmount") {
      setFlatAmount(value);
    } else if (name === "percentage") {
      if (value == 0 || value > 100) {
        setFormErrors({
          ...formErrors,
          percentageError: "Discount Percentage Invalid",
        });
      } else {
        setFormErrors({
          ...formErrors,
          percentageError: "",
        });
      }
      setPercentage(value);
    }
  };
  const changeDate = (name) => (date) => {
    if (name === "fromdate") {
      setFromdate(date);
    } else if (name === "todate") {
      setTodate(date);
    } else if (name === "rangeFromdate") {
      setRangeFromdate(date);
    } else if (name === "rangeTodate") {
      setRangeTodate(date);
    }
  };
  const changeHandlerForDropdown = (name, dropdown) => {
    if (name === "stakeholderType") {
      setStakeholderType(dropdown);
    } else if (name === "region") {
      setRegion(dropdown);
      showLoador({ loador: true });
      getStateByRegion({ regionIds: dropdown }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else if (name === "state") {
      setStates(dropdown);
      showLoador({ loador: true });
      getZoneByState({ stateIds: dropdown }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else if (name === "levelWiseType") {
      setDiscountPercentage("");
      setDiscountAmount("");
      setProductByCategory("");
      setQtyForItem("");
      setPercentage("");
      setlevelWiseType(dropdown);
    } else if (name === "product") {
      const filterData = productList.activeProduct.filter(
        (row) => row.id == dropdown
      );
      setProduct(dropdown);
      if (filterData.length != 0) {
        showLoador({ loador: true });
        getActiveProductByCategory({ categoryId: filterData[0].category }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
    } else if (name === "productByCategory") {
      setProductByCategory(dropdown);
    }
  };
  const checkValidation = () => {
    let formErrors = {};
    let formIsValid = true;

    if (name === "") {
      formIsValid = false;
      formErrors["nameError"] = nameMsg;
    }
    if (description === "") {
      formIsValid = false;
      formErrors["descriptionError"] = descriptionMsg;
    }
    if (fromdate === "") {
      formIsValid = false;
      formErrors["fromdateError"] = fromdateMsg;
    }
    if (todate === "") {
      formIsValid = false;
      formErrors["todateError"] = todateMsg;
    }
    if (rangeFromdate === "" && type === "1") {
      formIsValid = false;
      formErrors["rangeFromdateError"] = fromdateMsg;
    }
    if (rangeTodate === "" && type === "1") {
      formIsValid = false;
      formErrors["rangeTodateError"] = todateMsg;
    }
    if (
      (stakeholderType === "" || stakeholderType === null) &&
      offerFor === "1"
    ) {
      formIsValid = false;
      formErrors["stackHolderTypeError"] = stackHolderTypeMsg;
    }
    if (isOfferFor == 1 && (region === "" || region === null)) {
      formIsValid = false;
      formErrors["regionError"] = regionMsg;
    }
    if (isOfferFor == 1 && (state === "" || state === null)) {
      formIsValid = false;
      formErrors["stateError"] = stateMsg;
    }
    if (isOfferFor == 1 && (zone.length == 0 || zone === null)) {
      formIsValid = false;
      formErrors["zoneError"] = zoneMsg;
    }
    if (isOfferFor == 1 && (district.length == 0 || district === null)) {
      formIsValid = false;
      formErrors["districtError"] = districtMsg;
    }
    if (isOfferFor == 1 && (taluka.length == 0 || taluka === null)) {
      formIsValid = false;
      formErrors["talukaError"] = talukaMsg;
    }
    if (isOfferFor == 1 && (area.length == 0 || area === null)) {
      formIsValid = false;
      formErrors["areaError"] = areaMsg;
    }

    // if (levelWiseType === "" || levelWiseType === null) {
    //   formIsValid = false;
    //   formErrors["levelWiseTypeError"] = levelwiseTypeMsg;
    // }

    if (applicableAmount === "" && level === "0") {
      formIsValid = false;
      formErrors["applicableAmountError"] = applicableAmountMsg;
    }
    if (applicableAmount === "0") {
      formIsValid = false;
      formErrors["applicableAmountError"] = applicableAmountGreaterThanZeroMsg;
    }
    if (
      discountAmount === "" &&
      level === "0" &&
      (levelWiseType === 1 || levelWiseType === 2)
    ) {
      formIsValid = false;
      formErrors["discountAmountError"] = discountAmountMsg;
    }
    if (discountAmount === "0") {
      formIsValid = false;
      formErrors["discountAmountError"] = discountAmountGreaterThanZeroMsg;
    }
    if (
      discountAmount != "" &&
      applicableAmount !== "" &&
      +discountAmount >= +applicableAmount
    ) {
      formIsValid = false;
      formErrors["discountAmountError"] = discountAmountErrorMsg;
    }

    if (
      discountPercentage === "" &&
      level === "0" &&
      (levelWiseType === 0 || levelWiseType === 2)
    ) {
      formIsValid = false;
      formErrors["discountPercentageError"] = discountPercentageMsg;
    }
    if (discountPercentage === "0") {
      formIsValid = false;
      formErrors["discountPercentageError"] =
        discountPercentageGreaterThanZeroMsg;
    }
    if (discountPercentage >= 101) {
      formIsValid = false;
      formErrors["discountPercentageError"] = discountPercentageErrorMsg;
    }

    // if (
    //   product === "" &&
    //   (levelWiseType === 4 || levelWiseType === 5 || levelWiseType === 7)
    // ) {
    //   formIsValid = false;
    //   formErrors["productError"] = productMsg;
    // }
    if (qty === "" && (levelWiseType === 8 || levelWiseType === 9)) {
      formIsValid = false;
      formErrors["minQtyError"] = minQtyMsg;
    }
    if (qty === "0" && (levelWiseType === 8 || levelWiseType === 9)) {
      formIsValid = false;
      formErrors["minQtyError"] = minQtyGreaterThanZeroMsg;
    }
    if (maxValue === "" && (levelWiseType === 8 || levelWiseType === 9)) {
      formIsValid = false;
      formErrors["maxQtyError"] = maxQtyMsg;
    }
    if (maxValue === "0" && (levelWiseType === 8 || levelWiseType === 9)) {
      formIsValid = false;
      formErrors["maxQtyError"] = maxQtyGreaterThanZeroMsg;
    }
    if (
      qty === "" &&
      (levelWiseType === 4 || levelWiseType === 5 || levelWiseType === 7)
    ) {
      formIsValid = false;
      formErrors["qtyError"] = qtyMsg;
    }
    if (qty === "0") {
      formIsValid = false;
      formErrors["qtyError"] = qtyGreaterThanZeroMsg;
    }

    if ((levelWiseType === 8 || levelWiseType === 9) && flatAmount === "") {
      formIsValid = false;
      formErrors["flatAmountError"] =
        levelWiseType == "8" ? flatAmountMsg : unitValueMsg;
    }
    if (flatAmount === "0" && (levelWiseType === 8 || levelWiseType === 9)) {
      formIsValid = false;
      formErrors["flatAmountError"] =
        levelWiseType == "8"
          ? flatAmtGreaterThanZeroMsg
          : unitValueGreaterThanZeroMsg;
    }
    if (
      (levelWiseType === 8 || levelWiseType === 9) &&
      productMultiple.length == 0
    ) {
      formIsValid = false;
      formErrors["productMultipleError"] = productMsg;
    }
    setFormErrors(formErrors);
    return formIsValid;
  };

  const checkProductValidation = () => {
    let formErrors = {};
    let formIsValid = true;

    if (productByCategory === "") {
      formIsValid = false;
      formErrors["productByCategoryError"] = productByCategoryMsg;
    }
    if (percentage === "" && level === "1" && levelWiseType === 4) {
      formIsValid = false;
      formErrors["percentageError"] = percentageMsg;
    }

    if (
      levelWiseType === 3 ||
      levelWiseType === 5 ||
      levelWiseType === 6 ||
      levelWiseType === 7
    ) {
      if (qtyForItem === "") {
        formIsValid = false;
        formErrors["qtyForItemError"] = qtyForItemMsg;
      }
      if (qtyForItem == "0") {
        formIsValid = false;
        formErrors["qtyForItemError"] = qtyGreaterThanZeroMsg;
      }
    }
    setFormErrors(formErrors);
    return formIsValid;
  };

  const addProductList = (e, levelWiseType) => {
    if (checkProductValidation()) {
      let product = [];

      if (
        levelWiseType == "3" ||
        levelWiseType == "6" ||
        levelWiseType == "7"
      ) {
        product = productList.activeProduct;
        product = activeProductList.activeProduct.filter(
          (row) => row.id == productByCategory
        );
      } else {
        product = activeProductList.activeProductByCategory.filter(
          (row) => row.id == productByCategory
        );
      }

      let objectToSave = {
        index: listToSave.length + 1,
        product: product.length !== 0 ? product[0].name : "",
        quantity: qtyForItem === "" ? "-" : qtyForItem,
        percentage: percentage === "" ? "-" : percentage,
        productId: product.length !== 0 ? product[0].id : "",
      };

      listToSave.push(objectToSave);
      setRowList([...listToSave]);
      setProductByCategory("");
      setQtyForItem("");
      setPercentage("");
    }
  };

  const saveOfferData = () => {
    let offerDataObject = {
      name: name,
      discription: description,
      fromDate: fromdate === "" ? null : fromdate.format("DD-MM-YYYY"),
      toDate: todate === "" ? null : todate.format("DD-MM-YYYY"),
      type: type,
      fromRangeDate:
        rangeFromdate === "" ? null : rangeFromdate.format("DD-MM-YYYY"),
      toRangeDate: rangeTodate === "" ? null : rangeTodate.format("DD-MM-YYYY"),
      offerFor: offerFor === "" ? null : offerFor,
      stockStakeHolderType:
        stakeholderType === ""
          ? null
          : {
            id: stakeholderType,
          },
      level: level === "" ? null : level,
      levelWiseType: levelWiseType === "" ? null : levelWiseType,
      applicableAmount: applicableAmount === "" ? null : applicableAmount,
      discountAmount: discountAmount === "" ? null : discountAmount,
      discountPercentage: discountPercentage === "" ? null : discountPercentage,
      productIds: productMultiple === "" ? null : productMultiple.join(","),
      regionId: isOfferFor == 1 ? (region === "" ? null : region) : 0,
      stateId: isOfferFor == 1 ? (state === "" ? null : state) : 0,
      zoneIds: isOfferFor == 1 ? (zone === "" ? null : zone.join(",")) : 0,
      districtIds: isOfferFor == 1 ? (district === "" ? null : district.join(",")) : 0,
      talukaIds: isOfferFor == 1 ? (taluka === "" ? null : taluka.join(",")) : 0,
      areaIds: isOfferFor == 1 ? (area === "" ? null : area.join(",")) : 0,
      qty: qty === "" ? null : qty,
      maxValue: maxValue === "" ? null : maxValue,
      flatAmount: flatAmount === "" ? null : flatAmount,
      calculatedBy: calculatedBy === "" ? null : calculatedBy,
      offerType: offerType === "" ? null : offerType,
      isActive: "1",
      offerDetails:
        rowList.length !== 0
          ? rowList.map((offerObject) => {
            let offerData = {
              product: {
                id: offerObject.productId,
              },
              qty: offerObject.quantity == "-" ? null : offerObject.quantity,
              percentage:
                offerObject.percentage == "-" ? null : offerObject.percentage,
            };
            return offerData;
          })
          : [],
    };

    if (navigator.onLine) {
      if (checkValidation()) {
        console.log(offerDataObject);
        swal({
          title: "Are you sure?",
          text: saveWarningMsg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            showLoador({ loador: true });
            apiPost({
              url: endpoint.offer,
              postBody: offerDataObject,
            }).then(({ data, success }) => {
              showLoador({ loador: false });
              if (success) {
                if (isLog) {
                  if (data && data.data !== null) {
                    let updatedJsonToSend = JSON.stringify(data.data);

                    let dataToSaveForMLog = {
                      module: {
                        moduleId: moduleObjDataObj.moduleId,
                      },
                      subModule: {
                        subModuleId: moduleObjDataObj.subModuleId,
                      },
                      operationType: 0,
                      type: 0,
                      operationPerformId: data.data.id,
                      updatedJson: updatedJsonToSend,
                    };

                    showLoador({ loador: true });
                    apiPost({
                      url: endpoint.mlog,
                      postBody: dataToSaveForMLog,
                    }).then(({ success }) => {
                      showLoador({ loador: false });
                      if (success) {
                        navigate(-1);
                      } else {
                        showNotification({
                          msg: msgConstant.saveFailedMsg,
                          severity: "error",
                        });
                      }
                    });
                  }
                }
              } else {
                showNotification({ msg: serverMsg, severity: "error" });
              }
            });
          }
        });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  const rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: deleteWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const rowListData = rowList.filter((row) => row.index != rowData.index);
        const row = rowListData.map((rowListDataObj, index) => {
          return { ...rowListDataObj, index: index + 1 };
        });
        setRowList(row);
        setListToSave(row);
      }
    });
  };

  useEffect(() => {
    if (navigator.onLine) {
      showLoador({ loador: true });
      getStockStakeHolderType({ userTypeIds: 2 }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

      showLoador({ loador: true });
      getRegionNew().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

      showLoador({ loador: true });
      getActiveProduct().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

      const moduleObjData = localStorage.getItem("moduleObj");
      const moduleObjDataObj =
        moduleObjData != null ? JSON.parse(moduleObjData) : {};

      setModuleDataObj(moduleObjDataObj);
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }, []);
  const onCancel = () => {
    navigate(-1);
  };
  const onMultiSelectChange = (dataKey, masterName) => (value, name) => {
    if (name === "zone") {
      setZone(value);
      showLoador({ loador: true });
      getDistrictByZone({ zoneIds: value }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else if (name === "district") {
      setDistrict(value);
      showLoador({ loador: true });
      getTalukaByDistrict({ districtIds: value }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else if (name === "taluka") {
      setTaluka(value);
      let talukaIdsByPost = {
        talukaIds: value,
      };
      showLoador({ loador: true });
      getAreaByTalukaPostBodyUpdated({ talukaIds: talukaIdsByPost }).then(
        ({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        }
      );
    } else if (name === "area") {
      setArea(value);
    }

    if (name === "productMultiple") {
      let valueList = value.map((vauleObj) => {
        const filterData = productList.activeProduct.filter(
          (row) => row.id == vauleObj
        );
        if (filterData.length !== 0) {
          return filterData[0].category;
        }
      });

      setProductMultiple(value);
      const categoryIdsJS = {
        categoryIds: valueList,
      };

      if (valueList != "") {
        showLoador({ loador: true });
        getActiveProductByCategoryForAll({ categoryIds: categoryIdsJS }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
    }
  };

  const multiSelectHandler = (value, name) => {
    const isAllSelected = value.includes("all");
    const filterDataWithoutAll = value.filter((rowData) => rowData !== "all");
    const filterValue = value.filter((rowValue) => rowValue === "all");

    if (filterValue.length !== 0) {
      if (name === "zone") {
        if (isAllSelected) {
          if (filterDataWithoutAll.length === zoneList.zoneByState.length) {
            setZone([]);
          } else {
            const zoneListAll = zoneList.zoneByState
              .filter((data) => data.id !== "all")
              .map((data) => data.id);

            setZone(zoneListAll);
            showLoador({ loador: true });

            getDistrictByZone({ zoneIds: zoneListAll }).then(({ success }) => {
              showLoador({ loador: false });
              if (!success) {
                showNotification({ msg: serverMsg, severity: "error" });
              }
            });
          }
        } else {
          setZone(filterDataWithoutAll);
        }
      } else if (name === "district") {
        if (isAllSelected) {
          if (
            filterDataWithoutAll.length === districtList.districtByZone.length
          ) {
            setDistrict([]);
          } else {
            const districtListAll = districtList.districtByZone
              .filter((data) => data.id !== "all")
              .map((data) => data.id);

            setDistrict(districtListAll);
            showLoador({ loador: true });

            getTalukaByDistrict({ districtIds: districtListAll }).then(
              ({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                  showNotification({ msg: serverMsg, severity: "error" });
                }
              }
            );
          }
        } else {
          setDistrict(filterDataWithoutAll);
        }
      } else if (name === "taluka") {
        if (isAllSelected) {
          if (
            filterDataWithoutAll.length === talukaList.talukaByDistrict.length
          ) {
            setTaluka([]);
          } else {
            const talukaListAll = talukaList.talukaByDistrict
              .filter((data) => data.id !== "all")
              .map((data) => data.id);

            setTaluka(talukaListAll);
            let talukaIdsByPost = {
              talukaIds: talukaListAll,
            };
            showLoador({ loador: true });

            getAreaByTalukaPostBodyUpdated({ talukaIds: talukaIdsByPost }).then(
              ({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                  showNotification({ msg: serverMsg, severity: "error" });
                }
              }
            );
          }
        } else {
          setTaluka(filterDataWithoutAll);
        }
      } else if (name === "area") {
        if (isAllSelected) {
          if (filterDataWithoutAll.length === areaList.areaByTaluka.length) {
            setArea([]);
          } else {
            const areaListALL = areaList.areaByTaluka
              .filter((data) => data.id !== "all")
              .map((data) => data.id);

            setArea(areaListALL);
          }
        } else {
          setArea(filterDataWithoutAll);
        }
      }
      if (name === "productMultiple") {
        if (isAllSelected) {
          if (
            filterDataWithoutAll.length === productList.activeProduct.length
          ) {
            setProductMultiple([]);
          } else {
            const allProductList = productList.activeProduct
              .filter((data) => data.id !== "all")
              .map((data) => data.id);

            setProductMultiple(allProductList);
          }
        } else {
          setProductMultiple(filterDataWithoutAll);
        }
      }
    } else {
      if (name === "zone") {
        setZone(value);
        if (value != "") {
          showLoador({ loador: true });
          getDistrictByZone({ zoneIds: value }).then(({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          });
        }
      } else if (name === "district") {
        setDistrict(value);
        if (value != "") {
          showLoador({ loador: true });
          getTalukaByDistrict({ districtIds: value }).then(({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          });
        }
      } else if (name === "taluka") {
        setTaluka(value);
        if (value != "") {
          let talukaIdsByPost = {
            talukaIds: value,
          };
          showLoador({ loador: true });
          getAreaByTalukaPostBodyUpdated({ talukaIds: talukaIdsByPost }).then(
            ({ success }) => {
              showLoador({ loador: false });
              if (!success) {
                showNotification({ msg: serverMsg, severity: "error" });
              }
            }
          );
        }
      } else if (name === "area") {
        setArea(value);
      }
      if (name === "productMultiple") {
        setProductMultiple(value);
      }
    }
  };


  const handleChange = (event) => {
    setIsOfferFor(event.target.value);
  };

  return (
    <>
      <Paper
        sx={{
          p: 2,
          borderRadius: 2,
        }}
        elevation={0}
      >
        <LandingScreenHeader
          screenTitle={"Offer"}
          showTable={false}
          showBackButton={true}
          showAddButton={false}
          onCancel={onCancel}
        />
        <div>
          <br></br>

          {localStorage.getItem("showPanIndiaOffer") &&
            localStorage.getItem("showPanIndiaOffer") == 1 &&
            (
              <>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="space-between"
                >
                  <Grid item xs={12} md={2.5} sm={2.5}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16, color: redColor }}
                      label="* "
                    />
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16, color: titleColor }}
                      label="Is Offer For :"
                    />
                  </Grid>
                  <Grid item xs={12} sm={9.5} md={9.5}>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="isOfferFor"
                      value={isOfferFor}
                      onChange={handleChange}
                      defaultValue={isOfferFor}
                      row
                    >
                      <FormControlLabel value={1} control={<Radio />} label="Area Wise" />
                      <FormControlLabel value={0} control={<Radio />} label="Pan India" />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </>
            )}

          {isOfferFor == 1 && (
            <>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="space-between"
              >
                <Grid
                  item
                  sm={6}
                  xs={8}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} md={5} sm={5}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16, color: redColor }}
                      label="* "
                    />
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16, color: titleColor }}
                      label="Region:"
                    />
                  </Grid>
                  <Grid item xs={12} md={7} sm={7}>
                    <AutoComplete
                      keyColName={"id"}
                      placeHolder="Select Region"
                      value={region}
                      name={"region"}
                      options={regionList?.region}
                      errorText={
                        formErrors["regionError"] ? formErrors["regionError"] : ""
                      }
                      isError={
                        formErrors["regionError"] ? formErrors["regionError"] : ""
                      }
                      onChange={changeHandlerForDropdown}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={6}
                  xs={8}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} md={5} sm={5}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16, color: redColor }}
                      label="* "
                    />
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16, color: titleColor }}
                      label="State:"
                    />
                  </Grid>
                  <Grid item xs={12} md={7} sm={7}>
                    <AutoComplete
                      keyColName={"id"}
                      placeHolder="Select State"
                      value={state}
                      name={"state"}
                      options={stateList?.stateByRegion}
                      errorText={
                        formErrors["stateError"] ? formErrors["stateError"] : ""
                      }
                      isError={
                        formErrors["stateError"] ? formErrors["stateError"] : ""
                      }
                      onChange={changeHandlerForDropdown}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <br />
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="space-between"
              >
                <Grid
                  item
                  sm={6}
                  xs={8}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} md={5} sm={5}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16, color: redColor }}
                      label="* "
                    />
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16, color: titleColor }}
                      label="Zone:"
                    />
                  </Grid>
                  <Grid item xs={12} md={7} sm={7}>
                    {/* <NewMultipleSelect
                  er={formErrors["zoneError"]
                    ? formErrors["zoneError"]
                    : ""}
                  label={"Select Zone"}
                  name1="zone"
                  value1={zone}
                  names={zoneList?.zoneByState}
                  onChange={onMultiSelectChange(zone)}
                /> */}
                    <MultipleSelect
                      er={formErrors["zoneError"] ? formErrors["zoneError"] : ""}
                      label={"Select Zone"}
                      name1="zone"
                      value1={zone}
                      names={[{ id: "all", name: "All" }, ...zoneList?.zoneByState]}
                      onChange={multiSelectHandler}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={6}
                  xs={8}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} md={5} sm={5}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16, color: redColor }}
                      label="* "
                    />
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16, color: titleColor }}
                      label="District:"
                    />
                  </Grid>
                  <Grid item xs={12} md={7} sm={7}>
                    <MultipleSelect
                      er={
                        formErrors["districtError"]
                          ? formErrors["districtError"]
                          : ""
                      }
                      label={"Select District"}
                      name1="district"
                      value1={district}
                      names={[
                        { id: "all", name: "All" },
                        ...districtList?.districtByZone,
                      ]}
                      onChange={multiSelectHandler}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* <br /> */}
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="space-between"
              >
                <Grid
                  item
                  sm={6}
                  xs={8}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} md={5} sm={5}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16, color: redColor }}
                      label="* "
                    />
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16, color: titleColor }}
                      label="Taluka:"
                    />
                  </Grid>
                  <Grid item xs={12} md={7} sm={7}>
                    <MultipleSelect
                      er={
                        formErrors["talukaError"] ? formErrors["talukaError"] : ""
                      }
                      label={"Select Taluka"}
                      name1="taluka"
                      value1={taluka}
                      names={[
                        { id: "all", name: "All" },
                        ...talukaList?.talukaByDistrict,
                      ]}
                      onChange={multiSelectHandler}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={6}
                  xs={8}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} md={5} sm={5}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16, color: redColor }}
                      label="* "
                    />
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16, color: titleColor }}
                      label="Area:"
                    />
                  </Grid>
                  <Grid item xs={12} md={7} sm={7}>
                    <MultipleSelect
                      er={formErrors["areaError"] ? formErrors["areaError"] : ""}
                      label={"Select Area"}
                      name1="area"
                      value1={area}
                      names={[{ id: "all", name: "All" }, ...areaList.areaByTaluka]}
                      onChange={multiSelectHandler}
                    />
                  </Grid>
                </Grid>
              </Grid>

            </>
          )}

          {/* <br /> */}
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="space-between"
          >
            <Grid item xs={12} md={2.5} sm={2.5}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16, color: redColor }}
                label="* "
              />
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16, color: titleColor }}
                label="Name :"
              />
            </Grid>
            <Grid item xs={12} md={9.5} sm={9.5}>
              <TextFieldCompo
                placeHolder={"Name"}
                color="primary"
                name="name"
                size="small"
                value={name}
                fullWidth
                onChange={changeHandler}
                errorText={
                  formErrors["nameError"] ? formErrors["nameError"] : ""
                }
              />
            </Grid>
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="space-evenly"
          >
            <Grid item xs={12} md={2.5} sm={2.5}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16, color: redColor }}
                label="* "
              />
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16, color: titleColor }}
                label="Description :"
              />
            </Grid>
            <Grid item xs={12} md={9.5} sm={9.5}>
              <TextFieldCompo
                placeHolder={"Description"}
                color="primary"
                name="description"
                size="small"
                value={description}
                fullWidth
                onChange={changeHandler}
                errorText={
                  formErrors["descriptionError"]
                    ? formErrors["descriptionError"]
                    : ""
                }
              />
            </Grid>
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="space-between"
          >
            <Grid
              item
              sm={6}
              xs={7}
              md={8}
              lg={6}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} md={5} sm={5}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: redColor }}
                  label="* "
                />
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: titleColor }}
                  label="From Date :"
                />
              </Grid>
              <Grid item xs={12} md={7} sm={7}>
                <BasicDatePicker
                  color="primary"
                  name="fromdate"
                  size="small"
                  value={fromdate}
                  disabled={false}
                  fullWidth
                  onChange={changeDate("fromdate")}
                  errorText={
                    formErrors["fromdateError"]
                      ? formErrors["fromdateError"]
                      : ""
                  }
                  isError={
                    formErrors["fromdateError"]
                      ? formErrors["fromdateError"]
                      : ""
                  }
                />
              </Grid>
            </Grid>
            <Grid
              item
              sm={6}
              xs={8}
              md={6}
              lg={6}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} md={5} sm={5}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: redColor }}
                  label="* "
                />
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: titleColor }}
                  label="To Date :"
                />
              </Grid>
              <Grid item xs={12} md={7} sm={7}>
                <BasicDatePicker
                  color="primary"
                  name="todate"
                  size="small"
                  value={todate}
                  minDate={fromdate}
                  disabled={false}
                  fullWidth
                  onChange={changeDate("todate")}
                  errorText={
                    formErrors["todateError"] ? formErrors["todateError"] : ""
                  }
                  isError={
                    formErrors["todateError"] ? formErrors["todateError"] : ""
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <br />
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="space-between"
          >
            <Grid
              item
              sm={6}
              xs={8}
              md={6}
              lg={6}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={5} md={5}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: redColor }}
                  label="* "
                />
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: titleColor }}
                  label="Offer Type:"
                />
              </Grid>
              <Grid item xs={12} sm={7} md={7}>
                <RadioGroup
                  row
                  name={"offerType"}
                  id={"offerType"}
                  value={offerType}
                  onChange={changeHandler}
                  size="small"
                >
                  <FormControlLabel
                    value={"0"}
                    control={<Radio />}
                    label={"Bill-Wise"}
                  />
                  <FormControlLabel
                    value={"1"}
                    control={<Radio />}
                    label={"Credit Note-Wise"}
                  />
                </RadioGroup>
              </Grid>
            </Grid>
            {offerFor == "0" && offerType == "1" && (
              <Grid
                item
                sm={6}
                xs={8}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} md={5} sm={5}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label="* "
                  />
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: titleColor }}
                    label="Calculated By :"
                  />
                </Grid>
                <Grid item xs={12} md={7} sm={7}>
                  <RadioGroup
                    row
                    name={"calculatedBy"}
                    id={"calculatedBy"}
                    value={calculatedBy}
                    onChange={changeHandler}
                    size="small"
                  >
                    <FormControlLabel
                      value={"0"}
                      control={<Radio />}
                      label={"Secondary QTY"}
                    />
                    <FormControlLabel
                      value={"1"}
                      control={<Radio />}
                      label={"Primary QTY"}
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="space-between"
          >
            <Grid item xs={12} md={2.5} sm={2.5}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16, color: redColor }}
                label="* "
              />
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16, color: titleColor }}
                label="Type :"
              />
            </Grid>
            <Grid item xs={12} sm={9.5} md={9.5}>
              <RadioGroup
                row
                aria-label={"Type"}
                name={"type"}
                id={"type"}
                value={type}
                onChange={changeHandler}
                size="small"
              >
                <FormControlLabel
                  value={"0"}
                  control={<Radio />}
                  label={"Regular"}
                />
                <FormControlLabel
                  value={"1"}
                  control={<Radio />}
                  label={"Date Range"}
                />
              </RadioGroup>
            </Grid>
          </Grid>
          {rangeVisible && (
            <>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="space-between"
              >
                <Grid
                  item
                  sm={6}
                  xs={8}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} md={5} sm={5}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16, color: redColor }}
                      label="* "
                    />
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16, color: titleColor }}
                      label="From Date :"
                    />
                  </Grid>
                  <Grid item xs={12} md={7} sm={7}>
                    <BasicDatePicker
                      color="primary"
                      name="rangeFromdate"
                      size="small"
                      value={rangeFromdate}
                      disabled={false}
                      minDate={fromdate}
                      maxDate={todate}
                      fullWidth
                      onChange={changeDate("rangeFromdate")}
                      errorText={
                        formErrors["rangeFromdateError"]
                          ? formErrors["rangeFromdateError"]
                          : ""
                      }
                      isError={
                        formErrors["rangeFromdateError"]
                          ? formErrors["rangeFromdateError"]
                          : ""
                      }
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={6}
                  xs={8}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} md={5} sm={5}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16, color: redColor }}
                      label="* "
                    />
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16, color: titleColor }}
                      label="To Date :"
                    />
                  </Grid>
                  <Grid item xs={12} md={7} sm={7}>
                    <BasicDatePicker
                      color="primary"
                      name="rangeTodate"
                      size="small"
                      value={rangeTodate}
                      disabled={false}
                      minDate={rangeFromdate}
                      maxDate={todate}
                      fullWidth
                      onChange={changeDate("rangeTodate")}
                      errorText={
                        formErrors["rangeTodateError"]
                          ? formErrors["rangeTodateError"]
                          : ""
                      }
                      isError={
                        formErrors["rangeTodateError"]
                          ? formErrors["rangeTodateError"]
                          : ""
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <br />
            </>
          )}
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="space-between"
          >
            <Grid
              item
              sm={6}
              xs={8}
              md={6}
              lg={6}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={5} md={5}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: redColor }}
                  label="* "
                />
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: titleColor }}
                  label="Offer For :"
                />
              </Grid>
              <Grid item xs={12} sm={7} md={7}>
                <RadioGroup
                  row
                  name={"offerFor"}
                  id={"offerFor"}
                  value={offerFor}
                  onChange={changeHandler}
                  size="small"
                >
                  <FormControlLabel
                    value={"0"}
                    control={<Radio />}
                    label={"Outlet"}
                  />
                  <FormControlLabel
                    value={"1"}
                    control={<Radio />}
                    label={"Stock Holder"}
                  />
                </RadioGroup>
              </Grid>
            </Grid>
            {stakeholderVisible && (
              <Grid
                item
                sm={6}
                xs={8}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} md={5} sm={5}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label="* "
                  />
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: titleColor }}
                    label="Stock Holder Type :"
                  />
                </Grid>
                <Grid item xs={12} md={7} sm={7}>
                  <AutoComplete
                    keyColName={"id"}
                    placeHolder="Select Stock Holder"
                    value={stakeholderType}
                    name={"stakeholderType"}
                    options={stakeHolderTypeList?.stockStakeHolderType}
                    errorText={
                      formErrors["stackHolderTypeError"]
                        ? formErrors["stackHolderTypeError"]
                        : ""
                    }
                    isError={
                      formErrors["stackHolderTypeError"]
                        ? formErrors["stackHolderTypeError"]
                        : ""
                    }
                    onChange={changeHandlerForDropdown}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>

          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="space-between"
          >
            <Grid
              item
              sm={6}
              xs={8}
              md={6}
              lg={6}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={5} md={5}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: redColor }}
                  label="* "
                />
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: titleColor }}
                  label="Level:"
                />
              </Grid>
              <Grid item xs={12} sm={7} md={7}>
                <RadioGroup
                  row
                  name={"level"}
                  id={"level"}
                  value={level}
                  onChange={changeHandler}
                  size="small"
                >
                  <FormControlLabel
                    value={"0"}
                    control={<Radio />}
                    label={"Bill Level"}
                  />
                  <FormControlLabel
                    value={"1"}
                    control={<Radio />}
                    label={"Item Level"}
                  />
                </RadioGroup>
              </Grid>
            </Grid>
            <Grid
              item
              sm={6}
              xs={8}
              md={6}
              lg={6}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} md={5} sm={5}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: redColor }}
                  label="* "
                />
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: titleColor }}
                  label="Level-Wise Type :"
                />
              </Grid>
              <Grid item xs={12} md={7} sm={7}>
                <AutoComplete
                  keyColName={"id"}
                  placeHolder="Select Level-Wise Type"
                  value={levelWiseType}
                  name={"levelWiseType"}
                  options={levelWiseTypeList}
                  onChange={changeHandlerForDropdown}
                  errorText={
                    formErrors["levelWiseTypeError"]
                      ? formErrors["levelWiseTypeError"]
                      : ""
                  }
                  isError={
                    formErrors["levelWiseTypeError"]
                      ? formErrors["levelWiseTypeError"]
                      : ""
                  }
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="space-between"
          >
            {level === "0" && (
              <Grid
                item
                sm={6}
                xs={8}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} md={5} sm={5}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label="* "
                  />
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: titleColor }}
                    label="Applicable Amount :"
                  />
                </Grid>
                <Grid item xs={12} md={7} sm={7}>
                  <TextFieldCompo
                    placeHolder={"Applicable Amount"}
                    color="primary"
                    name="applicableAmount"
                    size="small"
                    value={applicableAmount}
                    type="number"
                    fullWidth
                    onChange={changeHandler}
                    multiline={true}
                    errorText={
                      formErrors["applicableAmountError"]
                        ? formErrors["applicableAmountError"]
                        : ""
                    }
                    isError={
                      formErrors["applicableAmountError"]
                        ? formErrors["applicableAmountError"]
                        : ""
                    }
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="space-between"
          >
            {(levelWiseType == "1" || levelWiseType == "2") && (
              <Grid
                item
                sm={6}
                xs={8}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} md={5} sm={5}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label="* "
                  />
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: titleColor }}
                    label="Discount Amount :"
                  />
                </Grid>
                <Grid item xs={12} md={7} sm={7}>
                  <TextFieldCompo
                    placeHolder={"Discount Amount"}
                    color="primary"
                    name="discountAmount"
                    size="small"
                    type="number"
                    value={discountAmount}
                    fullWidth
                    onChange={changeHandler}
                    multiline={true}
                    errorText={
                      formErrors["discountAmountError"]
                        ? formErrors["discountAmountError"]
                        : ""
                    }
                    isError={
                      formErrors["discountAmountError"]
                        ? formErrors["discountAmountError"]
                        : ""
                    }
                  />
                </Grid>
              </Grid>
            )}
            {(levelWiseType == "0" || levelWiseType == "2") && (
              <Grid
                item
                sm={6}
                xs={8}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} md={5} sm={5}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label="* "
                  />
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: titleColor }}
                    label="Discount Percentage (%) :"
                  />
                </Grid>
                <Grid item xs={12} md={7} sm={7}>
                  <TextFieldCompo
                    placeHolder={"Discount % "}
                    color="primary"
                    name="discountPercentage"
                    size="small"
                    value={discountPercentage}
                    type="number"
                    fullWidth
                    onChange={changeHandler}
                    multiline={true}
                    errorText={
                      formErrors["discountPercentageError"]
                        ? formErrors["discountPercentageError"]
                        : ""
                    }
                    isError={
                      formErrors["discountPercentageError"]
                        ? formErrors["discountPercentageError"]
                        : ""
                    }
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          {(levelWiseType == "4" ||
            levelWiseType == "5" ||
            levelWiseType == "7" ||
            levelWiseType == "8" ||
            levelWiseType == "9") && (
              <>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="space-between"
                >
                  <Grid
                    item
                    sm={levelWiseType == "8" || levelWiseType == "9" ? 12 : 6}
                    xs={levelWiseType == "8" || levelWiseType == "9" ? 12 : 8}
                    md={levelWiseType == "8" || levelWiseType == "9" ? 12 : 6}
                    lg={levelWiseType == "8" || levelWiseType == "9" ? 12 : 6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid
                      item
                      xs={12}
                      md={levelWiseType == "8" || levelWiseType == "9" ? 2.5 : 5}
                      sm={levelWiseType == "8" || levelWiseType == "9" ? 2.5 : 5}
                    >
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16, color: redColor }}
                        label="* "
                      />
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16, color: titleColor }}
                        label="Product :"
                      />
                    </Grid>
                    {levelWiseType == "7" ||
                      levelWiseType == "8" ||
                      levelWiseType == "9" ? (
                      <Grid
                        item
                        xs={12}
                        md={
                          levelWiseType == "8" || levelWiseType == "9" ? 9.5 : 7
                        }
                        sm={
                          levelWiseType == "8" || levelWiseType == "9" ? 9.5 : 7
                        }
                      >
                        <NewMultipleSelect
                          er={
                            formErrors["productMultipleError"]
                              ? formErrors["productMultipleError"]
                              : ""
                          }
                          label={"Select Product"}
                          name1="productMultiple"
                          value1={productMultiple}
                          names={[
                            { id: "all", name: "All" },
                            ...productList.activeProduct,
                          ]}
                          onChange={multiSelectHandler}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={12} md={7} sm={7}>
                        <AutoComplete
                          keyColName={"id"}
                          placeHolder="Select Product"
                          value={product}
                          name={"product"}
                          options={productList.activeProduct}
                          onChange={changeHandlerForDropdown}
                          errorText={
                            formErrors["productError"]
                              ? formErrors["productError"]
                              : ""
                          }
                          isError={
                            formErrors["productError"]
                              ? formErrors["productError"]
                              : ""
                          }
                        />
                      </Grid>
                    )}
                  </Grid>
                  {(levelWiseType == "8" || levelWiseType == "9") && (
                    <>
                      <Grid
                        item
                        sm={6}
                        xs={8}
                        md={6}
                        lg={6}
                        container
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={12} md={5} sm={5}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16, color: redColor }}
                            label="* "
                          />
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16, color: titleColor }}
                            label="Min Quantity :"
                          />
                        </Grid>
                        <Grid item xs={12} md={7} sm={7}>
                          <TextFieldCompo
                            placeHolder={"Enter Min Quantity"}
                            color="primary"
                            name="minQty"
                            size="small"
                            value={qty}
                            type="number"
                            fullWidth
                            onChange={changeHandler}
                            multiline={true}
                            errorText={
                              formErrors["minQtyError"]
                                ? formErrors["minQtyError"]
                                : ""
                            }
                            isError={
                              formErrors["minQtyError"]
                                ? formErrors["minQtyError"]
                                : ""
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        sm={6}
                        xs={8}
                        md={6}
                        lg={6}
                        container
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={12} md={5} sm={5}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16, color: redColor }}
                            label="* "
                          />
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16, color: titleColor }}
                            label="Max Quantity :"
                          />
                        </Grid>
                        <Grid item xs={12} md={7} sm={7}>
                          <TextFieldCompo
                            placeHolder={"Enter Maxminum Quantity"}
                            color="primary"
                            name="maxValue"
                            size="small"
                            value={maxValue}
                            type="number"
                            fullWidth
                            onChange={changeHandler}
                            multiline={true}
                            errorText={
                              formErrors["maxQtyError"]
                                ? formErrors["maxQtyError"]
                                : ""
                            }
                            isError={
                              formErrors["maxQtyError"]
                                ? formErrors["maxQtyError"]
                                : ""
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        sm={6}
                        xs={8}
                        md={6}
                        lg={6}
                        container
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={12} md={5} sm={5}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16, color: redColor }}
                            label="* "
                          />
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16, color: titleColor }}
                            label={
                              levelWiseType == "8"
                                ? "Flat Amount :"
                                : "Unit Value:"
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={7} sm={7}>
                          <TextFieldCompo
                            placeHolder={
                              levelWiseType == "8"
                                ? "Enter Flat Amount"
                                : "Enter Per Unit Value"
                            }
                            color="primary"
                            name="flatAmount"
                            size="small"
                            value={flatAmount}
                            type="number"
                            fullWidth
                            onChange={changeHandler}
                            multiline={true}
                            errorText={
                              formErrors["flatAmountError"]
                                ? formErrors["flatAmountError"]
                                : ""
                            }
                            isError={
                              formErrors["flatAmountError"]
                                ? formErrors["flatAmountError"]
                                : ""
                            }
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {levelWiseType != "8" && levelWiseType != "9" && (
                    <>
                      <Grid
                        item
                        sm={6}
                        xs={8}
                        md={6}
                        lg={6}
                        container
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={12} md={5} sm={5}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16, color: redColor }}
                            label="* "
                          />
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16, color: titleColor }}
                            label="Quantity :"
                          />
                        </Grid>
                        <Grid item xs={12} md={7} sm={7}>
                          <TextFieldCompo
                            placeHolder={"Enter Quantity"}
                            color="primary"
                            name="qty"
                            size="small"
                            value={qty}
                            type="number"
                            fullWidth
                            onChange={changeHandler}
                            multiline={true}
                            errorText={
                              formErrors["qtyError"] ? formErrors["qtyError"] : ""
                            }
                            isError={
                              formErrors["qtyError"] ? formErrors["qtyError"] : ""
                            }
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              </>
            )}

          {(levelWiseType == "3" ||
            levelWiseType == "4" ||
            levelWiseType == "5" ||
            levelWiseType == "6" ||
            levelWiseType == "7") && (
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="space-between"
              >
                <Grid item xs={12} md={5} sm={5}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 18, color: DarkBlue }}
                    label="Add Details"
                  />
                </Grid>
              </Grid>
            )}
          {levelWiseType != "8" && levelWiseType != "9" && (
            <>
              {(offerType == 1 && level == 1) || offerType == 0 ? <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="space-between"
              >
                <Grid
                  item
                  sm={6}
                  xs={8}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} md={5} sm={5}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16, color: redColor }}
                      label="* "
                    />
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16, color: titleColor }}
                      label={"Product : "}
                    />
                  </Grid>
                  <Grid item xs={12} md={7} sm={7}>
                    <AutoComplete
                      keyColName={"id"}
                      placeHolder="Select Product"
                      value={productByCategory}
                      name={"productByCategory"}
                      options={
                        levelWiseType == "3" ||
                          levelWiseType == "6" ||
                          levelWiseType == "7"
                          ? productList.activeProduct
                          : activeProductList.activeProductByCategory
                      }
                      onChange={changeHandlerForDropdown}
                      errorText={
                        formErrors["productByCategoryError"]
                          ? formErrors["productByCategoryError"]
                          : ""
                      }
                      isError={
                        formErrors["productByCategoryError"]
                          ? formErrors["productByCategoryError"]
                          : ""
                      }
                    />
                  </Grid>
                </Grid>

                {(levelWiseType == "3" ||
                  levelWiseType == "5" ||
                  levelWiseType == "6" ||
                  levelWiseType == "7") && (
                    <Grid
                      item
                      sm={6}
                      xs={8}
                      md={6}
                      lg={6}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} md={5} sm={5}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16, color: redColor }}
                          label="* "
                        />
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16, color: titleColor }}
                          label="Quantity :"
                        />
                      </Grid>
                      <Grid item xs={12} md={7} sm={7}>
                        <TextFieldCompo
                          placeHolder={"Enter Quantity"}
                          color="primary"
                          name="qtyForItem"
                          size="small"
                          value={qtyForItem}
                          type="number"
                          fullWidth
                          onChange={changeHandler}
                          errorText={
                            formErrors["qtyForItemError"]
                              ? formErrors["qtyForItemError"]
                              : ""
                          }
                          isError={formErrors["qtyForItemError"] ? true : false}
                        />
                      </Grid>
                    </Grid>
                  )}
                {levelWiseType == "4" && (
                  <Grid
                    item
                    sm={6}
                    xs={7}
                    md={8}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} md={5} sm={5}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16, color: redColor }}
                        label="* "
                      />
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16, color: titleColor }}
                        label="Percentage(%) :"
                      />
                    </Grid>
                    <Grid item xs={12} md={7} sm={7}>
                      <TextFieldCompo
                        placeHolder={"Percentage"}
                        color="primary"
                        name="percentage"
                        size="small"
                        value={percentage}
                        type="number"
                        fullWidth
                        onChange={changeHandler}
                        errorText={
                          formErrors["percentageError"]
                            ? formErrors["percentageError"]
                            : " "
                        }
                        isError={formErrors["percentageError"] ? true : false}
                      />
                    </Grid>
                  </Grid>
                )}
                {(levelWiseType == "3" ||
                  levelWiseType == "4" ||
                  levelWiseType == "5" ||
                  levelWiseType == "6" ||
                  levelWiseType == "7") && (
                    <>
                      <Grid
                        item
                        container
                        justifyContent="flex-end"
                        xs={12}
                        sm={12}
                        md={12}
                      >
                        <ButtonCompo
                          size="medium"
                          onClick={(e) => addProductList(e, levelWiseType)}
                          variant="contained"
                          name="Add"
                        />
                      </Grid>

                      <RTTable
                        showPegination={false}
                        columns={[
                          { name: "index", title: "Sr. No.", align: "center" },
                          { name: "product", title: "Product" },
                          {
                            name: "quantity",
                            title: "Quantity",
                            align: "center",
                          },
                          {
                            name: "percentage",
                            title: "Percentage",
                            align: "center",
                          },
                          { name: "action", title: "Action" },
                        ]}
                        tableData={rowList}
                        showEditIcon={false}
                        isActionColActive={true}
                        showDeleteIcon={true}
                        rowDelete={rowDelete}
                      />
                    </>
                  )}
              </Grid> : null}
            </>
          )}

          <DialogActions>
            <Grid item sx={{ mr: 3 }} xs={12} sm={12} md={1}>
              <ButtonCompo
                size="medium"
                onClick={saveOfferData}
                variant="contained"
                name="Submit"
              />
            </Grid>
          </DialogActions>
        </div>
      </Paper>
    </>
  );
};
const mapStateToProps = (state) => ({
  stakeHolderTypeList: state.stockStakeHolderType,
  productList: state.product,
  activeProductList: state.product,
  regionList: state.region,
  stateList: state.states,
  zoneList: state.zone,
  districtList: state.district,
  talukaList: state.taluka,
  areaList: state.area,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getStockStakeHolderType,
  getRegionNew,
  getStateByRegion,
  getZoneByState,
  getDistrictByZone,
  getTalukaByDistrict,
  getAreaByTalukaPostBodyUpdated,
  getActiveProduct,
  getActiveProductByCategory,
  getActiveProductByCategoryForAll,
};
export default connect(mapStateToProps, mapDispatchToProps)(OfferForm);
