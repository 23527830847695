import { Grid, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { compose } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { orderHistoryEditJSON } from "../../DynamicFormsJson/MastersJSON/orderHistoryEdit";
import { placedOrderJson } from "../../DynamicFormsJson/Transaction/placedOrder";
import { getBeatByArea } from "../../Slice/beat.slice";
import { setCategoryAndItem } from "../../Slice/category.slice";
import { getOrderHeaderById } from "../../Slice/orderHistory.slice";
import { getActiveOutletByBeatId } from "../../Slice/outlet.slice";
import { ButtonCompo } from "../../components/Comman/Button";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import { withRouter } from "../../components/withRouter";
import { ValidationCheck, mandatoryCheck } from "../../config/ValidationCheck";
import endpoint from "../../config/endpoints";
import {
  atleastOne,
  cdMsg,
  discountPercentageErrorMsg,
  noInternetMsg,
  saveFailedMsg,
  saveWarningMsg,
  savemsg,
  serverMsg,
  updateData,
  updateMsg,
} from "../../config/messageconstant";
import { apiGet, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import OrderHistoryEditTable from "./OrderHistoryEditTable";
import { LabelCompo } from "../../components/Comman/Label";
import { titleColor } from "../../config/ColorObj";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

class OrderHistoryEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: "",
      searchValue: "",
      dynamicMasterData: {
        orderDetail: this.props.stackHolderList.stackholder,
      },
      formErrors: {},
      fieldData: {},
      DataToSet: [],
      value: 0,
      total: 0,
      totalAmt: 0,
      open: false,
      orderList: [],
      qty: 0,
      flag: false,
      companyFlagTwoForDiscounts: false,
      distcountAmt: 0,
      headerData: {},
    };
  }

  setCatList = ({ row }) => {
    const { setCategoryAndItem } = this.props;
    let dataTotal = 0;
    let qtyTotal = 0;
    row.map((rowData) => {
      if (rowData.flag == 3) {
        rowData.productWithMarginAndMrps.map((productData) => {
          dataTotal = dataTotal + +productData.total;
          qtyTotal = qtyTotal + +productData.qty;
        });
      }
    });
    const { fieldData } = this.state;
    const discount = fieldData.DiscountPercentage
      ? fieldData.DiscountPercentage
      : 0;
    let discountAmt = (dataTotal * +discount) / 100;
    this.setState({
      totalAmt: dataTotal - +discountAmt,
      total: dataTotal,
      qty: qtyTotal,
    });
    setCategoryAndItem({ row });
  };
  async componentDidMount() {
    const { getOrderHeaderById, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      const showCompanyFlag = localStorage.getItem("showCompanyFlag");
      if (showCompanyFlag == 2) {
        this.setState({ companyFlagTwoForDiscounts: true });
      }

      showLoador({ loador: true });
      await getOrderHeaderById({
        orderHeaderId: this.props.params.id,
      }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.setState({
            distcountAmt: response.distcountAmt,
            headerData: response,
            DataToSet: [
              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "Outlet Name",
                value:
                  response.outLet !== null && response.outLet.firmName !== null
                    ? response.outLet.firmName
                    : "",
              },
              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "Owner Name",
                value:
                  response.outLet !== null && response.outLet.ownerName !== null
                    ? response.outLet.ownerName
                    : "",
              },
              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "Order Number",
                value: response.orderNo !== null ? response.orderNo : "",
              },
              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "Order Date",
                value: response.orderDate !== null ? response.orderDate : "",
              },
              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "Taxable Amount",
                value: response.taxableAmt !== null ? response.taxableAmt : "",
              },
              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "Total Amount",
                value: response.totalAmt !== null ? response.totalAmt : "",
              },

              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "Total Tax",
                value:
                  response.cgstAmt !== null && response.sgstAmt !== null
                    ? (+response.cgstAmt + +response.sgstAmt).toFixed(2)
                    : "",
              },
            ],
          });
          showLoador({ loador: true });
          apiGet({
            url:
              endpoint.categoryLatest +
              "/and-items-with-margin-and-mrp-by-user",
          }).then(({ data, success }) => {
            showLoador({ loador: false });
            if (success && data.data != null) {
              const rowData = data.data;
              if (rowData.errorMessage.error) {
                showNotification({
                  msg: rowData.errorMessage.message,
                  severity: "error",
                });
              } else {
                showLoador({ loador: true });
                apiGet({
                  url:
                    endpoint.manageStockHeaderLatest + "/stock-by-current-date",
                }).then(({ data, success }) => {
                  showLoador({ loador: false });
                  if (success) {
                    let stockList = data.data.productStocks.map(
                      (productStockObject) => {
                        let stockObject = {
                          productId: productStockObject.product.id,
                          stock: productStockObject.primaryClosingQty,
                        };
                        return stockObject;
                      }
                    );
                    localStorage.setItem(
                      "stockList",
                      JSON.stringify(stockList)
                    );
                    let i;
                    const row = rowData.categoryAndItems.map((rowObj) => {
                      return {
                        ...rowObj,
                        total: 0,
                        qty: 0,
                        productWithMarginAndMrps:
                          rowObj.productWithMarginAndMrps.map((product) => {
                            if (data.data.productStocks.length !== 0) {
                              for (
                                i = 0;
                                i < data.data.productStocks.length;
                                i++
                              ) {
                                if (
                                  product.products.id ==
                                  data.data.productStocks[i].product.id
                                ) {
                                  let outletOrderDetailsList =
                                    response.outletOrderDetails.filter(
                                      (row) =>
                                        row.productId == product.products.id
                                    );

                                  return {
                                    ...product,
                                    uomConversionValue:
                                      product.products.uomConversionValue,
                                    qty:
                                      outletOrderDetailsList.length !== 0
                                        ? outletOrderDetailsList[0].qty
                                        : "",
                                    disabledFlag:
                                      (outletOrderDetailsList.length !== 0 &&
                                        outletOrderDetailsList[0]
                                          .isBillGenerated == 1) ||
                                        (outletOrderDetailsList.length !== 0 &&
                                          outletOrderDetailsList[0]
                                            .isBillGenerated == 2) ||
                                        data.data.productStocks[i]
                                          .secondaryClosingQty <= 0
                                        ? true
                                        : false,
                                    freeQty: 0,
                                    itemDiscPer: 0,
                                    productStock:
                                      data.data.productStocks[
                                        i
                                      ].secondaryClosingQty.toFixed(2),
                                    discountPer:
                                      outletOrderDetailsList.length !== 0
                                        ? outletOrderDetailsList[0].discountPer
                                        : 0,
                                    total:
                                      outletOrderDetailsList.length !== 0
                                        ? outletOrderDetailsList[0].qty *
                                        outletOrderDetailsList[0].rate
                                        : 0,
                                    stockError: 0,
                                  };
                                }
                              }
                            } else {
                              let outletOrderDetailsList =
                                response.outletOrderDetails.filter(
                                  (row) =>
                                    row.productId == product.products.id &&
                                    row.isBillGenerated == 0
                                );

                              return {
                                ...product,
                                uomConversionValue:
                                  product.products.uomConversionValue,
                                qty:
                                  outletOrderDetailsList.length !== 0
                                    ? outletOrderDetailsList[0].qty
                                    : "",
                                disabledFlag:
                                  (outletOrderDetailsList.length !== 0 &&
                                    outletOrderDetailsList[0].isBillGenerated ==
                                    1) ||
                                    (outletOrderDetailsList.length !== 0 &&
                                      outletOrderDetailsList[0].isBillGenerated ==
                                      2)
                                    ? true
                                    : false,
                                freeQty: 0,
                                itemDiscPer: 0,
                                productStock: 0,
                                discountPer:
                                  outletOrderDetailsList.length !== 0
                                    ? outletOrderDetailsList[0].discountPer
                                    : 0,
                                total:
                                  outletOrderDetailsList.length !== 0
                                    ? outletOrderDetailsList[0].qty *
                                    outletOrderDetailsList[0].rate
                                    : 0,
                                stockError: 0,
                              };
                            }
                          }),
                      };
                    });
                    this.setCatList({ row });
                  } else {
                    showNotification({ msg: serverMsg, severity: "error" });
                  }
                });
              }
            } else {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          });
        }
      });

      const stockCalAtBill = localStorage.getItem("stockCalAtBill");

      this.setState({
        flag: stockCalAtBill === "1" ? true : false,
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.stackHolderList !== nextProps.stackHolderList) {
      if (
        this.props.stackHolderList?.stackholder !==
        nextProps.stackHolderList?.stackholder
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            orderDetail: nextProps.stackHolderList?.stackholder,
          },
        });
      }
    }
  }
  handleChange = (event, newValue) => {
    this.setState({
      value: newValue,
    });
  };
  subHandler = () => {
    if (this.checkValidationOnSubmit()) {
      const { category } = this.props;
      let rowList = [];
      category.category.map((catData) => {
        if (catData.flag == 3) {
          const filterData = catData.productWithMarginAndMrps.filter(
            (row) => row.qty != 0
          );
          rowList = [...rowList, ...filterData];
          return catData;
        }
      });
      if (rowList.length != 0) {
        this.setState({
          orderList: rowList.map((rowData, index) => {
            return {
              index: index + 1,
            };
          }),
        });
        this.toggle();
      } else {
        this.props.showNotification({ msg: atleastOne, severity: "error" });
      }
    }
  };

  checkValidationOnSubmit = () => {
    const { fieldData } = this.state;
    const mandatoryCheckErrors = mandatoryCheck({
      fieldData,
      fieldMeta: placedOrderJson.fieldMeta,
    });
    this.setState({
      formErrors: mandatoryCheckErrors.formErrors,
    });
    if (mandatoryCheckErrors.formValid) {
      const ValidationCheckErrors = ValidationCheck({
        fieldData,
        fieldMeta: placedOrderJson.fieldMeta,
      });
      this.setState({
        formErrors: ValidationCheckErrors.formErrors,
      });
      return ValidationCheckErrors.formValid;
    } else {
      return false;
    }
  };
  getOrder = () => {
    const { orderList, fieldData } = this.state;
    const outletBillDetails = orderList.map((orderData) => {
      return {
        product: orderData.product,
        qty: orderData.qty,
      };
    });
    const dataToSave = {
      orderDate: dayjs().format("DD-MM-YYYY"),
      discountOnBillPer: fieldData.DiscountPercentage
        ? fieldData.DiscountPercentage
        : 0,
      stockStakeHolderOrderDetails: outletBillDetails,
    };
    swal({
      title: "Are you sure?",
      text: saveWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onSave(dataToSave);
      }
    });
  };
  onSave = (dataToSave) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      this.toggle();
      showLoador({ loador: true });
      apiPost({
        url: endpoint.stockStakeholderOrderHeader,
        postBody: dataToSave,
      }).then(({ data, success }) => {
        if (success) {
          showLoador({ loador: false });
          this.props.navigate("/place-order");
          showNotification({ msg: savemsg });
        } else {
          showLoador({ loador: false });

          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  onDataChange = (fieldName, newValue) => {
    if (fieldName == "DiscountPercentage") {
      let discountAmt = (this.state.total * +newValue) / 100;
      this.setState({
        totalAmt: +this.state.total - +discountAmt,
      });
    }
    const { fieldData } = this.state;
    const dataToSearch = {};
    const dataToReset = { ...this.state.fieldData };
    let listError = false;
    placedOrderJson.fieldMeta.map((currentField) => {
      if (currentField.getDate && currentField.dataKey === fieldName) {
        dataToReset[currentField.getDate] = newValue.add(365, "day");
      }
      if (currentField.dataKey === fieldName && currentField.getListId) {
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
          return null;
        });
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key == fieldName && dataToReset[key]) {
            delete dataToReset[currentField.dataKey];
          }
        });
      }
      if (
        !listError &&
        fieldData[currentField.dataKey] &&
        currentField.controlType != "datepicker" &&
        currentField.getListFrom == undefined
      ) {
        dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
      }
      return null;
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
    });
  };
  toggle = () => {
    this.setState({
      open: !this.state.open,
    });
  };
  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  getFilteredTableData = (tableData) => {
    const { searchValue } = this.state;
    return tableData.filter((currentRow) => {
      let isValid = false;
      let productName =
        currentRow.products != null ? currentRow.products.name : "-";
      let stakeHolderUom =
        currentRow.products.stakeHolderUom != null
          ? currentRow.products.stakeHolderUom.name
          : "-";
      let rate = currentRow.rate != null ? currentRow.rate : "-";
      let qty = currentRow.qty != null ? currentRow.qty : "-";
      if (
        productName &&
        productName.toLowerCase().includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      if (
        stakeHolderUom &&
        stakeHolderUom.toLowerCase().includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      if (rate && rate.toLowerCase().includes(searchValue.toLowerCase())) {
        isValid = true;
        return true;
      }

      return isValid;
    });
  };
  changeHandler = (e) => {
    const { name, value } = e.target;
    if (name === "comment") {
      this.setState({ comment: value });
    }
  };
  onCancel = () => {
    this.props.navigate(-1);
  };

  changeHandlerTextField = () => (event) => {
    const { name, value } = event.target;
    this.setState({
      distcountAmt: value,
    });
  };

  checkValidation = () => {
    const { distcountAmt } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (distcountAmt === "") {
      formIsValid = false;
      formErrors["distcountAmtError"] = cdMsg;
    } else if (distcountAmt > 100) {
      formIsValid = false;
      formErrors["distcountAmtError"] = discountPercentageErrorMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  };

  submitHandler = () => {
    const { distcountAmt } = this.state;
    if (this.checkValidation()) {
      const { historyData, headerData } = this.state;
      const { showNotification, category, showLoador } = this.props;
      let rowList = [];
      category.category.map((catData) => {
        if (catData.flag == 3) {
          const filterData = catData.productWithMarginAndMrps.filter(
            (row) => row.qty != 0 && !row.disabledFlag
          );
          rowList = [...rowList, ...filterData];
          return catData;
        }
      });
      let historySaveData = {
        ...headerData,
        distcountAmt: distcountAmt,
        outletOrderDetails: rowList.map((productObject) => {
          return {
            id: productObject.id,
            discountPer:
              productObject.discountPer !== null
                ? productObject.discountPer
                : "",
            product:
              productObject.products !== null ? productObject.products : "",
            subCategory:
              productObject.products !== null &&
                productObject.products.group !== null &&
                productObject.products.group.subCategory !== null &&
                productObject.products.group.subCategory.id
                ? productObject.products.group.subCategory.id
                : "",
            category:
              productObject.products !== null &&
                productObject.products.group !== null &&
                productObject.products.group.subCategory !== null &&
                productObject.products.group.subCategory.category !== null &&
                productObject.products.group.subCategory.category.id
                ? productObject.products.group.subCategory.category.id
                : "",
            categoryName:
              productObject.products !== null &&
                productObject.products.group !== null &&
                productObject.products.group.subCategory !== null &&
                productObject.products.group.subCategory.category !== null &&
                productObject.products.group.subCategory.category.name
                ? productObject.products.group.subCategory.category.name
                : "",
            categoryShortName:
              productObject.products !== null &&
                productObject.products.group !== null &&
                productObject.products.group.subCategory !== null &&
                productObject.products.group.subCategory.category !== null &&
                productObject.products.group.subCategory.category.shortName
                ? productObject.products.group.subCategory.category.shortName
                : "",
            categorySortOrder:
              productObject.products !== null &&
                productObject.products.group !== null &&
                productObject.products.group.subCategory !== null &&
                productObject.products.group.subCategory.category !== null &&
                productObject.products.group.subCategory.category.sortOrder
                ? productObject.products.group.subCategory.category.sortOrder
                : "",
            isActive:
              productObject.products !== null &&
                productObject.products.group !== null &&
                productObject.products.group.subCategory !== null &&
                productObject.products.group.subCategory.category !== null &&
                productObject.products.group.subCategory.category.isActive
                ? productObject.products.group.subCategory.category.isActive
                : "",
            insertDateTime:
              productObject.products !== null &&
                productObject.products.group !== null &&
                productObject.products.group.subCategory !== null &&
                productObject.products.group.subCategory.category !== null &&
                productObject.products.group.subCategory.category.insertDateTime
                ? productObject.products.group.subCategory.category
                  .insertDateTime
                : "",
            taxPercentage:
              productObject.products !== null &&
                productObject.products.taxPercentage !== null &&
                productObject.products.taxPercentage.id
                ? productObject.products.taxPercentage.id
                : "",
            taxName:
              productObject.products !== null &&
                productObject.products.taxPercentage !== null &&
                productObject.products.taxPercentage.name
                ? productObject.products.taxPercentage.name
                : "",
            taxSgst:
              productObject.products !== null &&
                productObject.products.taxPercentage !== null &&
                productObject.products.taxPercentage.sgst
                ? productObject.products.taxPercentage.sgst
                : "",
            taxCgst:
              productObject.products !== null &&
                productObject.products.taxPercentage !== null &&
                productObject.products.taxPercentage.cgst
                ? productObject.products.taxPercentage.cgst
                : "",
            taxIgst:
              productObject.products !== null &&
                productObject.products.taxPercentage !== null &&
                productObject.products.taxPercentage.igst
                ? productObject.products.taxPercentage.igst
                : "",
            taxCess:
              productObject.products !== null &&
                productObject.products.taxPercentage !== null &&
                productObject.products.taxPercentage.cess
                ? productObject.products.taxPercentage.cess
                : "",
            hsnCode:
              productObject.products !== null &&
                productObject.products.taxPercentage !== null &&
                productObject.products.taxPercentage.hsnCode
                ? productObject.products.taxPercentage.hsnCode
                : "",
            taxInsertDateTime:
              productObject.products !== null &&
                productObject.products.taxPercentage !== null &&
                productObject.products.taxPercentage.insertDateTime
                ? productObject.products.taxPercentage.insertDateTime
                : "",
            stakeHolderUom:
              productObject.products !== null &&
                productObject.products.stakeHolderUom !== null &&
                productObject.products.stakeHolderUom.id
                ? productObject.products.stakeHolderUom.id
                : "",
            stakeHolderUom:
              productObject.products !== null &&
                productObject.products.stakeHolderUom !== null &&
                productObject.products.stakeHolderUom.id
                ? productObject.products.stakeHolderUom.id
                : "",
            productName:
              productObject.products !== null && productObject.products.name
                ? productObject.products.name
                : "",
            outletName:
              productObject.products !== null &&
                productObject.products.stakeHolderUom !== null &&
                productObject.products.stakeHolderUom.name
                ? productObject.products.stakeHolderUom.name
                : "",
            outletisActive:
              productObject.products !== null &&
                productObject.products.stakeHolderUom !== null &&
                productObject.products.stakeHolderUom.isActive
                ? productObject.products.stakeHolderUom.isActive
                : "",
            outletInsertDateTime:
              productObject.products !== null &&
                productObject.products.stakeHolderUom !== null &&
                productObject.products.stakeHolderUom.insertDateTime
                ? productObject.products.stakeHolderUom.insertDateTime
                : "",
            outletUom:
              productObject.products !== null &&
                productObject.products.outletUom !== null &&
                productObject.products.outletUom.id
                ? productObject.products.outletUom.id
                : "",
            uom: {
              id: productObject.products.outletUom.id,
            },
            outletUomName:
              productObject.products !== null &&
                productObject.products.outletUom !== null &&
                productObject.products.outletUom.name
                ? productObject.products.outletUom.name
                : "",
            outletUomIsActive:
              productObject.products !== null &&
                productObject.products.outletUom !== null &&
                productObject.products.outletUom.isActive
                ? productObject.products.outletUom.isActive
                : "",
            outletUomInsertDateTime:
              productObject.products !== null &&
                productObject.products.outletUom !== null &&
                productObject.products.outletUom.insertDateTime
                ? productObject.products.outletUom.insertDateTime
                : "",
            uomConversionValue:
              productObject.products !== null &&
                productObject.products.uomConversionValue
                ? productObject.products.uomConversionValue
                : 0,
            productCode:
              productObject.products !== null && productObject.products.code
                ? productObject.products.code
                : "",

            shortName:
              productObject.products !== null &&
                productObject.products.shortName
                ? productObject.products.shortName
                : "",
            weight:
              productObject.products !== null && productObject.products.weight
                ? productObject.products.weight
                : "",
            description:
              productObject.products !== null &&
                productObject.products.description
                ? productObject.products.description
                : "",
            selfLife:
              productObject.products !== null && productObject.products.selfLife
                ? productObject.products.selfLife
                : "",
            image:
              productObject.products !== null && productObject.products.image
                ? productObject.products.image
                : "",
            sortOrder:
              productObject.products !== null &&
                productObject.products.sortOrder
                ? productObject.products.sortOrder
                : "",
            isActive:
              productObject.products !== null && productObject.products.isActive
                ? productObject.products.isActive
                : "",
            insertDateTime:
              productObject.products !== null &&
                productObject.products.insertDateTime
                ? productObject.products.insertDateTime
                : "",
            insertedUserId:
              headerData.insertedUserId !== null && headerData.insertedUserId
                ? headerData.insertedUserId
                : "",
            mrp:
              productObject.products !== null && productObject.products.mrp
                ? productObject.products.mrp
                : "",
            marginPercentage:
              productObject.marginPercentage !== null
                ? (+productObject.marginPercentage).toFixed(2)
                : 0,
            mrp:
              productObject.mrp !== null ? (+productObject.mrp).toFixed(2) : 0,

            qty: productObject.qty !== null ? productObject.qty : 0,
            rate:
              productObject.rate !== null
                ? (+productObject.rate).toFixed(2)
                : 0,
            total: productObject.total.toFixed(2),
          };
        }),
      };
      console.log(historySaveData);
      swal({
        title: "Are you sure?",
        text: updateData,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          if (navigator.onLine) {
            showLoador({ loador: true });
            await apiPost({
              url: endpoint.adminDashboard + "/update",
              postBody: historySaveData,
            }).then(({ success }) => {
              if (success) {
                showLoador({ loador: false });
                showNotification({
                  msg: updateMsg,
                });
                this.props.navigate(-1);
              } else {
                showNotification({
                  msg: saveFailedMsg,
                  severity: "error",
                });
                showLoador({ loador: false });
              }
            });
          } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
          }
        }
      });
    }
  };
  render() {
    const {
      dynamicMasterData,
      DataToSet,
      value,
      total,
      totalAmt,
      open,
      orderList,
      formErrors,
      fieldData,
      qty,
      comment,
      flag,
      distcountAmt,
      headerData,
      companyFlagTwoForDiscounts,
    } = this.state;
    const { category } = this.props;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <LandingScreenHeader
            showTitle={orderHistoryEditJSON.showTitle}
            screenTitle={orderHistoryEditJSON.screenTitle}
            showSaveBtnMain={false}
            showAddButton={false}
            showBackButton={true}
            onCancel={this.onCancel}
          />
          <br />
          <DynamicDetails DataToSet={DataToSet} />

          {category.category.length != 0 && (
            <>
              <Tabs
                value={value}
                onChange={this.handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="scrollable force tabs example"
              >
                {category.category.map((catData) => {
                  return (
                    <Tab
                      label={
                        catData.category.name +
                        " ( " +
                        catData.productWithMarginAndMrps.length +
                        " )"
                      }
                    />
                  );
                })}
              </Tabs>
              {category.category.map((catData, index) => {
                return (
                  <TabPanel value={value} index={index}>
                    <LandingScreenHeader
                      screenTitle={""}
                      showSearchBox={true}
                      showTable={true}
                      showFilter={false}
                      showPdfDownload={false}
                      showExcelDownload={false}
                      onSearch={this.onSearch}
                    />
                    <OrderHistoryEditTable
                      total={total}
                      qty={qty}
                      tableHead={
                        companyFlagTwoForDiscounts
                          ? flag
                            ? [
                              {
                                title: "Sr.No.",
                                name: "index",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "center",
                              },
                              {
                                title: "Product Name",
                                name: "name",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "left",
                              },
                              {
                                title: "UOM",
                                name: "name",
                                positionCenter: true,
                                showInscreen: true,
                              },
                              {
                                title: "Rate",
                                name: "name",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "left",
                              },

                              {
                                title: "Product Stock",
                                name: "name",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "left",
                              },
                              {
                                title: "Quantity",
                                name: "target",
                                placeHolder: "Enter Target",
                                textFieldError: "targetErr",
                                positionCenter: false,
                                showInscreen: true,
                              },
                              {
                                title: "Discount",
                                name: "discount",
                                placeHolder: "Enter Discount",
                                textFieldError: "discountErr",
                                positionCenter: false,
                                showInscreen: true,
                              },
                              {
                                title: "Total",
                                name: "name",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "left",
                              },
                            ]
                            : [
                              {
                                title: "Sr.No.",
                                name: "index",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "center",
                              },
                              {
                                title: "Product Name",
                                name: "name",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "left",
                              },
                              {
                                title: "UOM",
                                name: "name",
                                positionCenter: true,
                                showInscreen: true,
                              },
                              {
                                title: "Rate",
                                name: "name",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "left",
                              },

                              {
                                title: "Quantity",
                                name: "target",
                                placeHolder: "Enter Target",
                                textFieldError: "targetErr",
                                positionCenter: false,
                                showInscreen: true,
                              },
                              {
                                title: "Discount",
                                name: "discount",
                                placeHolder: "Enter Discount",
                                textFieldError: "discountErr",
                                positionCenter: false,
                                showInscreen: true,
                              },
                              {
                                title: "Total",
                                name: "name",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "left",
                              },
                            ]
                          : flag
                            ? [
                              {
                                title: "Sr.No.",
                                name: "index",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "center",
                              },
                              {
                                title: "Product Name",
                                name: "name",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "left",
                              },
                              {
                                title: "UOM",
                                name: "name",
                                positionCenter: true,
                                showInscreen: true,
                              },
                              {
                                title: "Rate",
                                name: "name",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "left",
                              },

                              {
                                title: "Product Stock",
                                name: "name",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "left",
                              },
                              {
                                title: "Quantity",
                                name: "target",
                                placeHolder: "Enter Target",
                                textFieldError: "targetErr",
                                positionCenter: false,
                                showInscreen: true,
                              },
                              {
                                title: "Total",
                                name: "name",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "left",
                              },
                            ]
                            : [
                              {
                                title: "Sr.No.",
                                name: "index",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "center",
                              },
                              {
                                title: "Product Name",
                                name: "name",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "left",
                              },
                              {
                                title: "UOM",
                                name: "name",
                                positionCenter: true,
                                showInscreen: true,
                              },
                              {
                                title: "Rate",
                                name: "name",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "left",
                              },

                              {
                                title: "Quantity",
                                name: "target",
                                placeHolder: "Enter Target",
                                textFieldError: "targetErr",
                                positionCenter: false,
                                showInscreen: true,
                              },
                              {
                                title: "Total",
                                name: "name",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "left",
                              },
                            ]
                      }
                      data={this.getFilteredTableData(
                        catData.productWithMarginAndMrps
                      )}
                      // data={catData.productWithMarginAndMrps}
                      rowList={category.category}
                      setList={this.setCatList}
                      flag={flag}
                      companyFlagTwoForDiscounts={companyFlagTwoForDiscounts}
                    />
                  </TabPanel>
                );
              })}
              <br />

              {companyFlagTwoForDiscounts ? (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="space-between"
                >
                  <Grid
                    item
                    xs={8}
                    sm={6}
                    md={6}
                    lg={6}
                    container
                    justifyContent="flex-start"
                  >
                    <Grid item xs={12} sm={3} md={3}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16, color: titleColor }}
                        label="CD :"
                      />
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                      <TextFieldCompo
                        placeHolder="Enter CD"
                        color="primary"
                        name="distcountAmt"
                        value={distcountAmt}
                        fullWidth
                        onChange={this.changeHandlerTextField("distcountAmt")}
                        errorText={
                          formErrors["distcountAmtError"]
                            ? formErrors["distcountAmtError"]
                            : ""
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={8}
                    sm={6}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-between"
                  >
                    <DynamicDetails
                      DataToSet={[
                        {
                          xs: 12,
                          sm: 6,
                          md: 6,
                          lg: 6,
                          label: "Total Amount",
                          value: total.toFixed(2),
                        },
                        {
                          xs: 12,
                          sm: 6,
                          md: 6,
                          lg: 6,
                          label: "Total Quantity",
                          value: qty,
                        },
                      ]}
                    />
                  </Grid>
                </Grid>
              ) : (
                <DynamicDetails
                  DataToSet={[
                    {
                      xs: 12,
                      sm: 4,
                      md: 4,
                      lg: 4,
                      label: "Total Amount",
                      value: total.toFixed(2),
                    },
                    {
                      xs: 12,
                      sm: 4,
                      md: 4,
                      lg: 4,
                      label: "Total Quantity",
                      value: qty,
                    },
                  ]}
                />
              )}

              <Grid container spacing={1}>
                <Grid
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  sx={{ justifyContent: "flex-end" }}
                >
                  <ButtonCompo
                    size="medium"
                    type="submit"
                    variant="contained"
                    name={"Proceed"}
                    onClick={this.submitHandler}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  stackHolderList: state.stackholder,
  areaList: state.area,
  outletList: state.outlet,
  beatList: state.beat,
  outletOrderDetail: state.outletOrderDetail,
  salesExecutive: state.salesExecutive,
  category: state.category,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getBeatByArea,
  getActiveOutletByBeatId,
  setCategoryAndItem,
  getOrderHeaderById,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(OrderHistoryEdit);
