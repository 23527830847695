import { Route, Routes } from "react-router-dom";
import Login from "./Pages/Login/index";
import ForgotPasswordForm from "./Pages/Master/ForgotPassword/ForgotPasswordForm";
import PrivateRoute from "./routes/index";
import Landing from "./Pages/Landing";
import { connect } from "react-redux";
import { closeNotification } from "./Pages/Landing/Landing.slice";
import NotificationToast from "./components/Comman/NotificationToast";
import { Loading } from "./components/Loader";
import { Loading1 } from "./components/Loading1";
import BillPDFShowMyBill from "./Pages/Show My Bill List/BillPDFShowMyBill";
import CreditNoteBillPDF from "./Pages/History of credit note/CreditNoteBillPDF";
import CreditNoteBillPDFNew from "./Pages/History of credit note/CreditNoteBillPDFNew";
import LoadsheetForOrder from "./Pages/Order History/LoadsheetForOrder";
import BillPDF from "./Pages/Billing History/BillPDF";
import SimpleBillPDF from "./Pages/Billing History/SimpleBillPDF";
import LoadsheetPDF from "./Pages/Billing History/LoadsheetPDF";
import LoadSheetUpdatedPDF from "./Pages/Billing History/LoadSheetUpdatedPDF";
import PaymentSummary from "./Pages/Billing History/PaymentSummary";
import CreditNotePDF from "./Pages/Credit Note/CreditNotePDF";

import BillPDFNew from "./Pages/Billing History/BillPDFNew";
import BillPdfShowMyBillNew from "./Pages/Show My Bill List/BillPdfShowMyBillNew";
import PurchaseBillPdf from "./Pages/Show My Bill List/PurchaseBillPdf";
import ReceivedOrderPdf from "./Pages/ReceivedOrderList/ReceivedOrderPdf";
import Maintainance from "./Pages/Maintainance/Maintainance";
import PurchaseOrderPDF from "./Pages/PlaceOrder/PurchaseOrderPDF";
const App = ({ landingData, closeNotification }) => {
  const closeSnak = () => {
    closeNotification();
  };

  return (
    <>
      <NotificationToast
        open={landingData.isShowNotification}
        severity={
          landingData.severity === undefined ? "success" : landingData.severity
        }
        msg={landingData.msg}
        closeSnak={closeSnak}
      />
      {landingData.loador && <Loading1 />}
      {landingData.loador1 && <Loading />}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgotPassword" element={<ForgotPasswordForm />} />
        <Route
          path="/maintainance"
          element={
            <PrivateRoute>
              <Maintainance />
            </PrivateRoute>
          }
        />
        <Route
          path="/bill-pdf"
          element={
            <PrivateRoute>
              <BillPDF />
            </PrivateRoute>
          }
        />
        <Route
          path="/simple-bill-pdf"
          element={
            <PrivateRoute>
              <SimpleBillPDF />
            </PrivateRoute>
          }
        />

        <Route
          path="/bill-pdf-new"
          element={
            <PrivateRoute>
              <BillPDFNew />
            </PrivateRoute>
          }
        />
        <Route
          path="/my-bill-pdf"
          element={
            <PrivateRoute>
              <BillPDFShowMyBill />
            </PrivateRoute>
          }
        />
        <Route
          path="/new-my-bill-pdf"
          element={
            <PrivateRoute>
              <BillPdfShowMyBillNew />
            </PrivateRoute>
          }
        />

        <Route
          path="/purchase-bill-pdf"
          element={
            <PrivateRoute>
              <PurchaseBillPdf />
            </PrivateRoute>
          }
        />
        <Route
          path="/credit-note-bill-pdf"
          element={
            <PrivateRoute>
              <CreditNoteBillPDF />
            </PrivateRoute>
          }
        />
        <Route
          path="/credit-note-bill-pdf-new"
          element={
            <PrivateRoute>
              <CreditNoteBillPDFNew />
            </PrivateRoute>
          }
        />

        <Route
          path="/loadsheet-pdf"
          element={
            <PrivateRoute>
              <LoadsheetPDF />
            </PrivateRoute>
          }
        />

        <Route
          path="/loadsheetUpdated-pdf"
          element={
            <PrivateRoute>
              <LoadSheetUpdatedPDF />
            </PrivateRoute>
          }
        />


        <Route
          path="/loadsheet-order-pdf"
          element={
            <PrivateRoute>
              <LoadsheetForOrder />
            </PrivateRoute>
          }
        />

        <Route
          path="/purchase-order-pdf"
          element={
            <PrivateRoute>
              <PurchaseOrderPDF />
            </PrivateRoute>
          }
        />

        <Route
          path="/paymentsummary-pdf/:fromDate/:toDate"
          element={
            <PrivateRoute>
              <PaymentSummary />
            </PrivateRoute>
          }
        />
        <Route
          path="/creditNote-pdf"
          element={
            <PrivateRoute>
              <CreditNotePDF />
            </PrivateRoute>
          }
        />
        {/* <Route
          path="/creditNote-pdf/:id"
          element={
            <PrivateRoute>
              <CreditNotePDFNew />
            </PrivateRoute>
          }
        /> */}
        <Route
          path="/*"
          element={
            <PrivateRoute>
              <Landing />
            </PrivateRoute>
          }
        />
        <Route
          path="/receivedOrder-pdf"
          element={
            <PrivateRoute>
              <ReceivedOrderPdf />
            </PrivateRoute>
          }
        />
      </Routes>
    </>
  );
};

const mapStateToProps = (state) => ({
  common: state.common,
  landingData: state.landing,
});

const mapDispatchToProps = { closeNotification };
export default connect(mapStateToProps, mapDispatchToProps)(App);
