import React from "react";
import { connect } from "react-redux";

import { Paper } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { compose } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { withRouter } from "../../components/withRouter";
import endpoint from "../../config/endpoints";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import { AddDistributorJson } from "../../DynamicFormsJson/Transaction/addDistributor";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import BasicInformation from "./BasicInformation";
import PartnerDetails from "./PartnerDetails";
import OtherDetails from "./OtherDetails";
import { apiGet } from "../../utils/api_service";
import Attachments from "./Attachments";
import QuestionsChecklist from "./QuestionsChecklist";



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

class AddDistributorForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formErrors: {},
            fieldData: {},
            DataToSet: [],
            value: 0,
            open: false,
            salesPersonId: "",
        };
    }

    componentDidMount() {
        const activeTab = localStorage.getItem("activeTab");
        if (activeTab) {
            this.setState({
                value: +activeTab,
            });
            localStorage.removeItem("activeTab");
        }
    }


    handleChange = (event, newValue) => {
        this.setState({
            value: newValue,
        });
    };

    toggle = () => {
        this.setState({
            open: !this.state.open,
        });
    };
    changeTab = (value) => {
        this.setState({
            value: +value,
        });
    };
    onCancel = () => {
        window.location.replace("/request-for-distributor");
    };
    render() {
        const { value } = this.state;
        const { distributorInquiryList } = this.props;
        return (
            <>
                <Paper
                    sx={{
                        p: 2,
                        borderRadius: 2,
                        height: '800px',
                    }}
                    elevation={0}
                >
                    <LandingScreenHeader
                        tableColumnsToFilter={[]}
                        screenTitle={AddDistributorJson.screenTitle}
                        showPdfDownload={false}
                        showExcelDownload={false}
                        showAddButton={false}
                        showTable={false}
                        showBackButton={true}
                        onCancel={this.onCancel}
                    />
                    <br />
                    <>
                        <Tabs
                            value={value}
                            onChange={this.handleChange}
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                            aria-label="scrollable force tabs example"
                        >
                            <Tab label={"Basic Information"} />
                            <Tab
                                disabled={
                                    Object.keys(distributorInquiryList?.distributorInquiryData).length == 0
                                        ? true
                                        : false
                                }
                                label={"Partner Details"}
                            />
                            <Tab
                                disabled={
                                    Object.keys(distributorInquiryList?.distributorInquiryData).length == 0
                                        ? true
                                        : false
                                }
                                label={"Other"}
                            />
                            {/* <Tab
                                disabled={
                                    Object.keys(distributorInquiryList?.distributorInquiryData).length == 0
                                        ? true
                                        : false
                                }
                                label={"Attachments"}
                            /> */}
                            <Tab
                                disabled={
                                    Object.keys(distributorInquiryList?.distributorInquiryData).length == 0
                                        ? true
                                        : false
                                }

                                label={"Questions Checklist"}
                            />


                        </Tabs>
                        <TabPanel index={0} value={value}>
                            <BasicInformation changeTab={this.changeTab} />
                        </TabPanel>
                        <TabPanel index={1} value={value}>
                            <PartnerDetails changeTab={this.changeTab} />
                        </TabPanel>
                        <TabPanel index={2} value={value}>
                            <OtherDetails changeTab={this.changeTab} />
                        </TabPanel>
                        {/* <TabPanel index={3} value={value}>
                            <Attachments changeTab={this.changeTab} />
                        </TabPanel> */}
                        <TabPanel index={3} value={value}>
                            <QuestionsChecklist changeTab={this.changeTab} />
                        </TabPanel>
                    </>
                </Paper>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    distributorInquiryList: state.distributorInquiry,
});
const mapDispatchToProps = {
    showNotification,
    showLoador,
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(AddDistributorForm);
