import { Paper } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import MaterialUITableWithTextField from "../../components/Comman/RT/MaterialUITableWithTextField";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import endpoint from "../../config/endpoints";
import {
  saveFailedMsg,
  savemsg,
  serverMsg,
  noInternetMsg,
  saveWarningMsg,
} from "../../config/messageconstant";
import { MRPConfigrationJson } from "../../DynamicFormsJson/Transaction/MRPConfigration";
import { MRPConfigrationFooterJson } from "../../DynamicFormsJson/Transaction/MRPConfigurationFooter";
import {
  getCategory,
  getCategoryByProductRateStructure,
  getSubCategoryByCategoryId,
} from "../../Slice/category.slice";
import {
  getProductByCategoryId,
  setMRPConfList,
  getProductBySubCategory,
} from "../../Slice/mrpConfigration.slice";
import { apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import MRPConfigTable from "./MRPConfigTable";
import swal from "sweetalert";
class MRPConfiguration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        categoryDetail: this.props.categoryList?.category,
        subCategoryDetail: this.props.categoryList.subCategory,
      },
      publishDate: "",
      categoryName: "",
      subCategoryName: "",
      open: false,
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.categoryList !== nextProps.categoryList) {
      if (
        this.props.categoryList?.category !== nextProps.categoryList?.category
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            categoryDetail: nextProps.categoryList?.category,
          },
        });
      }
    }

    if (this.props.categoryList !== nextProps.categoryList) {
      if (
        this.props.categoryList?.subCategory !==
        nextProps.categoryList?.subCategory
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            subCategoryDetail: nextProps.categoryList.subCategory,
          },
        });
      }
    }
  }

  async componentDidMount() {
    const { getCategory, getCategoryByProductRateStructure, setMRPConfList, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {

      await setMRPConfList({ row: [] });

      const userTypeId = localStorage.getItem("userTypeId");

      if (userTypeId == 2) {
        showLoador({ loador: true });
        await getCategoryByProductRateStructure().then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      } else {
        showLoador({ loador: true });
        await getCategory().then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }

    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  getListById = async (data) => {
    const { getSubCategoryByCategoryId, showLoador, showNotification } =
      this.props;
    if (data.category) {
      if (navigator.onLine) {
        showLoador({ loador: true });
        await getSubCategoryByCategoryId({ categoryId: data.category.id }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      } else {
        this.props.showNotification({ msg: noInternetMsg, severity: "error" });
      }
    }
  };

  onSearchFooter = (data) => {
    const { mrpConfigrationList, showNotification } = this.props;
    if (navigator.onLine) {
      let filterMrpConfigList = mrpConfigrationList.productBySubCategory.filter(
        (productListObject) => {
          return (
            productListObject.changedValue != 0 &&
            productListObject.changedValue > 0 &&
            productListObject.changedValue != ""
          );
        }
      );
      this.setState({ publishDate: data.date });
      if (filterMrpConfigList.length != 0) {
        this.handleCloses();
      } else {
        showNotification({
          msg: "Please enter atleast one mrp",
          severity: "error",
        });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  onSearch = (data) => {
    console.log(data);
    const {
      getProductBySubCategory,
      mrpConfigrationList,
      categoryList,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      const filterCategory = categoryList?.category.filter(
        (rowData) => rowData.id == data.category.id
      );
      if (filterCategory.length != 0) {
        this.setState({
          categoryName: filterCategory[0].name,
        });
      }
      showLoador({ loador: true });
      getProductBySubCategory({
        subCategoryIds: data.subCategory,
        type: data.type,
        value: data.value,
        addSub: data.addSub,
      }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  saveMRPConfigtation = () => {
    const { mrpConfigrationList } = this.props;
    let filterMrpConfigList = [];
    mrpConfigrationList.productBySubCategory.map((productListObject) => {
      if (productListObject.changedValue > 1) {
        const productListObjectToSave = {
          productBySubCategory: {
            id: productListObject.id,
          },
          mrp: +productListObject.changedValue,
          publishDate: this.state.publishDate,
          isActive: 1,
        };
        filterMrpConfigList.push(productListObjectToSave);
      }
    });
    swal({
      title: "Are you sure?",
      text: saveWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onSave(filterMrpConfigList);
      }
    });
  };
  onSave = (filterMrpConfigList) => {
    const { showNotification, showLoador } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiPost({
        url: endpoint.mrpConfiguration + "/save-list",
        postBody: filterMrpConfigList.filter((rowData) => rowData != 0),
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          showNotification({ msg: savemsg });
          window.location.replace("/mrp-configuration-form");
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
        return success;
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  handleCloses = () => {
    const { open } = this.state;

    this.setState({
      open: !open,
    });
  };
  render() {
    const {
      DataToSet,
      dynamicMasterData,
      open,
      publishDate,
      categoryName,
      subCategoryName,
    } = this.state;
    const { setMRPConfList, mrpConfigrationList } = this.props;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            showBackToList={false}
            DataToSet={DataToSet}
            dynamicMasterData={dynamicMasterData}
            getListById={this.getListById}
            showTitle={MRPConfigrationJson.showTitle}
            screenTitle={MRPConfigrationJson.screenTitle}
            fieldMeta={MRPConfigrationJson.fieldMeta}
            showCancel={MRPConfigrationJson.showCancel}
            apiBaseURL={MRPConfigrationJson.apiBaseURL}
            showSaveNextBtn={MRPConfigrationJson.showSaveNextBtn}
            saveBtnText={"Search"}
            showSaveBtnMain={false}
            showSaveBtn={true}
            onSave={this.onSearch}
            isNote={true}
            isNoteValue={
              "If You Enter '0' In Amount Field And Search Then Only Selected Product MRP Will Get Updated With Respect To The Date"
            }
          />
          <br />
          {mrpConfigrationList?.productBySubCategory.length != 0 && (
            <>
              <MaterialUITableWithTextField
                tableHead={[
                  {
                    title: "Sr. No.",
                    name: "index",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                  },
                  {
                    title: "Product Code",
                    name: "code",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                  },
                  {
                    title: "Product Name",
                    name: "itemName",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                  },
                  {
                    title: "MRP",
                    name: "mrp",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "right",
                  },
                  {
                    title: "Applied MRP",
                    name: "changedValue",
                    textFieldError: "changedValueErr",
                    textField: true,
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "right",
                    placeHolder: "Enter Applied MRP",
                    error: "Applied MRP",
                  },
                ]}
                data={mrpConfigrationList?.productBySubCategory}
                setList={setMRPConfList}
                isActionColActive={false}
              />
              <br />
              <DynamicFormWithoutSave
                showBackToList={false}
                DataToSet={DataToSet}
                dynamicMasterData={dynamicMasterData}
                showSaveBtn={MRPConfigrationFooterJson.showSaveBtn}
                showTitle={MRPConfigrationFooterJson.showTitle}
                screenTitle={MRPConfigrationFooterJson.screenTitle}
                fieldMeta={MRPConfigrationFooterJson.fieldMeta}
                showCancel={MRPConfigrationFooterJson.showCancel}
                apiBaseURL={MRPConfigrationFooterJson.apiBaseURL}
                showSaveNextBtn={MRPConfigrationFooterJson.showSaveNextBtn}
                saveBtnText={"Submit"}
                onSave={this.onSearchFooter}
              />
            </>
          )}
        </Paper>
        {
          <MRPConfigTable
            handleCloses={this.handleCloses}
            open={open}
            publishDate={publishDate}
            categoryName={categoryName}
            columns={[
              {
                title: "Sr. No.",
                name: "index",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "center",
              },
              {
                title: "Product Code",
                name: "code",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
              },
              {
                title: "Product Name",
                name: "itemName",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
              },
              {
                title: "Sub Category",
                name: "name",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
              },
              {
                title: "MRP",
                name: "mrp",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "right",
              },

              {
                title: "Applied MRP",
                name: "changedValue",
                textFieldError: "changedValueErr",
                textField: true,
                positionCenter: false,
                showInscreen: true,
                alignCenter: "right",
                placeHolder: "Enter Remark",
                error: "MRP",
              },
            ]}
            onFormSave={this.saveMRPConfigtation}
            rows={mrpConfigrationList?.productBySubCategory.filter(
              (row) =>
                row.changedValue != 0 &&
                row.changedValue != "" &&
                row.changedValue > 0
            )}
          />
        }
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  mrpConfigrationList: state.mrpConfigration,
  categoryList: state.category,
});
const mapDispatchToProps = {
  showLoador,
  showNotification,
  setMRPConfList,
  getCategory,
  getProductByCategoryId,
  getSubCategoryByCategoryId,
  getProductBySubCategory,
  getCategoryByProductRateStructure,
};
export default connect(mapStateToProps, mapDispatchToProps)(MRPConfiguration);
