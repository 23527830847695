import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { multipleStockJson } from "../../DynamicFormsJson/Transaction/multipleStock";
import {
  getAreaByTalukaPostBody,
  getAreaByUserType,
} from "../../Slice/area.slice";
import { getDistrictByZone } from "../../Slice/district.slice";
import { getMultipleStockNew } from "../../Slice/manageStock.slice";
import { getRegionNew } from "../../Slice/region.slice";
import { getStateByRegion } from "../../Slice/state.slice";
import { getStockStakeHolderType } from "../../Slice/stockStakeHolderType.slice";
import { getTalukaByDistrict } from "../../Slice/taluka.slice";
import { getZoneByState } from "../../Slice/zone.slice";
import { FontAwesomeIconCompo } from "../../components/Comman/IconButton";
import { LabelCompo } from "../../components/Comman/Label";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import endpoint from "../../config/endpoints";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { downloadF } from "../PDF/PDFDownload";
class MultipleStockForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      matrixData: [],
      stockStakeHolderList: [],
      adminFlag: false,
      searchDataForPost: {},
      fieldDataForModule: {},
      dynamicMasterData: {
        stateDetails: this.props.stateList?.stateByRegion,
        regionDetail: this.props.regionList?.region,
        zoneDetail: this.props.zoneList?.zoneByState,
        districtDetail: this.props.districtList?.districtByZone,
        talukaDetail: this.props.talukaList?.talukaByDistrict,
        areaDetail: this.props.areaList.area,
        areaByTalukaDetail: this.props.areaList.areaByTaluka,
        stakeHolderTypeDetail:
          this.props.stakeHolderTypeList?.stockStakeHolderType,
        multipleStockDropdown: [
          { id: 1, name: "Opening Quantity" },
          { id: 2, name: "Purchase Quantity" },
          { id: 3, name: "Outlet Sell Quantity" },
          { id: 4, name: "Distributor Sell Quantity" },
          { id: 5, name: "GRN Quantity" },
          { id: 6, name: "In-Stock Quantity" },
          { id: 7, name: "Out-Stock Quantity" },
          { id: 8, name: "Closing Quantity" },
        ],
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.regionList !== nextProps.regionList) {
      if (this.props.regionList?.region !== nextProps.regionList?.region) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            regionDetail: nextProps.regionList?.region,
          },
        });
      }
    }

    if (this.props.stateList !== nextProps.stateList) {
      if (
        this.props.stateList?.stateByRegion !==
        nextProps.stateList?.stateByRegion
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stateDetail: nextProps.stateList?.stateByRegion,
          },
        });
      }
    }
    if (this.props.zoneList !== nextProps.zoneList) {
      if (
        this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            zoneDetail: nextProps.zoneList?.zoneByState,
          },
        });
      }
    }
    if (this.props.districtList !== nextProps.districtList) {
      if (
        this.props.districtList?.districtByZone !==
        nextProps.districtList?.districtByZone
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            districtDetail: nextProps.districtList?.districtByZone,
          },
        });
      }
    }
    if (this.props.talukaList !== nextProps.talukaList) {
      if (
        this.props.talukaList?.talukaByDistrict !==
        nextProps.talukaList?.talukaByDistrict
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            talukaDetail: nextProps.talukaList?.talukaByDistrict,
          },
        });
      }
    }
    if (this.props.areaList !== nextProps.areaList) {
      if (this.props.areaList?.area !== nextProps.areaList?.area) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.area,
          },
        });
      }
    }
    if (this.props.areaList !== nextProps.areaList) {
      if (
        this.props.areaList?.areaByTaluka !== nextProps.areaList?.areaByTaluka
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaByTalukaDetail: nextProps.areaList?.areaByTaluka,
          },
        });
      }
    }
    if (this.props.stakeHolderTypeList !== nextProps.stakeHolderTypeList) {
      if (
        this.props.stakeHolderTypeList?.stockStakeHolderType !==
        nextProps.stakeHolderTypeList?.stockStakeHolderType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stakeHolderTypeDetail:
              nextProps.stakeHolderTypeList?.stockStakeHolderType,
          },
        });
      }
    }
  }
  async componentDidMount() {
    const {
      getAreaByUserType,
      getStockStakeHolderType,
      getRegionNew,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {

      const moduleObjData = localStorage.getItem("moduleObj");
      this.setState({
        fieldDataForModule:
          moduleObjData != null ? JSON.parse(moduleObjData) : {},
      });

      const userTypeId = localStorage.getItem("userTypeId");

      if (userTypeId == 1 || userTypeId == 6) {
        this.setState({
          adminFlag: true,
        });
      }
      showLoador({ loador: true });
      await getAreaByUserType().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getStockStakeHolderType({ userTypeIds: 2 }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getRegionNew().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  getListById = async (data) => {
    const {
      getStateByRegion,
      getZoneByState,
      getDistrictByZone,
      getTalukaByDistrict,
      getAreaByTalukaPostBody,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      if (data.region && data.region.id) {
        showLoador({ loador: true });
        await getStateByRegion({ regionIds: data.region.id }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.state) {
        showLoador({ loador: true });
        await getZoneByState({ stateIds: data.state }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.zone) {
        showLoador({ loador: true });
        await getDistrictByZone({ zoneIds: data.zone }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.district) {
        showLoador({ loador: true });
        await getTalukaByDistrict({ districtIds: data.district }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.taluka && data.taluka != "") {
        let talukaJson = {
          talukaIds: data.taluka,
        };
        showLoador({ loador: true });
        await getAreaByTalukaPostBody({ talukaIds: talukaJson }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  downloadExcelHandler = (e) => {
    const { showLoador, showNotification } = this.props;
    const { searchDataForPost, dynamicMasterData } = this.state;
    console.log(searchDataForPost);
    console.log(dynamicMasterData);
    let searchDataForPostExcel = {
      fromDate: searchDataForPost.fromDate,
      toDate: searchDataForPost.toDate,
      stockStakeHolderTypeId: searchDataForPost.stockStakeHolderType,
      areaIds: searchDataForPost.areaIds.split(","),
      flag: searchDataForPost.multipleStockDropdown.id,
    };
    console.log(searchDataForPostExcel);

    if (navigator.onLine) {
      showLoador({ loador: true });
      downloadF({
        isGet: false,
        url: endpoint.manageStockHeader + "/get-holder-and-month-end-wise-report",
        ext: "xls",
        openNewTab: false,
        postBody: searchDataForPostExcel,
      }).then((response) => {
        if (!response) {
          showNotification({
            msg: serverMsg,
            severity: "error",
          });
        }
        showLoador({ loador: false });
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onSearch = (data) => {
    const { getMultipleStockNew, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      let dataForPost = {
        areaIds: data.areaIds.split(","),
        fromDate: data.fromDate,
        toDate: data.toDate,
        stockStakeHolderTypeId: data.stockStakeHolderType,
      };
      showLoador({ loador: true });
      getMultipleStockNew({
        dataForPost: dataForPost,
      }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          const matList = response.products.map((productData) => {
            const stockStakeHolderList = response.holders.map(
              (stockStakeHolderData) => {
                let value = 0;
                const filterProductList =
                  response.productStockStakeHoldersStocks.filter(
                    (rowData) =>
                      rowData.productId == productData.id &&
                      rowData.stockStakeHolderId == stockStakeHolderData.id
                  );
                if (filterProductList.length != 0) {
                  if (
                    data.multipleStockDropdown !== null &&
                    data.multipleStockDropdown.id == 1
                  ) {
                    value = filterProductList[0].primaryOpeningQty;
                  } else if (
                    data.multipleStockDropdown !== null &&
                    data.multipleStockDropdown.id == 2
                  ) {
                    value = filterProductList[0].primaryPurchaseQty;
                  } else if (
                    data.multipleStockDropdown !== null &&
                    data.multipleStockDropdown.id == 3
                  ) {
                    value = filterProductList[0].primaryOutletSellQty;
                  } else if (
                    data.multipleStockDropdown !== null &&
                    data.multipleStockDropdown.id == 4
                  ) {
                    value = filterProductList[0].primaryDistributorSellQty;
                  } else if (
                    data.multipleStockDropdown !== null &&
                    data.multipleStockDropdown.id == 5
                  ) {
                    value = filterProductList[0].primaryGrnQty;
                  } else if (
                    data.multipleStockDropdown !== null &&
                    data.multipleStockDropdown.id == 6
                  ) {
                    value = filterProductList[0].primaryInStockQty;
                  } else if (
                    data.multipleStockDropdown !== null &&
                    data.multipleStockDropdown.id == 7
                  ) {
                    value = filterProductList[0].primaryOutStockQty;
                  } else if (
                    data.multipleStockDropdown !== null &&
                    data.multipleStockDropdown.id == 8
                  ) {
                    value = filterProductList[0].primaryClosingQty;
                  }
                }
                return {
                  ...filterProductList[0],
                  value: value,
                };
              }
            );
            let productObj = {
              name: productData.name,
              stockStakeHolderList: stockStakeHolderList,
            };
            return productObj;
          });
          this.setState({
            matrixData: matList,
            stockStakeHolderList: response.holders,
            searchDataForPost: data,
          });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  render() {
    const { dynamicMasterData, stockStakeHolderList, matrixData, adminFlag, fieldDataForModule } =
      this.state;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            maxDateValid={true}
            showBackToList={false}
            getListById={this.getListById}
            dynamicMasterData={dynamicMasterData}
            showTitle={multipleStockJson.showTitle}
            screenTitle={multipleStockJson.screenTitle}
            fieldMeta={
              adminFlag
                ? multipleStockJson.fieldMeta
                : multipleStockJson.fieldMetaForStockHolder
            }
            showCancel={multipleStockJson.showCancel}
            showSaveNextBtn={multipleStockJson.showSaveNextBtn}
            saveBtnText={"Search"}
            showSaveBtnMain={false}
            showSaveBtn={true}
            onSave={this.onSearch}
          />
          <br />

          {stockStakeHolderList.length !== 0 && (

            <>
              {fieldDataForModule.editReject == 1 && (
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="right"
                >
                  <FontAwesomeIconCompo
                    className="fa-file-excel"
                    sx={{ color: "#58D68D" }}
                    title="Excel"
                    baseClassName="fas"
                    fontSize="small"
                    onClick={(e) => this.downloadExcelHandler(e)}
                  />
                </Grid>
              )}
              <br />
              <TableContainer>
                <Table aria-label="simple table" size="medium">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" style={{ minWidth: "70px" }}>
                        Sr. No.
                      </TableCell>
                      <TableCell align="center" style={{ minWidth: "150px" }}>
                        Product
                      </TableCell>
                      {stockStakeHolderList.map((data, index) => {
                        return (
                          <TableCell
                            align="center"
                            style={{ minWidth: "150px" }}
                          >
                            {data.firmName}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {matrixData.map((matrixDataRow, indexRow) => {
                      return (
                        <TableRow>
                          <TableCell align="center" component="td" scope="row">
                            {indexRow + 1}
                          </TableCell>
                          <TableCell component="td" scope="row">
                            {matrixDataRow.name}
                          </TableCell>

                          {matrixDataRow.stockStakeHolderList.map(
                            (columnName, index) => {
                              return (
                                <TableCell align="right">
                                  <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 12 }}
                                    label={columnName.value.toFixed(2)}
                                  />
                                </TableCell>
                              );
                            }
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  areaList: state.area,
  regionList: state.region,
  stateList: state.states,
  zoneList: state.zone,
  districtList: state.district,
  talukaList: state.taluka,
  stakeHolderTypeList: state.stockStakeHolderType,
  multipleStockList: state.manageStockByDate,
});
const mapDispatchToProps = {
  getAreaByTalukaPostBody,
  getRegionNew,
  getStateByRegion,
  getZoneByState,
  getDistrictByZone,
  getTalukaByDistrict,
  getStockStakeHolderType,
  getAreaByUserType,
  getMultipleStockNew,
  showLoador,
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(MultipleStockForm);
