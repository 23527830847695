import React from "react";
import { connect } from "react-redux";
import { paymentFormJSON } from "../../DynamicFormsJson/Transaction/paymentForm";
import { getBeatByArea } from "../../Slice/beat.slice";
import { getOutletByBeatsAndAssignedStockHolder } from "../../Slice/outlet.slice";
import { getPayment, getUpdatedPaymentPost, getLatestPayment, setPayment } from "../../Slice/payment.slice";
import { getAreaByUserType } from "../../Slice/area.slice";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import endpoint from "../../config/endpoints";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import { PaymentColumns } from "../../tableColumns/table-columns";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { downloadF } from "../PDF/PDFDownload";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import { Paper } from "@mui/material";
import { apiPostForDownload } from "../../utils/api_service";
class OrderHistoryTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        areaDetail: this.props.areaList?.area,
        beatDetail: this.props.beatList?.beat,
        outletDetail: this.props.outletList?.outlets,
      },
      outletIds: "",
      fromDate: "",
      toDate: "",
      DataToSet: [],
    };
  }

  async componentDidMount() {
    const {
      getLatestPayment,
      getAreaByUserType,
      showLoador,
      setPayment,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });

      setPayment({ row: [] });

      getAreaByUserType().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.areaList !== nextProps.areaList) {
      if (this.props.areaList?.area !== nextProps.areaList?.area) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.area,
          },
        });
      }
    }

    if (this.props.beatList !== nextProps.beatList) {
      if (this.props.beatList?.beat !== nextProps.beatList?.beat) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            beatDetail: nextProps.beatList?.beat,
          },
        });
      }
    }

    if (this.props.outletList !== nextProps.outletList) {
      if (this.props.outletList?.outlets !== nextProps.outletList?.outlets) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            outletDetail: nextProps.outletList?.outlets,
          },
        });
      }
    }
  }

  getListById = async (data) => {
    const { getBeatByArea, getOutletByBeatsAndAssignedStockHolder, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      if (data.area) {
        showLoador({ loador: true });
        await getBeatByArea({ areaId: data.area }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.beat) {
        let beatIdsJson = {
          beatIds: data.beat
        }
        showLoador({ loador: true });
        await getOutletByBeatsAndAssignedStockHolder({ beatIds: beatIdsJson }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onSearchData = async (data) => {
    const { getUpdatedPaymentPost, showLoador, showNotification } = this.props;
    this.setState({
      outletIds: data.outlet,
      fromDate: data.fromDate,
      toDate: data.toDate,
    });

    let paymentDataJson = {
      outletIds: data.outlet,
      fromDate: data.fromDate,
      toDate: data.toDate,
    }

    if (navigator.onLine) {
      showLoador({ loador: true });
      await getUpdatedPaymentPost({ paymentData: paymentDataJson }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          console.log(response);
          let total = 0;
          response.length !== 0 &&
            response.map((paymentObject) => {
              total = total + +paymentObject.amt;
            });
          this.setState({
            DataToSet: [
              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "Total Payment",
                value: total.toFixed(2),
              },
            ],
          });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  rowPDF = (rowData) => {
    const { showNotification, showLoador } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      downloadF({
        url:
          endpoint.paymentReceiptOutlet +
          "/report-by-id?documentType=1" +
          "&id=" +
          rowData.id,
        ext: "pdf",
        openNewTab: true,
      }).then((response) => {
        showLoador({ loador: false });
        if (!response) {
          this.props.showNotification({
            msg: serverMsg,
            severity: "error",
          });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };


  onExportHandler = () => {
    const { fromDate, outletIds, toDate } = this.state;
    const { showLoador } = this.props;

    let paymentDataJson = {
      outletIds: outletIds,
      fromDate: fromDate,
      toDate: toDate,
    }

    showLoador({ loador: true });
    apiPostForDownload({
      url:
        endpoint.paymentReceiptOutlet +
        "/excel-by-date-and-outlets",
      postBody: paymentDataJson,
    }).then(({ data, success }) => {
      showLoador({ loador: false });
      const blb = new Blob([data], { type: "text/plain" });
      const reader = new FileReader();
      reader.addEventListener("loadend", (e) => {
        if (success) {
          var blob = new Blob([data], {
            type: "application/xls",
          });
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "Report_" + new Date() + ".xls";
          link.click();
        }
      });
      reader.readAsText(blb);
    });
  };

  render() {
    const { dynamicMasterData, DataToSet } = this.state;
    const { paymentList, getLatestPayment } = this.props;
    return (
      <>
        <Paper
          sx={{
            p: 0,
            pt: 0,
            borderRadius: 0,
          }}
          elevation={0}
        >
          <DynamicMainScreen
            showViewButtonInTable={false}
            dynamicMasterData={dynamicMasterData}
            searchList={paymentFormJSON.searchList}
            onSearchData={this.onSearchData}
            formPathView={paymentFormJSON.formPathView}
            formPath={paymentFormJSON.formPath}
            screenTitle={paymentFormJSON.screenTitle}
            fieldMeta={paymentFormJSON.fieldMeta}
            getListById={this.getListById}
            isActiveURL={paymentFormJSON.isActiveURL}
            showAddButton={paymentFormJSON.showAddButton}
            tableColumnsToFilter={paymentFormJSON.tableColumnsToFilter}
            tableColumns={PaymentColumns}
            tableData={paymentList.paymentData}
            apiBaseURL={paymentFormJSON.apiBaseURL}
            callApi={false}
            staticExcel={true}
            generateExcelStatic={this.onExportHandler}
            getTableDataById={true}
            showSearchBox={true}
            showPdf={false}
            rowPDF={this.rowPDF}
            rowEdit={false}
            showEditIcon={true}
            showDeleteIcon={false}
            showPegination={false}
            deleteConditionName="deleteStatus"
            deleteConditionValue="0"
            getTableData={getLatestPayment}
            showPdfDownload={paymentFormJSON.showPdfDownload}
            isActionColActive={false}
          />
          <br />
          <DynamicDetails DataToSet={DataToSet} />
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  areaList: state.area,
  outletList: state.outlet,
  beatList: state.beat,
  paymentList: state.payment,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getBeatByArea,
  getOutletByBeatsAndAssignedStockHolder,
  getPayment,
  getLatestPayment,
  getUpdatedPaymentPost,
  setPayment,
  getAreaByUserType,
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderHistoryTable);
