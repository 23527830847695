import { Grid, Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React, { Component } from "react";
import { connect } from "react-redux";
import { downloadF } from "../../Pages/PDF/PDFDownload";
import { getAreaByUserType } from "../../Slice/area.slice";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import ColumnFilterTablePopUp from "../Comman/ColumnFilterPopup";
import { LabelCompo } from "../Comman/Label";
import LandingScreenHeader from "../Comman/LandingScreenHeader";
import MatrixTable from "../Comman/RT/MatrixTable";
import { Loading1 } from "../Loading1";
import DynamicForm from "./DynamicReportFormMain";

import { showLoador, showNotification } from "../../Pages/Landing/Landing.slice";
import { getReportName } from '../../Slice/matrixReport.slice';
import AutoComplete from "../../components/Comman/AutoComplete";
import { apiGet, apiPost } from "../../utils/api_service";
import { FontAwesomeIconCompo } from "../Comman/IconButton";
import { generateExcelForMatrix, generatePDF } from "../GeneratePDFExcel";

import endpoint from "../../config/endpoints";
import { withRouter } from "../withRouter";
export const OperationMode = {
    Add: "Add",
    Edit: "Edit",
    Status: "Status",
};


class DynamicMainScreen extends Component {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
        this.state = {
            tableColumnsToFilter: this.props.tableColumnsToFilter,
            fieldData: {},
            showTable: true,
            showForm: false,
            currentOperationMode: "",
            searchValue: "",
            isLoading: false,
            fromDate: "",
            reportName: {},
            graphName: {},
            formErrors: {},
            dynamicMasterData: {},
            tableData: [],
            reportTableCheckedInfo: {},
            openFilter: false,
            columns: [],
            fieldDataForModule: {},
        };
    }


    toggleFormTableVisibility = () => {
        const { showForm, showTable } = this.state;
        this.setState({
            showForm: !showForm,
            showTable: !showTable,
            openSnackbar: true,
        });
    };
    getListById = async (dataToSearch) => {
        const {
            // getAreaByUserType,
            showLoador,
            showNotification,
        } = this.props;
        if (navigator.onLine) {
            const { reportName } = this.state
            const fieldMeta = JSON.parse(reportName.parameterRequired)
            console.log(fieldMeta)

            let filterList = fieldMeta.filter((fieldObj) => fieldObj.getListFrom && fieldObj.getListFrom != "")
            Promise.all(filterList.map(fieldObj => this.getDynamicListById(fieldObj, dataToSearch)))
        } else {
            showNotification({ msg: msgConstant.noInternetMsg, severity: "error" });
        }
    }
    onDataChange = (fieldName, newValue) => {
        const { fieldData, reportName } = this.state;
        const fieldMeta = JSON.parse(reportName.parameterRequired)

        const dataToSearch = {};
        const dataToReset = { ...this.state.fieldData };
        let listError = false;
        fieldMeta.map((currentField) => {
            if (currentField.dataKey === fieldName && currentField.getListId) {
                // dataToSearch[fieldName] = newValue;
                const masterKeyList = currentField.getListId.split(",");
                masterKeyList.map((key) => {
                    if (fieldName === key) {
                        dataToSearch[key] = newValue;
                    } else if (fieldData[key]) {
                        dataToSearch[key] = fieldData[key];
                    }
                });

                this.getListById(dataToSearch);
            }
            if (currentField.getListFrom) {
                const masterKeyList = currentField.getListFrom.split(",");
                masterKeyList.map((key) => {
                    if (key === fieldName) {
                        listError = true;
                    }
                });
            }
            if (!listError && fieldData[currentField.dataKey]) {
                dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
            }
        });

        dataToReset[fieldName] = newValue;
        this.setState({
            fieldData: dataToReset,
        });
    };


    async componentDidMount() {
        this.getReportInfo(this.props.params.id)

        const moduleObjData = localStorage.getItem("moduleObj");

        this.setState({
            fieldDataForModule:
                moduleObjData != null ? JSON.parse(moduleObjData) : {},
        });
    }

    getReportInfo = (id) => {
        const { getReportName, showLoador, showNotification } = this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            getReportName({ locationPath: id }).then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });
        }
        else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.params != nextProps.params && this.props.params.id != nextProps.params.id) {
            this.getReportInfo(nextProps.params.id)
        }
    }

    getDynamicList = async fieldObj => {
        if (fieldObj.localList && fieldObj.localList.length != 0) {
            let LocalList = fieldObj.localList.map((dataObj) => {
                return { id: dataObj.key, name: dataObj.label }
            })

            this.setState({
                dynamicMasterData: {
                    ...dynamicMasterData,
                    [fieldObj.masterName]: LocalList
                }
            })
            return true;
        }
        else {
            console.log(fieldObj)
            const { showLoador, showNotification } = this.props
            showLoador({ loador: true });
            let response = await apiGet({
                url: "/api/" + fieldObj.url,
            }).then(({ data, success }) => {
                if (success) {
                    showLoador({ loador: false });
                } else {
                    showLoador({ loador: false });
                    showNotification({ msg: serverMsg, severity: "error" });
                }
                return { data: data.data, success }
            })
            if (response.success) {
                let masterListData = fieldObj.isContent == 1 && response.data && response.data.content ? response.data.content : response.data ? response.data : []

                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        [fieldObj.masterName]: masterListData.map((dataObj) => {
                            return { ...dataObj, id: fieldObj.selectKey && dataObj[fieldObj.selectKey] ? dataObj[fieldObj.selectKey] : dataObj.id, name: fieldObj.selectLabel && dataObj[fieldObj.selectLabel] ? dataObj[fieldObj.selectLabel] : dataObj.name }
                        })
                    }
                })
            }

            return response;
        }
    };
    getDropdownList = async (report) => {
        const {
            // getAreaByUserType,
            showLoador,
            showNotification,
        } = this.props;
        const { dynamicMasterData } = this.state
        if (navigator.onLine) {
            const fieldMeta = JSON.parse(report.parameterRequired)
            console.log(fieldMeta)

            let filterList = fieldMeta.filter((fieldObj) => (fieldObj.url && fieldObj.url != "" && fieldObj.getListFrom == "") || fieldObj.localList && fieldObj.localList.length != 0)
            Promise.all(filterList.map(fieldObj => this.getDynamicList(fieldObj)))
        } else {
            showNotification({ msg: msgConstant.noInternetMsg, severity: "error" });
        }
    }
    onFormSearch = (flag) => {
        const { fieldData, reportName } = this.state;
        const fieldMeta = JSON.parse(reportName.parameterRequired)
        if (fieldMeta != null) {
            const dataToSave = {};
            fieldMeta.forEach((currentField) => {
                if (currentField.controlType === "datepicker") {
                    if (fieldData[currentField.dataKey]) {
                        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
                            ? fieldData[currentField.dataKey].format("YYYY-MM-DD")
                            : "";
                    } else {
                        dataToSave[currentField.dataKey] = "";
                    }
                }
                else if (currentField.controlType === "multiSelect") {
                    dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
                        ? fieldData[currentField.dataKey].join()
                        : "";
                } else {
                    dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
                        ? fieldData[currentField.dataKey]
                        : "";
                }
            });

            if (flag = 1) {
                this.getSearchList(dataToSave, reportName)
            }
            return dataToSave
        }
    }

    getSearchList = (dataToSearch, reportName) => {
        const { showLoador, showNotification } = this.props
        console.log(dataToSearch)
        console.log(reportName.queryParams)
        let queryParams1 = JSON.parse(reportName.queryParam1) ? JSON.parse(reportName.queryParam1) : []
        let queryParams2 = JSON.parse(reportName.queryParam2) ? JSON.parse(reportName.queryParam2) : []
        let queryParams3 = JSON.parse(reportName.queryParam3) ? JSON.parse(reportName.queryParam3) : []

        let postBody1 = {}
        queryParams1.map((qData) => {
            postBody1 = {
                ...postBody1,
                [qData.parameterKey]: {
                    isIn: qData.isIn,
                    value: dataToSearch[qData.parameterValue]
                }
            }
        })
        let postBody2 = {}
        queryParams2.map((qData) => {
            postBody2 = {
                ...postBody2,
                [qData.parameterKey]: {
                    isIn: qData.isIn,
                    value: dataToSearch[qData.parameterValue]
                }
            }
        })
        let postBody3 = {}
        queryParams3.map((qData) => {
            postBody3 = {
                ...postBody3,
                [qData.parameterKey]: {
                    isIn: qData.isIn,
                    value: dataToSearch[qData.parameterValue]
                }
            }
        })


        showLoador({ loador: true });
        apiPost({
            url: "/api/" + reportName.apiName + "?reportId=" + reportName.id,
            postBody: [postBody1, postBody2, postBody3]

        }).then(({ data, success }) => {
            if (!success) {
                showLoador({ loador: false });
                showNotification({ msg: serverMsg, severity: "error" });
            }
            else {
                showLoador({ loador: false });

                let tableData = data.data

                if (Object.keys(tableData).length != 0) {
                    console.log(reportName.isPdf)
                    let parameterCheck = reportName.parameterCheck == "" ? "" : JSON.parse(reportName.parameterCheck)
                    let tableColumns3 = reportName.tableColumns3 ? JSON.parse(reportName.tableColumns3) : []

                    let tableColumns1 = reportName.tableColumns1 ? JSON.parse(reportName.tableColumns1) : []
                    let tableColumns2 = reportName.tableColumns2 ? JSON.parse(reportName.tableColumns2) : []
                    let matrixData = []
                    let isTotal2 = tableColumns2.filter((col) => col.isTotal == 1)
                    let isTotal3 = tableColumns3.filter((col) => col.isTotal == 1)
                    let isTotal1 = tableColumns1.filter((col) => col.isTotal == 1)
                    let totalExtra = []
                    let dataToSetTotal = []
                    let finalAmt = {
                    }
                    tableColumns2.map((col) => {
                        if (col.isExtra == 1) {
                            totalExtra.push({
                                isTotal: col.isTotal,
                                value: col.isTotal == 1 ? 0 : "",
                                label: col.fieldName
                            })
                        }
                    })
                    tableData.response2.map((cellData1, index1) => {
                        let cellData1Value = []
                        let cellExtraData1Value = []
                        tableColumns2.map((col) => {
                            if (col.isExtra == undefined || col.isExtra == 0) {
                                cellData1Value.push(cellData1[col.fieldName])
                            } else {
                                if (col.isTotal == 1) {
                                    totalExtra = totalExtra.map((mainRow) => {

                                        if (mainRow.isTotal == 1) {
                                            return { ...mainRow, value: col.fieldName === mainRow.label ? mainRow.value + cellData1[col.fieldName] : mainRow.value }
                                        }
                                        return mainRow
                                    })
                                }
                                cellExtraData1Value.push(cellData1[col.fieldName])
                            }
                        })
                        let dataToSave = {
                            name: cellData1Value.join(),
                            extraData: cellExtraData1Value
                        }

                        let details = []
                        let total1 = isTotal3
                        tableData.response1.map((cellData2, index2) => {
                            let flag = 0
                            tableData.response3.map((cellData3, index3) => {

                                if (cellData3[parameterCheck.xAxisKey] == cellData2[parameterCheck.xAxisValue] && cellData3[parameterCheck.yAxisKey] == cellData1[parameterCheck.yAxisValue]) {
                                    flag = 1
                                    let cellData3Value = []
                                    tableColumns3.map((col) => {

                                        let dataObj = {
                                            label: col.columnName,
                                            value: col.isTotal == 1 && cellData3[col.fieldName] ? +(cellData3[col.fieldName].toFixed(2)) : cellData3[col.fieldName]
                                        }
                                        cellData3Value.push(dataObj)
                                    })
                                    if (isTotal1.length != 0) {
                                        total1 = total1.map((totalData) => {
                                            return {
                                                ...totalData,
                                                label: totalData.columnName,
                                                value: totalData.value ? totalData.value + cellData3[totalData.fieldName] : cellData3[totalData.fieldName]
                                            }
                                        })
                                    }

                                    details.push(cellData3Value)

                                }

                            })
                            if (flag == 0) {
                                details.push([{

                                    label: "",
                                    value: "-"
                                }])
                            }
                        })


                        if (isTotal1.length != 0) {

                            details.push(total1.map((totalData) => {
                                return { ...totalData, value: totalData.value ? +(totalData.value.toFixed(2)) : 0 }
                            }))
                        }


                        dataToSave = {
                            ...dataToSave,
                            detailsList: details
                        }
                        matrixData.push(dataToSave)
                    })
                    let dataToSet = {
                        name: "Total"
                    }

                    let columns = tableData.response1.map((headCell, index) => {
                        let total2 = isTotal3

                        tableData.response3.map((cellData3, index3) => {

                            if (cellData3[parameterCheck.xAxisKey] == headCell[parameterCheck.xAxisValue]) {
                                if (isTotal2.length != 0) {
                                    total2 = total2.map((totalData) => {

                                        return {
                                            ...totalData,
                                            label: totalData.columnName,
                                            value: totalData.value ? totalData.value + cellData3[totalData.fieldName] : cellData3[totalData.fieldName]
                                        }
                                    })
                                }
                                if (isTotal1.length != 0) {
                                    total2.map((totalData) => {
                                        finalAmt = {
                                            ...finalAmt,
                                            [totalData.fieldName]: finalAmt[totalData.fieldName] ? finalAmt[totalData.fieldName] + cellData3[totalData.fieldName] : cellData3[totalData.fieldName]
                                        }

                                    })
                                }
                            }
                        })

                        dataToSetTotal.push(total2.map((totalData) => {
                            return { ...totalData, value: totalData.value ? +(totalData.value.toFixed(2)) : 0 }
                        }))
                        let colData = tableColumns1.map((col) => {
                            return headCell[col.fieldName]
                        })
                        return (
                            {
                                name: colData.join()
                            }
                        );
                    })

                    console.log(finalAmt)
                    Object.keys(finalAmt)
                    let rowList = isTotal3.map((totalData) => {
                        return {
                            ...totalData,
                            label: totalData.columnName,
                            value: finalAmt[totalData.fieldName] ? +(finalAmt[totalData.fieldName].toFixed(2)) : 0
                        }
                    })
                    let filterDataRow = rowList.filter((row) => row.value && row.value != 0)
                    totalExtra = totalExtra.map((rowInfo) => {
                        return [rowInfo]
                    })
                    let detailsListInfo = [...totalExtra, ...dataToSetTotal]
                    if (filterDataRow.length != 0) {
                        detailsListInfo = [...totalExtra, ...dataToSetTotal, rowList]
                    }
                    dataToSet = {
                        ...dataToSet,
                        detailsList: detailsListInfo,
                    }
                    if (isTotal2.length != 0) {
                        matrixData = [...matrixData, dataToSet]
                    }
                    console.log("matrixData")

                    console.log(matrixData)
                    this.setState({
                        tableData: matrixData,
                        columns: isTotal1.length != 0 ? [...columns,
                        {
                            name: "Total"
                        }
                        ] : columns
                    })
                }
            }
        })

    }


    getTotalData = (tableData, reportName) => {
        let totalList = []
        reportName.reportColumnNames.map((row) => {
            if (row.isChecked && row.isList != 1) {
                if (row.isTotal == 1) {
                    let total = 0
                    tableData.map((rowData) => {
                        total = total + +rowData[row.name]
                    })
                    totalList.push(+(total.toFixed(2)))
                }
                else {
                    totalList.push("")
                }
            }
        })
        return totalList
    };
    resetData = () => {
        this.setState({
            fieldData: {},
        });
    };
    onAutocompleteChange = (name, newValue) => {
        if (newValue != null) {

            if (name == "reportName") {

                const filterData = this.props.report.report.filter((row) => row.id == newValue)
                let parameter = JSON.parse(filterData[0].parameterRequired) != null ? JSON.parse(filterData[0].parameterRequired) : []
                this.setState({
                    tableData: [],
                    fieldData: {},
                    formErrors: {},
                    [name]: filterData.length != 0 ? filterData[0] : {}
                })
                if (parameter.length != 0) {
                    this.getDropdownList(filterData[0])
                } else {
                    this.getSearchList({}, filterData[0])
                }

            } else {
                const filterData = this.state.reportName.graphColumns.filter((row) => row.id == newValue)
                this.setState({
                    [name]: filterData.length != 0 ? filterData[0] : {}
                })
            }
        } else {
            this.setState({
                [name]: {},
                tableData: [],
                fieldData: {},
            })
        }



    };
    onAutocompleteChangeGraph = (reportName) => (name, newValue) => {
        if (newValue != null) {

            const filterData = reportName.graphColumns.filter((row) => row.graphId == newValue)
            this.setState({
                tableData: this.state.tableData.map((tableData) => {
                    return { ...tableData, graphName: tableData.reportName.id != reportName.id ? tableData.graphName : filterData[0] }
                })
            })

        } else {
            this.setState({
                tableData: this.state.tableData.map((tableData) => {
                    return { ...tableData, graphName: tableData.reportName.id != reportName.id ? tableData.graphName : {} }
                })
            })
        }



    };
    onClickFilter = (reportData) => {
        this.setState({
            reportTableCheckedInfo: reportData.reportName,
            openFilter: !this.state.openFilter
        })
    }
    onClickGraph = (reportData) => {
        const tableInfo = this.state.tableData.map((reportInfo) => {
            if (reportInfo.reportName.id == reportData.reportName.id) {
                return { ...reportInfo, openGraph: !reportInfo.openGraph, graphName: {} }
            }
            return reportInfo
        })
        this.setState({
            tableData: tableInfo
        })
    }

    getName = (List, fieldName) => {
        const nameList = List.map((ListData) => {
            const filterData = this.state.dynamicMasterData[fieldName].filter((dynamicList) => dynamicList.id == ListData)
            if (filterData.length != 0) {
                return filterData[0].name
            }
            return ""
        })
        return nameList.join(", ")
    }


    generateExcelMethod = (reportDetails, tableData) => {


        let matList = []
        let tableColumns2 = reportDetails.tableColumns2 ? JSON.parse(reportDetails.tableColumns2) : []
        let tableColumns2Data = tableColumns2.filter((col) => col.isExtra == 1)
        var columns = [tableColumns2.length != 0 ? tableColumns2[0].columnName : ""]
        tableColumns2Data.map((reportColumnNamesData, index) => {
            columns.push(reportColumnNamesData.columnName)

        })
        this.state.columns.map((reportColumnNamesData, index) => {
            columns.push(reportColumnNamesData.name)

        })
        tableData.map((cellData1, index1) => {
            let rowData = []
            rowData.push(cellData1.name)
            cellData1.extraData && cellData1.extraData.map((extraData, index2) => {
                rowData.push(extraData)
            })
            cellData1.detailsList && cellData1.detailsList.map((cellData2, index2) => {
                let info = ""
                if (cellData2.length == 1) {
                    cellData2.map((infoData) => {
                        let rowInfo = infoData.label && infoData.label != "" ? infoData.label + " : " + infoData.value : infoData.value
                        info = info == "" ? rowInfo : info + "," + rowInfo
                    })
                    rowData.push(cellData2[0].value)
                } else {
                    cellData2.map((infoData) => {
                        let rowInfo = infoData.label && infoData.label != "" ? infoData.label + " : " + infoData.value : infoData.value
                        info = info == "" ? rowInfo : info + "," + rowInfo
                    })
                    rowData.push(info)
                }

            })
            matList.push(rowData)
        })
        console.log(matList)
        const fieldMeta = JSON.parse(this.state.reportName.parameterRequired)

        let headerList = [""]
        fieldMeta.map((fieldInfo) => {
            headerList.push(fieldInfo.label)
            if (fieldInfo.controlType == "datepicker") {

                headerList.push(this.state.fieldData[fieldInfo.dataKey] ?
                    this.state.fieldData[fieldInfo.dataKey].format('DD-MM-YYYY') : "")
            } else if (fieldInfo.controlType == "multiSelect") {

                headerList.push(this.state.fieldData[fieldInfo.dataKey] ?
                    this.getName(this.state.fieldData[fieldInfo.dataKey], fieldInfo.dataKey) : "")
            }
            else if (fieldInfo.controlType == "autocomplete") {

                headerList.push(this.state.fieldData[fieldInfo.dataKey] ?
                    this.getName([this.state.fieldData[fieldInfo.dataKey]], fieldInfo.dataKey) : "")
            } else {
                headerList.push(this.state.fieldData[fieldInfo.dataKey] ?
                    this.state.fieldData[fieldInfo.dataKey] : "")

            }

        })
        generateExcelForMatrix({ columns, rows: matList, headerList, title: reportDetails.name })
    }
    getDynamicListById = async (fieldObj, dataToSearch) => {
        let str = "?"
        fieldObj.filterListParameter && fieldObj.filterListParameter.map((dataToSet) => {
            if (dataToSearch[dataToSet.value]) {
                str = str + dataToSet.key + "=" + dataToSearch[dataToSet.value] + "&"
            }
        })
        let strCheck = str.split("&");
        console.log()
        if (strCheck.length == fieldObj.filterListParameter.length + 1) {
            const { showLoador, showNotification } = this.props
            showLoador({ loador: true });
            let response = {}
            if (fieldObj.isApi == 0) {
                if (fieldObj.isPostBody == undefined || fieldObj.isPostBody == 0) {
                    response = await apiPost({
                        url: "/api/" + fieldObj.url + str,
                    }).then(({ data, success }) => {
                        if (success) {
                            showLoador({ loador: false });
                        } else {
                            showLoador({ loador: false });
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                        return { data: data.data, success }
                    })
                } else {
                    let postObj = {}
                    fieldObj.filterListParameter && fieldObj.filterListParameter.map((dataToSet) => {
                        if (dataToSearch[dataToSet.value]) {
                            postObj = {
                                ...postObj,
                                [dataToSet.key]: typeof dataToSearch[dataToSet.value] != 'string' ? dataToSearch[dataToSet.value] : dataToSearch[dataToSet.value].split(',')
                            }
                        }
                    })
                    response = await apiPost({
                        url: "/api/" + fieldObj.url,
                        postBody: postObj
                    }).then(({ data, success }) => {
                        if (success) {
                            showLoador({ loador: false });
                        } else {
                            showLoador({ loador: false });
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                        return { data: data.data, success }
                    })
                }
            } else {
                response = await apiGet({
                    url: "/api/" + fieldObj.url + str,
                }).then(({ data, success }) => {
                    if (success) {
                        showLoador({ loador: false });
                    } else {
                        showLoador({ loador: false });
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                    return { data: data.data, success }
                })
            }

            if (response.success) {
                let masterListData = fieldObj.isContent == 1 && response.data && response.data.content ? response.data.content : response.data ? response.data : []

                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        [fieldObj.masterName]: masterListData.map((dataObj) => {
                            return { ...dataObj, id: fieldObj.selectKey && dataObj[fieldObj.selectKey] ? dataObj[fieldObj.selectKey] : dataObj.id, name: fieldObj.selectLabel && dataObj[fieldObj.selectLabel] ? dataObj[fieldObj.selectLabel] : dataObj.name }
                        })
                    }
                })
            }

            return response;

        }
        return true;
    };

    setReportFilterList = (reportList) => {
        const tableList = this.state.tableData.map((reportData) => {
            if (this.state.reportTableCheckedInfo.id == reportData.reportName.id) {
                return {
                    ...reportData, reportName: {
                        ...reportData.reportName,
                        reportColumnNames: reportList
                    }
                }
            }
            return reportData
        })
        console.log(tableList)
        this.setState({
            openFilter: false,
            tableData: tableList,
            reportTableCheckedInfo: {}
        })
    }
    render() {
        const {
            screenTitle = "",
            PDFButton,
            buttonCenter,
            EXCELButton,
            marginTop,
            searchButton,
            report
        } = this.props;
        const { fieldData,
            formErrors,
            columns,
            reportName,
            reportTableCheckedInfo,
            openFilter,
            dynamicMasterData,
            tableData,
            isLoading,
            fieldDataForModule,
        } = this.state;
        return (
            <>
                <Paper sx={{ p: 2, pt: 2, borderRadius: 2 }} elevation={0}>
                    <LandingScreenHeader
                        tableColumnsToFilter={[]}
                        screenTitle={screenTitle}
                        showPdfDownload={false}
                        showExcelDownload={false}
                        showAddButton={false}
                        showTable={false}
                    />
                    <br />

                    <Grid container spacing={1}>

                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                        >
                            <LabelCompo
                                className="text-black"
                                style={{ fontSize: 16 }}
                                label={"Select Report"}
                            />

                            <AutoComplete
                                keyColName={"id"}
                                value={Object.keys(reportName).length != 0 && reportName.id}
                                name="reportName"
                                options={report.report}
                                onChange={this.onAutocompleteChange}
                            // isError={formErrors[currentField.dataKey]?.isError}
                            // errorText={formErrors[currentField.dataKey]?.errorText}
                            />
                        </Grid>

                    </Grid>
                    {Object.keys(reportName).length != 0 &&
                        <>
                            {JSON.parse(reportName.parameterRequired) != null && JSON.parse(reportName.parameterRequired).length != 0 &&

                                <DynamicForm
                                    reportDetails={reportName}
                                    dynamicMasterData={dynamicMasterData}
                                    fieldData={fieldData}
                                    formErrors={formErrors}
                                    onDataChange={this.onDataChange}
                                    buttonCenter={buttonCenter}
                                    searchButton={searchButton}
                                    PDFButton={PDFButton}
                                    EXCELButton={EXCELButton}
                                    marginTop={marginTop}
                                    onFormSave={this.onFormSearch}
                                />
                            }
                            <>
                                <Grid
                                    item
                                    sm={12}
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    sx={{ marginTop: 3 }}
                                    rowSpacing={2}
                                    columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                                    container
                                    justifyContent="right"
                                >

                                    {fieldDataForModule.editReject == 1 && reportName.isExcel && tableData.length != 0 && (
                                        <>
                                            &nbsp; &nbsp;
                                            <FontAwesomeIconCompo
                                                color="secondary"
                                                fontSize="small"
                                                title="Excel"
                                                baseClassName="fas"
                                                className="fa-file-excel"
                                                onClick={() => { this.generateExcelMethod(reportName, tableData) }}

                                            />
                                        </>
                                    )}

                                </Grid>
                            </>
                            {
                                tableData.length != 0 && <>
                                    <br />
                                    <MatrixTable
                                        tableData={tableData}
                                        reportDetails={reportName}
                                        columns={columns}
                                    />
                                </>
                            }

                        </>}
                </Paper>
                {isLoading && <Loading1 />}


                {openFilter && <ColumnFilterTablePopUp columnList={reportTableCheckedInfo.reportColumnNames} setReportData={this.setReportFilterList} openDialog={openFilter} setOpenDialouge={(openDia) => {
                    this.setState({
                        openFilter: openDia
                    })
                }}
                />}
            </>
        );
    }
}
const mapStateToProps = (state) => ({ report: state.report });
const mapDispatchToProps = { downloadF, getReportName, getAreaByUserType, showLoador, showNotification };
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(DynamicMainScreen);
