import React from "react";
import { connect } from "react-redux";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import { TagJson } from "../../../DynamicFormsJson/MastersJSON/tag";
class TagForm extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <>
                <DynamicForm
                    formPath={TagJson.formPath}
                    screenTitle={TagJson.screenTitle}
                    fieldMeta={TagJson.fieldMeta}
                    tableColumnsToFilter={TagJson.tableColumnsToFilter}
                    apiBaseURL={TagJson.apiBaseURL}
                    baseIdColumn={TagJson.baseIdColumn}
                    isLog={true}
                    type={0}
                />
            </>
        );
    }
}
const mapStateToProps = (state) => ({
});
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(TagForm);
