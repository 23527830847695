import React from "react";
import { connect } from "react-redux";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { AddDistributorJson } from "../../DynamicFormsJson/Transaction/addDistributor";


import { FormControlLabel, Grid, Paper, Radio, RadioGroup } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import { withRouter } from "../../components/withRouter";
import { redColor, titleColor } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import * as msgConstant from "../../config/messageconstant";
import {
    noInternetMsg,
    saveFailedMsg,
    serverMsg
} from "../../config/messageconstant";
import { getAreaByTalukaPostBodyUpdated, getAreaByUserType } from "../../Slice/area.slice";
import { saveDistributorInquiry, setSaveDistributorInquiry } from "../../Slice/distributorInquiry.slice";
import { getDistrictByZone } from "../../Slice/district.slice";
import { getRegionNew } from "../../Slice/region.slice";
import { getStateByRegion } from "../../Slice/state.slice";
import { getTalukaByDistrictPost } from "../../Slice/taluka.slice";
import { getZoneByState } from "../../Slice/zone.slice";
import { apiGet } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import GoogleMapForAreaExploration from "./GoogleMapForAreaExploration";
import DynamicFormForDocumentUpload from "../../components/dynamicscreens/DynamicFormForDocumentUpload";


class BasicInformation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showLocation: false,
            latitude: "",
            longitude: "",
            locationValue: "1",
            openMap: false,
            fieldData: {},
            address: "",
            userTypeId: "",
            googleApiKey: "AIzaSyDvKXkZ-S03qxR-8b8HLnCOKr36eYRxC2k",
            dynamicMasterData: {
                regionDetail: this.props.regionList?.regionNew,
            },
        };
    }
    componentWillReceiveProps(nextProps) {
        const { openMap } = this.state;

        if (this.props.regionList !== nextProps.regionList) {
            if (this.props.regionList?.regionNew !== nextProps.regionList?.regionNew) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        regionDetail: nextProps.regionList?.regionNew,
                    },
                });
            }
        }
        if (this.props.stateList !== nextProps.stateList) {
            if (
                this.props.stateList?.stateByRegion !==
                nextProps.stateList?.stateByRegion
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        stateDetail: nextProps.stateList?.stateByRegion,
                    },
                });
            }
        }
        if (this.props.zoneList !== nextProps.zoneList) {
            if (
                this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        zoneDetail: nextProps.zoneList?.zoneByState,
                    },
                });
            }
        }
        if (this.props.districtList !== nextProps.districtList) {
            if (
                this.props.districtList?.districtByZone !==
                nextProps.districtList?.districtByZone
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        districtDetail: nextProps.districtList?.districtByZone,
                    },
                });
            }
        }
        if (this.props.talukaList !== nextProps.talukaList) {
            if (
                this.props.talukaList?.talukaByDistrict !==
                nextProps.talukaList?.talukaByDistrict
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        talukaDetail: nextProps.talukaList?.talukaByDistrict,
                    },
                });
            }
        }


        if (this.props.areaList !== nextProps.areaList) {
            if (this.state.userTypeId == 1 || this.state.userTypeId == 6) {
                if (
                    this.props.areaList?.areaByTaluka !== nextProps.areaList?.areaByTaluka
                ) {
                    this.setState({
                        dynamicMasterData: {
                            ...this.state.dynamicMasterData,
                            areaDetail: nextProps.areaList?.areaByTaluka,
                        },
                    });
                }
            } else {
                if (
                    this.props.areaList?.area !== nextProps.areaList?.area
                ) {
                    this.setState({
                        dynamicMasterData: {
                            ...this.state.dynamicMasterData,
                            areaDetail: nextProps.areaList?.area,
                        },
                    });
                }
            }

        }

    }


    getCurrentLocationData = () => {
        const success = (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            this.setAddressData(latitude, longitude)

            this.setState({
                latitude: latitude,
                longitude: longitude,
            });
            return { latitude: latitude, longitude: longitude };
        };

        const error = () => {
            this.setState({
                latitude: "",
                longitude: "",
            });
            return "";
        };

        navigator.geolocation.getCurrentPosition(success, error);
    };

    async componentDidMount() {
        const {
            getRegionNew,
            getAreaByUserType,
            showLoador,
            showNotification,
        } = this.props;

        if (navigator.onLine) {


            const userTypeId = localStorage.getItem("userTypeId");


            this.setState({ userTypeId: userTypeId });

            if (this.props.params.id == undefined) {
                this.setState({
                    showLocation: true,
                });
                this.getCurrentLocationData();
            }

            if (userTypeId == 1 || userTypeId == 6) {
                await getRegionNew().then(({ response, success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            } else {
                await getAreaByUserType().then(({ response, success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }
        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }



    setAddressData = (latitude, longitude) => {

        const { googleApiKey, } = this.state;
        const { showLoador, showNotification } = this.props;


        if (googleApiKey !== null) {

            if (latitude !== null && longitude !== null) {

                showLoador({ loador: true });
                const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${googleApiKey}`;

                fetch(apiUrl)
                    .then((response) => response.json())
                    .then((data) => {
                        showLoador({ loador: false });

                        if (data.status === "OK" && data.results.length !== 0) {
                            const fetchedAddress = data.results[0].formatted_address;
                            this.setState({ address: fetchedAddress })
                        } else {
                            console.error("Error:", data.status);
                        }
                    })
                    .catch((error) => {
                        showLoador({ loador: false });
                        console.error("Error:", error);
                    });
            }
        }
    };


    getDataById = (id) => {
        const { showLoador, showNotification, setSaveDistributorInquiry } = this.props;
        const { showLocation } = this.state;
        if (navigator.onLine) {
            showLoador({ loador: true });
            return apiGet({
                url: endpoint.distributorInquiry + "/" + id,
            }).then(({ data, success }) => {
                showLoador({ loador: false });
                if (success) {
                    if (!data.error) {
                        let rowData = data.data;


                        if (!showLocation) {

                            this.setAddressData(rowData.currentLocationLatitude, rowData.currentLocationLongitude)

                            this.setState({
                                latitude: rowData.currentLocationLatitude ? rowData.currentLocationLatitude : "",
                                longitude: rowData.currentLocationLongitude ? rowData.currentLocationLongitude : "",
                            });
                        }

                        setSaveDistributorInquiry({ rowObject: rowData })
                        return {
                            data: rowData,
                            success,
                        };
                    } else {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                    return { data: {}, success: false };
                }
                return { response: {}, success: false };
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };


    getListById = async (data) => {
        const {
            getStateByRegion,
            getZoneByState,
            getDistrictByZone,
            getTalukaByDistrictPost,
            getAreaByTalukaPostBodyUpdated,
            showLoador,
            showNotification,
        } = this.props;
        if (navigator.onLine) {
            if (data.currentRegionId && data.currentRegionId != "") {
                showLoador({ loador: true });
                await getStateByRegion({ regionIds: data.currentRegionId }).then(
                    ({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    }
                );
            }
            if (data.currentStateId && data.currentStateId != "") {
                showLoador({ loador: true });
                await getZoneByState({ stateIds: data.currentStateId }).then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }
            if (data.currentZoneIds && data.currentZoneIds != "") {
                showLoador({ loador: true });
                await getDistrictByZone({ zoneIds: data.currentZoneIds }).then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }
            if (data.currentDistrictIds && data.currentDistrictIds != "") {
                let districtIdJson = {
                    districtIds: typeof data.currentDistrictIds === "string"
                        ? data.currentDistrictIds.split(",")
                        : data.currentDistrictIds,
                }

                showLoador({ loador: true });
                await getTalukaByDistrictPost({ districtIds: districtIdJson }).then(
                    ({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    }
                );
            }
            if (data.currentTalukaIds && data.currentTalukaIds != "") {
                let talukaJson = {
                    talukaIds: typeof data.currentTalukaIds === "string"
                        ? data.currentTalukaIds.split(",")
                        : data.currentTalukaIds,
                }
                showLoador({ loador: true });
                await getAreaByTalukaPostBodyUpdated({ talukaIds: talukaJson }).then(
                    ({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    }
                );
            }
        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };

    onSubmitData = (dataToSave) => {

        const { latitude, longitude } = this.state;
        const { distributorInquiryList } = this.props;


        if ((latitude === "" && longitude === "")) {
            this.props.showNotification({ msg: "Please allow location", severity: "error" });
            return
        }


        let dataToSaveWithLocation = {
            ...dataToSave,
            currentLocationLatitude: latitude,
            currentLocationLongitude: longitude,
        }



        dataToSave = {
            ...distributorInquiryList.distributorInquiryData,
            ...dataToSaveWithLocation,
        };


        swal({
            title: "Are you sure?",
            text: msgConstant.saveWarningMsg,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                this.onSave(dataToSave);
            }
        });
    };



    onSave = (dataToSave) => {
        const { showLoador, showNotification, saveDistributorInquiry, changeTab } = this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            saveDistributorInquiry({ dataToSave }).then(({ response, success }) => {
                showLoador({ loador: false });
                if (success) {
                    showNotification({
                        msg: dataToSave.id ? msgConstant.updateMsg : msgConstant.savemsg,
                    });

                    if (dataToSave.id) {
                        changeTab("1");
                    } else {
                        window.location.replace("/add-distributor-form/" + response.id);
                        localStorage.setItem("activeTab", "1");
                    }
                } else {
                    showNotification({ msg: saveFailedMsg, severity: "error" });
                }
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };

    getLocation = (latitude, longitude) => {

        this.setAddressData(latitude, longitude);

        this.setState({
            openMap: false,
            latitude: latitude,
            longitude: longitude,
        });
    };

    onCancel = () => {
        this.props.navigate(-1);
    };

    onSaveLocation = () => {
        this.setState({
            openMap: true,
        });
    };

    onRadioChange = (e) => {
        const { value } = e.target;
        this.setState({
            locationValue: value,
        });
        if (value == 1) {
            this.getCurrentLocationData();
        }
    };

    getLocationData = () => {
        let locationData = "";
        const { latitude, longitude } = this.state;
        if (
            this.props.params.id == undefined &&
            latitude == "" &&
            longitude == ""
        ) {
            this.props.showNotification({
                msg: "Please allow location",
                severity: "error",
            });
        } else if (latitude != "" && longitude != "") {
            locationData = {
                latitude: this.state.latitude,
                longitude: this.state.longitude,
            };
        }

        return locationData;
    };

    changeLocation = () => {
        const { latitude, longitude } = this.state;
        if (latitude == "" && longitude == "") {
            this.getCurrentLocationData();
        }
        this.setState({
            showLocation: true,
            locationValue: "",
        });
    };

    onCloseMap = () => {
        this.setState({
            openMap: false,
        });
    };


    setDataToForm = (data) => {
        const { latitude, longitude } = this.state;
        if (latitude == "" && longitude == "") {
            this.setState({
                latitude: data.latitude,
                longitude: data.longitude,
            });
        }
    };


    render() {
        const {
            dynamicMasterData,
            openMap,
            latitude,
            longitude,
            showLocation,
            locationValue,
            userTypeId,
            address,
        } = this.state;
        return (
            <>

                {!openMap ? (
                    <>
                        <Paper
                            sx={{
                                p: 2,
                                pt: 2,
                                borderRadius: 2,
                            }}
                            elevation={0}
                        >

                            <Grid container spacing={1}>
                                {this.props.params.id != undefined && !showLocation && (
                                    <>
                                        <Grid container xs={12} sm={6} md={6}>
                                            <ButtonCompo
                                                size="medium"
                                                type="submit"
                                                variant="contained"
                                                name={"Change Location"}
                                                onClick={this.changeLocation}
                                            />
                                        </Grid>
                                        <Grid container xs={12} sm={6} md={6}></Grid>
                                    </>
                                )}

                                {showLocation && (
                                    <>
                                        <Grid container xs={12} sm={6} md={6}>
                                            <Grid item xs={12} sm={4} md={4}>
                                                &nbsp;&nbsp;
                                                <LabelCompo
                                                    className="text-black"
                                                    style={{ fontSize: 16, color: redColor }}
                                                    label={"*"}
                                                />
                                                <LabelCompo
                                                    className="text-black"
                                                    style={{ fontSize: 16, color: titleColor }}
                                                    label={"Location"}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={8} md={8}>
                                                <RadioGroup
                                                    row
                                                    aria-label={"Location"}
                                                    name={"Location"}
                                                    id={"Location"}
                                                    value={locationValue}
                                                    onChange={this.onRadioChange}
                                                    size="small"
                                                >
                                                    <FormControlLabel
                                                        value={"1"}
                                                        control={<Radio />}
                                                        label={"Current"}
                                                    />
                                                    <FormControlLabel
                                                        value={"0"}
                                                        control={<Radio />}
                                                        label={"From Map"}
                                                    />
                                                </RadioGroup>
                                            </Grid>
                                        </Grid>
                                        <Grid container xs={12} sm={6} md={6}>
                                            {(locationValue && locationValue == 0) && (
                                                <ButtonCompo
                                                    size="small"
                                                    type="submit"
                                                    variant="contained"
                                                    name={"Get Location"}
                                                    onClick={this.onSaveLocation}
                                                />
                                            )}
                                        </Grid>
                                    </>
                                )}

                                {latitude != "" && longitude != "" && (
                                    <>
                                        <Grid
                                            container
                                            xs={12}
                                            sm={6}
                                            md={6}
                                            marginTop={2}
                                        >
                                            <Grid container xs={12} sm={4} md={4}>
                                                &nbsp;&nbsp;
                                                <LabelCompo
                                                    className="text-black"
                                                    style={{ fontSize: 16, color: titleColor }}
                                                    label={"Latitude - Longitude : "}
                                                />
                                            </Grid>
                                            <Grid container xs={12} sm={8} md={8}>
                                                <LabelCompo
                                                    className="text-black"
                                                    style={{ fontSize: 16 }}
                                                    label={latitude + ", " + longitude}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            xs={12}
                                            sm={6}
                                            md={6}
                                            marginTop={1}
                                        >
                                            <Grid container xs={12} sm={4} md={4}>
                                                &nbsp;&nbsp;
                                                <LabelCompo
                                                    className="text-black"
                                                    style={{ fontSize: 16, color: titleColor }}
                                                    label={"Address : "}
                                                />
                                            </Grid>
                                            <Grid container xs={12} sm={8} md={8}>
                                                <LabelCompo
                                                    className="text-black"
                                                    style={{ fontSize: 16 }}
                                                    label={address}
                                                />
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                            </Grid>

                            <DynamicFormWithoutSave
                                formPath={AddDistributorJson.formPath}
                                fieldMeta={userTypeId == 1 || userTypeId == 6 ?
                                    AddDistributorJson.fieldMetaForBasicInfo :
                                    AddDistributorJson.fieldMetaForBasicInfoWithoutHierarchy}
                                tableColumnsToFilter={AddDistributorJson.tableColumnsToFilter}
                                apiBaseURL={AddDistributorJson.apiBaseURL}
                                baseIdColumn={AddDistributorJson.baseIdColumn}
                                dynamicMasterData={dynamicMasterData}
                                showSaveBtnMain={false}
                                showSaveBtn={true}
                                saveBtnText={"Next"}
                                onSave={this.onSubmitData}
                                callApi={true}
                                getByIdApi={true}
                                getDataById={this.getDataById}
                                getListById={this.getListById}
                            />
                        </Paper>
                    </>

                ) : (
                    <>
                        <Paper
                            elevation={0}
                        >
                            <GoogleMapForAreaExploration
                                getLocation={this.getLocation}
                                setLocation={{
                                    latitude: latitude,
                                    longitude: longitude,
                                }}
                                closeMap={this.onCloseMap}
                                address={address}
                            />
                        </Paper>
                    </>
                )}
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    areaList: state.area,
    regionList: state.region,
    stateList: state.states,
    zoneList: state.zone,
    districtList: state.district,
    talukaList: state.taluka,
    distributorInquiryList: state.distributorInquiry,
});
const mapDispatchToProps = {
    saveDistributorInquiry,
    getRegionNew,
    getStateByRegion,
    getZoneByState,
    getDistrictByZone,
    getTalukaByDistrictPost,
    getAreaByTalukaPostBodyUpdated,
    setSaveDistributorInquiry,
    getAreaByUserType,
    showLoador,
    showNotification,
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(BasicInformation);
