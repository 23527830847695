import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  channel: [],
  subChannel: [],
};
let URL = endpoints.channel;
let URL1 = endpoints.subChannel;
const channelSlice = createSlice({
  name: "channel",
  initialState,
  reducers: {
    channelSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        channel: row,
      };
    },
    subChannelSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        subChannel: row,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const { channelSuccess, subChannelSuccess, resetState } =
  channelSlice.actions;

export default channelSlice.reducer;

export const getChannel = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL,
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        const row = content.map((channelObject, index) => {
          let channelData = {
            index: index + 1,
            id: channelObject.id === null ? "" : channelObject.id,
            name:
              channelObject.name === null
                ? ""
                : channelObject.name + " (" + channelObject.shortName + ")",
            shortName:
              channelObject.shortName === null ? "" : channelObject.shortName,
            numberOfElements:
              channelObject.numberOfElements === null
                ? ""
                : channelObject.numberOfElements,
            sortOrder:
              channelObject.sortOrder === null ? "" : channelObject.sortOrder,
            isActive:
              channelObject.isActive === null ? "" : channelObject.isActive,
          };
          return channelData;
        });
        dispatch(channelSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getSubChannel = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL1,
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        const row = content.map((subChannelObject, index) => {
          let subChannelData = {
            index: index + 1,
            id: subChannelObject.id === null ? "" : subChannelObject.id,
            name:
              subChannelObject.name === null
                ? ""
                : subChannelObject.name +
                " (" +
                subChannelObject.shortName +
                ")",
            shortName:
              subChannelObject.shortName === null
                ? ""
                : subChannelObject.shortName,
            channel:
              subChannelObject.channel === null ? "" : subChannelObject.channel,
            channelName:
              subChannelObject.channel === null
                ? ""
                : subChannelObject.channel.name,
            sortOrder:
              subChannelObject.sortOrder === null
                ? ""
                : subChannelObject.sortOrder,
            isActive:
              subChannelObject.isActive === null
                ? ""
                : subChannelObject.isActive,
          };
          return subChannelData;
        });
        dispatch(subChannelSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};


export const getSubChannelByChannel = ({ channelId }) => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL1 + "/by-channel?channelId=" + channelId,
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((subChannelObject, index) => {
          let subChannelData = {
            index: index + 1,
            id: subChannelObject.id === null ? "" : subChannelObject.id,
            name:
              subChannelObject.name === null
                ? ""
                : subChannelObject.name +
                " (" +
                subChannelObject.shortName +
                ")",
            shortName:
              subChannelObject.shortName === null
                ? ""
                : subChannelObject.shortName,
            channel:
              subChannelObject.channel === null ? "" : subChannelObject.channel,
            channelName:
              subChannelObject.channel === null
                ? ""
                : subChannelObject.channel.name,
            sortOrder:
              subChannelObject.sortOrder === null
                ? ""
                : subChannelObject.sortOrder,
            isActive:
              subChannelObject.isActive === null
                ? ""
                : subChannelObject.isActive,
          };
          return subChannelData;
        });
        dispatch(subChannelSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};


export const getSubChannelByOutletType = ({ outletTypeId }) => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL1 + "/by-outlet-type?outletTypeId=" + outletTypeId,
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((subChannelObject, index) => {
          let subChannelData = {
            index: index + 1,
            id: subChannelObject.id === null ? "" : subChannelObject.id,
            name:
              subChannelObject.name === null
                ? ""
                : subChannelObject.name +
                " (" +
                subChannelObject.shortName +
                ")",
            shortName:
              subChannelObject.shortName === null
                ? ""
                : subChannelObject.shortName,
            outletType:
              subChannelObject.outletType === null ? "" : subChannelObject.outletType,
            channelName:
              subChannelObject.outletType === null
                ? ""
                : subChannelObject.outletType.name,
            sortOrder:
              subChannelObject.sortOrder === null
                ? ""
                : subChannelObject.sortOrder,
            isActive:
              subChannelObject.isActive === null
                ? ""
                : subChannelObject.isActive,
          };
          return subChannelData;
        });
        dispatch(subChannelSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};


